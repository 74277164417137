import { Box, Button, FormControlLabel, Grid, Input, Link, Modal, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "../../../styles/extraService.scss";
import * as CM from "../../common/Common";
import * as CMJ from "../../common/CommonDataJson";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

const SendSmsDirectSendTab = (props) => {
  const transactionMethodOptions = [
    { label: "전체", value: "" },
    { label: "CMS", value: "CMS" },
    { label: "기타", value: "ETC" },
  ];
  const store = props.store;
  const [accountRegistrationStatus, setAccountRegistrationStatus] = useState(""); //서비스 상태
  const [capitalName, setCapitalName] = useState(""); //자금 종류
  const [customerGroupName, setCustomerGroupName] = useState(""); //고객구분
  const [sendCustomerType, setSendCustomerType] = useState("isReceiptCustomer"); //발송 대상 고객 타입
  const [isPaymentCustomer, setIsPaymentCustomer] = useState(false); //지급고객 여부
  const [isReceiptCustomer, setIsReceiptCustomer] = useState(true); //수납고객 여부
  const [pageNumber, setPageNumber] = useState(0); //요청 페이지 번호
  const [pageSize, setPageSize] = useState(5); //페이지당 조회 건수
  const [transactionMethod, setTransactionMethod] = useState(""); //수납방법
  const [searchType, setSearchType] = useState("customerName");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [customerGroupNameList, setCustomerGroupNameList] = useState([]); //고객구분 Select Box Option List
  const [capitalNameList, setCapitalNameList] = useState([]); //자금종류 Select Box Option List
  const [messageSendingTargetDtoList, setMessageSendingTargetDtoList] = useState([]); //API문서 > 파라미터 DTO 배열
  const [sendSmsContents, setSendSmsContents] = useState(""); //SMS 발송 내용
  const [sentMobile, setSentMobile] = useState(""); //회신 번호
  const [totalElements, setTotalElements] = useState(0); //검색건수
  const [offset, setOffset] = useState(0);
  const [searchRequest, setSearchRequest] = useState({ sortProperty: "customerName", sortDirection: "DESC" }); //table 정렬 여부
  const [open, setOpen] = useState(false);
  const [capitalAllList, setCapitalAllList] = useState([]);
  const [sendExcludedTargets, setSendExcludedTargets] = useState([]); // 발송 제외 대상

  useEffect(() => {
    const fetchCustomerGroupName = async () => {
      return new Promise((resolve, reject) => {
        store
          .axiosCustomerGroup()
          .then((list) => {
            setCustomerGroupNameList(list);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    const fetchCustomerType = async () => {
      return new Promise((resolve, reject) => {
        store
          .axiosBusinessInfo()
          .then((instituteInfo) => {
            if (CM.cfnIsNotEmpty(instituteInfo)) {
              if (instituteInfo.cmsService.indexOf("EB21") >= 0 || instituteInfo.cmsService.indexOf("EC21") >= 0) {
                setSendCustomerType("isReceiptCustomer");
                setIsReceiptCustomer(true);
                setIsPaymentCustomer(false);
              } else {
                setSendCustomerType("isPaymentCustomer");
                setIsReceiptCustomer(false);
                setIsPaymentCustomer(true);
              }
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const fetchCapitalList = async () => {
      return new Promise((resolve, reject) => {
        store
          .axiosCapitals()
          .then((capitals) => {
            setCapitalAllList(capitals);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const fetchMaster = async () => {
      return new Promise((resolve, reject) => {
        store
          .axiosMaster()
          .then((master) => {
            setSentMobile(master.telephone);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    const fetchAll = async () => {
      const first = fetchCustomerGroupName();
      const second = fetchCustomerType();
      const third = fetchCapitalList();
      const fourth = fetchMaster();
      searchSendSmsTargetList({ first: await first, second: await second, third: await third, fourth: await fourth });
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCapitalNameList(
      capitalAllList
        .filter((capital) => (sendCustomerType === "isReceiptCustomer" ? capital.capitalType === "RECEIPT" : capital.capitalType === "PAYMENT"))
        .map((capital) => ({
          label: capital.capitalName,
          value: capital.uniqueKey,
          key: capital.uniqueKey,
        }))
    );
  }, [capitalAllList, sendCustomerType]);

  const handleRadioChange = (e) => {
    setSendCustomerType(e.target.value);
    setIsPaymentCustomer(e.target.value === "isPaymentCustomer");
    setIsReceiptCustomer(e.target.value === "isReceiptCustomer");
  };

  const searchSendSmsTargetList = () => {
    let searchConditionQueryString = "";
    if (searchKeyword.trim().length > 0) {
      if (searchType === "customerName") {
        searchConditionQueryString += "&customerName=" + searchKeyword;
      } else {
        searchConditionQueryString += "&customerMobile=" + searchKeyword;
      }
    }

    let url = "/api/extraservice/messages/targets";
    let queryString =
      "?accountRegistrationStatus=" +
      accountRegistrationStatus +
      "&capitalName=" +
      capitalName +
      "&customerGroupName=" +
      customerGroupName +
      searchConditionQueryString +
      "&isPaymentCustomer=" +
      isPaymentCustomer +
      "&isReceiptCustomer=" +
      isReceiptCustomer +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&sortDirection=" +
      searchRequest.sortDirection +
      "&sortProperty=" +
      searchRequest.sortProperty;

    if (transactionMethod === "CMS") {
      if (sendCustomerType === "isReceiptCustomer") {
        //수납고객일경우 CMS출금
        queryString += "&transactionMethod=CMS_WITHDRAW";
      } else {
        //지급고객일경우 CMS입금
        queryString += "&transactionMethod=CMS_DEPOSIT";
      }
    } else {
      //CMS가 아닐경우(전체,기타)
      queryString += "&transactionMethod=" + transactionMethod;
    }

    CM.cfnAxios(url + queryString, "get", "", (status, responseSendSmsTargetList) => {
      if (status.status === 200) {
        let resultSendSmsTargetList = responseSendSmsTargetList.content.map((element) => ({
          ...element,
          excluded: sendExcludedTargets.findIndex((excluded) => excluded.customerUniqueKey === element.customerUniqueKey) >= 0,
        }));

        setMessageSendingTargetDtoList(resultSendSmsTargetList);
        setTotalElements(responseSendSmsTargetList.totalElements);
        setOffset(responseSendSmsTargetList.pageable.offset);
        setPageSize(responseSendSmsTargetList.pageable.pageSize);
        setPageNumber(responseSendSmsTargetList.pageable.pageNumber);
      }
    });
  };

  useEffect(() => {
    setPageNumber(0);
  }, [pageSize]);

  useEffect(() => {
    searchSendSmsTargetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setPageSize(value);
    setPageNumber(0);
  };

  const smsParamValidation = () => {
    if (sendSmsContents.trim().length < 1) {
      CM.cfnAlert("SMS 발송 내용을 입력해주세요.");
      return false;
    }
    let sentMobileNumber = sentMobile.replace(/[\D]/, "");
    if (sentMobileNumber.length < 1) {
      CM.cfnAlert("발신번호를 입력해주세요.");
      return false;
    }

    const numberRegex = /[^0-9]/g;
    if (numberRegex.test(sentMobileNumber)) {
      CM.cfnAlert("숫자만 입력해주세요.");
      return false;
    }

    if (sentMobileNumber.startsWith("15") || sentMobileNumber.startsWith("16") || sentMobileNumber.startsWith("18")) {
      if (sentMobileNumber.length !== 8) {
        CM.cfnAlert("전국대표번호(15YY,16YY,18YY)는 8자리만 허용됩니다.");
        return false;
      }
    } else if (sentMobileNumber.startsWith("030") || sentMobileNumber.startsWith("050")) {
      if (sentMobileNumber.length > 12) {
        CM.cfnAlert("030,050번호는 12자리까지 허용됩니다.");
        return false;
      }
    } else {
      if (sentMobileNumber.length < 8 || sentMobileNumber.length > 11) {
        CM.cfnAlert("발신번호의 전체자리수는 8~11자리만 허용됩니다.");
        return false;
      } else {
        const areaCode = [
          "010",
          "011",
          "016",
          "017",
          "019",
          "02",
          "031",
          "032",
          "033",
          "041",
          "042",
          "043",
          "051",
          "052",
          "053",
          "054",
          "055",
          "061",
          "062",
          "063",
          "064",
          "020",
          "040",
          "060",
          "070",
          "080",
          "090",
        ];

        let flag = false;
        for (let idx = 0; idx < areaCode.length; idx++) {
          if (sentMobileNumber.startsWith(areaCode[idx])) {
            flag = true;
            let tempNum = sentMobileNumber;
            tempNum = tempNum.substr(tempNum.indexOf(areaCode[idx]) + areaCode[idx].length, tempNum.length);

            if (tempNum.startsWith("15") || tempNum.startsWith("16") || tempNum.startsWith("18")) {
              CM.cfnAlert("전국대표번호(15YY,16YY,18YY)는 지역번호가 포함될 수 없습니다.");
              return false;
            }
          }
        }
        if (flag === false) {
          CM.cfnAlert("유선전화번호는 지역번호를 포함해야합니다.");
          return false;
        }
      }
    }

    let smsContentLength = getByteLength(sendSmsContents.trim());
    if (smsContentLength > 80) {
      CM.cfnAlert("발송내용이 80바이트를 초과하였습니다.");
    } else {
      sendSms();
    }
  };

  const sendSms = () => {
    const totalLength = totalElements - sendExcludedTargets.length;
    if (totalLength > 0) {
      let confirmMessage = "총 " + totalLength + "명에게 발송됩니다. 문자메세지를 발송하시겠습니까?";
      CM.cfnConfirm(confirmMessage, () => {
        let toDateFormat = CM.cfnConvertDateTimeToString(new Date());

        const url = "/api/extraservice/messages";
        const messageSendingExcludedDto = {};

        const sendingMessage = sendSmsContents.trim(); //메시지 내용
        let sentMobileNumber = sentMobile.replace(/[\D]/gi, ""); //발송번호
        let targetCriteriaDto = {}; //메시지 발송대상/내역 조회 및 발송처리 검색조건에 대한 Request Parameters Dto
        targetCriteriaDto.isReceiptCustomer = isReceiptCustomer;
        targetCriteriaDto.isPaymentCustomer = isPaymentCustomer;
        targetCriteriaDto.accountRegistrationStatus = accountRegistrationStatus;
        targetCriteriaDto.transactionMethod = transactionMethod;
        targetCriteriaDto.customerGroupName = customerGroupName;
        targetCriteriaDto.capitalName = capitalName;
        if (searchType === "customerName") {
          targetCriteriaDto.customerName = searchKeyword;
          targetCriteriaDto.customerMobile = "";
        } else {
          targetCriteriaDto.customerName = "";
          targetCriteriaDto.customerMobile = searchKeyword;
        }

        const messageExcludedDtos = sendExcludedTargets.map((value, key) => {
          if (value.customerMobile !== "" && value.customerMobile) {
            value.sentMobile = sentMobileNumber;
            value.messageSentMessage = sendSmsContents;
            value.messageType = "FREESTYLE";
            value.messageSendingTime = toDateFormat;
          }
          return value;
        });

        messageSendingExcludedDto.sendingMessage = sendingMessage;
        messageSendingExcludedDto.sentMobile = sentMobileNumber;
        messageSendingExcludedDto.targetCriteriaDto = targetCriteriaDto;
        messageSendingExcludedDto.messageExcludedDtos = messageExcludedDtos;
        messageSendingExcludedDto.messageAddedDtos = [];

        CM.cfnAxios(url, "post", messageSendingExcludedDto, (objStatus, responseData) => {
          if (objStatus.status === 200) {
            if (typeof responseData !== "object") {
              CM.cfnAlert("메세지가 성공적으로 발송되었습니다.");
              return false;
            }
          } else {
            if (typeof responseData !== "object") {
              CM.cfnAlert("메세지 발송 중 오류가 있습니다. (" + responseData + ")");
              return false;
            } else {
              CM.cfnAlert(responseData.message || "메세지 발송 중 오류가 있습니다. 결과 탭에서 결과를 확인하시기 바랍니다.");
              return false;
            }
          }
        });
      });
    }
  };

  const getByteLength = (smsContent) => {
    let length = 0;
    let str = smsContent.substring(0);
    if (str === null) return 0;
    for (let i = 0; i < str.length; i++) {
      let ch = escape(str.charAt(i));
      if (ch.length === 1) length++;
      else if (ch.indexOf("%u") !== -1) length += 2;
      else if (ch.indexOf("%") !== -1) length += ch.length / 3;
    }
    return length;
  };

  const handleSortProperty = (sortObjArray) => {
    let tempSearchRequest = { ...searchRequest };
    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") tempSearchRequest.sortProperty = obj.value;
      if (obj.name === "sortDirection") tempSearchRequest.sortDirection = obj.value;
    }
    setSearchRequest(tempSearchRequest);
  };

  // 발송제외 버튼을 눌렀을 때 동작
  const changeSendExcludedTargets = (content) => {
    const customerUniqueKey = content.customerUniqueKey;

    if (!content.excluded) {
      setSendExcludedTargets([...sendExcludedTargets, content]);
    } else {
      let firstIndex = sendExcludedTargets.findIndex((target) => !target.customerUniqueKey && target.customerName === content.customerName && target.customerMobile === content.customerMobile);
      if (firstIndex >= 0) {
        let toBeTargets = [...sendExcludedTargets];
        toBeTargets.splice(firstIndex, 1);
        setSendExcludedTargets(toBeTargets);
      }
    }

    setMessageSendingTargetDtoList(messageSendingTargetDtoList.map((element) => (element.customerUniqueKey === customerUniqueKey ? { ...element, excluded: !element.excluded } : element)));
  };

  return (
    <Typography component="div" role="tabpanel" value={0} index={0} hidden={1 !== props.tabIndex}>
      <Box>
        <div className="inforbox">
          <ul>
            <li>
              전기통신사업법 제84조에 의거 2015.10.16부터 전화번호 세칙에 위반되는 발신번호 문자전송이 제한됩니다.
              <Link to="" onClick={() => setOpen(true)}>
                <b>(전화번호 세칙 보기)</b>
              </Link>
            </li>
          </ul>
        </div>
        <div className="search-area tl">
          <div className="block">
            <RadioGroup name="sendCustomerType" onChange={handleRadioChange} value={sendCustomerType} row>
              <label className="label-l">수납/지급고객 구분</label>
              <FormControlLabel key={0} control={<Radio color="primary" />} value="isReceiptCustomer" label="수납고객" />
              <FormControlLabel key={1} control={<Radio color="primary" />} value="isPaymentCustomer" label="지급고객" />
            </RadioGroup>
            <label className="label-l">서비스상태</label>
            <Select
              native
              className="w130"
              name="accountRegistrationStatus"
              value={accountRegistrationStatus}
              onChange={(e) => setAccountRegistrationStatus(e.target.value)}
              disabled={sendCustomerType === "isPaymentCustomer" ? true : false}
              inputProps={{
                "data-testid": "service-status-select",
              }}>
              {Object.keys(CMJ.accountRegistrationStatusLabel).map((key, index) => {
                return (
                  <option value={key} key={index}>
                    {CMJ.accountRegistrationStatusLabel[key]}
                  </option>
                );
              })}
            </Select>
            <label className="label-l">수납·지급방법</label>
            <Select
              native
              className="w130"
              name="transactionMethod"
              value={transactionMethod}
              onChange={(e) => setTransactionMethod(e.target.value)}
              inputProps={{
                "data-testid": "payment-method-select",
              }}>
              {transactionMethodOptions.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
            <br />
            <br />
            <label className="label-l">고객구분</label>
            <Select
              native
              className="w120"
              name="customerGroupName"
              value={customerGroupName}
              onChange={(e) => setCustomerGroupName(e.target.value)}
              inputProps={{
                "data-testid": "customer-type-select",
              }}>
              <option value="">전체</option>
              {customerGroupNameList.map((index, key) => {
                return (
                  <option value={index["customerGroupName"]} key={key}>
                    {index["customerGroupName"]}
                  </option>
                );
              })}
            </Select>
            <label className="label-l">자금종류</label>
            <Select
              native
              className="w120"
              name="capitalName"
              value={capitalName}
              onChange={(e) => setCapitalName(e.target.value)}
              inputProps={{
                "data-testid": "fund-type-select",
              }}>
              <option value="">전체</option>
              {capitalNameList.map((index, key) => {
                return (
                  <option value={index["label"]} key={key}>
                    {index["label"]}
                  </option>
                );
              })}
            </Select>
            <label className="label-l">검색어입력</label>
            <Select
              native
              className="w120"
              name="searchType"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              inputProps={{
                "data-testid": "search-type-select",
              }}>
              {Object.keys(CMJ.searchType).map((key, index) => {
                return (
                  <option value={key} key={index}>
                    {CMJ.searchType[key]}
                  </option>
                );
              })}
            </Select>
            <Input name="searchKeyword" className="w130" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} onKeyUp={(e) => e.keyCode === 13 && searchSendSmsTargetList()} />
            <button className="search-button" onClick={searchSendSmsTargetList} data-testid="search-send-sms-btn">
              발송대상검색
            </button>
          </div>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Paper>
                <div className="table-top-area">
                  <CF.TotalCountForm totalElements={totalElements || 0} />
                  <CF.RowPerPageForm value={pageSize} onChange={handleRowPerPageChange} customProps={{ inputProps: { "data-testid": "support-faq-rowPerPage-select" } }} />
                </div>
                <div>
                  <Table>
                    {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "80px"])}
                    <TableForm.compServerSortTableHead
                      useCheckbox={false}
                      arrData={[
                        { id: "capitalType", label: "수납/지급구분", sortable: false },
                        { id: "customerGroupName", label: "고객구분", sortable: true },
                        { id: "customerName", label: "고객명", sortable: true },
                        { id: "customerMobile", label: "휴대전화번호", sortable: false },
                        { id: "exemptToSend", label: "발송제외", sortable: false },
                      ]}
                      searchRequest={searchRequest}
                      handleSortProperty={handleSortProperty}
                    />
                    <TableBody>
                      {totalElements === 0 ? (
                        <TableForm.compEmptyTableRow colSpan={5} />
                      ) : (
                        messageSendingTargetDtoList.map((row, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="center">{CMJ.serverCustomerType[row["capitalType"]]}</TableCell>
                              <TableCell align="center">{row["customerGroupName"]}</TableCell>
                              <TableCell align="center">{row["customerName"]}</TableCell>
                              <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(row["customerMobile"])}</TableCell>
                              <TableCell align="center">
                                <button className="btn-s search-button" onClick={() => changeSendExcludedTargets(row)}>
                                  {row.excluded ? "제외취소" : "제외하기"}
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                  <Pagination
                    className="centered"
                    limit={pageSize}
                    offset={offset}
                    total={totalElements}
                    onClick={(e, offset, page) => {
                      setOffset(offset);
                      setPageNumber(page - 1);
                    }}
                    reduced={true}
                    centerRipple={false}
                    disableFocusRipple={true}
                    disableRipple={true}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <div className="sms-message-area tc">
                  <div className="sms-message-textbox">
                    <div className="title">SMS 발송 내용</div>
                    <TextField
                      className="w100p"
                      multiline
                      rows="8"
                      cols="80"
                      value={sendSmsContents}
                      onChange={(e) => setSendSmsContents(e.target.value)}
                      variant="outlined"
                      margin="normal"
                      name="sendSmsContents"
                      data-testid="send-smsContents-input"
                    />
                    <div className="return-num">
                      <label className="stronger-label">발신번호</label>
                      <Input
                        name="sentMobile"
                        className="w180"
                        value={CM.cfnAddtDashToPhoneNumber(sentMobile) || ""}
                        onChange={(e) => setSentMobile(e.target.value.replace(/-/gi, ""))}
                        inputProps={{
                          "data-testid": "send-phoneNumber-input",
                        }}
                      />
                    </div>
                  </div>
                  <button className="btn-l" onClick={() => smsParamValidation()} data-testid="search-address-btn">
                    SMS 보내기
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
          <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
            <div
              className="paper"
              style={{
                width: "800px",
                height: "530px",
              }}>
              <div className="inner">
                <div className="modal-top-area">
                  <Button className="fr btn-close" onClick={() => setOpen(false)} data-testid="close-modal">
                    {""}
                  </Button>
                </div>
                <h3>발신번호 세칙</h3>
                <div className="inforbox">
                  <ol>
                    <li>1. 발신번호로 공백 사용 불가</li>
                    <li>
                      2. 전화번호의 전체자리수는 8자리~11자리만 허용되나 아래의 경우 예외
                      <br /> - 030,050 번호의 경우 12자리까지 허용
                      <br /> - 전국대표번호(15YY, 16YY, 18YY)는 8자리만 허용(지역번호 및 내선번호 포함 불가)
                      <br /> - 특수번호(112,1335 등)인 경우 해당 사용자(국가, 공공기관 등)에 한하여 허용
                    </li>
                    <li>3. 유선전화번호 : 지역번호를 반드시 포함할 것(17개 지역번호 포함)</li>
                    <li>4. 공통서비스특별번호(0N0 계열) : 번호 앞에 지역번호 사용금지</li>
                  </ol>
                </div>
                <div>
                  <Table className="contentsTable">
                    <TableHead>
                      <TableRow className="contentsTableRow">
                        <TableCell className="contentsTableCell">발신번호 이용 주의 사항</TableCell>
                        <TableCell className="contentsTableCell">예시</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="contentsTableRow">
                        <TableCell className="contentsTableCell">수신자가 실제 발신(통화)이 가능한 번호만 사용</TableCell>
                        <TableCell className="contentsTableCell">
                          1111(X)
                          <br /> 0000(X)
                        </TableCell>
                      </TableRow>
                      <TableRow className="contentsTableRow">
                        <TableCell className="contentsTableCell">유선번호의 경우 지역번호를 앞자리에 반드시 포함</TableCell>
                        <TableCell className="contentsTableCell">
                          02-6919-1000(O)
                          <br /> 6919-1000(X)
                        </TableCell>
                      </TableRow>
                      <TableRow className="contentsTableRow">
                        <TableCell className="contentsTableCell">전국대표번호는 8자리만 허용</TableCell>
                        <TableCell className="contentsTableCell">
                          1544-5992(O)
                          <br /> 02-1544-5992(X)
                        </TableCell>
                      </TableRow>
                      <TableRow className="contentsTableRow">
                        <TableCell className="contentsTableCell">030, 050 번호에 대해 12자리까지 허용</TableCell>
                        <TableCell className="contentsTableCell">0505-8888-8888(O)</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Box>
    </Typography>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(SendSmsDirectSendTab));
