import React, { useState } from "react";
import CustMnmtTable from "./CustMnmtTable";
import CustMnmtBankBookTable from "./CustMnmtBankBookTable";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
const CustomerCriteria = () => {
  /*
   * @desc  TabPanel 영역
   */
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };
  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
          <Tab disableRipple value={0} label="고객구분 관리" />
          <Tab disableRipple value={1} label="자금종류 및 통장기재내역 관리" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <div>
          <div className="inforbox">
            <ul>
              <li>최초 업무 시에는 기본고객이 기본으로 설정됩니다.</li>
            </ul>
          </div>
        </div>
        <CustMnmtTable />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div>
          <div className="inforbox">
            <ul>
              <li>통장기재내역은 수납(지급)시 고객의 통장에 기재되는 내용으로 현재 화면에서는 등록되어 있는 통장기재내역의 선택만 가능합니다.</li>
              <li>
                통장기재내역 항목의 추가, 변경, 삭제는{" "}
                <a href="https://www.cmsedi.or.kr/cms" target="_blank" rel="noopener noreferrer" className="link">
                  CMS홈페이지
                </a>{" "}
                및 고객센터(1577-5500)로 신청 가능합니다.{" "}
              </li>
            </ul>
          </div>
        </div>
        <CustMnmtBankBookTable />
      </TabPanel>
    </div>
  );
};

export default CustomerCriteria;
