import axios from "axios";
import React from "react";
import { trackPromise } from "react-promise-tracker";
import { openAlert } from "../template/AlertForm";
import { openConfirm } from "../template/ConfirmForm";
const http = require("http");
const https = require("https");
const httpAgent = new http.Agent({ keepAlive: true, timeout: 300000, freeSocketTimeout: 100000 });
const httpsAgent = new https.Agent({ keepAlive: true, timeout: 300000, freeSocketTimeout: 100000 });
/*
 * process.env.NODE_ENV 서버 환경
 * development : local
 * test : 개발서버
 * production : 운영
 */
export const SERVER_ENV = process.env.NODE_ENV;
export const buildUrlObject = {
  test: "/",
  development: "https://test.billingone.or.kr:28888/",
  production: "/",
};

export const SERVER_URL = buildUrlObject[SERVER_ENV];
axios.defaults.baseURL = SERVER_URL;
axios.defaults.httpAgent = httpAgent;
axios.defaults.httpsAgent = httpsAgent;
axios.interceptors.request.use(function (config) {
  if (config.method === "get") {
    config.url = config.url + (config.url.indexOf("?") === -1 ? "?" : "&") + "timestamp=" + new Date().getTime();
  }
  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 504: // TIMEOUT
          if (error.response.data) {
            error.response.data.message = "현재 요청량이 많아 처리가 지연되고 있습니다. 요청하신 내용을 계속 진행중이므로 잠시 후에 처리결과를 확인해주시기 바랍니다.";
          }
          break;
        case 500:
          if (error.response.data && error.response.data.message === "GENERAL") {
            error.response.data.message = "서버 시스템에서의 응답이 없습니다. 고객센터(1577-5500)로 문의주시기 바랍니다.";
          } else if (error.response?.data?.message?.indexOf("Maximum upload size exceeded") >= 0) {
            error.response.data.message =
              '업로드한 파일의 크기가 너무 큽니다(1MB 초과).\n엑셀(*.xls)파일이라면 엑셀 프로그램 > 파일 > 다른 이름으로 저장 > 저장위치 선택 > 파일 형식을 "Excel 통합 문서 (*.xlsx)"로 변경하여 다시 시도해 주시기 바랍니다.';
          } else if (error.response.data) {
            error.response.data.message = "서버 시스템 오류입니다. 고객센터(1577-5500)로 문의주시기 바랍니다.";
          } else {
            error.response.data = { message: "서버 시스템 오류입니다. 고객센터(1577-5500)로 문의주시기 바랍니다." };
          }
          break;
        case 406:
          if (error.response.data) {
            error.response.data.message = "서버와 브라우저 간 통신 오류입니다. 고객센터(1577-5500)로 문의주시기 바랍니다.";
          }
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export let gblHeader = "";

export const cfnSetHeader = (authorization) => {
  gblHeader = authorization;
};

export const cfnGetHeader = () => {
  if (cfnIsEmpty(gblHeader)) {
    if (sessionStorage.hasOwnProperty("loginDto")) {
      let loginDtoObject = typeof storage.get("loginDto") === "object" ? storage.get("loginDto") : JSON.parse(storage.get("loginDto"));
      gblHeader = loginDtoObject.hasOwnProperty("jwt") ? loginDtoObject["jwt"] : gblHeader;
    }
  }

  return gblHeader;
};

/*
 * @desc    object deep copy를 처리하는 함수
 * @param   { object }
 * @return  { object }
 */
export const cfnCopyObject = (obj) => {
  let clone = {};

  if (Array.isArray(obj)) {
    clone = obj.map((val) => {
      return cfnCopyObject(val);
    });
  } else if (typeof obj === "object" && obj !== null) {
    if (cfnValidDate(obj)) {
      clone = new Date(obj.getTime());
    } else {
      for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
          clone[i] = cfnCopyObject(obj[i]);
        }
      }
    }
  } else {
    clone = obj;
  }
  return clone;
};

/*
 * @desc    object deep copy를 처리하는 함수 (null -> ''로 치환)
 * @param   { object }
 * @return  { object }
 */
export const cfnCopyNotNullObject = (obj) => {
  let clone = {};

  if (Array.isArray(obj)) {
    clone = obj.slice().map((val) => {
      return cfnCopyNotNullObject(val);
    });
  } else if (typeof obj === "object" && obj !== null) {
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        clone[i] = cfnCopyNotNullObject(obj[i]);
      }
    }
  } else if (obj === null) {
    clone = "";
  } else {
    clone = obj;
  }
  return clone;
};

/*
 * @desc    값이 없는지 확인하는 함수
 * @param   { object }
 * @return  { boolean }
 */
export const cfnIsEmpty = (val) => {
  if (val === null) return true;
  if (typeof val === "undefined") return true;
  if (typeof val === "string" && val.trim() === "") return true;
  if (Array.isArray(val) && val.length < 1) return true;
  if (typeof val === "object" && val.constructor.name === "Object" && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true;
  if (typeof val === "object" && val.constructor.name === "String" && Object.keys(val).length < 1) return true;

  return false;
};

/*
 * @desc    값이 있는지 확인하는 함수
 * @param   { object }
 * @return  { boolean }
 */
export const cfnIsNotEmpty = (val) => {
  if (val === null) return false;
  if (typeof val === "undefined") return false;
  if (typeof val === "string" && val.trim() === "") return false;
  if (Array.isArray(val) && val.length < 1) return false;
  if (typeof val === "object" && val.constructor.name === "Object" && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val).length < 1) return false;
  if (typeof val === "object" && val.constructor.name === "String" && Object.keys(val).length < 1) return false;

  return true;
};

/*
 * @desc    axios를 처리하는 함수
 * @param   {
 *            url: string
 *            method: string
 *            data: object
 *            fncallback: function
 *            fnErrorCallback: function
 *            isMultipart : boolean
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnAxios = (url, method, data, fnCallback, fnErrorCallback, isMultipart, silent = false) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: encodeURI(url),
    method: method,
    data: data,
    headers: {
      "Content-type": "application/json",
    },
    // withCredentials: true,
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  //console.error('axios 전송 전 헤더 설정 : url = ' + url + ', config = ' + config.headers.Authorization); //로그인 테스트
  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status, statusText };
  };

  //파일포함 전송 시 Content-type 설정 변경
  if (isMultipart !== "undefined" && isMultipart) {
    config.headers["Content-type"] = "multipart/form-data";
  }

  trackPromise(
    axios(config)
      .then((response) => {
        const objStatus = fnSetStatus(response.status, response.statusText);

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === "function") fnCallback(objStatus, response.data);
      })
      .catch((error) => {
        // Network error 발생할 때
        console.error(error);
        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
          fnErrorCallback(error);
        }
        if (cfnIsNotEmpty(error.response) && !silent) {
          let message = "";
          if (typeof error.response.data === "string") message = error.response.data;
          else if (typeof error.response.data === "object") message = error.response.data.message;
          cfnAlert(message || error.response);
        }
      })
  );
};

export const cfnAxiosAsync = async (url, method, data, isMultipart) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: encodeURI(url),
    method: method,
    data: data,
    headers: {
      "Content-type": isMultipart !== "undefined" && isMultipart ? "multipart/form-data" : "application/json",
    },
    // withCredentials: true,
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  return trackPromise(axios(config));
};

/*
 * @desc    axios login을 처리하는 함수
 * @param   {
 *            url: string
 *            method: string
 *            data: object
 *            fncallback: function
 *            fnErrorCallback: function
 *            isMultipart : boolean
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnAxiosLogin = (url, method, data, fnCallback, fnErrorCallback, isMultipart) => {
  const config = {
    url: url,
    method: method,
    data: data,
    // withCredentials: true,
  };

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status, statusText };
  };

  trackPromise(
    axios(config)
      .then((response) => {
        const objStatus = fnSetStatus(response.status, response.statusText);

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === "function") fnCallback(objStatus, response.data);
      })
      .catch((error) => {
        // Network error 발생할 때
        if (cfnIsEmpty(error.response)) {
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
            fnErrorCallback(error);
          } else {
            cfnAlert("요청이 정상적으로 처리되지 않았습니다. 잠시 후 다시 시도해주시거나, 같은 현상이 반복될 경우 고객센터(1577-5500)로 문의하여주시기 바랍니다.");
          }
          return false;
        }

        let message = "";
        if (typeof error.response.data === "string") message = error.response.data;
        else if (typeof error.response.data === "object") message = error.response.data.message;

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
          fnErrorCallback(objStatus);
        } else {
          if (message.indexOf("Undefined") === -1) {
            // (임시) error message 출력
            cfnAlert(message);
          } else {
            cfnAlert("로그인에 실패하였습니다.");
          }
        }
      })
  );
};

/*
 * @desc    axios를 처리하는 함수
 * @param   {
 *            url: string
 *            method: string
 *            data: object
 *            fncallback: function
 *            fnErrorCallback: function
 *            isMultipart : boolean
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnAxiosFileDownload = async (url, method, data, fnCallback, fnErrorCallback, isMultipart, filename) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: encodeURI(url),
    method,
    data,
    responseType: "arraybuffer",
    exposedHeaders: ["Content-Disposition"],
    headers: {
      Accept: "application/json,application/octet-stream,image/*,text/plain;charset=UTF-8",
    },
    // withCredentials: true,
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status: status, statusText: statusText };
  };

  return trackPromise(
    axios(config)
      .then((response) => {
        let contentDisposition = response.headers["content-disposition"];
        let index = contentDisposition.indexOf("''") !== -1 ? contentDisposition.lastIndexOf("'") : contentDisposition.indexOf("=");

        let fileName = decodeURIComponent(contentDisposition.substring(index + 1));

        if (filename !== "" && filename !== undefined) {
          let fileNm = fileName.substring(0, fileName.indexOf("."));
          fileName = fileName.replace(fileNm, filename);
        }

        const objStatus = fnSetStatus(response.status, response.statusText);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: "application/octet-stream,text/plain" }), fileName);
        } else {
          const blobURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/octet-stream,text/plain" }));
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = blobURL;
          tempLink.setAttribute("download", fileName);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === "function") fnCallback(objStatus, response.data);
      })
      .catch((error) => {
        // Network error 발생할 때
        console.error(error);
        if (cfnIsEmpty(error.response)) {
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
            fnErrorCallback(error);
          } else {
            cfnAlert("요청이 정상적으로 처리되지 않았습니다. 잠시 후 다시 시도해주시거나, 같은 현상이 반복될 경우 고객센터(1577-5500)로 문의하여주시기 바랍니다.");
          }
          return false;
        }

        let message = "";
        if (typeof error.response.data === "string") {
          message = error.response.data;
        } else if (typeof error.response.data === "object") {
          let messageJson = JSON.parse(decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))));

          message = Object.keys(messageJson).length === 0 ? error.response.statusText : messageJson["message"];
        }

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
          fnErrorCallback(error, objStatus, message);
        } else {
          // (임시) error message 출력
          cfnAlert(
            message && typeof message === "string"
              ? message
              : error && error.response && error.response.data && typeof error.response.data === "string"
              ? error.response.data
              : error && error.response && error.response.data && typeof error.response.data.message === "string"
              ? error.response.data.message
              : "요청한 양이 너무 많아 처리가 불가합니다. 검색조건 설정 등으로 출력되는 항목의 수를 줄이신 후 다시 시도해주시기 바랍니다.",
            () => {}
          );
        }
      })
  );
};

export const cfnAxiosPreview = (url, method, data, fnCallback, fnErrorCallback) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: url,
    method: method,
    data: data,
    responseType: "arraybuffer",
    exposedHeaders: ["Content-Disposition"],
    headers: {
      Accept: "application/pdf,application/octet-stream,image/*,text/plain;charset=UTF-8",
    },
    // withCredentials: true,
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status: status, statusText: statusText };
  };

  trackPromise(
    axios(config)
      .then((response) => {
        let contentDisposition = response.headers["content-disposition"];
        let index = contentDisposition.indexOf("''") !== -1 ? contentDisposition.lastIndexOf("'") : contentDisposition.indexOf("=");

        let fileName = decodeURIComponent(contentDisposition.substring(index + 1));

        const objStatus = fnSetStatus(response.status, response.statusText);

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === "function") fnCallback(objStatus, response.headers["content-type"].toLowerCase(), response.data, fileName);
      })
      .catch((error) => {
        // Network error 발생할 때
        console.log(error);
        if (cfnIsEmpty(error.response)) {
          cfnAlert(error.response);
          return false;
        }

        let message = "";
        if (typeof error.response.data === "string") {
          message = error.response.data;
        } else if (typeof error.response.data === "object") {
          let messageJson = JSON.parse(decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))));

          message = Object.keys(messageJson).length === 0 ? error.response.statusText : messageJson["message"];
        }

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
          fnErrorCallback(objStatus);
        } else {
          // (임시) error message 출력

          cfnAlert(message);
        }
      })
  );
};

/*
 * @desc    axios를 처리하는 함수 (일반 파일 다운로드 처리)
 * @param   {
 *            url: string
 *            method: string
 *            data: object
 *            fncallback: function
 *            fnErrorCallback: function
 *            isMultipart : boolean
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnAxiosGeneralFileDownload = (url, method, data, fileName, fnCallback, fnErrorCallback, isMultipart) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: url,
    method: method,
    data: data,
    responseType: "arraybuffer",
    headers: {
      Accept: "application/json,application/octet-stream,text/plain;charset=UTF-8",
    },
    // withCredentials: true,
  };

  //String.fromCharCode.apply(null, new Uint8Array(error.response.data))

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status: status, statusText: statusText };
  };

  trackPromise(
    axios(config)
      .then((response) => {
        const objStatus = fnSetStatus(response.status, response.statusText);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: "application/octet-stream,text/plain" }), fileName);
        } else {
          const blobURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/octet-stream,text/plain" }));
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = blobURL;
          tempLink.setAttribute("download", fileName);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === "function") fnCallback(objStatus, response.data);
      })
      .catch((error) => {
        console.log(error);
        // Network error 발생할 때
        if (cfnIsEmpty(error.response)) {
          cfnAlert(error.response);
          return false;
        }

        let message = "";
        if (typeof error.response.data === "string") {
          message = error.response.data;
        } else if (typeof error.response.data === "object") {
          let messageJson = JSON.parse(decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))));

          message = Object.keys(messageJson).length === 0 ? error.response.statusText : messageJson["message"];
        }

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
          fnErrorCallback(objStatus);
        } else {
          // (임시) error message 출력

          cfnAlert(message);
        }
      })
  );
};

/*
 * @desc    localStorage 관리를 하는 함수
 */
export const storage = {
  set: (key, object) => {
    if (!sessionStorage) return false;
    sessionStorage[key] = typeof object === "string" ? object : JSON.stringify(object);
  },
  get: (key) => {
    if (!sessionStorage) return null;
    if (!sessionStorage[key]) return null;

    try {
      const parsed = JSON.parse(sessionStorage[key]);
      return parsed;
    } catch (e) {
      return sessionStorage[key];
    }
  },
  remove: (key) => {
    if (!sessionStorage) return null;

    if (sessionStorage[key]) {
      sessionStorage.removeItem(key);
    }
  },
};

/*
 * @desc    Date 객체를 String 객체로 변환하는 함수
 * @param   { Date }
 * @return  { String }
 */
export const cfnConvertDateToString = (date) => {
  let obj = date;
  //파라미터 객체가 Date 타입일 때만 변환
  if (cfnValidDate(date)) {
    const year = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();

    obj = `${year}${mm[1] ? mm : 0 + mm[0]}${dd[1] ? dd : 0 + dd[0]}`;
  }

  return obj;
};

/*
 * @desc    Date 객체를 String 객체로 변환하는 함수
 * @param   { Date }
 * @return  { String }
 */
export const cfnConvertDateTimeToString = (date) => {
  let obj = date;
  //파라미터 객체가 Date 타입일 때만 변환
  if (cfnValidDate(date)) {
    const year = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    const hh = date.getHours().toString();
    const mi = date.getMinutes().toString();
    const ss = date.getSeconds().toString();

    obj = `${year}${mm[1] ? mm : 0 + mm[0]}${dd[1] ? dd : 0 + dd[0]}${hh[1] ? hh : 0 + hh[0]}${mi[1] ? mi : 0 + mi[0]}${ss[1] ? ss : 0 + ss[0]}`;
  }

  return obj;
};

/*
 * @desc    String 객체(yyyyMMdd 포맷)를 Date 객체로 변환하는 함수
 * @param   { String }
 * @return  { Date }
 */
export const cfnConvertStringToDate = (stringDate) => {
  let obj = stringDate;

  //파라미터 객체가 Date 타입이 아니면 변환하지 않음
  if (cfnIsNotEmpty(stringDate) && !stringDate.getFullYear) {
    if (stringDate.length === 6) {
      //yyMMdd 포맷일 때
      const now = new Date().getFullYear().toString().substr(2, 2);
      const yy = stringDate.substr(0, 2) > now ? "19" : "20";
      obj = new Date(`${yy}${stringDate.substr(0, 2)}-${stringDate.substr(2, 2)}-${stringDate.substr(4, 2)}`);
    } else if (stringDate.length === 8) {
      //yyyyMMdd 포맷일 때
      obj = new Date(`${stringDate.substr(0, 4)}-${stringDate.substr(4, 2)}-${stringDate.substr(6, 2)}`);
    } else if (stringDate.length === 14) {
      obj = new Date(`${stringDate.substr(0, 4)}-${stringDate.substr(4, 2)}-${stringDate.substr(6, 2)}T${stringDate.substr(8, 2)}:${stringDate.substr(10, 2)}:${stringDate.substr(12, 2)}`);
    } else if (stringDate.length === 12) {
      obj = new Date(`${stringDate.substr(0, 4)}-${stringDate.substr(4, 2)}-${stringDate.substr(6, 2)}T${stringDate.substr(8, 2)}:${stringDate.substr(10, 2)}:00`);
    }
  }
  return obj;
};

/*
 * @desc    Table의 각 Column의 크기를 조절하는 colGroup을 생성하는 함수
 * @param   { array }
 * @return  { <colgroup> }
 */
export const cfnCompColGroup = (arrData) => {
  return (
    <colgroup>
      {arrData.map((element, index) => {
        return <col key={index} style={{ width: element }} />;
      })}
    </colgroup>
  );
};

/*
 * @desc    byte 계산을 처리하는 함수 (한글 2byte, 알파벳 1byte)
 * @param   { string }
 * @return  { number }
 */
export const cfnGetByteLength = (s, b, i, c) => {
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 2 : c >> 7 ? 2 : 1);
  return b;
};

/*
 * @desc    byte 계산 후 허용 바이트 수 초과 문자열 잘라내어 주는 함수 (한글 2byte, 알파벳 1byte)
 * @param   { string }
 * @return  { string }
 */
export const cfnCutByteLength = (s, maxByte, b, i, c) => {
  for (b = i = 0; (c = s.charCodeAt(i));) {
    b += c >> 11 ? 2 : c >> 7 ? 2 : 1;
    if (b > maxByte) break;
    i++;
  }
  return s.substr(0,i);
};

/*
 * @desc    날짜 포맷을 yyyy-MM-dd hh:mm:ss 형태로 변경하는 함수
 * @param   { string }
 * @return  { string }
 */
export const cfnDateFormat = (date, format) => {
  if (cfnIsEmpty(date)) {
    return date;
  }

  if (typeof date === "number") {
    date = String(date);
  }

  // return 날짜
  let year = "";
  let month = "";
  let day = "";
  let hour = "";
  let minute = "";
  let second = "";

  // 기본 포맷 설정
  if (cfnIsEmpty(format)) {
    switch (date.length) {
      case 4:
        format = "MMdd";
        break;
      case 6:
        format = "yyMMdd";
        break;
      case 8:
        format = "yyyyMMdd";
        break;
      case 14:
        format = "yyyyMMddhhmmss";
        break;
      default:
        break;
    }
  }

  // 포맷에 해당하는 결과값 return
  switch (format) {
    case "yyMM":
      return `${date.substr(2, 2)}.${date.substr(4, 2)}`;
    case "MMdd":
      return `${date.substr(0, 2)}.${date.substr(2, 2)}`;
    case "yyyyMM":
      year = date.substr(0, 4);

      // 예외 처리
      if (year === "9999") {
        return "";
      }

      month = date.substr(4, 2);
      return `${year}.${month}`;
    case "yyMMdd":
      if (date.length === 8) {
        year = date.substr(2, 2);
        month = date.substr(4, 2);
        day = date.substr(6, 2);
      } else {
        year = date.substr(0, 2);
        month = date.substr(2, 2);
        day = date.substr(4, 2);
      }
      return `${year}.${month}.${day}`;
    case "yyyyMMdd":
      year = date.substr(0, 4);
      month = date.substr(4, 2);
      day = date.substr(6, 2);
      return `${year}.${month}.${day}`;
    case "yyyyMMddhhmmss":
      year = date.substr(0, 4);
      month = date.substr(4, 2);
      day = date.substr(6, 2);
      hour = date.substr(8, 2);
      minute = date.substr(10, 2);
      second = date.substr(12, 2);
      return `${year}.${month}.${day}\n${hour}:${minute}:${second}`;
    default:
      break;
  }
};

/*
 * @desc    숫자 포맷을 천 단위 콤마 형태로 변경하는 함수
 * @param   { string || number }
 * @return  { string }
 */
export const cfnAddComma = (param) => {
  //undefined 또는 null일 경우 0으로 처리
  if (!param) param = 0;
  return Number(param).toLocaleString("en").split(".")[0];
};

/*
 * @desc    오늘 날짜를 구하는 함수
 * @param   N/A
 * @return  { string }
 */
export const cfnGetDate = () => {
  const today = new Date();

  let date = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  if (date < 10) {
    date = `0${date}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${year}${month}${date}`;
};

/*
 * @desc    입력값에서 기호를 제거하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnReplaceSymbol = (string) => {
  return string && typeof string === "string" ? string.replace(/[-,/._]/gi, "") : string;
};

/**
 * @desc 입력 값에서 숫자가 아닌 값을 제거해주는 함수
 * @param {input value} string
 */
export const cfnReplaceNonDigit = (string) => {
  return string && typeof string === "string" ? string.replace(/\D/g, "") : string;
};

/*
 * @desc    전화번호에 대쉬 기호(-)를 삽입하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnAddtDashToPhoneNumber = (string) => {
  let number;

  if (cfnIsNotEmpty(string)) {
    if (string.indexOf("02") === 0) {
      if (string.length === 9) {
        number = string.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
      } else {
        number = string.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else {
      switch (string.length) {
        case 11:
          number = string.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
          break;
        case 8:
          number = string.replace(/(\d{4})(\d{4})/, "$1-$2");
          break;
        default:
          number = string.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          break;
      }
    }
  } else {
    number = null;
  }

  return number;
};

/* @desc    값이 null일 때 다른 값으로 치환 처리를 하며 null이 아닐때는 자기 자신을 return하는 함수
 * @param   { string || number || boolean }
 * @return  { string || number || boolean }
 */
export const cfnNvl = (currentValue, changeValue) => {
  if (cfnIsEmpty(currentValue) && typeof changeValue === "function") return changeValue();
  else if (cfnIsEmpty(currentValue)) return changeValue;
  else return currentValue;
};

/*
 * @desc    생년월일 포맷을 yy-MM-dd(기본) 또는 yyyy-MM-dd 형태로 변경하는 함수
 * @param   { stirng }
 * @return  { string }
 */
export const cfnBirthdayFormat = (param) => {
  let year = "";
  let month = "";
  let day = "";

  // yy-MM-dd 일 때
  if (param.length === 6) {
    year = param.substr(0, 2);
    month = param.substr(2, 2);
    day = param.substr(4, 2);
  } else if (param.length === 8) {
    year = param.substr(0, 4);
    month = param.substr(4, 2);
    day = param.substr(6, 2);
  } else {
    return param;
  }

  return `${year}-${month}-${day}`;
};

/* @desc    일자 포맷을 d일 형태로 변경하는 함수
 * @param   { 
              day: string || number
              nullFormat: day값이 null일때 대체하고자 하는 값
            }
 * @return  { string }
 */
export const cfnDayFormat = (day, nullFormat) => {
  // 값이 없다면 day형태 그대로 또는 nullFormat으로 변경하여 return
  if (cfnIsEmpty(day)) {
    return cfnIsEmpty(nullFormat) ? "" : nullFormat;
  }

  const sDay = String(day);

  // 01과 같은 형태일 때 0을 자른다.
  const firstChar = sDay.charAt(0);
  if (firstChar === "0") {
    return sDay.substr(1, 2) + "일";
  } else if (sDay === "99") {
    return "말일";
  }

  return sDay + "일";
};

/*
 * @desc    이메일 도메인 목록
 * @param   { N/A }
 * @return  { array }
 */
export const cfnGetEmailDomains = () => {
  return [
    { domain: "naver.com", label: "naver.com" },
    { domain: "hanmail.net", label: "hanmail.net" },
    { domain: "daum.net", label: "daum.net" },
    { domain: "gmail.com", label: "gmail.com" },
    { domain: "hotmail.com", label: "hotmail.com" },
    { domain: "nate.com", label: "nate.com" },
    { domain: "yahoo.co.kr", label: "yahoo.co.kr" },
    { domain: "paran.com", label: "paran.com" },
    { domain: "empal.com", label: "empal.com" },
    { domain: "dreamwiz.com", label: "dreamwiz.com" },
    { domain: "freechal.com", label: "freechal.com" },
    { domain: "lycos.co.kr", label: "lycos.co.kr" },
    { domain: "korea.com", label: "korea.com" },
    { domain: "chollian.net", label: "chollian.net" },
    { domain: "hanafos.com", label: "hanafos.com" },
    { domain: "kebi.com", label: "kebi.com" },
    { domain: "netian.com", label: "netian.com" },
    { domain: "netsgo.com", label: "netsgo.com" },
    { domain: "unitel.co.kr", label: "unitel.co.kr" },
    { domain: "manual", label: "직접입력" },
  ];
};

/*
 * @desc    통장기재내역(추가기재) 입력란 변환 함수
 * @param   { String }
 * @return  { string }
 */
export const cfnConvertPostFix = (type) => {
  let text = null;
  switch (type) {
    case "YEARMONTH":
    case "TARGET_Y4MM":
      text = "YYMM";
      break;
    case "MONTH":
      text = "MM";
      break;
    case "SEQUENCE":
      text = "NNN";
      break;
    default:
      text = "";
      break;
  }
  return text;
};

/*
 * @desc    통장기재내역 추가내용 형식 목록
 * @param   { N/A }
 * @return  { array }
 */
export const cfnGetPostFixTypes = () => {
  return [
    { bankbookPostfixType: "MONTH", bankbookPostfixTypeName: "청구월" },
    { bankbookPostfixType: "YEARMONTH", bankbookPostfixTypeName: "청구연월" },
    { bankbookPostfixType: "SEQUENCE", bankbookPostfixTypeName: "청구회차" },
    { bankbookPostfixType: "TARGET_Y4MM", bankbookPostfixTypeName: "수납대상연월" },
    { bankbookPostfixType: "MANUAL", bankbookPostfixTypeName: "직접입력" },
  ];
};

/*
 * @desc    이메일 도메인을 직접 입력했는지 계산하여 리턴하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnFindEmailDomain = (domain) => {
  const emailDomains = cfnGetEmailDomains();
  let isExistDomain = emailDomains.some((row) => {
    return row.domain === domain;
  });

  return isExistDomain ? domain : "manual";
};

/*
 * @desc    생년월일 / 사업자번호를 format에 맞게 수정해서 리턴하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnIdentificationNoFormat = (data, type) => {
  if (cfnIsEmpty(data)) return data;
  let temp = data.replace(/[-,/._]/gi, "");

  // 사업자 번호일 때
  if (temp.length === 10) return temp.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");

  // 주민등록 번호 앞자리만 있을 때
  if (temp.length === 6) return cfnDateFormat(temp);

  if (temp.length === 7) return cfnDateFormat(temp.substr(0, 6));

  if (temp.length === 13 && type) return temp.replace(/(.{6})/, "$1-");

  //주민번호 뒷자리 안보이게(999999-*******)
  if (temp.length === 13) return temp.replace(/(.{6})/, "$1-").replace(/.{7}$/, "*******");

  // 휴대폰 번호일 때
  if (temp.length === 11) return cfnAddtDashToPhoneNumber(temp);

  return data;
};

/*
 * @desc    생년월일 / 사업자번호를 format에 맞게 수정해서 리턴하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnIdentificationNoFormatOnChange = (data, type) => {
  if (cfnIsEmpty(data)) return data;
  let temp = data.replace(/[-,/._]/gi, "");

  // 사업자 번호일 때
  if (temp.length === 10) return temp.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");

  // 주민등록 번호 앞자리만 있을 때
  if (temp.length === 6) return cfnDateFormat(temp);

  if (temp.length === 13 && type) return temp.replace(/(.{6})/, "$1-");

  //주민번호 뒷자리 안보이게(999999-*******)
  if (temp.length === 13) return temp.replace(/(.{6})/, "$1-");

  // 휴대폰 번호일 때
  if (temp.length === 11) return cfnAddtDashToPhoneNumber(temp);

  return data;
};

/*
 * @desc    동의자료 구분 목록
 * @param   { N/A }
 * @return  { array }
 */
export const cfnGetEvidenceFileTypes = () => {
  return [
    { evidenceFileType: "", evidenceFileTypeName: "선택" },
    { evidenceFileType: "PAPER", evidenceFileTypeName: "서면" },
    {
      evidenceFileType: "PUBLIC_SIGNATURE",
      evidenceFileTypeName: "공인전자서명",
    },
    {
      evidenceFileType: "GENERAL_SIGNATURE",
      evidenceFileTypeName: "일반전자서명",
    },
    { evidenceFileType: "RECORDING", evidenceFileTypeName: "녹취" },
    { evidenceFileType: "ARS", evidenceFileTypeName: "ARS" },
    { evidenceFileType: "ETC", evidenceFileTypeName: "기타" },
    {
      evidenceFileType: "PRIVATE_SIGNATURE",
      evidenceFileTypeName: "사설전자서명",
    },
  ];
};

/*
 * @desc    동의자료 구분 목록
 * @param   { N/A }
 * @return  { array }
 */
export const cfnGetEvidenceFileTypeNumber = () => {
  return [
    { evidenceFileType: "", evidenceFileTypeName: "선택" },
    { evidenceFileType: "1", evidenceFileTypeName: "서면" },
    {
      evidenceFileType: "2",
      evidenceFileTypeName: "공인전자서명",
    },
    {
      evidenceFileType: "3",
      evidenceFileTypeName: "일반전자서명",
    },
    { evidenceFileType: "4", evidenceFileTypeName: "녹취" },
    { evidenceFileType: "5", evidenceFileTypeName: "ARS" },
    { evidenceFileType: "6", evidenceFileTypeName: "기타" },
    {
      evidenceFileType: "7",
      evidenceFileTypeName: "사설전자서명",
    },
  ];
};
/*
 * @desc    유효한 이메일 형식인지 검사하는 함수
 * @param   { String }
 * @return  { boolean }
 */
export const cfnCheckEmail = (email) => {
  const regExp = /^[0-9a-zA-Z]([-_.]?[_0-9a-zA-Z-])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  if (regExp.test(email)) return true;
  else return false;
};

/*
 * @desc    입력 시 천단위 자리마다 ,를 삽입하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnAddCommaOnChange = (input) => {
  let inputString = null;
  if (cfnIsNotEmpty(input)) {
    inputString = input
      .toString()
      .replace(/\D/g, "")
      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  }

  return inputString;
};

/*
 * @desc    페이지 "00개씩 보기" pageSize Option 목록
 * @param   { N/A }
 * @return  { array }
 */
export const cfnGetOptionPageSize = () => {
  const arrCount = [5, 10, 25, 100, 200];
  const returnData = [];

  for (const count of arrCount) {
    const objData = { value: count, label: count + "개씩" };
    returnData.push(objData);
  }

  return returnData;
};

/*
 * @desc    금융기관 목록과 기관코드를 받아 일치하는 기관명을 반환
 * @param   {
 *            code : 기관명을 찾고자 하는 기관코드(String)
 *            list : 금융기관 Array
 *          }
 * @return  { String }
 */
export const cfnMatchBankName = (code, list) => {
  let bankName = null;
  let bankCode = code;
  if (bankCode && bankCode.length > 3) {
    bankCode = bankCode.substring(0, 3);
  }
  for (let i = 0; i < list.length; i++) {
    if (list[i].fncInsCd === bankCode) {
      bankName = list[i].fncInsNm;
      break;
    }
  }
  return bankName;
};

/*
 * @desc    오늘 날짜를 기준으로 특정 날짜를 구하는 함수
 * @param   {
 *            dateType: "year, month, date"
 *            number: 오늘 날짜로부터 + 또는 - 할 날짜
 *            dataType: "string, date" default "string"
 *          }
 * @return  { string }
 */
export const cfnGetCustomDate = (dateType, number, dataType) => {
  let customDate = new Date();

  switch (dateType) {
    case "year":
      customDate.setFullYear(customDate.getFullYear() + number);
      break;
    case "month":
      customDate.setMonth(customDate.getMonth() + number);
      break;
    case "date":
      customDate.setDate(customDate.getDate() + number);
      break;
    default:
      break;
  }

  let date = customDate.getDate();
  let month = customDate.getMonth() + 1;
  let year = customDate.getFullYear();

  if (date < 10) {
    date = `0${date}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  switch (dataType) {
    case "date":
      return new Date(`${year}-${month}-${date}`);
    case "string":
    default:
      return `${year}${month}${date}`;
  }
};

/*
 * @desc    해당 날짜를 기준으로 + 또는 - 된 날짜를 가져오는 함수
 * @param   {
 *            thisDate: 해당 날짜
 *            number: + 또는 - 할 날짜
 *            dateType: year, month, date
 *            dataType: "string, date" default "string"
 *          }
 * @return  { string }
 */
export const cfnGetCustomThisDate = (thisDate, number, dateType, dataType) => {
  let customDate;

  if (cfnIsEmpty(thisDate)) {
    return null;
  } else if (cfnValidDate(thisDate)) {
    customDate = new Date(thisDate.getTime());
  } else if (typeof thisDate === "string") {
    const convertDate = cfnConvertStringToDate(thisDate);
    if (cfnValidDate(convertDate)) {
      customDate = convertDate;
    } else {
      return null;
    }
  } else {
    return null;
  }

  switch (dateType) {
    case "year":
      customDate.setFullYear(customDate.getFullYear() + number);
      break;
    case "month":
      customDate.setMonth(customDate.getMonth() + number);
      break;
    case "date":
    default:
      customDate.setDate(customDate.getDate() + number);
      break;
  }

  let date = customDate.getDate();
  let month = customDate.getMonth() + 1;
  let year = customDate.getFullYear();

  if (date < 10) {
    date = `0${date}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  switch (dataType) {
    case "date":
      return new Date(`${year}-${month}-${date}`);
    case "string":
    default:
      return `${year}${month}${date}`;
  }
};

/*
 * @desc    두 날짜 차이를 return하는 함수
 * @param   {
 *            startDate: 시작일
 *            endDate: 종료일
 *            type: d = 일, m = 월, y = 년
 *          }
 * @return  { string }
 */
export const cfnDateCalculation = (startDate, endDate, type) => {
  const date1 = typeof startDate === "string" ? cfnConvertStringToDate(startDate) : startDate;
  const date2 = typeof endDate === "string" ? cfnConvertStringToDate(endDate) : endDate;

  const difference = date1 - date2;
  const day = 24 * 60 * 60 * 1000;
  const month = day * 30;
  const year = month * 12;

  switch (type) {
    case "y":
      return parseInt(difference / year);
    case "m":
      return parseInt(difference / month);
    case "d":
    default:
      return parseInt(difference / day);
  }
};

//targetStatus : E - 전자세금계산서, U - 이용요금
//taxbillPublicationNo : 전자세금계산서 발행번호 없으면 "" or 있을 경우 KF02로 시작하는 36자리~38자리 문자열
//useY4mm : yyyymm or yymm (201911 or 1911)
//servGbcd : B : 빌링원, C : CMS
//taxNo : login 정보에서 받은 각 기관별 instCode (B billingoneInstCode , C cmsInstCode))
export const cfTrsbTaxAccess = (targetPageCode, taxbillPublicationNo, useY4mm, servGbcd, taxNo) => {
  let pubCodeValue = String(taxbillPublicationNo).startsWith("KF02") && String(taxbillPublicationNo).length > 35 ? String(taxbillPublicationNo) : "";
  if (targetPageCode === "E") {
    //윈도우 open으로 열리지 않을때
    let pubCodeArray = taxbillPublicationNo.split("&");
    pubCodeArray.forEach((row, index) => {
      pubCodeValue = pubCodeValue === "" ? (row.split("=")[0] === "pubCode" ? row.split("=")[1] : "") : pubCodeValue;
    });
    //윈도우 open으로 열어도 될때
    // window.open(taxbillPublicationNo);
    // return false;
  } else {
    let pubCodeArray = [];
    let yymm = String(useY4mm).length === 4 ? String(useY4mm) : String(useY4mm).substring(2);

    if (pubCodeValue === "") {
      pubCodeArray.push("KF02");
      if (servGbcd === "B") {
        if (Number(yymm) > 1007) {
          pubCodeArray.push("1298208745billone1S" + yymm + "" + taxNo + "02");
        } else if (Number(yymm) <= 1007 && Number(yymm) > 701) {
          pubCodeArray.push("1298208745cmsasp01A" + yymm + "" + taxNo);
        } else {
          pubCodeArray.push("2208201439CMSASPA" + yymm + "" + taxNo);
        }
      } else if (servGbcd === "C") {
        if (Number(yymm) > 701) {
          pubCodeArray.push("1298208745cmsedi2E" + yymm + "" + taxNo);
        } else {
          pubCodeArray.push("2208201439cmsediE" + yymm + "" + taxNo);
        }
      }
      pubCodeValue = pubCodeArray.join("");
    }
  }

  let trsbForm = document.createElement("form");
  trsbForm.name = "act_form";
  trsbForm.method = "post";
  trsbForm.action = "https://www.trusbill.or.kr/jsp/directTax/TaxViewIndex.jsp";
  trsbForm.target = "form";
  document.body.appendChild(trsbForm);

  let sFromDate = document.createElement("input");
  sFromDate.type = "hidden";
  sFromDate.name = "sFromDate";
  trsbForm.appendChild(sFromDate);

  let sToDate = document.createElement("input");
  sToDate.type = "hidden";
  sToDate.name = "sToDate";
  trsbForm.appendChild(sToDate);

  let docType = document.createElement("input");
  docType.type = "hidden";
  docType.name = "docType";
  docType.value = "T";
  trsbForm.appendChild(docType);

  let userType = document.createElement("input");
  userType.type = "hidden";
  userType.name = "userType";
  userType.value = "R";
  trsbForm.appendChild(userType);

  let pubCode = document.createElement("input");
  pubCode.type = "hidden";
  pubCode.name = "pubCode";
  pubCode.value = pubCodeValue;
  trsbForm.appendChild(pubCode);

  window.open("", "form");
  trsbForm.submit();
};

/*
 * @desc    유효한 날짜인지 검사하는 함수
 * @param   { date: 날짜 }
 * @return  { boolean }
 */
export const cfnValidDate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export function equals(a, b, enforce_properties_order, cyclic) {
  return (
    (a === b && // strick equality should be enough unless zero
      // eslint-disable-next-line no-mixed-operators
      a !== 0) || // because 0 === -0, requires test by _equals()
    // eslint-disable-next-line no-mixed-operators
    _equals(a, b) // handles not strictly equal or zero values
  );

  function _equals(a, b) {
    // a and b have already failed test for strict equality or are zero

    var s, l, p, x, y;

    // They should have the same toString() signature
    try {
      s = toString.call(a);
    } catch (error) {
      s = a && a.toString ? a.toString() : null;
    }
    try {
      l = toString.call(b);
    } catch (error) {
      l = b && b.toString ? b.toString() : null;
    }
    if (s !== l) return false;

    switch (s) {
      default:
        // Boolean, Date, String
        return a.valueOf() === b.valueOf();

      case "[object Number]":
        // Converts Number instances into primitive values
        // This is required also for NaN test bellow
        a = +a;
        b = +b;

        return a // a is Non-zero and Non-NaN
          ? a === b
          : // a is 0, -0 or NaN
          // eslint-disable-next-line no-self-compare
          a === a // a is 0 or -O
          ? 1 / a === 1 / b // 1/0 !== 1/-0 because Infinity !== -Infinity
          : // eslint-disable-next-line no-self-compare
            b !== b; // NaN, the only Number not equal to itself!
      // [object Number]

      case "[object RegExp]":
        // eslint-disable-next-line eqeqeq
        return a.source == b.source && a.global == b.global && a.ignoreCase == b.ignoreCase && a.multiline == b.multiline && a.lastIndex == b.lastIndex;
      // [object RegExp]

      case "[object Function]":
        return false; // functions should be strictly equal because of closure context
      // [object Function]

      case "[object Array]":
        if (cyclic && (x = reference_equals(a, b)) !== null) return x; // intentionally duplicated bellow for [object Object]

        // eslint-disable-next-line eqeqeq
        if ((l = a.length) != b.length) return false;
        // Both have as many elements

        while (l--) {
          if (((x = a[l]) === (y = b[l]) && x !== 0) || _equals(x, y)) continue;

          return false;
        }

        return true;
      // [object Array]

      case "[object Object]":
        if (cyclic && (x = reference_equals(a, b)) !== null) return x; // intentionally duplicated from above for [object Array]

        l = 0; // counter of own properties

        if (enforce_properties_order) {
          var properties = [];

          for (p in a) {
            if (a.hasOwnProperty(p)) {
              properties.push(p);

              if (((x = a[p]) === (y = b[p]) && x !== 0) || _equals(x, y)) continue;

              return false;
            }
          }

          // Check if 'b' has as the same properties as 'a' in the same order
          // eslint-disable-next-line eqeqeq
          for (p in b) if (b.hasOwnProperty(p) && properties[l++] != p) return false;
        } else {
          for (p in a) {
            if (a.hasOwnProperty(p)) {
              ++l;

              if (((x = a[p]) === (y = b[p]) && x !== 0) || _equals(x, y)) continue;

              return false;
            }
          }

          // Check if 'b' has as not more own properties than 'a'
          for (p in b) if (b.hasOwnProperty(p) && --l < 0) return false;
        }

        return true;
      // [object Object]
    } // switch toString.call( a )
  } // _equals()

  /* -----------------------------------------------------------------------------------------
     reference_equals( a, b )
     
     Helper function to compare object references on cyclic objects or arrays.
     
     Returns:
       - null if a or b is not part of a cycle, adding them to object_references array
       - true: same cycle found for a and b
       - false: different cycle found for a and b
     
     On the first call of a specific invocation of equal(), replaces self with inner function
     holding object_references array object in closure context.
     
     This allows to create a context only if and when an invocation of equal() compares
     objects or arrays.
  */
  function reference_equals(a, b) {
    var object_references = [];

    // eslint-disable-next-line no-func-assign
    return (reference_equals = _reference_equals)(a, b);

    function _reference_equals(a, b) {
      var l = object_references.length;

      while (l--) if (object_references[l--] === b) return object_references[l] === a;

      object_references.push(a, b);

      return null;
    } // _reference_equals()
  } // reference_equals()
} // equals()

/*
 * @desc    텍스트 왼쪽에 0이 있을 때 제거하는 함수 (숫자 맨 앞에 0을 제거하고 싶을 때 사용)
 * @param   value: string
 * @return  string
 */
export const cfnLtrimZero = (value) => value.replace(/(^0+)/, "");

/*
 * @desc    페이지네이션(pagination) hooks 값 설정을 처리하는 함수
 * @param   value: object
 * @return  object
 */
export const cfnSetPagination = (value) => {
  return {
    rowsPerPage: value.pageable.pageSize,
    offset: value.pageable.offset,
    total: value.totalElements,
    totalPages: value.totalPages,
  };
};

/*
 * @desc    기간 조회 유효성 검사를 처리하는 함수
 * @param   startDate: 시작일, endDate: 종료일
 * @return  boolean
 */
export const cfnPeriodValidation = (startDate, endDate) => {
  if (cfnIsEmpty(startDate)) {
    cfnAlert("시작일을 입력해주세요.");
    return false;
  }

  if (cfnIsEmpty(endDate)) {
    cfnAlert("종료일을 입력해주세요.");
    return false;
  }

  if (typeof startDate === "string") {
    startDate = cfnConvertStringToDate(startDate);
  }

  if (typeof endDate === "string") {
    endDate = cfnConvertStringToDate(endDate);
  }

  if (!cfnValidDate(startDate)) {
    cfnAlert("올바른 시작일을 입력해주세요.");
    return false;
  }

  if (!cfnValidDate(endDate)) {
    cfnAlert("올바른 종료일 입력해주세요.");
    return false;
  }

  if (cfnDateCalculation(endDate, startDate) < 0) {
    const date = cfnDateFormat(cfnConvertDateToString(startDate));
    cfnAlert(`종료일을 ${date} 이후로 선택해주세요.`);
    return false;
  }

  return true;
};

/*
 * @desc    날짜 유효성 검사를 처리하는 함수
 * @param   date: 날짜, message: 날짜 label
 * @return  boolean
 */
export const cfnDateValidation = (date, label = "날짜") => {
  if (cfnIsEmpty(date)) {
    cfnAlert(`${label}을(를) 입력해주세요.`);
    return false;
  }

  if (typeof date === "string") {
    date = cfnConvertStringToDate(date);
  }

  if (!cfnValidDate(date)) {
    cfnAlert(`올바른 ${label}을(를) 입력해주세요.`);
    return false;
  }

  return true;
};

/*
 * @desc    1일 ~ 말일 까지 나오는 select option 데이터를 생성하는 함수
 * @param   valueType: string, integer
 * @return  array
 */
export const cfnSelectOptionDate = (valueType = "string") => {
  const arrDate = [];
  const maxLoop = 33;

  for (let i = 0; i < maxLoop; i++) {
    let label = `${i}일`;
    let value = valueType === "string" ? String(i) : i;

    if (i === 0) {
      label = "선택안함";
      value = "";
    } else if (i < 10) {
      value = valueType === "string" ? `0${i}` : i;
    } else if (i === maxLoop - 1) {
      label = "말일";
      value = valueType === "string" ? "99" : 99;
    }

    arrDate.push({
      label: label,
      value: value,
    });
  }

  return arrDate;
};

/*
 * @desc    기본 pagination data
 * @param   rowsPerPage: 페이지에 보여줄 row 수
 * @return  object
 */
export const cfnPaginationData = (rowsPerPage = 5) => ({
  rowsPerPage,
  offset: 0,
  total: 0,
  totalPages: 1,
  pageNumber: 0,
});

/*
 * @desc    공통 alert 창을 호출하는 함수
 * @param   message: 호출될 내용
 */
export const cfnAlert = (obj, callback) => {
  let messageString = typeof obj === "string" ? obj : String(obj);
  openAlert(messageString, callback);
};

/*
 * @desc    공통 confirm 창을 호출하는 함수
 * @param   message: 호출될 내용
 */
export const cfnConfirm = (obj, callback, cancelCallback) => {
  let messageString = typeof obj === "string" ? obj : String(obj);
  openConfirm(messageString, callback, cancelCallback);
};

/*
 * @desc    공통 confirm 창을 호출하는 함수
 * @param   message: 호출될 내용
 */
export const cfnConfirmAsync = async (obj) => {
  let messageString = typeof obj === "string" ? obj : String(obj);
  return new Promise((resolve, reject) => {
    openConfirm(messageString, resolve, reject);
  });
};

/*
 * @desc    lpad 기능을 구현하는 함수
 * @param   str : 문자열, num : 원하는 문자열 길이, chr : 공백을 채울 문자
 */
export const cfnLpad = (str, num, chr) => {
  let max = num - str.length;
  for (let i = 0, j = max; i < j; i++) {
    str = chr + str;
  }
  return str;
};

/*
 * @desc    rpad 기능을 구현하는 함수
 * @param   str : 문자열, num : 원하는 문자열 길이, chr : 공백을 채울 문자
 */
export const cfnRpad = (str, num, chr) => {
  let max = num - str.length;
  for (let i = 0, j = max; i < j; i++) {
    str = str + chr;
  }
  return str;
};

export const cfnAddDate = (startDate, endDate, number) => {
  let searchStartDate = new Date(startDate.substr(0, 4), startDate.substr(4, 2), cfnIsNotEmpty(startDate.substr(6, 2)) ? startDate.substr(6, 2) : 1);
  let searchEndDate = new Date(endDate.substr(0, 4), endDate.substr(4, 2), cfnIsNotEmpty(endDate.substr(6, 2)) ? endDate.substr(6, 2) : 1);
  let searchDate = searchStartDate.setFullYear(searchStartDate.getFullYear() + number);
  let result = searchDate < searchEndDate;
  return result;
};

/*
 * @desc    서버에 저장된 동의자료 파일 및 파일명 조회
 * @param   {
 *            url: string
 *            fncallback: function
 *            fnErrorCallback: function
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnGetSavedFile = (url, fnCallback, fnErrorCallback) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: url,
    method: "get",
    data: "",
    headers: {
      "Content-type": "application/json",
    },
    // withCredentials: true,
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status, statusText };
  };

  trackPromise(
    axios(config)
      .then((response) => {
        let contentDisposition = response.headers["content-disposition"];
        let index = contentDisposition.indexOf("''") !== -1 ? contentDisposition.lastIndexOf("'") : contentDisposition.indexOf("=");
        let fileName = decodeURIComponent(contentDisposition.substring(index + 1));

        const objStatus = fnSetStatus(response.status, response.statusText);
        const returnData = { file: response.data, fileName: fileName };

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === "function") fnCallback(objStatus, returnData);
      })
      .catch((error) => {
        // Network error 발생할 때
        if (cfnIsEmpty(error.response)) {
          cfnAlert(error.response);
          return false;
        }

        let message = "";
        if (typeof error.response.data === "string") message = error.response.data;
        else if (typeof error.response.data === "object") message = error.response.data.message;

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === "function") {
          fnErrorCallback(objStatus);
        } else {
          // (임시) error message 출력
          cfnAlert(message);
        }
      })
  );
};

//동의자료 첨부 시 validation check 함수
export const cfnCheckEvidenceFileValidation = (uploadFile, type) => {
  if (cfnIsEmpty(uploadFile.fileType)) {
    cfnAlert("동의자료 구분을 선택하여 주시기 바랍니다.");
    return false;
  }

  if (cfnIsEmpty(uploadFile.fileName)) {
    cfnAlert("등록할 파일을 선택하여 주시기 바랍니다.");
    return false;
  } else {
    //파일 용량 체크
    const selectedSize = uploadFile.file.size;
    if ((uploadFile.evidenceFileType === "PUBLIC_SIGNATURE" || uploadFile.evidenceFileType === "PRIVATE_SIGNATURE") && selectedSize > 10 * 1024) {
      cfnAlert(`첨부자료의 용량은 10KB 이내여야 합니다.\n선택된 파일 용량 : ${Math.round(selectedSize / 1024, 0)}KB`);
      return false;
    } else if (selectedSize > 300 * 1024) {
      cfnAlert(`첨부자료의 용량은 300KB 이내여야 합니다.\n선택된 파일 용량 : ${Math.round(selectedSize / 1024, 0)}KB`);
      return false;
    }

    //증빙파일 유형 선택에 따른 파일타입 체크
    const fileName = uploadFile.fileName;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toUpperCase();

    switch (uploadFile.evidenceFileType) {
      case "PAPER":
      case "GENERAL_SIGNATURE":
        if (fileType !== "JPG" && fileType !== "JPEG" && fileType !== "GIF" && fileType !== "TIF" && fileType !== "PDF") {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다. (" + fileType + ")");
          return false;
        }
        break;
      case "RECORDING":
      case "ARS":
        if (fileType !== "MP3" && fileType !== "WAV" && fileType !== "WMA") {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다. (" + fileType + ")");
          return false;
        }
        break;
      case "PUBLIC_SIGNATURE":
      case "PRIVATE_SIGNATURE":
        if (fileType !== "DER") {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.(" + fileType + ")");
          return false;
        }
        break;
      default:
        if (
          fileType !== "JPG" &&
          fileType !== "JPEG" &&
          fileType !== "GIF" &&
          fileType !== "TIF" &&
          fileType !== "PDF" &&
          fileType !== "MP3" &&
          fileType !== "WAV" &&
          fileType !== "WMA" &&
          fileType !== "DER" &&
          fileType !== "TXT" &&
          fileType !== "JB64"
        ) {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.");
          return false;
        }
        break;
    }
  }
  return true;
};

//동의자료 구분 수정 시 validation check 함수
export const cfnCheckEvidenceFileTypeValidation = (name, type) => {
  if (cfnIsEmpty(type)) {
    cfnAlert("동의자료 구분을 선택하여 주시기 바랍니다.");
    return false;
  }

  if (cfnIsEmpty(name)) {
    cfnAlert("등록할 파일을 선택하여 주시기 바랍니다.");
    return false;
  } else {
    //증빙파일 유형 선택에 따른 파일타입 체크
    const fileName = name;
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toUpperCase();

    switch (type) {
      case "PAPER":
      case "GENERAL_SIGNATURE":
        if (fileType !== "JPG" && fileType !== "JPEG" && fileType !== "GIF" && fileType !== "TIF" && fileType !== "PDF") {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다. (" + fileType + ")");
          return false;
        }
        break;
      case "RECORDING":
      case "ARS":
        if (fileType !== "MP3" && fileType !== "WAV" && fileType !== "WMA") {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다. (" + fileType + ")");
          return false;
        }
        break;
      case "PUBLIC_SIGNATURE":
      case "PRIVATE_SIGNATURE":
        if (fileType !== "DER") {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다. (" + fileType + ")");
          return false;
        }
        break;
      default:
        if (
          fileType !== "JPG" &&
          fileType !== "JPEG" &&
          fileType !== "GIF" &&
          fileType !== "TIF" &&
          fileType !== "PDF" &&
          fileType !== "MP3" &&
          fileType !== "WAV" &&
          fileType !== "WMA" &&
          fileType !== "DER" &&
          fileType !== "TXT" &&
          fileType !== "JB64"
        ) {
          cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.");
          return false;
        }
        break;
    }
  }

  return true;
};

//주어진 문자열 뒤에 space를 padding하는 함수
export const cfnSetSpace = (str, len) => {
  str = String(str);
  len = Number(len);
  let size = len - cfnGetByteLength(str);

  if (size <= 0) {
    return str;
  }

  for (let i = 0; i < size; i++) {
    str += " ";
  }

  return str;
};

//해당 월의 마지막 날을 구하는 함수
export const cfnGetLastDay = (yymm) => {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  yymm += "";
  if (yymm.length !== 6) {
    return 0;
  }

  const yy = yymm.substring(0, 4); // 월
  const mm = yymm.substring(4, 6); // 월

  /*
   * 윤년계산(1년==365.2422일)
   *
   *   4년 마다 윤년을 두고
   * 100년 마다 윤년을 두지 않고
   * 400년 에는 윤년을 둔다.
   */
  if (mm === 2) {
    if (yy % 4 === 0 && (yy % 100 !== 0 || yy % 400 === 0)) {
      return 29 + "";
    }
  }

  return days[mm - 1] + "";
};

//주어진 문자열 앞에 0을 padding 하는 함수
export const cfnSetZero = (str, len) => {
  str = String(str);
  len = Number(len);
  const size = len - str.length;

  if (size <= 0) {
    return str;
  }

  let minus = false;

  if (str.charAt(0) === "-") {
    minus = true;
    str = str.substring(1);
  }

  for (let i = 0; i < size; i++) {
    str = "0" + str;
  }
  return (minus ? "-" : "") + str;
};

/*
 * @desc    계좌번호 뒤에서 세자리를 마스킹처리하는 함수
 * @param   { object }
 * @return  { boolean }
 */
export const cfnMasking = (val) => {
  if (val === null) return "";
  if (typeof val === "undefined") return "";
  if (typeof val === "string" && val.trim() === "") return "";

  val = val.toString().replace(/.{3}$/, "***");
  return val;
};

//이전 영업일 계산
export const fnGetPrevBizDate = (date) => {
  return new Promise((resolve) => {
    const url = `api/gadget/bizDate/prev?targetDate=${date}`;
    cfnAxios(url, "get", "", (status, data) => {
      resolve(data + "");
    });
  });
};

//다음 영업일 계산
export const fnGetNextBizDate = (date) => {
  return new Promise((resolve) => {
    const url = `api/gadget/bizDate/next?targetDate=${date}`;
    cfnAxios(url, "get", "", (status, data) => {
      resolve(data + "");
    });
  });
};

/**
 * 페이징 데이터 생성 및 세팅
 */
export const makePaginationData = (data) => {
  if (cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/**
 * 연도 선택을 위한 select box의 arrayOption 생성
 * @param yearsToMinus
 *    리스트에 현재 기준 얼마나 이전 연도까지 포함하는지 설정
 * @returns {Array}
 * 결과 예시
 *    [{label: '2021년', value: '2021'}, {label: '2022년', value: '2022'}]
 */
export const getYearOptionListForSelectBox = (yearsToMinus) => {
  const arrYear = [];
  const today = new Date();
  for (let startY4 = today.getFullYear() - yearsToMinus; startY4 <= today.getFullYear(); startY4++) {
    arrYear.push({
      label: `${startY4}년`,
      value: startY4,
    });
  }
  return arrYear;
}

/**
 * 월 선택을 위한 select box의 arrayOption 생성
 * @param searchY4
 *    조회 기준 연도
 * @param yearsToMinus
 *    조회 기준 연도가 얼마나 이전 연도까지 포함하는 설정 값
 * @returns {Array}
 *    [{label: '9월', value: '09'},{label: '10월', value: '10'},{label: '11월', value: '11'},{label: '12월', value: '12'}]
 */
export const getMonthOptionListForSelectBox = (searchY4, yearsToMinus) => {
  const arrMonth = [];

  let year = new Date();
  const startY4Mm = new Date(year.getFullYear() - yearsToMinus, year.getMonth());
  let mm;

  if (searchY4.toString() === year.getFullYear().toString()) {
    //조회연도가 올해와 같으면 이번달까지만 포함한다.
    mm = `${new Date().getMonth() + 1}`; //이번달

    for (let i = 1; i <= mm; i++) {
      let label = `${i}월`;
      let value = i < 10 ? `0${i}` : i;
      arrMonth.push({
        label: label,
        value: value,
      });
    }
  } else if (searchY4.toString() === startY4Mm.getFullYear().toString()) {
    //조회연도가 2년전과 같으면 이번달부터 포함한다.
    mm = `${new Date().getMonth() + 1}`; //이번달

    for (let i = mm; i < 13; i++) {
      let label = `${i}월`;
      let value = i < 10 ? `0${i}` : i;
      arrMonth.push({
        label: label,
        value: value,
      });
    }
  } else {
    const maxLoop = 13;

    for (let i = 1; i < maxLoop; i++) {
      let label = `${i}월`;
      let value = i < 10 ? `0${i}` : i;

      arrMonth.push({
        label: label,
        value: value,
      });
    }
  }
  return arrMonth;
}