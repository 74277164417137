import {Box, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import SendSmsSendHistoryModal from "./SendSmsSendHistoryModal";

const SendSmsSendHistoryTab = (props) => {
  const [fromDate, setFromDate] = useState(() => {
    let fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 6);
    return fromDate;
  });
  const [toDate, setToDate] = useState(new Date());
  const [pagination, setPagination] = useState(CM.cfnPaginationData());
  const [content, setContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [smsInfo, setSmsInfo] = useState({});
  const [searchButton, setSearchButton] = useState(false);
  const [searchRequest, setSearchRequest] = useState({ sortProperty: "trSenddate", sortDirection: "DESC", transactionMethod: "", searchProperty:"SMS_SEND_MOBILE", searchContents:"", pageSize: 5, pageNumber: 0 });

  useEffect(() => {
    if (searchButton) {
      setSearchButton(false);
      searchSendSmsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchButton]);

  useEffect(() => {
    if (props.tabIndex === 2) {
      setSearchButton(true);
    }
  }, [props.tabIndex]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchButton(true);
  };

  const searchSendSmsList = () => {
    const url = "/api/extraservice/messages";

    if (CM.cfnAddDate(CM.cfnConvertDateToString(fromDate), CM.cfnConvertDateToString(toDate), 1)) {
      CM.cfnAlert("종료년월은 시작년월로부터 1년 이내만 가능합니다.");
      return false;
    }
    let queryString = [];

    Object.keys(searchRequest).forEach((key) => {
      queryString.push(key + "=" + searchRequest[key]);
    });
    Object.keys(pagination).forEach((key) => {
      queryString.push(key + "=" + pagination[key]);
    });
    queryString.push("fromDate=" + CM.cfnConvertDateToString(fromDate));
    queryString.push("toDate=" + CM.cfnConvertDateToString(toDate));

    const urlParameter = url + "?" + queryString.join("&");
    CM.cfnAxios(urlParameter, "get", "", (status, dataList) => {
      if (status.status === 200) {
        setContent(dataList.content);
        setPagination(CM.cfnSetPagination(dataList));
      }
    });
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setSearchRequest((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchButton(true);
  };

  const fnMatchMsgType = (msgType) => {
    switch (msgType) {
      case "CMS_WITHDRAWAL":
        return "CMS출금";
      case "FREESTYLE":
        return "자유형";
      case "CMS_DEPOSIT":
        return "CMS입금";
      case "ETC":
        return "기타";
      default:
        return "";
    }
  };

  const openModalHandler = (info) => {
    setSmsInfo(info);
    setOpen(true);
  };

  /*
 * @desc     키보드 이벤트 핸들러
 */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      setSearchButton(true);
    }
  };

  return (
    <Fragment>
      <Typography component="div" role="tabpanel" value={1} index={1} hidden={2 !== props.tabIndex}>
        <Box>
          <div className="inforbox">
            <ul>
              <li>SMS 발송 실패 내역은 요금이 청구되지 않습니다.</li>
            </ul>
          </div>
          <div className="search-area">
            <div className="block">
              <span className="label-l">발송일자</span>
              <Template.DatePickerForm
                className="w160 receiptRequestResultDatePicker"
                name="fromDate"
                value={fromDate}
                handleChange={(event) => setFromDate(event)}
                format="yyyy.MM.dd"
                customProps={{ placeholder: "YYYY.MM.DD" }}
              />
              <span className="between">~</span>
              <Template.DatePickerForm
                className="w160 receiptRequestResultDatePicker"
                name="toDate"
                value={toDate}
                handleChange={(event) => setToDate(event)}
                format="yyyy.MM.dd"
                customProps={{ placeholder: "YYYY.MM.DD" }}
              />
              <span className="label-l">발송구분</span>
              <Select
                native
                className="w130"
                name="transactionMethod"
                value={searchRequest.transactionMethod}
                onChange={(e) => setSearchRequest({ ...searchRequest, transactionMethod: e.target.value })}
                inputProps={{
                  "data-testid": "service-status-select",
                }}>
                <option value="">전체</option>
                <option value="CMS_WITHDRAWAL">CMS출금</option>
                <option value="CMS_DEPOSIT">CMS입금</option>
                <option value="FREESTYLE">자유형</option>
                <option value="ETC">기타</option>
              </Select>
              <label className="label-l">검색어입력</label>
              <Select native value={searchRequest.term} onChange={(e) => setSearchRequest({ ...searchRequest, searchProperty : e.target.value })}>
                <option value="SMS_SEND_MOBILE">발송휴대폰번호</option>
              </Select>
              <TextField className="w120" value={searchRequest.keyword} onChange={(e) => setSearchRequest({ ...searchRequest, searchContents : e.target.value })} onKeyUp={handleSearchKeyUp} name="search" />
              <button className="search-button" onClick={() => setSearchButton(true)} data-testid="search-send-sms-btn">
                발송 내역 조회
              </button>
            </div>
          </div>
          <div className="table-top-area">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
          </div>
          <div>
            <Table>
              {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "auto"])}
              <TableHead className="table-head-line">
                <TableRow key="headRow">
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>발송일시</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>발송구분</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>발송건수</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>성공건수</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>실패건수</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>전송중</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.total === 0 ? (
                  <TableForm.compEmptyTableRow colSpan={6} />
                ) : (
                  content.map((row, index) => {
                    return (
                      <TableRow key={index} hover className="show-detail" onClick={(event) => openModalHandler(row)}>
                        <TableCell className="td" align="center">
                          {CM.cfnDateFormat(row["trSenddate"], "yyyyMMddhhmmss")}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {fnMatchMsgType(row["messageType"])}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {row["totalSent"]}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {row["totalSuccess"]}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {row["totalFailure"]}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {row["totalRemaining"]}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <CF.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
          </div>
        </Box>
      </Typography>
      <SendSmsSendHistoryModal open={open} setOpen={setOpen} smsInfo={smsInfo} />
    </Fragment>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(SendSmsSendHistoryTab));
