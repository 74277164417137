import { Checkbox, Paper, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import CustomerData from "../customerBasicInformation/CustomerData";
import PaymentCreateModal from "./PaymentCustomerCreateBulk";
import { paginationData, searchData, searchOptionData } from "./PaymentInformationData";
import PaymentUpdateList from "./PaymentUpdateList";

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const searchOptionList = searchOptionData();

  // 검색창에서 키를 입력했을 때
  const handleSearKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색
  const fnSearch = () => {
    props.handleSearchFormChange(props.searchOption.group, props.searchOption.capital, props.searchOption.terms, props.searchOption.search, 0);
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">자금종류</label>
        <Select native value={props.searchOption.capital} onChange={props.handleSearchOptionChange} name="capital" inputProps={{ "data-testid": "paymentInformation-select-capital" }}>
          <option value={""} key={"none"}>
            전체
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객구분</label>
        <Select native value={props.searchOption.group} onChange={props.handleSearchOptionChange} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          <option value={""} key={"none"}>
            전체
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={props.searchOption.terms} onChange={props.handleSearchOptionChange} name="terms" inputProps={{ "data-testid": "paymentInformation-select-terms" }}>
          {searchOptionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField
          className="w150"
          value={props.searchOption.search}
          onChange={props.handleSearchOptionChange}
          onKeyUp={handleSearKeyUp}
          name="search"
          inputProps={{ "data-testid": "paymentInformation-textfield-search" }}
        />
        <button className="search-button" onClick={() => fnSearch()} data-testid="paymentInformation-button-search">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc	지급정보 테이블 목록 컴포넌트
 */
const ListForm = (props) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of props.paymentList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const paymentLength = props.paymentList.length;

    if (checkAllFlag === paymentLength && paymentLength > 0) setCheckAllRow(true);
    else setCheckAllRow(false);
  }, [props.paymentList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckBoxChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.paymentList];
    cloneData[index][key] = value;
    props.handlePaymentListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.handlePaymentListChange((payment) => payment.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // TableRow 클릭시 상세 페이지로 이동
  const handleRowClick = (e, payment) => {
    if (e.target.classList.contains("ignoreClick") || e.target.type === "checkbox") {
      return;
    }

    props.goPaymentUpdatePage(e, payment);
  };

  return (
    <div>
      <Paper>
        <Table data-testid="payment-customer-list">
          {CM.cfnCompColGroup(["4%", "auto", "11%", "12%", "10%", "9%", "6%", "7%", "7%", "6%", "7%", "7%", "8%"])}
          <TableForm.compServerSortDoubleRowTableHead
            useCheckbox={true}
            checked={checkAllRow}
            value=""
            onChange={handleCheckAllRowChange}
            rowOne={[
              { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
              { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true, rowSpan: 2 },
              { id: "", label: "휴대전화번호", sortable: false, rowSpan: 2 },
              { id: "CAPITAL_NAME", label: "자금종류", sortable: false, rowSpan: 2 },
              { id: "", label: "지급", sortable: false, colSpan: 3 },
              { id: "PAY_AMOUNT", label: "지급금액", sortable: true, rowSpan: 2 },
              { id: "SEND_FOR_SMS", label: "SMS\n사용여부", sortable: false, rowSpan: 2 },
              { id: "PAY_PERIOD", label: "지급주기", sortable: false, rowSpan: 2 },
              { id: "TRANSACTION_METHOD", label: "지급방법", sortable: false, rowSpan: 2 },
              { id: "CONTRACT_STATUS", label: "서비스\n상태", sortable: true, rowSpan: 2 },
            ]}
            rowTwo={[
              { id: "PAY_START_Y4MM", label: "시작월", sortable: true },
              { id: "PAY_END_Y4MM", label: "종료월", sortable: true },
              { id: "PAY_SPECIFIED_DAY", label: "지급일", sortable: true },
            ]}
            searchRequest={props.searchRequest}
            checkboxCustomProps={{ "data-testid": "paymentInformation-list-head-checkbox" }}
            tableSortLabelDataTestId={"paymentInformation-list-head-sortLabel"}
            handleSortProperty={props.handleSortProperty}
          />
          <TableBody>
            {props.paymentList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={13} />
            ) : (
              props.paymentList.map((payment, index) => {
                return (
                  <TableRow hover key={index} className="show-detail" onClick={(e) => handleRowClick(e, payment)} data-testid={`paymentInformation-goDetail-${index}`}>
                    <TableCell className="ignoreClick" align="center">
                      <Checkbox
                        checked={payment._checked}
                        value="_checked"
                        id={`deleteCheckbox-${index}`}
                        onChange={handleCheckBoxChange}
                        inputProps={{
                          index: index,
                        }}
                        data-testid={`paymentInformation-list-checkbox-${index}`}
                      />
                    </TableCell>
                    <TableCell align="center">{payment.customer.customerName}</TableCell>
                    <TableCell align="center">{CM.cfnIdentificationNoFormat(payment.customer.identificationNo)}</TableCell>
                    <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(payment.customer.customerMobile)}</TableCell>
                    <TableCell align="center">{payment.capital.capitalName}</TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(payment.payStartY4mm, "yyyyMM")}</TableCell>
                    <TableCell align="center">{payment.payEndY4mm !== "999912" ? CM.cfnDateFormat(payment.payEndY4mm, "yyyyMM") : "기한없음"}</TableCell>
                    <TableCell align="center">{CM.cfnDayFormat(payment.paySpecifiedDay)}</TableCell>
                    {payment.payAmountType === "VARIABLE" ? <TableCell align="center">비정액</TableCell> : <TableCell align="right">{CM.cfnAddComma(payment.payAmount)}</TableCell>}
                    <TableCell align="center">{payment.sendForAskingDeposit || payment.sendForFailureOnDeposit || payment.sendForSuccessOnDeposit ? "사용" : "미사용"}</TableCell>
                    <TableCell align="center">{CustomerData.getPaymentName.payPeriod(payment.payPeriod)}</TableCell>
                    <TableCell align="center">{CustomerData.getPaymentName.transactionMethod(payment.transactionMethod)}</TableCell>
                    <TableCell align="center">{payment.askStatus === "ASKING" ? "청구중" : CustomerData.getPaymentName.contractStatus(payment.contractStatus)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

/*
 * @desc  렌더를 처리하는 메인 컴포넌트
 */
const PaymentInformation = ({ props }) => {
  const { store } = props;
  const history = useHistory();

  /* TODO: hooks */
  const [summaryInfo, setSummaryInfo] = useState([
    { key: "paymentCustomerCount", label: "전체 지급고객수", value: 0 },
    { key: "cmsPaymentCustomerCount", label: "CMS 지급대상", value: 0 },
    { key: "etcPaymentCustomerCount", label: "기타 지급대상", value: 0 },
  ]);

  const [searchRequest, setSearchRequest] = useState(searchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(false); // 검색 실행 flag
  const [originalPayment, setOriginalPayment] = useState({}); // 서버로 부터 전달받은 original payment object
  const [paymentList, setPaymentList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());
  const [addExcelOpen, setAddExcelOpen] = useState(false); //일괄등록 모달창 제어
  const [instituteInfoState, setInstituteInfoState] = useState(false);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosPaymentCapitals(); //지급 자금종류
      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      if (businessInfo.cmsService.indexOf("EB31") === -1) {
        setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 입금이체 서비스를 이용하지 않아 본 서비스를 이용하실 수 없습니다.", () => {});
      }
    };

    getStoreDataAxios();
  }, [store]);

  // 지급 정보 데이터 조회
  useEffect(() => {
    const axiosSummary = () => {
      return new Promise((resolve) => {
        const url = "api/customer/payment/payments/summary";
        CM.cfnAxios(url, "get", "", (objStatus, data) => {
          resolve(data);
        });
      });
    };

    const startAxiosSummary = async () => {
      const resultData = await axiosSummary();
      handleSummaryInfoChange(resultData);
    };

    const axiosList = (search) => {
      return new Promise((resolve) => {
        const url = `api/customer/payment/payments?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchProperty=${search.terms}&searchContents=${search.search}&capitalUniqueKey=${search.capital}&groupUniqueKey=${search.group}&transactionMethod=${search.transactionMethod}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultPaymentCustomer = resultData.content.map((data) => ({ ...data, _checked: false }));

      handleOriginalPaymentChange(resultData);
      handlePaymentListChange(resultPaymentCustomer);
      handlePaginationChange(paginationData(resultData));
      handleSearchButtonChange(false);

      // state 유지를 위한 history 설정
      history.replace("/customer/paymentInformation", search);
    };

    // 실행 영역

    if (searchButton) {
      startAxiosSummary();
      startAxios(searchRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest, searchButton]);

  useEffect(() => {
    const getState = history.location.state;
    if ((history.action === "POP" || history.action === "PUSH") && CM.cfnIsNotEmpty(getState)) {
      setSearchOption({
        capital: getState.capital,
        group: getState.group,
        terms: getState.terms,
        search: getState.search,
      });
      handleSearchRequestChange(getState);
    }
    setSearchButton(true);
  }, [history]);
  // 요약 정보 handler
  const handleSummaryInfoChange = (param) => setSummaryInfo((data) => data.map((element) => ({ ...element, value: param[element.key] })));

  // 요약 정보를 클릭했을 때 동작
  const handleSummaryInfoClick = (key) => {
    handleSearchRequestChange((value) => ({
      ...value,
      pageNumber: 0,
      transactionMethod: key === "paymentCustomerCount" ? "" : key === "cmsPaymentCustomerCount" ? "CMS_DEPOSIT" : "ETC",
    }));
    handleSearchButtonChange(true);
  };

  // 오리지널 조회 데이터 handler
  const handleOriginalPaymentChange = (data) => setOriginalPayment(data);

  // 페이지네이션 handler
  const handlePaginationChange = (data) => setPagination(data);

  // 검색 flag handler
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 고객 목록 리스트 값 handler
  const handlePaymentListChange = (data) => setPaymentList(data);

  // 테이블 데이터 검색 조건 handler
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  // 검색 (page) handler
  const handleOffsetChange = (e, offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage) handler
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  // 검색 컴포넌트 handler
  const handleSearchFormChange = (group, capital, terms, search, pageNumber) => {
    handleSearchRequestChange((data) => ({
      ...data,
      group: group,
      capital: capital,
      terms: terms,
      search: search,
      pageNumber: pageNumber,
    }));
  };

  const [searchOption, setSearchOption] = useState({
    capital: "",
    group: "",
    terms: "CUSTOMER_NAME",
    search: "",
  });

  // 검색옵션 handler
  const handleSearchOptionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchOption((option) => ({ ...option, [name]: value }));
  };

  // 지급고객정보 등록 버튼 클릭 handler
  const goCreatePaymentPage = () => history.push("/customer/paymentCreate");

  // 일괄등록 버튼 클릭 이벤트 핸들러
  const handleAddExcelModal = () => {
    setAddExcelOpen(true);
  };

  // 고객 row 클릭시 조회/수정 페이지로 이동
  const goPaymentUpdatePage = (e, payment) => {
    e.preventDefault();
    history.push("/customer/paymentUpdate", {
      payment: payment,
      searchRequest: searchRequest,
    });
  };

  //일괄수정
  const [paymentUpdateListOpen, setPaymentUpdateListOpen] = useState(false);

  // 선택고객 삭제 처리 함수
  const fnDeletePaymentCustomer = () => {
    const sendData = paymentList.filter((payment) => payment._checked).map((payment) => payment.uniqueKey);

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      CM.cfnAlert("선택된 고객이 없습니다.");
      return false;
    }

    CM.cfnConfirm("총 " + sendData.length + "건의 지급고객정보를 삭제하시겠습니까?", () => {
      CM.cfnConfirm(
        "지급고객정보 삭제 후 고객기본정보에 남는 지급정보 및 수납정보가 없는 경우 고객기본정보까지 함께 삭제하시겠습니까?\n확인 : 고객기본정보 함께 삭제, 취소 : 고객기본정보 유지",
        () => {
          // axios 호출
          CM.cfnAxios("api/customer/payment/payments/deleteList/deleteBasics", "put", sendData, (status, response) => {
            CM.cfnAlert(response, () => {
              // 데이터 재조회
              handleSearchButtonChange(true);
            });
          });
        },
        () => {
          // axios 호출
          CM.cfnAxios("api/customer/payment/payments/deleteList", "put", sendData, (status, response) => {
            CM.cfnAlert(response, () => {
              // 데이터 재조회
              handleSearchButtonChange(true);
            });
          });
        }
      );
    });
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 엑셀 저장
  const fnDownloadExcel = () => {
    const url = `/api/customer/payment/payments/excel?capitalUniqueKey=${searchRequest.capital}&groupUniqueKey=${searchRequest.group}&searchContents=${searchRequest.search}&searchProperty=${searchRequest.terms}&transactionMethod=${searchRequest.transactionMethod}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  return (
    <div className={instituteInfoState ? style.disabled_div : ""}>
      <div className="summary-main-area">
        <CF.SummaryForm list={summaryInfo} onClick={handleSummaryInfoClick} data-testid="paymentInformation-main" />
        <div className="summary-button-area">
          <button className="btn-m summary-button" onClick={goCreatePaymentPage} data-testid="paymentInformation-insert-customers">
            지급고객등록
          </button>
          <button className="btn-m summary-button" onClick={handleAddExcelModal} data-testid="paymentInformation-insert-customer-bulk">
            일괄등록
          </button>
          <button className="btn-m summary-button" onClick={(event) => setPaymentUpdateListOpen(true)} data-testid="paymentInformation-update-customer-bulk">
            일괄변경
          </button>
        </div>
      </div>
      <SearchForm
        searchOption={searchOption} // 검색옵션
        handleSearchOptionChange={handleSearchOptionChange}
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        storeCapital={toJS(store.paymentCapital)} // 자금종류
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
      />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={originalPayment.totalElements} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} customProps={{ inputProps: { "data-testid": "paymentInformation-rowPerPage-select" } }} />
        <button className="btn-m fr" onClick={fnDownloadExcel} data-testid="paymentInformation-save-excel">
          엑셀저장
        </button>
        <button className="btn-m fr" onClick={fnDeletePaymentCustomer} data-testid="paymentInformation-delete-data">
          선택고객 삭제
        </button>
      </div>
      <ListForm
        paymentList={paymentList}
        handlePaymentListChange={handlePaymentListChange}
        goPaymentUpdatePage={goPaymentUpdatePage}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="paymentInformation-list-pagiation" />
      <PaymentCreateModal open={addExcelOpen} setOpen={setAddExcelOpen} handleSearchButtonChange={handleSearchButtonChange} />
      <PaymentUpdateList
        open={paymentUpdateListOpen}
        setOpen={setPaymentUpdateListOpen}
        searchCapitalUniqueKeyData={toJS(store.paymentCapital)}
        searchGroupUniqueKeyData={toJS(store.customerGroup)}
        handleSearchButtonChange={handleSearchButtonChange}
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaymentInformation));
