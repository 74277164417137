import React from "react";

class ServiceCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="inforbox">
          <li>화면설계중</li>
        </div>
      </div>
    );
  }
}
export default ServiceCenter;
