import * as CM from "../../common/Common";

/*
 * @desc  현재 연월 반환 함수
 */
export const fnGetCurrent = () => {
  let tempObj = {};

  const today = new Date();
  const year = today.getFullYear().toString();
  const month = (today.getMonth() + 1).toString();

  tempObj.year = year;
  tempObj.month = month;

  return tempObj;
};

/*
 * @desc  버튼 클릭 시 달력 전환 처리 함수
 */
export const fnMoveCalendar = (button, current) => {
  const currentYear = Number(current.year);
  const currentMonth = Number(current.month);

  let tempObj = {};
  let year = currentYear;
  let month = currentMonth;

  if (button === "prev") {
    if (month === 1) {
      year--;
      month = 12;
    } else {
      month--;
    }
  } else if (button === "next") {
    if (month === 12) {
      year++;
      month = 1;
    } else {
      month++;
    }
  }

  tempObj.year = year.toString();
  tempObj.month = month.toString();

  return tempObj;
};

/*
 * @desc  월 한글명 전환 함수
 */
export const fnGetMonthName = (month) => {
  switch (month) {
    case "1":
    case "01":
      return "1월";
    case "2":
    case "02":
      return "2월";
    case "3":
    case "03":
      return "3월";
    case "4":
    case "04":
      return "4월";
    case "5":
    case "05":
      return "5월";
    case "6":
    case "06":
      return "6월";
    case "7":
    case "07":
      return "7월";
    case "8":
    case "08":
      return "8월";
    case "9":
    case "09":
      return "9월";
    case "10":
      return "10월";
    case "11":
      return "11월";
    case "12":
      return "12월";
    default:
      return month;
  }
};

/*
 * @desc  선택연월에 대한 일자 목록 생성 함수
 */
export const fnMakeDateList = (selected, eventList, holidayList) => {
  let tempArray = [];
  const today = CM.cfnGetDate();

  let month = `${selected.month}`;
  if (0 < month && month < 10) {
    month = "0" + month;
  }

  const selectedTmp = `${selected.year}-` + month + `-01`;
  const selectedYm = new Date(selectedTmp); //선택연월
  const firstDate = new Date(selectedYm.getFullYear(), selectedYm.getMonth(), 1); //선택월의 첫째날
  const lastDate = new Date(selectedYm.getFullYear(), selectedYm.getMonth() + 1, 0); //선택월의 마지막날

  const lastDateOfPrevMonth = new Date(selectedYm.getFullYear(), selectedYm.getMonth(), 0).getDate(); //전월의 마지막날
  const lastDayOfPrevMonth = new Date(selectedYm.getFullYear(), selectedYm.getMonth(), 0).getDay(); //전월의 마지막 요일

  let prevCellCount = firstDate.getDay(); //필요한 이전달 day box 갯수
  if (prevCellCount === 0) prevCellCount = 7;

  let nextCellCount = 6 - lastDate.getDay(); //필요한 다음달 day box 갯수
  if (nextCellCount === 6) nextCellCount = 7;

  const totalDays = prevCellCount + nextCellCount + lastDate.getDate();
  if (totalDays < 42) nextCellCount += 42 - totalDays; //모든 월에서 6주치 내역을 표시하도록 모자란 셀을 추가

  //이전달 일자가 포함된 calendar week 생성
  for (let i = 0; i < prevCellCount; i++) {
    const prevMonth = selectedYm.getMonth();
    let tempDayBox = {
      isCurrentMonth: false,
      isToday: false,
      isHoliday: false,
      date: `${prevMonth > 9 ? prevMonth : "0" + prevMonth}${lastDateOfPrevMonth - (lastDayOfPrevMonth - i)}`,
    };
    tempArray.push(tempDayBox);
  }

  //이번달 day box 생성
  for (let i = 0; i < lastDate.getDate(); i++) {
    let tempDayBox = {
      isCurrentMonth: true,
      isToday: false,
      isHoliday: false,
      date: 0,
    };

    const date = firstDate.getDate() + i; //저장할 날짜
    const processedDate = `${selected.year}${selected.month > 9 ? selected.month : "0" + selected.month}${date > 9 ? date : "0" + date}`; //저장할 날짜를 연월일 형태로 가공

    if (today === processedDate) tempDayBox.isToday = true;

    for (let j = 0; j < holidayList.length; j++) {
      const holiDate = holidayList[j].date;
      const holidayName = holidayList[j].holidayName;

      //해당 날짜에 공휴일이 있을시
      if (holiDate === processedDate && holidayName !== "") {
        tempDayBox.isHoliday = true;
      }
    }
    tempDayBox.date = processedDate.substring(4);

    tempArray.push(tempDayBox);
  }

  //다음달 day box 생성
  for (let i = 0; i < nextCellCount; i++) {
    const nextMonth = selectedYm.getMonth() + 2;
    let tempDayBox = {
      isCurrentMonth: false,
      isToday: false,
      date: `${nextMonth > 9 ? nextMonth : "0" + nextMonth}${"0" + (i + 1)}`,
    };
    tempArray.push(tempDayBox);
  }

  //일주일 단위로 분할
  tempArray = fnMakeWeekList(tempArray, eventList, holidayList);

  return tempArray;
};

/*
 * @desc  생성된 일자 목록과 내역 목록을 취합 및 일주일 단위로 분할 처리 함수
 */
export const fnMakeWeekList = (dateList, eventList, holidayList) => {
  const tempArray = [];
  let tempWeekArray; //일주일 단위로 분할하여 저장할 Array 선언
  let weekIndex = 0; //일주일 단위로 분할 제어를 위한 인덱스

  for (let i = 0; i < dateList.length; i++) {
    //주차 변경 시 Array 초기화
    if (weekIndex === 0) tempWeekArray = [];

    if (eventList.length !== 0) {
      //출금/지급내역 목록이 있을 시 데이터 매핑
      const date = dateList[i].date;

      for (let j = 0; j < eventList.length; j++) {
        const eventDate = eventList[j].date.substr(4);
        let tempEventList = []; //해당 날짜의 출입금 청구 및 결과 내역을 저장할 Array 선언

        //해당 날짜에 출입금 내역이 있을 시
        if (date === eventDate) {
          //autoWithdrawYn === false 일 경우 한 날짜에 여러개의 내역이 존재 가능하므로 반복문 실행하면서 리스트 가공
          let fileInfoList = eventList[j].fileInfoList;
          if (fileInfoList) {
            for (const file of fileInfoList) {
              //가공한 청구 및 결과내역을 담을 Object 선언
              let tempObj = {
                type: "", //출금,입금 청구 유형
                summary: "", //달력에 출력할 요약정보
                onRequest: false, //청구중인 데이터 여부
                needTooltip: false, //툴팁 출력 필요 여부
                details: {}, //툴팁 내 출력할 상세 데이터
              };

              //fileType 값에 맞춰 파일유형 입력
              switch (file.fileType) {
                case "EB21": //익일출금
                case "EC21": //당일출금
                  tempObj.type = file.autoWithdrawYn ? "AUTO" : "WITHDRAW";
                  break;
                case "EB31": //입금이체
                  tempObj.type = "PAYMENT";
                  break;
                default:
                  break;
              }

              //fileStatus 값에 맞춰 요약정보 및 상세정보 가공하여 입력
              switch (file.fileStatus) {
                case "READY":
                  //청구중
                  tempObj.summary = `${CM.cfnAddComma(file.numberOfAskedRecords)}건 청구 대기중`;
                  tempObj.needTooltip = true;
                  tempObj.onRequest = true;
                  tempObj.details = {
                    date: eventList[j].date,
                    numberOfTotalRequest: CM.cfnAddComma(file.numberOfAskedRecords),
                    amountOfTotalAmount: CM.cfnAddComma(file.totalAskingAmount),
                  };
                  break;
                case "TRANSMITTED":
                  //청구중
                  tempObj.summary = `${CM.cfnAddComma(file.numberOfAskedRecords)}건 청구 중`;
                  tempObj.needTooltip = true;
                  tempObj.onRequest = true;
                  tempObj.details = {
                    date: eventList[j].date,
                    numberOfTotalRequest: CM.cfnAddComma(file.numberOfAskedRecords),
                    amountOfTotalAmount: CM.cfnAddComma(file.totalAskingAmount),
                  };
                  break;
                case "APPLIED_RESULT_FILE":
                case "CONFIRMED_RESULT_FILE":
                  //청구완료 이후
                  tempObj.summary = `${CM.cfnAddComma(file.numberOfAskedRecords)}건(${CM.cfnAddComma(file.numberOfFullWithdraw)}/${CM.cfnAddComma(
                    file.numberOfFullError + file.numberOfPartialWithdraw
                  )})`;
                  tempObj.needTooltip = true;
                  tempObj.onRequest = false;
                  tempObj.details = {
                    date: eventList[j].date,
                    numberOfTotalRequest: CM.cfnAddComma(file.numberOfAskedRecords - (file.numberOfFullError + file.numberOfPartialWithdraw)),
                    amountOfTotalAmount: CM.cfnAddComma(file.totalAskingAmount),
                    numberOfTotalError: CM.cfnAddComma(file.numberOfFullError + file.numberOfPartialWithdraw),
                    amountOfTotalError: CM.cfnAddComma(file.amountOfFullError + file.amountOfPartialError),
                  };
                  break;
                default:
                  break;
              }

              tempEventList.push(tempObj);
            }
          }
          if (eventList[j].autoWithdrawYn) {
            //자동출금예정일 경우 툴팁 없이 달력 내 요약정보만 출력
            if (
              tempEventList.findIndex((value, index) => {
                if (value.type === "AUTO") {
                  return index;
                } else {
                  return -1;
                }
              }) === -1
            ) {
              tempEventList.push({ type: "AUTO", summary: "자동출금예정", onRequest: false, needTooltip: false, details: {} });
            }
          }

          dateList[i].historyList = tempEventList;
        }
      }
    }

    if (holidayList.length !== 0) {
      //출금/지급내역 목록이 있을 시 데이터 매핑
      const date = dateList[i].date;

      for (let j = 0; j < holidayList.length; j++) {
        const holiDate = holidayList[j].date.substr(4);
        const holiDayName = holidayList[j].holidayName;
        let tempHolidayList = []; //해당 날짜의 공휴일 내역을 저장할 Array 선언

        //해당 날짜에 공휴일이 있을시
        if (date === holiDate && holiDayName !== "") {
          if (Object.isExtensible(dateList[i].historyList)) {
            tempHolidayList = dateList[i].historyList;
          }
          tempHolidayList.push({ type: "HOLIDAY", summary: holiDayName, onRequest: false, needTooltip: false, details: {} });
          dateList[i].historyList = tempHolidayList;
        }
      }
    }

    tempWeekArray.push(dateList[i]);
    weekIndex++;

    if (weekIndex === 7) {
      //주차 변경 시 현재까지 생성한 Array를 저장한 후 weekIndex 초기화
      tempArray.push(tempWeekArray);
      weekIndex = 0;
      continue;
    }
  }

  return tempArray;
};

export const fnMatchEventType = (type) => {
  switch (type) {
    case "WITHDRAW":
      return "출";
    case "PAYMENT":
      return "입";
    case "AUTO":
      return "자";
    default:
      return type;
  }
};
