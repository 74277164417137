import React, { useState, useEffect } from "react";
import { toJS } from "mobx";
import * as CM from "../../common/Common";
import { observer, inject } from "mobx-react";
import { Select, Table, TableBody, TableCell, TableRow, TableHead, TextField, FormControl, Input } from "@material-ui/core/";
import { EditableCellInput } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

const CustMnmtBankBookHead = () => {
  const arrData = ["자금구분", "자금종류", "통장기재내역(기본)", "통장기재내역(추가기재)", "관리"];

  return arrData.map((value, index) => {
    return (
      <TableCell key={index} className="th2">
        {value}
      </TableCell>
    );
  });
};

// 통장 기재내역(추가기재)type
// const addBankBookLog = [
//   {
//     key: '청구월',
//     value: 'MONTH'
//   },
//   {
//     key: '청구연월',
//     value: 'YEARMONTH'
//   },
//   {
//     key: '청구회차',
//     value: 'SEQUENCE'
//   },
//   {
//     key: '직접입력',
//     value: 'MANUAL'
//   }
// ];

// 통장 기재내역(추가기재)type
const addPaymentBankBookLog = [
  {
    key: "직접입력",
    value: "MANUAL",
  },
];

const CustMnmtBankBookTable = ({ capital, businessInfo, setCapital, instituteStore, handleChangeCapital }) => {
  const [tableData, setTableData] = useState([]); // 테이블 데이타
  const [bankbookContents, setBankbookContents] = useState([]);
  const [capitalType, setCapitalType] = useState([]);

  const [opValue, setOpValue] = useState({
    capitalType: "",
    bankbookPostfixType: "",
    bankbookContents: "",
  }); // 조건 셀렉트 박스 Hook

  const [newCapital, setNewCapital] = useState(""); // 인풋 데이터
  const [newBankbookPostfix, setNewBankbookPostfix] = useState("");

  useEffect(() => {
    setTableData(capital);

    if (CM.cfnIsNotEmpty(businessInfo.bankbookContents)) {
      setBankbookContents([{ key: "선택", value: null }].concat(businessInfo.bankbookContents.bankbookContents.map((contents) => ({ key: contents, value: contents }))));
    }

    if (CM.cfnIsNotEmpty(businessInfo.cmsService) && opValue.bankbookPostfixType === "") {
      let tmp = [];
      if (capitalType.length === 0) {
        const cmsService = businessInfo.cmsService;
        if (cmsService.includes("EB21") || cmsService.includes("EC21")) {
          tmp.push({
            key: "수납자금",
            value: "RECEIPT",
          });
        }
        if (cmsService.includes("EB31")) {
          tmp.push({
            key: "지급자금",
            value: "PAYMENT",
          });
        }
        setCapitalType(tmp);
      } else {
        tmp = capitalType;
      }

      setOpValue({
        capitalType: tmp.length > 0 ? tmp[0].value : "",
        bankbookPostfixType: "MANUAL",
        bankbookContents: "",
      });
    }
  }, [capital, businessInfo, capitalType, opValue]);

  // 테이블 내부 셀렉트 이벤트s
  const handleSelectChange = (e) => {
    const target = e.target;
    const idx = Number(target.getAttribute("index"));
    const name = target.getAttribute("name");
    const value = target.value;

    const cloneData = [...tableData];
    cloneData[idx][name] = value;

    setTableData(cloneData);
  };

  // 필터 셀렉트 이벤트
  const handleSelectFilterChange = (e) => {
    const target = e.target;
    const name = target.getAttribute("name");
    const value = target.value;
    setOpValue({
      ...opValue,
      [name]: value,
    });
  };

  const handleCapitalNameChange = (row, change) => {
    const setData = [...capital];
    setData[row][change.fieldName] = change.fieldValue;
    setCapital(setData);
  };

  function CreateSelect(props) {
    const { value, name, index, optionData, handleSelectChange, datatestid, disabled } = props;

    let rtnOption = [];
    let cloneOptionData = toJS(optionData);

    // optionData에 맞는 option 생성
    if (Array.isArray(cloneOptionData) && CM.cfnIsNotEmpty(cloneOptionData)) {
      if (name === "bankbookPostfixType") {
        rtnOption = cloneOptionData.map((obj, idx) => {
          if (typeof obj === "object") {
            return (
              <option value={obj.bankbookPostfixType} key={idx}>
                {obj.bankbookPostfixTypeName}
              </option>
            );
          }

          return (
            <option value={obj} key={idx}>
              {obj}
            </option>
          );
        });
      } else {
        rtnOption = cloneOptionData.map((obj, idx) => {
          if (typeof obj === "object") {
            return (
              <option value={obj.value} key={idx}>
                {obj.key}
              </option>
            );
          }

          return (
            <option value={obj} key={idx}>
              {obj}
            </option>
          );
        });
      }
    }

    return (
      <Select
        native
        value={value || ""}
        disabled={disabled}
        onChange={handleSelectChange}
        inputProps={{
          name: name,
          index: index,
          "data-testid": datatestid,
        }}>
        {rtnOption}
      </Select>
    );
  }

  /*
   * @desc    입력된 자금종류명에 대한 validation 체크 함수
   */
  const fnCheckValidation = (data, postFixData, isUpdate) => {
    let isValid = true;
    const capitalName = data.capitalName;
    const bankbookContents = opValue["bankbookContents"] === "" ? businessInfo.bankbookContents.bankbookContents[0] : opValue["bankbookContents"];

    if (capitalName.length > 20) {
      isValid = false;
      CM.cfnAlert("자금종류 이름은 최대 20자까지 입력가능합니다.");
    }

    if (Number(bankbookContents.length + postFixData.length) > 8) {
      isValid = false;
      CM.cfnAlert("통장기재내역은 추가기재 내용을 포함하여 최대 8자까지 입력가능합니다.");
    }

    let uniqueKey = null;
    //기존 데이터 수정인 경우 uniqueKey까지 비교하도록 값 대입
    if (isUpdate) uniqueKey = data.uniqueKey;
    for (let i = 0; i < tableData.length; i++) {
      if (!isUpdate && tableData[i].capitalName === capitalName && tableData[i].capitalType === data.capitalType) {
        //신규 데이터 입력인 경우 기존 자금종류명과 비교
        isValid = false;
        CM.cfnAlert("이미 등록된 이름입니다.");
        break;
      } else if (isUpdate && tableData[i].uniqueKey !== uniqueKey && tableData[i].capitalName === capitalName && tableData[i].capitalType === data.capitalType) {
        //기존 데이터 수정인 경우 기존 데이터의 uniqueKey 및 자금종류명과 비교
        isValid = false;
        CM.cfnAlert("이미 등록된 이름입니다.");
        break;
      }
    }

    return isValid;
  };

  /*
   * @desc    신규 추가 자금종류명 입력란 변경 이벤트 핸들러
   */
  const handleCapitalAdd = (e) => {
    e.preventDefault();
    if (fnCheckValidation({ capitalName: newCapital, capitalType: opValue["capitalType"] === "" ? capitalType[0].value : opValue["capitalType"] }, newBankbookPostfix, false)) fnInsertCapital();
  };

  /*
   * @desc     추가 필드 값 복사
   */
  const handleNewCapitalChange = (e) => {
    setNewCapital(e.target.value);
  };

  /*
   * @desc     추가 필드 값 복사
   */
  const handleNewBankbookContentsChange = (e) => {
    setOpValue({
      ...opValue,
      bankbookContents: e.target.value,
    });
  };
  /*
   * @desc     추가 필드 값 복사
   */
  const handleNewPostfixChange = (e) => {
    setNewBankbookPostfix(e.target.value);
  };
  /*
   * @desc    테이블 내 통장기재내역(추가기재) Input 변경 이벤트 핸들러
   */
  const handlePostfixChange = (e, row, name) => {
    //fieldName 선언에 대한 경고 메시지로 인해 handleCapitalRowsChange를 바로 호출하지 않고 함수 분리
    handleCapitalNameChange(row, {
      fieldValue: e.target.value,
      fieldName: name,
    });
  };

  /*
   * @desc    신규 자금종류명 추가 버튼 클릭 이벤트 핸들러
   */
  const fnInsertCapital = () => {
    const capitalName = {
      bankbookContents: opValue.bankbookContents === "" ? businessInfo.bankbookContents.bankbookContents[0] : opValue.bankbookContents,
      bankbookPostfix: newBankbookPostfix,
      bankbookPostfixType: opValue.bankbookPostfixType,
      capitalName: newCapital,
      capitalType: opValue.capitalType,
    };

    const url = "api/institute/capitals";
    CM.cfnAxios(url, "post", capitalName, fnCapitalCallback);
  };

  /*
   * @desc    Request 후 초기화하는 callback 함수
   */
  const fnCapitalCallback = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    if (objStatus.status === 200) {
      CM.cfnAlert("정상 처리되었습니다.");
      setNewCapital("");
      setNewBankbookPostfix("");
      if (opValue.capitalType === "PAYMENT")
        setOpValue({
          ...opValue,
          bankbookContents: "",
        });
      instituteStore.getCapital(fnGetCapital);
    }
  };

  /*
   * @desc    자금종류 조회 후 callback 함수
   */
  const fnGetCapital = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }
    handleChangeCapital(data);
    instituteStore.setCapital(data);
  };

  /*
   * @desc    기존 자금종류 수정 버튼 클릭 이벤트 핸들러
   */
  const handleCapitalUpdate = (e, rowData) => {
    CM.cfnConfirm("정말 수정하시겠습니까?", () => {
      fnUpdateCapital(rowData);
    });
  };

  /*
   * @desc    기존 자금종류 수정 Request
   */
  const fnUpdateCapital = async (rowData) => {
    const url = "api/institute/capitals";
    await CM.cfnAxios(url, "put", rowData, fnCapitalCallback);
  };

  const handleCapitalDelete = (capitalUniqueKey) => {
    CM.cfnConfirm("정말 삭제하시겠습니까?", () => {
      fnDeleteCustomerGroup(capitalUniqueKey);
    });
  };
  /*
   * @desc    기존 자금종류 삭제 Request
   */
  const fnDeleteCustomerGroup = async (capitalUniqueKey) => {
    const url = `/api/institute/capitals/${capitalUniqueKey}`;
    await CM.cfnAxios(url, "delete", "", fnCapitalCallback);
  };

  const fnSetPostfixTypeOption = (postFixTypeMap, index) => {
    let bankbookContentsLength = "";
    let length;

    if (index !== "" && index !== undefined) {
      if (capital[index] !== undefined) bankbookContentsLength = capital[index].bankbookContents.length;
      length = 8 - bankbookContentsLength;
    } else {
      if (opValue.bankbookContents === "") {
        bankbookContentsLength = bankbookContents[0].length;
      } else {
        bankbookContentsLength = opValue.bankbookContents.length;
      }
      length = 8 - bankbookContentsLength;
    }

    let result = [];

    for (let i = 0; i < postFixTypeMap.length; i++) {
      const postFixType = postFixTypeMap[i];
      if (length === 0) {
        result.push(postFixType);
      } else if (length === 2) {
        if (postFixType.bankbookPostfixType === "MONTH") {
          result.push(postFixType);
        }
      } else if (length === 3) {
        if (postFixType.bankbookPostfixType === "MONTH" || postFixType.bankbookPostfixType === "SEQUENCE") {
          result.push(postFixType);
        }
      } else if (length >= 4) {
        if (
          postFixType.bankbookPostfixType === "MONTH" ||
          postFixType.bankbookPostfixType === "SEQUENCE" ||
          postFixType.bankbookPostfixType === "YEARMONTH" ||
          postFixType.bankbookPostfixType === "TARGET_Y4MM"
        ) {
          result.push(postFixType);
        }
      }
      if (length !== 0 && postFixType.bankbookPostfixType === "MANUAL") {
        result.push(postFixType);
      }
    }
    return result;
  };
  return (
    <div>
      <div className="modify-area">
        <div className="block">
          <form name="capital" onSubmit={handleCapitalAdd}>
            <label className="label-l">자금구분</label>
            <FormControl>
              <Select
                native
                value={opValue["capitalType"]}
                onChange={handleSelectFilterChange}
                required={true}
                input={<Input name="capitalType" />}
                name="capitalType"
                inputProps={{ "data-testid": "insert-capitalType-select" }}>
                {CM.cfnIsNotEmpty(capitalType) &&
                  capitalType.map((row, index) => {
                    return (
                      <option key={index} value={row.value}>
                        {row.key}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
            <label className="label-l">자금종류</label>
            <TextField placeholder="자금종류명 입력" className="w160" value={newCapital} onChange={handleNewCapitalChange} required={true} data-testid="insert-capital-name" />
            <label className="label-l">통장기재내역(기본)</label>
            {opValue["capitalType"] === "RECEIPT" ? (
              <CreateSelect
                value={opValue["bankbookContents"]}
                name="bankbookContents"
                optionData={bankbookContents}
                handleSelectChange={handleSelectFilterChange}
                data-testid="insert-bankbookContents-select"
              />
            ) : (
              <Input
                className="w150"
                value={opValue["bankbookContents"]}
                name="bankbookContents"
                onChange={handleNewBankbookContentsChange}
                placeholder="8자리 이내"
                data-testid="insert-bankbookContents-input"
                inputProps={{ maxLength: 8 }}
              />
            )}
            {opValue["capitalType"] === "RECEIPT" ? (
              <span>
                <span className="label-l">(추가기재)</span>
                <span>
                  <Select
                    native
                    disabled={opValue.bankbookContents === "" ? bankbookContents[0].length === 8 : opValue.bankbookContents.length === 8}
                    //1-1. opValue의 통장기재내역이 없으면 통장기재내역(기본)셀렉트박스의 첫번째요소 글자수체크
                    //1-2. opValue의 통장기재내역이 있으면 그 값의 글자수체크
                    //2. 글자수가 8개가 넘으면 disabled
                    value={opValue.bankbookPostfixType}
                    name="bankbookPostfixType"
                    onChange={handleSelectFilterChange}
                    input={<Input name="bankbookPostfixType" />}
                    inputProps={{
                      "data-testid": "insert-bankbookPostfixType-select",
                    }}>
                    {fnSetPostfixTypeOption(CM.cfnGetPostFixTypes()).map((row, index) => {
                      return (
                        <option key={index} value={row.bankbookPostfixType}>
                          {row.bankbookPostfixTypeName}
                        </option>
                      );
                    })}
                  </Select>
                  <Input
                    placeholder="추가기재 입력"
                    style={opValue.bankbookPostfixType === "MANUAL" ? { display: "inline-block" } : { display: "none" }}
                    disabled={
                      opValue.bankbookContents === ""
                        ? bankbookContents[0].length === 8 || opValue.bankbookPostfixType !== "MANUAL"
                        : opValue.bankbookContents.length === 8 || opValue.bankbookPostfixType !== "MANUAL"
                    }
                    //1-1. opValue의 통장기재내역이 없으면 통장기재내역(기본)셀렉트박스의 첫번째요소 글자수체크
                    //1-2. opValue의 통장기재내역이 있으면 그 값의 글자수체크
                    //2. 글자수가 8개가 넘으면 disabled
                    //3. 글자수가 8개가 안넘는데 '직접입력'이 아닐경우 disabled
                    className="w100"
                    value={newBankbookPostfix}
                    onChange={handleNewPostfixChange}
                    data-testid="insert-Postfix-name"
                  />
                </span>
              </span>
            ) : (
              ""
            )}
            <button type="submit" className="btn-l">
              자금종류 추가
            </button>
          </form>
        </div>
      </div>
      <div>
        <div className="table-top-area" />
        <Table aria-labelledby="tableTitle" data-testid="capital-table" style={{ marginBottom: "120px" }}>
          {CM.cfnCompColGroup(["18%", "auto", "20%", "30%", "20%"])}
          <TableHead>
            <TableRow>
              <CustMnmtBankBookHead />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={5} />
            ) : (
              tableData.map((rows, index) => {
                // 통장기재 추가기재 디폴트 값
                rows.bankbookPostfixFormat = "";
                if (rows.bankbookPostfixType === "YEARMONTH" || rows.bankbookPostfixType === "TARGET_Y4MM") {
                  rows.bankbookPostfixFormat = "YYMM";
                } else if (rows.bankbookPostfixType === "MONTH") {
                  rows.bankbookPostfixFormat = "MM";
                } else if (rows.bankbookPostfixType === "SEQUENCE") {
                  rows.bankbookPostfixFormat = "NNN";
                } else {
                  rows.bankbookPostfixFormat = rows.bankbookPostfix;
                }

                return (
                  <TableRow key={index} hover tabIndex={-1}>
                    <TableCell align="center">
                      <CreateSelect value={rows.capitalType} name="capitalType" index={index} optionData={capitalType} handleSelectChange={handleSelectChange} />
                    </TableCell>
                    <TableCell align="center">
                      <EditableCellInput
                        row={rows}
                        fieldName="capitalName"
                        onCellValueChange={handleCapitalNameChange.bind(this, index)}
                        inputProps={{
                          "aria-label": "description",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {rows.capitalType === "RECEIPT" ? (
                        <CreateSelect value={rows.bankbookContents} name="bankbookContents" index={index} optionData={bankbookContents} handleSelectChange={handleSelectChange} />
                      ) : (
                        <Input
                          className="w150"
                          value={rows.bankbookContents}
                          name="bankbookContents"
                          onChange={(e) => handlePostfixChange(e, index, "bankbookContents")}
                          inputProps={{ maxLength: 8 }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {rows.capitalType === "RECEIPT" ? (
                        <div>
                          <CreateSelect
                            value={Number(rows.bankbookContents.length) === "8" ? "" : rows.bankbookPostfixType}
                            name="bankbookPostfixType"
                            index={index}
                            optionData={rows.capitalType === "RECEIPT" ? fnSetPostfixTypeOption(CM.cfnGetPostFixTypes(), index) : addPaymentBankBookLog}
                            handleSelectChange={handleSelectChange}
                            data-testid="update-bankbookPostfixType-select"
                            disabled={Number(rows.bankbookContents.length) === 8}
                          />
                          <Input
                            className="w100"
                            disabled={Number(rows.bankbookContents.length) === 8 || rows.bankbookPostfixType !== "MANUAL"}
                            value={Number(rows.bankbookContents.length) === 8 ? "" : CM.cfnNvl(rows.bankbookPostfixFormat, "")}
                            onChange={(e) => handlePostfixChange(e, index, "bankbookPostfix")}
                            placeholder={Number(rows.bankbookContents.length) === 8 ? "" : CM.cfnConvertPostFix(rows.bankbookPostfixType)}
                          />
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div className="btn-l" onClick={(e) => handleCapitalUpdate(e, rows)}>
                        수정
                      </div>
                      <div className="btn-m" onClick={(e) => handleCapitalDelete(rows.uniqueKey)}>
                        삭제
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const CustMnmtBankBook = ({ instituteStore }) => {
  const [capital, setCapital] = useState([]);
  const [businessInfo, setBusinessInfo] = useState([]);

  useEffect(() => {
    const fnGetCapital = () => {
      const fnGetCapitalCallback = (objStatus, data) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }

        instituteStore.setCapital(data); // store 값 설정
        setCapital(data);
      };

      const storeCapital = CM.cfnCopyObject(toJS(instituteStore.capital));
      if (CM.cfnIsEmpty(storeCapital)) {
        instituteStore.getCapital(fnGetCapitalCallback);
      } else {
        setCapital(storeCapital);
      }
    };

    const fnGetInstituBusiness = () => {
      const fnGetInstituteBusinessCallback = (objStatus, data) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        instituteStore.setBusinessInfo(data); // store 값 설정
        setBusinessInfo(data);
      };

      const storeBusinessInfo = CM.cfnCopyObject(toJS(instituteStore.businessInfo));
      if (CM.cfnIsEmpty(storeBusinessInfo)) {
        instituteStore.getBusinessInfo(fnGetInstituteBusinessCallback);
      } else {
        setBusinessInfo(storeBusinessInfo);
      }
    };
    fnGetInstituBusiness();
    fnGetCapital();
  }, [instituteStore]);

  function handleChangeCapital(data) {
    setCapital(data);
  }

  return <CustMnmtBankBookTable capital={capital} businessInfo={businessInfo} setCapital={setCapital} instituteStore={instituteStore} handleChangeCapital={handleChangeCapital} />;
};
export default inject((rootStore) => ({
  instituteStore: rootStore.instituteStore,
}))(observer(CustMnmtBankBook));
