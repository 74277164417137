import { Input, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsPaymentData";
import ResultDetailModal from "./CmsPaymentRequestResultDetailModal";
import {TooltipForm} from "../../template/ComponentForm";

/*
 * @desc  CMS 입금청구 결과 검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      props.handleSearchButtonChange(true);
    }
  };

  return (
    <div className="search-area centered">
      <div className="block">
        <label className="label-l" data-testid="request-date">
          입금청구일
        </label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.searchStartDate}
          handleChange={handleSearchFormChange("searchStartDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.searchEndDate}
          handleChange={handleSearchFormChange("searchEndDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <label className="label-l">검색어입력</label>
        <Select native name="searchProperty" value={searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")}>
          <option value="CUSTOMER_NAME">고객명</option>
          <option value="IDENTIFICATION_NO">생년월일(사업자번호)</option>
        </Select>
        <Input
          value={searchRequest.searchContents}
          onChange={handleSearchFormChange("searchContents")}
          onKeyUp={handleSearchKeyUp}
          name="searchContents"
          className="w120"
          data-testid="cms-payment-keyword-resulttab"
        />
        <button className="search-button" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  /*
   * @desc  입금청구 버튼 클릭 이벤트 핸들러
   */
  const handleMoveTab = () => {
    props.targetTab.click();
  };

  /*
   * @desc  진행상태에 따른 버튼 렌더 처리 함수
   */
  const fnMakeStatusButton = (row, index) => {
    switch (row.statusName) {
      case "청구대기":
        if (row.statusDisabled) {
          return <div> {row.statusName}</div>;
        } else {
          return (
            <button type="button" className={`btn-l2`} onClick={handleMoveTab}>
              {row.statusName}
              <TooltipForm contents={row.statusMessage} />
            </button>
          );
        }
      case "청구불가":
        return <Template.ButtonTooltipForm contents={row.statusMessage} buttonContents={row.statusName} disabled={true} />;
      case "취소요청":
        return (
          <button type="button" className={`btn-l2`} onClick={(e) => props.handleClickCancelRequest(e, row)}>
            {row.statusName}
            <TooltipForm contents={row.statusMessage} />
          </button>
        );
      case "결과확인":
        if (row.isBackupRequired) {
          return (
            <button type="button" className={`btn-l2`} onClick={(e) => props.handleClickBackUpButton(e, row)}>
              결과수신
              <TooltipForm contents={row.statusMessage} />
            </button>
          );
        } else if (row.statusDisabled) {
          return <Template.ButtonTooltipForm contents={row.statusMessage} buttonContents={row.statusName} disabled={true} />;
        } else {
          return (
            <button type="button" className={`btn-l2`} onClick={(e) => props.handleClickResultButton(e, row)}>
              {row.statusName}
              <TooltipForm contents={row.statusMessage} />
            </button>
          );
        }
      case "확인완료":
        return <div onClick={(e) => props.handleClickResultButton(e, row)}>{row.statusName}</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["AUTO", "11%", "13%", "7%", "11%", "11%", "11%", "11%", "11%"])}
        <TableForm.compServerSortDoubleRowTableHead
          useCheckbox={false}
          rowOne={[
            { id: "SENT_FILE_DATETIME", label: "청구전송일시", sortable: true, rowSpan: 2 },
            { id: "TRANSACTION_DATE", label: "입금청구일", sortable: true, rowSpan: 2 },
            {
              id: "RECEIVED_FILE_DATETIME",
              label: "입금결과\n수신일시",
              sortable: true,
              rowSpan: 2,
            },
            {
              id: "NUMBER_OF_ASKED_RECORDS",
              label: "입금청구내역",
              sortable: false,
              colSpan: 2,
            },
            {
              id: "AMOUNT_OF_WITHDRAW",
              label: "입금청구결과",
              sortable: false,
              colSpan: 2,
            },
            {
              id: "ASK_TARGET_CRITERIA",
              label: "입금청구구분",
              sortable: false,
              rowSpan: 2,
            },
            { id: "FILE_STATUS", label: "진행상태", sortable: false, rowSpan: 2, tooltip : true, contents : "일반적으로 청구대기, 취소요청, 청구중, 결과확인, 확인완료의 순서로 진행합니다."},
          ]}
          rowTwo={[
            {
              id: "NUMBER_OF_ASKED_RECORDS",
              label: "청구건수",
              sortable: false,
            },
            { id: "TOTAL_ASKED_AMOUNT", label: "청구금액", sortable: false },
            {
              id: "AMOUNT_OF_WITHDRAW",
              label: "입금합계",
              sortable: false,
            },
            {
              id: "AMOUNT_OF_ERROR",
              label: "입금실패액",
              sortable: false,
            },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId="cmsReceiptRequestTable"
        />
        <TableBody>
          {props.resultList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.resultList.map((row, index) => {
              return (
                <TableRow key={index} hover className="show-detail" onClick={(e) => props.handleRowClick(e, row)}>
                  <TableCell align="center" component="th" scope="row" padding="none">
                    {CM.cfnDateFormat(row.sentFileDatetime)}
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.transactionDate)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.receivedFileDatetime)}</TableCell>
                  <TableCell align="center">{row.numberOfAskedRecords}건</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.amountOfFullWithdraw + row.amountOfPartialWithdraw)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.amountOfFullError + row.amountOfPartialError)}원</TableCell>
                  <TableCell align="center">{CmsData.fileType(row.fileType)}</TableCell>
                  <TableCell align="center">{fnMakeStatusButton(row, index)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function CmsReceiptRequestResult(props) {
  const { tabIndex, businessInfo } = props;

  const [searchRequest, setSearchRequest] = useState(CmsData.resultSearchData()); // table 데이터 검색 조건

  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [resultList, setResultList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());

  const [selectedRow, setSelectedRow] = useState({}); //선택된 행의 uniqueKey 및 결과수신 확인 필요 여부 제어

  //모달 제어
  const [resultDetailOpen, setResultDetailOpen] = useState(false);

  /*
   * @desc  table row 클릭 이벤트 핸들러
   */
  const handleRowClick = async (e, row) => {
    if (e.target.type === "button") {
      return false;
    }
    // TableRow 클릭시 상세 모달창 생성
    await setSelectedRow({
      fileUniqueKey: row.uniqueKey,
      ...row,
    });
    setResultDetailOpen(true);
  };

  /*
   * @desc  테이블 내 결과확인 버튼 클릭 이벤트 핸들러
   */
  const handleClickResultButton = async (e, row) => {
    await setSelectedRow({
      fileUniqueKey: row.uniqueKey,
      ...row,
    });
    setResultDetailOpen(true);
  };

  /*
   * @desc  테이블 내 결과확인 버튼 클릭 이벤트 핸들러(파일 상태 TRANSMITTED이면서 결과수신가능날짜 경과 시 처리)
   */
  const handleClickBackUpButton = async (e, row) => {
    //백업 API 청구 후 성공 시 목록 재조회
    const url = `api/payment/cms/results/${row.uniqueKey}`;
    CM.cfnAxios(
      url,
      "put",
      "",
      (status, data) => {
        CM.cfnAlert("결과 확인이 완료되었습니다.", () => {
          setSearchButton(true);
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
          () => {
            setSearchButton(true);
          }
        );
      }
    );
  };

  /*
   * @desc  테이블 내 취소요청 버튼 클릭 이벤트 핸들러
   */
  const handleClickCancelRequest = (e, row) => {
    CM.cfnConfirm("정말로 해당 입금청구를 취소하시겠습니까?", () => {
      const url = `api/payment/cms/${row.uniqueKey}`;
      CM.cfnAxios(
        url,
        "delete",
        "",
        (status, data) => {
          CM.cfnAlert(data, () => {
            props.targetTab.click();
          });
        },
        (error) => {
          CM.cfnAlert(
            error && error.response && error.response.data && typeof error.response.data === "string"
              ? error.response.data
              : error && error.response && error.response.data && typeof error.response.data.message === "string"
              ? error.response.data.message
              : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
            () => {
              setSearchButton(true);
            }
          );
        }
      );
    });
  };

  //table 데이터 조회
  useEffect(() => {
    /*
     * @desc CMS입금청구 결과 목록 조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.searchStartDate = CM.cfnConvertDateToString(searchRequest.searchStartDate);
      tempObj.searchEndDate = CM.cfnConvertDateToString(searchRequest.searchEndDate);

      return tempObj;
    };

    /*
     * @desc    CMS입금청구 결과 목록 조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/cms/results?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&searchEndDate=${search.searchEndDate}&searchStartDate=${search.searchStartDate}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchProperty=${search.searchProperty}&searchContents=${search.searchContents}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      setOriginalList(resultData);

      //진행상태 버튼 관련 오브젝트를 가공하여 추가
      for (const row of resultList) {
        const statusObj = await CmsData.fnGetStatusButton(row);

        row.statusName = statusObj.statusName;
        row.statusMessage = statusObj.message;
        row.statusDisabled = statusObj.disabled;
        row.isResultApplyingRequired = statusObj.isResultApplyingRequired;
        row.isBackupRequired = statusObj.isBackupRequired;
      }
      setResultList(resultList);

      setPagination(CmsData.paginationData(resultData));
      setSearchButton(false);
    }

    // 실행 영역
    if (tabIndex === 1 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton]);

  useEffect(() => {
    //탭 이동 후 다시 돌아왔을 때 조회할 수 있도록 조회 trigger설정
    if (tabIndex !== 1 && !searchButton) setSearchButton(true);
  }, [tabIndex, searchButton]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    CMS입금청구 결과 목록 조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "searchEndDate" || name === "searchStartDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  //모달
  const handleDetailModalClose = async (e) => {
    //결과확인 필요 시 CMS 입금청구 결과 목록 재조회 수행
    if (selectedRow.isResultApplyingRequired) await handleSearchButtonChange(true);
    setResultDetailOpen(false);
  };

  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="result-per-page" />
      </div>
      <ListForm
        resultList={resultList}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
        handleRowClick={handleRowClick}
        targetTab={props.targetTab}
        handleClickCancelRequest={handleClickCancelRequest}
        handleClickResultButton={handleClickResultButton}
        handleClickBackUpButton={handleClickBackUpButton}
        handleSearchButtonChange={handleSearchButtonChange}
      />
      <Template.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <ResultDetailModal open={resultDetailOpen} handleDetailModalClose={handleDetailModalClose} selectedRow={selectedRow} cmsService={businessInfo} />
    </div>
  );
}

export default CmsReceiptRequestResult;
