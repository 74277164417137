import { Button, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";

/*
 * @desc  목록 컴포넌트 생성
 */
function RegistrationForm(props) {
  const { file, receipt } = props;
  return (
    <div>
      <h5>금융기관 접수 내역</h5>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="th">납부자 번호</TableCell>
            <TableCell className="td" data-testid="test-column-01">
              {file.payerNo}
            </TableCell>
            <TableCell className="th">개인/사업자 구분</TableCell>
            <TableCell className="td"> {!file.customerType ? "-" : file.customerType === "INDIVIDUAL" ? "개인" : "사업자"}</TableCell>
            <TableCell className="th">접수구분</TableCell>
            <TableCell className="td">{file.receiptType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              예금주생년월일
              <br />
              (사업자번호)
            </TableCell>
            <TableCell className="td">{CM.cfnIdentificationNoFormat(file.depositorIdentificationNumber)}</TableCell>
            <TableCell className="th">예금주연락처</TableCell>
            <TableCell className="td" colSpan="3">
              {CM.cfnIsNotEmpty(file.depositorTelephone) ? CM.cfnAddtDashToPhoneNumber(file.depositorTelephone) : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">금융기관</TableCell>
            <TableCell className="td"> {file.financialInstituteName}</TableCell>
            <TableCell className="th">계좌번호</TableCell>
            <TableCell className="td" colSpan="3">
              {" "}
              {file.accountNo}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h5 className="mt20">수납고객정보 내 고객정보</h5>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="th">납부자 번호</TableCell>
            <TableCell className="td" data-testid="test-column-01">
              {receipt && receipt.uniqueKey ? receipt.payerNo : "-"}
            </TableCell>
            <TableCell className="th">개인/사업자 구분</TableCell>
            <TableCell className="td">
              {receipt && receipt.uniqueKey && Object.isExtensible(receipt.customer) && CM.cfnIsNotEmpty(receipt.customer) ? (receipt.customer.customerType === "INDIVIDUAL" ? "개인" : "사업자") : "-"}
            </TableCell>
            <TableCell className="th">고객명</TableCell>
            <TableCell className="td">{receipt && receipt.uniqueKey && Object.isExtensible(receipt.customer) ? receipt.customer.customerName : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              예금주생년월일
              <br />
              (사업자번호)
            </TableCell>
            <TableCell className="td">{receipt && receipt.uniqueKey && Object.isExtensible(receipt.account) ? CM.cfnIdentificationNoFormat(receipt.account.depositorIdentificationNo) : "-"}</TableCell>
            <TableCell className="th">휴대전화번호</TableCell>
            <TableCell className="td">{receipt && receipt.uniqueKey && Object.isExtensible(receipt.customer) ? CM.cfnAddtDashToPhoneNumber(receipt.customer.customerMobile, "-") : ""}</TableCell>
            <TableCell className="th">유선전화번호</TableCell>
            <TableCell className="td">{receipt && receipt.uniqueKey && Object.isExtensible(receipt.customer) ? CM.cfnAddtDashToPhoneNumber(receipt.customer.customerTelephone, "-") : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">금융기관</TableCell>
            <TableCell className="td">
              {" "}
              {receipt && receipt.uniqueKey && Object.isExtensible(receipt.account) && CM.cfnMatchBankName(receipt.account.accountBankCode, props.financialInstitutes)}
            </TableCell>
            <TableCell className="th">계좌번호</TableCell>
            <TableCell className="td" colSpan="3">
              {receipt && receipt.uniqueKey && Object.isExtensible(receipt.account) ? receipt.account.accountNo : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
const ContentTextForm = (props) => {
  switch (props.modalType) {
    case "계좌해지":
    case "계좌(변경)해지":
      if (props.file.customerUniqueKey || (props.receipt && props.receipt.uniqueKey)) {
        return (
          <div className="inforbox">
            <ul>
              <li>아래의 수신내용 반영 승인을 눌러 수납고객정보 내 고객정보를 삭제하시기 바랍니다.</li>
              <li>여타 사유로 계좌 변경을 할 수 없는 경우, 아래의 오류처리를 누르시기 바랍니다.</li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="inforbox">
            <ul>
              <li>수납 고객정보에 동일한 납부자번호가 없습니다. 아래의 오류처리를 누르시기 바랍니다.</li>
              <li>고객이 잘못된 납부자번호를 입력하셨을 수 있으니 필요 시 해지 관련 내용을 확인하시기 바랍니다.</li>
            </ul>
          </div>
        );
      }
    default:
      if (props.file.customerUniqueKey || (props.receipt && props.receipt.uniqueKey)) {
        return (
          <div className="inforbox ">
            <ul>
              <li>수신받은 고객정보에 동일한 납부자번호로 등록된 내역이 있습니다. 출금계좌 등록(금융기관 접수분) 내용을 반영하시려면 아래의 수신내용반영 승인을 누르시기 바랍니다.</li>
              <li>금융기관 접수분 고객을 등록하지 않으시려면 아래의 오류처리를 누르시기 바랍니다.</li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="inforbox">
            <ul>
              <li>아래 고객을 등록하시려면 하단의 신규등록을 누르시고 고객정보 및 수납정보를 추가로 입력하시기 바랍니다.</li>
              <li>아래 고객을 등록하지 않으시려면 하단의 오류처리를 누르시기 바랍니다.</li>
            </ul>
          </div>
        );
      }
  }
};

const ButtonForm = (props) => {
  switch (props.receiptType) {
    case "계좌해지":
    case "계좌(변경)해지":
      if (props.file.customerUniqueKey || (props.receipt && props.receipt.uniqueKey)) {
        return (
          <div className="table-bottom-area">
            <button className="btn-m" onClick={(e) => props.fnGoReceiptCustomerUpdate(e)}>
              접수내용 반영 승인
            </button>
            <button className="btn-m" data-testid="error-button" onClick={(e) => props.fnGoReceiptCustomerError(e)}>
              오류처리
            </button>
          </div>
        );
      } else {
        return (
          <div className="table-bottom-area">
            <button className="btn-m" data-testid="error-button" onClick={(e) => props.fnGoReceiptCustomerError(e)}>
              오류처리
            </button>
          </div>
        );
      }
    default:
      if (props.file.customerUniqueKey || (props.receipt && props.receipt.uniqueKey)) {
        return (
          <div className="table-bottom-area">
            <button className="btn-m" onClick={(e) => props.fnGoReceiptCustomerUpdate(e)}>
              접수내용 반영 승인
            </button>
            <button className="btn-m" data-testid="error-button" onClick={(e) => props.fnGoReceiptCustomerError(e)}>
              오류처리
            </button>
          </div>
        );
      } else {
        return (
          <div className="table-bottom-area">
            <button className="btn-m" onClick={(e) => props.fnGoReceiptCustomerCreate(e)}>
              신규등록
            </button>
            <button className="btn-m" data-testid="error-button" onClick={(e) => props.fnGoReceiptCustomerError(e)}>
              오류처리
            </button>
          </div>
        );
      }
  }
};

/*
 * @desc  메인 컴포넌트
 */
function RegistrationDetail({ open, setOpen, file }) {
  let history = useHistory();
  const defaultReceiptCustomer = {
    account: {
      accountBankCode: file.financialInstituteCode,
      accountNo: file.accountNo,
      accountRegistrationStatus: file.accountRegistrationStatus,
      contractUniqueKey: "",
      depositorIdentificationNo: file.depositorIdentificationNumber,
      depositorName: file.depositorName,
      depositorTelephone: file.depositorTelephone,
      evidenceFileName: "",
      evidenceFileType: "",
    },
    askStatus: "",
    bankbookContents: "",
    bankbookPostfix: "",
    bankbookPostfixType: "",
    capital: {
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
      capitalName: file.capitalName,
      capitalType: "",
    },
    contractPauseResumeY4mm: null,
    contractStatus: "",
    currentNumberOfPayment: null,
    customer: {
      cashbillIdentificationNo: "",
      createdDate: "",
      customerAddress: "",
      customerAddressDetail: "",
      customerDetailInfo: {},
      customerEmail: "",
      customerFax: "",
      customerGroup: {},
      customerMobile: file.customerMobile || "",
      customerName: file.customerName || "",
      customerType: file.customerType || "INDIVIDUAL",
      customerZipCode: "",
      identificationNo: file.customerIdentification,
      remark: "",
      roadAddressYn: true,
      staff: {},
    },
    expectedApplyY4mm: null,
    payAmount: "",
    payAmountType: "VARIABLE",
    payEndY4mm: "",
    payPeriod: "VARIABLE",
    paySpecifiedDay: "",
    payStartY4mm: "",
    payerNo: file.payerNo,
    sendForAskingWithdraw: false,
    sendForFailureOnWithdraw: false,
    sendForSuccessOnWithdraw: false,
    totalNumberOfPayment: "",
    transactionMethod: "CMS_WITHDRAW",
    withdrawCriteria: "",
  };
  // 해당하는 납부자번호로 현재 DB에 내용이 존재하는지를 체크하기 위한 object
  const [receiptCustomer, setReceiptCustomer] = useState(defaultReceiptCustomer);

  useEffect(() => {
    //수납정보 가져올 수 있는 API 호출
    const axiosRegistrations = () => {
      return new Promise((resolve) => {
        CM.cfnAxios(`api/customer/receipt/account/financial-institute/registrations/${file.fileUniqueKey}/${file.askAccountUniqueKey}`, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      //수납정보
      const resultCustomerData = await axiosRegistrations();

      setReceiptCustomer(resultCustomerData);
    }

    if (open) {
      startAxios();
    }
  }, [open, file.fileUniqueKey, file.askAccountUniqueKey, file.receiptType]);

  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  //수신내용반영 승인
  const fnGoReceiptCustomerUpdate = (e) => {
    e.preventDefault();
    let url = "";
    let method = "";

    CM.cfnConfirm("수신내용으로 반영하시겠습니까?", () => {
      if (file.askedContents === "UNREGISTRATION") {
        url = `api/customer/receipt/account/financial-institute/registrations/${file.fileUniqueKey}/${file.askAccountUniqueKey}`;
        method = "delete";
      } else {
        url = `api/customer/receipt/account/financial-institute/registrations/${file.fileUniqueKey}/${file.askAccountUniqueKey}/${file.depositorName}`;
        method = "put";
      }
      CM.cfnAxios(url, method, "", fnSubmitCallback);
    });
  };

  //신규등록
  const fnGoReceiptCustomerCreate = (e) => {
    e.preventDefault();

    history.push("/customer/receiptCustomerCreate", {
      receiptCustomer: {
        ...defaultReceiptCustomer,
        customer: {
          ...defaultReceiptCustomer.customer,
          customerName: "",
          identificationNo: file.depositorIdentificationNumber,
          customerType: file.depositorIdentificationNumber && file.depositorIdentificationNumber.replace(/-/gi, "").length === 10 ? "BUSINESSER" : "INDIVIDUAL",
        },
        account: {
          ...defaultReceiptCustomer.account,
          depositorName: "",
          accountBankCode: file.financialInstituteCode ? file.financialInstituteCode.substring(0, 3) : null,
          financialInstituteName: file.financialInstituteName,
        },
      },
      fileUniqueKey: file.fileUniqueKey,
      askAccountUniqueKey: file.askAccountUniqueKey,
    });

    return;
  };

  //오류처리
  const fnGoReceiptCustomerError = (e) => {
    e.preventDefault();

    CM.cfnConfirm("오류처리 하시겠습니까?", () => {
      const url = `api/customer/receipt/account/financial-institute/registrations/refused/${file.fileUniqueKey}/${file.askAccountUniqueKey}`;
      CM.cfnAxios(url, "delete", "", fnSubmitCallback);
    });
  };

  /*
   * @desc  오류처리 콜백함수
   */
  const fnSubmitCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      // window.alert(objStatus.statusText);
      return;
    }
    CM.cfnAlert("정상 처리 되었습니다.", () => {
      handleClose();
    });
  };

  const [financialInstitutes, setFinancialInstitutes] = React.useState([]); //금융기관 자동완성 기능을 위한 state
  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const url = "api/gadget/financial-institutes";
      await CM.cfnAxios(url, "get", "", fnSetFinancialInstitutes);
    };

    /*
     * @desc    금융기관 목록 조회 Request Callback
     */
    const fnSetFinancialInstitutes = (objStatus, objData) => {
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        // window.alert(objStatus.statusText);
        return false;
      }

      setFinancialInstitutes(objData);
    };
    if (CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();
  }, [financialInstitutes, setFinancialInstitutes]);

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {" "}
            </Button>
          </div>
          <h3>출금 계좌 등록/해지(금융기관 접수분)</h3>
          <ContentTextForm file={file} modalType={file.receiptType} receipt={receiptCustomer} />
          <RegistrationForm file={file} receipt={receiptCustomer} financialInstitutes={financialInstitutes} />
          <ButtonForm
            fnGoReceiptCustomerCreate={fnGoReceiptCustomerCreate}
            fnGoReceiptCustomerUpdate={fnGoReceiptCustomerUpdate}
            fnGoReceiptCustomerError={fnGoReceiptCustomerError}
            file={file}
            receipt={receiptCustomer}
          />
        </div>
      </div>
    </Modal>
  );
}

export default RegistrationDetail;
