export const getSearchOption = (paramName) => {
  let searchOptionObject = [];
  switch (paramName) {
    case "searchProperty":
      searchOptionObject = [
        { label: "고객이름", value: "CUSTOMER_NAME" },
        { label: "생년월일(사업자번호)", value: "IDENTIFICATION_NO" },
        { label: "계좌번호", value: "ACCOUNT_NO" },
      ];
      break;
    case "transactionMethod":
      searchOptionObject = [
        { label: "전체", value: "" },
        { label: "CMS", value: "CMS_DEPOSIT" },
        { label: "기타", value: "ETC" },
      ];
      break;
    default:
      break;
  }
  return searchOptionObject;
};

export const getDailyStatisticsSelectBoxOption = (paramName) => {
  let searchOptionObject = [];
  switch (paramName) {
    case "searchProperty":
      searchOptionObject = [
        { label: "고객이름", value: "CUSTOMER_NAME" },
        { label: "생년월일(사업자번호)", value: "IDENTIFICATION_NO" },
        { label: "계좌번호", value: "ACCOUNT_NO" },
      ];
      break;
    default:
      break;
  }
  return searchOptionObject;
};

export const getApiAvailableLabel = (value) => {
  let rtnLabel;
  switch (value) {
    case "CUSTOMER_NAME":
      rtnLabel = "고객이름";
      break;
    case "IDENTIFICATION_NO":
      rtnLabel = "고객식별번호";
      break;
    case "PAID":
      rtnLabel = "지급완료";
      break;
    case "PARTIALLY_PAID":
      rtnLabel = "부분지급";
      break;
    case "UNPAID":
      rtnLabel = "미지급";
      break;
    case "DIRECTLY_PAID":
      rtnLabel = "직접지급";
      break;
    case "REFUNDED":
      rtnLabel = "환입";
      break;
    case "VARIABLE":
      rtnLabel = "비정액";
      break;
    case "FIXED":
      rtnLabel = "정액";
      break;
    case "TEMPORARY_ADDED":
      rtnLabel = "추가분";
      break;
    case "CMS_DEPOSIT":
      rtnLabel = "CMS";
      break;
    case "ETC":
      rtnLabel = "기타";
      break;
    default:
      rtnLabel = "전체";
      break;
  }
  return rtnLabel;
};
