import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Table, TableBody, TableRow, TableCell, NativeSelect, FormControl, FormControlLabel, Input, Radio, RadioGroup, TextField } from "@material-ui/core";
import { toJS } from "mobx";
import { BankAutoComplete, TooltipForm } from "../../template/ComponentForm";
import BusinessInfoModal from "./BusinessInfoModal";
import * as Common from "../../common/Common";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";

const reaskUnpaidLimitArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24];
const withdrawCriteriaArr = [
  { value: "ABOVE_FEE", option: "수수료 이상금액" },
  { value: "ENTIRE_PRICE", option: "전액 출금" },
  { value: "ABOVE_1000", option: "1000원 이상금액" },
  { value: "ABOVE_2000", option: "2000원 이상금액" },
  { value: "ABOVE_3000", option: "3000원 이상금액" },
  { value: "ABOVE_4000", option: "4000원 이상금액" },
  { value: "ABOVE_5000", option: "5000원 이상금액" },
];

const BusinessInfo = ({ instituteStore, props }) => {
  const store = instituteStore;
  const tabIndex = props.tabIndex;
  /*
   * @desc 빌링원플러스 업무 정보 및 설정 영역 default 데이터
   */
  const [defaultValues, setDefaultValue] = useState({
    autoGeneratePayerNoSize: 0,
    autoGeneratePayerNoYn: false,
    bankbookContents: {
      bankbookContents: [],
    },
    billingAccount: {
      accountApplyDate: new Date(),
      accountBranchCode: "",
      accountNo: "",
      depositorIdentificationNo: "",
      depositorName: "",
      intgtLoginId: "",
    },
    cmsBusiness: {
      cmsInstCode: 0,
      evidenceSubmissionType: "",
      limitAmountForEachWithdrawal: 0,
      limitAmountForMonthlyWithdrawal: 0,
      limitCountForMonthlyWithdrawal: 0,
      maxLimitAmountForMonthlyWithdrawal: 0,
      payerNoSystems: [],
      receiptResultTransferDate: "",
      status: "",
      useRealtimeAccountRegistrationYn: true,
      useRealtimeDepositorNameInquiryYn: true,
      wayToRegisterAccount: "",
    },
    cmsInstCode: 0,
    cmsService: [],
    delayRate: {
      applyY4mm: 0,
      createdDatetime: 0,
      delayCharge: 0,
      delayChargingType: "NONE",
    },
    eb21Account: {
      accountBranchCode: "",
      accountNo: "",
      transactionType: "",
    },
    eb31Account: {
      accountBranchCode: "",
      accountNo: "",
      transactionType: "",
    },
    ec21Account: {
      accountBranchCode: "",
      accountNo: "",
      transactionType: "",
    },
    intgtLoginId: "",
    maxCustomerCount: "100",
    reaskUnpaidLimit: 0,
    status: "",
    virtualAccountNo: "",
    withdrawCriteria: "",
  });

  /*
  @desc 빌링원플러스 업무 정보 및 설정 영역 내 요소와 관련된 state
  */
  const [values, setValues] = useState(defaultValues);
  const [checkData, setCheckData] = useState({
    unpaidPeriod: "value1",
    delayRate: {
      applyY4mm: "",
      fixedRateValue: "",
      fixedAmountValue: "",
    },
  });

  //선택된 계좌정보 저장
  const [searchValue, setSearchValue] = React.useState({
    accountNo: "",
    depositorIdentification: "",
    financialInstituteCode: "",
    fncInsNm: "",
  });
  useEffect(() => {
    /*
     * @desc 페이지 렌더 콜백함수
     */
    const fnGetInstituBusinessCallback = async (objStatus, objData) => {
      // 실패시
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        return;
      }

      setValues(objData);
      setDefaultValue(objData);
      if (!objStatus.exists) {
        store.setBusinessInfo(objData);
      }
      setCheckData({
        unpaidPeriod: objData.reaskUnpaidLimit === 0 ? "value1" : "value2",
        delayRate: {
          fixedRateValue: objData.delayRate.delayChargingType === "FIXED_RATE" ? objData.delayRate.delayCharge : "",
          fixedAmountValue: objData.delayRate.delayChargingType === "FIXED_PRICE" ? CM.cfnAddCommaOnChange(objData.delayRate.delayCharge) : "",
        },
        applyY4mm: objData.delayRate.applyY4mm, //default 년월
        month: "current",
        delayChargingType: objData.delayRate.delayChargingType, //default 부과방식
      });

      let financialInstituteCode = objData.billingAccount.accountBranchCode.substring(0, 3);
      const financialInstituteList = await store.axiosFinancialInstitutes();
      const financialInstituteIndex = financialInstituteList.findIndex((value, index) => {
        if (value.fncInsCd === financialInstituteCode) return index;
        return null;
      });
      if (financialInstituteIndex !== -1) {
        financialInstituteCode = financialInstituteList[financialInstituteIndex].repFncInsCd;
      }
      setSearchValue((value) => ({
        ...value,
        financialInstituteCode,
      }));
    };

    if (tabIndex === 0) {
      const storeBusinessInfo = CM.cfnCopyObject(toJS(store.businessInfo));
      if (CM.cfnIsEmpty(storeBusinessInfo)) {
        store.getBusinessInfo(fnGetInstituBusinessCallback);
      } else {
        fnGetInstituBusinessCallback({ status: 200, exists: true }, storeBusinessInfo);
      }
    }
  }, [store, tabIndex]);

  const [open, setOpen] = useState(false);
  /*
   * @desc 서비스 해지 value
   */
  const [serviceTermination, setServiceTermination] = useState(store.serviceTerminationYn);

  /*
   * @desc 서비스 해지 submit함수
   */
  const fnServiceTerminationSubmit = (object) => {
    const url = `/api/institute/status`;
    CM.cfnAxios(url, "put", object, fnServiceTerminationCallback);
  };
  /*
   * @desc 서비스 해지 콜백함수
   */
  const fnServiceTerminationCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      if (objStatus.status === 409) {
        setOpen(false);
      }
      return;
    }
    CM.cfnAlert(objData);
    setOpen(false);
    setServiceTermination("Y");
    store.setServiceTerminationYn("Y");
  };

  /*
   * @desc 서비스해지 모달 핸들러
   */
  const handleClickModal = () => {
    setOpen(true);
  };

  /*
   * @desc 계좌 변경 적용 희망일자 핸들러
   */
  const handleAccountApplyDate = (date) => {
    setValues((values) => ({
      ...values,
      billingAccount: {
        ...values.billingAccount,
        accountApplyDate: date,
      },
    }));
  };

  /*
   * @desc 빌링원플러스 업무 정보 및 설정 영역 내 요소 수정 이벤트 핸들러
   */
  const handleChangeSettings = (name, name1, name2) => (event) => {
    if (name !== "delayChargingType") {
      if (name === "autoGeneratePayerNoYn") {
        setValues({
          ...values,
          [name]: Boolean(event.target.value === "true"),
        });
      } else if (name1 !== undefined) {
        setValues({
          ...values,
          [name1]: {
            ...values[name1],
            [name]: CM.cfnReplaceSymbol(event.target.value),
          },
        });
        if (name2 !== undefined) {
          setCheckData({
            ...checkData,
            [name1]: {
              ...checkData[name1],
              [name2]: CM.cfnAddCommaOnChange(event.target.value.replace(/[^0-9]/g, "")),
            },
          });
          setValues({
            ...values,
            [name1]: {
              ...values[name1],
              [name]: CM.cfnReplaceSymbol(event.target.value),
            },
          });
        }
      } else if (name === "applyY4mm") {
        let date;
        if (values.delayRate.delayChargingType === defaultValues.delayRate.delayChargingType && values.delayRate.delayCharge === defaultValues.delayRate.delayCharge) {
          date = checkData.applyY4mm;
        } else {
          date = fnChangeMonth(event.target.value);
        }
        setValues({
          ...values,
          delayRate: {
            ...values.delayRate,
            applyY4mm: date,
          },
        });
        setCheckData({
          ...checkData,
          month: event.target.value,
        });
      } else if (name === "withdrawCriteria") {
        setValues({
          ...values,
          [name]: event.target.value,
        });
      } else {
        setValues({
          ...values,
          [name]: Number(event.target.value.replace(/[^0-9]/g, "")),
        });
      }
    } else {
      let date = fnChangeMonth("current");
      switch (event.target.value) {
        case "NONE":
          setValues({
            ...values,
            delayRate: {
              ...values.delayRate,
              applyY4mm: date,
              delayCharge: 0,
              [name]: event.target.value,
            },
          });
          setCheckData({
            ...checkData,
            month: "current",
            delayRate: {
              fixedRateValue: "",
              fixedAmountValue: "",
            },
          });
          break;
        case "FIXED_RATE":
          setValues({
            ...values,
            delayRate: {
              ...values.delayRate,
              applyY4mm: date,
              [name]: event.target.value,
            },
          });
          setCheckData({
            ...checkData,
            month: "current",
            delayRate: {
              ...checkData.delayRate,
              fixedAmountValue: "",
            },
          });
          break;
        case "FIXED_PRICE":
          setValues({
            ...values,
            delayRate: {
              ...values.delayRate,
              applyY4mm: date,
              [name]: event.target.value,
            },
          });
          setCheckData({
            ...checkData,
            month: "current",
            delayRate: {
              ...checkData.delayRate,
              fixedRateValue: "",
            },
          });
          break;
        default:
          break;
      }
    }
  };

  /*
   * @desc 미납금 연체료 부과 방식 > month 게싼 함수
   */
  const fnChangeMonth = (value) => {
    let obj;
    let date = new Date();
    if (value === "current") {
      obj = `${date.getFullYear().toString()}${(date.getMonth() + 1).toString()[1] ? date.getMonth() + 1 : 0 + (date.getMonth() + 1).toString()}`;
    } else {
      date.setMonth(date.getMonth() + 1);
      obj = `${date.getFullYear()}${(date.getMonth() + 1).toString()[1] ? date.getMonth() + 1 : 0 + (date.getMonth() + 1).toString()}`;
    }
    return obj;
  };

  /*
   * @desc 미납금 청구기간 radio버튼 제어
   */
  const handleChangeCheckData = (name) => (event) => {
    setCheckData({
      ...checkData,
      [name]: event.target.value,
    });
    if (event.target.value === "value1") {
      setValues({
        ...values,
        reaskUnpaidLimit: 0,
      });
    }
  };

  const fnSubmit = async () => {
    //submit시 서버에 전달 값이 저장 된 object
    const sendData = CM.cfnCopyObject(values);
    const financialInstituteList = await store.axiosFinancialInstitutes();

    return new Promise((resolve) => {
      // 이용요금 납부계좌 금융기관코드 변경 체크. 대표코드가 같으면 원래것으로 돌린다.
      if (
        defaultValues.billingAccount &&
        defaultValues.billingAccount.accountBranchCode &&
        !defaultValues.billingAccount.accountBranchCode.startsWith(sendData.billingAccount.accountBranchCode.substring(0, 3))
      ) {
        let financialInstituteCode =
          defaultValues.billingAccount.accountBranchCode.length < 3 ? defaultValues.billingAccount.accountBranchCode : defaultValues.billingAccount.accountBranchCode.substring(0, 3);
        const financialInstituteIndex = financialInstituteList.findIndex((value, index) => {
          if (value.fncInsCd === financialInstituteCode) return index;
          return null;
        });
        if (financialInstituteIndex !== -1) {
          financialInstituteCode = financialInstituteList[financialInstituteIndex].repFncInsCd; // 대표코드 찾기
        }
        if (financialInstituteCode === sendData.billingAccount.accountBranchCode.substring(0, 3)) {
          // 대표코드가 같으면, 원래 저장되어있던 값으로 변경(변화 없도록 유도)
          sendData.billingAccount.accountBranchCode = defaultValues.billingAccount.accountBranchCode;
        }
      }

      //미납금 연체료 부과 방식 유효성 체크
      if (Object.is(defaultValues.delayRate, sendData.delayRate)) {
        sendData.delayRate = null;
      } else {
        sendData.delayRate.applyY4mm = fnChangeMonth(checkData.month);
        sendData.delayRate.createdDatetime = CM.cfnConvertDateToString(new Date());
      }

      //계좌 변경 적용 희망일자 방식 유효성 체크
      if (
        defaultValues.billingAccount.accountBranchCode.substring(0, 3) !== sendData.billingAccount.accountBranchCode.substring(0, 3) ||
        defaultValues.billingAccount.accountNo !== sendData.billingAccount.accountNo ||
        defaultValues.billingAccount.depositorName !== sendData.billingAccount.depositorName ||
        defaultValues.billingAccount.depositorIdentificationNo !== sendData.billingAccount.depositorIdentificationNo
      ) {
        if (sendData.billingAccount.accountApplyDate === null || sendData.billingAccount.accountApplyDate === "") {
          CM.cfnAlert("계좌 변경 적용 희망일자를 선택해 주세요.", () => {
            return false;
          });
        } else {
          sendData.billingAccount.accountApplyDate = CM.cfnConvertDateToString(values.billingAccount.accountApplyDate);
          resolve(sendData);
        }
      } else {
        sendData.billingAccount.accountApplyDate = null;
        resolve(sendData);
      }
    });
  };

  /*
   * @desc 설정변경 버튼 클릭 이벤트 핸들러
   */
  const submitChanges = async (event) => {
    if (fnCheckValidation()) {
      CM.cfnConfirm("변경하시겠습니까?", async () => {
        const result = await fnSubmit();
        fnSubmitUrl(result);
      });
    }
  };

  /*
   * @desc    설정변경 시 필요한 validation check 함수
   */
  const fnCheckValidation = () => {
    let targetDate = values.maxCustomerCount;

    if (targetDate % 100 !== 0) {
      Common.cfnAlert("최대 등록 고객수는 100명 단위로 가능합니다.");
      return false;
    }
    return true;
  };

  const fnSubmitUrl = (sendData) => {
    const url = `/api/institute/business`;
    CM.cfnAxios(
      url,
      "put",
      sendData,
      fnSumitCallback,
      (error) => {
        if (error.response.status === 304) {
          CM.cfnAlert("변경된 내용이 없습니다.", () => {});
        } else {
          let message = "업무정보를 변경하지 못했습니다.";
          if (typeof error.response.data === "string") message = error.response.data;
          else if (typeof error.response.data === "object") message = error.response.data.message;
          CM.cfnAlert(message || error.response);
        }
      },
      false,
      true
    );
  };
  const fnSumitCallback = (objStatus, objData) => {
    setValues((values) => ({
      ...values,
      billingAccount: {
        ...values.billingAccount,
        accountApplyDate: null,
      },
    }));
    CM.cfnAlert(objData, () => {
      store.getBusinessInfo(fnGetBusinssInfo);
    });
  };

  /*
   * @desc    업무정보 조회 후 callback 함수
   */
  const fnGetBusinssInfo = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      // window.alert(objStatus.statusText);
      return false;
    }
    store.setBusinessInfo(data);
  };

  //필요 state 선언
  const [financialInstitutes, setFinancialInstitutes] = React.useState([]); //금융기관 자동완성 기능을 위한 state

  useEffect(() => {
    CM.cfnIsEmpty(financialInstitutes) &&
      (async () => {
        let list = await store.axiosFinancialRepresentativeInstitutes();
        setFinancialInstitutes(list);
      })();
  }, [financialInstitutes, store]);

  useEffect(() => {
    //금융기관명 자동완성 기능 사용 시 paymentInfo에 값 세팅
    setValues((value) => ({
      ...value,
      billingAccount: {
        ...value.billingAccount,
        accountBranchCode: `${searchValue.financialInstituteCode}${CM.cfnIsNotEmpty(searchValue.financialInstituteCode) ? "0000" : ""}`,
      },
    }));
  }, [searchValue.fncInsNm, searchValue.financialInstituteCode]);

  const tomorrowDay = new Date();
  tomorrowDay.setDate(new Date().getDate() + 1);

  return (
    <div>
      <div>
        <div className="inforbox">
          <ul>
            <li>이용요금 납부계좌 변경은 금융결제원 담당자 승인 후 반영됩니다.</li>
          </ul>
        </div>
      </div>
      <Table>
        {CM.cfnCompColGroup(["20%", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">
              최대 등록 고객수
              <TooltipForm
                contents="최대 등록 고객 수는 100명 단위로 변경할 수 있으며 변경 시 즉시 반영됩니다. 
              월별 요금은 최대 등록 설정되었던 고객수를 기준으로 부과됩니다. 
              (예로 최대 등록 고객 수가 300명인 경우, 추가 200명에 대해 월별 4,000원 추가 부과)"
              />
            </TableCell>
            <TableCell className="td">
              <div style={{ display: "flex" }}>
                <TextField
                  name="maxCustomerCount"
                  value={CM.cfnAddCommaOnChange(values.maxCustomerCount !== "" ? values.maxCustomerCount : "100")}
                  className="w100 tc"
                  // disabled={values.delayRate.delayChargingType === 'FIXED_PRICE' ? false : true}
                  onChange={handleChangeSettings("maxCustomerCount")}
                  inputProps={{ "data-testid": "customer-count" }}
                />
                <div className="under">명(기본 100명, 추가요금 2,000원/100명당)</div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              미납금 청구기간
              <TooltipForm contents="고객이 미납한 건에 대해 최대 24개월까지 청구대상에 포함할 수 있으며, 변경 즉시 반영됩니다." />
            </TableCell>
            <TableCell className="td">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={checkData.unpaidPeriod === "value1" ? "value1" : "value2"}
                  onChange={handleChangeCheckData("unpaidPeriod")}
                  data-testid="unpaid-period"
                  row>
                  <FormControlLabel value="value1" control={<Radio color="primary" />} label="미납금 청구 안함" />
                  <FormControlLabel value="value2" control={<Radio color="primary" />} />
                  <NativeSelect
                    className="w70"
                    value={values.reaskUnpaidLimit}
                    onChange={handleChangeSettings("reaskUnpaidLimit")}
                    input={<Input name="reaskUnpaidLimit" />}
                    name="reaskUnpaidLimit"
                    disabled={checkData.unpaidPeriod === "value2" ? false : true}>
                    <option key="0" value="0">
                      선택
                    </option>
                    {reaskUnpaidLimitArr.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                  </NativeSelect>
                  <div className="under">개월 이하 미납금만 미납분 청구 시 포함</div>
                </RadioGroup>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              미납금 연체료 부과 방식
              <TooltipForm
                contents="미납금에 대한 연체료는 월단위로만 변경가능하며, 당월 또는 익월부터 적용할 수 있습니다. 
              이미 적용된 이전 기간의 연체료는 변경할 수 없습니다."
              />
            </TableCell>
            <TableCell className="td">
              <FormControl>
                <RadioGroup aria-label="position" name="position" value={values.delayRate.delayChargingType} onChange={handleChangeSettings("delayChargingType")} data-testid="latefee-charging" row>
                  <FormControlLabel value="NONE" control={<Radio color="primary" />} label="미부과" />
                  <FormControlLabel value="FIXED_RATE" control={<Radio color="primary" />} className="delay-radio" />
                  <span className="radio-label-l">정율제 (년 </span>
                  <TextField
                    name="fixedRateValue"
                    className="w100"
                    value={checkData.delayRate.fixedRateValue !== "" ? checkData.delayRate.fixedRateValue : ""}
                    disabled={values.delayRate.delayChargingType === "FIXED_RATE" ? false : true}
                    onChange={handleChangeSettings("delayCharge", "delayRate", "fixedRateValue")}
                  />
                  <span className="radio-label-r">%)</span>
                  <FormControlLabel value="FIXED_PRICE" control={<Radio color="primary" />} className="delay-radio" />
                  <span className="radio-label-l">정액제 (월 </span>
                  <TextField
                    name="fixedAmountValue"
                    value={checkData.delayRate.fixedAmountValue !== "" ? checkData.delayRate.fixedAmountValue : ""}
                    className="w100 tc"
                    disabled={values.delayRate.delayChargingType === "FIXED_PRICE" ? false : true}
                    onChange={handleChangeSettings("delayCharge", "delayRate", "fixedAmountValue")}
                  />
                  <span className="radio-label-r">원)</span>
                  <NativeSelect className="" value={checkData.month} onChange={handleChangeSettings("applyY4mm")} input={<Input name="applyY4mm" />} name="applyY4mm">
                    <option value="current">이번달부터 적용</option>
                    <option value="next">다음달부터 적용</option>
                  </NativeSelect>
                </RadioGroup>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              고객계좌 출금 기준 금액
              <TooltipForm contents="출금의뢰 금액 대비 고객 계좌에 잔액이 부족한 경우, 고객계좌에서 출금할 수 있는 최저 기준 금액입니다." />
            </TableCell>
            <TableCell className="td">
              <FormControl component="fieldset">
                <NativeSelect className="aaaa" value={values.withdrawCriteria} onChange={handleChangeSettings("withdrawCriteria")} input={<Input name="withdrawCriteria" />} name="withdrawCriteria">
                  {withdrawCriteriaArr.map((data) => (
                    <option key={data.value} value={data.value}>
                      {data.option}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              납부자번호 채번 방식
              <TooltipForm contents="자동채번은 납부자번호체계가 숫자인 경우에만 가능하며 빌링원플러스에서 자동으로 부여됩니다. 직접입력은 이용기관에서 직접 고객별로 납부자번호를 부여해야 합니다. " />
            </TableCell>
            <TableCell className="td">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={values.autoGeneratePayerNoYn ? "true" : "false"}
                  onChange={handleChangeSettings("autoGeneratePayerNoYn")}
                  row
                  data-testid="numbering-method">
                  <FormControlLabel value="true" control={<Radio color="primary" />} label="자동채번" />
                  <NativeSelect
                    className=""
                    value={values.autoGeneratePayerNoSize}
                    onChange={handleChangeSettings("autoGeneratePayerNoSize")}
                    input={<Input name="autoGeneratePayerNoSize" />}
                    name="autoGeneratePayerNoSize"
                    disabled={values.autoGeneratePayerNoYn ? false : true}
                    inputProps={{ "data-testid": "numbering-digit" }}>
                    <option value="">선택</option>
                    {values.cmsBusiness.payerNoSystems.map((vaule, index) =>
                      vaule.payerNoType === "N" ? (
                        <option value={vaule.payerNoSize} key={index}>
                          숫자 {vaule.payerNoSize}자리
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </NativeSelect>
                  <FormControlLabel value="false" control={<Radio color="primary" />} label="직접입력" />
                </RadioGroup>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">이용요금 납부계좌</TableCell>
            <TableCell className="td">
              <span className="label-l">금융기관</span>
              <BankAutoComplete list={financialInstitutes} state={searchValue} setState={setSearchValue} className="w170" />
              <span className="label-l">계좌번호</span>
              <Input
                name="accountNo"
                className="w180"
                value={values.billingAccount.accountNo}
                onChange={handleChangeSettings("accountNo", "billingAccount")}
                //defaultValue="000-0000-0000"
                //inputProps={{"aria-label": "description"}}
              />
              <br />
              <br />
              <span className="label-l">예금주</span>
              <Input
                name="depositorName"
                className="w180"
                value={values.billingAccount.depositorName}
                onChange={handleChangeSettings("depositorName", "billingAccount")}
                //defaultValue=""
                //inputProps={{"aria-label": "description"}}
              />
              <span className="label-l">생년월일(사업자번호)</span>
              <Input
                name="depositorIdentificationNo"
                className="w180"
                value={CM.cfnIdentificationNoFormatOnChange(values.billingAccount.depositorIdentificationNo)}
                onChange={handleChangeSettings("depositorIdentificationNo", "billingAccount")}
                //defaultValue=""
                //inputProps={{"aria-label": "description"}}
              />
              <span className="label-l">계좌 변경 적용 희망일자</span>
              <CF.DatePickerForm
                className="w160"
                value={values.billingAccount.accountApplyDate}
                handleChange={handleAccountApplyDate}
                format="yyyy.MM.dd"
                customProps={{ placeholder: "YYYY.MM.DD", minDate: tomorrowDay }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th h73">이용요금 납부 무통장 가상계좌</TableCell>
            <TableCell className="td h73">{values.virtualAccountNo ? "SC은행 " + values.virtualAccountNo : ""}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area">
        <div className="btn-l table-bottom-button" data-testid="change-setting" onClick={submitChanges}>
          설정변경
        </div>
      </div>
      <h4 style={{ alignItems: "center", justifyContent: "flex-start", display: "flex" }}>
        서비스 해지
        {serviceTermination === "Y" ? (
          ""
        ) : (
          <div className="btn-m" data-testid="open-unregister" onClick={(event) => handleClickModal()} style={{ margin: "0 5px" }}>
            빌링원플러스 서비스 해지 신청
          </div>
        )}
      </h4>
      <div>
        <div className="inforbox">
          <ul>
            <li>
              CMS 서비스를 해지 하시려면{" "}
              <a href="https://www.cmsedi.or.kr/cms" target="_blank" rel="noopener noreferrer" className="link">
                CMS홈페이지
              </a>
              에서 CMS해지 신청서를 작성하여 제출하시거나 고객센터(1577-5500)로 문의하시기 바랍니다.
            </li>
          </ul>
        </div>
      </div>

      <BusinessInfoModal open={open} setOpen={setOpen} fnServiceTerminationSubmit={fnServiceTerminationSubmit} />
    </div>
  );
};
export default inject((rootStore, props) => ({
  instituteStore: rootStore.instituteStore,
  props: props,
}))(observer(BusinessInfo));
