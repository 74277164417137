import { Checkbox, FormControlLabel } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import * as CmsData from "./CmsPaymentData";
import * as Form from "./CmsPaymentRequestForm";
import TargetAddModal from "./CmsPaymentRequestTargetAddModal";
import TargetModifyModal from "./CmsPaymentRequestTargetModifyModal";

/*
 * @desc  메인 컴포넌트 생성
 */
const CmsReceiptRequest = ({ tabIndex, store, businessInfo }) => {
  //필요 state 선언
  const [cmsRequestStatus, setCmsRequestStatus] = useState(0); //입금청구 진행단계 {0:화면 진입시, 1:기존청구조회, 2:청구생성전, 3:청구생성후}
  const [financialInstitutes, setFinancialInstitutes] = useState(toJS(store.financialInstitutes));

  //입금청구 대상 생성 제어
  const [cmsRequestSummary, setCmsRequestSummary] = useState(CmsData.cmsDefaultSummary); //입금청구대상 생성 정보
  const [targetCheckbox, setTargetCheckbox] = useState(CmsData.defaultCheckbox); //입금청구대상 체크박스

  //목록 조회 제어
  const [searchButton, setSearchButton] = useState(true); //검색 실행 flag
  const [isTargetChanged, setIsTargetChanged] = useState(false); //입금대상 추가/수정 여부
  const [searchRequest, setSearchRequest] = useState(CmsData.requestSearchData); // table 데이터 검색 조건
  const [originalList, setOriginalList] = useState([]);
  const [cmsRequestList, setCmsRequestList] = useState([]); //table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());
  const [capitalOptions, setCapitalOptions] = useState([]); //검색 조건 내 셀렉트 옵션 제어
  const [customerGroupOptions, setCustomerGroupOptions] = useState([]); //고객구분 옵션 제어
  const [generatingRequest, setGeneratingRequest] = useState(false);
  const [saveCmsDepositSettings, setSaveCmsDepositSettings] = useState(true);
  const [targetAskUniqueKey, setTargetAskUniqueKey] = useState(null);

  //모달 제어
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);

  const excelInput = React.useRef("");

  /*
   * @desc  입금대상생성 버튼 클릭 이벤트 핸들러
   */
  const handleClickRequestGenerations = () => {
    if (CM.cfnIsEmpty(cmsRequestSummary.askTargetCriteria)) {
      CM.cfnAlert("입금청구 대상을 선택하여 주시기 바랍니다.");
      return false;
    } else {
      if (!generatingRequest) {
        setGeneratingRequest(true);
      }
    }
  };

  useEffect(() => {
    if (generatingRequest) {
      fnRequestGenerations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatingRequest]);

  /*
   * @desc  입금청구 버튼 클릭 이벤트 핸들러
   */
  const handleClickRequest = () => {
    CM.cfnConfirm("입금 청구하시겠습니까?", fnRequestCmsTarget);
  };

  /*
   * @desc  입금대상삭제 버튼 클릭 이벤트 핸들러
   */
  const handleClickRequestDelete = async () => {
    CM.cfnConfirm("정말로 삭제하시겠습니까?", fnDeleteCmsRequest);
  };

  /*
   * @desc  입금대상 추가 버튼 클릭 이벤트 핸들러
   */
  const handleTargetAddModal = () => {
    setAddModalOpen(true);
  };

  /*
   * @desc  입금내용 수정 버튼 클릭 이벤트 핸들러
   */
  const handleModifyTarget = () => {
    setModifyModalOpen(true);
  };

  /*
   * @desc  일괄수정(엑셀) 버튼 클릭 이벤트 핸들러
   */
  const handleModifyTargetBulk = () => {
    excelInput.current.click();
  };

  /*
   * @desc  입금청구대상 일괄수정 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc  입금청구대상 일괄수정 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/payment/cms/contents/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey ",
      new Blob([JSON.stringify(cmsRequestSummary.uniqueKey)], {
        type: "application/json",
      })
    );

    CM.cfnAxios(url, "post", form, fnUploadExcelCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
        () => {}
      );
    });
  };

  /*
   * @desc  입금청구대상 일괄수정 Request Callback
   */
  const fnUploadExcelCallback = (objStatus, objData) => {
    CM.cfnAlert(objData);
  };

  /*
   * @desc  엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleExcelDownload = () => {
    const search = fnMakeParameter("get");
    const url = `api/payment/cms/contents/excel?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&identificationNo=${search.identificationNo}&resultCode=${search.resultCode}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc  테이블 내 제외 버튼 클릭 이벤트 핸들러
   */
  const handleExcludeRow = (row) => {
    CM.cfnConfirm("선택한 대상을 제외하시겠습니까?", () => {
      fnExcludeRow(row);
    });
  };

  /**
   * 행 클릭 이벤트
   * @param {askDeposit} row
   */
  const handleClickedRow = (row) => {
    setTargetAskUniqueKey(row.uniqueKey);
    setModifyModalOpen(true);
  };

  /*
   * @desc CMS입금청구대상에서 제외 처리 Request
   */
  const fnExcludeRow = (row) => {
    /*
     * @desc CMS입금청구대상에서 제외 처리 Request Callback
     */
    const fnExcludeRowCallback = (objStatus, objData) => {
      if (objStatus.status !== 200) {
        row.handlingExclusion = false;
      } else {
        CM.cfnAlert("정상적으로 처리되었습니다.");
        handleSearchButtonChange(true);
        setIsTargetChanged(true);
      }
    };
    row.handlingExclusion = true;
    const url = `api/payment/cms/contents/${cmsRequestSummary.uniqueKey}/${row.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnExcludeRowCallback);
  };

  /*
   * @desc    CMS입금청구대상 요약정보 조회
   */
  const fnFetchCmsSummary = useCallback(() => {
    return new Promise((resolve) => {
      const url = "api/payment/cms/";

      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  }, []);

  /*
   * @desc CMS입금청구대상 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(
    (url) => {
      let tempObj = null;

      if (url === "get") {
        //입금청구대상 목록 조회 청구 시
        tempObj = CM.cfnCopyObject(searchRequest);
        if (tempObj.term === "customerName") {
          tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
          tempObj.identificationNo = "";
        } else if (tempObj.term === "identificationNo") {
          tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
          tempObj.customerName = "";
        }
      } else if (url === "post") {
        //입금청구대상 생성 시
        tempObj = CM.cfnCopyObject(cmsRequestSummary);
        tempObj.transactionDate = CM.cfnConvertDateToString(tempObj.transactionDate);
        tempObj.isPreviouslyUnaskedIncluded = targetCheckbox.unasked;
        tempObj.isThisTimeIncluded = targetCheckbox.thisTime;
        tempObj.isUnpaidIncluded = targetCheckbox.unpaid;
      }
      return tempObj;
    },
    [searchRequest, cmsRequestSummary, targetCheckbox.unasked, targetCheckbox.thisTime, targetCheckbox.unpaid]
  );

  /*
   * @desc    CMS입금청구대상 목록 조회 Request
   */
  const fnFetchCmsContents = useCallback(() => {
    const search = fnMakeParameter("get");
    return new Promise((resolve) => {
      let url = `api/payment/cms/contents?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&identificationNo=${search.identificationNo}&resultCode=&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  }, [fnMakeParameter]);

  /*
   * @desc    CMS입금청구대상 목록 조회 Request 호출 및 Callback
   */
  const startCmsContentsAxios = useCallback(
    async (search) => {
      const resultData = await fnFetchCmsContents();
      const resultList = resultData.content;

      if (CM.cfnIsNotEmpty(resultData)) {
        //조회 결과 response 데이터가 있을 시
        setOriginalList(resultData);
        setCmsRequestList(resultList);
        setPagination(CmsData.paginationData(resultData));
      }
    },
    [fnFetchCmsContents]
  );

  useEffect(() => {
    //체크박스 변경 시 값 가공
    setCmsRequestSummary((summary) => ({
      ...summary,
      askTargetCriteria: CmsData.convertToTargetCriteria(targetCheckbox),
    }));
  }, [setTargetCheckbox, targetCheckbox]);

  /*
   * @desc  입금대상생성 컴포넌트 내 변경 이벤트 핸들러
   */
  const handleCmsRequestInfoChange = (name) => (e) => {
    if (name === "transactionDate") {
      setCmsRequestSummary({
        ...cmsRequestSummary,
        [name]: e,
      });
    } else if (e.target.type === "radio") {
      setCmsRequestSummary({
        ...cmsRequestSummary,
        [name]: e.target.value === "true",
      });
    }
  };

  /*
   * @desc  입금대상생성 컴포넌트 내 체크박스 변경 이벤트 핸들러
   */
  const handleTargetCheckbox = (name) => (e) => {
    setTargetCheckbox({
      ...targetCheckbox,
      [name]: e.target.checked,
    });
  };

  /*
   * @desc  입금대상 목록 필터 입력란 변경 이벤트 핸들러
   */
  const handleRequestSearchChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  /*
   * @desc  입금대상 생성 Request
   */
  const fnRequestGenerations = () => {
    const searchParam = fnMakeParameter("post");
    if (saveCmsDepositSettings) {
      localStorage.setItem("CMS_DEPOSIT_ASK_TARGET_CRITERIA", CmsData.convertToTargetCriteria(targetCheckbox));
      localStorage.setItem("CMS_DEPOSIT_TARGET_INTEGRATED_YN", searchParam.targetIntegratedYn);
    }
    const url = `api/payment/cms/?depositRequestDate=${searchParam.transactionDate}&isPreviouslyUnaskedIncluded=${searchParam.isPreviouslyUnaskedIncluded}&isThisTimeIncluded=${searchParam.isThisTimeIncluded}&isUnpaidIncluded=${searchParam.isUnpaidIncluded}&targetIntegratedYn=${searchParam.targetIntegratedYn}`;
    CM.cfnAxios(url, "post", "", fnRequestGenerationsCallback, (error) => {
      setSearchButton(false);
      setGeneratingRequest(false);
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
        () => {}
      );
    });
  };

  /*
   * @desc  입금대상 생성 Request Callback
   */
  const fnRequestGenerationsCallback = async (objStatus, objData) => {
    setSearchRequest({
      ...searchRequest,
      fileUniqueKey: objData,
    });

    //정상 리턴 시 요약정보 재조회 및 입금청구대상 목록 조회하도록 state setting
    const summary = await fnFetchCmsSummary();
    await setCmsRequestSummary((element) => ({
      ...element,
      uniqueKey: summary.uniqueKey,
      // fileType: summary.fileType === "EB21",
      transactionDate: summary.transactionDate,
      askTargetCriteria: summary.askTargetCriteria,
      targetIntegratedYn: summary.targetIntegratedYn,
      fileStatus: summary.fileStatus,
      sentFileDatetime: summary.sentFileDatetime,
      numberOfAskedRecords: summary.numberOfAskedRecords,
      receivedFileDatetime: summary.receivedFileDatetime,
      totalAskingAmount: summary.totalAskingAmount,
      totalAskingNumberOfRegistration: summary.totalAskingNumberOfRegistration,
      totalAskingNumberOfUnregistration: summary.totalAskingNumberOfUnregistration,
      amountOfPartialError: summary.amountOfPartialError,
      numberOfPartialWithdraw: summary.numberOfPartialWithdraw,
      amountOfPartialWithdraw: summary.amountOfPartialWithdraw,
      numberOfFullError: summary.numberOfFullError,
      amountOfFullError: summary.amountOfFullError,
      numberOfFullWithdraw: summary.numberOfFullWithdraw,
      amountOfFullWithdraw: summary.amountOfFullWithdraw,
      numberOfRegistrationError: summary.numberOfRegistrationError,
      numberOfUnregistrationError: summary.numberOfUnregistrationError,
      numberOfExceededLimit: summary.numberOfExceededLimit,
      numberOfZeroAmount: summary.numberOfZeroAmount,
      totalAskingThisTimeAmount: summary.totalAskingThisTimeAmount,
      totalAskingPreviouslyUnaskedAmount: summary.totalAskingPreviouslyUnaskedAmount,
      totalAskingUnpaidAmount: summary.totalAskingUnpaidAmount,
      totalAskingDelayFee: summary.totalAskingDelayFee,
      totalBankFee: summary.totalBankFee,
    }));
    handleSearchButtonChange(true);
    setCmsRequestStatus(3);
    setGeneratingRequest(false);
  };

  /*
   * @desc  입금청구 수행 Request
   */
  const fnRequestCmsTarget = () => {
    const url = `api/payment/cms/${cmsRequestSummary.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnRequestCmsTargetCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
        () => {
          setSearchButton(true);
        }
      );
    });
  };

  /*
   * @desc  입금청구 수행 Request Callback
   */
  const fnRequestCmsTargetCallback = (objStatus, objData) => {
    CM.cfnAlert(objData, async () => {
      //기존 청구정보 조회하지 않도록 & 초기 정보 보여주도록 setting
      let checkboxObj = CmsData.defaultCheckbox;
      let savedAskTargetCriteria = localStorage.getItem("CMS_DEPOSIT_ASK_TARGET_CRITERIA");
      if (savedAskTargetCriteria) {
        if (savedAskTargetCriteria.indexOf("THIS_TIME") >= 0) {
          checkboxObj.thisTime = true;
        }
        if (savedAskTargetCriteria.indexOf("UNASKED") >= 0) {
          checkboxObj.unasked = true;
        }
        if (businessInfo && businessInfo.reaskUnpaidLimit > 0 && savedAskTargetCriteria.indexOf("UNPAID") >= 0) {
          checkboxObj.unpaid = true;
        }
      }
      const tempObj = CmsData.cmsDefaultSummary;

      const savedTargetIntegratedYn = localStorage.getItem("CMS_DEPOSIT_TARGET_INTEGRATED_YN");
      if (savedTargetIntegratedYn === "false" || savedTargetIntegratedYn === false) {
        tempObj.targetIntegratedYn = false;
      }

      tempObj.transactionDate = await CmsData.fnGetNextCmsPaymentDate();

      setTargetCheckbox(checkboxObj);
      setCmsRequestSummary(tempObj);
      setCmsRequestStatus(2);
    });
  };

  /*
   * @desc  입금청구 삭제 Request
   */
  const fnDeleteCmsRequest = () => {
    const url = `api/payment/cms/${cmsRequestSummary.uniqueKey}`;
    CM.cfnAxios(
      url,
      "delete",
      "",
      (objStatus, objData) => {
        CM.cfnAlert(objData, async () => {
          const tempObj = CmsData.cmsDefaultSummary;
          let checkboxObj = CmsData.defaultCheckbox;
          let savedAskTargetCriteria = localStorage.getItem("CMS_DEPOSIT_ASK_TARGET_CRITERIA");
          if (savedAskTargetCriteria) {
            if (savedAskTargetCriteria.indexOf("THIS_TIME") >= 0) {
              checkboxObj.thisTime = true;
            }
            if (savedAskTargetCriteria.indexOf("UNASKED") >= 0) {
              checkboxObj.unasked = true;
            }
            if (businessInfo && businessInfo.reaskUnpaidLimit > 0 && savedAskTargetCriteria.indexOf("UNPAID") >= 0) {
              checkboxObj.unpaid = true;
            }
          }

          if (businessInfo) {
            tempObj.askTargetCriteria = CmsData.convertToTargetCriteria(checkboxObj);
            tempObj.reaskUnpaidLimit = businessInfo.reaskUnpaidLimit > 0;
          }

          tempObj.transactionDate = await CmsData.fnGetNextCmsPaymentDate();

          const savedTargetIntegratedYn = localStorage.getItem("CMS_DEPOSIT_TARGET_INTEGRATED_YN");
          if (savedTargetIntegratedYn === "false" || savedTargetIntegratedYn === false) {
            tempObj.targetIntegratedYn = false;
          }
          setTargetCheckbox(checkboxObj);
          setCmsRequestSummary(tempObj);
          setOriginalList([]);
          setCmsRequestList([]);

          setCmsRequestStatus(2);
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
          () => {
            setSearchButton(true);
          }
        );
      }
    );
  };

  //화면 진입 시 기관업무 정보에 따라 한도 정보 세팅
  useEffect(() => {
    if (tabIndex === 0 && cmsRequestStatus === 0) {
      setCmsRequestStatus(1);

      const activeUnpaid = businessInfo && businessInfo.reaskUnpaidLimit > 0;

      const checkboxObj = { ...targetCheckbox };
      let savedAskTargetCriteria = localStorage.getItem("CMS_DEPOSIT_ASK_TARGET_CRITERIA");
      if (savedAskTargetCriteria) {
        if (savedAskTargetCriteria.indexOf("THIS_TIME") >= 0) {
          checkboxObj.thisTime = true;
        }
        if (savedAskTargetCriteria.indexOf("UNASKED") >= 0) {
          checkboxObj.unasked = true;
        }
        if (activeUnpaid && savedAskTargetCriteria.indexOf("UNPAID") >= 0) {
          checkboxObj.unpaid = true;
        }
      }
      const savedTargetIntegratedYn = localStorage.getItem("CMS_DEPOSIT_TARGET_INTEGRATED_YN");

      setTargetCheckbox(checkboxObj);

      setCmsRequestSummary((element) => ({
        ...element,
        targetIntegratedYn: savedTargetIntegratedYn !== "false" && savedTargetIntegratedYn !== false,
        maxLimitAmountForMonthlyWithdrawal: businessInfo.cmsBusiness.maxLimitAmountForMonthlyWithdrawal,
        limitAmountForMonthlyWithdrawal: businessInfo.cmsBusiness.limitAmountForMonthlyWithdrawal,
        limitAmountForEachWithdrawal: businessInfo.cmsBusiness.limitAmountForEachWithdrawal,
        askTargetCriteria: CmsData.convertToTargetCriteria(checkboxObj),
        reaskUnpaidLimit: activeUnpaid, //입금청구대상 - 미입금분 체크박스 disable 여부(reaskUnpaidLimit > 0 이면 활성화)
      }));
    }
  }, [tabIndex, cmsRequestStatus, businessInfo, targetCheckbox]);

  //화면 진입 시 현재 작업중인 입금청구대상 (요약)정보 조회
  useEffect(() => {
    /*
     * @desc  CMS입금청구대상 요약정보 Request Callback
     */
    const fnFetchCmsSummaryCallback = async (summary) => {
      if (summary && CM.cfnIsNotEmpty(summary.uniqueKey)) {
        //작업중인 정보가 있을 시 해당 정보를 세팅 후 목록 조회
        setCmsRequestStatus(3);
        setSearchRequest((element) => ({
          ...element,
          fileUniqueKey: summary.uniqueKey,
        }));
        setTargetCheckbox(CmsData.convertFromCmsAskTargetCriteria(summary.askTargetCriteria));
        await setCmsRequestSummary((element) => ({
          ...element,
          uniqueKey: summary.uniqueKey,
          //fileType: summary.fileType === "EB21",
          transactionDate: summary.transactionDate,
          askTargetCriteria: summary.askTargetCriteria,
          targetIntegratedYn: summary.targetIntegratedYn,
          fileStatus: summary.fileStatus,
          sentFileDatetime: summary.sentFileDatetime,
          numberOfAskedRecords: summary.numberOfAskedRecords,
          receivedFileDatetime: summary.receivedFileDatetime,
          totalAskingAmount: summary.totalAskingAmount,
          totalAskingNumberOfRegistration: summary.totalAskingNumberOfRegistration,
          totalAskingNumberOfUnregistration: summary.totalAskingNumberOfUnregistration,
          amountOfPartialError: summary.amountOfPartialError,
          numberOfPartialWithdraw: summary.numberOfPartialWithdraw,
          amountOfPartialWithdraw: summary.amountOfPartialWithdraw,
          numberOfFullError: summary.numberOfFullError,
          amountOfFullError: summary.amountOfFullError,
          numberOfFullWithdraw: summary.numberOfFullWithdraw,
          amountOfFullWithdraw: summary.amountOfFullWithdraw,
          numberOfRegistrationError: summary.numberOfRegistrationError,
          numberOfUnregistrationError: summary.numberOfUnregistrationError,
          numberOfExceededLimit: summary.numberOfExceededLimit,
          numberOfZeroAmount: summary.numberOfZeroAmount,
          totalAskingThisTimeAmount: summary.totalAskingThisTimeAmount,
          totalAskingPreviouslyUnaskedAmount: summary.totalAskingPreviouslyUnaskedAmount,
          totalAskingUnpaidAmount: summary.totalAskingUnpaidAmount,
          totalAskingDelayFee: summary.totalAskingDelayFee,
          totalBankFee: summary.totalBankFee,
        }));
      } else {
        //작업중인 정보 없을 시 다음 영업일 일자 구하여 계산 후 세팅
        let nextBizDate = await CmsData.fnGetNextCmsPaymentDate();
        setCmsRequestSummary((element) => ({
          ...element,
          transactionDate: CM.cfnConvertStringToDate(nextBizDate),
        }));
        setCmsRequestStatus(2);
      }
    };

    /*
     * @desc  CMS입금청구대상 요약정보 조회 Request 호출 후 callback에 파라미터 전달
     */
    const fnInitCmsRequest = async () => {
      const summary = await fnFetchCmsSummary();
      fnFetchCmsSummaryCallback(summary);
    };

    //실행영역
    if (tabIndex === 0 && cmsRequestStatus === 1 && !addModalOpen && !modifyModalOpen) fnInitCmsRequest();
  }, [tabIndex, cmsRequestStatus, fnFetchCmsSummary, addModalOpen, modifyModalOpen]);

  //화면 진입 시 금융기관 목록 조회
  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await store.axiosFinancialInstitutes();
      setFinancialInstitutes(institutesList);
    };

    if (CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();
  }, [financialInstitutes, store]);

  //화면 진입 시 자금 목록 조회
  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      const capitals = await store.axiosCapitals();
      setCapitalOptions(CmsData.fnMakeSelectOptions("capital", capitals));
    };

    //실행영역
    const storeCapital = CM.cfnCopyObject(toJS(store.capital));
    if (CM.cfnIsEmpty(storeCapital)) {
      fnFetchCapitalList();
    } else {
      setCapitalOptions(CmsData.fnMakeSelectOptions("capital", storeCapital));
    }
  }, [store]);

  //화면 진입 시 고객구분 목록 조회
  useEffect(() => {
    /*
     * @desc    고객구분 조회 Request
     */
    const fnFetchCustomerGroup = async () => {
      const groups = await store.axiosCustomerGroup();
      setCustomerGroupOptions(CmsData.fnMakeSelectOptions("group", groups));
    };

    //실행영역
    const storeGroup = CM.cfnCopyObject(toJS(store.customerGroup));
    if (CM.cfnIsEmpty(storeGroup)) {
      fnFetchCustomerGroup();
    } else {
      setCustomerGroupOptions(CmsData.fnMakeSelectOptions("group", storeGroup));
    }
  }, [store]);

  //입금청구대상 목록 조회
  useEffect(() => {
    if (tabIndex === 0 && cmsRequestStatus === 3 && searchButton === true && CM.cfnIsNotEmpty(searchRequest.fileUniqueKey)) {
      handleSearchButtonChange(false); //목록 조회 중복 실행 방지 위해 바로 flag 변경
      startCmsContentsAxios();
    }
  }, [tabIndex, cmsRequestStatus, searchButton, startCmsContentsAxios, searchRequest.fileUniqueKey]);

  //입금대상 추가 or 입금대상 수정 시 목록 재조회
  useEffect(() => {
    const fnSetCmsSummary = async () => {
      const summary = await fnFetchCmsSummary();
      await setCmsRequestSummary((element) => ({
        ...element,
        ...summary,
      }));
    };

    if (isTargetChanged) {
      setIsTargetChanged(false);
      fnSetCmsSummary();
      handleSearchButtonChange(true);
    }
  }, [isTargetChanged, fnFetchCmsSummary]);

  return (
    <div>
      <Form.CmsPaymenttRequestInfo cmsRequestStatus={cmsRequestStatus} />
      <Form.CmsPaymentGeneration
        cmsRequestStatus={cmsRequestStatus}
        cmsRequestSummary={cmsRequestSummary}
        handleCmsRequestInfoChange={handleCmsRequestInfoChange}
        targetCheckbox={targetCheckbox}
        handleTargetCheckbox={handleTargetCheckbox}
      />
      <div className="table-bottom-area">
        <FormControlLabel
          className={cmsRequestStatus !== 2 ? "hidden" : ""}
          control={<Checkbox checked={saveCmsDepositSettings} onChange={(e) => setSaveCmsDepositSettings(e.target.checked)} />}
          label="입금대상 설정을 저장합니다."
        />
        <button type="button" className={`btn-l ${cmsRequestStatus !== 2 ? "hidden" : ""}`} onClick={handleClickRequestGenerations}>
          입금대상 생성
        </button>
        <button type="button" className={`btn-l ${cmsRequestStatus !== 3 ? "hidden" : ""}`} onClick={handleClickRequest} data-testid="post-cms-request">
          청구하기
        </button>
        <button type="button" className={`btn-m ${cmsRequestStatus !== 3 ? "hidden" : ""}`} onClick={handleClickRequestDelete} data-testid="delete-cms-request">
          청구대상 삭제
        </button>
      </div>
      <div className="table-bottom-area">
        {cmsRequestStatus === 3 && (
          <Fragment>
            <h4>입금 대상</h4>
            <Form.CmsRequestTarget cmsRequestSummary={cmsRequestSummary} />
            <Form.CmsRequestSearchForm
              searchRequest={searchRequest}
              capitalOptions={capitalOptions}
              customerGroupOptions={customerGroupOptions}
              handleRequestSearchChange={handleRequestSearchChange}
              handleSearchButtonChange={handleSearchButtonChange}
              handleSearchKeyUp={handleSearchKeyUp}
            />
            <div className="table-top-area">
              <TotalCountForm totalElements={originalList.totalElements || 0} />
              <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleSelectExcelFile(e)}
                ref={excelInput}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                value=""
                data-testid="select-excel-input"
              />
              <button className="btn-m fr" onClick={handleModifyTargetBulk} data-testid="modify-excel">
                [일괄제외]엑셀파일 등록
              </button>
              <button className="btn-m fr" onClick={handleExcelDownload} data-testid="save-excel">
                [일괄제외양식]엑셀저장
              </button>

              <button className="btn-m fr" onClick={handleModifyTarget} data-testid="modify-open">
                입금금액 수정
              </button>
              <button className="btn-m fr" onClick={handleTargetAddModal} data-testid="add-cms-request">
                입금대상 추가
              </button>
            </div>
            <Form.CmsRequestTable
              cmsRequestList={cmsRequestList}
              handleExcludeRow={handleExcludeRow}
              searchRequest={searchRequest}
              handleSortProperty={handleSortProperty}
              financialInstitutes={financialInstitutes}
              handleClickedRow={handleClickedRow}
            />
            <Pagination
              className="centered"
              limit={pagination.rowsPerPage}
              offset={pagination.offset}
              total={pagination.total}
              onClick={(e, offset, page) => handleOffsetChange(offset, page)}
              reduced={true}
              centerRipple={false}
              disableFocusRipple={true}
              disableRipple={true}
            />
          </Fragment>
        )}
      </div>
      <TargetAddModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        fileUniqueKey={cmsRequestSummary.uniqueKey}
        setIsTargetChanged={setIsTargetChanged}
        capitalOptions={capitalOptions}
        customerGroupOptions={customerGroupOptions}
        financialInstitutes={financialInstitutes}
      />
      <TargetModifyModal
        open={modifyModalOpen}
        setOpen={(t) => {
          setTargetAskUniqueKey(null);
          setModifyModalOpen(t);
        }}
        fileUniqueKey={cmsRequestSummary.uniqueKey}
        setIsTargetChanged={setIsTargetChanged}
        capitalOptions={capitalOptions}
        customerGroupOptions={customerGroupOptions}
        financialInstitutes={financialInstitutes}
        targetAskUniqueKey={targetAskUniqueKey}
      />
    </div>
  );
};

export default CmsReceiptRequest;
