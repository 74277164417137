import React, { useState, useEffect, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Pagination from "material-ui-flat-pagination";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";

const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "700px",
  },
});

const defaultHooks = {
  searchStart: false,
  detailsList: [],
  searchCondition: (fileUniqueKey) => ({
    customerName: "",
    fileUniqueKey: fileUniqueKey,
    pageNumber: 0,
    pageSize: 5,
    cashbillIdentificationNo : "",
    payerNo: "",
    sortDirection: "ASC",
    sortProperty: "customerName",
  }),
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
  searchForm: {
    terms: "customerName",
    input: "",
  },
};

// 부가서비스 > 현금영수증 > 발행 요청결과 > 현금영수증 발행 결과 modal
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabRequestResultModalDetails = (props) => {
  const classes = useStyles();

  // hooks
  const [searchStart, setSearchStart] = useState(defaultHooks.searchStart);
  const [detailsList, setDetailsList] = useState(defaultHooks.detailsList);
  const [searchCondition, setSearchCondition] = useState(defaultHooks.searchCondition(props.fileUniqueKey));
  const [pagination, setPagination] = useState(defaultHooks.pagination);
  const [searchForm, setSearchForm] = useState(defaultHooks.searchForm);

  // handler
  const handleSearchForm = ({ target: { value } }, key) => setSearchForm((data) => ({ ...data, [key]: value }));
  const handleSearchCondition = (value) => setSearchCondition(value);
  const handleDetailsList = (value) => setDetailsList(value);
  const handleSearchStart = (value) => setSearchStart(value);
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  const handlePage = (page) => {
    handleSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchStart(true);
  };

  const handleRowPerPage = ({ target: { value } }) => {
    handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchStart(true);
  };

  // 엑셀 저장
  const downloadExcel = () => {
    let url = `api/extraservice/cashbill/files/excel/${props.fileUniqueKey}`;
    url += `?customerName=${searchCondition.customerName}`;
    url += `&cashbillIdentificationNo=${searchCondition.cashbillIdentificationNo}&payerNo=${searchCondition.payerNo}&sortProperty=${searchCondition.sortProperty}&sortDirection=${searchCondition.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  // useCallback
  // hooks 초기화
  const initializeHooks = useCallback(() => {
    setSearchStart(defaultHooks.searchStart);
    setDetailsList(defaultHooks.detailsList);
    setSearchCondition(defaultHooks.searchCondition(props.fileUniqueKey));
    setPagination(defaultHooks.pagination);
    setSearchForm(defaultHooks.searchForm);
  }, [props.fileUniqueKey]);

  // 현금영수증 발행수정대상 목록 가져오기
  const getDetailsList = useCallback(
    (parameter) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/cashbill/files/${props.fileUniqueKey}`;
        url += `?pageNumber=${parameter.pageNumber}&pageSize=${parameter.pageSize}`;
        url += `&sortDirection=${parameter.sortDirection}&sortProperty=${parameter.sortProperty}`;
        url += `&customerName=${parameter.customerName}&cashbillIdentificationNo=${parameter.cashbillIdentificationNo}&payerNo=${parameter.payerNo}`;

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    },
    [props.fileUniqueKey]
  );

  // useEffect
  // 모달 state에 변화가 생길 때 화면 초기화
  useEffect(() => {
    if (props.modalOpen) handleSearchStart(true);
    return () => initializeHooks();
  }, [props.modalOpen, initializeHooks]);

  // 현금 영수증 발행 결과 목록 설정
  useEffect(() => {
    const startAxios = async (parameter) => {
      const resultTargets = await getDetailsList(parameter);

      handleDetailsList(resultTargets.content);
      handlePagination(resultTargets);
    };

    if (searchStart) startAxios(searchCondition);

    return () => handleSearchStart(false);
  }, [searchCondition, searchStart, getDetailsList]);

  return (
    <Modal open={props.modalOpen}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpen(false)} data-testid="close-cashbill-details-modal">
              {""}
            </Button>
          </div>
          <h3>현금영수증 발행 결과</h3>
          <div className="inforbox">
            <ul>
              <li>신청일로부터 2일 이상 경과했으나 발행요청을 하지 못한 내역이 있으면, "발행대상 삭제" 후 다시 생성하시기 바랍니다.</li>
            </ul>
          </div>
          <div>
            <SearchComponent searchForm={searchForm} handleSearchForm={handleSearchForm} handleSearchCondition={handleSearchCondition} handleSearchStart={handleSearchStart} />
          </div>
          <div className="table-top-area">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} customProps={{ inputProps: { "data-testid": "cashbill-details-rowPerPage-select-inputProps" } }} />
            <button className="btn-m fr table-top-button" data-testid="cashbill-details-save-list-button" onClick={downloadExcel}>
              목록저장(엑셀)
            </button>
          </div>
          <div>
            <DetailsListComponent list={detailsList} pagination={pagination} handlePage={handlePage} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

// 검색 컴포넌트
const SearchComponent = (props) => {
  const optionList = [
    { value: "customerName", label: "고객명" },
    { value: "cashbillIdentificationNo", label: "신분확인번호" },
    { value: "payerNo", label: "납부자번호" },
  ];

  const handleSearchKeyUp = ({ keyCode }) => {
    if (keyCode === 13) startSearch();
  };

  const startSearch = async () => {
    props.handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      customerName: props.searchForm.terms === "customerName" ?  props.searchForm.input : "",
      cashbillIdentificationNo : props.searchForm.terms === "cashbillIdentificationNo" ?  props.searchForm.input : "",
      payerNo: props.searchForm.terms === "payerNo" ?  props.searchForm.input : "",
    }));
    props.handleSearchStart(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <Select native value={props.searchForm.terms} onChange={(e) => props.handleSearchForm(e, "terms")} inputProps={{ "data-testid": "cashbill-details-select-terms" }}>
          {optionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField className="w250" value={props.searchForm.input} onChange={(e) => props.handleSearchForm(e, "input")} onKeyUp={handleSearchKeyUp} data-testid="cashbill-details-input-textfield" />
        <button className="search-button" onClick={startSearch} data-testid="cashbill-details-button-search">
          검색
        </button>
      </div>
    </div>
  );
};

// 목록 컴포넌트
const DetailsListComponent = (props) => {
  return (
    <div>
      <Table>
        <TableForm.compTableHead arrData={["요청구분", "고객명", "납부자번호", "발행일(수납일)", "발행금액", "공급가액", "부가세", "발행구분", "수납구분", "신분확인번호", "처리결과(코드)", "승인번호"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionType(target.transactionType)}</TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">
                    <div>{CM.cfnDateFormat(target.transactionDate, "yyMMdd")}</div>
                    <div>{"(" + CM.cfnDateFormat(target.withdrawDate, "yyMMdd") + ")"}</div>
                  </TableCell>
                  <TableCell align="right">{CM.cfnAddComma(target.issuedAmount)}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(target.issuedAmount - target.vatAmount)}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(target.vatAmount)}</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.purposeType(target.purposeType)}</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionMethod(target.transactionMethod)}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo)}</TableCell>
                  <TableCell align="center">{target.errorProcessTypeMsg == null ?  "-" : target.errorProcessTypeMsg}</TableCell>
                  <TableCell align="center">{target.approvalNo === null ? "-" : target.approvalNo}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        className="centered"
        limit={props.pagination.rowsPerPage}
        offset={props.pagination.offset}
        total={props.pagination.total}
        onClick={(e, offset, page) => props.handlePage(page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
        data-testid="cashbill-requestResultDetails-pagination"
      />
    </div>
  );
};

export default CashbillTabRequestResultModalDetails;
