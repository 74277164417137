import React, { useState, useEffect } from "react";
import * as CM from "../common/Common";
import * as CF from "./ComponentForm";
import { Modal, Button, FormControl, Input } from "@material-ui/core";
import ComponentFormEmbed from "./ComponentFormEmbed";

const isIE = /*@cc_on!@*/ false || !!document.documentMode;
/*
 * @desc    메인 컴포넌트 선언
 */
const EvidenceFileModal = ({
  open,
  setOpen,
  originalState,
  setOriginalState,
  evidenceFile, // 수납정보에 가지고있는 동의자료 정보
  setEvidenceFile,
  fnSubmitEvidenceFile,
  type,
  modalType,
}) => {
  //필요 state 선언
  const [inModalFile, setInModalFile] = useState({
    file: null,
    fileName: "",
    evidenceFileType: (originalState && originalState.account && originalState.account.evidenceFileType) || "",
    fileSrc: "#",
    fileType: "",
  }); //모달 내에서 선택 파일 제어 위해 별도 선언
  const [fileChange, setFileChange] = useState(false);
  const [pdfAudioFile, setPdfAudioFile] = useState({ fileSrc: "", style: { height: "0px" } });

  useEffect(() => {
    //실행영역
    if (open && Object.isExtensible(originalState.account) && type !== "create") {
      if (CM.cfnIsEmpty(originalState.account.evidenceFileName) && CM.cfnIsEmpty(originalState.account.evidenceFileType)) {
        // 기존에 동의자료가 없는 경우
        if (!fileChange && inModalFile.file != null) {
          return () => {
            setInModalFile({
              file: null,
              fileName: "",
              fileSrc: "#",
              fileType: "",
            });
            setPdfAudioFile(() => ({
              fileSrc: "",
              style: { height: "0px" },
              text: "",
            }));
          };
        }
      } else if (CM.cfnIsNotEmpty(evidenceFile.fileName)) {
        // 기존에 동의자료가 있는 경우 - 해당 내용을 서버에서 가져와서 보여준다.
        if (!fileChange) {
          setFileChange(true); // 기존 동의자료가 있는 경우에는 이미 로드가 한번 되었는지를 체크하는 용도로 fileChange를 사용한다.
          let extension = evidenceFile.fileName.substring(evidenceFile.fileName.lastIndexOf(".") + 1).toLowerCase();
          if (extension === "der" || extension === "wma" || (isIE && (extension === "mp3" || extension === "pdf"))) {
            // der, wma파일은 미리보기 불가능하므로 호출하지 않음
            setInModalFile({
              file: null,
              fileName: evidenceFile.fileName,
              fileSrc: "#",
              fileType: null,
              evidenceFileType: originalState.account ? originalState.account.evidenceFileType : "",
            });
            setPdfAudioFile({
              fileSrc: "",
              style: { height: "0" },
              text: "미리보기를 지원하지 않는 형식의 파일입니다. 파일을 저장하여 확인하시기 바랍니다.",
            });
          } else {
            const url = `api/customer/receipt/evidence?accountUniqueKey=${originalState.account.uniqueKey}&isPreview=true`;
            setInModalFile({
              file: null,
              fileName: evidenceFile.fileName,
              fileSrc: "#",
              fileType: null,
              evidenceFileType: originalState.account ? originalState.account.evidenceFileType : "",
            });
            CM.cfnAxiosPreview(
              url,
              "get",
              null,
              (status, contentType, file, fileName) => {
                CF.fnLoadServerSavedImage(setInModalFile, file, fileName, contentType, true, false, setPdfAudioFile);
              },
              () => {
                /* 실패시 에러메시지 띄우지 않음 */
              }
            );
          }
        }
      }
    } else if (!open) {
      if (inModalFile.file != null && type !== "create") {
        setFileChange(false);
        setInModalFile({
          file: null,
          fileName: "",
          fileSrc: "#",
          fileType: "",
          evidenceFileType: (originalState.account && originalState.account.evidenceFileType) || null,
        });
        setPdfAudioFile(() => ({
          fileSrc: "",
          style: { height: "0px" },
          text: "",
        }));
      }
    }
  }, [open, inModalFile, fileChange, evidenceFile, originalState.account, type]);

  const fileInput = React.useRef();

  //파일찾기 버튼 클릭 이벤트 핸들러
  const handleFindFile = () => {
    if (CM.cfnIsNotEmpty(inModalFile.evidenceFileType)) {
      fileInput.current.click();
    } else {
      alert("동의자료 구분을 선택하여 주십시오.");
    }
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();

    if (CM.cfnCheckEvidenceFileValidation(inModalFile, type)) {
      fnSubmitEvidenceFile(inModalFile.file, inModalFile.fileName, inModalFile.evidenceFileType, setFileChange);
    }
  };

  //파일 선택 이벤트 핸들러
  const handleFileChange = async (e) => {
    const file = e.currentTarget.files && e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : null;
    if (file && file.name) {
      //선택된 파일이 있을 시 프로세스 진행
      const isImage = file.type.indexOf("image") !== -1;
      const isPdf = file.type.indexOf("pdf") !== -1;
      const isAudio = file.type.indexOf("audio") !== -1;
      const isText = file.type === "text/plain";

      let tempObj = {
        file: file,
        src: "#",
      };

      if (isImage) {
        //선택된 파일이 이미지일 경우
        if (file.type.indexOf("jpeg") !== -1 && file.type.indexOf("jpg") !== -1 && file.type.indexOf("gif") !== -1 && file.type.indexOf("tif") !== -1) {
          CM.cfnAlert("첨부가 허용된 이미지 형식이 아닙니다.");
          return false;
        }
        if (file.type.indexOf("tif") !== -1) {
          setInModalFile((inModalFile) => ({
            ...inModalFile,
            file: tempObj.file,
            fileName: file.name,
            fileSrc: "#",
            fileType: file.type,
          }));
          setEvidenceFile((evidenceFile) => ({
            ...evidenceFile,
            file: tempObj.file,
            fileName: file.name,
            fileType: inModalFile.evidenceFileType,
          }));
          setPdfAudioFile({ fileSrc: "", style: { height: "0px" }, text: "미리보기를 지원하지 않는 형식입니다." });
          return;
        }
        const reader = new FileReader();
        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = async (e) => {
            tempObj = await CF.fnCheckEvidenceImageFile(file, image, 300);

            setInModalFile((inModalFile) => ({
              ...inModalFile,
              file: tempObj.file,
              fileName: file.name,
              fileSrc: tempObj.src,
              fileType: file.type,
            }));
            setPdfAudioFile({ fileSrc: "", style: { height: "0px" }, text: "" });

            setEvidenceFile((evidenceFile) => ({
              ...evidenceFile,
              file: tempObj.file,
              fileName: file.name,
              fileType: inModalFile.evidenceFileType,
            }));
          };
        };
        reader.readAsDataURL(tempObj.file);
      } else if (isPdf || isAudio || isText) {
        //선택된 파일이 pdf거나 audio일 경우
        const reader = new FileReader();
        reader.onload = function (e) {
          const MAX_SIZE = 300 * 1024;
          if (file.size > MAX_SIZE) {
            CM.cfnAlert(`첨부자료의 용량은 300KB 이내여야 합니다.\n선택된 파일 용량 : ${Math.round(file.size / 1024, 0)}KB`);
            if (file.type.indexOf("wma") === -1) {
              setPdfAudioFile({ fileSrc: "", style: { height: "0px" }, text: "" });
            }
            return false;
          } else if (file.type.indexOf("wma") === -1) {
            setPdfAudioFile({ fileSrc: e.target.result, style: { height: "400px", width: "70%", marginLeft: "150px" }, text: "" });
          } else if (file.type.indexOf("wma") !== -1) {
            setPdfAudioFile({ fileSrc: "", style: { height: "0px" }, text: "미리보기를 지원하지 않는 형식입니다." });
          }

          setInModalFile((inModalFile) => ({
            ...inModalFile,
            file: tempObj.file,
            fileName: file.name,
            fileSrc: e.target.result,
            fileType: file.type,
          }));

          setEvidenceFile((evidenceFile) => ({
            ...evidenceFile,
            file: tempObj.file,
            fileName: file.name,
            fileType: inModalFile.evidenceFileType,
          }));
        };
        reader.readAsDataURL(tempObj.file);
      } else {
        setInModalFile((inModalFile) => ({
          ...inModalFile,
          file: tempObj.file,
          fileName: file.name,
          fileSrc: tempObj.src,
          fileType: file.type || file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase(),
        }));

        setPdfAudioFile({ fileSrc: "", style: { height: "0px" }, text: "미리보기를 지원하지 않는 형식입니다." });

        setEvidenceFile((evidenceFile) => ({
          ...evidenceFile,
          file: tempObj.file,
          fileName: file.name,
          fileType: inModalFile.evidenceFileType,
        }));
      }
    }
    setFileChange(true);
  };

  //동의자료 구분 셀렉트 박스 변경 이벤트 핸들러
  const handleEvidenceTypeChange = (e) => {
    setInModalFile({
      ...inModalFile,
      evidenceFileType: e.target.value,
    });
  };

  /*
   * @desc   자동납부 동의자료 다운로드 API
   */
  function fnFetchSavedEvidenceFileDownload() {
    return new Promise((resolve) => {
      const url = `api/customer/receipt/evidence?accountUniqueKey=${originalState.account.uniqueKey}`;
      CM.cfnAxiosGeneralFileDownload(url, "get", "", evidenceFile.fileName);
    });
  }

  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => setOpen(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>자동납부 동의자료</h3>
          <div className="inforbox">
            <ul>
              <li>고객 계좌를 신규 또는 변경 등록 시 자동납부 동의자료를 반드시 첨부해야 합니다.</li>
            </ul>
          </div>
          <form name="evidenceFileForm" onSubmit={handleSubmitFile}>
            <div className="modify-area">
              <div className="block">
                <label className="label-l" data-testid="select-evidence-label">
                  동의자료 구분
                </label>
                <FormControl>
                  <CF.SelectForm
                    value={inModalFile.evidenceFileType}
                    handleChange={handleEvidenceTypeChange}
                    name="evidenceFileType"
                    arrayOption={CM.cfnGetEvidenceFileTypes()}
                    optionValue={"evidenceFileType"}
                    optionLabel={"evidenceFileTypeName"}
                    className="box-preview"
                    disabled={evidenceFile.isSavedOnServer}
                  />
                </FormControl>
                {evidenceFile.isSavedOnServer ? <label className="label-l">파일명</label> : <label className="label-l">파일경로</label>}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e)}
                  accept=".jpg, .jpeg, .gif, .tif, .pdf, .der, .mp3, .wav, .wma, .txt, .jb64"
                  ref={fileInput}
                  value=""
                  data-testid="selected-file-input"
                />
                {evidenceFile.isSavedOnServer ? (
                  <React.Fragment>
                    <span style={{ fontSize: "14px", marginTop: "6px", paddingLeft: "10px", paddingRight: "10px" }}>{evidenceFile.fileName}</span>
                    <Button className="btn-file" onClick={fnFetchSavedEvidenceFileDownload} data-testid="find-file">
                      파일저장
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Input value={inModalFile.fileName} readOnly={true} onClick={handleFindFile} data-testid="uploaded-file-name" />
                    <Button className="btn-file" onClick={handleFindFile} data-testid="find-file">
                      파일 찾기
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div id="preview-section" className="preview-section">
              <img src={inModalFile.fileSrc} alt="" className="preview-image" data-testid="preview-image" style={inModalFile.fileSrc === "#" ? { display: "none" } : {}} />
              <ComponentFormEmbed src={pdfAudioFile.fileSrc} style={pdfAudioFile.style} />
              <div style={{ textAlign: "center" }}>
                <span style={{ fontSize: "14px" }}>{pdfAudioFile.text}</span>
              </div>
            </div>
            {evidenceFile.isSavedOnServer ? (
              ""
            ) : (
              <div className="table-bottom-area" style={{ textAlign: "center" }}>
                <button type="submit" className="btn-l">
                  동의자료 등록
                </button>
              </div>
            )}
          </form>
          <h4>자동납부 동의자료 구분 및 허용 용량</h4>
          <CF.EvidenceFileTypeInformation />
        </div>
      </div>
    </Modal>
  );
};

export default EvidenceFileModal;
