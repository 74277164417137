import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";

import { Modal, Table, TableBody, TableRow, TableCell, TextField, TableHead, Button } from "@material-ui/core";

import TableForm from "../../template/TableForm";

import StaffData from "./StaffInfoData";

/*
 * @desc    메인 컴포넌트 선언
 */
const BranchModal = ({ instituteStore, open, setOpen, setBranches, setStaffs }) => {
  //지사관리 필요 state 선언
  const [newBranch, setNewBranch] = useState("");
  const [branchRows, setBranchRows] = useState([]);
  const [branchPagination, setBranchPagination] = useState(StaffData.paginationData);

  //모달창 열릴 때 store에서 필요 데이터 deep copy 후 state로 세팅
  useEffect(() => {
    setBranchRows(CM.cfnCopyObject(toJS(instituteStore.branches)));
  }, [instituteStore.branches]);

  /*
   * @desc    페이지 변경 이벤트 핸들러
   */
  const handleOffsetChange = (offset, page) => {
    setBranchPagination({
      ...branchPagination,
      offset: offset,
      pageNumber: page - 1,
    });
  };

  /*
   * @desc    신규 추가 지사명 입력란 변경 이벤트 핸들러
   */
  const handleNewBranchChange = (e) => {
    setNewBranch(e.target.value);
  };

  /*
   * @desc    기존 지사명 입력란 변경 이벤트 핸들러
   */
  const handleBranchNameChange = (row, change) => {
    //array 형태의 state에 직접 접근하지 않도록 임시배열 선언 후 값 복사
    const tempArray = [...branchRows];
    const targetBranch = row.uniqueKey;
    let rowIndex = null;

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].uniqueKey === targetBranch) {
        rowIndex = i;
        break;
      }
    }

    tempArray[rowIndex].branchName = change.fieldValue;
    setBranchRows(tempArray);
  };

  /*
   * @desc    신규 지사 추가 버튼 클릭 이벤트 핸들러
   */
  const handleBranchAdd = (e) => {
    e.preventDefault();
    if (newBranch !== "" || newBranch !== "undefined") handlBranchInsert();
  };

  /*
   * @desc    기존 지사 수정 버튼 클릭 이벤트 핸들러
   */
  const handleBranchUpdate = (e, rowData) => {
    if (fnCheckValidation(rowData)) {
      CM.cfnConfirm("정말로 수정하시겠습니까?", () => {
        const url = "api/institute/branches";
        CM.cfnAxios(url, "put", rowData, (objStatus, objData) => {
          CM.cfnAlert("정상적으로 처리되었습니다.", async () => {
            const tempBranches = CM.cfnCopyObject(toJS(instituteStore.branches));

            let rowIndex;

            tempBranches.forEach((branch, index) => {
              if (branch.uniqueKey === rowData.uniqueKey) rowIndex = index;
            });
            tempBranches[rowIndex] = rowData;

            instituteStore.setBranches(tempBranches);
            setBranchRows(tempBranches);
            setBranches(tempBranches);

            let staffs = await instituteStore.axiosStaffs();
            staffs.forEach((staff, index) => {
              if (staff.branch.uniqueKey === rowData.uniqueKey) {
                staff.branch.branchName = rowData.branchName;
              }
            });
            setStaffs(staffs);
          });
        });
      });
    }
  };

  /*
   * @desc    기존 지사 삭제 버튼 클릭 이벤트 핸들러
   */
  const handleBranchDelete = (uniqueKey) => {
    CM.cfnConfirm("정말로 삭제하시겠습니까?", () => {
      const url = `api/institute/branches/${uniqueKey}`;
      CM.cfnAxios(url, "delete", "", (objStatus, objData) => {
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {
          const tempBranches = CM.cfnCopyObject(toJS(instituteStore.branches));

          let rowIndex;
          tempBranches.forEach((branch, index) => {
            if (branch.uniqueKey === uniqueKey) rowIndex = index;
          });
          tempBranches.splice(rowIndex, 1);

          instituteStore.setBranches(tempBranches);
          setBranchRows(tempBranches);
          setBranches(tempBranches);
          setBranchPagination({
            ...branchPagination,
            pageNumber: 0,
            offset: 0,
          });
        });
      });
    });
  };

  /*
   * @desc    신규 지사 추가 버튼 클릭 이벤트 핸들러
   */
  const handlBranchInsert = () => {
    CM.cfnConfirm("추가하시겠습니까?", fnInsertBranch);
  };

  /*
   * @desc    신규 지사 추가 Request
   */
  const fnInsertBranch = () => {
    const branchName = { branchName: newBranch };
    const url = "api/institute/branches";
    CM.cfnAxios(url, "post", branchName, (objStatus, objData) => {
      CM.cfnAlert("정상적으로 처리되었습니다.", () => {
        const tempBranches = CM.cfnCopyObject(toJS(instituteStore.branches));
        tempBranches.push({ uniqueKey: objData, branchName: newBranch });

        instituteStore.setBranches(tempBranches);
        setBranchRows(tempBranches);
        setBranches(tempBranches);
        setNewBranch("");
        setBranchPagination({
          ...branchPagination,
          pageNumber: 0,
          offset: 0,
        });
      });
    });
  };

  /*
   * @desc    지사 수정 전 validation 체크 함수
   */
  const fnCheckValidation = (rowData) => {
    const currentBranches = CM.cfnCopyObject(toJS(instituteStore.branches));

    let rowIndex;
    currentBranches.forEach((branch, index) => {
      if (branch.uniqueKey === rowData.uniqueKey) rowIndex = index;
    });

    if (currentBranches[rowIndex]["branchName"] === rowData["branchName"]) {
      CM.cfnAlert("수정된 내용이 없습니다.");
      return false;
    }

    return true;
  };

  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => setOpen(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>지사 관리</h3>
          <div className="modify-area">
            <div className="block">
              <form name="branchInfo" onSubmit={handleBranchAdd}>
                <label className="label-l">추가할 지사명</label>
                <TextField placeholder="추가할 지사명 입력" name="branchName" value={newBranch} data-testid="insert-branch-name" onChange={handleNewBranchChange} required={true} />
                <button type="submit" className="btn-l" data-testid="submit-button">
                  지사추가
                </button>
              </form>
            </div>
          </div>
          <Table aria-labelledby="tableTitle" data-testid="branch-table">
            {CM.cfnCompColGroup(["70%", "30%"])}
            <TableHead>
              <TableRow>
                <TableCell align="center">지사명</TableCell>
                <TableCell align="center">지사명 관리</TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="tablebody">
              {branchRows.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={2} />
              ) : (
                branchRows.slice(branchPagination.pageNumber * 5, branchPagination.pageNumber * 5 + 5).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <Template.EditableCellInput row={row} fieldName="branchName" onCellValueChange={handleBranchNameChange.bind(this, row)} />
                      </TableCell>
                      <TableCell align="center" component="th" scope="row" padding="none">
                        <div className="btn-l margin-btn" onClick={(e) => handleBranchUpdate(e, row)} data-testid={"modify-button-" + index}>
                          수정
                        </div>
                        <div className="btn-m margin-btn" onClick={(e) => handleBranchDelete(row.uniqueKey)} data-testid={"delete-button-" + index}>
                          삭제
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <Template.PaginationForm filteredRows={branchRows} pagination={branchPagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore) => ({
  instituteStore: rootStore.instituteStore,
}))(observer(BranchModal));
