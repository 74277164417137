import { Button, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import logo from "../../../styles/images/logo.png";
import "../../../styles/institute.css";
import * as CM from "../../common/Common";

/*
 * @desc    메인 컴포넌트 선언
 */
const ChargeBillingOneBillForm = (props) => {
  const billingoneObject = {
    subject: "빌링원플러스",
    title: "빌링원플러스 이용요금 청구서",
    instName: props.loginStore.loginDto.instName,
    staffName: props.loginStore.loginDto.staffName,
    lastPaidDate: "",
  };

  const { open, setOpen, target } = props;

  const [value, setValue] = useState({
    adjustedAmount: 0,
    adjustmentAmount: 0,
    adjustmentReason: "",
    amountLessThan100: 0,
    arsCount: 0,
    arsFee: 0,
    autoWithdrawCount: 0,
    autoWithdrawFee: 0,
    billingonePlusAmount: 0,
    customerAdditionalCount: 0,
    customerAdditionalFee: 0,
    dueDate: "20200101",
    extraServiceAmount: 0,
    previouslyUnpaidAmount: 0,
    serviceAmount: 0,
    smsCount: 0,
    smsFee: 0,
    suppliedAmount: 0,
    taxAmount: 0,
    taxbillCount: 0,
    taxbillFee: 0,
    thisMonthAmount: 0,
    totalAskingAmount: 0,
    useY4mm: "202001",
  });
  const [financialInstitutes, setFinancialInstitutes] = React.useState([]); //금융기관 자동완성 기능을 위한 state
  const [useInstitute, setUseInstitute] = useState({
    intgtLoginId: "",
    billingAccount: {
      intgtLoginId: "",
      accountNo: "",
      accountBranchName: "",
      accountBranchCode: "",
      depositorName: "",
      depositorIdentificationNo: "",
      accountApplyDate: null,
      virtualAccountNo: "",
    },
  });

  useEffect(() => {
    if (open) {
      const url = `/api/institute/billing/billingonePlus?useY4mm=${target.useY4mm}`;
      CM.cfnAxios(url, "get", "", async (status, data) => {
        setValue(data);

        setUseInstitute(toJS(await props.store.axiosBusinessInfo()));
        setFinancialInstitutes(toJS(await props.store.axiosFinancialInstitutes()));
      });
    } else {
      setValue({});
    }
  }, [open, target, props.store]);

  /*
   * @desc    모달창 닫기 이벤트 핸들러
   */
  const handleClose = () => {
    setOpen(false);
  };

  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();

  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          width: "1000px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <div className="table-top-area d-flex">
            <ReactToPrint trigger={() => <button className="btn-l print-button">청구서 인쇄</button>} content={() => printRef.current} copyStyles={true} />
          </div>
          <div className="print-outer-box-bill" ref={printRef}>
            <div className="charge-head">
              <img src={logo} alt="logo" />
              <div className="title" style={{ width: "calc(153mm - 180px)" }}>
                {billingoneObject.title}
              </div>
            </div>
            <div className="charge-summary">
              {billingoneObject.instName} 고객님께서 {CM.cfnDateFormat(value.dueDate, "yyyyMMdd")} 기준으로 납부하실 금액은 {CM.cfnAddComma(target.billingonePlusTotalAskingAmount)}
              원입니다.
            </div>
            <div className="charge-body">
              <h4>1. 청구내역</h4>
              <div className="charge01">
                <div className="charge0101">
                  <Table>
                    {CM.cfnCompColGroup(["25%", "35%", "auto"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th" colSpan={3}>
                          {CM.cfnDateFormat(value.useY4mm, "yyyyMM")} 청구내역
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          빌링원플러스 이용요금(1)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.serviceAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          조정금액(2)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.adjustmentAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          소계(3=1+2)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.adjustedAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          100원 미만 절사 금액(4)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.amountLessThan100)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={3}>
                          당월요금
                        </TableCell>
                        <TableCell align="center" className="td">
                          공급가액(5=3+4)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.suppliedAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          부가세(6=5&times;10%)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.taxAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          합계(7=5+6)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.thisMonthAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          미납요금(8)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.previouslyUnpaidAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          총 청구금액(9=7+8)
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.totalAskingAmount)}원
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="charge0102">
                  <Table>
                    {CM.cfnCompColGroup(["25%", "35%", "auto"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th" colSpan={3}>
                          이용기관정보
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          이용기관명
                        </TableCell>
                        <TableCell align="center" className="td">
                          {billingoneObject.instName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          이용기관ID
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.intgtLoginId}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          이용월
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnDateFormat(value.useY4mm, "yyyyMM")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          납기일
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnDateFormat(value.dueDate, "yyyyMMdd")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={3}>
                          이용요금
                          <br />
                          납부계좌
                        </TableCell>
                        <TableCell align="center" className="td">
                          금융기관
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.billingAccount && useInstitute.billingAccount.accountBranchCode
                            ? CM.cfnMatchBankName(useInstitute.billingAccount.accountBranchCode.substr(0, 3), financialInstitutes)
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          계좌번호
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.billingAccount && useInstitute.billingAccount.accountNo
                            ? useInstitute.billingAccount.accountNo.length > 3
                              ? useInstitute.billingAccount.accountNo.substr(0, useInstitute.billingAccount.accountNo.length - 3) + "***"
                              : useInstitute.billingAccount.accountNo
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          예금주
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.billingAccount && useInstitute.billingAccount.depositorName ? useInstitute.billingAccount.depositorName : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          무통장 입금계좌
                          <br />
                          (가상계좌)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.virtualAccountNo ? "[SC은행]" : "-"}
                          <br />
                          {useInstitute.virtualAccountNo}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="charge02">
                <h4>2. 청구상세내역</h4>
                <div className="charge0201">
                  <h5>가. 빌링원플러스 이용요금</h5>
                  <Table>
                    {CM.cfnCompColGroup(["15%", "15%", "20%", "auto", "20%"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th">
                          구분
                        </TableCell>
                        <TableCell align="center" className="th">
                          내역
                        </TableCell>
                        <TableCell align="center" className="th">
                          건수
                        </TableCell>
                        <TableCell align="center" className="th">
                          단가
                        </TableCell>
                        <TableCell align="center" className="th">
                          금액
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={3}>
                          기본이용요금
                        </TableCell>
                        <TableCell align="center" className="td">
                          기본요금
                        </TableCell>
                        <TableCell align="right" className="td">
                          기본 {CM.cfnAddComma(value.customerBaseCount)}명
                        </TableCell>
                        <TableCell align="right" className="td">
                          15,000원
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.customerBaseFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          추가요금
                        </TableCell>
                        <TableCell align="right" className="td">
                          추가 {CM.cfnAddComma(value.customerAdditionalCount)}명
                        </TableCell>
                        <TableCell align="right" className="td">
                          2,000원 / 100명당
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.customerAdditionalFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={3}>
                          기본이용요금 합계
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.billingonePlusAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={5}>
                          부가서비스
                          <br />
                          이용요금
                        </TableCell>
                        <TableCell align="center" className="td">
                          SMS고지
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.smsCount)}건
                        </TableCell>
                        <TableCell align="right" className="td">
                          16원
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.smsFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          자동출금
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.autoWithdrawCount)}건
                        </TableCell>
                        <TableCell align="right" className="td">
                          20원
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.autoWithdrawFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          전자세금계산서
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.taxbillCount)}건
                        </TableCell>
                        <TableCell align="right" className="td">
                          200원
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.taxbillFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          ARS출금동의
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.arsCount)}건
                        </TableCell>
                        <TableCell align="right" className="td">
                          동의성공 : 100원 / 건
                          <br />
                          동의실패 : 50원 / 건
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.arsFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={3}>
                          부가서비스요금 합계
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.extraServiceAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={3}>
                          이용요금합계
                        </TableCell>
                        <TableCell align="center" className="td" colSpan={2}>
                          {CM.cfnAddComma(value.thisMonthAmount)}원
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="charge0202">
                  <h5>나. 조정금액</h5>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="td">
                          조정금액
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.adjustmentAmount)}원
                        </TableCell>
                        <TableCell align="center" className="td">
                          조정사유
                        </TableCell>
                        <TableCell align="left" className="td">
                          {value.adjustmentReason || ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
  props: props,
}))(observer(ChargeBillingOneBillForm));
