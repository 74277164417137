import React, { useEffect, useState } from "react";

import { Modal, Button, Table, TableHead, TableBody, TableRow, TableCell, TextField, Select } from "@material-ui/core";

import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";

import TableForm from "../../template/TableForm";

// 부가서비스 > 기부금영수증 > 기부금영수증 개별발행 대상 선택
// 렌더를 처리하는 메인 컴포넌트
const TargetCustomerSearchModal = ({ open, setOpen, setSearchRequest, handleChangeSearchForm, setIssueTargetSearchButton }) => {
  //필요 state 선언
  const [searchButton, setSearchButton] = useState(true); //모달 내 검색 버튼 제어 flag
  const [customerList, setCustomerList] = useState([]);
  const [localSearchRequest, setLocalSearchRequest] = useState({ customerName: "", customerType: "INDIVIDUAL", identificationNo: "" });

  useEffect(() => {
    /*
     * @desc  개별발행 대상목록 조회 Request
     */
    const fnFetchCustomers = () => {
      const url = `api/extraservice/donations/targets/customers?customerName=${localSearchRequest.customerName}&identificationNo=${localSearchRequest.identificationNo}`;
      CM.cfnAxios(
        url,
        "get",
        "",
        (status, objData) => {
          setSearchButton(false);
          if (objData) {
            setCustomerList(
              objData.filter(
                (customer) => customer.identificationNo && (localSearchRequest.customerType === "INDIVIDUAL" || (customer.identificationNo.length === 10 && customer.customerType !== "INDIVIDUAL"))
              )
            );
          } else {
            setCustomerList([]);
          }
        },
        () => {}
      );
    };

    //실행영역
    if (open && searchButton) fnFetchCustomers();
  }, [open, searchButton, localSearchRequest.customerName, localSearchRequest.identificationNo, localSearchRequest.customerType]);

  /*
   * @desc  고객 검색 입력란 키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      setSearchButton(true);
    }
  };

  /*
   * @desc  고객 선택 버튼 클릭 이벤트 핸들러
   */
  const handleSelectCustomer = async (row) => {
    setSearchRequest((condition) => ({
      ...condition,
      customerName: row.customerName,
      identificationNo: row.identificationNo,
      customerUniqueKey: row.customerUniqueKey,
    }));
    await setIssueTargetSearchButton(true); //본 컴포넌트 내 개별발행 검색 flag 변경
    await setOpen(false);
    setSearchButton(true); //모달창 다시 열었을 때 조회 가능하도록 모달 내 검색 flag 변경
  };

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button
              className="fr btn-close"
              onClick={(e) => {
                setOpen(false);
              }}
              data-testid="targetCustomerSearchModal-close-button">
              {""}
            </Button>
          </div>
          <h3>기부금영수증 발행대상 선택</h3>
          <div className="search-area">
            <div className="block">
              <Select
                native
                value={localSearchRequest.customerType}
                onChange={(e) => {
                  setLocalSearchRequest({ ...localSearchRequest, customerType: e.target.value });
                  setSearchButton(true);
                }}
                inputProps={{ "data-testid": "donationIndividualIssue-customer-type-select" }}>
                <option value="INDIVIDUAL">개인고객</option>
                <option value="BUSINESSER">사업자고객</option>
              </Select>
              <label className="label-l">고객명</label>
              <TextField
                value={localSearchRequest.customerName}
                onChange={(e) => setLocalSearchRequest({ ...localSearchRequest, customerName: e.target.value })}
                onKeyUp={handleSearchKeyUp}
                data-testid="donationIndividualIssue-name-search-input"
              />
              <label className="label-l">주민등록번호(사업자번호)</label>
              <TextField
                value={localSearchRequest.identificationNo}
                onChange={(e) => setLocalSearchRequest({ ...localSearchRequest, identificationNo: e.target.value })}
                onKeyUp={handleSearchKeyUp}
                data-testid="donationIndividualIssue-identificationNo-search-input"
              />
              <button className="search-button" onClick={() => setSearchButton(true)} data-testid="donation-targetCustomer-search-button">
                조회
              </button>
            </div>
          </div>
          <div>
            <Table>
              {CM.cfnCompColGroup(["auto", "15%", "auto", "auto", "15%"])}
              <TableHead>
                <TableRow>
                  <TableCell className="th">고객명</TableCell>
                  <TableCell className="th">개인/사업자구분</TableCell>
                  <TableCell className="th">고객구분</TableCell>
                  <TableCell className="th">주민등록번호(사업자번호)</TableCell>
                  <TableCell className="th">선택</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerList.length === 0 ? (
                  <TableForm.compEmptyTableRow colSpan={5} />
                ) : (
                  customerList.map((row, index) => {
                    return (
                      <TableRow key={index} className="show-detail" onClick={(e) => handleSelectCustomer(row)} data-testid={`donation-targetCustomer-selectRow-${index}`}>
                        <TableCell className="td" align="center">
                          {row.customerName}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {CDN.customerType(row.customerType)}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {row.customerGroupName}
                        </TableCell>
                        <TableCell className="td" align="center">
                          {CM.cfnIdentificationNoFormat(row.identificationNo)}
                        </TableCell>
                        <TableCell className="td" align="center">
                          <button className="btn-s" onClick={(e) => handleSelectCustomer(row)} data-testid={`donation-targetCustomer-selectButton-${index}`}>
                            선택
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TargetCustomerSearchModal;
