import React, { useEffect, useState } from "react";
import * as CM from "../../components/common/Common";
import BoardTab from "../main/afterLoginHome/BoardTab";
import CheckList from "../main/afterLoginHome/CheckList";
import CSCenter from "../main/afterLoginHome/CustomerServiceCenter";
import LimitSummary from "../main/afterLoginHome/LimitSummary";
import RequestCalendar from "../main/afterLoginHome/RequestCalendar";
import { Popup } from "./Popup";

/*
 * @desc  로그인 후 메인화면 컴포넌트
 */
const Home = () => {
  const [modal, setModal] = useState([]);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  useEffect(() => {
    let mounted = false;
    const fnSearch = async () => {
      const url = "api/support/popups/authorized";
      CM.cfnAxios(url, "get", "", (status, dataList) => {
        const arrPopups = [];
        if (status.status === 200 && CM.cfnIsNotEmpty(dataList) && !mounted) {
          const fnGetImage = (data) => {
            const url = "api/support/popups/authorized/attachment?popupSequence=" + dataList[data].popupSequence;
            CM.cfnAxios(
              url,
              "get",
              "",
              (status, data) => {
                return data.file?.path;
              },
              null,
              false,
              true
            );
          };

          for (let data = 0; data < dataList.length; data++) {
            if (getCookie("popupYn" + dataList[data].popupSequence) !== "N") {
              let imgSrc = dataList[data].popupType === "IMAGE" ? fnGetImage(data) : null;
              dataList[data].imgSrc = imgSrc;
              arrPopups.push(dataList[data]);
            }
          }
        }
        setModal(arrPopups);
      });
    };

    fnSearch();
    return () => (mounted = true);
  }, []);

  const getCookie = (name) => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    const namedCookie = cookieArray.filter((cookie) => {
      return cookie.trim().startsWith(name + "=");
    });
    if (namedCookie.length > 0) {
      return namedCookie[0].trim().substring(name.length + 1);
    } else {
      return null;
    }
  };

  return (
    <div>
      {modal.map((md, i) => (
        <Popup key={i} data={md} isLogin={true} open={currentModalIndex === i} setOpen={() => setCurrentModalIndex(currentModalIndex + 1)} />
      ))}
      <div className="centered">
        <div className="dashboard">
          <div className="area-left">
            <CheckList />
            <LimitSummary />
            <BoardTab />
          </div>
          <div className="area-right">
            <RequestCalendar />
            <CSCenter />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Home };
