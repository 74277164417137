import React, { useState } from "react";

import * as CF from "../../template/ComponentForm";
import { Modal, Button } from "@material-ui/core";

import EvidenceData from "../../customer/customerModal/EvidenceFileData";
import { useEffect } from "react";

/*
 * @desc    메인 컴포넌트 선언
 */
const EvidenceFilePostCheckModal = ({ open, setOpen, originalState, setOriginalState, evidenceFile = {}, setEvidenceFile, fnSubmitEvidenceFile, fnDeleteEvidenceFile, type }) => {
  //필요 state 선언
  const [inModalFile, setInModalFile] = useState(EvidenceData.evidenceFileDefaultValue); //모달 내에서 선택 파일 제어 위해 별도 선언

  useEffect(() => {
    if (evidenceFile.fileType) {
      setInModalFile({ ...inModalFile, fileType: evidenceFile.fileType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidenceFile]);

  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => setOpen(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>동의자료 사후점검</h3>
          <div className="inforbox">
            <ul>
              <li>고객 계좌를 신규 또는 변경 등록 시 사후점검 동의자료를 반드시 첨부해서 금융기관에 송부해야 합니다.</li>
            </ul>
          </div>
          <CF.PostCheckEvidenceFileUploadForm
            inModalFile={inModalFile}
            setInModalFile={setInModalFile}
            evidenceFile={evidenceFile}
            setEvidenceFile={setEvidenceFile}
            fnSubmitEvidenceFile={fnSubmitEvidenceFile}
          />
          <h4>자동납부 동의자료 구분 및 허용 용량</h4>
          <CF.EvidenceFileTypeInformation />
        </div>
      </div>
    </Modal>
  );
};

export default EvidenceFilePostCheckModal;
