import React from "react";
import SendSmsDirectSendTab from "./SendSmsDirectSendTab";
import SendSmsSendHistoryTab from "./SendSmsSendHistoryTab";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import SendSmsTab from "./SendSmsTab";

class SendSms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }
  handleChangeTab = (e, v) => {
    let jsonValue = Object.assign({}, this.state);
    jsonValue["tabIndex"] = v;
    this.handleState(jsonValue);
  };

  handleState = ({ ...stateName }) => {
    let newState = Object.assign({}, this.state);
    Object.keys(stateName).forEach((key) => (newState[key] = stateName[key]));
    this.setState(newState);
  };
  render() {
    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs value={this.state.tabIndex} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary">
            <Tab disableRipple value={0} label="건별발송" />
            <Tab disableRipple value={1} label="일괄발송" />
            <Tab disableRipple value={2} label="발송내역" />
          </Tabs>
        </AppBar>
        <SendSmsTab tabIndex={this.state.tabIndex} />
        <SendSmsDirectSendTab tabIndex={this.state.tabIndex} />
        <SendSmsSendHistoryTab tabIndex={this.state.tabIndex} />
      </div>
    );
  }
}
export default SendSms;
