import { Button, Modal } from "@material-ui/core";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { PaperBill } from "./PaperBillForm";

/*
 * @desc  메인 컴포넌트
 */
const PaperBillPrint = ({ open, setOpen, selectedBills, master }) => {
  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={(e) => setOpen(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>영수증 인쇄</h3>
          <div className="inforbox">
            <ul>
              <li>양식의 글씨가 미리보기에 나타나지 않는 경우 2~3회 다시 시도해주시기 바랍니다.</li>
            </ul>
          </div>
          <ReactToPrint
            trigger={() => (
              <button className="btn-l" style={{ display: "block", marginLeft: "auto" }}>
                영수증 인쇄
              </button>
            )}
            content={() => printRef.current}
            copyStyles={true}
          />
          <div className="print-outer-box" ref={printRef}>
            {selectedBills.map((row, index) => (
              <PaperBill paperBill={row} master={master} key={index} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaperBillPrint;
