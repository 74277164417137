import React from "react";

import remote from "../../../styles/images/remote.png";
import person from "../../../styles/images/person.png";
import faq from "../../../styles/images/faq.png";
import email from "../../../styles/images/email.png";

/*
 * @desc  고객센터 컴포넌트
 */
export default function CustomerServiceCenter() {
  return (
    <div className="cus-sup">
      <h3>고객지원</h3>
      <div className="content-box custo">
        <div className="icon-area">
          <div className="icon">
            <a href="https://help.kftc.or.kr" target="_blank" rel="noopener noreferrer">
              <img src={remote} alt="remote" />
              원격지원서비스
            </a>
          </div>
          <div>
            <a href="http://callcenter.kftc.or.kr/customer/custom_list.jsp?callSite=billingone" target="_blank" rel="noopener noreferrer">
              <img src={person} alt="person" />
              고객제안
            </a>
          </div>
          <div>
            <a href="http://callcenter.kftc.or.kr/qna/qna_list.jsp" target="_blank" rel="noopener noreferrer">
              <img src={faq} alt="qna" />Q & A
            </a>
          </div>
          <div>
            <a href="http://callcenter.kftc.or.kr/qna/qna_form.jsp" target="_blank" rel="noopener noreferrer">
              <img src={email} alt="email_qna" />
              이메일 상담
            </a>
          </div>
        </div>
        <div className="cus-center">
          <div className="cus-center-title">
            고객센터
            <span className="work-time">월~금 / 09:00 ~ 17:45</span>
          </div>
          <div className="phone-num">
            1577-5500
            <span className="phone-num-short">
              (단축 3<span>▶</span>2)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
