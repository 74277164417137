import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow, RadioGroup, Radio, FormControlLabel } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import { TooltipForm } from "../../template/ComponentForm";
import { Link } from "react-router-dom";

// radio 데이터
const radioData = [
  { label: "자동발행 사용(직접발행 가능)", value: true },
  { label: "자동발행 미사용(직접발행만 사용)", value: false },
];

// disable을 처리하는 css
const useStyles = makeStyles((theme) => ({
  disabled: {
    "pointer-events": "none",
    opacity: 0.6,
  },
}));

// 기관 업무 정보 > 부가서비스 정보 관리 > 현금영수증 발급 tab
// 렌더를 처리하는 메인 컴포넌트
export default function TabCashBill(props) {
  const style = useStyles();

  const [cashBillData, setCashBillData] = useState({});

  React.useEffect(() => {
    // cashbillInfo state 초기화
    const initialCashbillInfo = (cashbillInfo, disabled) => {
      if (cashbillInfo.serviceUseYn || disabled) {
        setCashBillData({ ...cashbillInfo });
        return;
      }

      const date = CM.cfnGetDate();
      const url = `api/support-service/gadget/bizDate/next?targetDate=${date}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        const expectedApplyDate = String(data);

        setCashBillData({
          serviceEndDate: null,
          serviceUseYn: false,
          expectedApplyDate,
          autoIssueYn: false,
        });
      });
    };

    initialCashbillInfo(props.cashbillInfo, props.disabled);
  }, [props.cashbillInfo, props.disabled]);

  // 생성방식 변경
  const handleRadioChange = ({ target: { value, name } }) => {
    const flag = value === "true" ? true : false;
    setCashBillData((data) => ({ ...data, [name]: flag }));
  };

  // 적용(희망)일 변경
  const handleDateChange = (expectedApplyDate) => setCashBillData((data) => ({ ...data, expectedApplyDate }));

  // 서비스 신청 또는 설정 변경
  const updateService = () => {
    if (!CM.cfnDateValidation(cashBillData.expectedApplyDate, "적용(희망)일")) {
      return;
    }

    const expectedApplyDate = CM.cfnConvertDateToString(cashBillData.expectedApplyDate);
    const sendData = { ...cashBillData, serviceUseYn: true, expectedApplyDate };

    getAxios(sendData);
  };

  // 서비스 해지
  const terminateService = () => {
    const sendData = { ...cashBillData, serviceUseYn: false };
    sendData.expectedApplyDate = CM.cfnConvertDateToString(sendData.expectedApplyDate);
    getAxios(sendData);
  };

  // 서비스 신청, 변경, 해지 axios
  const getAxios = (sendData) => {
    const url = "api/institute/business/cashbill";
    CM.cfnAxios(url, "put", sendData, (status, data) => {
      CM.cfnAlert("신청이 접수되었습니다.");
    });
  };

  return (
    <div>
      <div className="modify-area">
        <div className="block">{cashBillData.serviceUseYn ? "현재 현금영수증 발급 서비스를 이용 중입니다" : "다음 내용으로 현금영수증 발급 서비스를 신청합니다."}</div>
      </div>
      <div>
        <h4>서비스 설정(현금영수증 발행)</h4>
      </div>
      <div>
        <Table>
          {CM.cfnCompColGroup(["15%", "20%", "15%", "auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">적용(희망)일</TableCell>
              <TableCell className={`td ${props.disabled ? style.disabled : null}`}>
                <CF.DatePickerForm
                  value={cashBillData.expectedApplyDate}
                  handleChange={handleDateChange}
                  className={"w150 fl"}
                  customProps={{
                    inputProps: { "data-testid": "tabCashbill-datepicker-expectedApplyDate" },
                  }}
                />
              </TableCell>
              <TableCell className="th">
                금융결제원 자동발행
                <br />
                사용여부
                <TooltipForm
                  contents="(금융결제원 자동발행) 고객으로부터 CMS로 수납한 내역에 대해, 금융결제원이 자동으로 현금영수증 내역을 생성하여 국세청에 통보<br/>
            (직접발행) 고객으로부터 수납한 내역에 대해, 직접 현금영수증 내역을 생성하여 국세청에 통보"
                />
              </TableCell>
              <TableCell className={`td ${props.disabled ? style.disabled : null}`}>
                <RadioGroup name="autoIssueYn" value={!!cashBillData.autoIssueYn} onChange={handleRadioChange} data-testid="tabCashbill-radio-autoIssueYn" row>
                  {radioData.map((element, index) => {
                    return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
                  })}
                </RadioGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ServiceButtonForm useYn={cashBillData.serviceUseYn} updateService={updateService} terminateService={terminateService} style={style} disabled={props.disabled} />
      </div>
      <div className="inforbox">
        <h4>서비스안내</h4>
        <ul>
          <li>최초 신청 시 국세청 등록 절차가 필요하며, 국세청 승인 결과는 업무 담당자의 이메일 및 휴대폰으로 알려드립니다.</li>
          <li>
            승인 진행상황은{" "}
            <Link to="/institute/changeHistory" className="link">
              기관/업무정보 &gt; 정보변경 이력
            </Link>
            에서 확인하실 수 있습니다.
          </li>
        </ul>
      </div>
    </div>
  );
}

// 서비스 신청 버튼 컴포넌트
function ServiceButtonForm(props) {
  const { useYn, updateService, terminateService, style, disabled } = props;

  // 사용여부 체크
  if (useYn) {
    return (
      <div className={`table-bottom-area ${disabled ? style.disabled : null}`}>
        <button className="btn-l" onClick={updateService} data-testid="tabCashbill-button-updateService">
          설정 변경
        </button>
        <button className="btn-l" onClick={terminateService} data-testid="tabCashbill-button-terminateService">
          서비스 중지
        </button>
      </div>
    );
  }

  return (
    <div className={`table-bottom-area ${disabled ? style.disabled : null}`}>
      <button className="btn-l" onClick={updateService} data-testid="tabCashbill-button-requestService">
        서비스 신청
      </button>
    </div>
  );
}
