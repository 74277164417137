export const buildUrlObject = {
  test: "https://test.billingone.or.kr:28888",
  development: "https://local.billingone.or.kr:28888",
  production: "https://asp.billingone.or.kr"
};

export const accountRegistrationStatusLabel = {
  "": "전체",
  READY_TO_REGISTER: "등록대기",
  REGISTERING: "등록중",
  REGISTERED: "등록완료",
  FAILED_TO_REGISTER: "등록실패",
  READY_TO_UNREGISTER: "해지대기",
  UNREGISTERING: "해지중",
  UNREGISTERED: "해지완료",
  FAILED_TO_UNREGISTER: "해지실패"
};

export const transactionMethodLabel = {
  CMS_WITHDRAW: "CMS수납",
  AUTOMATIC_TRANSFER: "자동이체",
  ELECTRONIC_RECEIPT: "전자수납",
  MICR: "MICR",
  OCR: "OCR",
  FIXED_OCR: "정액OCR",
  CMS_DEPOSIT: "CMS지급",
  ETC: "기타"
};

export const searchType = {
  customerName: "고객명",
  customerMobile: "휴대전화번호"
};

export const serverCustomerType = {
  RECEIPT: "수납고객",
  PAYMENT: "지급고객"
};
