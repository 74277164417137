import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import * as CM from "../../common/Common";

import DonationData from "./DonationBillData";
import * as DonationForm from "./DonationForm";

import BasedLawModal from "./BasedLawModal";
import SealImageModal from "./SealImageModal";
import { AddressSearchModal } from "../../customer/customerModal/AddressSearchModal";

// 부가서비스 > 기부금영수증 > 기부금영수증 양식관리 tab
// 렌더를 처리하는 메인 컴포넌트
const DonationFormManagementTab = ({ tabIndex, store, sealImage, setSealImage, donationForm, setDonationForm }) => {
  //필요 state 선언
  const [tempDonationForm, setTempDonationForm] = useState(donationForm);
  const [tempSealImage, setTempSealImage] = useState(sealImage); //선택된 직인 이미지 제어
  const [institute, setInstitute] = useState({}); //기관정보

  //주소 검색 모달 전달 파라미터
  const addressColumnList = {
    zipCode: "customerZipCode",
    addressCode: "customerAddress",
  };

  //모달 제어
  const [basedRawModalOpen, setBasedRawModalOpen] = useState(false); //기부금단체 근거법령 모달
  const [sealImageModalOpen, setSealImageModalOpen] = useState(false); //직인이미지 찾기 모달
  const [addressOpen, setAddressOpen] = useState(false); //주소 검색 모달
  const [selectedAddressButton, setSelectedAddressButton] = useState(""); //단체정보 or 모집처 주소 검색 버튼 중 어떤 버튼이 선택되었는지 구분

  // store로 부터 기관 정보 받아오기
  useEffect(() => {
    async function getMaster() {
      const result = await store.axiosMaster();
      setInstitute(result);
    }

    if (tabIndex === 0 && CM.cfnIsEmpty(institute)) {
      getMaster();
    }
  }, [store, institute, tabIndex]);

  /*
   * @desc  기부금영수증 양식 입력란 변경 이벤트 핸들러
   */

  const handleDonationFormChange = (name) => (e) => {
    switch (name) {
      case "sealPrintYn":
        setTempDonationForm({
          ...tempDonationForm,
          [name]: e.target.value === "true" ? true : false,
        });
        break;
      case "serialNoPrefix":
        setTempDonationForm({
          ...tempDonationForm,
          [name]: e.target.value.toUpperCase(),
        });
        break;
      case "serialNumberType":
        //일련번호체계를 '숫자일련번호'로 선택했을 시 영문대문자(고정내용) 초기화
        if (e.target.value !== "EN") {
          setTempDonationForm({
            ...tempDonationForm,
            serialNoPrefix: "",
            [name]: e.target.value,
          });
        } else {
          setTempDonationForm({
            ...tempDonationForm,
            [name]: e.target.value,
          });
        }
        break;
      case "isIdenticalGroup":
        //기부금 단체정보 영역 내 '기관정보와 동일' 체크박스 체크 시 처리
        if (e.target.checked) {
          setTempDonationForm({
            ...tempDonationForm,
            instituteName: institute.instName,
            identificationNo: institute.bizNo,
            zipCode: institute.address && institute.address.zipCode ? institute.address.zipCode : tempDonationForm.zipCode,
            address: institute.address && institute.address.address ? institute.address.address : tempDonationForm.address,
            addressDetail: institute.address && institute.address.addressDetail ? institute.address.addressDetail : tempDonationForm.addressDetail,
            [name]: e.target.checked,
          });
        } else {
          setTempDonationForm({
            ...tempDonationForm,
            instituteName: "",
            identificationNo: "",
            zipCode: "",
            address: "",
            addressDetail: "",
            [name]: e.target.checked,
          });
        }
        break;
      case "isIdenticalRaiser":
        //기부금 모집처 정보 영역 내 '기관정보와 동일' 체크박스 체크 시 처리
        if (e.target.checked) {
          setTempDonationForm({
            ...tempDonationForm,
            raiserInstituteName: institute.instName,
            raiserIdentificationNo: institute.bizNo,
            raiserZipCode: institute.address && institute.address.zipCode ? institute.address.zipCode : tempDonationForm.raiserZipCode,
            raiserAddress: institute.address && institute.address.address ? institute.address.address : tempDonationForm.raiserAddress,
            raiserAddressDetail: institute.address && institute.address.addressDetail ? institute.address.addressDetail : tempDonationForm.raiserAddressDetail,
            [name]: e.target.checked,
          });
        } else {
          setTempDonationForm({
            ...tempDonationForm,
            raiserInstituteName: "",
            raiserIdentificationNo: "",
            raiserZipCode: "",
            raiserAddress: "",
            raiserAddressDetail: "",
            [name]: e.target.checked,
          });
        }
        break;
      default:
        setTempDonationForm({
          ...tempDonationForm,
          [name]: e.target.value,
        });
        break;
    }
  };

  /*
   * @desc  기부금영수증 양식 저장 버튼 클릭 이벤트 핸들러
   */

  const handleClickSaveButton = (e) => {
    if (DonationData.fnCheckDonationFormValidation(tempDonationForm, tempSealImage)) fnSaveDonationForm();
  };

  /*
   * @desc  기부금단체 근거법령 찾기 버튼 클릭 이벤트 핸들러
   */
  const handleClickSearchLawButton = () => {
    setBasedRawModalOpen(true);
  };

  /*
   * @desc  직인 이미지 찾기 버튼 클릭 이벤트 핸들러
   */
  const handleClickSearchSealImageButton = () => {
    setSealImageModalOpen(true);
  };

  /*
   * @desc  주소 조회 버튼 클릭 이벤트 핸들러
   */
  const handleAddressModal = (type) => {
    setSelectedAddressButton(type);
    setAddressOpen(true);
  };

  /*
   * @desc  근거법령 조회 모달 내 선택 이벤트 핸들러
   */
  const handleSelectRaw = (obj) => {
    setTempDonationForm({
      ...tempDonationForm,
      basedLaw: obj.basedLaw,
      basedLawCode: obj.basedLawCode,
    });
  };

  /*
   * @desc  주소 조회 모달 내 선택 이벤트 핸들러
   */
  const handleSelectAddress = ({ ...address }) => {
    let tempAddressObj = CM.cfnCopyObject(tempDonationForm);

    Object.keys(address).forEach((key) => {
      if (key === "customerZipCode") {
        const fieldName = selectedAddressButton === "" ? "zipCode" : "raiserZipCode";
        tempAddressObj[fieldName] = address[key];
      } else if (key === "customerAddress") {
        const fieldName = selectedAddressButton === "" ? "address" : "raiserAddress";
        tempAddressObj[fieldName] = address[key];
      }
    });

    setTempDonationForm(tempAddressObj);
  };

  /*
   * @desc  기부금영수증 양식 저장 Request
   */
  const fnSaveDonationForm = () => {
    const url = "api/extraservice/donations/formats";

    const form = new FormData();
    form.append("accountUniqueKey", new Blob([JSON.stringify(tempDonationForm)], { type: "application/json" }));
    form.append("sealImageFile", tempSealImage.file);

    CM.cfnAxios(
      url,
      "post",
      form,
      (status, data) => {
        if (status.status === 200) {
          CM.cfnAlert("정상적으로 처리되었습니다.");
          setDonationForm(tempDonationForm);
          setSealImage(tempSealImage);
          setSealImageModalOpen(false);
        } else {
          if (typeof data === "string") {
            CM.cfnAlert(data);
          } else {
            CM.cfnAlert(data.message);
          }
        }
      },
      "",
      true
    );
  };

  return (
    <div>
      <div>
        <DonationForm.BasicInformation donationForm={tempDonationForm} handleDonationFormChange={handleDonationFormChange} />
        <DonationForm.GroupInformation
          donationForm={tempDonationForm}
          handleDonationFormChange={handleDonationFormChange}
          sealImage={tempSealImage}
          handleClickSearchLawButton={handleClickSearchLawButton}
          handleAddressModal={handleAddressModal}
          handleClickSearchSealImageButton={handleClickSearchSealImageButton}
        />
        <DonationForm.RaiserInformation donationForm={tempDonationForm} handleDonationFormChange={handleDonationFormChange} handleAddressModal={handleAddressModal} />
      </div>
      <div className="table-bottom-area">
        <button type="button" className="btn-l" onClick={handleClickSaveButton}>
          저장
        </button>
      </div>
      <BasedLawModal open={basedRawModalOpen} setOpen={setBasedRawModalOpen} handleSelectRaw={handleSelectRaw} />
      <SealImageModal open={sealImageModalOpen} setOpen={setSealImageModalOpen} donationForm={tempDonationForm} sealImage={tempSealImage} setSealImage={setTempSealImage} />{" "}
      <AddressSearchModal open={addressOpen} setOpen={setAddressOpen} setAddress={handleSelectAddress} addressColumnList={addressColumnList} />
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(DonationFormManagementTab));
