import * as CM from "../../common/Common";

// 검색 데이터
export function searchData() {
  return {
    searchStartDate: "", //검색 시작 수납일
    searchEndDate: "", //검색 종료 수납일
    search: "",
    terms: "CUSTOMER_NAME",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
    contractProperty: "",
    groupUniqueKey: "",
    staffUniqueKey: ""
  };
}

// 페이지네이션 data
export const paginationData = data => {
  const returnObject = {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1
  };

  if (CM.cfnIsNotEmpty(data)) {
    returnObject.rowsPerPage = data.pageable.pageSize;
    returnObject.offset = data.pageable.offset;
    returnObject.total = data.totalElements;
    returnObject.totalPages = data.totalPages;
  }

  return returnObject;
};

// 검색옵션 데이터
export function searchOptionData() {
  return [{ value: "CUSTOMER_NAME", label: "고객명" }, { value: "IDENTIFICATION_NO", label: "생년월일(사업자번호)" }, { value: "CREATED_DATE", label: "최초등록일" }];
}

// 요약 데이터
export function summaryData(data) {
  return [
    {
      value: data.totalCustomerCount,
      label: "전체등록고객"
    },
    {
      value: data.receiptCustomerCount,
      label: "수납고객정보"
    },
    {
      value: data.paymentCustomerCount,
      label: "지급고객정보"
    }
  ];
}
