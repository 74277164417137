import React, { useState } from "react";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";

import PaperTaxBillPublishTargetTab from "./PaperTaxBillPublishTargetTab";
import PaperTaxBillIssueHistoryTab from "./PaperTaxBillIssueHistoryTab";

//부가서비스 > 계산서 발행 > 종이세금계산서 메인 tab
//렌더를 처리하는 메인 컴포넌트
const PaperTaxBill = () => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };
  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="account-tab">
          <Tab disableRipple value={0} label="발행대상 조회 및 발행" data-testid="tab-paperTaxbill-targets" />
          <Tab disableRipple value={1} label="발행내역" data-testid="tab-paperTaxbill-issued" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <div>
          <div className="inforbox">
            <ul>
              <li>고객에게 종이세금계산서를 발행할 수 있습니다.</li>
              <li>종이세금계산서는 발행내역이 국세청에 내용이 전송되지 않으며 계산서로서 법적효력이 없으니 유의하시기 바랍니다.</li>
            </ul>
          </div>
        </div>
        <PaperTaxBillPublishTargetTab tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <PaperTaxBillIssueHistoryTab tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default PaperTaxBill;
