import React from "react";
import * as Common from "../../common/Common";

const CashbillTabTargetsSummary = ({ list }) => {
  return (
    <div className="summary-area">
      <div className="text-graph">
        <label>신청일</label>
        <span>{Common.cfnDateFormat(list.transactionDate)}</span>
      </div>
      <div className="text-graph" style={{ width: "200px" }}>
        <label>현금영수증 발행 대상 수납일</label>
        <span>{`${Common.cfnDateFormat(list.askTargetStartDate)} ~ ${Common.cfnDateFormat(list.askTargetEndDate)}`}</span>
      </div>
      <div className="text-graph">
        <label>대상건수</label>
        <span>{Common.cfnAddComma(list.numberOfAskedRecords)}건</span>
      </div>
      <div className="text-graph">
        <label>대상금액</label>
        <span>{Common.cfnAddComma(list.totalAskingAmount)}원</span>
      </div>
    </div>
  );
};

export default CashbillTabTargetsSummary;
