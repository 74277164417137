import { Button, Checkbox, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { EditableCellInput, PaginationForm, RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsPaymentData";

//모달창 크기 조정 위해 추가 선언
const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "800px",
  },
});

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const { searchRequest, handleSearchFormChange } = props;

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area centered" data-testid="search-area">
      <div className="block">
        <label className="label-l">고객구분</label>
        <Select native name="groupUniqueKey" value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")}>
          <option value="">전체</option>
          {props.customerGroupOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native name="capitalUniqueKey" value={searchRequest.capitalUniqueKey} onChange={handleSearchFormChange("capitalUniqueKey")}>
          <option value="">전체</option>
          {props.capitalOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <SelectForm value={searchRequest.term} handleChange={handleSearchFormChange("term")} name="term" arrayOption={CmsData.targetSearchKeyword} optionValue={"value"} optionLabel={"label"} />
        <TextField className="w150" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" />
        <button className="search-button" onClick={() => fnSearch()} data-testid="search-target-add">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of props.targetList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const targetLength = props.targetList.length;

    if (checkAllFlag === targetLength && targetLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [props.targetList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleDeleteChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.targetList];
    cloneData[index][key] = value;
    props.handleTargetListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.handleTargetListChange((target) => target.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  /*
   * @desc    테이블 내 입력란 변경 이벤트 핸들러
   */
  const handleTargetRowsChange = (row, change) => {
    const tempArray = [...props.targetList];
    const key = row.contractUniqueKey;
    let rowIndex = null;
    tempArray.forEach((row, index) => {
      if (row.contractUniqueKey === key) rowIndex = index;
    });

    if (change.fieldName === "totalAskingAmount") {
      tempArray[rowIndex][change.fieldName] = Number(change.fieldValue.replace(/[^0-9]/g, ""));
    } else {
      tempArray[rowIndex][change.fieldName] = change.fieldValue;
    }

    props.handleTargetListChange(tempArray);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["50px", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compServerSortTableHead
          checked={checkAllRow}
          useCheckbox={true}
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
            { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true },
            { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true },
            { id: "CAPITAL", label: "자금종류", sortable: true },
            {
              id: "FINANTIAL_INSTITUTE_CODE",
              label: "금융기관",
              sortable: false,
            },
            { id: "ACCOUNT_NO", label: "계좌번호", sortable: false },
            {
              id: "TOTAL_ASKING_AMOUNT",
              label: "입금청구금액",
              sortable: false,
            },
            { id: "BANKBOOK_CONTENTS", label: "통장기재내역", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.targetList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            props.targetList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center" name="?" index="0">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`selectCheckbox-${index}`}
                      onChange={handleDeleteChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center"> {CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center"> {row.capitalName}</TableCell>
                  <TableCell align="center">{CM.cfnMatchBankName(row.finantialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center"> {row.accountNo}</TableCell>
                  <TableCell align="center">
                    <EditableCellInput
                      row={row}
                      fieldName="totalAskingAmount"
                      onCellValueChange={handleTargetRowsChange.bind(this, row)}
                      needComma={true}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <EditableCellInput
                      row={row}
                      fieldName="bankbookContents"
                      onCellValueChange={handleTargetRowsChange.bind(this, row)}
                      inputProps={{
                        style: { textAlign: "left" },
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const CmsPaymentRequestTargetAdd = (props) => {
  const { open, setOpen, fileUniqueKey, customerGroupOptions } = props;

  const classes = useStyles();

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(CmsData.addTargetSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalTargetList, setOriginalTargetList] = useState([]); // 서버로 부터 전달받은 original list
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());

  const excelInput = React.useRef(""); //엑셀파일 선택 제어

  /*
   * @desc 입금청구 추가대상 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);

    if (tempObj.term === "customerName") {
      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.identificationNo = "";
    } else if (tempObj.term === "identificationNo") {
      tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
      tempObj.customerName = "";
    }

    tempObj.fileUniqueKey = fileUniqueKey;

    return tempObj;
  }, [fileUniqueKey, searchRequest]);

  // 테이블 데이터 검색
  useEffect(() => {
    // axios request
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/cms/contents/addition?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&identificationNo=${search.identificationNo}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          () => {
            handleSearchButtonChange(false);
          }
        );
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      // 체크 옵션 추가
      for (const element of resultList) {
        element._checked = false;
      }

      setOriginalTargetList(resultData);
      handleTargetListChange(resultList);
      setPagination(CmsData.paginationData(resultData));
    };

    //실행영역
    if (open && searchButton === true) {
      const param = fnMakeParameter();
      handleSearchButtonChange(false);
      startAxios(param);
    }
  }, [open, searchRequest, searchButton, fileUniqueKey, fnMakeParameter]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 청구 대상 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    창닫기 버튼 클릭 이벤트 핸들러
   */
  const handleTargetAddModalClose = () => {
    handleSearchButtonChange(true);
    setOpen(false);
  };

  /*
   * @desc    엑셀저장 Request
   */
  const fnDownloadAddTargetExcel = () => {
    const search = fnMakeParameter();
    const url = `api/payment/cms/contents/addition/excel?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&identificationNo=${search.identificationNo}&resultCode=${search.resultCode}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    일괄추가 버튼 클릭 이벤트 핸들러
   */
  const handleClickBulkAdd = () => {
    excelInput.current.click();
  };

  /*
   * @desc    입금청구대상 일괄추가 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc    입금청구대상 일괄추가 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/payment/cms/contents/addition/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey ",
      new Blob([JSON.stringify(fileUniqueKey)], {
        type: "application/json",
      })
    );

    CM.cfnAxios(
      url,
      "post",
      form,
      (status, data) => {
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {
          props.setIsTargetChanged(true); //입금청구완료 목록 재조회
          handleTargetAddModalClose();
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      }
    );
  };

  /*
   * @desc    선택항목 추가 버튼 클릭 이벤트 핸들러
   */
  const handleClickTargetAdd = () => {
    if (fnCheckValidation()) {
      CM.cfnConfirm("선택한 항목을 추가하시겠습니까?", fnAddTarget);
    }
  };

  /*
   * @desc    선택항목 추가 전 validation 체크 함수
   */
  const fnCheckValidation = () => {
    const tempArray = [];

    for (const element of targetList) {
      if (element._checked && element.totalAskingAmount !== 0) {
        tempArray.push(element);
      } else if (element._checked && element.totalAskingAmount === 0) {
        CM.cfnAlert("선택된 항목의 입금청구금액을 입력하여 주시기 바랍니다.");
        return false;
      }
    }

    //체크된 행 없을 시
    if (CM.cfnIsEmpty(tempArray)) {
      CM.cfnAlert("선택된 항목이 없습니다.");
      return false;
    }

    return true;
  };

  /*
   * @desc    입금청구대상 추가 Request
   */
  const fnAddTarget = () => {
    const url = `api/payment/cms/contents/addition/${fileUniqueKey}`;

    CM.cfnAxios(
      url,
      "post",
      targetList.filter((element) => element._checked).map((element) => ({ ...element, totalAskingAmount: Number(CM.cfnReplaceSymbol(element.totalAskingAmount)) })),
      fnAddTargetCallback
    );
  };

  /*
   * @desc    입금청구대상 추가 Request Callback
   */
  const fnAddTargetCallback = async (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    CM.cfnAlert("정상적으로 처리되었습니다. 추가된 내용은 입금대상 추가 화면을 닫으면 확인 가능합니다.");
    await props.setIsTargetChanged(true); //입금청구완료 목록 재조회
  };

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleTargetAddModalClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>입금 대상 추가</h3>
          <div>
            <SearchForm
              searchRequest={searchRequest}
              capitalOptions={props.capitalOptions}
              customerGroupOptions={customerGroupOptions}
              handleSearchFormChange={handleSearchFormChange}
              handleSearchButtonChange={handleSearchButtonChange}
            />
            <div className="table-top-area">
              <TotalCountForm totalElements={originalTargetList.totalElements || 0} />
              <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleSelectExcelFile(e)}
                ref={excelInput}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                value=""
                data-testid="select-excel-bulk-add"
              />
              <div className="btn-m fr" onClick={handleClickBulkAdd} data-testid="target-add-excel">
                [일괄추가]엑셀파일 등록
              </div>
              <div className="btn-m fr" onClick={fnDownloadAddTargetExcel} data-testid="list-save-excel">
                [일괄추가양식]엑셀저장
              </div>
              <div className="btn-l fr" onClick={handleClickTargetAdd}>
                선택항목 추가
              </div>
            </div>
            <ListForm
              targetList={targetList}
              handleTargetListChange={handleTargetListChange}
              financialInstitutes={props.financialInstitutes}
              searchRequest={searchRequest}
              handleSortProperty={handleSortProperty}
            />
            <PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(CmsPaymentRequestTargetAdd));
