import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";

import AccountRealNameCheck from "./AccountRealNameCheck";
import AccountRegisterRequest from "./AccountRegisterRequest";
import RegisterRequestHistories from "./RegisterRequestHistories";
import ChargeModal from "./ChargeModal";
const AccountCheck = ({ store }) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };
  const [chargeModal, setChargeModal] = useState(false);
  const [financialInstitutes, setFinancialInstitutes] = useState([]);

  useEffect(() => {
    const initFinancialInstitutes = async () => {
      setFinancialInstitutes(await store.axiosFinancialRepresentativeInstitutes());
    };
    initFinancialInstitutes();
  }, [store]);

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="account-tab">
          <Tab disableRipple value={0} label="계좌실명조회" />
          <Tab disableRipple value={1} label="실시간계좌등록/해지요청" />
          <Tab disableRipple value={2} label="실시간계좌등록요청 내역" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <div>
          <div className="inforbox">
            <ul>
              <li>계좌번호와 계좌소유주 생년월일(사업자)번호가 정확한지 실시간으로 확인하는 서비스입니다.</li>
              <li>서비스이용시간 : 매일 08:00 ~ 22:00</li>
              <li>
                본 서비스는 이용 건당 별도의 요금이 부과되는 유료서비스입니다.{" "}
                <div className="btn-s" onClick={(e) => setChargeModal(true)}>
                  수수료조회
                </div>
              </li>
            </ul>
          </div>
        </div>
        <AccountRealNameCheck tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div>
          <div className="inforbox">
            <ul>
              <li>수납고객정보에 계좌상태가 '신규등록', '해지등록' 상태인 고객을 자동 검색하여 실시간으로 신규,해지 등록을 요청하는 메뉴입니다.</li>
              <li>서비스 이용시간 : 매일 08:00 ~ 22:00</li>
              <li>신규신청 및 변경신규 내역에는 전송 전에 자동납부 동의자료를 첨부하시기 바랍니다.</li>
              <li>금융기관의 통신상태에 따라 건당 최대 30초까지 소요될 수 있습니다.</li>
              <li>
                본 서비스는 이용 건당 별도의 요금이 부과되는 유료서비스입니다.{" "}
                <div className="btn-s" onClick={(e) => setChargeModal(true)}>
                  수수료조회
                </div>
              </li>
            </ul>
          </div>
        </div>
        <AccountRegisterRequest tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <RegisterRequestHistories tabIndex={tabIndex} />
      </TabPanel>
      <ChargeModal open={chargeModal} setOpen={setChargeModal} financialInstitutes={financialInstitutes} />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(AccountCheck));
