import React, { useEffect, useState } from "react";
import {Table, TableBody, TableRow, TableCell, Select, TextField} from "@material-ui/core";
import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import * as Template from "../../template/ComponentForm";

// 검색 데이터
function searchData() {
    return {
        transactionDate: "",
        eventSequence: "",
        transactionStatus: "",
        capitalUniqueKey: "",
        groupUniqueKey: "",
        customerName: "",
        payerNo: "",
        sortDirection: "ASC",
        sortProperty: "CUSTOMER_NAME",
        searchSelect: "customerName",
        keyword: "",
        pageNumber: 0,
        pageSize: 5,
    };
}

// 페이징 데이터
function paginationData(data) {
    if (CM.cfnIsEmpty(data)) {
        return {
            rowsPerPage: 5,
            offset: 0,
            total: 0,
            totalPages: 1,
        };
    }

    return {
        rowsPerPage: data.pageable.pageSize,
        offset: data.pageable.offset,
        total: data.totalElements,
        totalPages: data.totalPages,
    };
}
/*
 * @desc 결과 요약 정보 컴포넌트 생성
 */
function AutoWithdrawFailResultSummary(props) {
    const headRowOne = [
        { value : "작업일시", rowSpan: 2},
        { value : "출금청구내역", colSpan: 2},
        { value : "진행상태", rowSpan: 2}
    ];
    const headRowTwo = [{ value : "건수" }, { value : "금액"}];
    return (
        <div>
            <Table>
                {CM.cfnCompColGroup(["25%", "15%", "20%", "auto"])}
                <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />
                <TableBody>
                    <React.Fragment>
                        <TableRow>
                            <TableCell align="center">{CM.cfnDateFormat(props.autoWithdrawSummary.eventDate + props.autoWithdrawSummary.eventTime)}</TableCell>
                            <TableCell align="center">{CM.cfnAddComma(props.autoWithdrawSummary.totalAskRecords)}건</TableCell>
                            <TableCell align="center">{CM.cfnAddComma(props.autoWithdrawSummary.totalAskingAmount)}원</TableCell>
                            <TableCell align="center">{props.autoWithdrawSummary.resultMessage}</TableCell>
                        </TableRow>
                    </React.Fragment>
                </TableBody>
            </Table>

        </div>
    )
};

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
    const { searchRequest, handleSearchFormChange } = props;

    /*
     * @desc     키보드 이벤트 핸들러
     */
    const handleSearchKeyUp = (e) => {
        if (e.keyCode === 13) {
            fnSearch();
        }
    };

    /*
     * @desc    검색버튼 클릭 이벤트 핸들러
     */
    const fnSearch = () => {
        props.handleSearchButtonChange(true);
    };

    return (
        <div className="search-area tl">
            <div className="block">
                <label className="label-l">고객구분</label>
                <Select native value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")} name="groupUniqueKey">
                    <option value="">전체</option>
                    {props.storeCustomerGroup.map((option, index) => {
                        return (
                            <option value={option.uniqueKey} key={index}>
                                {option.customerGroupName}
                            </option>
                        );
                    })}
                </Select>
                <label className="label-l">자금종류</label>
                <Select native value={searchRequest.capitalUniqueKey} onChange={handleSearchFormChange("capitalUniqueKey")} name="capitalUniqueKey">
                <option value="">전체</option>
                {props.storeCapital.map((option, index) => {
                    return (
                        <option value={option.uniqueKey} key={index}>
                            {option.capitalName}
                        </option>
                    );
                })}
            </Select>
                <label className="label-l">검색어입력</label>
                <Select native value={searchRequest.searchSelect} onChange={handleSearchFormChange("searchSelect")} inputProps={{ "data-testid": "electronictaxbillHistoryList-select-terms" }}>
                    <option value="customerName">고객명</option>
                    <option value="payerNo">납부자번호</option>
                </Select>
                <TextField
                    className="w130"
                    value={searchRequest.searchInput}
                    onChange={handleSearchFormChange("searchInput")}
                    onKeyUp={handleSearchKeyUp}
                    name="search"
                    inputProps={{ "data-testid": "electronictaxbillHistoryList-textfield-search" }}
                />
                <button className="search-button" onClick={(e) => fnSearch()}>
                    검색
                </button>
            </div>
        </div>
    );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
    return (
        <div>
            <Table>
                {CM.cfnCompColGroup(["7%", "6%", "9%", "6%", "6%", "12%", "7%", "9%", "9%", "10%", "9%", "10%"])}
                <TableForm.compServerSortDoubleRowTableHead
                    useCheckbox={false}
                    rowOne={[
                        { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
                        { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true, rowSpan: 2 },
                        { id: "PAYER_NO", label: "납부자\n번호", sortable: true, rowSpan: 2 },
                        { id: "CAPITAL", label: "자금종류", sortable: true, rowSpan: 2 },
                        { id: "ACCOUNT_BANK", label: "금융기관", sortable: false, rowSpan: 2 },
                        { id: "ACCOUNT_NO", label: "계좌번호", sortable: false, rowSpan: 2 },
                        { id: "TARGET_Y4MM", label: "수납년월", sortable: false, rowSpan: 2 },
                        {
                            id: "CMS_RECEIPT_TARGET",
                            label: "출금청구금액",
                            sortable: false,
                            colSpan: 5,
                        },
                    ]}
                    rowTwo={[
                        {
                            id: "TOTAL_ASKING_AMOUNT",
                            label: "합계",
                            sortable: true,
                        },
                        { id: "THIS_TIME_AMOUNT", label: "청구일분", sortable: true },
                        {
                            id: "PREVIOUSLY_UNASKED_AMOUNT",
                            label: "미청구분",
                            sortable: true,
                        },
                        { id: "ASKING_UNPAID_AMOUNT", label: "미납분", sortable: true },
                        { id: "ASKING_DELAY_FEE", label: "미납\n연체료", sortable: true },
                    ]}
                    searchRequest={props.searchRequest}
                    handleSortProperty={props.handleSortProperty}
                    tableSortLabelDataTestId=""
                />
                <TableBody>
                    {props.withdrawFailList.length === 0 ? (
                        <TableForm.compEmptyTableRow colSpan={13} />
                    ): (props.withdrawFailList.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align="center">{row.customerName}</TableCell>
                                <TableCell align="center">{row.customerGroupName}</TableCell>
                                <TableCell align="center">{row.payerNo}</TableCell>
                                <TableCell align="center">{row.capitalName}</TableCell>
                                <TableCell align="center">{row.accountBankName}</TableCell>
                                <TableCell align="center">{CM.cfnMasking(row.accountNo)}</TableCell>
                                <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.totalAskingAmount)}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.thisTimeAmount)}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.previouslyUnaskedAmount)}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.askingUnpaidAmount)}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.askingDelayFee)}원</TableCell>
                            </TableRow>
                        )
                    }))}
                </TableBody>
            </Table>
        </div>
    );
}

/*
 * @desc  메인 컴포넌트
 */
function CmsReceiptAutoWithdrawFailResult(props) {

    const { tabIndex } = props;
    const { store } = props;
    const { autoWithdrawSummary } = props;
    const [excelButton, setExcelButton] = useState(false);
    const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
    const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
    const [originalList, setOriginalList] = useState([]);
    const [withdrawFailList, setWithdrawFailList] = useState([]); // table 데이터
    const [pagination, setPagination] = useState(paginationData());
    const [customerGroupList, setCustomerGroupList] = useState([]); //고객구분 옵션 제어
    const [capitalList, setCapitalList] = useState([]); // 자금종류 옵션 제어

    useEffect(() => {
        /*
         * @desc  파라미터 생성 함수
         */
        const fnMakeParameter = () => {
            let tempObj = CM.cfnCopyObject(searchRequest);

            tempObj.transactionDate = autoWithdrawSummary.transactionDate;
            tempObj.eventSequence = autoWithdrawSummary.eventSequence;
            tempObj.transactionStatus = autoWithdrawSummary.transactionStatus;

            return tempObj;
        };

        /*
         * @desc    결과조회 Request
         */
        const axiosList = (search) => {
            return new Promise((resolve) => {
                let url = `/api/receipt/cms/autowithdraw/resultList?transactionDate=${search.transactionDate}&eventSequence=${search.eventSequence}&capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&customerName=${search.customerName}&payerNo=${search.payerNo}&sortProperty=${search.sortProperty}&sortDirection=${search.sortDirection}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}`;
                CM.cfnAxios(url, "get", "", (status, data) => {
                    resolve(data);
                });
            });
        };

        /*
         * @desc  엑셀저장
         */
        const axiosExcel = (search) => {
            return new Promise((resolve) => {
                let url = `/api/receipt/cms/autowithdraw/excel?transactionDate=${search.transactionDate}&eventSequence=${search.eventSequence}&capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&customerName=${search.customerName}&payerNo=${search.payerNo}&sortProperty=${search.sortProperty}&sortDirection=${search.sortDirection}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}`;
                CM.cfnAxiosFileDownload(url, "get", "", () => {});
            });
        };

        // start axios and set table data
        async function startAxios(search, buttonType) {
            if (buttonType === "excel") {
                await setExcelButton(false);
                await axiosExcel(search);
            } else {
                await setSearchButton(false);

                const resultData = await axiosList(search);

                let resultList = resultData.content;
                if (CM.cfnIsEmpty(resultList)) {
                    resultList = [];
                }

                await setOriginalList(resultData);
                await setWithdrawFailList(resultList);
                await setPagination(paginationData(resultData));
            }
        }

        // 실행 영역
        if (searchButton === true) {
            const param = fnMakeParameter();
            startAxios(param, "search");
        } else if (excelButton === true) {
            const param = fnMakeParameter();
            startAxios(param, "excel");
        }
    }, [tabIndex, searchRequest, searchButton, excelButton, autoWithdrawSummary]);

    // 검색조건 고객구분 및 자금종류 데이터 가져오기
    useEffect(() => {
        const getStoreDataAxios = async () => {
            setCustomerGroupList(await store.axiosCustomerGroup()); // 고객구분
            setCapitalList(await store.axiosCapitals()); // 자금종류
        };
        getStoreDataAxios();
    }, [store]);

    /*
     * @desc    페이지 변경 이벤트 핸들러 (검색)
     */
    const handleOffsetChange = (offset, page) => {
        handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
        handleSearchButtonChange(true);
    };

    /*
     * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
     */
    const handleRowPerPageChange = (e) => {
        const value = e.target.value;
        handleSearchRequestChange((data) => ({ ...data, pageSize: value, pageNumber : 0 }));
        handleSearchButtonChange(true);
    };

    /*
     * @desc    실시간 실명조회 결과조회 검색 조건 입력란 변경 이벤트 핸들러
     */
    const handleSearchFormChange = (name) => (e) => {
        if (name === "searchInput") {
            if (searchRequest.searchSelect === "" || searchRequest.searchSelect === "customerName") {
                setSearchRequest({
                    ...searchRequest,
                    customerName: e.target.value,
                    payerNo: "",
                    pageNumber: 0,
                });
            } else {
                setSearchRequest({
                    ...searchRequest,
                    customerName: "",
                    payerNo: e.target.value,
                    pageNumber: 0,
                });
            }
        } else if (name === "searchSelect") {
            if (e.target.value === "customerName" && CM.cfnIsNotEmpty(searchRequest.payerNo)) {
                let tmp = searchRequest.payerNo;
                setSearchRequest({
                    ...searchRequest,
                    [name]: e.target.value,
                    customerName : tmp,
                    payerNo : "",
                    pageNumber: 0,
                });
            } else if (e.target.value === "payerNo" && CM.cfnIsNotEmpty(searchRequest.customerName)) {
                let tmp = searchRequest.customerName;
                setSearchRequest({
                    ...searchRequest,
                    [name]: e.target.value,
                    customerName : "",
                    payerNo : tmp,
                    pageNumber: 0,
                });
            } else {
                setSearchRequest({
                    ...searchRequest,
                    [name]: e.target.value,
                    pageNumber: 0,
                });
            }
        } else {
            setSearchRequest({
                ...searchRequest,
                [name]: e.target.value,
                pageNumber: 0,
            });
        }
    };

    /*
     * @desc    검색 flag 변경 핸들러
     */
    const handleSearchButtonChange = (flag) => {
        setSearchButton(flag);
    }

    /*
     * @desc    검색조건 변경 이벤트 핸들러
     */
    const handleSearchRequestChange = (data) => setSearchRequest(data);



    const handleClickExcelButton = (flag) => setExcelButton(flag);

    /*
     * @desc    정렬 조건 변경 이벤트 핸들러
     */
    const handleSortProperty = (sortObjArray) => {
        let sortProperty = "";
        let sortDirection = "";

        for (const obj of sortObjArray) {
            if (obj.name === "sortProperty") sortProperty = obj.value;
            if (obj.name === "sortDirection") sortDirection = obj.value;
        }

        setSearchRequest({
            ...searchRequest,
            sortProperty: sortProperty,
            sortDirection: sortDirection,
        });

        //정렬조건 세팅 후 검색 trigger
        handleSearchButtonChange(true);
    };

    return (
        <div>
            <h4>현재 "자동출금예약 서비스"를 이용 중입니다.</h4>
            <h4>출금 청구 내역</h4>
            <AutoWithdrawFailResultSummary autoWithdrawSummary={props.autoWithdrawSummary}/><br/><br/>
            <SearchForm
                searchRequest={searchRequest}
                handleSearchFormChange={handleSearchFormChange}
                handleSearchButtonChange={handleSearchButtonChange}
                storeCustomerGroup={customerGroupList} // 고객구분
                storeCapital={capitalList} // 자금종류
            />
            <div className="table-top-area">
                <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
                <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
                <button className="btn-m fr" data-testid="save-excel" onClick={(e) => handleClickExcelButton(true)}>
                    엑셀저장
                </button>
            </div>
            <ListForm
                withdrawFailList={withdrawFailList}
                setWithdrawFailList={setWithdrawFailList}
                pagination={pagination}
                page={searchRequest.pageNumber}
                searchRequest={searchRequest}
                handleSortProperty={handleSortProperty}
            />
            <Template.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
        </div>
    );
}

export default inject((rootStore, props) => ({
    store: rootStore.instituteStore,
    props: props,
}))(observer(CmsReceiptAutoWithdrawFailResult));
