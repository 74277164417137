import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

let openAlertFn = () => {};

const errorMessageType = {
  GENERAL: "서버 시스템에서의 응답이 없습니다. 고객센터로 문의주시기 바랍니다. (GENERAL)",
  Not_Acceptable: "서버 이용에 문제가 있습니다. 고객센터로 문의주시기 바랍니다.(Not Acceptable)",
};

const AlertForm = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [callback, setCallback] = React.useState({ function: "" });

  React.useEffect(() => {
    openAlertFn = openAlert;
  }, []);

  const openAlert = (message, callback) => {
    setOpen(true);
    setMessage(message);
    setCallback({ function: callback });
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 27) {
      handleAlertOk();
    }
  };

  const handleAlertOk = () => {
    setOpen(false);
    if (typeof callback.function === "function") callback.function();
  };

  return (
    <Dialog open={open} onKeyUp={handleKeyUp} onClose={handleAlertOk} transitionDuration={{ enter: 100, exit: 100 }} scroll="paper">
      <DialogContent>
        <DialogContentText id="alert-description" style={{ whiteSpace: "pre-line" }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="table-bottom-area">
          <button className="btn-m" onClick={handleAlertOk} data-testid="common-alert-click">
            확인
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export function openAlert(message, callback) {
  let messageString = errorMessageType.hasOwnProperty(message.split(" ").join("_")) ? errorMessageType[message.split(" ").join("_")] : message;
  openAlertFn(messageString, callback);
}

export default AlertForm;
