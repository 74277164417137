import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import BusinessInfoModal from "../businessInfo/BusinessInfoModal";
import ChangeInformationModal from "./ChangeInformationModal";
import ChangeBillingAccountModal from "./ChangeBillingAccountModal";
import TabAutoWithdraw from "../extraServiceInfo/TabAutoWithdraw";
import TabCashBill from "../extraServiceInfo/TabCashBill";

// 기관 업무 정보 > 변경 요청 이력 > 상세 modal
// 렌더를 처리하는 메인 컴포넌트
export default function ChangeHistoryModal(props) {
  const { openModal, open, setOpen, object, bankList } = props;
  const disabled = true;

  // modal 닫기
  const handleClose = () => setOpen(false);

  // 렌더
  switch (openModal) {
    case "TERMINATE_SERVICE":
      return <BusinessInfoModal data-testid="changeHistoryModal-businessInfoModal-open-modal" open={open} setOpen={setOpen} disabled={disabled} object={object} />;
    case "CHANGE_BILLING_ACCOUNT":
      return <ChangeBillingAccountModal data-testid="changeHistoryModal-changeBillingAccountModal-open-modal" open={open} setOpen={setOpen} disabled={disabled} object={object} bankList={bankList} />;
    case "START_AUTO_WITHDRAW":
    case "CHANGE_AUTO_WITHDRAW":
      return (
        <Modal open={open} onClose={handleClose}>
          <div className="paper">
            <div className="inner">
              <div className="modal-top-area">
                <Button className="fr btn-close" onClick={handleClose} data-testid="changeHistoryModal-tabAutoWithdraw-button-close-modal">
                  {""}
                </Button>
              </div>
              <h3>자동출금서비스 신청 내역</h3>
              <TabAutoWithdraw disabled={disabled} autoWithdraw={object} />
            </div>
          </div>
        </Modal>
      );
    case "CHANGE_INFORMATION":
      return <ChangeInformationModal data-testid="changeHistoryModal-changeInfomationModal-open-modal" open={open} setOpen={setOpen} disabled={disabled} object={object} />;
    case "APPLY_CASHBILL":
      let applyCashbillObject = {
        autoIssueYn: object.autoIssueYn,
        expectedApplyDate: object.expectedApplyDate,
        serviceUseYn: object.serviceUseYn,
      };
      return (
        <Modal open={open} onClose={handleClose}>
          <div className="paper">
            <div className="inner">
              <div className="modal-top-area">
                <Button className="fr btn-close" onClick={handleClose} data-testid="changeHistoryModal-tabCashBill-button-close-modal">
                  {""}
                </Button>
              </div>
              <h3>현금영수증 변경신청 내역</h3>
              <TabCashBill disabled={disabled} cashbillInfo={applyCashbillObject} />
            </div>
          </div>
        </Modal>
      );
    default:
      return null;
  }
}
