import { Button, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Pagination from "material-ui-flat-pagination";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { EditableCellInput, RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsReceiptData";

//모달창 크기 조정 위해 추가 선언
const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "800px",
  },
});

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const { searchRequest, handleSearchFormChange } = props;

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchRequestChange((data) => ({...data, pageNumber: 0}));
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area" data-testid="modify-search-area">
      <div className="block">
        <label className="label-l">고객구분</label>
        <Select native name="groupUniqueKey" value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")}>
          <option value="">전체</option>
          {props.customerGroupOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native name="capitalUniqueKey" value={searchRequest.capitalUniqueKey} onChange={handleSearchFormChange("capitalUniqueKey")}>
          <option value="">전체</option>
          {props.capitalOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <SelectForm value={searchRequest.term} handleChange={handleSearchFormChange("term")} name="term" arrayOption={CmsData.targetOptionForUpdate} optionValue={"value"} optionLabel={"label"} />
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="modify-keyword-input" />
        <button className="search-button" onClick={() => fnSearch()} data-testid="modify-search">
          검색
        </button>
        <br/><br/>
        <label className="label-l">수납기간</label>
        <Select native name="searchStartY4" value={searchRequest.searchStartY4} onChange={handleSearchFormChange("searchStartY4")}>
          <option value="">전체</option>
          {props.fnCreateYearOption().map((option, index) => {
            return (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
            );
          })}
        </Select>
        <Select native name="searchStartMm" value={searchRequest.searchStartMm} onChange={handleSearchFormChange("searchStartMm")}>
          <option value="">전체</option>
          {props.fnCreateEndMonthOption(searchRequest.searchStartY4).map((option, index) => {
            return (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
            );
          })}
        </Select>
        <span className="between">~</span>
        <Select native name="searchEndY4" value={searchRequest.searchEndY4} onChange={handleSearchFormChange("searchEndY4")}>
          <option value="">전체</option>
          {props.fnCreateYearOption().map((option, index) => {
            return (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
            );
          })}
        </Select>
        <Select native name="searchEndMm" value={searchRequest.searchEndMm} onChange={handleSearchFormChange("searchEndMm")}>
          <option value="">전체</option>
          {props.fnCreateEndMonthOption(searchRequest.searchEndY4).map((option, index) => {
            return (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
            );
          })}
        </Select>
        <label className="label-l">수납지정일</label>
        <Select native name="searchPaySpecifiedDay" value={searchRequest.searchPaySpecifiedDay} onChange={handleSearchFormChange("searchPaySpecifiedDay")}>
          <option value="" key="">전체</option>
          {props.fnSearchPaySpecifiedDayOption().map((option, index) => {
            return (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  const [exemptAllRow, setExemptAllRow] = useState(false);

  useEffect(() => {
    if (!props.searchButton) {
      setExemptAllRow(false);
    }
  }, [props.searchButton]);
  /*
   * @desc    상태값에 따른 출금제외 버튼 렌더 함수
   */
  const fnRenderButton = (row) => {
    if (row.isExempted) {
      return (
        <button className={`btn-s btn-exempted`} onClick={(e) => handleClickExemptButton(row.uniqueKey)}>
          제외취소
        </button>
      );
    } else {
      return (
        <button className={`btn-s`} onClick={(e) => handleClickExemptButton(row.uniqueKey)}>
          제외하기
        </button>
      );
    }
  };

  /*
   * @desc    테이블 내 입력란 변경 이벤트 핸들러
   */
  const handleTargetRowsChange = (row, change) => {
    const tempArray = [...props.targetList];
    const uniqueKey = row.uniqueKey;
    let rowIndex = tempArray.findIndex((row) => row.uniqueKey === uniqueKey);

    if (change.fieldName === "bankbookPostfix") {
      tempArray[rowIndex][change.fieldName] = change.fieldValue;
    } else if (change.fieldName === "unpaidDelayFee") {
      //미납연체료 수정 시
      const currentValue = Number(change.fieldValue.replace(/[^0-9]/g, ""));

      tempArray[rowIndex]["totalAskingAmount"] = tempArray[rowIndex]["previouslyUnpaidAmount"] + tempArray[rowIndex]["unpaidAmount"] + currentValue;
      tempArray[rowIndex]["unpaidDelayFee"] = currentValue;
    } else {
      //당회청구액 수정 시
      const currentValue = Number(change.fieldValue.replace(/[^0-9]/g, ""));

      tempArray[rowIndex]["totalAskingAmount"] = tempArray[rowIndex]["previouslyUnpaidAmount"] + tempArray[rowIndex]["unpaidAmount"] + tempArray[rowIndex]["unpaidDelayFee"] + currentValue;
      tempArray[rowIndex]["tempEditAmount"] = currentValue;
    }

    props.handleTargetListChange(tempArray);
  };

  /*
   * @desc    테이블 내 출금제외 버튼 클릭 이벤트 함수
   */
  const handleClickExemptButton = (key) => {
    const tempArray = CM.cfnCopyObject(props.targetList);
    let rowIndex = null;
    tempArray.forEach((row, index) => {
      if (row.uniqueKey === key) rowIndex = index;
    });

    let originalValue = tempArray[rowIndex]["isExempted"];
    if (CM.cfnIsEmpty(originalValue)) originalValue = false;

    tempArray[rowIndex]["isExempted"] = !originalValue;

    props.handleTargetListChange(tempArray);
  };

  const handleClickAllRowExemptButton = () => {
    const tempArray = CM.cfnCopyObject(props.targetList);
    tempArray.forEach((row, index) => {
      row.isExempted = !exemptAllRow;
    });

    props.handleTargetListChange(tempArray);
    setExemptAllRow(!exemptAllRow);
  };

  const exemptEntireRowButton = () => {
    return (
      <>
        제외처리
        <br />
        <button className={`btn-s`} onClick={handleClickAllRowExemptButton}>
          {exemptAllRow ? "제외취소" : "목록제외"}
        </button>
      </>
    );
  };

  return (
    <div className="modal-overflow-table">
      <div className="left-table-div-scroll">
        <Table>
          {CM.cfnCompColGroup(["80px", "175px"])}
          <TableForm.compServerSortDoubleRowTableHead
            useCheckbox={false}
            rowOne={[
              { id: "EXCLUDE", label: exemptEntireRowButton(), sortable: false, rowSpan: 2 },
              { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
              { label: "" },
              { label: "" },
            ]}
            rowTwo={[{ label: "" }, { label: "" }]}
            searchRequest={props.searchRequest}
            handleSortProperty={props.handleSortProperty}
          />
          <TableBody>
            {props.targetList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={4} />
            ) : (
              props.targetList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center" className="ellipsisWithFixedHeight">
                      {fnRenderButton(row)}
                    </TableCell>
                    <TableCell align="center" className="ellipsisWithFixedHeight" title={row.customerName}>
                      {row.customerName /*고객명*/}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div-scroll">
        <Table>
          {CM.cfnCompColGroup(["100px", "120px", "100px", "100px", "50px", "80px", "130px", "130px", "140px", "140px", "140px", "100px", "130px", "140px", "140px"])}
          <TableForm.compServerSortDoubleRowTableHead
            useCheckbox={false}
            rowOne={[
              { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true, rowSpan: 2 },
              {
                id: "PAYER_NO",
                label: "납부자번호",
                sortable: true,
                rowSpan: 2,
              },
              {
                id: "CAPITAL",
                label: "자금종류",
                sortable: false,
                rowSpan: 2,
              },
              {
                id: "TARGET_Y4MM",
                label: "수납연월",
                sortable: true,
                rowSpan: 2,
              },
              {
                id: "PAY_SPECIFIED_DAY",
                label: "수납\n지정일",
                sortable: true,
                rowSpan: 2,
              },
              {
                id: "PAY_AMOUNT_TYPE",
                label: "자금\n유형",
                sortable: false,
                rowSpan: 2,
              },
              {
                id: "totalAskingAmount",
                label: "출금청구금액",
                sortable: false,
                colSpan: 5,
              },
              {
                id: "finantialInstituteCode",
                label: "금융기관",
                sortable: false,
                rowSpan: 2,
              },
              {
                id: "accountNo",
                label: "계좌번호",
                sortable: false,
                rowSpan: 2,
              },
              { id: "bankbookContents", label: "통장기재내역", sortable: false, colSpan: 2 },
            ]}
            rowTwo={[
              {
                id: "UNPAID_AMOUNT",
                label: "합계",
                sortable: true,
              },
              { id: "askingAmount", label: "청구일분", sortable: false },
              { id: "previousUnaskedAmount", label: "미청구분", sortable: false },
              { id: "unpaidAmount", label: "미납분", sortable: false },
              { id: "unpaidDelayFee", label: "미납연체료", sortable: false },
              { id: "bankbookContents", label: "기본", sortable: false },
              { id: "bankbookPostfix", label: "추가", sortable: false },
            ]}
            searchRequest={props.searchRequest}
            handleSortProperty={props.handleSortProperty}
          />
          <TableBody>
            {props.targetList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={15} />
            ) : (
              props.targetList.map((row, index) => {
                return (
                  <TableRow hover key={index} className="ellipsisWithFixedHeight">
                    <TableCell align="center" className="ellipsisWithFixedHeight" title={row.customerGroupName}>
                      {row.customerGroupName /*고객구분명*/}
                    </TableCell>
                    <TableCell align="center"> {row.payerNo /*납부자번호*/}</TableCell>
                    <TableCell align="center" className="ellipsisWithFixedHeight" title={row.capitalName}>
                      {row.capitalName /*자금종류*/}
                    </TableCell>
                    <TableCell align="center"> {CM.cfnDateFormat(row.targetY4mm, "yyyyMM") /*수납연월*/}</TableCell>
                    <TableCell align="center"> {CM.cfnDayFormat(row.paySpecifiedDay) /*수납지정일*/}</TableCell>
                    <TableCell align="center"> {CmsData.fnConvertPayAmountType(row.payAmountType) /*자금유형*/}</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount) /*출금청구금액 합계금액*/}원</TableCell>
                    <TableCell align="right">
                      {row.askResult === "UNREACHED" ? (
                        <EditableCellInput
                          row={row}
                          fieldName="tempEditAmount"
                          onCellValueChange={handleTargetRowsChange.bind(this, row)}
                          size="w120" /*당회청구액(가공)*/
                          needComma={true}
                          inputProps={{
                            style: { textAlign: "right" },
                          }}
                          endAdornment="원"
                        />
                      ) : row.askResult === "UNPAID" ? (
                        "0원"
                      ) : (
                        CM.cfnAddComma(row.unpaidAmount) + "원"
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {
                        row.askResult === "UNASKED" ? (
                          <EditableCellInput
                            row={row}
                            fieldName="tempEditAmount"
                            onCellValueChange={handleTargetRowsChange.bind(this, row)}
                            size="w120" /*당회청구액(가공)*/
                            needComma={true}
                            inputProps={{
                              style: { textAlign: "right" },
                            }}
                            endAdornment="원"
                          />
                        ) : (
                          CM.cfnAddComma(row.previouslyUnpaidAmount) + "원"
                        ) /*전회미청구액(가공)*/
                      }
                    </TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.unpaidAmount) /*미납액*/}원</TableCell>
                    <TableCell align="right">
                      {
                        row.askResult === "UNPAID" ? (
                          <EditableCellInput
                            row={row}
                            fieldName="unpaidDelayFee"
                            onCellValueChange={handleTargetRowsChange.bind(this, row)}
                            size="w120" /*당회청구액(가공)*/
                            needComma={true}
                            inputProps={{
                              style: { textAlign: "right" },
                            }}
                            endAdornment="원"
                          />
                        ) : (
                          CM.cfnAddComma(row.unpaidDelayFee) + "원"
                        ) /*미납연체료*/
                      }
                    </TableCell>
                    <TableCell align="center">{CM.cfnMatchBankName(row.accountBankCode, props.financialInstitutes) /*금융기관*/}</TableCell>
                    <TableCell align="center"> {row.accountNo /*계좌번호*/}</TableCell>
                    <TableCell align="center" className="ellipsisWithFixedHeight" title={row.bankbookContents}>
                      {row.bankbookContents /*통장기재내역*/}
                    </TableCell>
                    <TableCell align="center">
                      <EditableCellInput row={row} fieldName="bankbookPostfix" onCellValueChange={handleTargetRowsChange.bind(this, row)} size="w120" /*추가기재내역 */ />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const CmsReceiptRequestTargetModify = (props) => {
  const { open, setOpen, fileUniqueKey, targetAskUniqueKey } = props;

  const classes = useStyles();

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(CmsData.updateTargetSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(false); // 검색 실행 flag
  const [originalTargetList, setOriginalTargetList] = useState([]); // 서버로 부터 전달받은 original list
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());
  const excelInput = React.useRef("");

  /*
   * @desc 출금청구 추가대상 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);

    if (tempObj.term === "customerName") {
      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.payerNo = "";
      tempObj.askUniqueKey = "";
    } else if (tempObj.term === "payerNo") {
      tempObj.payerNo = CM.cfnNvl(tempObj.keyword, "");
      tempObj.customerName = "";
      tempObj.askUniqueKey = "";
    } else if (tempObj.term === "askUniqueKey") {
      tempObj.askUniqueKey = CM.cfnNvl(tempObj.keyword, "");
      tempObj.customerName = "";
      tempObj.payerNo = "";
    }

    tempObj.fileUniqueKey = fileUniqueKey;
    tempObj.searchStartY4mm = searchRequest.searchStartY4 + searchRequest.searchStartMm;
    tempObj.searchEndY4mm = searchRequest.searchEndY4 + searchRequest.searchEndMm;

    return tempObj;
  }, [fileUniqueKey, searchRequest]);

  useEffect(() => {
    if (targetAskUniqueKey) {
      setSearchRequest((s) => ({ ...s, term: "askUniqueKey", keyword: targetAskUniqueKey, pageNumber: 0, pageSize: 5}));
    }
    if (open) {
      setSearchButton(true);
    }
  }, [open, targetAskUniqueKey]);

  // 테이블 데이터 검색
  useEffect(() => {
    // axios request
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/cms/contents/update?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&paySpecifiedDay=${search.searchPaySpecifiedDay}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&payerNo=${search.payerNo}&askUniqueKey=${search.askUniqueKey}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&groupUniqueKey=${search.groupUniqueKey}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          () => {
            handleSearchButtonChange(false);
          }
        );
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      if (search.askUniqueKey !== "" && search.askUniqueKey.length !== 36) {
        CM.cfnAlert("올바른 청구대상 구분 검색어가 아닙니다. 검색어 조건을 확인하시기 바랍니다.");
        handleSearchButtonChange(false);
        return;
      }

      //수납기간 중 하나라도 셀렉박스의 '전체'가 선택되어있을 경우 에러
      if (search.searchStartY4 !== "" ||  search.searchStartMm !== "" || search.searchEndY4 !== "" || search.searchEndMm !== "") {
        if (search.searchStartY4 === "" ||  search.searchStartMm === "" || search.searchEndY4 === "" || search.searchEndMm === "") {
          CM.cfnAlert("수납기간을 올바르게 선택하여 주십시오.");
          handleSearchButtonChange(false);
          return;
        }
      }

     if (search.searchStartY4mm.length === 6 && search.searchEndY4mm.length === 6 && (search.searchStartY4mm > search.searchEndY4mm)) {
        CM.cfnAlert("수납기간 시작월이 종료월보다 클 수 없습니다. 다시 선택하여 주십시오.");
        handleSearchButtonChange(false);
        return;
      }

      const resultData = await axiosList(search);
      const resultList = resultData.content;

      for (const row of resultList) {
        row.totalAskingAmount -= row.paidAmount;
        //상태값에 따라 금액 세팅
        switch (row.askResult) {
          case "UNREACHED":
            row.tempEditAmount = row.unpaidAmount; //출금청구일분
            row.previouslyUnpaidAmount = 0; //미청구분
            row.unpaidAmount = 0; //미납분
            row.unpaidDelayFee = 0; //연체료
            break;
          case "UNASKED":
            row.tempEditAmount = row.unpaidAmount; //출금청구일분
            row.previouslyUnpaidAmount = 0; //미청구분
            row.unpaidAmount = 0; //미납분
            row.unpaidDelayFee = 0; //연체료
            break;
          case "UNPAID":
            row.tempEditAmount = 0; //출금청구일분
            row.previouslyUnpaidAmount = 0; //미청구분
            row.unpaidAmount = row.unpaidAmount - row.unpaidDelayFee; //미납분
            break;
          default:
            break;
        }
      }

      setOriginalTargetList(CM.cfnCopyObject(resultData));
      handleTargetListChange(resultList);
      setPagination(CmsData.paginationData(resultData));
      handleSearchButtonChange(false);
    };

    if (open && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest, searchButton, fileUniqueKey, fnMakeParameter]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 청구 대상 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    창닫기 버튼 클릭 이벤트 핸들러
   */
  const handleTargetModifyModalClose = () => {
    handleSearchButtonChange(false);
    if (searchRequest.term === "askUniqueKey") {
      setSearchRequest({ ...searchRequest, term: "customerName", keyword: "" });
    }
    setOpen(false);
  };

  /*
   * @desc  엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleExcelDownload = () => {
    const search = fnMakeParameter("get");
    const url = `api/receipt/cms/contents/update/excel?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&paySpecifiedDay=${search.searchPaySpecifiedDay}&groupUniqueKey=${search.groupUniqueKey}&payerNo=${search.payerNo}&askUniqueKey=${search.askUniqueKey}&resultCode=${search.resultCode}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc  일괄수정(엑셀) 버튼 클릭 이벤트 핸들러
   */
  const handleModifyTargetBulk = () => {
    excelInput.current.click();
  };

  /*
   * @desc  출금청구대상 일괄수정 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc  출금청구대상 일괄수정 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/receipt/cms/contents/update/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey ",
      new Blob([JSON.stringify(fileUniqueKey)], {
        type: "application/json",
      })
    );

    CM.cfnAxios(url, "post", form, fnUploadExcelCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
        () => {}
      );
    });
  };

  /*
   * @desc  출금청구대상 일괄수정 Request Callback
   */
  const fnUploadExcelCallback = (objStatus, objData) => {
    CM.cfnAlert(objData);
    props.setIsTargetChanged(true); //출금청구완료 목록 재조회
    handleTargetModifyModalClose();
  };

  /*
   * @desc    수정내용 저장 버튼 클릭 이벤트 핸들러
   */
  const handleSaveModified = () => {
    const tempArray = fnMakeParameterArray();

    if (tempArray.length < 1) {
      CM.cfnAlert("수정된 내용이 없습니다.");
    } else {
      CM.cfnConfirm("수정하시겠습니까?", () => {
        fnSaveModifiedTarget(tempArray);
      });
    }
  };

  /*
   * @desc    수정내용 저장 대상 파라미터 생성 함수
   */
  const fnMakeParameterArray = () => {
    let tempArray = [];

    for (const element of targetList) {
      const originalValue = originalTargetList.content.filter(function (row) {
        return row.uniqueKey === element.uniqueKey;
      });

      //기존 값과 비교하여 수정된 데이터만 추출하여 Request parameter 로 가공
      if (originalValue[0].bankbookPostfix !== element.bankbookPostfix || originalValue[0].totalAskingAmount !== element.totalAskingAmount || originalValue[0].isExempted !== element.isExempted) {
        //당회청구액 변경 시 입력된 당회청구액 가공
        element.regularAskingAmount = Number(CM.cfnReplaceSymbol(element.totalAskingAmount));

        tempArray.push(element);
      }
    }

    return tempArray;
  };

  /*
   * @desc    수정내용 저장 Request
   */
  const fnSaveModifiedTarget = (paramArray) => {
    const url = `api/receipt/cms/contents/update/${fileUniqueKey}`;
    CM.cfnAxios(url, "post", paramArray, fnSaveModifiedTargetCallback);
  };

  /*
   * @desc    수정내용 저장 Request Callback
   */
  const fnSaveModifiedTargetCallback = async (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    CM.cfnAlert("정상적으로 처리되었습니다.");
    await props.setIsTargetChanged(true); //출금청구완료 목록 재조회
    handleSearchButtonChange(true);
    // handleTargetModifyModalClose();
  };

  /*
 * @desc     수납기간 년도 옵션 생성하는 함수
 */
  const fnCreateYearOption = () => {
    const arrYear = [];

    const startYear = 2010;
    let maxLoop = new Date().getFullYear() - startYear + 2;
    // if (type === "end") {
    //   maxLoop++;
    // }

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납시작월 / 종료월의 옵션 생성하는 함수
   */
  const fnCreateEndMonthOption = (searchY4) => {
    const arrMonth = [];

    let year = `${(new Date().getFullYear() + 1).toString()}`;
    let mm;

    if (searchY4.toString() === year) {
      //수납종료기간 년도가 내년과 같다면 이번달까지만 셀렉트박스 생성
      mm = `${new Date().getMonth()}`; //이번달

      for (let i = 1; i <= mm; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;
        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else {
      const maxLoop = 13;

      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    }
    return arrMonth;
  };

  /*
  * @desc     수납지정일 옵션 생성하는 함수
  */
  const fnSearchPaySpecifiedDayOption = () => {
    const arrDate = [];
    const maxLoop = 32;
    for (let i = 1; i < maxLoop; i++) {
      let label = `${i}일`;
      let value = i < 10 ? `0${i}` : `${i}`;
      arrDate.push({
        label,
        value,
      });
    }
    arrDate.push({ label: "말일", value: "99" });
    return arrDate;
  };

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleTargetModifyModalClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>출금금액 수정</h3>
          <div>
            <SearchForm
              searchRequest={searchRequest}
              capitalOptions={props.capitalOptions}
              customerGroupOptions={props.customerGroupOptions}
              fnCreateYearOption={fnCreateYearOption}
              fnCreateEndMonthOption={fnCreateEndMonthOption}
              fnSearchPaySpecifiedDayOption={fnSearchPaySpecifiedDayOption}
              handleSearchFormChange={handleSearchFormChange}
              handleSearchButtonChange={handleSearchButtonChange}
              handleSearchRequestChange={handleSearchRequestChange}
            />
            <div className="table-top-area">
              <TotalCountForm totalElements={originalTargetList.totalElements || 0} />
              <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleSelectExcelFile(e)}
                ref={excelInput}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                value=""
                data-testid="select-excel-input"
              />
              <button className="btn-m fr" onClick={handleModifyTargetBulk} data-testid="modify-excel">
                [일괄수정]엑셀파일 등록
              </button>
              <button className="btn-m fr" onClick={handleExcelDownload} data-testid="save-excel">
                [일괄수정양식]엑셀저장
              </button>
              <div className="btn-l fr" onClick={handleSaveModified}>
                수정내용 저장
              </div>
            </div>
            <ListForm
              targetList={targetList}
              handleTargetListChange={handleTargetListChange}
              searchRequest={searchRequest}
              handleSortProperty={handleSortProperty}
              pagination={pagination}
              financialInstitutes={props.financialInstitutes}
              searchButton={searchButton}
            />
            <Pagination
              className="centered"
              limit={pagination.rowsPerPage}
              offset={pagination.offset}
              total={pagination.total}
              onClick={(e, offset, page) => handleOffsetChange(offset, page)}
              reduced={true}
              centerRipple={false}
              disableFocusRipple={true}
              disableRipple={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CmsReceiptRequestTargetModify;
