import { Input } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../common/Common";
// (임시 로그인 가능 목록)
// testinst01_박두만
// testinst01_서태윤
// testinst02_신동철
// testinst03_구희봉
// testinst03_권귀옥

// 로그인 버튼 컴포넌트3

const LoginButton = (props) => {
  const { value, handleClick } = props;
  const buttonValue = {
    value: "login",
    name: "로그인",
  };

  if (CM.cfnIsNotEmpty(value)) {
    buttonValue.value = "logout";
    buttonValue.name = "로그아웃";
  }

  return (
    <button className="btn-l" onClick={() => handleClick(buttonValue.value)}>
      {buttonValue.name}
    </button>
  );
};

// 메인 페이지
const LoginPage = ({ store }) => {
  // 로그인 값
  const [loginInfo, setLoginInfo] = useState({
    customerType: "INDIVIDUAL",
    identificationNo: "970303",
    intgtLoginId: "apt01_박두만",
  });

  const history = useHistory();

  // 로그인 버튼 클릭시 동작하는 함수
  async function handleClick(type) {
    if (type === "login") {
      await store.getLoginDto(loginInfo);
      history.push("/");
    } else {
      await store.getLogout();
      // setLoginInfo({ ...loginInfo, intgtLoginId: "" });
    }
  }

  async function handleClick2(type) {
    await store.getLoginDto({
      customerType: "INDIVIDUAL",
      identificationNo: "970303",
      intgtLoginId: "apt02_박두만",
    });
    history.push("/");
  }

  async function handleClick3() {
    await store.getLoginDto({
      customerType: "INDIVIDUAL",
      identificationNo: "970303",
      intgtLoginId: "apt03_박두만",
    });
    history.push("/");
  }

  const handleChange = (event) => {
    setLoginInfo({
      ...loginInfo,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <div
        style={{
          display: CM.cfnIsEmpty(store.loginDto.$mobx.values) ? "" : "none",
        }}
        className="h60">
        <Input type="text" name="intgtLoginId" value={loginInfo.intgtLoginId} onChange={handleChange} data-testid="input-id" />
        <LoginButton value={store.loginDto.$mobx.values} handleClick={handleClick} data-testid="login-button" />
        <button className="btn-l" onClick={handleClick2}>
          apt02
        </button>
        <button className="btn-l" onClick={handleClick3}>
          apt03
        </button>
      </div>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.loginStore,
}))(observer(LoginPage));
