import React, { useState, useEffect, useCallback, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "material-ui-flat-pagination";
import TableForm from "../../template/TableForm";
import { DatePickerForm } from "../../template/ComponentForm";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import CashbillTabRequestResultModalDetails from "./CashbillTabRequestResultModalDetails";

// 요청일자는 현재로부터 -1달 1일 ~ +1달 말일
const getSearchDate = () => {
  const nowDate = new Date();
  const fromDate = new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1);
  const toDate = new Date(nowDate.getFullYear(), nowDate.getMonth() + 2, 0);
  return [fromDate, toDate];
};

// hooks 기본값
const defaultHooks = {
  searchCondition: () => {
    const [getFromDate, getToDate] = getSearchDate();
    return {
      pageNumber: 0,
      pageSize: 5,
      sortDirection: "DESC",
      sortProperty: "applyDate", // 신청일
      fromDate: CM.cfnConvertDateToString(getFromDate),
      toDate: CM.cfnConvertDateToString(getToDate),
    };
  },
  searchStart: false,
  requestResult: [],
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
  searchForm: () => {
    const [getFromDate, getToDate] = getSearchDate();
    return {
      terms: "customerName",
      input: "",
      fromDate: getFromDate,
      toDate: getToDate,
    };
  },
};

// 부가서비스 > 현금영수증 > 발행 요청결과 탭
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabRequestResult = (props) => {
  // hooks
  const [searchCondition, setSearchCondition] = useState(defaultHooks.searchCondition());
  const [searchStart, setSearchStart] = useState(defaultHooks.searchStart);
  const [requestResult, setRequestResult] = useState(defaultHooks.requestResult);
  const [pagination, setPagination] = useState(defaultHooks.pagination);
  const [searchForm, setSearchForm] = useState(defaultHooks.searchForm());
  const [modalOpen, setModalOpen] = useState(false);
  const [fileUniqueKey, setFileUniqueKey] = useState();

  // handler
  const handleSearchForm = (key, value) => setSearchForm((data) => ({ ...data, [key]: value }));
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  const handlePage = (page) => {
    setSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchStart(true);
  };

  const handleRowPerPage = ({ target: { value } }) => {
    setSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchStart(true);
  };

  // useCallback
  // hooks 초기화
  const initializePage = useCallback(() => {
    setSearchCondition(defaultHooks.searchCondition());
    setSearchStart(true);
    setRequestResult(defaultHooks.requestResult);
    setPagination(defaultHooks.pagination);
    setSearchForm(defaultHooks.searchForm);
  }, []);

  // 요청결과 목록을 가져오는 useCallback
  const getRequestResult = useCallback((parameter) => {
    return new Promise((resolve) => {
      let url = "api/extraservice/cashbill/files";
      url += `?pageNumber=${parameter.pageNumber}&pageSize=${parameter.pageSize}`;
      url += `&sortDirection=${parameter.sortDirection}&sortProperty=${parameter.sortProperty}`;
      url += `&fromDate=${parameter.fromDate}&toDate=${parameter.toDate}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  // useEffect
  useEffect(() => {
    if (props.tabIndex === 1) initializePage();
  }, [props.tabIndex, initializePage]);

  useEffect(() => {
    const startAxios = async (parameter) => {
      const resultData = await getRequestResult(parameter);
      setRequestResult(resultData.content);
      handlePagination(resultData);
    };

    if (searchStart) startAxios(searchCondition);

    return () => setSearchStart(false);
  }, [searchStart, searchCondition, getRequestResult]);

  useEffect(() => {
    if (!modalOpen) {
      setSearchStart(true);
    }
  }, [modalOpen]);

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>현금영수증 발행 내역을 국세청에 전송한 후 마감시간 전까지 취소 가능합니다.</li>
        </ul>
      </div>
      <div>
        <SearchForm searchForm={searchForm} handleSearchForm={handleSearchForm} handleSearchCondition={setSearchCondition} handleSearchStart={setSearchStart} />
      </div>
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} data-testid="requestResult-rowPerPage-select" />
      </div>
      <div>
        <RequestResultFrom
          list={requestResult}
          pagination={pagination}
          handlePage={handlePage}
          handleSearchStart={setSearchStart}
          handleModalOpen={setModalOpen}
          handleDetailsUniqueKey={setFileUniqueKey}
          searchCondition={searchCondition}
          handleSearchCondition={setSearchCondition}
          setTabIndex={props.setTabIndex}
        />
      </div>
      <div aria-describedby="modal-components">
        <CashbillTabRequestResultModalDetails modalOpen={modalOpen} handleModalOpen={setModalOpen} fileUniqueKey={fileUniqueKey} />
      </div>
    </div>
  );
};

// 검색 컴포넌트
const SearchForm = (props) => {
  // 기간 조회
  const startSearch = async () => {
    const startDate = props.searchForm.fromDate;
    const endDate = props.searchForm.toDate;

    // 날짜 유효성 검사
    const validation = CM.cfnPeriodValidation(startDate, endDate);
    if (!validation) return false;
    if (CM.cfnAddDate(CM.cfnConvertDateTimeToString(startDate), CM.cfnConvertDateTimeToString(endDate), 10)) {
      CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
      return false;
    }

    props.handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      fromDate: CM.cfnConvertDateToString(startDate),
      toDate: CM.cfnConvertDateToString(endDate),
    }));
    props.handleSearchStart(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">발행일</label>
        <DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={props.searchForm.fromDate}
          handleChange={(date) => props.handleSearchForm("fromDate", date)}
          customProps={{ inputProps: { "data-testid": "cashbill-requestResult-fromDate" } }}
        />
        <span className="between">~</span>
        <DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={props.searchForm.toDate}
          handleChange={(date) => props.handleSearchForm("toDate", date)}
          customProps={{ minDate: props.searchForm.fromDate, inputProps: { "data-testid": "cashbill-requestResult-toDate" } }}
        />
        <button className="search-button" onClick={startSearch} data-testid="requestResult-search-button">
          검색
        </button>
      </div>
    </div>
  );
};

// 목록 컴포넌트
const RequestResultFrom = (props) => {
  // const styles = useStyles();

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    const sortProperty = sortObjArray.find((obj) => obj.name === "sortProperty").value;
    const sortDirection = sortObjArray.find((obj) => obj.name === "sortDirection").value;

    props.handleSearchCondition((data) => ({ ...data, sortProperty, sortDirection }));

    // 정렬조건 세팅 후 검색 trigger
    props.handleSearchStart(true);
  };

  // 현금영수증 취소요청
  const cancellationRequest = (fileUniqueKey) => {
    const url = `api/extraservice/cashbill/files/${fileUniqueKey}`;
    CM.cfnAxios(url, "delete", "", (status, data) => {
      CM.cfnAlert("취소 처리되었습니다.", () => {
        props.handleSearchStart(true);
      });
    });
  };

  // 현금영수증 발행 결과 modal 열기
  const openModalDetails = (target) => {
    props.handleDetailsUniqueKey(target.uniqueKey);
    props.handleModalOpen(true);
  };

  const renderFileStatusButton = (target, index) => {
    switch (target.fileStatus) {
      case "READY":
        return (
          <button
            className="btn-s"
            onClick={(e) => {
              e.stopPropagation();
              props.setTabIndex(0);
            }}
            data-testid={`requestResult-resultType-button-${index}`}>
            요청대기
          </button>
        );
      case "APPLY_NEEDED":
        return (
          <button
            className="btn-s"
            onClick={(e) => {
              e.stopPropagation();
              cancellationRequest(target.uniqueKey);
            }}
            data-testid={`requestResult-resultType-button-${index}`}>
            취소요청
          </button>
        );
      case "TRANSMITTED":
        return (
          <button className="btn-s" data-testid={`requestResult-resultType-button-${index}`}>
            국세청 요청중
          </button>
        );
      case "CONFIRMED_RESULT_FILE":
        return <div>확인완료</div>;
      default:
        return (
          <button className="btn-s" data-testid={`requestResult-resultType-button-${index}`}>
            결과 확인
          </button>
        );
    }
  };

  return (
    <Fragment>
      <Table>
        <TableForm.compServerSortDoubleRowTableHead
          useCheckbox={false}
          value=""
          rowOne={[
            { id: "issueType", label: "발행방법", sortable: false, rowSpan: 2 },
            { id: "receivedResultDatetime", label: "수신일시", sortable: true, rowSpan: 2 },
            { id: "applyDate", label: "신청일", sortable: true, rowSpan: 2 },
            { id: "totalRequestCount", label: "신청건수", sortable: true, rowSpan: 2 },
            { id: "", label: "발행결과", sortable: false, colSpan: 4 },
            { id: "resultType", label: "진행상태", sortable: false, rowSpan: 2 },
          ]}
          rowTwo={[
            { id: "issueSuccessCnt", label: "정상건수", sortable: false },
            { id: "issueSuccessAmount", label: "정상금액", sortable: false },
            { id: "issueFailedCnt", label: "오류건수", sortable: false },
            { id: "issueFailedAmount", label: "오류금액", sortable: false },
          ]}
          searchRequest={props.searchCondition}
          tableSortLabelDataTestId={"requestResult-list-head-sortLabel"}
          handleSortProperty={handleSortProperty}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index} onClick={(e) => openModalDetails(target)}>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.issuingType(target.fileType)}</TableCell>
                  <TableCell align="center">
                    {target.receivedFileDatetime &&
                      CM.cfnDateFormat(target.receivedFileDatetime)
                        .split("\n")
                        .map((line, i) => (
                          <Fragment key={i}>
                            <span>{line}</span>
                            <br />
                          </Fragment>
                        ))}
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.transactionDate, "yyyyMMdd")}</TableCell>
                  <TableCell align="center">{CM.cfnAddComma(target.numberOfAskedRecords)}</TableCell>
                  {target.fileStatus === "READY" || target.fileStatus === "APPLY_NEEDED" || target.fileStatus === "TRANSMITTED" ? (
                    <>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell align="center">{CM.cfnAddComma(target.numberOfRegistration + target.numberOfUnregistration)}</TableCell>
                      <TableCell align="right">{CM.cfnAddComma(target.amountOfRegistration + target.amountOfUnregistration)}</TableCell>
                      <TableCell align="center">{CM.cfnAddComma(target.numberOfAskedRecords - (target.numberOfRegistration + target.numberOfUnregistration))}</TableCell>
                      <TableCell align="right">{CM.cfnAddComma(target.totalAskingAmount - (target.amountOfRegistration + target.amountOfUnregistration))}</TableCell>
                    </>
                  )}
                  <TableCell align="center">{renderFileStatusButton(target, index)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        className="centered"
        limit={props.pagination.rowsPerPage}
        offset={props.pagination.offset}
        total={props.pagination.total}
        onClick={(e, offset, page) => props.handlePage(page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
        data-testid="request-result-pagination-button"
      />
    </Fragment>
  );
};

export default CashbillTabRequestResult;
