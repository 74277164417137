import React, {useEffect, useState} from "react";
import { Table, TableBody, TableCell, TableRow, Input } from "@material-ui/core/";
import * as CM from "../../common/Common";
import Modal from "@material-ui/core/Modal";
import {Button, Grid} from "@material-ui/core";
import {TooltipForm} from "../../template/ComponentForm";

// 기관 업무정보 > 부가서비스 정보 > 계좌/신용카드 수납(출금 불능분 대상)
// 렌더를 처리하는 메인 컴포넌트
const TabOpenGiroAndPgSendmessageModal = ({ open, setOpen }) => {
  const [searchFlag, setSearchFlag] = useState(true);
  const [lmsMessage, setLmsMessage] = useState({
    intgtLoginId: "",
    subjectMessage: "",
    titleMessage: "",
    etcMessage: ""
  });
  const [sendSmsSubjectContents, setSendSmsSubjectContents] = useState(""); //SMS 발송 내용(제목)
  const [sendSmsTitleContents, setSendSmsTitleContents] = useState(""); //SMS 발송 내용(안내 문구)
  const [sendSmsEtcContents, setSendSmsEtcContents] = useState(""); //SMS 발송 내용(기타)

  // 모달 클로즈
  const handleClose = () => {
    setSendSmsSubjectContents("")
    setSendSmsTitleContents("");
    setSendSmsEtcContents("");
    setOpen(false);
    setSearchFlag(true);
  };

  //input 데이터 60byte 제한
  const handleOnChange = (name) => (e) =>{
    if(name === "smsSubject"){
      setSendSmsSubjectContents(CM.cfnCutByteLength(e.target.value, 40));
    }else if(name === "smsTitle") {
      setSendSmsTitleContents(CM.cfnCutByteLength(e.target.value, 60));
    }else if(name === "smsEtc") {
      setSendSmsEtcContents(CM.cfnCutByteLength(e.target.value, 60));
    }
  }


  //발송 내용 설정 저장 시
  const sendOpenGiroAndPgSendMessage = () => {
    const sendData = CM.cfnCopyObject(lmsMessage);
    const url = "api/institute/payLmsInfo";

    if(CM.cfnIsEmpty(sendSmsSubjectContents)){
      CM.cfnAlert("입력항목의 제목을 입력해주세요.");
      return;
    }
    if(CM.cfnIsEmpty(sendSmsTitleContents)){
      CM.cfnAlert("입력항목의 안내 문구를 입력해주세요.");
      return;
    }

    //변경된 LMS 문구 설정
    sendData.subjectMessage = sendSmsSubjectContents;
    sendData.titleMessage = sendSmsTitleContents;
    sendData.etcMessage = sendSmsEtcContents;

    CM.cfnConfirm("수정하시겠습니까?", () => {
      // axios 호출
      CM.cfnAxios(url, "put", sendData, (status, data) => {
        CM.cfnAlert("정상적으로 변경되었습니다.", () => {
          setSearchFlag(true);
        });
      });
    });
  }


  useEffect(() => {
    // LMS 설정 내용 조회
    const axiosList = () => {
     return new Promise((resolve) => {
       const url = "api/institute/payLmsInfo";

       CM.cfnAxios(url, "get","", (status, data) => {
         resolve(data);
       });
     });
    };

    async function startAxios() {
      const resultData = await axiosList();

      setLmsMessage(resultData);
      setSendSmsSubjectContents(resultData.subjectMessage);
      setSendSmsTitleContents(resultData.titleMessage);
      setSendSmsEtcContents(resultData.etcMessage);
    }

    if(open && searchFlag) {
      setSearchFlag(false);
      startAxios()
    }

  },[open, searchFlag]);

  return (
      <div>
        <Modal open={open}>
          <div className="paper">
            <div className="inner">
              <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
                {""}
              </Button>
              <div className="inforbox">
                <h2>고객앞 발송 내용 화면</h2>
                <ul>
                  <li>아래의 발송 내용은 고객앞 문자로 제공될 정보이며 안내 문구 내용 일부는 수정 가능합니다.</li>
                  <li>기타에 내용을 입력하시면 문자 발송시 해당 내용이 문의 전화번호 아래에 보여집니다.</li>
                  <li>'설정 저장'을 클릭하시면 본 서비스 이용시 마다, 설정된 내용의 문자가 고객앞으로 발송됩니다.</li>
                  <li>설정 항목 입력 및 저장 이후, '발송 내용 미리보기' 클릭 시, 고객앞 발송될 내용이 보여집니다.</li>
                </ul>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <h4>입력 항목</h4>
                    <Table>
                      {CM.cfnCompColGroup(["20%", "55%", "auto"])}
                      <TableBody>
                        <TableRow>
                          <TableCell className="th">제목</TableCell>
                          <TableCell className="td">
                            <Input className="w350" value= {sendSmsSubjectContents} placeholder="OOO 기관 미출금 내역 안내" onChange={handleOnChange("smsSubject")}  />
                          </TableCell>
                          <TableCell className="td">자동 입력 및 내용 수정 가능</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">안내 문구</TableCell>
                          <TableCell className="td">
                            <Input className="w350" value= {sendSmsTitleContents} placeholder="OOO 고객님의 미출금 내역을 안내합니다." onChange={handleOnChange("smsTitle")}  />
                          </TableCell>
                          <TableCell className="td">자동 입력 및 내용 수정 가능<br/>(단,성명은 수정 불가)</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">미출금 발생일</TableCell>
                          <TableCell className="td">CMS 출금일로 미납 발생일자 표기
                            <br/>※ 예시) 2022.06.20</TableCell>
                          <TableCell className="td">자동 입력 및 수정 불가</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">계좌 정보</TableCell>
                          <TableCell className="td">미출금된 참가기관명 및 계좌번호 일부 표기
                            <br/>※ 예시) 우리 은행 123-45-67**** (마지막 4자리는 * 표기)</TableCell>
                          <TableCell className="td">자동 입력 및 수정 불가</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">미출 금액</TableCell>
                          <TableCell className="td">미출금 총액 표기 (부분출금시, 미출금된 잔액 표기)</TableCell>
                          <TableCell className="td">자동 입력 및 수정 불가</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">납부 가능 시한</TableCell>
                          <TableCell className="td">익일 표기</TableCell>
                          <TableCell className="td">자동 입력 및 수정 불가</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">문의 전화번호
                            <TooltipForm contents="빌링원플러스 기관정보의 전화번호를 표시합니다."/>
                          </TableCell>
                          <TableCell className="td">02-111-2222</TableCell>
                          <TableCell className="td">자동 입력 및 수정 불가</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="th">기타 (60byte 제한)</TableCell>
                          <TableCell className="td">
                            <Input className="w350" maxLength="5" value={sendSmsEtcContents || ''} onChange={handleOnChange("smsEtc")} />
                          </TableCell>
                          <TableCell className="td">(필요시) 이용기관에서 기재</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div className="table-bottom-middle-area">
                      <Button className="btn-l" onClick={sendOpenGiroAndPgSendMessage} data-testid="close-modal">
                        설정 저장
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <div>
                      <div className="sms-message-area tc" style={{ marginTop : '25px', marginLeft : '10px'}}>
                        <div className="lms-message-textbox">
                          <div className="title">{sendSmsSubjectContents}</div>
                          <div className="lms-message-textbox messagebox" readOnly={true}>
                            <table>
                              <tbody>
                              <tr className="contentsTableRow">
                                <td colSpan="2" className="td"> [WEB발신] </td>
                              </tr>
                            <tr className="contentsTableRow">
                              <td colSpan="2" className="td"> OOO(고객명) {sendSmsTitleContents}</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td className="th">·&nbsp;미출금 발생일</td>
                              <td className="td"> 2022.06.20</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td className="th">·&nbsp;미납 계좌 정보</td>
                              <td className="td"> 우리은행 123-45-67****</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td className="th">·&nbsp;미출금 금액</td>
                              <td className="td"> 30,000원</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td className="th">·&nbsp;납부 가능 시한</td>
                              <td className="td"> 2022.06.22.</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td className="th">·&nbsp;문의 전화번호</td>
                              <td className="td"> 02-111-2222</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td colSpan="2" className="td" style={{wordBreak: "break-all"}}>{sendSmsEtcContents}</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td colSpan="2" className="td"> 아래 URL에 접속하시면, 즉시 납부가 가능합니다.</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td colSpan="2" className="td"> · 계좌 납부 : http://billingone.or.kr.acct</td>
                            </tr>
                            <tr className="contentsTableRow">
                              <td colSpan="2" className="td"> · 카드 납부 : http://billingone.or.kr.card</td>
                            </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Modal>
    </div>
  );
};




export default TabOpenGiroAndPgSendmessageModal;


