import React, {Fragment, useEffect, useState} from "react";
import {RowPerPageForm, SelectForm, TotalCountForm} from "../../template/ComponentForm";
import {
    Button,
    Checkbox,
    FormControlLabel, Modal,
    Radio,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@material-ui/core";
import * as CM from "../../common/Common";
import Pagination from "material-ui-flat-pagination/lib/Pagination";
import TableForm from "../../template/TableForm";
import RadioGroup from "@material-ui/core/RadioGroup";

/**
 * 조회 조건 기본 값 객체 생성
 */
const defaultSearchParam = () => {
    const searchDate = new Date();
    const searchDateY4 = searchDate.getFullYear().toString();
    const searchDateMm = searchDate.getMonth() < 9 ? `${0}${searchDate.getMonth() + 1}` : searchDate.getMonth() + 1;

    return {
        searchStartY4: searchDateY4,
        searchStartMm: searchDateMm,
        searchEndY4: searchDateY4,
        searchEndMm: searchDateMm,
        searchStatusList: { //청구 결과 상태 조건
            ALL: true, //전체선택
            ASKING: true,//납부중
            PAID: true, //납부완료
            CANCELLABLE: true,//납부완료(취소가능)
            CANCELED: true, //납부취소
            UNPAID: true,//납부안함
            FAIL_TO_PAY: true,//납부실패
        },
        searchPayMethod: "", //수납방법(null 또는 empty(전체), OPEN_GIRO(계좌이체(지로), PG_SERVICE(신용카드)
        searchContents: "", //검색내용
        searchProperty: "CUSTOMER_NAME", //검색대상 - CUSTOMER_NAME(고객이름), PAYER_NO(납부자번호)
        sortProperty: "ASK_DATE", //정렬기준 -  ASK_DATE(청구일시), CUSTOMER_NAME(고객이름), PAYER_NO(납부자번호)
        sortDirection: "DESC", //정렬방향 - ASC(오름차순), DESC(내림차순)
        pageNumber: "0", //페이지 번호
        pageSize: "5", //페이지 크기
    };
}

/**
 * 검색 조건 컴포넌트
 */
const SearchForm = (props) => {
    const {searchOption, onClickSearch, onChangeSearchForm} = props;
    const searchKeywordTypeList = [
        {label: "고객이름", value: "CUSTOMER_NAME"},
        {label: "납부자번호", value: "PAYER_NO"}];

    return (
        <div className="block">
            <Table>
                {CM.cfnCompColGroup(["120px", "auto", "120px", "auto"])}
                <TableBody>
                    <TableRow>
                        <TableCell className="th">출금청구 월</TableCell>
                        <TableCell className="td">
                            <SelectForm
                                value={searchOption.searchStartY4}
                                handleChange={onChangeSearchForm("searchStartY4")}
                                name="searchStartY4"
                                arrayOption={CM.getYearOptionListForSelectBox(2)}
                                optionValue="value"
                                optionLabel="label"
                            />
                            <SelectForm
                                value={searchOption.searchStartMm}
                                handleChange={onChangeSearchForm("searchStartMm")}
                                name="searchStartMm"
                                arrayOption={CM.getMonthOptionListForSelectBox(searchOption.searchStartY4, 2)}
                                optionValue="value"
                                optionLabel="label"
                            />
                            <span className="between">~</span>
                            <SelectForm
                                value={searchOption.searchEndY4}
                                handleChange={onChangeSearchForm("searchEndY4")}
                                name="searchEndY4"
                                arrayOption={CM.getYearOptionListForSelectBox(2)}
                                optionValue="value"
                                optionLabel="label"
                            />
                            <SelectForm
                                value={searchOption.searchEndMm}
                                handleChange={onChangeSearchForm("searchEndMm")}
                                name="searchEndMm"
                                arrayOption={CM.getMonthOptionListForSelectBox(searchOption.searchEndY4, 2)}
                                optionValue="value"
                                optionLabel="label"
                            />
                        </TableCell>
                        <TableCell className="th"> 검색어 입력</TableCell>
                        <TableCell className="td">
                            <Select native data-testid="searchProperty_selectBox" className="w110" name="searchProperty"
                                    value={searchOption.searchProperty}
                                    onChange={onChangeSearchForm("searchProperty")}>
                                {searchKeywordTypeList.map((option, index) => {
                                    return (
                                        <option value={option.value} key={index}>
                                            {option.label}
                                        </option>
                                    );
                                })}
                            </Select>
                            <TextField
                                data-testid="searchContents_inputBox"
                                className="w130"
                                value={searchOption.searchContents}
                                onChange={onChangeSearchForm("searchContents")}
                                onKeyUp={(e) => {
                                    if (e.keyCode === 13) onClickSearch();
                                }}
                                name="searchContents"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="th">청구 결과</TableCell>
                        <TableCell className="td" colSpan={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.ALL}
                                        value={searchOption.searchStatusList.ALL}
                                        onChange={onChangeSearchForm("ALL")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-new-account",
                                        }}
                                    />
                                }
                                label="전체"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.ASKING}
                                        value={searchOption.searchStatusList.ASKING}
                                        onChange={onChangeSearchForm("ASKING")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-closing-account",
                                        }}
                                    />
                                }
                                label="납부중"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.PAID}
                                        value={searchOption.searchStatusList.PAID}
                                        onChange={onChangeSearchForm("PAID")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-closing-account",
                                        }}
                                    />
                                }
                                label="납부완료"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.CANCELLABLE}
                                        value={searchOption.searchStatusList.CANCELLABLE}
                                        onChange={onChangeSearchForm("CANCELLABLE")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-closing-account",
                                        }}
                                    />
                                }
                                label="납부완료(취소가능)"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.CANCELED}
                                        value={searchOption.searchStatusList.CANCELED}
                                        onChange={onChangeSearchForm("CANCELED")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-closing-account",
                                        }}
                                    />
                                }
                                label="납부취소"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.UNPAID}
                                        value={searchOption.searchStatusList.UNPAID}
                                        onChange={onChangeSearchForm("UNPAID")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-closing-account",
                                        }}
                                    />
                                }
                                label="납부안함"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={searchOption.searchStatusList.FAIL_TO_PAY}
                                        value={searchOption.searchStatusList.FAIL_TO_PAY}
                                        onChange={onChangeSearchForm("FAIL_TO_PAY")}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                            "data-testid": "check-closing-account",
                                        }}
                                    />
                                }
                                label="납부실패"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="th">수납 방법</TableCell>
                        <TableCell className="td">
                            <RadioGroup name="searchPayMethod" value={searchOption.searchPayMethod}
                                        onChange={onChangeSearchForm("searchPayMethod")} row>
                                <FormControlLabel value="" control={<Radio color="primary"/>} label="전체"/>
                                <FormControlLabel value="OPEN_GIRO" control={<Radio color="primary"/>}
                                                  label="계좌이체(지로)"/>
                                <FormControlLabel value="PG_SERVICE" control={<Radio color="primary"/>} label="신용카드"/>
                            </RadioGroup>
                        </TableCell>
                        <TableCell className="td" colSpan={2}/>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}

const askResultMap = {
    ASKING: "납부 중",
    UNPAID: "납부안함",
    PAID: "납부완료",
    CANCELED: "납부취소",
    CANCELLABLE: "납부완료(취소가능)",
    // FAIL_TO_ASK: "청구실패",
    FAIL_TO_PAY: "납부 중",
    FAIL_TO_CANCEL: "납부완료(취소가능)"
}
const payMethodMap = {
    OPEN_GIRO: "계좌이체(지로)",
    PG_SERVICE: "신용카드"
}

/**
 * 청구 결과 리스트 컴포넌트
 */
const ListForm = (props) => {
    let {resultList, openFailToPayModal, cancelPgPayment} = props;

    const onClickCancelPgPayment = (row) => {
        let message = `신용카드 납부(결제)내역을 취소하시겠습니까?\n(고객명: ${row.customerName},금액: ${CM.cfnAddComma(row.totalAskingAmount)}원)\n*납부취소는 되돌릴 수 없습니다.`;
        CM.cfnConfirm(message, () => cancelPgPayment(row.pgUniqueKey));
    }

    const renderRemark = (row) => {
        if(row.askResult === 'FAIL_TO_PAY'){
            return (
                <button className="btn-l3" type="button" onClick={()=>{openFailToPayModal(row.openGiroUniqueKey)}}>
                    납부실패
                </button>)
        }else if(row.payMethod === 'PG_SERVICE' && (row.askResult === 'CANCELLABLE' || row.askResult === 'FAIL_TO_CANCEL')){
            return (
                <button className="btn-l3" type="button" onClick={()=>{onClickCancelPgPayment(row)}}>
                    납부취소
                </button>
            )
        }
    }

    return (
        <div>
            <Table>
                {CM.cfnCompColGroup(["9%", "9%", "6%", "6%", "7%", "7%", "6%", "6%", "8%", "8%", "6%", "8%", "auto"])}
                <TableForm.compServerSortTableHead
                    arrData={[
                        {id: "ASK_DATE", label: "청구 일시", sortable: true},
                        {id: "PAID_CANCELED_DATE", label: "납부(취소) 일시", sortable: false},
                        {id: "TARGET_Y4MM", label: "대상 월", sortable: false},
                        {id: "PAY_SPECIFIED_DAY", label: "수납일", sortable: false},
                        {id: "CUSTOMER_NAME", label: "고객명", sortable: true},
                        {id: "PAYER_NO", label: "납부자번호", sortable: true},
                        {id: "TRANSACTION_METHOD", label: "수납방법", sortable: false},
                        {id: "PAY_AMOUNT_TYPE", label: "자금유형", sortable: false},
                        {id: "CAPITAL", label: "자금종류", sortable: false},
                        {id: "UNPAID_AMOUNT", label: "미납 금액", sortable: false},
                        {id: "DELAY_FEE", label: "연체료", sortable: false},
                        {id: "TOTAL_ASKING_AMOUNT", label: "청구 총액", sortable: false},
                        {id: "PAID_RESULT", label: "청구 결과", sortable: false},
                        {id: "REMARK", label: "비고", sortable: false},
                    ]}
                    searchRequest={props.searchRequest}
                    handleSortProperty={props.handleSortProperty}
                />
                <TableBody>
                    {resultList.length === 0 ? (
                        <TableForm.compEmptyTableRow colSpan={14}/>
                    ) : (
                        resultList.map((row, index) => {
                            return (
                                <TableRow key={"TableRow" + index}>
                                    <TableCell align="center">{CM.cfnDateFormat(row.askedDate + row.askedTime).split("\n")
                                        .map((line, i) => (
                                            <Fragment key={"askDate" + index + i}>
                                                <span>{line}</span>
                                                <br/>
                                            </Fragment>))}
                                    </TableCell>
                                    <TableCell
                                        align="center">{(row.cancelDateTime === "" && row.paidDate === "") ? "-" : CM.cfnDateFormat(
                                            row.cancelDateTime ? row.cancelDateTime : row.paidDate + row.paidTime).split("\n")
                                        .map((line, i) => (
                                            <Fragment key={"cancelDate"+ index + i}>
                                                <span>{line}</span>
                                                <br/>
                                            </Fragment>))}
                                    </TableCell>
                                    <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                                    <TableCell
                                        align="center">{row.paySpecifiedDay === "99" ? "말일" : row.paySpecifiedDay} </TableCell>
                                    <TableCell align="center">{row.customerName}</TableCell>
                                    <TableCell align="center">{row.payerNo}</TableCell>
                                    <TableCell align="center">{row.payMethod === null ? "-" : payMethodMap[row.payMethod] }</TableCell>
                                    <TableCell align="center">{row.payAmountType}</TableCell>
                                    <TableCell align="center">{row.capital}</TableCell>
                                    <TableCell align="center">{CM.cfnAddComma(row.askingUnpaidAmount)}원</TableCell>
                                    <TableCell align="center">{CM.cfnAddComma(row.askingDelayFee)}원</TableCell>
                                    <TableCell align="center">{CM.cfnAddComma(row.totalAskingAmount)}원</TableCell>
                                    <TableCell align="center">{askResultMap[row.askResult]}</TableCell>
                                    <TableCell align="center">{renderRemark(row)}</TableCell>
                                </TableRow>);
                        })
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

/**
 * 납부실패 내역 Modal 컴포넌트
 */
const FailToPayListModal = (props) => {
    let {open, close, list} = props;

    return (
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
            <div className="paper">
                <div className="inner">
                    <div className="modal-top-area">
                        <Button className="fr btn-close" onClick={close} data-testid="close-modal">
                            {" "}
                        </Button>
                    </div>
                    <h3>납부 실패 내역</h3>
                    <Table aria-labelledby="tableTitle">
                        {CM.cfnCompColGroup(["25%", "25%", "25%", "25%"])}
                        <TableForm.compTableHead arrData={["거래일시", "수납방법", "에러코드", "비고"]} />
                        <TableBody>
                            {list.length === 0 ? (
                                <TableForm.compEmptyTableRow colSpan={4} />
                            ) : (
                              list.map((row, index)=>{
                                  return (
                                      <TableRow key={index}>
                                          <TableCell align="center">{CM.cfnDateFormat(row.paidDate + row.paidTime).split("\n")
                                              .map((line, i) => (
                                                  <Fragment key={"askDate" + index + i}>
                                                      <span>{line}</span>
                                                      <br/>
                                                  </Fragment>))}</TableCell>
                                          <TableCell align="center">계좌이체(지로)</TableCell>
                                          <TableCell align="center">{row.responseCode}</TableCell>
                                          <TableCell align="center">{row.responseMessage}</TableCell>
                                      </TableRow>
                                  )
                              })
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Modal>
    );
}

/**
 * 메인 컴포넌트
 */
const UnpaidReceiptResult = (props) => {
    const {tabIndex} = props;

    const [excelOption, setExcelOption] = useState({});
    const [searchOption, setSearchOption] = useState(defaultSearchParam());
    const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
    const [excelButton, setExcelButton] = useState(false); // 엑셀버튼 실행 flag
    const [failToPayList, setFailToPayList] = useState([])
    const [resultList, setResultList] = useState([]); // table 데이터
    const [pagination, setPagination] = useState(CM.makePaginationData());
    const [modalOpen, setModalOpen] = useState(false); // 납부 실패내역 Modal 오픈 Flag

    /**
     * 검색 조건 폼 변경 처리
     */
    const onChangeSearchForm = (name) => (e) => {
        let newSearchOption = {...searchOption};
        if (e.target.type === "checkbox") {
            if (name === "ALL") {
                newSearchOption.searchStatusList = {
                    ALL: e.target.checked,//전체
                        PAID: e.target.checked,//납부완료
                        CANCELED: e.target.checked,//납부취소
                        CANCELLABLE: e.target.checked,//납부완료(취소가능)
                        UNPAID: e.target.checked,//납부안함
                        FAIL_TO_PAY: e.target.checked,//납부실패
                        ASKING: e.target.checked,//납부중
                };
            } else {
                newSearchOption.searchStatusList = {
                    ...searchOption.searchStatusList,
                    [name]: e.target.checked,
                }
            }
        } else {
            newSearchOption.searchContents = name === "searchProperty" ? "" : searchOption.searchContents;
            newSearchOption[name] = e.target.value;
        }
        setSearchOption(newSearchOption);
    }

    /**
    * 미납 내역 청구 결과 검색 내용 조회
    **/
    const onClickSearch = () => {
        setSearchOption((data) => ({...data, pageNumber: 0}));
        setSearchButton(true);
    }

    /**
    * 정렬 조건 변경 이벤트 핸들러
    **/
    const handleSortProperty = async (sortObjArray) => {
        let sortProperty = "";
        let sortDirection = "";

        for (const obj of sortObjArray) {
            if (obj.name === "sortProperty") sortProperty = obj.value;
            if (obj.name === "sortDirection") sortDirection = obj.value;
        }

        setSearchOption({
            ...searchOption,
            sortProperty: sortProperty,
            sortDirection: sortDirection
        });
        setSearchButton(true);
    };

    /**
     * 페이지 변경 이벤트 핸들러
     */
    const handleOffsetChange = (offset, page) => {
        setSearchOption((data) => ({...data, pageNumber: page - 1}));
        setSearchButton(true);
    };

    /**
     * 페이지 당 조회건수 변경 이벤트 핸들러
     */
    const handleRowPerPageChange = (e) => {
        const value = e.target.value;
        setSearchOption((data) => ({...data, pageSize: value, pageNumber: 0}));
        setSearchButton(true);
    };

    /**
     * 납부실패내역 조회
     */
    const getFailToPayList = (openGiroUniqueKey) => {

        let url = `api/receipt/unpaid/result/fail/${openGiroUniqueKey}`;
        CM.cfnAxios(url, "get", "", (status, data) => {
            setFailToPayList(data);
            setModalOpen(true);
        });
    }

    /**
     * 신용카드PG 납부 취소
     */
    const cancelPgPayment = (pgUniqueKey) => {
        const url = `api/receipt/unpaid/cancel/pg/${pgUniqueKey}`;
        CM.cfnAxios(
            url, "post", null, (statusObj) => {
                if(statusObj.status === 200){
                    CM.cfnAlert("미납금 신용카드 납부를 정상 취소했습니다.", () =>{
                        setSearchButton(true);
                    });
                }
            }
        );
    }

    //청구 결과 상태 조건 체크박스 선택 시, '전체'를 제외한 체크박스 모두 선택 또는 하나라도 미선택 시 '전체' 체크박스 활성화 또는 비활성화 시키는 함수
    useEffect(() => {
        if (!searchOption.searchStatusList.ALL &&
            searchOption.searchStatusList.PAID &&
            searchOption.searchStatusList.CANCELED &&
            searchOption.searchStatusList.CANCELLABLE &&
            searchOption.searchStatusList.UNPAID &&
            searchOption.searchStatusList.FAIL_TO_PAY &&
            searchOption.searchStatusList.ASKING) {
            //'전체'를 제외한 체크박스 모두 선택 시 '전체' 체크박스 선택
            setSearchOption({
                ...searchOption,
                searchStatusList: {
                    ...searchOption.searchStatusList,
                    ALL: true,
                },
            });
        } else if (searchOption.searchStatusList.ALL &&
            (!searchOption.searchStatusList.PAID ||
                !searchOption.searchStatusList.CANCELLABLE ||
                !searchOption.searchStatusList.CANCELED ||
                !searchOption.searchStatusList.UNPAID ||
                !searchOption.searchStatusList.FAIL_TO_PAY ||
                !searchOption.searchStatusList.ASKING)

        ) {
            //'전체'가 체크되어있는 상태에서 '전체'가 아닌 체크박스 하나라도 해제 시, '전체' 체크박스 해제
            setSearchOption({
                ...searchOption,
                searchStatusList: {
                    ...searchOption.searchStatusList,
                    ALL: false,
                },
            });
        }
    }, [searchOption]);

    useEffect(() => {
        const fnMakeParameter = (search) => {
            let tempObj = CM.cfnCopyObject(search);
            tempObj.searchStartY4Mm = search.searchStartY4 + search.searchStartMm;
            tempObj.searchEndY4Mm = search.searchEndY4 + search.searchEndMm;

            let tempArray = [];

            if (CM.cfnIsNotEmpty(search.searchStatusList)) {
                const keyArray = Object.keys(search.searchStatusList);
                Object.values(search.searchStatusList).map((value, index) => (value && keyArray[index] !== "ALL" ? tempArray.push(keyArray[index]) : ""));
            }

            tempObj.searchStatusList = tempArray;

            return tempObj;
        };

        const makeUrlQueryString = (searchParam) => `searchStartY4mm=${searchParam.searchStartY4Mm}&searchEndY4mm=${searchParam.searchEndY4Mm}&searchContents=${searchParam.searchContents}&searchProperty=${searchParam.searchProperty}&askResultConditionList=${searchParam.searchStatusList}&payMethod=${searchParam.searchPayMethod}&sortProperty=${searchParam.sortProperty}&sortDirection=${searchParam.sortDirection}`;

        const axiosList = (searchParam) => {
            return new Promise((resolve) => {
                let url = `api/receipt/unpaid/result?${makeUrlQueryString(searchParam)}&pageNumber=${searchParam.pageNumber}&pageSize=${searchParam.pageSize}`;
                CM.cfnAxios(url, "get", "", (status, data) => {
                    resolve(data);
                });
            });
        };

        const axiosExcel = (searchParam) => {
            return new Promise((resolve) => {
                const url = `api/receipt/unpaid/result/excel?${makeUrlQueryString(searchParam)}`;
                CM.cfnAxiosFileDownload(url, "get", "", () => {});
            });
        };

        const startAxios = async (search, buttonType) => {
            setSearchButton(false);
            setExcelButton(false);
            if(search.searchStartY4Mm > search.searchEndY4Mm){
                CM.cfnAlert("조회기간의 종료년월은 시작년월과 같거나 그 이후로 설정하세요.");
                return false;
            }
            if(search.searchStatusList.length === 0){
                CM.cfnAlert("청구 결과 상태를 체크하세요.");
                return false;
            }
            if (buttonType === "search") {
                const data = await axiosList(search);
                setResultList(data.content);
                setPagination(CM.makePaginationData(data));
                setExcelOption(searchOption);
            } else if (buttonType === "excel") {
                await axiosExcel(search);
            }
        }

        if (tabIndex === 2 && searchButton === true) {
            const param = fnMakeParameter(searchOption);
            startAxios(param, "search");
        } else if (tabIndex === 2  && excelButton === true) {
            const param = fnMakeParameter(excelOption);
            startAxios(param, "excel");
        } else if (tabIndex !== 2  && !searchButton) {
            setSearchButton(true);
        }
    }, [tabIndex, searchButton, searchOption, excelButton, excelOption]);



    return (
        <div>
            <div className="inforbox">
                <ul>
                    <li>(청구결과 조회) 조회 대상 월을 선택·검색하여 미납 내역 청구 결과를 확인할 수 있습니다.</li>
                    <li>청구결과는 다음과 같이 구분됩니다.
                        <br/>① 납부 중 : 청구후 납부진행 중인 건, ② 납부완료 : 정상납부 및 처리완료된 건, ③ 납부완료(취소가능) : 정상납부완료 건 중 취소가능 상태인 건,
                        <br/>④ 납부취소 : 납부 후 취소처리 된 건, ⑤ 납부안함 : 유효기간 만료시까지 납부되지 않은 건, ⑥ 납부실패 : 납부 중 정상처리되지 않은 건
                    </li>
                    <li>납부취소의 경우, 신용카드 납부분에 한하여 납부당일까지 가능합니다.</li>
                </ul>
            </div>
            <div>
                <SearchForm
                    searchOption={searchOption}
                    onClickSearch={onClickSearch}
                    onChangeSearchForm={onChangeSearchForm}
                />
                <button className="btn-l fr" onClick={onClickSearch} data-testid="post-opengiro-and-pg-request">
                    조회
                </button>
                <h4>즉시납부 청구결과</h4>
                {/* 건수 및 청구 버튼 폼 */}
                <div className="table-top-area">
                    <TotalCountForm totalElements={pagination.total || 0}/>
                    <RowPerPageForm data-testid="receiptList-rowPerPage-select" value={searchOption.pageSize}
                                    onChange={handleRowPerPageChange}/>
                    <button className="btn-m fr" data-testid="save-excel" onClick={() => setExcelButton(true)}>
                        엑셀저장
                    </button>
                </div>
                {/* 미납 내역 청구 결과분 리스트 보여주는 부분 */}
                <ListForm
                    resultList={resultList}
                    pagination={pagination}
                    page={searchOption.pageNumber}
                    searchRequest={searchOption}
                    handleSortProperty={handleSortProperty}
                    openFailToPayModal={getFailToPayList}
                    cancelPgPayment={cancelPgPayment}
                />
                <Pagination
                    className="centered"
                    limit={pagination.rowsPerPage}
                    offset={pagination.offset}
                    total={pagination.total}
                    onClick={(e, offset, page) => handleOffsetChange(offset, page)}
                    reduced={true}
                    centerRipple={false}
                    disableFocusRipple={true}
                    disableRipple={true}
                />
                <FailToPayListModal
                    open={modalOpen}
                    close={()=>{
                        setFailToPayList([]);
                        setModalOpen(false);
                    }}
                    list={failToPayList}
                />
            </div>
        </div>
    );
};

export default UnpaidReceiptResult;
