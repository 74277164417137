import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState, Fragment } from "react";
import * as CM from "../common/Common";
import { FolderUploadForm, SelectForm } from "./ComponentForm";
import TableForm from "./TableForm";

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// main component that handles the render
export default function EvidenceFileBatchRegister() {
  // value for initialization
  const defaultValue = { files: [], folderName: "" };

  const [evidenceType, setEvidenceType] = useState(""); // state to control the classification of evidence
  const [selectedFolder, setSelectedFolder] = useState(defaultValue); // selected folder and files
  const [fileStatus, setFileStatus] = useState([]); // 각 파일별 상태
  const [isRequesting, setIsRequesting] = useState(false); // 등록중인지 여부
  const [currentProcessingSlide, setCurrentProcessingSlide] = useState(0); // 현재 등록중인 부분(처음 인덱스)
  const [totalSlide, setTotalSlide] = useState(0);

  // temporary state
  // const [requestList, setRequestList] = useState([]);

  // modal close
  // const handleClose = () => {
  //   // setRequestList([]);
  //   setSelectedFolder(defaultValue);
  //   handleModalClose();
  // };

  // handler to change the classification of evidence
  const handleEvidenceTypeChange = (e) => setEvidenceType(e.target.value);

  // handler to click batch registration
  const handleFileBulkRegister = (event) => {
    event.preventDefault();
    if (fnCheckValidation()) CM.cfnConfirm("기존 등록내용은 덮어씁니다. 등록하시겠습니까?", fnRegisterFiles);
  };

  const handleFolderSelected = (folderData) => {
    setFileStatus(Array(folderData.files.length).fill(null));
    setCurrentProcessingSlide(0);
    setTotalSlide(folderData.files.length);
    setSelectedFolder(folderData);
  };

  // function to handle validation
  const fnCheckValidation = () => {
    if (evidenceType === "") {
      CM.cfnAlert("동의자료 구분을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (CM.cfnIsEmpty(selectedFolder.folderName)) {
      CM.cfnAlert("업로드할 폴더를 선택하여 주시기 바랍니다.");
      return false;
    }

    return true;
  };

  /*
   * @desc    선택된 폴더 내 개별 파일의 validation 검증 함수
   */
  const fnCheckEachFileValidation = (file) => {
    const regExp = /[^0-9a-zA-Z]/;
    const fileSize = file.size;

    if (!file.name || file.name.lastIndexOf(".") === -1) {
      console.warn("동의자료 파일 검증실패 : 파일명이 없거나, 확장자가 없습니다. [" + file.name + "]");
      return false;
    }
    //영문, 숫자 외 다른 문자 있을 시 해당 파일을 제외시킴
    const fileName = file.name.substring(0, file.name.lastIndexOf("."));
    if (regExp.test(fileName)) {
      console.warn("동의자료 파일 검증실패 : 파일명에 영문, 숫자 외외의 문자가 존재합니다. [" + file.name + "]");
      return false;
    }

    //증빙파일 유형 선택에 따른 파일타입 체크
    const fileFullName = file.name;
    const fileType = fileFullName.substring(fileFullName.lastIndexOf(".") + 1).toUpperCase();

    if (fileType === "DER" && fileSize > 10 * 1024) {
      console.warn("동의자료 파일 검증실패 : .der파일은 최대 10KB까지의 파일만 가능합니다. 현재 [" + fileSize / 1024 + "]KB");
      return false;
    } else if (fileSize > 300 * 1024) {
      console.warn("동의자료 파일 검증실패 : 동의자료 파일은 최대 300KB까지의 파일만 가능합니다. 현재 [" + fileSize / 1024 + "]KB");
      return false;
    }

    switch (evidenceType) {
      case "1": // 서면
      case "3": // 일반전자서명
        if (fileType !== "JPG" && fileType !== "JPEG" && fileType !== "GIF" && fileType !== "TIF" && fileType !== "TIFF" && fileType !== "PDF") {
          console.warn("동의자료 파일 검증실패 : 동의자료 구분에 맞지 않는 확장자입니다. [" + fileType + "]");
          return false;
        }
        break;
      case "4": // 녹취
      case "5": // ARS
        if (fileType !== "MP3" && fileType !== "WAV" && fileType !== "WMA") {
          console.warn("동의자료 파일 검증실패 : 동의자료 구분에 맞지 않는 확장자입니다. [" + fileType + "]");
          return false;
        }
        break;
      case "2": // 공인전자서명
      case "7": // 사설전자서명
        if (fileType !== "DER") {
          console.warn("동의자료 파일 검증실패 : 동의자료 구분에 맞지 않는 확장자입니다. [" + fileType + "]");
          return false;
        }
        break;
      default:
        if (
          fileType !== "JPG" &&
          fileType !== "JPEG" &&
          fileType !== "GIF" &&
          fileType !== "TIF" &&
          fileType !== "TIFF" &&
          fileType !== "PDF" &&
          fileType !== "MP3" &&
          fileType !== "WAV" &&
          fileType !== "WMA" &&
          fileType !== "DER" &&
          fileType !== "TXT" &&
          fileType !== "JB64"
        ) {
          console.warn("동의자료 파일 검증실패 : 동의자료 구분에 맞지 않는 확장자입니다. [" + fileType + "]");
          return false;
        }
        break;
    }

    return true;
  };

  /*
   * @desc    동의자료 일괄등록 Request
   */
  const fnRegisterFiles = async () => {
    const url = "api/customer/receipt/account/extra/account-registrations/file-groups";

    const totalCount = selectedFolder.files.length;
    let currentCount = 0;
    let slideSize = 30; // 30개씩 끊어서 보낸다.
    let currentFileStatus = [...fileStatus];
    setIsRequesting(true);

    let form = new FormData();
    let hasEvidenceFileList = false;
    form.append("evidenceFileType", evidenceType);
    while (currentCount < totalCount) {
      slideSize = Math.min(slideSize, totalCount - currentCount);
      hasEvidenceFileList = false;
      try {
        form.delete("evidenceFileList");
      } catch (e) {
        // IE11에서는 formData의 delete method를 지원하지 않음
        form = new FormData();
        form.append("evidenceFileType", evidenceType);
      }

      for (let i = 0; i < slideSize; i++) {
        const file = selectedFolder.files[currentCount + i].file;

        if (fnCheckEachFileValidation(file)) {
          form.append("evidenceFileList", file, file.name);
          currentFileStatus[currentCount + i] = "REQUESTING";
          hasEvidenceFileList = true;
        } else {
          currentFileStatus[currentCount + i] = "INVALID";
        }
      }
      setFileStatus(currentFileStatus);

      if (hasEvidenceFileList) {
        let response = null;
        try {
          response = await CM.cfnAxiosAsync(url, "post", form, true);
        } catch (error) {
          for (let i = 0; i < slideSize; i++) {
            if (currentFileStatus[currentCount + i] === "REQUESTING") {
              currentFileStatus[currentCount + i] = "ERROR";
            }
          }
          setFileStatus(currentFileStatus, currentFileStatus);
          let message = "";
          if (typeof error.response.data === "string") message = error.response.data;
          else if (typeof error.response.data === "object") message = error.response.data.message;
          else
            message =
              "첨부할 동의자료 파일이 네트워크(공유) 폴더에 보관하시는 경우라면,\n바탕화면 등으로 파일을 복사하신 뒤 첨부하여주시기 바랍니다.\n같은 현상이 반복된다면 고객센터(1577-5500)로 문의하여주시기 바랍니다.";
          CM.cfnAlert("등록 작업 중 오류가 발생하였습니다. (" + message + ")");
          break;
        }
        if (response.status !== 200) {
          for (let i = 0; i < slideSize; i++) {
            if (currentFileStatus[currentCount + i] === "REQUESTING") {
              currentFileStatus[currentCount + i] = "ERROR";
            }
          }
          setFileStatus(currentFileStatus);
          CM.cfnAlert("등록 작업 중 오류가 발생하였습니다. (" + (response.data ? response.data.message : "") + ")");
          break;
        }
      }

      for (let i = 0; i < slideSize; i++) {
        if (currentFileStatus[currentCount + i] === "REQUESTING") {
          currentFileStatus[currentCount + i] = "DONE";
        }
      }

      setFileStatus(currentFileStatus);

      currentCount += slideSize;
      setCurrentProcessingSlide(currentCount);
    }

    setIsRequesting(false);
    if (currentCount >= totalCount) {
      CM.cfnAlert("모든 작업이 완료되었습니다.");
    }
  };

  const readableSize = (size) => {
    let u,
      b = size;
    ["", "K", "M", "G", "T", "P", "E", "Z", "Y"].find((x) => {
      u = x;
      b /= 1024;
      return b ** 2 < 1;
    });
    return `${u ? (1024 * b).toFixed(1) : size}${u}B`;
  };

  // render start
  return (
    <Fragment>
      <div className="inforbox">
        <ul>
          <li>CMS출금계좌를 신규등록할 경우에는 반드시 자동납부 동의자료가 첨부되어야 합니다.</li>
          <li>{isIE ? "등록할 동의자료를 한 번에 선택해주세요." : "동의자료가 저장된 폴더를 선택해 주세요."}</li>
          <li>일괄등록 시 동의자료 파일명은 납부자번호와 동일해야 합니다.(예: 001.jpg, 001.pdf) 영문 및 숫자 이외의 다른 문자가 있는 경우 등록이 불가합니다.</li>
        </ul>
      </div>

      <div className="modify-area">
        <div className="d-inline-flex flex-center">
          <label className="label-l" data-testid="type-label">
            동의자료 구분
          </label>
          <SelectForm
            value={evidenceType}
            handleChange={handleEvidenceTypeChange}
            name="evidenceFileType"
            arrayOption={CM.cfnGetEvidenceFileTypeNumber()}
            optionValue={"evidenceFileType"}
            optionLabel={"evidenceFileTypeName"}
            customProps={{
              inputProps: { "data-testid": "file-type-select" },
            }}
          />
          <label className="label-l">{isIE ? "파일 일괄선택" : "폴더선택"}</label>
          <FolderUploadForm uploadFolder={selectedFolder} setUploadFolder={handleFolderSelected} />
          <button className="btn-l" onClick={handleFileBulkRegister} disabled={isRequesting}>
            일괄등록
          </button>
          {isRequesting || currentProcessingSlide > 0 ? (
            <span style={{ position: "absolute", right: 0 }}>
              일괄등록 {currentProcessingSlide < totalSlide ? (isRequesting ? "진행중..." : "진행중단") + `(${currentProcessingSlide}/${totalSlide})` : `진행완료(${totalSlide}/${totalSlide})`}
            </span>
          ) : null}
        </div>
      </div>
      <Table aria-labelledby="tableTitle" data-testid="FileRegisters-table">
        {CM.cfnCompColGroup(["10%", "auto", "10%", "10%"])}
        <TableForm.compTableHead arrData={["순번", "동의자료 파일명", "파일크기", "진행상태"]} />
        <TableBody>
          {selectedFolder.files.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={4} />
          ) : (
            CM.cfnIsNotEmpty(selectedFolder.files) &&
            selectedFolder.files.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{row.file.name}</TableCell>
                  <TableCell align="center">
                    {readableSize(row.file.size)}
                    {row.resized ? (
                      <Fragment>
                        <br />
                        (압축됨)
                      </Fragment>
                    ) : null}
                  </TableCell>
                  <TableCell align="center">
                    {fileStatus[index] === "DONE"
                      ? "등록완료"
                      : fileStatus[index] === "INVALID"
                      ? "파일검증실패"
                      : fileStatus[index] === "ERROR"
                      ? "등록실패"
                      : fileStatus[index] === "REQUESTING"
                      ? "등록중"
                      : "대기중"}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <h4>자동납부 동의자료 구분 및 허용 용량</h4>
      <div>
        <Table className="contentsTable">
          <TableHead>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">동의자료 구분</TableCell>
              <TableCell className="contentsTableCell">동의자료 확장자</TableCell>
              <TableCell className="contentsTableCell">최대 크기(KByte)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">서면</TableCell>
              <TableCell className="contentsTableCell">jpg, jpeg, gif, tif, pdf</TableCell>
              <TableCell className="contentsTableCell">300</TableCell>
            </TableRow>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">전자문서·공인전자서명</TableCell>
              <TableCell className="contentsTableCell">der(RFC 2630 SignedData를 DER 인코딩 형태로 생성)</TableCell>
              <TableCell className="contentsTableCell">10</TableCell>
            </TableRow>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">전자문서·일반전자서명</TableCell>
              <TableCell className="contentsTableCell">jpg, jpeg, gif, tif, pdf</TableCell>
              <TableCell className="contentsTableCell">300</TableCell>
            </TableRow>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">녹취</TableCell>
              <TableCell className="contentsTableCell">mp3, wav, wma</TableCell>
              <TableCell className="contentsTableCell">300</TableCell>
            </TableRow>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">ARS</TableCell>
              <TableCell className="contentsTableCell">mp3, wav, wma</TableCell>
              <TableCell className="contentsTableCell">300</TableCell>
            </TableRow>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">전자문서·기타</TableCell>
              <TableCell className="contentsTableCell">jpg, jpeg, gif, tif, pdf, der, mp3, wav, wma, txt, jb64</TableCell>
              <TableCell className="contentsTableCell">300</TableCell>
            </TableRow>
            <TableRow className="contentsTableRow">
              <TableCell className="contentsTableCell">전자문서·사설전자서명</TableCell>
              <TableCell className="contentsTableCell">der(RFC 2630 SignedData를 DER 인코딩 형태로 생성)</TableCell>
              <TableCell className="contentsTableCell">10</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Fragment>
  );
}
