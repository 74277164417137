import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

import { CreateCustomerDivision, CustomerRequiredInputs, CustomerIndividualSelectInput, CustomerBusinessSelectInput, ViewCustomerDivision } from "../customerBasicInformation/CustomerForm";
import { PaymentCustomerInfo } from "./PaymentCustomerForm";

import * as CM from "../../common/Common";
import PaymentData from "./PaymentCustomerData";
import CustomerSearch from "../customerBasicInformation/CustomerSearch";
import CustomerData from "../customerBasicInformation/CustomerData";
import { TooltipForm } from "../../template/ComponentForm";
import { Modal } from "@material-ui/core";
import { Button } from "@material-ui/core";
import StaffInfo from "../../institute/staffInfo/StaffInfo";
import CustMnmtTable from "../../institute/customerCriteria/CustMnmtTable";
/*
 * @desc: 렌더 메인 컴포넌트
 */
const CustomerCreate = ({ store, loginStore }) => {
  /* 고객정보 처리에 필요한 state 및 함수 선언 */
  // FIXME: 고객구분영역 hooks and handler
  const [firstIdentificationNo, setFirstIdentificationNo] = useState("");
  const [lastIdentificationNo, setLastIdentificationNo] = useState("");

  // 주민등록번호 앞자리, 뒷자리 handler
  const handleIdentificationNoChange = (e, type) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    changeIdentificationNo(value, type);
  };

  // 주민등록번호 앞자리, 뒷자리 입력시 해당 값 변경
  const changeIdentificationNo = (value, type) => {
    if (type === "first") {
      setFirstIdentificationNo(value);
    } else {
      setLastIdentificationNo(value);
    }
  };

  // FIXME: 개인 && 사업 > 고객정보 > 필수 입력사항 hooks and handler
  const [selectRequiredInputs, setSelectRequiredInputs] = useState({
    customerGroup: "", // 고객구분
    staff: "", // 고객관리담당자
  });
  const [optionStaffsAndBranches, setOptionStaffsAndBranches] = useState([]);

  // 필수 입력 사항 목록 가져오기
  useEffect(() => {
    async function startStoreAxios() {
      try {
        await store.axiosCustomerGroup(); // 고객구분
        const resultStaffs = await store.axiosStaffs();
        // 관리담당자/담당지사 option data 설정
        const tempStaffs = resultStaffs.map((staff) => ({ uniqueKey: staff.uniqueKey, name: `${staff.staffName}/${staff.branch.branchName}` }));

        setOptionStaffsAndBranches(tempStaffs);

        const customerGroups = toJS(store.customerGroup);
        if (!CM.cfnIsNotEmpty(location.state)) {
          setSelectRequiredInputs({
            ...selectRequiredInputs,
            staff: tempStaffs.length === 1 ? tempStaffs[0].uniqueKey : "",
            customerGroup: customerGroups.length === 1 ? customerGroups[0].uniqueKey : "",
          });

          setCustomer({
            ...customer,
            customerGroup: customerGroups[0],
            staff: resultStaffs[0],
          });
        }

        const storeInstituteService = await store.axiosInstituteService();
        let setting = { serviceUseYn: false, sendForAskingWithdraw: false, sendForFailureOnWithdraw: false, sendForSuccessOnWithdraw: false };
        for (const service of storeInstituteService) {
          if (service.serviceType === "SMS_TO_CUSTOMER") {
            setting = service;
          }
        }
        setting.serviceUseYn = !!setting.serviceUseYn;
        setting.sendForAskingDeposit = !!setting.sendForAskingDeposit;
        setting.sendForFailureOnDeposit = !!setting.sendForFailureOnDeposit;
        setting.sendForSuccessOnDeposit = !!setting.sendForSuccessOnDeposit;
        setSmsToCustomerSetting(setting);
        setPaymentInfo((val) => ({
          ...val,
          sendForAskingDeposit: setting.serviceUseYn && setting.sendForAskingDeposit,
          sendForFailureOnDeposit: setting.serviceUseYn && setting.sendForFailureOnDeposit,
          sendForSuccessOnDeposit: setting.serviceUseYn && setting.sendForSuccessOnDeposit,
        }));
      } catch (error) {
        console.log(error);
      }
    }

    startStoreAxios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  // 선택한 고객구분, 고객담당지사, 고객관리담당자 값을 변경하는 handler
  const handleRequiredInputsHandler = (e, name) => {
    const value = e.target.value;
    setSelectRequiredInputs((data) => ({ ...data, [name]: value }));
  };

  // FIXME: 고객정보 object 및 선택입력 사항 hooks & handler
  // 고객 이메일 주소
  const [customerEmail, setCustomerEmail] = useState({
    id: "",
    domain: "",
    selected: "manual",
  });

  // 사용자 정보
  const [customer, setCustomer] = useState({
    customerGroup: { uniqueKey: "" },
    staff: { branch: { uniqueKey: "" }, uniqueKey: "" },
    customerType: "INDIVIDUAL", // 고객 구분
    customerName: "", // 고객명
    identificationNo: "", // 전체 주민번호
    customerMobile: "", // 휴대전화번호
    customerEmail: "", // 이메일주소
    customerTelephone: "", // 유선전화번호
    customerFax: "", // 팩스번호
    customerDetailInfo: null,
    customerZipCode: "", // 우편번호
    customerAddress: "", // 주소
    customerAddressDetail: "", // 상세 주소
    remark: "", // 비고
    cashbillIdentificationNo: "", // 현금영수증 발행정보
  });
  const [smsToCustomerSetting, setSmsToCustomerSetting] = useState({ serviceUseYn: false, sendForAskingDeposit: false, sendForFailureOnDeposit: false, sendForSuccessOnDeposit: false });

  // 담당자 정보
  const [customerDetailInfo, setCustomerDetailInfo] = useState({
    결혼기념일: null,
    담당자명: "",
    담당자직위: "",
    대표자명: "",
    업태: "",
    업종: "",
  });

  // 담당자 정보 handler
  const handleCustomerDetailInfoChange = (name, e) => {
    const value = name !== "결혼기념일" ? e.target.value : e;
    setCustomerDetailInfo((data) => ({ ...data, [name]: value }));
  };

  // 현금영수증 발행정보 radio 값
  const [selectedCashbillIdentificationNo, setSelectedCashbillIdentificationNo] = useState("NONE");

  // 이메일 주소 handler
  const handleCustomerEmailChange = (e, name) => {
    const value = e.target.value;
    setCustomerEmail((email) => ({
      ...email,
      [name]: value,
    }));
  };

  // 이메일 주소 selectbox handler
  const handleSelectboxCustomerEmailChange = (e) => {
    const value = e.target.value;
    const domain = value !== "manual" ? value : "";

    setCustomerEmail((email) => ({
      ...email,
      domain: domain,
      selected: value,
    }));
  };

  // 선택입력사항 handler(이메일, 현금영수증 발행정보 제외)
  const handleCustomerChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    // 현금영수증 발행정보의 주민등록번호를 입력할 때
    if (name === "cashbillIdentificationNo" && selectedCashbillIdentificationNo === "IDENTIFICATION_NO") {
      value = value.replace(/[^0-9]/g, "");
    }

    changeCustomer(name, value);
  };
  // 현금영수증 발행정보 radio handler
  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedCashbillIdentificationNo(value);

    // 현금영수증 발행정보 input 초기화
    changeCustomer("cashbillIdentificationNo", "");
  };

  // 선택입력사항 입력시 해당 값 변경
  const changeCustomer = (name, value) => {
    setCustomer((data) => ({ ...data, [name]: value }));
  };
  /* 고객정보 처리에 필요한 state 및 함수 선언 종료 */

  /* 지급정보 처리에 필요한 state 및 함수 선언 */
  const [paymentInfo, setPaymentInfo] = useState(PaymentData.paymentDefaultValue);
  const [capitalRows, setCapitalRows] = useState([]);
  const [noneDeadline, setNoneDeadline] = useState(false);
  const [updateYn] = useState(false);
  /*
   * @desc    등록 버튼 클릭 이벤트 처리 함수
   */
  const handleSubmit = (e) => {
    if (PaymentData.fnCheckValidation("CREATE", paymentInfo, selectRequiredInputs, noneDeadline, readOnlyFlag, selectedCustomer, customer, firstIdentificationNo)) {
      fnInsertPaymentInformation();
    }
  };

  /*
   * @desc    지급정보 입력란 변경 이벤트 핸들러
   */
  const handlePaymentInfoChange = (depth1, depth2, depth3, depth4) => (e) => {
    if (depth1 === "transactionMethod") {
      //지급형태 변경 시
      if (e.target.value === "ETC") {
        //기타방식 선택 시 계좌정보 초기화
        setPaymentInfo({
          ...paymentInfo,
          transactionMethod: e.target.value,
          account: {
            accountBankCode: "",
            accountNo: "",
            depositorIdentificationNo: "",
            depositorName: "",
            registeredDate: "",
            unregisteredDate: "",
          },
        });
      } else {
        setPaymentInfo({
          ...paymentInfo,
          transactionMethod: e.target.value,
        });
      }
    } else if (depth1 === "payStartY4") {
      //지급시작월의 년도 변경 시
      const startMm = paymentInfo.payStartY4mm.substring(4);
      setPaymentInfo({
        ...paymentInfo,
        payStartY4mm: `${e.target.value}${startMm}`,
      });
    } else if (depth1 === "payStartMm") {
      //지급시작월의 월 변경 시
      const startY4 = paymentInfo.payStartY4mm.substring(0, 4);
      setPaymentInfo({
        ...paymentInfo,
        payStartY4mm: `${startY4}${e.target.value}`,
      });
    } else if (depth1 === "payEndY4") {
      //지급종료월의 년도 변경 시
      const endMm = paymentInfo.payEndY4mm.substring(4);
      setPaymentInfo({
        ...paymentInfo,
        payEndY4mm: `${e.target.value}${endMm}`,
      });
    } else if (depth1 === "payEndMm") {
      //지급종료월의 월 변경 시
      const endY4 = paymentInfo.payEndY4mm.substring(0, 4);
      setPaymentInfo({
        ...paymentInfo,
        payEndY4mm: `${endY4}${e.target.value}`,
      });
    } else if (depth1 === "payAmountType") {
      //지급금액 유형 변경 시
      setPaymentInfo({
        ...paymentInfo,
        payAmountType: e.target.value,
        payAmount: "", //지급금액 입력란 초기화
      });
    } else if (depth1 === "payAmount") {
      //지급금액 입력란 변경 시
      setPaymentInfo({
        ...paymentInfo,
        payAmount: CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, "")),
      });
    } else if (depth2 === "capitalName") {
      //자금종류 변경 시
      const selectedCapital = PaymentData.fnMatchSelectedCapital(e.target.value, capitalRows);

      setPaymentInfo({
        ...paymentInfo,
        bankbookContents: selectedCapital.bankbookContents,
        bankbookPostfix: selectedCapital.bankbookPostfix,
        bankbookPostfixType: selectedCapital.bankbookPostfixType,
        [depth1]: {
          uniqueKey: selectedCapital.uniqueKey,
        },
      });
    } else if (depth2 === "accountNo") {
      //계좌번호 입력 시
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: e.target.value.replace(/[^0-9]/g, ""),
        },
      });
    } else if (depth1 === "depositorNameCheckBox") {
      //납부자와 동일 체크박스 변경 시
      if (e.target.checked) {
        //고객기본정보 내 성명 및 생년월일(사업자번호) 입력
        setPaymentInfo({
          ...paymentInfo,
          account: {
            ...paymentInfo.account,
            depositorIdentificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo : customer.identificationNo,
            depositorName: customer.customerName,
          },
        });
      }
    } else if (depth1 === "smsSend") {
      //SMS고지 체크박스 변경 시
      if (e.target.value === "") {
        setPaymentInfo({
          ...paymentInfo,
          sendForAskingDeposit: false,
          sendForFailureOnDeposit: false,
          sendForSuccessOnDeposit: false,
        });
      } else if (e.target.value === "all") {
        setPaymentInfo({
          ...paymentInfo,
          sendForAskingDeposit: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingDeposit,
          sendForFailureOnDeposit: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnDeposit,
          sendForSuccessOnDeposit: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnDeposit,
        });
      } else if (depth2 !== "undefined") {
        setPaymentInfo({
          ...paymentInfo,
          [e.target.value]: e.target.checked,
        });
      }
    } else if (depth4 !== undefined) {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: {
            ...paymentInfo[depth1][depth2],
            [depth3]: {
              ...paymentInfo[depth1][depth2][depth3],
              [depth4]: e.target.value,
            },
          },
        },
      });
    } else if (depth3 !== undefined) {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: {
            ...paymentInfo[depth1][depth2],
            [depth3]: e.target.value,
          },
        },
      });
    } else if (depth2 !== undefined) {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: e.target.value,
        },
      });
    } else {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: e.target.value,
      });
    }
  };

  /*
   * @desc    전송 전 state 처리 함수
   */
  const fnConvertForRequest = () => {
    let customerClone = {};
    const paymentClone = CM.cfnCopyObject(paymentInfo);
    const copyCustomerDetailInfo = {}; // 고객추가정보
    let email = null;

    if (readOnlyFlag && CM.cfnIsNotEmpty(selectedCustomer)) {
      //고객기본정보 화면에서 이동했을 경우 uniqueKey만 전송
      customerClone = { uniqueKey: selectedCustomer.uniqueKey };
    } else {
      /* 고객정보 변환 처리 */
      customerClone = CM.cfnCopyObject(customer);

      // 고객추가정보 값 확인
      for (const [key, value] of Object.entries(customerDetailInfo)) {
        if (CM.cfnIsNotEmpty(value)) {
          const applyValue = key === "결혼기념일" ? CM.cfnConvertDateToString(value) : value;
          copyCustomerDetailInfo[key] = applyValue;
        }
      }

      // 고객추가정보 값 설정
      customerClone.customerGroup.uniqueKey = selectRequiredInputs.customerGroup;
      customerClone.staff.uniqueKey = selectRequiredInputs.staff;
      customerClone.customerDetailInfo = JSON.stringify(copyCustomerDetailInfo);
      customerClone.customerMobile = CM.cfnReplaceSymbol(customerClone.customerMobile);
      customerClone.customerTelephone = CM.cfnReplaceSymbol(customerClone.customerTelephone);
      customerClone.customerFax = CM.cfnReplaceSymbol(customerClone.customerFax);
      customerClone.identificationNo = CM.cfnReplaceSymbol(customerClone.identificationNo);

      // 현금영수증 발행정보가 휴대전화번호일 때
      if (selectedCashbillIdentificationNo === "MOBILE") {
        customerClone.cashbillIdentificationNo = CM.cfnReplaceSymbol(customerClone.cashbillIdentificationNo);
      }

      // 이메일이 빈값이 아니라면
      if (CM.cfnIsNotEmpty(customerEmail.id) || CM.cfnIsNotEmpty(customerEmail.domain)) {
        email = customerEmail.id + "@" + customerEmail.domain;
        // 이메일 형식 체크
        if (!CM.cfnCheckEmail(email)) {
          CM.cfnAlert("올바른 이메일 형식이 아닙니다.");
          return false;
        }
      }
      customerClone.customerEmail = email;

      // 개인일 때
      if ("INDIVIDUAL" === customer.customerType) {
        customerClone.identificationNo = firstIdentificationNo + lastIdentificationNo;
      }
      if (noneDeadline) {
        paymentClone.payEndY4mm = "999912";
        paymentClone.totalNumberOfPayment = null;
      }
    }

    paymentClone.customer = customerClone;

    /* 지급정보 변환 처리 */
    if (paymentClone.payAmountType === "FIXED" && CM.cfnIsNotEmpty(paymentClone.payAmount)) {
      //정액 선택 시 지급금액 integer로 변환
      paymentClone.payAmount = Number(CM.cfnReplaceSymbol(paymentClone.payAmount));
    } else if (paymentClone.payAmountType === "VARIABLE") {
      //비정액 선택 시 지급금액 null로 변환
      paymentClone.payAmount = null;
    }

    //총지급횟수 계산
    let numberOfPayments = Math.abs(CM.cfnDateCalculation(`${paymentClone.payStartY4mm}01`, `${paymentClone.payEndY4mm}01`, "m"));
    if (numberOfPayments === 0) numberOfPayments = 1; //총지급횟수가 0으로 나오면 임의로 1로 변경하여 넘김
    paymentClone.totalNumberOfPayment = numberOfPayments + 1;

    if (noneDeadline) {
      paymentClone.payEndY4mm = "999912";
      paymentClone.totalNumberOfPayment = null;
    }

    return paymentClone;
  };

  const checkMaxCustomerCount = async () => {
    const businessInfo = await store.axiosBusinessInfo();
    const loginStaffInfo = loginStore.loginDto;
    const url = `/api/customer/basics/count?customerName=${customer?.customerName}&identificationNo=${(firstIdentificationNo || "") + (lastIdentificationNo || "")}`;

    let response = await CM.cfnAxiosAsync(url, "get", null, false);
    if (typeof response.data === "number") {
      if (response.data < 0) {
        // 중복되는 항목이 존재함
        try {
          await CM.cfnConfirmAsync("등록할 지급고객정보의 같은 이름과 생년월일(사업자번호)로 등록된 기존 고객이 존재합니다.\n등록하시면 기존 고객에 지급정보가 추가됩니다. 등록하시겠습니까?");
          // true : 체크 완료
          return true;
        } catch (reject) {
          return false;
        }
      } else if (response.data >= businessInfo.maxCustomerCount) {
        //현재 등록 된 고객 수와 기관 최대 고객 수 비교
        //총괄담당자와 이용요금 담당자일 경우
        if (loginStaffInfo.masterYn || loginStaffInfo.billingManagerYn) {
          try {
            await CM.cfnConfirmAsync(
              "현재 등록된 고객수가 최대 등록가능 고객수에 도달하였습니다. 최대 등록가능 고객수를 100명 추가하신 후 등록하시겠습니까?\n※100명 추가 시 이용요금이 2,000원 증가됩니다."
            );
            businessInfo.maxCustomerCount = businessInfo.maxCustomerCount + 100;
            businessInfo.delayRate = null;
            await fnSubmitUrl(businessInfo);
            // true : 체크 완료
            return true;
          } catch (reject) {
            return false;
          }
        } else {
          CM.cfnAlert(
            "현재 등록된 고객수가 최대 등록가능 고객수에 도달하였습니다.\n최대 등록가능 고객수는 총괄담당자와 이용요금담당자가 변경할 수 있습니다.\n최대 등록가능 고객수 추가를 원하시면, 총괄담당자나 이용요금담당자에게 요청하시기 바랍니다."
          );
          return false;
        }
      }
    }
    return null; // 여유 있음
  };

  const fnSubmitUrl = async (sendData) => {
    return new Promise((resolve, reject) => {
      CM.cfnAxios(
        `/api/institute/business`,
        "put",
        sendData,
        () => {
          store.getBusinessInfo(fnGetBusinssInfo);
          resolve();
        },
        (error) => {
          if (error.response.status === 304) {
            CM.cfnAlert("변경된 내용이 없습니다.", () => {});
          } else {
            let message = "업무정보를 변경하지 못했습니다.";
            if (typeof error.response.data === "string") message = error.response.data;
            else if (typeof error.response.data === "object") message = error.response.data.message;
            CM.cfnAlert(message || error.response);
          }
          reject();
        },
        false,
        true
      );
    });
  };

  /*
   * @desc    업무정보 조회 후 callback 함수
   */
  const fnGetBusinssInfo = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }
    store.setBusinessInfo(data);
  };

  /*
   * @desc    지급정보 신규 저장 Request
   */
  const fnInsertPaymentInformation = async () => {
    //고객조회 정보가 없을 경우
    let checkResult = null;
    if (!(readOnlyFlag && CM.cfnIsNotEmpty(selectedCustomer))) {
      //현재 등록 된 고객 수와 기관 최대 고객 수 비교
      checkResult = await checkMaxCustomerCount();
      if (checkResult !== null && !checkResult) {
        return false;
      }
    }
    if (!checkResult) {
      // true인 경우는 이미 한 번 확인 받았으므로 바로 진행, null인 경우에는 확인창 없었으므로 한 번 더 확인
      try {
        await CM.cfnConfirmAsync("등록하시겠습니까?");
      } catch (reject) {
        return false;
      }
    }
    const sendData = fnConvertForRequest();
    const url = "/api/customer/payment/payments";
    CM.cfnAxios(url, "post", sendData, fnInsertCallback);
  };

  const fnInsertCallback = (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }
    CM.cfnAlert("정상적으로 등록되었습니다.");
    history.goBack();
  };
  /* 지급정보 처리에 필요한 state 및 함수 선언 종료 */

  const history = useHistory();
  const location = useLocation();

  // FIXME: 기타 hooks
  const [modalOpen, setModalOpen] = useState(false); // 모달 open
  const [customerGroupModalOpen, setCustomerGroupModalOpen] = useState(false);
  const [staffModalOpen, setStaffModalOpen] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState([]); // 고객조회에서 검색된 고객 목록
  const [selectedCustomer, setSelectedCustomer] = useState({}); // 고객조회에서 선택한 고객 정보
  const [readOnlyFlag, setReadOnlyFlag] = useState(false); // 화면 readOnly flag
  const [checkIdentificationNo, setCheckIdentificationNo] = useState(
    location.state && location.state && location.state.customer && location.state.customer.identificationNo && location.state.customer.identificationNo.length > 6
  ); // 주민등록번호앞자리 체크 hooks

  // 고객 조회 modal open
  const openSearchCustomerModal = async () => {
    let identificationNo = ""; // 주민등록번호 또는 사업자번호

    // 고객구분이 개인일 때
    if (customer.customerType === "INDIVIDUAL") {
      if (CM.cfnIsEmpty(customer.customerName) && CM.cfnIsEmpty(firstIdentificationNo)) {
        CM.cfnAlert("고객명 또는 주민등록번호 앞자리를 입력하세요.");
        return false;
      }

      identificationNo = firstIdentificationNo + lastIdentificationNo;
    } else {
      if (CM.cfnIsEmpty(customer.customerName) && CM.cfnIsEmpty(customer.identificationNo)) {
        CM.cfnAlert("고객명 또는 사업자등록번호를 입력하세요.");
        return false;
      }

      identificationNo = customer.identificationNo;
    }

    const resultData = await axiosSearchCustomer(customer.customerName, identificationNo);

    // 조회된 고객이 있다면 modal open
    if (resultData.length > 0) {
      setSearchCustomers(resultData);
      handleModalOpenChange(true);
    } else {
      CM.cfnAlert("이름 또는 생년월일/사업자번호가 유사한 고객이 존재하지 않습니다.");
    }
  };

  // 고객 조회 aixos
  const axiosSearchCustomer = (customerName, identificationNo) => {
    return new Promise((resolve) => {
      const url = `api/customer-service/customer/basics/duplication?customerName=${customerName}&identificationNo=${identificationNo}`;
      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  };

  /* TODO: 선택한 고객 정보를 화면에 Mapping */
  useEffect(() => {
    // 선택한 고객이 있을때
    if (CM.cfnIsNotEmpty(selectedCustomer) && readOnlyFlag === true) {
      const cloneData = CM.cfnCopyObject(selectedCustomer); // 고객 정보
      setCustomerEmail(CustomerData.getCustomerEmail(cloneData));
      setCustomerDetailInfo(CustomerData.getCustomerDetailInfo(cloneData));
      setSelectRequiredInputs(CustomerData.getCustomerRequiredInputs(cloneData));
      setCustomer(CustomerData.getCustomer(cloneData));
      setSelectedCashbillIdentificationNo(CustomerData.getCashbillIdentificationNo(cloneData));
    }
  }, [selectedCustomer, readOnlyFlag]);

  // 주민등록번호 앞, 뒷자리 초기화
  useEffect(() => {
    if (customer && customer.customerType === "INDIVIDUAL") {
      if (customer.identificationNo) {
        if (customer.identificationNo.length >= 6) {
          setFirstIdentificationNo(customer.identificationNo.substring(0, 6));
          setLastIdentificationNo(customer.identificationNo.substring(6));
        } else {
          setFirstIdentificationNo(customer.identificationNo);
        }
      }
    }
  }, [customer]);

  // 이전 page에서 넘어온 customer가 있을 때
  useEffect(() => {
    if (CM.cfnIsNotEmpty(location.state)) {
      handleSelectedCustomerChange(location.state);
    }
  }, [location.state]);

  // modalOpen handler
  const handleModalOpenChange = (value) => setModalOpen(value);

  // 선택한 고객정보 handler
  const handleSelectedCustomerChange = (objCustomer) => {
    setSelectedCustomer(objCustomer);
    setReadOnlyFlag(true);
  };

  //기한없음
  const handleDeadline = (e) => {
    if (e.target.checked) {
      //기한없음
      setNoneDeadline(true);
    } else {
      //기한없음 해제
      setNoneDeadline(false);
    }
  };

  // FIXME: 화면 렌더
  return (
    <div>
      <div hidden={readOnlyFlag}>
        <CreateCustomerDivision
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          firstIdentificationNo={firstIdentificationNo}
          lastIdentificationNo={lastIdentificationNo}
          handleIdentificationNoChange={handleIdentificationNoChange}
          changeIdentificationNo={changeIdentificationNo}
          checkIdentificationNo={checkIdentificationNo}
          setCheckIdentificationNo={setCheckIdentificationNo}
        />
        <div className="table-bottom-area">
          <button type="button" className="search-button" onClick={openSearchCustomerModal}>
            고객 조회
          </button>
          <TooltipForm contents="이미 등록된 고객에게 새로운 수납·지급 방법을 추가할 경우, 고객명 및 주민등록번호 앞자리를 입력 후 고객조회로 검색하시면 됩니다" />
        </div>
      </div>
      <div hidden={!readOnlyFlag}>
        <ViewCustomerDivision customer={customer} />
      </div>
      <CustomerRequiredInputs
        storeCustomerGroup={toJS(store.customerGroup)}
        optionStaffsAndBranches={optionStaffsAndBranches}
        selectRequiredInputs={selectRequiredInputs}
        handleRequiredInputsHandler={handleRequiredInputsHandler}
        readOnlyFlag={readOnlyFlag}
        setStaffModalOpen={setStaffModalOpen}
        setCustomerGroupModalOpen={setCustomerGroupModalOpen}
      />
      <CustomerIndividualSelectInput
        customer={customer}
        handleCustomerChange={handleCustomerChange}
        changeCustomer={changeCustomer}
        customerEmail={customerEmail}
        handleCustomerEmailChange={handleCustomerEmailChange}
        selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
        handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
        handleRadioChange={handleRadioChange}
        customerDetailInfo={customerDetailInfo}
        handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
        readOnlyFlag={readOnlyFlag}
        checkIdentificationNo={checkIdentificationNo}
        isPaymentInformation={true}
      />
      <CustomerBusinessSelectInput
        customer={customer}
        handleCustomerChange={handleCustomerChange}
        changeCustomer={changeCustomer}
        customerDetailInfo={customerDetailInfo}
        handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
        customerEmail={customerEmail}
        handleCustomerEmailChange={handleCustomerEmailChange}
        selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
        handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
        handleRadioChange={handleRadioChange}
        readOnlyFlag={readOnlyFlag}
        isPaymentInformation={true}
      />
      <div className="table-bottom-area">&nbsp;</div>
      <h4 data-testid="payment-header">지급정보</h4>
      <PaymentCustomerInfo
        paymentInfo={paymentInfo}
        setPaymentInfo={setPaymentInfo}
        handlePaymentInfoChange={handlePaymentInfoChange}
        setCapitalRows={setCapitalRows}
        noneDeadline={noneDeadline}
        handleDeadline={handleDeadline}
        store={store}
        updateYn={updateYn}
        smsToCustomerSetting={smsToCustomerSetting}
      />
      <div className="table-bottom-area">
        <button type="button" className="btn-l" onClick={handleSubmit} data-testid="submit-payment">
          등록
        </button>
        <button type="button" className="btn-m" onClick={() => history.goBack()}>
          등록취소
        </button>
      </div>
      <CustomerSearch modalOpen={modalOpen} handleModalOpenChange={handleModalOpenChange} searchCustomers={searchCustomers} handleSelectedCustomerChange={handleSelectedCustomerChange} />
      <Modal open={staffModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setStaffModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>담당자 정보</h3>
            <StaffInfo />
          </div>
        </div>
      </Modal>
      <Modal open={customerGroupModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setCustomerGroupModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>고객구분 관리</h3>
            <CustMnmtTable />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
}))(observer(CustomerCreate));
