import { Input, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

// ARS요청내역 페이지 컴포넌트
// 렌더를 처리하는 메인 컴포넌트
export default function ArsHistory() {
  const [list, setList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CM.cfnPaginationData());
  const [searchRequest, setSearchRequest] = useState({ searchProperty: "CUSTOMER_NAME", searchContents: "", fromDate: "", toDate: "" }); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(false); // 검색 실행 flag
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  useEffect(() => {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    setFromDate(date);
    setSearchRequest({ ...searchRequest, fromDate: CM.cfnConvertDateToString(date), toDate: CM.cfnConvertDateToString(toDate) });
    setSearchButton(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /*
     * @desc  조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/ars/result?fromDate=${search.fromDate}&toDate=${search.toDate}&searchProperty=${search.searchProperty}&searchContents=${search.searchContents}&pageNumber=${pagination.pageNumber}&pageSize=${pagination.rowsPerPage}`;
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      setSearchButton(false);
      const resultData = await axiosList(search);

      setList(resultData.content);
      setPagination({ ...CM.cfnSetPagination(resultData), pageNumber: resultData.pageable.pageNumber });
    }

    if (searchButton === true) {
      startAxios(searchRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchButton]);

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowsPerPageChange = (e) => {
    const value = e.target.value;
    setPagination({ ...pagination, rowsPerPage: value, pageNumber: 0 });
    setSearchButton(true);
  };

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    setPagination({ ...pagination, pageNumber: page - 1 });
    setSearchButton(true);
  };

  /**
   * @description 요청일자 검색조건 변경
   * @param {*} target
   * @param {*} value
   */
  const changeParamValueHandler = (target, value) => {
    if (!isNaN(value.getTime())) {
      if (target === "fromDate") {
        setFromDate(value);
      } else {
        setToDate(value);
      }
      setSearchRequest({ ...searchRequest, [target]: CM.cfnConvertDateToString(value) });
    }
  };

  /*
   * @desc ARS 출금동의자료 대행요청 현황 상태값에 따른 버튼 처리 및 상태명 변환 함수
   **/
  const fnConvertArsRequestStatus = (ars) => {
    if (CM.cfnIsNotEmpty(ars.createdDatetime) && CM.cfnIsNotEmpty(ars.callRequestResultCode)) {
      //createdDatetime 있음 및 callRequestResultCode null 혹은 empty -> 고객 출금동의 중
      if (ars.callRequestResultCode === "1") {
        //callRequestResultCode 1 -> 오류
        if (ars.callResultDesc === "inputErr") {
          return "인증번호 입력오류";
        } else if (ars.callResultDesc === "dialErr") {
          return "ARS전화 발신 실패";
        } else if (ars.callResultDesc === "noAnswer") {
          return "ARS전화 무응답";
        } else if (ars.callResultDesc === "reject") {
          return "자동납부 동의 거부";
        } else if (ars.callResultDesc === "expired") {
          return "제한기간(24시간) 만료";
        } else if (ars.callResultDesc === "canceled") {
          return "이용기관 취소";
        } else {
          return "오류";
        }
      } else if (ars.callRequestResultCode === "0" && ars.callResultResultYn === "Y") {
        //callRequestResultCode 0 및 callResultResultYn Y -> 출금동의 완료
        return "정상완료";
      } else if (ars.callRequestResultCode === "" || ars.callResultResultYn === null) {
        //callRequestResultCode null이거나 빈값 -> LMS 발송완료 및 고객 대기
        return "LMS 발송완료 및 고객 대기";
      } else {
        //나머지 케이스 -> 오류(출금동의 거부)
        return "오류(자동납부 거부)";
      }
    } else if (CM.cfnIsNotEmpty(ars.createdDatetime)) {
      return "ARS요청 중";
    } else {
      // 이외에는 무슨상황인지 모름..
      return "요청대기";
    }
  };

  return (
    <div>
      <div>
        <div className="inforbox">
          <ul>
            <li>ARS 서비스 이용내역을 조회할 수 있습니다.</li>
          </ul>
        </div>
      </div>
      <div>
        <div className="search-area">
          <div className="block">
            <span className="label-l">요청일자</span>
            <CF.DatePickerForm
              className="w160 receiptRequestResultDatePicker"
              name="fromDate"
              value={fromDate}
              handleChange={(event) => changeParamValueHandler("fromDate", event)}
              format="yyyy.MM.dd"
              customProps={{ placeholder: "YYYY.MM.DD" }}
            />
            <span className="between">~</span>
            <CF.DatePickerForm
              className="w160 receiptRequestResultDatePicker"
              name="toDate"
              value={toDate}
              handleChange={(event) => changeParamValueHandler("toDate", event)}
              format="yyyy.MM.dd"
              customProps={{ placeholder: "YYYY.MM.DD" }}
            />
            <label className="label-l">검색어입력</label>
            <Select
              native
              className="w120"
              name="searchProperty"
              value={searchRequest.searchProperty}
              onChange={(e) => setSearchRequest({ ...searchRequest, searchProperty: e.target.value })}
              inputProps={{
                "data-testid": "search-type-select",
              }}>
              <option value="CUSTOMER_NAME">고객이름</option>
              <option value="PAYER_NO">납부자번호</option>
            </Select>
            <Input
              name="searchKeyword"
              className="w130"
              value={searchRequest.searchContents}
              onChange={(e) => setSearchRequest({ ...searchRequest, searchContents: e.target.value })}
              onKeyUp={(e) => e.keyCode === 13 && setSearchButton(true)}
            />
            <button className="search-button" onClick={() => setSearchButton(true)}>
              조회
            </button>
          </div>
        </div>
        <div className="table-top-area">
          <CF.TotalCountForm totalElements={pagination.total || 0} />
          <CF.RowPerPageForm value={pagination.rowsPerPage} onChange={handleRowsPerPageChange} customProps={{ inputProps: { "data-testid": "arsHistory-select-rowPerPage" } }} />
        </div>
        <Table aria-labelledby="tablebusiness" data-testid="arsHistory-table">
          {CM.cfnCompColGroup(["15%", "10%", "10%", "15%", "10%", "20%", "auto"])}
          <TableForm.compTableHead arrData={["요청일시", "고객명", "납부자번호", "고객 휴대폰번호", "은행명", "계좌번호", "처리상태"]} />
          <TableBody>
            {list.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={7} />
            ) : (
              list.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell align="center">{CM.cfnDateFormat(row.createdDatetime)}</TableCell>
                    <TableCell align="center" className="w150">
                      {row.customerName}
                    </TableCell>
                    <TableCell align="center">{row.payerNo}</TableCell>
                    <TableCell align="center" className="w100">
                      {CM.cfnAddtDashToPhoneNumber(row.customerMobile)}
                    </TableCell>
                    <TableCell align="center" className="w80">
                      {row.financialInstituteName}
                    </TableCell>
                    <TableCell align="center" className="w100">
                      {CM.cfnMasking(row.accountNo)}
                    </TableCell>
                    <TableCell align="center" className="w100 ignoreClick">
                      {fnConvertArsRequestStatus(row)}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <CF.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} testId={"arsHistory-pagination"} />
    </div>
  );
}
