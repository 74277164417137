import { Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as CM from "../../common/Common";
import CustMnmtTable from "../../institute/customerCriteria/CustMnmtTable";
import StaffInfo from "../../institute/staffInfo/StaffInfo";
import CustomerData from "./CustomerData";
import { CreateCustomerDivision, CustomerBusinessSelectInput, CustomerIndividualSelectInput, CustomerPaymentList, CustomerReceiptList, CustomerRequiredInputs } from "./CustomerForm";

/*
 * @desc  렌더를 처리하는 메인 컴포넌트
 */
const CustomerUpdate = ({ store }) => {
  const location = useLocation();
  const history = useHistory();

  const customerUniqueKey = location.state.customer.uniqueKey;

  // Hooks
  const [originalCustomer, setOriginalCustomer] = useState({});
  const [customer, setCustomer] = useState({
    customerGroup: { uniqueKey: "" },
    staff: { branch: { uniqueKey: "" }, uniqueKey: "" },
    customerType: "INDIVIDUAL", // 고객 구분
    customerName: "", // 고객명
    identificationNo: "", // 전체 주민번호
    customerMobile: "", // 휴대전화번호
    customerEmail: "", // 이메일주소
    customerTelephone: "", // 유선전화번호
    customerFax: "", // 팩스번호
    customerDetailInfo: null,
    customerZipCode: "", // 우편번호
    customerAddress: "", // 주소
    customerAddressDetail: "", // 상세 주소
    roadAddressYn: true, // 도로명 주소 여부
    remark: "", // 비고
    cashbillIdentificationNo: "", // 현금영수증 발행정보
    paymentContractList: [],
    receiptContractList: [],
  }); // 고객정보
  const [customerEmail, setCustomerEmail] = useState({
    id: "",
    domain: "",
    selected: "manual",
  }); // 고객 이메일
  const [customerDetailInfo, setCustomerDetailInfo] = useState({
    결혼기념일: null,
    담당자명: "",
    담당자직위: "",
    대표자명: "",
    업태: "",
    업종: "",
  }); // 담당자 정보
  const [selectedCashbillIdentificationNo, setSelectedCashbillIdentificationNo] = useState("NONE"); // 현금영수증 발행정보 radio 값
  const [selectRequiredInputs, setSelectRequiredInputs] = useState({
    customerGroup: "", // 고객구분
    staff: "", // 고객관리담당자
  });

  //modals
  const [customerGroupModalOpen, setCustomerGroupModalOpen] = useState(false);
  const [staffModalOpen, setStaffModalOpen] = useState(false);

  useEffect(() => {
    const url = `api/customer/basics/${customerUniqueKey}`;
    CM.cfnAxios(url, "get", "", (objStatus, getCustomer) => {
      let customer = CustomerData.getCustomer(getCustomer);
      setOriginalCustomer(getCustomer);
      setCustomer(customer);
      setCustomerEmail(CustomerData.getCustomerEmail(getCustomer));
      setCustomerDetailInfo(CustomerData.getCustomerDetailInfo(getCustomer));
      setSelectRequiredInputs(CustomerData.getCustomerRequiredInputs(getCustomer));
      setSelectedCashbillIdentificationNo(CustomerData.getCashbillIdentificationNo(getCustomer));
      changeIdentificationNo(customer.identificationNo && customer.identificationNo.length === 13 ? customer.identificationNo.substring(0, 6) : customer.identificationNo, "first");
      changeIdentificationNo(customer.identificationNo && customer.identificationNo.length === 13 ? customer.identificationNo.substring(6) : "", "last");
    });
  }, [customerUniqueKey]);

  const [firstIdentificationNo, setFirstIdentificationNo] = useState("");
  const [lastIdentificationNo, setLastIdentificationNo] = useState("");
  const [checkIdentificationNo, setCheckIdentificationNo] = useState(
      location.state && location.state.customer && location.state.customer.identificationNo && location.state.customer.identificationNo.length > 6
  ); // 주민등록번호앞자리 체크 hooks

  // 주민등록번호 앞자리, 뒷자리 handler
  const handleIdentificationNoChange = (e, type) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    changeIdentificationNo(value, type);
  };

  // 주민등록번호 앞자리, 뒷자리 입력시 해당 값 변경
  const changeIdentificationNo = (value, type) => {
    if (type === "first") {
      setFirstIdentificationNo(value);
    } else {
      setLastIdentificationNo(value);
    }
  };

  // 주민등록번호 앞, 뒷자리 초기화
  useEffect(() => {
    if (customer && customer.customerType === "INDIVIDUAL") {
      if (customer.identificationNo) {
        if (customer.identificationNo.length >= 6) {
          setFirstIdentificationNo(customer.identificationNo.substring(0, 6));
          setLastIdentificationNo(customer.identificationNo.substring(6));
        } else {
          setFirstIdentificationNo(customer.identificationNo);
        }
      }
    }
  }, [customer]);

  // 담당자 정보 handler
  const handleCustomerDetailInfoChange = (name, e) => {
    const value = name !== "결혼기념일" ? e.target.value : e;
    setCustomerDetailInfo((data) => ({ ...data, [name]: value }));
  };

  // 이메일 주소 handler
  const handleCustomerEmailChange = (e, name) => {
    const value = e.target.value;
    setCustomerEmail((email) => ({
      ...email,
      [name]: value,
    }));
  };

  // 이메일 주소 selectbox handler
  const handleSelectboxCustomerEmailChange = (e) => {
    const value = e.target.value;
    const domain = value !== "manual" ? value : "";

    setCustomerEmail((email) => ({
      ...email,
      domain: domain,
      selected: value,
    }));
  };

  // 선택입력사항 handler(이메일, 현금영수증 발행정보 제외)
  const handleCustomerChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    // 현금영수증 발행정보의 주민등록번호를 입력할 때
    if (name === "cashbillIdentificationNo" && selectedCashbillIdentificationNo === "IDENTIFICATION_NO") {
      value = value.replace(/[^0-9]/g, "");
    }

    changeCustomer(name, value);
  };

  // 현금영수증 발행정보 radio handler
  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedCashbillIdentificationNo(value);

    // 현금영수증 발행정보 input 초기화
    changeCustomer("cashbillIdentificationNo", "");
  };

  // 선택입력사항 입력시 해당 값 변경
  const changeCustomer = (name, value) => {
    setCustomer((data) => ({ ...data, [name]: value }));
  };

  // FIXME: 개인 && 사업 > 고객정보 > 필수 입력사항 handler
  const [optionStaffsAndBranches, setOptionStaffsAndBranches] = useState([]);
  const [optionCustomerGroup, setOptionCustomerGroup] = useState([]);

  const [instituteInfoState, setInstituteInfoState] = useState([]);

  // 필수 입력 사항 목록 가져오기
  useEffect(() => {
    const startStoreAxios = async () => {
      const resultCustomerGroup = await store.axiosCustomerGroup(); // 고객구분
      setOptionCustomerGroup(resultCustomerGroup);

      const resultStaffs = await store.axiosStaffs();
      const branchesStaffs = resultStaffs.map((staff) => ({ uniqueKey: staff.uniqueKey, name: `${staff.staffName}/${staff.branch.branchName}` }));
      setOptionStaffsAndBranches(branchesStaffs);

      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값
      setInstituteInfoState(businessInfo.cmsService);
    };

    startStoreAxios();
  }, [store]);

  // 선택한 고객구분, 고객담당지사, 고객관리담당자 값을 변경하는 handler
  const handleRequiredInputsHandler = (e, name) => {
    const value = e.target.value;
    setSelectRequiredInputs((data) => ({ ...data, [name]: value }));
  };

  // FIXME: 등록 및 등록취소
  // 고객 등록시 필수 값 체크
  const checkRequired = () => {
    if (CM.cfnIsEmpty(selectRequiredInputs.customerGroup)) {
      CM.cfnAlert("고객구분을 선택하세요.");
      return false;
    }

    if (CM.cfnIsEmpty(selectRequiredInputs.staff)) {
      CM.cfnAlert("고객관리담당자/고객담당지사를 선택하세요.");
      return false;
    }

    return true;
  };

  // 수정버튼 클릭시 고객 수정 처리
  const handleModifyClick = () => {
    // 필수값 체크
    if (!checkRequired()) {
      return false;
    }

    const url = "api/customer/basics";

    const sendData = CM.cfnCopyObject(customer);
    if (customer.customerType === "INDIVIDUAL") {
      sendData.identificationNo = firstIdentificationNo + lastIdentificationNo;
      sendData.identificationNoFirst7 = firstIdentificationNo;
    }

    const copyCustomerDetailInfo = {}; // 고객추가정보

    // 고객추가정보 값 확인
    for (const [key, value] of Object.entries(customerDetailInfo)) {
      if (CM.cfnIsNotEmpty(value)) {
        const applyValue = key === "결혼기념일" ? CM.cfnConvertDateToString(value) : value;
        copyCustomerDetailInfo[key] = applyValue;
      }
    }

    // 고객추가정보 값 설정
    sendData.customerGroup.uniqueKey = selectRequiredInputs.customerGroup;
    sendData.staff.uniqueKey = selectRequiredInputs.staff;
    sendData.customerDetailInfo = JSON.stringify(copyCustomerDetailInfo);
    sendData.customerMobile = CM.cfnReplaceSymbol(sendData.customerMobile);
    sendData.customerTelephone = CM.cfnReplaceSymbol(sendData.customerTelephone);
    sendData.customerFax = CM.cfnReplaceSymbol(sendData.customerFax);
    sendData.identificationNo = CM.cfnReplaceSymbol(sendData.identificationNo);

    // 현금영수증 발행정보의 마스킹 기호 제거
    sendData.cashbillIdentificationNo = CM.cfnReplaceSymbol(sendData.cashbillIdentificationNo);

    let email = null;
    // 이메일이 빈값이 아니라면
    if (CM.cfnIsNotEmpty(customerEmail.id) || CM.cfnIsNotEmpty(customerEmail.domain)) {
      email = customerEmail.id + "@" + customerEmail.domain;
      // 이메일 형식 체크
      if (!CM.cfnCheckEmail(email)) {
        CM.cfnAlert("올바른 이메일 형식이 아닙니다.");
        return false;
      }
    }

    sendData.customerEmail = email;

    // 고객기본정보 생성 api 호출
    CM.cfnAxios(url, "put", sendData, (objStatus, data) => {
      CM.cfnAlert("정상적으로 처리되었습니다.", () => {
        goCustomerListPage();
      });
    });
  };

  // 삭제 버튼을 눌렀을 때 데이터 삭제
  const handleDeleteClick = () => {
    CM.cfnConfirm("고객을 삭제하시겠습니까? 해당 고객의 수납/지급정보도 함께 삭제됩니다. 삭제 후에도 기존 수납정보의 납부자번호는 재사용이 불가합니다.", () => {
      const customerUniqueKey = customer.uniqueKey;
      const url = `api/customer/basics/${customerUniqueKey}`;

      CM.cfnAxios(url, "delete", "", (status, data) => {
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {
          goCustomerListPage();
        });
      });
    });
  };

  // 수납정보 또는 지급정보 TableRow 클릭시 상세 페이지로 이동
  const handleRowClick = (e, type, contract) => {
    e.preventDefault();

    let url = "";
    let state = {};

    // 지급일 때
    if (type === "payment") {
      url = "/customer/paymentUpdate";
      state = { payment: contract };
    } else {
      url = "/customer/receiptCustomerUpdate";
      state = { receipt: contract };
    }

    history.push(url, state);
  };

  // 목록 버튼을 눌럿을때 이동
  const goCustomerListPage = () => {
    const searchRequest = location.state.searchRequest;
    history.push("/customer/customerInformationList", searchRequest);
  };

  const goReceiptCreatePage = () => {
    history.push("/customer/receiptCustomerCreate", { prevPageCustomerUpdate: originalCustomer });
  };

  // 지급 정보 페이지로 이동
  const goPaymentCreatePage = () => {
    history.push("/customer/paymentCreate", originalCustomer);
  };

  return (
    <div>
      <div>
        <CreateCustomerDivision
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          firstIdentificationNo={firstIdentificationNo}
          lastIdentificationNo={lastIdentificationNo}
          handleIdentificationNoChange={handleIdentificationNoChange}
          changeIdentificationNo={changeIdentificationNo}
          checkIdentificationNo={checkIdentificationNo}
          setCheckIdentificationNo={setCheckIdentificationNo}
        />
        <CustomerRequiredInputs
          storeCustomerGroup={optionCustomerGroup}
          optionStaffsAndBranches={optionStaffsAndBranches}
          selectRequiredInputs={selectRequiredInputs}
          handleRequiredInputsHandler={handleRequiredInputsHandler}
          setCustomerGroupModalOpen={setCustomerGroupModalOpen}
          setStaffModalOpen={setStaffModalOpen}
        />
        <CustomerIndividualSelectInput
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          customerEmail={customerEmail}
          handleCustomerEmailChange={handleCustomerEmailChange}
          selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
          handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
          handleRadioChange={handleRadioChange}
          customerDetailInfo={customerDetailInfo}
          handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
          checkIdentificationNo={checkIdentificationNo}
        />
        <CustomerBusinessSelectInput
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          customerDetailInfo={customerDetailInfo}
          handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
          customerEmail={customerEmail}
          handleCustomerEmailChange={handleCustomerEmailChange}
          selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
          handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
          handleRadioChange={handleRadioChange}
        />
      </div>
      <div className="table-bottom-area">
        <button type="submit" className="btn-l" onClick={handleModifyClick} data-testid="customer-update-button-update">
          수정
        </button>
        <button type="submit" className="btn-m" onClick={handleDeleteClick} data-testid="customer-update-button-delete">
          삭제
        </button>
        <button type="button" className="btn-m" onClick={goCustomerListPage} data-testid="customer-update-button-prevList">
          목록
        </button>
      </div>
      <div className="h50" />
      <div hidden={instituteInfoState.indexOf("EC21") === -1 && instituteInfoState.indexOf("EB21") === -1 ? true : false}>
        <h3>수납정보</h3>
        <CustomerReceiptList receiptContractList={customer.receiptContractList} handleRowClick={handleRowClick} />
        <div className="table-bottom-area">
          <button className="btn-m" onClick={goReceiptCreatePage} data-testid="customer-update-button-goReceiptCreatePage">
            수납정보추가
          </button>
        </div>
      </div>
      <div className="h50" />
      <div hidden={instituteInfoState.indexOf("EB31") === -1 ? true : false}>
        <h3>지급정보</h3>
        <CustomerPaymentList paymentContractList={customer.paymentContractList} handleRowClick={handleRowClick} />
        <div className="table-bottom-area">
          <button className="btn-m" onClick={goPaymentCreatePage} data-testid="customer-update-button-goPaymentCreatePage">
            지급정보추가
          </button>
        </div>
      </div>
      <Modal open={staffModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setStaffModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>담당자 정보</h3>
            <StaffInfo />
          </div>
        </div>
      </Modal>
      <Modal open={customerGroupModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setCustomerGroupModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>고객구분 관리</h3>
            <CustMnmtTable />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(CustomerUpdate));
