import { Checkbox, FormControlLabel } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import * as CmsData from "./CmsReceiptData";
import * as Form from "./CmsReceiptRequestForm";
import TargetAddModal from "./CmsReceiptRequestTargetAddModal";
import TargetModifyModal from "./CmsReceiptRequestTargetModifyModal";

/*
 * @desc  메인 컴포넌트 생성
 */
const CmsReceiptRequest = ({ tabIndex, store, businessInfo }) => {
  //필요 state 선언
  const [cmsRequestStatus, setCmsRequestStatus] = useState(0); //출금청구 진행단계 {0:화면 진입시, 1:기존청구조회, 2:청구생성전, 3:청구생성후}
  const [financialInstitutes, setFinancialInstitutes] = useState(toJS(store.financialInstitutes));

  //출금청구 대상 생성 제어
  const [cmsRequestSummary, setCmsRequestSummary] = useState(CmsData.cmsDefaultSummary); //출금청구대상 생성 정보
  const [targetCheckbox, setTargetCheckbox] = useState(CmsData.defaultCheckbox); //출금청구대상 체크박스

  //목록 조회 제어
  const [searchButton, setSearchButton] = useState(true); //검색 실행 flag
  const [isTargetChanged, setIsTargetChanged] = useState(false); //출금대상 추가/수정 여부
  const [searchRequest, setSearchRequest] = useState(CmsData.requestSearchData); // table 데이터 검색 조건
  const [originalList, setOriginalList] = useState([]);
  const [cmsRequestList, setCmsRequestList] = useState([]); //table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());
  const [capitalOptions, setCapitalOptions] = useState([]); //검색 조건 내 셀렉트 옵션 제어
  const [customerGroupOptions, setCustomerGroupOptions] = useState([]); //고객구분 옵션 제어
  const [generatingRequestTarget, setGeneratingRequestTarget] = useState(false);
  const [saveCmsWithdrawSettings, setSaveCmsWithdrawSettings] = useState(true);
  const [targetAskUniqueKey, setTargetAskUniqueKey] = useState(null);

  //모달 제어
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);

  const excelInput = React.useRef("");

  /*
   * @desc  출금대상생성 버튼 클릭 이벤트 핸들러
   */
  const handleClickRequestGenerations = () => {
    if (CM.cfnIsEmpty(cmsRequestSummary.askTargetCriteria)) {
      CM.cfnAlert("출금청구 대상을 선택하여 주시기 바랍니다.");
      return false;
    } else {
      if (!generatingRequestTarget) {
        setGeneratingRequestTarget(true);
      }
    }
  };

  useEffect(() => {
    if (generatingRequestTarget) {
      fnRequestGenerations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatingRequestTarget]);
  /*
   * @desc  출금청구 버튼 클릭 이벤트 핸들러
   */
  const handleClickRequest = () => {
    CM.cfnConfirm("출금 청구하시겠습니까?\n(출금청구일 : "+ CM.cfnDateFormat(CM.cfnConvertDateToString(cmsRequestSummary.transactionDate))+" / 출금건수 : " + originalList.totalElements+"건)", fnRequestCmsTarget);
  };

  /*
   * @desc  출금대상삭제 버튼 클릭 이벤트 핸들러
   */
  const handleClickRequestDelete = async () => {
    CM.cfnConfirm("정말로 삭제하시겠습니까?", fnDeleteCmsRequest);
  };

  /*
   * @desc  출금대상 추가 버튼 클릭 이벤트 핸들러
   */
  const handleTargetAddModal = () => {
    setAddModalOpen(true);
  };

  /*
   * @desc  출금대상 내용 수정 버튼 클릭 이벤트 핸들러
   */
  const handleModifyTarget = () => {
    setModifyModalOpen(true);
  };

  /*
   * @desc  일괄수정(엑셀) 버튼 클릭 이벤트 핸들러
   */
  const handleModifyTargetBulk = () => {
    excelInput.current.click();
  };

  /*
   * @desc  출금청구대상 일괄수정 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc  출금청구대상 일괄수정 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/receipt/cms/contents/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey ",
      new Blob([JSON.stringify(cmsRequestSummary.uniqueKey)], {
        type: "application/json",
      })
    );

    CM.cfnAxios(url, "post", form, fnUploadExcelCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 웹페이지 새로고침 또는 재접속을 통해 최종 반영 결과를 확인하신 후 청구하시기 바랍니다.",
        () => {}
      );
    });
  };

  /*
   * @desc  출금청구대상 일괄수정 Request Callback
   */
  const fnUploadExcelCallback = (objStatus, objData) => {
    handleSearchButtonChange(true);
    setIsTargetChanged(true);
    CM.cfnAlert(objData);
  };

  /*
   * @desc  엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleExcelDownload = () => {
    const search = fnMakeParameter("get");
    const url = `api/receipt/cms/contents/excel?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payerNo=${search.payerNo}&payAmountType=${search.payAmountType}&resultCode=${search.resultCode}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc  테이블 내 제외 버튼 클릭 이벤트 핸들러
   */
  const handleExcludeRow = (row) => {
    CM.cfnConfirm("선택한 대상을 제외하시겠습니까?", () => {
      fnExcludeRow(row);
    });
  };

  /**
   * 행 클릭 이벤트
   * @param {askWithdraw} row
   */
  const handleClickedRow = (row) => {
    setTargetAskUniqueKey(row.uniqueKey);
    setModifyModalOpen(true);
  };

  /*
   * @desc CMS출금청구대상에저 제외 처리 Request
   */
  const fnExcludeRow = (row) => {
    row.handlingExclusion = true;
    /*
     * @desc CMS출금청구대상에저 제외 처리 Request Callback
     */
    const fnExcludeRowCallback = (objStatus, objData) => {
      if (objStatus.status !== 200) {
        row.handlingExclusion = false;
      } else {
        CM.cfnAlert(objData);
        handleSearchButtonChange(true);
        setIsTargetChanged(true);
      }
    };
    const url = `api/receipt/cms/contents/${cmsRequestSummary.uniqueKey}/${row.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnExcludeRowCallback);
  };

  /*
   * @desc    CMS출금청구대상 요약정보 조회
   */
  const fnFetchCmsSummary = useCallback(() => {
    return new Promise((resolve) => {
      const url = "api/receipt/cms/";

      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  }, []);

  /*
   * @desc CMS출금청구대상 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(
    (url) => {
      let tempObj = null;

      if (url === "get") {
        //출금청구대상 목록 조회 청구 시
        tempObj = CM.cfnCopyObject(searchRequest);
        if (tempObj.term === "customerName") {
          tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
          tempObj.payerNo = "";
        } else if (tempObj.term === "payerNo") {
          tempObj.payerNo = CM.cfnNvl(tempObj.keyword, "");
          tempObj.customerName = "";
        }
      } else if (url === "post") {
        //출금청구대상 생성 시
        tempObj = CM.cfnCopyObject(cmsRequestSummary);
        tempObj.transactionDate = CM.cfnConvertDateToString(cmsRequestSummary.transactionDate);
        tempObj.fileType = tempObj.fileType === true ? "EB21" : "EC21";
        tempObj.isPreviouslyUnaskedIncluded = targetCheckbox.unasked;
        tempObj.isThisTimeIncluded = targetCheckbox.thisTime;
        tempObj.isUnpaidIncluded = targetCheckbox.unpaid;
      }

      return tempObj;
    },
    [searchRequest, cmsRequestSummary, targetCheckbox.unasked, targetCheckbox.thisTime, targetCheckbox.unpaid]
  );

  /*
   * @desc    CMS출금청구대상 목록 조회 Request
   */
  const fnFetchCmsContents = useCallback(() => {
    const search = fnMakeParameter("get");
    return new Promise((resolve) => {
      let url = `api/receipt/cms/contents?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&resultCode=&groupUniqueKey=${search.groupUniqueKey}&customerName=${search.customerName}&payerNo=${search.payerNo}&payAmountType=${search.payAmountType}&capitalUniqueKey=${search.capitalUniqueKey}&fileUniqueKey=${search.fileUniqueKey}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  }, [fnMakeParameter]);

  /*
   * @desc    CMS출금청구대상 목록 조회 Request 호출 및 Callback
   */
  const startCmsContentsAxios = useCallback(
    async (search) => {
      const resultData = await fnFetchCmsContents();
      const resultList = resultData.content;

      if (CM.cfnIsNotEmpty(resultData)) {
        //조회 결과 response 데이터가 있을 시
        setOriginalList(resultData);
        setCmsRequestList(resultList);
        setPagination(CmsData.paginationData(resultData));
      }
    },
    [fnFetchCmsContents]
  );

  useEffect(() => {
    //체크박스 변경 시 값 가공
    setCmsRequestSummary((summary) => ({
      ...summary,
      askTargetCriteria: CmsData.convertToTargetCriteria(targetCheckbox),
    }));
  }, [targetCheckbox]);

  /*
   * @desc  출금대상생성 컴포넌트 내 변경 이벤트 핸들러
   */
  const handleCmsRequestInfoChange = (name) => (e) => {
    if (name === "transactionDate") {
      setCmsRequestSummary({
        ...cmsRequestSummary,
        [name]: e,
      });
    } else if (e.target.type === "radio") {
      setCmsRequestSummary({
        ...cmsRequestSummary,
        [name]: e.target.value === "true",
      });
    }
  };

  /*
   * @desc  출금대상생성 컴포넌트 내 체크박스 변경 이벤트 핸들러
   */
  const handleTargetCheckbox = (name) => (e) => {
    setTargetCheckbox({
      ...targetCheckbox,
      [name]: e.target.checked,
    });
  };

  /*
   * @desc  출금대상 목록 필터 입력란 변경 이벤트 핸들러
   */
  const handleRequestSearchChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  /*
   * @desc  출금대상 생성 Request
   */
  const fnRequestGenerations = () => {
    const searchParam = fnMakeParameter("post");
    // 로컬 스토리지에 변경내용 저장
    if (saveCmsWithdrawSettings) {
      localStorage.setItem("CMS_WITHDRAW_ASK_TARGET_CRITERIA", CmsData.convertToTargetCriteria(targetCheckbox));
      localStorage.setItem("CMS_WITHDRAW_FILE_TYPE", searchParam.fileType);
      localStorage.setItem("CMS_WITHDRAW_TARGET_INTEGRATED_YN", searchParam.targetIntegratedYn);
    }
    const url = `api/receipt/cms/?fileType=${searchParam.fileType}&isPreviouslyUnaskedIncluded=${searchParam.isPreviouslyUnaskedIncluded}&isThisTimeIncluded=${searchParam.isThisTimeIncluded}&isUnpaidIncluded=${searchParam.isUnpaidIncluded}&targetIntegratedYn=${searchParam.targetIntegratedYn}&withdrawRequestDate=${searchParam.transactionDate}`;
    CM.cfnAxios(url, "post", "", fnRequestGenerationsCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
        () => {}
      );
      setSearchButton(false);
    });
    setGeneratingRequestTarget(false);
  };

  /*
   * @desc  출금대상 생성 Request Callback
   */
  const fnRequestGenerationsCallback = async (objStatus, objData) => {
    setSearchRequest({
      ...searchRequest,
      fileUniqueKey: objData,
    });

    //정상 리턴 시 요약정보 재조회 및 출금청구대상 목록 조회하도록 state setting
    const summary = await fnFetchCmsSummary();
    setCmsRequestSummary((element) => ({
      ...element,
      ...summary,
      fileType: summary.fileType === "EB21",
    }));
    handleSearchButtonChange(true);
    setCmsRequestStatus(3);
  };

  /*
   * @desc  출금청구 수행 Request
   */
  const fnRequestCmsTarget = () => {
    const url = `api/receipt/cms/${cmsRequestSummary.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnRequestCmsTargetCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
        () => {
          setSearchButton(true);
        }
      );
    });
  };

  /*
   * @desc  출금청구 수행 Request Callback
   */
  const fnRequestCmsTargetCallback = (objStatus, objData) => {
    CM.cfnAlert(objData, async () => {
      //기존 청구정보 조회하지 않도록 & 초기 정보 보여주도록 setting
      let checkboxObj = CmsData.defaultCheckbox;
      let savedAskTargetCriteria = localStorage.getItem("CMS_WITHDRAW_ASK_TARGET_CRITERIA");
      if (savedAskTargetCriteria) {
        if (savedAskTargetCriteria.indexOf("THIS_TIME") >= 0) {
          checkboxObj.thisTime = true;
        }
        if (savedAskTargetCriteria.indexOf("UNASKED") >= 0) {
          checkboxObj.unasked = true;
        }
        if (businessInfo && businessInfo.reaskUnpaidLimit > 0 && savedAskTargetCriteria.indexOf("UNPAID") >= 0) {
          checkboxObj.unpaid = true;
        }
      }
      const tempObj = CmsData.cmsDefaultSummary;
      const savedFileType = localStorage.getItem("CMS_WITHDRAW_FILE_TYPE");
      if (businessInfo) {
        tempObj.askTargetCriteria = CmsData.convertToTargetCriteria(checkboxObj);
        tempObj.reaskUnpaidLimit = businessInfo.reaskUnpaidLimit > 0;
        if (businessInfo.cmsService) {
          const isEB21 = businessInfo.cmsService.indexOf("EB21") !== -1;
          const isEC21 = businessInfo.cmsService.indexOf("EC21") !== -1;

          tempObj.fileType =
            isEB21 && (savedFileType === null || savedFileType === "" || savedFileType === "EB21")
              ? true
              : isEC21 && (savedFileType === null || savedFileType === "" || savedFileType === "EC21")
              ? false
              : "";
        }
      }
      const savedTargetIntegratedYn = localStorage.getItem("CMS_WITHDRAW_TARGET_INTEGRATED_YN");
      if (savedTargetIntegratedYn === "false" || savedTargetIntegratedYn === false) {
        tempObj.targetIntegratedYn = false;
      }

      tempObj.transactionDate = await CmsData.fnGetNextCmsReceiptDate(cmsRequestSummary.fileType);

      setTargetCheckbox(checkboxObj);

      setCmsRequestSummary(tempObj);
      setCmsRequestStatus(2);
    });
  };

  /*
   * @desc  출금청구 삭제 Request
   */
  const fnDeleteCmsRequest = () => {
    const url = `api/receipt/cms/${cmsRequestSummary.uniqueKey}`;
    CM.cfnAxios(
      url,
      "delete",
      "",
      async (objStatus, objData) => {
        CM.cfnAlert(objData, async () => {
          let checkboxObj = CmsData.defaultCheckbox;
          let savedAskTargetCriteria = localStorage.getItem("CMS_WITHDRAW_ASK_TARGET_CRITERIA");
          if (savedAskTargetCriteria) {
            if (savedAskTargetCriteria.indexOf("THIS_TIME") >= 0) {
              checkboxObj.thisTime = true;
            }
            if (savedAskTargetCriteria.indexOf("UNASKED") >= 0) {
              checkboxObj.unasked = true;
            }
            if (businessInfo && businessInfo.reaskUnpaidLimit > 0 && savedAskTargetCriteria.indexOf("UNPAID") >= 0) {
              checkboxObj.unpaid = true;
            }
          }
          const savedFileType = localStorage.getItem("CMS_WITHDRAW_FILE_TYPE");
          const tempObj = CmsData.cmsDefaultSummary;
          if (businessInfo) {
            tempObj.askTargetCriteria = CmsData.convertToTargetCriteria(checkboxObj);
            tempObj.reaskUnpaidLimit = businessInfo.reaskUnpaidLimit > 0;
            if (businessInfo.cmsService) {
              const isEB21 = businessInfo.cmsService.indexOf("EB21") !== -1;
              const isEC21 = businessInfo.cmsService.indexOf("EC21") !== -1;

              tempObj.fileType =
                isEB21 && (savedFileType === null || savedFileType === "" || savedFileType === "EB21")
                  ? true
                  : isEC21 && (savedFileType === null || savedFileType === "" || savedFileType === "EC21")
                  ? false
                  : "";
            }
            tempObj.maxLimitAmountForMonthlyWithdrawal = businessInfo.cmsBusiness.maxLimitAmountForMonthlyWithdrawal;
            tempObj.limitAmountForMonthlyWithdrawal = businessInfo.cmsBusiness.limitAmountForMonthlyWithdrawal;
            tempObj.limitAmountForEachWithdrawal = businessInfo.cmsBusiness.limitAmountForEachWithdrawal;
          }
          const savedTargetIntegratedYn = localStorage.getItem("CMS_WITHDRAW_TARGET_INTEGRATED_YN");
          if (savedTargetIntegratedYn === "false" || savedTargetIntegratedYn === false) {
            tempObj.targetIntegratedYn = false;
          }
          tempObj.transactionDate = await CmsData.fnGetNextCmsReceiptDate(cmsRequestSummary.fileType);

          setTargetCheckbox(checkboxObj);

          setCmsRequestSummary(tempObj);
          setOriginalList([]);
          setCmsRequestList([]);

          setCmsRequestStatus(2);
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "현재 요청량이 많아 처리가 지연되고 있습니다. 처리가 계속 진행중이니 잠시 후에 다시 확인해주시기 바랍니다.",
          () => {
            setSearchButton(true);
          }
        );
      }
    );
  };

  //화면 진입 시 기관업무 정보 데이터 세팅
  useEffect(() => {
    if (tabIndex === 0 && cmsRequestStatus === 0 && businessInfo.cmsService) {
      const isEB21 = businessInfo.cmsService.indexOf("EB21") !== -1;
      const isEC21 = businessInfo.cmsService.indexOf("EC21") !== -1;

      const savedFileType = localStorage.getItem("CMS_WITHDRAW_FILE_TYPE");
      const fileType =
        isEB21 && (savedFileType === null || savedFileType === "" || savedFileType === "EB21")
          ? true
          : isEC21 && (savedFileType === null || savedFileType === "" || savedFileType === "EC21")
          ? false
          : "";
      const activeUnpaid = businessInfo.reaskUnpaidLimit > 0;

      const checkboxObj = { ...targetCheckbox };
      let savedAskTargetCriteria = localStorage.getItem("CMS_WITHDRAW_ASK_TARGET_CRITERIA");
      if (savedAskTargetCriteria) {
        if (savedAskTargetCriteria.indexOf("THIS_TIME") >= 0) {
          checkboxObj.thisTime = true;
        }
        if (savedAskTargetCriteria.indexOf("UNASKED") >= 0) {
          checkboxObj.unasked = true;
        }
        if (activeUnpaid && savedAskTargetCriteria.indexOf("UNPAID") >= 0) {
          checkboxObj.unpaid = true;
        }
      }
      const savedTargetIntegratedYn = localStorage.getItem("CMS_WITHDRAW_TARGET_INTEGRATED_YN");

      setCmsRequestStatus(1);

      setTargetCheckbox(checkboxObj);

      setCmsRequestSummary((element) => ({
        ...element,
        targetIntegratedYn: savedTargetIntegratedYn !== "false" && savedTargetIntegratedYn !== false,
        fileType: fileType,
        maxLimitAmountForMonthlyWithdrawal: businessInfo.cmsBusiness.maxLimitAmountForMonthlyWithdrawal,
        limitAmountForMonthlyWithdrawal: businessInfo.cmsBusiness.limitAmountForMonthlyWithdrawal,
        limitAmountForEachWithdrawal: businessInfo.cmsBusiness.limitAmountForEachWithdrawal,
        askTargetCriteria: CmsData.convertToTargetCriteria(checkboxObj),
        reaskUnpaidLimit: activeUnpaid, //출금청구대상 - 미납금 체크박스 disable 여부(reaskUnpaidLimit > 0 이면 활성화)
      }));
    }
  }, [tabIndex, cmsRequestStatus, store, targetCheckbox, businessInfo]);

  //화면 진입 시 현재 작업중인 출금청구대상 (요약)정보 조회
  useEffect(() => {
    /*
     * @desc  CMS출금청구대상 요약정보 Request Callback
     */
    const fnFetchCmsSummaryCallback = async (summary) => {
      if (summary && CM.cfnIsNotEmpty(summary.uniqueKey)) {
        //작업중인 정보가 있을 시 해당 정보를 세팅 후 목록 조회
        setCmsRequestStatus(3);
        setSearchRequest((element) => ({
          ...element,
          fileUniqueKey: summary.uniqueKey,
        }));
        setTargetCheckbox(CmsData.convertFromCmsAskTargetCriteria(summary.askTargetCriteria));
        setCmsRequestSummary((element) => ({
          ...element,
          ...summary,
          fileType: summary.fileType === "EB21",
        }));
      } else {
        //작업중인 정보 없을 시
        const nextBizDate = await CmsData.fnGetNextCmsReceiptDate(cmsRequestSummary.fileType); //다음 영업일
        setCmsRequestSummary((element) => ({
          ...element,
          transactionDate: CM.cfnConvertStringToDate(nextBizDate),
        }));

        setCmsRequestStatus(2);
      }
    };

    /*
     * @desc  CMS출금청구대상 요약정보 조회 Request 호출 후 callback에 파라미터 전달
     */
    const fnInitCmsRequest = async () => {
      const summary = await fnFetchCmsSummary();
      fnFetchCmsSummaryCallback(summary);
    };

    //실행영역
    if (tabIndex === 0 && cmsRequestStatus === 1) fnInitCmsRequest();
  }, [tabIndex, cmsRequestStatus, cmsRequestSummary.fileType, fnFetchCmsSummary]);

  //화면 진입 시 금융기관 목록 조회
  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await store.axiosFinancialInstitutes();
      setFinancialInstitutes(institutesList);
    };

    if (CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();
  }, [financialInstitutes, store]);

  //화면 진입 시 자금 목록 조회
  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      const capitals = await store.axiosCapitals();
      setCapitalOptions(CmsData.fnMakeSelectOptions("capital", capitals));
    };

    //실행영역
    const storeCapital = CM.cfnCopyObject(toJS(store.capital));
    if (CM.cfnIsEmpty(storeCapital)) {
      fnFetchCapitalList();
    } else {
      setCapitalOptions(CmsData.fnMakeSelectOptions("capital", storeCapital));
    }
  }, [store]);

  //화면 진입 시 고객구분 목록 조회
  useEffect(() => {
    /*
     * @desc    고객구분 조회 Request
     */
    const fnFetchCustomerGroup = async () => {
      const groups = await store.axiosCustomerGroup();
      setCustomerGroupOptions(CmsData.fnMakeSelectOptions("group", groups));
    };

    //실행영역
    const storeGroup = CM.cfnCopyObject(toJS(store.customerGroup));
    if (CM.cfnIsEmpty(storeGroup)) {
      fnFetchCustomerGroup();
    } else {
      setCustomerGroupOptions(CmsData.fnMakeSelectOptions("group", storeGroup));
    }
  }, [store]);

  //출금청구대상 목록 조회
  useEffect(() => {
    if (tabIndex === 0 && cmsRequestStatus === 3 && searchButton === true && CM.cfnIsNotEmpty(searchRequest.fileUniqueKey)) {
      handleSearchButtonChange(false); //목록 조회 중복 실행 방지 위해 바로 flag 변경
      startCmsContentsAxios();
    }
  }, [tabIndex, cmsRequestStatus, searchButton, startCmsContentsAxios, searchRequest.fileUniqueKey]);

  //출금대상 추가 or 출금대상 수정 시 목록 재조회
  useEffect(() => {
    const fnSetCmsSummary = async () => {
      const summary = await fnFetchCmsSummary();
      setCmsRequestSummary((element) => ({
        ...element,
        ...summary,
        fileType: summary.fileType === "EB21",
      }));
    };

    if (isTargetChanged) {
      setIsTargetChanged(false);
      fnSetCmsSummary();
      handleSearchButtonChange(true);
    }
  }, [isTargetChanged, fnFetchCmsSummary]);

  return (
    <div>
      <Form.CmsReceiptRequestInfo cmsRequestStatus={cmsRequestStatus} />
      <Form.CmsReceiptGeneration
        businessInfo={businessInfo}
        cmsRequestStatus={cmsRequestStatus}
        cmsRequestSummary={cmsRequestSummary}
        handleCmsRequestInfoChange={handleCmsRequestInfoChange}
        targetCheckbox={targetCheckbox}
        handleTargetCheckbox={handleTargetCheckbox}
      />
      <div className="table-bottom-area">
        <FormControlLabel
          className={cmsRequestStatus !== 2 ? "hidden" : ""}
          control={<Checkbox checked={saveCmsWithdrawSettings} onChange={(e) => setSaveCmsWithdrawSettings(e.target.checked)} />}
          label="출금대상 설정을 저장합니다."
        />
        <button type="button" className={`btn-l ${cmsRequestStatus !== 2 ? "hidden" : ""}`} onClick={handleClickRequestGenerations}>
          출금대상 생성
        </button>
        <button type="button" className={`btn-l ${cmsRequestStatus !== 3 ? "hidden" : ""}`} onClick={handleClickRequest} data-testid="post-cms-request">
          청구하기
        </button>
        <button type="button" className={`btn-m ${cmsRequestStatus !== 3 ? "hidden" : ""}`} onClick={handleClickRequestDelete} data-testid="delete-cms-request">
          청구대상 삭제
        </button>
      </div>
      <div>
        {cmsRequestStatus === 3 && (
          <Fragment>
            <h4>출금 청구 내역</h4>
            <Form.CmsRequestHistory cmsRequestSummary={cmsRequestSummary} />
            <Form.WithdrawalLimitInfo cmsRequestSummary={cmsRequestSummary} />
            <Form.CmsRequestSearchForm
              searchRequest={searchRequest}
              capitalOptions={capitalOptions}
              customerGroupOptions={customerGroupOptions}
              handleRequestSearchChange={handleRequestSearchChange}
              handleSearchButtonChange={handleSearchButtonChange}
              handleSearchKeyUp={handleSearchKeyUp}
            />
            <div className="table-top-area">
              <TotalCountForm totalElements={originalList.totalElements || 0} />
              <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleSelectExcelFile(e)}
                ref={excelInput}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                value=""
                data-testid="select-excel-input"
              />
              <button className="btn-m fr" onClick={handleModifyTargetBulk} data-testid="modify-excel">
                [일괄제외]엑셀파일 등록
              </button>
              <button className="btn-m fr" onClick={handleExcelDownload} data-testid="save-excel">
                [일괄제외양식]엑셀저장
              </button>

              <button className="btn-m fr" onClick={handleModifyTarget} data-testid="modify-open">
                출금금액 수정
              </button>
              <button className="btn-m fr" onClick={handleTargetAddModal} data-testid="add-cms-request">
                출금대상 추가
              </button>
            </div>
            <div className="fl">
              <Form.CmsRequestTable
                cmsRequestList={cmsRequestList}
                handleExcludeRow={handleExcludeRow}
                searchRequest={searchRequest}
                handleSortProperty={handleSortProperty}
                financialInstitutes={financialInstitutes}
                handleClickedRow={handleClickedRow}
              />
              <Pagination
                className="centered"
                limit={pagination.rowsPerPage}
                offset={pagination.offset}
                total={pagination.total}
                onClick={(e, offset, page) => handleOffsetChange(offset, page)}
                reduced={true}
                centerRipple={false}
                disableFocusRipple={true}
                disableRipple={true}
              />
            </div>
          </Fragment>
        )}
      </div>
      <TargetAddModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        fileUniqueKey={cmsRequestSummary.uniqueKey}
        setIsTargetChanged={setIsTargetChanged}
        capitalOptions={capitalOptions}
        customerGroupOptions={customerGroupOptions}
        financialInstitutes={financialInstitutes}
      />
      <TargetModifyModal
        open={modifyModalOpen}
        setOpen={(t) => {
          setTargetAskUniqueKey(null);
          setModifyModalOpen(t);
        }}
        fileUniqueKey={cmsRequestSummary.uniqueKey}
        setIsTargetChanged={setIsTargetChanged}
        capitalOptions={capitalOptions}
        customerGroupOptions={customerGroupOptions}
        financialInstitutes={financialInstitutes}
        targetAskUniqueKey={targetAskUniqueKey}
      />
    </div>
  );
};

export default CmsReceiptRequest;
