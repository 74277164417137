import React, { useRef, useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import * as CM from "../../common/Common";
import "../../../styles/institute.css";
import kftcLogo from "../../../styles/images/kftcLogo.png";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Modal, Button, Table, TableRow, TableBody, TableCell } from "@material-ui/core";

/*
 * @desc    메인 컴포넌트 선언
 */
const ChargeReceiptForm = (props) => {
  const billingoneObject = {
    kftc: "금융결제원",
    instName: props.loginStore.loginDto.instName,
    staffName: props.loginStore.loginDto.staffName,
  };

  const { open, setOpen, target, type } = props;

  const toDate = new Date();
  const amoutType = type === "billingone" ? "billingonePlusTotalAskingAmount" : "cmsTotalAskingAmount";
  const amoutDateType = type === "billingone" ? "billingonePlusLastPaidDate" : "cmsLastPaidDate";
  const plusTotalAskingAmount = target[amoutType];
  const plusLastPaidDate = target[amoutDateType];

  // 모달이 오픈될 때 value에 선택한 row의 object 값 넣기
  const [financialInstitutes, setFinancialInstitutes] = React.useState([]); //금융기관 자동완성 기능을 위한 state
  const [useInstitute, setUseInstitute] = useState({
    intgtLoginId: "",
    billingAccount: {
      intgtLoginId: "",
      accountNo: "",
      accountBranchName: "",
      accountBranchCode: "",
      depositorName: "",
      depositorIdentificationNo: "",
      accountApplyDate: null,
      virtualAccountNo: "",
    },
    cmsBillingAccount: {
      intgtLoginId: "",
      accountNo: "",
      accountBranchName: "",
      accountBranchCode: "",
      depositorName: "",
      depositorIdentificationNo: "",
      accountApplyDate: null,
      virtualAccountNo: "",
    },
  });
  // 모달이 오픈될 때 value에 선택한 row의 object 값 넣기
  useEffect(() => {
    if (open) {
      const fnFetchFinancialInstitutes = async () => {
        const url = "/api/gadget/financial-institutes";
        await CM.cfnAxios(url, "get", "", fnSetFinancialInstitutes);
      };

      /*
       * @desc    금융기관 목록 조회 Request Callback
       */
      const fnSetFinancialInstitutes = (objStatus, objData) => {
        setFinancialInstitutes(objData);
      };

      fnFetchFinancialInstitutes();
      const fnSetBusinessInfo = (objStatus, data) => {
        props.store.setBusinessInfo(data); // store 값 설정
        setUseInstitute(data);
      };

      const storeBusinessInfo = CM.cfnCopyObject(toJS(props.store.businessInfo));
      if (CM.cfnIsEmpty(storeBusinessInfo)) {
        props.store.getBusinessInfo(fnSetBusinessInfo);
      } else {
        fnSetBusinessInfo(200, storeBusinessInfo);
      }
    }
  }, [open, target, props]);
  const handleClose = () => {
    setOpen(false);
  };
  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();

  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          width: "1000px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <div className="table-top-area d-flex">
            <ReactToPrint trigger={() => <button className="btn-l print-button">영수증 인쇄</button>} content={() => printRef.current} copyStyles={true} />
          </div>
          <div className="print-outer-box-bill" ref={printRef}>
            <div className="receipt-head">영수증</div>
            <div className="receipt-body">
              <Table>
                {CM.cfnCompColGroup(["40%", "60%"])}
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className="th">
                      이용기관명
                    </TableCell>
                    <TableCell align="center" className="td">
                      {billingoneObject.instName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className="th">
                      예금주명
                    </TableCell>
                    <TableCell align="center" className="td">
                      {type === "billingone" ? useInstitute.billingAccount.depositorName : useInstitute.cmsBillingAccount.depositorName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className="th">
                      거래은행
                    </TableCell>
                    <TableCell align="center" className="td">
                      {CM.cfnMatchBankName(
                        type === "billingone" ? useInstitute.billingAccount.accountBranchCode.substr(0, 3) : useInstitute.cmsBillingAccount.accountBranchCode.substr(0, 3),
                        financialInstitutes
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className="th">
                      계좌번호
                    </TableCell>
                    <TableCell align="center" className="td">
                      {type === "billingone"
                        ? useInstitute.billingAccount && useInstitute.billingAccount.accountNo
                          ? useInstitute.billingAccount.accountNo.length > 3
                            ? useInstitute.billingAccount.accountNo.substr(0, useInstitute.billingAccount.accountNo.length - 3) + "***"
                            : useInstitute.billingAccount.accountNo
                          : ""
                        : useInstitute.cmsBillingAccount && useInstitute.cmsBillingAccount.accountNo
                        ? useInstitute.cmsBillingAccount.accountNo.length > 3
                          ? useInstitute.cmsBillingAccount.accountNo.substr(0, useInstitute.cmsBillingAccount.accountNo.length - 3) + "***"
                          : useInstitute.cmsBillingAccount.accountNo
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className="th">
                      영수금액
                    </TableCell>
                    <TableCell align="center" className="td">
                      {CM.cfnAddCommaOnChange(plusTotalAskingAmount)}원
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className="th">
                      출금(이체)일자
                    </TableCell>
                    <TableCell align="center" className="td">
                      {CM.cfnDateFormat(plusLastPaidDate, "yyyyMMdd")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div className="receipt-message">귀사(하)께서 이용하신 이용요금이 위와 같이 자동납부(이체) 되었음을 알려드립니다.</div>
              <div className="receipt-date">
                {toDate.getFullYear()}년 {toDate.getMonth() + 1 < 10 ? "0" + (toDate.getMonth() + 1) : toDate.getMonth() + 1}월 {toDate.getDate() < 10 ? "0" + toDate.getDate() : toDate.getDate()}일
              </div>
              <div className="receipt-number">공급자 사업자등록번호 : 129-82-08745</div>
              <div className="receipt-bottom">
                <img src={kftcLogo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
  props: props,
}))(observer(ChargeReceiptForm));
