import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

// import CustomerData from '../customerBasicInformation/CustomerData';

// 화면 초기 hooks 값
const DEFAULT_HOOKS = {
  summaryInfo: [
    { key: "totalTargets", label: "전체 건수", value: 0 },
    { key: "totalNewAccountRegistrationWaitingTargets", label: "신규 등록 건수", value: 0 },
    { key: "totalClosingAccountTargets", label: "해지 건수", value: 0 },
  ],
  requestParams: {
    isClosingAccountIncluded: true, // (요약정보 연계)계좌해지 대상고객 조회
    isNewAccountIncluded: true, // (요약정보 연계)신규계좌 대상고객 조회
    pageNumber: 0, // 요청 페이지번호
    pageSize: 5, // 페이지당 조회건수
    sortDirection: "ASC", // 정렬방법(ASC or DESC)
    sortProperty: "customerName", // 정렬필드
  },
};

// 고객정보관리 > 직접 접수분 > 계좌등록중
// 렌더를 처리하는 메인 컴포넌트
const RegisteringsList = (props) => {
  const { tabCountChange } = props;

  // hooks
  const [searchOn, setSearchOn] = useState(false); // 검색 실행 flag
  const [summaryInfo, setSummaryInfo] = useState(DEFAULT_HOOKS.summaryInfo); // 요약 정보
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams); // 검색 조건
  const [registeringsList, setRegisteringsList] = useState([]); // 테이블 데이터
  const [pagination, setPagination] = useState(CM.cfnPaginationData()); // 페이지네이션

  // handler
  // 페이지네이션 handler
  const handlePaginationChange = (param) => setPagination(CM.cfnSetPagination(param));

  // 페이지 handler
  const handlePageChange = (e, offset, page) => {
    setRequestParams((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchOn(true);
  };

  // 요약 정보 handler
  const handleSummaryInfoChange = (param) => setSummaryInfo((data) => data.map((element) => ({ ...element, value: param[element.key] || 0 })));

  // 요약 정보를 클릭했을 때 동작
  const handleSummaryInfoClick = (key) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 0,
      isClosingAccountIncluded: key === "totalClosingAccountTargets" || key === "totalTargets" ? true : false,
      isNewAccountIncluded: key === "totalNewAccountRegistrationWaitingTargets" || key === "totalTargets" ? true : false,
    }));
    setSearchOn(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = ({ target: { value } }) => {
    setRequestParams((data) => ({ ...data, pageNumber: 0, pageSize: value }));
    setSearchOn(true);
  };

  // 엑셀 저장
  const saveExcel = (param) => {
    let url = "api/customer/receipt/account/institute/registerings/excel";
    url += `?isClosingAccountIncluded=${param.isClosingAccountIncluded}&isNewAccountIncluded=${param.isNewAccountIncluded}&sortProperty=${requestParams.sortProperty}&sortDirection=${requestParams.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setRequestParams((data) => ({ ...data, sortProperty, sortDirection }));

    //정렬조건 세팅 후 검색 trigger
    setSearchOn(true);
  };

  // Axios 실행
  const getAxios = (param) => {
    return new Promise((resolve) => {
      let url = "api/customer/receipt/account/institute/registerings";
      url += `?isClosingAccountIncluded=${param.isClosingAccountIncluded}&isNewAccountIncluded=${param.isNewAccountIncluded}`;
      url += `&pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
      url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}`;

      CM.cfnAxios(url, "get", "", (objectStatus, resultData) => resolve(resultData));
    });
  };

  const getRegistrations = async (param) => {
    setSearchOn(false);
    const resultData = await getAxios(param);
    const resultSummaryInfo = resultData.receiptAccountRegistrationWaitingSummaryInfo;
    const resultTarget = resultData.receiptAccountRegistrationTargets;

    tabCountChange("registerings", resultSummaryInfo.totalTargets);

    handleSummaryInfoChange(resultSummaryInfo); // 요약
    setRegisteringsList(resultTarget.content); // 목록
    handlePaginationChange(resultTarget); // 페이지네이션
  };

  useEffect(() => {
    getRegistrations(requestParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 검색 useEffect
  useEffect(() => {
    if (searchOn) {
      getRegistrations(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOn, requestParams]);

  // 검색 useEffect
  useEffect(() => {
    if (props.tabIndex === 2) {
      getRegistrations(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabIndex]);

  // TODO: 화면 렌더
  return (
    <div>
      <div className="summary-main-area">
        <CF.SummaryForm list={summaryInfo} onClick={handleSummaryInfoClick} data-testid="registerAccountByInstitute-registerings" />
      </div>
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={requestParams.pageSize} onChange={handleRowPerPageChange} data-testid="registerAccountByInstitute-registerings-select-rowPerPage" />
        <button className="btn-m fr" onClick={() => saveExcel(requestParams)} data-testid="registerAccountByInstitute-registerings-save-excel">
          엑셀저장
        </button>
      </div>
      <ListForm
        list={registeringsList}
        pagination={pagination}
        handlePageChange={handlePageChange}
        financialInstitutes={props.financialInstitutes}
        requestParams={requestParams}
        handleSortProperty={handleSortProperty}
      />
    </div>
  );
};

// 목록 컴포넌트
const ListForm = (props) => {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["10%", "10%", "12%", "10%", "12%", "10%", "auto", "12%", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          value=""
          arrData={[
            { id: "transactionDate", label: "접수일자", sortable: false },
            { id: "applyDate", label: "등록요청 일자", sortable: false },
            { id: "expectedResultDate", label: "결과확인\n가능일", sortable: false },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "financialInstituteName", label: "금융기관", sortable: false },
            { id: "depositorName", label: "예금주명", sortable: true },
            { id: "depositorIdentificationNo", label: "예금주 생년월일\n(사업자번호)", sortable: true },
            { id: "accountNo", label: "계좌번호", sortable: false },
            { id: "accountRegistrationStatus", label: "신청구분", sortable: false },
          ]}
          searchRequest={props.requestParams}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId="registerAccountByInstitute-registerings-list-head-sortLabel"
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{CM.cfnDateFormat(target.fileTransactionDate)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.applyDate)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.expectedResultDate)}</TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{target.financialInstituteName || CM.cfnMatchBankName(target.financialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{target.depositorName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.depositorIdentificationNo)}</TableCell>
                  <TableCell align="center">{target.accountNo}</TableCell>
                  <TableCell align="center">{target.accountRegistrationType}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <CF.PaginationForm pagination={props.pagination} onClick={props.handlePageChange} testId="registerAccountByInstitute-registerings-pagination" />
    </div>
  );
};

export default RegisteringsList;
