import { Checkbox, Input, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import { RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import DonationData, { getBusinessDonationTypeCode, getBusinessDonationTypeName, getPersonalDonationTypeCode, getPersonalDonationTypeName } from "./DonationBillData";
import DonationBillPrintModal from "./DonationBillPrintModal";

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = ({ searchRequest, handleSearchFormChange, handleSearchButtonChange }) => {
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">발행연도</label>
        <Input
          className="w80"
          value={searchRequest.targetYear}
          onChange={handleSearchFormChange("targetYear")}
          onKeyUp={handleSearchKeyUp}
          name="search"
          data-testid="donationBill-history-request-targetYear-input"
          inputProps={{ maxLength: 4, style: { textAlign: "center" } }}
          type="number"
          endAdornment="년"
        />
        <label className="label-l">개인/사업자구분</label>
        <Select native value={searchRequest.isCustomerIndividual} onChange={handleSearchFormChange("isCustomerIndividual")}>
          <option value={true}>개인고객</option>
          <option value={false}>사업자고객</option>
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native className="" value={1}>
          <option value={""} key={"none"}>
            고객명
          </option>
        </Select>
        <TextField className="w150" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="request-keyword-input" />
        <button className="search-button" onClick={() => handleSearchButtonChange(true)} data-testid="request-search-button">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = ({ historyList, handleHistoryListChange, searchRequest, handleSortProperty }) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of historyList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const historyLength = historyList.length;

    if (checkAllFlag === historyLength && historyLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [historyList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleSelectCheckbox = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...historyList];
    cloneData[index][key] = value;
    handleHistoryListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    handleHistoryListChange((history) => history.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  return (
    <div>
      <Table data-testid="request-table">
        {CM.cfnCompColGroup(["5%", "10%", "10%", "5%", "10%", "20%", "auto", "10%"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "issueDate", label: "발행일자", sortable: true },
            { id: "donationSequenceNumber", label: "일련번호", sortable: true },
            { id: "customerType", label: "구분", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            {
              id: "customerIdentificationNo",
              label: "주민등록번호\n(사업자번호)",
              sortable: false,
            },
            { id: "customerAddress", label: "주소", sortable: false },
            {
              id: "donationAmount",
              label: "기부금액",
              sortable: false,
            },
          ]}
          searchRequest={searchRequest}
          handleSortProperty={handleSortProperty}
        />
        <TableBody>
          {historyList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            historyList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center" index="0">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`selectCheckbox-${index}`}
                      onChange={handleSelectCheckbox}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.issueDate)}</TableCell>
                  <TableCell align="center">{row.donationSequenceNumber}</TableCell>
                  <TableCell align="center">{CDN.customerType(row.customerType)}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.customerIdentificationNo)}</TableCell>
                  <TableCell align="left">{row.customerAddress}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.donationAmount)}원</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const IssueHistoryTab = ({ tabIndex, store, sealImage, donationForm }) => {
  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(DonationData.historySearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalHistoryList, setOriginalHistoryList] = useState([]); // 서버로 부터 전달받은 original list
  const [historyList, setHistoryList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(DonationData.paginationData());
  const [selectedRows, setSelectedRows] = useState([]); //선택된 행 제어

  //모달 제어를 위한 state 선언
  const [printOpen, setPrintOpen] = useState(false);

  // 테이블 데이터 검색
  useEffect(() => {
    /*
     * @desc 발행내역 목록 조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.payerNo = "";
      return tempObj;
    };
    // axios request informationList
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/donations/issued?customerName=${search.customerName}&isCustomerIndividual=${search.isCustomerIndividual}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&targetYear=${search.targetYear}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          () => {
            handleSearchButtonChange(false);
          }
        );
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      // 체크 옵션 추가
      for (const element of resultList) {
        element._checked = false;
      }

      setOriginalHistoryList(resultData);
      handleHistoryListChange(resultList);
      setPagination(DonationData.paginationData(resultData));
      handleSearchButtonChange(false);
    };

    if (tabIndex === 3 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "targetYear") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value.replace(/[^0-9]/g, ""),
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });

      if (name === "isCustomerIndividual") {
        setSearchButton(true);
      }
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 고객 목록 리스트 값을 변경하는 함수
  const handleHistoryListChange = (value) => setHistoryList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    요청 validation 체크 함수
   */
  const fnCheckValidation = () => {
    for (const history of historyList) {
      if (history._checked) {
        return true;
      }
    }
    return false;
  };

  const getAxios = (historyList) => {
    return new Promise((resolve) => {
      let form = new FormData();
      form.append('donationUniqueKeyList', historyList);
      form.append('isIssuedIndividually', 'true');

      let url = `api/extraservice/donations/targets/print?`;
      CM.cfnAxios(url, "post", form, (objectStatus, resultData) => resolve(resultData));
    });
  };

  /*
   * @desc    선택 영수증 재인쇄 버튼 클릭 이벤트 핸들러
   */
  const handleClickRePrint = async () => {
    if (fnCheckValidation()) {
      const tempArray = await getAxios(historyList.filter((history) => history._checked).map((history) => history.donationbillUniqueKey));

      setSelectedRows(tempArray);
      setPrintOpen(true);
    }
  };

  /*
   * @desc    선택 영수증 삭제 버튼 클릭 이벤트 핸들러
   */
  const handleClickDelete = () => {
    if (fnCheckValidation()) CM.cfnConfirm("정말로 삭제하시겠습니까?", fnDeleteDonationBill);
  };

  /*
   * @desc    목록저장(엑셀) 버튼 클릭 이벤트 핸들러
   */
  const handleClickDownloadExcel = () => {
    /*
     * @desc 발행내역 목록 조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.payerNo = "";

      return tempObj;
    };
    const search = fnMakeParameter();
    const url = `api/extraservice/donations/issued/excel?customerName=${search.customerName}&isCustomerIndividual=${search.isCustomerIndividual}&targetYear=${search.targetYear}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    기부금영수증 삭제 Request
   */
  const fnDeleteDonationBill = () => {
    const tempArray = [];
    for (const history of historyList) {
      if (history._checked) {
        tempArray.push(history.donationbillUniqueKey);
      }
    }

    for (const selectedKey of tempArray) {
      const url = `api/extraservice/donations/issued/${selectedKey}`;
      CM.cfnAxios(url, "delete", "", (status, objData) => {
        CM.cfnAlert("정상적으로 삭제되었습니다.", () => {
          handleSearchButtonChange(true);
        });
      });
    }
  };

  // 화면 렌더
  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <TotalCountForm totalElements={originalHistoryList.totalElements || 0} />
        <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
        <div className="btn-m fr" onClick={handleClickDownloadExcel} data-testid="account-request">
          목록저장(엑셀)
        </div>
        <div className="btn-m fr" onClick={handleClickDelete} data-testid="account-request">
          선택 영수증 삭제
        </div>
        <div className="btn-l fr" onClick={handleClickRePrint} data-testid="account-request">
          선택 영수증 재인쇄
        </div>
      </div>
      <ListForm historyList={historyList} handleHistoryListChange={handleHistoryListChange} searchRequest={searchRequest} handleSortProperty={handleSortProperty} />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
      <DonationBillPrintModal
        open={printOpen}
        setOpen={setPrintOpen}
        selectedRows={selectedRows}
        isEachIssuingTarget={true}
        sealImage={sealImage}
        donationForm={donationForm}
        donationTypeCode={searchRequest.isCustomerIndividual ? getPersonalDonationTypeCode(donationForm.personalDonationType) : getBusinessDonationTypeCode(donationForm.businessDonationType)}
        donationTypeName={searchRequest.isCustomerIndividual ? getPersonalDonationTypeName(donationForm.personalDonationType) : getBusinessDonationTypeName(donationForm.businessDonationType)}
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore,
  props: props,
}))(observer(IssueHistoryTab));
