import * as CM from "../../common/Common";

const MailLabelPrintData = {
  //paging 처리
  paginationData: (data) => {
    const returnObject = {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };

    if (CM.cfnIsNotEmpty(data)) {
      returnObject.rowsPerPage = data.pageable.pageSize;
      returnObject.offset = data.pageable.offset;
      returnObject.total = data.totalElements;
      returnObject.totalPages = data.totalPages;
    }

    return returnObject;
  },
  //검색조건
  searchData: {
    capitalType: "",
    customerGroupName: "",
    customerGroupNameKey: "",
    customerStaffName: "",
    customerStaffNameKey: "",
    pageNumber: 0,
    pageSize: 5,
    capitalName: "",
    capitalNameKey: "",
    sortDirection: "ASC",
    sortProperty: "customerName",
  },
  //라벨지 인쇄 방식
  printSettings: {
    size: "16",
    printName: true,
    printZipCode: true,
    printAddress: true,
    printMobileNo: true,
    printTelNo: true,
  },
  //미리보기 모달창의 예시 고객 정보
  dummyCustomer: {
    customerName: "홍길동",
    customerMobile: "01000000000",
    customerTelephone: "0200000000",
    customerZipCode: "00000",
    customerAddress: "서울특별시 XX구 OO동 △△△로 OO(XX 아파트)",
    customerAddressDetail: "OO동 OOO호",
  },
};

export default MailLabelPrintData;
