import React from "react";
import Modal from "@material-ui/core/Modal";
import { TableCell, TableRow, TableBody, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";

//최대 고객수 ( maxCustomerCount )
const ChangeInformationModal = ({ open, setOpen, object }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={handleClose}>
                {""}
              </Button>
            </div>
            <h3>업무정보 변경 내역</h3>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="th w80">변경 전 </TableCell>
                  <TableCell className="td">
                    <div className="under">명(기본 100명, 추가요금 2,000원/100명당)</div>
                  </TableCell>
                  <TableCell className="th w80">변경 후 </TableCell>
                  <TableCell className="td">
                    <div className="under">명(기본 100명, 추가요금 2,000원/100명당)</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ChangeInformationModal;
