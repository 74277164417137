import React from "react";
import * as CM from "../../common/Common";
import { FileUploadForm } from "../../template/ComponentForm";

const ReceiptCreateList = (props) => {
  const { tabIndex, handleTabTitle, handleModalClose } = props;

  // modal title change
  React.useEffect(() => {
    if (tabIndex === 0) handleTabTitle("수납고객정보 일괄등록");
  }, [tabIndex, handleTabTitle]);

  const defaultValue = {
    file: null,
    fileName: "",
  };

  //필요 state 선언
  const [registerFile, setRegisterFile] = React.useState(defaultValue); //일괄등록 파일 제어
  const [convertFile, setConvertFile] = React.useState(defaultValue); //일괄해지 파일 제어

  // 모달 클로즈
  const handleClose = () => {
    setRegisterFile(defaultValue);
    setConvertFile(defaultValue);
    handleModalClose();
  };

  /*
   * @desc    일괄등록/해지용 파일 등록 버튼 클릭 이벤트 핸들러
   */
  const handleRegisterFileUpload = (e) => {
    if (fnCheckValidation(registerFile)) {
      e.preventDefault();
      fnUploadExcel(registerFile, false);
    }
  };

  /*
   * @desc    일괄전환용 파일 등록 버튼 클릭 이벤트 핸들러
   */
  const handleConvertFileUpload = (e) => {
    if (fnCheckValidation(convertFile)) {
      e.preventDefault();
      fnUploadExcel(convertFile, true);
    }
  };

  /*
   * @desc    파일 업로드 전 validation 체크 함수
   */
  const fnCheckValidation = (selectedFile) => {
    let isValid = true;

    if (CM.cfnIsEmpty(selectedFile.fileName)) {
      isValid = false;
      CM.cfnAlert("업로드할 양식을 선택하여 주시기 바랍니다.");
    } else if (selectedFile.fileName.indexOf("xls") === -1) {
      isValid = false;
      CM.cfnAlert("엑셀 형식의 파일만 업로드하실 수 있습니다.");
    }

    return isValid;
  };

  /*
   * @desc    엑셀파일 업로드 Request
   */
  const fnUploadExcel = (selectedFile, isRegistered) => {
    let form = new FormData();
    form.append("file", selectedFile.file);
    form.append(
      "isRegistered ",
      new Blob([JSON.stringify(isRegistered)], {
        type: "application/json",
      })
    );

    const url = "api/customer/receipt/receipts/excel";
    CM.cfnAxios(
      url,
      "post",
      form,
      fnUploadExcelCallback,
      (error) => {
        setRegisterFile(defaultValue);
        setConvertFile(defaultValue);
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      },
      true
    );
  };

  /*
   * @desc    엑셀파일 업로드 Request Callback
   */
  const fnUploadExcelCallback = (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    CM.cfnAlert("등록되었습니다.", () => {
      props.handleSearchButtonChange(true);
      handleClose();
    });
  };

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>수납고객정보를 일괄등록 하시려면, 아래양식을 다운로드 받으신 후 엑셀 파일을 편집하여 등록하시기 바랍니다. </li>
          <li>
            일괄등록시 유의사항
            <br />- 납부자번호,수납시작월,할부여부는 등록 후 수정이 불가능하오니 신중하게 등록하시기 바랍니다.
            <br />- 총 수납횟수,수납종료월은 수납횟수가 끝나기 전이나 수납종료월이 도래하기 전까지만 수정 가능합니다.
          </li>
        </ul>
      </div>
      <h4>양식 다운로드</h4>
      <div className="download-area">
        <li>
          <span>수납고객정보 일괄 신규/변경/삭제 양식 다운로드 : </span>
          <a href="/excel/수납고객정보.xlsx" download className="link">
            수납고객정보 일괄등록양식.xlsx
          </a>
        </li>
        <li>
          <span>수납고객정보 일괄전환등록 양식 다운로드 : </span>
          <a href="/excel/수납고객정보_전환등록.xlsx" download className="link">
            수납고객정보 일괄전환등록 양식.xlsx
          </a>
        </li>
      </div>
      <h4>수납고객정보 일괄 신규/변경/삭제 엑셀 파일 등록</h4>
      <div className="search-area w100p">
        <div className="d-inline-flex flex-center">
          <label className="label-l">일괄 신규/변경/삭제 파일</label>
          <FileUploadForm acceptFileType=".xls, .xlsx" needPreview={false} uploadFile={registerFile} setUploadFile={setRegisterFile} />
          <button type="button" className="btn-l margin-left-20" onClick={handleRegisterFileUpload}>
            등록
          </button>
        </div>
      </div>
      <h4>수납고객정보 일괄전환등록 엑셀 파일 등록</h4>
      <h5>CMS 출금이체 이용기관 중 다른 프로그램으로 이미 금융기관에 등록했던 고객을 빌링원플러스에 다시 등록하는 경우(CMS기관코드가 동일해야 함)</h5>
      <div className="search-area w100p">
        <div className="d-inline-flex flex-center">
          <label className="label-l">일괄전환등록 파일</label>
          <FileUploadForm acceptFileType=".xls, .xlsx" needPreview={false} uploadFile={convertFile} setUploadFile={setConvertFile} />
          <button type="button" className="btn-l margin-left-20" onClick={handleConvertFileUpload}>
            등록
          </button>
        </div>
      </div>
    </div>
  );
};
export default ReceiptCreateList;
