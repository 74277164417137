import * as CM from "../../common/Common";

export const getPersonalDonationTypeCode = (code) => {
  if (DonationBillData.personalDonationTypeOptions.find((option) => option.value === code)) {
    return DonationBillData.personalDonationTypeOptions.find((option) => option.value === code).code;
  } else if (code === "TAX_LAW_73") {
    // 양식에서는 더이상 선택할 수 없지만 레거시로 남아있는 항목
    return "30";
  } else if (code === "TAX_LAW_73_1") {
    return "31";
  }
  return null;
};
export const getBusinessDonationTypeCode = (code) => {
  if (DonationBillData.businessDonationTypeOptions.find((option) => option.value === code)) {
    return DonationBillData.businessDonationTypeOptions.find((option) => option.value === code).code;
  } else if (code === "TAX_LAW_73_1") {
    // 양식에서는 더이상 선택할 수 없지만 레거시로 남아있는 항목
    return "31";
  }
  return null;
};

export const getPersonalDonationTypeName = (code) => {
  if (DonationBillData.personalDonationTypeOptions.find((option) => option.value === code)) {
    return DonationBillData.personalDonationTypeOptions.find((option) => option.value === code).label;
  } else if (code === "TAX_LAW_73") {
    // 양식에서는 더이상 선택할 수 없지만 레거시로 남아있는 항목
    return "조특법 73조";
  } else if (code === "TAX_LAW_73_1") {
    return "조특법 73조 1항";
  }
  return null;
};
export const getBusinessDonationTypeName = (code) => {
  if (DonationBillData.businessDonationTypeOptions.find((option) => option.value === code)) {
    return DonationBillData.businessDonationTypeOptions.find((option) => option.value === code).label;
  } else if (code === "TAX_LAW_73_1") {
    // 양식에서는 더이상 선택할 수 없지만 레거시로 남아있는 항목
    return "조특법 73조 1항";
  }
  return null;
};
const DonationBillData = {
  //클라이언트에서 pagination 처리 시 사용 오브젝트
  clientPaginationData: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
    pageNumber: 0,
  },
  //서버에서 pagination 처리 시 사용 오브젝트
  paginationData: (data) => {
    if (CM.cfnIsEmpty(data)) {
      return {
        rowsPerPage: 5,
        offset: 0,
        total: 0,
        totalPages: 1,
      };
    }

    return {
      rowsPerPage: data.pageable.pageSize,
      offset: data.pageable.offset,
      total: data.totalElements,
      totalPages: data.totalPages,
    };
  },
  //기부금영수증 양식관리 - 양식 default value
  defaultDonationFormValue: {
    intgtLoginId: "",
    serialNoPrefix: "",
    serialNoDigit: 1,
    personalDonationType: "LEGAL_DONATION",
    businessDonationType: "CORPORATIONAL_DONATION_24_2",
    managerName: "",
    managerTelephone: "",
    sealPrintYn: false,
    donationContents: "",
    instituteName: "",
    identificationNo: "",
    basedLaw: "",
    basedLawCode: "",
    address: "",
    addressDetail: "",
    zipCode: "",
    sealImageWidth: 0,
    sealImageHeight: 0,
    raiserInstituteName: "",
    raiserIdentificationNo: "",
    raiserAddress: "",
    raiserAddressDetail: "",
    raiserZipCode: "",
    serialNumberType: "EN", //일련번호체계
    isIdenticalGroup: false, //단체정보 내 동일 기관 체크박스 제어
    isIdenticalRaiser: false, //모집처 내 동일 기관 체크박스 제어
  },
  //기부금영수증 양식관리 - 기부유형 옵션(개인)
  personalDonationTypeOptions: [
    { value: "LEGAL_DONATION", label: "법정 기부금", code: "10" },
    { value: "POLITICAL_DONATION", label: "정치자금 기부금", code: "20" },
    { value: "DESIGNATED_DONATION", label: "지정 기부금", code: "40" },
    { value: "RELIGIOUS_DONATION", label: "종교단체 기부금", code: "41" },
    { value: "EMPLOYEE_OWNERSHIP_DONATION", label: "우리사주 기부금", code: "42" },
    { value: "ETC_DONATION", label: "기타 기부금", code: "50" },
  ],
  //기부금영수증 양식관리 - 기부유형 옵션(사업자)
  businessDonationTypeOptions: [
    { value: "CORPORATIONAL_DONATION_24_2", label: "법인세법 24조 2항", code: "10" },
    { value: "TAX_LAW_76", label: "조특법 76조", code: "20" },
    { value: "CORPORATIONAL_DONATION_24_1", label: "법인세법 24조 1항", code: "40" },
    { value: "ETC_DONATION", label: "기타 기부금", code: "50" },
  ],
  //기부금영수증 양식관리 - 영문숫자조합 일련번호 자릿수
  enSerialNoDigit: [
    { value: 1, label: "1자리" },
    { value: 2, label: "2자리" },
    { value: 3, label: "3자리" },
  ],
  //기부금영수증 양식관리 - 숫자일련번호 자릿수
  numSerialNoDigit: [
    { value: 1, label: "1자리" },
    { value: 2, label: "2자리" },
    { value: 3, label: "3자리" },
    { value: 4, label: "4자리" },
    { value: 5, label: "5자리" },
    { value: 6, label: "6자리" },
    { value: 7, label: "7자리" },
    { value: 8, label: "8자리" },
    { value: 9, label: "9자리" },
  ],
  //기부금영수증 양식관리 - 직인등록 모달 내 default value
  defaultSealImageValue: {
    file: null,
    fileName: "",
    fileSrc: "",
    uri: "",
  },

  //기부금영수증 양식관리 조회 Request
  fnFetchDonationForm: () => {
    return new Promise((resolve) => {
      const url = "api/extraservice/donations/formats";

      CM.cfnAxios(
        url,
        "get",
        "",
        (status, data) => {
          resolve(data);
        },
        (status, data) => {
          //실패 시 알림창을 띄우지 않도록 아무것도 선언하지 않은 콜백함수 추가
        }
      );
    });
  },

  //기부금영수증 양식관리 - 저장 시 validation 체크 함수
  fnCheckDonationFormValidation: (form, sealImageFile) => {
    if (sealImageFile.file && sealImageFile.fileName) {
      const imageType = sealImageFile.fileName.substr(sealImageFile.fileName.lastIndexOf(".") + 1).toLowerCase();
      if (imageType !== "jpg") {
        CM.cfnAlert("jpg 형식의 파일만 업로드할 수 있습니다.");
        return false;
      }
    }

    return true;
  },

  //기부금영수증 개별 발행 - 기부년도 옵션 생성
  donationBillIssueTargetYearOptions: () => {
    let year = new Date().getFullYear();

    return Array.from(Array(5).keys()).map((value, index) => ({ label: `${year - index}년`, value: year - index + "" }));
  },

  //기부금영수증 개별 발행 조회 검색조건
  individualIssueSearchData: {
    customerType: "INDIVIDUAL",
    customerName: "",
    identificationNo: "",
    customerUniqueKey: "",
    isEachIssuingTarget: true, //건별인쇄 or 합계인쇄
    targetYear: new Date().getMonth() >= 8 && new Date().getMonth() <= 12 ? String(new Date().getFullYear()) : String(new Date().getFullYear() - 1),
  },

  //기부금영수증 개별 발행 - 고객 주소 출력 함수
  fnPrintCustomerAddress: (obj) => {
    let addressString = "";

    if (CM.cfnIsNotEmpty(obj.customerZipCode)) addressString = `(${obj.customerZipCode}) `;

    if (CM.cfnIsNotEmpty(obj.customerAddress)) addressString += `${obj.customerAddress} `;

    if (CM.cfnIsNotEmpty(obj.customerAddressDetail)) addressString += `${obj.customerAddressDetail} `;

    return addressString;
  },

  //기부금영수증 개별 발행 - 합계 금액 계산 함수
  fnGetSumAmount: (list, type) => {
    let sum = 0;

    if (type === "monthly") {
      for (const row of list) {
        sum += CM.cfnNvl(row.donationAmount, 0);
      }
    } else if (type === "notIssued") {
      for (const row of list) {
        if (!row.isIssued) sum += CM.cfnNvl(row.donationAmount, 0);
      }
    }

    return CM.cfnAddCommaOnChange(sum);
  },
  //기부금영수증 일괄 발행 - 대상 조회 검색조건
  batchTargetSearchData: {
    capitalUniqueKey: "",
    groupUniqueKey: "",
    isCustomerIndividual: true,
    isEachIssuingTarget: true, //건별인쇄 or 합계인쇄
    isIssuedBillIncluded: false,
    staffUniqueKey: "",
    targetYear: new Date().getMonth() >= 8 && new Date().getMonth() <= 12 ? String(new Date().getFullYear()) : String(new Date().getFullYear() - 1),
    transactionMethod: "",
  },
  //기부금영수증 발행내역 - 조회 검색조건
  historySearchData: {
    customerName: "",
    isCustomerIndividual: true,
    isIssuedIndividually: true,
    keyword: "",
    term: "customerName",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "issueDate",
    targetYear: new Date().getMonth() >= 8 && new Date().getMonth() <= 12 ? String(new Date().getFullYear()) : String(new Date().getFullYear() - 1),
  },
  //기부금영수증 - 국세청 제출파일 생성 대상 검색조건
  ntsSearchData: {
    customerType:"",
    groupUniqueKey: "",
    sortDirection: "ASC",
    sortProperty: "customerName",
    staffUniqueKey: "",
    targetYear: new Date().getMonth() >= 8 && new Date().getMonth() <= 12 ? String(new Date().getFullYear()) : String(new Date().getFullYear() - 1),
  },

  //기부금 단체코드 근거법령 목록
  basedLawList: [
    {
      group: "정당(후원회 및 선거관리위원회 포함)",
      type: "정치자금기부금",
      content: "10만원 이하 금액은 세액공제(기부금액*100/110)",
      basedLaw: "조특법 76",
      basedLawCode: "AAA",
      code: "20",
    },
    {
      group: "국가 또는 지방자치단체(지방자치단체조합 포함)",
      type: "법정기부금",
      content: `기부금품의 모집 및 사용에 관한 법률의 적용을 받는 기부금은 동법 제5조 제2항의 규정에 의하여 접수받는 것에 한한다.`,
      basedLaw: "법법 24 ③ 1",
      basedLawCode: "BAA",
      code: "10",
    },
    {
      group: "국방헌금과 국군장병 위문금품",
      type: "법정기부금",
      content: "국방헌금과 국군장병 위문금품",
      basedLaw: "법법 24 ③ 2",
      basedLawCode: "BAB",
      code: "10",
    },
    {
      group: "천재지변 이재민 구호금품",
      type: "법정기부금",
      content: "재난 및 안전관리기본법 제60조의 규정에 따라 특별재난지역으로 선포된 경우 그 선포의 사유가 된 재난을 포함",
      basedLaw: "법법 24 ③ 3",
      basedLawCode: "BAC",
      code: "10",
    },
    {
      group: "사립학교(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `사립학교법`,
      basedLaw: "법법 24 ③ 4 가",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "비영리교육재단(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `국립, 공립, 사립학교의 시설비, 교육비, 장학금 또는 연구비 지급을 목적으로 설립된 비영리 재단법인으로 한정`,
      basedLaw: "법법 24 ③ 4 나",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "기능대학(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `근로자직업능력 개발법`,
      basedLaw: "법법 24 ③ 4 다",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "전공대학의 명칭을 사용할 수 있는 평생교육시설 및 원격대학형태의 평생교육시설(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `평생교육법`,
      basedLaw: "법법 24 ③ 4 라",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "외국교육기관(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `경제자유구역 및 제주국제자유도시의 외국교육기관 설립운영에 관한 특별법에 따라 설립된 외국교육기관 및 제주특별자치도 설치 및 국제자유도시 조성을 위한 특별법에 따라 설치된 비영리법인이 운영하는 국제학교`,
      basedLaw: "법법 24 ③ 4 마",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "산학협력단(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `산업교육진흥 및 산학연협력촉진에 관한 법률 에 따른 산학협력단`,
      basedLaw: "법법 24 ③ 4 바",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "한국과학기술원,광주과학기술원,대구경북과학기술원, 울산과학기술원(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `국립대학법인 서울대학교 설립운영에 관한 법률, 국립대학법인 인천대학교 설립운영에 관한 법률, 정부출연연구기관 등의 설립,운영 및 육성에 관한 법률, 한국학중앙연구원 육성법, 과학기술분야 정부출연연구기관 등의 설립,운영 및 육성에 관한 법률`,
      basedLaw: "법법 24 ③ 4 사",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group:
        "서울대학교, 인천대학교, 한국개발연구원에 설치된 국제대학원, 한국중앙연구원에 설치된 대학원, 과학기술분야 정부출연연구기관 등의 설립,운영 및 육성에 관한법률 제33조에 따라 설립된 대학원 대학(시설비,교육비, 장학금, 연구비)",
      type: "법정기부금",
      content: `국립대학법인 서울대학교 설립운영에 관한 법률, 국립대학법인 인천대학교 설립운영에 관한 법률, 정부출연연구기관 등의 설립,운영 및 육성에 관한 법률, 한국학중앙연구원 육성법, 과학기술분야 정부출연연구기관 등의 설립,운영 및 육성에 관한 법률`,
      basedLaw: "법법 24 ③ 4 아",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: `○ 한국학교(시설비, 교육비, 장학금, 연구비) \n
- 리야드한국학교 \n
- 싱가포르한국국제학교 \n
- 연대한국학교 \n
- 타이뻬이한국학교 \n
- 까오숑한국국제학교, 필리핀한국국제학교  \n
- 소주한국학교, 광저우한국학교, 말레이시아 한국학교 \n
- 교토국제학교 \n
- 오사카금강학교 \n
- 건국한국학교 \n
- 대련한국국제학교 \n
- 동경한국학교 \n
- 무석한국학교  \n
- 북경한국국제학교 \n
- 상해한국학교 \n
- 선양한국국제학교 \n
- 아르헨티나한국학교 \n
- 자카르타한국국제학교 \n
- 젯다한국국제학교 \n
- 천진한국국제학교 \n
- 테헤란한국학교`,
      type: "법정기부금",
      content: `재외국민의 교육지원 등에 관한 법률 제2조 제3호에 다른 한국학교 \n기획재정부 고시(법정기부금단체 중 한국학교의 범위) 참고`,
      basedLaw: "법법 24 ③ 4 자",
      basedLawCode: "BBA",
      code: "10",
    },
    {
      group: "국립대학병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `국립대학병원설치법`,
      basedLaw: "법법 24 ③ 5 가",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "국립대학치과병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `국립대학치과병원설치법`,
      basedLaw: "법법 24 ③ 5 나",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "서울대학교병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `서울대학교병원설치법`,
      basedLaw: "법법 24 ③ 5 다",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "서울대학교치과병원설치법",
      type: "법정기부금",
      content: `서울대학교치과병원설치법`,
      basedLaw: "법법 24 ③ 5 라",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "사립학교가 운영하는 병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `사립학교법`,
      basedLaw: "법법 24 ③ 5 마",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "국립암센터(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `암관리법`,
      basedLaw: "법법 24 ③ 5 바",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "지방의료원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `지방의료원의 설립 및 운영에 관한 법률`,
      basedLaw: "법법 24 ③ 5 사",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "국립중앙의료원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `국립중앙의료원의 설립 및 운영에 관한 법률`,
      basedLaw: "법법 24 ③ 5 아",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "대한적십자사가 운영하는 병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `대한적십자사 조직법`,
      basedLaw: "법법 24 ③ 5 자",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "한국보훈복지의료공단이 운영하는 병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `한국보훈복지의료공단법`,
      basedLaw: "법법 24 ③ 5 차",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "한국원자력의학원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `방사선 및 방사선동위원소 이용진흥법 제13조의2`,
      basedLaw: "법법 24 ③ 5 카",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "국민건강보험공단이 운영하는 병원(시설비, 교육비, 연구비)",
      type: "법정기부금",
      content: `국민건강보험법`,
      basedLaw: "법법 24 ③ 5 타",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "산업재해보상보험법 제43조제1항제1호",
      type: "법정기부금",
      content: `산업재해보상보험법 제43조제1항제1호`,
      basedLaw: "법법 24 ③ 5 파",
      basedLawCode: "BBB",
      code: "10",
    },
    {
      group: "사회복지공동모금회",
      type: "법정기부금",
      content: `기획재정부 고시(법정기부금 단체중 전문모금기관의 범위)`,
      basedLaw: "법법 24 ③ 6",
      basedLawCode: "BCA",
      code: "10",
    },
    {
      group: "재단법인 바보의 나눔",
      type: "법정기부금",
      content: `기획재정부 고시(법정기부금 단체중 전문모금기관의 범위)`,
      basedLaw: "법법 24 ③ 6",
      basedLawCode: "BCA",
      code: "10",
    },
    {
      group: "국방과학연구소",
      type: "법정기부금",
      content: `국방과학연구소법(17.12.31 종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 1`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국잡월드",
      type: "법정기부금",
      content: `고용정책기본법 제18조의2(17.12.31 종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 2`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "2018 평창 동계올림픽대회 및 장애인동계올림픽대회 조직위원회",
      type: "법정기부금",
      content: `2018평창 동계올림픽대회 및 동계패럴림필대회 지원 등에 관한 특별법 제5조(17.12.31 종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 3`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "연구개발특구진흥재단",
      type: "법정기부금",
      content: `연구개발특구의 육성에 관한 특별법 제46조(17.12.31 종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 4`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국고전번역원",
      type: "법정기부금",
      content: `한국고전번역원법 제4조(17.12.31 종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 5`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "동학농민혁명 기념재단",
      type: "법정기부금",
      content: `동학농민혁명 참여자 등의 명예회복에 관한 특별법 제9조(18.12.31종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 6`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "2014인천장애인아시아경기대회조직위원회",
      type: "법정기부금",
      content: `2011대구세계육상선수권대회, 2014인천아시아경기대회, 2014인천장애인아시아경기대회 및 2015광주하계유니버시아드대회 지원법 제3조(18.12.31종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국화장실협회",
      type: "법정기부금",
      content: `공중화장실 등에 관한 법률 제15조의2`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 8`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국노인인력개발원",
      type: "법정기부금",
      content: `공공기관의 운영에 관한 법률 제5조(18.12.31종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 9`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국기상산업진흥원",
      type: "법정기부금",
      content: `기상산업진흥법 제17조(18.12.31종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 10`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국문화예술회관연합회",
      type: "법정기부금",
      content: `문화예술진흥법 제38조(18.12.31종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 11`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국산업기술진흥원",
      type: "법정기부금",
      content: `산업기술혁신 촉진법 제38조(18.12.31종료)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7 12`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "대한결핵협회",
      type: "법정기부금",
      content: `결핵예방법 제21조 \n 기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "2015경북문경세계군인체육대회조직위원회",
      type: "법정기부금",
      content: `2015경북문경세계군인체육대회 지원법 제3조\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },

    {
      group: "2015세계물포럼조직위원회",
      type: "법정기부금",
      content: `2015세계물포럼지원특별법 제3조\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "세종학당재단",
      type: "법정기부금",
      content: `국어기본법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "국외소재문화재재단",
      type: "법정기부금",
      content: `문화재보호법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "국립생태원",
      type: "법정기부금",
      content: `국립생태원의 설립 및 운영에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "아이오엠이민정책연구원",
      type: "법정기부금",
      content: `공공기관의 운영에 관한 법률 제4조\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "기초과학연구원",
      type: "법정기부금",
      content: `국제과학비즈니스벨트 조성 및 지원에 관한 특별법 제14조\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "일제강제동원피해자지원재단",
      type: "법정기부금",
      content: `대일항쟁기 강제동원 피해조사 및 국외강제동원희생자 등 지원에 관한 특별법 제37조\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "국립대구과학관",
      type: "법정기부금",
      content: `과학관의 설립·운영 및 육성에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "국립광주과학관",
      type: "법정기부금",
      content: `과학관의 설립·운영 및 육성에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "국립부산과학관",
      type: "법정기부금",
      content: `과학관의 설립·운영 및 육성에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국로봇산업진흥원",
      type: "법정기부금",
      content: `지능형 로봇 개발 및 보급 촉진법 제41조제1항\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "시청자미디어재단",
      type: "법정기부금",
      content: `방송법 제90조의2제1항\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "대한적십자사",
      type: "법정기부금",
      content: `대한적십자사 조직법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국국제교류재단",
      type: "법정기부금",
      content: `한국국제보건의료재단법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국과학창의재단",
      type: "법정기부금",
      content: `과학기술기본법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국사회복지협의회",
      type: "법정기부금",
      content: `사회복지사업법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "북한이탈주민지원재단",
      type: "법정기부금",
      content: `북한이탈주민의 보호 및 정착지원에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국장학재단",
      type: "법정기부금",
      content: `한국장학재단 설립 등에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국문화예술위원회",
      type: "법정기부금",
      content: `문화예술진흥법 제20조\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "독립기녑관",
      type: "법정기부금",
      content: `독립기념관법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "문화유산국민신탁 및 자연환경국민신탁",
      type: "법정기부금",
      content: `문화유산과 자연환경자산에 관한 국민신탁법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국해양수산연수원",
      type: "법정기부금",
      content: `한국해양수산연수원법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "대한민국전몰군경유족회, 대한민국전몰군경미망인회, 광복회, 재일학도의용군동지회 및 대한민국무공수훈자회",
      type: "법정기부금",
      content: `국가유공자 등 단체설립에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "대한장애인체육회",
      type: "법정기부금",
      content: `국민체육진흥법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group:
        "한국과학기술연구원, 한국기초과학지원연구원, 한국천문연구원, 한국생명공학연구원, 한국과학기술정보연구원, 한국한의학연구원, 한국생산기술연구원, 한국철도기술연구원, 한국표준과학연구원, 한국지질자원연구원, 한국기계연구원, 한국전기연구원 및 한국화학연구원",
      type: "법정기부금",
      content: `과학기술분야 정부출연연구기관 등의 설립운영 및 육성에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국법무보호복지공단",
      type: "법정기부금",
      content: `보호관찰 등에 관한 법률\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국출판문화산업진흥원",
      type: "법정기부금",
      content: `출판문화산업 진흥법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "한국선원복지고용센터",
      type: "법정기부금",
      content: `선원법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "아시아문화원",
      type: "법정기부금",
      content: `아시아문화중심도시 조성에 관한 특별법\n기획재정부 고시(법정기부금단체 중 공공기관 등의 범위)`,
      basedLaw: `(구)법법 24 ② 7 \n 법칙 별표 6의7`,
      basedLawCode: "BCB",
      code: "10",
    },
    {
      group: "재난 및 안전관리기본법에 따른 특별재난지역을  복구하기 위한 자원봉사를 한 경우 그 용역의 가액",
      type: "법정기부금",
      content: `봉사일수에 5만원을 곱한 금액 \n
            - 봉사일수=총봉사시간/8시간(소수점 이하의 부분은 1일로 보아 계산) \n
            당해 자원봉사용역에 부수하여 발생하는 유류비 재료비 등 직접 비용 : 제공할 당시의 시가 또는 장부가액`,
      basedLaw: `소법 34 ③ 2`,
      basedLawCode: "BDA",
      code: "10",
    },
    {
      group: `민법제32조의 규정에 따라 주무관청의 허가를 받아 설립된 비영리 법인, 비영리외국법인, 협동조합 기본법 제85조에 따라 설립된 사회적협동조합, 공공기관의 운영에 관한 법률 제 4조에 따른 공공기관 또는 법률에 따라 직접 설립된 기관 중 주무관청의 추천을 받아 기획재정부장관이 지정한 법인(지정일이 속하는 연도의 1월 1일부터 6년간 지출하는 기부금에 한함) \n
          ＊기획재정부 홈페이지(www.mosf.go.kr)>법령>공고 \n
          “지정기부금단체”검색(분기별 공고) `,
      type: "지정기부금",
      content: `(1) 다음의 구분에 따른 요건 \n
        가) 민법상 비영리법인 : 정관의 내용상 수입을 회원의 이익이 아닌 공익을 위하여 사용하고 사업의 직접 수혜자가 불특정 다수인 것이 인정될 것 \n
        나) 사회적협동조합 : 정관의 내용상 협동조합기본법 제93조제1항제1호 부터 제3호까지의의 사업 중 어느 하나의 사업을 수행하는 것으로 인정될 것 \n
      (2) 해산시 잔여재산을 국가ㆍ지방자치단체 또는 유사한 목적을 가진 다른 비영리법인에게 귀속하도록 한다는 내용이 정관에 포함되어 있을 것 \n
      (3) 인터넷 홈페이지가 개설되어 있고, 인터넷 홈페이지를 통하여 연간 기부금 모금액 및 활용실적을 공개한다는 내용이 정관에 포함되어 있을 것 \n 
      (4) 지정일이 속하는 연도와 그 직전 연도에 해당 비영리법인의 명의 또는 그 대표자의 명의로 특정 정당 또는 특정인에 대한 공직선거법 제58조 제1항에 따른 선거운동을한 것으로 권한있는 기관이 확인한 사실이 없을 것 \n
      (5) 지정이 취소된 경우에는 그 취소된 날부터 3년, 재지정을 받지 못하게 된 경우에는 그 지정기간의 종료일부터 3년이 지났을 것`,
      basedLaw: `법령 39 ① 1 바`,
      basedLawCode: "EBA",
      code: "40",
    },
    {
      group: `비영리민간단체 지원법에 따라 등록된 단체 중 행정안전부장관의 추천을 받아 기획재정부장관이 지정한 법인(고유목적사업비) \n
        ＊기획재정부 홈페이지(www.mosf.go.kr)>법령>공고 \n
        “지정기부금단체”검색(반기별 공고) `,
      type: "지정기부금",
      content: `가. 해산시 잔여재산을 국가 지방자치단체 또는 유사목적을 가진 비영리단체에 귀속하도록 한다는 내용이 정관에 포함되어 있을것 \n
      나. 수입(국가 또는 지방자치단체로부터 받는 보조금 수입은 제외) 중 개인의 회비 후원금이 차지하는 비율이 50%를 초과할 것 \n
      다. 수입을 침목등 회원의 이익이 아닌 공익을 위하여 사용하고 사업의 직접  수혜자가 불특정 다수일 것 \n
      라. 지정을 받으려는 과세기간의 직전 과세기간 종료일부터 소급하여 1년 이상 비영리민간단체 명의의 통장으로 회비 및 후원금 등의 수입을 관리할 것 \n
      마. 과세기간별 결산보고서의 공개에 동의할 것 \n
      바. 행정안전부장관의 추천일 현재 인터넷 홈페이지가 개설되어 있고, 인터넷 홈페이지를 통하여 연간 기부금 모금액 및 활용실적을 매년 3월 31일까지 공개한다는 내용이 정관에 기재되어 있을 것 \n
      사. 기부금대상민간단체 또는 그 대표자의 명의로 특정 정당 또는 특정인에 대한 공직선거법 제58조 제1항에 따른 선거운동을 하지 아니할 것`,
      basedLaw: `소령 80 ① 5`,
      basedLawCode: "EHC",
      code: "40",
    },
    {
      group: `사회복지법인(고유목적사업비)`,
      type: "지정기부금",
      content: `사회복지사업법`,
      basedLaw: `법령 39 ① 1 가`,
      basedLawCode: "EAA",
      code: "40",
    },
    {
      group: `종교단체(고유목적사업비)`,
      type: "지정기부금",
      content: `종교의 보급, 그 밖의 교화를 목적으로 민법 제32조에 따라 문화체육관광부장관 또는 지방자치단체의 장의 허가를 받아 설립한 비영리법인(그 소속 단체를 포함)`,
      basedLaw: `법령 39 ① 1 마`,
      basedLawCode: "ECA",
      code: "41",
    },
    {
      group: `사회복지법인(고유목적사업비)`,
      type: "지정기부금",
      content: `사회복지사업법`,
      basedLaw: `법령 39 ① 1 가`,
      basedLawCode: "EAA",
      code: "40",
    },
    {
      group: `우리사주조합`,
      type: "우리사주조합기부금",
      content: `우리사주조합원이 지출하는 기부금 제외`,
      basedLaw: `조특법 88조의4 `,
      basedLawCode: "DAA",
      code: "42",
    },
    {
      group: `어린이집 \n
      유치원 \n
      초등학교, 중등학교, 고등학교, 특수학교, 각종학교 \n
      대학교 \n
      기능대학 \n
      전공대학 형태의 평생교육시설, 원격대학 형태의 평생교육시설 \n
      (고유목적사업비로 지출하는 기부금)`,
      type: "지정기부금",
      content: `영유아보육법 \n
      유아교육법 \n 
      초ㆍ중등교육법 및 고등교육법 \n
      근로자직업능력 개발법 \n
      평생교육법`,
      basedLaw: `법령 39 ① 1 나, 다`,
      basedLawCode: "EAB",
      code: "40",
    },
    {
      group: `학술연구단체ㆍ장학단체ㆍ기술진흥단체(고유목적사업비로 지출하는 기부금)`,
      type: "지정기부금",
      content: `정부로부터 허가 또는 인가`,
      basedLaw: `(구) 법령 §36 ① 1 다`,
      basedLawCode: "EAC",
      code: "40",
    },
    {
      group: `문화ㆍ예술단체(「문화예술진흥법」에 의하여 지정을 받은 전문예술법인 및 전문예술단체를 포함) 또는 환경보호운동단체(고유목적사업비로 지출하는 기부금)`,
      type: "지정기부금",
      content: `정부로부터 허가 또는 인가`,
      basedLaw: `(구) 법령 §36 ① 1 라`,
      basedLawCode: "EAD",
      code: "40",
    },
    {
      group: `의료법인(고유목적사업비)`,
      type: "지정기부금",
      content: `의료법`,
      basedLaw: `법령 39 ① 1 라`,
      basedLawCode: "EAE",
      code: "40",
    },
    {
      group: `국민건강보험공단`,
      type: "지정기부금",
      content: `국민건강보험법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한일친선협회, 한일협력위원회`,
      type: "지정기부금",
      content: `재일본대한민국민단 또는 주무관청의 장의 추천을 받아 기획재정부장관이 지정 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `새마을운동중앙회(그 산하조직을 포함)`,
      type: "지정기부금",
      content: `새마을운동조직육성법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `어린이육영사업을 목적으로 설립된 비영리법인`,
      type: "지정기부금",
      content: `공익법인의 설립ㆍ운영에 관한 법률 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국보훈복지의료공단`,
      type: "지정기부금",
      content: `한국보훈복지의료공단법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국법무보호복지공단`,
      type: "지정기부금",
      content: `보호관찰 등에 관한 법률 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국재향경우회`,
      type: "지정기부금",
      content: `대한민국재향경우회법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국보건사회연구원, 한국여성정책연구원, 한국개발연구원, 대외경제정책연구원 `,
      type: "지정기부금",
      content: `정부출연연구기관 등의 설립ㆍ운영 및 육성에 관한 법률 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국해양소년단연맹`,
      type: "지정기부금",
      content: `한국해양소년단연맹육성에관한법률 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한결핵협회`,
      type: "지정기부금",
      content: `결핵예방법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한법률구조공단`,
      type: "지정기부금",
      content: `법률구조법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `법률구조법인 중 「공익법인의 설립ㆍ운영에 관한 법률」의 적용을 받는 법인`,
      type: "지정기부금",
      content: `법률구조법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국청소년단체협의회(회원단체를 포함), 정부로부터 인가 또는 허가를 받은 청소년단체로서 청소년기본법 제3조제8호에 따른 청소년단체`,
      type: "지정기부금",
      content: `청소년기본법, 청소년기본법 제3조제8호\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국연구재단`,
      type: "지정기부금",
      content: `한국연구재단법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `직업능력개발훈련법인`,
      type: "지정기부금",
      content: `근로자직업능력 개발법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `도서관 또는 작은도서관`,
      type: "지정기부금",
      content: `도서관법에 따라 등록하거나 신고 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `바르게살기운동중앙협의회(산하조직 포함)`,
      type: "지정기부금",
      content: `바르게살기운동조직육성법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국장애인개발원`,
      type: "지정기부금",
      content: `장애인복지법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `사학진흥재단`,
      type: "지정기부금",
      content: `한국사학진흥재단법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `환경보전협회\n환경보전범국민운동추진협의회, 환경운동연합(주무관청의 장의 추천을 받아 기획재정부장관이 지정)`,
      type: "지정기부금",
      content: `환경정책기본법, 주무관청의 장의 추천을 받아 기획재정부장관이 지정 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `박물관 또는 미술관`,
      type: "지정기부금",
      content: `박물관 및 미술관 진흥법에 따라 등록\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `과학관`,
      type: "지정기부금",
      content: `과학관의 설립·운영 및 육성에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `에너지관리공단`,
      type: "지정기부금",
      content: `에너지이용 합리화법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국시설안전공단`,
      type: "지정기부금",
      content: `시설물의 안전관리에 관한 특별법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국가유공자 등 단체설립에 관한 법률에 따라 설립한 각 단체`,
      type: "지정기부금",
      content: `국가유공자 등 단체설립에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `외국의 국제문화친선단체`,
      type: "지정기부금",
      content: `주무부장관이 추천\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `정신보건시설법인`,
      type: "지정기부금",
      content: `정신보건법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `인구보건복지협회`,
      type: "지정기부금",
      content: `모자보건법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `근로복지공단`,
      type: "지정기부금",
      content: `산업재해보상보험법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `북한이탈주민지원재단`,
      type: "지정기부금",
      content: `북한이탈주민의 보호 및 정착지원에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `신용보증재단 및 전국신용보증재단중앙회`,
      type: "지정기부금",
      content: `지역신용보증재단법 \n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `서울대학교병원`,
      type: "지정기부금",
      content: `서울대학교병원 설치법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국립대학병원`,
      type: "지정기부금",
      content: `국립대학병원 설치법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `스카우트주관단체`,
      type: "지정기부금",
      content: `스카우트활동 육성에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국청소년연맹`,
      type: "지정기부금",
      content: `한국청소년연맹 육성에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `사단법인 한국자유총연맹`,
      type: "지정기부금",
      content: `한국자유총연맹육성에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국재향군인회`,
      type: "지정기부금",
      content: `대한민국재향군인회법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `중소기업기술정보진흥원`,
      type: "지정기부금",
      content: `중소기업기술혁신 촉진법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `재외동포재단`,
      type: "지정기부금",
      content: `재외동포재단법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `산학협력단`,
      type: "지정기부금",
      content: `산업교육진흥 및 산학협력촉진에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `서울대학교치과병원`,
      type: "지정기부금",
      content: `서울대학교치과병원 설치법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `민주화운동기념사업회`,
      type: "지정기부금",
      content: `민주화운동기념사업회법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국산지보전협회`,
      type: "지정기부금",
      content: `산지관리법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국립공원관리공단`,
      type: "지정기부금",
      content: `자연공원법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `신용보증기금`,
      type: "지정기부금",
      content: `신용보증기금법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `기술신용보증기금`,
      type: "지정기부금",
      content: `기술신용보증기금법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대ㆍ중소기업협력재단`,
      type: "지정기부금",
      content: `대ㆍ중소기업 상생협력 촉진에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한장애인체육회`,
      type: "지정기부금",
      content: `국민체육진흥법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `사내근로복지기금`,
      type: "지정기부금",
      content: `근로복지기금법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국교육방송공사`,
      type: "지정기부금",
      content: `한국교육방송공사법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국립암센터`,
      type: "지정기부금",
      content: `암관리법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국장애인고용공단`,
      type: "지정기부금",
      content: `장애인고용촉진 및 직업재활법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국국제보건의료재단`,
      type: "지정기부금",
      content: `한국국제보건의료재단법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국환경산업기술원(종전의 친환경상품진흥원의 업무로 한정)`,
      type: "지정기부금",
      content: `환경기술 및 환경산업 지원법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국소방안전협회`,
      type: "지정기부금",
      content: `소방기본법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `항만운송사업법에 따른 교육훈련기관`,
      type: "지정기부금",
      content: `항만운송사업법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `범죄피해자 지원법인`,
      type: "지정기부금",
      content: `범죄피해자 보호법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `공탁금관리위원회`,
      type: "지정기부금",
      content: `공탁법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `산업기술보호협회`,
      type: "지정기부금",
      content: `산업기술의 유출방지 및 보호에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국특수임무수행자회`,
      type: "지정기부금",
      content: `특수임무수행자 지원 및 단체설립에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국고엽제전우회`,
      type: "지정기부금",
      content: `고엽제후유의증 등 환자지원 및 단체설립에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `겨레말큰사전남북공동편찬사업회`,
      type: "지정기부금",
      content: `겨레말큰사전남북공동편찬사업회법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `중소기업종합지원센타`,
      type: "지정기부금",
      content: `지역균형개발 및 지방중소기업 육성에 관한 법률 시행령\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `태권도진흥재단`,
      type: "지정기부금",
      content: `태권도 진흥 및 태권도 공원 조성 등에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `전국재해구호협회`,
      type: "지정기부금",
      content: `재해구호법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `소상공인시장진흥공단`,
      type: "지정기부금",
      content: `소기업 및 소상공인 지원을 위한 특별조치법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국등산지원센터`,
      type: "지정기부금",
      content: `산림문화ㆍ휴양에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국도서관협회`,
      type: "지정기부금",
      content: `도서관법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국민연금공단`,
      type: "지정기부금",
      content: `국민연금법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한-아세안센터`,
      type: "지정기부금",
      content: `대한민국과 동남아시아국가연합 회원국간의 한-아세안센터 설립에 관한 양해각서\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `사방협회`,
      type: "지정기부금",
      content: `사방사업법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국학교`,
      type: "지정기부금",
      content: `재외국민의 교육지원 등에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `독도의용수비대기념사업회`,
      type: "지정기부금",
      content: `독도의용수비대 지원법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국6ㆍ25참전유공자회`,
      type: "지정기부금",
      content: `참전유공자예우 및 단체설립에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국표준협회`,
      type: "지정기부금",
      content: `산업표준화법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국기원`,
      type: "지정기부금",
      content: `태권도 진흥 및 태권도공원 조성 등에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `학교안전공제회 및 학교안전공제중앙회`,
      type: "지정기부금",
      content: `학교안전사고 예방 및 보상에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `지방문화원 및 한국문화원연합회`,
      type: "지정기부금",
      content: `지방문화원진흥법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `국립대학치과병원`,
      type: "지정기부금",
      content: `국립대학치과병원 설치법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국월남전참전자회`,
      type: "지정기부금",
      content: `참전유공자예우 및 단체설립에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `전후납북자피해가족연합회`,
      type: "지정기부금",
      content: `군사정전에 관한 협정 체결 이후 납북피해자의 보상 및 지원에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `어린이집 안전공제회`,
      type: "지정기부금",
      content: `영유아보육법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국재향소방동우회`,
      type: "지정기부금",
      content: `대한민국재향소방동우회법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `유네스코한국위원회`,
      type: "지정기부금",
      content: `유네스코 활동에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국건강증진개발원`,
      type: "지정기부금",
      content: `국민건강증진법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `재단법인중앙입양원`,
      type: "지정기부금",
      content: `입양특례법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `창조경제혁신센터`,
      type: "지정기부금",
      content: `창조경제 민관협의회 등의 설치 및 운영에 관한 규정\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `협력중소기업의 사내근로보지기금`,
      type: "지정기부금",
      content: `조세특례제한법 제8조의3제1항제1호\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `공동근로복지기금`,
      type: "지정기부금",
      content: `근로복지기본법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국언론진흥재단`,
      type: "지정기부금",
      content: `신문 등의 진흥에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `공간정보산업진흥원`,
      type: "지정기부금",
      content: `공간정보산업진흥법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `대한민국재향교정동우회`,
      type: "지정기부금",
      content: `대한민국재향교정동우회법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `서민금융진흥원`,
      type: "지정기부금",
      content: `서민의 금융생활 지원에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국산림복지진흥원`,
      type: "지정기부금",
      content: `산림복지 진흥에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `신용회복위원회`,
      type: "지정기부금",
      content: `서민의 금융생활 지원에 관한 법률\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `한국건강가정진흥원`,
      type: "지정기부금",
      content: `건강가정기본법\n 기획재정부 고시(지정기부금단체등의 범위,(구 법인세법 시행규칙 별표6의2))`,
      basedLaw: `(구)법령 36 ① 아 \n 법칙 별표 6의2`,
      basedLawCode: "EDA",
      code: "40",
    },
    {
      group: `유치원의 장, 초등학교, 중등학교, 고등학교, 특수학교, 각종학교의 장, 대학교의 장, 기능대학의 장 \n
전공대학 형태의 평생교육시설, 원격대학 형태의 평생교육시설의 장이 추천하는 개인에게 교육비·연구비 또는 장학금으로지출하는 기부금 \n
(고유목적사업비로 지출하는 기부금)`,
      type: "지정기부금",
      content: ``,
      basedLaw: `법령 39 ① 2 가`,
      basedLawCode: "EAB",
      code: "40",
    },
    {
      group: `상속세 및 증여세법 시행령 제14조 각호의 요건을 갖춘 공익신탁으로 신탁하는 기부금`,
      type: "지정기부금",
      content: `공익신탁의 요건\n
1. 공익신탁의 수익자가 제12조에 규정된 공익법인 등이거나 그 공익법인 등의 수혜자일 것 \n
2. 공익신탁의 만기일까지 신탁계약이 중도해지되거나 취소되지 아니할 것\n
3. 공익신탁의 중도해지 또는 종료시 잔여신탁재산이 국가ㆍ지방자치단체 및 다른 공익신탁에 귀속될 것`,
      basedLaw: `법령 39 ① 2 나`,
      basedLawCode: "EEA",
      code: "40",
    },
    {
      group: `지역새마을사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `지역새마을사업을 위하여 지출하는 기부금(18.3.30 삭제) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `불우이웃을 돕기 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `불우이웃을 돕기 위하여 지출하는 기부금(18.3.30삭제) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `보건복지가족부장관이 인정하는 의료취약지역에서 의료사업의 사업비․시설비․운영비로 지출하는 기부금`,
      type: "지정기부금",
      content: `보건복지가족부장관이 인정하는 의료취약지역에서 의료사업의 사업비․시설비․운영비로 지출하는 기부금 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `국민체육진흥기금으로 출연하는 기부금`,
      type: "지정기부금",
      content: `국민체육진흥법 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `전쟁기념사업회`,
      type: "지정기부금",
      content: `전쟁기념관 또는 기념탑의 건립비용으로 지출하는 기부금(전쟁기념사업회법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `중소기업공제사업기금 또는 소기업ㆍ소상공인공제에 출연하는 기부금`,
      type: "지정기부금",
      content: `중소기업협동조합법 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `중소기업중앙회`,
      type: "지정기부금",
      content: `중소기업연수원 및 중소기업제품전시장의 건립비와 운영비로지출하는 기부금(중소기업협동조합법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `중소기업중앙회`,
      type: "지정기부금",
      content: `중소기업글로벌지원센터(중소기업이 공동으로 이용하는 중소기업 지원시설만 해당)의 건립비로 지출하는 기부금(중소기업협동조합법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `중소기업중앙회`,
      type: "지정기부금",
      content: `중소기업의 정보자원(정보 및 설비, 기술, 인력 등 정보화에 필요한 자원) 도입을 무상으로 지원하기 위한 사업비로 지출하는 기부금(중소기업협동조합법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `산림조합중앙회`,
      type: "지정기부금",
      content: `산림자원조성기금으로 출연하는 기부금(산림조합법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `근로자복지진흥기금 운용 단체`,
      type: "지정기부금",
      content: `근로자복지진흥기금으로 출연하는 기부금(근로자복지기본법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `발명진흥기금 운영 단체`,
      type: "지정기부금",
      content: `발명진흥기금으로 출연하는 기부금(발명진흥법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `과학기술진흥기금 운용 단체`,
      type: "지정기부금",
      content: `과학기술진흥기금으로 출연하는 기부금(과학기술기본법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `한국여성경제인협회`,
      type: "지정기부금",
      content: `여성경제인박람회 개최비 또는 연수원 및 여성기업종합지원센터의 건립비로 지출하는 기부금(여성기업지원에 관한 법률) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `종교방송을 하는 방송법인`,
      type: "지정기부금",
      content: `방송을 위한 건물(방송에 직접 사용되는 부분에 한한다)의 신축비로 지출하는 기부금(방송법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `범죄예방자원봉사위원지역협의회 및 그 전국연합회`,
      type: "지정기부금",
      content: `청소년 선도보호와 범법자 재범방지활동을 위하여 지출하는 기부금(보호관찰 등에 관한 법률)\n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `한국은행 등`,
      type: "지정기부금",
      content: `「한국은행법」에 따른 한국은행, 그 밖의 금융기관이 「금융위원회의 설치 등에 관한 법률」 제46조제2호 및 제3호에 따라 금융감독원에 지출하는 출연금 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `대한체육회 등`,
      type: "지정기부금",
      content: `국제체육대회 또는 세계선수권대회의 경기종목에 속하는 경기와 씨름ㆍ국궁 및 택견의 기능향상을 위하여 지방자치단체나 대한체육회(특별시ㆍ광역시ㆍ도체육회 및 대한체육회 가맹단체를 포함)가 추천하는 자 또는 대한체육회에 운동선수양성ㆍ단체경기비용 등으로 지출하는 기부금 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `국제기능올림픽대회한국위원회`,
      type: "지정기부금",
      content: `국제기능올림픽대회에 참가할 선수의 파견비용으로 지출하는 기부금\n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `한국정보화진흥원`,
      type: "지정기부금",
      content: `정보통신기기 및 소프트웨어로 기부하는 것(국가정보화 기본법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `근로자직업능력 개발법 시행령 제2조에 따른 공공단체`,
      type: "지정기부금",
      content: `근로자훈련사업비로 지출하는 기부금 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `한국산업인력공단`,
      type: "지정기부금",
      content: `숙련기술장려적립금으로 출연하는 기부금(숙련기술장려법 제6조)\n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `중앙자활센터 및 지역자활센터`,
      type: "지정기부금",
      content: `국민기초생활보장법 제15조의2 제1항 및 제16조 제1항 \n
제15조 의2【중앙자활센터】① 수급자 및 차상위자의 자활촉진에 필요한 다음 각 호의 사업을 수행하기 위하여 중앙자활센터를 둘 수 있다. <개정 2008.2.29> \n
1. 자활지원을 위한 조사·연구·교육 및 홍보 사업 \n
2. 자활지원을 위한 사업의 개발 및 평가 \n
3. 제15조의3에 따른 광역자활센터, 제16조에 따른 지역자활센터 및 제18조에 따른 자활기업의 기술·경영 지도 및 평가 \n
4. 자활 관련 기관간의 협력체계 및 정보네트워크 구축·운영 \n
5. 취업,창업을 위한 자활촉진 프로그램 개발 및 지원 \n
6. 제18조의2제2항 및 제3항에 따른 고용지원서비스 및 사회복지서비스의 대상자 관리 \n
7. 그 밖에 자활촉진에 필요한 사업으로서 보건복지가족부장관이 정하는 사업 \n
제16조 【지역자활센터 등 <개정 2006.12.28>】① 보장기관은 수급자 및 차상위자의 자활의 촉진에 필요한 다음 각호의 사업을 수행하게 하기 위하여 사회복지법인등 비영리법인과 단체(이하 "법인등"이라 한다. 이하 이 조에서 같다)를 법인등의 신청을 받아 지역자활센터로 지정할 수 있다. 이 경우 보장기관은 법인등의 지역사회복지사업 및 자활지원사업의 수행능력·경험등을 고려하여야 한다. <개정 2006.12.28> \n
1. 자활의욕 고취를 위한 교육 \n
2. 자활을 위한 정보제공·상담·직업교육 및 취업알선 \n
3. 생업을 위한 자금융자 알선 \n
4. 자영창업 지원 및 기술·경영지도 \n
5. 자활공동체의 설립·운영지원 \n
6. 기타 자활을 위한 각종 사업 \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `교통안전공단`,
      type: "지정기부금",
      content: `자동차손해배상보장사업비로 지출하는 기부금(교통안전공단법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `사단법인 한국중화총상회`,
      type: "지정기부금",
      content: `국내에서 개최되는 세계화상대회 개최비로 지출하는 기부금기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `사회적기업 육성법에 따른 사회적기업(비영리법인으로 한정)의 사회서비스 또는 일자리 제자리를 제공하는 사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `사회적기업 육성법\n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `사단법인 한국농수식품씨이오연합회`,
      type: "지정기부금",
      content: `농어업경영체 육성 및 지원에 관한 법률에 따른 농어업경영체에 대한 교육사업을 위하여 지출하는 기부금\n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `대한소방공제회`,
      type: "지정기부금",
      content: `직무수행 중 순직한 소방공무원의 유가족 또는 상이를 입은 소방공무원의 지원을 위하여 지출하는 기부금(대한소방공제회법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `한국장애경제인협회`,
      type: "지정기부금",
      content: `장애경제인에 대한 교육훈련비, 장애경제인 창업지원사업비, 장애경제인협회 회관ㆍ연수원 건립비, 장애경제인대회 개최비 및 장애인기업종합지원센터의 설치ㆍ운영비로 지출하는 기부금(장애인기업활동 촉진법) \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `대한민국헌정회`,
      type: "지정기부금",
      content: `정책연구비 및 헌정기념에 관한 사업비로 지출하는 기부금(대한민국헌정회 육성법) \n
기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `사단법인 한국회계기준원`,
      type: "지정기부금",
      content: `국제회계기준위원회재단 재정지원을 위하여 지출하는 기부금 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「법인세법 시행령」 제2조제1항제11호에 따른 사업을 수행하고 있는 비영리법인`,
      type: "지정기부금",
      content: `저소득층의 생활 안정 및 복지 향상을 위한 신용대출사업을 위한 비용으로 지출하는 기부금\n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `건설근로자공제회`,
      type: "지정기부금",
      content: `건설근로자의 복지증진 사업을 위하여 지출하는 기부금(건설근로자의 고용개선 등에 관한 법률) \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「문화예술진흥법」제7조에 따른 전문예술단체에 문화예술진흥사업 및 활동을 지원하기 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `문화예술진흥법 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「중소기업진흥 및 제품구매촉진에 관한 법률」에 의한 중소기업진흥공단에 같은 법 제74조제1항제20호에 따른 사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `중소기업진흥 및 제품구매촉진에 관한 법률 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「여신전문금융업법」 제62조에 따른 여신전문금융업협회에 금융사고를 예방하기 위하여 같은 법 시행령 제6조의13제1항에 따른 영세한 중소신용카드가맹점의 신용카드 단말기 교체를 지원하기 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `여신전문금융업법 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「정보통신기반 보호법」 제16조에 따른 정보공유·분석센터에 금융 분야의 주요 정보통신기반시설에 대한 침해사고 예방, 취약점의 분석·평가 등 정보통신기반시설 보호 사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `정보통신기반 보호법 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「상공회의소법」에 의한 대한상공회의소에 「근로자직업능력 개발법」에 따른 직업능력개발사업 및 「유통산업발전법」 제2조제1호에 따른 유통산업 지원 사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `근로자직업능력 개발법 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「보험업법」 제175조에 따른 보험협회에 생명보험 사회공헌사업 추진을 위한 협약에 따라 사회공헌기금 등을 통하여 수행하는 사회공헌사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `보험업법 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「노동조합 및 노동관계조정법」 제10조제2항에 따른 총연합단체인 노동조합이 시행하는 노사상생협력증진에 관한 교육·상담 사업, 그 밖에 선진 노사문화 정착과 노사 공동의 이익증진을 위한 사업으로서 고용노동부장관이 정하는 사업을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `노동조합 및 노동관계조정법 \n 기획재정부 고시 지정기부금의 범위(법인세법 시행령 제39조 제1항 제2호 다목)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `해외난민을 위하여 지출하는 기부금`,
      type: "지정기부금",
      content: `해외난민을 위하여 지출하는 기부금)`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `「법인세법」제24조 제3항 제5호 각 목의 병원에 자선의료비로 지출하는 기부금`,
      type: "지정기부금",
      content: `「법인세법」제24조 제3항 제5호 각 목의 병원에 자선의료비로 지출하는 기부금`,
      basedLaw: `법령 39 ① 2 다`,
      basedLawCode: "EDB",
      code: "40",
    },
    {
      group: `영업자가 조직한 단체로서 법인이거나 주무관청에 등록된 조합 또는 협회`,
      type: "지정기부금",
      content: `법인세법 시행령 제19조제11호의 규정에 의한 회비 중 특별회비와 동호의 규정에 의한 조합 또는 협회외의 임의로 조직된 조합 또는 협회에 지급한 회비
(18.2.13이전)`,
      basedLaw: `(구) 법령 36 ① 3`,
      basedLawCode: "EEC",
      code: "40",
    },
    {
      group: `아동복지시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `아동복지법 제16조제1항`,
      basedLaw: `법령 39 ① 4 가`,
      basedLawCode: "EFA",
      code: "40",
    },
    {
      group: `노인복지시설(무료) \n- 무료이용 노인여가복지시설(경로당, 노인교실, 노인휴양소)에 대한 기부금을 지정기부금으로 인정(2012.1.1. 이후 지출분부터)`,
      type: "지정기부금",
      content: `노인복지법 제31조에 따른 노인복지시설 (다음 단체는 제외) \n
1) 노인복지법 제32조 제1항에 따른 노인주거복지시설 중 입소자 본인이 입소비용의 전부를 부담하는 양로시설ㆍ노인공동생활가정 및 노인복지주택 \n
2) 노인복지법 제32조 제1항에 따른 노인의료복지시설 중 입소자 본인이 입소비용의 전부를 부담하는 노인요양시설ㆍ노인요양공동생활가정 및 노인전문병원 \n
3) 노인복지법 제38조에 따른 재가노인복지시설 중 이용자 본인이 재가복지서비스에 대한 이용대가를 전부 부담하는 시설`,
      basedLaw: `법령 39 ① 4 나`,
      basedLawCode: "EFB",
      code: "40",
    },
    {
      group: `장애인복지시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「장애인복지법」 제58조제1항\n
다음 시설은 제외\n
1) 비영리법인(「사회복지사업법」제16조제1항에 따라 설립된 사회복지법인을 포함한다) 외의 자가 운영하는 장애인 공동생활가정 \n
2) 장애인복지법 시행령 제36조에 따른 장애인생산품 판매시설 \n
3) 장애인 유료복지시설`,
      basedLaw: `법령 39 ① 4 다`,
      basedLawCode: "EFC",
      code: "40",
    },
    {
      group: `한부모가족복지시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「한부모가족지원법」제19조제1항`,
      basedLaw: `법령 39 ① 4 라`,
      basedLawCode: "EFD",
      code: "40",
    },
    {
      group: `정신질환자사회복귀시설 및 정신요양시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「정신보건법」제3조제4호 및 제5호`,
      basedLaw: `법령 39 ① 4 마`,
      basedLawCode: "EFE",
      code: "40",
    },
    {
      group: `성매매방지 지원시설 및 성매매피해상담소(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「성매매방지 및 피해자보호 등에 관한 법률」제6조제2항 및 제10조제2항`,
      basedLaw: `법령 39 ① 4 바`,
      basedLawCode: "EFF",
      code: "40",
    },
    {
      group: `가정폭력 관련 상담소 및 보호시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「가정폭력방지 및 피해자보호 등에 관한 법률」제5조제2항 및 제7조제2항`,
      basedLaw: `법령 39 ① 4 사`,
      basedLawCode: "EFG",
      code: "40",
    },
    {
      group: `성폭력피해상담소 및 성폭력피해자보호시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「성폭력방지 및 피해자보호 등에 관한 법률」 제10조제2항 및 제12조제2항`,
      basedLaw: `법령 39 ① 4 아`,
      basedLawCode: "EFH",
      code: "40",
    },
    {
      group: `사회복지시설 중 사회복지관과 부랑인ㆍ노숙인 시설(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「사회복지사업법」제34조`,
      basedLaw: `법령 39 ① 4 자`,
      basedLawCode: "EFI",
      code: "40",
    },
    {
      group: `재가장기요양기관(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `「노인장기요양보험법」제32조 `,
      basedLaw: `법령 39 ① 4 차`,
      basedLawCode: "EFJ",
      code: "40",
    },
    {
      group: `다문화가족지원센터(무료 또는 실비로 이용할 수 있는 시설 또는 기관)`,
      type: "지정기부금",
      content: `다문화가족지원법`,
      basedLaw: `법령 39 ① 4 카`,
      basedLawCode: "EFK",
      code: "40",
    },
    {
      group: `비영리외국법인으로서 주무관청의 추천을 받아 기획재정부장관이 지정하는 비영리외국법인(해외지정기부금단체)`,
      type: "지정기부금",
      content: `가. 비영리외국법인으로서 「재외동포의 출입국과 법적 지위에 관한 법률」 제2조에 따른 재외동포의 협력ㆍ지원, 한국의 홍보 또는 국제교류ㆍ협력을 목적으로 하는 법인일 것 \n
나. 다음의 요건을 모두 충족할 것 \n
  (1) 수입을 회원의 이익이 아닌 공익을 위하여 사용하고 사업의 직접 수혜자가 불특정 다수일 것  \n
  (2) 해산시 잔여재산을 국가ㆍ지방자치단체 또는 유사한 목적을 가진 다른 비영리법인에게 귀속하도록 할 것 \n
  (3) 인터넷 홈페이지가 개설되어 있고, 인터넷 홈페이지를 통하여 연간 기부금 모금액 및 활용실적을 공개한다는 내용이 정관에 포함되어 있을 것  \n
  (4) 해당 비영리법인의 명의 또는 그 대표자의 명의로 특정 정당 또는 특정인에 대한 공직선거법 제58조 제1항에 따른 선거운동을 하지 아니할 것 \n
  (5) 지정이 취소된 경우에는 그 취소된 날부터 3년, 재지정을 받지 못하게 된 경우에는 그 지정기간의 종료일부터 3년이 지났을 것 \n 
  (6)매년 기부금 모금액 및 활용실적을 다음 연도 3월31일가지 해당 해외지정기부금단체와 국세청의 인터넷 홈페이지에 각각 공개할 것. 이 경우 국세청의 인터넷 홈페이지에는 기획재정부령이 정하는 기부금 모금액 및 활용실적 명세서에 따라 공개하여야 한다. \n
  (7) 각 사업연도의 수입사업의 지출을 제외한 지출액의 100분의 80 이상을 직접 고유목적사업에 지출할 것(18.2.13 이전 지정한 비영리외국법인)`,
      basedLaw: `(구) 법령 36 ① 5`,
      basedLawCode: "EGA",
      code: "40",
    },

    {
      group: `국제기구로서 기획재정부령으로 정하는 국제기구\n
- 유엔난민기구(UNHCR)\n
- 세계식량계획(WFP)\n
- 국제이주기구(IOM)\n
- 글로벌녹색성장연구소(GGGI) \n
- 녹색기후기금(Green Climate Fund, GCF)`,
      type: "지정기부금",
      content: `가. 사회복지, 문화, 예술, 교육, 종교, 자선, 학술 등 공익을 위한 사업 수행할 것 \n
나. 우리나라가 회원국으로 가입하였을 것 \n
기획재정부 고시 : 국제기구의 범위(법인세법 시행령 제39조 제1항 제6호)`,
      basedLaw: `법령 39 ① 6`,
      basedLawCode: "EGB",
      code: "40",
    },
    {
      group: `노동조합`,
      type: "지정기부금",
      content: `노동조합비(노동조합및노동관계조정법 또는 교원의노동조합설립및운영등에관한법률)에 따라 설립된 노동조합에 가입한 사람이 납부한 회비`,
      basedLaw: `소령 80 ① 2 가`,
      basedLawCode: "EHA",
      code: "40",
    },
    {
      group: `교원단체`,
      type: "지정기부금",
      content: `교원단체납부회비(교육기본법 제15조)`,
      basedLaw: `소령 80 ① 2 나`,
      basedLawCode: "EHA",
      code: "40",
    },
    {
      group: `공무원직장협의회`,
      type: "지정기부금",
      content: `공무원직장협의회 회비(공무원직장협의회의 설립․운영에 관한 법률)`,
      basedLaw: `소령 80 ① 2 다`,
      basedLawCode: "EHA",
      code: "40",
    },
    {
      group: `공무원노동조합`,
      type: "지정기부금",
      content: `노동조합비(공무원의 노동조합 설립 및 운영 등에 관한 법률)`,
      basedLaw: `소령 80 ① 2 라`,
      basedLawCode: "EHA",
      code: "40",
    },
    {
      group: `위탁자의 신탁재산이 위탁자의 사망 또는 약정한 신탁계약 기간의 종료로 인하여 「상속세 및 증여세법」 제16조 제1항에 따른 공익법인등에 기부될 것을 조건으로 거주자가 설정한 신탁으로서 신탁계약 체결 이후 계약을 해지하거나 원금 일부를 반환할 수 없는 등 일정 요건을 갖춘 신탁에 신탁한 금액 `,
      type: "지정기부금",
      content: `공익법인 신탁의 요건 \n
1. 위탁자가 사망하거나 약정한 신탁계약기간이 위탁자의 사망 전에 종료하는 경우 신탁재산이 「상속세 및 증여세법」 제16조 제1항에 따른 공익법인등에 기부될 것을 조건으로 거주자가 설정할 것 \n
2. 신탁설정 후에는 계약을 해지하거나 원금 일부를 반환할 수 없음을 약관에 명시할 것\n
3. 위탁자와 제1호의 공익법인등 사이에 「국세기본법 시행령」 제20조 제13호에 해당하는 특수관계가 없을 것 \n
4. 금전으로 신탁할 것`,
      basedLaw: `소령 80 ① 3 `,
      basedLawCode: "EHB",
      code: "40",
    },
  ],
};

export default DonationBillData;
