import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import { SelectForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import ChargeBillingOneBillForm from "./ChargeBillingOneBillForm";
import ChargeCmsBillForm from "./ChargeCmsBillForm";
import ChargeReceiptForm from "./ChargeReceiptForm";

const cmsAskResultObject = {
  UNASKED: "미청구",
  PAID: "완납",
  UNPAID: "미납",
  LOSS: "대손",
};

const transactionMethodObject = {
  CMS: "CMS",
  VIRTUAL_ACCOUNT: "가상계좌",
  TRANSFER: "무통장입금",
  PG: "PG",
  GIRO: "지로",
};

const Charge = ({ loginStore }) => {
  /*
   * @desc     시작조회연월 이번달 기준 6개월 전으로 생성하는 함수
   */
  const fnSetPrevSixMonth = (dateGubun) => {
    let date = new Date();
    let monthOfYear = date.getMonth();
    date.setMonth(monthOfYear - 6);

    return date;
  };

  const [searchStartY4, setSearchStartY4] = useState(fnSetPrevSixMonth().getFullYear());
  const [searchStartMm, setSearchStartMm] = useState(fnSetPrevSixMonth().getMonth() < 9 ? `0${fnSetPrevSixMonth().getMonth() + 1}` : fnSetPrevSixMonth().getMonth() + 1);
  const [searchEndY4, setSearchEndY4] = useState(new Date().getFullYear());
  const [searchEndMm, setSearchEndMm] = useState(new Date().getMonth() < 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1);
  const [pagination, setPagination] = useState({ rowsPerPage: 5, offset: 0, total: 0, totalPages: 1 });
  const [selectedRows, setSelectedRows] = useState({});
  const [content, setContent] = useState([]);
  const [searchRequest, setSearchRequest] = useState({
    searchStartY4mm: `${fnSetPrevSixMonth().getFullYear()}` + (fnSetPrevSixMonth().getMonth() < 9 ? `0${fnSetPrevSixMonth().getMonth() + 1}` : fnSetPrevSixMonth().getMonth() + 1),
    searchEndY4mm: CM.cfnConvertDateToString(new Date()).substr(0, 6),
    pageNumber: 0,
    pageSize: 5,
    sortProperty: "useY4mm",
    sortDirection: "DESC",
  });
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [receiptType, setReceiptType] = useState("");
  const [billingoneBillOpen, setBillingoneBillOpen] = useState(false);
  const [cmsBillOpen, setCmsBillOpen] = useState(false);
  const [searchStart, setSearchStart] = useState(true);

  const handleSelectBox = (e) => {
    let searchStartY4mm = `${e.target.name === "searchStartY4" ? e.target.value : searchStartY4}${e.target.name === "searchStartMm" ? e.target.value : searchStartMm}`;
    let searchEndY4mm = `${e.target.name === "searchEndY4" ? e.target.value : searchEndY4}${e.target.name === "searchEndMm" ? e.target.value : searchEndMm}`;

    if (searchStartY4mm > searchEndY4mm) {
      if (e.target.name === "searchStartY4" || e.target.name === "searchStartMm") {
        // 시작월에 맞추기
        searchEndY4mm = searchStartY4mm;
      } else {
        // 종료월에 맞추기
        searchStartY4mm = searchEndY4mm;
      }
    }

    switch (e.target.name) {
      case "searchStartY4":
        setSearchStartY4(searchStartY4mm.substring(0, 4));
        break;
      case "searchStartMm":
        setSearchStartMm(searchStartY4mm.substring(4));
        break;
      case "searchEndY4":
        setSearchEndY4(searchEndY4mm.substring(0, 4));
        break;
      case "searchEndMm":
        setSearchEndMm(searchEndY4mm.substring(4));
        break;
      default:
        break;
    }
    setSearchRequest({ ...searchRequest, searchStartY4mm, searchEndY4mm });
  };

  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    setSearchStart(true);
  };

  const handleRowPerPageChange = (e) => {
    setSearchRequest({
      ...searchRequest,
      pageSize: e.target.value,
      pageNumber: 0,
    });
    setSearchStart(true);
  };

  const handleOffsetChange = (offset, page) => {
    setSearchRequest({ ...searchRequest, pageNumber: page - 1 });
    setSearchStart(true);
  };

  /*
   * @desc     지급기간 일자 옵션 생성하는 함수
   */
  const fnCreateMonthOption = (year) => {
    const arrDate = [];
    let endMonth = 12;

    if (year.toString() === new Date().getFullYear().toString()) {
      endMonth = new Date().getMonth() + 1;
    }

    for (let i = 1; i < endMonth + 1; i++) {
      arrDate.push({
        label: `${i}월`,
        value: i < 10 ? `0${i}` : i,
      });
    }

    return arrDate;
  };

  /*
   * @desc     지급기간 년도 옵션 생성하는 함수
   */
  const fnCreateYearOption = () => {
    const arrYear = [];
    let endYear = new Date().getFullYear();
    for (; endYear >= 2010; endYear--) {
      arrYear.push({
        label: `${endYear}년`,
        value: endYear,
      });
    }

    return arrYear;
  };

  const fnSearch = () => {
    const url = "/api/institute/billing";
    let queryString = [];
    let searchStartY4mm = searchRequest.searchStartY4mm;
    let searchEndY4mm = searchRequest.searchEndY4mm;

    let todayY4mm = CM.cfnConvertDateToString(new Date()).substr(0, 6);

    if (searchEndY4mm > todayY4mm) {
      CM.cfnAlert("조회 종료년월이 현재보다 미래의 날짜일 수 없습니다.");
      setSearchStart(false);
      return false;
    }

    if (CM.cfnAddDate(searchStartY4mm, searchEndY4mm, 10)) {
      CM.cfnAlert("조회 종료년월은 조회 시작년월로부터 10년 이내만 가능합니다.");
      setSearchStart(false);
      return false;
    }

    Object.keys(searchRequest).forEach((key) => {
      queryString.push(key + "=" + searchRequest[key]);
    });

    const urlParameter = url + "?" + queryString.join("&");
    CM.cfnAxios(
      urlParameter,
      "get",
      "",
      (status, dataList) => {
        if (status.status === 200) {
          setPagination({ rowsPerPage: dataList.pageable.pageSize, offset: dataList.pageable.offset, totalPages: dataList.totalPages, total: dataList.totalElements });
          setContent(dataList.content);
        }
        setSearchStart(false);
      },
      () => {
        setSearchStart(false);
      }
    );
  };

  const printCharge = (idx, type) => {
    setSelectedRows(content[idx]);
    switch (type) {
      case "billingoneBill":
        setBillingoneBillOpen(true);
        break;
      case "billingoneReceipt":
        setReceiptType("billingone");
        setReceiptOpen(true);
        break;
      case "cmsBill":
        setCmsBillOpen(true);
        break;
      case "cmsReceipt":
        setReceiptType("cms");
        setReceiptOpen(true);
        break;
      default:
        break;
    }
  };

  const taxBillPrint = (idx, type) => {
    let dataObject = content[idx];
    let nameObject = {
      B: {
        InstCode: loginStore.loginDto.billingoneInstCode,
        PlusTaxbill: "wayToPublishBillingonePlusTaxbill",
        AskingAmount: "billingonePlusTotalAskingAmount",
        TaxbillPublicationNo: "billingonePlusTaxbillPublicationNo",
      },
      C: {
        InstCode: loginStore.loginDto.cmsInstCode,
        PlusTaxbill: "wayToPublishCmsTaxbill",
        AskingAmount: "cmsTotalAskingAmount",
        TaxbillPublicationNo: "cmsTaxbillPublicationNo",
      },
    };
    //wayToPublishBillingonePlusTaxbill,
    //wayToPublishCmsTaxbill0(전자세금계산서)
    //billingonePlusTaxbillPublicationNo
    //cmsTaxbillPublicationNo
    //
    if (dataObject[nameObject[type].AskingAmount] === 0) {
      CM.cfnAlert("청구금액이 있는 세금계산서를 선택하여 주시기 바랍니다.");
      return false;
    }

    if (dataObject.useY4mm < "200403") {
      CM.cfnAlert("2004년 3월 부터 세금계산서 발행이 가능합니다.");
      return false;
    }

    CM.cfTrsbTaxAccess("U", dataObject[nameObject[type].TaxbillPublicationNo], dataObject.useY4mm, type, nameObject[type].InstCode);
  };

  useEffect(() => {
    if (searchStart) {
      fnSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStart]);

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>빌링원플러스 이용요금은 매월 15일(휴일인 경우 다음 영업일)에 출금되며, 미출금 시 20일, 25일에 재출금됩니다.</li>
          <li>
            이용요금 납부계좌는{" "}
            <Link to="/institute/business" className="link">
              기관&middot;업무정보 &gt; 기본정보 &gt; 업무정보 &gt; 이용요금 납부계좌
            </Link>
            에서 변경할 수 있습니다.
          </li>
          <li>전자세금계산서의 정보가 변경되는 경우 고객센터(1577-5500)로 문의하시기 바랍니다.</li>
        </ul>
      </div>
      <div className="search-area">
        <div className="block">
          <label className="label-l">조회대상 이용연월</label>
          <SelectForm value={searchStartY4} handleChange={handleSelectBox} name="searchStartY4" arrayOption={fnCreateYearOption()} optionValue="value" optionLabel="label" />
          <SelectForm value={searchStartMm} handleChange={handleSelectBox} name="searchStartMm" arrayOption={fnCreateMonthOption(searchStartY4)} optionValue={"value"} optionLabel={"label"} />
          <span className="between">~</span>
          <SelectForm value={searchEndY4} handleChange={handleSelectBox} name="searchEndY4" arrayOption={fnCreateYearOption()} optionValue="value" optionLabel="label" />
          <SelectForm value={searchEndMm} handleChange={handleSelectBox} name="searchEndMm" arrayOption={fnCreateMonthOption(searchEndY4)} optionValue={"value"} optionLabel={"label"} />
          <button className="search-button" data-testid="support-notice-button-search" onClick={() => setSearchStart(true)}>
            검색
          </button>
        </div>
      </div>
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} customProps={{ inputProps: { "data-testid": "support-notice-rowPerPage-select" } }} />
      </div>
      <Table>
        {CM.cfnCompColGroup(["90px", "110px", "90px", "90px", "90px", "90px", "110px", "100px", "200px"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          arrData={[
            { id: "useY4mm", label: "이용연월", sortable: true },
            { id: "chargeType", label: "서비스 종류", sortable: false },
            { id: "totalAmount", label: "이용요금", sortable: false },
            { id: "totalAskingAmount", label: "청구금액", sortable: false },
            { id: "askResult", label: "납부여부", sortable: false },
            { id: "unpaidAmount", label: "미납요금", sortable: false },
            { id: "lastPaidDate", label: "납부일자", sortable: false },
            { id: "transactionMethod", label: "납부방법", sortable: false },
            { id: "chargeButtonList", label: "청구서 등", sortable: false },
          ]}
          searchRequest={searchRequest}
          handleSortProperty={handleSortProperty}
          tableSortLabelDataTestId={"support-charge-head-sortLabel"}
        />
        <TableBody>
          {content.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            content.map((data, index) => {
              return (
                <Fragment key={index}>
                  <TableRow className="show-detail">
                    <TableCell align="center" data-testid="support-charge-useY4mm" rowSpan={2}>
                      {CM.cfnDateFormat(data.useY4mm, "yyyyMM")}
                    </TableCell>
                    <TableCell align="center">빌링원플러스</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(data.billingonePlusTotalAmount)}원</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(data.billingonePlusTotalAskingAmount)}원</TableCell>
                    <TableCell align="center">{!data.billingonePlusTotalAmount ? "미발생" : cmsAskResultObject[data.billingonePlusAskResult]}</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(data.billingonePlusUnpaidAmount)}원</TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(data.billingonePlusLastPaidDate, "yyyyMMdd") || "-"}</TableCell>
                    <TableCell align="center">{transactionMethodObject[data.billingonePlusTransactionMethod] || "-"}</TableCell>
                    <TableCell align="center">
                      <button className="btn-s" onClick={() => printCharge(index, "billingoneBill")} data-testid="test-charge-billing-bill-btn">
                        청구서
                      </button>
                      {data.billingonePlusAskResult === "PAID" ? (
                        <button className="btn-s" onClick={() => printCharge(index, "billingoneReceipt")} data-testid="test-charge-billing-receipt-btn">
                          영수증
                        </button>
                      ) : null}
                      {data.wayToPublishBillingonePlusTaxbill === "0" ? (
                        <button className="btn-s" onClick={() => taxBillPrint(index, "B")} data-testid="test-charge-taxBill-btn">
                          세금계산서
                        </button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow className="show-detail">
                    <TableCell align="center">CMS</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(data.cmsTotalAmount)}원</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(data.cmsTotalAskingAmount)}원</TableCell>
                    <TableCell align="center">{!data.cmsTotalAmount ? "미발생" : cmsAskResultObject[data.cmsAskResult]}</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(data.cmsUnpaidAmount)}원</TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(data.cmsLastPaidDate, "yyyyMMdd") || "-"}</TableCell>
                    <TableCell align="center">{transactionMethodObject[data.cmsTransactionMethod] || "-"}</TableCell>
                    <TableCell align="center">
                      <button className="btn-s" onClick={() => printCharge(index, "cmsBill")} data-testid="test-charge-bill-btn">
                        청구서
                      </button>
                      {data.cmsAskResult === "PAID" ? (
                        <button className="btn-s" onClick={() => printCharge(index, "cmsReceipt")} data-testid="test-charge-receipt-btn">
                          영수증
                        </button>
                      ) : null}
                      {data.wayToPublishCmsTaxbill === "0" ? (
                        <button className="btn-s" onClick={() => taxBillPrint(index, "C")} data-testid="test-charge-taxBill-btn">
                          세금계산서
                        </button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
      <ChargeBillingOneBillForm open={billingoneBillOpen} setOpen={() => setBillingoneBillOpen(false)} target={selectedRows} />
      <ChargeCmsBillForm open={cmsBillOpen} setOpen={() => setCmsBillOpen(false)} target={selectedRows} />
      <ChargeReceiptForm open={receiptOpen} setOpen={() => setReceiptOpen(false)} target={selectedRows} type={receiptType} />
    </div>
  );
};

export default inject((rootStore) => ({
  loginStore: rootStore.loginStore,
}))(observer(Charge));
