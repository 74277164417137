import * as CM from "../../common/Common";

const PaperTaxBillData = {
  //paging 처리
  paginationData: (data) => {
    const returnObject = {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };

    if (CM.cfnIsNotEmpty(data)) {
      returnObject.rowsPerPage = data.pageable.pageSize;
      returnObject.offset = data.pageable.offset;
      returnObject.total = data.totalElements;
      returnObject.totalPages = data.totalPages;
    }

    return returnObject;
  },
  //종이세금계산서 발행대상 조회 검색 조건
  targetSearchData: () => {
    const today = new Date();
    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    return {
      capitalName: "",
      customerGroupName: "",
      customerName: "",
      fromDate: monthAgo,
      identificationNo: "",
      pageNumber: 0,
      pageSize: 5,
      sortDirection: "DESC",
      sortProperty: "transactionDate",
      taxbillDirection: "RECEIPT",
      taxbillType: "PAPER",
      toDate: today,
      transactionMethod: "",
      term: "customerName",
      keyword: "",
    };
  },
  //종이세금계산서 발행대상 추가 검색조건
  addTargetSearchData: () => ({
    pageNumber: 0,
    pageSize: 5,
    customerGroupName: "",
    customerName: "",
    identificationNo: "",
  }),
  //종이세금계산서 발행내역 검색조건
  historySearchData: () => {
    const today = new Date();
    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    return {
      customerName: "",
      fromDate: monthAgo,
      identificationNo: "",
      pageNumber: 0,
      pageSize: 5,
      sortDirection: "DESC",
      sortProperty: "issueDate",
      taxbillDirection: "RECEIPT",
      taxbillType: "PAPER",
      toDate: today,
      term: "customerName",
      keyword: "",
    };
  },
  //종이세금계산서 종류
  fnConvertTaxBillDirection: (direction) => {
    if (direction === "RECEIPT") {
      return "영수";
    } else if (direction === "INVOICE") {
      return "청구";
    }
  },
  //customer 객체 내 고객기타정보 parsing
  fnGetCustomerDetailInfo: (customer) => {
    let tempObj = {
      contactName: "",
      contactPosition: "",
      representativeName: "",
      business: "",
      sectors: "",
    };

    const detailInfo = customer.customerDetailInfo;
    let parsingCustomerDetailInfo = detailInfo;
    if (typeof detailInfo === "string" && CM.cfnIsNotEmpty(detailInfo)) {
      parsingCustomerDetailInfo = JSON.parse(detailInfo);
    }

    if (Object.isExtensible(parsingCustomerDetailInfo)) {
      tempObj.contactName = parsingCustomerDetailInfo.담당자명 || "";
      tempObj.contactPosition = parsingCustomerDetailInfo.담당자직위 || "";
      tempObj.representativeName = parsingCustomerDetailInfo.대표자명 || "";
      tempObj.business = parsingCustomerDetailInfo.업태 || "";
      tempObj.sectors = parsingCustomerDetailInfo.업종 || "";
    }

    return tempObj;
  },
  //paperBillDetails에 대한 dummy data 생성 함수
  fnCreatePaperTaxBillDetails: (details) => {
    const length = 4 - details.length;
    // const tempArray = details;
    if (length > 0) {
      //총 4행의 공급내역 입력란 중 데이터가 없는 행의 숫자만큼 채워넣음
      for (let i = details.length; i < 4; i++) {
        let tempObj = {
          month: "",
          day: "",
          itemName: "",
          itemSpecification: "",
          itemQuantity: 0,
          itemUnitPrice: 0,
          itemSupplyAmount: 0,
          itemTaxAmount: 0,
          itemRemark: "",
        };

        details.push(tempObj);
      }
    }
    return details;
  },
  //integer 형태의 데이터를 배열로 변환하는 함수
  fnConvertAmountArray: (amount, length) => {
    let tempArray = [];
    const inputAmount = [...amount.toString()]; //입력된 금액을 문자배열로 변환

    //배열 크기 선언
    let index = length;
    //설정된 배열보다 실제 입력된 금액 자릿수가 작을 경우
    if (inputAmount.length < index) {
      const extraIndex = index - inputAmount.length + 1;
      //차이 나는 자릿수만큼 empty string 입력
      for (let i = 0; i < extraIndex; i++) {
        tempArray.push("");
      }
    }
    //반복문 돌면서 입력된 금액을 한자리씩 배열에 채워넣음
    for (let i = 0; i < inputAmount.length; i++) {
      tempArray.push(inputAmount[i]);
    }
    return tempArray;
  },
  //일련번호를 배열로 변환하는 함수
  fnConvertSerialNumber: (serialNumber) => {
    let tempArray = [];

    if (CM.cfnIsNotEmpty(serialNumber)) {
      const inputSerial = [...serialNumber]; //입력된 일련번호를 문자배열로 변환

      let index = 5;
      if (inputSerial.length < index) {
        const extraIndex = index - inputSerial.length + 1;
        //차이 나는 자릿수만큼 empty string 입력
        for (let i = 0; i < extraIndex; i++) {
          tempArray.push("");
        }
      }

      //반복문 돌면서 입력된 일련번호를 한자리씩 배열에 채워넣음
      for (let i = 0; i < inputSerial.length; i++) {
        tempArray.push(inputSerial[i]);
      }
    }

    return tempArray;
  },
};

export default PaperTaxBillData;
