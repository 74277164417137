import React, { useState, useEffect } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import { TabPanel } from "../../template/ComponentForm";

import CashbillTabTargets from "./CashbillTabTargets";
import CashbillTabRequestResult from "./CashbillTabRequestResult";
import CashbillTabIssued from "./CashbillTabIssued";
import { observer } from "mobx-react";
import { inject } from "mobx-react";
import { makeStyles } from "@material-ui/core";
import * as CM from "../../common/Common";

// 부가서비스 > 현금영수증 메인
// 렌더를 처리하는 메인 컴포넌트
const Cashbill = ({ props, store }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabIndexChange = (value) => setTabIndex(value);
  const [instituteInfoState, setInstituteInfoState] = useState(false);

  useEffect(() => {
    const loadInstituteService = async () => {
      const cashbill = await store.axiosCashbillInfo();
      setInstituteInfoState(cashbill && cashbill.serviceUseYn);
      if (!cashbill || !cashbill.serviceUseYn) {
        CM.cfnAlert("현금영수증 서비스를 이용하시려면 기관·업무정보 > 부가서비스 정보 화면에서 신청해주시기 바랍니다.", () => {});
      }
    };
    loadInstituteService();
  }, [store]);
  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  // TODO: 화면 렌더
  return (
    <div name="main-tab" className={instituteInfoState ? "" : style.disabled_div}>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={(event, value) => handleTabIndexChange(value)} indicatorColor="primary" textColor="primary" data-testid="tabs-cashbill">
          <Tab disableRipple value={0} label="현금영수증 직접발행&middot;취소" data-testid="tab-cashbill-targets" />
          <Tab disableRipple value={1} label="발행 요청결과" data-testid="tab-cashbill-request-results" />
          <Tab disableRipple value={2} label="현금영수증 발행내역" data-testid="tab-cashbill-issued" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <CashbillTabTargets tabIndex={tabIndex} setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <CashbillTabRequestResult tabIndex={tabIndex} setTabIndex={setTabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <CashbillTabIssued tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(Cashbill));
