import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { cfnAxios, cfnCompColGroup, cfnDateFormat } from "../../common/Common";
import ExtraServiceInfoTab from "./ExtraServiceInfoTab";
import TableForm from "../../template/TableForm";
import ARSInfoModal from "../../customer/customerModal/ARSInfoModal";
import OpenGiroAndPgInfoModal from "./OpenGiroAndPgInfoModal";

/*
 * @desc  서비스 이용현황 목록
 */
const ServiceList = (props) => {
  return (
    <Table>
      {cfnCompColGroup(["17%", "auto", "13%", "10%", "10%"])}
      <TableForm.compTableHead arrData={["서비스명", "서비스내용", "이용요금", "서비스시작일", "신청/변경"]} />
      <TableBody>
        <TableRow hover>
          <TableCell align="center">자동출금 예약</TableCell>
          <TableCell className="padding-20">미리 지정한 날짜에 출금 신청을 자동으로 진행하도록 예약하는 서비스</TableCell>
          <TableCell align="center">건당 20원</TableCell>
          <TableCell align="center">{getUseYn(props.autoWithdraw, "autoWithdraw")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(0)} data-testid="extraServiceInfo-openModal-autoWithdraw">
              {getUseYn(props.autoWithdraw, "autoWithdraw", true)}
            </div>
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell align="center">ARS 동의자료 대행</TableCell>
          <TableCell className="padding-20">
            ARS를 통해 고객에게 종이 신청서 없이 자동납부 동의자료를 받는 서비스
            <div className="btn-l2 btn-xs" onClick={() => props.handleArsModal(true)} style={{ paddingRight: "5px", margin: "0 4px" }}>
              이용안내
            </div>
          </TableCell>
          <TableCell align="center">
            성공 건당 100원
            <br />
            실패 건당 50원
          </TableCell>
          <TableCell align="center">{getUseYn(props.instituteService, "ARS_EVIDENCE")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(1)} data-testid="extraServiceInfo-openModal-arsEvidence">
              {getUseYn(props.instituteService, "ARS_EVIDENCE", true)}
            </div>
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell align="center">입출금 자동알림</TableCell>
          <TableCell className="padding-20">수납/지급 대상 고객에게 수납/지급 예정금액, 수납/지급 결과 등을 SMS로 통지하는 서비스</TableCell>
          <TableCell align="center">건당 16원</TableCell>
          <TableCell align="center">{getUseYn(props.instituteService, "SMS_TO_CUSTOMER")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(2)} data-testid="extraServiceInfo-openModal-smsToCustomer">
              {getUseYn(props.instituteService, "SMS_TO_CUSTOMER", true)}
            </div>
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell align="center">전자세금계산서 발행</TableCell>
          <TableCell className="padding-20">고객에게 전자세금계산서를 발행하는 서비스</TableCell>
          <TableCell align="center">건당 200원</TableCell>
          <TableCell align="center">{getUseYn(props.instituteService, "TRUSBILL")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(3)} data-testid="extraServiceInfo-openModal-trusbill">
              {getUseYn(props.instituteService, "TRUSBILL", true)}
            </div>
          </TableCell>
        </TableRow>

        <TableRow hover>
          <TableCell align="center">SMS 일정관리</TableCell>
          <TableCell className="padding-20">
            지정한 수납,지급 등 업무처리 일자에 문자메시지를 통해 업무 일정을 통지하여
            <br /> 업무일정을 놓치지 않도록 하는 서비스
          </TableCell>
          <TableCell align="center">무료</TableCell>
          <TableCell align="center">{getUseYn(props.notifyFile, "notifyFile")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(4)} data-testid="extraServiceInfo-openModal-notifyFile">
              {getUseYn(props.notifyFile, "notifyFile", true)}
            </div>
          </TableCell>
        </TableRow>

        <TableRow hover>
          <TableCell align="center">현금영수증 발급</TableCell>
          <TableCell className="padding-20">
            고객에게 현금영수증을 발급하고, 발급 내역을 국세청에 전송하여 이용기관이 편리하게
            <br /> 현금영수증을 사용할 수 있는 서비스
          </TableCell>
          <TableCell align="center">무료</TableCell>
          <TableCell align="center">{getUseYn(props.cashbillInfo, "cashbillInfo")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(5)} data-testid="extraServiceInfo-openModal-cashbillInfo">
              {getUseYn(props.cashbillInfo, "cashbillInfo", true)}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" rowSpan="2" >출금불능분 즉시납부<br/>(계좌/신용카드 수납)</TableCell>
          <TableCell className="padding-20" rowSpan="2">CMS불능분(CMS출금의뢰 내역 중 고객 미납분)에 대하여 납부링크(URL)를 포함한 메세지를 납부고객에 발송하여, 계좌이체 또는 신용카드로 수납하는 서비스
            <div className="btn-l2 btn-xs" onClick={() => props.handleImmediateModal(true)} style={{ paddingRight: "5px", margin: "0 4px" }}>
              이용안내
            </div>
            <br/>*지로(장표지로) 및 신용카드PG 이용신청 선행 필요
            <br/><span style={{color:"blue", fontWeight:"bold"}}>※ 현재 시범실시 이용기관에 한하여 서비스 중입니다.(추후 오픈예정)</span>
          </TableCell>
          <TableCell align="center" rowSpan="2">
            성공 건당 80원
            <br/>
            실패 건당 30원
          </TableCell>
          <TableCell align="center">(지로)<br/>{getUseYn(props.instituteService, "OPEN_GIRO")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(6)} data-testid="extraServiceInfo-openModal-openGiro">
              {getUseYn(props.instituteService, "OPEN_GIRO", true)}
            </div>
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell align="center">(신용카드)<br/>{getUseYn(props.instituteService, "PG_SERVICE")}</TableCell>
          <TableCell align="center">
            <div className="btn-l2" onClick={() => props.handleButtonClick(6)} data-testid="extraServiceInfo-openModal-pgService">
              {getUseYn(props.instituteService, "PG_SERVICE", true)}
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/*
 * @desc  서비스 시작일 및 사용유무 값을 구하는 함수
 * @param hooks: 훅 object
 *        hooksName: 훅 이름
 *        isButton: 서비스 변경에서 사용할 때
 */
const getUseYn = (hooks, hooksName, isButton) => {
  const usingButton = "서비스 변경";
  const unusedButton = "서비스 신청";
  const usedButton = "서비스 해지";
  const using = !isButton ? "사용" : usingButton;
  const unused = !isButton ? "미사용" : unusedButton;

  switch (hooksName) {
    case "notifyFile":
      for (const element of hooks) {
        if (element.useYn) return using;
      }
      return unused;
    case "SMS_TO_CUSTOMER":
    case "ARS_EVIDENCE":
    case "TRUSBILL":
      for (const element of hooks) {
        if (element.serviceType === hooksName && element.serviceUseYn) return !isButton ? cfnDateFormat(element.serviceStartDate) : usingButton;
      }
      return !isButton ? unused : unusedButton;
    case "OPEN_GIRO":
    case "PG_SERVICE":
      for (const element of hooks) {
        if (element.serviceType === hooksName && element.serviceUseYn) return !isButton ? cfnDateFormat(element.serviceStartDate) : usedButton;
      }
      return !isButton ? unused : unusedButton;
    case "autoWithdraw":
      if (hooks.serviceStatus === "NORMAL") return !isButton ? cfnDateFormat(hooks.serviceStartDate) : usingButton;
      return !isButton ? unused : unusedButton;
    case "cashbillInfo":
      if (hooks.serviceUseYn) return using;
      else if (hooks.expectedApplyDate) return isButton ? unused : "신청중";
      return unused;
    default:
      if (hooks.serviceUseYn) return using;
      return unused;
  }
};

// 렌더를 처리하는 메인 컴포넌트
const ExtraServiceInfo = ({ store }) => {
  const [notifyFile, setNotifyFile] = useState([]); // 업무일정 사전 통지 (SMS)
  const [instituteService, setInstituteService] = useState([]); // 출금 결과 알림(SMS), ARS 자동납부 동의자료, 전자세금계산서, 계좌/신용카드
  const [cashbillInfo, setCashbillInfo] = useState({}); // 현금영수증
  const [autoWithdraw, setAutoWithdraw] = useState({}); // 자동 출금
  const [modalOpen, setModalOpen] = useState(false);
  const [selectRow, setSelectRow] = useState(0);
  const [instituteServiceState, setInstituteServiceState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [arsModal, setArsModal] = useState(false);
  const [immediateModal, setImmediateModal] = useState(false);

  useEffect(() => {
    const aixosNotifyFile = () => {
      return new Promise((resolve) => {
        const url = "api/institute/business/notifyFile";
        cfnAxios(url, "get", "", (status, response) => resolve(response));
      });
    };

    const startAxios = async () => {
      const resultNotifyFile = await aixosNotifyFile();
      setNotifyFile(resultNotifyFile);

      const resultInstituteService = await store.axiosInstituteService();
      setInstituteService(resultInstituteService);

      const resultCashbillInfo = await store.axiosCashbillInfo();
      setCashbillInfo(resultCashbillInfo);

      const resultAutoWithdraw = await store.axiosAutoWithdraw();
      setAutoWithdraw(resultAutoWithdraw);

      const resultInstituteServiceState = await store.axiosBusinessInfo();
      setInstituteServiceState(resultInstituteServiceState.cmsService);

      setLoading(false);
    };

    startAxios();
  }, [store]);

  /*
   *@desc   modal open 처리
   */
  const handleButtonClick = (index) => {
    setSelectRow(index);
    setModalOpen(true);
  };

  /*
   * @desc  출금 결과 알림(SMS), ARS 자동납부 동의자료, 전자세금계산서 변경을 처리하는 handler
   */
  const updateInstituteService = (changeData, type) => {
    const cloneData = [...toJS(store.instituteService)];
    const storeData = cloneData.map((item) => (item.serviceType === type ? changeData : item));

    setInstituteService(instituteService.map((item) => (item.serviceType === type ? changeData : item)));
    store.setInstituteService(storeData);
  };

  if (loading) {
    return null;
  }

  return (
    <div>
      <Paper>
        <ServiceList
          notifyFile={notifyFile}
          instituteService={instituteService}
          cashbillInfo={cashbillInfo}
          autoWithdraw={autoWithdraw}
          handleButtonClick={handleButtonClick}
          handleArsModal={setArsModal}
          handleImmediateModal={setImmediateModal}
        />
      </Paper>
      <Modal open={modalOpen}>
        <div className="paper">
          <div className="inner">
            <ExtraServiceInfoTab
              handleModalClose={() => setModalOpen(false)}
              selectRow={selectRow}
              notifyFile={notifyFile}
              instituteService={instituteService}
              cashbillInfo={cashbillInfo}
              autoWithdraw={autoWithdraw}
              updateNotifyFile={(data) => setNotifyFile(data)}
              updateInstituteService={updateInstituteService}
              instituteServiceState={instituteServiceState}
            />
          </div>
        </div>
      </Modal>
      <ARSInfoModal open={arsModal} setOpen={setArsModal} />
      <OpenGiroAndPgInfoModal open={immediateModal} setOpen={setImmediateModal} />
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(ExtraServiceInfo));
