import { Button, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import iconDisk from "../../../styles/images/icon_disk.png";
import iconDoc from "../../../styles/images/icon_doc.png";
import iconHwp from "../../../styles/images/icon_hwp.png";
import iconMp3 from "../../../styles/images/icon_mp3.png";
import iconPdf from "../../../styles/images/icon_pdf.png";
import iconWav from "../../../styles/images/icon_wav.png";
import iconXls from "../../../styles/images/icon_xls.png";
import * as CM from "../../common/Common";
/*
 * @desc  선택된 게시물 상세보기 모달 컴포넌트
 */
const DetailModal = (props) => {
  const { open, selectedContent, handleModal, fnAttachedFileDownload } = props;
  const fnImageSet = (exeCommand) => {
    const iconObject = {
      disk: iconDisk,
      doc: iconDoc,
      hwp: iconHwp,
      mp3: iconMp3,
      pdf: iconPdf,
      wav: iconWav,
      xls: iconXls,
    };

    return CM.cfnIsNotEmpty(exeCommand) ? iconObject.hasOwnProperty(exeCommand) ? <img src={iconObject[exeCommand]} alt="" /> : <img src={iconObject["disk"]} alt="" /> : "";
  };

  return (
    <Modal open={open} data-testid="open-modal">
      <div
        className="paper"
        style={{
          height: "730px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => handleModal(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          {selectedContent.type === "notice" ? <h3>공지사항</h3> : <h3>자료실</h3>}
          <div>
            <Table>
              {CM.cfnCompColGroup(["15%", "auto"])}
              <TableBody>
                {selectedContent.type === "notice" && (
                  <TableRow>
                    <TableCell align="center" className="th">
                      구분
                    </TableCell>
                    <TableCell className="td">{selectedContent.serviceType === "BILLINGONEPLUS" ? "빌링원플러스" : selectedContent.serviceType}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="center" className="th">
                    제목
                  </TableCell>
                  <TableCell className="td">{selectedContent.title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className="th">
                    등록일
                  </TableCell>
                  <TableCell className="td">{CM.cfnDateFormat(selectedContent.createdDatetime, "yyyyMMdd")}</TableCell>
                </TableRow>
                <TableRow style={{ height: "300px", overFlow: "auto" }}>
                  <TableCell align="center" className="th">
                    내용
                  </TableCell>
                  <TableCell className="td" dangerouslySetInnerHTML={{ __html: selectedContent.contents && selectedContent.contents.replace(/(\r\n|\n\r|\r|\n)/g, "<br>") }} />
                </TableRow>
                <TableRow>
                  <TableCell align="center" className="th">
                    첨부파일
                  </TableCell>
                  {CM.cfnIsEmpty(selectedContent.attachedFileName) ? (
                    <TableCell align="center" className="td">
                      {selectedContent.attachedFileName}
                    </TableCell>
                  ) : (
                    <TableCell className="td show-detail" onClick={fnAttachedFileDownload}>
                      {fnImageSet(selectedContent.attachedFileName.split(".")[1])}
                      {selectedContent.attachedFileName.indexOf("/") !== -1
                        ? selectedContent.attachedFileName.split("/")[selectedContent.attachedFileName.split("/").length - 1]
                        : selectedContent.attachedFileName}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/*
 * @desc  공지사항 컴포넌트
 */
const Notice = (props) => {
  const { handleSelectRow } = props;

  const [noticeList, setNoticeList] = useState([]);

  //공지사항 목록 조회
  useEffect(() => {
    /*
     * @desc  공지사항 목록 조회 Request
     */
    const fnFetchNoticeList = () => {
      const url = `api/support/notices?pageNumber=0&pageSize=5&sortProperty=customerName&sortDirection=DESC`;
      CM.cfnAxios(url, "get", "", fnSetNoticeList);
    };

    /*
     * @desc  공지사항 목록 조회 Request Callback
     */
    const fnSetNoticeList = (objStatus, objData) => {
      if (objData.content.length > 0) setNoticeList(objData.content);
    };

    //실행영역
    if (props.tabIndex === 0) fnFetchNoticeList();
  }, [props.tabIndex]);

  return (
    <ul>
      {Object.keys(noticeList).length === 0 ? (
        <li className="noDataBoard">
          <div align="center" colSpan={2}>
            {"표시할 내용이 없습니다."}
          </div>
        </li>
      ) : (
        noticeList.map((row, index) => {
          return (
            <div key={index} onClick={() => handleSelectRow("notice", row)}>
              {Object.keys(noticeList).length - 1 === index ? (
                <li className="noBorderBtm">
                  <div className="text">{row.title}</div>
                  <div className="date">{CM.cfnDateFormat(row.createdDatetime, "yyyyMMdd")}</div>
                </li>
              ) : (
                <li>
                  <div className="text">{row.title}</div>
                  <div className="date">{CM.cfnDateFormat(row.createdDatetime, "yyyyMMdd")}</div>
                </li>
              )}
            </div>
          );
        })
      )}
    </ul>
  );
};

/*
 * @desc  자료실 컴포넌트
 */
const Archive = (props) => {
  const { handleSelectRow } = props;

  const [archiveList, setArchiveList] = useState([]);

  useEffect(() => {
    /*
     * @desc  자료실 목록 조회 Request
     */
    const fnFetchArchiveList = () => {
      const url = `api/support/data?pageNumber=0&pageSize=5&sortProperty=customerName&sortDirection=DESC`;
      CM.cfnAxios(url, "get", "", fnSetArchiveList);
    };

    /*
     * @desc  자료실 목록 조회 Request Callback
     */
    const fnSetArchiveList = (objStatus, objData) => {
      if (objData.content.length > 0) setArchiveList(objData.content);
    };

    //실행영역
    if (props.tabIndex === 1) fnFetchArchiveList();
  }, [props.tabIndex]);

  return (
    <ul>
      {Object.keys(archiveList).length === 0 ? (
        <li className="noDataBoard">
          <div align="center" colSpan={2}>
            {"표시할 내용이 없습니다."}
          </div>
        </li>
      ) : (
        archiveList.map((row, index) => {
          return (
            <div key={index} onClick={() => handleSelectRow("data", row)}>
              {Object.keys(archiveList).length - 1 === index ? (
                <li className="noBorderBtm">
                  <div className="text">{row.title}</div>
                  <div className="date">{CM.cfnDateFormat(row.createdDatetime, "yyyyMMdd")}</div>
                </li>
              ) : (
                <li>
                  <div className="text">{row.title}</div>
                  <div className="date">{CM.cfnDateFormat(row.createdDatetime, "yyyyMMdd")}</div>
                </li>
              )}
            </div>
          );
        })
      )}
    </ul>
  );
};

/*
 * @desc  공지사항/자료실 탭 컴포넌트
 */

// export default function BoardTab() {
const BoardTab = () => {
  //필요 state 선언
  const [tabIndex, setTabIndex] = useState(0); //탭 이동 제어
  const [openModal, setOpenModal] = useState(false); //상세보기 모달창 제어
  const [selectedContent, setSelectedContent] = useState([]); //선택된 게시물 데이터 저장
  let history = useHistory();
  /*
   * @desc  탭 클릭 이벤트 핸들러
   */
  const handleTabClick = (index) => {
    setTabIndex(index);
  };

  /*
   * @desc  모달창 open/close 이벤트 핸들러
   */
  const handleModal = (value) => {
    setOpenModal(value);
  };

  /*
   * @desc  탭 내 게시글 클릭 이벤트 핸들러
   */
  const handleSelectRow = async (type, row) => {
    let tempObj = {};

    for (const [key, value] of Object.entries(row)) {
      tempObj[key] = value;
    }
    tempObj.type = type;

    await setSelectedContent(tempObj);
    handleModal(true);
  };

  /*
   * @desc  첨부파일 클릭 시 다운로드 진행 이벤트 핸들러
   */
  const fnAttachedFileDownload = (type) => {
    const url = `api/support/attachment?articleNo=${selectedContent.articleNo}&boardType=${selectedContent.type === "notice" ? "NOTICE" : "DATA"}`;
    CM.cfnAxiosGeneralFileDownload(url, "get", "", selectedContent["attachedFileName"], (status, data) => {});
  };

  const handleMovePage = (e, url) => {
    history.push(url);
    e.preventDefault();
  };

  return (
    <div className="notice-mate">
      <ul className="tab">
        <li className={`${tabIndex === 0 ? "current" : ""}`} data-tab="tab1" onClick={(e) => handleTabClick(0)} data-testid="board-tab-1">
          공지사항
        </li>
        <li className={`${tabIndex === 1 ? "current" : ""}`} data-tab="tab2" onClick={(e) => handleTabClick(1)} data-testid="board-tab-2">
          자료실
        </li>
      </ul>
      <Link to="" onClick={tabIndex === 0 ? (e) => handleMovePage(e, "/support/notice") : (e) => handleMovePage(e, "/support/pds")}>
        <span className="notice-button btn-s">더보기</span>
      </Link>
      <div id="tab1" className={`notice tabcontent ${tabIndex === 0 ? "current" : ""}`}>
        <Notice tabIndex={tabIndex} handleSelectRow={handleSelectRow} />
      </div>
      <div id="tab2" className={`notice tabcontent ${tabIndex === 1 ? "current" : ""}`}>
        <Archive tabIndex={tabIndex} handleSelectRow={handleSelectRow} />
      </div>
      <DetailModal open={openModal} selectedContent={selectedContent} handleModal={handleModal} fnAttachedFileDownload={fnAttachedFileDownload} />
    </div>
  );
};
export default BoardTab;
