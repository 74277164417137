import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import CustomerData from "../customerBasicInformation/CustomerData";
import RegistrationsAccountRegistrationRequest from "./RegistrationsAccountRegistrationRequest";
import { observer, inject } from "mobx-react";
const defaultValue = {
  searchOn: false,
  requestParams: {
    isClosingAccountIncluded: true, // (요약정보 연계)계좌해지 대상고객 조회(true/false)
    isNewAccountIncluded: true, // (요약정보 연계)신규계좌 대상고객 조회(true/false)
    pageNumber: 0, // 요청 페이지번호
    pageSize: 5, // 페이지당 조회건수
    sortDirection: "ASC", // 정렬방법(ASC or DESC)
    sortProperty: "customerName", // 정렬필드(Response Dto 상의 영문필드명 : customerName, payerNo, depositorName, depositorIdentificationNoFirst7, evidenceFileType)
  },
  summaryInfo: [
    { key: "totalTargets", label: "전체대상 수", value: 0 },
    {
      key: "totalNewAccountRegistrationWaitingTargets",
      label: "등록대상 수",
      value: 0,
    },
    { key: "totalClosingAccountTargets", label: "해지대상 수", value: 0 },
    { key: "registrationExcludedTargets", label: "요청제외 건수", value: 0 },
  ],
  registrationTargets: [],
};

// 고객정보관리 > 직접 접수분 > 등록대기 tab
// 렌더를 처리하는 메인 컴포넌트
const RegistrationsList = (props) => {
  const { tabCountChange, loginStore } = props;
  const [searchOn, setSearchOn] = useState(defaultValue.searchOn); // 검색실행 flag
  const [requestParams, setRequestParams] = useState(defaultValue.requestParams); // 검색조건
  const [summaryInfo, setSummaryInfo] = useState(defaultValue.summaryInfo); // 요약정보
  const [registrationTargets, setRegistrationTargets] = useState([]); // 등록 요청 대상
  const [registrationExcludedTargets, setRegistrationExcludedTargets] = useState([]); // 등록 요청 제외 대상
  const [pagination, setPagination] = useState(CM.cfnPaginationData()); // 페이지네이션

  const handleRequestParamsChange = (param) => setRequestParams(param); // 검색 조건 handler

  const handlePaginationChange = (param) => setPagination(CM.cfnSetPagination(param)); // 페이지네이션 handler

  // 페이지 handler
  const handlePageChange = (e, offset, page) => {
    setRequestParams((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchOn(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = ({ target: { value } }) => {
    handleRequestParamsChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchOn(true);
  };

  // 요약 정보 handler
  const handleSummaryInfoChange = (param) => {
    setSummaryInfo((data) =>
      data.map((element) =>
        element.key === "registrationExcludedTargets"
          ? element
          : element.key === "totalClosingAccountTargets"
          ? { ...element, value: param.totalTargets - param.totalNewAccountRegistrationWaitingTargets }
          : { ...element, value: param[element.key] }
      )
    );
  };

  // 요약 정보를 클릭했을 때 동작
  const handleSummaryInfoClick = (key) => {
    handleRequestParamsChange((data) => ({
      ...data,
      pageNumber: 0,
      isClosingAccountIncluded: key === "totalClosingAccountTargets" || key === "totalTargets",
      isNewAccountIncluded: key === "totalNewAccountRegistrationWaitingTargets" || key === "totalTargets",
    }));
    setSearchOn(true);
  };

  // 등록제외 버튼을 눌렀을 때 동작
  const changeRegistrationExcludedTargets = (content) => {
    const accountUniqueKey = content.accountUniqueKey;

    if (!content.excluded) {
      addRegistrationExcludedTargets(content);
    } else {
      removeRegistrationExcludedTargets(accountUniqueKey);
    }

    setRegistrationTargets((value) => value.map((element) => (element.accountUniqueKey === accountUniqueKey ? { ...element, excluded: !element.excluded } : element)));
  };

  // 등록 요청 제외 대상에 목록 추가
  const addRegistrationExcludedTargets = (content) => {
    const list = [...registrationExcludedTargets];
    list.push(content);
    setRegistrationExcludedTargets(list);
  };

  // 등록 요청 제외 대상의 목록 제거
  const removeRegistrationExcludedTargets = (accountUniqueKey) => {
    const list = [...registrationExcludedTargets];
    setRegistrationExcludedTargets(list.filter((data) => data.accountUniqueKey !== accountUniqueKey));
  };

  // 등록 요청 제외 대상 초기화
  const initialRegistrationExcludedTargets = () => setRegistrationExcludedTargets([]);

  // 요약 정보 등록제외건수 handler
  useEffect(() => {
    const value = registrationExcludedTargets.length;
    setSummaryInfo((data) => data.map((element) => (element.key !== "registrationExcludedTargets" ? element : { ...element, value: value })));
  }, [registrationExcludedTargets]);

  // 등록 요청 대상 handler
  const handleRegistrationTargetsChange = (content) => {
    const list = content.map((data) => ({
      ...data,
      excluded: registrationExcludedTargets.filter((excluded) => excluded.accountUniqueKey === data.accountUniqueKey).length > 0,
    }));

    setRegistrationTargets(list);
  };

  // Axios 실행
  const getAxios = (param) => {
    return new Promise((resolve) => {
      let url = "api/customer/receipt/account/institute/registrations";
      url += `?isClosingAccountIncluded=${param.isClosingAccountIncluded}&isNewAccountIncluded=${param.isNewAccountIncluded}`;
      url += `&pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
      url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}`;

      CM.cfnAxios(url, "get", "", (objectStatus, resultData) => resolve(resultData));
    });
  };

  const getRegistrations = async (param) => {
    setSearchOn(false);
    const resultData = await getAxios(param);

    tabCountChange("registrations", resultData.receiptAccountRegistrationWaitingSummaryInfo.totalTargets);

    handleSummaryInfoChange(resultData.receiptAccountRegistrationWaitingSummaryInfo);
    handleRegistrationTargetsChange(resultData.receiptAccountRegistrationTargets.content);
    handlePaginationChange(resultData.receiptAccountRegistrationTargets);
  };

  useEffect(() => {
    getRegistrations(requestParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 목록 검색 useEffect
  useEffect(() => {
    // 검색 flag가 true일 때만 실행
    if (props.tabIndex === 1 && searchOn) {
      getRegistrations(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOn, requestParams, props.tabIndex]);

  // tab이 변경될 때 검색 flag 변경 useEffect
  useEffect(() => {
    if (props.tabIndex !== 1 && !searchOn) {
      setSearchOn(true);
    }
  }, [props.tabIndex, searchOn]);

  // 모달 열기 Hooks
  const [modalOpen, setModalOpen] = useState(false);

  // 모달 열기 handler
  const handleModalOpenChange = (param) => {
    if (!checkRequired()) {
      return false;
    }

    setModalOpen(param);
  };

  const checkRequired = () => {
    if (pagination.total === 0 || pagination.total === registrationExcludedTargets.length) {
      CM.cfnAlert("계좌등록/해지 요청이 가능한 대상이 없습니다.");
      return false;
    }

    return true;
  };

  // 엑셀 저장
  const saveExcel = (param) => {
    let url = "api/customer/receipt/account/institute/registrations/excel";
    url += `?isClosingAccountIncluded=${param.isClosingAccountIncluded}&isNewAccountIncluded=${param.isNewAccountIncluded}`;
    url += `&pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
    url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    handleRequestParamsChange((data) => ({ ...data, sortProperty, sortDirection }));

    //정렬조건 세팅 후 검색 trigger
    setSearchOn(true);
  };

  // TODO: 화면 렌더
  return (
    <div>
      <div className="summary-main-area">
        <CF.SummaryForm list={summaryInfo} onClick={handleSummaryInfoClick} data-testid="registerAccountByInstitute-registrations" />
        <div className="summary-button-area">
          <button className="summary-button btn-l" onClick={() => handleModalOpenChange(true)} data-testid="account-registration-request">
            계좌 등록/해지 요청
          </button>
        </div>
      </div>
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={requestParams.pageSize} onChange={handleRowPerPageChange} data-testid="registerAccountByInstitute-registrations-select-rowPerPage" />
        <button className="btn-m fr" onClick={() => saveExcel(requestParams)} data-testid="registerAccountByInstitute-registrations-save-excel">
          엑셀저장
        </button>
      </div>
      <AccountRegistrationTargetsForm
        list={registrationTargets}
        changeRegistrationExcludedTargets={changeRegistrationExcludedTargets}
        pagination={pagination}
        handlePageChange={handlePageChange}
        financialInstitutes={props.financialInstitutes}
        requestParams={requestParams}
        handleSortProperty={handleSortProperty}
        loginStore={loginStore}
      />
      <RegistrationsAccountRegistrationRequest
        modalOpen={modalOpen}
        handleModalOpenChange={handleModalOpenChange}
        registrationExcludedTargets={registrationExcludedTargets}
        initialRegistrationExcludedTargets={initialRegistrationExcludedTargets}
        handleSearchOnChange={setSearchOn}
        registrationTargets={registrationTargets}
        summaryInfo={summaryInfo}
      />
    </div>
  );
};

// 목록 컴포넌트
const AccountRegistrationTargetsForm = (props) => {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["12%", "10%", "13%", "12%", "12%", "12%", "12%", "auto", "8%"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          value=""
          arrData={[
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "financialInstituteName", label: "금융기관", sortable: false },
            { id: "depositorName", label: "예금주명", sortable: true },
            { id: "accountNo", label: "계좌번호", sortable: false },
            { id: "depositorIdentificationNo", label: "예금주 생년월일\n(사업자번호)", sortable: true },
            { id: "accountRegistrationStatus", label: "신청구분", sortable: false },
            { id: "evidenceFileType", label: "자동납부\n동의방법", sortable: true },
            { id: "excluded", label: "요청제외", sortable: false },
          ]}
          searchRequest={props.requestParams}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId={"registerAccountByInstitute-registrations-list-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{target.financialInstituteName || CM.cfnMatchBankName(target.financialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{target.depositorName}</TableCell>
                  <TableCell align="center">{target.accountNo}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.depositorIdentificationNo)}</TableCell>
                  <TableCell align="center">{target.accountRegistrationType}</TableCell>
                  <TableCell align="center">{props.loginStore.getEvidence() === "면제" ? "면제" : CustomerData.getDataName.evidenceFileType(target.evidenceFileType)}</TableCell>
                  <TableCell align="center">
                    <button
                      className="btn-s search-button"
                      onClick={() => props.changeRegistrationExcludedTargets(target)}
                      data-testid={`registerAccountByInstitute-registrations-excluded-button-${index}`}>
                      {target.excluded ? "제외대상" : "요청제외"}
                    </button>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <CF.PaginationForm pagination={props.pagination} onClick={props.handlePageChange} testId="registerAccountByInstitute-registrations-pagination" />
    </div>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  loginStore: rootStore.loginStore,
}))(observer(RegistrationsList));
