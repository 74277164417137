import { cfnIsEmpty } from "../../common/Common";

export const searchData = {
  transactionMethod: "",
  capitalUniqueKey: "",
  groupUniqueKey: "",
  accountRegistrationStatus: "",
  search: "",
  terms: "CUSTOMER_NAME",
  pageNumber: 0,
  pageSize: 5,
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
};

export function updateSearchData() {
  return {
    searchTransactionMethod: "",
    searchCapitalUniqueKey: "",
    searchPaySpecifiedDay: "",
    searchPayAmountType: "",
    searchGroupUniqueKey: "",
    search: "",
    terms: "CUSTOMER_NAME",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
  };
}

export function paginationData(data) {
  if (cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

export function searchAccountRegistrationStatusData() {
  return [
    { value: "", label: "전체" },
    { value: "READY_TO_REGISTER", label: "등록대기" },
    { value: "REGISTERING", label: "등록중" },
    { value: "REGISTERED", label: "등록완료" },
    { value: "FAILED_TO_REGISTER", label: "등록실패" },
    { value: "READY_TO_UNREGISTER", label: "해지대기" },
    { value: "UNREGISTERING", label: "해지중" },
    { value: "UNREGISTERED", label: "해지완료" },
    { value: "FAILED_TO_UNREGISTER", label: "해지실패" },
    { value: "NORMAL", label: "정상" },
    { value: "ASKING", label: "청구중" },
    { value: "PAUSED", label: "수납중지" },
  ];
}

export function searchOptionData() {
  return [
    { value: "CUSTOMER_NAME", label: "고객명" },
    { value: "PAYER_NO", label: "납부자번호" },
    { value: "PAY_SPECIFIED_DAY", label: "수납지정일" },
    { value: "IDENTIFICATION_NO", label: "생년월일(사업자번호)" },
    { value: "CUSTOMER_MOBILE", label: "고객휴대폰번호" },
    // { value: "STAFF_NAME", label: "고객담당자 이름" }
    { value: "ACCOUNT_NO", label: "계좌번호" },
    { value: "PAY_AMOUNT", label: "수납금액" },
  ];
}

export function searchTransactionMethodData() {
  return [
    { value: "", label: "전체" },
    { value: "CMS_WITHDRAW", label: "CMS" },
    { value: "ETC", label: "기타" },
  ];
}

export const searchPaySpecifiedDayData = () => {
  const arrDate = [];
  const maxLoop = 32;
  for (let i = 1; i < maxLoop; i++) {
    let label = `${i}일`;
    let value = i < 10 ? `0${i}` : `${i}`;
    arrDate.push({
      label,
      value,
    });
  }
  arrDate.push({ label: "말일", value: "99" });
  return arrDate;
};

export function searchPayAmountTypeData() {
  return [
    { value: "", label: "전체" },
    { value: "FIXED", label: "정액" },
    { value: "VARIABLE", label: "비정액" },
  ];
}

export function expectedApplyY4mmData() {
  let date = new Date();
  let currnetMM = `${date.getFullYear().toString()}${(date.getMonth() + 1).toString()[1] ? date.getMonth() + 1 : 0 + (date.getMonth() + 1).toString()}`;
  date.setMonth(date.getMonth() + 1);
  let nextMM = `${date.getFullYear()}${(date.getMonth() + 1).toString()[1] ? date.getMonth() + 1 : 0 + (date.getMonth() + 1).toString()}`;

  let obj = [
    {
      value: currnetMM,
      label: "이번달부터",
    },
    {
      value: nextMM,
      label: "다음달부터",
    },
  ];
  return obj;
}
export function summaryData(data) {
  return [
    {
      value: data.receiptCustomerCount !== null ? data.receiptCustomerCount : 0,
      label: "전체 수납고객정보",
    },
    {
      value: data.cmsReceiptCustomerCount !== null ? data.cmsReceiptCustomerCount : 0,
      label: "CMS 수납대상",
    },
    {
      value: data.etcReceiptCustomerCount !== null ? data.etcReceiptCustomerCount : 0,
      label: "기타 수납대상",
    },
  ];
}
