import { Button, Checkbox, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaperData from "./PaperTaxBillData";

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = ({ searchRequest, handleSearchFormChange, handleSearchButtonChange }) => {
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    handleSearchButtonChange(true);
  };

  return (
    <div className="search-area" data-testid="search-area">
      <div className="block">
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.term} onChange={handleSearchFormChange("term")}>
          <option value="customerName">고객명</option>
          <option value="customerGroupName">고객구분</option>
          <option value="identificationNo">주민등록번호(사업자번호)</option>
        </Select>
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" />
        <button className="search-button" onClick={() => fnSearch()} data-testid="search-paper-target-add">
          추가 대상 조회
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = ({ targetList, handleTargetListChange }) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of targetList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const targetLength = targetList.length;

    if (checkAllFlag === targetLength && targetLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [targetList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleSelectChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...targetList];
    cloneData[index][key] = value;
    handleTargetListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    handleTargetListChange((target) => target.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  return (
    <Table>
      {CM.cfnCompColGroup(["50px", "auto", "auto", "auto"])}
      <TableForm.compCheckboxTableHead checked={checkAllRow} value="" onChange={handleCheckAllRowChange} arrData={["고객명", "주민등록번호(사업자번호)", "고객구분"]} />
      <TableBody>
        {targetList.length === 0 ? (
          <TableForm.compEmptyTableRow colSpan={4} />
        ) : (
          targetList.map((row, index) => {
            return (
              <TableRow hover key={index}>
                <TableCell align="center" name="check_customer" index="0">
                  <Checkbox
                    checked={row._checked}
                    value="_checked"
                    id={`selectCheckbox-${index}`}
                    onChange={handleSelectChange}
                    inputProps={{
                      index: index,
                    }}
                  />
                </TableCell>
                <TableCell align="center">{row.customerName}</TableCell>
                <TableCell align="center"> {CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                <TableCell align="center"> {row.customerGroupName}</TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const PaperTaxBillTargetAdd = (props) => {
  const { open, handleModal } = props;
  const { type } = props;
  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(PaperData.addTargetSearchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [taxbillDirection, setTaxbillDirection] = useState("RECEIPT");
  const [taxbillType, setTaxbillType] = useState(type);
  const [pagination, setPagination] = useState(PaperData.paginationData());
  const [originalList, setOriginalList] = useState({});

  /*
   * @desc 발행대상 추가 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);

    switch (tempObj.term) {
      case "customerGroupName":
        tempObj.customerGroupName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.customerName = "";
        tempObj.identificationNo = "";
        break;
      case "customerName":
        tempObj.customerGroupName = "";
        tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.identificationNo = "";
        break;
      case "identificationNo":
        tempObj.customerGroupName = "";
        tempObj.customerName = "";
        tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
        break;
      default:
        break;
    }

    return tempObj;
  }, [searchRequest]);

  // 테이블 데이터 검색
  useEffect(() => {
    // axios request
    const axiosList = (search) => {
      return new Promise((resolve) => {
        const url = `api/extraservice/taxbill/paper/targets/extra?customerGroupName=${search.customerGroupName}&customerName=${search.customerName}&identificationNo=${search.identificationNo}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultList = await axiosList(search);

      // 체크 옵션 추가
      for (const element of resultList.content) {
        element._checked = false;
      }

      setPagination(PaperData.paginationData(resultList));
      setOriginalList(resultList);
      handleTargetListChange(resultList.content);
      handleSearchButtonChange(false);
    };

    //실행영역
    if (open && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [open, searchButton, fnMakeParameter]);

  /*
   * @desc    종이세금계산서 발행대상 추가 모달창 close 이벤트 핸들러(기존 데이터 초기화 후 close)
   */
  const handleModalClose = () => {
    // 모달창 close 시 선택된 체크박스 초기화
    for (const element of targetList) {
      if (element._checked) {
        element._checked = false;
      }
    }

    setTaxbillType(type);
    setSearchRequest(PaperData.addTargetSearchData());

    handleModal("add", false);
    handleSearchButtonChange(true);
  }

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    영수/청구 타입 변경 이벤트 핸들러
   */
  const handleTaxbillDirectionChange = (e) => {
    setTaxbillDirection(e.target.value);
  };

  /*
   * @desc    영수/청구 타입 변경 이벤트 핸들러
   */
  const handleTaxbillTypeChange = (e) => {
    setTaxbillType(e.target.value);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 요청 대상 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  /*
   * @desc    창닫기 버튼 클릭 이벤트 핸들러
   * 모달이 닫히며 목록재조회 실행
   * 발행대상추가 완료되어 모달이 닫히는 경우에는 메인컴포넌트(TargetTab)로 리스트를 넘겨 발행모달에 리스트가 전달된다.
   */
  const handleTargetAddModalClose = (paramArray2) => {
    handleModal("add", false, paramArray2);
    handleSearchButtonChange(true);
  };

  /*
   * @desc    선택 고객 추가 버튼 클릭 이벤트 핸들러
   */
  const handleClickAddSelected = () => {
    if (fnCheckValidation()) {
      fnAddTarget();
    }
  };

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setSearchRequest((data) => ({ ...data, pageNumber: 0, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    선택 고객 추가 전 validation 체크 함수
   * 체크된 고객이 없을시 false를 반환한다.
   */
  const fnCheckValidation = () => {
    const tempArray = [];

    for (const element of targetList) {
      if (element._checked) tempArray.push(element);
    }

    //체크된 행 없을 시
    if (CM.cfnIsEmpty(tempArray)) {
      CM.cfnAlert("선택된 고객이 없습니다.");
      return false;
    }

    //계산서 구분 미체크 시
    if(CM.cfnIsEmpty(taxbillType)){
      CM.cfnAlert("계산서 종류를 선택해주시기 바랍니다.");
      return false;
    }

    return true;
  };

  /*
   * @desc    종이(세금)계산서 발행대상 추가 Request
   * 발행대상 목록에서 체크된 값들을 paramArray에 담아 발행대상 추가 api를 호출한다.
   * 호출 후 체크된 리스트의 상세정보값을 모달을 닫으며 메인컴포넌트(paperTaxBillPublishTargetTab)으로 보낸다.
   */
  const fnAddTarget = async () => {
    const paramArray = [];
    const paramArray2 = [];
    for (const element of targetList) {
      if (element._checked) {
        paramArray.push(`customerUniqueKeys=${element.customerUniqueKey}`);
        paramArray.push(`taxbillDirections=${taxbillDirection}`);
      }
    }
    // await setPaperTaxBillsList(paramArray);
    // await handleTargetAddModalClose(paramArray2);

    const url = `api/extraservice/taxbill/paper/targets/extra?${paramArray.join("&")}&taxbillType=${taxbillType}`;
    CM.cfnAxios(url, "post", "", (objStatus, objData) => {
      if (objStatus.status === 200) {
        CM.cfnAlert("정상적으로 처리되었습니다.", async () => {
          let idx = 0;
          for (let i = 0; i < targetList.length; i++) {
            const element = targetList[i];
            if (element._checked) {
              paramArray2.push({
                taxbillUniqueKey: objData[idx],
                customerUniqueKey: element.customerUniqueKey,
                identificationNo: element.identificationNo,
                customerName: element.customerName,
                taxbillType,
                taxbillDirection,
              });
              idx++;
            }
          }
          handleTargetAddModalClose(paramArray2);
        });
      } else {
        CM.cfnAlert(objData && objData.message ? objData.message : objData ? objData : "종이세금계산서 발행대상을 추가하지 못했습니다.");
      }
    });
  };

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleModalClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>종이세금계산서 발행대상 추가</h3>
          <div>
            <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
            <div className="table-top-area" style={{ display: "inline-flex", justifyContent: "space-between" }}>
              <div>
                <TotalCountForm totalElements={originalList.totalElements || 0} />
                <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              </div>
              <div className="d-inline-flex">
                <label className="label-l">발행대상 구분</label>
                <FormControl component="fieldset">
                  <RadioGroup name="taxbillDirection" value={taxbillDirection} onChange={handleTaxbillDirectionChange} data-testid="tax-bill-direction-radio" row>
                    <FormControlLabel value="RECEIPT" control={<Radio color="primary" />} label="영수" />
                    <FormControlLabel value="INVOICE" control={<Radio color="primary" />} label="청구" />
                  </RadioGroup>
                </FormControl>
                <label className="label-l">계산서 구분</label>
                <FormControl component="fieldset">
                  <RadioGroup name="taxbillType" value={taxbillType} onChange={handleTaxbillTypeChange} data-testid="tax-bill-type-radio" row>
                    <FormControlLabel value="PAPER" control={<Radio color="primary" />} label="종이세금계산서" />
                    <FormControlLabel value="TAXFREE" control={<Radio color="primary" />} label="종이계산서" />
                  </RadioGroup>
                </FormControl>
                <div className="btn-l table-top-button" onClick={handleClickAddSelected}>
                  선택 고객 추가
                </div>
              </div>
            </div>
            <ListForm targetList={targetList} handleTargetListChange={handleTargetListChange} searchRequest={searchRequest} pagination={pagination} page={searchRequest.pageNumber} />
            <Pagination
              className="centered"
              limit={pagination.rowsPerPage}
              offset={pagination.offset}
              total={pagination.total}
              onClick={(e, offset, page) => handleOffsetChange(offset, page)}
              reduced={true}
              centerRipple={false}
              disableFocusRipple={true}
              disableRipple={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaperTaxBillTargetAdd;
