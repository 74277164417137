import { Button, TableBody, TableCell, TableRow } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import React from "react";
import { cfnIdentificationNoFormat, cfnMatchBankName } from "../../common/Common";

/*
 * @desc 계좌변경 modal component
 */
const ChangeBillingAccountModal = ({ open, setOpen, object, bankList }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={handleClose}>
                {""}
              </Button>
            </div>
            <h3>요금계좌 변경 내역</h3>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="th w100">변경 전 </TableCell>
                  <TableCell className="td">
                    {bankList && cfnMatchBankName(object?.previousBankCode, bankList)} {object?.previousAccountNo} <br />
                    예금주 : {object?.previousDepositorName}
                    <br />
                    납부자 실명확인 번호 : {cfnIdentificationNoFormat(object?.previousDepositorIdentificationNo)}
                  </TableCell>
                  <TableCell className="th w100">변경 후 </TableCell>
                  <TableCell className="td">
                    {bankList && cfnMatchBankName(object?.bankCode, bankList)} {object?.accountNo} <br />
                    예금주 : {object?.depositorName}
                    <br />
                    납부자 실명확인 번호 : {cfnIdentificationNoFormat(object?.depositorIdentificationNo)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ChangeBillingAccountModal;
