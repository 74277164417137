import React from "react";
import * as CM from "../../common/Common";
import { Grid, Table, TableRow, TableBody, TableCell, FormControlLabel, Checkbox } from "@material-ui/core";
import { observer, inject } from "mobx-react";
const customerBasicBackupLabelJson = {
  customerName: "고객명",
  customerType: "고객유형",
  identificationNoFirst7: "주민번호앞자리(사업자번호)",
  customerGroupName: "고객구분명",
  customerStaffName: "고객담당자",
  customerZipCode: "우편번호",
  customerAddress: "주소",
  customerMobile: "휴대전화번호",
  customerTelephone: "유선전화번호",
  customerEmail: "이메일주소",
  representativeName: "대표자명",
  businessCategory: "업태",
  businessDetailedCategory: "업종",
  customerFax: "FAX번호",
  remark: "비고",
  cashbillNo: "현금영수증발행번호",
  birthday: "생년월일",
  anniversary: "결혼기념일",
};

const receiptLabelJson = {
  customerName: "고객명",
  payerNo: "납부자번호",
  identificationNoFirst7: "주민번호앞자리(사업자번호)",
  customerGroupName: "고객구분명",
  customerStaffName: "고객담당자",
  representativeName: "대표자명",
  businessCategory: "업태",
  businessDetailedCategory: "업종",
  remark: "비고",
  accountRegistrationStatus: "서비스상태",
  capitalName: "자금종류",
  payPeriod: "수납주기",
  payAmountType: "수납구분",
  payAmount: "수납금액",
  paySpecifiedDay: "수납지정일",
  transactionMethod: "수납방법",
  payStartY4mm: "계약시작년월",
  payEndY4mm: "계약종료년월",
  fncInsNm: "계좌은행명",
  depositorName: "예금주명",
  accountNo: "계좌번호",
  depositorTelephone: "예금주전화번호",
  depositorIdentificationNoFirst7: "예금주생년월일(사업자번호)",
  totalUnpaidAmount: "미납총금액",
  lastUnpaidTargetY4mm: "미납최종년월",
  registrationAppliedSubject: "신규접수구분",
  unregistrationAppliedSubject: "해지접수구분",
};
const paymentLabelJson = {
  customerName: "고객명",
  identificationNoFirst7: "주민번호앞자리(사업자번호)",
  customerGroupName: "고객구분명",
  customerStaffName: "고객담당자",
  capitalName: "자금종류",
  payAmountType: "지급구분",
  payAmount: "지급금액",
  paySpecifiedDay: "지급지정일",
  payStartY4mm: "지급시작년월",
  payEndY4mm: "지급종료년월",
  fncInsNm: "계좌은행명",
  accountNo: "계좌번호",
  depositorName: "예금주명",
  depositorIdentificationNoFirst7: "예금주생년월일(사업자번호)",
  totalUnpaidAmount: "미지급금액",
};
//  customerFax: "FAX번호",
class DataBackup extends React.Component {
  constructor(props) {
    super(props);
    this.store = props.store;
    // this.instituteState = "";
    // if () {
    //   if (this.businessInfo.cmsService.indexOf("EB21") === -1 && this.businessInfo.cmsService.indexOf("EC21") === -1) {
    //     this.instituteState = "payment";
    //   } else if (this.businessInfo.cmsService.indexOf("EB31") === -1) {
    //     this.instituteState = "receipt";
    //   }
    // }
    this.state = {
      instituteState: [],
      customerRecentDownloadDatetime: "2019/07/21 01:48:20", //고객 기본정보 백업 다운로드 일시
      customerDataNumber: 0, //고객 기본정보 백업 건수
      receiptRecentDownloadDatetime: "2019/07/21 01:48:20", //수납 이체자료 백업 다운로드 일시
      receiptDataNumber: 122, //수납 이체자료 백업 건수
      paymentRecentDownloadDatetime: "2019/07/21 01:48:20", //지급 이체자료 백업 다운로드 일시
      paymentDataNumber: 0, //지급 이체자료 백업 건수
      receiptNotProcessedAmount: null,
      customerBasicBackup: true,
      receipt: true,
      payment: true,
      customerBasicBackupJson: {
        anniversary: true,
        birthday: true,
        businessCategory: true,
        businessDetailedCategory: true,
        cashbillNo: true,
        customerAddress: true,
        customerEmail: true,
        customerFax: true,
        customerGroupName: true,
        customerMobile: true,
        customerName: true,
        customerStaffName: true,
        customerTelephone: true,
        customerType: true,
        customerZipCode: true,
        identificationNoFirst7: true,
        remark: true,
        representativeName: true,
      },
      receiptJson: {
        accountNo: true,
        accountRegistrationStatus: true,
        businessCategory: true,
        businessDetailedCategory: true,
        capitalName: true,
        customerGroupName: true,
        customerName: true,
        customerStaffName: true,
        depositorIdentificationNoFirst7: true,
        depositorName: true,
        depositorTelephone: true,
        fncInsNm: true,
        identificationNoFirst7: true,
        lastUnpaidTargetY4mm: true,
        payAmount: true,
        payAmountType: true,
        payEndY4mm: true,
        payPeriod: true,
        paySpecifiedDay: true,
        payStartY4mm: true,
        payerNo: true,
        registrationAppliedSubject: true,
        remark: true,
        representativeName: true,
        totalUnpaidAmount: true,
        transactionMethod: true,
        unregistrationAppliedSubject: true,
      },
      paymentJson: {
        accountNo: true,
        capitalName: true,
        customerGroupName: true,
        customerName: true,
        customerStaffName: true,
        depositorIdentificationNoFirst7: true,
        depositorName: true,
        customerFax: true,
        fncInsNm: true,
        identificationNoFirst7: true,
        payAmount: true,
        payAmountType: true,
        payEndY4mm: true,
        paySpecifiedDay: true,
        payStartY4mm: true,
        totalUnpaidAmount: true,
      },
    };
  }
  componentDidMount = () => {
    let values = new Promise(async (resolve, reject) => {
      const instituteState = await this.store.axiosBusinessInfo();
      if (instituteState === null) {
        reject(Error("no"));
      } else {
        resolve(instituteState);
      }
    });

    values
      .then((instituteState) => {
        let jsonValue = {};
        jsonValue["instituteState"] = instituteState.cmsService;
        this.handleState(jsonValue);
      })
      .catch((e) => {
        CM.cfnAlert(e);
      });

    let url = "/api/extraservice/databackups";
    CM.cfnAxios(url, "get", "", (status, data) => {
      let jsonValue = {};
      jsonValue["customerRecentDownloadDatetime"] = data["customerRecentDownloadDatetime"] === "00000000000000" ? "" : CM.cfnDateFormat(data["customerRecentDownloadDatetime"], "yyyyMMddhhmmss");
      jsonValue["receiptRecentDownloadDatetime"] = data["receiptRecentDownloadDatetime"] === "00000000000000" ? "" : CM.cfnDateFormat(data["receiptRecentDownloadDatetime"], "yyyyMMddhhmmss");
      jsonValue["paymentRecentDownloadDatetime"] = data["paymentRecentDownloadDatetime"] === "00000000000000" ? "" : CM.cfnDateFormat(data["paymentRecentDownloadDatetime"], "yyyyMMddhhmmss");

      jsonValue["customerDataNumber"] = data["customerDataNumber"];
      jsonValue["receiptDataNumber"] = data["receiptDataNumber"];
      jsonValue["paymentDataNumber"] = data["paymentDataNumber"];

      this.handleState(jsonValue);
    });
  };
  handleState = ({ ...stateName }) => {
    let newState = Object.assign({}, this.state);
    Object.keys(stateName).forEach((key) => (newState[key] = stateName[key]));
    this.setState(newState);
  };

  handleInstituteState = (values) => {
    this.setInstituteState(values);
  };

  handleCheckBoxChange = (e) => {
    let jsonValue = Object.assign({}, this.state);
    let checkAll = true;
    if (e.target.name.indexOf("_") > 0) {
      let targetType = e.target.name.split("_")[0];
      let targetName = e.target.name.split("_")[1];
      let checkAllType = targetType.replace("Json", "");
      jsonValue[targetType][targetName] = e.target.checked;
      if (e.target.checked === false) {
        checkAll = false;
      } else {
        Object.keys(jsonValue[targetType]).forEach((key) => {
          if (jsonValue[targetType][key] === false) {
            checkAll = false;
          }
        });
      }
      jsonValue[checkAllType] = checkAll;
    } else {
      jsonValue[e.target.name] = e.target.checked;
      let targetType = e.target.name + "Json";
      Object.keys(jsonValue[targetType]).forEach((key) => {
        jsonValue[targetType][key] = e.target.checked;
      });
    }
    this.handleState(jsonValue);
  };

  makeParam = (type) => {
    let parameter = "?";
    let jsonValue = Object.assign({}, this.state);

    Object.keys(jsonValue[type]).forEach((key) => {
      if (parameter.length > 1) {
        parameter += "&";
      }
      parameter += key + "=" + jsonValue[type][key];
    });
    return parameter;
  };

  sendCustomerDataBackup = (e) => {
    let param = this.makeParam("customerBasicBackupJson");

    let url = "/api/extraservice/databackups/customer" + param;
    CM.cfnAxiosFileDownload(url, "get", "", (status, data) => {}, undefined, "", "고객기본정보");
  };

  sendReceiptDataBackup = (e) => {
    let param = this.makeParam("receiptJson");

    let url = "/api/extraservice/databackups/receipt" + param;
    CM.cfnAxiosFileDownload(url, "get", "", (status, data) => {}, undefined, "", "수납내역");
  };

  sendPaymentDataBackup = (e) => {
    let param = this.makeParam("paymentJson");

    let url = "/api/extraservice/databackups/payment" + param;
    CM.cfnAxiosFileDownload(url, "get", "", () => {}, undefined, "", "지급내역");
  };

  render() {
    return (
      <div>
        <h3>안내</h3>
        <div className="inforbox">
          <ul>
            <li>개인정보보호법의 시행으로 인해 고객기본정보와 수납이체자료 등을 백업하는 경우 주민번호 뒷자리와 계좌번호 뒷자리가 비표(*) 처리됨을 양해해주십시오.</li>
          </ul>
        </div>
        <h4>고객 기본정보 백업</h4>
        <Table>
          {CM.cfnCompColGroup(["170px", "auto", "170px", "auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">최근 다운로드일시</TableCell>
              <TableCell className="td">{this.state.customerRecentDownloadDatetime}</TableCell>
              <TableCell className="th">건수</TableCell>
              <TableCell className="td">{CM.cfnAddComma(this.state.customerDataNumber || 0)}건</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">
                백업할 내용
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="customerBasicBackup"
                      checked={this.state.customerBasicBackup}
                      onChange={(e) => this.handleCheckBoxChange(e)}
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "check-new-account",
                      }}
                    />
                  }
                  label="전체"
                />
              </TableCell>
              <TableCell className="td-strong-cell2" colSpan={3}>
                <Grid container spacing={4}>
                  {Object.keys(customerBasicBackupLabelJson).map((key) => {
                    return (
                      <Grid item xs={4} key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={"customerBasicBackupJson_" + key}
                              label={"customerBasicBackupJson_" + key}
                              name={"customerBasicBackupJson_" + key}
                              checked={this.state.customerBasicBackupJson[key]}
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              inputProps={{
                                "aria-label": "primary checkbox",
                                "data-testid": "check-new-account",
                              }}
                            />
                          }
                          label={customerBasicBackupLabelJson[key]}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="table-bottom-area">
          <button type="button" onClick={(event) => this.sendCustomerDataBackup()} className="btn-l fr">
            데이터 백업
          </button>
        </div>
        {Object.isExtensible(this.state.instituteState) && this.state.instituteState.indexOf("EB21") === -1 && this.state.instituteState.indexOf("EC21") === -1 ? (
          ""
        ) : (
          <div>
            <h4>수납 내역 백업</h4>
            <Table>
              {CM.cfnCompColGroup(["170px", "auto", "170px", "auto"])}
              <TableBody>
                <TableRow>
                  <TableCell className="th">최근 다운로드일시</TableCell>
                  <TableCell className="td">{this.state.receiptRecentDownloadDatetime}</TableCell>
                  <TableCell className="th">건수</TableCell>
                  <TableCell className="td">{CM.cfnAddComma(this.state.receiptDataNumber || 0)}건</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="th">
                    백업할 내용
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="receipt"
                          checked={this.state.receipt}
                          onChange={(e) => this.handleCheckBoxChange(e)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                            "data-testid": "check-new-account",
                          }}
                        />
                      }
                      label="전체"
                    />
                  </TableCell>
                  <TableCell className="td-strong-cell2" colSpan={3}>
                    <Grid container spacing={4}>
                      {Object.keys(receiptLabelJson).map((key) => {
                        return (
                          <Grid item xs={4} key={key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={"receiptJson_" + key}
                                  label={"receiptJson_" + key}
                                  name={"receiptJson_" + key}
                                  checked={this.state.receiptJson[key]}
                                  onChange={(e) => this.handleCheckBoxChange(e)}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                    "data-testid": "check-new-account",
                                  }}
                                />
                              }
                              label={receiptLabelJson[key]}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className="table-bottom-area">
              <button type="button" onClick={(event) => this.sendReceiptDataBackup()} className="btn-l fr">
                데이터 백업
              </button>
            </div>
          </div>
        )}
        {Object.isExtensible(this.state.instituteState) && this.state.instituteState.indexOf("EB31") === -1 ? (
          ""
        ) : (
          <div>
            <h4>지급 내역 백업</h4>
            <Table>
              {CM.cfnCompColGroup(["170px", "auto", "170px", "auto"])}
              <TableBody>
                <TableRow>
                  <TableCell className="th">최근 다운로드일시</TableCell>
                  <TableCell className="td">{this.state.paymentRecentDownloadDatetime}</TableCell>
                  <TableCell className="th">건수</TableCell>
                  <TableCell className="td">{CM.cfnAddComma(this.state.paymentDataNumber || 0)}건</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="th">
                    백업할 내용
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="payment"
                          checked={this.state.payment}
                          onChange={(e) => this.handleCheckBoxChange(e)}
                          inputProps={{
                            "aria-label": "primary checkbox",
                            "data-testid": "check-new-account",
                          }}
                        />
                      }
                      label="전체"
                    />
                  </TableCell>
                  <TableCell className="td-strong-cell2" colSpan={3}>
                    <Grid container spacing={4}>
                      {Object.keys(paymentLabelJson).map((key) => {
                        return (
                          <Grid item xs={4} key={key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={"paymentJson_" + key}
                                  label={"paymentJson_" + key}
                                  name={"paymentJson_" + key}
                                  checked={this.state.paymentJson[key]}
                                  onChange={(e) => this.handleCheckBoxChange(e)}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                    "data-testid": "check-new-account",
                                  }}
                                />
                              }
                              label={paymentLabelJson[key]}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className="table-bottom-area">
              <button type="button" onClick={(event) => this.sendPaymentDataBackup()} className="btn-l">
                데이터 백업
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(DataBackup));
