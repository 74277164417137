import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import { Modal, Table, TableBody, TableRow, TableCell, FormGroup, FormControl, FormControlLabel, Select, Input, Button, Checkbox, Radio, RadioGroup } from "@material-ui/core";

import * as Common from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import StaffData from "./StaffInfoData";

const emailDomains = Common.cfnGetEmailDomains();

//담당자 관리 모달 component 선언
const StaffModal = ({ loginStore, instituteStore, open, setOpen, selectedKey, setSelectedKey, branches, setStaffs }) => {
  let loginYn = false;
  const masterYn = loginStore.loginDto.masterYn;

  const defaultValue = {
    branch: { branchName: "", uniqueKey: "" }, //소속지사
    staffName: "", //담당자 이름
    staffMobile: "", //담당자 휴대폰 번호
    staffEmail: "", //담당자 이메일
    masterYn: false, //총괄담당자 여부
    billingManagerYn: false, //이용요금담당자 여부
    cashbillManagerYn: false, //현금영수증 담당자 여부
    trusbillManagerYn: false, //전자세금계산서 담당자 여부
    smsTargetYn: false, //업무용 SMS수신 여부
    staffBirthDate: null, //담당자 생년월일

    //선택입력사항
    staffDepartment: "", //담당자 부서
    staffPosition: "", //담당자 직위
    staffTelephone: "", //담당자 전화번호
    staffFax: "", //담당자 팩스번호
    cmsManagerYn: "", //cms담당자 여부
    intgtLoginId: "", //통합 로그인ID
    loginYn: false, //로그인 가능여부
    uniqueKey: "", //조회를 위한 uniqueKey
  };

  const [isNewStaff, setIsNewStaff] = useState(true);
  const [staffInfo, setStaffInfo] = useState(defaultValue);

  //이메일 입력 시  ID 입력창 / Domain 셀렉트 박스로 분리하기 위해 추가 state 선언
  const defaultEmail = {
    id: "",
    domain: "",
    selected: "manual",
  };
  const [email, setEmail] = useState(defaultEmail);

  useEffect(() => {
    /*
     * @desc    선택된 기존 담당자 정보 조회 Request
     */
    const fnFetchStaffInfo = async (uniqueKey) => {
      const url = `api/institute/staffs/${uniqueKey}`;
      await Common.cfnAxios(url, "get", "", fnSetStaffInfo);
    };

    /*
     * @desc    선택된 담당자 정보 조회 Request 후 callback 함수
     */
    const fnSetStaffInfo = (objStatus, objData) => {
      if (objStatus.status !== 200) {
        Common.cfnAlert(objStatus.statusText);
        return false;
      }

      //response 결과값을 임시 오브젝트에 담아 세팅
      let tempObj = {};
      for (const [key, value] of Object.entries(objData)) {
        tempObj[key] = value;
      }

      setStaffInfo(tempObj);
      setIsNewStaff(false);

      const staffEmail = objData.staffEmail;
      setEmail({
        id: staffEmail.substring(0, staffEmail.indexOf("@")),
        domain: staffEmail.substring(staffEmail.indexOf("@") + 1),
        selected: fnFindEmailDomain(staffEmail.substring(staffEmail.indexOf("@") + 1)),
      });
    };

    /*
     * @desc    담당자 정보 중 이메일 주소의 도메인이 셀렉트 박스에 존재하는지 확인
     */
    const fnFindEmailDomain = (domain) => {
      let isExistDomain = emailDomains.some((row) => {
        return row.domain === domain;
      });

      return isExistDomain ? domain : "manual";
    };

    if (Common.cfnIsNotEmpty(selectedKey)) {
      fnFetchStaffInfo(selectedKey);
    }
  }, [selectedKey]);

  /*
   * @desc    닫기 버튼 클릭 시 이벤트 핸들러
   */
  const handleStaffClose = (e) => {
    setSelectedKey();
    setStaffInfo(defaultValue);
    setIsNewStaff(true);
    setEmail(defaultEmail);
    setOpen(false);
  };

  /*
   * @desc    이메일 주소 변경 이벤트 핸들러
   */
  const handleEmailInput = (name) => async (e) => {
    if (name === "selected" && e.target.value === "manual") {
      //도메인 셀렉트 박스 중 '직접입력' 선택 시
      setEmail({
        ...email,
        domain: "",
        [name]: e.target.value,
      });

      if (Common.cfnIsNotEmpty(email.id)) {
        setStaffInfo({
          ...staffInfo,
          staffEmail: `${email.id}@${e.target.value}`,
        });
      }
    } else if (name === "domain") {
      //직접입력 선택 후 이메일 도메인 입력 시
      setEmail({
        ...email,
        [name]: e.target.value,
      });

      if (Common.cfnIsNotEmpty(email.id)) {
        setStaffInfo({
          ...staffInfo,
          staffEmail: `${email.id}@${e.target.value}`,
        });
      }
    } else if (name === "selected" && e.target.value !== "manual") {
      //도메인 셀렉트 박스 선택 시
      setEmail({
        ...email,
        domain: e.target.value,
        [name]: e.target.value,
      });

      if (Common.cfnIsNotEmpty(email.id)) {
        setStaffInfo({
          ...staffInfo,
          staffEmail: `${email.id}@${e.target.value}`,
        });
      }
    } else {
      //이메일 ID 입력 시
      setEmail({
        ...email,
        [name]: e.target.value,
      });

      if (Common.cfnIsNotEmpty(email.domain)) {
        setStaffInfo({
          ...staffInfo,
          staffEmail: `${e.target.value}@${email.domain}`,
        });
      }
    }
  };

  /*
   * @desc    모달 내 입력란 변경 이벤트 핸들러
   */
  const handleNewStaffInfoChange = (name) => (event) => {
    if (name === "staffBirthDate") {
      setStaffInfo({
        ...staffInfo,
        staffBirthDate: event,
      });
    } else {
      //유선전화번호 & 팩스번호 입력 시 숫자만 입력 가능하도록 변경
      //서울유선전화일 경우 02- 그외는 XXX-로 보여지게 가능
      if (name === "staffTelephone" || name === "staffFax" || name === "staffMobile") {
        let value = event.target.value;
        value = value.replace(/[^0-9]/g, "");
        value = value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
        event.target.value = value;
      }
      switch (event.target.type) {
        case "checkbox":
          handleStaffAuthCheck(name, event.target.checked);
          break;
        case "radio":
          setStaffInfo({
            ...staffInfo,
            [name]: event.target.value === "true",
          });
          break;
        case "select-one":
          const index = event.nativeEvent.target.selectedIndex;
          setStaffInfo({
            ...staffInfo,
            branch: {
              branchName: event.nativeEvent.target[index].text,
              uniqueKey: event.target.value,
            },
          });
          break;
        default:
          setStaffInfo({
            ...staffInfo,
            [name]: event.target.value,
          });
          break;
      }
    }
  };

  /*
   * @desc    업무담당 체크박스 클릭 이벤트 핸들러
   */
  const handleStaffAuthCheck = (name, isChecked) => {
    setStaffInfo({
      ...staffInfo,
      [name]: isChecked,
      loginYn: fnCountStaffAuth(),
    });
  };

  /*
   * @desc    업무담당 체크박스 클릭 시 로그인 가능여부 판별 함수
   */
  const fnCountStaffAuth = () => {
    const checkboxes = document.querySelectorAll('[name="staffAuth"]');
    let authCount = 0;

    checkboxes.forEach((box) => {
      if (box.checked) authCount++;
    });

    if (authCount > 0) loginYn = true;

    return loginYn;
  };

  /*
   * @desc    담당자 삭제 버튼 클릭 시 이벤트 핸들러
   */
  const handleStaffDelete = (e) => {
    //총괄담당자 / 이용요금 담당자 삭제 불가하도록 제어
    let isNotMaster = true;
    if (staffInfo.masterYn || staffInfo.billingManagerYn) {
      Common.cfnAlert("총괄담당자 혹은 이용요금담당자는 삭제할 수 없습니다.");
      isNotMaster = false;
    }

    if (isNotMaster) {
      Common.cfnConfirm("정말로 삭제하시겠습니까?", fnDeleteStaff);
    }
  };

  /*
   * @desc    담당자 저장 버튼 클릭 이벤트 핸들러
   */
  const handleStaffSave = (e) => {
    e.preventDefault();

    if (fnCheckValidation()) {
      Common.cfnConfirm("저장하시겠습니까?", () => {
        if (isNewStaff) fnInsertNewStaff();
        else fnUpdateStaffInfo();
      });
    }
  };

  /*
   * @desc    담당자 저장/수정 시 필요한 validation check 함수
   */
  const fnCheckValidation = () => {
    const staffs = CM.cfnCopyObject(toJS(instituteStore.staffs));
    const targetStaff = staffInfo;

    let isValid = true;
    let masterCount = 0,
      managerCount = 0,
      cashCount = 0,
      trustCount = 0;

    for (let i = 0; i < staffs.length; i++) {
      if (staffs[i].masterYn) masterCount++;
      if (staffs[i].billingManagerYn) managerCount++;
      if (staffs[i].cashbillManagerYn) cashCount++;
      if (staffs[i].trusbillManagerYn) trustCount++;

      if (targetStaff.isNewStaff && targetStaff.masterYn && masterCount === 1) {
        Common.cfnAlert("총괄 담당자는 1명만 지정할 수 있습니다.");
        isValid = false;
        break;
      }

      if (targetStaff.isNewStaff && targetStaff.billingManagerYn && managerCount === 1) {
        Common.cfnAlert("이용요금 담당자는 1명만 지정할 수 있습니다.");
        isValid = false;
        break;
      }
      if (targetStaff.isNewStaff && targetStaff.cashbillManagerYn && cashCount === 3) {
        Common.cfnAlert("현금영수증 담당자는 3명까지만 지정할 수 있습니다.");
        isValid = false;
        break;
      }

      if (targetStaff.isNewStaff && targetStaff.trusbillManagerYn && trustCount === 3) {
        Common.cfnAlert("전자세금계산서 담당자는 3명까지만 지정할 수 있습니다.");
        isValid = false;
        break;
      }

      if (Common.cfnIsEmpty(targetStaff.staffBirthDate) && (targetStaff.masterYn || targetStaff.billingManagerYn || targetStaff.cashbillManagerYn || targetStaff.trusbillManagerYn)) {
        Common.cfnAlert("담당자의 생년월일을 입력하여 주시기 바랍니다.");
        isValid = false;
        break;
      }

      if (Common.cfnIsNotEmpty(targetStaff.staffBirthDate) && staffs[i].staffBirthDate === targetStaff.staffBirthDate && staffs[i].uniqueKey !== targetStaff.uniqueKey) {
        Common.cfnAlert("입력하신 생년월일로 등록된 담당자가 존재합니다.");

        isValid = false;
        break;
      }
    }

    if (targetStaff.staffMobile.indexOf("_") !== -1) {
      Common.cfnAlert("휴대전화번호를 정확히 입력해주시기 바랍니다.");
      isValid = false;
    }

    return isValid;
  };

  /*
   * @desc    담당자 저장/수정 시 서버 요구 형식에 맞춰 값을 변환하는 함수
   */
  const fnConvertForRequest = () => {
    let clone = { ...staffInfo };
    clone.staffBirthDate = StaffData.fnConvertBirthDateToString(clone.staffBirthDate);
    clone.staffMobile = Common.cfnReplaceSymbol(clone.staffMobile);
    clone.staffEmail = clone.staffEmail === "" ? null : clone.staffEmail;

    return clone;
  };

  /*
   * @desc    신규 담당자 추가 Request
   */
  const fnInsertNewStaff = () => {
    let tempObj = fnConvertForRequest();
    const url = "api/institute/staffs";
    Common.cfnAxios(url, "post", tempObj, (objStatus, objData) => {
      Common.cfnAlert("정상적으로 처리되었습니다.", () => {
        const tempStaffs = CM.cfnCopyObject(toJS(instituteStore.staffs));
        tempObj.uniqueKey = objData;
        tempStaffs.push(tempObj);

        instituteStore.setStaffs(tempStaffs);
        setStaffs(tempStaffs);

        handleStaffClose();
      });
    });
  };

  /*
   * @desc    기존 담당자 수정 Request
   */
  const fnUpdateStaffInfo = () => {
    let tempObj = fnConvertForRequest();
    const url = "api/institute/staffs";
    Common.cfnAxios(url, "put", tempObj, (objStatus, objData) => {
      Common.cfnAlert(objData, () => {
        const tempStaffs = CM.cfnCopyObject(toJS(instituteStore.staffs));
        let rowIndex;
        tempStaffs.forEach((staff, index) => {
          if (staff.uniqueKey === tempObj.uniqueKey) rowIndex = index;
        });
        tempStaffs[rowIndex] = tempObj;

        instituteStore.setStaffs(tempStaffs);
        setStaffs(tempStaffs);

        handleStaffClose();
      });
    });
  };

  /*
   * @desc    담당자 삭제 Request
   */
  const fnDeleteStaff = () => {
    const url = `api/institute/staffs/${staffInfo.uniqueKey}`;
    Common.cfnAxios(url, "delete", "", (objStatus, objData) => {
      Common.cfnAlert(objData, () => {
        const tempStaffs = CM.cfnCopyObject(toJS(instituteStore.staffs));
        let rowIndex;
        tempStaffs.forEach((staff, index) => {
          if (staff.uniqueKey === staffInfo.uniqueKey) rowIndex = index;
        });
        tempStaffs.splice(rowIndex, 1);

        instituteStore.setStaffs(tempStaffs);
        setStaffs(tempStaffs);

        handleStaffClose();
      });
    });
  };

  return (
    <Modal open={open}>
      <div className="paper">
        <form name="staffInfo" onSubmit={handleStaffSave}>
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={(e) => handleStaffClose()} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>{Common.cfnIsEmpty(selectedKey) ? "담당자 추가" : "담당자 수정"}</h3>
            <div>
              <Table>
                {CM.cfnCompColGroup(["15%", "25%", "15%", "45%"])}
                <TableBody>
                  <TableRow className="innerTeble">
                    <TableCell className="th">소속지사</TableCell>
                    <TableCell className="td">
                      <FormControl>
                        <Select
                          native
                          value={staffInfo.branch.uniqueKey}
                          onChange={handleNewStaffInfoChange("branchUniqueKey")}
                          required={true}
                          input={<Input name="branchUniqueKey" />}
                          name="branchUniqueKey"
                          inputProps={{ "data-testid": "branch-select" }}>
                          <option key="0" value="">
                            지사 선택
                          </option>
                          {branches.map((row, index) => {
                            return (
                              <option key={index} value={row.uniqueKey}>
                                {row.branchName}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className="th">성명</TableCell>
                    <TableCell className="td">
                      <Input
                        placeholder="(4자리 이내)"
                        className="w190"
                        type="text"
                        value={staffInfo.staffName}
                        onChange={handleNewStaffInfoChange("staffName")}
                        required={true}
                        data-testid="staff-name"
                        inputProps={{ maxLength: 4 }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="th">휴대전화번호</TableCell>
                    <TableCell className="td">
                      <FormControl>
                        <Input
                          className="w100p"
                          value={staffInfo.staffMobile}
                          onChange={handleNewStaffInfoChange("staffMobile")}
                          required={staffInfo.smsTargetYn}
                          inputComponent={Template.MaskedField}
                          inputProps={{
                            mask: "mobile",
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className="th">이메일 주소</TableCell>
                    <TableCell className="td">
                      <Input
                        className="w100 nomargin"
                        value={email.id}
                        required={staffInfo.billingManagerYn || staffInfo.masterYn || staffInfo.trusbillManagerYn}
                        onChange={handleEmailInput("id")}
                        data-testid="email-id"
                      />
                      {"  "}@{"  "}
                      <Input
                        className="w120"
                        value={email.domain}
                        required={staffInfo.billingManagerYn || staffInfo.masterYn || staffInfo.trusbillManagerYn}
                        readOnly={email.selected !== "manual" ? true : false}
                        onChange={handleEmailInput("domain")}
                        data-testid="email-domain"
                      />
                      <FormControl>
                        <Select
                          native
                          value={email.selected}
                          onChange={handleEmailInput("selected")}
                          required={staffInfo.billingManagerYn || staffInfo.masterYn || staffInfo.trusbillManagerYn}
                          input={<Input name="selected" />}
                          name="selected"
                          inputProps={{ "data-testid": "email-select" }}>
                          {emailDomains.map((row, index) => {
                            return (
                              <option key={index} value={row.domain}>
                                {row.label}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="th">담당업무</TableCell>
                    <TableCell className="td" colSpan="3">
                      <FormControl required={true}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={staffInfo.loginYn}
                                onChange={handleNewStaffInfoChange("loginYn")}
                                value={staffInfo.loginYn}
                                disabled={masterYn ? false : true}
                                name="staffAuth"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="로그인"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={staffInfo.masterYn}
                                onChange={handleNewStaffInfoChange("masterYn")}
                                value={staffInfo.masterYn}
                                disabled={masterYn ? false : true}
                                name="staffAuth"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                  "data-testid": "staff-auth",
                                }}
                              />
                            }
                            label="총괄"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={staffInfo.billingManagerYn}
                                onChange={handleNewStaffInfoChange("billingManagerYn")}
                                value={staffInfo.billingManagerYn}
                                disabled={masterYn ? false : true}
                                name="staffAuth"
                                label="이용요금"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="이용요금"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={staffInfo.cashbillManagerYn}
                                onChange={handleNewStaffInfoChange("cashbillManagerYn")}
                                value={staffInfo.cashbillManagerYn}
                                disabled={masterYn ? false : true}
                                name="staffAuth"
                                label="현금영수증"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="현금영수증"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={staffInfo.trusbillManagerYn}
                                onChange={handleNewStaffInfoChange("trusbillManagerYn")}
                                value={staffInfo.trusbillManagerYn}
                                disabled={masterYn ? false : true}
                                name="staffAuth"
                                label="전자세금계산서"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="전자세금계산서"
                          />
                        </FormGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="th">생년월일</TableCell>
                    <TableCell className="td">
                      <Template.DatePickerForm
                        value={staffInfo.staffBirthDate}
                        handleChange={handleNewStaffInfoChange("staffBirthDate")}
                        format="yy.MM.dd"
                        customProps={{
                          placeholder: "YY.MM.DD",
                          inputProps: { "data-testid": "staffModal-datePicker-birthDate" },
                        }}
                        className="w150"
                      />
                    </TableCell>
                    <TableCell className="th">SMS수신여부</TableCell>
                    <TableCell className="td">
                      <FormControl component="fieldset" required={true}>
                        <RadioGroup aria-label="position" name="position" value={String(staffInfo.smsTargetYn)} onChange={handleNewStaffInfoChange("smsTargetYn")} data-testid="sms-target" row>
                          <FormControlLabel value="true" control={<Radio color="primary" />} label="수신" />
                          <FormControlLabel value="false" control={<Radio color="primary" />} label="미수신" />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div className="table-bottom-area" />
            </div>
            <h4>선택 입력사항</h4>
            <div>
              <Table>
                {CM.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
                <TableBody>
                  <TableRow>
                    <TableCell className="th">부서</TableCell>
                    <TableCell className="td">
                      <FormControl>
                        <Input
                          placeholder="(10자리 이내)"
                          className="w100p"
                          value={staffInfo.staffDepartment}
                          onChange={handleNewStaffInfoChange("staffDepartment")}
                          inputProps={{
                            "aria-label": "description",
                            maxLength: 10,
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className="th">직위</TableCell>
                    <TableCell className="td">
                      <FormControl>
                        <Input
                          placeholder="(5자리 이내)"
                          className="w100p"
                          value={staffInfo.staffPosition}
                          onChange={handleNewStaffInfoChange("staffPosition")}
                          inputProps={{
                            "aria-label": "description",
                            maxLength: 5,
                          }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="th">유선전화번호</TableCell>
                    <TableCell className="td">
                      <FormControl>
                        <Input
                          className="w100p"
                          value={staffInfo.staffTelephone}
                          onChange={handleNewStaffInfoChange("staffTelephone")}
                          inputProps={{
                            "aria-label": "description",
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className="th">팩스번호</TableCell>
                    <TableCell className="td">
                      <FormControl>
                        <Input
                          className="w100p"
                          value={staffInfo.staffFax}
                          onChange={handleNewStaffInfoChange("staffFax")}
                          inputProps={{
                            "aria-label": "description",
                          }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className="table-bottom-area">
              <Button className="btn-l table-bottom-button" type="submit" data-testid="save-staff">
                담당자 저장
              </Button>
              {!isNewStaff && (
                <Button className="btn-m table-bottom-button" onClick={(e) => handleStaffDelete()} data-testid="delete-staff">
                  담당자 삭제
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default inject((rootStore) => ({
  loginStore: rootStore.loginStore,
  instituteStore: rootStore.instituteStore,
}))(observer(StaffModal));
