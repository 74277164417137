import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CMData from "../../common/CommonDataName";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaperData from "./PaperTaxBillData";
import PaperTaxBillIssueModal from "./PaperTaxBillIssueModal";
import PaperTaxBillPrintModal from "./PaperTaxBillPrintModal";
import PaperTaxBillTargetAddModal from "./PaperTaxBillTargetAddModal";

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">수납방법</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" name="position" row>
            <FormControlLabel
              value={searchRequest.transactionMethod}
              control={<Checkbox checked={searchRequest.transactionMethod === "" ? true : false} value="" onChange={handleSearchFormChange("transactionMethod")} color="primary" />}
              label="전체"
              data-testid="select-all-transaction-method"
            />
            <RadioGroup value={searchRequest.transactionMethod} onChange={handleSearchFormChange("transactionMethod")} data-testid="select-transaction-method" row>
              <FormControlLabel
                checked={searchRequest.transactionMethod === "CMS_WITHDRAW" ? true : false}
                value="CMS_WITHDRAW"
                control={<Radio color="primary" />}
                label="CMS"
                data-testid="select-cms-method"
              />
              <FormControlLabel checked={searchRequest.transactionMethod === "ETC" ? true : false} value="ETC" control={<Radio color="primary" />} label="기타" data-testid="select-etc-method" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <label className="label-l">발행대상 구분</label>
        <FormControl component="fieldset">
          <RadioGroup name="taxbillDirection" value={searchRequest.taxbillDirection} onChange={handleSearchFormChange("taxbillDirection")} data-testid="unpaid-period" row>
            <FormControlLabel value="RECEIPT" control={<Radio color="primary" />} label="영수" />
            <FormControlLabel value="INVOICE" control={<Radio color="primary" />} label="청구" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">수납기간</label>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.fromDate}
          handleChange={handleSearchFormChange("fromDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.toDate}
          handleChange={handleSearchFormChange("toDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <br />
        <br />
        <label className="label-l">계산서 구분</label>
        <FormControl component="fieldset">
          <RadioGroup name="taxbillType" value={searchRequest.taxbillType} onChange={handleSearchFormChange("taxbillType")} data-testid="unpaid-period" row>
            <FormControlLabel value="PAPER" control={<Radio color="primary" />} label="세금계산서" />
            <FormControlLabel value="TAXFREE" control={<Radio color="primary" />} label="계산서" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">검색대상</label>
        <Select native value={searchRequest.customerGroupName} onChange={handleSearchFormChange("customerGroupName")} name="customerGroupName">
          <option value={""} key={"none"}>
            {"고객구분"}
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <Select native value={searchRequest.capitalName} onChange={handleSearchFormChange("capitalName")} name="capitalName">
          <option value={""} key={"none"}>
            {"자금종류"}
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.term} onChange={handleSearchFormChange("term")}>
          <option value="customerName">고객명</option>
          <option value="identificationNo">주민등록번호(사업자번호)</option>
        </Select>
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" />
        <button className="search-button" data-testid="search-history" onClick={() => fnSearch()}>
          발행 대상 조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state
  const { taxbillsList, setTaxbillsList } = props;

  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of taxbillsList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const customerLength = taxbillsList.length;

    if (checkAllFlag === customerLength && customerLength > 0) setCheckAllRow(true);
    else setCheckAllRow(false);
  }, [taxbillsList]);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    setTaxbillsList((taxbills) => taxbills.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...taxbillsList];
    cloneData[index][key] = value;
    setTaxbillsList(cloneData);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}

        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "transactionDate", label: "수납일", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "identificationNo", label: "주민등록번호\n(사업자번호)", sortable: true },
            { id: "transactionMethod", label: "수납방법", sortable: false },
            { id: "customerGroupName", label: "고객구분", sortable: false },
            { id: "capitalName", label: "자금종류", sortable: false },
            { id: "receivedAmount", label: "계산서 발행대상 금액", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />

        <TableBody>
          {taxbillsList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            taxbillsList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.transactionDate)}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                  <TableCell align="center">{CMData.transactionMethod(row.transactionMethod, "r")}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center">{row.capitalName}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.receivedAmount)}원</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function PaperTaxPublishTargetTab(props) {
  const { tabIndex, store } = props;

  //필요 state 선언
  const [searchRequest, setSearchRequest] = useState(PaperData.targetSearchData()); // table 데이터 검색 조건
  const [originalList, setOriginalList] = useState([]);
  const [paperTaxBillsList, setPaperTaxBillsList] = useState([]); // table 데이터
  const [selectedTaxBillsList, setSelectedTaxBillsList] = useState([]); //선택된 발행대상 제어
  const [pagination, setPagination] = useState(PaperData.paginationData());

  const [excelButton, setExcelButton] = useState(false);
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [isTargetChanged, setIsTargetChanged] = useState(false); //발행대상 추가 여부 flag

  //모달 제어
  const [openIssueModal, setOpenIssueModal] = useState(false); //발행 모달 제어
  const [openAddModal, setOpenAddModal] = useState(false); //대상 추가 모달 제어
  const [openPrintModal, setOpenPrintModal] = useState(false); //종이영수증 인쇄 모달 제어

  const [master, setMaster] = useState({});

  /*
   * @desc    종이(세금)계산서 발행 / 발행 대상 추가 모달 핸들러
   */
  const handleModal = async (type, open, list) => {
    if (type === "add") {
      await setOpenAddModal(open);
      if (Object.isExtensible(list)) {
        setOpenIssueModal(true);
        setSelectedTaxBillsList(list);
      }
    } else if (type === "issue") {
      const tempArray = [];

      for (const element of paperTaxBillsList) {
        if (element._checked) tempArray.push(element);
      }

      if (tempArray.length === 0) {
        CM.cfnAlert("선택된 내용이 없습니다.");
        return false;
      }

      await setSelectedTaxBillsList(tempArray);

      setOpenIssueModal(open);
    } else if (type === "close") {
      setOpenIssueModal(open);
      handleSearchButtonChange(true);
    } else if (type === "print") {
      setOpenIssueModal(open);
      if (Object.isExtensible(list)) {
        setSelectedTaxBillsList(list);
        setOpenPrintModal(true);
      }
    }
  };

  useEffect(() => {
    /*
     * @desc 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);
      tempObj.toDate = CM.cfnConvertDateToString(searchRequest.toDate);
      tempObj.fromDate = CM.cfnConvertDateToString(searchRequest.fromDate);

      if (tempObj.term === "customerName") {
        tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.identificationNo = "";
      } else if (tempObj.term === "identificationNo") {
        tempObj.customerName = "";
        tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
      }

      return tempObj;
    };

    /*
     * @desc    종이세금계산서 발행대상 조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/taxbill/paper/targets?capitalUniqueKey=${search.capitalName}&groupUniqueKey=${search.customerGroupName}&customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&taxbillDirection=${search.taxbillDirection}&taxbillType=${search.taxbillType}&toDate=${search.toDate}&transactionMethod=${search.transactionMethod}
        `;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    엑셀저장 Request
     */
    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/taxbill/paper/targets/excel?capitalUniqueKey=${search.capitalName}&groupUniqueKey=${search.customerGroupName}&customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&taxbillDirection=${search.taxbillDirection}&taxbillType=${search.taxbillType}&toDate=${search.toDate}&transactionMethod=${search.transactionMethod}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

        CM.cfnAxiosFileDownload(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      if (buttonType === "excel") {
        await setExcelButton(false);
        await axiosExcel(search);
      } else {
        // await setSearchButton(false);
        if (CM.cfnAddDate(search.fromDate, search.toDate, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }

        const resultData = await axiosList(search);
        let resultList = resultData.content;
        if (CM.cfnIsEmpty(resultList)) {
          resultList = [];
        } else {
          // 체크 옵션 추가
          for (const element of resultList) {
            element._checked = false;
          }
        }

        setOriginalList(resultData);
        setPaperTaxBillsList(resultList);
        setPagination(PaperData.paginationData(resultData));
        setSearchButton(false);
      }
    }

    // 실행 영역
    if (tabIndex === 0 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "search");
    } else if (tabIndex === 0 && excelButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "excel");
    }
  }, [tabIndex, searchRequest, searchButton, excelButton]);

  //발행대상 추가 시 목록 재조회
  useEffect(() => {
    if (isTargetChanged) {
      setIsTargetChanged(false);
      handleSearchButtonChange(true);
    }
  }, [isTargetChanged]);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosCapitals(); // 자금종류
      setMaster(toJS(await store.axiosMaster()));
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "fromDate" || name === "toDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
      if (name === "taxbillType" || name === "taxbillDirection") {
        handleSearchButtonChange(true);
      }
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    목록저장(엑셀) 버튼 클릭 이벤트 핸들러
   */
  const handleClickExcelTaxbills = (flag) => setExcelButton(flag);

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        storeCapital={toJS(store.capital)} // 자금종류
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
      />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={originalList.totalElements || 0} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
        <button className="btn-m fr" data-testid="save-excel" onClick={(e) => handleClickExcelTaxbills(true)}>
          목록저장(엑셀)
        </button>
        <button className="btn-m fr" data-testid="add-publish" onClick={(e) => handleModal("add", true)}>
          발행 대상 추가
        </button>
        <button className="btn-l fr" data-testid="paper-taxbills" onClick={(e) => handleModal("issue", true)}>
          종이{searchRequest.taxbillType === "PAPER" ? "세금" : ""}계산서 발행
        </button>
      </div>
      <ListForm
        taxbillsList={paperTaxBillsList}
        setTaxbillsList={setPaperTaxBillsList}
        pagination={pagination}
        page={searchRequest.pageNumber}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <CF.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />

      <PaperTaxBillTargetAddModal
        open={openAddModal}
        handleModal={handleModal}
        // setIsTargetChanged={setIsTargetChanged}
        // paperTaxBillsList={paperTaxBillsList}
        // setPaperTaxBillsList={setPaperTaxBillsList}
      />
      <PaperTaxBillIssueModal open={openIssueModal} master={master} handleModal={handleModal} selectedTaxBillsList={selectedTaxBillsList} setSelectedTaxBillsList={setSelectedTaxBillsList} />
      <PaperTaxBillPrintModal open={openPrintModal} setOpen={setOpenPrintModal} selectedTaxBills={selectedTaxBillsList} setSelectedTaxBills={setSelectedTaxBillsList} master={master} />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaperTaxPublishTargetTab));
