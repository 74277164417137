import * as CM from "../../common/Common";

//검색객체 data세팅
export const searchData = {
  group: "",
  capital: "",
  terms: "CUSTOMER_NAME",
  search: "",
  pageNumber: 0,
  pageSize: 5,
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  transactionMethod: "",
};

//페이징객체 data세팅
export const paginationData = (data) => {
  const returnObject = {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  };

  if (CM.cfnIsNotEmpty(data)) {
    returnObject.rowsPerPage = data.pageable.pageSize;
    returnObject.offset = data.pageable.offset;
    returnObject.total = data.totalElements;
    returnObject.totalPages = data.totalPages;
  }

  return returnObject;
};

export function searchOptionData() {
  return[
    { value: "CUSTOMER_NAME", label: "고객명" },
    { value: "IDENTIFICATION_NO", label: "생년월일(사업자번호)" },
    { value: "CUSTOMER_MOBILE", label: "고객휴대폰번호" },
    { value: "PAY_AMOUNT", label: "지급금액" },
  ];
}

//지급방법 data세팅
export const searchTransactionMethodData = () => {
  return [
    { value: "", label: "전체" },
    { value: "CMS_DEPOSIT", label: "CMS" },
    { value: "ETC", label: "기타" },
  ];
};

//지급일자 data세팅
export const searchPaySpecifiedDayData = () => {
  const arrDate = [];
  const maxLoop = 32;
  for (let i = 1; i < maxLoop; i++) {
    let label = `${i}일`;
    let value = i < 10 ? `0${i}` : String(i);
    arrDate.push({
      label: label,
      value: value,
    });
  }
  arrDate.push({ label: "말일", value: "99" });
  return arrDate;
};

export const updateSearchData = () => {
  return {
    searchTransactionMethod: "",
    searchCapitalUniqueKey: "",
    searchPayAmountType: "",
    searchGroupUniqueKey: "",
    searchPaySpecifiedDay: "",
    search: "",
    terms: "CUSTOMER_NAME",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
  };
};

//지급금액 data세팅
export const searchPayAmountTypeData = () => {
  return [
    { value: "", label: "전체" },
    { value: "FIXED", label: "정액" },
    { value: "VARIABLE", label: "비정액" },
  ];
};

export const expectedApplyY4mmData = () => {
  const thisMonth = CM.cfnGetDate().substr(0, 6);
  const nextMonth = CM.cfnGetCustomDate("month", 1, "string").substr(0, 6);

  return [
    { value: thisMonth, label: "이번달부터" },
    { value: nextMonth, label: "다음달부터" },
  ];
};
