import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import * as CM from "../../common/Common";

/*
 * @desc  고객 조회 화면
 */
const CustomerSearch = props => {
  let history = useHistory();

  // 선택 버튼을 눌렀을 때 동작하는 함수
  const selectButtonClick = customer => {
    // 고객 등록 화면일 때
    if (props.type === "customerCreate") {
      props.handleModalOpenChange(false);
      history.push("/customer/customerUpdate", { customer: customer });
    } else {
      props.handleSelectedCustomerChange(customer);
      props.handleModalOpenChange(false);
    }
  };

  // TODO: 화면 렌더
  return (
    <Modal open={props.modalOpen}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpenChange(false)} data-testid="customerSearch-close-modal">
              {""}
            </Button>
          </div>
          <h3>고객조회</h3>
          <div className="inforbox">
            <p>입력한 정보와 유사한 내용으로 등록된 고객이 있습니다.</p>
          </div>
          <div>
            <Table>
              <TableForm.compTableHead arrData={["고객명", "생년월일\n(사업자번호)", "휴대전화", "주소", "선택"]} />
              <TableBody>
                {props.searchCustomers.map((customer, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="center">{customer.customerName}</TableCell>
                      <TableCell align="center">{CM.cfnIdentificationNoFormat(customer.identificationNo)}</TableCell>
                      <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(customer.customerMobile)}</TableCell>
                      <TableCell align="center">{customer.customerAddress}</TableCell>
                      <TableCell align="center">
                        <button className="btn-s" onClick={() => selectButtonClick(customer)} data-testid={`customerSearch-select-customer-${index}`}>
                          {props.type === "customerCreate" ? "조회/수정" : "선택"}
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerSearch;
