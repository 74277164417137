import React, { useState, useEffect } from "react";

import { Modal, Button, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import TableForm from "../../template/TableForm";
import * as CM from "../../common/Common";

const ReceiptStopModal = ({ open, setOpen, value, setValue, fnReceiptStop }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
    setSearchButton(true);
  };

  // 검색 데이터
  function searchData(contractUniqueKey) {
    return {
      contractUniqueKey: contractUniqueKey,
    };
  }

  const searchRequest = searchData(value.uniqueKey); // 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [contractList, setContractList] = useState([]); // 회차별 수납 내역 데이터
  const [summeryList, setSummeryList] = useState({
    totalAmount: 0,
    totalAskingAmount: 0,
    totalUnpaidAmount: 0,
  }); //수납대상(수납정보 기준)

  useEffect(() => {
    /*
     * @desc    회차별 수납 내역
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/contract/unpaids?contractUniqueKey=${search.contractUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      await setSearchButton(false);
      //회차별 수납 내역
      const resultData = await axiosList(search);
      let resultListData = CM.cfnIsNotEmpty(resultData) ? resultData : [];
      let totalAmount = 0;
      let totalAskingAmount = 0;
      let totalUnpaidAmount = 0;

      for (const element of resultListData) {
        totalAskingAmount += element.totalAskingAmount;
        totalAmount += element.paidAmount;
        totalUnpaidAmount += element.unpaidAmount;
      }

      await setContractList(resultListData);
      await setSummeryList({
        totalAmount: CM.cfnIsNotEmpty(totalAmount) ? totalAmount : 0,
        totalAskingAmount: CM.cfnIsNotEmpty(totalAskingAmount) ? totalAskingAmount : 0,
        totalUnpaidAmount: CM.cfnIsNotEmpty(totalUnpaidAmount) ? totalUnpaidAmount : 0,
      });
    }

    // 실행 영역
    if (open === true && searchButton === true) {
      startAxios(searchRequest);
    }
  }, [searchRequest, searchButton, open]);
  //AskStatus 구분 함수
  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.AskStatus === "HOLDING") {
          return "미청구(수납보류)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.AskStatus === "HOLDING") {
          return "미도래(수납보류)";
        } else {
          return "미도래";
        }
      case "UNPAID":
        if (row.AskStatus === "HOLDING") {
          return "미납(수납보류)";
        } else {
          return "미납";
        }
      case "PARTIALLY_PAID":
        if (row.AskStatus === "HOLDING") {
          return "부분납(수납보류)";
        } else {
          return "부분납";
        }
      case "PAID":
        return "수납";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "수납보류";
      default:
        if (row.askResult === "ASKING" && row.numberOfAsking === 1) {
          return "출금중(최초출금)";
        } else if (row.askResult === "ASKING" && row.numberOfAsking > 1) {
          return "출금중(재출금)";
        }
    }
  };

  //수납보류/수납보류해제/손실처리
  const fnSubmitReceiptAskResult = (row, name) => {
    const fnCallback = (objStatus, objData) => {
      // 실패시
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        return;
      }
      //성공시
      CM.cfnAlert(objData, () => {
        setSearchButton(true);
      });
    };
    let url = `api/receipt/source/${name}/${row.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnCallback);
  };

  //수납보류, 보류해제 버튼 컴포넌트
  const fnReceiptHolding = (row) => {
    if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID" || row.askResult === "PARTIALLY_PAID") && row.askStatus === "NORMAL") {
      return (
        <div>
          <button className="btn-s" type="button" onClick={(e) => fnSubmitReceiptAskResult(row, "holding")}>
            수납보류
          </button>
        </div>
      );
    } else if (row.askStatus === "HOLDING") {
      return (
        <div>
          <button className="btn-s" type="button" onClick={(e) => fnSubmitReceiptAskResult(row, "cancelHolding")}>
            보류해제
          </button>
        </div>
      );
    }
  };
  //손실처리 버튼 컴포넌트
  const fnReceiptLoss = (row) => {
    if (row.askResult === "UNASKED" || row.askResult === "UNPAID" || row.askResult === "PARTIALLY_PAID") {
      return (
        <div>
          <button className="btn-s" type="button" onClick={(e) => fnSubmitReceiptAskResult(row, "loss")}>
            손실처리
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleClickAllRowHoldingButton = () => {
    const tempArray = contractList.filter((source) => source.askStatus === "NORMAL").map((source) => source.uniqueKey);
    if (tempArray.length > 0) {
      CM.cfnConfirm("총 " + tempArray.length + "건을 일괄적으로 수납보류 처리하시겠습니까?", () => {
        CM.cfnAxios("/api/receipt/source/holding", "put", tempArray, (objStatus, objData) => {
          CM.cfnAlert(objData, () => {
            setSearchButton(true);
          });
        });
      });
    } else {
      CM.cfnAlert("수납보류 처리할 내역이 없습니다.", () => {});
    }
  };

  const holdEntireRowButton = () => {
    return (
      <>
        수납보류
        <br />
        <button className="btn-s" onClick={handleClickAllRowHoldingButton}>
          전체보류
        </button>
      </>
    );
  };

  const handleClickAllRowLossButton = () => {
    const tempArray = contractList.filter((source) => source.askResult !== "UNREACHED").map((source) => source.uniqueKey);
    if (tempArray.length > 0) {
      CM.cfnConfirm("총 " + tempArray.length + "건을 일괄적으로 손실처리하시겠습니까?", () => {
        CM.cfnAxios("/api/receipt/source/loss", "put", tempArray, (objStatus, objData) => {
          CM.cfnAlert(objData, () => {
            setSearchButton(true);
          });
        });
      });
    } else {
      CM.cfnAlert("손실처리할 내역이 없습니다.", () => {});
    }
  };

  const lossEntireRowButton = () => {
    return (
      <>
        손실처리
        <br />
        <button className="btn-s" onClick={handleClickAllRowLossButton}>
          전체손실
        </button>
      </>
    );
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {" "}
            </Button>
          </div>
          <h3>수납중지</h3>
          <div className="inforbox">
            <ul>
              <li>
                수납고객정보를 수납중지하더라도 당월 이전의 미납/미청구 내역은 청구 대상에 포함되므로, <br />
                미납/미청구 내역에 대해 청구하지 않으시려면 아래에서 수납보류 혹은 손실처리 작업을 해주시기 바랍니다.
              </li>
            </ul>
          </div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="th">고객명</TableCell>
                <TableCell className="td">{value.customer.customerName}</TableCell>
                <TableCell className="th">납부자번호</TableCell>
                <TableCell className="td">{value.payerNo}</TableCell>
                <TableCell className="th">자금종류</TableCell>
                <TableCell className="td">{value.capital.capitalName}</TableCell>
                <TableCell className="th">수납형태</TableCell>
                <TableCell className="td">{value.transactionMethod === "CMS_WITHDRAW" ? "CMS" : "기타"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="table-bottom-area">
            <Button className="table-bottom-button btn-l" onClick={fnReceiptStop("receiptStop")}>
              수납중지
            </Button>
          </div>
          <div className="h30" />
          <h4>미납/미청구 내역</h4>
          <Table aria-labelledby="tableTitle">
            {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
            <TableForm.compTableHead arrData={["수납대상월", "수납대상액", "수납액", "미납액", "수납 상태", holdEntireRowButton(), lossEntireRowButton()]} />
            <TableBody>
              {contractList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={7} />
              ) : (
                contractList.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                      <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount)}</TableCell>
                      <TableCell align="right">{CM.cfnAddComma(row.paidAmount)}</TableCell>
                      <TableCell align="right">{CM.cfnAddComma(row.unpaidAmount)}</TableCell>
                      <TableCell align="center">{fnAskStatus(row)}</TableCell>
                      <TableCell align="center">{fnReceiptHolding(row)}</TableCell>
                      <TableCell align="center">{fnReceiptLoss(row)}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <Table aria-labelledby="tableTitle">
            {CM.cfnCompColGroup(["25%", "25%", "25%", "25%"])}
            <TableForm.compTableHead arrData={["수납대상액 합계", "수납액 합계", "미납액 합계", "미청구액 합계"]} />
            <TableBody>
              <TableRow>
                <TableCell align="center">{CM.cfnAddComma(summeryList.totalAskingAmount)}원</TableCell>
                <TableCell align="center">{CM.cfnAddComma(summeryList.totalAmount)}원</TableCell>
                <TableCell align="center">{CM.cfnAddComma(summeryList.totalUnpaidAmount)}원</TableCell>
                <TableCell align="center">{CM.cfnAddComma(Number(summeryList.totalAskingAmount) - Number(summeryList.totalUnpaidAmount))}원</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </Modal>
  );
};
export default ReceiptStopModal;
