import React, { useState } from "react";
import { FileUploadForm } from "../../template/ComponentForm";
import * as Common from "../../common/Common";

import { Modal, Button } from "@material-ui/core";

const CustomerAddExcelModal = (props) => {
  const defaultValue = {
    file: null,
    fileName: "",
  };

  //필요 state 선언
  const [selectedFile, setSelectedFile] = useState(defaultValue);

  /*
   * @desc    고객기본정보 일괄등록 모달창 close 이벤트 핸들러
   */
  const handleModalClose = () => {
    setSelectedFile(defaultValue);
    props.setOpen(false);
  };

  /*
   * @desc    등록 버튼 클릭 이벤트 핸들러
   */
  const handleEvidenceFileUpload = (e) => {
    e.preventDefault();
    if (fnCheckValidation()) fnUploadExcel();
  };

  /*
   * @desc    파일 업로드 전 validation 체크 함수
   */
  const fnCheckValidation = () => {
    let isValid = true;

    if (Common.cfnIsEmpty(selectedFile.fileName)) {
      isValid = false;
      Common.cfnAlert("업로드할 양식을 선택하여 주시기 바랍니다.");
    } else if (selectedFile.fileName.indexOf("xls") === -1) {
      isValid = false;
      Common.cfnAlert("엑셀 형식의 파일만 업로드하실 수 있습니다.");
    }

    return isValid;
  };

  /*
   * @desc    엑셀파일 업로드 Request
   */
  const fnUploadExcel = () => {
    let form = new FormData();
    form.append("file", selectedFile.file);

    const url = "api/customer/basics/excel";
    Common.cfnAxios(
      url,
      "post",
      form,
      fnUploadExcelCallback,
      (error) => {
        setSelectedFile(defaultValue);
        Common.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      },
      true
    );
  };

  /*
   * @desc    엑셀파일 업로드 Request Callback
   */
  const fnUploadExcelCallback = (objStatus, objData) => {
    Common.cfnAlert("등록되었습니다.", () => {
      props.handleSearchButtonChange(true);
      handleModalClose();
    });
  };

  return (
    <Modal open={props.open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleModalClose} data-testid="customer-addBulk-close-modal">
              {""}
            </Button>
          </div>
          <h3>고객기본정보 일괄등록</h3>
          <div className="inforbox">
            <ul>
              <li>고객기본정보를 일괄등록 하시려면, 아래 양식을 다운로드 받으신 후 엑셀 파일을 편집하여 등록하셔야 합니다.</li>
              <li>일괄등록을 하시기 전 현재 관리하고 있는 데이터의 백업을 권장합니다.</li>
            </ul>
          </div>
          <h4>양식 다운로드</h4>
          <div className="download-area">
            고객 기본정보 일괄 등록 양식 다운로드 :{" "}
            <a href="/excel/고객기본정보.xlsx" download className="link">
              고객기본정보 일괄등록양식.xlsx
            </a>
          </div>
          <form name="evidenceFile" onSubmit={handleEvidenceFileUpload}>
            <div className="search-area">
              <div className="block">
                <label className="label-l">일괄등록용 파일</label>
                <FileUploadForm acceptFileType=".xls, .xlsx" needPreview={false} uploadFile={selectedFile} setUploadFile={setSelectedFile} />
              </div>
            </div>
            <div className="table-bottom-area">
              <button type="submit" className="btn-l" data-testid="customer-addBulk-submit-file" style={{ paddingLeft: "26px", paddingRight: "26px" }}>
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerAddExcelModal;
