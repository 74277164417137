import * as CM from "../../common/Common";

const CustomerData = {
  getCustomerEmail: (customer) => {
    let id = "";
    let domain = "";
    let selected = "manual";

    const email = customer.customerEmail;
    if (CM.cfnIsNotEmpty(email)) {
      const splitEmail = email.split("@");
      id = splitEmail[0];
      domain = splitEmail[1];
      selected = CM.cfnFindEmailDomain(splitEmail[1]);
    }

    return {
      id: id,
      domain: domain,
      selected: selected,
    };
  },
  getCustomerDetailInfo: (customer) => {
    let weddingAnniversary = null;
    let contactName = "";
    let contactPosition = "";
    let representativeName = "";
    let business = "";
    let sectors = "";

    let detailInfo = customer.customerDetailInfo;
    if (typeof detailInfo === "string" && CM.cfnIsNotEmpty(detailInfo)) {
      try {
        detailInfo = JSON.parse(detailInfo);
      } catch (e) {
        console.error(e);
        detailInfo = {};
      }
    }

    if (Object.isExtensible(detailInfo)) {
      weddingAnniversary = detailInfo.결혼기념일 || null;
      contactName = detailInfo.담당자명 || "";
      contactPosition = detailInfo.담당자직위 || "";
      representativeName = detailInfo.대표자명 || "";
      business = detailInfo.업태 || "";
      sectors = detailInfo.업종 || "";
    }

    return {
      결혼기념일: weddingAnniversary,
      담당자명: contactName,
      담당자직위: contactPosition,
      대표자명: representativeName,
      업태: business,
      업종: sectors,
    };
  },
  getCustomerRequiredInputs: (customer) => ({
    customerGroup: customer.customerGroup.uniqueKey, // 고객구분
    staff: customer.staff.uniqueKey, // 고객관리담당자
  }),
  getCustomer: (customer) => {
    let detailInfo = customer.customerDetailInfo;

    if (typeof detailInfo === "string" && CM.cfnIsNotEmpty(detailInfo)) {
      try {
        detailInfo = JSON.parse(detailInfo);
      } catch (e) {
        console.error(e);
        detailInfo = {};
      }
    }

    customer.customerDetailInfo = detailInfo;
    delete customer._checked;
    return customer;
  },
  getCashbillIdentificationNo: (customer) => {
    const cashbillInfo = customer.cashbillIdentificationNo;

    if (CM.cfnIsEmpty(cashbillInfo)) return "NONE";
    if (
      cashbillInfo.length === 11 ||
      (cashbillInfo.length === 10 &&
        (cashbillInfo.startsWith("011") || cashbillInfo.startsWith("012") || cashbillInfo.startsWith("016") || cashbillInfo.startsWith("017") || cashbillInfo.startsWith("019")))
    )
      return "MOBILE";
    return "IDENTIFICATION_NO";
  },
  getReceiptName: {
    transactionMethod: (transactionMethod) => {
      switch (transactionMethod) {
        case "CMS_WITHDRAW":
          return "CMS";
        case "AUTOMATIC_TRANSFER":
          return "자동이체";
        case "ELECTRONIC_RECEIPT":
          return "전자수납";
        case "FIXED_OCR":
          return "정액OCR";
        case "CMS_DEPOSIT":
          return "CMS";
        case "ETC":
          return "기타";
        default:
          return transactionMethod;
      }
    },
    accountRegistrationStatus: (accountRegistrationStatus) => {
      switch (accountRegistrationStatus) {
        case "READY_TO_REGISTER":
          return "등록대기";
        case "REGISTERING":
          return "등록중";
        case "REGISTERED":
          return "등록완료";
        case "FAILED_TO_REGISTER":
          return "등록실패";
        case "READY_TO_UNREGISTER":
          return "해지대기";
        case "UNREGISTERING":
          return "해지중";
        case "UNREGISTERED":
          return "해지완료";
        case "FAILED_TO_UNREGISTER":
          return "해지실패";
        default:
          return accountRegistrationStatus;
      }
    },
    contractStatus: (contractStatus) => {
      switch (contractStatus) {
        case "NORMAL":
          return "정상";
        case "PAUSED":
          return "수납중지";
        case "DELETED":
          return "삭제";
        default:
          return contractStatus;
      }
    },
    payPeriod: (payPeriod) => {
      switch (payPeriod) {
        case "MONTHLY":
          return "매월";
        case "EVERY_2_MONTHS":
          return "2개월마다";
        case "EVERY_3_MONTHS":
          return "3개월마다";
        case "EVERY_6_MONTHS":
          return "6개월마다";
        case "YEARLY":
          return "매년";
        case "VARIABLE":
          return "비정기";
        default:
          return payPeriod;
      }
    },
  },
  getPaymentName: {
    contractStatus: (contractStatus) => {
      switch (contractStatus) {
        case "NORMAL":
          return "정상";
        case "PAUSED":
          return "지급중지";
        case "DELETED":
          return "삭제";
        default:
          return contractStatus;
      }
    },
    askStatus: (askStatus) => {
      switch (askStatus) {
        case "NORMAL":
          return "정상/미청구";
        case "INITIAL":
          return "초기상태";
        case "ASKING":
          return "청구중";
        case "HOLDING":
          return "지급보류";
        default:
          return askStatus;
      }
    },
    payPeriod: (payPeriod) => {
      switch (payPeriod) {
        case "MONTHLY":
          return "매월";
        case "EVERY_2_MONTHS":
          return "2개월마다";
        case "EVERY_3_MONTHS":
          return "3개월마다";
        case "EVERY_6_MONTHS":
          return "6개월마다";
        case "YEARLY":
          return "매년";
        case "VARIABLE":
          return "비정기";
        default:
          return payPeriod;
      }
    },
    transactionMethod: (transactionMethod) => {
      switch (transactionMethod) {
        case "CMS_DEPOSIT":
          return "CMS";
        case "ETC":
          return "기타";
        default:
          return transactionMethod;
      }
    },
  },
  getDataName: {
    evidenceFileType: (evidenceFileType) => {
      switch (evidenceFileType) {
        case "PAPER":
          return "서면";
        case "PUBLIC_SIGNATURE":
          return "전자문서-공인전자서명";
        case "GENERAL_SIGNATURE":
          return "전자문서-일반전자서명";
        case "RECORDING":
          return "녹취";
        case "ARS":
          return "ARS";
        case "ETC":
          return "기타";
        case "PRIVATE_SIGNATURE":
          return "전자문서-사설전자서명";
        default:
          return evidenceFileType;
      }
    },
    accountFixingReason: (accountFixingReason) => {
      switch (accountFixingReason) {
        case "EVIDENCE_FILE_MISSING_ERROR":
          return "동의자료 미존재";
        case "ACCOUNT_ERROR":
          return "계좌 오류";
        case "IDENTIFICATION_ERROR":
          return "생년월일(사업자번호) 오류";
        case "EVIDENCE_TYPE_MISSING_ERROR":
          return "동의자료 구분 미입력";
        case "ETC_ERROR":
          return "기타 오류";
        default:
          return accountFixingReason;
      }
    },
  },
};

export default CustomerData;
