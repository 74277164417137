//import { useHistory } from "react-router-dom";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CustomerData from "../../customer/customerBasicInformation/CustomerData";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

// 검색 데이터
function searchData() {
  const today = new Date();
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return {
    transactionMethod: "",
    taxbillDirection: "RECEIPT",
    fromDate: monthAgo,
    toDate: today,
    customerGroupName: "",
    capitalName: "",
    searchSelect: "customerName",
    customerName: "",
    identificationNo: "",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "customerName",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">수납방법</label>
        <FormControl component="fieldset">
          <RadioGroup name="transactionMethod" value={searchRequest.transactionMethod} onChange={handleSearchFormChange("transactionMethod")} data-testid="unpaid-period" row>
            <FormControlLabel value="" control={<Radio color="primary" />} label="전체" />
            <FormControlLabel value="CMS_WITHDRAW" control={<Radio color="primary" />} label="CMS" />
            <FormControlLabel value="ETC" control={<Radio color="primary" />} label="기타" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">전자세금계산서 구분</label>
        <FormControl component="fieldset">
          <RadioGroup name="taxbillDirection" value={searchRequest.taxbillDirection} onChange={handleSearchFormChange("taxbillDirection")} data-testid="unpaid-period" row>
            <FormControlLabel value="RECEIPT" control={<Radio color="primary" />} label="영수" />
            <FormControlLabel value="INVOICE" control={<Radio color="primary" />} label="청구" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">영수(청구)기간</label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.fromDate}
          handleChange={handleSearchFormChange("fromDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.toDate}
          handleChange={handleSearchFormChange("toDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <br />
        <br />
        <label className="label-l">관리기준</label>
        <Select
          native
          value={searchRequest.customerGroupName}
          onChange={handleSearchFormChange("customerGroupName")}
          name="customerGroupName"
          inputProps={{ "data-testid": "electronictaxbillList-select-group" }}>
          <option value={""} key={"none"}>
            고객구분
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <Select native value={searchRequest.capitalName} onChange={handleSearchFormChange("capitalName")} name="capitalName" inputProps={{ "data-testid": "electronictaxbillList-select-capital" }}>
          <option value={""} key={"none"}>
            자금종류
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.searchSelect} onChange={handleSearchFormChange("searchSelect")} inputProps={{ "data-testid": "electronictaxbillList-select-terms" }}>
          <option value="customerName">고객명</option>
          <option value="identificationNo">주민등록번호(사업자번호)</option>
        </Select>
        <TextField
          className="w130"
          value={searchRequest.searchInput}
          onChange={handleSearchFormChange("searchInput")}
          onKeyUp={handleSearchKeyUp}
          name="search"
          inputProps={{ "data-testid": "electronictaxbillList-textfield-search" }}
        />
        <button className="search-button" data-testid="search-history" onClick={() => fnSearch()}>
          발행 대상 조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.setTaxbillsList((taxbills) => taxbills.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.taxbillsList];
    cloneData[index][key] = value;
    props.setTaxbillsList(cloneData);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}

        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "transactionDate", label: "영수(청구)일", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "identificationNo", label: "주민등록번호\n(사업자번호)", sortable: false },
            { id: "transactionMethod", label: "수납방법", sortable: false },
            { id: "customerGroupName", label: "고객구분", sortable: false },
            { id: "capitalName", label: "자금종류", sortable: false },
            { id: "issueTargetAmount", label: "발행대상금액", sortable: false },
            { id: "ceoName", label: "대표자명", sortable: false },
            { id: "customerEmail", label: "이메일주소", sortable: false },
            { id: "errorDesc", label: "오류내용", sortable: false },
            { id: "isPenalties", label: "가산세대상 여부", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />

        <TableBody>
          {props.taxbillsList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={12} />
          ) : (
            props.taxbillsList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.transactionDate)}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                  <TableCell align="center">{CustomerData.getReceiptName.transactionMethod(row.transactionMethod)}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center">{row.capitalName}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.issueTargetAmount)}</TableCell>
                  <TableCell align="center">{row.ceoName}</TableCell>
                  <TableCell align="center">{row.customerEmail}</TableCell>
                  <TableCell align="center">{row.errorDesc}</TableCell>
                  <TableCell align="center">{row.isPenalties ? "가산세 대상" : null}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function ElectronicTaxbillsRequest(props) {
  const { tabIndex } = props;
  const { store } = props;

  const [excelButton, setExcelButton] = useState(false);
  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [taxbillsList, setTaxbillsList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());
  const [capitalList, setCapitalList] = useState([]);
  const [customerGroupList, setCustomerGroupList] = useState([]);

  useEffect(() => {
    /*
     * @desc 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);
      tempObj.toDate = CM.cfnConvertDateToString(searchRequest.toDate);
      tempObj.fromDate = CM.cfnConvertDateToString(searchRequest.fromDate);

      return tempObj;
    };

    /*
     * @desc    처리결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/taxbill/electronic/targets?fromDate=${search.fromDate}&toDate=${search.toDate}&taxbillDirection=${search.taxbillDirection}&transactionMethod=${search.transactionMethod}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&capitalName=${search.capitalName}&customerGroupName=${search.customerGroupName}&customerName=${search.customerName}&identificationNo=${search.identificationNo}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    처리결과조회 Request
     */
    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/taxbill/electronic/targets/excel?fromDate=${search.fromDate}&toDate=${search.toDate}&taxbillDirection=${search.taxbillDirection}&transactionMethod=${search.transactionMethod}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&capitalName=${search.capitalName}&customerGroupName=${search.customerGroupName}&customerName=${search.customerName}&identificationNo=${search.identificationNo}`;

        CM.cfnAxiosFileDownload(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      if (buttonType === "excel") {
        setExcelButton(false);
        await axiosExcel(search);
      } else {
        setSearchButton(false);
        if (CM.cfnAddDate(search.fromDate, search.toDate, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }
        const resultData = await axiosList(search);
        let resultList = resultData.content;

        if (CM.cfnIsEmpty(resultList)) {
          resultList = [];
        }
        // 체크 옵션 추가
        if (CM.cfnIsNotEmpty(resultList)) {
          for (const element of resultList) {
            element._checked = false;
          }
        }
        setOriginalList(resultData);
        setTaxbillsList(resultList);
        setPagination(paginationData(resultData));
      }
    }

    // 실행 영역
    if (tabIndex === 0 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "search");
    } else if (tabIndex === 0 && excelButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "excel");
    }
  }, [tabIndex, searchRequest, searchButton, excelButton]);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      setCustomerGroupList(await store.axiosCustomerGroup()); // 고객구분
      setCapitalList(await store.axiosCapitals()); // 자금종류
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "fromDate" || name === "toDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else if (e.target.type === "checkbox") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.checked,
      });
    } else if (name === "searchInput") {
      if (searchRequest.searchSelect === "" || searchRequest.searchSelect === "customerName") {
        setSearchRequest({
          ...searchRequest,
          customerName: e.target.value,
          identificationNo: "",
        });
      } else {
        setSearchRequest({
          ...searchRequest,
          customerName:"",
          identificationNo: e.target.value,
        });
      }
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  const handleClickExcelTaxbills = (flag) => setExcelButton(flag);

  const handleClickTaxbills = () => {
    const sendData = []; // 발행처리를 위한 data
    const url = "api/extraservice/taxbill/electronic/targets";
    let neededToAdjustDueToCeoName = 0, neededToAdjustDueToCustomerEmail = 0;
    for (const taxbills of taxbillsList) {
      if (taxbills._checked) {
        if (taxbills.identificationNo && taxbills.identificationNo.length === 10 && (!taxbills.ceoName || taxbills.ceoName === "")) {
          neededToAdjustDueToCeoName++;
        } else if (!taxbills.customerEmail || taxbills.customerEmail === "") {
          neededToAdjustDueToCustomerEmail++;
        } else {
          const copyObj = CM.cfnCopyObject(taxbills);
          sendData.push(copyObj);
        }
      }
    }

    let warningMessage = "";
    if (neededToAdjustDueToCeoName > 0) {
      warningMessage =
        neededToAdjustDueToCeoName +
        neededToAdjustDueToCustomerEmail +
        "건(대표자명 미기재 " +
        neededToAdjustDueToCeoName +
        "건" +
        (neededToAdjustDueToCustomerEmail > 0 ? ", 이메일주소 미기재 " + neededToAdjustDueToCustomerEmail + "건)" : ")의 자료에 대한 보완이 필요합니다.\n");
    } else if (neededToAdjustDueToCustomerEmail > 0) {
      warningMessage = neededToAdjustDueToCustomerEmail + "건(이메일주소 미기재 " + neededToAdjustDueToCustomerEmail + "건)의 자료에 대한 보완이 필요합니다.\n";
    }
    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      return CM.cfnAlert(warningMessage + "전자세금계산서를 발행할 대상을 선택해주세요.");
      // return window.alert("발행할 영수증을 선택해주세요");
    }

    if (neededToAdjustDueToCeoName > 0 || neededToAdjustDueToCustomerEmail > 0) {
      CM.cfnConfirm(warningMessage + "보완이 필요한 자료를 제외하고, 전자세금계산서를 발행하시겠습니까?", () => {
        // axios 호출
        CM.cfnAxios(url, "post", sendData, (status, response) => {
          CM.cfnAlert("정상적으로 처리 되었습니다.", () => {
            handleSearchButtonChange(true); // 데이터 재조회
          });
        });
      });
    }else{
      CM.cfnConfirm("전자세금계산서를 발행하시겠습니까?", () => {
        // axios 호출
        CM.cfnAxios(url, "post", sendData, (status, response) => {
          CM.cfnAlert("정상적으로 처리 되었습니다.", () => {
            handleSearchButtonChange(true); // 데이터 재조회
          });
        });
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        storeCapital={capitalList} // 자금종류
        storeCustomerGroup={customerGroupList} // 고객구분
      />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="electronictaxbillList-rowPerPage-select" />
        <button className="btn-l fr" data-testid="electronic-taxbills" onClick={(e) => handleClickTaxbills()}>
          전자세금계산서 발행
        </button>
        <button className="btn-m fr" data-testid="save-excel" onClick={(e) => handleClickExcelTaxbills(true)}>
          엑셀저장
        </button>
      </div>
      <ListForm
        taxbillsList={taxbillsList}
        setTaxbillsList={setTaxbillsList}
        pagination={pagination}
        page={searchRequest.pageNumber}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <Template.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} testId="electronictaxbillList-list-pagiation" />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(ElectronicTaxbillsRequest));
