import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Pagination from "material-ui-flat-pagination";
import * as CM from "../../common/Common";

// 부가서비스 > 현금영수증 > 현금영수증 발행내역 > 고객별 월별 합산 form
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabIssuedCustomerInformation = (props) => {
  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    const sortProperty = sortObjArray.find((obj) => obj.name === "sortProperty").value;
    const sortDirection = sortObjArray.find((obj) => obj.name === "sortDirection").value;

    props.handleSearchCondition((data) => ({ ...data, sortProperty, sortDirection }));

    // 정렬조건 세팅 후 검색 trigger
    props.handleSearchStart(true);
  };

  return (
    <div className="scroll-table">
      <div className="left-table-div">
        <Table>
          {CM.cfnCompColGroup(["17%", "17%", "17%", "15%", "15%", "17%", "2%"])}
          <TableForm.compServerSortTableHead
            useCheckbox={false}
            value=""
            arrData={[
              { id: "customerName", label: "고객명", sortable: true },
              { id: "cashbillIdentificationNo", label: "신분확인번호", sortable: false },
              { id: "payerNo", label: "납부자번호", sortable: true },
              { id: "customerGroupName", label: "고객구분", sortable: true },
              { id: "capitalName", label: "자금종류", sortable: true },
              { id: "issuedAmounts", label: "발행금액", sortable: false },
              { id: "issuedAmounts2", label: "", sortable: false },
            ]}
            searchRequest={props.searchCondition}
            handleSortProperty={handleSortProperty}
            tableSortLabelDataTestId={"cashbillTabIssuedCustomerInformation-list-head-sortLabel"}
          />
          <TableBody>
            {props.list.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={7} />
            ) : (
              props.list.map((target) => {
                return (
                  <TableRow
                    hover
                    key={target.customerUniqueKey}
                    className="show-detail"
                    onClick={() => props.searchDetail(target.customerUniqueKey, target.payerNo)}
                    data-testid={`cashbill-issued-goDetail-${target.customerUniqueKey}`}>
                    <TableCell align="center" className="ellipsis" title={target.customerName}>
                      {target.customerName}
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo)}>
                      {CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo)}
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={target.payerNo}>
                      {target.payerNo}
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={target.customerGroupName}>
                      {target.customerGroupName}
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={target.capitalName}>
                      {target.capitalName}
                    </TableCell>
                    <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(target.issuedAmounts.reduce((accumulator, currentValue) => accumulator + currentValue))}>
                      {CM.cfnAddComma(target.issuedAmounts.reduce((accumulator, currentValue) => accumulator + currentValue))}
                    </TableCell>
                    <TableCell align="right" />
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["90px", "90px", "90px", "90px", "90px", "90px", "90px", "90px", "90px", "90px", "90px", "90px"])}
            <TableHead className="table-head-line">
              <TableRow>
                {props.dynamicHead.map((element, index) => {
                  return (
                    <TableCell key={index} align="center">
                      {element}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={12} />
              ) : (
                props.list.map((target) => {
                  return (
                    <TableRow hover key={target.customerUniqueKey}>
                      {target.issuedAmounts.map((issuedAmounts, index) => {
                        return (
                          <TableCell align="right" key={index} className="ellipsis" title={CM.cfnAddComma(issuedAmounts)}>
                            {CM.cfnAddComma(issuedAmounts)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <Pagination
        className="centered"
        limit={props.pagination.rowsPerPage}
        offset={props.pagination.offset}
        total={props.pagination.total}
        onClick={(e, offset, page) => props.handlePage(page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
        data-testid="cashbill-issued-pagination"
      />
    </div>
  );
};

export default CashbillTabIssuedCustomerInformation;
