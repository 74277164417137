import { Checkbox, FormControl, FormControlLabel, Link, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import { trusbillErrorMessageObject, trusbillNtsErrorMessageObject } from "./ElectronicTaxbillData";

// 검색 데이터
function searchData() {
  const today = new Date();
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return {
    taxbillDirection: "RECEIPT",
    fromDate: monthAgo,
    toDate: today,
    searchSelect: "customerName",
    customerName: "",
    identificationNo: "",
    statusMessage: "all",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "customerName",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">전자세금계산서 구분</label>
        <FormControl component="fieldset">
          <RadioGroup name="taxbillDirection" value={searchRequest.taxbillDirection} onChange={handleSearchFormChange("taxbillDirection")} data-testid="unpaid-period" row>
            <FormControlLabel value="RECEIPT" control={<Radio color="primary" />} label="영수" />
            <FormControlLabel value="INVOICE" control={<Radio color="primary" />} label="청구" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">발행기간</label>
        <Template.DatePickerForm
          data-testid="test-datepicker-01"
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.fromDate}
          handleChange={handleSearchFormChange("fromDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          data-testid="test-datepicker-02"
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.toDate}
          handleChange={handleSearchFormChange("toDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <br />
        <br />
        <label className="label-l">발행상태</label>
        <Select native value={searchRequest.statusMessage} onChange={handleSearchFormChange("statusMessage")} inputProps={{ "data-testid": "electronictaxbillHistoryList-status-terms" }}>
          <option value="all">전체</option>
          <option value="sendingTrusbill">트러스빌 전송중</option>
          <option value="centerError">트러스빌 센터오류</option>
          <option value="sendingNts">국세청 전송중</option>
          <option value="errorNts">국세청 오류수신</option>
          <option value="successIssued">발행완료</option>
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.searchSelect} onChange={handleSearchFormChange("searchSelect")} inputProps={{ "data-testid": "electronictaxbillHistoryList-select-terms" }}>
          <option value="customerName">고객명</option>
          <option value="identificationNoFirst7">주민등록번호(사업자번호)</option>
        </Select>
        <TextField
          className="w130"
          value={searchRequest.searchInput}
          onChange={handleSearchFormChange("searchInput")}
          onKeyUp={handleSearchKeyUp}
          name="search"
          inputProps={{ "data-testid": "electronictaxbillHistoryList-textfield-search" }}
        />
        <button className="search-button" data-testid="search-history" onClick={() => fnSearch()}>
          발행내역조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state
  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.setTaxbillsList((taxbills) => taxbills.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.taxbillsList];
    cloneData[index][key] = value;
    props.setTaxbillsList(cloneData);
  };

  //const fnIssueStatus = () => {};
  const fnTrusbillLink = (row) => {
    CM.cfTrsbTaxAccess("E", row.trusbillLink, null, null, null);
  };

  const errorDetailMessage = (row) => {
    switch (row.statusMessage) {
      case "트러스빌 전송중":
      case "국세청 전송중":
      case "발행완료":
        return null;
      case "국세청 오류수신":
        if (row.ntsResult) {
          return <Template.TooltipForm contents={trusbillNtsErrorMessageObject[row.ntsResult] || ""} />;
        } else {
          return null;
        }
      case "트러스빌 센터 오류":
        if (row.loadStatus) {
          return <Template.TooltipForm contents={trusbillErrorMessageObject[row.loadStatus] || ""} />;
        } else {
          return null;
        }
      default:
        return null;
    }
  };
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "10%", "10%", "10%", "auto", "auto"])}
        {/* <TableForm.compCheckboxTableHead
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={["영수(청구)일", "고객명", "생년원일\n(사업자번호)", "공급가액", "세액", "합계", "발행상태", "트러스빌 확인"]}
        /> */}

        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "transactionDate", label: "발행일", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "identificationNo", label: "주민등록번호\n(사업자번호)", sortable: false },
            { id: "suplyAmt", label: "공급가액", sortable: false },
            { id: "vat", label: "세액", sortable: false },
            { id: "trdAmtTotAmt", label: "합계", sortable: false },
            { id: "resultMessage", label: "발행상태", sortable: true },
            { id: "resultCode", label: "트러스빌 확인", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.taxbillsList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.taxbillsList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.transactionDate)}</TableCell>
                  <TableCell align="center" data-testid="test-customer-name">
                    {row.customerName}
                  </TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.supplyAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.vatAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalAmount)}원</TableCell>
                  <TableCell align="center">
                    {row.statusMessage}
                    {errorDetailMessage(row)}
                  </TableCell>
                  <TableCell align="center">
                    {row.trusbillLink !== "" && row.trusbillLink !== null ? (
                      <Link to="" className="btn-m" onClick={() => fnTrusbillLink(row)}>
                        확인
                      </Link>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function ElectronicTaxbillsResult(props) {
  const { tabIndex } = props;
  const { store } = props;
  const [excelButton, setExcelButton] = useState(false);
  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [taxbillsList, setTaxbillsList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  useEffect(() => {
    /*
     * @desc  파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.toDate = CM.cfnConvertDateToString(searchRequest.toDate);
      tempObj.fromDate = CM.cfnConvertDateToString(searchRequest.fromDate);

      return tempObj;
    };

    /*
     * @desc    결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `/api/extraservice/taxbill/electronic/issued?fromDate=${search.fromDate}&toDate=${search.toDate}&taxbillDirection=${search.taxbillDirection}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&customerName=${search.customerName}&identificationNo=${search.identificationNo}&statusMessage=${search.statusMessage}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc  엑셀저장
     */
    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `/api/extraservice/taxbill/electronic/issued/excel?fromDate=${search.fromDate}&toDate=${search.toDate}&taxbillDirection=${search.taxbillDirection}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&customerName=${search.customerName}&identificationNo=${search.identificationNo}&statusMessage=${search.statusMessage}`;

        CM.cfnAxiosFileDownload(url, "get", "", () => {});
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      if (buttonType === "excel") {
        await setExcelButton(false);
        await axiosExcel(search);
      } else {
        await setSearchButton(false);
        if (CM.cfnAddDate(search.fromDate, search.toDate, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }
        const resultData = await axiosList(search);

        let resultList = resultData.content;
        if (CM.cfnIsEmpty(resultList)) {
          resultList = [];
        }
        // 체크 옵션 추가
        if (CM.cfnIsNotEmpty(resultList)) {
          for (const element of resultList) {
            element._checked = false;
          }
        }

        await setOriginalList(resultData);
        await setTaxbillsList(resultList);
        await setPagination(paginationData(resultData));
      }
    }

    // 실행 영역
    if (tabIndex === 1 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "search");
    } else if (tabIndex === 1 && excelButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "excel");
    }
  }, [tabIndex, searchRequest, searchButton, excelButton]);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    실시간 실명조회 결과조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "fromDate" || name === "toDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
        pageNumber: 0,
      });
    } else if (e.target.type === "checkbox") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.checked,
      });
    } else if (name === "searchInput") {
      if (searchRequest.searchSelect === "" || searchRequest.searchSelect === "customerName") {
        setSearchRequest({
          ...searchRequest,
          customerName: e.target.value,
          identificationNo: "",
          pageNumber: 0,
        });
      } else {
        setSearchRequest({
          ...searchRequest,
          customerName: "",
          identificationNo: e.target.value,
          pageNumber: 0,
        });
      }
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
        pageNumber: 0,
      });
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  const handleClickCancelTaxbills = () => {
    const sendData = []; // 발행취소처리를 위한 data
    const url = "api/extraservice/taxbill/electronic/issued";
    for (const taxbills of taxbillsList) {
      if (taxbills._checked) {
        const copyObj = CM.cfnCopyObject(taxbills);
        sendData.push(copyObj);
      }
    }

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      return CM.cfnAlert("발행취소할 영수증을 선택해주세요.");
      // return window.alert("취소할 영수증을 선택해주세요");
    }

    // axios 호출
    CM.cfnAxios(url, "post", sendData, (status, response) => {
      CM.cfnAlert(response, () => {
        handleSearchButtonChange(true); // 데이터 재조회
      });
    });
  };

  const handleClickExcelTaxbills = (flag) => setExcelButton(flag);

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
      />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="electronictaxbillHistoryList-rowPerPage-select" />
        <button className="btn-l fr" data-testid="delete-select-Receipt" onClick={(e) => handleClickCancelTaxbills()}>
          발행취소(완료건)
        </button>
        <button className="btn-m fr" data-testid="save-excel" onClick={(e) => handleClickExcelTaxbills(true)}>
          엑셀저장
        </button>
      </div>
      <ListForm
        taxbillsList={taxbillsList}
        // checkAllRow={checkAllRow}
        // handleCheckAllRowChange={handleCheckAllRowChange}
        setTaxbillsList={setTaxbillsList}
        pagination={pagination}
        page={searchRequest.pageNumber}
        // handleUpdateChange={handleUpdateChange}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <Template.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} testId="electronictaxbillHistoryList-list-pagiation" />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(ElectronicTaxbillsResult));
