import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import BilingonePlus from "./BillingonePlus";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./styles/Theme";
import { Provider } from "mobx-react";
import RootStore from "./stores";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// 알람 컴포넌트
import AlertForm from "./components/template/AlertForm";
import ConfirmForm from "./components/template/ConfirmForm";

//axios Loading image
import LoadingImage from "./components/template/LoadingImage";

const root = new RootStore(); // 루트 스토어 생성

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <Provider {...root}>
        <BilingonePlus />
        <AlertForm />
        <ConfirmForm />
        <LoadingImage />
      </Provider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.register();
