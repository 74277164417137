import React from "react";
import * as Cm from "../../common/Common";
import ReactState from "react";

import { Modal, Table, TableBody, TableCell, TableRow, Button } from "@material-ui/core";
import TableForm from "../../template/TableForm";
import { PaginationForm } from "../../template/ComponentForm";

/*
 * @desc    메인 컴포넌트 선언
 */
const ReceiptCustomerChangeHistoriesModal = ({ open, setOpen, object }) => {
  const [pagination, setPagination] = ReactState.useState({ rowsPerPage: 5, offset: 0, page: 0 });

  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  /*
   * @desc    페이지 변경 이벤트 핸들러
   */
  const handleOffsetChange = (offset, page) => {
    setPagination({
      ...pagination,
      offset: offset,
      page: page - 1,
    });
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {" "}
            </Button>
          </div>
          <h3>수납고객정보 변경이력</h3>
          <Table aria-labelledby="tableTitle">
            {Cm.cfnCompColGroup(["5%", "auto", "auto", "auto", "5%", "auto", "auto", "auto", "auto"])}
            <TableForm.compTableHead arrData={["순번", "변경일자", "변경항목", "변경전", "", "변경후", "변경자", "수정적용월", "비고"]} />
            <TableBody>
              {!object || object.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={9} />
              ) : (
                object.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage).map((row, index) => {
                  return (
                    <TableRow key={index} hover tabIndex={-1}>
                      <TableCell align="center"> {index + 1} </TableCell>
                      <TableCell align="center">{Cm.cfnDateFormat(row.changedDatetime.toString())}</TableCell>
                      <TableCell align="center">{row.changeTarget}</TableCell>
                      <TableCell align="center">{row.beforeChange}</TableCell>
                      <TableCell align="center">&rarr;</TableCell>
                      <TableCell align="center">{row.afterChange}</TableCell>
                      <TableCell align="center" data-testid={"test-column-" + index}>
                        {row.appliedStaffName}
                      </TableCell>
                      <TableCell align="center">{Cm.cfnDateFormat(row.expectedApplyY4mm, "yyyyMM")}</TableCell>
                      <TableCell align="center">{row.note || ""}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <PaginationForm filteredRows={object || []} pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
        </div>
      </div>
    </Modal>
  );
};
export default ReceiptCustomerChangeHistoriesModal;
