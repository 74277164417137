import React, { useState, useEffect, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";

const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "650px",
  },
});

const DEFAULT_HOOKS = {
  searchStart: false,
  modificationsList: [],
  searchCondition: (applyDate) => ({
    applyDate: applyDate,
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "customerName",
    customerName: "",
    cashbillIdentificationNo: "",
    payerNo: "",
  }),
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
  searchForm: {
    terms: "customerName",
    input: "",
  },
};

// 부가서비스 > 현금영수증 > 현금영수증 직접발행ㆍ취소 > 금액/대상 수정 modal
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabTargetsModalModifications = (props) => {
  const classes = useStyles();

  // hooks
  const [searchStart, setSearchStart] = useState(DEFAULT_HOOKS.searchStart);
  const [modificationsList, setModificationsList] = useState(DEFAULT_HOOKS.modificationsList);
  const [searchCondition, setSearchCondition] = useState(DEFAULT_HOOKS.searchCondition(props.applyDate));
  const [pagination, setPagination] = useState(DEFAULT_HOOKS.pagination);
  const [searchForm, setSearchForm] = useState(DEFAULT_HOOKS.searchForm);

  // handler
  const handleSearchForm = ({ target: { value } }, key) => setSearchForm((data) => ({ ...data, [key]: value }));
  const handleSearchCondition = (value) => setSearchCondition(value);
  const handleModificationsList = (value) => setModificationsList(value);
  const handleSearchStart = (value) => setSearchStart(value);
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  const handlePage = (e, offset, page) => {
    handleSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchStart(true);
  };

  const handleRowPerPage = ({ target: { value } }) => {
    handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchStart(true);
  };

  // useCallback
  // hooks 초기화
  const initializeHooks = useCallback(() => {
    setSearchStart(DEFAULT_HOOKS.searchStart);
    setModificationsList(DEFAULT_HOOKS.modificationsList);
    setSearchCondition(DEFAULT_HOOKS.searchCondition(props.applyDate));
    setPagination(DEFAULT_HOOKS.pagination);
  }, [props.applyDate]);

  // 현금영수증 발행수정대상 목록 가져오기
  const getModificationsList = useCallback(
    (parameter) => {
      return new Promise((resolve) => {
        let url = "api/extraservice/cashbill/targets";
        url += `?pageNumber=${parameter.pageNumber}&pageSize=${parameter.pageSize}`;
        url += `&sortDirection=${parameter.sortDirection}&sortProperty=${parameter.sortProperty}`;
        url += `&fileUniqueKey=${props.summary.uniqueKey}`;
        url += `&customerName=${parameter.customerName}&cashbillIdentificationNo=${parameter.cashbillIdentificationNo}&payerNo=${parameter.payerNo}`;

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    },
    [props.summary]
  );

  // useEffect
  // 모달 state에 변화가 생길 때 화면 초기화
  useEffect(() => {
    if (props.modalOpen.modifications) handleSearchStart(true);
    return () => initializeHooks();
  }, [props.modalOpen.modifications, initializeHooks]);

  // 현금영수증 발생수정대상 목록 설정
  useEffect(() => {
    const startAxios = async (parameter) => {
      const resultTargets = await getModificationsList(parameter);

      handleModificationsList(resultTargets.content);
      handlePagination(resultTargets);
    };

    if (searchStart) startAxios(searchCondition);

    return () => handleSearchStart(false);
  }, [searchCondition, searchStart, getModificationsList]);

  // 수정내용 저장
  const saveModifications = () => {
    CM.cfnConfirm("저장하시겠습니까?", () => {
      const targetDtoList = modificationsList.map((value) => (typeof value.issuingAmount === "string" ? { ...value, issuingAmount: Number(CM.cfnReplaceSymbol(value.issuingAmount)) } : value));

      const url = `api/extraservice/cashbill/targets/${props.summary.uniqueKey}`;

      CM.cfnAxios(url, "put", targetDtoList, (status, data) => {
        CM.cfnAlert("수정되었습니다.", () => {
          handleSearchStart(true);
          props.handleSearchStart(true);
        });
      });
    });
  };

  return (
    <Modal open={props.modalOpen.modifications}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpen("modifications", false)} data-testid="close-cashbill-modifications-modal">
              {""}
            </Button>
          </div>
          <h3>현금영수증 발행 대상 수정</h3>
          <div className="inforbox">
            <ul>
              <li>신분확인번호는 주민등록번호, 휴대전화번호, 사업자등록번호 중 하나입니다.</li>
            </ul>
          </div>
          <div>
            <SearchComponent searchForm={searchForm} handleSearchForm={handleSearchForm} handleSearchCondition={handleSearchCondition} handleSearchStart={handleSearchStart} />
          </div>
          <div className="table-top-area">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} customProps={{ inputProps: { "data-testid": "modifications-rowPerPage-select-inputProps" } }} />
            <button className="btn-l fr table-top-button" data-testid="save-modifications-button" onClick={saveModifications}>
              수정내용 저장
            </button>
          </div>
          <div>
            <ModificationsListComponent
              list={modificationsList}
              handleModificationsList={handleModificationsList}
              pagination={pagination}
              handlePage={handlePage}
              searchCondition={searchCondition}
              handleSearchCondition={handleSearchCondition}
              handleSearchStart={handleSearchStart}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

// 검색 컴포넌트
const SearchComponent = (props) => {
  const optionList = [
    { value: "customerName", label: "고객명" },
    { value: "cashbillIdentificationNo", label: "신분확인번호" },
    { value: "payerNo", label: "납부자번호" },
  ];

  const handleSearchKeyUp = ({ keyCode }) => {
    if (keyCode === 13) startSearch();
  };

  const startSearch = async () => {
    props.handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      customerName: props.searchForm.terms === "customerName" ?  props.searchForm.input : "",
      cashbillIdentificationNo : props.searchForm.terms === "cashbillIdentificationNo" ?  props.searchForm.input : "",
      payerNo: props.searchForm.terms === "payerNo" ?  props.searchForm.input : "",
    }));
    props.handleSearchStart(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <Select native value={props.searchForm.terms} onChange={(e) => props.handleSearchForm(e, "terms")} inputProps={{ "data-testid": "modifications-select-terms" }}>
          {optionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField className="w250" value={props.input} onChange={(e) => props.handleSearchForm(e, "input")} onKeyUp={handleSearchKeyUp} data-testid="modifications-input-input" />
        <button className="search-button" onClick={startSearch} data-testid="modifications-button-search">
          검색
        </button>
      </div>
    </div>
  );
};

// 수정대상 목록 컴포넌트
const ModificationsListComponent = (props) => {
  // 발행금액, 신분 확인번호 수정을 처리하는 함수
  const handleModificationsListChange = ({ target: { value } }, index, key) => {
    const prevValue = value.replace(/[^0-9]/g, "");
    const nowValue = key === "issuingAmount" ? CM.cfnAddCommaOnChange(CM.cfnLtrimZero(prevValue)) : prevValue;
    props.handleModificationsList((data) => data.map((element, idx) => (idx === Number(index) ? { ...element, [key]: nowValue } : { ...element })));
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    const sortProperty = sortObjArray.find((obj) => obj.name === "sortProperty").value;
    const sortDirection = sortObjArray.find((obj) => obj.name === "sortDirection").value;

    props.handleSearchCondition((data) => ({ ...data, sortProperty, sortDirection }));

    // 정렬조건 세팅 후 검색 trigger
    props.handleSearchStart(true);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "auto", "120px", "auto", "150px"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          value=""
          arrData={[
            { id: "issuingType", label: "요청구분", sortable: false },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "transactionMethod", label: "수납방법", sortable: true },
            { id: "receiptDate", label: "수납일", sortable: false },
            { id: "paidAmount", label: "출금금액", sortable: true },
            { id: "issuingAmount", label: "발행금액", sortable: true },
            { id: "issuePurposeType", label: "발행구분", sortable: true },
            { id: "cashbillIdentificationNo", label: "신분확인번호", sortable: false },
          ]}
          searchRequest={props.searchCondition}
          handleSortProperty={handleSortProperty}
          tableSortLabelDataTestId={"modifications-list-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionType(target.transactionType)}</TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionMethod(target.transactionMethod)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.receiptDate, "yyyyMMdd")}</TableCell>
                  <TableCell align="center">{CM.cfnAddComma(target.paidAmount)}원</TableCell>
                  <TableCell align="center">
                    {target.isExempted ? (
                      CM.cfnAddComma(target.issuingAmount)
                    ) : (
                      <Input
                        value={CM.cfnAddCommaOnChange(target.issuingAmount) || 0}
                        onChange={(event) => handleModificationsListChange(event, index, "issuingAmount")}
                        inputProps={{
                          style: { textAlign: "right" },
                        }}
                        data-testid={`modifications-input-issuingAmount-${index}`}
                        endAdornment="원"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.purposeType(target.purposeType)}</TableCell>
                  <TableCell align="center">
                    {target.isExempted ? (
                      target.cashbillIdentificationNo
                    ) : (
                      <Input
                        value={(target.cashbillIdentificationNo.length === 10 || target.cashbillIdentificationNo.length === 11) ? CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo) :
                            (target.cashbillIdentificationNo.length === 13) ? CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo, true) : (target.cashbillIdentificationNo || "")}
                        onChange={(event) => handleModificationsListChange(event, index, "cashbillIdentificationNo")}
                        data-testid={`modifications-input-cashbillIdentificationNo-${index}`}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <CF.PaginationForm pagination={props.pagination} onClick={props.handlePage} testId="cashbill-modifications-pagination" />
    </div>
  );
};

export default CashbillTabTargetsModalModifications;
