import { Button, Modal } from "@material-ui/core";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { PaperTaxBill } from "./PaperTaxBillForm";

/*
 * @desc  메인 컴포넌트
 */
const PaperTaxBillPrint = ({ open, setOpen, setSelectedTaxBills, selectedTaxBills, master }) => {
  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();
  // 모달 클로즈
  const handleClose = () => {
    setSelectedTaxBills([]);
    setOpen(false);
  };
  // 화면 렌더
  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          width: "870px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={(e) => handleClose()} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>{selectedTaxBills && selectedTaxBills.length > 0 && selectedTaxBills[0].taxbillType === "PAPER" ? "종이세금계산서" : "종이계산서"} 인쇄</h3>
          <div className="inforbox">
            <ul>
              <li>양식의 글씨가 미리보기에 나타나지 않는 경우 2~3회 다시 시도해주시기 바랍니다.</li>
            </ul>
          </div>
          <ReactToPrint
            trigger={() => (
              <button className="btn-l" style={{ display: "block", marginLeft: "auto" }}>
                {selectedTaxBills && selectedTaxBills.length > 0 && selectedTaxBills[0].taxbillType === "PAPER" ? "종이세금계산서" : "종이계산서"} 인쇄
              </button>
            )}
            content={() => printRef.current}
            copyStyles={true}
          />
          <div className="print-outer-box" ref={printRef}>
            {selectedTaxBills.map((row, index) => {
              return <PaperTaxBill key={index} paperTaxBill={row} master={master} />;
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaperTaxBillPrint;
