import React from "react";
import { Modal, Button } from "@material-ui/core";
import ars from "../../../styles/images/ars.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// import TableForm from "../../template/TableForm";

const ARSInfoModal = (props) => {
  /*
   * @desc    자금종류 관리 모달창 close 이벤트 핸들러
   */
  const handleClose = async () => {
    props.setOpen(false);
  };

  return (
    <Modal open={props.open}>
      <div
        className="paper"
        style={{
          width: "1050px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>ARS 동의자료 대행 서비스 안내</h3>
          <div className="inforbox">
            <ul>
              <li>고객의 자동납부 동의자료를 ARS를 이용하여 취득 및 등록하는 기능입니다.</li>
              <li>
                <Link to="/institute/extraService" className="link">
                  기관&middot;업무정보 &gt; 부가서비스정보
                </Link>{" "}
                메뉴에서 “ARS 동의자료 대행” 서비스를 신청합니다.
              </li>
              <li>고객이 ARS로 자동납부 동의를 완료하면 자동납부 동의자료에 “ARS 녹취 파일”이 등록되어 나타납니다.</li>
              <li>
                서비스 이용내역은{" "}
                <Link to="/extraService/arsHistory" className="link">
                  ARS 서비스 이용내역
                </Link>
                에서 확인하실 수 있습니다.
              </li>
            </ul>
          </div>
          <h4 style={{ marginTop: "0px", marginBottom: "9px" }}>ARS 자동납부 동의 절차</h4>
          <img src={ars} alt="ars 동의자료 대행 안내" />
        </div>
      </div>
    </Modal>
  );
};

export default ARSInfoModal;
