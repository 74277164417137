import React from "react";
// import logo3 from "../../styles/images/logo3.png";
// import plus from '../../styles/images/plus.png';
// import equal from '../../styles/images/equal.png';
import { Tab, Tabs } from "@material-ui/core";
import * as ComponentForm from "../template/ComponentForm";

class Information extends React.Component {
  constructor(props) {
    super(props);
    //focus 처리
    this.state = {
      tabIndex: 0,
    };
  }

  handleState = ({ ...stateName }) => {
    let newState = Object.assign({}, this.state);
    Object.keys(stateName).forEach((key) => (newState[key] = stateName[key]));
    this.setState(newState);
  };

  handleTabIndexChange = (e, v) => {
    let jsonValue = {};
    jsonValue["tabIndex"] = v;
    this.handleState(jsonValue);
  };

  render() {
    return (
      <div>
        <div className="billingone_info_top">
          <div className="tab-area">
            <Tabs value={this.state.tabIndex} onChange={this.handleTabIndexChange} indicatorColor="primary" textColor="primary" data-testid="tabs">
              <Tab disableRipple value={0} label="서비스 개요" data-testid="tab-service" className="tab" />
              <Tab disableRipple value={1} label="서비스 특징" data-testid="tab-service-information" className="tab" />
              <Tab disableRipple value={2} label="이용요금 안내" data-testid="tab-pay" className="tab" />
            </Tabs>
          </div>
        </div>
        <div className="billingone_info">
          <ComponentForm.TabPanel value={this.state.tabIndex} index={0}>
            <div className="contentbox">
              <h3>서비스 소개</h3>
              <div className="tab1">
                <li>빌링원플러스는 수납, 지급, 고객관리까지 이용기관이 원하는 다양한 서비스를 One-Stop으로 이용할 수 있는 국내유일 지급결제 전담기관인 금융결제원의 솔루션입니다.</li>
              </div>
            </div>
            <div className="contentbox">
              <h3>서비스 목표</h3>
              <div className="box-centered">
                <div className="box-text-light-blue">
                  효율적인
                  <br />
                  고객 등록 및 관리
                </div>

                <div className="box-text-light-blue">
                  직관적인 수납 및 <br />
                  지급 내역 확인
                </div>

                <div className="box-text-light-blue ml_20">
                  자금 수납 및<br />
                  지급의 자동화 처리
                </div>

                <div className="box-text-light-blue">
                  이용기관 수요에 적합한 <br />
                  부가서비스 제공
                </div>

                <div className="box-text-dark-blue bg_grey_box">
                  효과적인 <br />
                  통합 빌링 서비스 제공
                </div>
              </div>
            </div>
            <div className="contentbox w100p">
              <h3>서비스 도입 대상</h3>
              <div className="tab1">고객관리부터 수납/지급관리까지 자금관리서비스를 편리하게 이용하고자 하는 국내 이용기관</div>
            </div>
            <div className="contentbox">
              <h3>기대효과</h3>
              <div className="tab1">
                <li>고객 관리 및 수납·지급의 One-Stop 처리로 기업 담당자의 업무 편의 제고</li>
                <li>효과적인 자금관리로 기업의 자금 관리 효율성 제고</li>
                <li>편리한 고객만족 서비스를 위하여 원격지원서비스 제공</li>
              </div>
            </div>
          </ComponentForm.TabPanel>
          <ComponentForm.TabPanel value={this.state.tabIndex} index={1}>
            <div className="contentbox">
              <h3>이용기관 니즈를 반영한 고객관리</h3>
              <h4 className="sm_tit">효율적인 고객관리 및 편리한 고객 등록 수단을 제공합니다.</h4>
              <div className="box_group">
                <ul>
                  <li>
                    개인/법인, <br /> 수납/지급 등 <br /> <b>고객특성</b>을 감안한 관리
                  </li>
                  <li>
                    정기/비정기, <br />
                    정액/비정액, <br /> 일반/할부 등 <br /> <b>다양한 거래형태</b> 수용
                  </li>
                  <li>
                    현금영수증, <br /> 세금계산서 등 <br /> <b>다양한 부가서비스</b> <br /> 제공
                  </li>
                  <li>
                    <b>일괄등록</b> <br />
                    기능 제공
                  </li>
                  <li>
                    <b>ARS 출금동의</b>
                    <br /> 서비스 제공
                  </li>
                  <li>
                    실시간 <b>계좌 실명조회,</b> <br />
                    실시간 <b>계좌등록</b>/ <br />
                    <b>계좌해지 서비스</b> 제공
                  </li>
                </ul>
              </div>
            </div>
            <div className="contentbox">
              <h3>편리한 수납과 지급</h3>
              <h4 className="sm_tit">수납/지급 신청과 내역을 관리합니다.</h4>
              <div className="box_group">
                <ul>
                  <li>
                    편리한 <br />{" "}
                    <b>
                      수납(지급)신청 및 <br />
                      결과 확인
                    </b>
                  </li>
                  <li>
                    <b>
                      수납(지급)대상 고객 <br />
                      수정
                    </b>{" "}
                    기능
                  </li>
                  <li>
                    미납 고객의
                    <br /> 보류처리 및 <br />
                    직접수납 등<br /> 관리 서비스 제공
                  </li>
                  <li>
                    등록된 고객정보로
                    <br /> <b>월별 수납(지급)계획 </b>
                    <br />
                    생성 서비스 제공
                  </li>
                  <li>
                    수납(지급) <br /> <b>내역 관리 기능</b>
                  </li>
                  <li>
                    다양한 수납(지급)상태로 <br />
                    <b>
                      세분화 된 <br />
                      고객관리 기능
                    </b>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <div className="contentbox">
              <h3>업무 편의를 위한 부가서비스 제공</h3>
              <div className="bg_service">
                <ul className="hidden">
                  <li className="li-title">자동출금 예약</li>
                  <li className="li-title">ARS 출금 동의 대행 징구</li>
                  <li className="li-title">입출금정보 자동 알림</li>
                  <li className="li-title">SMS 직접 발송</li>
                  <li className="li-title">전자 세금 계산서 발행</li>
                  <li className="li-title">업무일정 SMS 사전 통지</li>
                  <li className="li-title">현금영수증, 기부금영수증, 종이영수증 등 영수증 발행</li>
                  <li className="li-title">세금계산서 발행</li>
                  <li className="li-title">데이터 전환, 데이터 백업 등 데이터 일괄 관리</li>
                  <li className="li-title">우편물 라벨지 인쇄</li>
                </ul>
              </div>
            </div>
          </ComponentForm.TabPanel>
          <ComponentForm.TabPanel value={this.state.tabIndex} index={2}>
            <div className="contentbox">
              <h3>빌링원플러스 이용요금 (부가세 별도)</h3>
              <div className="tab1">이용요금은 월 단위로 부과되며 등록고객 수를 기준으로 부과</div>
              <ul>
                <li className="li-title1">기본요금 : 15,000원/월 (기본 등록고객 100명)</li>
                <li className="li-title1">추가요금 : 100명 추가당 (2,000원)</li>
              </ul>
            </div>
            <div className="contentbox">
              <h3>부가서비스</h3>
              <div className="tab2 hidden">유료 부가서비스 및 이용요금&#40;부가세 별도&#41;</div>
              <div className="box">
                <table>
                  <colgroup>
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "auto" }} />
                    <col style={{ width: "20%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>서비스 명</th>
                      <th>서비스 내용</th>
                      <th>이용요금</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>자동출금 예약</td>
                      <td style={{ textAlign: "left" }}>이용기관에서 지정한 날짜에 수납고객의 CMS 출금을 자동으로 요청하도록 예약하는 서비스</td>
                      <td>발송 건당 20원</td>
                    </tr>
                    <tr>
                      <td>ARS 출금동의 대행 징구</td>
                      <td style={{ textAlign: "left" }}>이용기관이 고객으로부터 자동납부 동의자료를 비대면 ARS 방식으로 자동납부 동의자료를 대행 징구하는 서비스</td>
                      <td>
                        발송 건당 성공시 100원
                        <br />
                        실패시 50원
                      </td>
                    </tr>
                    <tr>
                      <td>입출금 정보 자동 알림</td>
                      <td style={{ textAlign: "left" }}>이용기관에서 입금 또는 출금 대상 고객에게 입출금 예정 금액, 입출금 결과를 SMS로 자동 통지해주는 서비스</td>
                      <td rowSpan="2">발송 건당 16원</td>
                    </tr>
                    <tr>
                      <td>SMS 직접 발송</td>
                      <td style={{ textAlign: "left" }}>등록된 고객 또는 임의의 사람에게 SMS를 직접 발송을 지원하는 서비스</td>
                    </tr>
                    <tr>
                      <td>전자세금계산서 발행</td>
                      <td style={{ textAlign: "left" }}>
                        이용기관에서 고객에게 전자세금계산서를 발행하는 서비스
                        <br />※ 금융결제원의 전자세금계산서 서비스 트러스빌&#40;www.trusbill.or.kr&#41;과 연동
                      </td>
                      <td>발행 건당 200원</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="hidden">
                <div className="tab2">무료 부가서비스</div>
                <div className="box">
                  <table>
                    <colgroup>
                      <col style={{ width: "60px" }} />
                      <col style={{ width: "90px" }} />
                      <col style={{ width: "600px" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colSpan="2">서비스 명</th>
                        <th>서비스 내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">업무일정 SMS 사전 통지</td>
                        <td style={{ textAlign: "left" }}>지정한 날짜에 업무 담당자앞 입금, 출금 및 전자세금 계산서 발급 작업일정을 SMS로 발송하는 서비스</td>
                      </tr>
                      <tr>
                        <td rowSpan="3">영수증 발행</td>
                        <td>현금영수증</td>
                        <td style={{ textAlign: "left" }}>고객으로부터 수납한 금액이 현금영수증 발급 대상인 경우, 수납내역을 국세청에 전송하고 그 결과를 수신하는 서비스</td>
                      </tr>
                      <tr>
                        <td>기부금 영수증</td>
                        <td style={{ textAlign: "left" }}>연말정산용 기부금 영수증 동의자료를 인쇄하고 국세청에 제출할 기부금영수증 파일을 생성하는 서비스</td>
                      </tr>
                      <tr>
                        <td>종이 영수증</td>
                        <td style={{ textAlign: "left" }}>고객으로부터 수납한 금액을 종이 영수증으로 발행하는 서비스</td>
                      </tr>
                      <tr>
                        <td colSpan="2">종이세금계산서 발행</td>
                        <td style={{ textAlign: "left" }}>
                          고객에게 종이세금계산서를 발행하는 서비스
                          <br />※ 발행내역이 국세청에 전송되지 않으며 계산서로의 법적효력 없음
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan="2">데이터 전환</td>
                        <td>데이터 등록</td>
                        <td style={{ textAlign: "left" }}>
                          다수의 고객 데이터를 일괄 등록할 경우, 이용기관에서 엑셀 파일로 작업하여 등록할 수 있도록 지원하는 서비스 (단, 데이터 등록 전 고객정보 우선 설정 필요)
                        </td>
                      </tr>
                      <tr>
                        <td>데이터 백업</td>
                        <td style={{ textAlign: "left" }}>빌링원플러스에 등록되어 있는 고객정보를 이용기관 담당자 PC로 다운로드할 수 있도록 지원하는 서비스</td>
                      </tr>
                      <tr>
                        <td colSpan="2">라벨지 인쇄</td>
                        <td style={{ textAlign: "left" }}>우편물(편지 봉투 등)에 기재할 고객의 주소를 프린터로 인쇄하여 우편물에 부착할 수 있도록 지원하는 서비스</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ComponentForm.TabPanel>
        </div>
      </div>
    );
  }
}
export default Information;
