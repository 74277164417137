import { Checkbox, FormControlLabel, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import { searchPayAmountTypeData, searchTransactionMethodData } from "../../customer/receiptCustomerInformation/ReceiptInformationData";
import { ButtonTooltipFormTwo, RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import ReceiptSourceCreateModal from "./ReceiptSourceCreateModal";
import ReceiptSourceUpdateModal from "./ReceiptSourceUpdateModal";

// 검색 데이터
function searchData() {
  const today = new Date();
  return {
    searchStartY4: `${today.getFullYear().toString()}`,
    searchStartMm: today.getMonth() < 9 ? `${0}${today.getMonth() + 1}` : today.getMonth() + 1,
    searchEndY4: `${today.getFullYear().toString()}`,
    searchEndMm: today.getMonth() < 9 ? `${0}${today.getMonth() + 1}` : today.getMonth() + 1,
    sourceStatusList: {
      sourceStatus: true,
      UNASKED: true,
      UNPAID: true,
      UNREACHED: true,
      PAID: true,
      LOSS: true,
      ASKING: true,
      ASKING_AGAIN: true,
      HOLDING: false,
    },
    capitalUniqueKey: "",
    groupUniqueKey: "",
    transactionMethod: "",
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
    payAmountType: "",
    pageNumber: 0,
    pageSize: 5,
    payerNo: "",
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;
  const [holdingButtonDisabled, setHoldingButtonDisabled] = useState(true); //일괄수납보류 버튼 비활성화flag
  const [unholdingButtonDisabled, setUnholdingButtonDisabled] = useState(true); //일괄수납보류 해지 버튼 비활성화flag
  const [hasSearchedAfterChangedCheckbox, setHasSearchedAfterChangedCheckbox] = useState(true); // 검색조건 수납상태 변경 후 검색을 했는지 플래그

  const searchOptionList = [
    { value: "CUSTOMER_NAME", label: "고객명" },
    { value: "PAYER_NO", label: "납부자번호" },
    { value: "IDENTIFICATION_NO_FIRST7", label: "생년월일(사업자번호)" },
  ];

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    //검색 버튼 클릭 시, pageNumber 0으로 초기화
    props.setSearchRequest((searchRequest) => ({ ...searchRequest, pageNumber: 0 }));
    props.handleSearchButtonChange(true);
    setHasSearchedAfterChangedCheckbox(true);
  };

  useEffect(() => {
    setHasSearchedAfterChangedCheckbox(
      unholdingButtonDisabled ===
        !(
          !searchRequest.sourceStatusList.ASKING &&
          !searchRequest.sourceStatusList.ASKING_AGAIN &&
          !searchRequest.sourceStatusList.LOSS &&
          !searchRequest.sourceStatusList.PAID &&
          !searchRequest.sourceStatusList.UNASKED &&
          !searchRequest.sourceStatusList.UNPAID &&
          !searchRequest.sourceStatusList.UNREACHED &&
          searchRequest.sourceStatusList.HOLDING
        ) &&
        holdingButtonDisabled ===
          !(
            !searchRequest.sourceStatusList.ASKING &&
            !searchRequest.sourceStatusList.ASKING_AGAIN &&
            !searchRequest.sourceStatusList.LOSS &&
            !searchRequest.sourceStatusList.PAID &&
            (searchRequest.sourceStatusList.UNASKED || searchRequest.sourceStatusList.UNPAID || searchRequest.sourceStatusList.UNREACHED) &&
            !searchRequest.sourceStatusList.HOLDING
          )
    );
    // 일괄수납보류 해제 : 수납보류만 체크되어있을 때 비활성화 해제
    setUnholdingButtonDisabled(
      !(
        !searchRequest.sourceStatusList.ASKING &&
        !searchRequest.sourceStatusList.ASKING_AGAIN &&
        !searchRequest.sourceStatusList.LOSS &&
        !searchRequest.sourceStatusList.PAID &&
        !searchRequest.sourceStatusList.UNASKED &&
        !searchRequest.sourceStatusList.UNPAID &&
        !searchRequest.sourceStatusList.UNREACHED &&
        searchRequest.sourceStatusList.HOLDING
      )
    );
    // 일괄 수납보류 : 미청구/미도래/미납분 중 하나이상만 체크되어있을 때 비활성화 해제
    setHoldingButtonDisabled(
      !(
        !searchRequest.sourceStatusList.ASKING &&
        !searchRequest.sourceStatusList.ASKING_AGAIN &&
        !searchRequest.sourceStatusList.LOSS &&
        !searchRequest.sourceStatusList.PAID &&
        (searchRequest.sourceStatusList.UNASKED || searchRequest.sourceStatusList.UNPAID || searchRequest.sourceStatusList.UNREACHED) &&
        !searchRequest.sourceStatusList.HOLDING
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest]);

  /*
   * @desc    일괄수납보류 클릭 이벤트 핸들러
   */
  const fnSubmit = () => {
    props.handleHoldingButtonChange(true);
  };

  /*
   * @desc    일괄수납보류 해지 클릭 이벤트 핸들러
   */
  const fnUnholdingSubmit = () => {
    props.handleUnholdingButtonChange(true);
  };

  const SearchMonth = () => {
    const buttonHtml = [];
    for (let i = 1; i <= 12; i++) {
      buttonHtml.push(
        <div key={i} className="btn-xs" onClick={(e) => props.fnChangeMonth(i > 9 ? i : "0" + i)}>
          {i}월
        </div>
      );
    }
    return buttonHtml;
  };
  return (
    <div className="search-area tl">
      <div className="block" style={{ width: "100%" }}>
        <label className="label-l">수납기간</label>
        <SelectForm
          value={searchRequest.searchStartY4}
          handleChange={handleSearchFormChange("searchStartY4")}
          name="searchStartY4"
          arrayOption={props.fnCreateYearOption()}
          optionValue="value"
          optionLabel="label"
        />
        <SelectForm
          value={searchRequest.searchStartMm}
          handleChange={handleSearchFormChange("searchStartMm")}
          name="searchStartMm"
          arrayOption={props.fnCreateEndMonthOption(searchRequest.searchStartY4)}
          optionValue="value"
          optionLabel="label"
        />
        <span className="between">~</span>
        <SelectForm
          value={searchRequest.searchEndY4}
          handleChange={handleSearchFormChange("searchEndY4")}
          name="searchEndY4"
          arrayOption={props.fnCreateYearOption()}
          optionValue="value"
          optionLabel="label"
        />
        <SelectForm
          value={searchRequest.searchEndMm}
          handleChange={handleSearchFormChange("searchEndMm")}
          name="searchEndMm"
          arrayOption={props.fnCreateEndMonthOption(searchRequest.searchEndY4)}
          optionValue="value"
          optionLabel="label"
        />
        <div style={{ display: "flex" }}>
          <SearchMonth />
        </div>

        <label className="label-l">수납상태</label>
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.sourceStatus ? true : false}
              value={searchRequest.sourceStatusList.sourceStatus}
              onChange={handleSearchFormChange("sourceStatus")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-new-account",
              }}
            />
          }
          label="전체"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.PAID ? true : false}
              value={searchRequest.sourceStatusList.PAID}
              onChange={handleSearchFormChange("PAID")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="수납"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.ASKING ? true : false}
              value={searchRequest.sourceStatusList.ASKING}
              onChange={handleSearchFormChange("ASKING")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="출금중(최초출금)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.ASKING_AGAIN ? true : false}
              value={searchRequest.sourceStatusList.ASKING_AGAIN}
              onChange={handleSearchFormChange("ASKING_AGAIN")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="출금중(재출금)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.UNASKED ? true : false}
              value={searchRequest.sourceStatusList.UNASKED}
              onChange={handleSearchFormChange("UNASKED")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="미청구"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.UNREACHED ? true : false}
              value={searchRequest.sourceStatusList.UNREACHED}
              onChange={handleSearchFormChange("UNREACHED")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="미도래"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.UNPAID ? true : false}
              value={searchRequest.sourceStatusList.UNPAID}
              onChange={handleSearchFormChange("UNPAID")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="미납"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.HOLDING ? true : false}
              value={searchRequest.sourceStatusList.HOLDING}
              onChange={handleSearchFormChange("HOLDING")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="수납보류"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.LOSS ? true : false}
              value={searchRequest.sourceStatusList.LOSS}
              onChange={handleSearchFormChange("LOSS")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="손실처리"
        />
        <br />
        <br />
        <label className="label-l">자금종류</label>
        <Select
          native
          value={props.searchRequest.capitalUniqueKey}
          onChange={handleSearchFormChange("capitalUniqueKey")}
          name="capital"
          inputProps={{ "data-testid": "paymentInformation-select-capital" }}>
          <option value="" key="none">
            전체
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객구분</label>
        <Select native value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          <option value="" key="none">
            전체
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">수납방법</label>
        <Select native value={searchRequest.transactionMethod} onChange={handleSearchFormChange("transactionMethod")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          {props.transactionMethod.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금유형</label>
        <Select native value={searchRequest.payAmountType} onChange={handleSearchFormChange("payAmountType")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          {props.payAmountType.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
          <option value="TEMPORARY_ADDED" key="TEMPORARY_ADDED">
            추가분
          </option>
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")} inputProps={{ "data-testid": "receiptsource-select-terms" }} name="terms">
          {searchOptionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField
          className="w130"
          value={searchRequest.searchContents}
          onChange={handleSearchFormChange("searchContents")}
          onKeyUp={handleSearchKeyUp}
          inputProps={{ "data-testid": "receiptsource-textfield-search" }}
          name="search"
        />
        <div style={{ marginTop: "5px", display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
          <button className="search-button" data-testid="" onClick={(e) => fnSearch()}>
            수납내역 조회
          </button>
          <ButtonTooltipFormTwo
            buttonContents="선택조건 일괄수납보류"
            contents="해당 버튼은 '미청구','미도래','미납' 이외의 항목이 체크되어있을 시 비활성화됩니다."
            className="btn-l"
            onClick={(e) => !holdingButtonDisabled && (hasSearchedAfterChangedCheckbox ? fnSubmit() : CM.cfnAlert("선택조건을 변경하신 후에는 조회버튼을 클릭하여 대상을 확인해주시기 바랍니다."))}
            disabled={holdingButtonDisabled}
          />
          <ButtonTooltipFormTwo
            buttonContents="선택조건 일괄수납보류 해제"
            contents="해당 버튼은 '수납보류'만 체크해야 활성화됩니다."
            className="btn-l"
            onClick={(e) =>
              !unholdingButtonDisabled && (hasSearchedAfterChangedCheckbox ? fnUnholdingSubmit() : CM.cfnAlert("선택조건을 변경하신 후에는 조회버튼을 클릭하여 대상을 확인해주시기 바랍니다."))
            }
            disabled={unholdingButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const handleRowClick = (e, row) => {
    if (e.target.classList.contains("ignoreClick") || e.target.type === "button") {
      return;
    }
    props.fnGoSourceDetail(row);
  };

  //수납보류/수납보류해제/손실처리
  const fnSubmitReceiptAskResult = (row, name) => {
    const fnCallback = (objStatus, objData) => {
      // 실패시
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        return;
      }
      //성공시
      CM.cfnAlert(objData, () => {
        props.setSearchButton(true);
      });
    };
    let url = `api/receipt/source/${name}/${row.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnCallback);
  };

  //삭제
  const fnSubmitReceiptDelete = (row) => {
    const fnCallback = (objStatus, objData) => {
      // 실패시
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        row.handlingDeletion = false;
        return;
      }
      //성공시
      CM.cfnAlert(objData, () => {
        props.setSearchButton(true);
      });
    };
    row.handlingDeletion = false;
    let url = `api/receipt/source/${row.uniqueKey}`;
    CM.cfnAxios(url, "delete", "", fnCallback);
  };

  //수납보류, 보류해제 버튼 컴포넌트
  const fnReceiptHolding = (row) => {
    if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID" || row.askResult === "PARTIALLY_PAID") && row.askStatus === "NORMAL") {
      return (
        <div>
          <button className="btn-l2" type="button" onClick={(e) => fnSubmitReceiptAskResult(row, "holding")}>
            수납보류
          </button>
        </div>
      );
    } else if (row.askStatus === "HOLDING") {
      return (
        <div>
          <button className="btn-l3" type="button" onClick={(e) => fnSubmitReceiptAskResult(row, "cancelHolding")}>
            보류해제
          </button>
        </div>
      );
    }
  };
  //손실처리 버튼 컴포넌트
  const fnReceiptLoss = (row) => {
    if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") && row.askStatus !== "ASKING") {
      return (
        <div>
          <button className="btn-l2" type="button" onClick={(e) => fnSubmitReceiptAskResult(row, "loss")}>
            손실처리
          </button>
        </div>
      );
    }
  };

  //삭제버튼 노출 컴포넌트
  const fnReceiptDelete = (row) => {
    if (
      row.payAmountType === "TEMPORARY_ADDED" &&
      row.numberOfAsking === 0 &&
      row.delayFee === 0 &&
      row.directlyPaidAmount === 0 &&
      row.directlyPaidDelayFee === 0 &&
      row.refundedAmount === 0 &&
      row.refundedDelayFee === 0 &&
      row.lostAmount === 0 &&
      row.lostDelayFee === 0 &&
      row.paidAmount === 0 &&
      row.paidDelayFee === 0 &&
      row.askStatus !== "ASKING"
    ) {
      return (
        <div>
          <button className="btn-m" type="button" onClick={(e) => fnSubmitReceiptDelete(row)} disabled={row.handlingDeletion || false}>
            삭제
          </button>
        </div>
      );
    } else if (row.payAmountType === "TEMPORARY_ADDED" && row.askStatus !== "ASKING") {
      return (
        <div>
          <button className="btn-m btn-disabled" disabled={true}>
            삭제
          </button>
        </div>
      );
    }
  };

  const fnAskStatus = (row) => {
    let status = "";
    switch (row.askResult) {
      case "UNASKED":
        status = "미청구";
        break;
      case "UNREACHED":
        status = "미도래";
        break;
      case "UNPAID":
        status = "미납";
        break;
      case "PAID":
        status = "수납";
        break;
      case "LOSS":
        status = "손실처리";
        break;
      case "PARTIALLY_PAID":
        status = "부분납";
        break;
      default:
        break;
    }
    if (row.contractStatus === "DELETED") {
      status += "\n(삭제)";
    } else if (row.contractStatus === "PAUSED") {
      status += "\n(수납중지)";
    } else if (row.askStatus === "HOLDING") {
      status += "\n(수납보류)";
    } else if (row.askStatus === "ASKING") {
      status += "\n(청구중)";
    }
    return status;
  };

  const fnPayAmountType = (payAmountType) => {
    switch (payAmountType) {
      case "FIXED":
        return "정액";
      case "VARIABLE":
        return "비정액";
      case "TEMPORARY_ADDED":
        return "추가분";
      default:
        return payAmountType;
    }
  };
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["6%", "5%", "9%", "10%", "9%", "5%", "5%", "8%", "10%", "10%", "9%", "70px", "70px", "70px"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          arrData={[
            { id: "targetY4mm", label: "대상월", sortable: false },
            { id: "TARGET_Y4MM", label: "수납일", sortable: true },
            { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
            { id: "PAYER_NO", label: "납부자\n번호", sortable: true },
            { id: "customerGroupName", label: "고객구분", sortable: false },
            { id: "transactionMethod", label: "수납\n방법", sortable: false },
            { id: "payAmountType", label: "자금유형", sortable: false },
            { id: "capitalName", label: "자금종류", sortable: false },
            { id: "TOTAL_ASKING_AMOUNT", label: "수납\n대상금액", sortable: true },
            { id: "PAID_AMOUNT", label: "수납금액", sortable: true },
            { id: "ASK_RESULT", label: "수납상태", sortable: true },
            { id: "receiptHolding", label: "수납보류", sortable: false },
            { id: "receiptLoss", label: "손실처리", sortable: false },
            { id: "receiptDelete", label: "삭제", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId="receiptList-list-head-sortLabel"
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={14} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} className="show-detail" hover onClick={(e) => handleRowClick(e, row)} data-testid={`receiptList-goDetail-${index}`}>
                  <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                  <TableCell align="center">{row.paySpecifiedDay === "99" ? "말일" : row.paySpecifiedDay}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{row.payerNo}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center">{row.transactionMethod !== "CMS_WITHDRAW" ? "기타" : "CMS"}</TableCell>
                  <TableCell align="center">{fnPayAmountType(row.payAmountType)}</TableCell>
                  <TableCell align="center">{row.capitalName}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.paidAmount)}원</TableCell>
                  <TableCell align="center" style={{ whiteSpace: "pre-wrap" }}>
                    {fnAskStatus(row)}
                  </TableCell>
                  <TableCell align="center" className="ignoreClick">
                    {fnReceiptHolding(row)}
                  </TableCell>
                  <TableCell align="center" className="ignoreClick">
                    {fnReceiptLoss(row)}
                  </TableCell>
                  <TableCell align="center" className="ignoreClick">
                    {fnReceiptDelete(row)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}
/*
 * @desc  목록 컴포넌트 생성
 */
function SummaryForm(props) {
  return (
    <div>
      <h4>수납대상(수납정보 기준)</h4>
      <div className="summary-main-area">
        <div className="summary-area">
          <div className="text-graph">
            <label>수납대상금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalAmount)}원</span>
          </div>
          <div className="text-graph">
            <label>수납금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalAmount - props.summaryList.totalUnpaidAmount)}원</span>
          </div>
          <div className="text-graph">
            <label>미납금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalUnpaidAmount)}원</span>
          </div>
          <div className="text-graph">
            <label>연체금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalDelayFee)}원</span>
          </div>
        </div>
        <div className="summary-button-area">
          <button className="summary-button btn-m" data-testid="create-receipts" onClick={(event) => props.setCreateModalOpen(true)}>
            수납내역추가
          </button>
          <button className="summary-button btn-m" data-testid="update-receipts" onClick={(event) => props.setUpdateModalOpen(true)}>
            수납금액수정
          </button>
        </div>
      </div>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function ReceiptSourceList(props) {
  const { store } = props;
  const history = useHistory();

  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [holdingButton, setHoldingButton] = useState(false); // 수납보류/수납보류해제/손실처리 실행 flag
  const [unholdingButton, setUnholdingButton] = useState(false);
  const [excelButton, setExcelButton] = useState(false); // 엑셀버튼 실행 flag
  const [summaryList, setSummaryList] = useState({
    totalAmount: 0,
    totalAskingAmount: 0,
    totalDelayFee: 0,
    totalUnpaidAmount: 0,
  }); //수납대상(수납정보 기준)
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  // const [originRequest, setOriginRequest] = useState(searchData());
  //검색 request옵션 생성 함수
  const fnCreatSearchPropertyOption = [
    { label: "고객명", value: "CUSTOMER_NAME" },
    { label: "납부자번호", value: "PAYER_NO" },
  ];
  const [instituteInfoState, setInstituteInfoState] = useState(false);

  // 검색조건 고객구분 및 자금종류 / 기관구분정보 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosReceiptCapitals(); //지급 자금종류
      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      if (businessInfo.cmsService.indexOf("EB21") === -1 && businessInfo.cmsService.indexOf("EC21") === -1) {
        await setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 입금이체서비스만 이용하시는 기관입니다.", () => {});
      }
    };

    getStoreDataAxios();
  }, [store]);

  useEffect(() => {
    /*
     * @desc 검색 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = (search, buttonType) => {
      let tempObj = CM.cfnCopyObject(search);
      tempObj.searchStartY4mm = search.searchStartY4 + search.searchStartMm;
      tempObj.searchEndY4mm = search.searchEndY4 + search.searchEndMm;
      let tempArray = [];
      if (CM.cfnIsNotEmpty(search.sourceStatusList) && search.sourceStatusList.length > 0 && typeof search.sourceStatusList[0] === "string") {
        tempArray = search.sourceStatusList;
      } else {
        const keyArray = Object.keys(search.sourceStatusList);
        if (CM.cfnIsNotEmpty(search.sourceStatusList) && buttonType !== "submit") {
          Object.values(search.sourceStatusList).map((value, index) => (value && keyArray[index] !== "sourceStatus" ? tempArray.push(keyArray[index]) : ""));
        } else {
          Object.values(search.sourceStatusList).map((value, index) =>
            value && (keyArray[index] === "UNASKED" || keyArray[index] === "UNPAID" || keyArray[index] === "UNREACHED") ? tempArray.push(keyArray[index]) : ""
          );
        }
      }
      tempObj.sourceStatusList = tempArray;

      return tempObj;
    };
    /*
     * @desc    검색결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    수납대상(수납정보 기준) 결과조회 Request
     */
    const axiosSummaryList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/summary?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    일괄수납보류 처리 함수
     */
    const axiosHolding = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/holding/bulk?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "put", "", (status, data) => {
          CM.cfnAlert(data, () => {
            resolve(data);
          });
        });
      });
    };

    /*
     * @desc    일괄수납보류 해지 처리 함수
     */
    const axiosUnholding = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/unholding/bulk?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "put", "", (status, data) => {
          CM.cfnAlert(data, () => {
            resolve(data);
          });
        });
      });
    };

    /*
     * @desc    엑셀저장 처리 함수
     */
    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `/api/receipt/source/excel?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;
        CM.cfnAxiosFileDownload(url, "get", "", () => {});
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      //일괄수납보류 시
      if (buttonType === "submit") {
        setHoldingButton(false);
        await axiosHolding(search);
        const resultData = await axiosList(search);
        const resultList = resultData.content;
        setList(resultList);
      } else if (buttonType === "unholdingSubmit") {
        setUnholdingButton(false);
        await axiosUnholding(search);
        const resultData = await axiosList(search);
        const resultList = resultData.content;
        setList(resultList);
      } else if (buttonType === "excel") {
        setExcelButton(false);
        await axiosExcel(search);
      } else {
        setSearchButton(false);
        if (CM.cfnAddDate(search.searchStartY4mm, search.searchEndY4mm, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }
        //검색 시
        const summaryResultData = await axiosSummaryList(search);
        const resultData = await axiosList(search);

        const resultList = resultData.content;
        const summaryResultList = summaryResultData;
        setOriginalList(resultData);
        setList(resultList);
        setSummaryList(summaryResultList);
        setPagination(paginationData(resultData));
      }
      // state 유지를 위한 history 설정
      history.replace("/receipt/receiptSource", search);
    }

    if (searchButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param, "search");
    } else if (holdingButton === true) {
      const param = fnMakeParameter(searchRequest, "submit");
      startAxios(param, "submit");
    } else if (unholdingButton === true) {
      const param = fnMakeParameter(searchRequest, "unholdingSubmit");
      startAxios(param, "unholdingSubmit");
    } else if (excelButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param, "excel");
    } else if ((history.action === "POP" || history.action === "PUSH") && CM.cfnIsNotEmpty(history.location.state)) {
      const getState = history.location.state;
      let sourceStatusList = {};
      if (getState.sourceStatusList && Array.isArray(getState.sourceStatusList)) {
        getState.sourceStatusList.map((status) => {
          sourceStatusList[status] = true;
          return null;
        });
        sourceStatusList.sourceStatus =
          sourceStatusList.PAID &&
          sourceStatusList.ASKING &&
          sourceStatusList.ASKING_AGAIN &&
          sourceStatusList.UNASKED &&
          sourceStatusList.UNREACHED &&
          sourceStatusList.UNPAID &&
          sourceStatusList.LOSS;
      }
      let originalParameter = { ...searchRequest, ...getState, sourceStatusList };
      if (!CM.equals(sourceStatusList, searchRequest.sourceStatusList)) {
        setSearchRequest(originalParameter);
        // setOriginRequest(originalParameter);
        setSearchButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchButton, holdingButton, unholdingButton, excelButton, history.action]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchButton(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setSearchRequest((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    setSearchButton(true);
  };

  /*
   * @desc  검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (e.target.type === "checkbox") {
      if (name === "sourceStatus") {
        setSearchRequest({
          ...searchRequest,
          sourceStatusList: {
            sourceStatus: e.target.checked,
            UNASKED: e.target.checked,
            UNPAID: e.target.checked,
            UNREACHED: e.target.checked,
            PAID: e.target.checked,
            LOSS: e.target.checked,
            ASKING: e.target.checked,
            ASKING_AGAIN: e.target.checked,
          },
        });
      } else if (name === "HOLDING") {
        setSearchRequest({
          ...searchRequest,
          sourceStatusList: {
            ...searchRequest.sourceStatusList,
            UNPAID: false,
            UNASKED: false,
            UNREACHED: false,
            HOLDING: e.target.checked,
          },
        });
      } else if (name === "UNPAID" || name === "UNASKED" || name === "UNREACHED") {
        setSearchRequest({
          ...searchRequest,
          sourceStatusList: {
            ...searchRequest.sourceStatusList,
            [name]: e.target.checked,
            HOLDING: false,
          },
        });
      } else {
        setSearchRequest({
          ...searchRequest,
          sourceStatusList: {
            ...searchRequest.sourceStatusList,
            [name]: e.target.checked,
          },
        });
      }
    } else if (name === "searchStartY4") {
      setSearchRequest({
        ...searchRequest,
        searchStartMm: e.target.value > searchRequest.searchStartY4 ? "01" : e.target.value < searchRequest.searchStartY4 ? "12" : searchRequest.searchStartMm,
        searchStartY4: e.target.value,
      });
    } else if (name === "searchEndY4") {
      setSearchRequest({
        ...searchRequest,
        searchEndMm: e.target.value > searchRequest.searchEndY4 ? "01" : e.target.value < searchRequest.searchEndY4 ? "12" : searchRequest.searchEndMm,
        searchEndY4: e.target.value,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = async (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });
    //정렬조건 세팅 후 검색 trigger
    setSearchButton(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    수납내역 검색조건에 따른 일괄 수납보류처리 핸들러
   */
  const handleHoldingButtonChange = (flag) => setHoldingButton(flag);

  /*
   * @desc    수납내역 검색조건에 따른 일괄 수납보류처리 핸들러
   */
  const handleUnholdingButtonChange = (flag) => setUnholdingButton(flag);

  const fnGoSourceDetail = (row) => {
    history.push("/receipt/receiptSourceDetail", {
      receiptSourceDetail: row,
      searchRequest,
    });
  };

  /*
   * @desc     수납기간 일자 옵션 생성하는 함수
   */
  // const fnCreateMonthOption = () => {
  //   const arrDate = [];
  //   const maxLoop = 13;

  //   for (let i = 1; i < maxLoop; i++) {
  //     let label = `${i}월`;
  //     let value = i < 10 ? `0${i}` : i;

  //     arrDate.push({
  //       label: label,
  //       value: value
  //     });
  //   }

  //   return arrDate;
  // };

  /*
   * @desc     수납기간 년도 옵션 생성하는 함수
   */
  const fnCreateYearOption = () => {
    const arrYear = [];

    const startYear = 2010;
    let maxLoop = new Date().getFullYear() - startYear + 2;
    // if (type === "end") {
    //   maxLoop++;
    // }

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납시작월 / 종료월의 옵션 생성하는 함수
   */
  const fnCreateEndMonthOption = (searchY4) => {
    const arrMonth = [];

    let year = `${(new Date().getFullYear() + 1).toString()}`;
    let mm;

    if (searchY4.toString() === year) {
      //수납종료기간 년도가 내년과 같다면 이번달까지만 셀렉트박스 생성
      mm = `${new Date().getMonth()}`; //이번달

      for (let i = 1; i <= mm; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;
        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else {
      const maxLoop = 13;

      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    }
    return arrMonth;
  };

  //수납기간 (월 선택 ) 함수
  async function fnChangeMonth(month) {
    await setSearchRequest((searchRequest) => ({
      ...searchRequest,
      searchEndMm: month,
      searchStartMm: month,
      pageNumber: 0,
    }));
    handleSearchButtonChange(true);
  }

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  return (
    <div className={instituteInfoState ? style.disabled_div : ""}>
      <div>
        <div className="inforbox">
          <ul>
            <li>
              수납고객 정보를 바탕으로 매월 출금해야 할 내역들이 수납내역에 3개월 주기로 자동 생성됩니다.
              <br />
              - 아래 정보에서 수납해야 할 내역이 제대로 생성되었는지 꼭 확인하시기 바랍니다.
              <br />- 일괄수납보류는 현재 조회된 내역 중 수납상태가 미납/미청구/수납일미도래건을 모두 동시에 수납보류 하는 기능입니다.
            </li>
          </ul>
        </div>
      </div>
      <SearchForm
        searchRequest={searchRequest}
        setSearchRequest={setSearchRequest}
        fnChangeMonth={fnChangeMonth}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        handleHoldingButtonChange={handleHoldingButtonChange}
        handleUnholdingButtonChange={handleUnholdingButtonChange}
        // fnCreateMonthOption={fnCreateMonthOption}
        fnCreateYearOption={fnCreateYearOption}
        fnCreateEndMonthOption={fnCreateEndMonthOption}
        storeCapital={toJS(store.receiptCapital)} // 자금종류
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
        payAmountType={searchPayAmountTypeData()}
        transactionMethod={searchTransactionMethodData()}
      />
      <SummaryForm summaryList={summaryList} setCreateModalOpen={setCreateModalOpen} setUpdateModalOpen={setUpdateModalOpen} />
      <div className="table-top-area">
        <TotalCountForm totalElements={originalList.totalElements || 0} />
        <RowPerPageForm data-testid="receiptList-rowPerPage-select" value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
        <button className="btn-m fr" data-testid="save-excel" onClick={() => setExcelButton(true)}>
          엑셀저장
        </button>
      </div>
      <ListForm
        list={list}
        pagination={pagination}
        fnGoSourceDetail={fnGoSourceDetail}
        page={searchRequest.pageNumber}
        setSearchButton={setSearchButton}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />

      <ReceiptSourceCreateModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        setListSearchButton={setSearchButton}
        storeCapital={toJS(store.receiptCapital)}
        storeCustomerGroup={toJS(store.customerGroup)}
        searchProperty={fnCreatSearchPropertyOption}
        transactionMethod={searchTransactionMethodData()}
      />

      <ReceiptSourceUpdateModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        searchRequestData={searchRequest}
        paginationData={pagination}
        setListSearchButton={setSearchButton}
        storeCapital={toJS(store.receiptCapital)}
        storeCustomerGroup={toJS(store.customerGroup)}
        payAmountType={searchPayAmountTypeData()}
        searchProperty={fnCreatSearchPropertyOption}
      />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(ReceiptSourceList));
