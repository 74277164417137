import React, { useState, Fragment } from "react";
import { AppBar, Tabs, Tab, Typography, Box, Button } from "@material-ui/core/";
import TabWorkSchedulesNotice from "./TabWorkSchedulesNotice";
import TabSmsAlarm from "./TabSmsAlarm";
import TabArsWithdraw from "./TabArsWithdraw";
import TabTrusBill from "./TabTrusBill";
import TabCashBill from "./TabCashBill";
import TabAutoWithdraw from "./TabAutoWithdraw";
import TabOpenGiroAndPg from "./TabOpenGiroAndPg";

/*
 * @desc  TabPanel 영역
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
      <Box>{children}</Box>
    </Typography>
  );
}

/*
 * @desc  serviceType에 해당하는 object를 반환하는 함수
 */
function getObject(object, type) {
  for (const element of object) {
    if (element.serviceType === type) {
      return element;
    }
  }
}

/*
 * @desc  Default 메인 컴포넌트
 */
export default function ExtraServiceInfoTab(props) {
  const [tabIndex, setTabIndex] = useState(props.selectRow); // Tab 인덱스
  const handleTabChange = (event, value) => setTabIndex(value);

  return (
    <Fragment>
      <div className="modal-top-area">
        <Button className="fr btn-close" onClick={props.handleModalClose} data-testid="extraServiceInfo-modal-close">
          {""}
        </Button>
      </div>
      <div className="tabTitle">
        <h3>부가서비스 상세 내역</h3>
      </div>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
          <Tab disableRipple value={0} label="자동출금 예약" data-testid="extraServiceInfo-tab-autoWithdraw" />
          <Tab disableRipple value={1} label="ARS 동의자료 대행" data-testid="extraServiceInfo-tab-arsEvidence" />
          <Tab disableRipple value={2} label="입출금 자동 알림" data-testid="extraServiceInfo-tab-smsToCustomer" />
          <Tab disableRipple value={3} label="전자세금계산서 발행" data-testid="extraServiceInfo-tab-trusbill" />
          <Tab disableRipple value={4} label="SMS 일정관리" data-testid="extraServiceInfo-tab-notifyFile" />
          <Tab disableRipple value={5} label="현금영수증 발급" data-testid="extraServiceInfo-tab-cashbillInfo" />
          <Tab disableRipple value={6} label="출금불능분 즉시납부" data-testid="extraServiceInfo-tab-openGiroAndPg" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <TabAutoWithdraw
          autoWithdraw={props.autoWithdraw}
          instituteServiceState={props.instituteServiceState}
          disabled={props.instituteServiceState && props.instituteServiceState.indexOf("EB21") === -1 && props.instituteServiceState.indexOf("EC21") === -1}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <TabArsWithdraw instituteService={getObject(props.instituteService, "ARS_EVIDENCE")} updateInstituteService={props.updateInstituteService} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <TabSmsAlarm
          instituteService={getObject(props.instituteService, "SMS_TO_CUSTOMER")}
          updateInstituteService={props.updateInstituteService}
          instituteServiceState={props.instituteServiceState}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <TabTrusBill instituteService={getObject(props.instituteService, "TRUSBILL")} updateInstituteService={props.updateInstituteService} />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <TabWorkSchedulesNotice notifyFile={props.notifyFile} handleChangeNotifyFile={props.handleChangeNotifyFile} updateNotifyFile={props.updateNotifyFile} />
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        <TabCashBill cashbillInfo={props.cashbillInfo} />
      </TabPanel>
      <TabPanel value={tabIndex} index={6}>
        <TabOpenGiroAndPg instituteService={props.instituteService} updateInstituteService={props.updateInstituteService} />
      </TabPanel>
    </Fragment>
  );
}
