import { Button, Checkbox, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import PaymentDirectlyPaidModal from "../../payment/paymentSource/PaymentDirectlyPaidModal";
import PaymentRefundModal from "../../payment/paymentSource/PaymentRefundModal";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaymentData from "./PaymentCustomerData";

/*
 * @desc     상단 지급고객정보 요약 테이블 생성
 */
const PaymentCustomerInformation = (props) => {
  const { paymentInfo } = props;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className="th">고객명</TableCell>
          <TableCell className="td" align="left">
            {paymentInfo.customer.customerName}
          </TableCell>
          <TableCell className="th">
            생년월일
            <br />
            (사업자번호)
          </TableCell>
          <TableCell className="td" align="left">
            {CM.cfnIdentificationNoFormat(paymentInfo.customer.identificationNo)}
          </TableCell>
          <TableCell className="th">자금종류</TableCell>
          <TableCell className="td" align="left">
            {paymentInfo.capital.capitalName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="th">지급시작월</TableCell>
          <TableCell className="td" align="left">
            {CM.cfnDateFormat(paymentInfo.payStartY4mm, "yyyyMM")}
          </TableCell>
          <TableCell className="th">지급종료월</TableCell>
          <TableCell className="td" align="left">
            {CM.cfnDateFormat(paymentInfo.payEndY4mm, "yyyyMM")}
          </TableCell>
          <TableCell className="th">회차당 지급금액</TableCell>
          <TableCell className="td" align="left">
            {paymentInfo.payAmountType === "VARIABLE" ? "비정액" : CM.cfnAddComma(paymentInfo.payAmount) + "원"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">지급기간</label>
        <Template.DatePickerForm
          className="w150 receiptHistDatepicker"
          value={searchRequest.searchStartY4mm}
          handleChange={handleSearchFormChange("searchStartY4mm")}
          format="yyyy.MM"
          customProps={{ placeholder: "YYYY.MM", views: ["month", "year"] }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w150 receiptHistDatepicker"
          value={searchRequest.searchEndY4mm}
          handleChange={handleSearchFormChange("searchEndY4mm")}
          format="yyyy.MM"
          customProps={{ placeholder: "YYYY.MM", views: ["month", "year"] }}
        />
        <button className="search-button" onClick={(e) => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  선택 회차 지급내역 변경 이력 컴포넌트 생성
 */
function HistoryListForm(props) {
  return (
    <div>
      <h4>선택 회차 지급내역 변경 이력</h4>
      <Table>
        {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compTableHead arrData={["변경일", "처리구분", "지급일", "지급원금", "미지급액", "비고"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={6} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">{CM.cfnDateFormat(row.askedDate)}</TableCell>
                  <TableCell align="center">{PaymentData.fnTransactionType(row.transactionType)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.paidDate)}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.amountBeforeTransaction)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.amountAfterTransaction)}원</TableCell>
                  <TableCell align="center">{row.remark}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc 회차별 지급 내역 컴포넌트 생성
 */
function ContractListForm(props) {
  const {
    fnContractDetail,
    list,
    checkAllRow,
    handleCheckAllRowChange,
    handleUpdateChange,
    setPaymentHolding,
    setPaymentCancelHolding,
    setPaymentDirectlyPaid,
    setPaymentRefund,
    setContractDetail,
    pagination,
    handleOffsetChange,
    handleChangeRowsPerPage,
  } = props;

  const data = [];
  for (const row of list) {
    if (row._checked) {
      const copyObj = CM.cfnCopyObject(row);
      data.push(copyObj);
      setContractDetail(row);
    }
  }

  const fnButton = () => {
    if (data.length === 1) {
      let buttonCount = 0;
      let buttonHtml = [];
      if (data[0].askStatus === "NORMAL" && (data[0].paidAmount > 0 || data[0].delayFee > 0)) {
        //환입버튼
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" data-testid="refund-modal" onClick={(e) => setPaymentRefund(true)}>
            환입
          </button>
        );
      }

      if (
        (data[0].askStatus === "NORMAL" || data[0].askStatus === "HOLDING") &&
        (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")
      ) {
        //직접지급
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" data-testid="directlyPaid-modal" onClick={(e) => setPaymentDirectlyPaid(true)}>
            직접지급
          </button>
        );
      }
      if (data[0].askStatus === "HOLDING") {
        // 보류해제
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setPaymentCancelHolding(true)}>
            보류해제
          </button>
        );
      }
      if (data[0].askStatus === "NORMAL" && (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")) {
        //지급보류
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setPaymentHolding(true)}>
            지급보류
          </button>
        );
      }

      if (buttonCount > 0) {
        buttonHtml.push(<label className="label-l">선택한 회차를</label>);
      }
      return buttonHtml;
    } else if (data.length === 0) {
      return (
        <span>
          <button className="btn-l fr" data-testid="refund-modal" onClick={(e) => (data.length > 0 ? setPaymentRefund(true) : CM.cfnAlert("환입을 변경하실 회차를 선택해주세요."))}>
            환입
          </button>
          <button className="btn-l fr" data-testid="directlyPaid-modal" onClick={(e) => (data.length > 0 ? setPaymentDirectlyPaid(true) : CM.cfnAlert("직접지급을 변경하실 회차를 선택해주세요."))}>
            직접지급
          </button>
          <button className="btn-l fr" data-testid="hodingCancel-modal" onClick={(e) => setPaymentCancelHolding(true)}>
            보류해제
          </button>
          <button className="btn-l fr" data-testid="hoding-modal" onClick={(e) => setPaymentHolding(true)}>
            지급보류
          </button>
          <label className="label-l fr">선택한 회차를</label>
        </span>
      );
    } else {
      return (
        <span>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setPaymentCancelHolding(true)}>
            보류해제
          </button>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setPaymentHolding(true)}>
            지급보류
          </button>
          <label className="label-l fr">선택한 회차를</label>
        </span>
      );
    }
  };
  return (
    <div>
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={list.length || 0} />
        <Template.RowPerPageForm value={pagination.rowsPerPage} onChange={handleChangeRowsPerPage} customProps={{ inputProps: { name: "rowsPerPage" } }} />
        <div className="fr">{fnButton()}</div>
      </div>
      <Table>
        {CM.cfnCompColGroup(["5%", "10%", "15%", "15%", "15%", "15%", "auto"])}
        <TableForm.compCheckboxTableHead checked={checkAllRow} value="" onChange={handleCheckAllRowChange} arrData={["회차", "지급년월", "지급대상액", "지급액", "미지급액", "지급상태"]} />
        <TableBody>
          {list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            list.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage).map((row, index) => {
              return (
                <TableRow key={index} hover className="show-detail" onClick={(e) => fnContractDetail(row, e, index)}>
                  <TableCell className="checkbox">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.contractAskSequence) ? row.contractAskSequence : "추가분"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.totalAskingAmount)}원
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.paidAmount)}원
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.unpaidAmount)}원
                  </TableCell>
                  <TableCell className="td" align="center">
                    {PaymentData.fnAskStatus(row)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Template.PaginationForm filteredRows={list} pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <div className="table-bottom-area" />
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function PaymentHistory(props) {
  const { open, setOpen, paymentInfo } = props;

  //필요 state 선언
  const [searchRequest, setSearchRequest] = useState(PaymentData.historySearchData(paymentInfo.uniqueKey)); // 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [contractList, setContractList] = useState([]); // 회차별 지급 내역 데이터
  const [historyList, setHistoryList] = useState([]); // 선택 회차 지급내역 변경 이력 데이터

  const [paymentHolding, setPaymentHolding] = useState(false); //지급보류
  const [paymentCancelHolding, setPaymentCancelHolding] = useState(false); //지급보류해제
  const [paymentDirectlyPaid, setPaymentDirectlyPaid] = useState(false); //직접지급
  const [paymentRefund, setPaymentRefund] = useState(false); //환입
  const [contractDetail, setContractDetail] = useState(false); //모달에 props로 넘길 데이터

  const [pagination, setPagination] = useState({ rowsPerPage: 5, offset: 0, page: 0 });

  // table head checkbox state
  const [checkAllRow, setCheckAllRow] = useState(false);

  useEffect(() => {
    if (open) {
      /*
       * @desc yyyyMM 타입으로 객체 변환하는 함수
       */
      const fnConvertDateToString = (date) => {
        let obj = date;
        //파라미터 객체가 Date 타입일 때만 변환
        if (date.getFullYear) {
          const year = date.getFullYear().toString();
          const mm = (date.getMonth() + 1).toString();

          obj = `${year}${mm[1] ? mm : 0 + mm[0]}`;
        }

        return obj;
      };

      /*
       * @desc 결과조회 파라미터 생성 함수
       */
      const fnMakeParameter = (search) => {
        let tempObj = CM.cfnCopyObject(search);
        //파라미터 객체가 Date 타입일 때만 변환
        tempObj.searchStartY4mm = fnConvertDateToString(search.searchStartY4mm);
        tempObj.searchEndY4mm = fnConvertDateToString(search.searchEndY4mm);
        return tempObj;
      };

      /*
       * @desc    회차별 지급 내역
       */
      const axiosList = (search) => {
        return new Promise((resolve) => {
          let url = `api/payment/source/contract?searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&contractUniqueKey=${search.contractUniqueKey}`;

          CM.cfnAxios(url, "get", "", (status, data) => {
            resolve(data);
          });
        });
      };

      // start axios and set table data
      async function startAxios(search) {
        //회차별 지급 내역
        const resultData = await axiosList(search);
        if (CM.cfnIsNotEmpty(resultData)) {
          for (const element of resultData) {
            element._checked = false;
            element.index = resultData.indexOf(element);
          }
        }
        setContractList(resultData);
        setCheckAllRow(false);
        setSearchButton(false);
      }

      // 실행 영역
      if (searchButton === true) {
        const param = fnMakeParameter(searchRequest);
        startAxios(param);
      }
    }
  }, [open, searchRequest, searchButton]);

  //지급보류, 지급보류해제, 손실처리 (버튼 제어)
  useEffect(() => {
    /*
     * @desc   지급보류 Request
     */
    const startAxiosHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/holding`;
        if (sendData.length === 1) {
          url = `api/payment/source/holding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };

    /*
     * @desc   지급보류해제 Request
     */
    const startAxiosCancelHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/cancelHolding`;
        if (sendData.length === 1) {
          url = `api/payment/source/cancelHolding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };

    const checkedData = [];
    const sendData = [];
    for (const row of contractList) {
      if (row._checked) {
        checkedData.push(row);
      }
    }

    // 지급보류 버튼 클릭
    if (paymentHolding === true) {
      if (checkedData.length < 1) {
        CM.cfnAlert("보류처리 할 지급내역을 선택해주세요. ", () => {
          setPaymentHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if (row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 지급내역은 보류처리 대상이 아닙니다.`, () => {
            setPaymentHolding(false);
          });
          return;
        }
      }
      startAxiosHolding(sendData);
      setPaymentHolding(false);
    }
    // 보류해체처리 버튼 클릭
    if (paymentCancelHolding === true) {
      if (checkedData.length === 0) {
        CM.cfnAlert("보류해체처리 할 지급내역을 선택해주세요. ", () => {
          setPaymentCancelHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") && row.askStatus === "HOLDING") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 지급내역은 보류해체처리 대상이 아닙니다.`, () => {
            setPaymentCancelHolding(false);
          });
          return;
        }
      }
      startAxiosCancelHolding(sendData);
      setPaymentCancelHolding(false);
    }
  }, [paymentHolding, paymentCancelHolding, contractList]);

  /*
   * @desc    모달창 닫기 버튼 클릭 이벤트 핸들러
   */
  const handleClose = async () => {
    //선택 항목 저장 state 초기화
    setContractList([]);
    await setHistoryList([]);

    setOpen(false);
    setSearchButton(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e,
    });
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;
    setCheckAllRow(checkAllValue);
    setContractList((constract) => constract.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...contractList];
    cloneData[index][key] = value;
    setContractList(cloneData);
  };

  /*
   * @desc    선택 회차 지급내역 변경 이력 조회 Request
   */
  function fnContractDetail(row, e, index) {
    let nowIndex = index;
    if (pagination.page !== 0) {
      nowIndex = index + pagination.page * pagination.rowsPerPage;
    }

    const key = "_checked";
    const value = row._checked;
    const cloneData = [...contractList];
    cloneData[index][key] = value;

    setContractList(cloneData);

    for (let i = 0; i < contractList.length; i++) {
      if (i === nowIndex) {
        if (contractList[i]._checked === true) {
          contractList[i]._checked = false;
        } else {
          contractList[i]._checked = true;
        }
      }
    }

    let url = `api/payment/source/history?sourceUniqueKey=${row.uniqueKey}`;
    CM.cfnAxios(url, "get", "", fnCallback);
  }

  /*
   * @desc    선택 회차 지급내역 변경 이력 조회 Request Callback
   */
  const fnCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return;
    }
    setHistoryList(objData);
  };

  /*
   * @desc    페이지 변경 이벤트 핸들러
   */
  const handleOffsetChange = (offset, page) => {
    setPagination({
      ...pagination,
      offset: offset,
      page: page - 1,
    });
  };

  /*
  @desc 표시 데이터 수 변경 이벤트 핸들러
 */
  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: event.target.value,
      page: 0,
    });
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>회차별 지급내역</h3>
          <PaymentCustomerInformation paymentInfo={paymentInfo} />
          <div className="table-top-area" />
          <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
          <h4>회차별 지급내역</h4>
          <ContractListForm
            list={contractList}
            setContractList={setContractList}
            checkAllRow={checkAllRow}
            fnContractDetail={fnContractDetail}
            handleCheckAllRowChange={handleCheckAllRowChange}
            handleUpdateChange={handleUpdateChange}
            setPaymentHolding={setPaymentHolding}
            setPaymentCancelHolding={setPaymentCancelHolding}
            setPaymentDirectlyPaid={setPaymentDirectlyPaid}
            setPaymentRefund={setPaymentRefund}
            setContractDetail={setContractDetail}
            pagination={pagination}
            handleOffsetChange={handleOffsetChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <HistoryListForm list={historyList} />
          <PaymentDirectlyPaidModal open={paymentDirectlyPaid} setOpen={setPaymentDirectlyPaid} contractDetail={contractDetail} setSearchButton={setSearchButton} />
          <PaymentRefundModal open={paymentRefund} setOpen={setPaymentRefund} contractDetail={contractDetail} setSearchButton={setSearchButton} />
        </div>
      </div>
    </Modal>
  );
}

export default PaymentHistory;
