import { Button, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import { RawTooltipForm, RowPerPageForm, SelectForm, TooltipForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsReceiptData";

//모달창 크기 조정 위해 추가 선언
const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "800px",
  },
});

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const { searchRequest, handleSearchFormChange } = props;

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area centered">
      <div className="block">
        <label className="label-l">고객구분</label>
        <Select native name="groupUniqueKey" value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")}>
          <option value="">전체</option>
          {props.customerGroupOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native name="capitalName" value={searchRequest.capitalUniqueKey} onChange={handleSearchFormChange("capitalUniqueKey")}>
          <option value="">전체</option>
          {props.capitalOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">출금결과</label>
        <Select native name="resultCode" value={searchRequest.resultCode} onChange={handleSearchFormChange("resultCode")}>
          <option value="">전체</option>
          {props.withdrawResultOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <SelectForm value={searchRequest.term} handleChange={handleSearchFormChange("term")} name="term" arrayOption={CmsData.targetOption} optionValue={"value"} optionLabel={"label"} />
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" />
        <button className="search-button" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  return (
    <div className="modal-overflow-table" style={{ maxHeight: "400px", overflowY: "auto" }}>
      <div className="left-table-div-cms">
        <Table>
          {CM.cfnCompColGroup(["120px"])}
          <TableForm.compServerSortDoubleRowTableHead
            rowOne={[{ id: "CUSTOMER_NAME", sortable: true, label: "고객명", rowSpan: 2 }, { label: "" }]}
            rowTwo={[{ label: "" }]}
            searchRequest={props.searchRequest}
            handleSortProperty={props.handleSortProperty}
          />

          <TableBody>
            {props.targetList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={1} />
            ) : (
              props.targetList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "12%" }} title={row.customer.customerName}>
                      {row.customer.customerName}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div-cms">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["100px", "100px", "130px", "110px", "130px", "150px", "150px", "130px", "130px", "130px", "130px", "130px", "130px", "130px"])}
            <TableForm.compServerSortDoubleRowTableHead
              useCheckbox={false}
              rowOne={[
                { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true, rowSpan: 2 },
                { id: "PAYER_NO", label: "납부자\n번호", sortable: true, rowSpan: 2 },
                { id: "CAPITAL", label: "자금종류", sortable: true, rowSpan: 2 },
                { id: "ACCOUNT_BANK", label: "금융기관", sortable: false, rowSpan: 2 },
                { id: "ACCOUNT_NO", label: "계좌번호", sortable: false, rowSpan: 2 },
                { id: "TARGET_Y4MM", label: "수납내역\n기간(갯수)", sortable: false, rowSpan: 2 },
                {
                  id: "CMS_RECEIPT_TARGET",
                  label: "출금청구 금액",
                  sortable: false,
                  colSpan: 5,
                },
                { id: "WITHDRAW_RESULT", label: "출금 결과", sortable: false, colSpan: 2 },
                { id: "RESULT_CODE", label: "오류내역", sortable: true, rowSpan: 2 },
              ]}
              rowTwo={[
                {
                  id: "TOTAL_ASKING_AMOUNT",
                  label: "합계",
                  sortable: true,
                },
                { id: "THIS_TIME_AMOUNT", label: "청구일분", sortable: true },
                {
                  id: "PREVIOUSLY_UNASKED_AMOUNT",
                  label: "미청구분",
                  sortable: true,
                },
                { id: "ASKING_UNPAID_AMOUNT", label: "미납분", sortable: true },
                { id: "ASKING_DELAY_FEE", label: "미납연체료", sortable: true },
                { id: "PAID_AMOUNT", label: "출금합계", sortable: true },
                { id: "UNPAID_AMOUNT", label: "출금실패", sortable: false },
              ]}
              searchRequest={props.searchRequest}
              handleSortProperty={props.handleSortProperty}
              tableSortLabelDataTestId="cmsReceiptRequestResultDetailTable"
            />

            <TableBody>
              {props.targetList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={13} />
              ) : (
                props.targetList.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center" className="ellipsis" title={row.customer.customerGroup.customerGroupName}>
                        {row.customer.customerGroup.customerGroupName}
                      </TableCell>
                      <TableCell align="center" className="ellipsis" title={row.contract.payerNo}>
                        {" "}
                        {row.contract.payerNo /*납부자번호*/}
                      </TableCell>
                      <TableCell align="center" className="ellipsis" title={row.contract.capital.capitalName}>
                        {" "}
                        {row.contract.capital.capitalName /*자금종류*/}
                      </TableCell>
                      <TableCell align="center" className="ellipsis" title={CM.cfnMatchBankName(row.account.accountBankCode, props.financialInstitutes)}>
                        {CM.cfnMatchBankName(row.account.accountBankCode, props.financialInstitutes) /*금융기관*/}
                      </TableCell>
                      <TableCell align="center" className="ellipsis" title={CM.cfnMasking(row.account.accountNo)}>
                        {CM.cfnMasking(row.account.accountNo) /*계좌번호*/}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="ellipsis"
                        title={
                          (row.targetSourceStartY4mm
                            ? CM.cfnDateFormat(row.targetSourceStartY4mm, "yyyyMM") +
                              (row.targetSourceStartY4mm === row.targetSourceEndY4mm ? "" : " ~\n" + CM.cfnDateFormat(row.targetSourceEndY4mm, "yyyyMM"))
                            : "") +
                          "(" +
                          row.numberOfIncludedSources +
                          ")"
                        }>
                        {(row.targetSourceStartY4mm
                          ? CM.cfnDateFormat(row.targetSourceStartY4mm, "yyyyMM") +
                            (row.targetSourceStartY4mm === row.targetSourceEndY4mm ? "" : " ~\n" + CM.cfnDateFormat(row.targetSourceEndY4mm, "yyyyMM"))
                          : "") +
                          "(" +
                          row.numberOfIncludedSources +
                          ")"}
                      </TableCell>
                      <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.totalAskingAmount)}>
                        {CM.cfnAddComma(row.totalAskingAmount) /*합계금액*/}원
                      </TableCell>
                      <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.thisTimeAmount)}>
                        {CM.cfnAddComma(row.thisTimeAmount) /*당회분*/}원
                      </TableCell>
                      <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.previouslyUnaskedAmount)}>
                        {CM.cfnAddComma(row.previouslyUnaskedAmount) /*미청구금*/}원
                      </TableCell>
                      <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.askingUnpaidAmount - row.askingDelayFee)}>
                        {CM.cfnAddComma(row.askingUnpaidAmount - row.askingDelayFee) /*미납액*/}원
                      </TableCell>
                      <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.askingDelayFee)}>
                        {CM.cfnAddComma(row.askingDelayFee) /*미납연체료*/}원
                      </TableCell>

                      {props.fileStatus === "TRANSMITTED" || props.fileStatus === "READY" ? (
                        <>
                          {/* 출금금액 */}
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                        </>
                      ) : (
                        <>
                          {/*출금금액*/}
                          <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.paidAmount)}>
                            {CM.cfnAddComma(row.paidAmount) + "원"}
                          </TableCell>
                          {/*출금실패액 */}
                          <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.totalAskingAmount - row.paidAmount)}>
                            {CM.cfnAddComma(row.totalAskingAmount - row.paidAmount) + "원"}
                          </TableCell>
                          {CM.cfnIsEmpty(row.resultCode) ? (
                            <TableCell align="center">{row.resultCode}</TableCell>
                          ) : (
                            <TableCell align="center">
                              <RawTooltipForm
                                rawContents={row.resultCode === "E_0000" ? "정상(0000)" : CDN.cmsResultCode(row.resultCode) + `(${row.resultCode.replace("E_", "")})`}
                                contents={row.resultCode === "E_0000" ? "정상(0000)" : CDN.cmsResultCode(row.resultCode) + `(${row.resultCode.replace("E_", "")})`}
                                rawClass="ellipsis"
                              />
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

/*
 * @desc  상단 요약 목록 컴포넌트
 */
const SummaryForm = (props) => {
  const renderBankFeeTooltip = (cmsBusiness) => {
    if (cmsBusiness) {
      switch (props.selectedRow.fileType) {
        case "EB21":
          return <TooltipForm contents={`청구 건당 ${cmsBusiness.bankFeeForEachEb21Request || 0}원, 이체 건당 ${cmsBusiness.bankFeeForEachEb21 || 0}원`} />;
        case "EC21":
          return <TooltipForm contents={`청구 건당 ${cmsBusiness.bankFeeForEachEc21Request || 0}원, 이체 건당 ${cmsBusiness.bankFeeForEachEc21 || 0}원`} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  };
  return (
    <div className="modal-overflow-table">
      <Table>
        {CM.cfnCompColGroup(["130px", "130px", "130px", "130px", "130px", "130px", "130px", "130px", "200px", "130px", "130px"])}
        <TableForm.compDoubleRowTableHead
          useCheckbox={false}
          rowOne={[
            { value: "출금청구내역", colSpan: 6 },
            { value: "출금결과내역", colSpan: 4 },
            { value: "입금예상액\n(예상수수료)", rowSpan: 2 },
          ]}
          rowTwo={[
            { value: "건수" },
            { value: "금액합계" },
            { value: "출금청구일분" },
            { value: "미청구분" },
            { value: "미납분" },
            { value: "연체료" },
            { value: "출금합계" },
            { value: "출금성공" },
            { value: "부분출금" },
            { value: "출금실패" },
          ]}
        />
        <TableBody>
          {CM.cfnIsEmpty(props.selectedRow) ? (
            <TableForm.compEmptyTableRow colSpan={15} />
          ) : (
            <TableRow>
              <TableCell align="center">{CM.cfnAddComma(props.selectedRow.numberOfAskedRecords) + "건"}</TableCell>
              <TableCell align="right">{CM.cfnAddComma(props.selectedRow.totalAskingAmount) + "원"}</TableCell>
              <TableCell align="right">{CM.cfnAddComma(props.selectedRow.totalAskingThisTimeAmount) + "원"}</TableCell>
              <TableCell align="right">{CM.cfnAddComma(props.selectedRow.totalAskingPreviouslyUnaskedAmount) + "원"}</TableCell>
              <TableCell align="right"> {CM.cfnAddComma(props.selectedRow.totalAskingUnpaidAmount) + "원"}</TableCell>
              <TableCell align="right">{CM.cfnAddComma(props.selectedRow.totalAskingDelayFee) + "원"}</TableCell>
              <TableCell align="right">
                {CM.cfnAddComma((props.selectedRow.amountOfFullWithdraw || 0) + (props.selectedRow.amountOfPartialWithdraw || 0)) + "원"}
                <br />
                {CM.cfnAddComma((props.selectedRow.numberOfFullWithdraw || 0) + (props.selectedRow.numberOfPartialWithdraw || 0)) + "건"}
              </TableCell>
              <TableCell align="right">
                {CM.cfnAddComma(props.selectedRow.amountOfFullWithdraw) + "원"}
                <br />
                {CM.cfnAddComma(props.selectedRow.numberOfFullWithdraw) + "건"}
              </TableCell>
              <TableCell align="right">
                {CM.cfnAddComma(props.selectedRow.amountOfPartialWithdraw) + "원 / "}
                <span className="red3">{CM.cfnAddComma(props.selectedRow.amountOfPartialError)}</span>원
                <br />
                {CM.cfnAddComma(props.selectedRow.numberOfPartialWithdraw) + "건"}
              </TableCell>
              <TableCell align="right">
                <span className="red3">{CM.cfnAddComma(props.selectedRow.amountOfFullError)}</span>원
                <br />
                {CM.cfnAddComma(props.selectedRow.numberOfFullError) + "건"}
              </TableCell>
              <TableCell align="right">
                {CM.cfnAddComma((props.selectedRow.amountOfFullWithdraw || 0) + (props.selectedRow.amountOfPartialWithdraw || 0) - (props.selectedRow.totalBankFee || 0)) + "원"}
                <br />({CM.cfnAddComma(props.selectedRow.totalBankFee)}원)
                {props.cmsService && props.cmsService.cmsBusiness && renderBankFeeTooltip(props.cmsService.cmsBusiness)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const CmsReceiptRequestResultDetail = (props) => {
  const classes = useStyles();

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(CmsData.requestSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalTargetList, setOriginalTargetList] = useState([]); // 서버로 부터 전달받은 original list
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());

  //검색조건 셀렉트 박스 옵션 관련 state 선언
  const [capitalOptions, setCapitalOptions] = useState([]);
  const [customerGroupOptions, setCustomerGroupOptions] = useState([]); //고객구분 옵션 제어

  //금융기관명 매칭을 위한 금융기관 목록
  const [financialInstitutes, setFinancialInstitutes] = useState(toJS(props.store.financialInstitutes));
  const [fileStatus, setFileStatus] = useState([]);

  /*
   * @desc 출금청구 추가대상 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);

    if (tempObj.term === "customerName") {
      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.payerNo = "";
    } else if (tempObj.term === "payerNo") {
      tempObj.payerNo = CM.cfnNvl(tempObj.keyword, "");
      tempObj.customerName = "";
    }

    return tempObj;
  }, [searchRequest]);

  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await props.store.axiosFinancialInstitutes();
      setFinancialInstitutes(institutesList);
    };

    if (props.open && props.tabIndex === 1 && CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();
  }, [props.open, props.tabIndex, props.store, financialInstitutes]);

  //화면 진입 시 자금 목록 조회
  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      const capitals = await props.store.axiosCapitals();
      setCapitalOptions(CmsData.fnMakeSelectOptions("capital", capitals));
    };

    //실행영역
    const storeCapital = CM.cfnCopyObject(toJS(props.store.capital));
    if (props.open && CM.cfnIsEmpty(storeCapital)) {
      fnFetchCapitalList();
    } else if (props.open && CM.cfnIsNotEmpty(storeCapital)) {
      setCapitalOptions(CmsData.fnMakeSelectOptions("capital", storeCapital));
    }
  }, [props.open, props.store]);

  //화면 진입 시 고객구분 목록 조회
  useEffect(() => {
    /*
     * @desc    고객구분 조회 Request
     */
    const fnFetchCustomerGroup = async () => {
      const groups = await props.store.axiosCustomerGroup();
      setCustomerGroupOptions(CmsData.fnMakeSelectOptions("group", groups));
    };

    //실행영역
    const storeGroup = CM.cfnCopyObject(toJS(props.store.customerGroup));
    if (CM.cfnIsEmpty(storeGroup)) {
      fnFetchCustomerGroup();
    } else {
      setCustomerGroupOptions(CmsData.fnMakeSelectOptions("group", storeGroup));
    }
  }, [props.open, props.store]);

  // 테이블 데이터 검색
  useEffect(() => {
    // axios request
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/cms/contents?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&resultCode=${search.resultCode}&groupUniqueKey=${search.groupUniqueKey}&customerName=${search.customerName}&payerNo=${search.payerNo}&capitalUniqueKey=${search.capitalUniqueKey}&fileUniqueKey=${props.selectedRow.fileUniqueKey}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          () => {
            handleSearchButtonChange(false);
          }
        );
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      setOriginalTargetList(resultData);
      handleTargetListChange(resultList);
      setPagination(CmsData.paginationData(resultData));
      handleSearchButtonChange(false);
      setFileStatus(props.selectedRow.fileStatus);
    };

    //실행영역
    if (props.open && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [props.open, props.selectedRow, searchRequest, searchButton, fnMakeParameter, setFileStatus]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 청구 대상 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleClickExcel = () => {
    const search = fnMakeParameter();
    const url = `api/receipt/cms/contents/excel?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${props.selectedRow.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payerNo=${search.payerNo}&resultCode=${search.resultCode}&isExemptExcluded=true&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    엑셀저장(목록) 버튼 클릭 이벤트 핸들러
   */
  const handleClickExcelForList = () => {
    const search = fnMakeParameter();
    const url = `api/receipt/cms/contents/excel?capitalUniqueKey=${search.capitalUniqueKey}&customerName=${search.customerName}&fileUniqueKey=${props.selectedRow.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payerNo=${search.payerNo}&resultCode=${search.resultCode}&isSummaryExcluded=true&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    모달 닫기 버튼 클릭 이벤트 핸들러
   */
  const handleClickModalClose = async () => {
    await props.handleDetailModalClose();

    //모달 내 상태 초기화
    setSearchRequest(CmsData.requestSearchData);
    setTargetList([]);
    handleSearchButtonChange(true); //검색 flag 설정
  };

  // 화면 렌더
  return (
    <Modal open={props.open}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClickModalClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>CMS 출금청구 결과</h3>
          <div>
            <SummaryForm selectedRow={props.selectedRow} cmsService={props.cmsService} />
            <div className="inforbox">
              <ul>
                <li>입금예상액은 실제 통장입금액과 다를 수 있습니다.</li>
              </ul>
            </div>
            <SearchForm
              searchRequest={searchRequest}
              capitalOptions={capitalOptions}
              customerGroupOptions={customerGroupOptions}
              withdrawResultOptions={CDN.cmsResultOptions}
              handleSearchFormChange={handleSearchFormChange}
              handleSearchButtonChange={handleSearchButtonChange}
            />
            <div className="table-top-area">
              <TotalCountForm totalElements={originalTargetList.totalElements || 0} />
              <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              <div className="btn-m fr" onClick={handleClickExcel} data-testid="insert-evidence">
                엑셀저장
              </div>
              <div className="btn-m fr" onClick={handleClickExcelForList} data-testid="insert-evidence">
                엑셀저장(목록)
              </div>
            </div>
            <ListForm
              targetList={targetList}
              setTargetList={setTargetList}
              handleTargetListChange={handleTargetListChange}
              searchRequest={searchRequest}
              financialInstitutes={financialInstitutes}
              handleSortProperty={handleSortProperty}
              fileStatus={fileStatus}
            />
            <Pagination
              className="centered"
              limit={pagination.rowsPerPage}
              offset={pagination.offset}
              total={pagination.total}
              onClick={(e, offset, page) => handleOffsetChange(offset, page)}
              reduced={true}
              centerRipple={false}
              disableFocusRipple={true}
              disableRipple={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(CmsReceiptRequestResultDetail));
