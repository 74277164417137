import React, { useState, useEffect } from "react";
import { Tab, Tabs, AppBar } from "@material-ui/core";

import * as CF from "../../template/ComponentForm";

/* 각 탭에 해당하는 화면 import */
import RegistrationsList from "./RegistrationsList"; // 등록대기
import FixingsList from "./FixingsList"; // 보완대상
import RegisteringsList from "./RegisteringsList"; // 계좌등록중
import RegisteredList from "./RegisteredList"; // 등록요청 내역
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import makeStyles from "@material-ui/core/styles/makeStyles";
/*
 * @desc  렌더를 처리하는 메인 컴포넌트
 */
const RegisterAccountByInstitute = (props) => {
  const { instituteStore } = props;
  // 이전 page로부터 전달받은 값
  let location = useLocation();
  let tabIdx = "";
  if (Object.isExtensible(location.state)) {
    tabIdx = location.state.tabIndex;
  }

  // 금융 기관/업무정보 목록을 가져오는 useEffect
  useEffect(() => {
    instituteStore.axiosFinancialInstitutes();
    instituteStore.axiosBusinessInfo();
  }, [instituteStore]);

  /* 각 탭의 전체 목록수 hooks */
  const [tabCount, setTabCount] = useState({
    registrations: 0, // 등록대기
    fixings: 0, // 보완대상
    registerings: 0, // 계좌등록중
    registered: 0, // 등록요청 내역
  });

  /* 각 탭의 전체 목록수를 변경 */
  const tabCountChange = (key, value) => setTabCount((element) => ({ ...element, [key]: value }));

  /* 탭 인덱스 hooks */
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabIndexChange = (value) => setTabIndex(value);

  useEffect(() => {
    if (tabIdx !== "") {
      setTabIndex(tabIdx);
    }
  }, [setTabIndex, tabIdx]);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  /* 화면 렌더 */
  return Object.isExtensible(instituteStore.businessInfo) && Object.isExtensible(instituteStore.businessInfo.cmsBusiness) ? (
    instituteStore.businessInfo.cmsBusiness.wayToRegisterAccount === "INSTITUTE_DIRECT_REGISTRATION" ? (
      <div name="main-tab">
        <AppBar position="static" color="default">
          <Tabs value={tabIndex} onChange={(event, value) => handleTabIndexChange(value)} indicatorColor="primary" textColor="primary" data-testid="tabs">
            <Tab value={0} disableRipple label={`보완대상(${tabCount.fixings})`} data-testid="tab-fixings" />
            <Tab value={1} disableRipple label={`등록대기(${tabCount.registrations})`} data-testid="tab-registrations" />
            <Tab value={2} disableRipple label={`계좌등록중(${tabCount.registerings})`} data-testid="tab-registerings" />
            <Tab value={3} disableRipple label={`등록요청 내역`} data-testid="tab-registered" />
          </Tabs>
        </AppBar>

        <CF.TabPanel value={tabIndex} index={0}>
          <FixingsList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
        </CF.TabPanel>
        <CF.TabPanel value={tabIndex} index={1}>
          <RegistrationsList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
        </CF.TabPanel>
        <CF.TabPanel value={tabIndex} index={2}>
          <RegisteringsList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
        </CF.TabPanel>
        <CF.TabPanel value={tabIndex} index={3}>
          <RegisteredList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
        </CF.TabPanel>
      </div>
    ) : (
      <div name="main-tab">
        <AppBar position="static" color="default" className={style.disabled_div}>
          <Tabs value={tabIndex} onChange={(event, value) => handleTabIndexChange(value)} indicatorColor="primary" textColor="primary" data-testid="tabs">
            <Tab disableRipple label={`보완대상(${tabCount.fixings})`} data-testid="tab-fixings" />
            <Tab disableRipple label={`등록대기(${tabCount.registrations})`} data-testid="tab-registrations" />
            <Tab disableRipple label={`계좌등록중(${tabCount.registerings})`} data-testid="tab-registerings" />
            <Tab disableRipple label={`등록요청 내역`} data-testid="tab-registered" />
          </Tabs>
        </AppBar>
        <h4>귀 기관은 수납고객을 금융기관 접수로만 등록 가능한 기관입니다.</h4>
        <div className="infobox fl">고객센터(1577-5500)로 문의주시기 바랍니다.</div>
      </div>
    )
  ) : (
    <div name="main-tab">
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={(event, value) => handleTabIndexChange(value)} indicatorColor="primary" textColor="primary" data-testid="tabs">
          <Tab value={0} disableRipple label={`보완대상(${tabCount.fixings})`} data-testid="tab-fixings" />
          <Tab value={1} disableRipple label={`등록대기(${tabCount.registrations})`} data-testid="tab-registrations" />
          <Tab value={2} disableRipple label={`계좌등록중(${tabCount.registerings})`} data-testid="tab-registerings" />
          <Tab value={3} disableRipple label={`등록요청 내역`} data-testid="tab-registered" />
        </Tabs>
      </AppBar>

      <CF.TabPanel value={tabIndex} index={0}>
        <FixingsList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
      </CF.TabPanel>
      <CF.TabPanel value={tabIndex} index={1}>
        <RegistrationsList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
      </CF.TabPanel>
      <CF.TabPanel value={tabIndex} index={2}>
        <RegisteringsList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
      </CF.TabPanel>
      <CF.TabPanel value={tabIndex} index={3}>
        <RegisteredList tabCountChange={tabCountChange} tabIndex={tabIndex} financialInstitutes={instituteStore.financialInstitutes} />
      </CF.TabPanel>
    </div>
  );
};

export default inject((rootStore, props) => ({
  instituteStore: rootStore.instituteStore,
  props: props,
}))(observer(RegisterAccountByInstitute));
