import { Checkbox, FormControl, Input, Paper, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { PaginationForm, RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import BranchModal from "./BranchModal";
import StaffData from "./StaffInfoData";
import StaffModal from "./StaffModal";

/*
 * @desc    상단 검색조건 영역 컴포넌트 선언
 */
const SearchForm = (props) => {
  const { searchCondition, handleSearchConditionChange } = props;

  return (
    <Fragment>
      <label className="label-l">검색조건</label>
      <FormControl>
        <Select native value={searchCondition.term} onChange={handleSearchConditionChange("term")} inputProps={{ "data-testid": "search-terms" }}>
          {StaffData.searchKeywordOptions.map((row, index) => {
            return (
              <option key={index} value={row.value}>
                {row.label}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl>
        <Input className="w230" placeholder="검색" value={searchCondition.keyword} onChange={handleSearchConditionChange("keyword")} data-testid="search-keyword" />
      </FormControl>
    </Fragment>
  );
};

/*
 * @desc    메인 컴포넌트 선언
 */
const StaffInfo = (props) => {
  const store = props.instituteStore;

  const [fetchStaffFlag, setFetchStaffFlag] = useState(true);
  const [fetchBranchFlag, setFetchBranchFlag] = useState(true);

  const [staffs, setStaffs] = useState(toJS(store.staffs)); //담당자 목록
  const [branches, setBranches] = useState(toJS(store.branches)); //지사 목록
  const [selectedKey, setSelectedKey] = useState(""); //행 클릭 시 선택된 담당자의 uniqueKey 저장
  const [searchCondition, setSearchCondition] = useState(StaffData.staffSearchCondition); //검색조건
  const [pagination, setPagination] = useState(StaffData.paginationData); //paging 처리

  //모달창 열고 닫기 위한 state 선언
  const [staffOpen, setStaffOpen] = useState(false);
  const [branchOpen, setBranchOpen] = useState(false);

  //화면 진입 시 데이터 조회
  useEffect(() => {
    /*
     * @desc    담당자 목록 조회 Request
     */
    const fnFetchStaffList = async () => {
      const staffList = await store.axiosStaffs();
      setStaffs(staffList);
    };

    /*
     * @desc    지사 목록 조회 Request
     */
    const fnFetchBranchList = async () => {
      const branchList = await store.axiosBranches();
      setBranches(branchList);
    };
    if (fetchBranchFlag) {
      setFetchBranchFlag(false);
      fnFetchBranchList();
    }

    if (fetchStaffFlag) {
      setFetchStaffFlag(false);
      fnFetchStaffList();
    }
  }, [fetchBranchFlag, fetchStaffFlag, store]);

  /*
   * @desc    검색조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchConditionChange = (name) => (e) => {
    setSearchCondition({
      ...searchCondition,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    테이블 정렬 이벤트 핸들러
   */
  const handleRequestSort = (event, property) => {
    const isDesc = searchCondition.sortProperty === property && searchCondition.sortDirection === "desc";

    setSearchCondition({
      ...searchCondition,
      sortDirection: isDesc ? "asc" : "desc",
      sortProperty: property,
    });
  };

  /*
   * @desc    페이지 변경 이벤트 핸들러
   */
  const handleOffsetChange = (offset, page) => {
    setPagination({
      ...pagination,
      offset: offset,
      pageNumber: page - 1,
    });
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러
   */
  const handleRowPerPageChange = (e) => {
    setPagination({
      ...pagination,
      pageNumber: 0,
      rowsPerPage: e.target.value,
    });
  };

  /*
   * @desc    테이블 내 담당자 이름 클릭 이벤트 핸들러
   */
  const handleCellClick = async (rowData) => {
    await setSelectedKey(rowData.uniqueKey);
    setStaffOpen(true);
  };

  /*
   * @desc    테이블 내 데이터 정렬 처리 함수
   */
  const getSorting = (order, orderBy) => {
    return order === "desc" ? (a, b) => StaffData.desc(a, b, orderBy) : (a, b) => -StaffData.desc(a, b, orderBy);
  };

  /*
   * @desc    상단 검색조건에 따라 테이블 결과값 필터링 되도록 변수 선언
   */
  const filteredRows = StaffData.stableSort(staffs, getSorting(searchCondition.sortDirection, searchCondition.sortProperty)).filter((row) => {
    let flag = true;
    if (searchCondition.keyword.length > 0) {
      switch (searchCondition.term) {
        case "staffName":
          if (searchCondition.keyword !== "" && row.staffName.indexOf(searchCondition.keyword) === -1) flag = false;
          break;
        case "branchName":
          if (searchCondition.keyword !== "" && row.branch.branchName.indexOf(searchCondition.keyword) === -1) flag = false;
          break;
        default:
          flag = true;
          break;
      }
    }
    return flag;
  });

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>빌링원플러스의 담당자 정보를 수정하여도 다른 서비스의 담당자 정보가 변경되지는 않습니다.</li>
          <li>빌링원플러스의 총괄담당자를 변경하시면 CMS업무담당자는 일치화되어 변경이 됩니다. <br/>
          단, 기타 CMS민원, 요금담당자 등은 {" "}
            <a href="https://www.cmsedi.or.kr/cms" target="_blank" rel="noopener noreferrer" className="link">
              CMS홈페이지
            </a>
            에서 변경하시거나 고객센터(1577-5500)로 문의하시기 바랍니다.
          </li>
        </ul>
      </div>
      <div className="search-area">
        <div className="block">
          <SearchForm searchCondition={searchCondition} handleSearchConditionChange={handleSearchConditionChange} />
        </div>
      </div>
      <div className="table-top-area">
        <TotalCountForm totalElements={staffs.length || 0} />
        <RowPerPageForm value={pagination.rowsPerPage} onChange={handleRowPerPageChange} data-testid="staff-row-per-page" />
        <div className="btn-m fr" onClick={(e) => setStaffOpen(true)} data-testid="open-staff-modal">
          담당자 추가
        </div>
        <div className="btn-m fr" onClick={(e) => setBranchOpen(true)} data-testid="open-branch-modal">
          지사 관리
        </div>
      </div>
      <div>
        <Paper>
          <Table aria-labelledby="tableTitle" data-testid="staff-table">
            <TableForm.compSortableTableHead searchCondition={searchCondition} onRequestSort={handleRequestSort} rowCount={staffs.length} headRows={StaffData.staffTableheadRows} />
            <TableBody>
              {filteredRows.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={9} />
              ) : (
                filteredRows
                  .slice(pagination.pageNumber * pagination.rowsPerPage, pagination.pageNumber * pagination.rowsPerPage + pagination.rowsPerPage)
                  .filter((rows) => rows !== {})
                  .map((row, index) => {
                    return (
                      <TableRow key={index} hover className="show-detail" onClick={(e) => handleCellClick(row)}>
                        <TableCell align="center" component="th" scope="row" padding="none" value={row.branchUniqueKey}>
                          {row.branch.branchName}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" padding="none">
                          {row.staffName}
                        </TableCell>
                        <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(row.staffMobile)}</TableCell>
                        <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(row.staffTelephone)}</TableCell>
                        <TableCell align="center">
                          <Checkbox checked={!!row.loginYn} disabled={true} />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox checked={!!row.masterYn} disabled={true} />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox checked={!!row.billingManagerYn} disabled={true} />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox checked={!!row.cashbillManagerYn} disabled={true} />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox checked={!!row.trusbillManagerYn} disabled={true} />
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <PaginationForm filteredRows={filteredRows} pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <BranchModal open={branchOpen} setOpen={setBranchOpen} setStaffs={setStaffs} setBranches={setBranches} />
      <StaffModal open={staffOpen} setOpen={setStaffOpen} selectedKey={selectedKey} setSelectedKey={setSelectedKey} branches={branches} setStaffs={setStaffs} />
    </div>
  );
};

export default inject((rootStore) => ({
  instituteStore: rootStore.instituteStore,
}))(observer(StaffInfo));
