import React, { useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

let openConfirmFn = () => {};

const ConfirmForm = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [callback, setCallback] = useState({ function: "" });
  const [cancelCallback, setCancelCallback] = useState({ function: "" });

  React.useEffect(() => {
    openConfirmFn = openConfirm;
  }, []);

  const openConfirm = async (message, cb, ccb) => {
    setOpen(true);
    setMessage(message);
    setCallback({ function: cb });
    setCancelCallback({ function: ccb });
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleConfirmOk();
    } else if (e.keyCode === 27) {
      handleConfirmClose();
    }
  };

  const handleConfirmClose = () => {
    setOpen(false);
    if (typeof cancelCallback.function === "function") cancelCallback.function();
  };

  const handleConfirmOk = () => {
    setOpen(false);
    if (typeof callback.function === "function") callback.function();
  };

  return (
    <Dialog open={open} onClose={handleConfirmClose} transitionDuration={{ enter: 100, exit: 100 }} scroll="paper" disableBackdropClick={true} onKeyUp={handleKeyUp}>
      <DialogContent>
        <DialogContentText id="alert-description" style={{ whiteSpace: "pre-line" }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="table-bottom-area">
          <button className="btn-m" onClick={handleConfirmOk} data-testid="common-confirm-ok">
            확인
          </button>
          <button className="btn-m" onClick={handleConfirmClose} data-testid="common-confirm-cancel">
            취소
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export function openConfirm(message, callback, cancelCallback) {
  openConfirmFn(message, callback, cancelCallback);
}

export default ConfirmForm;
