import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import { AppBar, Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "../../template/ComponentForm";

import makeStyles from "@material-ui/core/styles/makeStyles";

import * as CM from "../../common/Common";

import CmsReceiptRequest from "./CmsReceiptRequest";
import CmsReceiptRequestResult from "./CmsReceiptRequestResult";
import CmsReceiptAutoWithdrawFailResult from "./CmsReceiptAutoWithdrawFailResult";

/*
 * @desc  전체 disable 처리를 하는 css
 */
const useStyles = makeStyles((theme) => ({
  disabled_div: {
    "pointer-events": "none",
    opacity: 0.6,
  },
}));

/*
 * @desc  자동출금예약 서비스 이용중 안내 컴포넌트 생성
 */
const AutoWithdraw = () => {
  return (
    <div>
      <h4 data-testid="auto-withdraw-text">현재 "자동출금예약 서비스"를 이용 중입니다.</h4>
      <div className="infobox">
        긴급히 출금할 내역이 있는 경우 가장 빨리 도래하는 예약된 이체일에 일시내역을 추가하시거나 수납고객원장에서 수납일을 가장 빨리 도래하는 출금일로 변경하시기 바랍니다.
      </div>
    </div>
  );
};

//수납관리 > CMS 출금관리
/*
 * @desc  메인 컴포넌트 생성
 */
const CmsReceipt = (props) => {
  const tabRef = useRef();
  const store = props.instituteStore;

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  const [autoWithdraw, setAutoWithdraw] = useState(toJS(store.autoWithdraw));
  const [businessInfo, setBusinessInfo] = useState(toJS(store.businessInfo)); //기관업무정보 제어
  const [isReceiptCustomer, setIsReceiptCustomer] = useState(false);
  const [autoWithdrawSummary, setAutoWithdrawSummary] = useState();

  const style = useStyles();
  // 이전 page로부터 전달받은 값
  let location = useLocation();
  let tabIdx = "";
  if (Object.isExtensible(location.state)) {
    tabIdx = location.state.tabIndex;
  }

  useEffect(() => {
    if (tabIdx !== "") {
      setTabIndex(tabIdx);
    }
  }, [setTabIndex, tabIdx]);

  useEffect(() => {
    /*
     * @desc  자동출금 정보 조회
     */
    async function fnFetchAutoWithdrawInfo() {
      try {
        const storeInfo = CM.cfnCopyObject(toJS(store.autoWithdraw));
        if (CM.cfnIsEmpty(storeInfo)) {
          await store.axiosAutoWithdraw();
        }
        setAutoWithdraw(CM.cfnCopyObject(toJS(store.autoWithdraw)));
      } catch (error) {
        console.log(error);
      }
    }

    if (CM.cfnIsEmpty(autoWithdraw)) fnFetchAutoWithdrawInfo();
  }, [store, autoWithdraw]);

  useEffect( () => {

    /*
    * @desc 자동출금 요약정보 조회 Request
    */

    const axiosAutowithdrawSummary = () => {
      return new Promise((resolve => {
        let url = 'api/receipt/cms/autowithdraw/results';
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      }));
    };


    async function fnFetchAutowithdrawSummary() {
      const resultData = await axiosAutowithdrawSummary();
      setAutoWithdrawSummary((element) => ({
        ...element,
        isResultSummary : resultData.isResultSummary,
        eventDate : resultData.eventDate,
        eventSequence : resultData.eventSequence,
        eventTime : resultData.eventTime,
        fileType : resultData.fileType,
        resultMessage : resultData.resultMessage,
        totalAskRecords : resultData.totalAskRecords,
        totalAskingAmount : resultData.totalAskingAmount,
        transactionDate : resultData.transactionDate,
        transactionStatus : resultData.transactionStatus
      }));
    };

    if (CM.cfnIsEmpty(autoWithdrawSummary)) {
      fnFetchAutowithdrawSummary();
    }

  }, [autoWithdrawSummary]);

  //화면 진입 시 기관업무 정보 조회
  useEffect(() => {
    /*
     * @desc    기관 업무정보 조회 Request callback
     */
    const fnSetBusinessInfo = async () => {
      const result = await store.axiosBusinessInfo();
      setBusinessInfo(result);
      fnSetReceiptUser(result);
    };

    /*
     * @desc    출금이체기관 여부 판별 및 데이터 세팅 함수
     */
    const fnSetReceiptUser = (data) => {
      if (data.cmsService.indexOf("EB21") !== -1 || data.cmsService.indexOf("EC21") !== -1) {
        setIsReceiptCustomer(true);
      } else {
        CM.cfnAlert("CMS출금이체 서비스를 이용하지 않아 본 페이지의 기능을 이용할 수 없습니다.");
        setIsReceiptCustomer(false);
      }
    };

    if (CM.cfnIsEmpty(businessInfo)) {
      fnSetBusinessInfo();
    } else {
      fnSetReceiptUser(businessInfo);
    }
  }, [businessInfo, store]);

  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  return (
    <div>
      {isReceiptCustomer ? (
        <div>
          <AppBar position="static" color="default">
            <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="cms-receipt-tab">
              <Tab ref={tabRef} disableRipple value={0} label="CMS 출금청구" />
              <Tab disableRipple value={1} label="CMS 출금청구 결과" />
            </Tabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
            {autoWithdraw.serviceStatus === "NORMAL" ?
                CM.cfnIsEmpty(autoWithdrawSummary) ?
                    <AutoWithdraw /> :
                autoWithdrawSummary.isResultSummary ?
                    <CmsReceiptAutoWithdrawFailResult autoWithdrawSummary={autoWithdrawSummary} store={store} /> : <AutoWithdraw />
                    : <CmsReceiptRequest tabIndex={tabIndex} store={store} businessInfo={businessInfo} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <div>
              <div className="inforbox">
                <ul>
                  <li>출금청구 건은 출금청구 가능시각까지 출금청구하지 않을 경우 출금청구가 불가합니다.</li>
                </ul>
              </div>
            </div>
            <CmsReceiptRequestResult tabIndex={tabIndex} setTabIndex={setTabIndex} targetTab={tabRef.current} store={store} />
          </TabPanel>
        </div>
      ) : (
        <div className={style.disabled_div}>
          <AppBar position="static" color="default">
            <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="cms-receipt-tab">
              <Tab ref={tabRef} disableRipple value={0} label="CMS 출금청구" />
              <Tab disableRipple value={1} label="CMS 출금청구 결과" />
            </Tabs>
          </AppBar>
        </div>
      )}
    </div>
  );
};

export default inject((rootStore) => ({
  instituteStore: rootStore.instituteStore,
}))(observer(CmsReceipt));
