import React, { Fragment } from "react";

import { Table, TableBody, TableRow, TableCell, Input, FormControl, FormControlLabel, RadioGroup, Radio, Divider } from "@material-ui/core";

import PaperData from "./PaperTaxBillData";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

/*
 * @desc  종이(세금)계산서 발행 모달 - 상단 영수자 선택 컴포넌트
 */
export const CustomerSelect = ({ selectedBillsList, selectedPaperTaxBill, currentIndex, handleChangeSelected, master }) => {
  return (
    <div className="search-area">
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <button className="btn-s" disabled={currentIndex === 0 ? true : false} onClick={(e) => handleChangeSelected("prev")}>
          이전
        </button>
        <span className="label-l">등록번호</span>
        <span name="billRecipient" className="w100" data-testid="paperTaxbill-selected-target-customer-name">
          {CM.cfnIdentificationNoFormat(selectedPaperTaxBill.customer.identificationNo) || ""}
        </span>
        <span className="label-l">공급받는자</span>
        <span name="billRecipient" className="w100" data-testid="paperTaxbill-selected-target-customer-name">
          {selectedPaperTaxBill.customer.customerName || ""}
        </span>
        <button className="btn-s" disabled={currentIndex === selectedBillsList.length - 1 ? true : false} onClick={(e) => handleChangeSelected("next")}>
          다음
        </button>
        <label className="label-l fr">
          전체 {selectedBillsList.length}건 중 {currentIndex + 1}번째
        </label>
      </div>
    </div>
  );
};

/*
 * @desc  종이(세금)계산서 발행 모달 - 상단 영수자 상세정보 컴포넌트
 */
export const CustomerInfo = ({ selectedPaperTaxBill, handlePaperTaxBillFormChange, master }) => {
  const convertSelectedPaperTaxBill = PaperData.fnGetCustomerDetailInfo(selectedPaperTaxBill.customer);
  return (
    <Fragment>
      <Table>
        {CM.cfnCompColGroup(["50%", "10%", "10%", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th" colSpan="2">
              {selectedPaperTaxBill["taxbillType"] === "PAPER" ? "세금계산서" : "계산서"}
            </TableCell>
            <TableCell className="th">책번호</TableCell>
            <TableCell className="td" align="center">
              <Input
                className="w60 editableCellInput"
                value={selectedPaperTaxBill["instMainBookNumber"] || ""}
                onChange={handlePaperTaxBillFormChange("instMainBookNumber")}
                data-testid="paperTaxbill-selectedTarget-instMainBookNumber-input"
                inputProps={{ maxLength: 6 }}
              />
              권
              <Input
                className="w60 editableCellInput"
                value={selectedPaperTaxBill["instSubBookNumber"] || ""}
                onChange={handlePaperTaxBillFormChange("instSubBookNumber")}
                data-testid="paperTaxbill-selectedTarget-instSubBookNumber-input"
              />
              호
            </TableCell>
            <TableCell className="th">일련번호</TableCell>
            <TableCell className="td" align="center">
              <Input
                className="w100 editableCellInput"
                value={selectedPaperTaxBill["instSerialNumber"] || ""}
                onChange={handlePaperTaxBillFormChange("instSerialNumber")}
                data-testid="paperTaxbill-selectedTarget-instSerialNumber-input"
                inputProps={{ maxLength: 6 }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        {CM.cfnCompColGroup(["5%", "10%", "15%", "10%", "15%", "5%", "10%", "15%", "10%", "15%"])}
        <TableBody>
          <TableRow>
            <TableCell className="th borderTop-none" rowSpan="4">
              공<br />급<br />자
            </TableCell>
            <TableCell className="th borderTop-none">등록번호</TableCell>
            <TableCell className="td borderTop-none" colSpan="3" align="center">
              {CM.cfnIdentificationNoFormat(master.bizNo)}
            </TableCell>
            <TableCell className="th borderTop-none" rowSpan="4">
              공<br />급<br />받<br />는<br />자
            </TableCell>
            <TableCell className="th borderTop-none">등록번호</TableCell>
            <TableCell className="td borderTop-none" colSpan="3" align="center">
              {selectedPaperTaxBill.customer.identificationNo && selectedPaperTaxBill.customer.identificationNo.length >= 10
                ? CM.cfnIdentificationNoFormat(selectedPaperTaxBill.customer.identificationNo)
                : selectedPaperTaxBill.customer.identificationNo || ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              상호
              <br />
              (법인명)
            </TableCell>
            <TableCell className="td" align="center">
              {master.instName}
            </TableCell>
            <TableCell className="th">성명</TableCell>
            <TableCell className="td" align="center">
              {master.ceoName}
            </TableCell>
            <TableCell className="th">
              상호
              <br />
              (법인명)
            </TableCell>
            <TableCell className="td" align="center">
              {selectedPaperTaxBill.customer.customerName}
            </TableCell>
            <TableCell className="th">성명</TableCell>
            <TableCell className="td" align="center">
              {convertSelectedPaperTaxBill.representativeName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              사업장
              <br />
              주소
            </TableCell>
            <TableCell className="td" colSpan="3" align="center">
              {master.address ? "(" + master.address.zipCode + ") " + master.address.address + " " + master.address.addressDetail : "" /* 공급자 주소*/}
            </TableCell>
            <TableCell className="th">
              사업장
              <br />
              주소
            </TableCell>
            <TableCell className="td" colSpan="3" align="center">
              {
                selectedPaperTaxBill.customer.customerAddress
                  ? "(" + selectedPaperTaxBill.customer.customerZipCode + ")" + selectedPaperTaxBill.customer.customerAddress + " " + selectedPaperTaxBill.customer.customerAddressDetail
                  : "" /*공급받는자주소*/
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">업태</TableCell>
            <TableCell className="td" align="center">
              {master.bizType /* 업태 */}
            </TableCell>
            <TableCell className="th">종목</TableCell>
            <TableCell className="td" align="center">
              {master.bizItem /* 종목 */}
            </TableCell>
            <TableCell className="th">업태</TableCell>
            <TableCell className="td" align="center">
              {convertSelectedPaperTaxBill.business || "" /* 영수자 업태 */}
            </TableCell>
            <TableCell className="th">종목</TableCell>
            <TableCell className="td" align="center">
              {convertSelectedPaperTaxBill.sectors || "" /* 영수자 종목 */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

/*
 * @desc  종이(세금)계산서 발행 모달 - 계산서 내역 컴포넌트
 */
export const ReceiptInfo = ({ selectedPaperTaxBill, handlePaperTaxBillFormChange }) => {
  return (
    <Fragment>
      <h4>{selectedPaperTaxBill.taxbillType === "PAPER" ? "세금" : ""}계산서 내역</h4>
      <Table>
        {selectedPaperTaxBill.taxbillType === "PAPER" ? (
          <Fragment>
            {CM.cfnCompColGroup(["25%", "25%", "25%", "25%"])}
            <TableForm.compTableHead arrData={["작성년월일", "공급가액", "세액", "비고"]} />
          </Fragment>
        ) : (
          <Fragment>
            {CM.cfnCompColGroup(["auto", "auto", "auto"])}
            <TableForm.compTableHead arrData={["작성년월일", "공급가액", "비고"]} />
          </Fragment>
        )}
        <TableBody>
          <TableRow>
            <TableCell className="td" align="center">
              <CF.DatePickerForm
                className="w160"
                value={selectedPaperTaxBill["issueDate"] || "20200101"}
                handleChange={handlePaperTaxBillFormChange("issueDate")}
                format="yyyy.MM.dd"
                customProps={{
                  placeholder: "YYYY.MM.DD",
                  inputProps: { "data-testid": "paperbill-selectedTarget-datePicker-issueDate" },
                }}
              />
            </TableCell>
            <TableCell className="td" align="center">
              {CM.cfnAddCommaOnChange(selectedPaperTaxBill["totalSuppliedAmount"]) || 0}원
            </TableCell>
            {selectedPaperTaxBill.taxbillType === "PAPER" && (
              <TableCell className="td" align="center">
                {CM.cfnAddCommaOnChange(selectedPaperTaxBill["totalTaxAmount"]) || 0}원
              </TableCell>
            )}
            <TableCell className="td" align="center">
              <Input
                className="w180"
                value={selectedPaperTaxBill["billRemark"] || ""}
                onChange={handlePaperTaxBillFormChange("billRemark")}
                data-testid="paperbill-selectedTarget-remark-input"
                inputProps={{ maxLength: 20 }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

/*
 * @desc  종이(세금)계산서 발행 모달 - 공급내역 컴포넌트
 */
export const TaxBillDetails = ({ selectedPaperTaxBill, handlePaperTaxBillFormChange, inputMethod, handleInputMethodChange }) => {
  return (
    <Fragment>
      <h4>공급내역</h4>
      <FormControl component="fieldset" style={{ marginBottom: "10px" }}>
        <RadioGroup name="inputMethod" value={inputMethod} onChange={handleInputMethodChange} data-testid="paper-taxbill-inputMethod-radio" row>
          <FormControlLabel value="unitPrice" control={<Radio color="primary" />} label="수량/단가 입력" />
          <FormControlLabel value="supply" control={<Radio color="primary" />} label="공급가액 입력" />
          <FormControlLabel value="whole" control={<Radio color="primary" />} label="전체 입력" />
          <FormControlLabel value="sum" control={<Radio color="primary" />} label="합계금액 입력 :" />
          <Input
            name="totalAmount"
            value={CM.cfnAddCommaOnChange(selectedPaperTaxBill["totalAmount"]) || 0}
            className="w100 tc editableCellInput"
            disabled={inputMethod !== "sum"}
            readOnly={inputMethod !== "sum"}
            onChange={handlePaperTaxBillFormChange("totalAmount")}
            endAdornment="원"
          />
        </RadioGroup>
      </FormControl>

      <Table>
        {selectedPaperTaxBill.taxbillType === "PAPER" ? (
          <Fragment>
            {CM.cfnCompColGroup(["5%", "5%", "12%", "10%", "8%", "15%", "15%", "15%", "15%"])}
            <TableForm.compTableHead arrData={["월", "일", "품목", "규격", "수량", "단가", "공급가액", "세액", "비고"]} />
          </Fragment>
        ) : (
          <Fragment>
            {CM.cfnCompColGroup(["5%", "5%", "12%", "10%", "8%", "15%", "15%", "auto%"])}
            <TableForm.compTableHead arrData={["월", "일", "품목", "규격", "수량", "단가", "공급가액", "비고"]} />
          </Fragment>
        )}
        <TableBody>
          {CM.cfnIsNotEmpty(selectedPaperTaxBill.paperTaxbillItemDtos) &&
            selectedPaperTaxBill.paperTaxbillItemDtos.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="td" align="center">
                    <Input
                      className="w40 editableCellInput"
                      value={selectedPaperTaxBill["paperTaxbillItemDtos"][index]["month"] || ""}
                      onChange={handlePaperTaxBillFormChange("month", index)}
                      data-testid={`paperbill-selectedTarget-month-${index}`}
                      inputProps={{ maxLength: 2 }}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="w40 editableCellInput"
                      value={selectedPaperTaxBill["paperTaxbillItemDtos"][index]["day"] || ""}
                      onChange={handlePaperTaxBillFormChange("day", index)}
                      data-testid={`paperbill-selectedTarget-day-${index}`}
                      inputProps={{ maxLength: 2 }}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="w100"
                      value={selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemName"] || ""}
                      onChange={handlePaperTaxBillFormChange("itemName", index)}
                      data-testid={`paperbill-selectedTarget-itemName-${index}`}
                      inputProps={{ maxLength: 30 }}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="w80"
                      value={selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemSpecification"] || ""}
                      onChange={handlePaperTaxBillFormChange("itemSpecification", index)}
                      data-testid={`paperbill-selectedTarget-itemSpecification-${index}`}
                      inputProps={{ maxLength: 20 }}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="editableCellInput"
                      value={CM.cfnAddCommaOnChange(selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemQuantity"]) || 0}
                      onChange={handlePaperTaxBillFormChange("itemQuantity", index, "amount")}
                      disabled={inputMethod === "supply" || inputMethod === "sum"}
                      data-testid={`paperbill-selectedTarget-itemQuantity-${index}`}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="editableCellInput"
                      value={CM.cfnAddCommaOnChange(selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemUnitPrice"]) || 0}
                      onChange={handlePaperTaxBillFormChange("itemUnitPrice", index, "amount")}
                      disabled={inputMethod === "supply" || inputMethod === "sum"}
                      data-testid={`paperbill-selectedTarget-itemUnitPrice-${index}`}
                      endAdornment="원"
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="editableCellInput"
                      value={CM.cfnAddCommaOnChange(selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemSupplyAmount"]) || 0}
                      onChange={handlePaperTaxBillFormChange("itemSupplyAmount", index, "amount")}
                      disabled={inputMethod === "unitPrice" || inputMethod === "sum"}
                      data-testid={`paperbill-selectedTarget-itemSupplyAmount-${index}`}
                      endAdornment="원"
                    />
                  </TableCell>
                  {selectedPaperTaxBill.taxbillType === "PAPER" && (
                    <TableCell className="td">
                      <Input
                        className="editableCellInput"
                        value={CM.cfnAddCommaOnChange(selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemTaxAmount"]) || 0}
                        onChange={handlePaperTaxBillFormChange("itemTaxAmount", index, "amount")}
                        disabled={inputMethod !== "whole"}
                        data-testid={`paperbill-selectedTarget-itemTaxAmount-${index}`}
                        endAdornment="원"
                      />
                    </TableCell>
                  )}
                  <TableCell className="td">
                    <Input
                      value={selectedPaperTaxBill["paperTaxbillItemDtos"][index]["itemRemark"] || ""}
                      onChange={handlePaperTaxBillFormChange("itemRemark", index)}
                      data-testid={`paperbill-selectedTarget-itemRemark-${index}`}
                      inputProps={{ maxLength: 30 }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

/*
 * @desc  종이(세금)계산서 발행 모달 - 하단 결제방법 컴포넌트
 */
export const PaymentMethod = ({ selectedPaperTaxBill, handlePaperTaxBillFormChange }) => {
  return (
    <Fragment>
      <h4>결제방법</h4>
      <div className="summary-main-area">
        <div className="summary-area">
          <Table aria-labelledby="tableTitle">
            {CM.cfnCompColGroup(["100px", "100px", "100px", "100px", "150px"])}
            <TableForm.compTableHead arrData={["현금", "수표", "어음", "외상미수금", "합계"]} cellClassName="borderTop-none" />
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Input className="editableCellInput" endAdornment="원" value={CM.cfnAddCommaOnChange(selectedPaperTaxBill.cashAmount) || 0} onChange={handlePaperTaxBillFormChange("cashAmount")} />
                </TableCell>
                <TableCell align="center">
                  <Input
                    className="editableCellInput"
                    endAdornment="원"
                    value={CM.cfnAddCommaOnChange(selectedPaperTaxBill.chequeAmount) || 0}
                    onChange={handlePaperTaxBillFormChange("chequeAmount")}
                  />
                </TableCell>
                <TableCell align="center">
                  <Input className="editableCellInput" endAdornment="원" value={CM.cfnAddCommaOnChange(selectedPaperTaxBill.noteAmount) || 0} onChange={handlePaperTaxBillFormChange("noteAmount")} />
                </TableCell>
                <TableCell align="center">
                  <Input
                    className="editableCellInput"
                    endAdornment="원"
                    value={CM.cfnAddCommaOnChange(selectedPaperTaxBill.unpaidAmount) || 0}
                    onChange={handlePaperTaxBillFormChange("unpaidAmount")}
                  />
                </TableCell>
                <TableCell align="center">
                  <Input className="editableCellInput" endAdornment="원" value={CM.cfnAddCommaOnChange(selectedPaperTaxBill.totalAmount) || 0} readOnly={true} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="summary-button-area">
          <label>위 금액을</label>
          <FormControl component="fieldset">
            <RadioGroup
              name="taxbillDirection"
              value={selectedPaperTaxBill.taxbillDirection || "RECEIPT"}
              onChange={handlePaperTaxBillFormChange("taxbillDirection")}
              data-testid="paper-taxbill-taxbillDirection-radio"
              row>
              <FormControlLabel value="RECEIPT" checked={selectedPaperTaxBill.taxbillDirection === "RECEIPT" ? true : false} control={<Radio color="primary" />} label="영수함" />
              <FormControlLabel value="INVOICE" checked={selectedPaperTaxBill.taxbillDirection === "INVOICE" ? true : false} control={<Radio color="primary" />} label="청구함" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </Fragment>
  );
};

/*
 * @desc  종이(세금)계산서 발행내역 - 인쇄용 계산서 생성 컴포넌트
 */
export const PaperTaxBill = ({ paperTaxBill, master }) => {
  const convertPaperTaxBill = PaperData.fnGetCustomerDetailInfo(paperTaxBill.customer);
  return (
    <div className="bill-print-area">
      <div className="tax-bill-recipient">
        <div className="table-top-area">
          <span className="fl">[별지 제11호 서식]</span>
        </div>
        <table>
          {CM.cfnCompColGroup(["60%", "10%", "auto"])}
          <tbody>
            <tr key="taxbill-0">
              <th rowSpan="2" style={{ fontSize: "20px" }}>
                {paperTaxBill.taxbillType === "PAPER" ? "세금계산서" : "계산서"}
                (공급받는자 보관용)
              </th>
              <th rowSpan="2">
                책번호
                <br />
                일련번호
              </th>
              <td colSpan="3">{paperTaxBill.instMainBookNumber}권</td>
              <td colSpan="4">{paperTaxBill.instSubBookNumber}호</td>
            </tr>
            <tr key="taxbill-1">
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[0] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[1] : ""}</td>
              <td>-</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[2] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[3] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[4] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[5] : ""}</td>
            </tr>
          </tbody>
        </table>
        <table>
          {CM.cfnCompColGroup(["5%", "15%", "15%", "7%", "12%", "5%", "15%", "15%", "7%", "12%"])}
          <tbody>
            <tr key="recipient-0">
              <th rowSpan="4">
                공<br />급<br />자
              </th>
              <th>등록번호</th>
              <td colSpan="3">{CM.cfnIdentificationNoFormat(master.bizNo)}</td>
              <th rowSpan="4">
                공<br />급<br />받<br />는<br />자
              </th>
              <th>등록번호</th>
              <td className="text-black" colSpan="3">
                {paperTaxBill.customer.identificationNo && paperTaxBill.customer.identificationNo.length >= 10
                  ? CM.cfnIdentificationNoFormat(paperTaxBill.customer.identificationNo)
                  : paperTaxBill.customer.identificationNo || ""}
              </td>
            </tr>
            <tr key="recipient-1">
              <th>상호(법인명)</th>
              <td>{master.instName}</td>
              <th>성명</th>
              <td>{master.ceoName}</td>
              <th>상호(법인명)</th>
              <td className="text-black">{paperTaxBill.customer.customerName}</td>
              <th>성명</th>
              <td className="text-black">{convertPaperTaxBill.representativeName}</td>
            </tr>
            <tr key="recipient-2">
              <th>사업장 주소</th>
              <td colSpan="3">{master.address ? "(" + master.address.zipCode + ") " + master.address.address + " " + master.address.addressDetail : "" /* 공급자 주소*/}</td>
              <th>사업장 주소</th>
              <td className="text-black" colSpan="3">
                {
                  paperTaxBill.customer.customerAddress
                    ? "(" + paperTaxBill.customer.customerZipCode + ")" + paperTaxBill.customer.customerAddress + " " + paperTaxBill.customer.customerAddressDetail
                    : "" /*공급받는자주소*/
                }
              </td>
            </tr>
            <tr key="recipient-3">
              <th>업태</th>
              <td>{master.bizType /* 업태 */}</td>
              <th>종목</th>
              <td>{master.bizItem /* 종목 */}</td>
              <th>업태</th>
              <td className="text-black">{convertPaperTaxBill.business || "" /* 영수자 업태 */}</td>
              <th>종목</th>
              <td className="text-black">{convertPaperTaxBill.sectors || "" /* 영수자 종목 */}</td>
            </tr>
          </tbody>
        </table>
        <table>
          {paperTaxBill.taxbillType === "PAPER"
            ? CM.cfnCompColGroup([
                "9%",
                "5%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "11%",
              ])
            : CM.cfnCompColGroup(["15%", "10%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "auto"])}
          <thead>
            {paperTaxBill.taxbillType === "PAPER" ? (
              <tr key="taxbill-summary-0">
                <th>작성</th>
                <th colSpan="14">공급가액</th>
                <th colSpan="12">세액</th>
                <th>비고</th>
              </tr>
            ) : (
              <tr key="taxbill-summary-0">
                <th>작성</th>
                <th colSpan="11">공급가액</th>
                <th>비고</th>
              </tr>
            )}
          </thead>

          {paperTaxBill.taxbillType === "PAPER" ? (
            <tbody>
              <tr key="taxbill-summary-1">
                <th>년 월 일</th>
                <th>공란수</th>
                <th>조</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>억</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>만</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>일</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>억</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>만</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>일</th>
                <td className="text-black" rowSpan="2">
                  {paperTaxBill.billRemark}
                </td>
              </tr>
              <tr key="taxbill-summary-2">
                <td className="text-black">{CM.cfnDateFormat(paperTaxBill.issueDate)}</td>
                <td className="text-black">{13 - (paperTaxBill.totalSuppliedAmount + "").length /*공란수*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[0] /*조*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[1] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[2] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[3] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[4] /*억*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[5] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[6] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[7] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[8] /*만*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[9] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[10] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[11] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[12] /*일*/}</td>

                <td className="text-black">{paperTaxBill.totalTaxAmountArray[0] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[1] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[2] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[3] /*억*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[4] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[5] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[6] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[7] /*만*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[8] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[9] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[10] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[11] /*일*/}</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr key="taxbill-summary-1">
                <th>년 월 일</th>
                <th>공란수</th>
                <th>십</th>
                <th>억</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>만</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>일</th>
                <td className="text-black" rowSpan="2">
                  {paperTaxBill.billRemark}
                </td>
              </tr>
              <tr key="taxbill-summary-2">
                <td className="text-black">{CM.cfnDateFormat(paperTaxBill.issueDate)}</td>
                <td className="text-black">{10 - (paperTaxBill.totalSuppliedAmount + "").length /*공란수*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[0] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[1] /*억*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[2] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[3] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[4] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[5] /*만*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[6] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[7] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[8] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[9] /*일*/}</td>
              </tr>
            </tbody>
          )}
        </table>
        <table>
          <thead>
            <tr key="taxbill-detail-0">
              <th>월 일</th>
              <th>품 목</th>
              <th>규 격</th>
              <th>수 량</th>
              <th>단 가</th>
              <th>공급가액</th>
              <th>세 액</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            {Object.isExtensible(paperTaxBill["paperBillDetails"])
              ? paperTaxBill["paperBillDetails"].map((row, index) => {
                  return (
                    <tr key={`paperBillDetails-${index}`}>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? `${row.month}.${row.day}` : ""}</td>
                      <td className="text-black">{row.itemName}</td>
                      <td className="text-black">{row.itemSpecification}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemQuantity) : ""}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemUnitPrice) : ""}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemSupplyAmount) : ""}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemTaxAmount) : ""}</td>
                      <td className="text-black">{row.itemRemark}</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr key="taxbill-detail-2">
              <th>합계금액</th>
              <th>현 금</th>
              <th>수 표</th>
              <th>어 음</th>
              <th>외상미수금</th>
              <td rowSpan="2">{`위 금액을 (${PaperData.fnConvertTaxBillDirection(paperTaxBill.taxbillDirection)})함`}</td>
            </tr>
            <tr key="taxbill-detail-3">
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.totalAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.cashAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.chequeAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.noteAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.unpaidAmount)}</td>
            </tr>
          </tbody>
        </table>
        <div className="table-bottom-area">
          <span className="fl">22226-281 312일 96. 2.27 개정</span>
        </div>
      </div>

      <Divider className="bill-print-middleLine" />

      <div className="tax-bill-recipientRed">
        <div className="table-top-area">
          <span className="fl">[별지 제11호 서식]</span>
        </div>
        <table>
          {CM.cfnCompColGroup(["60%", "10%", "auto"])}
          <tbody>
            <tr key="taxbill-0">
              <th rowSpan="2" style={{ fontSize: "20px" }}>
                {paperTaxBill.taxbillType === "PAPER" ? "세금계산서" : "계산서"}
                (공급자 보관용)
              </th>
              <th rowSpan="2">
                책번호
                <br />
                일련번호
              </th>
              <td colSpan="3">{paperTaxBill.instMainBookNumber}권</td>
              <td colSpan="4">{paperTaxBill.instSubBookNumber}호</td>
            </tr>
            <tr key="taxbill-1">
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[0] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[1] : ""}</td>
              <td>-</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[2] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[3] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[4] : ""}</td>
              <td>{CM.cfnIsNotEmpty(paperTaxBill.instSerialNumber) ? paperTaxBill.instSerialNumberArray[5] : ""}</td>
            </tr>
          </tbody>
        </table>
        <table>
          {CM.cfnCompColGroup(["5%", "15%", "15%", "7%", "12%", "5%", "15%", "15%", "7%", "12%"])}
          <tbody>
            <tr key="recipient-0">
              <th rowSpan="4">
                공<br />급<br />자
              </th>
              <th>등록번호</th>
              <td colSpan="3">{CM.cfnIdentificationNoFormat(master.bizNo)}</td>
              <th rowSpan="4">
                공<br />급<br />받<br />는<br />자
              </th>
              <th>등록번호</th>
              <td className="text-black" colSpan="3">
                {paperTaxBill.customer.identificationNo && paperTaxBill.customer.identificationNo.length >= 10
                  ? CM.cfnIdentificationNoFormat(paperTaxBill.customer.identificationNo)
                  : paperTaxBill.customer.identificationNo || ""}
              </td>
            </tr>
            <tr key="recipient-1">
              <th>상호(법인명)</th>
              <td>{master.instName}</td>
              <th>성명</th>
              <td>{master.ceoName}</td>
              <th>상호(법인명)</th>
              <td className="text-black">{paperTaxBill.customer.customerName}</td>
              <th>성명</th>
              <td className="text-black">{convertPaperTaxBill.representativeName}</td>
            </tr>
            <tr key="recipient-2">
              <th>사업장 주소</th>
              <td colSpan="3">{master.address ? "(" + master.address.zipCode + ") " + master.address.address + " " + master.address.addressDetail : "" /* 공급자 주소*/}</td>
              <th>사업장 주소</th>
              <td className="text-black" colSpan="3">
                {
                  paperTaxBill.customer.customerAddress
                    ? "(" + paperTaxBill.customer.customerZipCode + ")" + paperTaxBill.customer.customerAddress + " " + paperTaxBill.customer.customerAddressDetail
                    : "" /*공급받는자주소*/
                }
              </td>
            </tr>
            <tr key="recipient-3">
              <th>업태</th>
              <td>{master.bizType /* 업태 */}</td>
              <th>종목</th>
              <td>{master.bizItem /* 종목 */}</td>
              <th>업태</th>
              <td className="text-black">{convertPaperTaxBill.business || "" /* 영수자 업태 */}</td>
              <th>종목</th>
              <td className="text-black">{convertPaperTaxBill.sectors || "" /* 영수자 종목 */}</td>
            </tr>
          </tbody>
        </table>
        <table>
          {paperTaxBill.taxbillType === "PAPER"
            ? CM.cfnCompColGroup([
                "9%",
                "5%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "3%",
                "11%",
              ])
            : CM.cfnCompColGroup(["15%", "10%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "5%", "auto"])}
          <thead>
            {paperTaxBill.taxbillType === "PAPER" ? (
              <tr key="taxbill-summary-0">
                <th>작성</th>
                <th colSpan="14">공급가액</th>
                <th colSpan="12">세액</th>
                <th>비고</th>
              </tr>
            ) : (
              <tr key="taxbill-summary-0">
                <th>작성</th>
                <th colSpan="11">공급가액</th>
                <th>비고</th>
              </tr>
            )}
          </thead>

          {paperTaxBill.taxbillType === "PAPER" ? (
            <tbody>
              <tr key="taxbill-summary-1">
                <th>년 월 일</th>
                <th>공란수</th>
                <th>조</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>억</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>만</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>일</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>억</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>만</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>일</th>
                <td className="text-black" rowSpan="2">
                  {paperTaxBill.billRemark}
                </td>
              </tr>
              <tr key="taxbill-summary-2">
                <td className="text-black">{CM.cfnDateFormat(paperTaxBill.issueDate)}</td>
                <td className="text-black">{13 - (paperTaxBill.totalSuppliedAmount + "").length /*공란수*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[0] /*조*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[1] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[2] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[3] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[4] /*억*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[5] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[6] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[7] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[8] /*만*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[9] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[10] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[11] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[12] /*일*/}</td>

                <td className="text-black">{paperTaxBill.totalTaxAmountArray[0] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[1] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[2] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[3] /*억*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[4] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[5] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[6] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[7] /*만*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[8] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[9] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[10] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalTaxAmountArray[11] /*일*/}</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr key="taxbill-summary-1">
                <th>년 월 일</th>
                <th>공란수</th>
                <th>십</th>
                <th>억</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>만</th>
                <th>천</th>
                <th>백</th>
                <th>십</th>
                <th>일</th>
                <td className="text-black" rowSpan="2">
                  {paperTaxBill.billRemark}
                </td>
              </tr>
              <tr key="taxbill-summary-2">
                <td className="text-black">{CM.cfnDateFormat(paperTaxBill.issueDate)}</td>
                <td className="text-black">{10 - (paperTaxBill.totalSuppliedAmount + "").length /*공란수*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[0] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[1] /*억*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[2] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[3] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[4] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[5] /*만*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[6] /*천*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[7] /*백*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[8] /*십*/}</td>
                <td className="text-black">{paperTaxBill.totalSuppliedAmountArray[9] /*일*/}</td>
              </tr>
            </tbody>
          )}
        </table>
        <table>
          <thead>
            <tr key="taxbill-detail-0">
              <th>월 일</th>
              <th>품 목</th>
              <th>규 격</th>
              <th>수 량</th>
              <th>단 가</th>
              <th>공급가액</th>
              <th>세 액</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            {Object.isExtensible(paperTaxBill["paperBillDetails"])
              ? paperTaxBill["paperBillDetails"].map((row, index) => {
                  return (
                    <tr key={`paperBillDetails-${index}`}>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? `${row.month}.${row.day}` : ""}</td>
                      <td className="text-black">{row.itemName}</td>
                      <td className="text-black">{row.itemSpecification}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemQuantity) : ""}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemUnitPrice) : ""}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemSupplyAmount) : ""}</td>
                      <td className="text-black">{CM.cfnIsNotEmpty(row.month) ? CM.cfnAddComma(row.itemTaxAmount) : ""}</td>
                      <td className="text-black">{row.itemRemark}</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr key="taxbill-detail-2">
              <th>합계금액</th>
              <th>현 금</th>
              <th>수 표</th>
              <th>어 음</th>
              <th>외상미수금</th>
              <td rowSpan="2">{`위 금액을 (${PaperData.fnConvertTaxBillDirection(paperTaxBill.taxbillDirection)})함`}</td>
            </tr>
            <tr key="taxbill-detail-3">
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.totalAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.cashAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.chequeAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.noteAmount)}</td>
              <td className="text-black">{CM.cfnAddComma(paperTaxBill.unpaidAmount)}</td>
            </tr>
          </tbody>
        </table>
        <div className="table-bottom-area">
          <span className="fl">22226-281 312일 96. 2.27 개정</span>
        </div>
      </div>
    </div>
  );
};
