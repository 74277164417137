import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { observer } from "mobx-react";
import { inject } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import CHN from "./ChangeHistoryCodeName";
import ChangeHistoryModal from "./ChangeHistoryModal";

// 기관 업무 정보 > 변경 요청 이력
// 렌더를 처리하는 메인 컴포넌트
const ChangeHistory = ({ props, instituteStore }) => {
  const [openModal, setOpenModal] = useState("");
  const [open, setOpen] = useState(false);
  const [object, setObject] = useState({});
  const [pagination, setPagination] = useState(CM.cfnPaginationData());
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [bankList, setBankList] = useState([]);

  // handler
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value)); // 페이지네이션 handler
  const handleRowsPerPageChange = ({ target: { value } }) => fnRowsRender(0, value); // rowPerPage handler

  // 페이지 handler
  const handlePageChange = (e, offset, page) => {
    setCurrentPage(page - 1);
    fnRowsRender(page - 1, pagination.rowsPerPage);
  };

  // row 검색
  const fnRowsRender = useCallback((pageNumber = 0, pageSize = 5) => {
    const url = `api/institute/histories?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    CM.cfnAxios(url, "get", "", (status, data) => {
      setRows(data.content);
      handlePagination(data);
    });
    const fetchBankList = async () => {
      setBankList(await instituteStore.axiosFinancialInstitutes());
    };
    fetchBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fnRowsRender();
  }, [fnRowsRender]);

  // 신청 취소 버튼
  //접수대기 : '신청취소', 기관취소 : '취소완료', 그외 : '내용확인'
  const fnClickCancel = (applicationStatus, registrationNo) => {
    if (applicationStatus === "READY_TO_ACCEPTANCE") {
      return (
        <div className="btn-s" onClick={(e) => handleClickCancel(e, registrationNo)}>
          신청취소
        </div>
      );
    }

    if (applicationStatus === "INSTITUTE_CANCELED") {
      return (
        <div className="btn-s btn-disabled" disabled={true}>
          취소완료
        </div>
      );
    }

    // INSTITUTE_CANCELED
    return (
      <div className="btn-s" disabled={true}>
        내용확인
      </div>
    );
  };

  // 신청 취소버튼 클릭시 동작
  const handleClickCancel = (event, registrationNo) => {
    event.stopPropagation();

    CM.cfnConfirm("신청을 취소하시겠습니까?", () => {
      const url = `api/institute/histories/${registrationNo}`;

      CM.cfnAxios(url, "put", "", (objStatus, objData) => {
        CM.cfnAlert(objData, () => {
          setRows(rows.map((row) => (row.registrationNo === registrationNo ? { ...row, applicationStatus: "INSTITUTE_CANCELED" } : row)));
        });
      });
    });
  };

  // modal에 전달할 object 가져오기
  const getObject = (applicationContents, applicationDetail) => {
    return new Promise((resolve) => {
      let sendData;

      // 자동출금일 때
      switch (applicationContents) {
        case "START_AUTO_WITHDRAW":
        case "CHANGE_AUTO_WITHDRAW":
          const { askTargetCriteria, expectedApplyDate, fileType1, maxNumberOfTryToAsk, targetIntegratedYn } = applicationDetail;
          const paySpecifiedDays = [];

          for (const [key, value] of Object.entries(applicationDetail)) {
            if (key.indexOf("paySpecifiedDay") !== -1 && CM.cfnIsNotEmpty(value)) {
              paySpecifiedDays.push(value);
            }
          }

          sendData = {
            askTargetCriteria,
            expectedApplyDate,
            fileType: fileType1,
            maxNumberOfTryToAsk,
            paySpecifiedDays,
            targetIntegratedYn,
          };
          break;
        default:
          sendData = applicationDetail;
          break;
      }

      resolve(sendData);
    });
  };

  // 클릭한 row의 상세정보 modal 열기
  const handleClickModal = (e, applicationContents, registrationNo) => {
    if (e.target.classList.contains("ignoreClick")) {
      return;
    }

    const url = `api/institute/histories/${registrationNo}`;
    CM.cfnAxios(url, "get", "", async (status, data) => {
      const { applicationDetail } = data;
      const sendData = await getObject(applicationContents, applicationDetail);

      setOpenModal(applicationContents);
      setObject(sendData);
      setOpen(true);
    });
  };

  return (
    <div>
      <div>
        <div className="inforbox">
          <ul>
            <li>이용기관 정보변경 등을 신청한 경우, 신청 내역의 진행상태를 조회할 수 있습니다.</li>
          </ul>
        </div>
      </div>
      <div>
        <div className="table-top-area">
          <CF.TotalCountForm totalElements={pagination.total || 0} />
          <CF.RowPerPageForm value={pagination.rowsPerPage} onChange={handleRowsPerPageChange} customProps={{ inputProps: { "data-testid": "changeHistory-select-rowPerPage" } }} />
        </div>
        <Table aria-labelledby="tablebusiness" data-testid="changeHinstory-table">
          {CM.cfnCompColGroup(["10%", "15%", "auto", "15%", "10%", "25%", "auto"])}
          <TableForm.compTableHead arrData={["순번", "서비스", "업무", "신청일", "진행상태", "비고", "신청작업"]} />
          <TableBody>
            {rows.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={7} />
            ) : (
              rows.map((row, index) => {
                return (
                  <TableRow key={index} className="show-detail" onClick={(e) => handleClickModal(e, row.applicationContents, row.registrationNo)} data-testid="changeHistory-open-modal" hover>
                    <TableCell align="center">{pagination.total - pagination.rowsPerPage * currentPage - index}</TableCell>
                    <TableCell align="center" className="w150" data-testid={"test" + index}>
                      {CHN.applicationBusiness(row.applicationBusiness)}
                    </TableCell>
                    <TableCell align="center">{CHN.applicationContents(row.applicationContents)}</TableCell>
                    <TableCell align="center" className="w100">
                      {CM.cfnDateFormat(row.requestedDatetime.substring(0, 8))}
                    </TableCell>
                    <TableCell align="center" className="w80">
                      {CHN.applicationStatus(row.applicationStatus)}
                    </TableCell>
                    <TableCell align="center" className="w100">
                      {row.sentbackReason ? row.sentbackReason : "-"}
                    </TableCell>
                    <TableCell align="center" className="w100 ignoreClick" data-testid={"testCancelButton" + index}>
                      {fnClickCancel(row.applicationStatus, row.registrationNo)}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <CF.PaginationForm pagination={pagination} onClick={handlePageChange} testId="changeHistory-pagination" />
      <ChangeHistoryModal openModal={openModal} open={open} setOpen={setOpen} object={object} bankList={bankList} />
    </div>
  );
};

export default inject((rootStore, props) => ({
  instituteStore: rootStore.instituteStore,
  props: props,
}))(observer(ChangeHistory));
