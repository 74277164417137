import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import { Select, Table, TableBody, TableCell, TableRow, Checkbox, FormGroup, FormControl, FormControlLabel, RadioGroup, Radio, Input } from "@material-ui/core";
//import Pagination from "material-ui-flat-pagination";
import TableForm from "../../template/TableForm";
// import * as CF from "../../template/ComponentForm";

import * as CM from "../../common/Common";
import DonationData, { getPersonalDonationTypeCode, getPersonalDonationTypeName, getBusinessDonationTypeCode, getBusinessDonationTypeName } from "./DonationBillData";

import DonationBillPrintModal from "./DonationBillPrintModal";
import { TotalCountForm } from "../../template/ComponentForm";

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = ({ searchRequest, handleChangeSearchRequestForm, handleSearchButtonChange, customerGroups, staffs, capitals }) => {
  return (
    <div className="search-area tl">
      <div>
        <FormControl component="fieldset" className="w100p">
          <FormGroup name="position" row>
            <RadioGroup
              name="isCustomerIndividual"
              onChange={handleChangeSearchRequestForm("isCustomerIndividual")}
              value={searchRequest.isCustomerIndividual}
              data-testid="donationBillBatchIssue-customerType-radio"
              row>
              <label className="label-l">개인/사업자구분</label>
              <FormControlLabel value={"true"} checked={searchRequest.isCustomerIndividual ? true : false} control={<Radio color="primary" />} label="개인고객" />
              <FormControlLabel value={"false"} checked={searchRequest.isCustomerIndividual ? false : true} control={<Radio color="primary" />} label="사업자고객" />
            </RadioGroup>
            <label className="label-l">수납방법</label>
            <FormControlLabel
              value={searchRequest.transactionMethod}
              control={<Checkbox checked={searchRequest.transactionMethod === "" ? true : false} value="" onChange={handleChangeSearchRequestForm("transactionMethod")} color="primary" />}
              label="전체"
              data-testid="donationBatchIssue-select-all-transaction-method"
            />
            <RadioGroup value={searchRequest.transactionMethod} onChange={handleChangeSearchRequestForm("transactionMethod")} data-testid="donationBatchIssue-select-transaction-method" row>
              <FormControlLabel
                checked={searchRequest.transactionMethod === "CMS_WITHDRAW" ? true : false}
                value="CMS_WITHDRAW"
                control={<Radio color="primary" />}
                label="CMS"
                data-testid="donationBatchIssue-select-cms-method"
              />
              <FormControlLabel
                checked={searchRequest.transactionMethod === "ETC" ? true : false}
                value="ETC"
                control={<Radio color="primary" />}
                label="기타"
                data-testid="donationBatchIssue-select-etc-method"
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <br />
        <div>
          <label className="label-l">고객구분</label>
          <Select native value={searchRequest.groupUniqueKey} onChange={handleChangeSearchRequestForm("groupUniqueKey")} inputProps={{ "data-testid": "donationBatchIssue-customer-group-select" }}>
            <option value="">전체</option>
            {CM.cfnIsNotEmpty(customerGroups) &&
              customerGroups.map((row, index) => {
                return (
                  <option key={index} value={row.uniqueKey}>
                    {row.customerGroupName}
                  </option>
                );
              })}
          </Select>
          <label className="label-l">고객담당자</label>
          <Select native value={searchRequest.staffUniqueKey} onChange={handleChangeSearchRequestForm("staffUniqueKey")} inputProps={{ "data-testid": "donationBatchIssue-staffName-select" }}>
            <option value="">전체</option>
            {CM.cfnIsNotEmpty(staffs) &&
              staffs.map((row, index) => {
                return (
                  <option key={index} value={row.uniqueKey}>
                    {row.staffName}
                  </option>
                );
              })}
          </Select>
          <label className="label-l">자금종류</label>
          <Select native value={searchRequest.capitalUniqueKey} onChange={handleChangeSearchRequestForm("capitalUniqueKey")} inputProps={{ "data-testid": "donationBatchIssue-customer-group-select" }}>
            <option value="">전체</option>
            {CM.cfnIsNotEmpty(capitals) &&
              capitals.map((row, index) => {
                return (
                  <option key={index} value={row.uniqueKey}>
                    {row.capitalName}
                  </option>
                );
              })}
          </Select>
        </div>
        <br />
        <br />
        <FormGroup row>
          <label className="label-l">수납연도</label>
          <Select native value={searchRequest.targetYear} onChange={handleChangeSearchRequestForm("targetYear")} inputProps={{ "data-testid": "donationBillBatchIssue-targetYear-select" }}>
            {DonationData.donationBillIssueTargetYearOptions().map((el, index) => {
              return (
                <option value={el.value} key={index}>
                  {el.label}
                </option>
              );
            })}
          </Select>

          <RadioGroup
            name="isIssuedBillIncluded"
            onChange={handleChangeSearchRequestForm("isIssuedBillIncluded")}
            value={searchRequest.isIssuedBillIncluded}
            data-testid="donationBillBatchIssue-includeType-radio"
            row>
            <label className="label-l">조회대상</label>
            <FormControlLabel value={"false"} checked={!searchRequest.isIssuedBillIncluded} control={<Radio color="primary" />} label="발행완료내역 제외" />
            <FormControlLabel value={"true"} checked={searchRequest.isIssuedBillIncluded} control={<Radio color="primary" />} label="발행완료내역 포함" />
          </RadioGroup>
          <button className="search-button" onClick={() => handleSearchButtonChange(true)} data-testid="save-excel" style={{ marginLeft: "auto" }}>
            발행대상 조회
          </button>
        </FormGroup>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = ({ targetList, searchRequest, handleTargetListChange, handleClickTargets, handleChangeSearchRequestForm }) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of targetList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const targetLength = targetList.length;

    if (checkAllFlag === targetLength && targetLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [targetList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");
    const cloneData = [...targetList];
    cloneData[index][key] = value;
    handleTargetListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = async (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    await handleTargetListChange((target) => target.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  /*
   * @desc  주소 변경 이벤트 핸들러
   */
  const handleChangeAddress = (e) => {
    const tempArray = [...targetList];
    const rowIndex = e.target.getAttribute("index");
    tempArray[rowIndex]["customerAddress"] = e.target.value;

    handleTargetListChange(tempArray);
  };

  return (
    <div>
      <div className="table-top-area">
        <TotalCountForm totalElements={targetList.length || 0} />
        <button className="btn-l fr" onClick={handleClickTargets}>
          선택 영수증 발행
        </button>
        <RadioGroup
          className="fr"
          name="isEachIssuingTarget"
          onChange={handleChangeSearchRequestForm("isEachIssuingTarget")}
          value={searchRequest.isEachIssuingTarget}
          data-testid="donationBillBatchIssue-issueType-radio"
          row>
          <label className="label-l">발행방식</label>
          <FormControlLabel value={"true"} checked={searchRequest.isEachIssuingTarget ? true : false} control={<Radio color="primary" />} label="건별인쇄" />
          <FormControlLabel value={"false"} checked={searchRequest.isEachIssuingTarget ? false : true} control={<Radio color="primary" />} label="합계로인쇄" />
        </RadioGroup>
      </div>
      <Table>
        {CM.cfnCompColGroup(["5%", "8%", "10%", "15%", "auto", "15%", "10%"])}
        <TableForm.compCheckboxTableHead
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={["발행여부", "고객명", "주민등록번호\n(사업자번호)", "주소", "수납금액", "일련번호"]}
        />
        <TableBody>
          {targetList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            targetList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">
                    {!row.isIssued && (
                      <Checkbox
                        checked={!row._checked ? false : true}
                        value="_checked"
                        id={`selectCheckbox-${row.index}`}
                        onChange={handleCheckChange}
                        inputProps={{
                          index: row.index,
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">{row.isIssued ? "발행" : "미발행"}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.customerIdentificationNo)}</TableCell>
                  <TableCell align="center">
                    <Input
                      className={"w100p"}
                      value={row.customerAddress}
                      disabled={row.isIssued}
                      onChange={(e) => handleChangeAddress(e)}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.donationAmount)}원</TableCell>
                  <TableCell align="center">{row.donationSequenceNumber}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

//부가서비스 > 기부금영수증 > 일괄 발행
/*
 * @desc  메인 컴포넌트
 */
const BatchIssueTab = ({ store, tabIndex, sealImage, donationForm }) => {
  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(DonationData.batchTargetSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [selectedPrintRows, setSelectedPrintRows] = useState([]);

  //검색조건 셀렉트박스 옵션 제어 state 선언
  const [customerGroups, setCustomerGroups] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [capitals, setCapitals] = useState([]);

  //모달 제어를 위한 state 선언
  const [printOpen, setPrintOpen] = useState(false); //인쇄 모달

  //검색조건 내 셀렉트 박스 옵션 조회
  useEffect(() => {
    // 고객구분 option data 설정
    const initCustomerGroups = async () => {
      const customerGroupList = await store.axiosCustomerGroup();
      setCustomerGroups(customerGroupList);
    };

    //고객담당자 option data 설정
    const initStaffs = async () => {
      const resultStaffs = await store.axiosStaffs();
      setStaffs(resultStaffs);
    };

    // 자금종류 option data 설정
    const initCapitals = async () => {
      const resultCapitals = await store.axiosCapitals();
      setCapitals(resultCapitals);
    };

    if (tabIndex === 2) {
      initCustomerGroups();
      initStaffs();
      initCapitals();
    }
  }, [store, tabIndex]);

  // 테이블 데이터 검색
  useEffect(() => {
    /*
     * @desc  일괄발행 대상 조회 Request
     */

    const fnFetchBatchIssueTargetList = (search) => {
      return new Promise((resolve) => {
        const url = `api/extraservice/donations/targets?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&isCustomerIndividual=${search.isCustomerIndividual}&isEachIssuingTarget=${search.isEachIssuingTarget}&isIssuedBillIncluded=${search.isIssuedBillIncluded}&staffUniqueKey=${search.staffUniqueKey}&targetYear=${search.targetYear}&transactionMethod=${search.transactionMethod}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      handleSearchButtonChange(false);
      const resultData = await fnFetchBatchIssueTargetList(search);
      // // 체크 옵션 추가
      for (const element of resultData) {
        element._checked = false;
        element.index = resultData.indexOf(element);
      }

      handleTargetListChange(resultData);
    };

    //실행영역
    if (searchButton && tabIndex === 2) {
      startAxios(searchRequest);
    }
  }, [searchButton, tabIndex, searchRequest]);

  /*
   * @desc 선택고객 일괄 발행 버튼 클릭 이벤트 핸들러
   */
  const handleClickTargets = async () => {
    const tempArray = targetList.filter((target) => target._checked && !target.isIssued).map((target) => ({ ...target, targetMonth: "01", isIssuedIndividually: searchRequest.isEachIssuingTarget }));

    if (CM.cfnIsEmpty(tempArray)) {
      CM.cfnAlert("선택된 내역이 없습니다.");
      return false;
    }

    // setSelectedRows(tempArray);
    //일괄발행 Request
    const fnIssueDonationBill = () => {
      const url = `api/extraservice/donations/targets/${searchRequest.targetYear}`;

      return new Promise((resolve) => {
        CM.cfnAxios(
          url,
          "post",
          tempArray,
          (status, data) => {
            resolve(data);
          },
          (error) => {
            CM.cfnAlert(
              error && error.response && error.response.data && typeof error.response.data === "string"
                ? error.response.data
                : error && error.response && error.response.data && typeof error.response.data.message === "string"
                ? error.response.data.message
                : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
              () => {}
            );
          }
        );
      });
    };

    //선택 내역이 있을 경우 일괄발행 처리
    const isIssuedList = await fnIssueDonationBill();

    //일괄발행 처리 성공 시, DonationBills초기화, response(isIssuedList)로 handlePrintModalOpen 호출
    if (CM.cfnIsNotEmpty(isIssuedList) && Array.isArray(isIssuedList) && isIssuedList.length > 0) {
      if (isIssuedList.length > 300) {
        CM.cfnConfirm(
          `총 ${isIssuedList.length}건이 발행처리 되었습니다. 한 번에 모든 내역을 인쇄하시겠습니까? PC환경에 따라 로딩에 오랜 시간이 소요될 수 있습니다. 느린 PC에서는 발행내역 탭에서 적은 갯수로 나누어 인쇄하시는 것을 권장합니다.`,
          () => {
            handlePrintModalOpen(isIssuedList);
          }
        );
      } else {
        CM.cfnAlert(`총 ${isIssuedList.length}건이 발행처리 되었습니다.`, () => {
          handlePrintModalOpen(isIssuedList);
        });
      }
    }
  };

  /*
   * @desc  검색조건 변경 이벤트 핸들러
   */
  const handleChangeSearchRequestForm = (name) => (e) => {
    switch (name) {
      case "isCustomerIndividual":
      case "isIssuedBillIncluded":
      case "isEachIssuingTarget":
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value === "true" ? true : false,
        });
        break;
      case "targetYear":
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        break;
      default:
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
        });
        break;
    }
    if (name === "isCustomerIndividual") {
      setSearchButton(true);
    }
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => {
    setSearchButton(flag);
  };

  // 고객 목록 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  //인쇄용 API 조회
  const handlePrintModalOpen = async (isIssuedList) => {
    const fnFetchPrintInformation = (donationUniqueKey) => {
      let form = new FormData();
      form.append('donationUniqueKeyList', donationUniqueKey);
      form.append('isIssuedIndividually', searchRequest.isEachIssuingTarget);

      const url = `api/extraservice/donations/targets/print?`;

      return new Promise((resolve) => {
        CM.cfnAxios(url, "post", form, (status, objData) => {
          resolve(objData);
        });
      });
    };

    const data = await fnFetchPrintInformation(isIssuedList);

    setSelectedPrintRows(data);
    setPrintOpen(true);
  };

  // 화면 렌더
  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleChangeSearchRequestForm={handleChangeSearchRequestForm}
        handleSearchButtonChange={handleSearchButtonChange}
        customerGroups={customerGroups}
        staffs={staffs}
        capitals={capitals}
      />
      <ListForm
        searchRequest={searchRequest}
        targetList={targetList}
        handleTargetListChange={handleTargetListChange}
        handleClickTargets={handleClickTargets}
        handleChangeSearchRequestForm={handleChangeSearchRequestForm}
      />
      <DonationBillPrintModal
        open={printOpen}
        setOpen={setPrintOpen}
        selectedRows={selectedPrintRows}
        isEachIssuingTarget={searchRequest.isEachIssuingTarget}
        setSearchButton={setSearchButton}
        sealImage={sealImage}
        donationForm={donationForm}
        donationTypeCode={searchRequest.isCustomerIndividual ? getPersonalDonationTypeCode(donationForm.personalDonationType) : getBusinessDonationTypeCode(donationForm.businessDonationType)}
        donationTypeName={searchRequest.isCustomerIndividual ? getPersonalDonationTypeName(donationForm.personalDonationType) : getBusinessDonationTypeName(donationForm.businessDonationType)}
      />
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(BatchIssueTab));
