import { Button, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

const ReceiptSourceUpdateModal = ({ open, setOpen, searchRequestData, paginationData, setListSearchButton, storeCapital, storeCustomerGroup, payAmountType, searchProperty }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
    setListSearchButton(true);
  };

  // 페이징 데이터
  function fnPagination(data) {
    if (CM.cfnIsEmpty(data)) {
      return {
        rowsPerPage: 5,
        offset: 0,
        total: 0,
        totalPages: 1,
      };
    }

    return {
      rowsPerPage: data.pageable.pageSize,
      offset: data.pageable.offset,
      total: data.totalElements,
      totalPages: data.totalPages,
    };
  }

  const [searchButton, setSearchButton] = useState(true);
  const [searchRequest, setSearchRequest] = useState(searchRequestData);
  const [originalList, setOriginalList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [pagination, setPagination] = useState(paginationData);

  // 화면 open시 화면 초기화
  useEffect(() => {
    if (!open) {
      return;
    }
    // setCheckAllRow(false);
    setOriginalList([]);
    setFilterList([]);
    setSearchRequest(searchRequestData);
    setSearchButton(true);
  }, [open, searchRequestData]);

  useEffect(() => {
    /*
     * @desc 파라미터 생성 함수
     */
    const fnMakeParameter = (search) => {
      let tempObj = CM.cfnCopyObject(search);
      tempObj.searchStartY4mm = search.searchStartY4 + search.searchStartMm;
      tempObj.searchEndY4mm = search.searchEndY4 + search.searchEndMm;
      const tempArray = [];
      if (CM.cfnIsNotEmpty(search.sourceStatusList)) {
        const keyArray = Object.keys(search.sourceStatusList);

        Object.values(search.sourceStatusList).map((value, index) => (value && keyArray[index] !== "sourceStatus" ? tempArray.push(keyArray[index]) : ""));
      }
      tempObj.sourceStatusList = tempArray;

      return tempObj;
    };

    /*
     * @desc   결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&searchProperty=${search.searchProperty}&searchContents=${search.searchContents}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      setSearchButton(false);
      const resultData = await axiosList(search);

      let resultList = resultData.content;

      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }

      // 체크 옵션 추가
      if (CM.cfnIsNotEmpty(resultList)) {
        for (const element of resultList) {
          element.remark = "";
          element.index = resultList.indexOf(element);
          element.amountAfterTransaction = CM.cfnIsNotEmpty(element.unpaidAmount) ? CM.cfnAddComma(element.unpaidAmount) : 0;
          element.delayFeeAfterTransaction = CM.cfnIsNotEmpty(element.delayFee) ? CM.cfnAddComma(element.delayFee) : 0;
        }
      }

      await setOriginalList(resultData);
      await setFilterList(resultList);
      await setPagination(fnPagination(resultData));
      await setSearchButton(false);
    }
    if (open && searchButton) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param);
    }
  }, [searchRequest, searchButton, open]);

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);
  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };
  /*
   * @desc 조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  // 검색 (rowperpage) handler
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageSize: value,
      pageNumber: 0,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc 값(추가금액/수납지정일) 변경 핸들러
   */
  const handleFormChange = (name, row) => (e) => {
    const tempArray = [...filterList];
    const targetRow = row.uniqueKey;
    let rowIndex = null;

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].uniqueKey === targetRow) {
        rowIndex = i;
        break;
      }
    }
    if (name === "remark") {
      tempArray[rowIndex][name] = e.target.value;
    } else {
      tempArray[rowIndex][name] = CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, ""));
    }

    setFilterList(tempArray);
  };

  /*
   * @desc 수정내용 저장 버튼 클릭 시, 이벤트 함수
   */
  const fnSubmit = () => {
    const sendData = []; // 선택 항목 추가
    const url = "api/receipt/source/amount/list";

    for (const row of filterList) {
      if (
        (row.amountAfterTransaction !== "" && row.amountAfterTransaction !== null && Number(row.unpaidAmount) !== Number(CM.cfnReplaceSymbol(row.amountAfterTransaction))) ||
        (row.delayFeeAfterTransaction !== "" && row.delayFeeAfterTransaction !== null && Number(row.unpaidDelayFee) !== Number(CM.cfnReplaceSymbol(row.delayFeeAfterTransaction)))
      ) {
        let sendObj = {
          amountAfterTransaction: CM.cfnIsNotEmpty(row.amountAfterTransaction) ? Number(CM.cfnReplaceSymbol(row.amountAfterTransaction)) : 0,
          amountBeforeTransaction: row.unpaidAmount === null ? 0 : row.unpaidAmount,
          delayFeeAfterTransaction: CM.cfnIsNotEmpty(row.delayFeeAfterTransaction) ? Number(CM.cfnReplaceSymbol(row.delayFeeAfterTransaction)) : 0,
          delayFeeBeforeTransaction: row.unpaidDelayFee === null ? 0 : row.unpaidDelayFee,
          sourceUniqueKey: row.uniqueKey,
          transactionType: "CHANGE_AMOUNT",
          remark: row.remark,
        };
        sendData.push(sendObj);
      }
    }

    if (CM.cfnIsEmpty(sendData)) {
      CM.cfnAlert("변경할 내용을 입력해 주세요.");
      return false;
    }

    // axios 호출
    CM.cfnAxios(url, "put", sendData, fnCallback);
  };
  /*
   * @desc 수정내용 저장 버튼 클릭 시, 콜백함수
   */
  const fnCallback = (objStatus, data) => {
    CM.cfnAlert(data, () => {
      setSearchButton(true);
    });
  };

  //수납상태 구분 함수
  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.AskStatus === "HOLDING") {
          return "미청구(수납보류)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.AskStatus === "HOLDING") {
          return "수납일 미도래(수납보류)";
        } else {
          return "수납일 미도래";
        }
      case "UNPAID":
        if (row.AskStatus === "HOLDING") {
          return "미납(수납보류)";
        } else {
          return "미납";
        }
      case "PAID":
        return "수납";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "수납보류";
      default:
        if (row.askResult === "ASKING" && row.numberOfAsking === 1) {
          return "출금중(최초출금)";
        } else if (row.askResult === "ASKING" && row.numberOfAsking > 1) {
          return "출금중(재출금)";
        }
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };
  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    setSearchButton(true);
  };

  const fnPayAmountType = (pat) => {
    switch (pat) {
      case "VARIABLE":
        return "비정액";
      case "FIXED":
        return "정액";
      case "TEMPORARY_ADDED":
        return "추가분";
      default:
        return pat;
    }
  };
  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner" style={{ paddingBottom: "7px" }}>
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>수납금액 수정</h3>
          <div className="search-area">
            <div className="block">
              <label className="label-l">자금종류</label>
              <Select
                native
                value={searchRequest.capitalUniqueKey}
                onChange={handleSearchFormChange("capitalUniqueKey")}
                name="capital"
                inputProps={{ "data-testid": "paymentInformation-select-capital" }}>
                <option value={""} key={"none"}>
                  {"전체"}
                </option>
                {storeCapital.map((option, index) => {
                  return (
                    <option value={option.uniqueKey} key={index}>
                      {option.capitalName}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">고객구분</label>
              <Select native value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
                <option value={""} key={"none"}>
                  {"전체"}
                </option>
                {storeCustomerGroup.map((option, index) => {
                  return (
                    <option value={option.uniqueKey} key={index}>
                      {option.customerGroupName}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">자금유형</label>
              <Select native value={searchRequest.payAmountType} onChange={handleSearchFormChange("payAmountType")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
                {payAmountType.map((option, index) => {
                  return (
                    <option value={option.value} key={index}>
                      {option.label}
                    </option>
                  );
                })}
                <option value={"TEMPORARY_ADDED"} key={"TEMPORARY_ADDED"}>
                  {"추가분"}
                </option>
              </Select>
              <label className="label-l">검색어입력</label>
              <SelectForm
                value={searchRequest.searchProperty}
                handleChange={handleSearchFormChange("searchProperty")}
                name="searchProperty"
                arrayOption={searchProperty}
                optionValue={"value"}
                optionLabel={"label"}
              />
              <TextField className="w150" value={searchRequest.searchContents} onChange={handleSearchFormChange("searchContents")} onKeyUp={handleSearchKeyUp} name="search" />
              <button className="search-button" onClick={() => fnSearch()}>
                검색
              </button>
            </div>
          </div>

          <div className="table-top-area" style={{ marginTop: "0px" }}>
            <TotalCountForm totalElements={originalList.totalElements || 0} />
            <RowPerPageForm value={pagination.rowsPerPage} onChange={handleRowPerPageChange} />
            <button className="btn-l fr" data-testid="update-button-test" onClick={(e) => fnSubmit()}>
              수정내용저장
            </button>
          </div>
          <div className="scroll-table" style={{ width: "100%" }}>
            <div className="left-table-div" style={{ width: "35%" }}>
              <Table>
                {CM.cfnCompColGroup(["50px", "100px", "100px", "50px"])}
                <TableForm.compServerSortTableHead
                  useCheckbox={false}
                  arrData={[
                    { id: "TARGET_Y4MM", label: "수납일", sortable: true },
                    { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
                    { id: "PAYER_NO", label: "납부자번호", sortable: true },
                    { id: "TRANSACTION_METHOD", label: "수납\n방법", sortable: true },
                  ]}
                  searchRequest={searchRequest}
                  handleSortProperty={handleSortProperty}
                />
                <TableBody>
                  {filterList.length === 0 ? (
                    <TableForm.compEmptyTableRow colSpan={4} />
                  ) : (
                    filterList.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{row.paySpecifiedDay === "99" ? "말일" : row.paySpecifiedDay}</TableCell>
                          <TableCell align="center" className="ellipsis" title={row.customerName}>
                            {row.customerName}
                          </TableCell>
                          <TableCell align="center" className="ellipsis" title={row.payerNo}>
                            {row.payerNo}
                          </TableCell>
                          <TableCell align="center">{row.transactionMethod !== "CMS_WITHDRAW" ? "기타" : "CMS"}</TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </div>
            <div className="right-table-div" style={{ width: "65%" }}>
              <div className="right-inner-table-div">
                <Table>
                  {CM.cfnCompColGroup(["60px", "80px", "150px", "150px", "150px", "150px", "100px", "100px"])}
                  <TableForm.compServerSortTableHead
                    useCheckbox={false}
                    arrData={[
                      { id: "PAY_AMOUNT_TYPE", label: "자금\n유형", sortable: true },
                      { id: "CAPITAL", label: "자금\n종류", sortable: true },
                      { id: "unpaidAmount", label: "수납액원금", sortable: false },
                      { id: "amountAfterTransaction", label: "원금조정액", sortable: false },
                      { id: "delayFee", label: "연체료", sortable: false },
                      { id: "delayFeeAfterTransaction", label: "연체료조정액", sortable: false },
                      { id: "ASK_RESULT", label: "수납상태", sortable: true },
                      { id: "askStatus", label: "비고", sortable: false },
                    ]}
                    searchRequest={searchRequest}
                    handleSortProperty={handleSortProperty}
                  />
                  <TableBody>
                    {filterList.length === 0 ? (
                      <TableForm.compEmptyTableRow colSpan={7} />
                    ) : (
                      filterList.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center" className="ellipsis" title={fnPayAmountType(row.payAmountType)}>
                              {fnPayAmountType(row.payAmountType)}
                            </TableCell>
                            <TableCell align="center" className="ellipsis" title={row.capitalName}>
                              {row.capitalName}
                            </TableCell>
                            <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.unpaidAmount)}>
                              {CM.cfnAddComma(row.unpaidAmount)}
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                inputProps={{
                                  style: { textAlign: "right" },
                                }}
                                className="w100p"
                                value={CM.cfnIsNotEmpty(row.amountAfterTransaction) ? row.amountAfterTransaction : ""}
                                onChange={handleFormChange("amountAfterTransaction", row)}
                                name="search"
                              />
                            </TableCell>
                            <TableCell align="right" className="ellipsis" title={CM.cfnAddComma(row.delayFee)}>
                              {CM.cfnAddComma(row.delayFee)}
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                inputProps={{
                                  style: { textAlign: "right" },
                                }}
                                className="w100p"
                                value={CM.cfnIsNotEmpty(row.delayFeeAfterTransaction) ? row.delayFeeAfterTransaction : ""}
                                onChange={handleFormChange("delayFeeAfterTransaction", row)}
                                name="search"
                              />
                            </TableCell>
                            <TableCell align="center" className="ellipsis" title={fnAskStatus(row)}>
                              {fnAskStatus(row)}
                            </TableCell>
                            <TableCell align="center">
                              <TextField className="w100p" value={CM.cfnIsNotEmpty(row.remark) ? row.remark : ""} onChange={handleFormChange("remark", row)} name="search" />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          className="centered"
          limit={pagination.rowsPerPage}
          offset={pagination.offset}
          total={pagination.total}
          onClick={(e, offset, page) => handleOffsetChange(offset, page)}
          reduced={true}
          centerRipple={false}
          disableFocusRipple={true}
          disableRipple={true}
        />
      </div>
    </Modal>
  );
};

export default ReceiptSourceUpdateModal;
