import { Checkbox, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
//고객일괄등록 모달창 import 선언
import CustomerAddBulk from "./CustomerBasicInformationAddBulk";
import { paginationData, searchData, searchOptionData } from "./CustomerInformationListData";

// 고객정보관리 > 고객 기본정보
// 렌더를 처리하는 메인 컴포넌트
const InformationList = ({ props }) => {
  const { store } = props;
  const { loginStore } = props;

  // history
  const history = useHistory();

  const [summaryInfo, setSummaryInfo] = useState([
    { key: "totalCustomerCount", label: "전체 등록 고객", value: 0 },
    { key: "receiptCustomerCount", label: "수납정보", value: 0 },
    { key: "paymentCustomerCount", label: "지급정보", value: 0 },
  ]); // 요약
  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(false); // 검색 실행 flag
  const [originalCustomerList, setOriginalCustomerList] = useState({}); // 서버로 부터 전달받은 original customer object
  const [customerList, setCustomerList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  // 요약 정보 handler
  const handleSummaryInfoChange = (param) => setSummaryInfo((data) => data.map((element) => ({ ...element, value: param[element.key] })));

  // 요약 정보를 클릭했을 때 동작
  const handleSummaryInfoClick = (key) => {
    handleSearchRequestChange((value) => ({
      ...value,
      contractProperty: key === "totalCustomerCount" ? "" : key === "receiptCustomerCount" ? "RECEIPT" : "PAYMENT",
      pageNumber: 0,
    }));
    handleSearchButtonChange(true);
  };

  // 선택고객 수정 hooks
  const [modifyCustomerGroupOption, setModifyCustomerGroupOption] = useState([]);
  // 선택고객 수정의 고객구분 option
  const [modifyStaffsOption, setModifyStaffsOption] = useState([]); // 선택고객 수정의 담당지사/관리담당자 option
  const [modifyCustomerGroup, setModifyCustomerGroup] = useState(""); // 선택고객 수정의 고객구분 select value
  const [modifyStaffs, setModifyStaffs] = useState(""); // 선택고객 수정의 담당지사/관리담당자 select value

  // 검색 옵션 영역 Hooks
  const [terms, setTerms] = useState("CUSTOMER_NAME");
  const [input, setInput] = useState("");
  const [group, setGroup] = useState("");
  const [staff, setStaff] = useState("");

  const handleTermsChange = ({ target: { value } }) => {
    setTerms(value);
    setInput("");
  };
  const handleInputChange = ({ target: { value } }) => setInput(value);
  const handleGroupChange = ({ target: { value } }) => setGroup(value);
  const handleStaffChange = ({ target: { value } }) => setStaff(value);

  //고객일괄등록 모달창 제어용 state 선언
  const [addExcelOpen, setAddExcelOpen] = useState(false);

  // 고객구분, 담당지사/관리담당자 select 값 설정
  useEffect(() => {
    const startStoreAxios = async () => {
      // 고객구분 option data 설정
      const resultCustomerGroup = await store.axiosCustomerGroup();
      setModifyCustomerGroupOption(resultCustomerGroup);

      const resultStaffs = await store.axiosStaffs();
      const staffsList = resultStaffs.map((staff) => ({ uniqueKey: staff.uniqueKey, name: `${staff.staffName}/${staff.branch.branchName}` }));

      setModifyStaffsOption(staffsList);
    };

    startStoreAxios();
  }, [store]);

  // 테이블 데이터 검색
  useEffect(() => {
    // aixos request summary
    const axiosSummary = () => {
      return new Promise((resolve, reject) => {
        const url = `api/customer/basics/summary`;
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      if (searchRequest.terms === "CREATED_DATE") {
        tempObj.searchStartDate = CM.cfnConvertDateToString(searchRequest.searchStartDate);
        tempObj.searchEndDate = CM.cfnConvertDateToString(searchRequest.searchEndDate);
      } else {
        tempObj.searchStartDate = "";
        tempObj.searchEndDate = "";
      }
      return tempObj;
    };

    // useEffect start function
    const startAxiosSummary = async () => {
      const resultData = await axiosSummary();
      handleSummaryInfoChange(resultData);
    };

    // axios request informationList
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = "api/customer/basics";
        url += `?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}`;
        url += `&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;
        url += `&searchProperty=${search.terms}&searchContents=${search.search}`;
        url += `&searchStartDate=${search.searchStartDate}&searchEndDate=${search.searchEndDate}`;
        url += `&contractProperty=${search.contractProperty}`;
        url += `&groupUniqueKey=${search.groupUniqueKey}&staffUniqueKey=${search.staffUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultCustomer = resultData.content.map((data) => ({ ...data, _checked: false }));

      setOriginalCustomerList(resultData);
      handleCustomerListChange(resultCustomer);
      setPagination(paginationData(resultData));
      handleSearchButtonChange(false);

      // state 유지를 위한 history 설정
      history.replace("/customer/customerInformationList", search);
    };

    // 실행 영역
    const convertSearchRequest = fnMakeParameter();
    if (searchButton) {
      startAxiosSummary();
      startAxios(convertSearchRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest, searchButton]);

  useEffect(() => {
    const getState = history.location.state;
    if ((history.action === "POP" || history.action === "PUSH") && CM.cfnIsNotEmpty(getState)) {
      setTerms(getState.terms); // 검색 옵션
      setInput(getState.search); // 검색 내용
      setGroup(getState.groupUniqueKey); // 고객 구분
      setStaff(getState.staffUniqueKey); // 고객 담당자
      setSearchRequest(getState);
    }
    setSearchButton(true);
  }, [history]);
  // 검색 (page)
  const handleOffsetChange = (e, offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPage = ({ target: { value } }) => {
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  // 검색 컴포넌트 handleChange
  const handleSearchFormChange = (terms, search, groupUniqueKey, staffUniqueKey, pageNumber) => {
    handleSearchRequestChange((data) => ({
      ...data,
      terms,
      search,
      groupUniqueKey,
      staffUniqueKey,
      pageNumber,
      contractProperty: "",
    }));
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 고객 목록 리스트 값을 변경하는 함수
  const handleCustomerListChange = (value) => setCustomerList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  // 선택고객 일괄 삭제를 처리하는 함수
  const fnDeleteCustomer = () => {
    const url = "api/customer/basics/deleteList";
    const sendData = customerList.filter((customer) => customer._checked).map((customer) => customer.uniqueKey);

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      CM.cfnAlert("선택된 고객이 없습니다.");
      return false;
    }

    // axios 호출
    CM.cfnAxios(url, "put", sendData, (status, response) => {
      CM.cfnAlert("삭제되었습니다.", () => {
        handleSearchButtonChange(true); // 데이터 재조회
      });
    });
  };

  // 선택고객 일괄 수정을 처리하는 함수
  const fnModifyCustomer = () => {
    const sendData = []; // 삭제를 위한 data

    for (const customer of customerList) {
      if (customer._checked) sendData.push(customer);
    }

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) return false;

    // 수정할 고객구분과 담당지사/관리담당자를 모두 선택하지 않았다면
    if (CM.cfnIsEmpty(modifyCustomerGroup) && CM.cfnIsEmpty(modifyStaffs)) return false;

    // 수정할 data의 값 설정
    for (const data of sendData) {
      if (CM.cfnIsNotEmpty(modifyCustomerGroup)) data.customerGroup.uniqueKey = modifyCustomerGroup;
      if (CM.cfnIsNotEmpty(modifyStaffs)) data.staff.uniqueKey = modifyStaffs;
    }

    // 성공 콜백
    const fnCallback = (status, data) => {
      CM.cfnAlert("수정되었습니다.", () => {
        handleSearchButtonChange(true); // 데이터 재조회
      });
    };

    const url = "api/customer/basics/updateList";
    CM.cfnAxios(url, "put", sendData, fnCallback);
  };

  const checkMaxCustomerCount = async () => {
    const businessInfo = await store.axiosBusinessInfo();
    const loginStaffInfo = loginStore.loginDto;
    const url = "/api/customer/basics/count";

    let response = await CM.cfnAxiosAsync(url, "get", null, false);
    //현재 등록 된 고객 수와 기관 최대 고객 수 비교
    if (response.data >= businessInfo.maxCustomerCount) {
      //총괄담당자와 이용요금 담당자일 경우
      if (loginStaffInfo.masterYn || loginStaffInfo.billingManagerYn) {
        CM.cfnConfirm("최대 등록 고객수에 도달하였습니다. 등록가능 고객수를 100명 늘리시겠습니까?\n※100명 추가 시 이용요금이 2000원 증가됩니다.", async () => {
          businessInfo.maxCustomerCount = businessInfo.maxCustomerCount + 100;
          businessInfo.delayRate = null;
          fnSubmitUrl(businessInfo);
        });
      } else {
        CM.cfnAlert(
          "최대 등록 고객수에 도달하였습니다.\n최대 등록 고객수는 총괄담당자와 이용요금담당자가 변경할 수 있습니다.\n등록가능 고객수 추가를 원하시면, 총괄담당자나 이용요금담당자에게 요청하시기 바랍니다"
        );
      }
      return false;
    }
    return true;
  };

  const fnSubmitUrl = (sendData) => {
    const url = `/api/institute/business`;
    CM.cfnAxios(
      url,
      "put",
      sendData,
      fnSumitCallback,
      (error) => {
        if (error.response.status === 304) {
          CM.cfnAlert("변경된 내용이 없습니다.", () => {});
        } else {
          let message = "업무정보를 변경하지 못했습니다.";
          if (typeof error.response.data === "string") message = error.response.data;
          else if (typeof error.response.data === "object") message = error.response.data.message;
          CM.cfnAlert(message || error.response);
        }
      },
      false,
      true
    );
  };

  const fnSumitCallback = (objStatus, objData) => {
    CM.cfnAlert("정상적으로 변경되었습니다. 다시 등록해주시기 바랍니다.", () => {
      store.getBusinessInfo(fnGetBusinssInfo);
    });
  };

  /*
   * @desc    업무정보 조회 후 callback 함수
   */
  const fnGetBusinssInfo = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }
    store.setBusinessInfo(data);
  };

  // 고객등록 버튼 클릭 handler
  const goCreateCustomerPage = async () => {
    //현재 등록 된 고객 수와 기관 최대 고객 수 비교
    if (!(await checkMaxCustomerCount())) {
      return false;
    }
    history.push("/customer/customerCreate");
  };

  //고객일괄등록 버튼 클릭 이벤트 핸들러
  const handleAddExcelModal = () => {
    setAddExcelOpen(true);
  };

  // 고객 row 클릭시 고객 조회/수정 페이지로 이동
  const goCustomerUpdatePage = (e, customer) => {
    e.preventDefault();
    history.push("/customer/customerUpdate", {
      customer: customer,
      searchRequest: searchRequest,
    });
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 엑셀 저장
  const downloadExcel = () => {
    // 실행 영역
    const downloadSearchRequest = CM.cfnCopyObject(searchRequest);

    if (searchRequest.terms === "CREATED_DATE") {
      downloadSearchRequest.searchStartDate = CM.cfnConvertDateToString(searchRequest.searchStartDate);
      downloadSearchRequest.searchEndDate = CM.cfnConvertDateToString(searchRequest.searchEndDate);
    } else {
      downloadSearchRequest.searchStartDate = "";
      downloadSearchRequest.searchEndDate = "";
    }

    let url = "api/customer/basics/excel";
    url += `?contractProperty=${downloadSearchRequest.contractProperty}`;
    url += `&groupUniqueKey=${downloadSearchRequest.groupUniqueKey}&searchContents=${downloadSearchRequest.search}`;
    url += `&searchStartDate=${downloadSearchRequest.searchStartDate}&searchEndDate=${downloadSearchRequest.searchEndDate}`;
    url += `&searchProperty=${downloadSearchRequest.terms}&staffUniqueKey=${downloadSearchRequest.staffUniqueKey}&sortProperty=${downloadSearchRequest.sortProperty}&sortDirection=${downloadSearchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  //최초등록일 기간 검색 일자 검색
  const changeSearchFormHandler = (name) => (e) => {
    if (name === "searchStartDate" || name === "searchEndDate") {
      const chagedEvent = e;
      setSearchRequest({
        ...searchRequest,
        [name]: chagedEvent,
      });
    }
  };

  // 화면 렌더
  return (
    <div>
      <div className="summary-main-area">
        <CF.SummaryForm list={summaryInfo} onClick={handleSummaryInfoClick} data-testid="customerInformation-main" />
        <div className="summary-button-area">
          <button className="btn-m summary-button" onClick={goCreateCustomerPage} data-testid="customerInformation-insert-customers">
            고객등록
          </button>
          <button className="btn-m summary-button" onClick={handleAddExcelModal} data-testid="customerInformation-insert-customer-bulk">
            고객일괄등록
          </button>
        </div>
      </div>
      <SearchForm
        terms={terms}
        input={input}
        handleTermsChange={handleTermsChange}
        handleInputChange={handleInputChange}
        searchRequest={searchRequest}
        handleChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        customerGroupOption={modifyCustomerGroupOption}
        staffsOption={modifyStaffsOption}
        group={group}
        staff={staff}
        handleGroupChange={handleGroupChange}
        handleStaffChange={handleStaffChange}
        changeSearchFormHandler={changeSearchFormHandler}
      />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={originalCustomerList.totalElements} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPage} customProps={{ inputProps: { "data-testid": "customerInformation-rowPerPage-select" } }} />
        <button className="btn-m fr" onClick={downloadExcel} data-testid="customerInformation-save-excel">
          엑셀저장
        </button>
        <div className="modify-area">
          <div className="block">
            <span className="label-l">선택 고객을</span>
            <Select native value={modifyCustomerGroup} onChange={(e) => setModifyCustomerGroup(e.target.value)} inputProps={{ "data-testid": "customerInformation-select-modifyCustomerGroup" }}>
              <option value="" key="none">
                고객구분
              </option>
              {modifyCustomerGroupOption.map((option, index) => {
                return (
                  <option value={option.uniqueKey} key={index}>
                    {option.customerGroupName}
                  </option>
                );
              })}
            </Select>
            <Select native value={modifyStaffs} onChange={(e) => setModifyStaffs(e.target.value)} inputProps={{ "data-testid": "customerInformation-select-modifyStaffs" }}>
              <option value="" key="none">
                고객담당자
              </option>
              {modifyStaffsOption.map((option, index) => {
                return (
                  <option value={option.uniqueKey} key={index}>
                    {option.name}
                  </option>
                );
              })}
            </Select>
            <span className="label-r">으로</span>
            <button className="btn-l" data-testid="customerInformation-modify-select-customer" onClick={fnModifyCustomer}>
              일괄변경
            </button>
            <button className="btn-m" data-testid="customerInformation-delete-select-customer" onClick={fnDeleteCustomer}>
              선택고객 삭제
            </button>
          </div>
        </div>
      </div>
      <ListForm
        customerList={customerList}
        handleCustomerListChange={handleCustomerListChange}
        goCustomerUpdatePage={goCustomerUpdatePage}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="customerInformation-list-pagiation" />

      <CustomerAddBulk open={addExcelOpen} setOpen={setAddExcelOpen} handleSearchButtonChange={handleSearchButtonChange} />
    </div>
  );
};

// 검색 컴포넌트
const SearchForm = (props) => {
  const optionList = searchOptionData();
  const today = new Date();

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleChange(props.terms, props.input, props.group, props.staff, 0);
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area centered">
      <div className="block">
        <label className="label-l">고객구분</label>
        <Select native value={props.group} onChange={props.handleGroupChange} inputProps={{ "data-testid": "customerInformation-select-group" }}>
          <option value={""} key={"none"}>
            {"전체"}
          </option>
          {props.customerGroupOption.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객담당자</label>
        <Select native value={props.staff} onChange={props.handleStaffChange} inputProps={{ "data-testid": "customerInformation-select-staff" }}>
          <option value={""} key={"none"}>
            {"전체"}
          </option>
          {props.staffsOption.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.name}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={props.terms} onChange={props.handleTermsChange} name="terms" inputProps={{ "data-testid": "customerInformation-select-terms" }}>
          {optionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <div className="block">
          {props.terms === "CREATED_DATE" ? (
            <>
              <Template.DatePickerForm
                className="w170 receiptRequestResultDatePicker"
                value={props.searchRequest.searchStartDate}
                handleChange={props.changeSearchFormHandler("searchStartDate")}
                format="yyyy.MM.dd"
                customProps={{ placeholder: "YYYY.MM.DD", maxDate: today }}
              />
              <span className="between">~</span>
              <Template.DatePickerForm
                className="w170 receiptRequestResultDatePicker"
                value={props.searchRequest.searchEndDate}
                handleChange={props.changeSearchFormHandler("searchEndDate")}
                format="yyyy.MM.dd"
                customProps={{ placeholder: "YYYY.MM.DD", maxDate: today }}
              />
            </>
          ) : (
            <TextField className="w160" value={props.input} onChange={props.handleInputChange} onKeyUp={handleSearchKeyUp} name="search" data-testid="customerInformation-search-input" />
          )}
          <button className="search-button" onClick={() => fnSearch()}>
            검색
          </button>
        </div>
      </div>
    </div>
  );
};

// 목록 컴포넌트
const ListForm = (props) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of props.customerList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const customerLength = props.customerList.length;

    if (checkAllFlag === customerLength && customerLength > 0) setCheckAllRow(true);
    else setCheckAllRow(false);
  }, [props.customerList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleDeleteChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.customerList];
    cloneData[index][key] = value;
    props.handleCustomerListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.handleCustomerListChange((customer) => customer.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // TableRow 클릭시 상세 페이지로 이동
  const handleRowClick = (e, customer) => {
    if (e.target.classList.contains("ignoreClick") || e.target.type === "checkbox") {
      return;
    }

    props.goCustomerUpdatePage(e, customer);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["30px", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "8%", "8%"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
            { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true },
            { id: "CUSTOMER_MOBILE", label: "휴대전화", sortable: false },
            { id: "CUSTOMER_TYPE", label: "고객구분", sortable: false },
            { id: "STAFF_BRANCH", label: "고객담당지사", sortable: false },
            { id: "STAFF_NAME", label: "관리담당자", sortable: false },
            { id: "CREATED_DATE", label: "최초등록일", sortable: true },
            { id: "RECEIPT", label: "수납정보", sortable: false },
            { id: "PAYMENT", label: "지급정보", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
          checkboxCustomProps={{ "data-testid": "customerInformation-list-head-checkbox" }}
          tableSortLabelDataTestId={"customerInformation-list-head-sortLabel"}
        />
        <TableBody>
          {props.customerList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.customerList.map((customer, index) => {
              return (
                <TableRow hover key={index} className="show-detail" onClick={(e) => handleRowClick(e, customer)} data-testid={`customerInformation-goDetail-${index}`}>
                  <TableCell className="ignoreClick" align="center">
                    <Checkbox
                      checked={customer._checked}
                      value="_checked"
                      id={`deleteCheckbox-${index}`}
                      onChange={handleDeleteChange}
                      inputProps={{
                        index: index,
                      }}
                      data-testid={`customerInformation-list-checkbox-${index}`}
                    />
                  </TableCell>
                  <TableCell align="center" data-testid="list-customer-name">
                    {customer.customerName}
                  </TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(customer.identificationNo)}</TableCell>
                  <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(customer.customerMobile)}</TableCell>
                  <TableCell align="center">{Object.isExtensible(customer.customerGroup) ? customer.customerGroup.customerGroupName : ""}</TableCell>
                  <TableCell align="center">{!Object.isExtensible(customer.staff) ? "" : Object.isExtensible(customer.staff.branch) ? customer.staff.branch.branchName : ""}</TableCell>
                  <TableCell align="center">{!Object.isExtensible(customer.staff) ? "" : customer.staff.staffName}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(customer.createdDate)}</TableCell>
                  <TableCell align="center">{customer.receiptContractCount}</TableCell>
                  <TableCell align="center">{customer.paymentContractCount}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
  props: props,
}))(observer(InformationList));
