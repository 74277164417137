/*
 * @desc  메인 컴포넌트
 */
import React, {Fragment, useEffect, useState} from "react";
import {Button, Modal, Select, Table, TableBody, TableCell, TableRow, TextField} from "@material-ui/core";
import {RowPerPageForm, SelectForm, TotalCountForm} from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";
import Pagination from "material-ui-flat-pagination/lib/Pagination";


function defaultSearchParam() {
    const endY4Mm = new Date();
    const startY4Mm = new Date(endY4Mm.getFullYear(), endY4Mm.getMonth() - 1);


    return {
        searchStartY4:  `${startY4Mm.getFullYear().toString()}`,//검색 시작 년월
        searchStartMm : startY4Mm.getMonth() < 9 ? `${0}${startY4Mm.getMonth() + 1}` : startY4Mm.getMonth() + 1,
        searchEndY4: `${endY4Mm.getFullYear().toString()}`,
        searchEndMm: endY4Mm.getMonth() < 9 ? `${0}${endY4Mm.getMonth() + 1}` : endY4Mm.getMonth() + 1,
        searchContents: "", //검색내용
        searchProperty: "CUSTOMER_NAME", //검색대상 - CUSTOMER_NAME(고객이름), PAYER_NO(납부자번호)
        sortProperty: "ASK_DATE", //정렬기준
        sortDirection: "DESC", //정렬방향 - ASC(오름차순), DESC(내림차순)
        pageNumber: "0", //페이지 번호
        pageSize: "5", //페이지 크기
    };
}

/*
 * 페이징 데이터
 */
function paginationData(data) {
    if (CM.cfnIsEmpty(data)) {
        return {
            rowsPerPage: 5,
            offset: 0,
            total: 0,
        };
    }

    return {
        rowsPerPage: data.pageable.pageSize,
        offset: data.pageable.offset,
        total: data.totalElements,
    };
}

/*
 * @desc  검색 부분 생성
 */
function CreateSearchForm(props) {
    const {searchOption, changeSearchFormHandler, changeSearchRequest} = props;
    const searchOptionObject = [
        { label: "고객이름", value: "CUSTOMER_NAME" },
        { label: "납부자번호", value: "PAYER_NO" }
    ];

    const fnClickSearch = () => {
        changeSearchRequest();
    };

    return(
        <div className="search-area tl">
            <div className="block">
                <label className="label-l">출금불능분 거래 월</label>
                <SelectForm
                    value={searchOption.searchStartY4}
                    handleChange={changeSearchFormHandler("searchStartY4")}
                    name="searchStartY4"
                    arrayOption={props.fnCreateYearOption()}
                    optionValue="value"
                    optionLabel="label"
                />
                <SelectForm
                    value={searchOption.searchStartMm}
                    handleChange={changeSearchFormHandler("searchStartMm")}
                    name="searchStartMm"
                    arrayOption={props.fnCreateEndMonthOption(searchOption.searchStartMm)}
                    optionValue="value"
                    optionLabel="label"
                />
                <span className="between">~</span>
                <SelectForm
                    value={searchOption.searchEndY4}
                    handleChange={changeSearchFormHandler("searchEndY4")}
                    name="searchEndY4"
                    arrayOption={props.fnCreateYearOption()}
                    optionValue="value"
                    optionLabel="label"
                />
                <SelectForm
                    value={searchOption.searchEndMm}
                    handleChange={changeSearchFormHandler("searchEndMm")}
                    name="searchEndMm"
                    arrayOption={props.fnCreateEndMonthOption(searchOption.searchEndMm)}
                    optionValue="value"
                    optionLabel="label"
                />
                <div className="margin-right-20 display-block" />
                <label className="label-l">검색어입력</label>
                <Select native data-testid="searchProperty_selectBox" className="w110" name="searchProperty" value={searchOption.searchProperty} onChange={changeSearchFormHandler("searchProperty")}>
                    {searchOptionObject.map((option, index) => {
                        return (
                            <option value={option.value} key={index}>
                                {option.label}
                            </option>
                        );
                    })}
                </Select>
                <TextField
                    data-testid="searchContents_inputBox"
                    className="w130"
                    value={searchOption.searchContents}
                    onChange={changeSearchFormHandler("searchContents")}
                    onKeyUp={(e) => {
                        if (e.keyCode === 13) fnClickSearch();
                    }}
                    name="searchContents"
                />
                <button className="search-button" onClick={() => fnClickSearch()}>
                    검색
                </button>
            </div>
        </div>
    );

}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
    return (
        <div>
            <Table>
                {CM.cfnCompColGroup(["10%", "10%", "10%", "10%", "10%", "10%", "8%", "8%", "auto"])}
                <TableForm.compServerSortTableHead
                    useCheckbox={false}
                    arrData={[
                        { id: "ASK_DATE", label: "거래시간", sortable: true },
                        { id: "TRANSACTION_DATE", label: "CMS 청구일", sortable: false },
                        { id: "TARGET_Y4MM", label: "대상 월", sortable: true },
                        { id: "CUSTOMER_NAME", label: "고객명", sortable: false },
                        { id: "PAYER_NO", label: "납부자번호", sortable: false },
                        { id: "TOTAL_ASKING_AMOUNT", label: "청구 총액", sortable: false },
                        { id: "PAY_METHOD", label: "수납 방법", sortable: false },
                        { id: "RESULT_CODE", label: "불능 코드", sortable: false },
                        { id: "RESULT_MESSAGE", label: "불능 메시지", sortable: false },
                    ]}
                    searchRequest={props.searchRequest}
                    handleSortProperty={props.handleSortProperty}
                    />
                <TableBody>
                {props.targetList.length === 0 ? (
                    <TableForm.compEmptyTableRow colSpan={9} />
                ) : (
                    props.targetList.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align="center">{CM.cfnDateFormat(row.askedDate + row.askedTime).split("\n")
                                    .map((line, i) => (
                                        <Fragment key={i}>
                                            <span>{line}</span>
                                            <br/>
                                        </Fragment>))}
                                </TableCell>
                                <TableCell align="center">{CM.cfnDateFormat(row.lastCmsPaidDate)}</TableCell>
                                <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                                <TableCell align="center">{row.customerName}</TableCell>
                                <TableCell align="center">{row.payerNo}</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.totalAskingAmount)}원</TableCell>
                                <TableCell align="center">{row.payMethod === null ? "-" : row.payMethod}</TableCell>
                                <TableCell align="center">{row.resultCode === "" ? "-" : row.resultCode}</TableCell>
                                <TableCell align="center">{row.resultMessage}</TableCell>
                            </TableRow>);
                    })
                )}
            </TableBody>
            </Table>
        </div>
    )
}

const UnpaidReceiptRequestFailContents = (props) => {
    const {open, setOpen} = props;

    const [searchOption, setSearchOption] = useState(defaultSearchParam());
    const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag

    const [originalList, setOriginalList] = useState([]);
    const [targetList, setTargetList] = useState([]); // table 데이터
    const [pagination, setPagination] = useState(paginationData());

    // 모달 클로즈
    const handleClose = () => {
        //pagination 데이터초기화
        setSearchOption(defaultSearchParam);
        setSearchButton(true);
        setOpen(false);
    };


    /*
    * @desc    페이지 변경 이벤트 핸들러 (검색)
    */
    const handleOffsetChange = (offset, page) => {
        setSearchOption((data) => ({ ...data, pageNumber: page - 1 }));
        setSearchButton(true);
    };

    /*
    * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
    */
    const handleRowPerPageChange = (e) => {
        const value = e.target.value;
        setSearchOption((data) => ({...data, pageSize: value, pageNumber: 0}));
        setSearchButton(true);
    };


    //조회 대상 셀렉박스 변경 시
    const changeSearchFormHandler = (name) => (e) => {
        const originEndY4Mm = new Date();
        const originStartY4Mm = new Date(originEndY4Mm.getFullYear(), originEndY4Mm.getMonth() - 1);

        //조회대상의 년도가 걸쳐있을 경우(2022.12 ~ 2023.01) 년도 월 선택에 따라 년월 맞춰주는 부분(2023년01일 경우 2022년 선택했을 때 12월로 자동 맞춰주기)
        if(name === "searchStartY4" || name === "searchEndY4" || name === "searchStartMm" || name === "searchEndMm"){
            if(originEndY4Mm.getFullYear() !== originStartY4Mm.getFullYear()){
                if(name === "searchStartY4"){
                    setSearchOption({
                        ...searchOption,
                        searchStartMm : e.target.value === originEndY4Mm.getFullYear().toString() ? `${0}${originEndY4Mm.getMonth() + 1}` : originStartY4Mm.getMonth() + 1,
                        [name]: e.target.value,
                    });
                }else if(name === "searchEndY4"){
                    setSearchOption({
                        ...searchOption,
                        searchEndMm : e.target.value === originEndY4Mm.getFullYear().toString() ? `${0}${originEndY4Mm.getMonth() + 1}` : originStartY4Mm.getMonth() + 1,
                        [name]: e.target.value,
                    });
                }else if(name === "searchStartMm"){
                    setSearchOption({
                        ...searchOption,
                        searchStartY4 : e.target.value === '12' ? originStartY4Mm.getFullYear().toString() : originEndY4Mm.getFullYear().toString(),
                        [name]: e.target.value,
                    });
                }else if(name === "searchEndMm"){
                    setSearchOption({
                        ...searchOption,
                        searchEndY4 : e.target.value === '12' ? originStartY4Mm.getFullYear().toString() : originEndY4Mm.getFullYear().toString(),
                        [name]: e.target.value,
                    });
                }
            }else {
                setSearchOption({
                    ...searchOption,
                    [name]: e.target.value,
                });
            }
        } else if (e.target.type === "checkbox") {
            const checked = e.target.checked;
            setSearchOption({
                ...searchOption,
                [name]: checked,
            });
        } else if(name === "searchProperty") {
            setSearchOption({
                ...searchOption,
                [name]: e.target.value,
                searchContents: "",
            });
        }else {
            const changedValue = e.target.value;
            setSearchOption({
                ...searchOption,
                [name]: changedValue,
            });
        }
    };

    const changeSearchRequest = () => {
        setSearchOption((data) => ({ ...data, pageNumber: 0 }));
        setSearchButton(true);
    };

    /*
 * @desc    정렬 조건 변경 이벤트 핸들러
 */
    const handleSortProperty = async (sortObjArray) => {
        let sortProperty = "";
        let sortDirection = "";

        for (const obj of sortObjArray) {
            if (obj.name === "sortProperty") sortProperty = obj.value;
            if (obj.name === "sortDirection") sortDirection = obj.value;
        }

        setSearchOption({
            ...searchOption,
            sortProperty: sortProperty,
            sortDirection: sortDirection,
        });
        //정렬조건 세팅 후 검색 trigger
        setSearchButton(true);
    };

    /*
* @desc     미납내역 청구 년도 옵션 생성하는 함수
*/
    const fnCreateYearOption = () => {
        const arrYear = [];

        const today = new Date();
        const startY4Mm = new Date(today.getFullYear(), today.getMonth() - 1);
        const endY4Mm = new Date();

        if(startY4Mm.getFullYear() === endY4Mm.getFullYear()){
            arrYear.push({
                label: `${startY4Mm.getFullYear()}년`,
                value: startY4Mm.getFullYear(),
            });
        }else{
            for(let startY4=startY4Mm.getFullYear(); startY4<=endY4Mm.getFullYear(); startY4++){
                arrYear.push({
                    label : `${startY4}년`,
                    value : startY4,
                });
            }
        }
        return arrYear;
    };

    /*
* @desc     미납내역 청구 시작월 / 종료월의 옵션 생성하는 함수
*/
    const fnCreateEndMonthOption = (searchMm) => {
        const arrMonth = [];

        const endY4Mm = new Date();
        const startY4Mm = new Date(endY4Mm.getFullYear(), endY4Mm.getMonth() - 1);

        arrMonth.push({
                label : `${startY4Mm.getMonth() + 1}월`,
                value : startY4Mm.getMonth() < 9 ? `${0}${startY4Mm.getMonth() + 1}` : startY4Mm.getMonth() + 1,
            },
            {
                label : `${endY4Mm.getMonth() + 1}월`,
                value : endY4Mm.getMonth() < 9 ? `${0}${endY4Mm.getMonth() + 1}` : endY4Mm.getMonth() + 1,
            });

        return arrMonth;
    };

    useEffect(() => {
        /*
         * @desc 검색 결과조회 파라미터 생성 함수
         */
        const fnMakeParameter = (search) => {
            let tempObj = CM.cfnCopyObject(search);
            tempObj.searchStartY4mm = search.searchStartY4 + search.searchStartMm;
            tempObj.searchEndY4mm = search.searchEndY4 + search.searchEndMm;

            return tempObj;
        };

        /*
        * @desc  청구 실패 내역 조회 검색 URL
        */
        const axiosList = (searchParam) => {
            return new Promise((resolve) => {
                let url = `api/receipt/unpaid/request/failContents/?searchStartY4mm=${searchParam.searchStartY4mm}&searchEndY4mm=${searchParam.searchEndY4mm}&searchContents=${searchParam.searchContents}&searchProperty=${searchParam.searchProperty}&sortProperty=${searchParam.sortProperty}&sortDirection=${searchParam.sortDirection}&pageNumber=${searchParam.pageNumber}&pageSize=${searchParam.pageSize}`;

                CM.cfnAxios(url, "get", "", (status, data) => {
                    resolve(data);
                });
            });
        };

        const startAxios = async (search, buttonType) => {
            if (buttonType === "search") {
                setSearchButton(false);
                if(search.searchStartY4mm.substr(0,4) === search.searchEndY4mm.substr(0,4)){
                    if(search.searchStartY4mm.substr(4,2) > search.searchEndY4mm.substr(4,2)){
                        CM.cfnAlert("종료년월은 시작년월보다 크거나 같아야합니다.");
                        return false;
                    }
                }else if(search.searchStartY4mm.substr(0,4) > search.searchEndY4mm.substr(0,4)){
                    CM.cfnAlert("종료년월은 시작년월보다 크거나 같아야합니다.");
                    return false;
                }

                axiosList(search).then((data) => {
                    setOriginalList(data);
                    setTargetList(data.content.map((data) => ({...data, _checked: false})));
                    setPagination(paginationData(data));
                });

            }
        }

        if (open === true && searchButton === true) {
            const param = fnMakeParameter(searchOption);
            startAxios(param, "search");
        }
    },[open, searchButton, searchOption]);

    return (
        <Modal open={open}>
            <div className="paper">
                <div className="inner">
                    <div className="modal-top-area">
                        <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
                            {""}
                        </Button>
                    </div>
                    <h3>청구실패 내역 조회</h3>
                    <div>
                        {/* 검색 폼 */}
                        <CreateSearchForm
                            searchOption={searchOption}
                            changeSearchFormHandler={changeSearchFormHandler}
                            changeSearchRequest={changeSearchRequest}
                            fnCreateYearOption={fnCreateYearOption}
                            fnCreateEndMonthOption={fnCreateEndMonthOption}
                        />
                        {/* 건수 폼 */}
                        <div className="table-top-area">
                            <TotalCountForm totalElements={originalList.totalElements || 0} />
                            <RowPerPageForm data-testid="receiptList-rowPerPage-select" value={searchOption.pageSize} onChange={handleRowPerPageChange} />
                        </div>
                        {/* 청구 실패 내역 리스트 보여주는 부분 */}
                        <ListForm
                            targetList={targetList}
                            page={searchOption.pageNumber}
                            setSearchButton={setSearchButton}
                            searchRequest={searchOption}
                            handleSortProperty={handleSortProperty}
                        />
                        <Pagination
                            className="centered"
                            limit={pagination.rowsPerPage}
                            offset={pagination.offset}
                            total={pagination.total}
                            onClick={(e, offset, page) => handleOffsetChange(offset, page)}
                            reduced={true}
                            centerRipple={false}
                            disableFocusRipple={true}
                            disableRipple={true}
                        />
                    </div>
                </div>
            </div>
        </Modal>

    );
}

export default UnpaidReceiptRequestFailContents;