import axios from "axios";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "./components/common/Common";
//router
import PrivateRoute from "./components/common/PrivateRoute";
import "./styles/main.scss";

/*
 * @desc    (임시) 로그인을 안했을 때 로그인 페이지로 redirect 처리를 하는 컴포넌트
 * @author  안태현
 */
const BillingonePlus = (props) => {
  const history = useHistory();
  const [init, setInit] = useState(false);

  useEffect(() => {
    /*
      * @desc    (임시) 로그인 유지를 처리하는 함수
      * @author  안태현
      */
    const initLoginInfo = async () => {
      const loginDto = CM.storage.get("loginDto");
      if (CM.cfnIsEmpty(loginDto)) return;
      props.loginStore.setIsLogin(true);
      props.loginStore.setLoginDto(loginDto);
      CM.cfnSetHeader(loginDto.jwt);
    
      if (CM.gblHeader !== null && CM.gblHeader !== "") {
        // 로그인 상태 체크
        axios.defaults.baseURL = CM.SERVER_URL;
        const config = {
          headers: {
            Authorization: CM.gblHeader
          }
        };
        const url = "api/auth/login";
    
        await axios
          .get(url, config)
          .then(response => {
            props.loginStore.setIsLogin(true);
            props.loginStore.resetSessionExpireTime();
          })
          .catch(async error => {
            props.loginStore.setIsLogin(false);
            props.loginStore.expireSession();
            history.replace("/");
            CM.cfnAlert("로그인 정보가 없어 로그아웃 처리됩니다.", null);
          });
      }
    };
    (async () => {
      if(!init){
        setInit(true);
        await initLoginInfo();
      }
    })();
  }, [history, props, init]);

  //서비스 이용 중 이벤트 핸들러
  const handleOnUse = () => {
    //키보드 입력 또는 마우스 클릭 이벤트 발생 시 세션 만료 기간 연장
    props.loginStore.resetSessionExpireTime();
  };
  
  return (
    <div className="wrap" onClick={handleOnUse} onKeyDown={handleOnUse}>
      {init ? <PrivateRoute loginStore={props.loginStore} /> : ""}
    </div>
  );
}

export default inject(rootStore => ({
  loginStore: rootStore.loginStore
}))(observer(BillingonePlus));
