import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import * as CM from "../../common/Common";

// 렌더를 처리하는 메인 컴포넌트
export default function ArsWithDraw(props) {
  const { instituteService } = props;
  const [arsData, setArsData] = useState(instituteService);

  // 서비스 신청 또는 해지 처리를 하는 함수
  const fnUpdateSetting = (service) => {
    const sendData = { ...arsData };

    // 서비스 신청일 때
    if (service === "insert") {
      sendData.serviceUseYn = true;
      sendData.serviceStartDate = CM.cfnGetDate();
    } else {
      sendData.serviceUseYn = false;
    }

    const url = "api/institute/services";

    CM.cfnAxios(url, "put", sendData, (status, data) => {
      const message = service === "insert" ? "서비스가 신청되었습니다." : "서비스가 해지되었습니다.";
      CM.cfnAlert(message, () => {
        setArsData(sendData);
        props.updateInstituteService(sendData, "ARS_EVIDENCE");
      });
    });
  };

  return (
    <div>
      <div className="modify-area">
        <div className="block">{arsData.serviceUseYn ? "현재 ARS 동의자료 대행 서비스를 이용 중입니다." : "다음 내용으로 ARS 동의자료 대행 서비스를 신청합니다."}</div>
      </div>
      <h4>서비스안내</h4>
      <div className="inforbox">
        <ul>
          <li>ARS 동의자료 대행 서비스는 성공 건당 100원, 실패 건당 50원(부가세 별도)의 수수료가 부과됩니다.</li>
          <li>본 서비스는 ARS방식으로 자동납부 동의자료를 대행하여 받는 서비스입니다. 부당출금 방지를 위해 이용기관에서 이용 전 계좌의 실제 소유주와 고객이 동일한지 반드시 확인하셔야 합니다.</li>
          <li>
            서비스 이용내역은{" "}
            <Link to="/extraService/arsHistory" className="link">
              ARS 서비스 이용내역
            </Link>
            에서 확인하실 수 있습니다.
          </li>
        </ul>
      </div>
      <div className="table-bottom-area">
        <ServiceButtonForm useYn={arsData.serviceUseYn} fnUpdateSetting={fnUpdateSetting} className="table-bottom-area" />
      </div>
    </div>
  );
}

// 서비스 설정 버튼
function ServiceButtonForm(props) {
  const service = props.useYn ? "terminate" : "insert";
  const text = props.useYn ? "서비스 해지" : "서비스 신청";

  return (
    <button className="btn-l" onClick={() => props.fnUpdateSetting(service)} data-testid={props.useYn ? "tabArsWithdraw-button-terminateService" : "tabArsWithdraw-button-requestService"}>
      {text}
    </button>
  );
}
