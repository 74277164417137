import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import * as ComponentForm from '../../template/ComponentForm';
import ReceiptCreateList from './ReceiptCreateList';
import EvidenceFileBatchRegister from '../../template/EvidenceFileBatchRegister';

// main component that handles the render
export default function ReceiptCreateModal(props) {
  // tab index hooks
  const [tabIndex, setTabIndex] = useState(0);
  const [tabTitle, setTabTitle] = useState('수납고객정보 일괄등록');
  const handleTabIndex = (e, value) => setTabIndex(value);
  const handleTabTitle = value => setTabTitle(value);

  // modal cloase
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    setTabTitle(tabIndex === 0 ? '수납고객정보 일괄등록' : '동의자료 일괄등록')
  }, [tabIndex]);

  // render start
  return (
    <Modal open={props.open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="receiptCreateList-close-modal">
              {''}
            </Button>
          </div>
          <h3>{tabTitle}</h3>
          <AppBar position="static" color="default">
            <Tabs value={tabIndex} onChange={handleTabIndex} indicatorColor="primary" textColor="primary" data-testid="tabs-receipt-create-modal">
              <Tab value={0} label="수납고객정보 일괄등록" data-testid="tab-receipt-create-list" />
              {props.submissionType === '제출' && <Tab value={1} label="동의자료 일괄등록" data-testid="tab-file-register" />}
            </Tabs>
          </AppBar>
          <ComponentForm.TabPanel value={tabIndex} index={0}>
            <ReceiptCreateList
              tabIndex={tabIndex}
              handleModalClose={handleClose}
              handleTabTitle={handleTabTitle}
              handleSearchButtonChange={props.handleSearchButtonChange}
            />
          </ComponentForm.TabPanel>
          <ComponentForm.TabPanel value={tabIndex} index={1}>
            <EvidenceFileBatchRegister/>
          </ComponentForm.TabPanel>
        </div>
      </div>
    </Modal>
  );
}
