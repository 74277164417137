import React, { useState, useEffect } from "react";
import { FormControl, Table, TableBody, TableRow, TableCell, FormControlLabel, FormGroup, RadioGroup, Radio } from "@material-ui/core";

import * as CM from "../../common/Common";
import { SelectForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

function CreateSearchForm(props) {
  const { changeSearchFormHandler } = props;

  const selectYear = setSelectBoxYearList();
  const [targetYear, setTargetYear] = useState(new Date().getFullYear()); // 통계연도
  const [transactionMethod, setTransactionMethod] = useState(""); // 수납방법

  const handleChangeYear = ({ target: { value } }) => setTargetYear(value);
  const handleChangeMethod = ({ target: { value } }) => setTransactionMethod(value);

  const fnClickSearch = () => {
    changeSearchFormHandler((data) => ({ ...data, targetYear, transactionMethod }));
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">통계연도</label>
        <SelectForm value={targetYear} handleChange={handleChangeYear} arrayOption={selectYear} customProps={{ inputProps: { "data-testid": "monthlyStatistics-select-year" } }} />
        <label className="label-l">수납방법</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="transactionMethod" name="transactionMethod" row>
            <RadioGroup value={transactionMethod} onChange={handleChangeMethod} data-testid="monthlyStatistics-radio-method" row>
              <FormControlLabel value="" control={<Radio color="primary" />} label="전체" />
              <FormControlLabel value="CMS_WITHDRAW" control={<Radio color="primary" />} label="CMS" />
              <FormControlLabel value="ETC" control={<Radio color="primary" />} label="기타" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <button className="search-button" onClick={() => fnClickSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

function CreateTopListForm(props) {
  const headRowOne = [
    { value: "구분", rowSpan: 2 },
    { value: "수납대상", rowSpan: 2 },
    { value: "수납내역", colSpan: 4 },
    { value: "수납보류", rowSpan: 2 },
    { value: "손실처리", rowSpan: 2 },
    { value: "환불", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "완납" }, { value: "부분수납" }, { value: "미납" }, { value: "미청구" }];

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["8%", "15%", "11%", "11%", "11%", "11%", "11%", "11%", "11%"])}
        <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />
        <TableBody>
          {props.topList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            <React.Fragment>
              <TableRow>
                <TableCell align="center"> 건수 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalAskingCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPartiallyPaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnpaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnaskedCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalHoldingCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalLossCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalRefundedCount)}건 </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"> 금액 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalAskingAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPaidAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPartiallyPaidAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnpaidAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnaskedAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalHoldingAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalLossAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalRefundedAmount)}원 </TableCell>
              </TableRow>
            </React.Fragment>
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}

function CreateBottomListForm(props) {
  const headRowOne = [
    { value: "년/월", rowSpan: 2 },
    { value: "구분", rowSpan: 2 },
    { value: "수납대상", rowSpan: 2 },
    { value: "수납내역", colSpan: 4 },
    { value: "수납보류", rowSpan: 2 },
    { value: "손실처리", rowSpan: 2 },
    { value: "환불", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "완납" }, { value: "부분수납" }, { value: "미납" }, { value: "미청구" }];

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["8%", "7%", "auto", "10%", "10%", "10%", "10%", "10%", "10%", "10%"])}
        <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />
        <TableBody>
          {props.bottomList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.bottomList.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>
                      {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                    </TableCell>
                    <TableCell align="center"> 건수 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalAskingCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPaidCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPartiallyPaidCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnpaidCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnaskedCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalHoldingCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalLossCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalRefundedCount)}건 </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center"> 금액 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalAskingAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPartiallyPaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnpaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnaskedAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalHoldingAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalLossAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalRefundedAmount)}원 </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}

function defaultSearchParam() {
  const today = new Date();

  const currentYYYY = today.getFullYear();

  return {
    transactionMethod: "", //수납방법 - null 또는 empty(전체), CMS_WITHDRAW(CMS출금), ETC(기타수납)
    targetYear: currentYYYY,
  };
}

function setSelectBoxYearList() {
  const today = new Date();
  const currentYYYY = today.getFullYear();
  const startOptionYear = 2005;
  const lastOptionYear = currentYYYY;
  const selectOptionYearList = [];
  for (var i = lastOptionYear; i >= startOptionYear; i--) {
    selectOptionYearList.push({
      value: i,
      label: `${i}년`,
    });
  }
  return selectOptionYearList;
}

// 렌더를 처리하는 메인 컴포넌트
const MonthlyStatistics = (props) => {
  const { tabIndex } = props;
  const [searchParam, setSearchParam] = useState(defaultSearchParam());
  const [topList, setTopList] = useState([]);
  const [bottomList, setBottomList] = useState([]);

  // 조회조건 변경
  const changeSearchFormHandler = (searchParam) => setSearchParam(searchParam);

  useEffect(() => {
    const getTopListAxios = (queryString) => {
      return new Promise((resolve) => {
        let url = `api/receipt/statistics/month/summary` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseTopListData) => {
          resolve(responseTopListData);
        });
      });
    };

    const getBottomListAxios = (queryString) => {
      return new Promise((resolve) => {
        let url = `api/receipt/statistics/month` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseBottomListData) => {
          resolve(responseBottomListData);
        });
      });
    };

    const startAxios = async (searchParam) => {
      const queryString = `?transactionMethod=${searchParam.transactionMethod}&targetYear=${searchParam.targetYear}`;

      //고객별 수납통계 요약내용 조회
      getTopListAxios(queryString)
        .then((responseSummaryData) => {
          setTopList(responseSummaryData);
        })
        .catch((error) => {
          console.error(error);
        });

      getBottomListAxios(queryString)
        .then((responseDetailData) => {
          setBottomList(responseDetailData);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (tabIndex === 2) {
      startAxios(searchParam);
    }
  }, [tabIndex, searchParam]);

  const monthlyExcelDownload = (e) => {
    const param = [];
    Object.keys(searchParam).forEach((key) => param.push(key + "=" + searchParam[key]));
    const url = "api/receipt/statistics/month/excel?" + param.join("&");
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  return (
    <div id="searchForm" data-testid="searchForm">
      <CreateSearchForm searchParam={searchParam} changeSearchFormHandler={changeSearchFormHandler} />
      <CreateTopListForm topList={topList} />
      <div className="table-top-area">
        <button className="btn-m fr" onClick={monthlyExcelDownload} data-testid="monthlyStatistics-save-excel">
          엑셀저장
        </button>
      </div>
      <CreateBottomListForm bottomList={bottomList} />
    </div>
  );
};

export default MonthlyStatistics;
