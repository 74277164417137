import React from "react";
import Pagination from "material-ui-flat-pagination";
import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";
import * as CF from "../../template/ComponentForm";

import { Table, Modal, Button, TableBody, TableCell, TableRow, TextField, Select } from "@material-ui/core";
import iconDisk from "../../../styles/images/icon_disk.png";
import iconDoc from "../../../styles/images/icon_doc.png";
import iconHwp from "../../../styles/images/icon_hwp.png";
import iconMp3 from "../../../styles/images/icon_mp3.png";
import iconPdf from "../../../styles/images/icon_pdf.png";
import iconWav from "../../../styles/images/icon_wav.png";
import iconXls from "../../../styles/images/icon_xls.png";

const searchPropertyList = [
  { value: "", label: "전체" },
  { value: "TITLE", label: "제목" },
  { value: "CONTENTS", label: "내용" },
];

class Pds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchContents: "",
      searchProperty: "TITLE",
      totalElements: 0,
      pagination: {
        rowsPerPage: 5,
        offset: 0,
        total: 0,
        totalPages: 1,
      },
      content: [],
      //table 정렬 여부
      searchRequest: {
        searchContents: "",
        searchProperty: "",
        pageNumber: 0,
        pageSize: 5,
        sortProperty: "createdDatetime",
        sortDirection: "DESC",
      },
      open: false,
      selectedIndex: 0,
    };
  }

  componentDidMount = () => {
    this.fnSearch();
  };

  handleState = ({ ...stateName }) => {
    let newState = Object.assign({}, this.state);
    Object.keys(stateName).forEach((key) => (newState[key] = stateName[key]));
    this.setState(newState);
  };

  handleSelectBox = (e) => {
    let jsonValue = {};
    let searchRequest = this.state.searchRequest;
    jsonValue[e.target.name] = e.target.value;
    searchRequest[e.target.name] = e.target.value;
    jsonValue["searchRequest"] = searchRequest;
    this.handleState(jsonValue);
  };

  handleInputChange = (e) => {
    let jsonValue = {};
    jsonValue[e.target.name] = e.target.value;
    this.handleState(jsonValue);
  };

  handleSearKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.fnSearch();
    }
  };

  handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    let stateObject = new Promise((resolve, reject) => {
      let jsonValue = {};
      let searchRequest = {};
      searchRequest["sortDirection"] = sortDirection;
      searchRequest["sortProperty"] = sortProperty;
      searchRequest["searchProperty"] = this.state.searchProperty;
      searchRequest["searchContents"] = this.state.searchContents;

      this.handleState(jsonValue);
      if (sortDirection === "") {
        reject(Error("no"));
      } else {
        resolve();
      }
    });

    stateObject
      .then(() => {
        this.fnSearch();
      })
      .catch((e) => {
        CM.cfnAlert(e);
      });
  };

  handleOffsetChange = (offset, page) => {
    let stateObject = new Promise((resolve, reject) => {
      let jsonValue = {};
      let searchRequest = this.state.searchRequest;
      searchRequest["pageNumber"] = page - 1;
      jsonValue["searchRequest"] = searchRequest;
      this.handleState(jsonValue);
      if (page === "") {
        reject(Error("no"));
      } else {
        resolve();
      }
    });

    stateObject
      .then(() => {
        this.fnSearch();
      })
      .catch((e) => {
        CM.cfnAlert(e);
      });
  };

  openModalHandler(event, index) {
    if (event.target.cellIndex !== 2) {
      let jsonValue = {};
      jsonValue["open"] = true;
      jsonValue["selectedIndex"] = index;
      this.handleState(jsonValue);
    }
  }

  attachedFileDownload = (index) => {
    let contents = this.state.content[index];
    let param = [];
    param.push("boardType=DATA");
    param.push("articleNo=" + contents["articleNo"]);
    let url = "/api/support/attachment?" + param.join("&");
    CM.cfnAxiosGeneralFileDownload(url, "get", "", contents["attachedFileName"], (status, data) => {});
  };

  handleClose = () => {
    let jsonValue = {};
    jsonValue["open"] = false;
    this.handleState(jsonValue);
  };

  fnSearch = () => {
    const url = "/api/support/data";
    let queryString = [];
    let jsonValue = {};
    let pageNation = this.state.pagination;

    Object.keys(this.state.searchRequest).forEach((key) => {
      queryString.push(key + "=" + (this.state.hasOwnProperty(key) ? this.state[key] : this.state.searchRequest[key]));
    });

    const urlParameter = queryString.length > 0 ? url + "?" + queryString.join("&") : url;
    CM.cfnAxios(urlParameter, "get", "", (status, dataList) => {
      if (status.status === 200) {
        pageNation["offset"] = dataList["pageable"]["offset"];
        pageNation["rowsPerPage"] = dataList["pageable"]["pageSize"];
        pageNation["totalPages"] = dataList["totalPages"];
        pageNation["total"] = dataList["totalElements"];
        jsonValue["content"] = dataList["content"];
        jsonValue["totalElements"] = dataList["totalElements"];
        jsonValue["pageNation"] = pageNation;

        this.handleState(jsonValue);
      }
    });
  };

  fnImageSet = (exeCommand) => {
    const iconObject = {
      disk: iconDisk,
      doc: iconDoc,
      hwp: iconHwp,
      mp3: iconMp3,
      pdf: iconPdf,
      wav: iconWav,
      xls: iconXls,
    };

    return CM.cfnIsNotEmpty(exeCommand) ? iconObject.hasOwnProperty(exeCommand) ? <img src={iconObject[exeCommand]} alt="" /> : <img src={iconObject["disk"]} alt="" /> : "";
  };

  render() {
    return (
      <div>
        <div className="search-area">
          <div className="block">
            <label className="label-l">검색어입력</label>
            <Select native name="searchProperty" value={this.state.searchProperty} onChange={this.handleSelectBox} inputProps={{ "data-testid": "support-pds-select-searchProperty" }}>
              {searchPropertyList.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
            <TextField
              className="w250"
              value={this.state.searchContents}
              onChange={this.handleInputChange}
              onKeyUp={this.handleSearKeyUp.bind(this)}
              name="searchContents"
              data-testid="support-pds-input-searchContents"
            />
            <button className="search-button" data-testid="support-pds-button-search" onClick={() => this.fnSearch()}>
              검색
            </button>
          </div>
        </div>
        <div className="table-top-area">
          <CF.TotalCountForm totalElements={this.state.totalElements} />
        </div>
        <Table>
          {CM.cfnCompColGroup(["80px", "auto", "200px", "120px"])}
          <TableForm.compServerSortTableHead
            useCheckbox={false}
            arrData={[
              { id: "articleNo", label: "번호", sortable: false },
              { id: "title", label: "제목", sortable: false },
              { id: "attachedFileName", label: "첨부파일", sortable: false },
              { id: "createdDatetime", label: "등록일", sortable: true },
            ]}
            searchRequest={this.state.searchRequest}
            handleSortProperty={this.handleSortProperty}
            tableSortLabelDataTestId={"support-list-pds-head-sortLabel"}
          />
          <TableBody>
            {this.state.content.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={5} />
            ) : (
              this.state.content.map((data, index) => {
                return data ? (
                  <TableRow key={index} hover className="show-detail" onClick={(event) => this.openModalHandler(event, index)}>
                    <TableCell align="center" data-testid="support-pds-list-articleNo">
                      {data.articleNo}
                    </TableCell>
                    <TableCell align="left">{data.title}</TableCell>
                    <TableCell align="center" hover="true" onClick={(event) => this.attachedFileDownload(index)}>
                      {this.fnImageSet(data.attachedFileName.split(".")[1])}
                      <div className="hidden">{data.attachedFileName.indexOf("/") !== -1 ? data.attachedFileName.split("/")[data.attachedFileName.split("/").length - 1] : data.attachedFileName}</div>
                    </TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(data.createdDatetime, "yyyyMMdd")}</TableCell>
                  </TableRow>
                ) : null;
              })
            )}
          </TableBody>
        </Table>
        <Pagination
          className="centered"
          limit={this.state.pagination.rowsPerPage}
          offset={this.state.pagination.offset}
          total={this.state.pagination.total}
          onClick={(e, offset, page) => this.handleOffsetChange(offset, page)}
          reduced={true}
          centerRipple={false}
          disableFocusRipple={true}
          disableRipple={true}
        />
        <Modal open={this.state.open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" data-testid="open-modal">
          <div
            className="paper"
            style={{
              height: "730px",
            }}>
            <div className="inner">
              <div className="modal-top-area">
                <Button className="fr btn-close" onClick={this.handleClose} data-testid="close-modal">
                  {""}
                </Button>
              </div>
              <h3>자료실</h3>
              <div>
                <Table>
                  {CM.cfnCompColGroup(["15%", "auto"])}
                  {this.state.content.length > 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th">
                          제목
                        </TableCell>
                        <TableCell className="td">{this.state.content[this.state.selectedIndex].title}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="th">
                          등록일
                        </TableCell>
                        <TableCell className="td">{CM.cfnDateFormat(this.state.content[this.state.selectedIndex].createdDatetime, "yyyyMMdd")}</TableCell>
                      </TableRow>
                      <TableRow style={{ height: "300px", overFlow: "auto" }}>
                        <TableCell align="center" className="th">
                          내용
                        </TableCell>
                        <TableCell className="td" dangerouslySetInnerHTML={{ __html: this.state.content[this.state.selectedIndex].contents.replace(/(\r\n|\n\r|\r|\n)/g, "<br>") }} />
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="th">
                          첨부파일
                        </TableCell>
                        {this.state.content[this.state.selectedIndex].attachedFileName ? (
                          <TableCell align="left" hover className="show-detail" onClick={(event) => this.attachedFileDownload(this.state.selectedIndex)}>
                            {this.fnImageSet(this.state.content[this.state.selectedIndex].attachedFileName.split(".")[1])}
                            {this.state.content[this.state.selectedIndex].attachedFileName.indexOf("/") !== -1
                              ? this.state.content[this.state.selectedIndex].attachedFileName.split("/")[this.state.content[this.state.selectedIndex].attachedFileName.split("/").length - 1]
                              : this.state.content[this.state.selectedIndex].attachedFileName}
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                      </TableRow>
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Pds;
