import { Button, Modal, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import "../../../styles/extraService.scss";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

const SendSmsSendHistoryModal = (props) => {
  const { open, setOpen, smsInfo } = props;

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState({ pageNumber: 0, pageSize: 5, sortDirection: "DESC", sortProperty: "customerName", smsStatusProperty: "" }); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 트리거
  const [contentList, setContentList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CM.cfnPaginationData());
  const [summaryInfo, setSummaryInfo] = useState([
    { key: "trSenddate", label: "발송일시", value: 0 },
    { key: "messageType", label: "발송구분", value: 0 },
    { key: "totalSent", label: "발송건수", value: 0 },
    { key: "totalSuccess", label: "성공건수", value: 0 },
    { key: "totalFailure", label: "실패건수", value: 0 },
    { key: "totalRemaining", label: "전송중", value: 0 },
  ]); // 요약

  const handleSummaryInfoChange = () => {
    setSummaryInfo((data) =>
      data.map((element) =>
        element.key === "trSenddate"
          ? { ...element, value: CM.cfnDateFormat(smsInfo.trSenddate, "yyyyMMddhhmmss") }
          : element.key === "messageType"
          ? { ...element, value: fnMatchMsgType(smsInfo.messageType) }
          : element.key === "totalSent"
          ? { ...element, value: smsInfo.totalSent }
          : element.key === "totalSuccess"
          ? { ...element, value: smsInfo.totalSuccess }
          : element.key === "totalFailure"
          ? { ...element, value: smsInfo.totalFailure }
          : element.key === "totalRemaining"
          ? { ...element, value: smsInfo.totalRemaining }
          : element
      )
    );
  };

  /*
   * @desc 출금요청 추가대상 목록 조회 파라미터 생성 함수
   */
  const axiosList = useCallback(() => {
    // axios request
    return new Promise((resolve) => {
      const url = "api/extraservice/messages/details";
      let queryString =
        "?pageNumber=" +
        (searchRequest.pageNumber || 0) +
        "&pageSize=" +
        (searchRequest.pageSize || 5) +
        "&sentDateTime=" +
        smsInfo.trSenddate +
        "&sortDirection=" +
        (pagination.sortDirection || "DESC") +
        "&sortProperty=" +
        (pagination.sortProperty || "customerName") +
        "&smsStatusProperty=" +
        searchRequest.smsStatusProperty;
      CM.cfnAxios(url + queryString, "get", "", (status, responseBottomList) => {
        setContentList(responseBottomList.content);
        setPagination(CM.cfnSetPagination(responseBottomList));
      });
    });
  }, [searchRequest.pageSize, pagination.sortDirection, pagination.sortProperty, searchRequest.pageNumber, searchRequest.smsStatusProperty, smsInfo.trSenddate]);

  // 테이블 데이터 검색
  useEffect(() => {
    if (open && searchButton) {
      setSearchButton(false);
      axiosList();
      handleSummaryInfoChange();
    } else if (!open) {
      setSearchButton(true);
      setPagination(CM.cfnPaginationData());
      setSearchRequest({ pageNumber: 0, pageSize: 5, sortDirection: "DESC", sortProperty: "customerName", smsStatusProperty: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, searchButton]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchButton(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setSearchRequest((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchButton(true);
  };

  // 요약 정보를 클릭했을 때 동작
  const handleSummaryInfoClick = (key) => {
    setSearchRequest((data) => ({
      ...data,
      smsStatusProperty: key,
    }));
    setSearchButton(true);
  };

  const fnMatchMsgType = (msgType) => {
    switch (msgType) {
      case "CMS_WITHDRAWAL":
        return "CMS출금";
      case "FREESTYLE":
        return "자유형";
      case "CMS_DEPOSIT":
        return "CMS입금";
      case "ETC":
        return "기타";
      default:
        return "";
    }
  };
  //재전송
  const reSendSms = (reSendSmsParam) => {
    const url = "api/extraservice/messages";
    CM.cfnAxios(url, "post", [{ ...reSendSmsParam, messageSendingTime: reSendSmsParam.trSenddate }], (status, responseTopListData) => {
      if (status.status !== 200) {
        CM.cfnAlert(status.statusText);
        return false;
      }
      CM.cfnAlert(responseTopListData);
    });
  };

  // 엑셀 저장
  const downloadExcel = () => {
    let url =
      "api/extraservice/messages/details/excel?sentDateTime=" +
      smsInfo.trSenddate +
      "&sortDirection=" +
      (searchRequest.sortDirection || "ASC") +
      "&sortProperty=" +
      (searchRequest.sortProperty || "customerName") +
      "&smsStatusProperty=" +
      searchRequest.smsStatusProperty;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  // 화면 렌더
  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          height: "730px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => setOpen(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>SMS 발송내역</h3>
          <div className="inforbox">
            <ul>
              <li>재전송을 클릭하면 발송내역 중 실패한 SMS만 다시 전송되며, 재전송을 클릭한 경우 발송내역을 다시 확인하시기 바랍니다.</li>
            </ul>
          </div>
          <div className="summary-sms-area" align="center">
            {summaryInfo.map((summary, index) => {
              if (summary.key === "trSenddate" || summary.key === "messageType") {
                return (
                  <div className={"text-graph"} style={{ minWidth: "170px" }} key={index}>
                    <label>{summary.label}</label>
                    <span>{summary.value}</span>
                  </div>
                );
              } else {
                return (
                  <div
                    className={"text-graph"}
                    style={{ minWidth: "170px" }}
                    key={index}
                    onClick={() => handleSummaryInfoClick(summary.key)}
                    data-testid={`${props["data-testid"]}-summary-info-${index}`}>
                    <label>{summary.label}</label>
                    <span>{CM.cfnAddComma(summary.value)}</span>
                  </div>
                );
              }
            })}
          </div>
          <div className="table-top-area h30">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} customProps={{ inputProps: { "data-testid": "support-faq-rowPerPage-select" } }} />
            <button className="btn-m fr table-top-button" data-testid="sms-details-save-list-button" onClick={downloadExcel}>
              엑셀저장
            </button>
          </div>
          <div>
            <Table>
              {CM.cfnCompColGroup(["8%", "13%", "13%", "13%", "auto", "13%", "10%"])}
              <TableHead className="table-head-line">
                <TableRow key="headRow">
                  <TableCell className="th">순번</TableCell>
                  <TableCell className="th">고객명</TableCell>
                  <TableCell className="th">고객구분</TableCell>
                  <TableCell className="th">휴대전화번호</TableCell>
                  <TableCell className="th">발송메시지</TableCell>
                  <TableCell className="th">발송결과</TableCell>
                  <TableCell className="th">재발송</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {contentList.length === 0 ? (
                  <TableForm.compEmptyTableRow colSpan={7} />
                ) : (
                  contentList.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center" className="td">
                          {searchRequest.pageNumber * searchRequest.pageSize + index + 1}
                        </TableCell>
                        <TableCell align="center">{row["customerName"]}</TableCell>
                        <TableCell align="center">{row["customerGroupName"]}</TableCell>
                        <TableCell align="center">{CM.cfnIdentificationNoFormat(row["customerMobile"])}</TableCell>
                        <TableCell align="center">{row["messageSentMessage"]}</TableCell>
                        <TableCell align="center">{row["messageSendingResultMsg"]}</TableCell>
                        <TableCell align="center">
                          <button className="btn-s" onClick={() => reSendSms(row)} data-testid="search-send-sms-btn">
                            재발송
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <CF.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      </div>
    </Modal>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(SendSmsSendHistoryModal));
