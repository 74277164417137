import { Checkbox, FormControlLabel, FormGroup, Input, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import * as CM from "../../common/Common";
import { DatePickerForm, SelectForm, TooltipForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsPaymentData";

/*
 * @desc  CMS 입금 청구 상단 안내 문구
 */
export const CmsPaymenttRequestInfo = (props) => {
  return (
    <div className="inforbox">
      {props.cmsRequestStatus === 2 ? (
        <ul>
          <li>입금청구 대상 생성이후, 입금청구 버튼을 눌러야 입금작업이 완료됩니다.</li>
          <li>입금청구 전송 마감시각은 입금청구 전영업일 14시입니다.</li>
          <li>입금청구 작업 중에 중복 로그인 등으로 고객기본정보 또는 지급고객정보를 수정하지 마시기 바랍니다.</li>
        </ul>
      ) : (
        <ul>
          <li>이용기관의 지급계좌 잔액이 입금총액과 수수료 합산금액보다 적을 경우 처리되지 않습니다.</li>
          <li>다수의 내역을 수정하려면 "엑셀저장" 및 "일괄제외(엑셀)" 기능을 이용하시기 바랍니다.</li>
          <li>이때 자체 암호화프로그램을 사용하시는 경우 반드시 암호화 해제 후 업로드하시기 바랍니다.</li>
        </ul>
      )}
    </div>
  );
};

/*
 * @desc  입금대상 생성 컴포넌트
 */
export const CmsPaymentGeneration = (props) => {
  const calculateMaxDate = () => {
    let date = new Date();
    return new Date(date.setMonth(date.getMonth() + 2));
  };

  const maxDate = calculateMaxDate();
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["120px", "auto", "120px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">
              고객계좌
              <br />
              입금청구일
            </TableCell>
            <TableCell className="td">
              <DatePickerForm
                disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                value={props.cmsRequestSummary.transactionDate}
                handleChange={props.handleCmsRequestInfoChange("transactionDate")}
                className="w160 receiptRequestResultDatePicker"
                customProps={{
                  placeholder: "YYYY.MM.DD",
                  inputProps: {
                    "data-testid": "cms-request-date",
                  },
                  minDate: props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? undefined : new Date(),
                  shouldDisableDate: (day) => day.getDay() === 0 || day.getDay() === 6,
                  maxDate,
                }}
              />
            </TableCell>
            <TableCell className="th">
              입금청구대상
              <TooltipForm contents="⧠ 청구일분 : 지급일이 도래하여 입금 청구일에 해당되는 고객<br/>⧠ 미청구분 : 지급일이 지났으나 입금청구를 하지 않은 고객<br/>⧠ 미입금분 : 지급일에 입금청구 하였으나 입금실패된 고객" />
            </TableCell>
            <TableCell className="td">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                      checked={props.targetCheckbox.thisTime}
                      onChange={props.handleTargetCheckbox("thisTime")}
                      name="thisTime"
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "this-time-checkbox",
                      }}
                    />
                  }
                  label="청구일분"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                      checked={props.targetCheckbox.unasked}
                      onChange={props.handleTargetCheckbox("unasked")}
                      name="unasked"
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "unasked-checkbox",
                      }}
                    />
                  }
                  label="미청구분"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : props.cmsRequestSummary.reaskUnpaidLimit !== false ? false : true}
                      checked={props.targetCheckbox.unpaid}
                      onChange={props.handleTargetCheckbox("unpaid")}
                      name="unpaid"
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "unpaid-checkbox",
                      }}
                    />
                  }
                  label="미입금분"
                />
              </FormGroup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              입금방식구분
              <TooltipForm
                contents="- 통합입금 : 회차(월별) 구분 없이 동일 입금대상 건에 대하여 1건으로 통합되어 입금대상이 생성됩니다. (단, 입금대상 추가 건은 별도 건으로 생성)<br/>
            - 건별입금 : 동일 입금대상에 대해 회차(월별)로 분리하여 건별로 입금대상이 생성됩니다. (건별입금 청구시 각 건별로 은행수수료가 발생)"
              />
            </TableCell>
            <TableCell className="td" colSpan={3}>
              <RadioGroup
                name="targetIntegratedYn"
                value={String(props.cmsRequestSummary.targetIntegratedYn)}
                onChange={props.handleCmsRequestInfoChange("targetIntegratedYn")}
                disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                data-testid="target-integrated-radio"
                row>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="true"
                  label="통합입금"
                  disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="false"
                  label="건별입금"
                  disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                />
              </RadioGroup>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  입금 대상 컴포넌트
 */
export const CmsRequestTarget = (props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className="th">고객계좌 입금일</TableCell>
          <TableCell className="th">금액 합계</TableCell>
          <TableCell className="th">청구일분</TableCell>
          <TableCell className="th">미청구분</TableCell>
          <TableCell className="th">미입금분</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className="td" align="center">
            {CM.cfnDateFormat(CM.cfnConvertDateToString(props.cmsRequestSummary.transactionDate)) /*고객계좌입금일*/}
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingAmount) /*금액 합계*/}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingThisTimeAmount) /*청구일분*/}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingPreviouslyUnaskedAmount) /*미청구분*/}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingUnpaidAmount) /*미입금분*/}원
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/*
 * @desc  입금대상 목록 검색조건 컴포넌트
 */
export const CmsRequestSearchForm = (props) => {
  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">고객구분</label>
        <Select native name="groupUniqueKey" value={props.searchRequest.groupUniqueKey} onChange={props.handleRequestSearchChange("groupUniqueKey")}>
          <option value="">전체</option>
          {props.customerGroupOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <span className="label-l">자금종류</span>
        <Select
          native
          name="capitalUniqueKey"
          value={props.searchRequest.capitalUniqueKey}
          onChange={props.handleRequestSearchChange("capitalUniqueKey")}
          inputProps={{
            "data-testid": "cms-payment-search-capitalSelect",
          }}>
          <option value="">전체</option>
          {props.capitalOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <span className="label-l">검색어입력</span>
        <SelectForm
          value={props.searchRequest.term}
          handleChange={props.handleRequestSearchChange("term")}
          name="term"
          arrayOption={CmsData.targetSearchKeyword}
          optionValue={"value"}
          optionLabel={"label"}
        />
        <Input
          value={props.searchRequest.keyword}
          onChange={props.handleRequestSearchChange("keyword")}
          onKeyUp={props.handleSearchKeyUp}
          name="keyword"
          className="w120"
          data-testid="cms-request-keyword"
        />
        <button className="search-button" onClick={(e) => props.handleSearchButtonChange(true)} data-testid="cms-payment-search-button">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  입금대상 목록 컴포넌트
 */
export const CmsRequestTable = (props) => {
  return (
    <Table>
      {CM.cfnCompColGroup(["9%", "9%", "7%", "7%", "7%", "7%", "8%", "8%", "8%", "8%", "8%", "auto", "7%"])}
      <TableForm.compServerSortDoubleRowTableHead
        useCheckbox={false}
        rowOne={[
          { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
          { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true, rowSpan: 2 },
          { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true, rowSpan: 2 },
          { id: "CAPITAL", label: "자금종류", sortable: true, rowSpan: 2 },
          { id: "ACCOUNT_BANK", label: "금융기관", sortable: false, rowSpan: 2 },
          { id: "ACCOUNT_NO", label: "계좌번호", sortable: false, rowSpan: 2 },
          { id: "TARGET_Y4MM", label: "지급내역\n기간(갯수)", sortable: false, rowSpan: 2 },
          {
            id: "CMS_PAYMENT_TARGET",
            label: "입금청구금액",
            sortable: false,
            colSpan: 5,
          },
          { id: "EXCLUDE", label: "제외", sortable: false, rowSpan: 2 },
        ]}
        rowTwo={[
          { id: "TOTAL_ASKING_AMOUNT", label: "합계", sortable: true },
          { id: "THIS_TIME_AMOUNT", label: "청구일분", sortable: false },
          {
            id: "PREVIOUSLY_UNASKED_AMOUNT",
            label: "미청구분",
            sortable: false,
          },
          { id: "ASKING_DELAY_FEE", label: "미입급분", sortable: false },
          { id: "BANKBOOK_CONTENTS", label: "통장\n기재내역", sortable: false },
        ]}
        searchRequest={props.searchRequest}
        handleSortProperty={props.handleSortProperty}
        tableSortLabelDataTestId="cmsPaymentRequestTable"
      />
      <TableBody>
        {props.cmsRequestList.length === 0 ? (
          <TableForm.compEmptyTableRow colSpan={13} />
        ) : (
          props.cmsRequestList.map((row, index) => {
            return (
              <TableRow key={index} hover onClick={() => props.handleClickedRow && props.handleClickedRow(row)}>
                <TableCell align="center">{row.customer.customerName}</TableCell>
                <TableCell align="center">{CM.cfnIdentificationNoFormat(row.customer.identificationNo)}</TableCell>
                <TableCell align="center">{row.customer.customerGroup.customerGroupName /*고객구분*/}</TableCell>
                <TableCell align="center">{row.contract.capital.capitalName}</TableCell>
                <TableCell align="center">{CM.cfnMatchBankName(row.account.accountBankCode, props.financialInstitutes)}</TableCell>
                <TableCell align="center">{CM.cfnMasking(row.account.accountNo)}</TableCell>
                <TableCell align="center" className="multiLine-cell">
                  {CM.cfnDateFormat(row.targetSourceStartY4mm, "yyyyMM") +
                    (row.targetSourceStartY4mm === row.targetSourceEndY4mm ? "" : " ~\n" + CM.cfnDateFormat(row.targetSourceEndY4mm, "yyyyMM")) +
                    "(" +
                    row.numberOfIncludedSources +
                    ")"}
                </TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount) /*입금대상 금액합계*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.thisTimeAmount) /*입금청구일분*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.previouslyUnaskedAmount) /*미청구분*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.askingUnpaidAmount) /*미입급분*/}원</TableCell>
                <TableCell align="center">{row.bankbookContents /*통장기재내역*/}</TableCell>
                <TableCell align="center">
                  <button
                    className="btn-s"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleExcludeRow(row);
                    }}
                    data-testid={`exclude-button-${index}`}
                    disabled={row.handlingExclusion || false}>
                    제외
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};
