import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";

// 부가서비스 > 현금영수증 > 현금영수증 발행내역 > 고객 상세 정보 modal form
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabIssuedModalDetail = (props) => {
  return (
    <Modal open={props.modalOpen}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpen(false)} data-testid="cashbill-issued-detail-modal-close">
              {""}
            </Button>
          </div>
          <h3>현금영수증 발행내역(고객별)</h3>
          <div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="th">고객명</TableCell>
                  <TableCell className="td">{props.list[0].customerName}</TableCell>
                  <TableCell className="th">신분확인번호</TableCell>
                  <TableCell className="td">{CM.cfnIdentificationNoFormat(props.list[0].cashbillIdentificationNo)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div>
            <Table>
              <TableForm.compTableHead arrData={["발행일자", "발행방법", "발행상태", "발행금액", "비고"]} />
              <TableBody>
                {props.list.map((target, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="center">{CM.cfnDateFormat(target.issuedDate, "yyyyMMdd")}</TableCell>
                      <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionType(target.issueType)}</TableCell>
                      <TableCell align="center">{target.returnCodeMsg}</TableCell>
                      <TableCell align="center">{target.issuedAmount}</TableCell>
                      <TableCell align="center">{target.remark}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CashbillTabIssuedModalDetail;
