import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "../../../styles/extraService.scss";
import * as CM from "../../common/Common";
import { PaginationForm, RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import LabelData from "./MailLabelPrintData";
import PrintModal from "./MailLabelPrintModal";
import PrintPreviewModal from "./MailLabelPrintPreviewModal";

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const { searchRequest, handleSearchFormChange, customerGroups, staffs, capitals } = props;

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">인쇄대상</label>{" "}
        <FormControl component="fieldset">
          <FormGroup aria-label="position" name="position" row>
            <FormControlLabel
              value={searchRequest.capitalType}
              control={<Checkbox checked={searchRequest.capitalType === ""} value="" onChange={handleSearchFormChange("capitalType")} color="primary" />}
              label="전체"
              data-testid="select-all-customer"
            />
            <RadioGroup value={searchRequest.capitalType} onChange={handleSearchFormChange("capitalType")} data-testid="capital-type-receipt" row>
              <FormControlLabel checked={searchRequest.capitalType === "RECEIPT"} value="RECEIPT" control={<Radio color="primary" />} label="수납고객" data-testid="select-receipt-customer" />
              <FormControlLabel checked={searchRequest.capitalType === "PAYMENT"} value="PAYMENT" control={<Radio color="primary" />} label="지급고객" data-testid="select-payment-customer" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <label className="label-l">고객구분</label>
        <Select native value={searchRequest.customerGroupNameKey} onChange={handleSearchFormChange("customerGroupName")} inputProps={{ "data-testid": "customer-group-select" }}>
          <option value="">전체</option>
          {CM.cfnIsNotEmpty(customerGroups) &&
            customerGroups.map((row, index) => {
              return (
                <option key={index} value={row.uniqueKey}>
                  {row.customerGroupName}
                </option>
              );
            })}
        </Select>
        <label className="label-l">고객담당자</label>
        <Select native value={searchRequest.customerStaffNameKey} onChange={handleSearchFormChange("customerStaffName")} inputProps={{ "data-testid": "staff-select" }}>
          <option value="">전체</option>
          {CM.cfnIsNotEmpty(staffs) &&
            staffs.map((row, index) => {
              return (
                <option key={index} value={row.uniqueKey}>
                  {row.staffName}
                </option>
              );
            })}
        </Select>
        {searchRequest.capitalType === "" ? "" :
            <> <label className="label-l">자금종류</label>
            <Select native value={searchRequest.capitalNameKey} onChange={handleSearchFormChange("capitalName")}
                    inputProps={{"data-testid": "receipt-capital-select"}}>
              <option value="">전체</option>
              {CM.cfnIsNotEmpty(capitals) &&
              capitals.map((row) => {
                return (
                    <option key={row.key} value={row.value}>
                      {row.label}
                    </option>
                );
              })}
            </Select> </>
            }
        <button className="btn-s" onClick={() => fnSearch()}>
          인쇄 대상 조회
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  라벨지 인쇄 방식 설정 컴포넌트
 */
const PrintSettingsForm = (props) => {
  const { printSettings, handleSettingsChange, setPreviewOpen } = props;

  /*
   * @desc  미리보기 버튼 클릭 이벤트 핸들러
   */
  const handleClickPreview = () => {
    setPreviewOpen(true);
  };

  return (
    <div className="download-area">
      <label className="label-l">규격</label>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" name="position" row>
          <RadioGroup name="size" value={printSettings.size} onChange={handleSettingsChange("size")} data-testid="print-settings-size" row>
            <FormControlLabel value="16" control={<Radio color="primary" />} label="16칸(2x8)" />
            <FormControlLabel value="24" control={<Radio color="primary" />} label="24칸(3x8)" />
            <FormControlLabel value="40" control={<Radio color="primary" />} label="40칸(4x10)" />
          </RadioGroup>
        </FormGroup>
      </FormControl>
      <br />
      <label className="label-l">인쇄항목</label>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" name="position" row>
          <FormControlLabel
            value={printSettings.printName}
            control={<Checkbox checked={printSettings.printName} onChange={handleSettingsChange("printName")} color="primary" />}
            label="고객명"
            data-testid="print-customer-name"
          />
          <FormControlLabel
            value={printSettings.printZipCode}
            control={<Checkbox checked={printSettings.printZipCode} onChange={handleSettingsChange("printZipCode")} color="primary" />}
            label="우편번호"
            data-testid="print-zip-code"
          />
          <FormControlLabel
            value={printSettings.printAddress}
            control={<Checkbox checked={printSettings.printAddress} onChange={handleSettingsChange("printAddress")} color="primary" />}
            label="주소"
            data-testid="print-customer-address"
          />
          <FormControlLabel
            value={printSettings.printMobileNo}
            control={<Checkbox checked={printSettings.printMobileNo} onChange={handleSettingsChange("printMobileNo")} color="primary" />}
            label="휴대전화번호"
            data-testid="print-customer-mobile"
          />
          <FormControlLabel
            value={printSettings.printTelNo}
            control={<Checkbox checked={printSettings.printTelNo} onChange={handleSettingsChange("printTelNo")} color="primary" />}
            label="유선전화번호"
            data-testid="print-customer-tel"
          />
        </FormGroup>
      </FormControl>
      <button className="btn-m" onClick={handleClickPreview} data-testid="preview-open">
        미리보기
      </button>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of props.targetList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const targetLength = props.targetList.length;

    if (checkAllFlag === targetLength && targetLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [props.targetList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.targetList];
    cloneData[index][key] = value;
    props.handleTargetListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.handleTargetListChange((target) => target.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["50px", "150px", "150px", "150px", "100px", "auto"])}
        <TableForm.compServerSortTableHead
          checked={checkAllRow}
          useCheckbox={true}
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "customerName", label: "고객명", sortable: true },
            { id: "customerMobile", label: "휴대전화번호", sortable: true },
            { id: "customerTelephone", label: "유선전화번호", sortable: true },
            { id: "customerZipCode", label: "우편번호", sortable: true },
            { id: "customerAddress", label: "주소", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.targetList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={6} />
          ) : (
            props.targetList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center" name="?" index="0">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`selectCheckbox-${index}`}
                      onChange={handleCheckChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(row.customerMobile)}</TableCell>
                  <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(row.customerTelephone)}</TableCell>
                  <TableCell align="center">{row.customerZipCode}</TableCell>
                  <TableCell align="center">
                    {row.customerAddress} {row.customerAddressDetail}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const LabelPrintTargetList = (props) => {
  const store = props.store;

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(LabelData.searchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalTargetList, setOriginalTargetList] = useState([]); // 서버로 부터 전달받은 original object
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [selectedRows, setSelectedRows] = useState([]); //선택된 목록
  const [pagination, setPagination] = useState(LabelData.paginationData());

  //라벨지 인쇄 방식 제어 state 선언
  const [printSettings, setPrintSettings] = useState(LabelData.printSettings);

  //검색조건 셀렉트박스 옵션 제어 state 선언
  const [customerGroups, setCustomerGroups] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [capitals, setCapitals] = useState([]);
  const [paymentCapitals, setPaymentCapitals] = useState([]);
  const [receiptCapitals, setReceiptCapitals] = useState([]);
  const [allCapitals, setAllCapitals] = useState([]);

  //모달 제어를 위한 state 선언
  const [previewOpen, setPreviewOpen] = useState(false); //미리보기
  const [printOpen, setPrintOpen] = useState(false); //라벨지 인쇄

  //검색조건 내 셀렉트 박스 옵션 조회
  useEffect(() => {
    /*
     * @desc    자금종류 셀렉트박스 옵션 요소 생성 함수
     */
    const fnMakeCapitalOptions = (list) => {
      let tempArray = [];

      if (CM.cfnIsNotEmpty(list)) {
        list.forEach((row) => {
          tempArray.push({
            label: row.capitalName,
            value: row.uniqueKey,
            key: row.uniqueKey,
            capitalType: row.capitalType,
          });
        });
      }

      return tempArray;
    };

    const startStoreAxios = async () => {
      // 고객구분 option data 설정
      const customerGroupList = await store.axiosCustomerGroup();
      setCustomerGroups(customerGroupList);

      //고객담당자 option data 설정
      const resultStaffs = await store.axiosStaffs();
      setStaffs(resultStaffs);

      //자금종류 option data 설정
      const tempAllCapitals = await store.axiosCapitals();
      const capitalList = fnMakeCapitalOptions(tempAllCapitals);
      setAllCapitals(capitalList);

      //지급자금종류 option data 설정
      const tempPaymentCapitals = await store.axiosPaymentCapitals();
      setPaymentCapitals(fnMakeCapitalOptions(tempPaymentCapitals));

      //수납자금종류 option data 설정
      const tempReceiptCapitals = await store.axiosReceiptCapitals();
      setReceiptCapitals(fnMakeCapitalOptions(tempReceiptCapitals));

      setCapitals(capitalList);
    };

    startStoreAxios();
  }, [store]);

  // 테이블 데이터 검색
  useEffect(() => {
    // axios request
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/label-print?capitalType=${search.capitalType}&groupUniqueKey=${search.customerGroupNameKey}&staffUniqueKey=${search.customerStaffNameKey}&capitalUniqueKey=${search.capitalNameKey}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

        /*
        for (let capital of capitals) {
          if (search.capitalName === capital.label) {
            const capitalType = capital.capitalType;
            if (capitalType === "PAYMENT") {
              url += `&paymentCapitalName=${search.capitalName}`;
            } else {
              //RECEIPT
              url += `&receiptCapitalName=${search.capitalName}`;
            }
          }
        }
         */

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      // 체크 옵션 추가
      for (const element of resultList) {
        element._checked = false;
      }

      setOriginalTargetList(resultData);
      handleTargetListChange(resultList);
      setPagination(LabelData.paginationData(resultData));
      handleSearchButtonChange(false);
    };

    if (searchButton === true) startAxios(searchRequest);
  }, [searchRequest, searchButton, capitals]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (e.target.type === "select-one") {
      const index = e.nativeEvent.target.selectedIndex;
      const key = `${name}Key`;
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value !== "" ? e.nativeEvent.target[index].text : "",
        [key]: e.target.value,
      });
    } else {
      if (name === "capitalType" && e.target.value === "PAYMENT") {
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
          capitalName : "",
          capitalNameKey : "",
        });
        //지급자금종류 option data 설정
        setCapitals(paymentCapitals);
      } else if (name === "capitalType" && e.target.value === "RECEIPT"){
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
          capitalName : "",
          capitalNameKey : "",
        });
        //수납자금종류 option data 설정
        setCapitals(receiptCapitals);
      } else if (name === "capitalType"){
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
          capitalName : "",
          capitalNameKey : "",
        });
        setCapitals(allCapitals);
      } else {
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
        });
      }
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 고객 목록 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc  라벨지 인쇄 방식 변경 이벤트 핸들러
   */
  const handleSettingsChange = (name) => (e) => {
    if (e.target.type === "radio") {
      setPrintSettings({
        ...printSettings,
        [name]: e.target.value,
      });
    } else if (e.target.type === "checkbox") {
      setPrintSettings({
        ...printSettings,
        [name]: e.target.checked,
      });
    }
  };

  /*
   * @desc    라벨지 인쇄 버튼 클릭 이벤트 핸들러
   */
  const handleLabelPrint = () => {
    if (fnCheckValidation()) {
      const tempArray = [];

      for (const customer of targetList) {
        if (customer._checked) {
          tempArray.push(customer);
        }
      }
      setSelectedRows(tempArray);
      setPrintOpen(true);
    }
  };

  /*
   * @desc    validation 체크 함수
   */
  const fnCheckValidation = () => {
    const tempArray = [];

    for (const customer of targetList) {
      if (customer._checked) {
        tempArray.push(customer);
      }
    }

    //체크된 행 없을 시
    if (CM.cfnIsEmpty(tempArray)) {
      CM.cfnAlert("선택된 고객이 없습니다.");
      return false;
    }

    return true;
  };

  // 화면 렌더
  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>고객주소를 프린터로 인쇄하여 우편물 등에 부착할 수 있도록 지원하는 서비스 입니다.</li>
          <li>
            라벨지 인쇄를 원하는 고객이 목록에 없다면{" "}
            <Link to="/customer/customerInformationList" className="link">
              고객기본정보
            </Link>
            에서 해당 고객의 기본정보에 우편번호, 주소를 입력하시기 바랍니다.
          </li>
        </ul>
      </div>
      <h4>라벨지 인쇄 방식</h4>
      <PrintSettingsForm printSettings={printSettings} handleSettingsChange={handleSettingsChange} setPreviewOpen={setPreviewOpen} />
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        customerGroups={customerGroups}
        staffs={staffs}
        capitals={capitals}
      />
      <div className="table-top-area">
        <TotalCountForm totalElements={originalTargetList.totalElements || 0} />
        <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
        <div className="btn-l table-top-button" onClick={handleLabelPrint} data-testid="label-print">
          라벨지 인쇄
        </div>
      </div>
      <ListForm targetList={targetList} handleTargetListChange={handleTargetListChange} searchRequest={searchRequest} handleSortProperty={handleSortProperty} />
      <PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <PrintPreviewModal open={previewOpen} setOpen={setPreviewOpen} printSettings={printSettings} setPrintSettings={setPrintSettings} />
      <PrintModal open={printOpen} setOpen={setPrintOpen} printSettings={printSettings} targetList={selectedRows} />
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(LabelPrintTargetList));
