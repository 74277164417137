import * as CM from "../../common/Common";

const PaymentCustomerData = {
  //지급고객등록 시 default value
  paymentDefaultValue: {
    account: {
      accountBankCode: "",
      accountNo: "",
      depositorIdentificationNo: "",
      depositorName: "",
      registeredDate: "",
      unregisteredDate: "",
    },
    askStatus: "",
    bankbookContents: "",
    bankbookPostfix: "",
    bankbookPostfixType: "",
    capital: {
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
      capitalName: "",
      capitalType: "",
    },
    contractStatus: "",
    customer: {
      cashbillIdentificationNo: "",
      createdDate: "",
      customerAddress: "",
      customerAddressDetail: "",
      customerDetailInfo: {
        결혼기념일: "",
        직위: "",
      },
      customerEmail: null,
      customerFax: "",
      customerGroup: {
        uniqueKey: "",
        customerGroupName: "",
      },
      customerMobile: "",
      customerName: "",
      customerTelephone: "",
      customerType: "",
      customerZipCode: "",
      identificationNo: "",
      paymentContractCount: 0,
      paymentContractList: [null],
      receiptContractCount: 0,
      receiptContractList: [],
      remark: "",
      roadAddressYn: true,
      staff: {
        uniqueKey: "",
        billingManagerYn: true,
        branch: {
          uniqueKey: "",
          branchName: "",
        },
        cashbillManagerYn: true,
        cmsManagerYn: true,
        loginYn: true,
        masterYn: true,
        smsTargetYn: true,
        staffBirthDate: "",
        staffDepartment: "",
        staffEmail: "",
        staffFax: "",
        staffMobile: "",
        staffName: "",
        staffPosition: "",
        staffTelephone: "",
        trusbillManagerYn: true,
      },
    },
    expectedApplyY4mm: "",
    payAmount: null,
    payAmountType: "VARIABLE",
    payEndY4mm: "999912",
    payPeriod: "MONTHLY",
    paySpecifiedDay: "",
    payStartY4mm: `${new Date().getFullYear()}${new Date().getMonth() < 9 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`,
    sendForAskingDeposit: false,
    sendForFailureOnDeposit: false,
    sendForSuccessOnDeposit: false,
    totalNumberOfPayment: 0,
    transactionMethod: "CMS_DEPOSIT",
  },

  //선택된 자금종류에 해당하는 정보 반환
  fnMatchSelectedCapital: (target, list) => {
    let capital = {
      uniqueKey: "",
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
    };

    list.forEach((row) => {
      if (row.uniqueKey === target) capital = row;
    });

    return capital;
  },
  //자금종류 셀렉트 옵션 생성
  fnCreateCapitalOptions: (data) => {
    let tempArray = [];

    if (CM.cfnIsNotEmpty(data)) {
      data.forEach((row) => {
        if (row.capitalType === "PAYMENT")
          tempArray.push({
            label: row.capitalName,
            value: row.uniqueKey,
            key: row.uniqueKey,
          });
      });
    }

    return tempArray;
  },
  //yyyyMM 형태로 입력된 데이터를 연도와 월로 분리
  fnConvertY4mm: (y4mm, type) => {
    let val = "";

    if (type === "year") {
      val = y4mm.toString().substring(0, 4);
    } else if (type === "mm") {
      if (y4mm.toString().startsWith("9999")) {
        val = "";
      } else {
        val = y4mm.toString().substring(4);
      }
    }

    return val;
  },
  //지급시작월 & 회차별 지급내역 - 검색시작월의 년도 옵션 생성
  fnCreateStartYearOption: (payStartY4mm) => {
    const arrYear = [];
    const maxLoop = 10;
    const startYear = payStartY4mm ? Math.min(Number(payStartY4mm.substr(0, 4)), new Date().getFullYear()) : new Date().getFullYear();

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  },
  //지급종료월의 연도 옵션 생성
  fnCreateEndYearOption: (payEndY4mm) => {
    const arrYear = [];
    const maxLoop = payEndY4mm && payEndY4mm.substr(0, 4) !== "9999" ? Math.max(Number(payEndY4mm.substr(0, 4)) - new Date().getFullYear() + 1, 50) : 50;
    const startYear = new Date().getFullYear();

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  },
  //셀렉트 박스의 월별 옵션 생성
  fnCreateMonthOption: (paymentInfo) => {
    const arrMonth = [];
    const maxLoop = 13;

    let year = `${new Date().getFullYear().toString()}`;
    let currnetMM = `${new Date().getMonth() + 1}`;

    if (CM.cfnIsNotEmpty(paymentInfo) && CM.cfnIsNotEmpty(paymentInfo.expectedApplyY4mm) && paymentInfo.expectedApplyY4mm.toString().substring(0, 4) === year) {
      for (let i = currnetMM; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : `${i}`;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else {
      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : `${i}`;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    }

    return arrMonth;
  },
  //지급예정일 옵션 생성
  fnCreateDateOption: () => {
    const arrDate = [];
    const maxLoop = 32;

    for (let i = 0; i < maxLoop; i++) {
      let label = `${i}일`;
      let value = i < 10 ? `0${i}` : `${i}`;

      if (i === 0) {
        label = "지정일";
        value = "";
      }

      arrDate.push({
        label: label,
        value: value,
      });
    }

    return arrDate;
  },

  //회차별 지급내역 - 조회조건
  historySearchData: (uniqueKey) => {
    const today = new Date();

    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 2);
    const monthAfter = new Date(today.getFullYear(), today.getMonth() + 2);

    return {
      contractUniqueKey: uniqueKey,
      searchStartY4mm: monthAgo,
      searchEndY4mm: monthAfter,
    };
  },
  //회차별 지급내역 - askResult 및 askStatus 에 따른 지급상태명 반환
  fnAskStatus: (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.askStatus === "HOLDING") {
          return "미청구(지급보류)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.askStatus === "HOLDING") {
          return "지급일 미도래(지급보류)";
        } else {
          return "지급일 미도래";
        }
      case "UNPAID":
        if (row.askStatus === "HOLDING") {
          return "미납(지급보류)";
        } else {
          return "미납";
        }
      case "PAID":
        return "지급";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "지급보류";
      default:
        if (row.askResult === "ASKING" && row.numberOfAsking === 1) {
          return "출금중(최초출금)";
        } else if (row.askResult === "ASKING" && row.numberOfAsking > 1) {
          return "출금중(재출금)";
        }
    }
  },
  //회차별 지급내역 - 선택 회차 지급내역 변경 이력 처리구분명
  fnTransactionType: (transactionType) => {
    switch (transactionType) {
      case "EB21":
        return "익일출금";
      case "EC21":
        return "당일출금";
      case "DIRECTLY_PAID":
        return "직접수납";
      case "CHANGE_AMOUNT":
        return "금액변경";
      case "DELAY_FEE":
        return "연체료조정";
      case "LOSS_AND_DELAY_FEE":
        return "손실처리 및 연체료부과";
      case "REFUND":
        return "환불";
      case "TEMPORARY_ADDED":
        return "내역추가";
      case "ASK_TAXBILL":
        return "세금계산서청구";
      case "TAXBILL_CENTER_ERROR":
        return "세금계산서발행오류";
      case "ISSUE_TAXBILL":
        return "세금계산서발행";
      case "CANCEL_ASK_TAXBILL":
        return "세금계산서청구취소";
      case "CANCEL_ISSUE_TAXBILL":
        return "세금계산서발행취소";
      case "EXEMPT_ASK_TARGET":
        return "청구대상제외";
      case "CANCEL_EXEMPT_ASK_TARGET":
        return "청구대상제외취소";
      case "HOLD_OFF_ASKING":
        return "수납보류";
      case "CANCEL_HOLD_OFF_ASKING":
        return "수납보류취소";
      case "LOSS_AND_REMOVE":
        return "손실처리";
      case "NOTICE_ERROR":
        return "고지오류";
      case "STOP_NOTICING":
        return "고지중지";
      case "DELETED":
        return "삭제";
      default:
        return transactionType;
    }
  },
  //지급정보 저장 시 validation 체크 함수
  fnCheckValidation: (calledFrom, paymentInfo, selectRequiredInputs, noneDeadline, readOnlyFlag, selectedCustomer, customer, firstIdentificationNo) => {
    //필수값 입력 여부
    if (calledFrom === "CREATE") {
      //지급고객정보 등록 화면일 경우
      if (!readOnlyFlag && CM.cfnIsEmpty(selectedCustomer)) {
        //고객기본정보 화면에서 이동한 것이 아닐 경우
        if (CM.cfnIsEmpty(customer.customerName)) {
          CM.cfnAlert("고객명을 입력하여 주시기 바랍니다.");
          return false;
        }

        if (customer.customerType === "INDIVIDUAL" && (CM.cfnIsEmpty(firstIdentificationNo) || firstIdentificationNo.length !== 6)) {
          CM.cfnAlert("주민등록번호를 입력하여 주시기 바랍니다.");
          return false;
        }

        if (customer.customerType !== "INDIVIDUAL" && CM.cfnIsEmpty(customer.identificationNo)) {
          CM.cfnAlert("사업자등록번호를 입력하여 주시기 바랍니다.");
          return false;
        }
      }
    }

    if (CM.cfnIsEmpty(selectRequiredInputs.customerGroup)) {
      CM.cfnAlert("고객구분을 선택하세요.");
      return false;
    }

    if (CM.cfnIsEmpty(selectRequiredInputs.staff)) {
      CM.cfnAlert("고객관리담당자/고객담당지사를 선택하세요.");
      return false;
    }

    //지급정보 관련 체크
    if (CM.cfnIsEmpty(paymentInfo.transactionMethod)) {
      CM.cfnAlert("지급형태를 선택하여 주시기 바랍니다.");
      return false;
    }

    if (CM.cfnIsEmpty(paymentInfo.capital.uniqueKey)) {
      CM.cfnAlert("자금종류를 선택하여 주시기 바랍니다.");
      return false;
    }

    if (CM.cfnIsEmpty(paymentInfo.paySpecifiedDay)) {
      CM.cfnAlert("지급일을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (CM.cfnIsEmpty(paymentInfo.payAmountType)) {
      CM.cfnAlert("지급금액을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (paymentInfo.payAmountType === "FIXED" && CM.cfnIsEmpty(paymentInfo.payAmount)) {
      CM.cfnAlert("지금금액을 입력하여 주시기 바랍니다.");
      return false;
    }

    if (paymentInfo.payStartY4mm.length !== 6) {
      CM.cfnAlert("지급시작월을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (!noneDeadline && paymentInfo.payEndY4mm.length !== 6) {
      CM.cfnAlert("지급종료월을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (paymentInfo.transactionMethod === "CMS_DEPOSIT" && CM.cfnIsEmpty(paymentInfo.account.accountBankCode)) {
      CM.cfnAlert("입력된 금융기관명을 다시 확인하여 주시기 바랍니다.");
      return false;
    }

    if (calledFrom === "UPDATE" && paymentInfo.expectedApplyY4mm.length !== 6) {
      CM.cfnAlert("반영희망월을 선택하여 주시기 바랍니다.");
      return false;
    }

    return true;
  },
};

export default PaymentCustomerData;
