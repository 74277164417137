import { Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as CM from "../../common/Common";
import CustMnmtTable from "../../institute/customerCriteria/CustMnmtTable";
import StaffInfo from "../../institute/staffInfo/StaffInfo";
import CustomerData from "../customerBasicInformation/CustomerData";
import { CreateCustomerDivision, CustomerBusinessSelectInput, CustomerIndividualSelectInput, CustomerRequiredInputs } from "../customerBasicInformation/CustomerForm";
import PaymentData from "./PaymentCustomerData";
import { PaymentCustomerInfo } from "./PaymentCustomerForm";
import PaymentHistory from "./PaymentHistory";

/*
 * @desc  렌더를 처리하는 메인 컴포넌트
 */
const CustomerUpdate = ({ store }) => {
  // 이전 page로부터 전달받은 값
  let location = useLocation();
  let history = useHistory();
  const passedInfo = location.state ? location.state.payment : {};

  /* 고객정보 처리에 필요한 state 및 함수 선언 */
  // 고객정보 hooks
  const [customer, setCustomer] = useState(CustomerData.getCustomer(passedInfo.customer));

  // FIXME: 고객정보 object 및 선택입력 사항 hooks & handler
  // 고객 이메일 주소
  const [customerEmail, setCustomerEmail] = useState(CustomerData.getCustomerEmail(passedInfo.customer));

  // 담당자 정보
  const [customerDetailInfo, setCustomerDetailInfo] = useState(CustomerData.getCustomerDetailInfo(passedInfo.customer));

  // 담당자 정보 handler
  const handleCustomerDetailInfoChange = (name, e) => {
    const value = name !== "결혼기념일" ? e.target.value : e;
    setCustomerDetailInfo((data) => ({ ...data, [name]: value }));
  };

  // 고객구분영역 hooks and handler
  const [firstIdentificationNo, setFirstIdentificationNo] = useState("");
  const [lastIdentificationNo, setLastIdentificationNo] = useState("");

  // 주민등록번호 앞자리, 뒷자리 handler
  const handleIdentificationNoChange = (e, type) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    changeIdentificationNo(value, type);
  };

  // 주민등록번호 앞자리, 뒷자리 입력시 해당 값 변경
  const changeIdentificationNo = (value, type) => {
    if (type === "first") {
      setFirstIdentificationNo(value);
    } else {
      setLastIdentificationNo(value);
    }
  };

  // 주민등록번호 앞, 뒷자리 초기화
  useEffect(() => {
    if (customer && customer.customerType === "INDIVIDUAL") {
      if (customer.identificationNo) {
        if (customer.identificationNo.length >= 6) {
          setFirstIdentificationNo(customer.identificationNo.substring(0, 6));
          setLastIdentificationNo(customer.identificationNo.substring(6));
        } else {
          setFirstIdentificationNo(customer.identificationNo);
        }
      }
    }
  }, [customer]);

  // 현금영수증 발행정보 radio 값
  const [selectedCashbillIdentificationNo, setSelectedCashbillIdentificationNo] = useState(CustomerData.getCashbillIdentificationNo(passedInfo.customer));

  // 이메일 주소 handler
  const handleCustomerEmailChange = (e, name) => {
    const value = e.target.value;
    setCustomerEmail((email) => ({
      ...email,
      [name]: value,
    }));
  };

  // 이메일 주소 selectbox handler
  const handleSelectboxCustomerEmailChange = (e) => {
    const value = e.target.value;
    const domain = value !== "manual" ? value : "";

    setCustomerEmail((email) => ({
      ...email,
      domain: domain,
      selected: value,
    }));
  };

  // 선택입력사항 handler(이메일, 현금영수증 발행정보 제외)
  const handleCustomerChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    // 현금영수증 발행정보의 주민등록번호를 입력할 때
    if (name === "cashbillIdentificationNo" && selectedCashbillIdentificationNo === "IDENTIFICATION_NO") {
      value = value.replace(/[^0-9]/g, "");
    }

    changeCustomer(name, value);
  };

  // 현금영수증 발행정보 radio handler
  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedCashbillIdentificationNo(value);

    // 현금영수증 발행정보 input 초기화
    changeCustomer("cashbillIdentificationNo", "");
  };

  // 선택입력사항 입력시 해당 값 변경
  const changeCustomer = (name, value) => {
    setCustomer((data) => ({ ...data, [name]: value }));
  };

  // FIXME: 개인 && 사업 > 고객정보 > 필수 입력사항 hooks and handler
  const [selectRequiredInputs, setSelectRequiredInputs] = useState(CustomerData.getCustomerRequiredInputs(passedInfo.customer));
  const [optionStaffsAndBranches, setOptionStaffsAndBranches] = useState([]);
  const [optionCustomerGroup, setOptionCustomerGroup] = useState([]);

  // 필수 입력 사항 목록 가져오기
  useEffect(() => {
    const startStoreAxios = async () => {
      const resultCustomerGroup = await store.axiosCustomerGroup(); // 고객구분
      setOptionCustomerGroup(resultCustomerGroup);

      const resultStaffs = await store.axiosStaffs();
      const branchesStaffs = resultStaffs.map((staff) => ({ uniqueKey: staff.uniqueKey, name: `${staff.staffName}/${staff.branch.branchName}` }));
      setOptionStaffsAndBranches(branchesStaffs);
    };
    const fetchInstituteService = async () => {
      const storeInstituteService = await store.axiosInstituteService();
      let setting = { serviceUseYn: false, sendForAskingDeposit: false, sendForFailureOnDeposit: false, sendForSuccessOnDeposit: false };
      for (const service of storeInstituteService) {
        if (service.serviceType === "SMS_TO_CUSTOMER") {
          setting = service;
        }
      }
      setting.serviceUseYn = !!setting.serviceUseYn;
      setting.sendForAskingDeposit = !!setting.sendForAskingDeposit;
      setting.sendForFailureOnDeposit = !!setting.sendForFailureOnDeposit;
      setting.sendForSuccessOnDeposit = !!setting.sendForSuccessOnDeposit;
      setSmsToCustomerSetting(setting);
    };
    fetchInstituteService();
    startStoreAxios();
  }, [store]);

  // 선택한 고객구분, 고객담당지사, 고객관리담당자 값을 변경하는 handler
  const handleRequiredInputsHandler = (e, name) => {
    const value = e.target.value;
    setSelectRequiredInputs((data) => ({ ...data, [name]: value }));
  };
  /* 고객정보 처리에 필요한 state 및 함수 선언 종료 */

  /* 지급정보 처리에 필요한 state 및 함수 선언 */
  let defaultValue = {
    account: {
      accountBankCode: "",
      accountNo: "",
      depositorIdentificationNo: "",
      depositorName: "",
      registeredDate: "",
      unregisteredDate: "",
    },
    askStatus: "",
    bankbookContents: "",
    bankbookPostfix: "",
    bankbookPostfixType: "",
    capital: {
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
      capitalName: "",
      capitalType: "",
    },
    contractStatus: "",
    expectedApplyY4mm: "",
    payAmount: null,
    payAmountType: "VARIABLE",
    payEndY4mm: "999912",
    payPeriod: "MONTHLY",
    paySpecifiedDay: "",
    payStartY4mm: "",
    sendForAskingDeposit: false,
    sendForFailureOnDeposit: false,
    sendForSuccessOnDeposit: false,
    totalNumberOfPayment: 1,
    transactionMethod: "CMS_DEPOSIT",
    uniqueKey: "",
  };

  const [paymentInfo, setPaymentInfo] = useState(() => {
    for (let i in defaultValue) {
      if (CM.cfnIsNotEmpty(passedInfo[i])) {
        defaultValue[i] = passedInfo[i];
      }
    }
    defaultValue.customer = customer;
    const today = new Date();
    defaultValue.expectedApplyY4mm = `${today.getFullYear()}${today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1}`;
    return defaultValue;
  });

  const [capitalRows, setCapitalRows] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [staffModalOpen, setStaffModalOpen] = useState(false);
  const [customerGroupModalOpen, setCustomerGroupModalOpen] = useState(false);
  const [noneDeadline, setNoneDeadline] = useState(paymentInfo.payEndY4mm === "999912" ? true : false);
  const [updateYn] = useState(true);
  const [checkIdentificationNo, setCheckIdentificationNo] = useState(
    location.state && location.state.payment && location.state.payment.customer && location.state.payment.customer.identificationNo && location.state.payment.customer.identificationNo.length > 6
  ); // 주민등록번호앞자리 체크 hooks
  const [smsToCustomerSetting, setSmsToCustomerSetting] = useState({ serviceUseYn: false, sendForAskingDeposit: false, sendForFailureOnDeposit: false, sendForSuccessOnDeposit: false });

  const handleHistoryModalOpen = (e) => {
    setHistoryOpen(true);
  };

  /*
   * @desc    전송 전 state 처리 함수
   */
  const fnConvertForRequest = (paymentClone) => {
    let customerClone = paymentClone.customer;
    const copyCustomerDetailInfo = {}; // 고객추가정보
    let email = null;

    /* 고객정보 변환 처리 */
    // 고객추가정보 값 확인
    for (const [key, value] of Object.entries(customerDetailInfo)) {
      if (CM.cfnIsNotEmpty(value)) {
        const applyValue = key === "결혼기념일" ? CM.cfnConvertDateToString(value) : value;
        copyCustomerDetailInfo[key] = applyValue;
      }
    }

    // 고객추가정보 값 설정
    customerClone.customerGroup.uniqueKey = selectRequiredInputs.customerGroup;
    customerClone.staff.uniqueKey = selectRequiredInputs.staff;
    customerClone.customerDetailInfo = JSON.stringify(copyCustomerDetailInfo);
    customerClone.customerMobile = CM.cfnReplaceSymbol(customerClone.customerMobile);
    customerClone.customerTelephone = CM.cfnReplaceSymbol(customerClone.customerTelephone);
    customerClone.customerFax = CM.cfnReplaceSymbol(customerClone.customerFax);
    customerClone.identificationNo = CM.cfnReplaceSymbol(customerClone.identificationNo);

    // 이메일이 빈값이 아니라면
    if (CM.cfnIsNotEmpty(customerEmail.id) || CM.cfnIsNotEmpty(customerEmail.domain)) {
      email = customerEmail.id + "@" + customerEmail.domain;
      // 이메일 형식 체크
      if (!CM.cfnCheckEmail(email)) {
        CM.cfnAlert("올바른 이메일 형식이 아닙니다.");
        return false;
      }
    }
    customerClone.customerEmail = email;

    paymentClone.customer = customerClone;

    /* 지급정보 변환 처리 */
    if (paymentClone.payAmountType === "FIXED" && CM.cfnIsNotEmpty(paymentClone.payAmount)) {
      //정액 선택 시 지급금액 integer로 변환
      paymentClone.payAmount = Number(CM.cfnReplaceSymbol(paymentClone.payAmount));
    } else if (paymentClone.payAmountType === "VARIABLE") {
      //비정액 선택 시 지급금액 null로 변환
      paymentClone.payAmount = null;
    }

    //총지급횟수 계산
    let numberOfPayments = "";
    let startYear = paymentClone.payStartY4mm !== null ? Number(paymentClone.payStartY4mm.toString().substring(0, 4)) : 0;
    let startMonth = paymentClone.payStartY4mm !== null ? Number(paymentClone.payStartY4mm.toString().substring(4)) - 1 : 0;
    let endYear = paymentClone.payEndY4mm !== null ? Number(paymentClone.payEndY4mm.toString().substring(0, 4)) : 0;
    let endMonth = paymentClone.payEndY4mm !== null ? Number(paymentClone.payEndY4mm.toString().substring(4)) - 1 : 0;

    if (paymentClone.payPeriod !== "VARIABLE" && endYear !== 9999 && paymentClone.payStartY4mm.length > 4) {
      let monthDifference = (endYear - startYear) * 12 - startMonth + endMonth;
      switch (paymentClone.payPeriod) {
        case "MONTHLY":
          numberOfPayments = Math.floor(monthDifference / 1.0);
          break;
        case "EVERY_2_MONTHS":
          numberOfPayments = Math.floor(monthDifference / 2.0);
          break;
        case "EVERY_3_MONTHS":
          numberOfPayments = Math.floor(monthDifference / 3.0);
          break;
        case "EVERY_4_MONTHS":
          numberOfPayments = Math.floor(monthDifference / 4.0);
          break;
        case "EVERY_6_MONTHS":
          numberOfPayments = Math.floor(monthDifference / 6.0);
          break;
        case "YEARLY":
          numberOfPayments = Math.floor(monthDifference / 12.0);
          break;
        default:
          break;
      }
      paymentClone.totalNumberOfPayment = numberOfPayments + 1;
    } else {
      if (noneDeadline) {
        paymentClone.payEndY4mm = "999912";
        paymentClone.totalNumberOfPayment = null;
      }
    }

    return paymentClone;
  };

  /*
   * @desc    고객정보 수정 여부를 비교하여 boolean값 리턴하는 함수
   */
  const fnCheckCustomerModified = (passedCustomer, convertedCustomer) => {
    return Object.keys(convertedCustomer).some((key) => {
      let value = convertedCustomer[key];

      if (key === "customerDetailInfo" && !_.isEmpty(value)) {
        value = JSON.parse(value);
      }

      return !_.isEqual(value, passedCustomer[key]);
    });
  };

  /*
   * @desc    고객정보 수정 저장 Request
   */
  const fnUpdateCustomerInformation = (customer) => {
    return new Promise((resolve) => {
      const url = "api/customer/basics";
      CM.cfnAxios(
        url,
        "put",
        customer,
        () => {
          resolve(true);
        },
        () => {
          //고객정보 수정 실패 시
          resolve(false);
        }
      );
    });
  };

  /*
   * @desc    지급정보 수정 저장 Request
   */
  const fnUpdatePaymentInformation = async () => {
    const tempObj = { ...paymentInfo, customer: { ...customer } };
    // 개인일 때
    if ("INDIVIDUAL" === customer.customerType) {
      tempObj.customer.identificationNo = firstIdentificationNo + lastIdentificationNo;
      setCustomer({ ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo });
    }
    setPaymentInfo({ ...paymentInfo, customer: { ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo } });
    // validation 체크 및 값 변환
    const validationData = fnConvertForRequest(tempObj);
    if (!validationData) {
      return false;
    }

    // 고객정보 수정 여부 체크
    const isModified = fnCheckCustomerModified({ ...passedInfo.customer }, { ...validationData.customer });
    let customerUpdate = true;

    // 고객정보 수정 시 고객정보 수정 API 호출
    if (isModified) customerUpdate = await fnUpdateCustomerInformation(validationData.customer);
    if (customerUpdate) {
      const url = "/api/customer/payment/payments";
      CM.cfnAxios(url, "put", validationData, (objStatus, objData) => {
        CM.cfnAlert("정상적으로 처리되었습니다.");
        history.replace({
          ...history.location,
          state: {
            ...location.state,
            payment: { ...paymentInfo, customer: { ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo } },
          },
        });
      });
    }
  };

  // 수정버튼 클릭시 수정 처리
  const handleModifySubmit = (e) => {
    if (PaymentData.fnCheckValidation("UPDATE", paymentInfo, selectRequiredInputs, noneDeadline)) CM.cfnConfirm("수정하시겠습니까?", fnUpdatePaymentInformation);
  };

  /*
   * @desc    지급정보 입력란 변경 이벤트 핸들러
   */
  const handlePaymentInfoChange = (depth1, depth2, depth3, depth4) => (e) => {
    if (depth1 === "transactionMethod") {
      //지급형태 변경 시
      if (e.target.value === "ETC") {
        //기타방식 선택 시 계좌정보 초기화
        setPaymentInfo({
          ...paymentInfo,
          transactionMethod: e.target.value,
          account: {
            accountBankCode: "",
            accountNo: "",
            depositorIdentificationNo: "",
            depositorName: "",
            registeredDate: "",
            unregisteredDate: "",
          },
        });
      } else {
        setPaymentInfo({
          ...paymentInfo,
          transactionMethod: e.target.value,
        });
      }
    } else if (depth1 === "payStartY4") {
      //지급시작월의 년도 변경 시
      const startMm = paymentInfo.payStartY4mm.substring(4);
      setPaymentInfo({
        ...paymentInfo,
        payStartY4mm: `${e.target.value}${startMm}`,
      });
    } else if (depth1 === "payStartMm") {
      //지급시작월의 월 변경 시
      const startY4 = paymentInfo.payStartY4mm.substring(0, 4);
      setPaymentInfo({
        ...paymentInfo,
        payStartY4mm: `${startY4}${e.target.value}`,
      });
    } else if (depth1 === "payEndY4") {
      //지급종료월의 년도 변경 시
      const endMm = paymentInfo.payEndY4mm.substring(4);
      setPaymentInfo({
        ...paymentInfo,
        payEndY4mm: `${e.target.value}${endMm}`,
      });
    } else if (depth1 === "payEndMm") {
      //지급종료월의 월 변경 시
      const endY4 = paymentInfo.payEndY4mm.substring(0, 4);
      setPaymentInfo({
        ...paymentInfo,
        payEndY4mm: `${endY4}${e.target.value}`,
      });
    } else if (depth1 === "expectedApplyY4") {
      //반영희망월의 년도 변경 시
      const applyMm = paymentInfo.expectedApplyY4mm.substring(4);
      setPaymentInfo({
        ...paymentInfo,
        expectedApplyY4mm: `${e.target.value}${applyMm}`,
      });
    } else if (depth1 === "expectedApplyMm") {
      //반영희망월의 월 변경 시
      const applyY4 = paymentInfo.expectedApplyY4mm.substring(0, 4);
      setPaymentInfo({
        ...paymentInfo,
        expectedApplyY4mm: `${applyY4}${e.target.value}`,
      });
    } else if (depth1 === "payAmountType") {
      //지급금액 유형 변경 시
      setPaymentInfo({
        ...paymentInfo,
        payAmountType: e.target.value,
        payAmount: "", //지급금액 입력란 초기화
      });
    } else if (depth1 === "payAmount") {
      //지급금액 입력란 변경 시
      setPaymentInfo({
        ...paymentInfo,
        payAmount: CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, "")),
      });
    } else if (depth2 === "capitalName") {
      //자금종류 변경 시
      const selectedCapital = PaymentData.fnMatchSelectedCapital(e.target.value, capitalRows);
      setPaymentInfo({
        ...paymentInfo,
        bankbookContents: selectedCapital.bankbookContents,
        bankbookPostfix: selectedCapital.bankbookPostfix,
        bankbookPostfixType: selectedCapital.bankbookPostfixType,
        [depth1]: {
          uniqueKey: selectedCapital.uniqueKey,
        },
      });
    } else if (depth2 === "accountNo") {
      //계좌번호 입력 시
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: e.target.value.replace(/[^0-9]/g, ""),
        },
      });
    } else if (depth1 === "depositorNameCheckBox") {
      //납부자와 동일 체크박스 변경 시
      if (e.target.checked) {
        //고객기본정보 내 성명 및 생년월일(사업자번호) 입력
        setPaymentInfo({
          ...paymentInfo,
          account: {
            ...paymentInfo.account,
            depositorIdentificationNo: customer.identificationNo,
            depositorName: customer.customerName,
          },
        });
      }
    } else if (depth1 === "smsSend") {
      //SMS고지 체크박스 변경 시
      if (e.target.value === "") {
        setPaymentInfo({
          ...paymentInfo,
          sendForAskingDeposit: false,
          sendForFailureOnDeposit: false,
          sendForSuccessOnDeposit: false,
        });
      } else if (e.target.value === "all") {
        setPaymentInfo({
          ...paymentInfo,
          sendForAskingDeposit: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingDeposit,
          sendForFailureOnDeposit: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnDeposit,
          sendForSuccessOnDeposit: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnDeposit,
        });
      } else if (depth2 !== "undefined") {
        setPaymentInfo({
          ...paymentInfo,
          [e.target.value]: e.target.checked,
        });
      }
    } else if (depth4 !== undefined) {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: {
            ...paymentInfo[depth1][depth2],
            [depth3]: {
              ...paymentInfo[depth1][depth2][depth3],
              [depth4]: e.target.value,
            },
          },
        },
      });
    } else if (depth3 !== undefined) {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: {
            ...paymentInfo[depth1][depth2],
            [depth3]: e.target.value,
          },
        },
      });
    } else if (depth2 !== undefined) {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: {
          ...paymentInfo[depth1],
          [depth2]: e.target.value,
        },
      });
    } else {
      setPaymentInfo({
        ...paymentInfo,
        [depth1]: e.target.value,
      });
    }
  };

  //기한없음
  const handleDeadline = (e) => {
    if (e.target.checked) {
      //기한없음
      setNoneDeadline(true);
      setPaymentInfo({
        ...paymentInfo,
        payEndY4mm: "999912",
      });
    } else {
      //기한없음 해제
      setNoneDeadline(false);
    }
  };

  return (
    <div>
      <div>
        <h3>고객정보</h3>
        <CreateCustomerDivision
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          firstIdentificationNo={firstIdentificationNo}
          lastIdentificationNo={lastIdentificationNo}
          handleIdentificationNoChange={handleIdentificationNoChange}
          changeIdentificationNo={changeIdentificationNo}
          checkIdentificationNo={checkIdentificationNo}
          setCheckIdentificationNo={setCheckIdentificationNo}
        />
        <CustomerRequiredInputs
          storeCustomerGroup={optionCustomerGroup}
          optionStaffsAndBranches={optionStaffsAndBranches}
          selectRequiredInputs={selectRequiredInputs}
          handleRequiredInputsHandler={handleRequiredInputsHandler}
          setCustomerGroupModalOpen={setCustomerGroupModalOpen}
          setStaffModalOpen={setStaffModalOpen}
        />
        <CustomerIndividualSelectInput
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          customerEmail={customerEmail}
          handleCustomerEmailChange={handleCustomerEmailChange}
          selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
          handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
          handleRadioChange={handleRadioChange}
          customerDetailInfo={customerDetailInfo}
          handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
          checkIdentificationNo={checkIdentificationNo}
          isPaymentInformation={true}
        />
        <CustomerBusinessSelectInput
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          customerDetailInfo={customerDetailInfo}
          handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
          customerEmail={customerEmail}
          handleCustomerEmailChange={handleCustomerEmailChange}
          selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
          handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
          handleRadioChange={handleRadioChange}
          isPaymentInformation={true}
        />
        <div className="table-bottom-area" />
        <h4>
          지급정보
          <div className="fr">
            <div className="btn-m fr" onClick={handleHistoryModalOpen}>
              회차별 지급내역 보기
            </div>
          </div>
        </h4>
        <PaymentCustomerInfo
          paymentInfo={paymentInfo}
          setPaymentInfo={setPaymentInfo}
          handlePaymentInfoChange={handlePaymentInfoChange}
          setCapitalRows={setCapitalRows}
          noneDeadline={noneDeadline}
          handleDeadline={handleDeadline}
          store={store}
          updateYn={updateYn}
          smsToCustomerSetting={smsToCustomerSetting}
        />
        <div className="table-bottom-area">
          <button type="button" className="btn-l" onClick={handleModifySubmit}>
            수정
          </button>
          <button type="button" className="btn-m" onClick={() => history.goBack()}>
            목록
          </button>
        </div>
        <PaymentHistory open={historyOpen} setOpen={setHistoryOpen} paymentInfo={paymentInfo} />
      </div>
      <Modal open={staffModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setStaffModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>담당자 정보</h3>
            <StaffInfo />
          </div>
        </div>
      </Modal>
      <Modal open={customerGroupModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setCustomerGroupModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>고객구분 관리</h3>
            <CustMnmtTable />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(CustomerUpdate));
