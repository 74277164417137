import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CMData from "../../common/CommonDataName";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaperData from "./PaperBillData";
import IssueBillModal from "./PaperBillIssueModal";
import PaperBillPrintModal from "./PaperBillPrintModal";
import TargetAddModal from "./PaperIssueTargetAddModal";

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">수납일</label>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.fromDate}
          handleChange={handleSearchFormChange("fromDate")}
          format="yyyy.MM.dd"
          customProps={{
            placeholder: "YYYY.MM.DD",
            inputProps: { "data-testid": "paperbill-target-datePicker-fromDate" },
          }}
        />
        <span className="between">~</span>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.toDate}
          handleChange={handleSearchFormChange("toDate")}
          format="yyyy.MM.dd"
          customProps={{
            placeholder: "YYYY.MM.DD",
            inputProps: { "data-testid": "paperbill-target-datePicker-toDate" },
          }}
        />
        <label className="label-l">수납방법</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" name="position" row>
            <FormControlLabel
              value={searchRequest.transactionMethod}
              control={<Checkbox checked={searchRequest.transactionMethod === "" ? true : false} value="" onChange={handleSearchFormChange("transactionMethod")} color="primary" />}
              label="전체"
              data-testid="select-all-transaction-method"
            />
            <RadioGroup value={searchRequest.transactionMethod} onChange={handleSearchFormChange("transactionMethod")} data-testid="select-transaction-method" row>
              <FormControlLabel
                checked={searchRequest.transactionMethod === "CMS_WITHDRAW" ? true : false}
                value="CMS_WITHDRAW"
                control={<Radio color="primary" />}
                label="CMS"
                data-testid="select-cms-method"
              />
              <FormControlLabel checked={searchRequest.transactionMethod === "ETC" ? true : false} value="ETC" control={<Radio color="primary" />} label="기타" data-testid="select-etc-method" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <br />
        <br />
        <label className="label-l">고객구분</label>
        <Select
          native
          value={searchRequest.customerGroupKey}
          onChange={handleSearchFormChange("customerGroupName")}
          name="customerGroupName"
          inputProps={{ "data-testid": "paperbill-customerGroup-select" }}>
          <option value={""} key={"none"}>
            {"전체"}
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native value={searchRequest.capitalKey} onChange={handleSearchFormChange("capitalName")} name="capitalName" inputProps={{ "data-testid": "paperbill-capital-select" }}>
          <option value={""} key={"none"}>
            {"전체"}
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.term} onChange={handleSearchFormChange("term")} inputProps={{ "data-testid": "paperbill-target-term-select" }}>
          <option value="customerName">고객명</option>
          <option value="identificationNo">주민등록번호(사업자번호)</option>
        </Select>
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="paperbill-target-keyword" />
        <button className="search-button" data-testid="search-paper-bill-target" onClick={() => fnSearch()}>
          발행대상조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state
  const { paperBillsList, setPaperBillsList } = props;

  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of paperBillsList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const customerLength = paperBillsList.length;

    if (checkAllFlag === customerLength && customerLength > 0) setCheckAllRow(true);
    else setCheckAllRow(false);
  }, [paperBillsList]);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    setPaperBillsList((taxbills) => taxbills.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleSelectChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...paperBillsList];
    cloneData[index][key] = value;
    setPaperBillsList(cloneData);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "transactionDate", label: "수납일", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "identificationNo", label: "주민등록번호\n(사업자번호)", sortable: true },
            { id: "transactionMethod", label: "수납방법", sortable: true },
            { id: "customerGroupName", label: "고객구분", sortable: true },
            { id: "capitalName", label: "자금종류", sortable: true },
            { id: "receivedAmount", label: "수납금액", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {paperBillsList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            paperBillsList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleSelectChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.transactionDate)}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                  <TableCell align="center">{CMData.transactionMethod(row.transactionMethod)}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center">{row.capitalName}</TableCell>
                  <TableCell align="center">{CM.cfnAddComma(row.receivedAmount)}원</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function PaperPublishTargetTab(props) {
  const { tabIndex, store } = props;

  const [searchRequest, setSearchRequest] = useState(PaperData.targetSearchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  // const [checkAllRow, setCheckAllRow] = useState(true); // 검색 실행 flag

  const [isTargetChanged, setIsTargetChanged] = useState(false); //발행대상 추가 여부 flag
  const [originalList, setOriginalList] = useState([]);
  const [paperBillsList, setPaperBillsList] = useState([]); // table 데이터
  const [selectedBillsList, setSelectedBillsList] = useState([]); //선택된 발행대상 제어
  const [pagination, setPagination] = useState(PaperData.paginationData());

  //모달 제어
  const [openIssueBillModal, setOpenIssueBillModal] = useState(false); //종이영수증 발행 모달 제어
  const [openTargetAddModal, setOpenTargetAddModal] = useState(false); //발행 대상 추가 모달 제어
  const [openPrintModal, setOpenPrintModal] = useState(false); //종이영수증 인쇄 모달 제어
  const [master, setMaster] = useState({});

  /*
   * @desc 종이영수증 발행대상 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);
    tempObj.toDate = CM.cfnConvertDateToString(searchRequest.toDate);
    tempObj.fromDate = CM.cfnConvertDateToString(searchRequest.fromDate);

    if (tempObj.term === "customerName") {
      tempObj.customerName = tempObj.keyword;
      tempObj.identificationNo = "";
    } else if (tempObj.term === "identificationNo") {
      tempObj.customerName = "";
      tempObj.identificationNo = tempObj.keyword;
    }

    return tempObj;
  }, [searchRequest]);

  useEffect(() => {
    /*
     * @desc    종이영수증 발행대상 조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `/api/extraservice/receipt/targets?capitalUniqueKey=${search.capitalName}&groupUniqueKey=${search.customerGroupName}&customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&toDate=${search.toDate}&transactionMethod=${search.transactionMethod}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      const resultData = await axiosList(search);
      let resultList = resultData.content;
      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }

      // 체크 옵션 추가
      if (CM.cfnIsNotEmpty(resultList)) {
        for (const element of resultList) {
          element._checked = false;
        }
      }
      setOriginalList(resultData);
      setPaperBillsList(resultList);
      setPagination(PaperData.paginationData(resultData));
      setSearchButton(false);
      // await setCheckAllRow(false);
    }

    // 실행 영역
    if (tabIndex === 0 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton, fnMakeParameter]);

  //발행대상 추가 시 목록 재조회
  useEffect(() => {
    if (isTargetChanged) {
      setIsTargetChanged(false);
      handleSearchButtonChange(true);
    }
  }, [isTargetChanged]);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosCapitals(); // 자금종류
      setMaster(toJS(await store.axiosMaster()));
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    상단 검색조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "fromDate" || name === "toDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else if (name === "capitalName" || name === "customerGroupName") {
      const index = e.nativeEvent.target.selectedIndex;
      const key = `${name}Key`;
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value !== "" ? e.nativeEvent.target[index].text : "",
        [key]: e.target.value,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => {
    setSearchButton(flag);
  };

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc   엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleClickTargetExcelDownload = () => {
    const search = fnMakeParameter();
    const url = `api/extraservice/receipt/targets/excel?capitalUniqueKey=${search.capitalName}&groupUniqueKey=${search.customerGroupName}&customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&toDate=${search.toDate}&transactionMethod=${search.transactionMethod}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    종이영수증 발행 / 발행 대상 추가 모달 핸들러
   */
  const handleModal = async (type, open, list) => {
    if (type === "add") {
      setOpenTargetAddModal(open);
      if (Object.isExtensible(list)) {
        setSelectedBillsList(list);
        setOpenIssueBillModal(true);
      }
    } else if (type === "issue") {
      const tempArray = [];
      for (const element of paperBillsList) {
        if (element._checked) tempArray.push(element);
      }

      if (tempArray.length === 0) {
        CM.cfnAlert("선택된 내용이 없습니다.");
        return false;
      }

      setSelectedBillsList(tempArray);

      setOpenIssueBillModal(open);
    } else if (type === "close") {
      setOpenIssueBillModal(open);
      handleSearchButtonChange(true);
    } else if (type === "print") {
      setOpenIssueBillModal(open);
      if (Object.isExtensible(list)) {
        setSelectedBillsList(list);
        setOpenPrintModal(true);
      }
    }
  };

  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        storeCapital={toJS(store.capital)} // 자금종류
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
      />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={originalList.totalElements} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="paperbill-target-rowPerPage-select" />
        <button className="btn-m fr" onClick={handleClickTargetExcelDownload} data-testid="save-target-excel">
          엑셀저장
        </button>
        <button className="btn-m fr" onClick={() => handleModal("add", true)} data-testid="add-bill-target-open">
          발행 대상 추가
        </button>
        <button className="btn-l fr" onClick={() => handleModal("issue", true)} data-testid="issue-paper-bill-open">
          종이영수증 발행
        </button>
      </div>
      <ListForm
        paperBillsList={paperBillsList}
        setPaperBillsList={setPaperBillsList}
        pagination={pagination}
        page={searchRequest.pageNumber}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
      <TargetAddModal open={openTargetAddModal} handleModal={handleModal} setIsTargetChanged={setIsTargetChanged} />
      <IssueBillModal open={openIssueBillModal} handleModal={handleModal} selectedBillsList={selectedBillsList} setSelectedBillsList={setSelectedBillsList} master={master} />
      <PaperBillPrintModal open={openPrintModal} setOpen={setOpenPrintModal} selectedBills={selectedBillsList} master={master} />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaperPublishTargetTab));
