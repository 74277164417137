import * as CM from "../../common/Common";

const AccountCheckData = {
  //계좌실명조회 결과조회 검색 데이터
  realNameCheckSearchData: () => {
    const today = new Date();
    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return {
      startDate: monthAgo,
      endDate: today,
      resultCode: "", //처리상태
      keyword: "",
      term: "depositorName",
      pageNumber: 0,
      pageSize: 5,
      sortDirection: "DESC",
      sortProperty: "inquiryDatetime",
    };
  },
  //실시간계좌등록/해지요청 조회 검색 데이터
  requestSearchData: {
    customerMobile: "",
    customerName: "",
    payerNo: "",
    keyword: "",
    term: "customerName",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "customerName",
  },
  //실시간계좌등록요청내역 조회 검색 데이터
  historySearchData: () => {
    const today = new Date();
    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    return {
      startDate: monthAgo,
      endDate: today,
      customerMobile: "",
      customerName: "",
      term: "customerName",
      isClosingAccountIncluded: true,
      isNewAccountIncluded: true,
      pageNumber: 0,
      pageSize: 5,
      payerNo: "",
      sortDirection: "DESC",
      sortProperty: "applyDatetime",
    };
  },
  //계좌실명조회 - 처리상태 코드 변환
  statusCode: (code) => {
    switch (code) {
      case "0000":
        return "정상";
      case null:
        return "처리중";
      default:
        return "오류";
    }
  },
  //pagination
  paginationData: (data) => {
    if (CM.cfnIsEmpty(data)) {
      return {
        rowsPerPage: 5,
        offset: 0,
        total: 0,
        totalPages: 1,
      };
    }

    return {
      rowsPerPage: data.pageable.pageSize,
      offset: data.pageable.offset,
      total: data.totalElements,
      totalPages: data.totalPages,
    };
  },
  //자동납부 동의자료 모달 내 사용 state 초기화
  evidenceFileDefaultValue: {
    file: null,
    fileName: "",
    fileSrc: "#",
    fileType: "",
    isFirstLoaded: true,
  },
};

export default AccountCheckData;
