import * as Common from "../../common/Common";
import { CheckboxForm, TooltipForm } from "../../template/ComponentForm";
import { useHistory } from "react-router-dom";
import { BankAutoComplete, SelectForm } from "../../template/ComponentForm";
import React, { useEffect, useState, Fragment } from "react";

import PaymentData from "./PaymentCustomerData";

import { Table, TableBody, TableRow, TableCell, FormControl, FormControlLabel, RadioGroup, Radio, Select, Input, FormGroup, Checkbox } from "@material-ui/core";

import CapitalsModal from "../customerModal/CapitalsModal";

/*
 * @desc  고객정보 > 지급정보 컴포넌트
 */
export const PaymentCustomerInfo = ({ paymentInfo, setPaymentInfo, handlePaymentInfoChange, setCapitalRows, noneDeadline, handleDeadline, store, updateYn, smsToCustomerSetting }) => {
  const history = useHistory();
  const calledFrom = history.location.pathname.indexOf("paymentUpdate") !== -1 ? "UPDATE" : "CREATE";

  //필요 state 선언
  const [searchValue, setSearchValue] = useState({
    accountNo: "",
    depositorIdentification: "",
    financialInstituteCode: Object.isExtensible(paymentInfo.account) ? paymentInfo.account.accountBankCode : "",
    fncInsNm: "",
  });

  const [financialRepresentativeInstitutes, setFinancialRepresentativeInstitutes] = useState([]); //금융기관 자동완성 기능을 위한 state
  const [capitalOptions, setCapitalOptions] = useState([]); //자금종류 옵션 생성을 위한 state

  // 모달창 관리용 state 선언
  const [capitalOpen, setCapitalOpen] = useState(false);

  // 자금종류 store 가져오기
  useEffect(() => {
    const getAxiosStore = async () => {
      const responseData = await store.axiosCapitals();
      setCapitalRows(responseData);
      setCapitalOptions(PaymentData.fnCreateCapitalOptions(responseData));
    };

    getAxiosStore();
  }, [store, setCapitalRows]);

  // 금융기관 목록 store 가져오기
  useEffect(() => {
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await store.axiosFinancialRepresentativeInstitutes();
      setFinancialRepresentativeInstitutes(institutesList);
    };

    fnFetchFinancialInstitutes();
  }, [store]);

  useEffect(() => {
    const initFinancialInstituteCode = async () => {
      if (paymentInfo.hasOwnProperty("account")) {
        //account 객체가 null 이 아닐 경우 전달 받은 값을 setting
        let financialInstituteCode = paymentInfo.account.accountBankCode.substring(0, 3);
        const financialInstituteList = await store.axiosFinancialInstitutes();
        const financialInstituteIndex = financialInstituteList.findIndex((value, index) => {
          if (value.fncInsCd === financialInstituteCode) return index;
          return null;
        });
        if (financialInstituteIndex !== -1) {
          financialInstituteCode = financialInstituteList[financialInstituteIndex].repFncInsCd;
        }
        if (searchValue.financialInstituteCode !== financialInstituteCode) {
          setSearchValue(() => ({
            accountNo: paymentInfo.account.accountNo,
            depositorIdentification: paymentInfo.account.depositorIdentificationNo,
            financialInstituteCode: searchValue.financialInstituteCode,
            fncInsNm: "",
          }));
        }
      } else {
        setSearchValue(() => ({
          accountNo: "",
          depositorIdentification: "",
          financialInstituteCode: "",
          fncInsNm: "",
        }));
      }
    };
    initFinancialInstituteCode();
  }, [paymentInfo, searchValue.financialInstituteCode, store]);
  useEffect(() => {
    //금융기관명 자동완성 기능 사용 시
    setPaymentInfo((value) => ({
      ...value,
      account: {
        ...value.account,
        accountBankCode: searchValue.financialInstituteCode,
      },
    }));
  }, [searchValue.financialInstituteCode, setPaymentInfo]);

  /*
   * @desc    자금종류 추가 버튼 클릭 이벤트 핸들러
   */
  const handleCapitalModal = () => {
    setCapitalOpen(true);
  };

  /*
   * @desc    자금종류 관련 state 설정 함수
   */
  const fnSetCapitals = (data) => {
    setCapitalRows(data);
    setCapitalOptions(PaymentData.fnCreateCapitalOptions(data));
  };

  /*
   * @desc   수정페이지에서 기존 종료월 값이 과거라 셀렉트박스에 해당년도값이 없을 경우, 년도-월 셀렉트박스를 기본값으로 선택
   */
  const fnPastCheck = (data, yymm) => {
    const startYear = new Date().getFullYear();

    if (PaymentData.fnConvertY4mm(data, "year") < startYear) {
      return "";
    } else {
      return PaymentData.fnConvertY4mm(data, yymm);
    }
  };

  return (
    <Fragment>
      <Table>
        {Common.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">지급형태</TableCell>
            <TableCell className="td">
              {updateYn ? (
                "CMS_DEPOSIT" === paymentInfo.transactionMethod ? (
                  "CMS"
                ) : (
                  "기타"
                )
              ) : (
                <FormControl>
                  <RadioGroup
                    name="transactionMethod"
                    value={paymentInfo.transactionMethod}
                    onChange={handlePaymentInfoChange("transactionMethod")}
                    data-testid="paymentCustomerForm-radio-transactionMethod"
                    row>
                    <FormControlLabel value="CMS_DEPOSIT" control={<Radio color="primary" />} label="CMS" />
                    <FormControlLabel value="ETC" control={<Radio color="primary" />} label="기타" />
                  </RadioGroup>
                </FormControl>
              )}
            </TableCell>
            <TableCell className="th">자금종류</TableCell>
            <TableCell className="td">
              <Select
                native
                value={paymentInfo.capital.uniqueKey}
                onChange={handlePaymentInfoChange("capital", "capitalName")}
                inputProps={{
                  "data-testid": "paymentCustomerForm-select-capital",
                }}>
                <option value="">선택</option>
                {capitalOptions.map((row) => {
                  return (
                    <option value={row.value} key={row.key}>
                      {row.label}
                    </option>
                  );
                })}
              </Select>
              <button className="btn-s" onClick={handleCapitalModal} data-testid="capital-modal-open">
                관리
              </button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">지급일</TableCell>
            <TableCell className="td">
              <FormControl component="fieldset">
                <RadioGroup
                  name="paySpecifiedDay"
                  value={paymentInfo.paySpecifiedDay}
                  onChange={handlePaymentInfoChange("paySpecifiedDay")}
                  data-testid="paymentCustomerForm-radio-paySpecifiedDay"
                  row>
                  <FormControlLabel checked={paymentInfo.paySpecifiedDay !== "99" ? true : false} control={<Radio color="primary" />} className="delay-radio" />
                  <Select
                    native
                    value={paymentInfo.paySpecifiedDay}
                    onChange={handlePaymentInfoChange("paySpecifiedDay")}
                    disabled={paymentInfo.paySpecifiedDay === "99" ? true : false}
                    inputProps={{ "data-testid": "paymentCustomerForm-select-paySpecifiedDay" }}>
                    {PaymentData.fnCreateDateOption().map((row, idx) => {
                      return (
                        <option value={row.value} key={idx}>
                          {row.label}
                        </option>
                      );
                    })}
                  </Select>
                  <FormControlLabel checked={paymentInfo.paySpecifiedDay === "99" ? true : false} value="99" control={<Radio color="primary" />} label="말일" />
                  <TooltipForm contents="매월 마지막 영업일" spanStyle={{ lineHeight: "32px" }} />
                </RadioGroup>
              </FormControl>
            </TableCell>
            <TableCell className="th">지급금액</TableCell>
            <TableCell className="td">
              <FormControl component="fieldset">
                <RadioGroup name="payAmountType" value={paymentInfo.payAmountType} onChange={handlePaymentInfoChange("payAmountType")} data-testid="paymentCustomerForm-radio-payAmountType" row>
                  <FormControlLabel value="VARIABLE" control={<Radio color="primary" />} label="비정액" />
                  <FormControlLabel value="FIXED" control={<Radio color="primary" />} className="delay-radio" />
                  <span className="radio-label-l">정액</span>
                  <Input
                    name="payAmount"
                    className="w150"
                    value={paymentInfo.payAmount || ""}
                    onChange={handlePaymentInfoChange("payAmount")}
                    disabled={paymentInfo.payAmountType === "FIXED" ? false : true}
                    inputProps={{ "data-testid": "paymentCustomerForm-input-payAmount" }}
                    endAdornment="원"
                  />
                </RadioGroup>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">지급시작월</TableCell>
            <TableCell className="td">
              <Select
                disabled={updateYn}
                native
                value={PaymentData.fnConvertY4mm(paymentInfo.payStartY4mm, "year")}
                onChange={handlePaymentInfoChange("payStartY4")}
                inputProps={{
                  "data-testid": "paymentCustomerForm-select-payStartY4",
                }}>
                <option value="" key="">
                  연도
                </option>
                {PaymentData.fnCreateStartYearOption(paymentInfo && paymentInfo.payStartY4mm).map((element, index) => {
                  return (
                    <option value={element.value} key={index}>
                      {element.label}
                    </option>
                  );
                })}
              </Select>
              <Select
                disabled={updateYn}
                native
                value={PaymentData.fnConvertY4mm(paymentInfo.payStartY4mm, "mm")}
                onChange={handlePaymentInfoChange("payStartMm")}
                inputProps={{
                  "data-testid": "paymentCustomerForm-select-payStartMm",
                }}>
                <option value="" key="">
                  월
                </option>
                {PaymentData.fnCreateMonthOption("").map((element, index) => {
                  return (
                    <option value={element.value} key={index}>
                      {element.label}
                    </option>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell className="th">지급종료월</TableCell>
            <TableCell className="td">
              <Select
                native
                value={fnPastCheck(paymentInfo.payEndY4mm, "year")}
                onChange={handlePaymentInfoChange("payEndY4")}
                disabled={noneDeadline}
                inputProps={{
                  "data-testid": "paymentCustomerForm-select-payEndY4",
                }}>
                <option value="" key="">
                  연도
                </option>
                {PaymentData.fnCreateEndYearOption(paymentInfo && paymentInfo.payEndY4mm).map((element, index) => {
                  return (
                    <option value={element.value} key={index}>
                      {element.label}
                    </option>
                  );
                })}
              </Select>
              <Select
                native
                value={fnPastCheck(paymentInfo.payEndY4mm, "mm")}
                onChange={handlePaymentInfoChange("payEndMm")}
                disabled={noneDeadline}
                inputProps={{
                  "data-testid": "paymentCustomerForm-select-payEndMm",
                }}>
                <option value="" key="">
                  월
                </option>
                {PaymentData.fnCreateMonthOption("").map((element, index) => {
                  return (
                    <option value={element.value} key={index}>
                      {element.label}
                    </option>
                  );
                })}
              </Select>
              <FormGroup aria-label="position" name="position" row>
                <FormControlLabel checked={noneDeadline} control={<Checkbox onChange={(e) => handleDeadline(e)} color="primary" />} label="기한 없음" />
              </FormGroup>
            </TableCell>
          </TableRow>

          {calledFrom === "UPDATE" && (
            <TableRow className={calledFrom === "UPDATE" ? "" : "hidden"}>
              <TableCell className="th td-strong-cell">수정적용 희망월</TableCell>
              <TableCell className="td td-strong-cell" colSpan="3">
                <SelectForm
                  value={PaymentData.fnConvertY4mm(paymentInfo.expectedApplyY4mm, "year")}
                  handleChange={handlePaymentInfoChange("expectedApplyY4")}
                  name="expectedApplyY4"
                  arrayOption={PaymentData.fnCreateEndYearOption()}
                  optionValue={"value"}
                  optionLabel={"label"}
                  customProps={{ inputProps: { "data-testid": "paymentCustomerForm-select-expectedApplyY4" } }}
                />
                <Select
                  native
                  name="expectedApplyMm"
                  value={PaymentData.fnConvertY4mm(paymentInfo.expectedApplyY4mm, "mm")}
                  onChange={handlePaymentInfoChange("expectedApplyMm")}
                  inputProps={{
                    "data-testid": "paymentCustomerForm-select-expectedApplyMm",
                  }}>
                  <option value="">월</option>
                  {PaymentData.fnCreateMonthOption(paymentInfo).map((element, index) => {
                    return (
                      <option value={element.value} key={index}>
                        {element.label}
                      </option>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {paymentInfo.transactionMethod === "CMS_DEPOSIT" ? (
        <div>
          <h4>계좌정보</h4>
          <Table>
            {Common.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
            <TableBody>
              <TableRow>
                <TableCell className="th">금융기관</TableCell>
                <TableCell className="td">
                  <BankAutoComplete
                    list={financialRepresentativeInstitutes}
                    state={searchValue}
                    setState={setSearchValue}
                    className={"w180"}
                    customProps={{ "data-testid": "paymentCustomerForm-select-financialInstitutes" }}
                  />
                </TableCell>
                <TableCell className="th">계좌번호</TableCell>
                <TableCell className="td">
                  <Input
                    className="w160"
                    name="accountNo"
                    value={paymentInfo.account.accountNo}
                    onChange={handlePaymentInfoChange("account", "accountNo")}
                    required={paymentInfo.transactionMethod === "CMS_DEPOSIT" ? true : false}
                    inputProps={{
                      "data-testid": "paymentCustomerForm-input-accountNo",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">예금주</TableCell>
                <TableCell className="td">
                  <Input
                    className="w160"
                    name="depositorName"
                    value={paymentInfo.account.depositorName}
                    onChange={handlePaymentInfoChange("account", "depositorName")}
                    required={paymentInfo.transactionMethod === "CMS_DEPOSIT" ? true : false}
                    inputProps={{ "data-testid": "paymentCustomerForm-input-depositorName" }}
                  />
                  <FormControl component="fieldset">
                    <CheckboxForm
                      handleChange={handlePaymentInfoChange("depositorNameCheckBox")}
                      label="고객명과 동일"
                      customProps={{ inputProps: { "data-testid": "paymentCustomerForm-checkbox-depositorNameCheckBox" } }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className="th">
                  예금주 생년월일
                  <br />
                  (사업자번호)
                </TableCell>
                <TableCell className="td">
                  <Input
                    className="w160"
                    value={paymentInfo.account.depositorIdentificationNo}
                    onChange={handlePaymentInfoChange("account", "depositorIdentificationNo")}
                    required={paymentInfo.transactionMethod === "CMS_DEPOSIT" ? true : false}
                    inputProps={{ "data-testid": "paymentCustomerForm-input-depositorIdentificationNo" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">통장기재내용</TableCell>
                <TableCell className="td" colSpan={3}>
                  <span className="label-l">기본</span>
                  <Input className="w160" value={paymentInfo.bankbookContents} readOnly={true} />
                  <span className="label-l">추가</span>
                  <Select native value={paymentInfo.bankbookPostfixType} readOnly={true}>
                    {Common.cfnGetPostFixTypes().map((row, index) => {
                      return (
                        <option key={index} value={row.bankbookPostfixType}>
                          {row.bankbookPostfixTypeName}
                        </option>
                      );
                    })}
                  </Select>
                  <Input className="w100" value={paymentInfo.bankbookPostfix} readOnly={true} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">
                  입금정보 자동 알림
                  <TooltipForm contents="이용기관에서 입금 또는 출금 대상 고객에게 입출금 예정 금액이나 실제 입출금 결과를 SMS로 자동 통지해주는 서비스.<br/>발송 건당 16원(부가세 별도) 요금 부과" />
                </TableCell>
                <TableCell className="td" colSpan="3">
                  <FormControl component="fieldset">
                    <RadioGroup row>
                      <FormControlLabel
                        value=""
                        checked={
                          !(smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingDeposit && paymentInfo.sendForAskingDeposit) &&
                          !(smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnDeposit && paymentInfo.sendForSuccessOnDeposit) &&
                          !(smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnDeposit && paymentInfo.sendForFailureOnDeposit)
                        }
                        onChange={handlePaymentInfoChange("smsSend")}
                        control={<Radio color="primary" />}
                        label="고지안함"
                      />
                      <FormControlLabel
                        value="all"
                        checked={
                          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingDeposit && paymentInfo.sendForAskingDeposit) ||
                          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnDeposit && paymentInfo.sendForSuccessOnDeposit) ||
                          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnDeposit && paymentInfo.sendForFailureOnDeposit)
                        }
                        onChange={handlePaymentInfoChange("smsSend")}
                        control={<Radio color="primary" />}
                        disabled={
                          !smsToCustomerSetting.serviceUseYn ||
                          (!smsToCustomerSetting.sendForAskingDeposit && !smsToCustomerSetting.sendForFailureOnDeposit && !smsToCustomerSetting.sendForSuccessOnDeposit)
                        }
                        label={
                          <span className="radio-label-l">
                            고지
                            {!smsToCustomerSetting.serviceUseYn ||
                            (!smsToCustomerSetting.sendForAskingDeposit && !smsToCustomerSetting.sendForFailureOnDeposit && !smsToCustomerSetting.sendForSuccessOnDeposit) ? (
                              <TooltipForm contents="입출금 자동 알림 서비스를 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                            ) : null}
                          </span>
                        }
                      />
                      <FormControlLabel
                        value={"sendForAskingDeposit"}
                        checked={smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingDeposit && paymentInfo.sendForAskingDeposit}
                        onChange={handlePaymentInfoChange("smsSend", "sendForAskingDeposit")}
                        control={<Checkbox color="primary" />}
                        disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForAskingDeposit}
                        label={
                          <>
                            입금청구시
                            {smsToCustomerSetting.serviceUseYn &&
                              !smsToCustomerSetting.sendForAskingDeposit &&
                              (smsToCustomerSetting.sendForFailureOnDeposit || smsToCustomerSetting.sendForSuccessOnDeposit) && (
                                <TooltipForm contents="입금청구시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                              )}
                          </>
                        }
                      />
                      <FormControlLabel
                        value={"sendForSuccessOnDeposit"}
                        checked={smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnDeposit && paymentInfo.sendForSuccessOnDeposit}
                        onChange={handlePaymentInfoChange("smsSend", "sendForSuccessOnDeposit")}
                        control={<Checkbox color="primary" />}
                        disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForSuccessOnDeposit}
                        label={
                          <>
                            정상입금시
                            {smsToCustomerSetting.serviceUseYn &&
                              !smsToCustomerSetting.sendForSuccessOnDeposit &&
                              (smsToCustomerSetting.sendForFailureOnDeposit || smsToCustomerSetting.sendForAskingDeposit) && (
                                <TooltipForm contents="정상입금시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                              )}
                          </>
                        }
                      />
                      <FormControlLabel
                        value={"sendForFailureOnDeposit"}
                        checked={smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnDeposit && paymentInfo.sendForFailureOnDeposit}
                        onChange={handlePaymentInfoChange("smsSend", "sendForFailureOnDeposit")}
                        control={<Checkbox color="primary" />}
                        disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForFailureOnDeposit}
                        label={
                          <>
                            미입금시
                            {smsToCustomerSetting.serviceUseYn &&
                              !smsToCustomerSetting.sendForFailureOnDeposit &&
                              (smsToCustomerSetting.sendForAskingDeposit || smsToCustomerSetting.sendForSuccessOnDeposit) && (
                                <TooltipForm contents="미입금시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                              )}
                          </>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : (
        <div />
      )}

      <CapitalsModal open={capitalOpen} setOpen={setCapitalOpen} store={store} setCapitals={fnSetCapitals} />
    </Fragment>
  );
};
