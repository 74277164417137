import { Button, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import * as CM from "../common/Common";
import { EvidenceFileTypeInformation, FolderUploadForm, SelectForm } from "./ComponentForm";
import TableForm from "./TableForm";

/*
 * @desc    메인 컴포넌트 선언
 */
const FolderRegisterModal = (props) => {
  const { open, setOpen, fnSubmitBulkEvidenceFile } = props;

  //모달창 사용 state 초기화를 위한 default 값
  const defaultValue = {
    files: [],
    folderName: "",
  };

  //필요 state 선언
  const [evidenceType, setEvidenceType] = useState(""); //동의자료 구분 select 제어
  const [selectedFolder, setSelectedFolder] = useState(defaultValue); //선택된 폴더 및 파일 목록

  /*
   * @desc    동의자료 구분 셀렉트박스 변경 이벤트 핸들러
   */
  const handleEvidenceTypeChange = (e) => {
    setEvidenceType(e.target.value);
  };

  /*
   * @desc    일괄등록 버튼 클릭 이벤트 핸들러
   */
  const handleFileBulkRegister = (event) => {
    if (fnCheckValidation()) CM.cfnConfirm("기존 등록내용은 덮어씁니다. 등록하시겠습니까?", fnRegisterFiles);
  };

  /*
   * @desc    모달창 닫기 이벤트 핸들러
   */
  const handleClose = () => {
    setSelectedFolder(defaultValue);
    setOpen(false);
  };

  /*
   * @desc    validation 검증 함수
   */
  const fnCheckValidation = () => {
    if (evidenceType === "") {
      CM.cfnAlert("동의자료 구분을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (CM.cfnIsEmpty(selectedFolder.folderName)) {
      CM.cfnAlert("업로드할 폴더를 선택하여 주시기 바랍니다.");
      return false;
    }

    return true;
  };

  /*
   * @desc    선택된 폴더 내 개별 파일의 validation 검증 함수
   */
  const fnCheckEachFileValidation = (file) => {
    const regExp = /[^0-9a-zA-Z]/;
    const fileSize = file.size;

    //영문, 숫자 외 다른 문자 있을 시 해당 파일을 제외시킴
    const fileName = file.name.substring(0, file.name.lastIndexOf("."));
    if (regExp.test(fileName)) return false;

    if (evidenceType === "PUBLIC_SIGNATURE" && fileSize > 10 * 1024) {
      CM.cfnAlert(`공인전자서명파일의 용량은 10KB 이내여야 합니다.\n선택된 파일 용량 : ${Math.round(fileSize / 1024, 0)}KB`);
      return false;
    } else if (fileSize > 300 * 1024) {
      CM.cfnAlert(`첨부자료의 용량은 300KB 이내여야 합니다.\n선택된 파일 용량 : ${Math.round(fileSize / 1024, 0)}KB`);
      return false;
    } else {
      //증빙파일 유형 선택에 따른 파일타입 체크
      const fileFullName = file.name;
      const fileType = fileFullName.substring(fileFullName.lastIndexOf(".") + 1).toUpperCase();

      switch (evidenceType) {
        case "PAPER":
        case "GENERAL_SIGNATURE":
          if (fileType !== "JPG" && fileType !== "JPEG" && fileType !== "GIF" && fileType !== "TIF" && fileType !== "PDF") {
            return false;
          }
          break;
        case "RECORDING":
        case "ARS":
          if (fileType !== "MP3" && fileType !== "WAV" && fileType !== "WMA") {
            return false;
          }
          break;
        case "PUBLIC_SIGNATURE":
          if (fileType !== "DER") {
            return false;
          }
          break;
        default:
          if (
            fileType !== "JPG" &&
            fileType !== "JPEG" &&
            fileType !== "GIF" &&
            fileType !== "TIF" &&
            fileType !== "PDF" &&
            fileType !== "MP3" &&
            fileType !== "WAV" &&
            fileType !== "WMA" &&
            fileType !== "DER" &&
            fileType !== "TXT"
          ) {
            return false;
          }
          break;
      }
    }

    return true;
  };

  /*
   * @desc    동의자료 일괄등록 Request
   */
  const fnRegisterFiles = () => {
    let form = [];

    for (let i = 0; i < selectedFolder.files.length; i++) {
      const file = selectedFolder.files[i].file;

      if (fnCheckEachFileValidation(file)) {
        form.push({ evidenceFileList: file, evidenceFileType: evidenceType });
      }
    }

    fnSubmitBulkEvidenceFile(form);
  };

  const readableSize = (size) => {
    let u,
      b = size;
    ["", "K", "M", "G", "T", "P", "E", "Z", "Y"].find((x) => {
      u = x;
      b /= 1024;
      return b ** 2 < 1;
    });
    return `${u ? (1024 * b).toFixed(1) : size}${u}${u ? "i" : ""}B`;
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" data-testid="open-modal" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>동의자료 일괄등록</h3>
          <div className="inforbox">
            <ul>
              <li>신규 및 변경신규 일 경우에는 반드시 자동납부 동의자료가 첨부되어야 합니다.</li>
              <li>동의자료가 저장된 폴더를 선택해 주세요.</li>
              <li>일괄등록 시 동의자료 파일명은 납부자번호와 동일해야 합니다. ( 예: 001.jpg, 001.pdf )</li>
            </ul>
          </div>

          <div className="search-area">
            <div className="block">
              <form name="FileRegisters">
                <label className="label-l" data-testid="type-label">
                  동의자료 구분
                </label>
                <SelectForm
                  value={evidenceType}
                  handleChange={handleEvidenceTypeChange}
                  name="evidenceFileType"
                  arrayOption={CM.cfnGetEvidenceFileTypeNumber()}
                  optionValue={"evidenceFileType"}
                  optionLabel={"evidenceFileTypeName"}
                  customProps={{
                    inputProps: { "data-testid": "file-type-select" },
                  }}
                />
                <label className="label-l">폴더선택</label>
                <FolderUploadForm uploadFolder={selectedFolder} setUploadFolder={setSelectedFolder} />
              </form>
            </div>
          </div>

          <Table aria-labelledby="tableTitle" data-testid="FileRegisters-table">
            {CM.cfnCompColGroup(["10%", "auto", "10%"])}
            <TableForm.compTableHead arrData={["순번", "동의자료 파일명", "파일크기"]} />
            <TableBody>
              {selectedFolder.files.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={3} />
              ) : (
                CM.cfnIsNotEmpty(selectedFolder.files) &&
                selectedFolder.files.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.file.name}</TableCell>
                      <TableCell align="center">{readableSize(row.file.size)}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <div className="table-bottom-area">
            <button className="btn-l" onClick={handleFileBulkRegister}>
              일괄등록
            </button>
          </div>
          <h4>자동납부 동의자료 구분 및 허용 용량</h4>
          <EvidenceFileTypeInformation />
        </div>
      </div>
    </Modal>
  );
};

export default FolderRegisterModal;
