import * as CM from "../../common/Common";

//paging 처리
export const paginationData = (data) => {
  const returnObject = {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  };

  if (CM.cfnIsNotEmpty(data)) {
    returnObject.rowsPerPage = data.pageable.pageSize;
    returnObject.offset = data.pageable.offset;
    returnObject.total = data.totalElements;
    returnObject.totalPages = data.totalPages;
  }

  return returnObject;
};

//CMS입금관리 > CMS 입금 요청 탭에서 입금요청일 세팅하는 함수
export const fnGetNextCmsPaymentDate = async () => {
  let today = new Date();
  const currentYear = today.getFullYear().toString();
  const currentMonth = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
  const currentDate = today.getDate().toString() > 9 ? today.getDate().toString() : `0${today.getDate().toString()}`;
  const currentTime = (today.getHours() > 9 ? today.getHours() : `0${today.getHours()}`) + "" + (today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`);

  //휴일 조회 Request
  const fnFetchPeriodDate = (paramDate) => {
    return new Promise((resolve) => {
      const url = `api/gadget/bizDate/period?startDate=${paramDate}&endDate=${paramDate}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        if (data.length !== 0) {
          resolve(data[0].isHoliday);
        } else {
          resolve(false);
        }
      });
    });
  };

  //기준일자
  let requestDate = new Date();
  let periodDate = await fnFetchPeriodDate(`${currentYear}${currentMonth}${currentDate}`);

  return new Promise(async (resolve) => {
    if (periodDate || currentTime > "1400") {
      //휴일
      let tempRequestDate = await CM.fnGetNextBizDate(`${currentYear}${currentMonth}${currentDate}`);
      requestDate = await CM.fnGetNextBizDate(tempRequestDate);
    } else {
      requestDate = await CM.fnGetNextBizDate(`${currentYear}${currentMonth}${currentDate}`);
    }
    resolve(requestDate);
  });
};

//cms 입금요청 - 입금요청대상 요약정보
export const cmsDefaultSummary = {
  amountOfFullError: 0,
  amountOfFullWithdraw: 0,
  amountOfPartialError: 0,
  amountOfPartialWithdraw: 0,
  askTargetCriteria: "",
  fileStatus: "READY",
  fileType: true,
  numberOfAskedRecords: 0,
  numberOfExceededLimit: 0,
  numberOfFullError: 0,
  numberOfFullWithdraw: 0,
  numberOfPartialWithdraw: 0,
  numberOfRegistrationError: 0,
  numberOfUnregistrationError: 0,
  numberOfZeroAmount: 0,
  receivedFileDatetime: "",
  sentFileDatetime: "",
  targetIntegratedYn: true,
  totalAskingAmount: 0,
  totalAskingDelayFee: 0,
  totalAskingNumberOfRegistration: 0,
  totalAskingNumberOfUnregistration: 0,
  totalAskingPreviouslyUnaskedAmount: 0,
  totalAskingThisTimeAmount: 0,
  totalAskingUnpaidAmount: 0,
  totalBankFee: 0,
  transactionDate: new Date(),
  uniqueKey: "",
  reaskUnpaidLimit: true,
};

//cms 입금요청 - 입금요청대상 체크박스 제어
export const defaultCheckbox = {
  thisTime: false,
  unasked: false,
  unpaid: false,
};

//cms 입금요청 - 입금요청대상 필터 셀렉트 박스
export const targetOption = [
  { label: "고객명", value: "customerName" },
  { label: "생년월일(사업자번호)", value: "identificationNo" },
];

//cms 입금요청 - CMS 입금요청대상(완료내역) 목록 조회 조건
export const requestSearchData = {
  pageNumber: 0,
  pageSize: 5,
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  resultCode: "",
  groupUniqueKey: "",
  customerName: "",
  identificationNo: "",
  capitalUniqueKey: "",
  fileUniqueKey: "",
  term: "customerName",
  keyword: "",
};

//cms 입금요청 - 입금대상추가 모달 조회 조건
export const addTargetSearchData = {
  capitalUniqueKey: "",
  customerName: "",
  fileUniqueKey: "",
  groupUniqueKey: "",
  pageNumber: 0,
  pageSize: 5,
  identificationNo: "",
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  term: "customerName",
  keyword: "",
};

//cms 입금요청 - 입금대상추가 모달 조회 조건 내 검색키워드 셀렉트 옵션
export const targetSearchKeyword = [
  {
    label: "고객명",
    value: "customerName",
  },
  {
    label: "생년월일(사업자번호)",
    value: "identificationNo",
  },
];

//cms 입금요청 - 입금금액수정 모달 조회 조건 내 검색키워드 셀렉트 옵션
export const targetSearchKeywordForUpdate = [
  {
    label: "고객명",
    value: "CUSTOMER_NAME",
  },
  {
    label: "생년월일(사업자번호)",
    value: "IDENTIFICATION_NO",
  },
  { label: "청구대상구분",
    value: "ASK_UNIQUEKEY",
  },
  {
    label: "지급연월",
    value: "TARGET_Y4MM",
  },
];

//cms 입금요청 - 입금대상추가 모달 조회 조건 내 수납방법 셀렉트 옵션
export const transactionMethodOption = [
  {
    label: "수납방법(전체)",
    value: "",
  },
  {
    label: "CMS",
    value: "CMS_DEPOSIT",
  },
  {
    label: "기타",
    value: "ETC",
  },
];

//cms 입금요청 - 입금 요청 대상 수정 모달 조회 조건
export const updateTargetSearchData = {
  capitalUniqueKey: "",
  customerName: "",
  fileUniqueKey: "",
  groupUniqueKey: "",
  pageNumber: 0,
  pageSize: 5,
  identificationNo: "",
  askUniqueKey: "",
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  term: "CUSTOMER_NAME",
  keyword: "",
};

//cms 입금요청 결과 조회 조건
export const resultSearchData = () => {
  const today = new Date();
  const endDate = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate());
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return {
    searchStartDate: monthAgo,
    searchEndDate: endDate,
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "SENT_FILE_DATETIME",
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
  };
};

//cms 입금요청 결과 - 입금요청 요약정보로 체크박스 제어 state 생성
export const convertFromCmsAskTargetCriteria = (value) => {
  let tempObj = {
    thisTime: false,
    unasked: false,
    unpaid: false,
  };

  switch (value) {
    case "THIS_TIME_ONLY":
      tempObj.thisTime = true;
      break;
    case "UNASKED_ONLY":
      tempObj.unasked = true;
      break;
    case "UNPAID_ONLY":
      tempObj.unpaid = true;
      break;
    case "THIS_TIME_AND_UNASKED":
      tempObj.thisTime = true;
      tempObj.unasked = true;
      break;
    case "THIS_TIME_AND_UNPAID":
      tempObj.thisTime = true;
      tempObj.unpaid = true;
      break;
    case "UNASKED_AND_UNPAID":
      tempObj.unasked = true;
      tempObj.unpaid = true;
      break;
    case "THIS_TIME_AND_UNASKED_AND_UNPAID":
      tempObj.thisTime = true;
      tempObj.unasked = true;
      tempObj.unpaid = true;
      break;
    default:
      break;
  }

  return tempObj;
};

//cms 입금요청 결과 - 체크박스 값을 가공하여 입금요청 요약정보 생성
export const convertToTargetCriteria = (targetCheckbox) => {
  let targetCriteria = "";

  if (targetCheckbox.thisTime) {
    if (targetCheckbox.unasked) {
      if (targetCheckbox.unpaid) {
        //당회분 O & 미청구분 O & 미납분 O
        targetCriteria = "THIS_TIME_AND_UNASKED_AND_UNPAID";
      } else {
        //당회분 O & 미청구분 O & 미납분 X
        targetCriteria = "THIS_TIME_AND_UNASKED";
      }
    } else {
      if (targetCheckbox.unpaid) {
        //당회분 O & 미청구분 X & 미납분 O
        targetCriteria = "THIS_TIME_AND_UNPAID";
      } else {
        //당회분 O & 미청구분 X & 미납분 X
        targetCriteria = "THIS_TIME_ONLY";
      }
    }
  } else {
    if (targetCheckbox.unasked) {
      if (targetCheckbox.unpaid) {
        //당회분 X & 미청구분 O & 미납분 O
        targetCriteria = "UNASKED_AND_UNPAID";
      } else {
        //당회분 X & 미청구분 O & 미납분 X
        targetCriteria = "UNASKED_ONLY";
      }
    } else {
      if (targetCheckbox.unpaid) {
        //당회분 X & 미청구분 X & 미납분 O
        targetCriteria = "UNPAID_ONLY";
      }
    }
  }

  return targetCriteria;
};

//cms 입금요청 결과 - 요청상태
export const fileStatus = (value) => {
  switch (value) {
    case "READY":
      return "청구대기중";
    case "TRANSMITTED":
      return "청구완료";
    case "APPLY_NEEDED":
      return "미납분";
    case "THIS_TIME_AND_UNASKED":
      return "결과수신완료 및 결과반영필요";
    case "ERROR_ON_TRANSMISSION":
      return "청구중오류";
    case "RECEIVED_ERROR_FILE":
      return "오류응답수신";
    case "APPLIED_RESULT_FILE":
      return "결과반영완료";
    default:
      return value;
  }
};

//cms 입금청구 결과 - 파일종류
export const fileType = (value) => {
  switch (value) {
    case "EB21":
      return "익일출금";
    case "EC21":
      return "당일출금";
    case "EB31":
      return "입금이체";
    case "EB13":
      return "이용기관접수분";
    case "EB11":
      return "금융기관접수분";
    case "EA44":
      return "현금영수증자동발행";
    case "BO11":
      return "현금영수증직접발행";
    default:
      return value;
  }
};

//cms 입금청구 - 검색조건 내 셀렉트 옵션 생성
export const fnMakeSelectOptions = (type, list) => {
  const tempArray = [];

  if (type === "capital" && CM.cfnIsNotEmpty(list)) {
    //자금종류 검색 셀렉트 옵션
    for (let i = 0; i < list.length; i++) {
      tempArray.push({
        label: list[i].capitalName,
        value: list[i].uniqueKey,
      });
    }
  } else if (type === "group" && CM.cfnIsNotEmpty(list)) {
    //고객구분 검색 셀렉트 옵션
    for (let i = 0; i < list.length; i++) {
      tempArray.push({
        label: list[i].customerGroupName,
        value: list[i].uniqueKey,
      });
    }
  }
  return tempArray;
};

//cms 입금청구 결과 - 청구상태
export const fnGetStatusButton = async (row) => {
  let statusObj = {
    statusName: "",
    disabled: false,
    message: "",
    isResultApplyingRequired: false, //결과확인 버튼 렌더 시 "결과확인" 기능 필요 여부 (사용자가 청구결과를 조회했는지 확인 후 CMS 입금 청구 결과 목록 재조회)
  };

  if (row.transactionDate.length > 0) {
    const currentTime = new Date();
    const fileDate = `${row.transactionDate}`;

    let prevBizDate, eb31SendableTime, eb31CancelableTime, eb31ConfirmTime;
    if (row.fileType === "EB31") {
      switch (row.fileStatus) {
        case "READY":
          prevBizDate = await CM.fnGetPrevBizDate(fileDate);
          eb31SendableTime = new Date(`${prevBizDate.toString().substr(0, 4)}-${prevBizDate.toString().substr(4, 2)}-${prevBizDate.toString().substr(6, 2)}T14:00:00`); //송신가능시간

          if (currentTime < eb31SendableTime) {
            //EB31 파일전송 가능시간 경과 전
            statusObj.disabled = false;
            statusObj.statusName = "청구대기";
            statusObj.message = "입금대상 생성 후 청구하기를 클릭하지 않은 상태입니다.<br> 버튼을 눌러 CMS 입금청구 탭으로 이동하여 청구하기를 클릭해주세요.";
          } else {
            statusObj.disabled = true;
            statusObj.statusName = "청구불가";
            statusObj.message = "전송 가능 시간을 초과하였습니다. <br>CMS 입금청구 탭에서 청구대상을 삭제하여 주시기 바랍니다.";
          }
          break;
        case "TRANSMITTED":
          prevBizDate = await CM.fnGetPrevBizDate(fileDate);

          eb31CancelableTime = new Date(`${prevBizDate.toString().substr(0, 4)}-${prevBizDate.toString().substr(4, 2)}-${prevBizDate.toString().substr(6, 2)}T14:40:00`); //송신취소가능시간
          eb31ConfirmTime = new Date(`${fileDate.substr(0, 4)}-${fileDate.substr(4, 2)}-${fileDate.substr(6, 2)}T16:30:00`); //결과확인시간

          if (currentTime < eb31CancelableTime) {
            //취소가능시간이 지나기 전일 경우
            statusObj.disabled = false;
            statusObj.statusName = "취소요청";
            statusObj.message = "청구마감 시점이 도래하지 않아 청구취소가 가능한 상태입니다."
          } else {
            if (currentTime >= eb31ConfirmTime) {
              //결과확인가능시간 경과 후
              statusObj.disabled = false;
              statusObj.statusName = "결과확인";
              statusObj.isBackupRequired = true;
              statusObj.message = "청구한 내역의 입금결과를 수신할 수 있습니다.<br> 버튼을 눌러 결과를 확인해주세요.";
            } else {
              //취소가능시간 경과 후
              statusObj.disabled = true;
              statusObj.statusName = "결과확인";
              statusObj.message = `취소요청 가능 시간을 초과하였습니다. <br>${fileDate.toString().substr(0, 4)}-${fileDate.toString().substr(4, 2)}-${fileDate
                .toString()
                .substr(6, 2)} 16시 이후에 입금결과를 확인할 수 있습니다.`;
            }
          }
          break;
        case "APPLIED_RESULT_FILE":
          statusObj.disabled = false;
          statusObj.statusName = "결과확인";
          statusObj.isResultApplyingRequired = true;
          statusObj.message = "청구한 내역의 실제 입금여부를 확인할 수 있습니다.<br> 버튼을 눌러 결과를 확인해주세요."
          break;
        case "CONFIRMED_RESULT_FILE":
          statusObj.disabled = true;
          statusObj.statusName = "확인완료";
          break;
        default:
          break;
      }
    }
  }
  return statusObj;
};
