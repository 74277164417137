import React, { useEffect, useState } from 'react';
import * as Cm from '../../common/Common';
import { Modal, Button, Input, FormControl, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { MaskedField, BankAutoComplete } from '../../template/ComponentForm';

const AccountUpdateModal = (props) => {
  const { open, setOpen, value, financialInstitutes, financialRepresentativeInstitutes, accountValue, setAccountValue, handleAccountChange, fnAccountUpdate } = props;
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  //필요 state 선언
  const [isBankSelected, setIsBankSelected] = useState(false);
  const [searchValue, setSearchValue] = useState({
    //선택된 계좌정보 저장을 위한 state
    accountNo: '',
    depositorIdentification: '',
    financialInstituteCode: '',
    fncInsNm: ''
  });

  const submitAccountUpdate = () => {
    if(searchValue.financialInstituteCode === "" || !searchValue.financialInstituteCode){
      Cm.cfnAlert("변경할 출금계좌의 금융기관을 선택해주세요.");
    }else{
      fnAccountUpdate();
    }
  }

  useEffect(() => {
    //금융기관 선택 시 accountValue에 값 세팅
    if (isBankSelected) {
      setIsBankSelected(false);
      setAccountValue(data => ({
        ...data,
        accountBankCode: searchValue.financialInstituteCode
      }));
    }
  }, [isBankSelected, searchValue.financialInstituteCode, setAccountValue]);

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">{""}</Button>
          </div>
          <h3>수납계좌변경</h3>
          <h4>변경 전</h4>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="th w130">납부자 번호</TableCell>
                <TableCell className="td">{value.payerNo}</TableCell>
                <TableCell className="th w130">수납방법</TableCell>
                <TableCell className="td">{value.transactionMethod === 'CMS_WITHDRAW' ? 'CMS' : '기타'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">금융기관</TableCell>
                <TableCell className="td">
                  {Object.isExtensible(value.account) ? Cm.cfnMatchBankName(value.account.accountBankCode, financialInstitutes) : ''}
                </TableCell>
                <TableCell className="th">계좌번호</TableCell>
                <TableCell className="td"> {Object.isExtensible(value.account) ? Cm.cfnMasking(value.account.accountNo) : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">예금주</TableCell>
                <TableCell className="td">{Object.isExtensible(value.account) ? value.account.depositorName : ''}</TableCell>
                <TableCell className="th">
                  예금주생년월일
                  <br />
                  (사업자번호)
                </TableCell>
                <TableCell className="td">
                  {Object.isExtensible(value.account) ? Cm.cfnIdentificationNoFormat(value.account.depositorIdentificationNo) : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">예금주연락처 </TableCell>
                <TableCell className="td" colSpan="3">
                  {Cm.cfnAddtDashToPhoneNumber(Object.isExtensible(value.account) ? value.account.depositorTelephone : '')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <h4>변경 후</h4>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="th w130">금융기관</TableCell>
                <TableCell className="td">
                  <BankAutoComplete list={financialRepresentativeInstitutes} state={searchValue} setState={setSearchValue} setFlag={setIsBankSelected} />
                </TableCell>
                <TableCell className="th w130">계좌번호</TableCell>
                <TableCell className="td">
                  <Input
                    className="w200"
                    value={accountValue.accountNo}
                    data-testid="accountUpdate-accountNo"
                    inputProps={{
                      'aria-label': 'description'
                    }}
                    onChange={handleAccountChange('accountNo')}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">예금주</TableCell>
                <TableCell className="td">
                  <Input
                    className="w200"
                    value={accountValue.depositorName}
                    data-testid="accountUpdate-depositorName"
                    onChange={handleAccountChange('depositorName')}
                    inputProps={{
                      'aria-label': 'description'
                    }}
                  />
                </TableCell>
                <TableCell className="th">
                  예금주생년월일
                  <br />
                  (사업자번호)
                </TableCell>
                <TableCell className="td">
                  <Input
                    className="w200"
                    value={accountValue.depositorIdentificationNo}
                    data-testid="accountUpdate-depositorIdentificationNo"
                    onChange={handleAccountChange('depositorIdentificationNo')}
                    inputProps={{
                      'aria-label': 'description'
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">예금주연락처 </TableCell>
                <TableCell className="td" colSpan="3">
                  <FormControl>
                    <Input
                      className="w200"
                      value={accountValue.depositorTelephone}
                      data-testid="accountUpdate-depositorTelephone"
                      onChange={handleAccountChange('depositorTelephone')}
                      required={true}
                      inputComponent={MaskedField}
                      inputProps={{
                        mask: 'mobile'
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="table-bottom-area">
            <Button className="btn-l" data-testid="cc" onClick={submitAccountUpdate}>
              계좌변경
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AccountUpdateModal;