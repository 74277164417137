import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as Data from "./PaymentStatisticsChartEnum";
import * as PaymentStatisticsChartEnum from "./PaymentStatisticsChartEnum";

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

function CreateSearchForm(props) {
  const { searchOption, customerGroups, changeSearchFormHandler, changeRadioValueHandler, capitalUniqueKey, changeSearchRequest } = props;

  const fnClickSearch = () => {
    changeSearchRequest();
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">지급기간</label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchOption.searchStartDate}
          handleChange={changeSearchFormHandler("searchStartDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchOption.searchEndDate}
          handleChange={changeSearchFormHandler("searchEndDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <div className="margin-right-20 display-block" />
        <label className="label-l">고객구분</label>
        <Select native data-testid="groupUniqueKeySelectBox" value={searchOption.groupUniqueKey} onChange={changeSearchFormHandler("groupUniqueKey")}>
          <option value="">전체</option>
          {CM.cfnIsNotEmpty(customerGroups) &&
            customerGroups.map((row, index) => {
              return (
                <option key={index} value={row.uniqueKey}>
                  {row.customerGroupName}
                </option>
              );
            })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native data-testid="capitalUniqueKeySelectBox" value={searchOption.capitalUniqueKey} onChange={changeSearchFormHandler("capitalUniqueKey")} name="capitalUniqueKey">
          {capitalUniqueKey.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <br />
        <br />
        <label className="label-l">지급상태</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="askResult" name="askResult" row>
            <RadioGroup name="askResult" value={searchOption.askResult} onChange={changeRadioValueHandler("askResult")} data-testid="print-settings-size" row>
              <FormControlLabel value="" control={<Radio color="primary" />} label="전체" />
              <FormControlLabel value="PAID" control={<Radio color="primary" />} label="지급완료" />
              <FormControlLabel value="PARTIALLY_PAID" control={<Radio color="primary" />} label="부분지급" />
              <FormControlLabel value="UNPAID" control={<Radio color="primary" />} label="미지급" />
              <FormControlLabel value="DIRECTLY_PAID" control={<Radio color="primary" />} label="직접지급" />
              <FormControlLabel value="REFUND" control={<Radio color="primary" />} label="환입" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <div className="margin-right-20 display-block" />
        <label className="label-l">지급방법</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="transactionMethod" name="transactionMethod" row>
            <RadioGroup name="transactionMethod" value={searchOption.transactionMethod} onChange={changeRadioValueHandler("transactionMethod")} data-testid="transactionMethod" row>
              <FormControlLabel value="" control={<Radio color="primary" />} label="전체" />
              <FormControlLabel value="CMS_DEPOSIT" control={<Radio color="primary" />} label="CMS" />
              <FormControlLabel value="ETC" control={<Radio color="primary" />} label="기타" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <br />
        <br />
        <label className="label-l">자금유형</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="payAmountType" name="payAmountType" row>
            <RadioGroup name="size" value={searchOption.payAmountType} onChange={changeRadioValueHandler("payAmountType")} data-testid="print-settings-size" row>
              <FormControlLabel value="" control={<Radio color="primary" />} label="전체" />
              <FormControlLabel value="FIXED" control={<Radio color="primary" />} label="정액" />
              <FormControlLabel value="VARIABLE" control={<Radio color="primary" />} label="비정액" />
              <FormControlLabel value="TEMPORARY_ADDED" control={<Radio color="primary" />} label="추가분" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <div className="margin-right-20 display-block" />
        <label className="label-l">검색어입력</label>
        <Select native data-testid="searchProperty_selectBox" className="w110" name="searchProperty" value={searchOption.searchProperty} onChange={changeSearchFormHandler("searchProperty")}>
          {PaymentStatisticsChartEnum.getDailyStatisticsSelectBoxOption("searchProperty").map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField
          data-testid="searchContents_inputBox"
          className="w130"
          value={searchOption.searchContents}
          onChange={changeSearchFormHandler("searchContents")}
          // onKeyUp={handleSearKeyUp}
          name="searchContents"
        />
        <button className="search-button" onClick={() => fnClickSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

function CreateTopListForm(props) {
  return (
    <div>
      <h4>조회결과</h4>
      <Table>
        <TableBody>
          <TableRow data-testid={"topList"}>
            <TableCell className="th"> 지급대상금액 </TableCell>
            <TableCell className="td" align="right">
              {CM.cfnAddComma(props.topList.totalAskingAmount)}원
            </TableCell>
            <TableCell className="th"> CMS지급금액 </TableCell>
            <TableCell className="td" align="right">
              {CM.cfnAddComma(props.topList.totalPaidAmountByCms)}원
            </TableCell>
            <TableCell className="th"> 직접지급금액 </TableCell>
            <TableCell className="td" align="right">
              {CM.cfnAddComma(props.topList.totalDirectlyPaidAmount)}원
            </TableCell>
            <TableCell className="th"> 미지급금액 </TableCell>
            <TableCell className="td" align="right">
              {CM.cfnAddComma(props.topList.totalUnpaidAmount)}원
            </TableCell>
            <TableCell className="th"> 환입금액 </TableCell>
            <TableCell className="td" align="right">
              {CM.cfnAddComma(props.topList.totalRefundedAmount)}원
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

function CreateBottomListForm(props) {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["9%", "9%", "9%", "9%", "9%", "9%", "9%", "9%", "9%", "auto"])}
        <TableHead className="table-head-line">
          <TableRow>
            <TableCell className="th">지급일</TableCell>
            <TableCell className="th">고객명</TableCell>
            <TableCell className="th">
              생년월일
              <br />
              (사업자번호)
            </TableCell>
            <TableCell className="th">지급방법</TableCell>
            <TableCell className="th">고객구분</TableCell>
            <TableCell className="th">자금종류</TableCell>
            <TableCell className="th">지급대상금액</TableCell>
            <TableCell className="th">지급금액</TableCell>
            <TableCell className="th">지급결과</TableCell>
            <TableCell className="th">대상월</TableCell>
            <TableCell className="th">비고</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.bottomList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={11} />
          ) : (
            props.bottomList.map((row, index) => {
              return (
                <TableRow data-testid={"detailList2_" + index} key={index}>
                  <TableCell align="center" className="td">
                    {CM.cfnDateFormat(row.transactionDate, "yyyyMMdd")}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {row.customerName}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {CM.cfnIdentificationNoFormat(row.identificationNo || "")}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {PaymentStatisticsChartEnum.getApiAvailableLabel(row.transactionMethod)}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {row.customerGroupName}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {row.capitalName}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.askingAmount)}원
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.paidAmount)}원
                  </TableCell>
                  <TableCell align="center" className="td">
                    {row.transactionType === "REFUND" ? "환입" : Data.getApiAvailableLabel(row.askResult)}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                  </TableCell>
                  <TableCell align="center" className="td">
                    {row.remark}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

function defaultSearchParam() {
  const today = new Date();

  const lastYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  return {
    searchStartDate: lastYear, //검색 시작 지급일
    searchEndDate: today, //검색 종료 지급일
    searchContents: "", //검색내용
    searchProperty: "CUSTOMER_NAME", //검색대상 - CUSTOMER_NAME(고객이름), IDENTIFICATION_NO(생년월일사업자번호)
    groupUniqueKey: "", //고객그룹 구분key
    askResult: "", //고객지급상태 - null 또는 empty(전체), PAID(완납), PARTIALLY_PAID(부분납), UNPAID(미납), DIRECTLY_PAID(직접수납), REFUND(환불)
    payAmountType: "", //지급금액구분 - VARIABLE(비정액), FIXED(정액), TEMPORARY_ADDED(추가분)
    transactionMethod: "", //지급방법 - null 또는 empty(전체), CMS_WITHDRAW(CMS출금), ETC(기타)
    capitalUniqueKey: "", //자금종류key
    sortProperty: "CUSTOMER_NAME", //정렬기준 -  CUSTOMER_NAME(고객이름), IDENTIFICATION_NO(생년월일사업자번호)
    sortDirection: "ASC", //정렬방향 - ASC(오름차순), DESC(내림차순)
    pageNumber: "0", //페이지 번호
    pageSize: "5", //페이지 크기
  };
}

//Main Component
const PaymentDailyStatistics = (props) => {
  const store = props.store;
  const { tabIndex } = props;
  const [searchParam, setSearchParam] = useState(defaultSearchParam());
  const [searchOption, setSearchOption] = useState(defaultSearchParam());
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [topList, setTopList] = useState([]);
  const [bottomList, setBottomList] = useState([]);
  const [pagination, setPagination] = useState(paginationData());
  const [bottomListTotalCount, setBottomListTotalCount] = useState();
  const [capitalUniqueKey, setCapitalUniqueKey] = useState([]);
  const [excelButton, setExcelButton] = useState(false);
  /*
   * @desc    자금종류 셀렉트박스 옵션 요소 생성 함수
   */
  const fnSetCapitalOptions = (data) => {
    let tempArray = [];
    tempArray.push({ label: "전체", value: "", key: "" });
    if (CM.cfnIsNotEmpty(data)) {
      data.forEach((row) => {
        if (row.capitalType === "PAYMENT")
          tempArray.push({
            label: row.capitalName,
            value: row.uniqueKey,
            key: row.uniqueKey,
          });
      });
    }
    setCapitalUniqueKey(tempArray);
  };

  const [customerGroups, setCustomerGroups] = useState([]);

  useEffect(() => {
    const startStoreAxios = async () => {
      // 고객구분 option data 설정
      const customerGroupList = await store.axiosCustomerGroup();
      setCustomerGroups(customerGroupList);
    };

    startStoreAxios();
  }, [store]);

  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      await store.getCapital(fnSetCapitalList);
    };

    /*
     * @desc    자금종류 조회 Request Callback
     */
    const fnSetCapitalList = (objStatus, responseData) => {
      if (objStatus.status === 200) {
        fnSetCapitalOptions(responseData);
      }
    };

    if (CM.cfnIsEmpty(store.capital.$mobx.values)) {
      fnFetchCapitalList();
    } else {
      fnSetCapitalOptions(toJS(store.capital));
    }

    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchParam);

      tempObj.searchStartDate = CM.cfnConvertDateToString(searchParam.searchStartDate);
      tempObj.searchEndDate = CM.cfnConvertDateToString(searchParam.searchEndDate);

      return tempObj;
    };

    const getTopListAxios = (queryString) => {
      return new Promise((resolve) => {
        let url = `api/payment/statistics/date/summary` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseTopListData) => {
          resolve(responseTopListData);
        });
      });
    };

    const getBottomListAxios = (queryString) => {
      return new Promise((resolve) => {
        let url = `api/payment/statistics/date` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseBottomListData) => {
          resolve(responseBottomListData);
        });
      });
    };

    const getExcelListAxios = (queryString) => {
      let url = "/api/payment/statistics/date/excel" + queryString;
      CM.cfnAxiosFileDownload(url, "get", "", () => {});
    };

    const startAxios = async (searchParam) => {
      let queryString = `?searchStartDate=${searchParam.searchStartDate}&searchEndDate=${searchParam.searchEndDate}&searchContents=${searchParam.searchContents}&searchProperty=${searchParam.searchProperty}&groupUniqueKey=${searchParam.groupUniqueKey}&askResult=${searchParam.askResult}&payAmountType=${searchParam.payAmountType}&transactionMethod=${searchParam.transactionMethod}&capitalUniqueKey=${searchParam.capitalUniqueKey}&sortProperty=${searchParam.sortProperty}&sortDirection=${searchParam.sortDirection}&pageNumber=${searchParam.pageNumber}&pageSize=${searchParam.pageSize}`;

      if (excelButton === true) {
        setExcelButton(false);
        await getExcelListAxios(queryString);
      } else if (searchButton === true) {
        setSearchButton(false);
        if (CM.cfnAddDate(searchParam.searchStartDate, searchParam.searchEndDate, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }

        //고객별 수납통계 요약내용 조회
        getTopListAxios(queryString)
          .then((responseSummaryData) => {
            setTopList(responseSummaryData);
          })
          .catch((error) => {
            console.error(error);
          });

        getBottomListAxios(queryString)
          .then((responseDetailData) => {
            const resultDetailData = responseDetailData.content;
            setBottomListTotalCount(responseDetailData.totalElements);
            setBottomList(resultDetailData);

            setPagination(paginationData(responseDetailData));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    const convertSearchParam = fnMakeParameter();
    if (tabIndex === 1 && (searchButton === true || excelButton === true)) {
      startAxios(convertSearchParam);
    }
  }, [tabIndex, searchButton, excelButton, searchParam, store]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    changeSearchParamHandler((data) => ({ ...data, pageNumber: page - 1 }));
    changeSearchButtonHandler(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    changeSearchParamHandler((data) => ({ ...data, pageSize: value }));
    changeSearchButtonHandler(true);
  };
  const changeSearchButtonHandler = (flag) => {
    setSearchButton(flag);
  };

  const changeSearchParamHandler = (searchParam) => setSearchParam(searchParam);

  /*
   * @desc  라벨지 출력 방식 변경 이벤트 핸들러
   */
  const changeRadioValueHandler = (name) => (e) => {
    if (e.target.type === "radio") {
      setSearchOption({
        ...searchOption,
        [name]: e.target.value,
      });
    } else if (e.target.type === "checkbox") {
      setSearchOption({
        ...searchOption,
        [name]: e.target.checked,
      });
    }
  };

  const changeSearchFormHandler = (name) => (e) => {
    if (name === "searchStartDate" || name === "searchEndDate") {
      const chagedEvent = e;
      setSearchOption({
        ...searchOption,
        [name]: chagedEvent,
      });
    } else if (e.target.type === "checkbox") {
      const checked = e.target.checked;
      setSearchOption({
        ...searchOption,
        [name]: checked,
      });
    } else {
      const changedValue = e.target.value;
      setSearchOption({
        ...searchOption,
        [name]: changedValue,
      });
    }
  };

  const changeSearchRequest = () => {
    setSearchParam(searchOption);
    changeSearchButtonHandler(true);
  };

  return (
    <div id="searchForm" data-testid="searchForm">
      <div>
        {/* 검색 폼 */}
        <CreateSearchForm
          searchOption={searchOption}
          customerGroups={customerGroups}
          capitalUniqueKey={capitalUniqueKey}
          changeSearchFormHandler={changeSearchFormHandler}
          changeSearchButtonHandler={changeSearchButtonHandler}
          changeRadioValueHandler={changeRadioValueHandler}
          changeSearchRequest={changeSearchRequest}
        />
        <CreateTopListForm topList={topList} />
        <div className="table-top-area">
          <Template.TotalCountForm totalElements={bottomListTotalCount || 0} />
          <Template.RowPerPageForm value={searchParam.pageSize} onChange={handleRowPerPageChange} />
          <button className="btn-m fr" onClick={(event) => setExcelButton(true)} data-testid="save-excel">
            엑셀저장
          </button>
        </div>
      </div>

      {/* 상세 목록 폼 */}
      <CreateBottomListForm bottomList={bottomList} pagination={pagination} page={searchParam.pageNumber} />

      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced="true"
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaymentDailyStatistics));
