import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaperData from "./PaperTaxBillData";
import PaperTaxBillPrintModal from "./PaperTaxBillPrintModal";

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">발행대상 구분</label>
        <FormControl component="fieldset">
          <RadioGroup name="taxbillDirection" value={searchRequest.taxbillDirection} onChange={handleSearchFormChange("taxbillDirection")} data-testid="unpaid-period" row>
            <FormControlLabel value="RECEIPT" control={<Radio color="primary" />} label="영수" />
            <FormControlLabel value="INVOICE" control={<Radio color="primary" />} label="청구" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">계산서 발행기간</label>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.fromDate}
          handleChange={handleSearchFormChange("fromDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.toDate}
          handleChange={handleSearchFormChange("toDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <br />
        <br />
        <label className="label-l">계산서 구분</label>
        <FormControl component="fieldset">
          <RadioGroup name="taxbillType" value={searchRequest.taxbillType} onChange={handleSearchFormChange("taxbillType")} data-testid="unpaid-period" row>
            <FormControlLabel value="PAPER" control={<Radio color="primary" />} label="세금계산서" />
            <FormControlLabel value="TAXFREE" control={<Radio color="primary" />} label="계산서" />
          </RadioGroup>
        </FormControl>
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.term} onChange={handleSearchFormChange("term")}>
          <option value="customerName">고객명</option>
          <option value="identificationNo">주민등록번호(사업자번호)</option>
        </Select>
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" />
        <button className="search-button" data-testid="search-history" onClick={() => fnSearch()}>
          발행 내역 조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state
  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.setTaxbillsList((taxbills) => taxbills.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.taxbillsList];
    cloneData[index][key] = value;
    props.setTaxbillsList(cloneData);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "issueDate", label: "발행일", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "instIdentificationNo", label: "주민등록번호\n(사업자번호)", sortable: true },
            { id: "totalSuppliedAmount", label: "공급가액", sortable: false },
            { id: "totalTaxAmount", label: "세액", sortable: false },
            { id: "totalAmount", label: "합계", sortable: false },
            { id: "taxbillDirection", label: "구분", sortable: false },
            { id: "billRemark", label: "비고", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.taxbillsList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.taxbillsList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.issueDate) /* 발행일 */}</TableCell>
                  <TableCell align="center">{row.customerName /* 고객명 */}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo) /* 주민등록번호(사업자번호)*/}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.supplyAmount) /*공급가액*/}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.vatAmount) /*세액*/}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalAmount) /*거래금액 총합계*/}원</TableCell>
                  <TableCell align="center">{PaperData.fnConvertTaxBillDirection(row.taxbillDirection) /*구분*/}</TableCell>
                  <TableCell align="center">{CDN.billIssueStatus(row.issueStatus) /*비고*/}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function PaperTaxIssueHistoryTab(props) {
  const { tabIndex, store } = props;

  //필요 state 선언
  const [searchRequest, setSearchRequest] = useState(PaperData.historySearchData()); // table 데이터 검색 조건
  const [originalList, setOriginalList] = useState([]);
  const [taxbillsList, setTaxbillsList] = useState([]); // table 데이터
  const [selectedTaxBills, setSelectedTaxBills] = useState([]); //선택된 계산서 제어
  const [pagination, setPagination] = useState(PaperData.paginationData());
  const [master, setMaster] = useState({});

  const [excelButton, setExcelButton] = useState(false);
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag

  const [openPrintModal, setOpenPrintModal] = useState(false); //인쇄 모달 제어

  useEffect(() => {
    /*
     * @desc 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.toDate = CM.cfnConvertDateToString(searchRequest.toDate);
      tempObj.fromDate = CM.cfnConvertDateToString(searchRequest.fromDate);

      if (tempObj.term === "customerName") {
        tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.identificationNo = "";
      } else if (tempObj.term === "identificationNo") {
        tempObj.customerName = "";
        tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
      }

      return tempObj;
    };

    /*
     * @desc    결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `/api/extraservice/taxbill/paper/issued?customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&taxbillDirection=${search.taxbillDirection}&taxbillType=${search.taxbillType}&toDate=${search.toDate}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc  엑셀저장
     */
    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `/api/extraservice/taxbill/paper/issued/excel?customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&taxbillDirection=${search.taxbillDirection}&taxbillType=${search.taxbillType}&taxbillType=${search.taxbillType}&toDate=${search.toDate}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

        CM.cfnAxiosFileDownload(url, "get", "", () => {});
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      if (buttonType === "excel") {
        //발행내역 엑셀저장인 경우
        await setExcelButton(false);
        await axiosExcel(search);
      } else {
        //발행내역 조회인 경우
        await setSearchButton(false);
        let resultData = await axiosList(search);

        let masterResult;
        let resultList;

        const getMaster = async () => {
          masterResult = await store.axiosMaster(); // 기관정보(동록번호(생년월일,법인등록번호),상호(기관명),성명(대표자명))
        };
        await getMaster();

        resultList = resultData.content;
        if (CM.cfnIsEmpty(resultList)) {
          resultList = [];
        }
        // 체크 옵션 추가
        if (CM.cfnIsNotEmpty(resultList)) {
          for (const element of resultList) {
            element._checked = false;
            element.instInfo = masterResult;
          }
        }
        await setOriginalList(resultData);
        await setTaxbillsList(resultList);
        await setPagination(PaperData.paginationData(resultData));
      }
    }

    // 실행 영역
    if (tabIndex === 1 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "search");
    } else if (tabIndex === 1 && excelButton === true) {
      const param = fnMakeParameter();
      startAxios(param, "excel");
    }
  }, [tabIndex, searchRequest, searchButton, excelButton, store]);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      setMaster(toJS(await store.axiosMaster()));
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc    결과조회 Request
   */
  const axiosPrintList = (taxbillUniqueKey, taxbillDirection, taxbillType) => {
    return new Promise((resolve) => {
      let url = `/api/extraservice/taxbill/paper/targets/detail?taxbillUniqueKey=${taxbillUniqueKey}&taxbillDirection=${taxbillDirection}&taxbillType=${taxbillType}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  };

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    발행내역 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "fromDate" || name === "toDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
      if (name === "taxbillType" || name === "taxbillDirection") {
        handleSearchButtonChange(true);
      }
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    종이세금계산서 인쇄 버튼 클릭 이벤트 핸들러
   */
  const handleClickPrintTaxbills = async () => {
    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(taxbillsList)) {
      return CM.cfnAlert("인쇄할 (세금)계산서를 선택해주세요.");
    }

    const tempArray = [];
    for (const taxbills of taxbillsList) {
      if (taxbills._checked) {
        let resultData = await axiosPrintList(taxbills.taxbillUniqueKey, taxbills.taxbillDirection, taxbills.taxbillType);
        let copyObj = CM.cfnCopyObject(resultData);
        copyObj.instSerialNumberArray = PaperData.fnConvertSerialNumber(copyObj.instSerialNumber);
        //인쇄화면의 table-td 칸마다 공급가액과 세액을 넣기위해 배열로 변경(fnConvertAmountArray)
        copyObj.totalSuppliedAmountArray = PaperData.fnConvertAmountArray(copyObj.totalSuppliedAmount, copyObj.taxbillType === "PAPER" ? 12 : 9);
        copyObj.totalTaxAmountArray = PaperData.fnConvertAmountArray(copyObj.totalTaxAmount, 11);

        copyObj.paperBillDetails = PaperData.fnCreatePaperTaxBillDetails(copyObj.paperTaxbillItemDtos);
        tempArray.push(copyObj);
      }
    }

    await setSelectedTaxBills(tempArray);
    setOpenPrintModal(true);
  };

  /*
   * @desc    엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleClickExcelTaxbills = (flag) => setExcelButton(flag);

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
      />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={originalList.totalElements || 0} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
        <button className="btn-m fr" data-testid="save-excel" onClick={(e) => handleClickExcelTaxbills(true)}>
          엑셀저장
        </button>
        <button className="btn-l fr" data-testid="print-paperTaxBill" onClick={(e) => handleClickPrintTaxbills()}>
          종이{searchRequest.taxbillType === "PAPER" ? "세금" : ""}계산서 인쇄
        </button>
      </div>
      <ListForm taxbillsList={taxbillsList} setTaxbillsList={setTaxbillsList} searchRequest={searchRequest} handleSortProperty={handleSortProperty} />
      <CF.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <PaperTaxBillPrintModal
        open={openPrintModal}
        setOpen={setOpenPrintModal}
        selectedTaxBills={selectedTaxBills}
        setSelectedTaxBills={setSelectedTaxBills}
        type={searchRequest.taxbillType}
        master={master}
      />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaperTaxIssueHistoryTab));
