import React, { useRef } from "react";
import "../../../styles/extraService.scss";
import ReactToPrint from "react-to-print";
import { Modal, Button } from "@material-ui/core";
import MailLabelPrintForm from "./MailLabelPrintForm";

/*
 * @desc    메인 컴포넌트 선언
 */
const PrintModal = (props) => {
  const { open, setOpen, targetList, printSettings } = props;

  /*
   * @desc    모달창 닫기 이벤트 핸들러
   */
  const handleClose = () => {
    setOpen(false);
  };

  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();

  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          width: "900px",
        }}>
        <div className="inner d-flex wrap">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>라벨지 인쇄</h3>
          <div className="table-top-area">
            <ReactToPrint trigger={() => <button className="btn-l fr">라벨지 인쇄</button>} content={() => printRef.current} copyStyles={true} />
          </div>
          <div className="print-outer-box" ref={printRef}>
            <MailLabelPrintForm targetList={targetList} printSettings={printSettings} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrintModal;
