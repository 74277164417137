import { FormControl, Input, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import AccountCheckData from "./AccountCheckData";

/*
 * @desc  실시간 실명조회 요청 컴포넌트 생성
 */
function AccountInquiry(props) {
  const { financialRepresentativeInstitutes, setSearchButton } = props;

  //계좌실명조회 처리를 위한 state 선언
  const [accountSearch, setAccountSearch] = React.useState({
    accountNo: "",
    depositorIdentification: "",
    financialInstituteCode: "",
  });

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleChange = (keyword) => (event) => {
    setAccountSearch({
      ...accountSearch,
      [keyword]: event.target.value,
    });
  };

  /*
   * @desc    계좌조회 버튼 클릭 이벤트 핸들러
   */
  const handleSearch = (e) => {
    e.preventDefault();
    if (fnCheckValidation()) {
      CM.cfnConfirm("조회하시겠습니까?", fnAccountSearch);
    }
  };

  /*
   * @desc    계좌조회 시 validation 체크 함수
   */
  const fnCheckValidation = () => {
    if (CM.cfnIsEmpty(accountSearch.financialInstituteCode)) {
      CM.cfnAlert("입력된 금융기관명을 다시 확인하여 주시기 바랍니다");
      return false;
    }
    return true;
  };

  /*
   * @desc    실시간실명조회 Request
   */
  const fnAccountSearch = async () => {
    const url = `api/customer/receipt/account/extra/account-inquiry?accountNo=${accountSearch.accountNo}&depositorIdentification=${accountSearch.depositorIdentification}&financialInstituteCode=${accountSearch.financialInstituteCode}`;
    await CM.cfnAxios(url, "post", "", fnAccountSearchCallback);
  };

  /*
   * @desc    실시간실명조회 Request Callback
   */
  const fnAccountSearchCallback = (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    } else {
      CM.cfnAlert("등록에 성공하였습니다.");
    }
    setSearchButton(true);
  };

  return (
    <div className="modify-area">
      <div className="block">
        <form name="search" onSubmit={handleSearch}>
          <label className="label-l" data-testid="bank-name-input">
            금융기관
          </label>
          <FormControl>
            <Template.BankAutoComplete
              list={financialRepresentativeInstitutes}
              state={accountSearch}
              setState={setAccountSearch}
              className={"w200"}
              customProps={{
                placeholder: "금융기관을 선택해주세요",
                required: true,
                maxLength: 12,
              }}
            />
          </FormControl>
          <label className="label-l">계좌번호</label>
          <Input className="w130" placeholder="계좌번호" onChange={handleChange("accountNo")} required={true} data-testid="account-no" />
          <label className="label-l">생년월일 (사업자번호)</label>
          <Input className="w100" placeholder="YYMMDD" onChange={handleChange("depositorIdentification")} required={true} data-testid="depositor-no" />
          <button type="submit" className="btn-l" data-testid="submit-account-check">
            계좌조회
          </button>
        </form>
      </div>
    </div>
  );
}

/*
 * @desc  실시간실명조회 결과조회 검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.setSearchButton(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">조회기간</label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.startDate}
          handleChange={handleSearchFormChange("startDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.endDate}
          handleChange={handleSearchFormChange("endDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <label className="label-l">처리상태</label>
        <Select
          native
          value={searchRequest.resultCode}
          onChange={handleSearchFormChange("resultCode")}
          inputProps={{
            "data-testid": "result-code-select",
          }}>
          <option value="">전체</option>
          <option value="0000">정상 상태</option>
        </Select>
        <label className="label-l">검색어입력</label>
        <Select
          native
          value={searchRequest.term}
          onChange={handleSearchFormChange("term")}
          inputProps={{
            "data-testid": "term-select",
          }}>
          <option value="depositorName">예금주명</option>
          <option value="accountNo">계좌번호</option>
        </Select>
        <TextField className="w140 tc" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="keyword-input" />
        <button className="search-button" onClick={() => fnSearch()} data-testid="search-button">
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  return (
    <div>
      <Table data-testid="name-check-table">
        {CM.cfnCompColGroup(["15%", "14%", "12%", "15%", "15%", "15%", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          arrData={[
            { id: "inquiryDatetime", label: "조회일시", sortable: true },
            { id: "depositorName", label: "예금주명", sortable: true },
            {
              id: "depositorIdentification",
              label: "예금주생년월일",
              sortable: true,
            },
            { id: "customerZipCode", label: "금융기관명", sortable: true },
            { id: "financialInstituteCode", label: "계좌번호", sortable: true },
            { id: "resultCode", label: "처리상태", sortable: true },
            { id: "resultMessage", label: "에러내용", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.accountList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            props.accountList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center" component="th" scope="row" padding="none">
                    {CM.cfnDateFormat(row.inquiryDatetime)}
                  </TableCell>
                  <TableCell align="center">{row.depositorName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.depositorIdentification)}</TableCell>
                  <TableCell align="center">{CM.cfnMatchBankName(row.financialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{row.accountNo}</TableCell>
                  <TableCell align="center">
                    {row.resultCode === null ? (
                      <Fragment>
                        처리중
                        <div className="d-inline-flex">
                          <button className="btn-m table-top-button" data-testid="update-account-inquiry" onClick={() => props.setSearchButton(true)}>
                            갱신
                          </button>
                        </div>
                      </Fragment>
                    ) : (
                      AccountCheckData.statusCode(row.resultCode)
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <span style={{ position: "relative" }}>
                      {CM.cfnNvl(row.resultCode, "처리중")}
                      <Template.TooltipForm contents={row.resultCode === "0000" ? "정상" : CM.cfnNvl(row.resultCodeMsg, "처리중")} tooltipClass="tooltipBottom tooltipRight" />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function AccountInquiryList(props) {
  const { tabIndex, store } = props;

  //금융기관 자동 조회를 위한 state
  const [financialInstitutes, setFinancialInstitutes] = useState(toJS(store.financialInstitutes));
  const [financialRepresentativeInstitutes, setFinancialRepresentativeInstitutes] = useState(toJS(store.financialRepresentativeInstitutes));

  const [searchRequest, setSearchRequest] = useState(AccountCheckData.realNameCheckSearchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [accountList, setAccountList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(AccountCheckData.paginationData());

  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await store.axiosFinancialInstitutes();
      setFinancialInstitutes(institutesList);
    };

    if (tabIndex === 0 && CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();

    /*
     * @desc    대표 금융기관 목록 조회 Request
     */
    const fnFetchFinancialRepresentativeInstitutes = async () => {
      const institutesList = await store.axiosFinancialRepresentativeInstitutes();
      setFinancialRepresentativeInstitutes(institutesList);
    };

    if (tabIndex === 0 && CM.cfnIsEmpty(financialRepresentativeInstitutes)) fnFetchFinancialRepresentativeInstitutes();
  }, [tabIndex, financialInstitutes, financialRepresentativeInstitutes, store, setFinancialInstitutes]);

  useEffect(() => {
    /*
     * @desc 계좌실명조회 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      if (tempObj.term === "depositorName") {
        tempObj.depositorName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.accountNo = "";
      } else if (tempObj.term === "accountNo") {
        tempObj.accountNo = CM.cfnNvl(tempObj.keyword, "");
        tempObj.depositorName = "";
      }

      tempObj.endDate = CM.cfnConvertDateToString(searchRequest.endDate);
      tempObj.startDate = CM.cfnConvertDateToString(searchRequest.startDate);

      return tempObj;
    };

    /*
     * @desc    실시간실명조회 결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/extra/account-inquiry?accountNo=${search.accountNo}&depositorName=${search.depositorName}&endDate=${search.endDate}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&resultCode=${search.resultCode}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&startDate=${search.startDate}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      setSearchButton(false);

      if (CM.cfnAddDate(search.startDate, search.endDate, 10)) {
        CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
        return false;
      }
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      setOriginalList(resultData);
      setAccountList(resultList);
      setPagination(AccountCheckData.paginationData(resultData));
    }

    // 실행 영역
    if (tabIndex === 0 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchButton(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setSearchRequest((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchButton(true);
  };

  /*
   * @desc    실시간 실명조회 결과조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "startDate" || name === "endDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    setSearchButton(true);
  };

  return (
    <div>
      <AccountInquiry financialInstitutes={financialInstitutes} financialRepresentativeInstitutes={financialRepresentativeInstitutes} setSearchButton={setSearchButton} />
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} setSearchButton={setSearchButton} />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="row-per-page-select" />
      </div>
      <ListForm accountList={accountList} financialInstitutes={financialInstitutes} searchRequest={searchRequest} handleSortProperty={handleSortProperty} setSearchButton={setSearchButton} />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(AccountInquiryList));
