import { Checkbox, Input, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import DonationData, { getPersonalDonationTypeCode } from "./DonationBillData";
import { encode } from "iconv-lite";


/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm({ searchRequest, handleSearchFormChange, handleSearchButtonChange, customerGroups, staffs }) {
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">발행연도</label>
        <Input
          className="w80"
          value={searchRequest.targetYear}
          onChange={handleSearchFormChange("targetYear")}
          onKeyUp={handleSearchKeyUp}
          name="search"
          data-testid="donationBill-history-request-targetYear-input"
          inputProps={{ maxLength: 4, style: { textAlign: "center" } }}
          type="number"
          endAdornment="년"
        />
        <label className="label-l">고객구분</label>
        <Select native value={searchRequest.customerGroupNameKey} onChange={handleSearchFormChange("groupUniqueKey")} inputProps={{ "data-testid": "donationNts-customer-group-select" }}>
          <option value="">전체</option>
          {CM.cfnIsNotEmpty(customerGroups) &&
            customerGroups.map((row, index) => {
              return (
                <option key={index} value={row.uniqueKey}>
                  {row.customerGroupName}
                </option>
              );
            })}
        </Select>
        <label className="label-l">고객담당자</label>
        <Select native value={searchRequest.staffNameKey} onChange={handleSearchFormChange("staffUniqueKey")} inputProps={{ "data-testid": "donationNts-staffName-select" }}>
          <option value="">전체</option>
          {CM.cfnIsNotEmpty(staffs) &&
            staffs.map((row, index) => {
              return (
                <option key={index} value={row.uniqueKey}>
                  {row.staffName}
                </option>
              );
            })}
        </Select>
        <label className="label-l">개인/사업자</label>
        <Select native value={searchRequest.customerType} onChange={handleSearchFormChange("customerType")}>
          <option value="">전체</option>
          <option value="INDIVIDUAL">개인</option>
          <option value="BUSINESSER">사업자</option>
        </Select>
        <button className="search-button" onClick={() => handleSearchButtonChange(true)} data-testid="search-send-sms-btn">
          발행 대상 조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of props.targetList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const targetLength = props.targetList.length;

    if (checkAllFlag === targetLength && targetLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [props.targetList]);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.setTargetList((row) => row.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleSelectChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.targetList];
    cloneData[index][key] = value;
    props.setTargetList(cloneData);
  };

  return (
    <div className="scroll-table">
      <div className="left-table-div-top">
        <Table>
          {CM.cfnCompColGroup(["13%", "auto", "25%", "35%"])}
          <TableForm.compServerSortTableHead
            useCheckbox={true}
            checked={checkAllRow}
            value=""
            onChange={handleCheckAllRowChange}
            arrData={[
              { id: "customerName", label: "고객명", sortable: false },
              { id: "customerIdentificationNo", label: "주민등록번호\n(사업자번호)", sortable: false },
              { id: "donationTotalAmount", label: "합계", sortable: false },
            ]}
            searchRequest={props.searchRequest}
          />
          <TableBody>
            {props.targetList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={4} />
            ) : (
              props.targetList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Checkbox
                        checked={row._checked}
                        value="_checked"
                        id={`checkbox-${index}`}
                        onChange={handleSelectChange}
                        inputProps={{
                          index: index,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={row.customerName}>
                      {row.customerName}
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={row.customerIdentificationNo}>
                      {CM.cfnIdentificationNoFormat(row.customerIdentificationNo, true)}
                    </TableCell>
                    <TableCell align="center" className="ellipsis" title={CM.cfnAddComma(row.donationTotalAmount) + "원"}>
                      {CM.cfnAddComma(row.donationTotalAmount)}원
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div-top">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["100px", "100px", "100px", "100px", "100px", "100px", "100px", "100px", "100px", "100px", "100px", "100px"])}
            <TableForm.compTableHead arrData={[...Array(12).keys()].map((value) => `\n${value + 1}월`)} />
            <TableBody>
              {props.targetList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={12} />
              ) : (
                props.targetList.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {row.donationAmounts.map((amount, i) => (
                        <TableCell align="right" key={index + "," + i}>
                          <Input
                            className="w95p"
                            value={amount ? CM.cfnAddCommaOnChange(amount) : "0"}
                            onChange={(e) => props.handleChangeDonationAmount(index, i, e)}
                            inputProps={{
                              style: { textAlign: "right" },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="table-bottom-area" />
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function SubmissionFileTab({ tabIndex, store, donationForm }) {
  //필요 state선언
  const [searchRequest, setSearchRequest] = useState(DonationData.ntsSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [isTargetChanged, setIsTargetChanged] = useState(false); //발행대상 추가 여부 flag

  const [targetList, setTargetList] = useState([]); //테이블 데이터

  //검색조건 셀렉트박스 옵션 제어 state 선언
  const [customerGroups, setCustomerGroups] = useState([]);
  const [staffs, setStaffs] = useState([]);

  //검색조건 내 셀렉트 박스 옵션 조회
  useEffect(() => {
    const startStoreAxios = async () => {
      // 고객구분 option data 설정
      const customerGroupList = await store.axiosCustomerGroup();
      setCustomerGroups(customerGroupList);
    };
    const startFetchStaffList = async () => {
      //고객담당자 option data 설정
      const resultStaffs = await store.axiosStaffs();
      setStaffs(resultStaffs);
    };

    if (tabIndex === 4) {
      startStoreAxios();
      startFetchStaffList();
    }
  }, [store, tabIndex]);

  useEffect(() => {
    /*
     * @desc    국세청 제출파일 발행대상 조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/donations/issued/nts?groupUniqueKey=${search.groupUniqueKey}&staffUniqueKey=${search.staffUniqueKey}&targetYear=${search.targetYear}&customerType=${search.customerType}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios() {
      const resultData = await axiosList(searchRequest);
      let resultList = resultData;
      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }

      // 체크 옵션 추가
      if (CM.cfnIsNotEmpty(resultList)) {
        for (const element of resultList) {
          element._checked = false;
        }
      }
      setTargetList(resultList);
    }

    // 실행 영역
    if (searchButton === true && tabIndex === 4) {
      handleSearchButtonChange(false);
      startAxios();
    }
  }, [searchRequest, searchButton, tabIndex]);

  //발행대상 추가 시 목록 재조회
  useEffect(() => {
    if (isTargetChanged) {
      setIsTargetChanged(false);
      handleSearchButtonChange(true);
    }
  }, [isTargetChanged]);

  /*
   * @desc    상단 검색조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    switch (name) {
      case "targetYear":
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        break;
      case "groupUniqueKey":
      case "staffUniqueKey":
      case "capitalUniqueKey":
      case "customerType":
        const index = e.nativeEvent.target.selectedIndex;
        const key = `${name}`;
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value !== "" ? e.nativeEvent.target[index].text : "",
          [key]: e.target.value,
        });
        break;
      default:
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
        });
        break;
    }
  };

  /*
   * @desc    월별 기부금액 변경 이벤트 핸들러
   */
  const handleChangeDonationAmount = (index, column, e) => {
    const cloneData = [...targetList];
    cloneData[index].donationAmounts[column] = Number(e.target.value.replace(/[^0-9]/g, ""));
    cloneData[index].donationTotalAmount = fnCalcDonationAmount(cloneData[index]);
    setTargetList(cloneData);
  };

  /*
   * @desc    월별 기부금액 총합 계산
   */
  const fnCalcDonationAmount = (data) => {
    return data.donationAmounts.reduce((a, b) => (a || 0) + (b || 0));
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  //음수 체크
  const fnCheckMinusAmount = async (list, fileName) => {
    var minusAmountChkMsg = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].donationTotalAmount !== 0) {
        var minusSw = false;
        for (let j = 0; j < 12; j++) {
          if (list[i].donationAmounts[j] < 0) {
            minusSw = true;
          }
        }
        if (minusSw) {
          minusAmountChkMsg.push(i + 1 + "번째 " + list[i].customerName);
        }
      }
    }

    if (minusAmountChkMsg.length === 0) {
      fnDFileCreate(list, fileName);
    } else {
      try {
        await CM.cfnConfirmAsync(
          `아래 고객의 기부금액 중에 음수가 포함되어 있습니다. 해당 고객을 제외하고 파일을 생성하시겠습니까? 포함시키시려면 '취소'를 누르신 후 금액정보를 수정하여 다시 작성해주시기 바랍니다.\n\n${minusAmountChkMsg.join(
            ", "
          )}`
        );
        fnDFileCreate(list, fileName);
      } catch {
        return;
      }
    }
  };

  const fnDFileCreate = (list, fileName) => {
    let dStr = "",
      hStr = "",
      str = "",
      dt = "",
      minDt = "",
      maxDt = "";
    let rowCnt = 0,
      cnt = 0,
      amt = 0,
      sumAmt = 0;

    for (let i = 0; i < list.length; i++) {
      if (list[i].donationTotalAmount !== 0) {
        str = "D|"; //레코드구분 (D:데이터)
        str += `${donationForm.identificationNo}|`; //기관사업자번호
        str += `${list[i].customerIdentificationNo}|`; //기부자 주민등록번호
        str += `${CM.cfnSetSpace(list[i].customerName, 30)}|`; //기부 고객명
        str += `${getPersonalDonationTypeCode(donationForm.personalDonationType)}|`; //기부내용코드
        str += `1|`; //기부내용구분 1=금전기부, 2=현물기부
        str += `${donationForm.basedLawCode}|`; //기부금 단체코드

        if (CM.cfnIsEmpty(list[i].customerIdentificationNo) || list[i].customerIdentificationNo.length !== 13) continue;

        if ((donationForm.personalDonationType === "TAX_LAW_73" || donationForm.personalDonationType === "TAX_LAW_73_1") && list[i].targetYear > "2011") {
          CM.cfnAlert("기부유형이 '조특법 73조(30,31)'인 경우에는 2011년 6월30일까지의 기부금에 대해서만 파일 생성이 가능합니다.");
          return false;
        }

        let strJ;
        let flag = false;

        for (let j = 0; j < 12; j++) {
          strJ = j;
          strJ++;

          if (strJ < 10) strJ = `0${strJ}`;

          amt = list[i].donationAmounts[j];
          dt = list[i].donationDates[j];
          amt = `${amt.toString().replace(/(,)/g, "").replace(/^0*/, "")}`;

          if (amt < 0) continue;

          if (amt !== "0" && amt !== "") {
            flag = true;
            if (dt?.length !== 8) {
              // 항목이 없는 월에 금액을 입력해서 출력하는 경우 날짜가 존재하지 않음
              dt = searchRequest.targetYear + strJ;
              dt += CM.cfnGetLastDay(dt);
            }

            ///////////////// 시작일 / 종료일 구하기.. max/min으로 구하기
            if (minDt === "" || Number(minDt) > Number(dt)) minDt = dt;
            if (maxDt === "" || Number(maxDt) < Number(dt)) maxDt = dt;
            ///////////////// 시작일 / 종료일 구하기.. max/min으로 구하기

            dStr += str;
            dStr += dt + "|"; // 기부일자
            dStr += CM.cfnSetZero(amt, 15) + "|"; // 기부금액 합계
            dStr += CM.cfnSetZero(amt, 15) + "|"; // 공제대상 기부금액 - 합계와 동일하게 설정
            dStr += CM.cfnSetZero("0", 15) + "|"; // 기부장려금 신청금액 - 0원으로 설정
            dStr += CM.cfnSetSpace(" ", 46) + "\r\n"; //공란
            cnt++;
            sumAmt += Number(amt);
          }
        }

        if (flag) rowCnt++;
      }
    }

    CM.cfnConfirm(
      `고객수 : ${rowCnt}명, 대상건 : ${cnt}건, 합계금액 : ${CM.cfnAddCommaOnChange(
        sumAmt
      )}원 입니다. \r\n파일에서는 기부장려금 설정이 어려우므로, 기부장려금을 설정하시려면 엑셀저장 기능을 이용해주시기 바랍니다.\r\n\r\n파일을 저장하시겠습니까?`,
      () => {
        hStr = `B|`; //레코드구분 (B)
        hStr += `G0020|`; //자료코드 (L01:기부금)
        hStr += `${CM.cfnSetSpace(donationForm.identificationNo, 10)}|`; //발급기관사업자번호
        hStr += `${CM.cfnSetSpace(donationForm.instituteName, 50)}|`; //발급기관명칭
        hStr += `${CM.cfnSetSpace(donationForm.managerName, 30)}|`; //발급기관담당자성명
        hStr += `${CM.cfnSetSpace(donationForm.managerTelephone, 14)}|`; //발급기관담당자연락처
        hStr += `${CM.cfnSetSpace(CM.cfnGetDate(), 8)}|`; //자료제출년월일
        hStr += `${CM.cfnSetSpace(minDt, 8)}|`; //기부시작일자
        hStr += `${CM.cfnSetSpace(maxDt, 8)}|`; //기부종료일자
        hStr += `${CM.cfnSetZero(cnt, 10)}|`; //자료제출건수
        hStr += `${CM.cfnSetZero(sumAmt, 15)}|`; //자료합계금액
        hStr += "\r\n"; //공란사라짐. 2016년.
        var ntxTxt = hStr + dStr;

        if (CM.cfnIsEmpty(ntxTxt) || !ntxTxt) return false;

        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        let blob;
        if (isIE) {
          //ie
          blob = new Blob([ntxTxt], "data:text/plain;");
        } else {
          const convertChar = encode(ntxTxt, "EUC-KR");

          const buf = Buffer.from(convertChar);
          blob = new Blob([buf], { type: "/", endings: "native" });
        }
        if (window.navigator?.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const objURL = window.URL.createObjectURL(blob);

          if (window.__Xr_objURL_forCreatingFile__) {
            window.URL.revokeObjectURL(window.__Xr_objURL_forCreatingFile__);
          }

          window.__Xr_objURL_forCreatingFile__ = objURL;
          const fileDown = document.createElement("a");
          fileDown.download = fileName;
          fileDown.href = objURL;
          fileDown.click();
        }
      }
    );
  };

  //주민등록번호 체크
  const fnCheckIdentificationNo = (list, fileName) => {
    var identificationNoChkMsg = "";
    var identificationNoChkVal = true;

    for (let i = 0; i < list.length; i++) {
      if (list[i].donationTotalAmount !== 0) {
        if (CM.cfnIsEmpty(list[i].customerIdentificationNo) || list[i].customerIdentificationNo.length !== 13) {
          identificationNoChkMsg += i + 1 + "번째 " + list[i].customerName + ", ";
          identificationNoChkVal = false;
        }
      }
    }

    if (identificationNoChkVal) {
      fnCheckMinusAmount(list, fileName);
    } else {
      CM.cfnConfirm(
        `${identificationNoChkMsg.substring(
          0,
          identificationNoChkMsg.length - 2
        )} 고객님의 주민등록번호가 13자리가 아닙니다. 해당 고객을 제외하고 파일 생성을 하시겠습니까? 포함시키시려면 '취소'를 누르신 후 고객정보를 수정하여 다시 작성해주시기 바랍니다.`,
        () => {
          fnCheckMinusAmount(list, fileName);
        }
      );
    }
  };

  /*
   * @desc   파일(.D001) 생성 버튼 클릭 이벤트 핸들러
   */
  const handleClickDFileCreate = async () => {
    const tempArray = await fnCheckValidation("d", donationForm);
    if (typeof tempArray !== "boolean") {
      const fileName = `G0020_${donationForm.identificationNo}${CM.cfnGetDate()}.D001`;
      fnCheckIdentificationNo(tempArray, fileName);
    }
  };

  /*
   * @desc   엑셀파일(.xls) 생성 버튼 클릭 이벤트 핸들러
   */
  const handleClickXlsFileCreate = async () => {
    const tempArray = await fnCheckValidation("xls");

    if (typeof tempArray !== "boolean") {
      let data = {
        groupUniqueKey: searchRequest.groupUniqueKey,
        staffUniqueKey: searchRequest.staffUniqueKey,
        customerType: searchRequest.customerType === "" ? null : searchRequest.customerType,
        targetYear: searchRequest.targetYear,
        customerUniqueKeyList: targetList.length === tempArray.length ? null : tempArray.map((r) => r.customerUniqueKey),
      };
      const url = "api/extraservice/donations/issued/nts/excel";
      CM.cfnAxiosFileDownload(url, "post", data);
    }
  };

  /*
   * @desc   파일 생성 전 선택 데이터의 validation 체크 함수
   */
  const fnCheckValidation = async (type, formats) => {
    if (type === "d") {
      if (CM.cfnIsEmpty(formats.basedLawCode)) {
        CM.cfnAlert("영수증양식중 '기부금 공제대상 기부금 단체근거법령'이 설정되어 있지 않습니다. 양식을 먼저 설정해 주십시오.");
        return false;
      }

      if (CM.cfnIsEmpty(formats.identificationNo)) {
        CM.cfnAlert("기관 사업자번호를 등록하지 않았습니다. 기부금영수증 양식관리 탭에서 기관 사업자번호를 등록하신 후 저장해 주십시오.");
        return false;
      }

      if (CM.cfnIsEmpty(formats.instituteName)) {
        CM.cfnAlert("기부금단체명을 등록하지 않았습니다. 기부금영수증 양식관리 탭에서 기부금단체명을 등록하신 후 저장해 주십시오.");
        return false;
      }

      if (CM.cfnIsEmpty(formats.managerName)) {
        CM.cfnAlert("담당자성명을 등록하지 않았습니다. 기부금영수증 양식관리 탭에서 담당자성명을 등록하신 후 저장해 주십시오.");
        return false;
      }

      if (CM.cfnIsEmpty(formats.managerTelephone)) {
        CM.cfnAlert("담당자전화번호를 등록하지 않았습니다. 기부금영수증 양식관리 탭에서 담당자전화번호를 등록하신 후 저장해 주십시오.");
        return false;
      }

      if (CM.cfnIsEmpty(formats.basedLawCode)) {
        CM.cfnAlert("기부금단체 근거법령코드를 등록하지 않았습니다. 기부금영수증 양식관리 탭에서 기부금단체 근거법령코드를 등록하신 후 저장해 주십시오.");
        return false;
      }

      if (CM.cfnIsEmpty(formats.personalDonationType) || CM.cfnIsEmpty(formats.businessDonationType)) {
        CM.cfnAlert("국세청 전송파일은 기부유형이 없는 경우는 생성하실 수 없습니다. 기부금영수증 양식관리 탭에서 기부유형을 수정해 주시기 바랍니다.");
        return false;
      }

      if (
        (CM.cfnIsEmpty(formats.personalDonationType) && formats.personalDonationType === "ETC_DONATION") ||
        (CM.cfnIsEmpty(formats.businessDonationType) && formats.businessDonationType === "ETC_DONATION")
      ) {
        CM.cfnAlert("국세청 전송파일은 기부유형이 '기타기부금'인 경우는 생성하실 수 없습니다. 기부금영수증 양식관리 탭에서 기부유형을 수정해 주시기 바랍니다.");
        return false;
      }
      if (targetList.some((d) => d._checked && (d.customerType !== "INDIVIDUAL" || d.customerIdentificationNo?.length !== 13))) {
        try {
          await CM.cfnConfirmAsync(
            "아래의 고객은 개인 기부자가 아니거나, 주민등록번호가 올바르게 입력되지 않아 생성이 불가합니다. 이 내역을 제외하고 생성하시겠습니까?\n포함시키시려면 '취소'를 누르신 후 고객정보를 수정하여 다시 작성해주시기 바랍니다.\n\n" +
              targetList
                .filter((d) => d._checked && (d.customerType !== "INDIVIDUAL" || d.customerIdentificationNo?.length !== 13))
                .map((d) => d.customerName)
                .join(", ")
          );
          setTargetList((t) => t.map((d) => (d._checked && (d.customerType !== "INDIVIDUAL" || d.customerIdentificationNo?.length !== 13) ? { ...d, _checked: false } : d)));
        } catch {
          return false;
        }
      }
    }

    const tempArray = type === "d" ? targetList.filter((d) => d._checked && d.customerType === "INDIVIDUAL" && d.customerIdentificationNo.length === 13) : targetList.filter((d) => d._checked);

    if (CM.cfnIsEmpty(tempArray)) {
      CM.cfnAlert("생성할 내용이 없습니다.");
      return false;
    }

    return tempArray;
  };

  return (
    <div>
      <SearchForm
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        customerGroups={customerGroups} // 고객구분
        staffs={staffs}
      />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={targetList.length} />
        <button className="btn-m fr" onClick={handleClickDFileCreate} data-testid="add-bill-target-open">
          파일(.D001) 생성 - 개인만 해당
        </button>
        <button className="btn-m fr" onClick={handleClickXlsFileCreate} data-testid="save-target-excel">
          엑셀파일(.xls) 생성 - 개인/사업자 포함
        </button>
      </div>
      <ListForm targetList={targetList} setTargetList={setTargetList} searchRequest={searchRequest} handleChangeDonationAmount={handleChangeDonationAmount} />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(SubmissionFileTab));
