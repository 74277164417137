import React from "react";

//메인화면 > 고객서비스헌장
const Customer = () => {
  return (
    <div className="ftm">
      <h3 className="title">고객서비스헌장</h3>
      <div className="content-box-scroll-back">
        <div className="content-box-scroll">
          <h4 className="title" style={{ marginTop: "10px !important" }}>
            고객서비스헌장 전문
          </h4>
          <p>
            금융결제원은 고객 중심의 금융결제서비스를 통하여 금융회사 이용자의 편의제고, 나아가 금융 산업의 발전에 기여하고 있다는 사실을 깊이 인식하고 고객에게 언제나 최상의 서비스를 제공할 수 있도록
            다음의 사항을 실천할 것을 다짐합니다.
          </p>
          <div className="box-text2">
            <li>고객에게 안전하고 편리한 금융결제서비스를 제공하기 위해 최선을 다하고 고객만족을 최우선 가치로 삼겠습니다.</li>
            <li>다양한 이해관계자와의 협력을 바탕으로 고객에게 신뢰할 수 있는 서비스를 구현하겠습니다.</li>
            <li>금융결제와 금융정보중개에서 최고 수준의 전문성과 차별화된 역량을 확보하여 고객가치를 극대화하겠습니다.</li>
            <li>창의적 사고와 도전정신으로 급변하는 환경에 선제적으로 대응하여 금융결제서비스의 혁신을 지속적으로 추구하겠습니다.</li>
            <li>사회적 책임을 다하는 따뜻한 지급결제기관으로서 나눔과 배려의 사회공동체를 만드는 데에 기여하겠습니다.</li>
          </div>
          <p>금융결제원은 이와 같은 약속을 지키기 위하여 다음과 같이 고객만족경영 원칙을 정하고 직원 모두가 이를 성실히 실천하겠습니다.</p>

          <h4 className="title">고객만족경영 원칙</h4>
          <h5 className="title">서비스 품질 향상</h5>
          <ul>
            <li>금융결제원은 금융회사 및 고객의 편의 제고를 위하여 금융결제서비스의 시간적·공간적 제약을 해소하고 금융거래의 안정성과 효율성이 향상되도록 노력하겠습니다.</li>
          </ul>
          <h5 className="title">친절하고 편리한 고객접점 운영</h5>
          <ul>
            <li>항상 친절하고 상냥하게 고객을 맞이하겠습니다.</li>
            <li>직접 금융결제원을 방문하시는 고객을 위하여 현관에 안내원을 배치하고 방문상담을 위한 미팅룸을 설치·운영하여 고객이 불편하지 않도록 하겠습니다.</li>
            <li>고객 상담 전담조직인 “고객센터”를 설치 및 운영하고, 상담원에 대한 교육을 강화하여 신속하고 정확하게 상담하겠습니다.</li>
          </ul>
          <h6 className="title">[ 고객센터 상담안내 ]</h6>
          <table className="contentbox-table">
            <thead>
              <tr>
                <th className="w100">구분</th>
                <th className="w200">내용</th>
                <th className="w150">운영시간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>전화번호</td>
                <td>1577-5500</td>
                <td>평일 09:00~17:00</td>
              </tr>
              <tr>
                <td>홈페이지</td>
                <td>
                  <a href="http://callcenter.kftc.or.kr" target="_blank" rel="noopener noreferrer">
                    callcenter.kftc.or.kr
                  </a>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              고객 여러분이 금융결제원과 관련된 일반적인 사항과 주요 수행업무 등을 파악하실 수 있도록 관련 정보를 홈페이지(
              <a href="http://www.kftc.or.kr" target="_blank" className="link02" rel="noopener noreferrer">
                www.kftc.or.kr
              </a>
              )를 통해 제공하겠습니다.
            </li>
            <li>고객의 질의 및 요청사항에 대하여 7영업일 내에 답변해 드리는 것을 원칙으로 하되, 부득이하게 기일이 더 소요될 경우에는 지연사유 등 진행과정에 대해 중간에 안내 드리겠습니다.</li>
          </ul>
          <h5 className="title">고객참여를 통한 서비스 개선</h5>
          <ul>
            <li>금융결제원은 고객 만족도를 높이고 서비스에 대한 고객 제안을 겸허하게 수용하기 위하여 노력하겠습니다.</li>
            <li>
              금융결제원은 고객의 불편 또는 애로사항 및 각종 업무개선 제안을 수집하기 위해 현관 로비에 「고객의 소리함」을 설치 운영하며, 홈페이지 고객의견 수렴코너 또는 우편 등으로도 고객의견을 접수
              받고 있습니다.
            </li>
          </ul>
          <div className="box-text2">
            <h6 className="title">우편으로 의견을 주실 곳</h6>
            <li>주소 : &#40;우&#41;06220 서울시 강남구 논현로 432 &#40;역삼동&#41; 금융결제원 고객지원실 TEL : 1577-5500</li>
          </div>
          <ul>
            <li>금융결제원은 다양한 채널을 통해 수집되는 고객의견을 고객만족리더 협의회 등을 통하여 경영진이 경청하고 고객중심의 제도와 서비스로 개선하는 데에 활용하겠습니다.</li>
          </ul>
          <h5 className="title">고객께 부탁드리는 사항</h5>
          <p>금융결제원의 모든 임직원은 고객만족경영을 위하여 서비스헌장을 운영하고 있사오니 고객 여러분께서도 다음 사항에 대하여 적극 협조하여 주시기 바랍니다.</p>
          <ul>
            <li>고객의견에 대한 신속, 정확한 처리 및 회신을 위해서는 회신 받으실 전화번호 또는 이메일 주소를 정확하게 기재하여 주시기 바랍니다.</li>
            <li>금융결제원 직원의 서비스가 불친절하거나 공정하지 못했을 경우에는 즉시 알려주시면 최선을 다하여 개선하도록 노력하겠습니다.</li>
            <li>금융결제원 직원이 자긍심을 갖고 열심히 일할 수 있도록 잘한 점이 있거나 모범이 되는 직원에 대하여는 많은 격려와 칭찬을 부탁드립니다.</li>
            <li>금융결제서비스 개선에 도움이 될 수 있는 의견을 주시면 업무에 적극 반영하도록 노력하겠습니다.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Customer;
