import React from 'react';
import EvidenceFileBatchRegister from './EvidenceFileBatchRegister';

import { Modal, Button } from '@material-ui/core';

/*
 * @desc    메인 컴포넌트 선언
 */
const FolderRegisterModal = props => {
  const { open, setOpen } = props;

  /*
   * @desc    모달창 닫기 이벤트 핸들러
   */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" data-testid="open-modal" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {''}
            </Button>
          </div>
          <h3>동의자료 일괄등록</h3>
          <EvidenceFileBatchRegister />
        </div>
      </div>
    </Modal>
  );
};

export default FolderRegisterModal;
