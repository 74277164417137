import React, { useState, useEffect /*useCallback, , */ } from "react";

import { Modal, Button } from "@material-ui/core";

import * as CM from "../../common/Common";

import * as BillForm from "./PaperBillForm";

/*
 * @desc  메인 컴포넌트
 */
const PaperBillIssue = ({ open, handleModal, selectedBillsList, setSelectedBillsList, master }) => {
  //필요 state 선언
  const [currentIndex, setCurrentIndex] = useState(0); //현재 선택된 발행 대상 제어
  const [selectedPaperBill, setSelectedPaperBill] = useState({});
  const [isAmountChanged, setIsAmountChanged] = useState(false); //공급대가총액 계산 필요 여부 제어
  const [flag, setFlag] = useState(true);
  const [isContentsChanged, setIsContentsChanged] = useState(false); // 내용 변경시 저장여부 확인을 위해 필요

  //화면 진입 시 선택된 종이영수증의 uniqueKey로 개별 데이터 조회
  useEffect(() => {
    //발행정보 조회 api호출
    const getAxios = (parameter) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/receipt/targets/detail?`;
        url += parameter.taxbillUniqueKey ? "taxbillUniqueKey=" + parameter.taxbillUniqueKey : "";
        url += parameter.historyUniqueKey ? (parameter.taxbillUniqueKey ? "&" : "") + "historyUniqueKey=" + parameter.historyUniqueKey : "";

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    };

    const fnFetchSelectedPaperBill = async () => {
      let tempObjList = [];
      for (let i = 0; i < selectedBillsList.length; i++) {
        const selectedBill = selectedBillsList[i];

        let data = await getAxios(selectedBill);

        //조회 성공 시 개별 데이터 setting
        for (let j = 0; j < 10; j++) {
          if (j < data.paperReceiptItemDtos.length) {
            const dto = data.paperReceiptItemDtos[j];
            dto.itemAmount = dto.itemQuantity * dto.itemUnitPrice;
          } else {
            data.paperReceiptItemDtos.push({ itemName: "", itemQuantity: "", itemUnitPrice: "", itemAmount: "", itemRemark: "" });
          }
        }
        tempObjList.push(data);
      }
      if (tempObjList.length > 0) {
        setSelectedPaperBill(tempObjList[0]);
        setSelectedBillsList(tempObjList);
      }
    };

    //모달 처음 열릴시 초기화
    if (open === true && flag === true) {
      setSelectedPaperBill({});
      setFlag(false);
    } else if (open === true && flag === false) {
      //모달 초기화된 후 넘어온 리스트의 uniqueKey가 있을 경우 api조회를 호출 후 상세정보 모달에 표현
      if (selectedBillsList.length !== 0) {
        if (selectedBillsList[currentIndex].taxbillUniqueKey !== undefined) {
          //실행영역
          if (CM.cfnIsEmpty(selectedPaperBill)) {
            fnFetchSelectedPaperBill();
          }
        }
      }
    }
  }, [currentIndex, flag, selectedPaperBill, selectedBillsList, open, setSelectedPaperBill, setSelectedBillsList]);

  //수량 or 단가 수정 시 공급대가총액 재계산
  useEffect(() => {
    if (isAmountChanged) {
      const tempObj = CM.cfnCopyObject(selectedPaperBill);
      const tempObjList = CM.cfnCopyObject(selectedBillsList);
      let sum = 0;

      for (const row of selectedPaperBill["paperReceiptItemDtos"]) {
        sum += row.itemAmount;
      }

      tempObj["totalAmount"] = sum;
      tempObjList[currentIndex] = tempObj;
      setIsAmountChanged(false);
      setSelectedPaperBill(tempObj);
      setSelectedBillsList(tempObjList);
    }
  }, [isAmountChanged, currentIndex, selectedPaperBill, selectedBillsList, setSelectedBillsList]);

  /*
   * @desc  영수증 발행 서식 상단 - 이전/다음 버튼 클릭 이벤트 핸들러
   */
  const handleChangeSelected = (type) => {
    let tempValue = currentIndex;
    const tempObjList = CM.cfnCopyObject(selectedBillsList);
    if (isContentsChanged) {
      CM.cfnConfirm(
        "저장되지 않은 내용은 사라집니다. 계속하시겠습니까?",
        () => {
          setIsContentsChanged(false);
          if (type === "prev") {
            //이전버튼 클릭 시

            setSelectedPaperBill(tempObjList[tempValue - 1]);
            setCurrentIndex(--tempValue);
          } else if (type === "next") {
            //다음버튼 클릭 시
            if (tempObjList[tempValue + 1].customerName !== undefined) {
              //이미 taxbillUniqueKey로 발행내역을 조회해 왔다면
              setSelectedPaperBill(tempObjList[tempValue + 1]);
            } else {
              //조회한적이 없다면 selectedPaperBill을 초기화하여 useEffect에서 조회api호출
              setSelectedPaperBill({});
            }
            setCurrentIndex(++tempValue);
          }
        },
        () => {}
      );
    } else {
      if (type === "prev") {
        //이전버튼 클릭 시

        setSelectedPaperBill(tempObjList[tempValue - 1]);
        setCurrentIndex(--tempValue);
      } else if (type === "next") {
        //다음버튼 클릭 시
        if (tempObjList[tempValue + 1].customerName !== undefined) {
          //이미 taxbillUniqueKey로 발행내역을 조회해 왔다면
          setSelectedPaperBill(tempObjList[tempValue + 1]);
        } else {
          //조회한적이 없다면 selectedPaperBill을 초기화하여 useEffect에서 조회api호출
          setSelectedPaperBill({});
        }
        setCurrentIndex(++tempValue);
      }
    }
  };

  /*
   * @desc  영수증 발행 버튼 클릭 이벤트 핸들러
   */
  const handleClickIssuePaperBill = () => {
    if (fnValidation()) {
      fnIssuePaperBill();
    }
  };

  /*
   * @desc  종이영수증 발행 서식 내 입력란 변경 이벤트 핸들러
   */
  const handlePaperBillFormChange = (name, index) => (e) => {
    const tempObj = CM.cfnCopyObject(selectedPaperBill);
    const tempObjList = CM.cfnCopyObject(selectedBillsList);
    let tempValue;

    if (CM.cfnIsEmpty(index)) {
      switch (name) {
        case "receiptNumber":
          //영수증 No 입력 시
          tempValue = Number(CM.cfnReplaceNonDigit(e.target.value));
          break;
        case "transactionDate":
          //영수내역 - 작성년월일 입력 시
          tempValue = e;
          break;
        case "issueDate":
          //영수내역 - 작성년월일 입력 시
          tempValue = e;
          break;
        default:
          //영수내역 - 비고 입력 시
          tempValue = e.target.value;
          break;
      }

      tempObj[name] = tempValue;
    } else {
      //공급내역 상세항목 입력 시
      if (name === "itemName" || name === "itemRemark" || name === "itemAmount") {
        tempValue = e.target.value;
      } else {
        if (name === "itemQuantity") {
          //수량 입력 시
          tempValue = Number(CM.cfnReplaceSymbol(e.target.value).replace(/[^0-9]/g, ""));
          tempObj["paperReceiptItemDtos"][index]["itemAmount"] = tempValue * tempObj["paperReceiptItemDtos"][index]["itemUnitPrice"];
        } else if (name === "itemUnitPrice") {
          //단가 입력 시
          tempValue = Number(CM.cfnReplaceSymbol(e.target.value).replace(/[^0-9]/g, ""));
          tempObj["paperReceiptItemDtos"][index]["itemAmount"] = tempValue * tempObj["paperReceiptItemDtos"][index]["itemQuantity"];
        }
        //변경된 금액을 토대로 공급대가총액 계산하도록 flag 변경
        setIsAmountChanged(true);
      }

      tempObj["paperReceiptItemDtos"][index][name] = tempValue;
    }
    tempObjList[currentIndex] = tempObj;
    setSelectedBillsList(tempObjList);
    setSelectedPaperBill(tempObj);
    setIsContentsChanged(true);
  };

  /*
   * @desc  영수증 발행 전 validation 체크 함수
   */
  const fnValidation = async () => {
    if (CM.cfnIsEmpty(selectedPaperBill["totalAmount"]) || selectedPaperBill["totalAmount"] === 0) {
      CM.cfnAlert("영수금액이 입력된 내용이 없습니다.");
      return false;
    }

    //상단부터 순차적으로 공급내역 기입 여부
    for (let i = 0; i < 10; i++) {
      const nextIndex = i + 1;

      if (nextIndex === 10) break;

      let upperAmount = selectedPaperBill["paperReceiptItemDtos"][i]["itemAmount"];
      let lowerAmount = selectedPaperBill["paperReceiptItemDtos"][nextIndex]["itemAmount"];
      if (upperAmount === "" || upperAmount === null) {
        upperAmount = 0;
      }
      if (lowerAmount === "" || lowerAmount === null) {
        lowerAmount = 0;
      }
      if (upperAmount === 0 && lowerAmount !== 0) {
        CM.cfnAlert("공급내역을 순서대로 입력하셔야 합니다.");
        return false;
      }
    }
    return true;
  };

  /*
   * @desc    전송 전 state 처리 함수
   * 발행일자, 영수일자, 공급내역(DTO)를 하나의 Object리스트객체에 담아 반환한다.
   */
  const fnConvertForRequest = () => {
    const tempObjList = CM.cfnCopyObject(selectedBillsList);
    for (let i = 0; i < tempObjList.length; i++) {
      tempObjList[i] = adjustParameters(tempObjList[i]);
    }
    return tempObjList;
  };

  const adjustParameters = (tempObj) => {
    const tempArray = [];

    if (tempObj.billRemark === null) {
      tempObj.billRemark = "";
    }
    tempObj.issueDate = CM.cfnConvertDateToString(tempObj.issueDate);
    tempObj.transactionDate = CM.cfnConvertDateToString(tempObj.transactionDate).replace(/\./gi, "");
    for (const row of tempObj.paperReceiptItemDtos) {
      if (row.itemAmount !== 0 && row.itemAmount !== "") {
        tempArray.push(row);
      }
    }
    tempObj.paperReceiptItemDtos = tempArray;
    return tempObj;
  };

  /*
   * @desc  영수증 발행 Request 함수
   * 발행완료 후 발행된 리스트의 상세정보값을 모달을 닫으며 메인컴포넌트(paperBillPublishTargetTab)으로 보낸다.
   * 발행모달안에 보여주었던 정보를 초기화한다.
   */
  const fnIssuePaperBill = () => {
    const paramObj = fnConvertForRequest();

    const url = "api/extraservice/receipt/targets";
    CM.cfnAxios(url, "post", paramObj, (status, data) => {
      CM.cfnAlert("정상적으로 처리되었습니다.", async () => {
        let tempObjList = CM.cfnCopyObject(selectedBillsList);
        setSelectedPaperBill({});
        handleModal("print", false, tempObjList);
      });
    });
  };

  /*
   * @desc  종이(세금)계산서 발행 버튼 클릭 이벤트 핸들러
   */
  const handleClickSavePaperBill = () => {
    if (fnValidation()) {
      fnSavePaperBill();
    }
  };

  /*
   * @desc  계산서 저장 Request 함수
   * 정보 임시저장 요청
   */
  const fnSavePaperBill = () => {
    const paramObj = adjustParameters(CM.cfnCopyObject(selectedPaperBill));
    const url = "api/extraservice/receipt/targets/detail";
    CM.cfnAxios(url, "put", paramObj, (status, data) => {
      if (status.status === 200) {
        setIsContentsChanged(false);
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {});
      } else {
        CM.cfnAlert(typeof data === "string" ? data : data.message || "종이영수증 정보를 임시저장하지 못했습니다.");
      }
    });
  };

  // 모달 클로즈
  const handleClose = (type, open) => {
    setSelectedPaperBill({});
    setSelectedBillsList([]);
    handleModal(type, open);
  };

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={(e) => handleClose("close", false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>종이영수증 발행</h3>
          <BillForm.PaperBillCustomerSelect selectedBillsList={selectedBillsList} selectedPaperBill={selectedPaperBill} currentIndex={currentIndex} handleChangeSelected={handleChangeSelected} />
          <BillForm.PaperBillCustomerInfo selectedPaperBill={selectedPaperBill} currentIndex={currentIndex} handlePaperBillFormChange={handlePaperBillFormChange} master={master} />
          <BillForm.PaperBillReceiptInfo selectedPaperBill={selectedPaperBill} handlePaperBillFormChange={handlePaperBillFormChange} />
          <BillForm.PaperBillDetails selectedPaperBill={selectedPaperBill} handlePaperBillFormChange={handlePaperBillFormChange} />

          <div className="table-bottom-area centered">
            <button className="btn-m" onClick={handleClickSavePaperBill} data-testid="paper-bill-save">
              임시저장
            </button>
            <button className="btn-l" onClick={handleClickIssuePaperBill} data-testid="paper-bill-issue">
              영수증 전체 발행
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaperBillIssue;
