import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import CustomerStatistics from "./CustomerStatistics";
import DailyStatistics from "./DailyStatistics";
import MonthlyStatistics from "./MonthlyStatistics";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as CM from "../../common/Common";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
const StatisticsChart = ({ props }) => {
  const { store } = props;
  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  const [instituteInfoState, setInstituteInfoState] = useState(false);
  // 기관구분정보 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosPaymentCapitals(); //지급 자금종류
      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      if (businessInfo.cmsService.indexOf("EB21") === -1 && businessInfo.cmsService.indexOf("EC21") === -1) {
        await setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 입금이체서비스만 이용하시는 기관입니다.", () => {});
      }
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles(theme => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6
    }
  }));

  const style = useStyles();

  return (
    <div id="statisticsChartWrapper" data-testid="statisticsChartWrapper" className={instituteInfoState ? style.disabled_div : ""}>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
          <Tab disableRipple id="customerStatistics" value={0} label="고객별통계(수납정보기준)" />
          <Tab disableRipple id="dailyStatistics" value={1} label="일별통계(수납일기준)" />
          <Tab disableRipple id="monthlyStatistics" value={2} label="월별(수납정보기준)" />
        </Tabs>
      </AppBar>

      <TabPanel value={tabIndex} index={0}>
        <div>
          <div className="inforbox">
            <ul>
              <li>수납 내역을 기준으로 각 고객별로 수납금액, 미납금액, 청구중금액을 볼 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <CustomerStatistics tabIndex={tabIndex} />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <div>
          <div className="inforbox">
            <ul>
              <li>조회 조건에 해당하는 수납대상을 조회할 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <DailyStatistics tabIndex={tabIndex} />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <div>
          <div className="inforbox">
            <ul>
              <li>월별 통계는 대상 년도의 수납건수 및 금액을 월 단위로 제공합니다.</li>
            </ul>
          </div>
        </div>
        <MonthlyStatistics tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props
}))(observer(StatisticsChart));
