import React, { useState, useEffect } from "react";
import ElectronicTaxbillsRequest from "./ElectronicTaxbillsRequest";
import ElectronicTaxbillsResult from "./ElectronicTaxbillsResult";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import * as CM from "../../common/Common";
import { Link } from "react-router-dom";

const ElectronicTaxbills = ({ props, store }) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  const [instituteInfoState, setInstituteInfoState] = useState(false);

  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  useEffect(() => {
    const loadInstituteService = async () => {
      const resultInstituteService = await store.axiosInstituteService();
      const trusbill = resultInstituteService.find((service) => service.serviceType === "TRUSBILL");
      setInstituteInfoState(trusbill && trusbill.serviceUseYn);
      if (!trusbill || !trusbill.serviceUseYn) {
        CM.cfnAlert("전자세금계산서 서비스를 이용하시려면 기관·업무정보 > 부가서비스 정보 화면에서 신청해주시기 바랍니다.", () => {});
      }
    };
    loadInstituteService();
  }, [store]);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  return (
    <div className={instituteInfoState ? "" : style.disabled_div}>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="account-tab">
          <Tab disableRipple value={0} label="발행대상 조회 및 발행" />
          <Tab disableRipple value={1} label="발행내역" />
          <Tab disableRipple value={2} label="이용안내" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <ElectronicTaxbillsRequest tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ElectronicTaxbillsResult tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className="inforbox">
          <ul>
            <li>
              전자세금계산서 발행 내역을 국세청으로 안정적으로 전송하기 위해 빌링원플러스에서 발행되는 <b style={{ color: "#2C83B9" }}>모든 전자세금계산서는 위수탁 전자세금계산서로 발행</b>됩니다.
              <div className="h05" />
              위수탁 전자세금계산거는 공급하는자, 공급받는자 등 모든 기재 내용은 보통 전자세금계산서와 동일하나 금융결제원이 수탁자로 추가 기재될 뿐이며,
              <div className="h05" />
              이로 인한 부가가치세 관련 세법 위반 등 문제는 없으므로 안심하고 이용하시기 바랍니다.
            </li>

            <li>
              세금계산서 발행(작성)일은 아래와 같이 세팅됩니다.
              <div className="h05" />
              - 영수세금계산서 : 수납일
              <div className="h05" />- 청구(취소)세금계산서 : 발행일
            </li>
            <li>
              세금계산서는 주민등록번호(사업자번호)로 발행이 가능하오니, 발행대상 고객의 고객 기본정보(
              <Link to="/customer/customerInformationList">
                <b style={{ color: "#2C83B9" }}>고객정보관리 &gt; 고객 기본정보</b>
              </Link>
              )에서 생년월일(주민등록번호, 사업자번호)란에 반드시 주민등록번호(사업자번호)를 입력하시기 바랍니다.(생년월일만 입력한 경우 보여지지 않음)
            </li>
            <li>
              영세 및 면세에 대해 세금계산서를 발행하려는 경우,{" "}
              <a href="https://www.trusbill.or.kr" target="_blank" rel="noopener noreferrer" className="link">
                트러스빌 홈페이지
              </a>
              에서 발행하여 주시기 바랍니다.(빌링원플러스에서는 과세만 가능)
            </li>

            <li>세금계산서발행 버튼을 클릭하면 익일 17:00에 국세청으로 자동전송되며, 최종발행결과는 발행결과 화면에서 확인가능합니다.</li>
            <li>발행내역에 대한 취소는 익일 17:00 이후(국세청 전송결과 수신 후)에 발행결과 화면에서 가능합니다.</li>
            <li>
              금액을 수정하여 발행하려는 경우, 월별자금관리에서 해당 수납자금내역으로 금액을 수정하신 후 발행하시기 바랍니다.
              <br />
              (단, 이미 전자세금계선서가 발행된 수납자금내역은 수정이 불가합니다)
            </li>
            <li>
              부분 출금내역에 대해 세금계산서를 발행하려는 경우,{" "}
              <a href="https://www.trusbill.or.kr" target="_blank" rel="noopener noreferrer" className="link">
                트러스빌 홈페이지
              </a>
              에서 개별 발행하시거나 완납 후 영수세금계산서를 발행하여 주시기 바랍니다.
            </li>
            <li>발행완료된 청구세금계산서를 취소하는 경우, 수납내역의 상태가 미납으로 바뀌면 세금계산서를 발행할 수 없으니 발행취소작업은 신중하게 진행해주시기 바랍니다.</li>
            <li>발행결과 &gt; 문서상태에서 "센터오류"는 빌링원플러스 내부의 검증결과이므로 과급되지 않습니다.단, 국세청 오류는 과급 대상</li>
            <li>
              빌링원플러스 사이트 이외의 곳에서 발행한 세금계산서 내역은 확인할 수 없으며, 기타 자세한 내용은{" "}
              <a href="https://www.trusbill.or.kr" target="_blank" rel="noopener noreferrer" className="link">
                트러스빌 홈페이지
              </a>{" "}
              공지사항을 참고하시기 바랍니다.
            </li>
            <li>전자세금계산서는 이용건수에 따라 별도로 요금이 부과되는 유료서비스 입니다.</li>
          </ul>
        </div>
      </TabPanel>
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(ElectronicTaxbills));
