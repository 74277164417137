import { action, observable } from "mobx";
import * as CM from "../components/common/Common";
import CDN from "../components/common/CommonDataName";

/*
 * @desc  로그인 스토어
 */
export default class LoginStore {
  constructor(root) {
    this.root = root;
    this.DEFAULT_TIMEOUT_IN_SECONDS = 1 * 60 * 30; //세션 유지 시간

    //이용 시간 경과에 따른 세션 만료 처리
    setInterval(() => {
      let expireTime = this.getSessionTime();

      if (this.isLogin) {
        this.setSessionExpireTime(--expireTime);
      }

      if (this.isLogin && expireTime === 0) {
        this.expireSession();
        CM.cfnAlert("장시간(30분) 미사용으로 인하여 로그아웃되었습니다. 다시 로그인 후 이용해주세요.", null);
      }
    }, 1000);
  }

  @observable
  loginDto = {};

  @observable
  isLogin = null;

  @action
  setLoginDto = (objData) => (this.loginDto = objData);

  @action
  setIsLogin = (flag) => (this.isLogin = flag);

  @action
  setSessionExpireTime = (time) => {
    CM.storage.set("expireTime", time);
  };

  @action
  resetSessionExpireTime = () => {
    CM.storage.set("expireTime", this.DEFAULT_TIMEOUT_IN_SECONDS);
  };

  @action("세션 유효시간 경과 후 자동 로그아웃 처리")
  expireSession = async () => {
    // 로그인 정보 제거
    CM.storage.set("expired", true);
    await this.setIsLogin(false);
    this.setLoginDto({});
    CM.cfnSetHeader("");

    this.root.instituteStore.initialInstituteStore();

    CM.storage.remove("loginDto");
    CM.storage.remove("expireTime");
  };

  @action("login axios - 개발용 로그인")
  getLoginDto = (data) => {
    //200108 api/auth/login/yessign
    const url = "api/auth/login/yessign/test";
    CM.cfnAxios(url, "post", data, this.fnLoginCallback);
  };

  @action("logout axios")
  getLogout = () => {
    const url = "api/auth/logout";
    CM.cfnAxios(url, "post", "", this.fnLogoutCallback);
  };

  @action("서비스 권한 가져오기")
  getEvidence = () => {
    if (CM.cfnIsEmpty(this.loginDto)) return false;
    if (this.loginDto.evidenceSubmissionTargetYn === false) return "면제";
    if (this.loginDto.evidenceSubmissionTargetYn) {
      const today = new Date();
      const dateDifference = CM.cfnDateCalculation(today, this.loginDto.evidenceSubmissionStartDate);

      if (dateDifference < 0) return CDN.evidenceSubmissionType(this.loginDto.evidenceSubmissionTypeBeforeStartDate);
      else return CDN.evidenceSubmissionType(this.loginDto.evidenceSubmissionType);
    }
  };

  @action("로컬 스토리지에 저장된 세션 만료 시간 조회")
  getSessionTime = () => {
    let sessionTime = CM.storage.get("expireTime");
    if (CM.cfnIsEmpty(sessionTime)) sessionTime = 0;

    return sessionTime;
  };

  /*
   * @desc  로그인 콜백 함수
   */
  fnLoginCallback = async (objStatus, objData) => {
    // 로그인 실패시
    if (objStatus.status !== 200) {
      this.setIsLogin(false);
      this.setLoginDto({});
      CM.cfnAlert(objStatus.statusText);
      return;
    }

    this.setSessionExpireTime(this.DEFAULT_TIMEOUT_IN_SECONDS); //세션 유지시간 설정
    CM.cfnSetHeader(objData.jwt); // Authorization 설정
    this.setIsLogin(true);

    let loginData = objData;
    loginData.loginParameters = this.loginParameters;

    // 로그인 데이터 설정
    await this.setLoginDto(loginData);
    CM.storage.set("loginDto", loginData);
    CM.storage.remove("expired");
  };

  /*
   * @desc  로그인 콜백 함수
   */
  fnCertificationLoginCallback = async (objStatus, objData) => {
    // 로그인 실패시
    if (objStatus.status !== 200) {
      this.setIsLogin(false);
      this.setLoginDto({});
      CM.cfnAlert(objStatus.statusText);
      return;
    }

    //console.error('로그인스토어 콜백' + objData.jwt); //로그인 테스트

    this.setSessionExpireTime(this.DEFAULT_TIMEOUT_IN_SECONDS); //세션 유지시간 설정
    CM.cfnSetHeader(objData.jwt); // Authorization 설정
    this.setIsLogin(true);

    let loginData = objData;
    loginData.loginParameters = this.loginParameters;

    // 로그인 데이터 설정
    await this.setLoginDto(loginData);
    CM.storage.set("loginDto", loginData);
    CM.storage.remove("expired");

    document.location.href = "/";
  };

  /*
   * @desc  로그아웃 콜백 함수
   */
  fnLogoutCallback = (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return;
    }

    this.expireSession();
  };
}
