import * as CM from "../../common/Common";

//paging 처리
export const paginationData = (data) => {
  const returnObject = {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  };

  if (CM.cfnIsNotEmpty(data)) {
    returnObject.rowsPerPage = data.pageable.pageSize;
    returnObject.offset = data.pageable.offset;
    returnObject.total = data.totalElements;
    returnObject.totalPages = data.totalPages;
  }

  return returnObject;
};

//CMS입금관리 > CMS 출금 청구 탭에서 출금청구일 세팅하는 함수
export const fnGetNextCmsReceiptDate = async (fileType) => {
  let today = new Date();
  const currentYear = today.getFullYear().toString();
  const currentMonth = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
  const currentDate = today.getDate().toString() > 9 ? today.getDate().toString() : `0${today.getDate().toString()}`;
  const currentTime = (today.getHours() > 9 ? today.getHours() : `0${today.getHours()}`) + "" + (today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`);

  //휴일 조회 Request
  const fnFetchPeriodDate = (paramDate) => {
    return new Promise((resolve) => {
      const url = `api/gadget/bizDate/period?startDate=${paramDate}&endDate=${paramDate}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        if (data.length !== 0) {
          resolve(data[0].isHoliday);
        } else {
          resolve(false);
        }
      });
    });
  };

  //기준일자
  let requestDate = new Date();
  let periodDate = await fnFetchPeriodDate(`${currentYear}${currentMonth}${currentDate}`);

  return new Promise(async (resolve) => {
    if (fileType) {
      //익일
      if (periodDate || currentTime > "1700") {
        //휴일 혹은 시간초과
        let tempRequestDate = await CM.fnGetNextBizDate(`${currentYear}${currentMonth}${currentDate}`);
        requestDate = await CM.fnGetNextBizDate(tempRequestDate);
      } else {
        requestDate = await CM.fnGetNextBizDate(`${currentYear}${currentMonth}${currentDate}`);
      }

      resolve(requestDate);
    } else {
      //당일
      if (periodDate || currentTime > "1200") {
        //휴일 혹은 시간초과
        requestDate = await CM.fnGetNextBizDate(`${currentYear}${currentMonth}${currentDate}`);
      }
      resolve(requestDate);
    }
  });
};

//cms 출금청구 - 출금청구일 계산 함수
export const fnCalculateDate = (fileType, nextBizDate) => {
  let transactionDate = new Date();
  const currentDay = new Date().getDay();
  const currentTime = new Date().getHours();

  if (fileType) {
    //익일출금
    transactionDate = new Date(`${nextBizDate.substr(0, 4)}-${nextBizDate.substr(4, 2)}-${nextBizDate.substr(6, 2)}`);
  } else {
    //당일출금 (12시 이전이고 주중에 작업 시에는 별도 조건문 처리 필요 없으므로 생략)
    if (currentDay === 0 || currentDay === 6 || (currentTime >= 12 && currentDay !== 6)) {
      //주말에 작업하거나 주중 12시 이후에 작업 시 다음 영업일을 출력
      transactionDate = new Date(`${nextBizDate.substr(0, 4)}-${nextBizDate.substr(4, 2)}-${nextBizDate.substr(6, 2)}`);
    }
  }

  return transactionDate;
};

//cms 출금청구 - 출금청구대상 요약정보
export const cmsDefaultSummary = {
  amountOfFullError: 0,
  amountOfFullWithdraw: 0,
  amountOfPartialError: 0,
  amountOfPartialWithdraw: 0,
  askTargetCriteria: "",
  fileStatus: "READY",
  fileType: true,
  numberOfAskedRecords: 0,
  numberOfExceededLimit: 0,
  numberOfFullError: 0,
  numberOfFullWithdraw: 0,
  numberOfPartialWithdraw: 0,
  numberOfRegistrationError: 0,
  numberOfUnregistrationError: 0,
  numberOfZeroAmount: 0,
  receivedFileDatetime: "",
  sentFileDatetime: "",
  targetIntegratedYn: true,
  totalAskingAmount: 0,
  totalAskingDelayFee: 0,
  totalAskingNumberOfRegistration: 0,
  totalAskingNumberOfUnregistration: 0,
  totalAskingPreviouslyUnaskedAmount: 0,
  totalAskingThisTimeAmount: 0,
  totalAskingUnpaidAmount: 0,
  totalBankFee: 0,
  transactionDate: new Date(),
  uniqueKey: "",
  maxLimitAmountForMonthlyWithdrawal: 0, //월간 출금한도
  limitAmountForMonthlyWithdrawal: 0, //잔여 월간출금한도
  limitAmountForEachWithdrawal: 0, //건별 출금한도
  reaskUnpaidLimit: "", //출금청구대상 - 미납금 체크박스 제어
};

//cms 출금청구 - 출금청구대상 체크박스 제어
export const defaultCheckbox = {
  thisTime: false,
  unasked: false,
  unpaid: false,
};

//cms 출금청구 - 출금청구대상 필터 셀렉트 박스
export const targetOption = [
  { label: "고객명", value: "customerName" },
  { label: "납부자번호", value: "payerNo" },
];

//cms 출금청구 수정- 출금청구대상 필터 셀렉트 박스
export const targetOptionForUpdate = [
  { label: "고객명", value: "customerName" },
  { label: "납부자번호", value: "payerNo" },
  { label: "청구대상구분", value: "askUniqueKey" },
];

//cms 출금청구 - CMS 출금청구대상(완료내역) 목록 조회 조건
export const requestSearchData = {
  capitalUniqueKey: "",
  customerName: "",
  fileUniqueKey: "",
  groupUniqueKey: "",
  payAmountType: "",
  pageNumber: 0,
  pageSize: 5,
  payerNo: "",
  resultCode: "",
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  term: "customerName",
  keyword: "",
};

//cms 출금청구 - 출금대상추가 모달 조회 조건
export const addTargetSearchData = {
  capitalUniqueKey: "",
  customerName: "",
  fileUniqueKey: "",
  groupUniqueKey: "",
  pageNumber: 0,
  pageSize: 5,
  payerNo: "",
  resultCode: "",
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  term: "customerName",
  keyword: "",
};

//cms 출금청구 - 출금대상추가 모달 조회 조건 내 수납방법 셀렉트 옵션
export const transactionMethodOption = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "CMS",
    value: "CMS_DEPOSIT",
  },
  {
    label: "기타",
    value: "ETC",
  },
];
//cms 출금청구 - 출금 청구 대상 수정 모달 조회 조건
export const updateTargetSearchData = {
  searchStartY4: "",
  searchStartMm: "",
  searchEndY4: "",
  searchEndMm: "",
  searchPaySpecifiedDay: "",
  capitalUniqueKey: "",
  customerName: "",
  fileUniqueKey: "",
  groupUniqueKey: "",
  pageNumber: 0,
  pageSize: 5,
  payerNo: "",
  askUniqueKey: "",
  sortDirection: "ASC",
  sortProperty: "CUSTOMER_NAME",
  term: "customerName",
  keyword: "",
};

//cms 출금청구 결과 조회 조건
export const resultSearchData = () => {
  const today = new Date();
  const endDate = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate());
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return {
    searchStartDate: monthAgo,
    searchEndDate: endDate,
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "SENT_FILE_DATETIME",
    capitalName: "",
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
  };
};

//cms 출금청구 결과 - 출금청구대상 요약정보로 체크박스 제어 state 생성
export const convertFromCmsAskTargetCriteria = (value) => {
  let tempObj = {
    thisTime: false,
    unasked: false,
    unpaid: false,
  };

  switch (value) {
    case "THIS_TIME_ONLY":
      tempObj.thisTime = true;
      break;
    case "UNASKED_ONLY":
      tempObj.unasked = true;
      break;
    case "UNPAID_ONLY":
      tempObj.unpaid = true;
      break;
    case "THIS_TIME_AND_UNASKED":
      tempObj.thisTime = true;
      tempObj.unasked = true;
      break;
    case "THIS_TIME_AND_UNPAID":
      tempObj.thisTime = true;
      tempObj.unpaid = true;
      break;
    case "UNASKED_AND_UNPAID":
      tempObj.unasked = true;
      tempObj.unpaid = true;
      break;
    case "THIS_TIME_AND_UNASKED_AND_UNPAID":
      tempObj.thisTime = true;
      tempObj.unasked = true;
      tempObj.unpaid = true;
      break;
    default:
      break;
  }

  return tempObj;
};

//cms 출금청구 결과 - 상세 조회 시 출금결과 셀렉트 박스 제어 state 변환
export const convertToTargetCriteria = (targetCheckbox) => {
  let targetCriteria = "";

  if (targetCheckbox.thisTime) {
    if (targetCheckbox.unasked) {
      if (targetCheckbox.unpaid) {
        //당회분 O & 미청구분 O & 미납분 O
        targetCriteria = "THIS_TIME_AND_UNASKED_AND_UNPAID";
      } else {
        //당회분 O & 미청구분 O & 미납분 X
        targetCriteria = "THIS_TIME_AND_UNASKED";
      }
    } else {
      if (targetCheckbox.unpaid) {
        //당회분 O & 미청구분 X & 미납분 O
        targetCriteria = "THIS_TIME_AND_UNPAID";
      } else {
        //당회분 O & 미청구분 X & 미납분 X
        targetCriteria = "THIS_TIME_ONLY";
      }
    }
  } else {
    if (targetCheckbox.unasked) {
      if (targetCheckbox.unpaid) {
        //당회분 X & 미청구분 O & 미납분 O
        targetCriteria = "UNASKED_AND_UNPAID";
      } else {
        //당회분 X & 미청구분 O & 미납분 X
        targetCriteria = "UNASKED_ONLY";
      }
    } else {
      if (targetCheckbox.unpaid) {
        //당회분 X & 미청구분 X & 미납분 O
        targetCriteria = "UNPAID_ONLY";
      }
    }
  }

  return targetCriteria;
};

//cms 출금청구 결과 - 청구상태
export const fnGetStatusButton = async (row) => {
  let statusObj = {
    statusName: "",
    disabled: false,
    message: "",
    isResultApplyingRequired: false, //결과확인 버튼 렌더 시 "결과확인" 기능 필요 여부 (사용자가 청구결과를 조회했는지 확인 후 CMS 출금 청구 결과 목록 재조회)
    isBackupRequired: false, //결과확인 버튼 렌더 시 "결과확인 API" 청구 필요 여부
  };

  if (row.transactionDate.length > 0) {
    const currentTime = new Date();
    const fileDate = row.transactionDate;

    let prevBizDate, nextBizDate, eb21SendableTime, eb21CancelableTime, eb21ConfirmTime, ec21SendableTime, ec21CancelableTime, ec21ConfirmTime;
    if (row.fileType === "EB21") {
      switch (row.fileStatus) {
        case "READY":
          prevBizDate = await CM.fnGetPrevBizDate(fileDate);

          eb21SendableTime = new Date(`${prevBizDate.toString().substr(0, 4)}-${prevBizDate.toString().substr(4, 2)}-${prevBizDate.toString().substr(6, 2)}T17:00:00`); //송신가능시간
          if (currentTime < eb21SendableTime) {
            //EB21 파일전송 가능시간 전
            statusObj.disabled = false;
            statusObj.statusName = "청구대기";
            statusObj.message = "출금대상 생성 후 청구하기를 클릭하지 않은 상태입니다.<br> 버튼을 눌러 CMS 출금 청구 탭으로 이동하여 청구하기를 클릭해주세요.";
          } else {
            //EB21 파일전송 가능시간 경과 후
            statusObj.disabled = true;
            statusObj.statusName = "청구불가";
            statusObj.message = "전송 가능 시간을 초과하였습니다.<br>CMS 출금 청구 탭에서 청구대상을 삭제하여 주시기 바랍니다.";
          }
          break;
        case "TRANSMITTED":
          prevBizDate = await CM.fnGetPrevBizDate(fileDate);
          nextBizDate = await CM.fnGetNextBizDate(fileDate);

          eb21CancelableTime = new Date(`${prevBizDate.toString().substr(0, 4)}-${prevBizDate.toString().substr(4, 2)}-${prevBizDate.toString().substr(6, 2)}T19:50:00`); //송신취소가능시간
          eb21ConfirmTime = new Date(`${nextBizDate.toString().substr(0, 4)}-${nextBizDate.toString().substr(4, 2)}-${nextBizDate.toString().substr(6, 2)}T03:30:00`); //결과확인시간

          if (currentTime < eb21CancelableTime) {
            //취소가능시간이 지나기 전일 경우
            if (row.autoWithdrawYn) {
              statusObj.disabled = true;
              statusObj.statusName = "청구중";
              statusObj.message = "자동출금으로 청구되어 현재 정상적으로 청구 진행 중입니다.";
            } else {
              statusObj.disabled = false;
              statusObj.statusName = "취소요청";
              statusObj.message = "청구마감 시점이 도래하지 않아 청구취소가 가능한 상태입니다."
            }
          } else {
            if (currentTime >= eb21ConfirmTime) {
              //결과확인가능시간 경과 후
              statusObj.disabled = false;
              statusObj.statusName = "결과확인";
              statusObj.isBackupRequired = true; //결과수신인지 확인하기 위한 플래그값
              statusObj.message = "청구한 내역의 출금결과를 수신할 수 있습니다.<br> 버튼을 눌러 결과를 확인해주세요.";
            } else {
              //취소가능시간 경과 후
              statusObj.disabled = true;
              statusObj.statusName = "결과확인";
              statusObj.message = `취소요청 가능 시간을 초과하였습니다. <br>${nextBizDate.toString().substr(0, 4)}-${nextBizDate.toString().substr(4, 2)}-${nextBizDate
                .toString()
                .substr(6, 2)} 03시 이후에 출금결과를 확인할 수 있습니다.`;
            }
          }
          break;
        case "APPLIED_RESULT_FILE":
          statusObj.disabled = false;
          statusObj.statusName = "결과확인";
          statusObj.isResultApplyingRequired = true;
          statusObj.message = "청구한 내역의 실제 출금여부를 확인할 수 있습니다.<br> 버튼을 눌러 결과를 확인해주세요."
          break;
        case "CONFIRMED_RESULT_FILE":
          statusObj.disabled = true;
          statusObj.statusName = "확인완료";
          break;
        default:
          break;
      }
    } else if (row.fileType === "EC21") {
      switch (row.fileStatus) {
        case "READY":
          ec21SendableTime = new Date(`${fileDate.substr(0, 4)}-${fileDate.substr(4, 2)}-${fileDate.substr(6, 2)}T12:00:00`); //송신가능시간

          if (currentTime < ec21SendableTime) {
            //EC21 파일전송 가능시간 전
            statusObj.disabled = false;
            statusObj.statusName = "청구대기";
            statusObj.message = "출금대상 생성 후 청구하기를 클릭하지 않은 상태입니다.<br> 버튼을 눌러 CMS 출금 청구 탭으로 이동하여 청구하기를 클릭해주세요."
          } else {
            //EC21 파일전송 가능시간 경과 후
            statusObj.disabled = true;
            statusObj.statusName = "청구불가";
            statusObj.message = "전송 가능 시간을 초과하였습니다.<br>CMS 출금 청구 탭에서 청구대상을 삭제하여 주시기 바랍니다.";
          }
          break;
        case "TRANSMITTED":
          ec21CancelableTime = new Date(`${fileDate.substr(0, 4)}-${fileDate.substr(4, 2)}-${fileDate.substr(6, 2)}T12:30:00`); //송신취소가능시간
          ec21ConfirmTime = new Date(`${fileDate.substr(0, 4)}-${fileDate.substr(4, 2)}-${fileDate.substr(6, 2)}T23:30:00`); //결과확인시간

          if (currentTime < ec21CancelableTime) {
            //취소가능시간이 지나기 전일 경우
            if (row.autoWithdrawYn) {
              statusObj.disabled = true;
              statusObj.statusName = "청구중";
              statusObj.message = "자동출금으로 청구되어 현재 정상적으로 청구 진행 중입니다.";
            } else {
              statusObj.disabled = false;
              statusObj.statusName = "취소요청";
            }
          } else {
            if (currentTime >= ec21ConfirmTime) {
              //결과확인가능시간 경과 후
              statusObj.disabled = false;
              statusObj.statusName = "결과확인";
              statusObj.isBackupRequired = true; //결과수신인지 확인하기 위한 플래그값
              statusObj.message = "청구한 내역의 출금결과를 수신할 수 있습니다.<br> 버튼을 눌러 결과를 확인해주세요.";
            } else {
              //취소가능시간 경과 후
              statusObj.disabled = true;
              statusObj.statusName = "결과확인";
              statusObj.message = `취소요청 가능 시간을 초과하였습니다. <br>${fileDate.toString().substr(0, 4)}-${fileDate.toString().substr(4, 2)}-${fileDate
                .toString()
                .substr(6, 2)} 23시 이후에 출금결과를 확인할 수 있습니다.`;
            }
          }
          break;
        case "APPLIED_RESULT_FILE":
          statusObj.disabled = false;
          statusObj.statusName = "결과확인";
          statusObj.message = "청구한 내역의 실제 출금여부를 확인할 수 있습니다.<br> 버튼을 눌러 결과를 확인해주세요."
          statusObj.isResultApplyingRequired = true;
          break;
        case "CONFIRMED_RESULT_FILE":
          statusObj.disabled = true;
          statusObj.statusName = "확인완료";
          break;
        default:
          break;
      }
    }
  }
  return statusObj;
};

//cms 출금청구 결과 - 파일종류
export const fileType = (value) => {
  switch (value) {
    case "EB21":
      return "익일출금";
    case "EC21":
      return "당일출금";
    case "EB31":
      return "입금이체";
    case "EB13":
      return "이용기관접수분";
    case "EB11":
      return "금융기관접수분";
    case "EA44":
      return "현금영수증자동발행";
    case "BO11":
      return "현금영수증직접발행";
    default:
      return value;
  }
};

//cms 출금청구 - 검색조건 내 셀렉트 옵션 생성
export const fnMakeSelectOptions = (type, list) => {
  const tempArray = [];

  if (type === "capital" && CM.cfnIsNotEmpty(list)) {
    //자금종류 검색 셀렉트 옵션
    for (let i = 0; i < list.length; i++) {
      tempArray.push({
        label: list[i].capitalName,
        value: list[i].uniqueKey,
      });
    }
  } else if (type === "group" && CM.cfnIsNotEmpty(list)) {
    //고객구분 검색 셀렉트 옵션
    for (let i = 0; i < list.length; i++) {
      tempArray.push({
        label: list[i].customerGroupName,
        value: list[i].uniqueKey,
      });
    }
  }
  return tempArray;
};

//출금청구대상 수정 모달 - 자금유형 변환
export const fnConvertPayAmountType = (type) => {
  switch (type) {
    case "FIXED":
      return "정액";
    case "VARIABLE":
      return "비정액";
    case "TEMPORARY_ADDED":
      return "추가분";
    default:
      return "";
  }
};
