import * as CM from "../../common/Common";

const PaperReceiptData = {
  //paging 처리
  paginationData: (data) => {
    const returnObject = {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };

    if (CM.cfnIsNotEmpty(data)) {
      returnObject.rowsPerPage = data.pageable.pageSize;
      returnObject.offset = data.pageable.offset;
      returnObject.total = data.totalElements;
      returnObject.totalPages = data.totalPages;
    }

    return returnObject;
  },
  //종이영수증 발행대상 검색조건
  targetSearchData: () => {
    const today = new Date();
    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    return {
      capitalName: "",
      capitalNameKey: "",
      customerGroupName: "",
      customerGroupNameKey: "",
      customerName: "",
      fromDate: monthAgo,
      identificationNo: "",
      pageNumber: 0,
      pageSize: 5,
      sortDirection: "DESC",
      sortProperty: "transactionDate",
      toDate: today,
      transactionMethod: "",
      term: "customerName",
      keyword: "",
    };
  },
  //종이영수증 발행대상 추가 검색조건
  addTargetSearchData: () => ({
    pageNumber: 0,
    pageSize: 5,
    customerGroupName: "",
    customerName: "",
    identificationNo: "",
    term: "customerName",
    keyword: "",
  }),
  //종이영수증 발행대상 추가 모달 내 페이징 처리
  modalPaginationData: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
    pageNumber: 0,
  },
  //종이영수증 발행내역 검색조건
  issuedSearchData: () => {
    const today = new Date();
    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    return {
      customerName: "",
      fromDate: monthAgo,
      identificationNo: "",
      pageNumber: 0,
      pageSize: 5,
      sortDirection: "DESC",
      sortProperty: "issueDate",
      toDate: today,
      term: "customerName",
      keyword: "",
    };
  },
  //종이세금계산서 종류
  fnConvertTaxBillDirection: (direction) => {
    if (direction === "RECEIPT") {
      return "영수";
    } else if (direction === "INVOICE") {
      return "청구";
    }
  },
};

export default PaperReceiptData;
