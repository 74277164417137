import React from "react";
import { Modal, Button } from "@material-ui/core";
const PersonalInfoModalVersion3 = ({ open, setOpen }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="personalInfoVer3-close-modal">
              {""}
            </Button>
          </div>
          <div className="ftm2">
            <h3 className="title">개인정보 처리방침 (2016. 9. 30 ~ 2017. 11. 13 적용)</h3>

            <div className="content-box-scroll-back">
              <div className="content-box-scroll">
                <p>
                  금융결제원 빌링원플러스 홈페이지에 방문하여 주셔서 대단히 감사합니다.
                  <br />
                  금융결제원은 이용고객의 개인정보를 무엇보다 중요하게 생각하며 이용고객이 안심하고 서비스를 이용할 수 있도록 개인정보의
                  <br />
                  안전한 처리를 위하여 다음과 같이 개인정보 처리방침을 수립하여 운영하고 있습니다.
                </p>
                <h5 className="title">제1조(개인정보의 처리 목적)</h5>
                <p>
                  금융결제원 빌링원플러스는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용목적이 변경되는 경우에는
                  개인정보보호법에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                  <br />
                  <br />
                  1. 이용기관 담당자 인증 : 회원제 서비스 제공에 따른 본인 식별 · 인증, 회원자격 유지 · 관리, 서비스 부정이용 방지
                  <br />
                  2. 금융(세무)서비스 이용 : CMS 고객등록 및 입 · 출금업무, 현금영수증, 전자세금계산서, 기부금영수증
                  <br />
                  3. 민원 및 이용기관 관리 : 민원사항 확인 및 연락 · 통지, 각종 안내사항 고지 · 통지 등
                </p>
                <h5 className="title">제2조(처리하는 개인정보의 항목)</h5>
                <p>① 금융결제원 빌링원플러스는 다음의 개인정보를 처리하고 있습니다.</p>
                <table className="contentbox-table">
                  <thead>
                    <tr>
                      <th className="w200">구분</th>
                      <th className="w350">필수항목</th>
                      <th className="w250">선택항목</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. 이용기관 담당자 인증</td>
                      <td>성명, 생년월일, ID, 비밀번호</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>2. 금융&#40;세무&#41;서비스 이용</td>
                      <td>거래금융기관명, 계좌번호, 휴대폰번호, 생년월일, 주민등록번호 </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>3. 민원 및 이용기관 관리</td>
                      <td>전화번호, 이메일주소, 주소</td>
                      <td>팩스번호, 직위, 생일, 결혼기념일</td>
                    </tr>
                  </tbody>
                </table>
                <p>주&#41; 현금영수증, 기부금영수증 발급 등 주민등록번호 수집에 대한 법적근거가 있는 업무에 한함.</p>
                <p>
                  ② 기타 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
                  <br />- IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
                </p>

                <h5 className="title">제3조(개인정보의 수집방법)</h5>
                <p>금융결제원 빌링원플러스는 고객 동의 및 관계 법령에 근거하여 다음의 방법으로 개인정보를 수집하고 있습니다.&#13;&#10;&#13;&#10;&#13;&#10; </p>
                <ol>
                  <li>
                    이용기관 담당자 인증
                    <br />- 서비스 이용신청시 이용기관이 제공한 정보 수집
                  </li>
                  <li>
                    금융(세무)서비스 이용
                    <br />- 서비스 이용신청시 이용기관이 제공한 정보 수집
                    <br />- 이용기관이 고객의 동의를 받아 수집하여 빌링원플러스에 입력한 정보 수집
                  </li>
                  <li>
                    민원 및 이용기관 관리
                    <br />- 서비스 이용신청시 이용기관이 제공한 정보 수집
                    <br />- 고객센터 등을 통한 민원 신청 시 고객으로부터 직접 수집
                  </li>
                </ol>
                <h5 className="title">제4조(개인정보의 처리 및 보유 기간)</h5>
                <p>금융결제원 빌링원플러스는 법령에 따른 개인정보 보유 · 이용기간 내에서 개인정보를 처리 및 보유합니다</p>
                <ol>
                  <li>
                    이용기관 정보
                    <br />- 해지 신청 시까지 &#13;&#10;다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지&#13;&#10;
                    <br />
                    가. 관계 법령 위반에 따른 수사 · 조사등이 진행중인 경우에는 해당 수사 · 조사 종료시까지&#13;&#10;
                    <br />
                    나. 홈페이지 이용에 따른 채권 · 채무관계 잔존시에는 해당 채권 · 채무관계 정산시까지&#13;&#10;
                  </li>

                  <li>
                    금융(세무)서비스 이용 기록&#13;&#10;
                    <br />- 「전자금융거래법」 제22조에 따라 5년간 보관&#13;&#10;
                  </li>
                  <li>
                    민원관리&#13;&#10;
                    <br /> - 민원처리 완료 후 5년까지
                  </li>
                </ol>
                <h5 className="title">제5조(개인정보의 제3자 제공에 관한 사항)</h5>
                <p>
                  ① 금융결제원 빌링원플러스는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
                  외부에 공개 또는 제3자에게 제공하지 않습니다. 다만 아래의 경우는 예외로 합니다.&#13;&#10;
                </p>
                <ol>
                  <li>정보주체가 사전 동의한 경우&#13;&#10;</li>
                  <li>법령의 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우&#13;&#10;</li>
                </ol>
                <p>② 금융결제원 빌링원플러스는 제1항에 따른 개인정보 제3자 제공 내역이 없습니다.</p>
                <h5 className="title">제6조(개인정보처리의 위탁에 관한 사항)</h5>
                <p>금융결제원 빌링원플러스는 개인정보처리를 위탁하지 않습니다.</p>
                <h5 className="title">제7조(정보주체와 법정대리인의 권리 · 의무 및 그 행사방법에 관한 사항)</h5>
                <p>
                  ① 정보주체와 법정대리인은 금융결제원 빌링원플러스에서 보유하고 있는 개인정보의 열람, 정정 · 삭제, 처리정지를 청구하실 수 있습니다. 다만 다음에 해당하는 경우에는 개인정보의 열람, 정정
                  · 삭제, 처리정지가 제한될 수 있습니다.
                </p>
                <ol>
                  <li>법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위해 불가피한 경우</li>
                  <li>다른 사람의 생명 · 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</li>
                </ol>
                <p>② 제1항에 따른 권히 행사는 서면, 전자우편, 모사전송(FAX)을 통하여 요청하실 수 있습니다.</p>
                <p>
                  ③ 정보주체의 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있으며, 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
                  합니다.
                </p>
                <p>④ 금융결제원 빌링원플러스는 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우, 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</p>
                <p>⑤ 열람, 정정 · 삭제, 처리정지 요구에 대한 처리절차는 다음과 같습니다.</p>
                <div className="box-text2">
                  □ 개인정보 열람요구
                  <br />
                  ①개인정보 열람요구 → ②청구주체 확인 및 열람범위 확인 → ③개인정보 열람 제한사항 확인 → ④열람결정 통지(or 열람거부 통지) → ⑤열람
                  <br />
                  <br />
                  □ 개인정보 정정·삭제, 처리정지 요구
                  <br />
                  ①개인정보 정정·삭제·처리정지 요구 → ②청구주체 확인 및 처리범위 확인 → ③개인정보 정정·삭제, 처리정지 제한사항 확인 → ④정정·삭제, 처리정지 결과통지
                  <br />
                  <br />
                  (양식){" "}
                  <a href="/documents/개인정보열람등관련서식.hwp" download>
                    개인정보 열람 등 관련서식(요구서, 통지서, 위임장)
                  </a>
                </div>
                <h5 className="title">제8조(개인정보의 안전성 확보 조치에 관한 사항)</h5>
                <p>금융결제원 빌링원플러스는 개인정보의 안전성 확보를 위해 다음과 같은 안전성 확보조치를 취하고 있습니다.</p>
                <ol>
                  <li>관리적 조치 : 개인정보보호 관련 규정 및 계획 수립, 개인정보취급자 최소화 및 교육 등</li>
                  <li>기술적 조치 : 개인정보처리시스템 접근통제 및 접근권한 관리, 고유식별정보 등 주요개인정보 암호화, 네트워크 송수신 구간 암호화, 침입탐지시스템 등 보안솔루션 설치 및 운영 등</li>
                  <li>물리적 조치 : 전산실 및 자료보관실 접근통제, 장비 및 자료 반출입 통제 등</li>
                </ol>
                <h5 className="title">제9조(개인정보의 파기에 관한 사항)</h5>
                <p>① 금융결제원 빌링원플러스는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
                <p>
                  ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 다른 개인정보와
                  분리하여 엄격히 별도 저장 · 관리 합니다.
                </p>
                <p>③ 금융결제원 빌링원플러스의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
                <ol>
                  <li>파기절차&#13;&#10; 파기사유가 발생한 개인정보를 선정하고 책임자의 승인을 거쳐 파기합니다.</li>
                  <li>
                    파기방법&#13;&#10; 개인정보를 복구 또는 재생되지 않도록 다음과 같이 완전삭제 조치합니다.
                    <br />- 전자적 파일 : 전용소자장비 등을 이용하여 복원이 불가능한 방법으로 삭제
                    <br />- 기타 기록물, 인쇄물, 서면, 그 밖의 기록매체 : 파쇄 또는 소각
                  </li>
                </ol>
                <h5 className="title">제10조(개인정보 보호책임자에 관한 사항)</h5>
                <p>
                  ① 금융결제원 빌링원플러스는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                  지정하고 있습니다.
                </p>
                <ul>
                  <li>개인정보보호책임자 : 정보보호본부장</li>
                  <li>개인정보관리책임자 : e사업실장</li>
                  <li>
                    개인정보관리담당자 : e사업실 빌링원플러스 담당자
                    <br />· 연락처 : 02) 531-3343 ~ 3344, ebpp@kftc.or.kr
                    <br />· 주소 : (우)463-811 경기도 성남시 분당구 정자일로 213번길 9
                  </li>
                </ul>
                <p>② 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제등에 관한 사항을 위의 연락처로 문의주시면 즉시 조치하여 처리결과를 통보해 드리겠습니다.</p>
                <h5 className="title">제11조(개인정보 자동수집장치의 설치ㆍ운영 및 그 거부에 관한 사항)</h5>
                <p>
                  ① 금융결제원 빌링원플러스는 홈페이지에 대한 기본 설정정보를 보관하기 위해 쿠키(cookie) 등 개인정보 자동수집장치를 설치ㆍ운영하고 있습니다. 쿠키는 빌링원플러스 홈페이지를 운영하는데
                  이용되는 서버가 고객의 브라우저에 보내는 아주 작은 텍스트 파일로서 고객의 컴퓨터 하드디스크에 저장됩니다.
                </p>
                <p>
                  ② 고객은 쿠키 이용에 대한 선택권을 가지고 있으며 웹브라우저 설정을 통해 쿠키의 이용을 허용하거나 거부할 수 있습니다. 단, 쿠키의 저장을 거부하는 옵션을 선택하는 경우 서비스 이용에
                  불편이 야기될 수 있습니다.{" "}
                </p>
                <div className="box-text2">
                  · 쿠키 설정 거부 방법
                  <br />
                  (인터넷 익스플로어의 경우) 웹 브라우저 상단의 “도구 &gt; 인터넷옵션 &gt; 개인정보 &gt; 고급” → ‘허용’, ‘차단’, ‘사용자가 선택’ 설정 가능
                  <br />
                  (크롬 브라우저의 경우) 웹 브라우저 상단 우측의 “설정 &gt; 개인정보 &gt; 콘텐츠 설정 &gt; 쿠키” → ‘허용’, ‘브라우저 종료 시까지 유지’, ‘차단’ 설정 가능
                </div>
                <h5 className="title">제12조(권익침해 구제방법)</h5>
                <p>① 개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 삼당 등을 신청할 수 있습니다.</p>
                <p>② 개인정보 침해로 인한 신고나 상담이 필요한 경우에는 아래 기관에 문의하시기 바랍니다.</p>
                <div className="box-text2">
                  · 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                  <br />· 개인정보 분쟁조정위원회 : (국번없이) 118 (privacy.kisa.or.kr)
                  <br />· 대검찰청 사이버수사과 : 02-3480-3571(cybercid.spo.go.kr)
                  <br />· 경찰청 사이버안전국 : (국번없이 ) 182 (cyberbureau.police.go.kr)
                </div>
                <h5 className="title">제13조(개인정보 처리 방침 변경)</h5>
                <p>① 이 개인정보 처리방침은 2016. 9. 30일 부터 적용됩니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PersonalInfoModalVersion3;
