import { Checkbox, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaymentDirectlyPaidModal from "./PaymentDirectlyPaidModal";
import PaymentRefundModal from "./PaymentRefundModal";
// import Data from "../../customer/customerBasicInformation/CustomerData";

// 검색 데이터
function searchData(contractUniqueKey) {
  const today = new Date();

  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 2);
  const monthAfter = new Date(today.getFullYear(), today.getMonth() + 2);

  return {
    contractUniqueKey: contractUniqueKey,
    searchStartY4mm: monthAgo,
    searchEndY4mm: monthAfter,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;
  const today = new Date();

  const nextYear = new Date(today.getFullYear() + 1, today.getMonth() + 2);
  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">지급기간</label>
        <Template.DatePickerForm
          className="w140 receiptRequestResultDatePicker"
          value={searchRequest.searchStartY4mm}
          handleChange={handleSearchFormChange("searchStartY4mm")}
          format="yyyy.MM"
          customProps={{
            placeholder: "YYYY.MM",
            maxDate: nextYear,
            views: ["month", "year"],
          }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w140 receiptRequestResultDatePicker"
          value={searchRequest.searchEndY4mm}
          handleChange={handleSearchFormChange("searchEndY4mm")}
          format="yyyy.MM"
          customProps={{
            placeholder: "YYYY.MM",
            maxDate: nextYear,
            views: ["month", "year"],
          }}
        />
        <button className="search-button" onClick={(e) => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  선택 회차 지급내역 변경 이력 컴포넌트 생성
 */
function HistoryListForm(props) {
  const fnTransactionType = (transactionType) => {
    switch (transactionType) {
      case "EB31":
        return "CMS지급";
      case "DIRECTLY_PAID":
        return "직접지급";
      case "REFUND":
        return "환입";
      case "CHANGE_AMOUNT":
        return "금액변경";
      case "HOLD_OFF_ASKING":
        return "지급보류";
      case "CANCEL_HOLD_OFF_ASKING":
        return "지급보류취소";
      case "TEMPORARY_ADDED":
        return "추가분";
      case "EXEMPT_ASK_TARGET":
        return "입금대상제외";
      default:
        return transactionType;
    }
  };

  const fnPaidAmount = (transactionType, amountBeforeTransaction, amountAfterTransaction) => {
    switch (transactionType) {
      case "EB31":
      case "DIRECTLY_PAID":
      case "REFUND":
        return CM.cfnAddComma(amountBeforeTransaction - amountAfterTransaction);
      case "CHANGE_AMOUNT":
        return CM.cfnAddComma(amountAfterTransaction - amountBeforeTransaction);
      default:
        return "-";
    }
  };

  return (
    <div>
      <h4>선택 회차 지급내역 변경 이력</h4>
      <Table>
        {CM.cfnCompColGroup(["13%", "13%", "15%", "15%", "auto"])}
        <TableForm.compTableHead arrData={["변경일", "처리구분", "지급금액", "미지급금액", "비고"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={5} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">{CM.cfnDateFormat(row.askedDate)}</TableCell>
                  <TableCell align="center">{fnTransactionType(row.transactionType)}</TableCell>

                  {fnPaidAmount(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction) === "-" ? (
                    <TableCell align="center">{fnPaidAmount(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction)}</TableCell>
                  ) : (
                    <TableCell align="right">{fnPaidAmount(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction)}</TableCell>
                  )}

                  {row.transactionType === "EB31" || row.transactionType === "DIRECTLY_PAID" || row.transactionType === "CHANGE_AMOUNT" || row.transactionType === "TEMPORARY_ADDED" ? (
                    <TableCell align="right"> {CM.cfnAddComma(row.amountAfterTransaction)}</TableCell>
                  ) : (
                    <TableCell align="center">-</TableCell>
                  )}

                  <TableCell align="center">{row.remark}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc 회차별 지급 내역 컴포넌트 생성
 */
function ContractListForm(props) {
  const {
    fnContractDetail,
    list,
    checkAllRow,
    handleCheckAllRowChange,
    handleUpdateChange,
    setPaymentHolding,
    setPaymentCancelHolding,
    setPaymentDirectlyPaid,
    setPaymentRefund,
    setContractDetail,
    pagination,
    handleOffsetChange,
    handleChangeRowsPerPage,
  } = props;

  const data = [];
  for (const row of list) {
    if (row._checked) {
      const copyObj = CM.cfnCopyObject(row);
      data.push(copyObj);
      setContractDetail(row);
    }
  }

  const fnAlert = (message) => {
    CM.cfnAlert(`${message}할 지급내역을 선택해주세요.`);
  };

  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.askStatus === "HOLDING") {
          return "미청구\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "미청구\n(청구중)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.askStatus === "HOLDING") {
          return "미도래\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "미도래\n(청구중)";
        } else {
          return "미도래";
        }
      case "UNPAID":
        if (row.askStatus === "HOLDING") {
          return "미납\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "미납\n(청구중)";
        } else {
          return "미납";
        }
      case "PARTIALLY_PAID":
        if (row.askStatus === "HOLDING") {
          return "부분납\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "부분납\n(청구중)";
        } else {
          return "부분납";
        }
      case "PAID":
        return "지급";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "지급보류";
      default:
        if (row.askStatus === "ASKING" && row.numberOfAsking === 1) {
          return "입금중(최초입금)";
        } else if (row.askStatus === "ASKING" && row.numberOfAsking > 1) {
          return "입금중(재입금)";
        }
    }
  };

  const fnButton = () => {
    if (data.length === 1) {
      let buttonCount = 0;
      let buttonHtml = [];
      if (data[0].askStatus === "NORMAL" && (data[0].paidAmount > 0 || data[0].delayFee > 0)) {
        //환입버튼
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="refund-modal" onClick={(e) => setPaymentRefund(true)}>
            환입
          </button>
        );
      }

      if (
        (data[0].askStatus === "NORMAL" || data[0].askStatus === "HOLDING") &&
        (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")
      ) {
        //직접지급
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="directlyPaid-modal" onClick={(e) => setPaymentDirectlyPaid(true)}>
            직접지급
          </button>
        );
      }
      if (data[0].askStatus === "HOLDING") {
        // 보류해제
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="save-excel" onClick={(e) => setPaymentCancelHolding(true)}>
            보류해제
          </button>
        );
      }
      if (data[0].askStatus === "NORMAL" && (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")) {
        //지급보류
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="save-excel" onClick={(e) => setPaymentHolding(true)}>
            지급보류
          </button>
        );
      }

      if (buttonCount > 0) {
        buttonHtml.push(
          <label className="label-l" key={buttonCount + 1}>
            선택한 회차를
          </label>
        );
      }
      return buttonHtml;
    } else if (data.length === 0) {
      return (
        <span>
          <button className="btn-l fr" data-testid="refund-modal" onClick={(e) => (data.length > 0 ? setPaymentRefund(true) : fnAlert("환입"))}>
            환입
          </button>
          <button className="btn-l fr" data-testid="directlyPaid-modal" onClick={(e) => (data.length > 0 ? setPaymentDirectlyPaid(true) : fnAlert("지급"))}>
            직접지급
          </button>
          <button className="btn-l fr" data-testid="hodingCancel-modal" onClick={(e) => setPaymentCancelHolding(true)}>
            보류해제
          </button>
          <button className="btn-l fr" data-testid="hoding-modal" onClick={(e) => setPaymentHolding(true)}>
            지급보류
          </button>
          <label className="label-l">선택한 회차를</label>
        </span>
      );
    } else {
      return (
        <span>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setPaymentCancelHolding(true)}>
            보류해제
          </button>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setPaymentHolding(true)}>
            지급보류
          </button>
          <label className="label-l">선택한 회차를</label>
        </span>
      );
    }
  };
  return (
    <div>
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={list.length || 0} />
        <Template.RowPerPageForm value={pagination.rowsPerPage} onChange={handleChangeRowsPerPage} customProps={{ inputProps: { name: "rowsPerPage" } }} />
        <div className="fr">{fnButton()}</div>
      </div>
      <Table>
        {CM.cfnCompColGroup(["8%", "10%", "15%", "15%", "15%", "15%", "auto"])}
        <TableForm.compCheckboxTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={["회차", "지급년월", "지급대상금액", "지급금액", "미지급금액", "지급상태"]}

          // arrData={[
          //   { id: "CUSTOMER_NAME", label: "회차", sortable: true },
          //   { id: "PAYER_NO", label: "지급년월", sortable: true },
          //   { id: "identificationNoFirst7", label: "지급대상액", sortable: false },
          //   { id: "accountNo", label: "지급액", sortable: false },
          //   { id: "paySpecifiedDate", label: "미지급액", sortable: false },
          //   { id: "totalAskingAmount", label: "지급상태", sortable: false }
          // ]}
        />
        <TableBody>
          {list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            list.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage).map((row, index) => {
              return (
                <TableRow key={index} hover className="show-detail" onClick={(e) => fnContractDetail(row, e)}>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: pagination.page * pagination.rowsPerPage + index,
                      }}
                    />
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.contractAskSequence) ? row.contractAskSequence : "추가분"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.totalAskingAmount)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.paidAmount)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.unpaidAmount)}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {fnAskStatus(row)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Template.PaginationForm filteredRows={list} pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <div className="table-bottom-area" />
    </div>
  );
}

/*
 * @desc  지급 고객 정보 컴포넌트 생성
 */
function PaymentCustomerForm(props) {
  return (
    <div>
      <div>
        <Table>
          {CM.cfnCompColGroup(["15%", "19%", "15%", "18%", "15%", "18%"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">고객명</TableCell>
              <TableCell className="td">{Object.isExtensible(props.paymentSourceDetail.customer) ? props.paymentSourceDetail.customer.customerName : ""}</TableCell>
              <TableCell className="th">고객구분</TableCell>
              <TableCell className="td">{!Object.isExtensible(props.paymentSourceDetail.customer) ? "" : props.paymentSourceDetail.customer.customerType === "INDIVIDUAL" ? "개인" : "법인"}</TableCell>
              <TableCell className="th">생년월일(사업자번호)</TableCell>
              <TableCell className="td">{Object.isExtensible(props.paymentSourceDetail.customer) ? CM.cfnIdentificationNoFormat(props.paymentSourceDetail.customer.identificationNo) : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">담당지사/담당자</TableCell>
              <TableCell className="td">
                {!Object.isExtensible(props.paymentSourceDetail.customer)
                  ? ""
                  : Object.isExtensible(props.paymentSourceDetail.customer.staff)
                  ? Object.isExtensible(props.paymentSourceDetail.customer.staff.branch)
                    ? props.paymentSourceDetail.customer.staff.branch.branchName
                    : ""
                  : ""}
                /
                {!Object.isExtensible(props.paymentSourceDetail.customer)
                  ? ""
                  : Object.isExtensible(props.paymentSourceDetail.customer.staff)
                  ? props.paymentSourceDetail.customer.staff.staffName
                  : ""}
              </TableCell>
              <TableCell className="th">지급금액</TableCell>
              <TableCell className="td">{props.paymentSourceDetail.payAmount === 0 ? "비정액" : CM.cfnAddComma(props.paymentSourceDetail.payAmount) + "원"}</TableCell>
              <TableCell className="th">자금종류</TableCell>
              <TableCell className="td">{Object.isExtensible(props.paymentSourceDetail.capital) ? props.paymentSourceDetail.capital.capitalName : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">지급시작월</TableCell>
              <TableCell className="td">{CM.cfnDateFormat(props.paymentSourceDetail.payStartY4mm, "yyyyMM")}</TableCell>
              <TableCell className="th">지급종료월</TableCell>
              <TableCell className="td">{props.paymentSourceDetail.payEndY4mm !== "999912" ? CM.cfnDateFormat(props.paymentSourceDetail.payEndY4mm, "yyyyMM") : "기한없음"}</TableCell>
              <TableCell className="th">지급일</TableCell>
              <TableCell className="td">{props.paymentSourceDetail.paySpecifiedDay === "99" ? "말일" : props.paymentSourceDetail.paySpecifiedDay}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="table-bottom-area">
          <button className="btn-m" data-testid="insert-Payments" onClick={(event) => props.history.goBack()}>
            목록
          </button>
        </div>
      </div>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function PaymentSourceDetail(props) {
  let history = useHistory();
  let location = useLocation();

  const [paymentSourceDetail, setPaymentSourceDetail] = useState(location.state.paymentSourceDetail);

  const [searchRequest, setSearchRequest] = useState(searchData(paymentSourceDetail.contractUniqueKey)); // 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [contractList, setContractList] = useState([]); // 회차별 지급 내역 데이터
  const [historyList, setHistoryList] = useState([]); // 선택 회차 지급내역 변경 이력 데이터

  // table head checkbox state
  const [checkAllRow, setCheckAllRow] = useState(false);

  useEffect(() => {
    /*
     * @desc 날짜(년월)변환함수 date to string
     */
    const cfnConvertDateToString = (date) => {
      let obj = date;
      //파라미터 객체가 Date 타입일 때만 변환
      if (date.getFullYear) {
        const year = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();

        obj = `${year}${mm[1] ? mm : 0 + mm[0]}`;
      }

      return obj;
    };

    /*
     * @desc 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = (search) => {
      let tempObj = CM.cfnCopyObject(search);
      //파라미터 객체가 Date 타입일 때만 변환
      tempObj.searchStartY4mm = cfnConvertDateToString(search.searchStartY4mm);
      tempObj.searchEndY4mm = cfnConvertDateToString(search.searchEndY4mm);
      return tempObj;
    };

    /*
     * @desc 지급 고객 정보 조회
     */
    const axiosPayment = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/payment/payments/${search.contractUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    회차별 지급 내역
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/contract?searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&contractUniqueKey=${search.contractUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      //지급 고객 정보
      const resultPayment = await axiosPayment(search);
      setPaymentSourceDetail(resultPayment);

      //회차별 지급 내역
      const resultData = await axiosList(search);
      if (CM.cfnIsNotEmpty(resultData)) {
        for (const element of resultData) {
          element._checked = false;
          element.index = resultData.indexOf(element);
        }
      }
      await setContractList(resultData);
      await setCheckAllRow(false);
      await setSearchButton(false);
    }

    // 실행 영역
    if (searchButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param);
    }
  }, [searchRequest, searchButton]);

  const [paymentHolding, setPaymentHolding] = useState(false); //지급보류
  const [paymentCancelHolding, setPaymentCancelHolding] = useState(false); //지급보류해제
  const [paymentDirectlyPaid, setPaymentDirectlyPaid] = useState(false); //직접지급
  const [paymentRefund, setPaymentRefund] = useState(false); //환불
  const [contractDetail, setContractDetail] = useState(false); //모달에 props로 넘길 데이터

  //지급보류, 지급보류해제 (버튼 제어)
  useEffect(() => {
    /*
     * @desc   지급보류 Request
     */
    const startAxiosHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/holding`;
        if (sendData.length === 1) {
          url = `api/payment/source/holding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };
    /*
     * @desc   지급보류해제 Request
     */
    const startAxiosCancelHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/cancelHolding`;
        if (sendData.length === 1) {
          url = `api/payment/source/cancelHolding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };

    const checkedData = [];
    const sendData = [];
    for (const row of contractList) {
      if (row._checked) {
        checkedData.push(row);
      }
    }

    // 지급보류 버튼 클릭
    if (paymentHolding === true) {
      if (checkedData.length < 1) {
        CM.cfnAlert("보류처리 할 지급내역을 선택해주세요. ", () => {
          setPaymentHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if (row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 지급내역은 보류처리 대상이 아닙니다.`, () => {
            setPaymentHolding(false);
          });
          return;
        }
      }
      startAxiosHolding(sendData);
      setPaymentHolding(false);
    }
    // 보류해체처리 버튼 클릭
    if (paymentCancelHolding === true) {
      if (checkedData.length === 0) {
        CM.cfnAlert("보류해체처리 할 지급내역을 선택해주세요. ", () => {
          setPaymentCancelHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") && row.askStatus === "HOLDING") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 지급내역은 보류해체처리 대상이 아닙니다.`, () => {
            setPaymentCancelHolding(false);
          });
          return;
        }
      }
      startAxiosCancelHolding(sendData);
      setPaymentCancelHolding(false);
    }
  }, [paymentHolding, paymentCancelHolding, contractList]);

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e,
    });
  };
  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;
    setCheckAllRow(checkAllValue);
    setContractList((constract) => constract.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...contractList];
    cloneData[index][key] = value;
    setContractList(cloneData);
  };

  //선택 회차 지급내역 변경 이력 조회
  function fnContractDetail(row, e) {
    let url = `api/payment/source/history?sourceUniqueKey=${row.uniqueKey}`;
    CM.cfnAxios(url, "get", "", fnCallback);
  }

  const fnCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return;
    }
    setHistoryList(objData);
  };

  const [pagination, setPagination] = useState({ rowsPerPage: 5, offset: 0, page: 0 });

  /*
   * @desc    페이지 변경 이벤트 핸들러
   */
  const handleOffsetChange = (offset, page) => {
    setPagination({
      ...pagination,
      offset: offset,
      page: page - 1,
    });
  };

  /*
  @desc 표시 데이터 수 변경 이벤트 핸들러
 */
  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: event.target.value,
      page: 0,
    });
  };

  return (
    <div>
      <h4>지급 고객 정보</h4>
      <PaymentCustomerForm paymentSourceDetail={paymentSourceDetail} history={history} />
      <h4>회차별 지급내역</h4>
      <div className="inforbox">
        <ul>
          <li>
            "직접지급", "환입" 처리 후 <b>취소 및 수정이 불가능하오니</b> 신중하게 처리하시기 바랍니다.
          </li>
        </ul>
      </div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <ContractListForm
        list={contractList}
        setContractList={setContractList}
        checkAllRow={checkAllRow}
        fnContractDetail={fnContractDetail}
        handleCheckAllRowChange={handleCheckAllRowChange}
        handleUpdateChange={handleUpdateChange}
        setPaymentHolding={setPaymentHolding}
        setPaymentCancelHolding={setPaymentCancelHolding}
        setPaymentDirectlyPaid={setPaymentDirectlyPaid}
        setPaymentRefund={setPaymentRefund}
        setContractDetail={setContractDetail}
        pagination={pagination}
        handleOffsetChange={handleOffsetChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <HistoryListForm list={historyList} />

      <PaymentDirectlyPaidModal open={paymentDirectlyPaid} setOpen={setPaymentDirectlyPaid} contractDetail={contractDetail} setSearchButton={setSearchButton} />
      <PaymentRefundModal open={paymentRefund} setOpen={setPaymentRefund} contractDetail={contractDetail} setSearchButton={setSearchButton} />
    </div>
  );
}

export default PaymentSourceDetail;
