import { Button, Checkbox, Input, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import {
  expectedApplyY4mmData,
  paginationData,
  searchOptionData,
  searchPayAmountTypeData,
  searchPaySpecifiedDayData,
  searchTransactionMethodData,
  updateSearchData
} from "./PaymentInformationData";
import {TotalCountForm} from "../../template/ComponentForm";
import {RowPerPageForm} from "../../template/ComponentForm";

// 기본 적용시점
const defaultExpectedApplyY4mm = CM.cfnGetDate().substr(0, 6);

// 지급고객정보 > 일괄변경 Modal
// 렌더를 처리하는 메인 컴포넌트
const PaymentUpdateList = ({ open, setOpen, searchCapitalUniqueKeyData, searchGroupUniqueKeyData }) => {
  const [searchRequest, setSearchRequest] = useState(updateSearchData()); // table 검색 조건
  const [searchButton, setSearchButton] = useState(false);

  const [filterPaymentList, setFilterPaymentList] = useState([]);
  const [pagination, setPagination] = useState(paginationData());

  // 화면 open시 화면 초기화
  useEffect(() => {
    if (!open) {
      return;
    }

    // state 초기화
    setSearchRequest(updateSearchData());
    setSearchButton(true);
    setFilterPaymentList([]);
    setPagination(paginationData());
    setCheckAllRow(false);

    // 검색조건 state
    setSearchCapitalUniqueKey("");
    setSearchPayAmountType("");
    setSearchPaySpecifiedDay("");
    setSearchTransactionMethod("");
    setSearchGroupUniqueKey("");
    setInput("");

    // 변경조건 state
    setCapitalUniqueKey("");
    setPaySpecifiedDay("");
    setPayAmountType("");
    setPayAmount("");
    setExpectedApplyY4mm(defaultExpectedApplyY4mm);
  }, [open]);

  // 수정 가능한 목록 가져오기
  useEffect(() => {
    function axiosList() {
      return new Promise((resolve, reject) => {
        let url = `api/customer/payment/payments/update?pageNumber=${searchRequest.pageNumber}&pageSize=${searchRequest.pageSize}&sortDirection=${searchRequest.sortDirection}&sortProperty=${searchRequest.sortProperty}&capitalUniqueKey=${searchRequest.searchCapitalUniqueKey}&transactionMethod=${searchRequest.searchTransactionMethod}&payAmountType=${searchRequest.searchPayAmountType}&searchProperty=${searchRequest.terms}&paySpecifiedDay=${searchRequest.searchPaySpecifiedDay}&searchContents=${searchRequest.search}&groupUniqueKey=${searchRequest.searchGroupUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    }

    async function startAxios() {
      const resultData = await axiosList();
      const resultPayment = resultData.content.map((element) => ({ ...element, _checked: false }));

      setFilterPaymentList(resultPayment);
      setPagination(paginationData(resultData));
      setSearchButton(false);
    }

    if (searchButton) {
      startAxios();
    }
  }, [searchRequest, searchButton, open]);

  // 검색 (page)
  function handleOffsetChange(e, offset, page) {
    setSearchRequest({
      ...searchRequest,
      pageNumber: page - 1,
    });

    handleSearchButtonChange(true);
  }

  // 검색 컴포넌트 handleChange
  function handleSearchFormChange(searchTransactionMethod, searchCapitalUniqueKey, searchPayAmountType, searchPaySpecifiedDay, searchGroupUniqueKey, terms, search, pageNumber) {
    setSearchRequest({
      ...searchRequest,
      searchTransactionMethod,
      searchCapitalUniqueKey,
      searchPayAmountType,
      searchPaySpecifiedDay,
      searchGroupUniqueKey,
      terms,
      search,
      pageNumber,
    });
  }

  // 검색 flag handleChange
  function handleSearchButtonChange(flag) {
    setSearchButton(flag);
  }

  const [searchCapitalUniqueKey, setSearchCapitalUniqueKey] = useState("");
  const [searchPayAmountType, setSearchPayAmountType] = useState("");
  const [searchPaySpecifiedDay, setSearchPaySpecifiedDay] = useState("");
  const [searchGroupUniqueKey, setSearchGroupUniqueKey] = useState("");
  const [searchTransactionMethod, setSearchTransactionMethod] = useState("");
  const [input, setInput] = useState("");

  const searchTransactionMethodList = searchTransactionMethodData();
  const searchPaySpecifiedDayList = searchPaySpecifiedDayData();
  const searchPayAmountTypeList = searchPayAmountTypeData();
  const searchOptionList = searchOptionData();

  const handlesearchCapitalUniqueKeyChange = (e) => setSearchCapitalUniqueKey(e.target.value);
  const handleInputChange = (e) => setInput(e.target.value);
  const handleTransactionMethodChange = (e) => setSearchTransactionMethod(e.target.value);
  const handlesearchPayAmountTypeListChange = (e) => setSearchPayAmountType(e.target.value);
  const handleSearchPaySpecifiedDayListChange = (e) => setSearchPaySpecifiedDay(e.target.value);

  function handleSearKeyUp(e) {
    if (e.keyCode === 13) {
      fnSearch();
    }
  }

  // 검색바 옵션 검색
  function fnSearch() {
    handleSearchFormChange(searchTransactionMethod, searchCapitalUniqueKey, searchPayAmountType, searchPaySpecifiedDay, searchGroupUniqueKey, searchRequest.terms, input, 0);
    handleSearchButtonChange(true);
  }

  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  const [capitalUniqueKey, setCapitalUniqueKey] = useState("");
  const [paySpecifiedDay, setPaySpecifiedDay] = useState("");
  const [payAmountType, setPayAmountType] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [expectedApplyY4mm, setExpectedApplyY4mm] = useState(defaultExpectedApplyY4mm);

  const expectedApplyY4mmList = expectedApplyY4mmData();
  const handleCapitalUniqueKeyChange = (e) => setCapitalUniqueKey(e.target.value);
  const handlePayAmountChange = (e) => setPayAmount(CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, "")));
  const handlePaySpecifiedDayListChange = (e) => setPaySpecifiedDay(e.target.value);
  const handlePayAmountTypeListChange = (e) => setPayAmountType(e.target.value);
  const handleExpectedApplyY4mmChange = (e) => setExpectedApplyY4mm(e.target.value);

  // submit 버튼
  function fnSubmit(getCapitalUniqueKey, getPayAmount, getPayAmountType, getPaySpecifiedDay, getExpectedApplyY4mm) {
    const url = "api/customer/payment/payments/updateList";

    const sendData = filterPaymentList
      .filter((payment) => payment._checked)
      .map((element) => {
        const uniqueKey = CM.cfnIsNotEmpty(element.uniqueKey) ? element.uniqueKey : "";
        const capitalUniqueKey = CM.cfnIsNotEmpty(getCapitalUniqueKey) ? getCapitalUniqueKey : element.capital.uniqueKey;
        const payAmount = CM.cfnIsNotEmpty(getPayAmount) ? Number(CM.cfnReplaceSymbol(getPayAmount)) : element.payAmount;
        const payAmountType = CM.cfnIsNotEmpty(getPayAmountType) ? getPayAmountType : element.payAmountType;
        const paySpecifiedDay = CM.cfnIsNotEmpty(getPaySpecifiedDay) ? getPaySpecifiedDay : element.paySpecifiedDay;

        return {
          ...element,
          capital: { uniqueKey: capitalUniqueKey },
          uniqueKey,
          payAmount,
          payAmountType,
          paySpecifiedDay,
          expectedApplyY4mm: element.expectedApplyY4mm > getExpectedApplyY4mm ? element.expectedApplyY4mm : getExpectedApplyY4mm,
        };
      });

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      CM.cfnAlert("변경할 지급정보를 선택해주세요.");
      return false;
    }

    // 변경사항이 없을 경우
    if (CM.cfnIsEmpty(getCapitalUniqueKey) && CM.cfnIsEmpty(getPayAmount) && CM.cfnIsEmpty(getPayAmountType) && CM.cfnIsEmpty(getPaySpecifiedDay)) {
      CM.cfnAlert("변경할 내용을 입력해주세요.");
      return false;
    }

    // 체크한 row가 비정액이 있을 경우
    // 변경 form에서 정액구분을 비정액으로 선택하고 지급액이 0원 이상일 경우
    if (sendData.some((row) => row.payAmountType === "VARIABLE" && Number(CM.cfnReplaceSymbol(getPayAmount)) > 0)) {
      CM.cfnAlert("비정액이면서 지급금액을 가질 수 없습니다.");
      return false;
    }

    // axios 호출
    CM.cfnAxios(url, "put", sendData, (status, response) => {
      CM.cfnAlert("변경되었습니다.", () => {
        // 데이터 재조회
        handleSearchButtonChange(true);

        // 데이터 초기화
        setCapitalUniqueKey("");
        setPaySpecifiedDay("");
        setPayAmountType("");
        setPayAmount("");
        setExpectedApplyY4mm(defaultExpectedApplyY4mm);
      });
    });
  }

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...filterPaymentList];
    cloneData[index][key] = value;
    setFilterPaymentList(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    setFilterPaymentList((Payment) => Payment.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    const checkPayment = filterPaymentList.filter((payment) => payment._checked);

    if (checkPayment.length === filterPaymentList.length && filterPaymentList.length > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [filterPaymentList]);

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색(rowperpage) handler
  const handleRowPerPageChange = ({ target: { value } }) => {
    setSearchRequest({ ...searchRequest, pageSize: value, pageNumber: 0 });
    handleSearchButtonChange(true);
  };

  // 검색옵션 handler
  const handleSearchOptionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name === "terms"){
      setSearchRequest((option) => ({ ...option, [name]: value }));
      setInput("");
    }else{
      setSearchRequest((option) => ({ ...option, [name]: value }));
    }
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner" style={{ paddingBottom: "0px" }}>
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="paymentUpdateList-close-modal">
              {""}
            </Button>
          </div>
          <h3>지급고객정보 일괄변경</h3>
          <div className="search-area">
            <div className="wrapSafeArea" style={{ justifyContent: "center" }}>
              <label className="label-l">고객구분</label>
              <Select native value={searchGroupUniqueKey} onChange={(e) => setSearchGroupUniqueKey(e.target.value)} name="searchGroupUniqueKey">
                <option value={""} key={"none"}>
                  전체
                </option>
                {searchGroupUniqueKeyData.map((option, index) => {
                  return (
                    <option value={option.uniqueKey} key={index}>
                      {option.customerGroupName}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">지급방법</label>
              <Select
                native
                value={searchTransactionMethod}
                onChange={handleTransactionMethodChange}
                name="accountRegistrationStatus"
                inputProps={{
                  "data-testid": "paymentUpdateList-select-searchTransactionMethod",
                }}>
                {searchTransactionMethodList.map((option, index) => {
                  return (
                    <option value={option.value} key={index}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">자금종류</label>
              <Select
                native
                value={searchCapitalUniqueKey}
                onChange={handlesearchCapitalUniqueKeyChange}
                name="searchCapitalUniqueKey"
                inputProps={{
                  "data-testid": "paymentUpdateList-select-searchCapitalUniqueKey",
                }}>
                <option value="" key="">
                  전체
                </option>
                {searchCapitalUniqueKeyData && searchCapitalUniqueKeyData.map
                  ? searchCapitalUniqueKeyData.map((option, index) => {
                      return (
                        <option value={option.uniqueKey} key={index}>
                          {option.capitalName}
                        </option>
                      );
                    })
                  : ""}
              </Select>
              <label className="label-l">지급일</label>
              <Select native value={searchPaySpecifiedDay} onChange={handleSearchPaySpecifiedDayListChange} name="accountRegistrationStatus">
                <option value={""} key={"none"}>
                  전체
                </option>
                {searchPaySpecifiedDayList.map((option, index) => {
                  return (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                  );
                })}
              </Select>
              <div>
              <label className="label-l">자금유형</label>
              <Select
                 native
                 value={searchPayAmountType}
                  onChange={handlesearchPayAmountTypeListChange}
                  name="accountRegistrationStatus"
                  inputProps={{
                    "data-testid": "paymentUpdateList-select-searchPayAmountType",
                  }}>
                {searchPayAmountTypeList.map((option, index) => {
                   return (
                       <option value={option.value} key={index}>
                         {option.label}
                       </option>
                   );
                 })}
              </Select>
              <label className="label-l">검색어 입력</label>
              <Select native value={searchRequest.terms} onChange={handleSearchOptionChange} name="terms">
                {searchOptionList.map((option, index) => {
                  return (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                  );
                })}
              </Select>
              <TextField className="w150" value={input} onChange={handleInputChange} onKeyUp={handleSearKeyUp} name="search" inputProps={{ "data-testid": "paymentUpdateList-textfield-search" }} />
              <button className="search-button" onClick={fnSearch} data-testid="paymentUpdateList-button-search">
                검색
              </button>
              </div>
            </div>
          </div>

          <div className="table-top-area">
            <TotalCountForm totalElements={pagination.total || 0} />
            <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
          </div>
          <Table>
            {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
            <TableForm.compServerSortTableHead
              useCheckbox={true}
              checked={checkAllRow}
              value=""
              onChange={handleCheckAllRowChange}
              arrData={[
                { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
                { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: false },
                { id: "CUSTOMER_GROUP_NAME", label: "고객구분", sortable: false },
                { id: "CAPITAL_NAME", label: "자금종류", sortable: false },
                { id: "PAY_SPECIFIED_DAY", label: "지급일", sortable: true },
                { id: "TRANSACTION_METHOD", label: "지급방법", sortable: false },
                { id: "PAY_AMOUNT_TYPE", label: "정액구분", sortable: false },
                { id: "PAY_AMOUNT", label: "지급금액", sortable: true },
                { id: "EXPECTED_APPLY_Y4MM", label: "적용가능월", sortable: false },
              ]}
              searchRequest={searchRequest}
              handleSortProperty={handleSortProperty}
              checkboxCustomProps={{ "data-testid": "paymentUpdateList-list-head-checkbox" }}
              tableSortLabelDataTestId={"paymentUpdateList-list-head-sortLabel"}
            />
            <TableBody>
              {filterPaymentList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={10} />
              ) : (
                filterPaymentList.map((payment, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        <Checkbox
                          checked={payment._checked}
                          value="_checked"
                          id={`deleteCheckbox-${index}`}
                          onChange={handleUpdateChange}
                          inputProps={{
                            index: index,
                            "data-testid": `paymentUpdateList-list-checkbox-${index}`,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" className="show-detail">
                        {payment.customer.customerName}
                      </TableCell>
                      <TableCell align="center">{CM.cfnIdentificationNoFormat(payment.customer.identificationNo)}</TableCell>
                      <TableCell align="center">{payment.customer?.customerGroup?.customerGroupName}</TableCell>
                      <TableCell align="center">{payment.capital.capitalName}</TableCell>
                      <TableCell align="center">{CM.cfnDayFormat(payment.paySpecifiedDay)}</TableCell>
                      <TableCell align="center">{CDN.paymentContract.transactionMethod(payment.transactionMethod)}</TableCell>
                      <TableCell align="center">{payment.payAmountType === "FIXED" ? "정액" : "비정액"}</TableCell>
                      <TableCell align={payment.payAmountType === "FIXED" ? "right" : "center"}>{payment.payAmountType === "FIXED" ? CM.cfnAddComma(payment.payAmount) + "원" : "-"}</TableCell>
                      <TableCell align="center">{CM.cfnDateFormat(payment.expectedApplyY4mm, "yyyyMM")}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
        <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="paymentUpdateList-list-pagiation" />
        <div className="modify-area">
          <div className="block">
            <span className="label-l">자금종류</span>
            <Select
              native
              value={capitalUniqueKey}
              onChange={handleCapitalUniqueKeyChange}
              inputProps={{
                "data-testid": "paymentUpdateList-select-capitalUniqueKey",
              }}>
              <option value="" key="">
                전체
              </option>
              {searchCapitalUniqueKeyData && searchCapitalUniqueKeyData.map
                ? searchCapitalUniqueKeyData.map((option, index) => {
                    return (
                      <option value={option.uniqueKey} key={index}>
                        {option.capitalName}
                      </option>
                    );
                  })
                : ""}
            </Select>
            <span className="label-l">지급일</span>
            <Select
              native
              value={paySpecifiedDay}
              onChange={handlePaySpecifiedDayListChange}
              inputProps={{
                "data-testid": "paymentUpdateList-select-paySpecifiedDay",
              }}>
              <option value="" key="">
                전체
              </option>
              {searchPaySpecifiedDayList.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
            <span className="label-l">자금유형</span>
            <Select
              native
              value={payAmountType}
              onChange={handlePayAmountTypeListChange}
              inputProps={{
                "data-testid": "paymentUpdateList-select-payAmountType",
              }}>
              {searchPayAmountTypeList.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
            <span className="label-l">지급액</span>
            <Input
              className="w110"
              value={payAmount || ""}
              inputProps={{
                "data-testid": "paymentUpdateList-input-payAmount",
              }}
              onChange={handlePayAmountChange}
            />
            <span className="label-r">원</span>
            <span className="label-l">적용시점</span>
            <Select
              native
              value={expectedApplyY4mm}
              onChange={handleExpectedApplyY4mmChange}
              inputProps={{
                "data-testid": "paymentUpdateList-select-expectedApplyY4mm",
              }}>
              {expectedApplyY4mmList.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
            <button className="btn-l" onClick={() => fnSubmit(capitalUniqueKey, payAmount, payAmountType, paySpecifiedDay, expectedApplyY4mm)} data-testid="update-btn">
              선택정보 일괄변경
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentUpdateList;
