import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import CashbillTabTargetsCreateIssueTarget from "./CashbillTabTargetsCreateIssueTarget";
import CashbillTabTargetsList from "./CashbillTabTargetsList";
import CashbillTabTargetsModalCancellations from "./CashbillTabTargetsModalCancellations";
import CashbillTabTargetsModalCandidates from "./CashbillTabTargetsModalCandidates";
import CashbillTabTargetsModalModifications from "./CashbillTabTargetsModalModifications";
import CashbillTabTargetsSummary from "./CashbillTabTargetsSummary";

// TODO:
// 자동발행은 소득공제용만 할 수 있음.
// 자동발행은 지출증빙용은 발행하면 안됨.
// 직접발행은 소득공제용, 지출증빙용 다 발행할 수 있음.

const defaultHooks = {
  searchCondition: {
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "customerName",
    customerName: "",
    cashbillIdentificationNo : "",
    payerNo: "",
  },
  summary: {
    transactionDate: "",
    uniqueKey: null,
    askTargetStartDate: "",
    askTargetEndDate: "",
    totalAskingAmount: 0,
    numberOfAskedRecords: 0,
  },
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
  modalOpen: {
    candidates: false, // 발행대상추가
    cancellations: false, // 발행취소대상 추가
    modifications: false, // 발행대상수정
  },
};

// 현금영수증 직접발행ㆍ취소 메인 탭
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabTargets = (props) => {
  // hooks
  const [hiddenTargets, setHiddenTargets] = useState(true);
  const [searchCondition, setSearchCondition] = useState(defaultHooks.searchCondition);
  const [summary, setSummary] = useState(defaultHooks.summary);
  const [targets, setTargets] = useState([]);
  const [searchStart, setSearchStart] = useState(false); // 목록 조회 시작
  const [pagination, setPagination] = useState(defaultHooks.pagination);
  const [modalOpen, setModalOpen] = useState(defaultHooks.modalOpen);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [searchProperty, setSearchProperty] = useState("customerName");
  const [searchContents, setSearchContents] = useState("");

  // handler
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));
  const handleModalOpen = (key, value) => setModalOpen((data) => ({ ...data, [key]: value }));

  const excelInput = React.useRef("");

  // handlePage
  const handlePage = (page) => {
    setSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchStart(true);
  };

  const handleRowPerPage = ({ target: { value } }) => {
    setSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchStart(true);
  };

  // useCallback
  // 요약정보 가져오기
  const getSummary = useCallback(() => {
    return new Promise((resolve) => {
      const url = `api/extraservice/cashbill/`;

      CM.cfnAxios(
        url,
        "get",
        "",
        (status, data) => resolve(data),
        () => resolve({})
      );
    });
  }, []);

  // 수정목록 가져오기
  const getTargets = useCallback((parameter, summary) => {
    return new Promise((resolve) => {
      let url = "api/extraservice/cashbill/targets";
      url += `?fileUniqueKey=${summary.uniqueKey}&pageNumber=${parameter.pageNumber}&pageSize=${parameter.pageSize}`;
      url += `&sortDirection=${parameter.sortDirection}&sortProperty=${parameter.sortProperty}`;
      url += `&customerName=${parameter.customerName}&cashbillIdentificationNo=${parameter.cashbillIdentificationNo}&payerNo=${parameter.payerNo}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 화면 초기화
  const initializePage = useCallback(async () => {
    setPagination(defaultHooks.pagination);
    setSearchCondition(defaultHooks.searchCondition);
    setSearchStart(true);
  }, []);

  // useEffect
  // 화면 첫 render시 동작
  useEffect(() => {
    if (props.tabIndex === 0) initializePage();
  }, [props.tabIndex, initializePage]);

  // 요약 정보 조회 후 목록 조회
  useEffect(() => {
    const startAxios = async (parameter) => {
      setSearchStart(false);
      // 01. 요약정보 조회
      const resultSummary = await getSummary();

      // 02. 목록 조회
      if (resultSummary.uniqueKey) {
        setSummary(resultSummary);
        setHiddenTargets(false);
        const resultTargets = await getTargets(parameter, resultSummary);
        setTargets(resultTargets.content);

        handlePagination(resultTargets);
      } else {
        setSummary(defaultHooks.summary);
        setHiddenTargets(true);
      }
    };

    if (searchStart) {
      setSearchCondition({ ...searchCondition, customerName: searchProperty === "customerName" ? searchContents : "", cashbillIdentificationNo :  searchProperty === "cashbillIdentificationNo" ? searchContents : "" , payerNo: searchProperty === "payerNo" ? searchContents : "" });
      startAxios({ ...searchCondition, customerName: searchProperty === "customerName" ? searchContents : "", cashbillIdentificationNo :  searchProperty === "cashbillIdentificationNo" ? searchContents : "", payerNo: searchProperty === "payerNo" ? searchContents : "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStart, searchCondition, getSummary, getTargets]);

  useEffect(() => {
    if (summary.uniqueKey) {
      // 다음날 12시 20분까지 전송 가능함
      let transactionDate = CM.cfnConvertStringToDate(summary.transactionDate + "122000");
      transactionDate.setDate(transactionDate.getDate() + 1);
      setIsOverLimit(transactionDate.getTime() < new Date().getTime());
    }
  }, [summary]);
  // 발행대상 삭제
  const deleteTargets = () => {
    CM.cfnConfirm("정말 생성한 발행대상을 삭제하시겠습니까? 삭제 후에는 임시로 수정/추가한 내역이 모두 사라집니다.", () => {
      const url = `api/extraservice/cashbill/targets/${summary.uniqueKey}`;
      CM.cfnAxios(url, "delete", "", (status, data) => setSearchStart(true));
    });
  };

  // 영수증 발행 요청
  const requestReceiptIssue = () => {
    // 현금영수증 발행범위 설정 (사업자고객/매입세액공제용 포함여부)
    if (targets.length === 0 || !summary.uniqueKey) {
      CM.cfnAlert("발행을 요청할 영수증이 없습니다.", () => {});
      return false;
    }

    CM.cfnConfirm("현금영수증을 발행요청하시겠습니까?", () => {
      const url = `api/extraservice/cashbill/files/${summary.uniqueKey}`;

      CM.cfnAxios(url, "post", "", (status, data) => {
        CM.cfnAlert(data || "정상적으로 처리되었습니다.", () => {
          setHiddenTargets(true);
          props.setTabIndex(1);
        });
      });
    });
  };

  // 엑셀 저장
  const downloadExcel = () => {
    let url = "api/extraservice/cashbill/targets/excel";
    url += `?customerName=${searchCondition.customerName}`;
    url += `&cashbillIdentificationNo=${searchCondition.cashbillIdentificationNo}`
    url += `&payerNo=${searchCondition.payerNo}`;
    url += `&fileUniqueKey=${summary.uniqueKey}&sortProperty=${searchCondition.sortProperty}&sortDirection=${searchCondition.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", undefined);
  };

  /*
   * @desc    일괄수정 버튼 클릭 이벤트 핸들러
   */
  const handleClickBulkModify = () => {
    excelInput.current.click();
  };

  /*
   * @desc    일괄수정 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc   일괄수정 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/extraservice/cashbill/targets/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey",
      new Blob([JSON.stringify(summary.uniqueKey)], {
        type: "application/json",
      })
    );
    CM.cfnAxios(
      url,
      "post",
      form,
      (status, data) => {
        CM.cfnAlert(data || "정상적으로 처리되었습니다.", () => {
          setSearchStart(true);
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      }
    );
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      setSearchStart(true);
    }
  };

  return (
    <div>
      <div hidden={!hiddenTargets}>
        <CashbillTabTargetsCreateIssueTarget tabIndex={props.tabIndex} handleSearchStart={setSearchStart} />
      </div>
      <div hidden={hiddenTargets}>
        <div className="inforbox">
          <ul>
            <li> 현금영수증 발행내역을 국세청에 전송하려면 신청일 다음날 오후 12시 20분까지 반드시 "영수증 발행요청"을 클릭하시기 바랍니다.</li>
            <li> 전송 완료된 현금영수증 발행요청내역은 신청일로부터 3일 뒤 오후 6시 이후 "현금영수증 발행내역" 탭에서 결과 확인이 가능합니다.</li>
            <li> 이미 전송이 완료되어 정상처리된 현금영수증 발행 내역을 취소하려면 "취소대상 추가"를 통해 발행된 내역을 취소할 수 있습니다.</li>
            <li>
              금융결제원 자동생성 방식을 사용해도 직접발행을 통하여 이미 전송된 내역을 취소하거나 추가 발행할 수 있습니다. <br />
              단, 자동생성으로 국세청에 전송되어 정상처리된 내역은 직접발행 대상에 포함되지 않습니다.
            </li>
          </ul>
        </div>
        <div className="summary-main-area">
          <CashbillTabTargetsSummary list={summary} />
          <div className="summary-button-area">
            <button className="btn-m summary-button" data-testid="" onClick={deleteTargets}>
              발행대상 삭제
            </button>
            {isOverLimit ? (
              <CF.ButtonTooltipForm contents="요청 가능한 시간이 경과하여 발행요청이 불가능합니다. 발행대상을 삭제 후 다시 생성해주세요." buttonContents="영수증 발행요청" disabled={true} />
            ) : (
              <button className="btn-l summary-button" onClick={requestReceiptIssue} data-testid="request-receipt-issue-button">
                영수증 발행요청
              </button>
            )}
          </div>
        </div>
        <div className="search-area">
          <div className="block">
            <span className="label-l">검색어입력</span>
            <CF.SelectForm
              value={searchProperty}
              handleChange={(e) => {setSearchProperty(e.target.value); setSearchContents(""); }}
              name="term"
              arrayOption={[
                { value: "customerName", label: "고객명" },
                { value: "cashbillIdentificationNo", label: "신분확인번호" },
                { value: "payerNo", label: "납부자번호" },
              ]}
              optionValue="value"
              optionLabel="label"
            />
            <TextField className="w150" value={searchContents} onChange={(e) => setSearchContents(e.target.value)} onKeyUp={handleSearchKeyUp} name="keyword" />
            <button className="search-button" onClick={(e) => setSearchStart(true)}>
              검색
            </button>
          </div>
        </div>
        <div className="table-top-area">
          <CF.TotalCountForm totalElements={pagination.total} />
          <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} customProps={{ inputProps: { "data-testid": "cashbillTabTargets-rowPerPage-select" } }} />
          <input
            type="file"
            style={{ display: "none" }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
            onChange={(e) => handleSelectExcelFile(e)}
            ref={excelInput}
            value=""
            data-testid="select-excel-input"
          />
          <button className="btn-m fr" data-testid="save-list-excel" onClick={downloadExcel}>
            [일괄수정양식]엑셀저장
          </button>
          <button className="btn-m fr" data-testid="batch-modified-excel" onClick={handleClickBulkModify}>
            [일괄수정]엑셀파일 등록
          </button>
          <button className="btn-m fr" data-testid="target-amount-and-fixes" onClick={() => handleModalOpen("modifications", true)}>
            금액 수정
          </button>
          <button className="btn-m fr" onClick={() => handleModalOpen("candidates", true)} data-testid="open-candidates-modal">
            발행대상 추가
          </button>
          <button className="btn-m fr" onClick={() => handleModalOpen("cancellations", true)} data-testid="open-cancellations-modal">
            취소대상 추가
          </button>
        </div>
        <div>
          <CashbillTabTargetsList
            list={targets}
            pagination={pagination}
            handleSearchStart={setSearchStart}
            handlePage={handlePage}
            summary={summary}
            searchCondition={searchCondition}
            handleSearchCondition={setSearchCondition}
          />
        </div>
      </div>
      <div aria-describedby="modal-components">
        <CashbillTabTargetsModalCandidates summary={summary} modalOpen={modalOpen} handleModalOpen={handleModalOpen} handleSearchStart={setSearchStart} />
        <CashbillTabTargetsModalCancellations modalOpen={modalOpen} handleModalOpen={handleModalOpen} summary={summary} handleSearchStart={setSearchStart} />
        <CashbillTabTargetsModalModifications modalOpen={modalOpen} handleModalOpen={handleModalOpen} summary={summary} handleSearchStart={setSearchStart} />
      </div>
    </div>
  );
};

export default CashbillTabTargets;
