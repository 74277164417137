import React from "react";
import { cfnAlert, storage } from "./Common";

// 메인 페이지
const LoginRedirect = () => {
  React.useLayoutEffect(() => {
    if (storage.get("expired")) {
      cfnAlert("장시간(30분) 미사용으로 인하여 로그아웃되었습니다. 다시 로그인 후 이용해주세요.", () => {
        storage.remove("expired");
        document.location.href = "/login/";
      });
    } else {
      document.location.href = "/login/";
    }
  }, []);

  return <div></div>;
};

export default LoginRedirect;
