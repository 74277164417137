import React, { useState, Fragment, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import logo from "../../styles/images/logo.png";
import * as CM from "./Common";
import { useEffect } from "react";
const mainMenuJson = {
  home: {
    name: "",
    list: {
      home: {
        name: "",
      },
    },
  },
  login: {
    name: "",
    list: {
      login: {
        name: "",
      },
    },
  },
  institute: {
    name: "기관·업무 정보",
    list: {
      institute: {
        name: "기관 정보",
      },
      business: {
        name: "업무 정보",
      },
      staff: {
        name: "담당자 정보",
      },
      customerCriteria: {
        name: "고객관리 기준",
      },
      extraService: {
        name: "부가서비스 정보 관리",
      },
      changeHistory: {
        name: "변경 요청 이력",
      },
      delayHistory: {
        name: "연체료 적용 이력",
      },
      charge: {
        name: "이용요금",
      },
    },
  },
  customer: {
    name: "고객정보관리",
    list: {
      customerInformationList: {
        name: "고객 기본정보",
      },
      receiptCustomerInformationList: {
        name: "수납 고객정보",
      },
      paymentInformation: {
        name: "지급 고객정보",
      },
      registerAccountByInstitute: {
        name: "직접 접수분",
      },
      registrations: {
        name: "금융기관 접수분",
      },
      accountCheckRegister: {
        name: "실시간 서비스",
      },
    },
  },
  receipt: {
    name: "수납관리",
    list: {
      cmsReceipt: {
        name: "CMS 출금관리",
      },
      receiptSource: {
        name: "수납 내역",
      },
      unpaid: {
        name: "미납관리",
      },
      statistics_statisticsChart: {
        name: "수납 통계",
      },
    },
  },
  payment: {
    name: "지급관리",
    list: {
      cmsPayment: {
        name: "CMS 입금관리",
      },
      paymentSource: {
        name: "지급 내역",
      },
      statistics_statisticsChart: {
        name: "지급 통계",
      },
    },
  },
  extraService: {
    name: "부가서비스",
    list: {
      dataBackup: {
        name: "데이터 백업",
      },
      Cashbill: {
        name: "현금영수증",
      },
      electronicTaxbills: {
        name: "전자세금계산서",
      },
      sendSms: {
        name: "SMS 발송",
      },
      donationBill: {
        name: "기부금영수증",
      },
      paperBill: {
        name: "종이영수증",
      },
      paperTaxBill: {
        name: "종이세금계산서",
      },
      labelPrint: {
        name: "우편물 라벨지 인쇄",
      },
      arsHistory: {
        name: "ARS 서비스 이용내역",
      },
      postCheck: {
        name: "동의자료 사후점검",
      },
    },
  },
  support: {
    name: "서비스지원",
    list: {
      notice: {
        name: "공지사항",
      },
      faq: {
        name: "FAQ",
      },
      pds: {
        name: "자료실",
      },
    },
  },
};

const detailMenuJson = {
  customerCreate: {
    name: "고객 기본정보 등록",
    parent: "customerInformationList",
  },
  customerUpdate: {
    name: "고객 기본정보 조회",
    parent: "customerInformationList",
  },
  receiptCustomerCreate: {
    name: "수납정보 등록",
    parent: "receiptCustomerInformationList",
  },
  receiptCustomerUpdate: {
    name: "수납 고객정보 조회",
    parent: "receiptCustomerInformationList",
  },
  paymentCreate: {
    name: "지급정보 등록",
    parent: "paymentInformation",
  },
  paymentUpdate: {
    name: "지급 고객정보 조회",
    parent: "paymentInformation",
  },
  registrationDetail: {
    name: "금융기관 접수분 상세 내역",
    parent: "registrations",
  },
  receiptSourceDetail: {
    name: "수납 상세 내역",
    parent: "receiptSource",
  },
  paymentSourceDetail: {
    name: "지급 상세 내역",
    parent: "paymentSource",
  },
};

const exceptHistoryUrl = ["information", "common", "about"];

// Main component for handling render
const Header = ({ props }) => {
  const history = useHistory();
  const location = useLocation();
  const fullPathName = location.pathname;
  const pathNameSplit = fullPathName.split("/");
  const [useCashbillYn, setUseCashbillYn] = useState(false);
  const [useElectronicTaxbillYn, setUseElectronicTaxbillYn] = useState(false);

  useEffect(() => {
    const startAxios = async () => {
      const resultInstituteService = await props.instituteStore.axiosInstituteService();
      for (const service of resultInstituteService) {
        if (service.serviceType === "TRUSBILL") {
          setUseElectronicTaxbillYn(service.serviceUseYn);
        }
      }

      const resultCashbillInfo = await props.instituteStore.axiosCashbillInfo();
      setUseCashbillYn(resultCashbillInfo.serviceUseYn);
    };

    if (props.isLogin) {
      startAxios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLogin]);

  // top menu class
  const fnGetMenu = () => {
    const topMenu = ["information", "institute", "customer", "receipt", "payment", "extraService", "support"];
    const menuObject = {};

    for (const menu of topMenu) {
      menuObject[menu] = {
        key: menu,
        li: "li",
        line: "sel-line",
        ul: "ul",
      };
    }

    return menuObject;
  };

  const getMenuStyle = useCallback(() => {
    const menuObject = fnGetMenu();
    const mainPath = pathNameSplit[1];

    menuObject[mainPath] = {
      key: mainPath,
      li: "li select-li",
      line: "current-line",
      ul: "ul",
    };

    return menuObject;
  }, [pathNameSplit]);

  const [menuState, setMenuState] = useState(getMenuStyle());

  // const sideTitle = useSideTitle(pathNameSplit);
  // const mainTitle = useMainTitle(fullPathName);
  const [mainMenu, secondMenu, thirdMenu, fourthMenu] = fullPathName.split("/");
  // ex)http://localhost:28888/receipt/statistics/statisticsChart
  // secondMenu : receipt
  // thirdMenu : statistics
  // fourthMenu : statisticsChart

  let detailTitle = "";
  let subDetailTitle = "";
  let sideTitle = "";

  if (fullPathName.length > 1) {
    // 1단계 메뉴가 있다면
    if (exceptHistoryUrl.indexOf(secondMenu) === -1) {
      // 2단계 메뉴값은 4번째값(fourthMenu)이 있다면 "3번째_4번째", 아니면 "3번째"
      const detailMenu = typeof fourthMenu === "undefined" ? thirdMenu : thirdMenu + "_" + fourthMenu;
      if (detailMenuJson.hasOwnProperty(thirdMenu)) {
        //3번째값(thirdMenu)이 detailMenuJson에 있는 메뉴라면 detaulMenuJson맵의 값을 사용
        detailTitle = mainMenuJson[mainMenu + secondMenu]["list"][detailMenuJson[thirdMenu]["parent"]]["name"];
        subDetailTitle = detailMenuJson[thirdMenu]["name"];
      } else {
        //3번째값(thirdMenu)이 detailMenuJson에 없다면 MenuJson맵의 값을 사용
        detailTitle =
          Object.keys(mainMenuJson[mainMenu + secondMenu]["list"]).length === 0
            ? ""
            : Object.isExtensible(mainMenuJson[mainMenu + secondMenu]["list"][detailMenu])
            ? mainMenuJson[mainMenu + secondMenu]["list"][detailMenu]["name"]
            : "";
      }
      sideTitle = typeof secondMenu === "undefined" || secondMenu === "" ? "" : mainMenuJson[mainMenu + secondMenu]["name"];
    }
  }

  const mainTitle = detailTitle;
  const subTitle = subDetailTitle;

  // Change top menu state
  const handleClick = async (event, path) => {
    const name = event.currentTarget.getAttribute("name");

    // Do not deactivate the menu when you click on the same menu
    for (const value of Object.values(menuState)) {
      if (value.li === "current-li" && value.key === name) {
        return false;
      }
    }

    const cloneState = fnGetMenu();

    cloneState[name] = {
      key: name,
      li: "li select-li",
      line: "current-line",
      ul: "ul",
    };

    setMenuState(cloneState);

    const topMenuName = event.target.getAttribute("name");

    if (CM.cfnIsNotEmpty(topMenuName)) {
      history.push(path);
    }
  };

  return (
    <div className="main">
      <div className="header" id="gnb">
        <div className="fl logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="fl menu-text ul">
          <TopMenu
            isLogin={props.isLogin}
            loginStore={props.loginStore}
            menuState={menuState}
            handleClick={handleClick}
            useCashbillYn={useCashbillYn}
            useElectronicTaxbillYn={useElectronicTaxbillYn}
          />
        </div>
        <div className="fr top-area">
          <div className="top-user ellipsis" title={Object.keys(props.loginStore.loginDto).length > 0 ? props.loginStore.loginDto.instName + "(" + props.loginStore.loginDto.intgtLoginId + ")" : ""}>
            {Object.keys(props.loginStore.loginDto).length > 0 ? props.loginStore.loginDto.instName + "(" + props.loginStore.loginDto.intgtLoginId + ")" : ""}
          </div>
          <div className="top-logout">
            <LoginButton isLogin={props.isLogin} loginStore={props.loginStore} />
            <button className="btntop" onClick={(event) => window.print()}>
              화면인쇄
            </button>
          </div>
        </div>
      </div>
      {props.main === "root" ? (
        <div className="bigtitle">
          <div className="fl select_menu">{sideTitle}</div>
          <div className="title_main">{subTitle !== "" ? subTitle : mainTitle}</div>
          <div className="location">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link className="lotext" color="inherit" to="/">
                메인
              </Link>
              <span className="lotext" color="inherit">
                {sideTitle}
              </span>
              {subTitle !== "" ? (
                <span className="lotext" color="textPrimary">
                  {mainTitle}
                </span>
              ) : (
                <span className="lotext" color="inherit">
                  {mainTitle}
                </span>
              )}
              {subTitle !== "" ? (
                <span className="lotext" color="inherit">
                  {subTitle}
                </span>
              ) : (
                ""
              )}
            </Breadcrumbs>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

// Top menu component
const TopMenu = (props) => {
  return (
    <ul>
      {!props.isLogin ? (
        <li
          className={props.menuState.information.li + " infoLi"}
          name={props.menuState.information.key}
          onClick={(e) => props.handleClick(e, "/information/information")}
          tabIndex="0"
          onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/information/information") : "")}>
          {"빌링원플러스 이용안내"}
          <div className={props.menuState.institute.line} />
        </li>
      ) : (
        <li
          className={props.menuState.institute.li}
          name={props.menuState.institute.key}
          onClick={(e) => props.handleClick(e, "/institute/institute")}
          tabIndex="0"
          onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/institute/institute") : "")}>
          기관·업무 정보
          <div className={props.menuState.institute.line} />
          <div className={props.menuState.institute.ul} data-testid="header">
            <Link className="li" to="/institute/institute">
              기본 정보
            </Link>
            <Link className="li" to="/institute/customerCriteria">
              고객관리 기준
            </Link>
            <Link className="li" to="/institute/extraService">
              부가서비스 정보
            </Link>
            <Link className="li" to="/institute/changeHistory">
              정보 변경 이력
            </Link>
            <Link className="li" to="/institute/charge">
              이용요금
            </Link>
          </div>
        </li>
      )}
      <li
        className={props.menuState.customer.li}
        name={props.menuState.customer.key}
        onClick={(e) => props.handleClick(e, "/customer/customerInformationList")}
        onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/customer/customerInformationList") : "")}
        tabIndex="0">
        고객정보관리
        <div className={props.menuState.customer.line} />
        <div className={props.menuState.customer.ul}>
          <Link className="li" to="/customer/customerInformationList">
            고객 기본정보
          </Link>
          <Link className="li" to="/customer/receiptCustomerInformationList">
            수납 고객정보
          </Link>
          <Link className="li" to="/customer/paymentInformation">
            지급 고객정보
          </Link>
          <Link className="li" to="/customer/registerAccountByInstitute">
            출금 계좌 등록&middot;해지
          </Link>
        </div>
      </li>
      <li
        className={props.menuState.receipt.li}
        name={props.menuState.receipt.key}
        onClick={(e) => props.handleClick(e, "/receipt/cmsReceipt")}
        tabIndex="0"
        onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/receipt/cmsReceipt") : "")}>
        수납관리
        <div className={props.menuState.receipt.line} />
        <div className={props.menuState.receipt.ul}>
          <Link className="li" to="/receipt/cmsReceipt">
            CMS 출금관리
          </Link>
          <Link className="li" to="/receipt/receiptSource">
            수납 내역
          </Link>
          <Link className="li" to="/receipt/unpaid">
            미납관리
          </Link>
          <Link className="li" to="/receipt/statistics/statisticsChart">
            수납 통계
          </Link>
        </div>
      </li>
      <li
        className={props.menuState.payment.li}
        name={props.menuState.payment.key}
        onClick={(e) => props.handleClick(e, "/payment/cmsPayment")}
        tabIndex="0"
        onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/payment/cmsPayment") : "")}>
        지급관리
        <div className={props.menuState.payment.line} />
        <div className={props.menuState.payment.ul}>
          <Link className="li" to="/payment/cmsPayment">
            CMS 입금관리
          </Link>
          <Link className="li" to="/payment/paymentSource">
            지급 내역
          </Link>
          <Link className="li" to="/payment/statistics/statisticsChart">
            지급 통계
          </Link>
        </div>
      </li>
      <li
        className={props.menuState.extraService.li}
        name={props.menuState.extraService.key}
        onClick={(e) => props.handleClick(e, "/extraService/dataBackup")}
        tabIndex="0"
        onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/extraService/dataBackup") : "")}>
        부가서비스
        <div className={props.menuState.extraService.line} />
        <div className={props.menuState.extraService.ul}>
          <Link className="li" to="/extraService/dataBackup">
            데이터 백업
          </Link>
          <Link className="li" to={props.useCashbillYn ? "/extraService/Cashbill" : "/extraService/donationBill"}>
            영수증 발행
          </Link>
          <Link className="li" to={props.useElectronicTaxbillYn ? "/extraService/electronicTaxbills" : "/extraService/paperTaxBill"}>
            계산서 발행
          </Link>
          <Link className="li" to="/extraService/sendSms">
            SMS 발송
          </Link>
          <Link className="li" to="/extraService/labelPrint">
            우편물 라벨지 인쇄
          </Link>
          <Link className="li" to="/extraService/arsHistory">
            ARS 서비스 이용내역
          </Link>
          {props.loginStore.getEvidence() === "일부제출" ? (
            <Link className="li" to="/extraService/postCheck">
              동의자료 사후점검
            </Link>
          ) : (
            ""
          )}
        </div>
      </li>
      <li
        className={props.menuState.support.li}
        name={props.menuState.support.key}
        onClick={(e) => props.handleClick(e, "/support/notice")}
        tabIndex="0"
        onKeyDown={(e) => (e.keyCode === 13 ? props.handleClick(e, "/support/notice") : "")}>
        서비스지원
        <div className={props.menuState.support.line} />
        <div className={props.menuState.support.ul}>
          <Link className="li" to="/support/notice">
            공지사항
          </Link>
          <Link className="li" to="/support/pds">
            자료실
          </Link>
          <Link className="li" to="/support/faq">
            FAQ
          </Link>
        </div>
      </li>
    </ul>
  );
};

/*
 * @desc  로그인 / 로그아웃 버튼
 */
const LoginButton = (props) => {
  if (props.isLogin === true) {
    return (
      <Link to="/home">
        <button className="btntop btnlogintop" onClick={() => props.loginStore.getLogout()} data-testid="logout-button">
          로그아웃
        </button>
      </Link>
    );
  }

  return (
    <Fragment>
      {process.env.NODE_ENV === "production" ? (
        ""
      ) : (
        <Link to="/common/login">
          <button className="btntop btnlogintop" data-testid="login-button" style={{ padding: "0 5px" }}>
            간편
          </button>
        </Link>
      )}
      <a href="/login/">
        <button className="btntop btnlogintop" data-testid="login-button">
          로그인
        </button>
      </a>
    </Fragment>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  loginStore: rootStore.loginStore,
  instituteStore: rootStore.instituteStore,
}))(observer(Header));
