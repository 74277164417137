import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import Modal from "@material-ui/core/Modal";
import { Table, TableBody, TableRow, TableCell, Button, Input, Select } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import * as CM from "../../common/Common";
import {BankAutoComplete, MaskedField} from "../../template/ComponentForm";
import * as CF from "../../template/ComponentForm";
import CustomerData from "../customerBasicInformation/CustomerData";

// TODO:
// 자동납부 동의자료 hidden or show
// 면제, 한시적면제: hidden
// 일부제출: 파일첨부는 안보임, select만 (evidenFileType) 보임
// 제출: 둘 다 보임

/*
 * @desc  전체 disable 처리를 하는 css
 */
const useStyles = makeStyles((theme) => ({
  disabled_div: {
    "pointer-events": "none",
    opacity: 0.6,
  },
  hidden: {
    display: "none",
    visibility: "hidden",
  },
}));

const defaultCustomerInfo = {
  accountNo: "",
  depositorName: "",
  depositorIdentificationNo: "",
  evidenceFileType: "",
};

/*
 * @desc  고객 조회 화면
 */
const FixingsUpdate = ({ props }) => {
  const { instituteStore } = props;
  const style = useStyles();

  // hooks
  const [customerInfo, setCustomerInfo] = useState(defaultCustomerInfo); // 고객 정보
  const [fncIns, setFncIns] = useState(""); // 금융기관
  const [file, setFile] = useState({ file: null, fileName: "", fileSrc: "#" }); // 파일
  const [fileName, setFileName] = useState(""); // 파일명

  // 고객 정보 handler
  const handleCustomerInfoChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setCustomerInfo((data) => ({ ...data, [key]: value }));
  };

  // 금융기관 목록 가져오기
  useEffect(() => {
    const startAxios = async () => await instituteStore.axiosFinancialRepresentativeInstitutes();
    startAxios();
  }, [instituteStore]);

  // 고객 정보 hooks 값 설정
  // useEffect(() => {
  //   const propsData = { ...props.target };
  //   setCustomerInfo(propsData);
  //   setFncIns({
  //     fncInsNm: propsData.financialInstituteName || "",
  //     financialInstituteCode: propsData.financialInstituteCode
  //   });
  //   setFile({ file: null, fileName: "", fileSrc: "#" });
  // }, [props.modalOpen, props.target]);

  useEffect(() => {
    const axiosResult = (accountErrorUniqueKey) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/institute/fixing/${accountErrorUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      // 보완대상 조회
      const result = await axiosResult(search);

      await setCustomerInfo(result);
      await getFileName(result);
      await setFncIns({
        fncInsNm: result.financialInstituteName || "",
        financialInstituteCode: result.financialInstituteCode,
      });
      await setFile({ file: null, fileName: "", fileSrc: "#" });
    }

    const accountErrorUniqueKey = props.target.accountErrorUniqueKey;
    if (props.modalOpen) {
      startAxios(accountErrorUniqueKey);
    } else {
      setCustomerInfo(defaultCustomerInfo);
      setFncIns({
        fncInsNm: "",
        financialInstituteCode: "",
      });
      setFile({ file: null, fileName: "", fileSrc: "#" });
    }
  }, [props.modalOpen, props.target.accountErrorUniqueKey]);

  // 필수 값 체크
  const checkRequired = () => {
    if (CM.cfnIsEmpty(fncIns.fncInsNm)) {
      CM.cfnAlert("금융기관을 입력해주세요.");
      return false;
    }

    if (CM.cfnIsEmpty(fncIns.financialInstituteCode)) {
      CM.cfnAlert("존재하지않는 금융기관입니다.");
      return false;
    }

    if (CM.cfnIsEmpty(customerInfo.accountNo)) {
      CM.cfnAlert("계좌번호를 입력해주세요.");
      return false;
    }

    if (CM.cfnIsEmpty(customerInfo.depositorName)) {
      CM.cfnAlert("예금주를 입력해주세요.");
      return false;
    }

    if (CM.cfnIsEmpty(customerInfo.depositorIdentificationNo)) {
      CM.cfnAlert("예금주생년월일(사업자번호)를 입력해주세요.");
      return false;
    }

    if (props.evidence === "제출" && CM.cfnIsEmpty(file.file) && CM.cfnIsEmpty(fileName)) {
      CM.cfnAlert("자동납부동의자료를 첨부해주세요.");
      return false;
    }

    if ((props.evidence === "제출" || props.evidence === "일부제출") && CM.cfnIsEmpty(customerInfo.evidenceFileType)) {
      CM.cfnAlert("동의자료 구분을 선택해주세요.");
      return false;
    }

    return true;
  };

  // 저장 처리
  const saveFixings = (e) => {
    e.preventDefault();

    if (!checkRequired()) {
      return false;
    }

    const formData = new FormData();
    let fixingUpdateDto = {
      accountErrorUniqueKey: customerInfo.accountErrorUniqueKey, // Error Unique Key
      accountBankCode: fncIns.financialInstituteCode, // 금융기관코드
      accountNo: customerInfo.accountNo, // 계좌번호
      depositorName: customerInfo.depositorName, // 예금주명
      depositorIdentification: CM.cfnReplaceSymbol(customerInfo.depositorIdentificationNo), // 예금주 식별번호
      evidenceFileType: customerInfo.evidenceFileType, // 동의자료구분
    };

    if (file.file && file.fileName && file.fileName !== "") {
      formData.append("evidenceFile", file.file, file.fileName);
    } else {
      formData.append("evidenceFile", file.file);
    }
    formData.append("fixingUpdateDto", new Blob([JSON.stringify(fixingUpdateDto)], { type: "application/json" }));

    const requestUrl = "api/customer/receipt/account/institute/fixing";

    const axiosCallback = (status, data) => {
      CM.cfnAlert("보완처리가 완료되었습니다.", () => {
        props.handleSearchOnChange(true);
        props.handleModalOpenChange(false);
      });
    };

    CM.cfnAxios(requestUrl, "post", formData, axiosCallback, "", true);
  };

  const getFileName = (data) => {
    var prntFileName = "";
    if (CM.cfnIsNotEmpty(data.evidenceFile)) {
      prntFileName = data.payerNo + "." + data.evidenceFile.substring(data.evidenceFile.lastIndexOf(".") + 1, data.evidenceFile.length);
    }
    setFileName(prntFileName);
  };

  // TODO: 화면 렌더
  return (
    <Modal open={props.modalOpen}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpenChange(false)} data-testid="registerAccountByInstitute-fixingsUpdate-close-modal">
              {""}
            </Button>
          </div>
          <h3>수납계좌정보 수정</h3>
          <h4>오류내용</h4>
          <div className="summary-area w100p ">
            {CustomerData.getDataName.accountFixingReason(customerInfo.accountFixingReason)}
            {CM.cfnIsNotEmpty(customerInfo.fileResultCodeMessage) ? " (상세내역 : " + customerInfo.fileResultCodeMessage + ")" : ""}
          </div>
          <h4>이체할 고객 계좌 정보</h4>
          <div name="customer-update-info">
            <Table>
              {CM.cfnCompColGroup(["130px", "auto", "130px", "auto"])}
              <TableBody>
                <TableRow>
                  <TableCell className="th">고객명</TableCell>
                  <TableCell className="td" colSpan="3">
                    {customerInfo.customerName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="th">금융기관</TableCell>
                  <TableCell className="td">
                    <BankAutoComplete
                      list={toJS(instituteStore.financialRepresentativeInstitutes)}
                      state={fncIns}
                      setState={setFncIns}
                      className={"w180"}
                      customProps={{ "data-testid": "registerAccountByInstitute-fixingsUpdate-bankAutoComplete" }}
                    />
                  </TableCell>
                  <TableCell className="th">계좌번호</TableCell>
                  <TableCell className="td">
                    <Input
                      name={"accountNo"}
                      value={customerInfo.accountNo}
                      onChange={handleCustomerInfoChange}
                      inputProps={{ "data-testid": "registerAccountByInstitute-fixingsUpdate-input-accountNo" }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="th">예금주</TableCell>
                  <TableCell className="td">
                    <Input
                      name={"depositorName"}
                      value={customerInfo.depositorName}
                      onChange={handleCustomerInfoChange}
                      inputProps={{ "data-testid": "registerAccountByInstitute-fixingsUpdate-input-depositorName" }}
                    />
                  </TableCell>
                  <TableCell className="th" style={{ whiteSpace: "pre-wrap" }}>
                    {"예금주생년월일\n(사업자번호)"}
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      name={"depositorIdentificationNo"}
                      value={customerInfo.depositorIdentificationNo}
                      onChange={handleCustomerInfoChange}
                      inputComponent={MaskedField}
                      inputProps={{
                        mask: "identificationNo",
                        "aria-label": "description",
                        "data-testid": "registerAccountByInstitute-fixingsUpdate-input-depositorIdentificationNoFirst7" }}
                      placeholder="6자리 생년월일 또는 10자리 사업자번호"
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={props.evidence === "면제" ? style.hidden : ""}>
                  <TableCell className={`th ${props.evidence === "일부제출" ? style.hidden : ""}`}>자동납부동의자료</TableCell>
                  <TableCell className={`td ${props.evidence === "일부제출" ? style.hidden : ""}`}>
                    {CM.cfnIsNotEmpty(customerInfo.evidenceFile) ? (
                      <Input value={fileName} readOnly={true} data-testid="uploaded-file-name" />
                    ) : (
                      <CF.FileUploadForm acceptFileType=".jpg, .jpeg, .gif, .tif, .pdf, .der, .mp3, .wav, .wma, .txt, .jb64" needPreview={false} uploadFile={file} setUploadFile={setFile} />
                    )}
                  </TableCell>
                  <TableCell className="th">동의자료구분</TableCell>
                  <TableCell className="td">
                    <Select
                      native
                      className=""
                      name={"evidenceFileType"}
                      value={customerInfo.evidenceFileType || ""}
                      onChange={handleCustomerInfoChange}
                      inputProps={{ "data-testid": "registerAccountByInstitute-fixingsUpdate-select-evidenceFileType" }}>
                      {CM.cfnGetEvidenceFileTypes().map((element, index) => {
                        return (
                          <option value={element.evidenceFileType} key={index}>
                            {element.evidenceFileTypeName}
                          </option>
                        );
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="table-bottom-area">
            <button className="btn-l" onClick={saveFixings} data-testid="registerAccountByInstitute-fixingsUpdate-button-saveFixings">
              오류 수정 후 저장
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  instituteStore: rootStore.instituteStore,
}))(observer(FixingsUpdate));
