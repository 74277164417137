import { Checkbox, Paper, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import Data from "../../common/CommonDataName";
import { PaginationForm, RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import ReceiptCreateModal from "./ReceiptCreateModal";
import { paginationData, searchAccountRegistrationStatusData, searchData, searchOptionData, summaryData } from "./ReceiptInformationData";
import ReceiptUpdateList from "./ReceiptUpdateList";

// 요약 컴포넌트
function SummaryForm(props) {
  return (
    <div className="summary-area">
      {props.summaryList.map((summary, index) => {
        return (
          <div
            //20191104 jest 테스트를 위해 data-testid 속성 추가
            data-testid={`receiptCustomerInformationList-main-summary-info-${index}`}
            className="text-graph show-detail"
            key={index}
            onClick={() => props.handleSummaryClick(summary.label)}>
            <label>{summary.label}</label>
            <span>{CM.cfnAddComma(summary.value)}</span>
          </div>
        );
      })}
    </div>
  );
}
// 검색 컴포넌트
const SearchForm = (props) => {
  const searchOptionList = searchOptionData();
  const searchAccountRegistrationStatus = searchAccountRegistrationStatusData();
  const searchCapitalUniqueKey = props.searchCapitalUniqueKey;
  const searchCustomerGroup = props.searchCustomerGroup;
  function handleSearKeyUp(e) {
    if (e.keyCode === 13) {
      fnSearch();
    }
  }

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchFormChange(
      props.searchOption.capitalUniqueKey,
      props.searchOption.groupUniqueKey,
      props.searchOption.accountRegistrationStatus,
      props.searchOption.terms,
      props.searchOption.search,
      0
    );
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">자금종류</label>
        <Select
          native
          value={props.searchOption.capitalUniqueKey}
          onChange={props.handleSearchOptionChange}
          inputProps={{ "data-testid": "receiptCustomerInformationList-select-capital" }}
          name="capitalUniqueKey">
          <option value={""} key={"none"}>
            전체
          </option>
          {searchCapitalUniqueKey.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객구분</label>

        <Select
          native
          value={props.searchOption.groupUniqueKey}
          onChange={props.handleSearchOptionChange}
          inputProps={{ "data-testid": "receiptCustomerInformationList-select-group" }}
          name="groupUniqueKey">
          <option value={""} key={"none"}>
            전체
          </option>
          {searchCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">계좌/서비스상태</label>
        <Select
          native
          value={props.searchOption.accountRegistrationStatus}
          onChange={props.handleSearchOptionChange}
          inputProps={{ "data-testid": "receiptCustomerInformationList-select-accountRegistrationStatus" }}
          name="accountRegistrationStatus">
          {searchAccountRegistrationStatus.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={props.searchOption.terms} onChange={props.handleSearchOptionChange} inputProps={{ "data-testid": "receiptCustomerInformationList-select-terms" }} name="terms">
          {searchOptionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField
          className="w130"
          value={props.searchOption.search}
          onChange={props.handleSearchOptionChange}
          onKeyUp={handleSearKeyUp}
          inputProps={{ "data-testid": "receiptCustomerInformationList-textfield-search" }}
          name="search"
        />
        <button className="search-button" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
};

// 목록 컴포넌트
function ListForm(props) {
  const { filterReceiptList, setFilterReceiptList } = props;
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of filterReceiptList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const customerLength = filterReceiptList.length;

    if (checkAllFlag === customerLength && customerLength > 0) setCheckAllRow(true);
    else setCheckAllRow(false);
  }, [filterReceiptList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleDeleteChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...filterReceiptList];
    cloneData[index][key] = value;
    setFilterReceiptList(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    setFilterReceiptList((receipt) => receipt.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // TableRow 클릭시 상세 페이지로 이동
  const handleRowClick = (e, receipt) => {
    if (e.target.type === "checkbox") {
      return false;
    }

    props.goUpdateReceiptPage(e, receipt);
  };

  return (
    <div>
      <Paper>
        <Table>
          {CM.cfnCompColGroup(["2%", "9%", "10%", "10%", "8%", "6%", "6%", "5%", "auto", "6%", "5%", "6%", "6%", "6%", "6%"])}
          <TableForm.compServerSortDoubleRowTableHead
            useCheckbox={true}
            checked={checkAllRow}
            value=""
            onChange={handleCheckAllRowChange}
            rowOne={[
              { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
              { id: "PAYER_NO", label: "납부자\n번호", sortable: true, rowSpan: 2 },
              { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true, rowSpan: 2 },
              { id: "CAPITAL_NAME", label: "자금종류", sortable: false, rowSpan: 2 },
              { id: "", label: "수납", sortable: false, colSpan: 3 },
              { id: "PAY_AMOUNT", label: "수납금액", sortable: true, rowSpan: 2 },
              { id: "PAY_PERIOD", label: "수납\n주기", sortable: true, rowSpan: 2 },
              { id: "TRANSACTION_METHOD", label: "수납방법", sortable: false, rowSpan: 2 },
              { id: "REGISTERED_DATE", label: "등록\n완료일", sortable: true, rowSpan: 2 },
              { id: "UNREGISTERED_DATE", label: "해지\n완료일", sortable: true, rowSpan: 2 },
              { id: "", label: "상태", sortable: false, colSpan: 2 },
            ]}
            rowTwo={[
              { id: "PAY_START_Y4MM", label: "시작월", sortable: true },
              { id: "PAY_END_Y4MM", label: "종료월", sortable: true },
              { id: "PAY_SPECIFIED_DAY", label: "지정일", sortable: true },
              { id: "ACCOUNT_REGISTRATION_STATUS", label: "계좌", sortable: true },
              { id: "CONTRACT_STATUS", label: "서비스", sortable: true },
            ]}
            searchRequest={props.searchRequest}
            handleSortProperty={props.handleSortProperty}
            checkboxCustomProps={{ "data-testid": "receiptCustomerInformationList-list-head-checkbox" }}
            tableSortLabelDataTestId={"receiptCustomerInformationList-list-head-sortLabel"}
          />

          <TableBody>
            {filterReceiptList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={15} />
            ) : (
              filterReceiptList.map((receipt, index) => {
                return (
                  <TableRow hover key={index} className="show-detail" onClick={(e) => handleRowClick(e, receipt)} data-testid={`receiptCustomerInformationList-goDetail-${index}`}>
                    <TableCell className="checkbox" align="center">
                      <Checkbox
                        checked={receipt._checked}
                        value="_checked"
                        id={`deleteCheckbox-${index}`}
                        data-testid={`receiptCustomerInformationList-list-checkbox-${index}`}
                        onChange={handleDeleteChange}
                        inputProps={{
                          index: index,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className="show-detail" data-testid={``}>
                      {receipt.customer.customerName}
                    </TableCell>
                    <TableCell align="center">{receipt.payerNo}</TableCell>
                    <TableCell align="center">{CM.cfnIdentificationNoFormat(receipt.customer.identificationNo)}</TableCell>
                    <TableCell align="center">{Object.isExtensible(receipt.capital) ? receipt.capital.capitalName : ""}</TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(receipt.payStartY4mm, "yyMM")}</TableCell>
                    <TableCell align="center">{receipt.payEndY4mm !== "999912" ? CM.cfnDateFormat(receipt.payEndY4mm, "yyMM") : "기한없음"}</TableCell>
                    <TableCell align="center">{CM.cfnDayFormat(receipt.paySpecifiedDay)}</TableCell>
                    <TableCell align="right">{receipt.payAmountType === "VARIABLE" ? "비정액" : CM.cfnAddComma(receipt.payAmount)}</TableCell>
                    <TableCell align="center">{Data.payPeriod(receipt.payPeriod)}</TableCell>
                    <TableCell align="center">{Data.transactionMethod(receipt.transactionMethod)}</TableCell>
                    <TableCell align="center">
                      {Object.isExtensible(receipt.account)
                        ? CM.cfnDateFormat(receipt.account.registeredDate && receipt.account.registeredDate.length > 6 ? receipt.account.registeredDate.substring(2) : receipt.account.registeredDate)
                        : ""}
                      {receipt.transactionMethod === "ETC" ? CM.cfnDateFormat(receipt.createdDate && receipt.createdDate.length > 6 ? receipt.createdDate.substring(2) : receipt.createdDate) : ""}
                    </TableCell>
                    <TableCell align="center">
                      {Object.isExtensible(receipt.account) && receipt.account.accountRegistrationStatus === "UNREGISTERED"
                        ? CM.cfnDateFormat(
                            receipt.account.unregisteredDate && receipt.account.unregisteredDate.length > 6 ? receipt.account.unregisteredDate.substring(2) : receipt.account.unregisteredDate
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="center">{Object.isExtensible(receipt.account) ? Data.accountRegistrationStatus(receipt.account.accountRegistrationStatus) : ""}</TableCell>
                    <TableCell align="center">
                      {(receipt.askStatus === "ASKING" || receipt.askStatus === "REGISTERING") &&
                      !(receipt?.account?.accountRegistrationStatus === "REGISTERING" || receipt?.account?.accountRegistrationStatus === "UNREGISTERING")
                        ? "청구중"
                        : Data.contractStatus(receipt.contractStatus, "r")}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

// 고객정보관리 > 지급 고객정보
// 렌더를 처리하는 메인 컴포넌트
const InformationList = ({ props }) => {
  const { store } = props;

  const history = useHistory();

  const [searchRequest, setSearchRequest] = useState(searchData); // table 검색 조건
  const [searchButton, setSearchButton] = useState(false);
  const [receiptList, setReceiptList] = useState([]);
  const [filterReceiptList, setFilterReceiptList] = useState([]);
  const [pagination, setPagination] = useState(paginationData());
  const [summaryList, setSummaryList] = useState(summaryData({}));
  const [searchOption, setSearchOption] = useState({
    capitalUniqueKey: "",
    groupUniqueKey: "",
    accountRegistrationStatus: "",
    terms: "CUSTOMER_NAME",
    search: "",
  }); // 검색 옵션

  // 서버로부터 수납 고객정보 목록 가져오기
  useEffect(() => {
    // aixos request summary
    function axiosSummary() {
      return new Promise((resolve, reject) => {
        const url = "api/customer/receipt/receipts/summary";
        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    }

    // useEffect start function
    async function startAxiosSummary() {
      const resultData = await axiosSummary();
      setSummaryList(summaryData(resultData));
    }

    // axios 호출
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = "api/customer/receipt/receipts";
        url += `?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}`;
        url += `&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;
        url += `&capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&contractStatus=${search.accountRegistrationStatus}`;
        url += `&searchProperty=${search.terms}&searchContents=${search.search}&transactionMethod=${search.transactionMethod}`;

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    };

    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      handleSearchButtonChange(false);
      const resultReceipt = resultData.content.map((data) => ({ ...data, _checked: false }));

      handleReceiptList(resultData);
      handleFilterReceiptList(resultReceipt);
      handlePagination(paginationData(resultData));

      // state 유지를 위한 history 설정
      history.replace("/customer/receiptCustomerInformationList", search);
    };

    // 실행 영역
    if (searchButton) {
      startAxiosSummary();
      startAxios(searchRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest, searchButton]);

  useEffect(() => {
    const getState = history.location.state;
    if ((history.action === "POP" || history.action === "PUSH") && CM.cfnIsNotEmpty(getState)) {
      setSearchOption({
        capitalUniqueKey: getState.capitalUniqueKey,
        groupUniqueKey: getState.groupUniqueKey,
        accountRegistrationStatus: getState.accountRegistrationStatus,
        terms: getState.terms,
        search: getState.search,
      });
      setSearchRequest(getState);
    }
    setSearchButton(true);
  }, [history]);

  // 오리지널 조회 데이터 handler
  const handleReceiptList = (data) => setReceiptList(data);

  // 페이지네이션 handler
  const handlePagination = (data) => setPagination(data);

  // 검색 flag handler
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 고객 목록 리스트 값 handler
  const handleFilterReceiptList = (data) => setFilterReceiptList(data);

  // 테이블 데이터 검색 조건 handler
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  // 검색 (page) handler
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage) handler
  const handleRowPerPageChange = ({ target: { value } }) => {
    handleSearchRequestChange((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    handleSearchButtonChange(true);
  };

  // 검색 컴포넌트 handler
  const handleSearchFormChange = (capitalUniqueKey, groupUniqueKey, accountRegistrationStatus, terms, search, pageNumber) => {
    handleSearchRequestChange((data) => ({
      ...data,
      capitalUniqueKey: capitalUniqueKey,
      groupUniqueKey: groupUniqueKey,
      accountRegistrationStatus: accountRegistrationStatus,
      terms: terms,
      search: search,
      pageNumber: pageNumber,
    }));
  };

  // 검색옵션 handler
  const handleSearchOptionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "terms") {
      setSearchOption((option) => ({ ...option, [name]: value, search: "" }));
    } else {
      setSearchOption((option) => ({ ...option, [name]: value }));
    }
  };

  // 요약 정보에 해당하는 table 데이터 조회
  const handleSummaryClick = (label) => {
    let value = "";

    switch (label) {
      case "CMS 수납대상":
        value = "CMS_WITHDRAW";
        break;
      case "기타 수납대상":
        value = "ETC";
        break;
      default:
        value = "";
        break;
    }

    handleSearchRequestChange((data) => ({
      ...data,
      search: "",
      transactionMethod: value,
    }));

    handleSearchButtonChange(true);
  };

  //모달 관련
  const [receiptUpdateListOpen, setReceiptUpdateListOpen] = React.useState(false);
  const [receiptCreateListOpen, setReceiptCreateListOpen] = React.useState(false);

  const handleClickModal = (modal) => {
    switch (modal) {
      case "receiptUpdateListOpen":
        setReceiptUpdateListOpen(true);
        break;
      case "receiptCreateListOpen":
        setReceiptCreateListOpen(true);
        break;
      default:
        break;
    }
  };

  // 선택고객 일괄 삭제를 처리하는 함수
  const fnDeleteReceipt = () => {
    const sendData = []; // 삭제를 위한 data

    // 체크된 row의 고객 정보를 담음
    for (const receipt of filterReceiptList) {
      if (receipt._checked) {
        sendData.push(receipt.uniqueKey);
      }
    }

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      CM.cfnAlert("삭제할 수납 고객정보를 선택해 주세요.");
      return;
    }

    CM.cfnConfirm("총 " + sendData.length + "건의 수납고객정보를 삭제하시겠습니까?", () => {
      CM.cfnConfirm(
        "수납고객정보 삭제 후에 고객기본정보에 남는 수납정보 및 지급정보가 없는 경우,\n고객기본정보까지 함께 삭제하시겠습니까?\n확인 : 고객기본정보 함께 삭제, 취소 : 고객기본정보 유지",
        () => {
          // axios 호출
          CM.cfnAxios("/api/customer/receipt/receipts/deleteList/deleteBasics", "put", sendData, (status, response) => {
            CM.cfnAlert(response, () => {
              // 데이터 재조회
              handleSearchButtonChange(true);
            });
          });
        },
        () => {
          // axios 호출
          CM.cfnAxios("/api/customer/receipt/receipts/deleteList", "put", sendData, (status, response) => {
            CM.cfnAlert(response, () => {
              // 데이터 재조회
              handleSearchButtonChange(true);
            });
          });
        }
      );
    });
  };

  // 선택고객계좌 일괄 해지를 처리하는 함수
  const fnUnregisterAccount = () => {
    const sendData = []; // 삭제를 위한 data
    const url = "/api/customer/receipt/receipts/accountList";

    const omittedData = [];
    // 체크된 row의 고객 정보를 담음
    for (const receipt of filterReceiptList) {
      if (receipt._checked) {
        if (!receipt.account || receipt.account.accountRegistrationStatus !== "REGISTERED") {
          omittedData.push(receipt);
        } else {
          sendData.push(receipt.uniqueKey);
        }
      }
    }

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) {
      CM.cfnAlert("계좌를 해지할 수납 고객정보를 선택해 주세요." + (omittedData.length > 0 ? " 계좌가 등록된 상태의 수납고객만 일괄 해지가 가능합니다." : ""));
      return;
    }

    if (omittedData.length > 0) {
      CM.cfnConfirm(
        omittedData[0].customer.customerName +
          "(납부자번호 " +
          omittedData[0].payerNo +
          ")" +
          (omittedData.length > 1 ? " 외 " + (omittedData.length - 1) : "") +
          "건은 계좌가 등록된 상태가 아니므로 제외하고 " +
          sendData.length +
          "건만 처리됩니다. 계속하시겠습니까?\n\n※계좌가 해지되어도 수납내역은 계속 생성됩니다.\n더 이상 수납하지 않을 고객이라면 수납 고객정보에서 해당 고객을 수납중지하거나, 수납종료월을 입력(총수납횟수 \"기한없음\"이 체크된 경우 해제 후)하시기 바랍니다.",
        () => {
          // axios 호출
          CM.cfnAxios(url, "put", sendData, (status, response) => {
            CM.cfnAlert(response, () => {
              // 데이터 재조회
              handleSearchButtonChange(true);
            });
          });
        }
      );
    } else {
      CM.cfnConfirm("총 " + sendData.length + "건의 수납고객의 계좌를 해지처리합니다. 계속하시겠습니까?\n\n※계좌가 해지되어도 수납내역은 계속 생성됩니다.\n더 이상 수납하지 않을 고객이라면 수납 고객정보에서 해당 고객을 수납중지하거나, 수납종료월을 입력(총수납횟수 \"기한없음\"이 체크된 경우 해제 후)하시기 바랍니다.", () => {
        // axios 호출
        CM.cfnAxios(url, "put", sendData, (status, response) => {
          CM.cfnAlert(response, () => {
            // 데이터 재조회
            handleSearchButtonChange(true);
          });
        });
      });
    }
  };

  const [instituteInfoState, setInstituteInfoState] = useState(false);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.instituteStore.axiosCustomerGroup(); // 고객구분
      await store.instituteStore.axiosReceiptCapitals(); //수납 자금종류

      const businessInfo = await store.instituteStore.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      if (businessInfo.cmsService.indexOf("EB21") === -1 && businessInfo.cmsService.indexOf("EC21") === -1) {
        await setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 출금이체서비스를 이용하지 않아 본 서비스를 이용할 수 없습니다.", () => {});
      }
    };

    getStoreDataAxios();
  }, [store.instituteStore]);

  // 고객 row 클릭시 고객 조회/수정 페이지로 이동
  const goUpdateReceiptPage = (e, receipt) => {
    history.push("/customer/receiptCustomerUpdate", {
      receipt,
      searchRequest,
    });
  };

  //수납고객정보등록 버튼 클릭 시 수납정보 등록 페이지로 이동
  const goCreateReceiptPage = () => {
    history.push("/customer/receiptCustomerCreate");
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    // 정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 엑셀다운로드
  const fnSavedExcel = () => {
    let url = `/api/customer/receipt/receipts/excel?capitalUniqueKey=${searchRequest.capitalUniqueKey}&groupUniqueKey=${searchRequest.groupUniqueKey}&contractStatus=${searchRequest.accountRegistrationStatus}&searchProperty=${searchRequest.terms}&searchContents=${searchRequest.search}&transactionMethod=${searchRequest.transactionMethod}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  // 화면 렌더
  return (
    <div className={instituteInfoState ? style.disabled_div : ""}>
      <div className="summary-main-area">
        <SummaryForm handleSummaryClick={handleSummaryClick} summaryList={summaryList} />
        <div className="summary-button-area">
          <button className="btn-m summary-button" onClick={goCreateReceiptPage} data-testid="insert-Receipt">
            수납고객등록
          </button>
          <button className="btn-m summary-button" data-testid="insert-Receipts" onClick={(event) => handleClickModal("receiptCreateListOpen")}>
            일괄등록
          </button>
          <button className="btn-m summary-button" data-testid="insert-Receipts" onClick={(event) => handleClickModal("receiptUpdateListOpen")}>
            일괄변경
          </button>
        </div>
      </div>
      <SearchForm
        searchOption={searchOption} // 검색옵션
        handleSearchOptionChange={handleSearchOptionChange}
        searchRequest={searchRequest}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        searchCapitalUniqueKey={toJS(store.instituteStore.receiptCapital)}
        searchCustomerGroup={toJS(store.instituteStore.customerGroup)} // 고객구분
      />
      <div className="table-top-area">
        <TotalCountForm totalElements={receiptList.totalElements || 0} />
        <RowPerPageForm
          value={searchRequest.pageSize}
          onChange={handleRowPerPageChange}
          customProps={{ inputProps: { "data-testid": "receiptCustomerInformationList-rowPerPage-select", name: "rowPerPage" } }}
        />
        <button className="btn-m fr" data-testid="save-excel" onClick={fnSavedExcel}>
          엑셀저장
        </button>
        <button className="btn-m fr" data-testid="delete-select-Receipt" onClick={fnDeleteReceipt}>
          선택고객 삭제
        </button>
        <button className="btn-m fr" data-testid="delete-select-withdraw-account" onClick={fnUnregisterAccount}>
          선택고객 계좌해지
        </button>
      </div>
      <ListForm
        filterReceiptList={filterReceiptList}
        setFilterReceiptList={setFilterReceiptList}
        goUpdateReceiptPage={goUpdateReceiptPage}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} testId="receiptCustomerInformationList-list-pagiation" />

      <ReceiptUpdateList
        open={receiptUpdateListOpen}
        setOpen={setReceiptUpdateListOpen}
        searchCapitalUniqueKeyData={toJS(store.instituteStore.receiptCapital)}
        searchGroupUniqueKeyData={toJS(store.instituteStore.customerGroup)}
      />

      <ReceiptCreateModal open={receiptCreateListOpen} setOpen={setReceiptCreateListOpen} handleSearchButtonChange={handleSearchButtonChange} submissionType={store.loginStore.getEvidence()} />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore,
  props: props,
}))(observer(InformationList));
