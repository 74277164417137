import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import CDN from "../../common/CommonDataName";
import { Link } from "react-router-dom";

// 기관/업무정보 > 정보 변경 이력 > 연체료 적용 이력
const DelayHistory = () => {
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({ rowsPerPage: 5, offset: 0, page: 0, total: 0 });

  useEffect(() => {
    const rowsRender = () => {
      const url = "api/institute/histories/delay";
      CM.cfnAxios(url, "get", "", (status, data) => {
        setRows(data);
        setPagination((element) => ({ ...element, total: data.length }));
      });
    };

    rowsRender();
  }, []);

  // 표시 데이터 수 변경 이벤트 핸들러
  const handleChangeRowsPerPage = ({ target: { value } }) => setPagination((data) => ({ ...data, rowsPerPage: value, page: 0 }));

  // 페이지 변경 이벤트 핸들러
  const handleOffsetChange = (e, offset, page) => setPagination((data) => ({ ...data, offset, page: page - 1 }));

  // 렌더
  return (
    <div>
      <div>
        <div className="inforbox">
          <ul>
            <li>
              연체료 부과방식은{" "}
              <Link to="/institute/business" className="link">
                기관&middot;업무정보 &gt; 기본정보 &gt; 업무정보
              </Link>{" "}
              에서 변경 가능합니다.
            </li>
            <li>연체료 적용 이력은 월별 변경 적용된 내역을 보여줍니다.</li>
          </ul>
        </div>
      </div>
      <div>
        <div className="table-top-area">
          <CF.TotalCountForm totalElements={pagination.total || 0} />
          <CF.RowPerPageForm value={pagination.rowsPerPage} onChange={handleChangeRowsPerPage} customProps={{ inputProps: { "data-testid": "delayHistory-rowPerPage-select" } }} />
        </div>
        <Table data-testid="delayHistory-table">
          {CM.cfnCompColGroup(["8%", "15%", "auto", "15%", "15%"])}
          <TableForm.compTableHead arrData={["순번", "구분", "연체료 부과방식", "적용월", "등록일"]} />
          <TableBody>
            {rows.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={5} />
            ) : (
              rows.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage).map((row, index) => {
                return (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="center">{rows.length - pagination.rowsPerPage * pagination.page - index}</TableCell>
                    <TableCell align="center">{CDN.delayRate.delayChargingType(row.delayChargingType)}</TableCell>
                    <TableCell align="center">{row.delayChargingType === "FIXED_RATE" ? `연 ${row.delayCharge}%` : `월 ${CM.cfnAddComma(row.delayCharge)}원`}</TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(row.applyY4mm, "yyyyMM")}</TableCell>
                    <TableCell align="center">{CM.cfnDateFormat(row.createdDatetime, "yyyyMMdd")}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="delayHistory-pagination" />
    </div>
  );
};

export default DelayHistory;
