import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { toJS } from "mobx";
import * as CM from "../../common/Common";

const headRows1 = [
  { id: "seq", numeric: true, disablePadding: false, label: "기재내역 1" },
  { id: "gbn", numeric: true, disablePadding: false, label: "기재내역 2" },
  { id: "title", numeric: true, disablePadding: false, label: "기재내역 3" },
  { id: "file", numeric: true, disablePadding: false, label: "기재내역 4" },
  { id: "createDt", numeric: true, disablePadding: false, label: "기재내역 5" },
];
const headRows2 = [
  { id: "seq2", numeric: true, disablePadding: false, label: "번호체계 1" },
  { id: "gbn2", numeric: true, disablePadding: false, label: "번호체계 2" },
  { id: "title2", numeric: true, disablePadding: false, label: "번호체계 3" },
  { id: "file2", numeric: true, disablePadding: false, label: "번호체계 4" },
  { id: "createDt2", numeric: true, disablePadding: false, label: "번호체계 5" },
];

const BusinessInfo = ({ instituteStore, props }) => {
  const store = instituteStore;
  const tabIndex = props.tabIndex;
  /*
   * @desc 빌링원플러스 업무 정보 및 설정 영역 default 데이터
   */
  const [defaultValues] = useState({
    autoGeneratePayerNoSize: 0,
    autoGeneratePayerNoYn: false,
    bankbookContents: {
      bankbookContents: [],
    },
    billingAccount: {
      accountApplyDate: 0,
      accountBranchCode: "",
      accountNo: "",
      depositorIdentificationNo: "",
      depositorName: "",
      intgtLoginId: "",
    },
    cmsBusiness: {
      cmsInstCode: 0,
      evidenceSubmissionType: "",
      limitAmountForEachWithdrawal: 0,
      limitAmountForMonthlyWithdrawal: 0,
      limitCountForMonthlyWithdrawal: 0,
      maxLimitAmountForMonthlyWithdrawal: 0,
      payerNoSystems: [],
      receiptResultTransferDate: "",
      status: "",
      useRealtimeAccountRegistrationYn: true,
      useRealtimeDepositorNameInquiryYn: true,
      wayToRegisterAccount: "",
    },
    cmsInstCode: 0,
    cmsService: [],
    delayRate: {
      applyY4mm: 0,
      createdDatetime: 0,
      delayCharge: 0,
      delayChargingType: "NONE",
    },
    eb21Account: {
      accountBranchCode: "",
      accountNo: "",
      transactionType: "",
    },
    eb31Account: {
      accountBranchCode: "",
      accountNo: "",
      transactionType: "",
    },
    ec21Account: {
      accountBranchCode: "",
      accountNo: "",
      transactionType: "",
    },
    intgtLoginId: "",
    maxCustomerCount: "100",
    reaskUnpaidLimit: 0,
    status: "",
    virtualAccountNo: "",
    withdrawCriteria: "",
  });

  /*
  @desc 빌링원플러스 업무 정보 및 설정 영역 내 요소와 관련된 state
  */
  const [values, setValues] = useState(defaultValues);
  //필요 state 선언
  const [financialInstitutes, setFinancialInstitutes] = React.useState([]);

  useEffect(() => {
    if (tabIndex === 1) {
      const storeBusinessInfo = CM.cfnCopyObject(toJS(store.businessInfo));
      setValues(storeBusinessInfo);
    }

    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const url = "/api/gadget/financial-institutes";
      await CM.cfnAxios(url, "get", "", fnSetFinancialInstitutes);
    };

    /*
     * @desc    금융기관 목록 조회 Request Callback
     */
    const fnSetFinancialInstitutes = (objStatus, objData) => {
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        // window.alert(objStatus.statusTe);
        return false;
      }
      setFinancialInstitutes(objData);
    };
    if (CM.cfnIsEmpty(financialInstitutes) && tabIndex === 1) fnFetchFinancialInstitutes();
  }, [tabIndex, store, financialInstitutes, setFinancialInstitutes]);

  const fnRenderTd = (length) => {
    const tableTd = [];
    for (let i = 5 - length; i > 0; i--) {
      tableTd.push(
        <TableCell className="td" align="center" key={i}>
          -
        </TableCell>
      );
    }
    return tableTd;
  };

  const fnRenderFeesTd = (fees) => {
    const tableTd = [];

    let renderStr1 = "-";
    let renderStr2 = "-";
    let renderStr3 = "-";

    for (let i = 0; i < fees.length; i++) {
      if (fees[i] === "EB21") {
        renderStr1 = `의뢰건당 ${values.cmsBusiness.bankFeeForEachEb21Request || 0}원, 이체건당 ${values.cmsBusiness.bankFeeForEachEb21 || 0}원`;
      }
      if (fees[i] === "EC21") {
        renderStr2 = `의뢰건당 ${values.cmsBusiness.bankFeeForEachEc21Request || 0}원, 이체건당 ${values.cmsBusiness.bankFeeForEachEc21 || 0}원`;
      }
      if (fees[i] === "EB31") {
        renderStr3 = `의뢰건당 ${values.cmsBusiness.bankFeeForEachEb31Request || 0}원, 이체건당 ${values.cmsBusiness.bankFeeForEachEb31 || 0}원`;
      }
    }
    tableTd.push(
      <TableRow key="fee">
        <TableCell className="td" align="center">
          {renderStr1}
        </TableCell>
        <TableCell className="td" align="center">
          {renderStr2}
        </TableCell>
        <TableCell className="td" align="center">
          {renderStr3}
        </TableCell>
      </TableRow>
    );

    return tableTd;
  };

  return (
    <div>
      <div className="inforbox">
        <li>
          CMS 업무 신청정보를 변경 하시려면{" "}
          <a href="https://www.cmsedi.or.kr/cms" target="_blank" rel="noopener noreferrer" className="link">
            CMS홈페이지
          </a>
          에서 변경하시거나 고객센터(1577-5500)로 문의하시기 바랍니다.
        </li>
      </div>
      <h4>이체 서비스 신청내역</h4>
      <Table>
        {CM.cfnCompColGroup(["20%", "30%", "20%", "30%"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">출금이체서비스</TableCell>
            <TableCell className="td" align="center">
              {values.cmsService.includes("EB21") || values.cmsService.includes("EC21")
                ? values.cmsService
                    .filter((code) => code === "EB21" || code === "EC21")
                    .map((code) => (code === "EB21" ? "익일출금" : "당일출금"))
                    .join(", ")
                : "-"}
            </TableCell>
            <TableCell className="th">출금이체 수납계좌</TableCell>
            <TableCell className="td" align="center">
              {values.eb21Account.accountBranchCode !== null && values.eb21Account.accountBranchCode !== ""
                ? financialInstitutes.map((row) => (row.fncInsCd === values.eb21Account.accountBranchCode.substr(0, 3) ? row.fncInsNm + " " + CM.cfnMasking(values.eb21Account.accountNo) : ""))
                : values.ec21Account.accountBranchCode !== null && values.ec21Account.accountBranchCode !== ""
                ? financialInstitutes.map((row) => (row.fncInsCd === values.ec21Account.accountBranchCode.substr(0, 3) ? row.fncInsNm + " " + CM.cfnMasking(values.ec21Account.accountNo) : ""))
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">입금이체서비스</TableCell>
            <TableCell className="td" align="center">
              {values.cmsService.indexOf("EB31") !== -1 ? "익일입금" : "-"}
            </TableCell>
            <TableCell className="th">입금이체 지급계좌</TableCell>
            <TableCell className="td" align="center">
              {values.eb31Account.accountBranchCode !== null && values.eb21Account.accountBranchCode !== ""
                ? financialInstitutes.map((row) => (row.fncInsCd === values.eb31Account.accountBranchCode.substr(0, 3) ? row.fncInsNm + " " + CM.cfnMasking(values.eb31Account.accountNo) : ""))
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">월간 출금한도</TableCell>
            <TableCell className="td" align="center">
              {CM.cfnIsNotEmpty(values.cmsBusiness.maxLimitAmountForMonthlyWithdrawal) ? CM.cfnAddComma(values.cmsBusiness.maxLimitAmountForMonthlyWithdrawal) : "-"}원
            </TableCell>
            <TableCell className="th">건당 출금한도</TableCell>
            <TableCell className="td" align="center">
              {CM.cfnIsNotEmpty(values.cmsBusiness.limitAmountForEachWithdrawal) ? CM.cfnAddComma(values.cmsBusiness.limitAmountForEachWithdrawal) : "-"}원
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
      <h4>통장기재내역(기본) </h4>
      <Table aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {headRows1.map((row) => (
              <TableCell className="th" key={row.id}>
                {row.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {values.bankbookContents.bankbookContents.map((contents, index) => (
              <TableCell className="td" align="center" key={index} data-testid={"testColumn" + index}>
                {contents}
              </TableCell>
            ))}
            {fnRenderTd(values.bankbookContents.bankbookContents.length)}
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area">
        <div className="inforbox2">※ 자금 입출금시 고객통장에 기재되는 내역으로, 최대 8자까지 등록해서 사용할 수 있습니다.</div>
      </div>
      <h4>은행수수료 </h4>
      <Table>
        <TableHead>
          <TableRow className="innerTeble">
            <TableCell className="th">익일출금</TableCell>
            <TableCell className="th">당일출금</TableCell>
            <TableCell className="th">입금이체</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{fnRenderFeesTd(values.cmsService)}</TableBody>
      </Table>
      <div className="table-bottom-area">
        <div className="inforbox2">※ 상기 은행수수료는 금융결제원에 등록된 내역으로 실제 은행과의 변경 계약 등에 따라 다를 수 있습니다.</div>
      </div>
      <h4>납부자번호 체계 </h4>
      <Table aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {headRows2.map((row) => (
              <TableCell className="th" key={row.id}>
                {row.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {values.cmsBusiness.payerNoSystems.map((payerNoSystems, index) => (
              <TableCell className="td" key={index} align="center">
                {payerNoSystems !== null && payerNoSystems.payerNoType === "N" ? "숫자 " : "영문 숫자 "}
                {payerNoSystems !== null && payerNoSystems.payerNoSize}자리
              </TableCell>
            ))}
            {fnRenderTd(values.cmsBusiness.payerNoSystems.length)}
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area">
        <div className="inforbox2">
          ※ 납부자번호 체계란 이용기관에서 수납할 고객을 관리하기 위한 번호 체계로 최대 20자리까지 구성 가능합니다.
          <br />※ "숫자"인 경우는 숫자로만, "영문 숫자"인 경우에는 영문(대문자) 및 숫자를 혼용하여 사용할 수 있습니다.
        </div>
      </div>
    </div>
  );
};
export default inject((rootStore, props) => ({
  instituteStore: rootStore.instituteStore,
  props: props,
}))(observer(BusinessInfo));
