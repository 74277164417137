import { Checkbox, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import Data from "../../customer/customerBasicInformation/CustomerData";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import ReceiptChangeAmountModal from "./ReceiptChangeAmountModal";
import ReceiptDelayFeeModal from "./ReceiptDelayFeeModal";
import ReceiptDirectlyPaidModal from "./ReceiptDirectlyPaidModal";
import ReceiptRefundModal from "./ReceiptRefundModal";

// 검색 데이터
function searchData(contractUniqueKey) {
  const today = new Date();

  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 2);
  const monthAfter = new Date(today.getFullYear(), today.getMonth() + 2);

  return {
    contractUniqueKey: contractUniqueKey,
    searchStartY4mm: monthAgo,
    searchEndY4mm: monthAfter,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const today = new Date();

  const nextYear = new Date(today.getFullYear() + 1, today.getMonth() + 2);
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 2);
  const payStart = props.payStartY4mm;

  let startDate = "";
  if (payStart < CM.cfnConvertDateToString(monthAgo).substring(0, 6)) {
    startDate = monthAgo;
  } else {
    startDate = CM.cfnConvertStringToDate(payStart + "01");
  }

  const { searchRequest, handleSearchFormChange } = props;

  handleSearchFormChange({
    ...searchRequest,
    searchStartY4mm: startDate,
  });
  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">수납기간</label>
        <Template.DatePickerForm
          className="w140 receiptRequestResultDatePicker"
          value={searchRequest.searchStartY4mm}
          handleChange={handleSearchFormChange("searchStartY4mm")}
          format="yyyy.MM"
          customProps={{
            placeholder: "YYYY.MM",
            maxDate: nextYear,
            views: ["month", "year"],
          }}
        />
        <span className="between" style={{ marginLeft: "0px" }}>
          ~
        </span>
        <Template.DatePickerForm
          className="w140 receiptRequestResultDatePicker"
          value={searchRequest.searchEndY4mm}
          handleChange={handleSearchFormChange("searchEndY4mm")}
          format="yyyy.MM"
          customProps={{ placeholder: "YYYY.MM", maxDate: nextYear, views: ["month", "year"] }}
        />
        <button className="search-button" onClick={(e) => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  선택 회차 수납내역 변경 이력 컴포넌트 생성
 */
function HistoryListForm(props) {
  const fnTransactionType = (transactionType) => {
    if (transactionType == null) {
      return "";
    }
    transactionType = transactionType.trim();
    switch (transactionType) {
      case "EB21":
        return "익일출금";
      case "EC21":
        return "당일출금";
      case "DIRECTLY_PAID":
        return "직접수납";
      case "CHANGE_AMOUNT":
        return "금액변경";
      case "DELAY_FEE":
        return "연체료조정";
      case "LOSS_AND_DELAY_FEE":
        return "손실처리 및 연체료부과";
      case "REFUND":
        return "환불";
      case "TEMPORARY_ADDED":
        return "내역추가";
      case "ASK_TAXBILL":
        return "세금계산서청구";
      case "TAXBILL_CENTER_ERROR":
        return "세금계산서발행오류";
      case "ISSUE_TAXBILL":
        return "세금계산서발행";
      case "CANCEL_ASK_TAXBILL":
        return "세금계산서청구취소";
      case "CANCEL_ISSUE_TAXBILL":
        return "세금계산서발행취소";
      case "EXEMPT_ASK_TARGET":
        return "청구대상제외";
      case "CANCEL_EXEMPT_ASK_TARGET":
        return "청구대상제외취소";
      case "HOLD_OFF_ASKING":
        return "수납보류";
      case "CANCEL_HOLD_OFF_ASKING":
        return "수납보류취소";
      case "LOSS_AND_REMOVE":
        return "손실처리";
      case "NOTICE_ERROR":
        return "고지오류";
      case "STOP_NOTICING":
        return "고지중지";
      case "DELETED":
        return "삭제";
      default:
        return transactionType;
    }
  };

  const fnReceiptTransactionType = (transactionType) => {
    switch (transactionType) {
      case "EB21":
      case "EC21":
        return "CMS";
      case "DIRECTLY_PAID":
        return "직접수납";
      default:
        return "-";
    }
  };

  const cfnPaidAmount = (transactionType, amountBeforeTransaction, amountAfterTransaction, delayFeeBeforeTransaction, delayFeeAfterTransaction) => {
    switch (transactionType) {
      case "EB21":
      case "EC21":
      case "DIRECTLY_PAID":
        return CM.cfnAddComma(amountBeforeTransaction - amountAfterTransaction - (delayFeeBeforeTransaction - delayFeeAfterTransaction));
      case "REFUND":
        return CM.cfnAddComma(amountAfterTransaction - amountBeforeTransaction - (delayFeeAfterTransaction - delayFeeBeforeTransaction));
      case "CHANGE_AMOUNT":
        return CM.cfnAddComma(amountAfterTransaction - amountBeforeTransaction);
      case "LOSS_AND_REMOVE":
        return CM.cfnAddComma(-(amountBeforeTransaction - delayFeeBeforeTransaction));
      // case "DELAY_FEE":
      // case "LOSS_AND_DELAY_FEE":
      // case "TEMPORARY_ADDED":
      // case "HOLD_OFF_ASKING":
      // case "CANCEL_HOLD_OFF_ASKING":
      // case "EXEMPT_ASK_TARGET":
      // case "ASK_TAXBILL":
      // case "TAXBILL_CENTER_ERROR":
      // case "ISSUE_TAXBILL":
      // case "CANCEL_ASK_TAXBILL":
      // case "CANCEL_ISSUE_TAXBILL":
      default:
        return "-";
    }
  };

  const cfnUnPaidAmount = (transactionType, amountBeforeTransaction, amountAfterTransaction, delayFeeBeforeTransaction, delayFeeAfterTransaction) => {
    switch (transactionType) {
      case "EB21":
      case "EC21":
      case "DIRECTLY_PAID":
      case "CHANGE_AMOUNT":
      case "DELAY_FEE":
      case "LOSS_AND_DELAY_FEE":
      case "TEMPORARY_ADDED":
        return CM.cfnAddComma(amountAfterTransaction - delayFeeAfterTransaction);
      case "REFUND":
      case "LOSS_AND_REMOVE":
        return 0;
      default:
        return "-";
    }
  };

  const cfnPaidDelayFee = (transactionType, amountBeforeTransaction, amountAfterTransaction, delayFeeBeforeTransaction, delayFeeAfterTransaction) => {
    switch (transactionType) {
      case "EB21":
      case "EC21":
      case "DIRECTLY_PAID":
      case "DELAY_FEE":
      case "REFUND":
        return CM.cfnAddComma(delayFeeBeforeTransaction - delayFeeAfterTransaction);
      case "LOSS_AND_REMOVE":
        return CM.cfnAddComma(-delayFeeBeforeTransaction);
      default:
        return "-";
    }
  };
  const cfnUnPaidDelayFee = (transactionType, amountBeforeTransaction, amountAfterTransaction, delayFeeBeforeTransaction, delayFeeAfterTransaction) => {
    switch (transactionType) {
      case "EB21":
      case "EC21":
      case "DIRECTLY_PAID":
      case "CHANGE_AMOUNT":
      case "DELAY_FEE":
      case "TEMPORARY_ADDED":
      case "LOSS_AND_DELAY_FEE":
        return delayFeeAfterTransaction === null ? "-" : CM.cfnAddComma(delayFeeAfterTransaction);
      case "LOSS_AND_REMOVE":
        return 0;
      default:
        return "-";
    }
  };

  return (
    <div>
      <h4>선택 회차 수납내역 변경 이력</h4>
      <Table>
        {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compTableHead arrData={["변경일", "처리구분", "수납일", "수납원금", "미납원금", "수납연체료", "미납연체료", "수납방법", "비고"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">{CM.cfnDateFormat(row.askedDate)}</TableCell>
                  <TableCell align="center">{fnTransactionType(row.transactionType)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.paidDate)}</TableCell>
                  <TableCell align="center">
                    {cfnPaidAmount(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction, row.delayFeeBeforeTransaction, row.delayFeeAfterTransaction)}
                  </TableCell>
                  <TableCell align="center">
                    {cfnUnPaidAmount(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction, row.delayFeeBeforeTransaction, row.delayFeeAfterTransaction)}
                  </TableCell>
                  <TableCell align="center">
                    {cfnPaidDelayFee(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction, row.delayFeeBeforeTransaction, row.delayFeeAfterTransaction)}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {cfnUnPaidDelayFee(row.transactionType, row.amountBeforeTransaction, row.amountAfterTransaction, row.delayFeeBeforeTransaction, row.delayFeeAfterTransaction)}
                  </TableCell>
                  <TableCell align="center">{fnReceiptTransactionType(row.transactionType)}</TableCell>
                  <TableCell align="center">{row.remark}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc 회차별 수납 내역 컴포넌트 생성
 */
function ContractListForm(props) {
  const {
    fnContractDetail,
    list,
    checkAllRow,
    handleCheckAllRowChange,
    handleUpdateChange,
    setReceiptHolding,
    setReceiptCancelHolding,
    setReceiptLoss,
    setReceiptChangeAmount,
    setReceiptDirectlyPaid,
    setReceiptDelayFee,
    setReceiptRefund,
    setContractDetail,
    pagination,
    handleOffsetChange,
    handleChangeRowsPerPage,
  } = props;

  const data = [];
  for (const row of list) {
    if (row._checked) {
      const copyObj = CM.cfnCopyObject(row);
      data.push(copyObj);
      setContractDetail(row);
    }
  }

  const fnAlert = (massage) => {
    CM.cfnAlert(`${massage}할 수납내역을 선택해주세요.`);
  };

  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.askStatus === "HOLDING") {
          return "미청구\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미청구\n(청구중)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.askStatus === "HOLDING") {
          return "미도래\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미도래\n(청구중)";
        } else {
          return "미도래";
        }
      case "UNPAID":
        if (row.askStatus === "HOLDING") {
          return "미납\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미납\n(청구중)";
        } else {
          return "미납";
        }
      case "PARTIALLY_PAID":
        if (row.askStatus === "HOLDING") {
          return "부분납\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "부분납\n(청구중)";
        } else {
          return "부분납";
        }
      case "PAID":
        return "수납";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "수납보류";
      default:
        if (row.askStatus === "ASKING" && row.numberOfAsking === 1) {
          return "출금중\n(최초출금)";
        } else if (row.askStatus === "ASKING" && row.numberOfAsking > 1) {
          return "출금중\n(재출금)";
        }
    }
  };

  const headRowOne = [
    { value: "수납\n회차", rowSpan: 2 },
    { value: "수납년월", rowSpan: 2 },
    { value: "수납대상금액", colSpan: 2 },
    { value: "수납금액", colSpan: 2 },
    { value: "미납금액", colSpan: 2 },
    { value: "손실처리금액", colSpan: 2 },
    { value: "수납상태", rowSpan: 2 },
    { value: "오류내역", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "원금" }, { value: "연체료" }, { value: "원금" }, { value: "연체료" }, { value: "원금" }, { value: "연체료" }, { value: "원금" }, { value: "연체료" }];

  const fnButton = () => {
    if (data.length === 1) {
      let buttonCount = 0;
      let buttonHtml = [];
      if (data[0].askStatus === "NORMAL" && (data[0].paidAmount > 0 || data[0].delayFee > 0)) {
        //환불버튼
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="refund-modal" onClick={(e) => setReceiptRefund(true)}>
            환불
          </button>
        );
      }

      if (data[0].askStatus === "NORMAL" && (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")) {
        //직접수납
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="directlyPaid-modal" onClick={(e) => setReceiptDirectlyPaid(true)}>
            직접수납
          </button>
        );
      }

      if (data[0].askStatus === "NORMAL" && (data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")) {
        //연체료
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="delayFee-modal" onClick={(e) => setReceiptDelayFee(true)}>
            연체료 수정
          </button>
        );
      }
      if (data[0].askStatus === "NORMAL" && (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")) {
        //수납원금 수정
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="changeAmount-modal" onClick={(e) => setReceiptChangeAmount(true)}>
            수납대상금액 수정
          </button>
        );
      }
      if ((data[0].askStatus === "NORMAL" || data[0].askStatus === "HOLDING") && (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID")) {
        // 손실처리
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="loss-modal" onClick={(e) => setReceiptLoss(true)}>
            손실처리
          </button>
        );
      }
      if (data[0].askStatus === "HOLDING") {
        // 보류해제
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="holdingCancel-modal" onClick={(e) => setReceiptCancelHolding(true)}>
            보류해제
          </button>
        );
      }
      if (data[0].askStatus === "NORMAL" && (data[0].askResult === "UNASKED" || data[0].askResult === "UNREACHED" || data[0].askResult === "UNPAID" || data[0].askResult === "PARTIALLY_PAID")) {
        //수납보류
        buttonCount++;
        buttonHtml.push(
          <button className="btn-l fr" key={buttonCount} data-testid="holding-modal" onClick={(e) => setReceiptHolding(true)}>
            수납보류
          </button>
        );
      }

      if (buttonCount > 0) {
        buttonHtml.push(
          <label className="label-l" key={buttonCount + 1}>
            선택한 회차를
          </label>
        );
      }
      return buttonHtml;
    } else if (data.length === 0) {
      return (
        <span>
          <button className="btn-l fr" data-testid="refund-modal" onClick={(e) => (data.length > 0 ? setReceiptRefund(true) : fnAlert("환불"))}>
            환불
          </button>
          <button className="btn-l fr" data-testid="directlyPaid-modal" onClick={(e) => (data.length > 0 ? setReceiptDirectlyPaid(true) : fnAlert("직접수납"))}>
            직접수납
          </button>
          <button className="btn-l fr" data-testid="delayFee-modal" onClick={(e) => (data.length > 0 ? setReceiptDelayFee(true) : fnAlert("연체료"))}>
            연체료 수정
          </button>
          <button className="btn-l fr" data-testid="changeAmount-modal" onClick={(e) => (data.length > 0 ? setReceiptChangeAmount(true) : fnAlert("수납원금"))}>
            수납금액 수정
          </button>
          <button className="btn-l fr" data-testid="loss-modal" onClick={(e) => setReceiptLoss(true)}>
            손실처리
          </button>
          <button className="btn-l fr" data-testid="holdingCancel-modal" onClick={(e) => setReceiptCancelHolding(true)}>
            보류해제
          </button>
          <button className="btn-l fr" data-testid="holding-modal" onClick={(e) => setReceiptHolding(true)}>
            수납보류
          </button>
          <label className="label-l">선택한 회차를</label>
        </span>
      );
    } else {
      return (
        <span>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setReceiptLoss(true)}>
            손실처리
          </button>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setReceiptCancelHolding(true)}>
            보류해제
          </button>
          <button className="btn-l fr" data-testid="save-excel" onClick={(e) => setReceiptHolding(true)}>
            수납보류
          </button>
          <label className="label-l">선택한 회차를</label>
        </span>
      );
    }
  };
  return (
    <div>
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={list.length || 0} />
        <Template.RowPerPageForm value={pagination.rowsPerPage} onChange={handleChangeRowsPerPage} customProps={{ inputProps: { name: "rowsPerPage" } }} />
        <div className="fr">{fnButton()}</div>
      </div>
      <Table>
        {CM.cfnCompColGroup(["3%", "5%", "7%", "9%", "7%", "9%", "7%", "9%", "7%", "8%", "7%", "10%","auto"])}
        <TableForm.compCheckBoxDoubleRowTableHead checked={checkAllRow} value="" onChange={handleCheckAllRowChange} rowOne={headRowOne} rowTwo={headRowTwo} />
        <TableBody>
          {list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={13} />
          ) : (
            list.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage).map((row, index) => {
              return (
                <TableRow key={index} hover className="show-detail" onClick={(e) => fnContractDetail(row, e)}>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: pagination.page * pagination.rowsPerPage + index,
                      }}
                    />
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.contractAskSequence) ? row.contractAskSequence : "추가분"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.totalAskingAmount - row.totalAskingDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.totalAskingDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.paidAmount - row.paidDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.paidDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.unpaidAmount - row.unpaidDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.unpaidDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.lostAmount - row.lostDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="right">
                    {CM.cfnAddComma(row.lostDelayFee)}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {fnAskStatus(row)}
                  </TableCell>
                  <TableCell align="center">
                    {row.resultCodeMsg === null ? "-" : row.resultCodeMsg}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Template.PaginationForm filteredRows={list} pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <div className="table-bottom-area" />
    </div>
  );
}

/*
 * @desc  수납 고객 정보 컴포넌트 생성
 */
function ReceiptCustomerForm(props) {
  return (
    <div>
      <div>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="th w120">고객명</TableCell>
              <TableCell className="td">{Object.isExtensible(props.receiptSourceDetail.customer) ? props.receiptSourceDetail.customer.customerName : ""}</TableCell>
              <TableCell className="th w120">생년월일(사업자번호)</TableCell>
              <TableCell className="td">{Object.isExtensible(props.receiptSourceDetail.customer) ? CM.cfnIdentificationNoFormat(props.receiptSourceDetail.customer.identificationNo) : ""}</TableCell>
              <TableCell className="th w120">납부자번호</TableCell>
              <TableCell className="td">{Object.isExtensible(props.receiptSourceDetail.customer) ? props.receiptSourceDetail.payerNo : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">담당부서</TableCell>
              <TableCell className="td">
                {!Object.isExtensible(props.receiptSourceDetail.customer)
                  ? ""
                  : Object.isExtensible(props.receiptSourceDetail.customer.staff)
                  ? props.receiptSourceDetail.customer.staff.staffDepartment
                  : ""}
              </TableCell>
              <TableCell className="th">고객담당자</TableCell>
              <TableCell className="td">
                {!Object.isExtensible(props.receiptSourceDetail.customer)
                  ? ""
                  : Object.isExtensible(props.receiptSourceDetail.customer.staff)
                  ? props.receiptSourceDetail.customer.staff.staffName
                  : ""}
              </TableCell>
              <TableCell className="th">수납방법</TableCell>
              <TableCell className="td">{props.receiptSourceDetail.transactionMethod !== "CMS_WITHDRAW" ? "기타" : "CMS"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">수납금액</TableCell>
              <TableCell className="td">{props.receiptSourceDetail.payAmount !== null ? CM.cfnAddComma(props.receiptSourceDetail.payAmount) + "원" : "비정액"}</TableCell>
              <TableCell className="th">자금종류</TableCell>
              <TableCell className="td">{Object.isExtensible(props.receiptSourceDetail.capital) ? props.receiptSourceDetail.capital.capitalName : ""}</TableCell>
              <TableCell className="th">수납주기</TableCell>
              <TableCell className="td">{Data.getReceiptName.payPeriod(props.receiptSourceDetail.payPeriod)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">수납시작월</TableCell>
              <TableCell className="td">{CM.cfnDateFormat(props.receiptSourceDetail.payStartY4mm, "yyyyMM")}</TableCell>
              <TableCell className="th">수납종료월</TableCell>
              <TableCell className="td">{props.receiptSourceDetail.payEndY4mm !== "999912" ? CM.cfnDateFormat(props.receiptSourceDetail.payEndY4mm, "yyyyMM") : "기한없음"}</TableCell>
              <TableCell className="th">총수납횟수</TableCell>
              <TableCell className="td">{props.receiptSourceDetail.totalNumberOfPayment}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">출금일</TableCell>
              <TableCell className="td">{props.receiptSourceDetail.paySpecifiedDay === "99" ? "말일" : props.receiptSourceDetail.paySpecifiedDay}</TableCell>
              <TableCell className="th">수납중지시작월</TableCell>
              <TableCell className="td">
                {props.receiptSourceDetail.contractPauseResumeY4mm !== "" ? CM.cfnDateFormat(props.receiptSourceDetail.contractPauseResumeY4mm, "yyyyMM") : "해당없음"}
              </TableCell>
              <TableCell className="th">계좌등록상태</TableCell>
              <TableCell className="td">
                {Object.isExtensible(props.receiptSourceDetail.account) ? Data.getReceiptName.accountRegistrationStatus(props.receiptSourceDetail.account.accountRegistrationStatus) : "계좌없음"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="table-bottom-area">
          <button className="btn-m" data-testid="insert-Receipts" onClick={(event) => props.history.goBack()}>
            목록
          </button>
        </div>
      </div>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function ReceiptSourceDetail(props) {
  let history = useHistory();
  let location = useLocation();

  const [receiptSourceDetail, setReceiptSourceDetail] = useState(location.state.receiptSourceDetail);

  const [searchRequest, setSearchRequest] = useState(searchData(receiptSourceDetail.contractUniqueKey)); // 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [contractList, setContractList] = useState([]); // 회차별 수납 내역 데이터
  const [historyList, setHistoryList] = useState([]); // 선택 회차 수납내역 변경 이력 데이터

  // table head checkbox state
  const [checkAllRow, setCheckAllRow] = useState(false);

  useEffect(() => {
    /*
     * @desc 날짜(년월)변환함수 date to string
     */
    const cfnConvertDateToString = (date) => {
      let obj = date;
      //파라미터 객체가 Date 타입일 때만 변환
      if (date.getFullYear) {
        const year = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();

        obj = `${year}${mm[1] ? mm : 0 + mm[0]}`;
      }

      return obj;
    };

    /*
     * @desc 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = (search) => {
      let tempObj = CM.cfnCopyObject(search);
      //파라미터 객체가 Date 타입일 때만 변환
      tempObj.searchStartY4mm = cfnConvertDateToString(search.searchStartY4mm);
      tempObj.searchEndY4mm = cfnConvertDateToString(search.searchEndY4mm);
      return tempObj;
    };

    /*
     * @desc 수납 고객 정보 조회
     */
    const axiosReceipt = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/receipts/${search.contractUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    회차별 수납 내역
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/contract?searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&contractUniqueKey=${search.contractUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      //수납 고객 정보
      const resultReceipt = await axiosReceipt(search);
      setReceiptSourceDetail(resultReceipt);

      //회차별 수납 내역
      const resultData = await axiosList(search);
      if (CM.cfnIsNotEmpty(resultData)) {
        for (const element of resultData) {
          element._checked = false;
          element.index = resultData.indexOf(element);
        }
      }
      setContractList(resultData);
      setCheckAllRow(false);
      setSearchButton(false);
    }

    // 실행 영역
    if (searchButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param);
    }
  }, [searchRequest, searchButton]);

  const [receiptHolding, setReceiptHolding] = useState(false); //수납보류
  const [receiptCancelHolding, setReceiptCancelHolding] = useState(false); //수납보류해제
  const [receiptLoss, setReceiptLoss] = useState(false); //손실처리
  const [receiptChangeAmount, setReceiptChangeAmount] = useState(false); //원금수정
  const [receiptDirectlyPaid, setReceiptDirectlyPaid] = useState(false); //직접수납
  const [receiptDelayFee, setReceiptDelayFee] = useState(false); //연체료 수정
  const [receiptRefund, setReceiptRefund] = useState(false); //환불
  const [contractDetail, setContractDetail] = useState(false); //모달에 props로 넘길 데이터

  //수납보류, 수납보류해제, 손실처리 (버튼 제어)
  useEffect(() => {
    /*
     * @desc   수납보류 Request
     */
    const startAxiosHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/holding`;
        if (sendData.length === 1) {
          url = `api/receipt/source/holding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };
    /*
     * @desc   수납보류해제 Request
     */
    const startAxiosCancelHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/cancelHolding`;
        if (sendData.length === 1) {
          url = `api/receipt/source/cancelHolding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };
    /*
     * @desc   손실처리 Request
     */

    const startAxiosLoss = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/loss`;
        if (sendData.length === 1) {
          url = `api/receipt/source/loss/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };

    const checkedData = [];
    const sendData = [];
    for (const row of contractList) {
      if (row._checked) {
        checkedData.push(row);
      }
    }

    // 수납보류 버튼 클릭
    if (receiptHolding === true) {
      if (checkedData.length < 1) {
        CM.cfnAlert("보류처리 할 수납내역을 선택해주세요. ", () => {
          setReceiptHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if (row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 수납내역은 보류처리 대상이 아닙니다.`, () => {
            setReceiptHolding(false);
          });
          return;
        }
      }
      startAxiosHolding(sendData);
      setReceiptHolding(false);
    }
    // 보류해체처리 버튼 클릭
    if (receiptCancelHolding === true) {
      if (checkedData.length === 0) {
        CM.cfnAlert("보류해체처리 할 수납내역을 선택해주세요. ", () => {
          setReceiptCancelHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") && row.askStatus === "HOLDING") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 수납내역은 보류해체처리 대상이 아닙니다.`, () => {
            setReceiptCancelHolding(false);
          });
          return;
        }
      }
      startAxiosCancelHolding(sendData);
      setReceiptCancelHolding(false);
    }
    // 손실처리 버튼 클릭
    if (receiptLoss === true) {
      if (checkedData.length < 1) {
        CM.cfnAlert("손실처리 할 수납내역을 선택해주세요. ", () => {
          setReceiptLoss(false);
        });
        return;
      }
      for (const row of checkedData) {
        if (row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 수납내역은 손실처리 대상이 아닙니다.`, () => {
            setReceiptLoss(false);
          });
          return;
        }
      }
      startAxiosLoss(sendData);
      setReceiptLoss(false);
    }
  }, [receiptHolding, receiptCancelHolding, receiptLoss, contractList]);

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e,
    });
  };
  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;
    setCheckAllRow(checkAllValue);
    setContractList((contract) => contract.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = Number(event.target.getAttribute("index"));

    const cloneData = [...contractList];
    cloneData[index][key] = value;
    setContractList(cloneData);
  };

  //선택 회차 수납내역 변경 이력 조회
  function fnContractDetail(row, e) {
    let url = `api/receipt/source/history?sourceUniqueKey=${row.uniqueKey}`;
    CM.cfnAxios(url, "get", "", fnCallback);
  }

  const fnCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return;
    }
    setHistoryList(objData);
  };

  const [pagination, setPagination] = useState({ rowsPerPage: 5, offset: 0, page: 0 });

  /*
   * @desc    페이지 변경 이벤트 핸들러
   */
  const handleOffsetChange = (offset, page) => {
    setPagination({
      ...pagination,
      offset: offset,
      page: page - 1,
    });
  };

  /*
  @desc 표시 데이터 수 변경 이벤트 핸들러
 */
  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: event.target.value,
      page: 0,
    });
  };

  return (
    <div>
      <h4>수납 고객 정보</h4>
      <ReceiptCustomerForm receiptSourceDetail={receiptSourceDetail} history={history} />
      <h4>회차별 수납내역</h4>
      <div className="inforbox">
        <ul>
          <li>계좌이체, 현금등으로 대금을 수납받았을 경우 해당월의 내역을 선택한 후 직접수납 기능으로 완납 처리 하시기 바랍니다.</li>
          <li>수납할 필요가 없는 내역은 해당월의 내역을 선택한 후 수납보류 혹은 손실처리 하시기 바랍니다.</li>
          <li>
            "직접수납", "손실처리", "환불", "연체료" 처리 후 <b>취소 및 수정이 불가능하오니</b> 신중하게 처리하시기 바랍니다.
          </li>
        </ul>
      </div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} payStartY4mm={receiptSourceDetail.payStartY4mm} />
      <ContractListForm
        list={contractList}
        setContractList={setContractList}
        checkAllRow={checkAllRow}
        fnContractDetail={fnContractDetail}
        handleCheckAllRowChange={handleCheckAllRowChange}
        handleUpdateChange={handleUpdateChange}
        setReceiptHolding={setReceiptHolding}
        setReceiptCancelHolding={setReceiptCancelHolding}
        setReceiptLoss={setReceiptLoss}
        setReceiptChangeAmount={setReceiptChangeAmount}
        setReceiptDirectlyPaid={setReceiptDirectlyPaid}
        setReceiptDelayFee={setReceiptDelayFee}
        setReceiptRefund={setReceiptRefund}
        setContractDetail={setContractDetail}
        pagination={pagination}
        handleOffsetChange={handleOffsetChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <HistoryListForm list={historyList} />

      <ReceiptChangeAmountModal open={receiptChangeAmount} setOpen={setReceiptChangeAmount} contractDetail={contractDetail} setSearchButton={setSearchButton} />
      <ReceiptDirectlyPaidModal open={receiptDirectlyPaid} setOpen={setReceiptDirectlyPaid} contractDetail={contractDetail} setSearchButton={setSearchButton} />
      <ReceiptDelayFeeModal open={receiptDelayFee} setOpen={setReceiptDelayFee} contractDetail={contractDetail} setSearchButton={setSearchButton} />
      <ReceiptRefundModal open={receiptRefund} setOpen={setReceiptRefund} contractDetail={contractDetail} setSearchButton={setSearchButton} />
    </div>
  );
}

export default ReceiptSourceDetail;
