import React, { useState, useEffect } from "react";
import * as CM from "../../common/Common";

import icon1 from "../../../styles/images/icon1.png";
import { Link, useHistory } from "react-router-dom";
/*
 * @desc  확인사항 컴포넌트
 */
export default function CheckList() {
  //필요 state 선언
  const [checkList, setCheckList] = useState({
    financialInstituteRegistrationCount: 0,
    instituteRegistrationCount: 0,
    totalTargets: 0,
    totalFixingTargets: 0,
    withdrawCount: 0,
    depositCount: 0,
  });
  const [isFirstLoaded, setIsFirstLoaded] = useState(true);
  const history = useHistory();

  //화면 진입 시 확인사항 조회
  useEffect(() => {
    /*
     * @desc  등록대기 조회 Request
     */
    const fnFetchWaitingSummaryInfo = () => {
      return new Promise((resolve) => {
        const url = `api/customer/receipt/account/institute/registrations?isClosingAccountIncluded=true&isNewAccountIncluded=true&pageNumber=0&pageSize=5&sortDirection=ASC&sortProperty=customerName`;
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data.receiptAccountRegistrationWaitingSummaryInfo.totalTargets);
        });
      });
    };

    /*
     * @desc  보완대상 조회 Request
     */
    const fnFetchFixingSummaryInfo = () => {
      return new Promise((resolve) => {
        const url = `api/customer/receipt/account/institute/fixing?isAccountNoErrorIncluded=true&isEtcErrorIncluded=true&isIdentificationNumberErrorIncluded=true&isWithdrawalAgreementMissingIncluded=true&pageNumber=0&pageSize=5&sortDirection=ASC&sortProperty=customerName`;
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data.receiptAccountRegistrationFixingSummaryInfo.totalFixingTargets);
        });
      });
    };

    /*
     * @desc  보완대상 조회 Request
     */
    const fnFetchCurrentCmsInfo = () => {
      return new Promise((resolve) => {
        const url = "api/receipt/cms/current";
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc  확인사항 조회 Request
     */
    const fnFetchCheckList = async () => {
      const totalTargets = await fnFetchWaitingSummaryInfo();
      const totalFixingTargets = await fnFetchFixingSummaryInfo();
      const currentCms = await fnFetchCurrentCmsInfo();

      const newCheckListObj = {
        financialInstituteRegistrationCount: currentCms.financialInstituteRegistrationCount, //금융기관접수분 미처리건수
        instituteRegistrationCount: currentCms.instituteRegistrationCount, //이용기관접수분 결과 미처리건수
        totalTargets: totalTargets, //등록대기고객
        totalFixingTargets: totalFixingTargets, //보완대상고객
        withdrawCount: currentCms.withdrawCount, //출금청구 결과 미처리건수
        depositCount: currentCms.depositCount, //입금청구 결과 미처리건수
      };

      setCheckList(newCheckListObj);
    };

    if (isFirstLoaded) {
      fnFetchCheckList();
      setIsFirstLoaded(false);
    }
  }, [isFirstLoaded, checkList]);

  const handleMovePage = (e, url, tabIndex) => {
    e.preventDefault();
    let state = { tabIndex: tabIndex };
    history.push(url, state);
  };

  return (
    <div className="checklist">
      <div className="icon-header">
        <h3 className="icon" style={{ backgroundImage: `url(${icon1})` }}>
          확인사항
        </h3>
      </div>
      <div className="content-box check">
        <h4>수납고객 출금계좌등록</h4>
        <Link className="text-unit line" to="" onClick={(e) => handleMovePage(e, "/customer/registerAccountByInstitute", 3)}>
          <div className="text-unit_text">직접 접수분</div>
          <div>
            <span className="text-unit_num">{checkList.instituteRegistrationCount}</span>
            <span className="text-unit_text"> 건</span>
          </div>
        </Link>
        <Link className="text-unit" to="" onClick={(e) => handleMovePage(e, "/customer/registrations", 0)}>
          <div className="text-unit_text">금융기관 접수분</div>
          <div>
            <span className="text-unit_num">{checkList.financialInstituteRegistrationCount}</span>
            <span className="text-unit_text"> 건</span>
          </div>
        </Link>
        <div className="text-unit">
          <span className="text-unit2">
            - 등록대기 고객 : {checkList.totalTargets}명, 보완대상 고객 : {checkList.totalFixingTargets}명
          </span>
        </div>
        <h4>출금·입금 청구결과</h4>
        <Link className="text-unit line" to="" onClick={(e) => handleMovePage(e, "/receipt/cmsReceipt", 1)}>
          <div className="text-unit_text">출금 청구결과</div>
          <div>
            <span className="text-unit_num">{checkList.withdrawCount}</span>
            <span className="text-unit_text"> 건</span>
          </div>
        </Link>
        <Link className="text-unit" to="" onClick={(e) => handleMovePage(e, "/payment/cmsPayment", 1)}>
          <div className="text-unit_text">입금 청구결과</div>
          <div>
            <span className="text-unit_num">{checkList.depositCount}</span>
            <span className="text-unit_text"> 건</span>
          </div>
        </Link>
      </div>
    </div>
  );
}
