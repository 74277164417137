import { Button, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import logo from "../../../styles/images/logo.png";
import "../../../styles/institute.css";
import * as CM from "../../common/Common";

/*
 * @desc    메인 컴포넌트 선언
 */
const ChargeCmsBillForm = ({ open, setOpen, target, store, loginStore }) => {
  const billingoneObject = {
    subject: "CMS",
    title: "CMS 이용요금 청구서",
    instName: loginStore.loginDto.instName,
    staffName: loginStore.loginDto.staffName,
    lastPaidDate: "",
  };

  const [value, setValue] = useState({});
  const [financialInstitutes, setFinancialInstitutes] = React.useState([]); //금융기관 자동완성 기능을 위한 state
  const [useInstitute, setUseInstitute] = useState({
    intgtLoginId: "",
    billingAccount: {
      intgtLoginId: "",
      accountNo: "",
      accountBranchName: "",
      accountBranchCode: "",
      depositorName: "",
      depositorIdentificationNo: "",
      accountApplyDate: null,
      virtualAccountNo: "",
    },
    cmsBillingAccount: {
      intgtLoginId: "",
      accountNo: "",
      accountBranchName: "",
      accountBranchCode: "",
      depositorName: "",
      depositorIdentificationNo: "",
      accountApplyDate: null,
      virtualAccountNo: "",
    },
  });
  // 모달이 오픈될 때 value에 선택한 row의 object 값 넣기
  useEffect(() => {
    if (open) {
      const url = `/api/institute/billing/cms?useY4mm=${target.useY4mm}`;
      CM.cfnAxios(url, "get", "", (status, data) => {
        setValue(data);

        /*
         * @desc    금융기관 목록 조회 Request
         */
        const fnFetchFinancialInstitutes = async () => {
          const url = "/api/gadget/financial-institutes";
          await CM.cfnAxios(url, "get", "", fnSetFinancialInstitutes);
        };

        /*
         * @desc    금융기관 목록 조회 Request Callback
         */
        const fnSetFinancialInstitutes = (objStatus, objData) => {
          if (objStatus.status !== 200) {
            CM.cfnAlert(objStatus.statusText);
            // window.alert(objStatus.statusText);
            return false;
          }

          setFinancialInstitutes(objData);
        };

        fnFetchFinancialInstitutes();
        const fnSetBusinessInfo = (objStatus, data) => {
          store.setBusinessInfo(data); // store 값 설정
          setUseInstitute(data);
        };

        const storeBusinessInfo = CM.cfnCopyObject(toJS(store.businessInfo));
        if (CM.cfnIsEmpty(storeBusinessInfo)) {
          store.getBusinessInfo(fnSetBusinessInfo);
        } else {
          fnSetBusinessInfo(200, storeBusinessInfo);
        }
      });
    } else {
      setValue({});
    }
  }, [open, target, store]);

  /*
   * @desc    모달창 닫기 이벤트 핸들러
   */
  const handleClose = () => {
    setOpen(false);
  };

  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();

  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          width: "1000px",
        }}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <div className="table-top-area d-flex">
            <ReactToPrint trigger={() => <button className="btn-l print-button">청구서 인쇄</button>} content={() => printRef.current} copyStyles={true} />
          </div>
          <div className="print-outer-box-bill" ref={printRef}>
            <div className="charge-head">
              <img src={logo} alt="logo" />
              <div className="title" style={{ width: "calc(153mm - 180px)" }}>
                {billingoneObject.title}
              </div>
            </div>
            <div className="charge-summary">
              {billingoneObject.instName} 고객님께서 {CM.cfnDateFormat(value.dueDate, "yyyyMMdd")} 기준으로 납부하실 금액은 {CM.cfnAddComma(value.totalAskingAmount)}원입니다.
            </div>
            <div className="charge-body">
              <h4>1. 청구내역</h4>
              <div className="charge01">
                <div className="charge0101">
                  <Table>
                    {CM.cfnCompColGroup(["25%", "35%", "auto"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th" colSpan={3}>
                          {CM.cfnDateFormat(value.useY4mm, "yyyyMM")} 청구내역
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={3}>
                          CMS 수수료
                        </TableCell>
                        <TableCell align="center" className="td">
                          공급가액(1)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.suppliedAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          100원 미만
                          <br />
                          절사 금액(2)
                        </TableCell>
                        <TableCell align="center" className="td">
                          0원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          공급가액(3=1-2)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.suppliedAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          가입비(4)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.entryAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          부가세(5=(3+4)&times;10%)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.taxAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          당월 요금 합계(6=3+4+5)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.thisMonthAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          전월미납요금(7)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.previouslyUnpaidAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          금융기관수수료(8)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.realtimeBankFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          총 청구금액(9=6+7+8)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnAddComma(value.totalAskingAmount)}원
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="charge0102">
                  <Table>
                    {CM.cfnCompColGroup(["25%", "35%", "auto"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th" colSpan={3}>
                          이용기관정보
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          이용기관명
                        </TableCell>
                        <TableCell align="center" className="td">
                          {billingoneObject.instName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          이용기관ID
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.intgtLoginId}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          이용월
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnDateFormat(value.useY4mm, "yyyyMM")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          납기일
                        </TableCell>
                        <TableCell align="center" className="td">
                          {CM.cfnDateFormat(value.dueDate, "yyyyMMdd")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={3}>
                          이용요금
                          <br />
                          납부계좌
                        </TableCell>
                        <TableCell align="center" className="td">
                          금융기관
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.cmsBillingAccount && useInstitute.cmsBillingAccount.accountBranchCode
                            ? CM.cfnMatchBankName(useInstitute.cmsBillingAccount.accountBranchCode.substr(0, 3), financialInstitutes)
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          계좌번호
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.cmsBillingAccount && useInstitute.cmsBillingAccount.accountNo
                            ? useInstitute.cmsBillingAccount.accountNo.length > 3
                              ? useInstitute.cmsBillingAccount.accountNo.substr(0, useInstitute.cmsBillingAccount.accountNo.length - 3) + "***"
                              : useInstitute.cmsBillingAccount.accountNo
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          예금주
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.billingAccount && useInstitute.billingAccount.depositorName ? useInstitute.billingAccount.depositorName : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          무통장 입금계좌
                          <br />
                          (가상계좌)
                        </TableCell>
                        <TableCell align="center" className="td">
                          {useInstitute.virtualAccountNo ? "[SC은행]" : "-"}
                          <br />
                          {useInstitute.virtualAccountNo}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="charge02">
                <h4>2. 청구상세내역</h4>
                <div className="charge0201">
                  <h5>가. CMS 이용 수수료</h5>
                  <Table>
                    {CM.cfnCompColGroup(["25%", "auto", "25%", "25%"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th">
                          구분
                        </TableCell>
                        <TableCell align="center" className="th">
                          내역
                        </TableCell>
                        <TableCell align="center" className="th">
                          건수
                        </TableCell>
                        <TableCell align="center" className="th">
                          금액
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          CMS 가입비
                        </TableCell>
                        <TableCell align="right" className="td">
                          -
                        </TableCell>
                        <TableCell align="right" className="td">
                          -
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" rowSpan={2}>
                          CMS 이용수수료
                        </TableCell>
                        <TableCell align="center" className="td">
                          CMS 대량자금이체
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.cmsCount)}건
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.cmsAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td">
                          실시간 계좌조회, 등록
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.realtimeServiceCount)}건
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.realtimeServiceAmount)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={3}>
                          CMS 이용 수수료 합계
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma((value.cmsAmount || 0) + (value.realtimeServiceAmount || 0))}원
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="charge0202">
                  <h5>나. 금융기관 수수료</h5>
                  <Table>
                    {CM.cfnCompColGroup(["25%", "25%", "25%", "25%"])}
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" className="th">
                          구분
                        </TableCell>
                        <TableCell align="center" className="th">
                          내역
                        </TableCell>
                        <TableCell align="center" className="th">
                          건수
                        </TableCell>
                        <TableCell align="center" className="th">
                          금액
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={2}>
                          금융기관 수수료 (수납대행)
                        </TableCell>
                        <TableCell align="center" className="td">
                          0 건
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.realtimeBankFee)}원
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="td" colSpan={3}>
                          금융기관 수수료 합계
                        </TableCell>
                        <TableCell align="right" className="td">
                          {CM.cfnAddComma(value.realtimeBankFee)}원
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
  props: props,
}))(observer(ChargeCmsBillForm));
