import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import remote from "../../styles/images/remote.png";
// 렌더를 처리하는 메인 컴포넌트
const Side = (props) => {
  const location = useLocation();
  const [pathName, setPathName] = React.useState("");
  const [selected, setSeleceted] = useState("");

  useEffect(() => {
    const pathNameSplit = location.pathname.split("/");
    const mainPath = pathNameSplit[1] || "";
    const subPath = pathNameSplit[2] || "";
    setPathName(mainPath);
    setSeleceted(subPath);
  }, [location.pathname]);

  const handleListItemClick = (event, name) => {
    setSeleceted(name);
  };

  return (
    <Fragment>
      <InstituteSide pathName={pathName} selected={selected} handleListItemClick={handleListItemClick} />
      <CustomerSide pathName={pathName} selected={selected} handleListItemClick={handleListItemClick} />
      <ReceiptSide pathName={pathName} selected={selected} handleListItemClick={handleListItemClick} />
      <PaymentSide pathName={pathName} selected={selected} handleListItemClick={handleListItemClick} />
      <ExtraServiceSide pathName={pathName} selected={selected} handleListItemClick={handleListItemClick} loginStore={props.loginStore} />
      <SupportSide pathName={pathName} selected={selected} handleListItemClick={handleListItemClick} />
    </Fragment>
  );
};

const CallCenter = () => {
  return (
    <div className="icon-area" style={{ width: "201px", margin: "20px auto", position: "absolute" }}>
      <a href="https://help.kftc.or.kr" target="_blank" rel="noopener noreferrer">
        <img src={remote} alt="remote" />
        원격지원서비스
      </a>
    </div>
  );
};

// 기관·업무 정보
const InstituteSide = (props) => {
  const [tab1, setTab1] = useState(true);
  // const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(true);

  // const handleTab1Change = () => setTab1(value => !value);
  // const handleTab2Change = () => setTab2(value => !value);
  // const handleTab3Change = () => setTab3(value => !value);

  useEffect(() => {
    if (props.pathName !== "institute") {
      setTab1(true);
      // setTab2(false);
      setTab3(true);
    }
  }, [props.pathName]);

  return (
    <div hidden={props.pathName === "institute" ? false : true}>
      <List id="lnb" component="nav" aria-labelledby="nested-list-subheader" className={"nav"} data-testid="sideTab-list">
        <ListItem button>
          <ListItemText className="sub-title" primary="기본 정보" />
          {/*tab1 ? <ExpandLess /> : <ExpandMore />*/}
        </ListItem>
        <Collapse in={tab1} timeout="auto" unmountOnExit>
          <List component="div" className="ssub-title" disablePadding>
            <Link to="/institute/institute">
              <ListItem button selected={props.selected === "institute"} onClick={(event) => props.handleListItemClick(event, "institute")} className="nested">
                기관 정보
              </ListItem>
            </Link>
            <Link to="/institute/business">
              <ListItem button selected={props.selected === "business"} onClick={(event) => props.handleListItemClick(event, "business")} className="nested">
                업무 정보
              </ListItem>
            </Link>
            <Link to="/institute/staff">
              <ListItem button selected={props.selected === "staff"} onClick={(event) => props.handleListItemClick(event, "staff")} className="nested">
                담당자 정보
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <Link to="/institute/customerCriteria">
          <ListItem button selected={props.selected === "customerCriteria"} onClick={(event) => props.handleListItemClick(event, "customerCriteria")}>
            <ListItemText className="sub-title" primary="고객관리 기준" />
          </ListItem>
        </Link>
        <Link to="/institute/extraService">
          <ListItem button selected={props.selected === "extraService"} onClick={(event) => props.handleListItemClick(event, "extraService")}>
            <ListItemText className="sub-title" primary="부가서비스 정보" />
          </ListItem>
        </Link>
        <ListItem button>
          <ListItemText className="sub-title" primary="정보 변경 이력" />
          {/*tab3 ? <ExpandLess /> : <ExpandMore />*/}
        </ListItem>
        <Collapse in={tab3} timeout="auto" unmountOnExit>
          <List component="div" className="ssub-title" disablePadding>
            <Link to="/institute/changeHistory">
              <ListItem button className="nested" selected={props.selected === "changeHistory"} onClick={(event) => props.handleListItemClick(event, "changeHistory")}>
                변경 요청 이력
              </ListItem>
            </Link>
            <Link to="/institute/delayHistory">
              <ListItem button className="nested" selected={props.selected === "delayHistory"} onClick={(event) => props.handleListItemClick(event, "delayHistory")}>
                연체료 적용 이력
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <Link to="/institute/charge">
          <ListItem button selected={props.selected === "charge"} onClick={(event) => props.handleListItemClick(event, "charge")}>
            <ListItemText className="sub-title" primary="이용요금" />
          </ListItem>
        </Link>
        <CallCenter />
      </List>
    </div>
  );
};

// 고객정보관리
const CustomerSide = (props) => {
  // const [tab1, setTab1] = useState(false);
  // const [tab2, setTab2] = useState(false);
  // const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(true);

  // const handleTab1Change = () => setTab1(value => !value);
  // const handleTab2Change = () => setTab2(value => !value);
  // const handleTab3Change = () => setTab3(value => !value);
  // const handleTab4Change = () => setTab4(value => !value);

  useEffect(() => {
    if (props.pathName !== "customer") {
      // setTab1(false);
      // setTab2(false);
      // setTab3(false);
      setTab4(true);
    }
  }, [props.pathName]);

  return (
    <div hidden={props.pathName === "customer" ? false : true}>
      <List component="nav" aria-labelledby="nested-list-subheader" className="nav">
        <Link to="/customer/customerInformationList">
          <ListItem button selected={props.selected === "customerInformationList"} onClick={(event) => props.handleListItemClick(event, "customerInformationList")}>
            <ListItemText className="sub-title" primary="고객 기본정보" />
          </ListItem>
        </Link>
        <Link to="/customer/receiptCustomerInformationList">
          <ListItem button selected={props.selected === "receiptCustomerInformationList"} onClick={(event) => props.handleListItemClick(event, "receiptCustomerInformationList")}>
            <ListItemText className="sub-title" primary="수납 고객정보" />
          </ListItem>
        </Link>
        <Link to="/customer/paymentInformation">
          <ListItem button selected={props.selected === "paymentInformation"} onClick={(event) => props.handleListItemClick(event, "paymentInformation")}>
            <ListItemText className="sub-title" primary="지급 고객정보" />
          </ListItem>
        </Link>
        <ListItem className="sub-title" button>
          <ListItemText primary="출금 계좌 등록&middot;해지" />
          {/*tab4 ? <ExpandLess /> : <ExpandMore />*/}
        </ListItem>
        <Collapse in={tab4} timeout="auto" unmountOnExit>
          <List component="div" className="ssub-title" disablePadding>
            <Link to="/customer/registerAccountByInstitute">
              <ListItem button className="nested" selected={props.selected === "registerAccountByInstitute"} onClick={(event) => props.handleListItemClick(event, "registerAccountByInstitute")}>
                직접 접수분
              </ListItem>
            </Link>
            <Link to="/customer/registrations">
              <ListItem button className="nested" selected={props.selected === "registrations"} onClick={(event) => props.handleListItemClick(event, "registrations")}>
                금융기관 접수분
              </ListItem>
            </Link>
            <Link to="/customer/accountCheckRegister">
              <ListItem button className="nested" selected={props.selected === "accountCheckRegister"} onClick={(event) => props.handleListItemClick(event, "accountCheckRegister")}>
                실시간 서비스
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <CallCenter />
      </List>
    </div>
  );
};

// 수납관리
const ReceiptSide = (props) => {
  // const [tab1, setTab1] = useState(false);
  // const [tab2, setTab2] = useState(false);
  // const [tab3, setTab3] = useState(false);

  // const handleTab1Change = () => setTab1(value => !value);
  // const handleTab2Change = () => setTab2(value => !value);
  // const handleTab3Change = () => setTab3(value => !value);

  // useEffect(() => {
  //   if (props.pathName !== "receipt") {
  //     setTab1(false);
  //     setTab2(false);
  //     setTab3(false);
  //   }
  // }, [props.pathName]);

  return (
    <div hidden={props.pathName === "receipt" ? false : true}>
      <List component="nav" aria-labelledby="nested-list-subheader" className="nav">
        <Link to="/receipt/cmsReceipt">
          <ListItem button selected={props.selected === "cmsReceipt"} onClick={(event) => props.handleListItemClick(event, "cmsReceipt")}>
            <ListItemText className="sub-title" primary="CMS 출금관리" />
          </ListItem>
        </Link>
        <Link to="/receipt/receiptSource">
          <ListItem button selected={props.selected === "receiptSource"} onClick={(event) => props.handleListItemClick(event, "receiptSource")}>
            <ListItemText className="sub-title" primary="수납 내역" />
          </ListItem>
        </Link>
        <Link to="/receipt/unpaid">
          <ListItem button selected={props.selected === "unpaid"} onClick={(event) => props.handleListItemClick(event, "unpaid")}>
            <ListItemText className="sub-title" primary="미납관리" />
          </ListItem>
        </Link>
        <Link to="/receipt/statistics/statisticsChart">
          <ListItem button selected={props.selected === "statisticsChart"} onClick={(event) => props.handleListItemClick(event, "statisticsChart")}>
            <ListItemText className="sub-title" primary="수납 통계" />
          </ListItem>
        </Link>
        <CallCenter />
      </List>
    </div>
  );
};

// 지급관리
const PaymentSide = (props) => {
  // const [tab1, setTab1] = useState(false);
  // const [tab2, setTab2] = useState(false);
  // const [tab3, setTab3] = useState(false);

  // const handleTab1Change = () => setTab1(value => !value);
  // const handleTab2Change = () => setTab2(value => !value);
  // const handleTab3Change = () => setTab3(value => !value);

  // useEffect(() => {
  //   if (props.pathName !== "payment") {
  //     setTab1(false);
  //     setTab2(false);
  //     setTab3(false);
  //   }
  // }, [props.pathName]);

  return (
    <div hidden={props.pathName === "payment" ? false : true}>
      <List component="nav" aria-labelledby="nested-list-subheader" className="nav">
        <Link to="/payment/cmsPayment">
          <ListItem button selected={props.selected === "cmsPayment"} onClick={(event) => props.handleListItemClick(event, "cmsPayment")}>
            <ListItemText className="sub-title" primary="CMS 입금관리" />
          </ListItem>
        </Link>
        <Link to="/payment/paymentSource">
          <ListItem button selected={props.selected === "paymentSource"} onClick={(event) => props.handleListItemClick(event, "paymentSource")}>
            <ListItemText className="sub-title" primary="지급 내역" />
          </ListItem>
        </Link>
        <Link to="/payment/statistics/statisticsChart">
          <ListItem button selected={props.selected === "statisticsChart"} onClick={(event) => props.handleListItemClick(event, "statisticsChart")}>
            <ListItemText className="sub-title" primary="지급 통계" />
          </ListItem>
        </Link>
        <CallCenter />
      </List>
    </div>
  );
};

// 부가서비스
const ExtraServiceSide = (props) => {
  // const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(true);
  const [tab3, setTab3] = useState(true);
  // const [tab4, setTab4] = useState(false);
  // const [tab5, setTab5] = useState(false);
  // const [tab6, setTab6] = useState(false);

  // const handleTab1Change = () => setTab1(value => !value);
  // const handleTab2Change = () => setTab2(value => !value);
  // const handleTab3Change = () => setTab3(value => !value);
  // const handleTab4Change = () => setTab4(value => !value);
  // const handleTab5Change = () => setTab5(value => !value);
  // const handleTab6Change = () => setTab6(value => !value);

  useEffect(() => {
    if (props.pathName !== "extraService") {
      // setTab1(false);
      setTab2(true);
      setTab3(true);
      // setTab4(false);
      // setTab5(false);
      // setTab6(false);
    }
  }, [props.pathName]);
  return (
    <div hidden={props.pathName === "extraService" ? false : true}>
      <List component="nav" aria-labelledby="nested-list-subheader" className="nav">
        <Link to="/extraService/dataBackup">
          <ListItem button selected={props.selected === "dataBackup"} onClick={(event) => props.handleListItemClick(event, "dataBackup")}>
            <ListItemText className="sub-title" primary="데이터 백업" />
          </ListItem>
        </Link>
        <ListItem button>
          <ListItemText className="sub-title" primary="영수증 발행" />
          {/*tab2 ? <ExpandLess /> : <ExpandMore />*/}
        </ListItem>
        <Collapse in={tab2} timeout="auto" unmountOnExit>
          <List component="div" className="ssub-title" disablePadding>
            <Link to="/extraService/Cashbill">
              <ListItem button className="nested" selected={props.selected === "Cashbill"} onClick={(event) => props.handleListItemClick(event, "Cashbill")}>
                현금영수증
              </ListItem>
            </Link>
            <Link to="/extraService/donationBill">
              <ListItem button className="nested" selected={props.selected === "donationBill"} onClick={(event) => props.handleListItemClick(event, "donationBill")}>
                기부금영수증
              </ListItem>
            </Link>
            <Link to="/extraService/paperBill">
              <ListItem button className="nested" selected={props.selected === "paperBill"} onClick={(event) => props.handleListItemClick(event, "paperBill")}>
                종이영수증
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <ListItem button>
          <ListItemText className="sub-title" primary="계산서 발행" />
          {/*tab3 ? <ExpandLess /> : <ExpandMore />*/}
        </ListItem>
        <Collapse in={tab3} timeout="auto" unmountOnExit>
          <List component="div" className="ssub-title" disablePadding>
            <Link to="/extraService/electronicTaxbills">
              <ListItem button className="nested" selected={props.selected === "electronicTaxbills"} onClick={(event) => props.handleListItemClick(event, "electronicTaxbills")}>
                전자세금계산서
              </ListItem>
            </Link>
            <Link to="/extraService/paperTaxBill">
              <ListItem button className="nested" selected={props.selected === "paperTaxBill"} onClick={(event) => props.handleListItemClick(event, "paperTaxBill")}>
                종이세금계산서
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <Link to="/extraService/sendSms">
          <ListItem button selected={props.selected === "sendSms"} onClick={(event) => props.handleListItemClick(event, "sendSms")}>
            <ListItemText className="sub-title" primary="SMS 발송" />
          </ListItem>
        </Link>
        <Link to="/extraService/labelPrint">
          <ListItem button selected={props.selected === "labelPrint"} onClick={(event) => props.handleListItemClick(event, "labelPrint")}>
            <ListItemText className="sub-title" primary="우편물 라벨지 인쇄" />
          </ListItem>
        </Link>
        <Link to="/extraService/arsHistory">
          <ListItem button selected={props.selected === "arsHistory"} onClick={(event) => props.handleListItemClick(event, "arsHistory")}>
            <ListItemText className="sub-title" primary="ARS 서비스 이용내역" />
          </ListItem>
        </Link>
        {props.loginStore.getEvidence() === "일부제출" ? (
          <Link to="/extraService/postCheck">
            <ListItem button selected={props.selected === "postCheck"} onClick={(event) => props.handleListItemClick(event, "postCheck")}>
              <ListItemText className="sub-title" primary="동의자료 사후점검" />
            </ListItem>
          </Link>
        ) : (
          ""
        )}
        <CallCenter />
      </List>
    </div>
  );
};

// 서비스지원
const SupportSide = (props) => {
  return (
    <div hidden={props.pathName === "support" ? false : true}>
      <List component="nav" aria-labelledby="nested-list-subheader" className="nav">
        <Link to="/support/notice">
          <ListItem button selected={props.selected === "notice"} onClick={(event) => props.handleListItemClick(event, "notice")}>
            <ListItemText className="sub-title" primary="공지사항" />
          </ListItem>
        </Link>
        <Link to="/support/pds">
          <ListItem button selected={props.selected === "pds"} onClick={(event) => props.handleListItemClick(event, "pds")}>
            <ListItemText className="sub-title" primary="자료실" />
          </ListItem>
        </Link>
        <Link to="/support/faq">
          <ListItem button selected={props.selected === "faq"} onClick={(event) => props.handleListItemClick(event, "faq")}>
            <ListItemText className="sub-title" primary="FAQ" />
          </ListItem>
        </Link>
        <CallCenter />
      </List>
    </div>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  loginStore: rootStore.loginStore,
}))(observer(Side));
