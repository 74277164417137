import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import * as CM from "../../common/Common";

// 부가서비스 > 현금영수증 > 현금영수증 발행내역 > 월별 요약 form
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabIssuedMonthlySummary = (props) => {
  return (
    <div>
      <h4>&nbsp;합계금액 : {CM.cfnAddComma((props.list[props.list.length - 1] && props.list[props.list.length - 1].issuedAmount) || 0)}원</h4>
      <Table>
        <TableHead className="table-head-line">
          <TableRow>
            {props.dynamicHead
              .filter((element, index, array) => index < array.length / 2)
              .map((element, index) => {
                return (
                  <TableCell key={index} align="center" style={{ whiteSpace: "pre-wrap" }}>
                    {element}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={props.dynamicHead.length / 2} />
          ) : (
            <TableRow hover>
              {props.list
                .filter((element, index) => index < props.dynamicHead.length / 2)
                .map((target, index) => {
                  return (
                    <TableCell align="right" key={index}>
                      {CM.cfnAddComma(target.issuedAmount)}원
                    </TableCell>
                  );
                })}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table>
        <TableHead className="table-head-line">
          <TableRow>
            {props.dynamicHead
              .filter((element, index, array) => index >= array.length / 2)
              .map((element, index) => {
                return (
                  <TableCell key={index} align="center" style={{ whiteSpace: "pre-wrap" }}>
                    {element}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={props.dynamicHead.length / 2} />
          ) : (
            <TableRow hover>
              {props.list
                .filter((element, index) => index >= props.dynamicHead.length / 2 && index < props.dynamicHead.length)
                .map((target, index) => {
                  return (
                    <TableCell align="right" key={index}>
                      {CM.cfnAddComma(target.issuedAmount)}원
                    </TableCell>
                  );
                })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CashbillTabIssuedMonthlySummary;
