import React, { useEffect, useState } from "react";
import EvidenceFileModal from "../../template/EvidenceFileModal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useHistory, useLocation } from "react-router-dom";
import { Radio, RadioGroup, FormControlLabel, FormGroup, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import * as CM from "../../common/Common";
import { SelectForm, MaskedField, BankAutoComplete, TooltipForm } from "../../template/ComponentForm";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

import CapitalsModal from "../customerModal/CapitalsModal";
import { CreateCustomerDivision, CustomerRequiredInputs, CustomerIndividualSelectInput, CustomerBusinessSelectInput, ViewCustomerDivision } from "../customerBasicInformation/CustomerForm";
import CustomerSearch from "../customerBasicInformation/CustomerSearch";

import CustomerData from "../customerBasicInformation/CustomerData";
import { Modal } from "@material-ui/core";
import { Button } from "@material-ui/core";
import StaffInfo from "../../institute/staffInfo/StaffInfo";
import CustMnmtTable from "../../institute/customerCriteria/CustMnmtTable";

const ReceiptInfo = (props) => {
  const {
    capitalOptions,
    dayOptions,
    totalNumberOfPaymentState,
    payPeriodOption,
    startYearOptions,
    endYearOptions,
    flag,
    handleClickModal,
    handleValuesChange,
    value,
    handleCheckChange,
    radioState,
    handleRadioChange,
    fnConvertY4mm,
    fnCreateMonthOption,
    receiptCustomer,
    limitAmountForEachWithdrawal,
  } = props;
  return (
    <div>
      <h4 data-testid="receipt-header">수납정보</h4>
      <div>
        <Table>
          {CM.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">수납형태</TableCell>
              <TableCell className="td">
                {Object.isExtensible(receiptCustomer) ? (
                  "CMS 수납"
                ) : (
                  <FormControl component="fieldset">
                    <RadioGroup name="transactionMethod" value={value.transactionMethod} onChange={handleValuesChange("transactionMethod")} data-testid="unpaid-period" row>
                      <FormControlLabel value="CMS_WITHDRAW" control={<Radio color="primary" />} label="CMS" />
                      <FormControlLabel value="ETC" control={<Radio color="primary" />} label="기타" />
                    </RadioGroup>
                  </FormControl>
                )}
              </TableCell>
              <TableCell className="th">납부자번호</TableCell>
              <TableCell className="td">
                {Object.isExtensible(receiptCustomer) ? (
                  receiptCustomer.payerNo
                ) : (
                  <FormControl component="fieldset">
                    <RadioGroup name="payerNoType" value={radioState.payerNoType} onChange={handleRadioChange("payerNoType")} data-testid="unpaid-period" row>
                      <FormControlLabel disabled={radioState.autoGeneratePayerNoYn ? false : true} value="value1" control={<Radio color="primary" />} label="자동채번" />
                      <FormControlLabel value="value2" control={<Radio color="primary" />} className="delay-radio" />
                      <span className="radio-label-l">직접입력</span>
                      <Input
                        name="payerNo"
                        className="w120"
                        value={value.payerNo}
                        onChange={handleValuesChange("payerNo")}
                        disabled={radioState.payerNoType === "value2" ? false : true}
                        required={radioState.payerNoType === "value2" ? true : false}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">할부구분</TableCell>
              <TableCell className="td">
                <FormControl>
                  <RadioGroup name="installment" value={radioState.installment} onChange={handleRadioChange("installment")} data-testid="unpaid-period" row>
                    <FormControlLabel value="value1" control={<Radio color="primary" />} label="일반" />
                    <FormControlLabel value="value2" control={<Radio color="primary" />} label="할부" />
                  </RadioGroup>
                </FormControl>
              </TableCell>
              <TableCell className="th">자금종류</TableCell>
              <TableCell className="td">
                <Select native value={value.capital.uniqueKey} onChange={handleValuesChange("capitalName", "capital")}>
                  <option value="">선택</option>
                  {capitalOptions.map((row) => {
                    return (
                      <option value={row.value} key={row.key}>
                        {row.label}
                      </option>
                    );
                  })}
                </Select>
                <button className="btn-s" onClick={(event) => handleClickModal("capitalName")}>
                  관리
                </button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">{radioState.installment === "value2" ? "총 할부금액" : "수납금액"}</TableCell>
              <TableCell className="td">
                <FormControl component="fieldset">
                  {radioState.installment === "value2" ? (
                    <Input name="payAmount" value={value.payAmount} onChange={handleValuesChange("payAmount")} disabled={value.payAmountType === "FIXED" ? false : true} />
                  ) : (
                    <RadioGroup name="payAmountType" value={value.payAmountType} onChange={handleValuesChange("payAmountType")} data-testid="unpaid-period" row>
                      <FormControlLabel value="VARIABLE" control={<Radio color="primary" />} label="비정액" disabled={radioState.installment === "value2" ? true : false} />
                      <FormControlLabel value="FIXED" control={<Radio color="primary" />} className="delay-radio" />
                      <span className="radio-label-l">정액</span>
                      <Input
                        name="payAmount"
                        value={value.payAmount}
                        className="w150"
                        onChange={handleValuesChange("payAmount")}
                        disabled={value.payAmountType === "FIXED" ? false : true}
                        required={value.payAmountType === "FIXED" ? true : false}
                        endAdornment="원"
                      />
                    </RadioGroup>
                  )}
                  {value.transactionMethod === "CMS_WITHDRAW" &&
                  limitAmountForEachWithdrawal &&
                  value.payAmountType === "FIXED" &&
                  (radioState.installment !== "value2" ? CM.cfnReplaceSymbol(value.payAmount) : CM.cfnReplaceSymbol(value.payAmount) / (Number(CM.cfnReplaceSymbol(value.totalNumberOfPayment)) || 1)) >
                    limitAmountForEachWithdrawal ? (
                    <span className="inforbox3" style={{ marginBottom: 0 }}>
                      건당 CMS출금한도 {CM.cfnAddComma(limitAmountForEachWithdrawal)}원을 초과합니다.
                    </span>
                  ) : null}
                </FormControl>
              </TableCell>
              <TableCell className="th">수납일</TableCell>
              <TableCell className="td">
                <FormControl component="fieldset">
                  <RadioGroup name="paySpecifiedDay" value={value.paySpecifiedDay} onChange={handleValuesChange("paySpecifiedDay")} data-testid="unpaid-period" row>
                    <FormControlLabel checked={value.paySpecifiedDay !== "99"} control={<Radio color="primary" />} className="delay-radio" />
                    <Select native value={value.paySpecifiedDay} onChange={handleValuesChange("paySpecifiedDay")} disabled={value.paySpecifiedDay === "99"} required={value.paySpecifiedDay !== "99"}>
                      {dayOptions.map((row, idx) => {
                        return (
                          <option value={row.value} key={idx}>
                            {row.label}
                          </option>
                        );
                      })}
                    </Select>
                    <FormControlLabel checked={value.paySpecifiedDay === "99"} value="99" control={<Radio color="primary" />} label="말일" />
                    <TooltipForm contents="매월 마지막 영업일" spanStyle={{ lineHeight: "32px" }} />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">총 수납횟수</TableCell>
              <TableCell className="td">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" name="position" row>
                    <FormControlLabel
                      // value={state.checkedB}
                      checked={totalNumberOfPaymentState}
                      control={<Checkbox onChange={handleCheckChange("totalNumberOfPayment")} color="primary" />}
                      label="기한 없음"
                    />
                  </FormGroup>
                </FormControl>
                <Input
                  name="totalNumberOfPayment"
                  className="w80"
                  value={value.totalNumberOfPayment}
                  onChange={handleValuesChange("totalNumberOfPayment")}
                  required={true}
                  disabled={totalNumberOfPaymentState}
                />
                <span className="label-r">회</span>
              </TableCell>
              <TableCell className="th">수납주기</TableCell>
              <TableCell className="td">
                <FormControl component="fieldset">
                  <RadioGroup name="payPeriod" value={radioState.payPeriod} onChange={handleRadioChange("payPeriod")} data-testid="unpaid-period" row>
                    <FormControlLabel
                      // checked={value.payPeriod === "" ? true : false}
                      value="value1"
                      control={<Radio color="primary" />}
                      label="비정기"
                      disabled={radioState.installment === "value2" ? true : false}
                    />
                    <FormControlLabel
                      // checked={value.payPeriod !== "" ? true : false}
                      value="value2"
                      control={<Radio color="primary" />}
                      className="delay-radio"
                    />
                    <span className="radio-label-l">정기</span>
                    <SelectForm
                      value={value.payPeriod}
                      handleChange={handleValuesChange("payPeriod")}
                      name="payPeriod"
                      arrayOption={payPeriodOption}
                      optionValue="value"
                      optionLabel="label"
                      disabled={radioState.payPeriod === "value2" ? false : true}
                      required={radioState.payPeriod === "value2" ? true : false}
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="th">수납시작월</TableCell>
              <TableCell className="td">
                <SelectForm
                  value={fnConvertY4mm(value.payStartY4mm, "year")}
                  handleChange={handleValuesChange("payStartY4mm", "payStartY4")}
                  name="payStartY4"
                  arrayOption={startYearOptions}
                  optionValue="value"
                  optionLabel="label"
                />
                <SelectForm
                  value={fnConvertY4mm(value.payStartY4mm, "mm")}
                  handleChange={handleValuesChange("payStartY4mm", "payStartMm")}
                  name="payStartMm"
                  arrayOption={fnCreateMonthOption("startMM")}
                  optionValue="value"
                  optionLabel="label"
                />
              </TableCell>
              <TableCell className="th">수납종료월</TableCell>
              <TableCell className="td">
                <SelectForm
                  value={fnConvertY4mm(value.payEndY4mm, "year")}
                  handleChange={handleValuesChange("payEndY4mm", "payEndY4")}
                  name="payEndY4"
                  arrayOption={endYearOptions}
                  optionValue="value"
                  optionLabel="label"
                  disabled={flag ? true : false}
                  required={true}
                />
                <SelectForm
                  value={value.payEndY4mm && value.payEndY4mm.startsWith("9999") ? "" : fnConvertY4mm(value.payEndY4mm, "mm")}
                  handleChange={handleValuesChange("payEndY4mm", "payEndMm")}
                  name="payEndMm"
                  arrayOption={fnCreateMonthOption("endMM")}
                  optionValue="value"
                  optionLabel="label"
                  disabled={flag ? true : false}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="inforbox2">※ 납부자번호, 수납시작월, 할부여부는 등록 후 수정이 불가능합니다.</div>
      </div>
    </div>
  );
};
/*
 * @desc  고객 계좌 정보/ 자동납부 동의자료 component
 */
const AccountInfo = (props) => {
  const {
    withdrawCriteriaOptions,
    handleRadioChange,
    transactionMethod,
    value,
    handleValuesChange,
    financialInstitutes,
    searchValue,
    setSearchValue,
    setIsBankSelected,
    radioState,
    bankbookPostfixTypeOptions,
    handleCheckChange,
    evidenceFile,
    evidenceSubmissionType,
    handleClickModal,
    handleDeleteFile,
    readOnlyFlag,
    smsToCustomerSetting,
  } = props;

  /*
  @desc 조건에 따라 자동납부 동의자료 cell render
  */
  const fnRenderWithdrawAgreementCell = () => {
    switch (evidenceSubmissionType) {
      case "제출":
        return (
          <TableRow>
            <TableCell className="th">
              자동납부 동의자료
              <TooltipForm
                contents="수납정보를 금융기관에 등록하기 위해서는 납부자로부터 받은 자동납부 동의자료(서면, 녹취, ARS 등) 파일을 고객님의 PC에 저장한 후 파일첨부를 눌러 첨부하시기 바랍니다. 
            <br/>※ 납부자로부터 자동납부 동의자료를 받지 못한 경우 등록 후 ARS 동의자료 대행을 활용하시기 바랍니다."
              />
            </TableCell>
            <TableCell className="td" colSpan="3">
              {evidenceFile.file === null ? (
                <div className="btn-s" onClick={() => handleClickModal("file")}>
                  동의자료 첨부
                </div>
              ) : (
                <React.Fragment>
                  <div className="btn-s" onClick={() => handleClickModal("file")}>
                    동의자료 확인
                  </div>
                  <div className="btn-s" onClick={handleDeleteFile}>
                    동의자료 삭제
                  </div>
                </React.Fragment>
              )}
            </TableCell>
          </TableRow>
        );
      case "일부제출":
        return (
          <TableRow>
            <TableCell className="th">자동납부 동의자료</TableCell>
            <TableCell className="td" colSpan="3">
              <Select native value={Object.isExtensible(value.account) ? value.account.evidenceFileType : ""} onChange={handleValuesChange("evidenceFileType", "account")}>
                {CM.cfnGetEvidenceFileTypes().map((row, idx) => {
                  return (
                    <option value={row.evidenceFileType} key={idx}>
                      {row.evidenceFileTypeName}
                    </option>
                  );
                })}
              </Select>
            </TableCell>
          </TableRow>
        );
      default:
        break;
    }
  };

  if (transactionMethod === "CMS_WITHDRAW") {
    return (
      <div>
        <h4>이체 계좌정보</h4>
        <Table>
          {CM.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">금융기관</TableCell>
              <TableCell className="td">
                <BankAutoComplete list={financialInstitutes} state={searchValue} setState={setSearchValue} setFlag={setIsBankSelected} customProps={{ readOnly: readOnlyFlag }} className="w100p" />
              </TableCell>
              <TableCell className="th">계좌번호</TableCell>
              <TableCell className="td" colSpan="2">
                <Input
                  className="w100p"
                  value={value.account.accountNo}
                  inputProps={{
                    "aria-label": "description",
                  }}
                  required={searchValue.financialInstituteCode !== "" || searchValue.depositorIdentification !== ""}
                  onChange={handleValuesChange("accountNo", "account")}
                  readOnly={readOnlyFlag}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">출금형태</TableCell>
              <TableCell className="td">
                <SelectForm
                  value={value.withdrawCriteria}
                  handleChange={handleValuesChange("withdrawCriteria")}
                  name="withdrawCriteria"
                  arrayOption={withdrawCriteriaOptions}
                  optionValue="value"
                  optionLabel="label"
                  required={true}
                  style={{ width: "98%" }}
                />
              </TableCell>
              <TableCell className="th">예금주</TableCell>
              <TableCell className="td">
                <Input
                  className="w50p"
                  value={value.account.depositorName}
                  onChange={handleValuesChange("depositorName", "account")}
                  inputProps={{
                    "aria-label": "description",
                  }}
                  required={searchValue.accountNo !== "" || searchValue.financialInstituteCode !== "" || searchValue.depositorIdentification !== ""}
                  placeholder="선택"
                />
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" name="position" row>
                    <FormControlLabel
                      // value={state.checkedB}
                      control={<Checkbox onChange={handleCheckChange("payer")} color="primary" />}
                      label="고객명과 동일"
                    />
                  </FormGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">
                예금주 생년월일
                <br />
                (사업자번호)
                <TooltipForm contents="출금계좌 등록시나 현금영수증 자동발행시 예금주 사업자번호로 들어간 경우 사용(지출증빙용)됩니다." spanStyle={{ lineHeight: "32px" }} />
              </TableCell>
              <TableCell className="td">
                <Input
                  className="w100p"
                  value={value.account.depositorIdentificationNo}
                  onChange={handleValuesChange("depositorIdentificationNo", "account")}
                  required={searchValue.accountNo !== "" || searchValue.financialInstituteCode !== ""}
                  inputComponent={MaskedField}
                  inputProps={{
                    mask: "identificationNo",
                    "aria-label": "description",
                    readOnly: readOnlyFlag,
                  }}
                />
              </TableCell>
              <TableCell className="th">
                예금주 연락처
                <TooltipForm contents="현금영수증 자동발행시 예금주 생년월일로 들어간 경우 사용(소득공제용)됩니다." spanStyle={{ lineHeight: "32px" }} />
              </TableCell>
              <TableCell className="td">
                <FormControl>
                  <Input
                    className="w100p"
                    value={value.account.depositorTelephone}
                    placeholder={'상세 내용은 "좌측의 물음표 그림" 위에 마우스를 올려보세요'}
                    style={{
                      width: `150px`,
                      ...(value.account.depositorTelephone === "" && { minWidth: "400px" }),
                    }}
                    onChange={handleValuesChange("depositorTelephone", "account")}
                    required={searchValue.accountNo !== "" || searchValue.financialInstituteCode !== "" || searchValue.depositorIdentification !== ""}
                    inputComponent={MaskedField}
                    inputProps={{
                      mask: "mobile",
                    }}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">통장기재내용</TableCell>
              <TableCell className="td" colSpan="3">
                <label>기본</label>
                <Input
                  className="w130"
                  value={value.bankbookContents}
                  onChange={handleValuesChange("bankbookContents")}
                  inputProps={{
                    "aria-label": "description",
                  }}
                  required={true}
                  readOnly={true}
                />
                <label>추가</label>
                <SelectForm
                  value={value.bankbookPostfixType}
                  handleChange={handleValuesChange("bankbookPostfixType")}
                  name="bankbookPostfixType"
                  arrayOption={bankbookPostfixTypeOptions}
                  optionValue="value"
                  optionLabel="label"
                />
                <Input
                  disabled={value.bankbookPostfixType === "MANUAL" ? false : true}
                  className="w150"
                  value={value.bankbookPostfix}
                  required={value.bankbookPostfixType === "MANUAL" ? false : true}
                  onChange={handleValuesChange("bankbookPostfix")}
                  inputProps={{
                    "aria-label": "description",
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">
                출금정보 자동 알림
                <TooltipForm contents="이용기관에서 입금 또는 출금 대상 고객에게 입출금 예정 금액이나 실제 입출금 결과를 SMS로 자동 통지해주는 서비스.<br/>발송 건당 16원(부가세 별도) 요금 부과" />
              </TableCell>
              <TableCell className="td" colSpan="3">
                <FormControl component="fieldset">
                  <RadioGroup aria-label="position" name="position" value={radioState.SMS} onChange={handleRadioChange("SMS")} row>
                    <FormControlLabel value="value1" control={<Radio color="primary" />} label="고지안함" />
                    <FormControlLabel
                      value="value2"
                      control={<Radio color="primary" />}
                      disabled={
                        !smsToCustomerSetting.serviceUseYn ||
                        (!smsToCustomerSetting.sendForAskingWithdraw && !smsToCustomerSetting.sendForFailureOnWithdraw && !smsToCustomerSetting.sendForSuccessOnWithdraw)
                      }
                      label={
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" name="position" value={value} onChange={handleCheckChange} row>
                            <span className="radio-label-l">
                              고지
                              {!smsToCustomerSetting.serviceUseYn ||
                              (!smsToCustomerSetting.sendForAskingWithdraw && !smsToCustomerSetting.sendForFailureOnWithdraw && !smsToCustomerSetting.sendForSuccessOnWithdraw) ? (
                                <TooltipForm contents="입출금 자동 알림 서비스를 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                              ) : null}
                            </span>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value.sendForAskingWithdraw}
                                  onChange={handleCheckChange("sendForAskingWithdraw")}
                                  color="primary"
                                  disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForAskingWithdraw}
                                />
                              }
                              label={
                                <>
                                  출금청구시
                                  {smsToCustomerSetting.serviceUseYn &&
                                    !smsToCustomerSetting.sendForAskingWithdraw &&
                                    (smsToCustomerSetting.sendForFailureOnWithdraw || smsToCustomerSetting.sendForSuccessOnWithdraw) && (
                                      <TooltipForm contents="출금청구시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                                    )}
                                </>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value.sendForSuccessOnWithdraw}
                                  onChange={handleCheckChange("sendForSuccessOnWithdraw")}
                                  color="primary"
                                  disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForSuccessOnWithdraw}
                                />
                              }
                              label={
                                <>
                                  정상출금시
                                  {smsToCustomerSetting.serviceUseYn &&
                                    !smsToCustomerSetting.sendForSuccessOnWithdraw &&
                                    (smsToCustomerSetting.sendForFailureOnWithdraw || smsToCustomerSetting.sendForAskingWithdraw) && (
                                      <TooltipForm contents="정상출금시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                                    )}
                                </>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value.sendForFailureOnWithdraw}
                                  onChange={handleCheckChange("sendForFailureOnWithdraw")}
                                  color="primary"
                                  disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForFailureOnWithdraw}
                                />
                              }
                              label={
                                <>
                                  미출금시
                                  {smsToCustomerSetting.serviceUseYn &&
                                    !smsToCustomerSetting.sendForFailureOnWithdraw &&
                                    (smsToCustomerSetting.sendForAskingWithdraw || smsToCustomerSetting.sendForSuccessOnWithdraw) && (
                                      <TooltipForm contents="미출금시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                                    )}
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            {readOnlyFlag ? null : fnRenderWithdrawAgreementCell() /* 조건에 따라 자동납부 동의자료 첨부란 렌더 */}
          </TableBody>
        </Table>
      </div>
    );
  } else {
    return "";
  }
};

/*
 * @desc  렌더를 처리하는 메인 컴포넌트
 */
const ReceiptCustomerCreate = ({ props }) => {
  const { store, loginStore } = props;
  const location = useLocation();

  // FIXME: 고객구분영역 hooks and handler
  const [firstIdentificationNo, setFirstIdentificationNo] = useState("");
  const [lastIdentificationNo, setLastIdentificationNo] = useState("");

  // 주민등록번호 앞자리, 뒷자리 handler
  const handleIdentificationNoChange = (e, type) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    changeIdentificationNo(value, type);
  };

  // 주민등록번호 앞자리, 뒷자리 입력시 해당 값 변경
  const changeIdentificationNo = (value, type) => {
    if (type === "first") {
      setFirstIdentificationNo(value);
    } else {
      setLastIdentificationNo(value);
    }
  };

  // FIXME: 개인 && 사업 > 고객정보 > 필수 입력사항 hooks and handler
  const [selectRequiredInputs, setSelectRequiredInputs] = useState({
    customerGroup: "", // 고객구분
    staff: "", // 고객관리담당자
  });
  const [optionStaffsAndBranches, setOptionStaffsAndBranches] = useState([]);

  // 필수 입력 사항 목록 가져오기
  useEffect(() => {
    async function startStoreAxios() {
      try {
        await store.axiosCustomerGroup(); // 고객구분
        const resultStaffs = await store.axiosStaffs();
        // 관리담당자/담당지사 option data 설정
        const tempStaffs = resultStaffs.map((staff) => ({ uniqueKey: staff.uniqueKey, name: `${staff.staffName}/${staff.branch.branchName}` }));

        setOptionStaffsAndBranches(tempStaffs);

        const customerGroups = toJS(store.customerGroup);
        if (!(Object.isExtensible(location.state) && CM.cfnIsNotEmpty(location.state.prevPageCustomerUpdate))) {
          setSelectRequiredInputs({
            ...selectRequiredInputs,
            staff: tempStaffs.length === 1 ? tempStaffs[0].uniqueKey : "",
            customerGroup: customerGroups.length === 1 ? customerGroups[0].uniqueKey : "",
          });
          setCustomer((cust) => ({
            ...cust,
            customerGroup: customerGroups[0],
            staff: resultStaffs[0],
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    startStoreAxios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  // 선택한 고객구분, 고객담당지사, 고객관리담당자 값을 변경하는 handler
  const handleRequiredInputsHandler = (e, name) => {
    const value = e.target.value;
    setSelectRequiredInputs((data) => ({ ...data, [name]: value }));
  };

  // FIXME: 고객정보 object 및 선택입력 사항 hooks & handler
  // 고객 이메일 주소
  const [customerEmail, setCustomerEmail] = useState({
    id: "",
    domain: "",
    selected: "manual",
  });

  // 사용자 정보
  const [customer, setCustomer] = useState({
    customerGroup: { uniqueKey: "" },
    staff: { branch: { uniqueKey: "" }, uniqueKey: "" },
    customerType: "INDIVIDUAL", // 고객 구분
    customerName: "", // 고객명
    identificationNo: "", // 전체 주민번호
    customerMobile: "", // 휴대전화번호
    customerEmail: "", // 이메일주소
    customerTelephone: "", // 유선전화번호
    customerFax: "", // 팩스번호
    customerDetailInfo: null,
    customerZipCode: "", // 우편번호
    customerAddress: "", // 주소
    customerAddressDetail: "", // 상세 주소
    remark: "", // 비고
    cashbillIdentificationNo: "", // 현금영수증 발행정보
  });

  // 담당자 정보
  const [customerDetailInfo, setCustomerDetailInfo] = useState({
    결혼기념일: null,
    담당자명: "",
    담당자직위: "",
    대표자명: "",
    업태: "",
    업종: "",
  });

  // 담당자 정보 handler
  const handleCustomerDetailInfoChange = (name, e) => {
    const value = name !== "결혼기념일" ? e.target.value : e;
    setCustomerDetailInfo((data) => ({ ...data, [name]: value }));
  };

  // 현금영수증 발행정보 radio 값
  const [selectedCashbillIdentificationNo, setSelectedCashbillIdentificationNo] = useState("NONE");

  // 이메일 주소 handler
  const handleCustomerEmailChange = (e, name) => {
    const value = e.target.value;
    setCustomerEmail((email) => ({
      ...email,
      [name]: value,
    }));
  };

  // 이메일 주소 selectbox handler
  const handleSelectboxCustomerEmailChange = (e) => {
    const value = e.target.value;
    const domain = value !== "manual" ? value : "";

    setCustomerEmail((email) => ({
      ...email,
      domain: domain,
      selected: value,
    }));
  };

  // 선택입력사항 handler(이메일, 현금영수증 발행정보 제외)
  const handleCustomerChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    // 현금영수증 발행정보의 주민등록번호를 입력할 때
    if (name === "cashbillIdentificationNo" && selectedCashbillIdentificationNo === "IDENTIFICATION_NO") {
      value = value.replace(/[^0-9]/g, "");
    }

    changeCustomer(name, value);
  };

  // 현금영수증 발행정보 radio handler
  const handleCashbillIdentificationNoRadioChange = (e) => {
    const value = e.target.value;
    setSelectedCashbillIdentificationNo(value);

    // 현금영수증 발행정보 input 초기화
    changeCustomer("cashbillIdentificationNo", "");
  };

  // 선택입력사항 입력시 해당 값 변경
  const changeCustomer = (name, value) => {
    setCustomer((data) => ({ ...data, [name]: value }));
  };

  // FIXME: 등록 및 등록취소
  // 고객 등록시 필수 값 체크
  const checkRequired = () => {
    if (CM.cfnIsEmpty(selectRequiredInputs.customerGroup)) {
      CM.cfnAlert("고객구분은 필수 입력사항입니다.");
      // window.alert("고객구분은 필수 입력사항입니다.");
      return false;
    }

    if (CM.cfnIsEmpty(selectRequiredInputs.staff)) {
      CM.cfnAlert("고객담당자/고객지사는 필수 입력사항입니다.");
      // window.alert("고객담당자/고객지사는 필수 입력사항입니다.");
      return false;
    }

    return true;
  };

  // FIXME: 기타 hooks
  const [modalOpen, setModalOpen] = useState(false); // 모달 open
  const [staffModalOpen, setStaffModalOpen] = useState(false);
  const [customerGroupModalOpen, setCustomerGroupModalOpen] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState([]); // 고객조회에서 검색된 고객 목록
  const [selectedCustomer, setSelectedCustomer] = useState({}); // 고객조회에서 선택한 고객 정보
  const [readOnlyFlag, setReadOnlyFlag] = useState(false); // 화면 readOnly flag
  const [readOnlyForEB11, setReadOnlyForEB11] = useState(false); // 화면 readOnly flag (eb11 신규등록 전용)
  const [checkIdentificationNo, setCheckIdentificationNo] = useState(
    location.state &&
      ((location.state.receiptCustomer &&
        location.state.receiptCustomer.customer &&
        location.state.receiptCustomer.customer.identificationNo &&
        location.state.receiptCustomer.customer.identificationNo.length > 6) ||
        (location.state.receiptCustomer &&
          location.state.receiptCustomer.customer &&
          location.state.receiptCustomer.customer.identificationNo &&
          location.state.receiptCustomer.customer.identificationNo.length > 6) ||
        (location.state.prevPageCustomerUpdate && location.state.prevPageCustomerUpdate.identificationNo && location.state.prevPageCustomerUpdate.identificationNo.length > 6))
  ); // 주민등록번호앞자리 체크 hooks

  const history = useHistory();

  // 고객 조회 modal open
  const openSearchCustomerModal = async () => {
    let identificationNo = ""; // 주민등록번호 또는 사업자번호

    // 고객구분이 개인일 때
    if (customer.customerType === "INDIVIDUAL") {
      if (CM.cfnIsEmpty(customer.customerName) && CM.cfnIsEmpty(firstIdentificationNo)) {
        CM.cfnAlert("고객명 또는 주민등록번호 앞자리를 입력하세요.");
        return false;
      }

      identificationNo = firstIdentificationNo + lastIdentificationNo;
    } else {
      if (CM.cfnIsEmpty(customer.customerName) && CM.cfnIsEmpty(customer.identificationNo)) {
        CM.cfnAlert("고객명 또는 사업자등록번호를 입력하세요.");
        return false;
      }

      identificationNo = customer.identificationNo;
    }

    const resultData = await axiosSearchCustomer(customer.customerName, identificationNo);

    // 조회된 고객이 있다면 modal open
    if (resultData.length > 0) {
      setSearchCustomers(resultData);
      handleModalOpenChange(true);
    } else {
      CM.cfnAlert("이름 또는 생년월일/사업자번호가 유사한 고객이 존재하지 않습니다.");
    }
  };

  // 고객 조회 aixos
  const axiosSearchCustomer = (customerName, identificationNo) => {
    return new Promise((resolve) => {
      const url = `api/customer-service/customer/basics/duplication?customerName=${customerName}&identificationNo=${identificationNo}`;
      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  };

  // modalOpen handler
  const handleModalOpenChange = (value) => {
    setModalOpen(value);
  };

  // 선택한 고객정보 handler
  const handleSelectedCustomerChange = (objCustomer) => {
    setSelectedCustomer(objCustomer);
    setReadOnlyFlag(true);
  };

  /* TODO: 선택한 고객 정보를 화면에 Mapping */
  // 01. 고객 기본정보 수정 페이지에서 수납정보추가 버튼을 눌러서 이동했을 때
  // setSelectedCustomer 에 고객 정보 값을 설정 후 잠금
  useEffect(() => {
    if (Object.isExtensible(location.state) && CM.cfnIsNotEmpty(location.state.prevPageCustomerUpdate)) {
      handleSelectedCustomerChange(location.state.prevPageCustomerUpdate);
    } else if (Object.isExtensible(location.state) && CM.cfnIsNotEmpty(location.state.receiptCustomer)) {
      const receiptCustomer = location.state.receiptCustomer;
      setCustomerEmail(CustomerData.getCustomerEmail(receiptCustomer.customer));
      setCustomer((customer) => ({
        ...customer,
        customerGroup: receiptCustomer.customer.customerGroup,
        staff: receiptCustomer.customer.staff,
        customerType: receiptCustomer.customer.customerType, // 고객 구분
        customerName: receiptCustomer.customer.customerName, // 고객명
        identificationNo: receiptCustomer.customer.identificationNo, // 전체 주민번호
        customerMobile: receiptCustomer.customer.customerMobile, // 휴대전화번호
        customerEmail: receiptCustomer.customer.customerEmail, // 이메일주소
        customerTelephone: receiptCustomer.customer.customerTelephone, // 유선전화번호
        customerFax: receiptCustomer.customer.customerFax, // 팩스번호
        customerDetailInfo: receiptCustomer.customer.customerDetailInfo,
        customerZipCode: receiptCustomer.customer.customerZipCode, // 우편번호
        customerAddress: receiptCustomer.customer.customerAddress, // 주소
        customerAddressDetail: receiptCustomer.customer.customerAddressDetail, // 상세 주소
        remark: receiptCustomer.customer.remark, // 비고
        cashbillIdentificationNo: receiptCustomer.customer.cashbillIdentificationNo, // 현금영수증 발행정보
      }));
      setValue(receiptCustomer);
      setRadioState((radiostate) => ({
        ...radiostate,
        SMS: receiptCustomer.sendForAskingWithdraw || receiptCustomer.sendForFailureOnWithdraw || receiptCustomer.sendForSuccessOnWithdraw ? "value2" : "value1",
      }));
      // setIsBankSelected(true);
      setSearchValue({
        //선택된 계좌정보 저장을 위한 state
        accountNo: (receiptCustomer.account && receiptCustomer.account.accountNo) || "",
        depositorIdentification: (receiptCustomer.account && receiptCustomer.account.depositorIdentificationNo) || "",
        financialInstituteCode: (receiptCustomer.account && receiptCustomer.account.accountBankCode) || "",
        fncInsNm: (receiptCustomer.account && receiptCustomer.account.financialInstituteName) || "",
      });
      setReadOnlyForEB11(true);
    }
  }, [location.state]);

  useEffect(() => {
    // 선택한 고객이 있을때
    if (CM.cfnIsNotEmpty(selectedCustomer) && readOnlyFlag === true) {
      const cloneData = CM.cfnCopyObject(selectedCustomer); // 고객 정보 //TODO:
      setCustomerEmail(CustomerData.getCustomerEmail(cloneData));
      setCustomerDetailInfo(CustomerData.getCustomerDetailInfo(cloneData));
      setSelectRequiredInputs(CustomerData.getCustomerRequiredInputs(cloneData));
      setCustomer(CustomerData.getCustomer(cloneData));
      setSelectedCashbillIdentificationNo(CustomerData.getCashbillIdentificationNo(cloneData));
    }
  }, [selectedCustomer, readOnlyFlag]);

  // 주민등록번호 앞, 뒷자리 초기화
  useEffect(() => {
    if (customer && customer.customerType === "INDIVIDUAL") {
      if (customer.identificationNo) {
        if (customer.identificationNo.length >= 6) {
          setFirstIdentificationNo(customer.identificationNo.substring(0, 6));
          setLastIdentificationNo(customer.identificationNo.substring(6));
        } else {
          setFirstIdentificationNo(customer.identificationNo);
        }
      }
    }
  }, [customer]);

  // 자금종류 (수납)
  const capitalType = "RECEIPT";

  // data 관리 state
  const [value, setValue] = useState({
    account: {
      accountBankCode: "",
      accountNo: "",
      accountRegistrationStatus: "",
      contractUniqueKey: "",
      depositorIdentificationNo: "",
      depositorName: "",
      depositorTelephone: "",
      evidenceFileName: "",
      evidenceFileType: "",
    },
    askStatus: "",
    bankbookContents: "",
    bankbookPostfix: "",
    bankbookPostfixType: "",
    capital: {
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
      capitalName: "",
      capitalType: "",
    },
    contractPauseResumeY4mm: null,
    contractStatus: "",
    currentNumberOfPayment: null,
    customer: {
      cashbillIdentificationNo: "",
      createdDate: "",
      customerAddress: "",
      customerAddressDetail: "",
      customerDetailInfo: {},
      customerEmail: "",
      customerFax: "",
      customerGroup: {},
      customerMobile: "",
      customerName: "",
      customerTelephone: "",
      customerType: "INDIVIDUAL",
      customerZipCode: "",
      identificationNo: "",
      paymentContractCount: null,
      paymentContractList: [
        {
          account: {
            accountBankCode: "",
            accountNo: "",
            depositorIdentificationNo: "",
            depositorName: "",
            registeredDate: null,
            unregisteredDate: null,
          },
          askStatus: "INITIAL",
          bankbookContents: "",
          bankbookPostfix: "",
          bankbookPostfixType: "",
          capital: {
            bankbookContents: "",
            bankbookPostfix: "",
            bankbookPostfixType: "",
            capitalName: "",
            capitalType: "",
          },
          contractStatus: "",
          expectedApplyY4mm: null,
          payAmount: null,
          payAmountType: "",
          payEndY4mm: null,
          payPeriod: "",
          paySpecifiedDay: "",
          payStartY4mm: null,
          sendForAskingDeposit: false,
          sendForFailureOnDeposit: false,
          sendForSuccessOnDeposit: false,
          totalNumberOfPayment: "",
          transactionMethod: "",
        },
      ],
      receiptContractCount: 0,
      receiptContractList: [null],
      remark: "",
      roadAddressYn: true,
      staff: {},
    },
    expectedApplyY4mm: null,
    payAmount: "",
    payAmountType: "FIXED",
    payEndY4mm: "999912",
    payPeriod: "VARIABLE",
    paySpecifiedDay: "",
    payStartY4mm: "",
    payerNo: Object.isExtensible(location.state) ? (Object.isExtensible(location.state.receiptCustomer) ? location.state.receiptCustomer.payerNo : "") : "",
    sendForAskingWithdraw: false,
    sendForFailureOnWithdraw: false,
    sendForSuccessOnWithdraw: false,
    totalNumberOfPayment: "",
    transactionMethod: "CMS_WITHDRAW",
    withdrawCriteria: "",
  });
  const [smsToCustomerSetting, setSmsToCustomerSetting] = useState({ serviceUseYn: false, sendForAskingWithdraw: false, sendForFailureOnWithdraw: false, sendForSuccessOnWithdraw: false });

  /*
   * @desc  radio 버튼 제어 state
   */
  const [radioState, setRadioState] = useState({
    cashbillIdentificationNo: "NONE",
    autoGeneratePayerNoYn: false, //store.businessInfo.autoGeneratePayerNoYn
    payerNoType: "", //납부자번호
    installment: "value1", //할부구분
    payPeriod: value.payPeriod !== "VARIABLE" ? "value2" : "value1", //수납주기
    SMS: value.sendForAskingWithdraw || value.sendForFailureOnWithdraw || value.sendForSuccessOnWithdraw ? "value2" : "value1", //입출금 정보 자동 알림
  });

  //필요 state 선언
  const [isBankSelected, setIsBankSelected] = useState(false);
  const [searchValue, setSearchValue] = useState({
    //선택된 계좌정보 저장을 위한 state
    accountNo: "",
    depositorIdentification: "",
    financialInstituteCode: "",
    fncInsNm: "",
  });
  const [totalNumberOfPaymentState, setTotalNumberOfPaymentState] = useState(true);
  const [limitAmountForEachWithdrawal, setLimitAmountForEachWithdrawal] = useState(null);

  /*
   * @desc  수납종료월 자동계산 여부 판단 flag
   */
  const [flag, setFlag] = useState(false);
  const [isDirectlyUpdatingPayRange, setIsDirectlyUpdatingPayRange] = useState(false);

  /*
   * @desc  페이지 진입 시 실행되는 useEffect
   */
  useEffect(() => {
    const fnGetBusinessInfo = async () => {
      const fnGetBusinessInfoCallback = (objStatus, data) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          // window.alert(objStatus.statusText);
          return;
        }
        store.setBusinessInfo(data); // store 값 설정
        /*
         * @desc 스토어 autoGeneratePayerNoYn/withdrawCriteria 값을 저장
         */
        setRadioState((val) => ({
          ...val,
          autoGeneratePayerNoYn: data.autoGeneratePayerNoYn,
          payerNoType: data.autoGeneratePayerNoYn ? "value1" : "value2",
        }));
        setValue((val) => ({
          ...val,
          withdrawCriteria: data.withdrawCriteria,
        }));
        setLimitAmountForEachWithdrawal(data.cmsBusiness ? data.cmsBusiness.limitAmountForEachWithdrawal : null);
      };

      const storeBusinessInfo = CM.cfnCopyObject(toJS(store.businessInfo));
      if (CM.cfnIsEmpty(storeBusinessInfo)) {
        store.getBusinessInfo(fnGetBusinessInfoCallback);
      } else {
        // 값이 있을 경우
        setRadioState((val) => ({
          ...val,
          autoGeneratePayerNoYn: storeBusinessInfo.autoGeneratePayerNoYn,
          payerNoType: storeBusinessInfo.autoGeneratePayerNoYn ? "value1" : "value2",
        }));
        setValue((val) => ({
          ...val,
          withdrawCriteria: storeBusinessInfo.withdrawCriteria,
        }));
        setLimitAmountForEachWithdrawal(storeBusinessInfo.cmsBusiness ? storeBusinessInfo.cmsBusiness.limitAmountForEachWithdrawal : null);
      }

      const storeInstituteService = await store.axiosInstituteService();
      let setting = { serviceUseYn: false, sendForAskingWithdraw: false, sendForFailureOnWithdraw: false, sendForSuccessOnWithdraw: false };
      for (const service of storeInstituteService) {
        if (service.serviceType === "SMS_TO_CUSTOMER") {
          setting = service;
        }
      }
      setting.serviceUseYn = !!setting.serviceUseYn;
      setting.sendForAskingWithdraw = !!setting.sendForAskingWithdraw;
      setting.sendForFailureOnWithdraw = !!setting.sendForFailureOnWithdraw;
      setting.sendForSuccessOnWithdraw = !!setting.sendForSuccessOnWithdraw;
      setSmsToCustomerSetting(setting);
      setValue((val) => ({
        ...val,
        sendForAskingWithdraw: setting.serviceUseYn && setting.sendForAskingWithdraw,
        sendForFailureOnWithdraw: setting.serviceUseYn && setting.sendForFailureOnWithdraw,
        sendForSuccessOnWithdraw: setting.serviceUseYn && setting.sendForSuccessOnWithdraw,
      }));
      setRadioState((val) => ({
        ...val,
        SMS: setting.serviceUseYn && (setting.sendForAskingWithdraw || setting.sendForFailureOnWithdraw || setting.sendForSuccessOnWithdraw) ? "value2" : "value1",
      }));
    };
    fnGetBusinessInfo();
  }, [store]);

  /*
   * @desc  기본정보 > 수납종료월 자동계산을 위한 useEffect
   */
  useEffect(() => {
    //수납시작월 이번달 Default세팅
    let payStartY4mm = value.payStartY4mm;
    if (value.payStartY4mm === "") {
      const today = new Date();
      payStartY4mm = CM.cfnConvertDateToString(today).substring(0, 6);
      setValue((val) => ({
        ...val,
        payStartY4mm,
      }));
    }
    let year = value.payStartY4mm !== null ? Number(value.payStartY4mm.toString().substring(0, 4)) : 0;
    let month = value.payStartY4mm !== null ? Number(value.payStartY4mm.toString().substring(4)) - 1 : 0;
    let endYear = value.payEndY4mm !== null ? Number(value.payEndY4mm.toString().substring(0, 4)) : 0;
    let endMonth = value.payEndY4mm !== null ? Number(value.payEndY4mm.toString().substring(4)) - 1 : 0;
    if (value.payPeriod !== "VARIABLE" && value.payPeriod !== "" && endYear !== 9999 && value.payStartY4mm.length > 4) {
      let monthDifference = (endYear - year) * 12 - month + endMonth;
      let totalNumberOfPayment = "";
      switch (value.payPeriod) {
        case "MONTHLY":
          totalNumberOfPayment = Math.floor(monthDifference / 1.0);
          break;
        case "EVERY_2_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 2.0);
          break;
        case "EVERY_3_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 3.0);
          break;
        case "EVERY_4_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 4.0);
          break;
        case "EVERY_6_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 6.0);
          break;
        case "YEARLY":
          totalNumberOfPayment = Math.floor(monthDifference / 12.0);
          break;
        default:
          break;
      }

      totalNumberOfPayment += 1;
      totalNumberOfPayment = totalNumberOfPayment < 0 ? "" : totalNumberOfPayment;

      // setFlag(true);
      setValue((val) => ({
        ...val,
        totalNumberOfPayment: totalNumberOfPayment,
      }));
    } else {
      if (endYear === 9999) {
        setValue((val) => ({
          ...val,
          totalNumberOfPayment: "",
        }));
      }
      setFlag(!!totalNumberOfPaymentState);
    }
  }, [value.payPeriod, value.payEndY4mm, value.payStartY4mm, flag, totalNumberOfPaymentState]);

  useEffect(() => {
    if (isDirectlyUpdatingPayRange) {
      if (value.payPeriod !== "VARIABLE" && radioState.payPeriod !== "value1" && value.payStartY4mm.length > 4) {
        if (value.totalNumberOfPayment !== "" && value.totalNumberOfPayment > 0) {
          let period = 1.0;
          switch (value.payPeriod) {
            case "EVERY_2_MONTHS":
              period = 2.0;
              break;
            case "EVERY_3_MONTHS":
              period = 3.0;
              break;
            case "EVERY_4_MONTHS":
              period = 4.0;
              break;
            case "EVERY_6_MONTHS":
              period = 6.0;
              break;
            case "YEARLY":
              period = 12.0;
              break;
            default:
              break;
          }
          let months = period * (Number(value.totalNumberOfPayment) - 1);
          let payEndY4mm = new Date(value.payStartY4mm.substring(0, 4), value.payStartY4mm.substring(4) === "" ? 0 : Number(value.payStartY4mm.substring(4)) - 1 + months);
          setValue((val) => ({
            ...val,
            totalNumberOfPayment: val.totalNumberOfPayment,
            payEndY4mm: payEndY4mm.getFullYear() + "" + (payEndY4mm.getMonth() < 9 ? "0" + (payEndY4mm.getMonth() + 1) : payEndY4mm.getMonth() + 1),
          }));
        } else {
          setValue((val) => ({ ...val, payEndY4mm: "999912" }));
        }
      }
      setIsDirectlyUpdatingPayRange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirectlyUpdatingPayRange, value.payEndY4mm, value.payStartY4mm]);

  const [financialInstitutes, setFinancialInstitutes] = useState([]); //금융기관 자동완성 기능을 위한 state
  useEffect(() => {
    CM.cfnIsEmpty(financialInstitutes) &&
      (async () => {
        let list = await store.axiosFinancialRepresentativeInstitutes();
        setFinancialInstitutes(list);
      })();
  }, [financialInstitutes, store]);

  useEffect(() => {
    //금융기관 선택 시 paymentInfo에 값 세팅
    if (isBankSelected) {
      setValue((value) => ({
        ...value,
        account: {
          ...value.account,
          accountBankCode: searchValue.financialInstituteCode,
        },
      }));
      setIsBankSelected(false);
    }
  }, [isBankSelected, searchValue.financialInstituteCode]);

  /*
  @desc 수납주기 > 정기 옵션 요소
  */
  const payPeriodOption = [
    { value: "MONTHLY", label: "매월" },
    { value: "EVERY_2_MONTHS", label: "2개월마다" },
    { value: "EVERY_3_MONTHS", label: "3개월마다" },
    { value: "EVERY_4_MONTHS", label: "4개월마다" },
    { value: "EVERY_6_MONTHS", label: "6개월마다" },
    { value: "YEARLY", label: "매년" },
  ];

  /*
  @desc 출금형태 옵션 요소
  */
  const withdrawCriteriaOptions = [
    { value: "ENTIRE_PRICE", label: "전액출금" },
    { value: "ABOVE_1000", label: "1000원이상" },
    { value: "ABOVE_2000", label: "2000원이상" },
    { value: "ABOVE_3000", label: "3000원이상" },
    { value: "ABOVE_4000", label: "4000원이상" },
    { value: "ABOVE_5000", label: "5000원이상" },
    { value: "ABOVE_FEE", label: "수수료 이상금액" },
  ];

  /*
  @desc 통장기재내용 > 추가 옵션 요소
  */
  const bankbookPostfixTypeOptions = [
    {
      label: "수납월",
      value: "MONTH",
    },
    {
      label: "수납연월",
      value: "YEARMONTH",
    },
    {
      label: "수납회차",
      value: "SEQUENCE",
    },
    {
      label: "수납대상연월",
      value: "TARGET_Y4MM",
    },
    {
      label: "직접입력",
      value: "MANUAL",
    },
  ];

  /*
  @desc value State 제어 핸들러
  */
  const handleValuesChange = (depth4, depth3, depth2, depth1) => (event) => {
    if (depth1 !== undefined) {
      setValue({
        ...value,
        [depth1]: {
          ...value[depth1],
          [depth2]: {
            ...value[depth1][depth2],
            [depth3]: {
              ...value[depth1][depth2][depth3],
              [depth4]: event.target.value,
            },
          },
        },
      });
    } else if (depth2 !== undefined) {
      setValue({
        ...value,
        [depth2]: {
          ...value[depth2],
          [depth3]: {
            ...value[depth2][depth3],
            [depth4]: event.target.value,
          },
        },
      });
    } else if (depth3 !== undefined) {
      switch (depth3) {
        //수납시작년도
        case "payStartY4":
          const startMm = value.payStartY4mm.substring(4);
          setValue({
            ...value,
            payStartY4mm: `${event.target.value}${startMm}`,
          });
          break;
        //수납시작월
        case "payStartMm":
          const startY4 = value.payStartY4mm.substring(0, 4);
          setValue({
            ...value,
            payStartY4mm: `${startY4}${event.target.value}`,
          });
          break;
        //수납종료년도
        case "payEndY4":
          const endMm = value.payEndY4mm.substring(4);
          setValue({
            ...value,
            payEndY4mm: `${event.target.value}${endMm}`,
          });
          break;
        //수납종료월
        case "payEndMm":
          const endY4 = value.payEndY4mm.substring(0, 4);
          setValue({
            ...value,
            payEndY4mm: `${endY4}${event.target.value}`,
          });
          break;
        //자금종류 변경 시
        case "capital":
          const selectedCapital = fnMatchSelectedCapital(event.target.value);
          setValue({
            ...value,
            bankbookContents: selectedCapital.bankbookContents,
            bankbookPostfix: selectedCapital.bankbookPostfix,
            bankbookPostfixType: selectedCapital.bankbookPostfixType,
            [depth3]: {
              uniqueKey: selectedCapital.uniqueKey,
            },
          });
          break;
        default:
          setValue({
            ...value,
            [depth3]: {
              ...value[depth3],
              [depth4]: event.target.value,
            },
          });
      }
    } else {
      //총 수납 횟수
      if (depth4 === "totalNumberOfPayment") {
        let totalNumberOfPayment = event.target.value.replace(/[^0-9]/g, "");
        if (totalNumberOfPayment === "0") {
          event.preventDefault();
          setIsDirectlyUpdatingPayRange(true);
        } else if (Number(totalNumberOfPayment) > 200) {
          event.preventDefault();
        } else {
          setValue({
            ...value,
            [depth4]: totalNumberOfPayment,
          });
          setIsDirectlyUpdatingPayRange(true);
        }
      } else if (depth4 === "payAmount") {
        setValue({
          ...value,
          [depth4]: CM.cfnAddCommaOnChange(event.target.value.replace(/[^0-9]/g, "")),
        });
      } else if (depth4 === "payerNo") {
        setValue({
          ...value,
          [depth4]: event.target.value ? event.target.value.toUpperCase() : event.target.value,
        });
      } else if (depth4 === "paySpecifiedDay") {
        let todayYear = `${new Date().getFullYear().toString()}`;
        let todayMonth = `${new Date().getMonth() + 1}`; //이번달
        todayMonth = todayMonth < 10 ? "0" + todayMonth : todayMonth;
        let todayDate = CM.cfnConvertDateToString(new Date()).substring(6, 8);
        let payStartYear = value.payStartY4mm.toString().substring(0, 4);
        let payStartMonth = value.payStartY4mm.toString().substring(4);
        let mm;
        let year;

        //년, 월 모두 같을 경우 수납일자가 현재일자보다 이전일 경우
        if (payStartYear === todayYear && payStartMonth === todayMonth && todayDate >= event.target.value && event.target.value !== "") {
          mm = todayMonth === "12" ? `${new Date().getMonth() - 10}` : `${new Date().getMonth() + 2}`; //다음달
          mm = mm < 10 ? "0" + mm : mm;
          year = todayMonth === "12" ? `${new Date().getFullYear() + 1}` : todayYear;
        } else if (payStartYear === todayYear && payStartMonth === todayMonth && todayDate < event.target.value) {
          //년, 월 모두 같을 경우 수납일자가 현재일자보다 이후일 경우
          mm = `${new Date().getMonth() + 1}`; //이번달
          mm = mm < 10 ? "0" + mm : mm;
          year = todayYear;
        } else {
          //년, 월 모두 다를 경우 수납일자는 기존 입력된 수납시작일 유지
          mm = payStartMonth;
          year = payStartYear;
        }
        setValue({
          ...value,
          [depth4]: event.target.value,
          payStartY4mm: year + mm,
        });
      } else {
        setValue({
          ...value,
          [depth4]: event.target.value,
        });
      }
    }
  };

  /*
  @desc radio state 제어 핸들러 
  */
  const handleRadioChange = (name) => (event) => {
    setRadioState({ ...radioState, [name]: event.target.value });
    switch (name) {
      //납부자번호
      case "payerNoType":
        if (event.target.value === "value1") {
          setValue({ ...value, payerNo: "" });
        }
        break;
      //할부구분
      case "installment":
        if (event.target.value === "value2") {
          setRadioState({
            ...radioState,
            payPeriod: "value2",
            [name]: event.target.value,
          });
          setValue({ ...value, payAmountType: "FIXED", payPeriod: "MONTHLY" });
          //기한없음 해제
          setTotalNumberOfPaymentState(false);
          setFlag(false);
        }
        break;
      //수납주기
      case "payPeriod":
        if (event.target.value === "value1") {
          setValue({ ...value, payPeriod: "VARIABLE" });
        } else {
          setValue({ ...value, payPeriod: "MONTHLY" });
        }
        break;
      //입출금 정보 자동 알림
      case "SMS":
        if (event.target.value === "value1") {
          setValue({
            ...value,
            sendForAskingWithdraw: false,
            sendForFailureOnWithdraw: false,
            sendForSuccessOnWithdraw: false,
          });
        } else {
          setValue((val) => ({
            ...val,
            sendForAskingWithdraw: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingWithdraw,
            sendForFailureOnWithdraw: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnWithdraw,
            sendForSuccessOnWithdraw: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnWithdraw,
          }));
        }
        break;
      case "cashbillIdentificationNo":
        setValue({
          ...value,
          customer: {
            ...value.customer,
            cashbillIdentificationNo: "",
          },
        });
        break;
      default:
        break;
    }
  };

  /*
   * @desc    yyyyMM 형태로 들어오는 데이터를 년도와 월로 분리하여 출력
   */
  const fnConvertY4mm = (y4mm, type) => {
    let val;

    if (type === "year") {
      val = y4mm.toString().substring(0, 4);
    } else if (type === "mm") {
      val = y4mm.toString().substring(4);
    }

    return val;
  };

  /*
   * @desc    자금종류 관련 state 설정 함수
   */
  const fnSetCapitals = (data) => {
    setCapitalRows(data);
    fnSetCapitalOptions(data);
  };

  /*
   * @desc    자금종류 셀렉트박스 옵션 요소 생성 함수
   */
  const fnSetCapitalOptions = (data) => {
    let tempArray = [];

    if (CM.cfnIsNotEmpty(data)) {
      data.forEach((row) => {
        if (row.capitalType === "RECEIPT")
          tempArray.push({
            label: row.capitalName,
            value: row.uniqueKey,
            key: row.uniqueKey,
          });
      });
    }

    setCapitalOptions(tempArray);
  };

  /*
   * @desc     수납예정일 옵션 생성하는 함수
   */
  const fnCreateDateOption = () => {
    const arrDate = [];
    const maxLoop = 32;

    for (let i = 0; i < maxLoop; i++) {
      let label = `${i}일`;
      let value = i < 10 ? `0${i}` : i;

      if (i === 0) {
        label = "지정일";
        value = "";
      }

      arrDate.push({
        label: label,
        value: value,
      });
    }

    return arrDate;
  };

  /*
   * @desc     수납시작월의 년도 옵션 생성하는 함수
   */
  const fnCreateStartYearOption = () => {
    const arrYear = [];
    const maxLoop = 10;
    const startYear = new Date().getFullYear();

    arrYear.push({
      label: "년도",
      value: "",
    });

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납종료월의 년도 옵션 생성하는 함수
   */
  const fnCreateEndYearOption = () => {
    const arrYear = [];
    const maxLoop = 50;
    const startYear = new Date().getFullYear();

    arrYear.push({
      label: "년도",
      value: "",
    });

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납시작월 / 종료월의 옵션 생성하는 함수
   */
  const fnCreateMonthOption = (type) => {
    const arrMonth = [];
    arrMonth.push({
      label: "월",
      value: "",
    });
    const maxLoop = 13;
    let year = `${new Date().getFullYear().toString()}`;
    let mm = `${new Date().getMonth() + 1}`; //이번달;

    if (CM.cfnIsNotEmpty(value.payStartY4mm) && value.payStartY4mm.toString().substring(0, 4) === year && type === "startMM") {
      //수납시작월
      for (let i = mm; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;
        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else if (CM.cfnIsNotEmpty(value.payEndY4mm) && value.payEndY4mm.toString().substring(0, 4) === year && type === "endMM") {
      //수납종료월
      for (let i = mm; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else {
      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    }
    return arrMonth;
  };

  const dayOptions = fnCreateDateOption();
  const startYearOptions = fnCreateStartYearOption();
  const endYearOptions = fnCreateEndYearOption();

  /*
  @desc 입출금 정보 자동 알림 > radio 버튼 제어 함수
  */
  const fnRadioValue = (name, eventValue) => {
    let smsValue = "value1";
    let flag = false;
    switch (name) {
      case "sendForAskingWithdraw":
        flag = eventValue || value.sendForFailureOnWithdraw || value.sendForSuccessOnWithdraw;
        break;
      case "sendForFailureOnWithdraw":
        flag = value.sendForAskingWithdraw || eventValue || value.sendForSuccessOnWithdraw;
        break;
      case "sendForSuccessOnWithdraw":
        flag = value.sendForAskingWithdraw || value.sendForFailureOnWithdraw || eventValue;
        break;
      default:
        break;
    }
    if (flag) smsValue = "value2";
    setRadioState({ ...radioState, SMS: smsValue });
  };

  /*
  @desc checkBox 제어 핸들러 (예금주> 고객명과 동일, 입출금 정보 자동 알림 > 출금의뢰시/정상출금시/미출금시)
  */
  const handleCheckChange = (name) => (event) => {
    if (name === "payer") {
      setValue({
        ...value,
        account: {
          ...value.account,
          depositorName: customer.customerName,
          depositorIdentificationNo: readOnlyFlag
            ? customer.customerType === "BUSINESSER"
              ? customer.identificationNo
              : firstIdentificationNo
            : customer.customerType === "BUSINESSER"
            ? customer.identificationNo
            : firstIdentificationNo,
          depositorTelephone: customer.customerMobile,
        },
      });
    } else if (name === "totalNumberOfPayment") {
      if (event.target.checked) {
        //기한없음
        setTotalNumberOfPaymentState(true);
        setValue((val) => ({
          ...val,
          totalNumberOfPayment: "",
          payEndY4mm: "",
        }));
        setFlag(true);
      } else {
        //기한없음 해제
        setTotalNumberOfPaymentState(false);
        setFlag(false);
      }
    } else {
      setValue({ ...value, [name]: event.target.checked });
      fnRadioValue(name, event.target.checked);
    }
  };

  /*
   * @desc   modal창 관련  state
   */
  const [evidenceFileOpen, setEvidenceFileOpen] = useState(false);
  const [capitalOpen, setCapitalOpen] = useState(false);
  const [capitalRows, setCapitalRows] = useState([]);
  const [capitalOptions, setCapitalOptions] = useState([]);

  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      await store.getCapital(fnSetCapitalList);
    };

    /*
     * @desc    자금종류 조회 Request Callback
     */
    const fnSetCapitalList = (objStatus, responseData) => {
      if (objStatus.status === 200) {
        setCapitalRows(responseData);
        fnSetCapitalOptions(responseData);
      }
    };

    if (CM.cfnIsEmpty(store.capital.$mobx.values)) {
      fnFetchCapitalList();
    } else {
      setCapitalRows(toJS(store.capital));
      fnSetCapitalOptions(toJS(store.capital));
    }
  }, [store, setCapitalOptions, setCapitalRows]);

  /*
   * @desc    선택된 자금종류에 해당하는 정보 가져오는 함수
   */
  const fnMatchSelectedCapital = (target) => {
    let capital = {
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
      uniqueKey: "",
    };

    capitalRows.forEach((row) => {
      if (row.uniqueKey === target) capital = row;
    });

    return capital;
  };

  /*
   * @desc   modal창 관련 핸들러
   */
  const handleClickModal = (name) => {
    switch (name) {
      case "capitalName":
        setCapitalOpen(true);
        break;
      case "file":
        setEvidenceFileOpen(true);
        break;
      default:
        break;
    }
  };

  /*
   * @desc    출금동의 파일 state
   */
  const [evidenceFile, setEvidenceFile] = useState({
    file: null,
    fileName: "",
    isFileChanged: false,
  });

  /*
   * @desc   첨부파일삭제 버튼 클릭 이벤트 핸들러
   */
  const handleDeleteFile = () => {
    CM.cfnConfirm("선택한 동의자료를 삭제하시겠습니까?", () => {
      setEvidenceFile({
        file: null,
        fileName: "",
        fileSrc: "#",
        fileType: "",
      });
      setValue((value) => ({
        ...value,
        account: {
          ...value.account,
          evidenceFileName: "",
          evidenceFileType: "",
        },
      }));
    });
  };

  /*
   * @desc   자동납부 동의자료 모달 내 동의자료 등록 함수
   */
  const fnSubmitEvidenceFile = (file, fileName, evidenceFileType) => {
    setEvidenceFileOpen(false);
    setEvidenceFile({ ...evidenceFile, file, evidenceFileType, isSavedOnServer: false });
    setValue({ ...value, account: { ...value.account, evidenceFileType, evidenceFileName: fileName } });
  };

  const copyCustomerDetailInfo = {}; // 고객추가정보

  function fnSubmit(sendData) {
    return new Promise((resolve) => {
      if (readOnlyFlag && CM.cfnIsNotEmpty(selectedCustomer)) {
        //sendData.customer = selectedCustomer;
        //고객기본정보 화면에서 이동했을 경우 uniqueKey만 전송
        sendData.customer = { uniqueKey: selectedCustomer.uniqueKey };
      } else {
        // 필수값 체크
        if (!checkRequired()) {
          resolve(false);
          return;
        }

        // 고객추가정보 값 확인
        for (const [key, value] of Object.entries(customerDetailInfo)) {
          if (CM.cfnIsNotEmpty(value)) {
            const applyValue = key === "결혼기념일" ? CM.cfnConvertDateToString(value) : value;
            copyCustomerDetailInfo[key] = applyValue;
          }
        }

        // 고객추가정보 값 설정
        sendData.customer = customer;
        sendData.customer.customerGroup.uniqueKey = selectRequiredInputs.customerGroup;
        sendData.customer.staff.uniqueKey = selectRequiredInputs.staff;
        sendData.customer.customerDetailInfo = JSON.stringify(copyCustomerDetailInfo);
        sendData.customer.customerMobile = CM.cfnReplaceSymbol(sendData.customer.customerMobile);
        sendData.customer.cashbillIdentificationNo = CM.cfnReplaceSymbol(sendData.customer.cashbillIdentificationNo);
        sendData.account.depositorTelephone = CM.cfnReplaceSymbol(sendData.account.depositorTelephone);
        sendData.customer.customerTelephone = CM.cfnReplaceSymbol(sendData.customer.customerTelephone);
        sendData.customer.customerFax = CM.cfnReplaceSymbol(sendData.customer.customerFax);
        let email = null;
        // 이메일이 빈값이 아니라면
        if (CM.cfnIsNotEmpty(customerEmail.id) || CM.cfnIsNotEmpty(customerEmail.domain)) {
          email = customerEmail.id + "@" + customerEmail.domain;
          // 이메일 형식 체크
          if (!CM.cfnCheckEmail(email)) {
            CM.cfnAlert("올바른 이메일 형식이 아닙니다.");
            resolve(false);
            return;
          }
        }

        sendData.customer.customerEmail = email;

        // 개인일 때
        if ("INDIVIDUAL" === customer.customerType) {
          sendData.customer.identificationNo = firstIdentificationNo + lastIdentificationNo;
        } else {
          sendData.customer.identificationNo = CM.cfnReplaceSymbol(sendData.customer.identificationNo);
        }
      }

      //수납정보 > 수납종료월 (총수납횟수가 ""이거나 0일 때 null로 데이터 전환)
      if (sendData.payEndY4mm === "" || (sendData.payEndY4mm && sendData.payEndY4mm.startsWith("9999"))) {
        sendData.payEndY4mm = null;
        sendData.totalNumberOfPayment = null;
      }

      // 수납금액 ,(콤마) 삭제
      if (CM.cfnIsNotEmpty(sendData.payAmount)) {
        sendData.payAmount = Number(CM.cfnReplaceSymbol(sendData.payAmount));
      }

      //할부금액 계산
      if (radioState.installment === "value2") {
        if (CM.cfnIsEmpty(value.totalNumberOfPayment)) {
          CM.cfnAlert("할부 고객인 경우 총 수납횟수를 입력해주세요.", null);
          resolve(false);
          return;
        }
        sendData.payAmount = Number(CM.cfnReplaceSymbol(value.payAmount)) / Number(CM.cfnReplaceSymbol(value.totalNumberOfPayment));
      }

      if (sendData.account) {
        if (sendData.account.depositorIdentificationNo) {
          sendData.account.depositorIdentificationNo = CM.cfnReplaceSymbol(sendData.account.depositorIdentificationNo);
        }
        if (
          sendData.account.depositorIdentificationNo &&
          sendData.account.depositorIdentificationNo !== "" &&
          sendData.account.depositorIdentificationNo.length !== 6 &&
          sendData.account.depositorIdentificationNo.length !== 10
        ) {
          CM.cfnAlert("예금주 생년월일(사업자번호)는 6자리 혹은 10자리로 입력해주세요.");
          resolve(false);
          return;
        }
      }

      resolve(true);
    });
  }

  const checkMaxCustomerCount = async () => {
    const businessInfo = await store.axiosBusinessInfo();
    const loginStaffInfo = loginStore.loginDto;
    const url = `/api/customer/basics/count?customerName=${customer?.customerName}&identificationNo=${(firstIdentificationNo || "") + (lastIdentificationNo || "")}`;

    let response = await CM.cfnAxiosAsync(url, "get", null, false);
    if (typeof response.data === "number") {
      if (response.data < 0) {
        // 중복되는 항목이 존재함
        try {
          await CM.cfnConfirmAsync("등록할 수납고객정보의 같은 이름과 생년월일(사업자번호)로 등록된 기존 고객이 존재합니다.\n등록하시면 기존 고객에 수납정보가 추가됩니다. 등록하시겠습니까?");
          // true : 체크 완료
          return true;
        } catch (reject) {
          return false;
        }
      } else if (response.data >= businessInfo.maxCustomerCount) {
        //현재 등록 된 고객 수와 기관 최대 고객 수 비교
        //총괄담당자와 이용요금 담당자일 경우
        if (loginStaffInfo.masterYn || loginStaffInfo.billingManagerYn) {
          try {
            await CM.cfnConfirmAsync(
              "현재 등록된 고객수가 최대 등록가능 고객수에 도달하였습니다. 최대 등록가능 고객수를 100명 추가하신 후 등록하시겠습니까?\n※100명 추가 시 이용요금이 2,000원 증가됩니다."
            );
            businessInfo.maxCustomerCount = businessInfo.maxCustomerCount + 100;
            businessInfo.delayRate = null;
            await fnSubmitUrl(businessInfo);
            // true : 체크 완료
            return true;
          } catch (reject) {
            return false;
          }
        } else {
          CM.cfnAlert(
            "현재 등록된 고객수가 최대 등록가능 고객수에 도달하였습니다.\n최대 등록가능 고객수는 총괄담당자와 이용요금담당자가 변경할 수 있습니다.\n최대 등록가능 고객수 추가를 원하시면, 총괄담당자나 이용요금담당자에게 요청하시기 바랍니다."
          );
          return false;
        }
      }
    }
    return null; // 여유 있음
  };

  const fnSubmitUrl = async (sendData) => {
    return new Promise((resolve, reject) => {
      CM.cfnAxios(
        `/api/institute/business`,
        "put",
        sendData,
        () => {
          store.getBusinessInfo(fnGetBusinssInfo);
          resolve();
        },
        (error) => {
          if (error.response.status === 304) {
            CM.cfnAlert("변경된 내용이 없습니다.", () => {});
          } else {
            let message = "업무정보를 변경하지 못했습니다.";
            if (typeof error.response.data === "string") message = error.response.data;
            else if (typeof error.response.data === "object") message = error.response.data.message;
            CM.cfnAlert(message || error.response);
          }
          reject();
        },
        false,
        true
      );
    });
  };

  /*
   * @desc    업무정보 조회 후 callback 함수
   */
  const fnGetBusinssInfo = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }
    store.setBusinessInfo(data);
  };

  /*
   * @desc    등록버튼 클릭 이벤트 핸들러
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    //고객조회 정보가 없을 경우
    let checkResult = null;
    if (!(readOnlyFlag && CM.cfnIsNotEmpty(selectedCustomer))) {
      //현재 등록 된 고객 수와 기관 최대 고객 수 비교
      checkResult = await checkMaxCustomerCount();
      if (checkResult !== null && !checkResult) {
        return false;
      }
    }
    if (!checkResult) {
      // true인 경우는 이미 한 번 확인 받았으므로 바로 진행, null인 경우에는 확인창 없었으므로 한 번 더 확인
      try {
        await CM.cfnConfirmAsync("등록하시겠습니까?");
      } catch (reject) {
        return false;
      }
    }
    const form = new FormData();
    const sendData = CM.cfnCopyObject(toJS(value));
    const result = await fnSubmit(sendData);
    if (result) {
      if (evidenceFile.file && evidenceFile.fileName && evidenceFile.fileName !== "") {
        form.append("file", evidenceFile.file, evidenceFile.fileName);
      } else {
        form.append("file", evidenceFile.file);
      }
      form.append("contract", new Blob([JSON.stringify(sendData)], { type: "application/json" }));
      let url = "";
      if (Object.isExtensible(location.state) && CM.cfnIsNotEmpty(location.state.receiptCustomer)) {
        url = `/api/customer/receipt/account/financial-institute/registrations/${location.state.fileUniqueKey}/${location.state.askAccountUniqueKey}`;
      } else {
        url = `/api/customer/receipt/receipts`;
      }
      CM.cfnAxios(url, "post", form, fnSubmitCallback, "", true);
    }
  };

  /*
   * @desc 등록버튼 콜백함수
   */
  const fnSubmitCallback = (objStatus, objData) => {
    CM.cfnAlert("정상적으로 등록되었습니다.", () => {
      if (history.length > 0) {
        history.goBack();
      } else {
        history.push("/customer/receiptCustomerInformationList");
      }
    });
  };

  // 목록 버튼을 클릭 시 이동
  const goReceiptCustomerListPage = () => {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push("/customer/receiptCustomerInformationList");
    }
  };

  return (
    <div>
      <div>
        <div hidden={readOnlyFlag}>
          <CreateCustomerDivision
            customer={customer}
            handleCustomerChange={handleCustomerChange}
            changeCustomer={changeCustomer}
            firstIdentificationNo={firstIdentificationNo}
            lastIdentificationNo={lastIdentificationNo}
            handleIdentificationNoChange={handleIdentificationNoChange}
            changeIdentificationNo={changeIdentificationNo}
            checkIdentificationNo={checkIdentificationNo}
            setCheckIdentificationNo={setCheckIdentificationNo}
          />
          <div className="table-bottom-area">
            <button className="search-button" onClick={openSearchCustomerModal}>
              고객 조회
            </button>
            <TooltipForm contents="이미 등록된 고객에게 새로운 수납·지급 방법을 추가할 경우, 고객명 및 주민등록번호 앞자리를 입력 후 고객조회로 검색하시면 됩니다" />
          </div>
          <CustomerSearch modalOpen={modalOpen} handleModalOpenChange={handleModalOpenChange} searchCustomers={searchCustomers} handleSelectedCustomerChange={handleSelectedCustomerChange} />
          <div className="h50" />
        </div>
        <div hidden={!readOnlyFlag}>
          <ViewCustomerDivision customer={customer} />
        </div>
        <CustomerRequiredInputs
          storeCustomerGroup={toJS(store.customerGroup)}
          optionStaffsAndBranches={optionStaffsAndBranches}
          selectRequiredInputs={selectRequiredInputs}
          handleRequiredInputsHandler={handleRequiredInputsHandler}
          readOnlyFlag={readOnlyFlag}
          setStaffModalOpen={setStaffModalOpen}
          setCustomerGroupModalOpen={setCustomerGroupModalOpen}
        />
        <CustomerIndividualSelectInput
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          customerEmail={customerEmail}
          handleCustomerEmailChange={handleCustomerEmailChange}
          selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
          handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
          handleRadioChange={handleCashbillIdentificationNoRadioChange}
          customerDetailInfo={customerDetailInfo}
          handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
          readOnlyFlag={readOnlyFlag}
          checkIdentificationNo={checkIdentificationNo}
        />
        <CustomerBusinessSelectInput
          customer={customer}
          handleCustomerChange={handleCustomerChange}
          changeCustomer={changeCustomer}
          customerDetailInfo={customerDetailInfo}
          handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
          customerEmail={customerEmail}
          handleCustomerEmailChange={handleCustomerEmailChange}
          selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
          handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
          handleRadioChange={handleCashbillIdentificationNoRadioChange}
          readOnlyFlag={readOnlyFlag}
          checkIdentificationNo={checkIdentificationNo}
        />
        <div className="table-bottom-area">&nbsp;</div>
        <div>
          <div data-testid="receiptCustomerInfo">
            <ReceiptInfo
              capitalOptions={capitalOptions}
              dayOptions={dayOptions}
              totalNumberOfPaymentState={totalNumberOfPaymentState}
              payPeriodOption={payPeriodOption}
              startYearOptions={startYearOptions}
              endYearOptions={endYearOptions}
              flag={flag}
              handleClickModal={handleClickModal}
              handleValuesChange={handleValuesChange}
              value={value}
              handleCheckChange={handleCheckChange}
              radioState={radioState}
              handleRadioChange={handleRadioChange}
              fnConvertY4mm={fnConvertY4mm}
              fnCreateMonthOption={fnCreateMonthOption}
              receiptCustomer={Object.isExtensible(location.state) ? location.state.receiptCustomer : ""}
              limitAmountForEachWithdrawal={limitAmountForEachWithdrawal}
            />
            <AccountInfo
              withdrawCriteriaOptions={withdrawCriteriaOptions}
              handleRadioChange={handleRadioChange}
              financialInstitutes={financialInstitutes}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsBankSelected={setIsBankSelected}
              transactionMethod={value.transactionMethod}
              value={value}
              handleValuesChange={handleValuesChange}
              radioState={radioState}
              bankbookPostfixTypeOptions={bankbookPostfixTypeOptions}
              handleCheckChange={handleCheckChange}
              evidenceFile={evidenceFile}
              handleClickModal={handleClickModal}
              handleDeleteFile={handleDeleteFile}
              evidenceSubmissionType={loginStore.getEvidence()}
              readOnlyFlag={readOnlyForEB11}
              smsToCustomerSetting={smsToCustomerSetting}
            />
            <div className="table-bottom-area" style={{ marginBottom: "150px" }}>
              <button type="button" className="btn-l" onClick={handleSubmit}>
                등록
              </button>
              <button type="button" className="btn-m" onClick={goReceiptCustomerListPage}>
                등록취소
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal open={staffModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setStaffModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>담당자 정보</h3>
            <StaffInfo />
          </div>
        </div>
      </Modal>
      <Modal open={customerGroupModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setCustomerGroupModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>고객구분 관리</h3>
            <CustMnmtTable />
          </div>
        </div>
      </Modal>
      <CapitalsModal open={capitalOpen} setOpen={setCapitalOpen} capitalType={capitalType} store={store} setCapitals={fnSetCapitals} />
      <EvidenceFileModal
        open={evidenceFileOpen}
        setOpen={setEvidenceFileOpen}
        originalState={value}
        setOriginalState={setValue}
        evidenceFile={evidenceFile}
        setEvidenceFile={setEvidenceFile}
        fnSubmitEvidenceFile={fnSubmitEvidenceFile}
        fnDeleteEvidenceFile={handleDeleteFile}
        type="create"
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
}))(observer(ReceiptCustomerCreate));
