import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core/";
import TableForm from "../../template/TableForm";
import { SelectForm } from "../../template/ComponentForm";
import * as CM from "../../common/Common";

// 담당자 목록 생성
const getStaffsOption = (staffs) => {
  const staffsList = [{ label: "선택안함", value: "" }];
  staffs.map((staff) => staffsList.push({ label: staff.staffName, value: staff.uniqueKey }));

  return staffsList;
};

// 서비스 시작 여부 검사
const checkUsing = (staffs) => {
  for (const staff of staffs) {
    if (staff.useYn) {
      return true;
    }
  }
  return false;
};

const convertServiceType = (element) => {
  if (element.transactionType === "CMS_WITHDRAW") return "출금이체";
  else if (element.transactionType === "CMS_DEPOSIT") return "입금이체";
  else return "전자세금계산서";
};

// 기관 업무 정보 > 부가서비스 정보 관리 > 업무일정 SMS 사전통지 tab
// 렌더를 처리하는 메인 컴포넌트
function TabWorkSchedulesNotice({ props }) {
  const { store } = props;

  const [notifyFile, setNotifyFile] = useState(CM.cfnCopyObject(props.notifyFile));
  const [loading, setLoading] = useState(false);

  // staffs 목록 가져오기
  useEffect(() => {
    const startAxios = async () => {
      await store.axiosStaffs();
      setLoading(true);
    };

    startAxios();
  }, [store]);

  // 통지일 변경
  const handleDateChange = ({ target: { value } }, key, index) => {
    let apply = "";

    if (CM.cfnIsNotEmpty(value)) {
      const find = notifyFile.find((e, i) => i === index);
      for (let i = 1; i < 4; i++) {
        const prev = String(find[`transactionNotifyDay${i}`]);
        if (prev === value) {
          CM.cfnAlert("같은 통지일을 선택할 수 없습니다.");
          return;
        }
      }
      apply = Number(value);
    }

    setNotifyFile((data) => data.map((e, i) => (i === index ? { ...e, [key]: apply } : { ...e })));
  };

  // 담당자 변경
  const handleStaffChange = ({ target: { value } }, key, index) => {
    const staffList = store.staffs.filter((staff) => staff.uniqueKey === value);

    const staff = staffList.length > 0 ? staffList[0] : { uniqueKey: "" };
    const transactionNotifyDay1 = staffList.length > 0 ? notifyFile[index].transactionNotifyDay1 : "";
    const transactionNotifyDay2 = staffList.length > 0 ? notifyFile[index].transactionNotifyDay2 : "";
    const transactionNotifyDay3 = staffList.length > 0 ? notifyFile[index].transactionNotifyDay3 : "";

    setNotifyFile((data) => data.map((e, i) => (i === index ? { ...e, [key]: staff, transactionNotifyDay1, transactionNotifyDay2, transactionNotifyDay3 } : { ...e })));
  };

  // 서비스 신청 또는 설정 변경
  const updateSetting = (status) => {
    let applyFlag = 0;

    const sendData = CM.cfnCopyObject(notifyFile);

    for (const data of sendData) {
      if (data.transactionNotifyDay1 === 0) {
        data.transactionNotifyDay1 = null;
      }
      if (data.transactionNotifyDay2 === 0) {
        data.transactionNotifyDay2 = null;
      }
      if (data.transactionNotifyDay3 === 0) {
        data.transactionNotifyDay3 = null;
      }
      if (!Object.isExtensible(data.staff) || CM.cfnIsEmpty(data.staff.uniqueKey)) {
        if (CM.cfnIsNotEmpty(data.transactionNotifyDay1) || CM.cfnIsNotEmpty(data.transactionNotifyDay2) || CM.cfnIsNotEmpty(data.transactionNotifyDay3)) {
          CM.cfnAlert(convertServiceType(data) + "의 수신자를 선택해주세요.");
          return;
        }
        data.useYn = false;
      } else {
        if (CM.cfnIsEmpty(data.transactionNotifyDay1) && CM.cfnIsEmpty(data.transactionNotifyDay2) && CM.cfnIsEmpty(data.transactionNotifyDay3)) {
          CM.cfnAlert(convertServiceType(data) + "의 통지일을 하나 이상 입력해주세요.");
          return;
        }
        data.useYn = true;
        applyFlag++;
      }
    }

    if (status === "apply" && applyFlag < 1) {
      CM.cfnAlert("최소 하나의 통지일과 수신자를 선택해주세요.");
      return;
    }

    getAxios(sendData);
  };

  const terminateSetting = () => {
    const clone = CM.cfnCopyObject(notifyFile);

    const sendData = clone.map((data) => ({
      ...data,
      transactionNotifyDay1: null,
      transactionNotifyDay2: null,
      transactionNotifyDay3: null,
      useYn: false,
      staff: { uniqueKey: null },
    }));

    getAxios(sendData);
  };

  // 기관 작업안내 정보 변경을 요청하는 axios
  const getAxios = (sendData) => {
    const url = "api/institute/business/notifyFile";
    CM.cfnAxios(url, "put", sendData, (status, data) => {
      CM.cfnAlert("처리되었습니다.", () => {
        setNotifyFile(sendData);
        props.updateNotifyFile(sendData);
      });
    });
  };

  if (!loading) {
    return null;
  }

  return (
    <div>
      <div className="modify-area">
        <div className="block">{checkUsing(notifyFile) ? "현재 업무일정 사전 통지 서비스를 이용 중입니다." : "다음 내용으로 업무일정 사전 통지 서비스를 신청합니다."}</div>
      </div>
      <div>
        <h4>서비스 설정(업무 일정 SMS 사전 통지)</h4>
        <ServiceList notifyFile={notifyFile} staffs={store.staffs} handleDateChange={handleDateChange} handleStaffChange={handleStaffChange} />
        <ServiceButtonForm notifyFile={notifyFile} updateSetting={updateSetting} terminateSetting={terminateSetting} />
      </div>
      <div className="inforbox">
        <h4>서비스안내</h4>
        <ul>
          <li>업무가 많은 날짜를 최대 3개까지 등록해 놓으면 담당자 앞으로 해당일자 오전 9시경에 SMS를 전송해 드리는 서비스입니다.</li>
          <li>
            본 서비스는 담당자 편의를 위하여 제공되는 무료서비스로써 공휴일 등을 감안하여 SMS 발송일자가 자동으로 조정되지 않으며, 통신사의 일시적인 장애, 시스템 점검 등으로 SMS수신이 안 될 수도
            있으니, 본 서비스에 너무 의존하시어 출금요청 업무에 차질이 발생하는 일이 없도록 주의하시기 바랍니다.
          </li>
        </ul>
      </div>
    </div>
  );
}

// 서비스 설정 버튼
function ServiceButtonForm(props) {
  if (checkUsing(props.notifyFile)) {
    return (
      <div className="table-bottom-area">
        <button className="btn-l" onClick={props.updateSetting} data-testid="tabWorkSchedulesNotice-button-updateService">
          설정 변경
        </button>
        <button className="btn-l" onClick={props.terminateSetting} data-testid="tabWorkSchedulesNotice-button-terminateService">
          서비스 해지
        </button>
      </div>
    );
  }

  return (
    <div className="table-bottom-area">
      <button className="btn-l" onClick={() => props.updateSetting("apply")} data-testid="tabWorkSchedulesNotice-button-requestService">
        서비스 신청
      </button>
    </div>
  );
}

// 서비스 목록
function ServiceList(props) {
  const optionDate = CM.cfnSelectOptionDate("integer");
  const optionStaffs = getStaffsOption(props.staffs);

  return (
    <Table>
      {CM.cfnCompColGroup(["20%", "16%", "16%", "16%", "16%", "auto"])}
      <TableForm.compTableHead arrData={["통지대상업무", "통지일1", "통지일2", "통지일3", "수신자", "발송예정시간"]} />
      <TableBody>
        {props.notifyFile.map((element, index) => {
          return (
            <TableRow key={index} hover>
              <TableCell align="center">{convertServiceType(element)}</TableCell>
              <TableCell align="center">
                <SelectForm
                  value={element.transactionNotifyDay1 || ""}
                  arrayOption={optionDate}
                  handleChange={(e) => props.handleDateChange(e, "transactionNotifyDay1", index)}
                  customProps={{ "data-testid": `tabWorkSchedulesNotice-select-${index}-transactionNotifyDay1` }}
                />
              </TableCell>
              <TableCell align="center">
                <SelectForm
                  value={element.transactionNotifyDay2 || ""}
                  arrayOption={optionDate}
                  handleChange={(e) => props.handleDateChange(e, "transactionNotifyDay2", index)}
                  customProps={{ "data-testid": `tabWorkSchedulesNotice-select-${index}-transactionNotifyDay2` }}
                />
              </TableCell>
              <TableCell align="center">
                <SelectForm
                  value={element.transactionNotifyDay3 || ""}
                  arrayOption={optionDate}
                  handleChange={(e) => props.handleDateChange(e, "transactionNotifyDay3", index)}
                  customProps={{ "data-testid": `tabWorkSchedulesNotice-select-${index}-transactionNotifyDay3` }}
                />
              </TableCell>
              <TableCell align="center">
                <SelectForm
                  value={Object.isExtensible(element.staff) ? element.staff.uniqueKey || "" : ""}
                  arrayOption={optionStaffs}
                  handleChange={(e) => props.handleStaffChange(e, "staff", index)}
                  customProps={{ "data-testid": `tabWorkSchedulesNotice-select-${index}-staff` }}
                />
              </TableCell>
              <TableCell align="center">09:00경</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default inject((rootStore, props) => ({
  props: props,
  store: rootStore.instituteStore,
}))(observer(TabWorkSchedulesNotice));
