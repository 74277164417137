import { Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as PaymentStatisticsChartEnum from "./PaymentStatisticsChartEnum";

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

function CreateSearchForm(props) {
  const { searchParam, changeSearchFormHandler, capitalUniqueKey, selectOptionYear } = props;

  const fnClickSummarySearch = () => {
    props.changeSearchButtonHandler(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">통계연도</label>
        <Select native data-testid="targetYear_selectBox" name="targetYear" value={searchParam.targetYear} onChange={changeSearchFormHandler("targetYear")}>
          {selectOptionYear.map((option, index) => {
            return (
              <option value={option} key={index}>
                {option}년
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native data-testid="capitalUniqueKey_selectBox" value={searchParam.capitalUniqueKey} onChange={changeSearchFormHandler("capitalUniqueKey")} name="capitalUniqueKey">
          {capitalUniqueKey.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">지급방법</label>
        <Select native data-testid="transactionMethod_selectBox" name="transactionMethod" value={searchParam.transactionMethod} onChange={changeSearchFormHandler("transactionMethod")}>
          {PaymentStatisticsChartEnum.getSearchOption("transactionMethod").map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native data-testid="searchProperty_selectBox" name="searchProperty" value={searchParam.searchProperty} onChange={changeSearchFormHandler("searchProperty")}>
          {PaymentStatisticsChartEnum.getSearchOption("searchProperty").map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>

        <TextField
          data-testid="searchContents_inputBox"
          className="w150"
          value={searchParam.searchContents}
          onChange={changeSearchFormHandler("searchContents")}
          // onKeyUp={handleSearKeyUp}
          name="searchContents"
        />

        <button className="search-button" data-testid="summarySearch_button" onClick={() => fnClickSummarySearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

function CreateListForm(props) {
  return (
    <div className="scroll-table">
      <div className="left-table-div-top">
        <Table>
          {CM.cfnCompColGroup(["24%", "auto", "47%"])}
          <TableForm.compTableHead arrData={["구분", "건수", "총액"]} />

          {props.topList.length === 0 ? (
            <TableBody>
              <TableForm.compEmptyTableRow colSpan={3} />
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" data-testid={"summaryList1_startCell"}>
                  {" "}
                  지급{" "}
                </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPaidCount)}건 </TableCell>
                <TableCell align="right" id="test1" data-testid="test1">
                  {" "}
                  {CM.cfnAddComma(props.topList.totalPaidAmount)}원{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" data-testid={"summaryList2_startCell"}>
                  {" "}
                  미지급{" "}
                </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnpaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnpaidAmount)}원 </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </div>
      <div className="right-table-div-top">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"])}
            <TableForm.compTableHead arrData={[...Array(12).keys()].map((value) => (typeof value === "number" ? `${value + 1}월` : value))} />
            <TableBody>
              {props.topList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={12} />
              ) : (
                <Fragment>
                  <TableRow>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[0])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[1])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[2])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[3])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[4])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[5])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[6])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[7])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[8])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[9])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[10])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.paidAmounts[11])}원 </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[0])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[1])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[2])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[3])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[4])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[5])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[6])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[7])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[8])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[9])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[10])}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(props.topList.unpaidAmounts[11])}원 </TableCell>
                  </TableRow>
                </Fragment>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="table-bottom-area"></div>
    </div>
  );
}

function CreateBottomListForm(props) {
  return (
    <div>
      <div className="left-table-div">
        <Table>
          {CM.cfnCompColGroup(["auto", "20%", "12%", "14%", "14%", "12%", "14%"])}
          <TableHead className="table-head-line">
            <TableRow key="headRow">
              <TableCell className="th">고객명</TableCell>
              <TableCell className="th">생년월일(사업자번호)</TableCell>
              <TableCell className="th">지급방법</TableCell>
              <TableCell className="th">총지급금액</TableCell>
              <TableCell className="th">청구중금액</TableCell>
              <TableCell className="th">미지급건수</TableCell>
              <TableCell className="th">미지급금액</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.bottomList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={7} />
            ) : (
              props.bottomList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center" data-testid={"detailList1_" + index} title={row.customerName} className="ellipsis" style={{ maxWidth: "12%" }}>
                      {row.customerName}
                    </TableCell>
                    <TableCell className="ellipsis" align="center" title={CM.cfnIdentificationNoFormat(row.identificatioNo || "")}>
                      {" "}
                      {CM.cfnIdentificationNoFormat(row.identificationNo || "")}{" "}
                    </TableCell>
                    <TableCell align="center">{PaymentStatisticsChartEnum.getApiAvailableLabel(row.transactionMethod)}</TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalAskingAmount)}원 </TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.totalUnpaidCount)}건</TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnpaidAmount)}원 </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"])}
            <TableHead className="table-head-line">
              <TableRow key="headRow">
                <TableCell className="th">1월</TableCell>
                <TableCell className="th">2월</TableCell>
                <TableCell className="th">3월</TableCell>
                <TableCell className="th">4월</TableCell>
                <TableCell className="th">5월</TableCell>
                <TableCell className="th">6월</TableCell>
                <TableCell className="th">7월</TableCell>
                <TableCell className="th">8월</TableCell>
                <TableCell className="th">9월</TableCell>
                <TableCell className="th">10월</TableCell>
                <TableCell className="th">11월</TableCell>
                <TableCell className="th">12월</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props.bottomList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={12} />
              ) : (
                props.bottomList.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="right" data-testid={"detailList2_" + index}>
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[0])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[1])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[2])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[3])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[4])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[5])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[6])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[7])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[8])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[9])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[10])}
                        {"원 "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {CM.cfnAddComma(row.paidAmounts[11])}
                        {"원 "}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

function defaultSearchParam() {
  const today = new Date();

  const currentYYYY = today.getFullYear();

  return {
    searchContents: "", //검색내용
    searchProperty: "CUSTOMER_NAME", //검색대상 - CUSTOMER_NAME(고객명), IDENTIFICATION_NO(고객식별번호), IDENTIFICATION_NO(생년월일사업자번호)
    transactionMethod: "", //수납방법 - null 또는 empty(전체), CMS_WITHDRAW(CMS출금), ETC(기타수납)
    capitalUniqueKey: "", //자금종류key
    sortProperty: "CUSTOMER_NAME", //정렬기준 -  CUSTOMER_NAME(고객명), IDENTIFICATION_NO(생년월일사업자번호)
    sortDirection: "ASC", //정렬방향 - ASC(오름차순), DESC(내림차순)
    targetYear: currentYYYY,
    pageNumber: "0", //페이지 번호
    pageSize: "5", //페이지 크기
  };
}

//Main Component
const PaymentCustomerStatistics = (props) => {
  const { store } = props;
  const { tabIndex } = props;
  const [searchParam, setSearchParam] = useState(defaultSearchParam());
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [topList, setTopList] = useState([]);
  const [bottomList, setBottomList] = useState([]);
  const [pagination, setPagination] = useState(paginationData());
  const [bottomListTotalCount, setBottomListTotalCount] = useState();
  const [capitalUniqueKey, setCapitalUniqueKey] = useState([]);
  const [selectOptionYear, setSelectOptionYear] = useState([]);

  /*
   * @desc    자금종류 셀렉트박스 옵션 요소 생성 함수
   */
  const fnSetCapitalOptions = (data) => {
    let tempArray = [];
    tempArray.push({ label: "전체", value: "", key: "" });
    if (CM.cfnIsNotEmpty(data)) {
      data.forEach((row) => {
        if (row.capitalType === "PAYMENT")
          tempArray.push({
            label: row.capitalName,
            value: row.uniqueKey,
            key: row.uniqueKey,
          });
      });
    }
    setCapitalUniqueKey(tempArray);
  };

  const setSelectBoxYearList = () => {
    const today = new Date();
    const currentYYYY = today.getFullYear();
    const startOptionYear = 2005;
    const lastOptionYear = currentYYYY;

    let selectOptionYearList = [];
    for (var i = lastOptionYear; i >= startOptionYear; i--) {
      selectOptionYearList.push(i);
    }
    setSelectOptionYear(selectOptionYearList);
  };

  useEffect(() => {
    setSelectBoxYearList();
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      await store.getCapital(fnSetCapitalList);
    };

    /*
     * @desc    자금종류 조회 Request Callback
     */
    const fnSetCapitalList = (objStatus, responseData) => {
      if (objStatus.status === 200) {
        fnSetCapitalOptions(responseData);
      }
    };

    if (CM.cfnIsEmpty(store.capital.$mobx.values)) {
      fnFetchCapitalList();
    } else {
      fnSetCapitalOptions(toJS(store.capital));
    }

    let queryString = `?searchContents=${searchParam.searchContents}&searchProperty=${searchParam.searchProperty}&transactionMethod=${searchParam.transactionMethod}&capitalUniqueKey=${searchParam.capitalUniqueKey}&sortProperty=${searchParam.sortProperty}&sortDirection=${searchParam.sortDirection}&targetYear=${searchParam.targetYear}&pageNumber=${searchParam.pageNumber}&pageSize=${searchParam.pageSize}
            `;

    const getTopListAxios = (searchParam) => {
      return new Promise((resolve) => {
        let url = `api/payment/statistics/customer/summary` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseTopListData) => {
          resolve(responseTopListData);
        });
      });
    };

    const getBottomListAxios = (searchParam) => {
      return new Promise((resolve) => {
        let url = `api/payment/statistics/customer` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseBottomListData) => {
          resolve(responseBottomListData);
        });
      });
    };

    const startAxios = async (searchParam) => {
      //고객별 수납통계 요약내용 조회
      getTopListAxios(searchParam)
        .then((responseSummaryData) => {
          setTopList(responseSummaryData);
        })
        .catch((error) => {
          console.error(error);
        });

      getBottomListAxios(searchParam)
        .then((responseDetailData) => {
          const resultDetailData = responseDetailData.content;

          setBottomListTotalCount(responseDetailData.totalElements);
          setBottomList(resultDetailData);

          setPagination(paginationData(responseDetailData));
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (tabIndex === 0 && searchButton === true) {
      startAxios(searchParam);
      setSearchButton(false);
    }
  }, [tabIndex, searchButton, store, searchParam]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    changeSearchParamHandler((data) => ({ ...data, pageNumber: page - 1 }));
    changeSearchButtonHandler(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    changeSearchParamHandler((data) => ({ ...data, pageSize: value }));
    changeSearchButtonHandler(true);
  };

  const changeSearchButtonHandler = (flag) => {
    setSearchButton(flag);
  };

  const changeSearchParamHandler = (searchParam) => setSearchParam(searchParam);

  const changeSearchFormHandler = (name) => (e) => {
    const changedValue = e.target.value;
    setSearchParam((searchParam) => ({
      ...searchParam,
      [name]: changedValue,
    }));
  };

  const customerExcelDownload = (e) => {
    let param = [];
    Object.keys(searchParam).forEach((key) => param.push(key + "=" + searchParam[key]));
    let url = "/api/payment/statistics/customer/excel?" + param.join("&");
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };
  return (
    <div id="customerStatisticsWrapper" data-testid="customerStatisticsWrapper" style={{ width: "1180px" }}>
      {/* 요약 내용 목록 검색 폼 */}
      <CreateSearchForm
        searchParam={searchParam}
        capitalUniqueKey={capitalUniqueKey}
        changeSearchFormHandler={changeSearchFormHandler}
        changeSearchButtonHandler={changeSearchButtonHandler}
        selectOptionYear={selectOptionYear}
      />

      {/* 요약 내용 목록 폼 */}
      <CreateListForm topList={topList} />
      <div className="table-top-area">
        <TotalCountForm totalElements={bottomListTotalCount} />
        <RowPerPageForm value={searchParam.pageSize} onChange={handleRowPerPageChange} />
        <button className="btn-m table-top-button" onClick={(event) => customerExcelDownload()} data-testid="save-excel">
          엑셀저장
        </button>
      </div>

      {/* 상세 목록 폼 */}
      <CreateBottomListForm bottomList={bottomList} pagination={pagination} page={searchParam.pageNumber} totalCount={bottomListTotalCount} pageSize={searchParam.pageSize} />

      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaymentCustomerStatistics));
