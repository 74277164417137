import * as CM from "../../common/Common";

const StaffInfoData = {
  //담당저 정보 화면 테이블 헤더
  staffTableheadRows: [
    {
      id: "branch",
      numeric: false,
      disablePadding: false,
      label: "소속지사",
      sortable: true,
    },
    { id: "staffName", numeric: false, disablePadding: false, label: "이름", sortable: true },
    {
      id: "staffMobile",
      numeric: false,
      disablePadding: false,
      label: "휴대전화",
      sortable: false,
    },
    {
      id: "staffTelephone",
      numeric: false,
      disablePadding: false,
      label: "일반전화",
      sortable: false,
    },
    {
      id: "loginYn",
      numeric: false,
      disablePadding: false,
      label: "로그인\n담당자",
      sortable: false,
    },
    {
      id: "masterYn",
      numeric: false,
      disablePadding: false,
      label: "총괄\n담당자",
      sortable: false,
    },
    {
      id: "billingManagerYn",
      numeric: false,
      disablePadding: false,
      label: "이용요금\n담당자",
      sortable: false,
    },
    {
      id: "cashbillManagerYn",
      numeric: false,
      disablePadding: false,
      label: "현금영수증\n담당자",
      sortable: false,
    },
    {
      id: "trusbillManagerYn",
      numeric: false,
      disablePadding: false,
      label: "전자세금계산서\n담당자",
      sortable: false,
    },
  ],
  //담당자 정보 화면 내 검색 셀렉트 박스 옵션
  searchKeywordOptions: [
    {
      label: "전체",
      value: "",
    },
    {
      label: "담당자명",
      value: "staffName",
    },
    {
      label: "소속지사",
      value: "branchName",
    },
  ],
  //담당자 정보 화면 검색조건
  staffSearchCondition: {
    term: "",
    keyword: "",
    sortProperty: "staffName",
    sortDirection: "ASC",
  },
  //paging 처리
  paginationData: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
    pageNumber: 0,
  },
  fnConvertBirthDateToString: (obj) => {
    let birthDate = obj;

    if (CM.cfnIsNotEmpty(birthDate) && obj.getFullYear) {
      birthDate = CM.cfnConvertDateToString(birthDate).substr(2, 6);
    }

    return birthDate;
  },
  //담당자정보 테이블 sorting 관련 함수 선언 start
  desc: (a, b, orderBy) => {
    if (orderBy === "branch") {
      if (b[orderBy].branchName < a[orderBy].branchName) {
        return -1;
      }
      if (b[orderBy].branchName > a[orderBy].branchName) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  },
  stableSort: (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  },
  //담당자정보 테이블 sorting 관련 함수 선언 end
};

export default StaffInfoData;
