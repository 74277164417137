import { observable, action, toJS } from "mobx";
import * as CM from "../components/common/Common";

/*
 * @desc    기관 정보 store
 *          {
 *            기관 부가서비스 정보, 현금영수증 신청정보, 자동출금 관련정보, 담당자 지점, 담당자 목록
 *          }
 * @modify  19-09-28: staff store과 합침
 */
class InstituteStore {
  constructor(root) {
    this.root = root;
  }

  @observable
  instituteService = []; // 기관 부가서비스 정보 (출금결과 알림(SMS), 전자세금계산서 사용 정보)

  @observable
  cashbillInfo = {}; // 현금영수증 신청 정보

  @observable
  autoWithdraw = {}; // 자동출금 관련 정보

  @observable
  branches = []; //담당자 지점 목록

  @observable
  staffs = []; //담당자 목록

  @observable
  master = {}; // 기관 정보

  @observable
  customerGroup = []; // 고객관리 정보

  @observable
  capital = []; // 자금관리 정보

  @observable
  paymentCapital = []; //지급자금 정보

  @observable
  receiptCapital = []; //수납자금 정보

  @observable
  businessInfo = {}; //업무정보

  @observable
  financialInstitutes = []; // 금융기관

  @observable
  financialRepresentativeInstitutes = [];

  @action("InstituteStore 초기화")
  initialInstituteStore() {
    this.setInstituteService([]);
    this.setCashbillInfo({});
    this.setAutoWithdraw({});
    this.setBranches([]);
    this.setStaffs([]);
    this.setMaster({});
    this.setCustomerGroup([]);
    this.setCapital({});
    this.setBusinessInfo({});
    this.setFinancialInstitutes([]);
    this.setFinancialRepresentativeInstitutes([]);
    this.setPaymentCapital([]);
    this.setReceiptCapital([]);
    this.serviceTerminationYn = "N";
  }

  @action("기관 부가서비스 정보 setter")
  setInstituteService = objData => {
    this.instituteService = objData;
  };

  @action("현금영수증 신청 정보 setter")
  setCashbillInfo = objData => {
    this.cashbillInfo = objData;
  };

  @action("자동출금 관련 정보 setter")
  setAutoWithdraw = objData => {
    this.autoWithdraw = objData;
  };

  @action("담당자 지점 목록 setter")
  setBranches = data => {
    this.branches = data;
  };

  @action("담당자 목록 setter")
  setStaffs = data => {
    this.staffs = data;
  };

  @action("기관 정보 setter")
  setMaster = objData => {
    this.master = objData;
  };

  @action("고객그룹 전체조회 setter")
  setCustomerGroup = data => {
    this.customerGroup = data;
  };

  @action("자금종류 전체조회 setter")
  setCapital = data => {
    this.capital = data;
  };

  @action("지급자금종류 전체조회 setter")
  setPaymentCapital = data => {
    this.paymentCapital = data;
  };

  @action("수납자금종류 전체조회 setter")
  setReceiptCapital = data => {
    this.receiptCapital = data;
  };

  @action("업무정보 setter")
  setBusinessInfo = objData => {
    this.businessInfo = objData;
  };

  @action("금융기관 setter")
  setFinancialInstitutes = data => {
    this.financialInstitutes = data;
  };

  @action("대표금융기관 setter")
  setFinancialRepresentativeInstitutes = data => {
    this.financialRepresentativeInstitutes = data;
  };

  /*
   * @desc   axios Request 실행
   * @param   { url }
   * @return  { response }
   */
  getAxios(url) {
    return new Promise((resolve, reject) => {
      CM.cfnAxios(url, "get", "", (status, response) => {
        resolve(response);
      });
    });
  }

  @action("기관 부가서비스 정보 목록 조회")
  async axiosInstituteService() {
    if (CM.cfnIsNotEmpty(toJS(this.instituteService))) return toJS(this.instituteService);

    const result = await this.getAxios("api/institute/services");
    this.setInstituteService(result);
    return result;
  }

  @action("현금영수증 신청 정보 조회")
  async axiosCashbillInfo() {
    if (CM.cfnIsNotEmpty(toJS(this.cashbillInfo))) return toJS(this.cashbillInfo);

    const result = await this.getAxios("api/institute/business/cashbill");
    this.setCashbillInfo(result);
    return result;
  }

  @action("자동출금 관련 정보 조회")
  async axiosAutoWithdraw() {
    if (CM.cfnIsNotEmpty(toJS(this.autoWithdraw))) return toJS(this.autoWithdraw);

    const result = await this.getAxios("api/institute/business/autoWithdraw");
    this.setAutoWithdraw(result);
    return result;
  }

  @action("담당자 목록 조회")
  async axiosStaffs() {
    if (CM.cfnIsNotEmpty(toJS(this.staffs))) return toJS(this.staffs);

    const result = await this.getAxios("api/institute/staffs");
    this.setStaffs(result);
    return result;
  }

  @action("담당자 지점 목록")
  async axiosBranches() {
    if (CM.cfnIsNotEmpty(toJS(this.branches))) return toJS(this.branches);

    const result = await this.getAxios("api/institute/branches");
    this.setBranches(result);
    return result;
  }

  @action("자금종류 전체목록 조회")
  async axiosCapitals() {
    if (CM.cfnIsNotEmpty(toJS(this.capital))) return toJS(this.capital);

    const result = await this.getAxios("api/institute/capitals");
    this.setCapital(result);
    return result;
  }

  @action("지급자금종류 전체목록 조회")
  async axiosPaymentCapitals() {
    let tempArray = toJS(this.capital);
    const paymentArray = [];

    if (CM.cfnIsEmpty(tempArray)) {
      const result = await this.getAxios("api/institute/capitals");
      this.setCapital(result);
      tempArray = result;
    }

    for (const capital of tempArray) {
      if (capital.capitalType === "PAYMENT") paymentArray.push(capital);
    }

    this.setPaymentCapital(paymentArray);
    return paymentArray;
  }

  @action("수납자금 전체목록 조회")
  async axiosReceiptCapitals() {
    let tempArray = toJS(this.capital);
    const receiptArray = [];

    if (CM.cfnIsEmpty(tempArray)) {
      const result = await this.getAxios("api/institute/capitals");
      this.setCapital(result);
      tempArray = result;
    }

    for (const capital of tempArray) {
      if (capital.capitalType === "RECEIPT") receiptArray.push(capital);
    }

    this.setReceiptCapital(receiptArray);
    return receiptArray;
  }

  @action("고객구분 목록 조회")
  async axiosCustomerGroup() {
    if (CM.cfnIsNotEmpty(toJS(this.customerGroup))) return toJS(this.customerGroup);

    const result = await this.getAxios("api/institute/groups");
    this.setCustomerGroup(result);
    return result;
  }

  @action("금융기관 목록 조회")
  async axiosFinancialInstitutes() {
    if (CM.cfnIsNotEmpty(toJS(this.financialInstitutes))) return toJS(this.financialInstitutes);

    const result = await this.getAxios("api/gadget/financial-institutes");
    this.setFinancialInstitutes(result);
    return result;
  }

  @action("대표금융기관 목록 조회")
  async axiosFinancialRepresentativeInstitutes() {
    if (CM.cfnIsNotEmpty(toJS(this.financialRepresentativeInstitutes))) return toJS(this.financialRepresentativeInstitutes);

    const result = await this.getAxios("api/gadget/financial-representative-institutes");
    this.setFinancialRepresentativeInstitutes(result);
    return result;
  }

  @action("기관 정보 조회")
  async axiosMaster() {
    if (CM.cfnIsNotEmpty(toJS(this.master))) {
      return toJS(this.master);
    }

    const result = await this.getAxios("api/institute/informations");
    this.setMaster(result);
    return result;
  }

  @action("업무 정보 조회")
  async axiosBusinessInfo() {
    if (CM.cfnIsNotEmpty(toJS(this.businessInfo))) {
      return toJS(this.businessInfo);
    }

    const result = await this.getAxios("api/institute/business");
    this.setBusinessInfo(result);
    return result;
  }

  /*
   * @desc   담당자 지점 목록 조회 Request 실행
   * @param   { function }
   * @return  { none }
   */
  @action("담당자 지점 목록 조회")
  getBranches = async callback => {
    const url = "api/institute/branches";
    CM.cfnAxios(url, "get", "", callback);
  };

  /*
   * @desc   담당자 목록 조회 Request 실행
   * @param   { function }
   * @return  { none }
   */
  @action("담당자 목록 조회")
  getStaffs = async callback => {
    const url = "api/institute/staffs";
    CM.cfnAxios(url, "get", "", callback);
  };

  /*
   * @desc   기관 정보 조회 Request 실행
   * @param   { function }
   * @return  { none }
   */
  @action("기관 정보 조회")
  getMaster = fnCallback => {
    const url = "api/institute/informations";
    CM.cfnAxios(url, "get", "", fnCallback);
  };

  @action("고객그룹 전체조회")
  getCustomerGroup = fnCallback => {
    const url = "api/institute/groups";
    CM.cfnAxios(url, "get", "", fnCallback);
  };

  @action("자금종류 전체조회")
  getCapital = fnCallback => {
    const url = "api/institute/capitals";
    CM.cfnAxios(url, "get", "", fnCallback);
  };

  @action("업무정보")
  getBusinessInfo = fnCallback => {
    const url = "/api/institute/business";
    CM.cfnAxios(url, "get", "", fnCallback);
  };

  @observable serviceTerminationYn = "N";
  @action
  setServiceTerminationYn = serviceTerminationYn => {
    this.serviceTerminationYn = serviceTerminationYn;
  };
}

export default InstituteStore;
