import React from "react";
import { Modal, Button } from "@material-ui/core";
const PersonalInfoModalVersion1 = ({ open, setOpen }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="personalInfoVer1-close-modal">
              {""}
            </Button>
          </div>
          <div className="ftm2">
            <h3 className="title">개인정보 처리방침 (2010. 7. 19 ~ 2015. 12. 6 적용)</h3>
            <div className="content-box-scroll-back">
              <div className="content-box-scroll">
                <h5 className="title">1. 홈페이지 이용고객에 대한 개인정보처리방침 </h5>
                <p>
                  금융결제원 빌링원 홈페이지를 방문하여 주셔서 대단히 감사합니다. <br />
                  금융결제원은 이용고객의 개인정보를 무엇보다 중요하게 생각하고, 이용고객이 안심하고 이용할 수 있도록 개인정보보호에 최선을 다하고 있습니다.
                  <br />
                  또한 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등에 따라 「개인정보처리방침」을 아래와 같이 수립하여 시행하고 있습니다.
                </p>
                <h5 className="title">2. 개인정보 수집에 대한 동의</h5>
                <p>
                  빌링원 홈페이지에서는 이용고객이 개인정보 수집에 대한 동의여부를 선택할 수 있도록 개인정보 수집 및 이용, 고유식별정보수집 및 이용에 동의할 수
                  있는 절차를 마련하고 있습니다. <br />
                  다만, 서면으로 서비스 이용 신청서를 제출한 경우 정보수집 및 이용에 대한 별도의 동의서를 받고 있습니다.
                </p>
                <h5 className="title">3. 개인정보</h5>
                <p>이용목적 및 수집항목 빌링원 홈페이지에서는 다음과 같은 목적을 위하여 최소한의 개인정보를 수집하고 있습니다.</p>
                <ul>
                  <li>이용고객 인증 : 성명, 생년월일, ID, 비밀번호, 휴대폰번호, E-Mail주소</li>
                  <li>
                    금융(세무)서비스 이용 : 거래금융기관명, 계좌번호, 휴대폰번호, 생년월일, 주민등록번호(현금영수증, 기부금영수증 발급 등 주민등록번호 수집에
                    대한 법적근거가 있는 업무에 한함)
                  </li>
                  <li>이용고객과 연락 : 전화번호, E-Mail주소, 주소</li>
                  <br />
                  <br />* 이용고객의 기본적 인권 침해 우려가 있는 민감한 개인정보는 수집하지 않습니다.
                </ul>
                <h5 className="title">4. 개인정보 주체의 권리 및 행사방법</h5>
                <p>이용고객(정보주체)은 개인정보의 처리와 관련하여 다음의 권리를 가집니다.</p>
                <ul>
                  <li>개인정보의 처리에 관한 정보를 제공받을 권리</li>
                  <li>개인정보의 처리에 관한 동의 여부, 동의 범위 등을 선택하고 결정할 수 있는 권리</li>
                  <li>개인정보에 대한 열람, 정정ㆍ삭제 및 파기를 요구할 권리</li>
                  <li>개인정보 처리로 인하여 발생한 피해를 구제받을 권리 </li>
                </ul>
                <p>개인정보에 대한 열람, 정정 등의 권리는 다음과 같이 행사 가능합니다.</p>
                <ul>
                  <li>개인정보의 열람 : 홈페이지에서 조회</li>
                  <li>개인정보의 정정 : 홈페이지에서 수정 및 별도의 신청서로 신청</li>
                  <li>개인정보의 삭제(처리정지) : 홈페이지 회원탈퇴 및 별도의 신청서로 신청</li>
                </ul>
                <h5 className="title">5. 개인정보 보유기간 및 이용기간</h5>
                <p>
                  빌링원 서비스 이용을 위하여 제출된 개인정보는 이용고객이 해지를 요청할 때까지 금융결제원에서 안전하게 보유하고 있으며, 해지를 요청한 경우에는
                  해지완료일에 지체없이 파기하고 있습니다.
                  <br />
                  다만, 관계 법령에 의거 별도로 정한 정보는 일정기간 동안 보관합니다.{" "}
                </p>
                <h5 className="title">6. 개인정보를 제3자에게 제공하는 경우</h5>
                <p>
                  이용자의 개인정보는 본인의 동의가 있거나, 관련법령에 저촉이 되지 않는 경우에만 제3자에게 제공합니다.
                  <br />
                  다만, 동의를 받을 때에는 다음의 사항을 정보주체에게 알림니다.{" "}
                </p>
                <ul>
                  <li>개인정보를 제공받는 자</li>
                  <li>개인정보를 제공받는 자의 개인정보 이용 목적</li>
                  <li>제공받는 개인정보의 항목</li>
                  <li>개인정보를 제공받는 자의 개인정보 보유 및 이용기간</li>
                  <li>동의를 거부할 권리가 있다는 사실 및 거부에 따른 불이익</li>
                </ul>
                <h5 className="title">7. 정보주체 이외로부터 수집한 개인정보의 수집 출처 등 고지</h5>
                <p>
                  금융결제원 빌링원은 정보주체 이외로부터 수집한 개인정보는 정보주체의 요구가 있으면 법률에 별도로 정하는 경우에 해당하지 않는 한 다음 사항을
                  즉시 정보주체에게 알려드립니다.
                </p>
                <ul>
                  <li>개인정보의 수집출처 및 처리목적</li>
                  <li>개인정보처리의 정지를 요구할 권리가 있다는 사실</li>
                </ul>
                <h5 className="title">8. 개인정보보호를 위한 보안 조치</h5>
                <p>
                  이용고객의 개인정보는 접근 및 관리에 필요한 최소한의 인원으로 사용자를 지정한 후 비밀번호를 통해 철저히 관리하고 있으며 개인정보를 취급하는
                  직원에 대한 정기적인 교육을 실시하고 있습니다.
                  <br />
                  홈페이지는 분실·도난·누출·변조 또는 훼손되지 않도록 서비스 제공과 관련하여 다음과 같은 보안프로그램을 운용하고 있습니다.
                </p>
                <ul>
                  <li>암호화알고리즘 등을 적용함으로써 고객정보의 보관 및 송수신 네트워크의 안전성 확보</li>
                  <li>컴퓨터 바이러스에 의한 피해 방지를 위한 백신 프로그램 연계</li>
                  <li>고객 컴퓨터에 부정사용자의 접근을 차단하기 위한 접근통제장치의 설치 및 운영</li>
                  <li>키보드 입력값에 대한 해킹방지를 위해 키보드 보안장치 설치 및 운영</li>
                </ul>
                <h5 className="title">9. 쿠키(Cookie)의 이용</h5>
                <p>
                  빌링원 홈페이지에서는 사이트 대한 기본 설정정보를 보관하기 위해 쿠키를 사용합니다. 쿠키는 해당 웹사이트가 이용고객의 컴퓨터 브라우저로
                  전송하는 소량의 정보로서 쿠키 이용에 대한 선택권은 이용고객께서 가지고 있습니다.
                  <br />
                  이용고객의 웹브라우저에서 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인 또는 모든 쿠키의 저장을 거부하는 등의 옵션을 선택하실 수 있습니다.
                  <br />
                  단, 쿠키의 저장을 거부하는 옵션을 선택하는 경우 서비스 이용에 불편이 야기될 수 있습니다.{" "}
                </p>
                <h5 className="title">10. 어린이의 개인정보보호</h5>
                <p>
                  빌링원 홈페이지에서는 만14세 미만 어린이의 개인정보는 법정대리인의 동의가 있는 경우 수집하며, 법정대리인의 동의와 함께 법정대리인임을 확인할
                  수 있는 서류(주민등록등본, 가족관계확인서 등)을 별도로 받고 있습니다.{" "}
                </p>
                <h5 className="title">11. 개인정보처리방침 개정</h5>
                <p>
                  법령, 정부의 정책, 보안기술의 변경에 따라 개인정보처리방침을 개정(추가·삭제·변경)하는 경우 최소한 개정일 7일전부터 공지사항을 통하여
                  이용자에게 변경 내용, 적용일자를 알려드립니다.{" "}
                </p>
                <h5 className="title">12. 개인정보 관련 불만의 처리</h5>
                <p>
                  서비스 이용과 관련하여 개인정보가 침해되었거나 불만이 있는 경우에는 아래의 개인정보 관리책임자에게 의견을 주시면 즉시 조치하여 처리결과를
                  통보해 드리겠습니다.{" "}
                </p>
                <ul>
                  <li>담당부서 : 금융결제원 e사업실</li>
                  <li>개인정보 보호책임자 : e사업실장</li>
                  <li>개인정보 관리담당자 : e사업실 문수혁</li>
                  <li>연 락 처 : 02-531-3340~4, billone@kftc.or.kr</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PersonalInfoModalVersion1;
