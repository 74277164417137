import { Button, Modal, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { PaginationForm } from "../../template/ComponentForm";
// import { ViewCustomerDivision } from "../customerBasicInformation/CustomerForm";
// import { useHistory } from "react-router-dom";
import TableForm from "../../template/TableForm";

// 검색 데이터
function searchData() {
  return {
    fileUniqueKey: "",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "payerNo",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  목록 컴포넌트 생성
 */
function RegistrationErrorList(props) {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "15%", "auto"])}
        <TableForm.compTableHead arrData={["납부자번호", "예금주생년월일\n(사업자번호)", "전화번호", "금융기관", "계좌번호", "접수구분", "오류내용"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">{row.payerNo}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.depositorIdentificationNo)}</TableCell>
                  <TableCell align="center">{CM.cfnIsNotEmpty(row.depositorTelephone) ? CM.cfnAddtDashToPhoneNumber(row.depositorTelephone) : ""}</TableCell>
                  <TableCell align="center">{row.financialInstituteName}</TableCell>
                  <TableCell align="center">{row.accountNo}</TableCell>
                  <TableCell align="center">{row.askedContents === "REGISTRATION" ? "등록" : "등록실패"}</TableCell>
                  <TableCell align="center">{row.resultCodeMsg}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function RegistrationError({ open, setOpen, file }) {
  const [pagination, setPagination] = useState({
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  });
  const [searchRequest, setSearchRequest] = useState(searchData());
  // const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [registrationErrorList, setRegistrationErrorList] = useState([]);
  // const [originalList, setOriginalList] = useState([]);
  const [summary, setSummary] = useState({});

  //fileUniqueKey를 넣어준다?
  // useEffect(() => {

  // }, open);

  useEffect(() => {
    /*
     * @desc    summary 결과조회 Request
     */
    const axiosSummary = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/financial-institute/registrations/refused/summary/${file.fileUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/financial-institute/registrations/refused/${file.fileUniqueKey}?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      const resultSummaryData = await axiosSummary(search);
      const resultData = await axiosList(search);

      let resultList = resultData.content;
      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }
      setSummary(resultSummaryData);
      // setOriginalList(resultData);
      setRegistrationErrorList(resultList);
      setPagination(paginationData(resultData));
    }

    if (open) {
      startAxios(searchRequest);
    }
  }, [open, searchRequest, file.fileUniqueKey]);

  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  // 페이지 변경 이벤트 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
  };

  //오류처리
  const handleErrorSubmit = (e) => {
    e.preventDefault();
    CM.cfnConfirm("전송하시겠습니까?", () => {
      const url = `api/customer/receipt/account/financial-institute/registrations/file/${file.fileUniqueKey}`;
      CM.cfnAxios(url, "post", "", fnSubmitCallback);
    });
  };

  /*
   * @desc  오류처리 콜백함수
   */
  const fnSubmitCallback = (objStatus, objData) => {
    CM.cfnAlert(objData, () => {
      handleClose();
    });
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal" />
          </div>
          <h3>수납 계좌 등록/해지(금융기관 접수분) 오류내역 전송</h3>
          <div>
            <div className="inforbox">
              <ul>
                <li>오류내역을 전송하시면 해당 미처리 고객에 대해서는 추가로 오류처리할 수 없습니다.</li>
              </ul>
            </div>
          </div>
          <div className="table-top-area">
            <button className="btn-m fr" onClick={(e) => handleErrorSubmit(e)}>
              오류내용전송
            </button>
          </div>
          <h4 className="fl">
            {CM.cfnDateFormat(summary.transactionDate)} 접수한 총 {summary.totalRequestCount}건 중 미처리 고객 건수가 {summary.notProcessedCount}건 있습니다.
          </h4>
          <RegistrationErrorList list={registrationErrorList} />
        </div>
        <PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      </div>
    </Modal>
  );
}

export default RegistrationError;
