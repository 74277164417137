import React, { useState, useEffect, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Pagination from "material-ui-flat-pagination";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import { cfnAlert } from "../../common/Common";

const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "750px",
  },
});

const DEFAULT_HOOKS = {
  searchStart: false,
  candidatesList: [],
  searchCondition: {
    customerName: "",
    cashbillIdentificationNo: "",
    payerNo: "",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "customerName",
  },
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
  searchForm: {
    terms: "customerName",
    input: "",
  },
};

// 부가서비스 > 현금영수증 > 현금영수증 직접발행ㆍ취소 > 발행대상 추가 modal
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabTargetsModalCandidates = (props) => {
  const classes = useStyles();

  // hooks
  const [searchStart, setSearchStart] = useState(DEFAULT_HOOKS.searchStart);
  const [candidatesList, setCandidatesList] = useState(DEFAULT_HOOKS.candidatesList);
  const [searchCondition, setSearchCondition] = useState(DEFAULT_HOOKS.searchCondition);
  const [pagination, setPagination] = useState(DEFAULT_HOOKS.pagination);
  const [searchForm, setSearchForm] = useState(DEFAULT_HOOKS.searchForm);

  // handler
  const handleSearchForm = ({ target: { value } }, key) => setSearchForm((data) => ({ ...data, [key]: value }));
  const handleSearchCondition = (value) => setSearchCondition(value);
  const handleCandidatesList = (value) => setCandidatesList(value);
  const handleSearchStart = (value) => setSearchStart(value);
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  const excelInput = React.useRef("");

  const handlePage = (page) => {
    handleSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchStart(true);
  };

  const handleRowPerPage = ({ target: { value } }) => {
    handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchStart(true);
  };

  // 선택항목을 추가하기 전 유효성 검사
  const additionalValidationOfSelection = (value) => {
    if (CM.cfnIsEmpty(value.issuingAmount) || value.issuingAmount === "0") {
      CM.cfnAlert(`${value.customerName} 고객의 발행금액을 입력해주세요.`);
      return false;
    }

    if (CM.cfnIsEmpty(value.receiptDate)) {
      CM.cfnAlert(`${value.customerName} 고객의 수납일을 입력해주세요.`);
      return false;
    }

    if (!CM.cfnValidDate(value.receiptDate)) {
      CM.cfnAlert(`${value.customerName} 고객의 올바른 수납일을 입력해주세요.`);
      return false;
    }

    if (CM.cfnIsEmpty(value.cashbillIdentificationNo)) {
      CM.cfnAlert(`${value.customerName} 고객의 신분확인번호를 입력해주세요.`);
      return false;
    }

    return true;
  };

  // 선택항목 추가
  const addSelection = () => {
    const checkRow = candidatesList.filter((candidate) => candidate._checked);

    if (checkRow.length === 0) {
      CM.cfnAlert("선택된 항목이 없습니다.");
      return false;
    }

    for (const value of checkRow) {
      if (!additionalValidationOfSelection(value)) return false;
    }

    const extraCandidateList = checkRow.map((value) => ({
      ...value,
      issuingAmount: Number(CM.cfnReplaceSymbol(value.issuingAmount)),
      receiptDate: CM.cfnConvertDateToString(value.receiptDate),
      applyDate: props.applyDate,
      transactionStartDate: props.fromDate,
    }));

    const url = "api/extraservice/cashbill/targets/candidates/" + props.summary.uniqueKey;

    CM.cfnAxios(url, "post", extraCandidateList, (status, data) => {
      CM.cfnAlert(data || "선택된 항목이 추가되었습니다.", () => {
        handleSearchStart(true);
        props.handleSearchStart(true);
      });
    });
  };

  // useCallback
  const getCandidatesList = useCallback((parameter) => {
    return new Promise((resolve) => {
      let url = "api/extraservice/cashbill/targets/candidates";
      url += `?customerName=${parameter.customerName}&cashbillIdentificationNo=${parameter.cashbillIdentificationNo}&payerNo=${parameter.payerNo}`;
      url += `&pageNumber=${parameter.pageNumber}&pageSize=${parameter.pageSize}`;
      url += `&sortDirection=${parameter.sortDirection}&sortProperty=${parameter.sortProperty}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  // hooks 초기화
  const initializeHooks = useCallback(() => {
    setSearchStart(DEFAULT_HOOKS.searchStart);
    setCandidatesList(DEFAULT_HOOKS.candidatesList);
    setSearchCondition(DEFAULT_HOOKS.searchCondition);
    setPagination(DEFAULT_HOOKS.pagination);
    setSearchForm(DEFAULT_HOOKS.searchForm);
  }, []);

  useEffect(() => {
    if (props.modalOpen.candidates) handleSearchStart(true);
    return () => initializeHooks();
  }, [props.modalOpen.candidates, initializeHooks]);

  useEffect(() => {
    const startAxios = async (parameter) => {
      const resultData = await getCandidatesList(parameter);
      const content = resultData.content;
      const contentWithCheckAdded = content.map((value) => ({
        ...value,
        _checked: false,
      }));

      handleCandidatesList(contentWithCheckAdded);
      handlePagination(resultData);
    };

    if (searchStart) startAxios(searchCondition);

    return () => handleSearchStart(false);
  }, [searchStart, searchCondition, getCandidatesList]);

  // 엑셀 저장
  const downloadExcel = () => {
    let url = "api/extraservice/cashbill/targets/candidates/excel";
    url += `?customerName=${searchCondition.customerName}`;
    url += `&cashbillIdentificationNo=${searchCondition.cashbillIdentificationNo}&payerNo=${searchCondition.payerNo}&sortProperty=${searchCondition.sortProperty}&sortDirection=${searchCondition.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  /*
   * @desc    일괄추가 버튼 클릭 이벤트 핸들러
   */
  const handleClickBulkAdd = () => {
    excelInput.current.click();
  };

  /*
   * @desc    일괄추가 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc   일괄추가 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/extraservice/cashbill/targets/candidates/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey",
      new Blob([JSON.stringify(props.summary.uniqueKey)], {
        type: "application/json",
      })
    );
    CM.cfnAxios(
      url,
      "post",
      form,
      (status, data) => {
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {
          handleSearchStart(true);
          props.handleSearchStart(true);
          props.handleModalOpen("candidates", false);
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      }
    );
  };

  return (
    <Modal open={props.modalOpen.candidates}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpen("candidates", false)} data-testid="close-cashbill-candidates-modal">
              {""}
            </Button>
          </div>
          <h3>현금영수증 발행 대상 추가</h3>
          <div className="inforbox">
            <ul>
              <li>
                현금영수증 직접발행대상은 고객의 수납고객정보 &gt; 선택 입력사항의 <b>"현금영수증 발행정보"가 입력되어있는 경우에만</b> 검색 및 추가 가능합니다.
              </li>
              <li>다수의 내역을 추가하려면 "목록저장(엑셀)"을 클릭하여 추가대상 목록을 다운받아 수정한 후 "일괄추가(엑셀)"을 클릭하여 수정한 파일을 업로드 하시기 바랍니다.</li>
            </ul>
          </div>

          <SearchComponent searchForm={searchForm} handleSearchForm={handleSearchForm} handleSearchCondition={handleSearchCondition} handleSearchStart={handleSearchStart} />
          <div className="table-top-area">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} customProps={{ inputProps: { "data-testid": "cashbill-candidates-rowPerPage-select" } }} />
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleSelectExcelFile(e)}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
              ref={excelInput}
              value=""
              data-testid="select-excel-input"
            />
            <button className="btn-m fr table-top-button" data-testid="cashbill-candidates-add-list-as-excel-button" onClick={handleClickBulkAdd}>
              일괄추가(엑셀)
            </button>
            <button className="btn-m fr table-top-button" data-testid="cashbill-candidates-save-cashbill-candidates-save-lst-as-excel-button" onClick={downloadExcel}>
              엑셀저장
            </button>
            <button className="btn-l fr table-top-button" data-testid="cashbill-candidates-add-selection-button" onClick={addSelection}>
              선택항목 추가
            </button>
          </div>
          <CandidatesListComponent
            list={candidatesList}
            handleCandidatesList={handleCandidatesList}
            pagination={pagination}
            handlePage={handlePage}
            searchCondition={searchCondition}
            handleSearchCondition={handleSearchCondition}
            handleSearchStart={handleSearchStart}
          />
        </div>
      </div>
    </Modal>
  );
};

// 검색 컴포넌트
const SearchComponent = (props) => {
  const optionList = [
    { value: "customerName", label: "고객명" },
    { value: "cashbillIdentificationNo", label: "신분확인번호" },
    { value: "payerNo", label: "납부자번호" },
  ];

  const handleSearchKeyUp = ({ keyCode }) => {
    if (keyCode === 13) startSearch();
  };

  const startSearch = async () => {

    props.handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      customerName: props.searchForm.terms === "customerName" ?  props.searchForm.input : "",
      cashbillIdentificationNo : props.searchForm.terms === "cashbillIdentificationNo" ?  props.searchForm.input : "",
      payerNo: props.searchForm.terms === "payerNo" ?  props.searchForm.input : "",
    }));
    props.handleSearchStart(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <Select native value={props.searchForm.terms} onChange={(e) => props.handleSearchForm(e, "terms")} inputProps={{ "data-testid": "cashbill-candidates-select-terms" }}>
          {optionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField className="w250" value={props.input} onChange={(e) => props.handleSearchForm(e, "input")} onKeyUp={handleSearchKeyUp} data-testid="cashbill-candidates-search-input" />
        <button className="search-button" onClick={startSearch} data-testid="cashbill-candidates-search-button">
          검색
        </button>
      </div>
    </div>
  );
};

// 추가대상 목록 컴포넌트
const CandidatesListComponent = (props) => {
  // table head checkbox state
  const [checkAllRow, setCheckAllRow] = useState(false);

  const handleDateOfCandidatesList = (date, value, index) => {
    const today = new Date();
    const compareDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
    if (date < compareDate) {
      cfnAlert("수납일은 오늘부터 8일이내로 선택해주시기 바랍니다.");
    } else if (date > today) {
      cfnAlert("수납일자가 생성한 현금영수증 발행대상의 마지막일자보다 이후입니다.");
    } else {
      props.handleCandidatesList((data) => data.map((element, idx) => (idx === Number(index) ? { ...element, receiptDate: date } : { ...element })));
    }
  };

  const handleCandidatesListChange = (event, index, key) => {
    const prevValue = event.target.value.replace(/[^0-9]/g, "");
    const nowValue = key === "issuingAmount" ? CM.cfnAddCommaOnChange(CM.cfnLtrimZero(prevValue)) : prevValue;

    props.handleCandidatesList((data) => data.map((element, idx) => (idx === Number(index) ? { ...element, [key]: nowValue } : { ...element })));
  };

  const handleCheckAllRow = ({ target: { checked } }) => {
    setCheckAllRow(checked);
    props.handleCandidatesList((data) => data.map((element) => ({ ...element, _checked: checked })));
  };

  const handleChecked = ({ target: { value, checked } }, index) => {
    props.handleCandidatesList((data) => data.map((element, idx) => (idx === Number(index) ? { ...element, [value]: checked } : { ...element })));
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    const sortProperty = sortObjArray.find((obj) => obj.name === "sortProperty").value;
    const sortDirection = sortObjArray.find((obj) => obj.name === "sortDirection").value;

    props.handleSearchCondition((data) => ({ ...data, sortProperty, sortDirection }));

    // 정렬조건 세팅 후 검색 trigger
    props.handleSearchStart(true);
  };

  const enableHeaderRow = useCallback(() => {
    const checkRow = props.list.filter((data) => data._checked);

    if (checkRow.length === props.list.length && props.list.length > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [props.list]);

  useEffect(() => {
    enableHeaderRow();
  }, [enableHeaderRow]);

  return (
    <div className="modal-overflow-y-table">
      <Table>
        {CM.cfnCompColGroup(["50px", "auto", "auto", "70px", "100px", "160px", "80px", "auto", "80px"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRow}
          arrData={[
            { id: "customerName", label: "고객명", sortable: true },
            { id: "cashbillIdentificationNo", label: "신분확인번호", sortable: false },
            { id: "customerType", label: "고객/사업자 구분", sortable: false },
            { id: "payerNo", label: "납부자번호", sortable: false },
            { id: "receiptDate", label: "수납일", sortable: false },
            { id: "transactionType", label: "거래구분", sortable: false },
            { id: "issuingAmount", label: "발행금액", sortable: false },
            { id: "purposeType", label: "발행구분", sortable: false },
          ]}
          searchRequest={props.searchCondition}
          handleSortProperty={handleSortProperty}
          checkboxCustomProps={{ "data-testid": "cashbill-candidates-head-checkbox" }}
          tableSortLabelDataTestId={"cashbill-candidates-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">
                    <Checkbox checked={target._checked} value="_checked" onChange={(e) => handleChecked(e, index)} data-testid={`cashbill-candidates-checkbox-${index}`} />
                  </TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo)}</TableCell>
                  <TableCell align="center">{CDN.customerType(target.customerType)}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">
                    <CF.DatePickerForm
                      value={target.receiptDate}
                      handleChange={(date, value) => handleDateOfCandidatesList(date, value, index)}
                      customProps={{
                        style: { width: "160px" },
                        inputProps: { "data-testid": `cashbill-candidates-datepicker-receiptDate-${index}` },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionType(target.transactionType)}</TableCell>
                  <TableCell align="center">
                    <Input
                      value={target.issuingAmount || 0}
                      onChange={(event) => handleCandidatesListChange(event, index, "issuingAmount")}
                      inputProps={{ style: { textAlign: "right" } }}
                      data-testid={`cashbill-candidates-input-issuingAmount-${index}`}
                      endAdornment="원"
                    />
                  </TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.purposeType(target.purposeType)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        className="centered"
        limit={props.pagination.rowsPerPage}
        offset={props.pagination.offset}
        total={props.pagination.total}
        onClick={(e, offset, page) => props.handlePage(page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
        data-testid="cashbill-candidates-pagination"
      />
    </div>
  );
};

export default CashbillTabTargetsModalCandidates;
