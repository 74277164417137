// 기관 업무 정보 > 변경 요청 이력의 dataCode에 해당하는 dataName을 가져오는 js
const ChangeHistoryCodeName = {
  // 서비스 포맷
  applicationBusiness: value => {
    switch (value) {
      case "CASHBILL":
        return "현금영수증";
      case "BILLINGONEPLUS":
        return "빌링원플러스";
      // UNKNOWN
      default:
        return "기타";
    }
  },
  // 진행상태
  applicationStatus: value => {
    switch (value) {
      case "READY_TO_ACCEPTANCE":
        return "접수대기";
      case "ACCEPTANCE_COMPLETED":
        return "접수완료";
      case "READY_TO_APPROVAL":
        return "승인대기";
      case "TEMPORARY_APPROVAL_COMPLETED":
        return "가승인완료";
      case "APPROVAL_COMPLETED":
        return "승인완료";
      case "SENTBACK":
        return "반송";
      case "INSTITUTE_CANCELED":
        return "기관취소";
      case "READY_TO_APPROVAL_FROM_NTS":
        return "국세청승인대기";
      case "READY_TO_TEST":
        return "테스트대기";
      case "READY_TO_TRANSFORMATION":
        return "변환대기";
      case "TRANSFORMING":
        return "변환중";
      case "TRANSFORMATION_COMPLETED":
        return "변환완료";
      case "READY_TO_REGISTRATION":
        return "등록대기";
      case "REGISTERING":
        return "등록중";
      case "FAILED_TO_REGISTER":
        return "등록실패";
      case "APPROVING_FROM_NTS":
        return "국세청승인중";
      // FAILED_TO_APPROVE_FROM_NTS
      default:
        return "국세청승인거절";
    }
  },
  // 업무 포맷
  applicationContents: value => {
    switch (value) {
      case "START_SERVICE":
        return "서비스 개시";
      case "TERMINATE_SERVICE":
        return "서비스 해지";
      case "CHANGE_BILLING_ACCOUNT":
        return "요금계좌 변경";
      case "CHANGE_IMAGE":
        return "이미지 변경";
      case "PAUSE_SERVICE":
        return "서비스 일시정지";
      case "RESUME_SERVICE":
        return "서비스 일시정지 해제";
      case "START_AUTO_WITHDRAW":
        return "자동출금 개시";
      case "CHANGE_AUTO_WITHDRAW":
        return "자동출금 변경";
      case "TERMINATE_AUTO_WITHDRAW":
        return "자동출금 해지";
      case "CHANGE_INFORMATION":
        return "정보변경";
      case "CHANGE_TO_WEB":
        return "WEB으로 변경";
      case "CHANGE_TO_CS":
        return "CS로 변경";
      case "CHANGE_GIRO":
        return "지로 변경";
      case "APPLY_CASHBILL":
        return "현금영수증";
      case "APPLY_REALTIME_ELECTRONIC_RECEIPT":
        return "실시간 전자수납 신청";
      case "TERMINATE_REALTIME_ELECTRONIC_RECEIPT":
        return "실시간 전자수납 해지";
      // TERMINATE_LONGTERM_UNUSED
      default:
        return "장기 미이용 해지";
    }
  }
};

export default ChangeHistoryCodeName;
