import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Carousel from "nuka-carousel";
import React, { useEffect, useState } from "react";
import * as CM from "../../components/common/Common";
import icon1 from "../../styles/images/icon1.png";
import icon2 from "../../styles/images/icon2.png";
import icon3 from "../../styles/images/icon3.png";
import icon4 from "../../styles/images/icon4.png";
import icon5 from "../../styles/images/icon5.png";
import icon6 from "../../styles/images/icon6.png";
import img1 from "../../styles/images/img1.png";
import img2 from "../../styles/images/img2.png";
import img3 from "../../styles/images/img3.png";
import img4 from "../../styles/images/img4.png";
import img5 from "../../styles/images/img5.png";
import logo2 from "../../styles/images/logo2.png";
import { Popup } from "./Popup";
//import popupContent from "./NoticePopupContent";

const LoginHome = () => {
  const [modal, setModal] = useState([]);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  useEffect(() => {
    let mounted = false;

    const fnSearch = async () => {
      const url = "api/support/popups";
      CM.cfnAxios(url, "get", "", async (status, dataList) => {
        if (status.status === 200 && CM.cfnIsNotEmpty(dataList) && !mounted) {
          const arrPopups = [];

          for (let data = 0; data < dataList.length; data++) {
            if (getCookie("popupYn" + dataList[data].popupSequence) !== "N") {
              arrPopups.push(dataList[data]);
            }
          }
          setModal(arrPopups);
        }
      });
    };

    fnSearch();
    return () => (mounted = true);
  }, []);

  const getCookie = (name) => {
    if (document && document.cookie) {
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");
      const namedCookie = cookieArray.filter((cookie) => {
        return cookie.trim().startsWith(name + "=");
      });
      if (namedCookie.length > 0) {
        return namedCookie[0].trim().substring(name.length + 1);
      }
    }
    return null;
  };

  return (
    <div>
      {modal.map((md, i) => (
        <Popup key={i} data={md} isLogin={false} index={i} open={currentModalIndex === i} setOpen={() => setCurrentModalIndex(currentModalIndex + 1)} />
      ))}

      <div className="main-img">
        <Carousel
          autoplay
          speed={1500}
          autoplayInterval={5000}
          easing="easeSinInOut"
          edgeEasing="easeSinInOut"
          renderCenterLeftControls={({ previousSlide }) => <ArrowBackIosIcon onClick={previousSlide} style={{ marginLeft: "10px", cursor: "pointer" }} />}
          wrapAround
          renderCenterRightControls={({ nextSlide }) => <ArrowForwardIosIcon onClick={nextSlide} style={{ marginRight: "10px", cursor: "pointer" }} />}>
          <div
            className="main-img-area"
            style={{
              backgroundImage: `url('${img1}')`,
            }}>
            <div className="main-img-text">
              <div>
                <img src={logo2} alt="logo2" />
              </div>
              <span>지급결제 전담기관인 금융결제원에서 제공하는 서비스</span>
              <h2>
                고객 정보와 수납/지급 정보를
                <br />
                결합한 편리한 고객 등록 및 관리
              </h2>
              <ul>
                <li>고객/수납/지급 정보 등록/관리</li>
              </ul>
            </div>
          </div>
          <div
            className="main-img-area"
            style={{
              backgroundImage: `url('${img2}')`,
            }}>
            <div className="main-img-text-left">
              <div>
                <img src={logo2} alt="logo2" />
              </div>
              <span>지급결제 전담기관인 금융결제원에서 제공하는 서비스</span>
              <h2>
                월별 수납/지급 내역
                <br />
                자동 생성 및 수납/지급 통계 제공
              </h2>
              <ul>
                <li>수납/지급 자금관리 및 통계 </li>
              </ul>
            </div>
          </div>
          <div
            className="main-img-area"
            style={{
              backgroundImage: `url('${img3}')`,
            }}>
            <div className="main-img-text">
              <div>
                <img src={logo2} alt="logo2" />
              </div>
              <span>지급결제 전담기관인 금융결제원에서 제공하는 서비스</span>
              <h2>
                수납/지급 정보와 고객 정보를
                <br /> 결합한 편리한 고객 등록 및 관리
              </h2>
              <ul>
                <li>수납서비스 : 등록된 고객 계좌에서 출금</li>
                <li>지급서비스 : 등록된 고객 계좌로 입금</li>
              </ul>
            </div>
          </div>
          <div
            className="main-img-area"
            style={{
              backgroundImage: `url('${img4}')`,
            }}>
            <div className="main-img-text">
              <div>
                <img src={logo2} alt="logo2" />
              </div>
              <span>지급결제 전담기관인 금융결제원에서 제공하는 서비스</span>
              <h2>
                자동출금 예약, 입출금 정보 알림 등<br />
                다양하고 편리한 부가서비스
              </h2>
              <ul>
                <li>각종 영수증 및 세금계산서 발행 등</li>
              </ul>
            </div>
          </div>
          <div
            className="main-img-area"
            style={{
              backgroundImage: `url('${img5}')`,
            }}>
            <div className="main-img-text">
              <div>
                <img src={logo2} alt="logo2" />
              </div>
              <span>지급결제 전담기관인 금융결제원에서 제공하는 서비스</span>
              <h2>
                월별 자금 흐름 파악을 위한
                <br /> 입출금 통계와 월력 제공
              </h2>
              <ul>
                <li>기타 서비스 : 고객 정보 일괄 등록 및 백업 등</li>
              </ul>
            </div>
          </div>
        </Carousel>
      </div>
      <div>
        <div className="loginhome-bottom-area">
          <div className="service-icon">
            <div
              className="service"
              style={{
                marginRight: "36px",
              }}>
              <img src={icon1} alt="" />
              <div>
                <h2>고객정보 관리</h2>
                <span>고객/수납/지급 정보등록</span>
              </div>
            </div>
            <div
              className="service"
              style={{
                marginRight: "50px",
              }}>
              <img src={icon2} alt="logo2" />
              <div>
                <h2>수납서비스</h2>
                <span>고객계좌 자동/수동 수납청구</span>
              </div>
            </div>
            <div
              className="service"
              style={{
                marginRight: "50px",
              }}>
              <img src={icon3} alt="logo2" />
              <div>
                <h2>지급서비스</h2>
                <span>지급금액 계좌이체</span>
              </div>
            </div>
            <br />
            <div
              className="service mt46"
              style={{
                marginRight: "36px",
              }}>
              <img src={icon4} alt="logo2" />
              <div>
                <h2>자금관리</h2>
                <span>수납/지급 자금내역 및 통계</span>
              </div>
            </div>
            <div
              className="service mt46"
              style={{
                marginRight: "36px",
              }}>
              <img src={icon5} alt="logo2" />
              <div>
                <h2>영수증/계산서</h2>
                <span>기부금 및 현금영수증, 세금계산서</span>
              </div>
            </div>
            <div className="service mt46">
              <img src={icon6} alt="logo2" />
              <div>
                <h2>백업/일괄등록</h2>
                <span>고객/수납/지급 자료 등록/백업</span>
              </div>
            </div>
          </div>
          <div className="cs-area">
            <h2>고객센터</h2>
            <div className="cs-menu">
              <a href="https://help.kftc.or.kr" target="_blank" rel="noopener noreferrer">
                원격지원서비스
              </a>
              <a href="http://callcenter.kftc.or.kr/qna/qna_form.jsp" target="_blank" rel="noopener noreferrer">
                이메일상담
              </a>
              <a href="http://callcenter.kftc.or.kr/customer/custom_list.jsp?callSite=billingone" target="_blank" rel="noopener noreferrer">
                고객제안
              </a>
              <a href="http://callcenter.kftc.or.kr/qna/qna_list.jsp" target="_blank" rel="noopener noreferrer">
                FAQ
              </a>
            </div>
            <span>평일 09:00 ~ 17:45</span>
            <h2 className="phone-num">1577-5500</h2>
            <div className="cs-num">단축번호 : 3번, 2번</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginHome };
