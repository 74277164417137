import React from "react";
import * as CM from "../../common/Common";
import axios from "axios";
import Pagination from "material-ui-flat-pagination";
import {
  // Hidden,
  Modal,
  Table,
  Select,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
} from "@material-ui/core";
const BILLINGONE_KEY = "U01TX0FVVEgyMDE5MDExMTE0NTEwNTEwODQ0MDY=";
const jusoObject = {
  test: {
    url: "https://www.juso.go.kr/addrlink/addrLinkApi.do",
  },
  development: {
    url: "http://localhost:28888/road.json",
  },
  production: {
    url: "https://www.juso.go.kr/addrlink/addrLinkApi.do",
  },
};

class AddressSearchModal extends React.Component {
  constructor(props) {
    super(props);
    //focus 처리
    this.inpuAddresInput = React.createRef();
    this.focusInpuAddresInput = this.focusInpuAddresInput.bind(this);

    this.state = {
      page: 0,
      offset: 0,
      rowsPerPage: 10,
      open: false,
      inputAddress: "",
      zipCode: "",
      addressCode: "",
      totalSize: 0,
      sido: "",
      localState: [
        "",
        "서울특별시",
        "경기도",
        "세종특별자치시",
        "강원도",
        "충청북도",
        "충청남도",
        "전라북도",
        "전라남도",
        "경상북도",
        "경상남도",
        "제주특별자치도",
        "부산광역시",
        "대구광역시",
        "대전광역시",
        "인천광역시",
        "광주광역시",
        "울산광역시",
      ],
      juso: [],
    };
  }
  componentDidMount = () => {
    let jsonValue = {};
    if (typeof this.props.addressColumnList !== "undefined") {
      if (!this.props.addressColumnList.hasOwnProperty("zipCode")) {
        jsonValue.zipCode = "zipCode";
      } else {
        jsonValue.zipCode = this.props.addressColumnList.zipCode;
      }
      if (!this.props.addressColumnList.hasOwnProperty("zipCode")) {
        jsonValue.addressCode = "addressCode";
      } else {
        jsonValue.addressCode = this.props.addressColumnList.addressCode;
      }
    }
    this.handleState(jsonValue);
  };

  handleClose = () => {
    let newState = Object.assign({}, this.state);
    newState.page = 0;
    newState.offset = 0;
    newState.rowsPerPage = 10;
    newState.open = false;
    newState.inputAddress = "";
    newState.totalSize = 0;
    newState.sido = "";
    newState.juso = [];
    this.handleState(newState);
    this.props.setOpen(false);
  };
  handleState = ({ ...stateName }) => {
    let newState = Object.assign({}, this.state);
    Object.keys(stateName).forEach((key) => (newState[key] = stateName[key]));
    this.setState(newState);
  };
  handleInputChange = (e) => {
    let jsonValue = Object.assign({}, this.state);
    jsonValue.inputAddress = e.target.value;
    this.handleState(jsonValue);
  };
  selectAddress = (jusoAddress) => {
    let jusoAddressJson = {};
    jusoAddressJson[this.state.zipCode] = jusoAddress.zipNo;
    jusoAddressJson[this.state.addressCode] = jusoAddress.roadAddr === "" || jusoAddress.roadAddr === null ? jusoAddress.jibunAddr : jusoAddress.roadAddr;
    jusoAddressJson.roadAddressYn = jusoAddress.roadAddr === "" || jusoAddress.roadAddr === null ? false : true;
    this.props.setAddress(jusoAddressJson);
    this.handleClose();
  };
  handleSearKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.searchAddress();
    }
  };

  focusInpuAddresInput() {
    this.inpuAddresInput.current.focus();
  }

  searchAddress() {
    if (CM.cfnIsEmpty(this.state.inputAddress)) {
      CM.cfnAlert("주소를 입력하세요.", this.focusInpuAddresInput());
      return false;
    }
    let searchWord = this.state.sido === "" ? this.state.inputAddress : this.state.sido + " " + this.state.inputAddress;
    let currentPage = this.state.page + 1;
    let searchUrl =
      jusoObject[CM.SERVER_ENV].url +
      "?currentPage=" +
      currentPage +
      "&countPerPage=" +
      this.state.rowsPerPage +
      "&keyword=" +
      encodeURIComponent(searchWord) +
      "&confmKey=" +
      BILLINGONE_KEY +
      "&resultType=json";

    const config = {};
    axios
      .get(searchUrl, config)
      .then((response) => {
        this.searchData(response);
      })
      .catch((error) => {
        CM.cfnAlert(error);
      });
  }
  searchData(response) {
    if (response.status !== 200) {
      CM.cfnAlert(response.error);
    } else {
      let jsonValue = {};
      let responseData = response.data;
      let jusoData = responseData.results.juso;
      let totalSize = parseInt(responseData.results.common.totalCount);

      jsonValue.totalSize = totalSize;
      jsonValue.juso = totalSize === 0 ? [] : jusoData;
      this.handleState(jsonValue);
    }
  }
  handleOffsetChange(offset, page) {
    let jsonValue = {};
    jsonValue.offset = offset;
    jsonValue.page = page - 1;
    this.handleState(jsonValue);
    this.searchAddress();
  }
  handleRadioChange = (e) => {
    let jsonValue = {};
    jsonValue[e.target.name] = e.target.value;
    this.handleState(jsonValue);
  };
  handleSelectBox = (e) => {
    let jsonValue = {};
    jsonValue[e.target.name] = e.target.value;
    this.handleState(jsonValue);
  };
  render() {
    return (
      <Modal open={this.props.open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" data-testid="open-modal">
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={this.handleClose} data-testid="addressSearch-close-modal">
                {""}
              </Button>
            </div>
            <h3>주소 검색</h3>
            <div className="search-area">
              <div className="block">
                <Select
                  native
                  name="sido"
                  value={this.state.sido}
                  onChange={this.handleSelectBox}
                  inputProps={{
                    "data-testid": "sido-select",
                  }}>
                  {this.state.localState.map((option, index) => {
                    return (
                      <option value={option} key={index}>
                        {option === "" ? "지역을 선택하세요" : option}
                      </option>
                    );
                  })}
                </Select>
                {"  "}
                <TextField
                  className="w250"
                  value={this.state.inputAddress}
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleSearKeyUp.bind(this)}
                  name="search"
                  inputRef={this.inpuAddresInput}
                  data-testid="search-address-keyword-input"
                />
                <button className="search-button" onClick={() => this.searchAddress()} data-testid="search-address-btn">
                  검색
                </button>
              </div>
            </div>
            <div className="h500">
              <Table>
                {CM.cfnCompColGroup(["20%", "65%", "15%"])}
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className="th">
                      우편번호
                    </TableCell>
                    <TableCell className="th">주소</TableCell>
                    <TableCell align="center" className="th">
                      선택
                    </TableCell>
                  </TableRow>
                  {this.state.totalSize === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        검색할 주소를 입력해 주세요.
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.juso.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center" className="td">
                            {row.zipNo}
                          </TableCell>
                          <TableCell className="td">{row.roadAddr === "" || row.roadAddr === null ? row.jibunAddr : row.roadAddr}</TableCell>
                          <TableCell align="center" className="td">
                            <button className="btn-s" onClick={(event) => this.selectAddress(row)} data-testid={`addressSearch-button-select-${index}`}>
                              선택
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <Pagination
            className="centered"
            limit={this.state.rowsPerPage}
            offset={this.state.offset}
            total={this.state.totalSize}
            onClick={(e, offset, page) => this.handleOffsetChange(offset, page)}
            reduced={true}
            centerRipple={false}
            disableFocusRipple={true}
            disableRipple={true}
          />
        </div>
      </Modal>
    );
  }
}
export { AddressSearchModal };
