import React, { useEffect, useState } from "react";
import * as Cm from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import { Input, Table, TableBody, TableRow, TableCell, Modal, NativeSelect, FormControl, Button } from "@material-ui/core";

const arr = [
  {key:"11", value:"목적사업종료"},
  {key:"12", value:"사업중지"},
  {key:"13", value:"휴업"},
  {key:"14", value:"폐업"},
  {key:"15", value:"건수부족"},
  {key:"16", value:"무거래"},
  {key:"24", value:"이용수수료과다"},
  {key:"31", value:"법인사업자 전환"},
  {key:"32", value:"개인사업자 전환"},
  {key:"33", value:"사업양도(명의이전)"},
  {key:"34", value:"임의단체(명의이전)"},
  {key:"41", value:"보증보험 만기 미연장"},
  {key:"42", value:"민원(약관 위반)"},
  {key:"51", value:"주거래은행 요청"},
  {key:"61", value:"이용기관ID통합"},
  {key:"63", value:"타프로그램 사용(CMS는 계속 사용)"},
  {key:"타프로그램 사용(CMS는 계속 사용 - 자체개발 프로그램 사용)", value:"타프로그램 사용(CMS는 계속 사용 - 자체개발 프로그램 사용)"},
  {key:"64", value:"타프로그램 사용(CMS도 해지요청)"},
  {key:"65", value:"손실처리"}
];

const BusinessInfoModal = ({ open, setOpen, disabled, object, fnServiceTerminationSubmit }) => {
  const [requestDate, setRequestDate] = useState(null);
  const [requestReason, setRequestReason] = useState("");
  const [requestDetailReason, setRequestDetailReason] = useState("");
  const [detailRequestReasonPlaceholder, setDetailRequestReasonPlaceholder] = useState("상세 해지사유를 입력해주세요.");

  useEffect(() => {
    /*
     * @desc 변경 요청 이력 요청 여부 체크
     */
    const fetchRequestDate = async () => {
      let today = new Date();
      const currentYear = today.getFullYear().toString();
      const currentMonth = today.getMonth() + 1;
      const currentDate = today.getDate().toString();
      const nextDate = await Cm.fnGetNextBizDate(`${currentYear}${currentMonth > 9 ? currentMonth : "0" + currentMonth}${
        currentDate > 9 ? currentDate : "0" + currentDate
      }`);
      setRequestDate(new Date(`${nextDate.toString().substr(0, 4)}/${nextDate.toString().substr(4, 2)}/${nextDate.toString().substr(6, 2)}`));
    }
    
    if (open) {
      if (disabled) {
        setRequestDate(new Date(`${object.serviceEndDate.substr(0, 4)}-${object.serviceEndDate.substr(4, 2)}-${object.serviceEndDate.substr(6)}`));
        if(object.programSwitchedTo === 'DIRECT_DEVELOPMENT'){
          setRequestReason("타프로그램 사용(CMS는 계속 사용 - 자체개발 프로그램 사용)");
        }else{
          setRequestReason(object.serviceEndReason);
          setRequestDetailReason(object.programSwitchedTo);
        }
      } else {
        fetchRequestDate();
      }
    }
  }, [open, disabled, object, setRequestReason, setRequestDate, setRequestDetailReason]);

  /*
   * @desc  해지항목 변경을 처리하는 handler
   */
  const handleRequestDate = date => {
    setRequestDate(date);
  };

  const handleRequestReason = e => {
    setRequestReason(e.target.value);
    if(e.target.value === "63"){
      setDetailRequestReasonPlaceholder("해지 후 사용할 다른 프로그램 이름을 입력해주세요.");
    }else{
      setDetailRequestReasonPlaceholder("상세 해지사유를 입력해주세요.");
    }
  };

  const handleDetailRequestReason = e => {
    setRequestDetailReason(e.target.value);
  };

  const fnCheckValidation = () => {
    let isValid = true;

    if (Cm.cfnIsEmpty(requestDate) || requestDate === "" || requestDate === undefined) {
      isValid = false;
      Cm.cfnAlert("해지희망일을 선택하여 주시기 바랍니다.");
    } else if (Cm.cfnIsEmpty(requestReason) || requestReason === "" || requestReason === undefined) {
      isValid = false;
      Cm.cfnAlert("해지사유를 선택하여 주시기 바랍니다.");
    }

    return isValid;
  };
  /*
   * @desc 해지신청 이벤트 핸들러
   */
  const handelSubmitUnregisterRequest = async () => {
    if (fnCheckValidation()) {
      Cm.cfnConfirm("해지 신청하시겠습니까?", async () => {
        let tempObject = {
          programSwitchedTo: requestReason === "타프로그램 사용(CMS는 계속 사용 - 자체개발 프로그램 사용)" ? "DIRECT_DEVELOPMENT" : requestDetailReason,
          serviceEndDate: Cm.cfnConvertDateToString(requestDate),
          serviceEndReason: requestReason === "타프로그램 사용(CMS는 계속 사용 - 자체개발 프로그램 사용)" ? "63" : requestReason
        };
        await fnServiceTerminationSubmit(tempObject);
      });
    }
  };

  /*
   * @desc 모달 클로즈
   */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" data-testid="open-modal" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>서비스 해지</h3>
          <div className="inforbox">
            <ul>
              <li>
                해지 신청은 금융결제원의 확인 후 처리되며, 진행상황은 <a href="/institute/changeHistory">변경 요청 이력</a>
                메뉴에서 확인하실 수 있습니다.
              </li>              
              <li>
                <span style={{fontSize:15}}>해지 신청 후에는 정보 제공이 불가하오니, <span style={{color:"red", fontWeight:"bold"}}>고객정보 및 수납고객정보를 반드시 백업</span>을 받으신 후 해지하시기 바랍니다.</span>
              </li>
            </ul>
          </div>
          <div className="table-top-area" />
          <Table>
            {Cm.cfnCompColGroup(["20%", "auto"])}
            <TableBody>
              <TableRow>
                <TableCell className="th">해지희망일</TableCell>
                <TableCell className="td">
                  <CF.DatePickerForm value={requestDate} handleChange={handleRequestDate} className="w150" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">해지사유</TableCell>
                <TableCell className="td">
                  <FormControl>
                    <NativeSelect
                      name="requestReason"
                      value={requestReason}
                      onChange={handleRequestReason}
                      inputProps={{ "data-testid": "request-reason" }}
                      disabled={disabled}
                    >
                      <option value={""} key={"none"}>
                        {"선택하세요"}
                      </option>
                      {arr.map(data => (
                        <option key={data.key} value={data.key}>
                          {data.value}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <Input
                    placeholder={detailRequestReasonPlaceholder}
                    value={requestDetailReason}
                    className="w300"
                    disabled={disabled || (requestReason === "타프로그램 사용(CMS는 계속 사용 - 자체개발 프로그램 사용)")}
                    onChange={handleDetailRequestReason}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {disabled ? (
            ""
          ) : (
            <div className="table-bottom-area">
              <div className="btn-l table-bottom-button" data-testid="submit-unregister" onClick={event => handelSubmitUnregisterRequest()}>
                해지 신청
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default BusinessInfoModal;
