import { createMuiTheme } from "@material-ui/core/styles";
import sortNone from './images/icon-sort-none.png';

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Noto Sans KR", "sans-serif"].join(",")
  },
  overrides: {
    MuiFormControl: {
      root: {
        margin: "8px",
        minWidth: 120
      }
    },
    MuiNativeSelect: {
      root: {
        marginTop: "16px"
      }
    },
    MuiInputBase: {
      input: {
        margin: "8px 0"
      }
    },
    MuiButtonBase: {
      root: {
        margin: "20px 0 0 0"
      }
    },
    MuiTableSortLabel:{
      icon:{
        backgroundImage:`url(${sortNone})`,
        backgroundRepeat:"no-repeat",
        backgroundSize:'contain',
        width:'10px',
        height:'10px',
        opacity:1
      }
    },
    MuiTextField:{
      root:{
        '& .MuiOutlinedInput-root':{
          '&.Mui-focused fieldset':{
            borderColor:'#000'
          }
        }
      }
    }
  }
});

export default theme;
