import { FormControlLabel, FormGroup, Radio, RadioGroup, Select } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import { BankAutoComplete, ButtonTooltipForm, MaskedField, SelectForm, TooltipForm } from "../../template/ComponentForm";
import EvidenceFileModal from "../../template/EvidenceFileModal";
import CustomerData from "../customerBasicInformation/CustomerData";
import { CreateCustomerDivision, CustomerBusinessSelectInput, CustomerIndividualSelectInput, CustomerRequiredInputs } from "../customerBasicInformation/CustomerForm";
import ARSInfoModal from "../customerModal/ARSInfoModal";
import CapitalsModal from "../customerModal/CapitalsModal";
import AccountUpdateModal from "./AccountUpdateModal";
import NumberReceiptHistories from "./NumberReceiptHistories";
import ReceiptCustomerChangeHistoriesModal from "./ReceiptCustomerChangeHistoriesModal";
import ReceiptStopModal from "./ReceiptStopModal";
import Data from "../../common/CommonDataName";
import { Modal } from "@material-ui/core";
import { Button } from "@material-ui/core";
import StaffInfo from "../../institute/staffInfo/StaffInfo";
import CustMnmtTable from "../../institute/customerCriteria/CustMnmtTable";

/*
@desc 수납정보 component
*/
const ReceiptInfo = (props) => {
  const {
    fnContractStatus,
    capitalOptions,
    dayOptions,
    totalNumberOfPaymentState,
    payPeriodOption,
    startYearOptions,
    endYearOptions,
    applyYearOptions,
    flag,
    handleClickModal,
    handleValuesChange,
    value,
    handleCheckChange,
    radioState,
    handleRadioChange,
    fnConvertY4mm,
    fnCreateMonthOption,
    limitAmountForEachWithdrawal,
  } = props;

  /*
   * @desc   수정페이지에서 기존 종료월 값이 과거라 셀렉트박스에 해당년도값이 없을 경우, 년도-월 셀렉트박스를 기본값으로 선택
   */
  const fnPastCheck = (data, y4mm) => {
    return fnConvertY4mm(data, y4mm);
  };

  return (
    <div>
      <h4>
        수납정보
        <div className="btn-m fr" onClick={(event) => handleClickModal("ReceiptCustomerChangeHistories")}>
          변경이력 보기
        </div>
        <div className="btn-m fr" data-testid="numberReceiptHistories-modal" onClick={(event) => handleClickModal("NumberReceiptHistories")}>
          회차별 수납내역 보기
        </div>
      </h4>
      <div>
        <form name="paymentContractList">
          <Table>
            {CM.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
            <TableBody>
              <TableRow>
                <TableCell className="th">수납형태</TableCell>
                <TableCell className="td">{Data.transactionMethod(value.transactionMethod)}</TableCell>
                <TableCell className="th">서비스상태</TableCell>
                <TableCell className="td">{fnContractStatus(value.contractStatus, value.askStatus)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">납부자번호</TableCell>
                <TableCell className="td">{value.payerNo}</TableCell>
                <TableCell className="th">자금종류</TableCell>
                <TableCell className="td">
                  <Select native value={value.capital.uniqueKey} onChange={handleValuesChange("capitalName", "capital")} required={true}>
                    <option value="">선택</option>
                    {capitalOptions.map((row) => {
                      return (
                        <option value={row.value} key={row.key}>
                          {row.label}
                        </option>
                      );
                    })}
                  </Select>
                  <button className="btn-s" onClick={(event) => handleClickModal("capitalName")}>
                    관리
                  </button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">수납금액</TableCell>
                <TableCell className="td">
                  <FormControl component="fieldset">
                    {" "}
                    <RadioGroup name="payAmountType" value={value.payAmountType} onChange={handleValuesChange("payAmountType")} data-testid="unpaid-period" row>
                      <FormControlLabel value="VARIABLE" control={<Radio color="primary" />} label="비정액" disabled={radioState.installment === "value2"} />
                      <FormControlLabel value="FIXED" control={<Radio color="primary" />} className="delay-radio" />
                      <span className="radio-label-l">정액</span>
                      <Input
                        name="payAmount"
                        className="w100"
                        value={value.payAmount}
                        onChange={handleValuesChange("payAmount")}
                        disabled={value.payAmountType !== "FIXED"}
                        required={value.payAmountType === "FIXED"}
                        endAdornment="원"
                      />
                      {value.transactionMethod === "CMS_WITHDRAW" &&
                      limitAmountForEachWithdrawal &&
                      value.payAmountType === "FIXED" &&
                      CM.cfnReplaceSymbol(value.payAmount) > limitAmountForEachWithdrawal ? (
                        <span className="inforbox3" style={{ marginBottom: 0 }}>
                          건당 CMS출금한도 {CM.cfnAddComma(limitAmountForEachWithdrawal)}원을 초과합니다.
                        </span>
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </TableCell>
                <TableCell className="th">수납일</TableCell>
                <TableCell className="td">
                  <FormControl component="fieldset">
                    <RadioGroup name="paySpecifiedDay" value={value.paySpecifiedDay} onChange={handleValuesChange("paySpecifiedDay")} data-testid="unpaid-period" row>
                      <FormControlLabel checked={value.paySpecifiedDay !== "99"} control={<Radio color="primary" />} className="delay-radio" />
                      <Select native value={value.paySpecifiedDay} onChange={handleValuesChange("paySpecifiedDay")} disabled={value.paySpecifiedDay === "99"} required={value.paySpecifiedDay !== "99"}>
                        {dayOptions.map((row, idx) => {
                          return (
                            <option value={row.value} key={idx}>
                              {row.label}
                            </option>
                          );
                        })}
                      </Select>
                      <FormControlLabel checked={value.paySpecifiedDay === "99"} value="99" control={<Radio color="primary" />} label="말일" />
                      <TooltipForm contents="매월 마지막 영업일" spanStyle={{ lineHeight: "32px" }} />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="th">총수납횟수</TableCell>
                <TableCell className="td">
                  <Input
                    name="totalNumberOfPayment"
                    className="w100"
                    value={value.totalNumberOfPayment === "0" ? "" : value.totalNumberOfPayment}
                    onChange={handleValuesChange("totalNumberOfPayment")}
                    required={true}
                    disabled={totalNumberOfPaymentState}
                  />
                  <span className="label-r">회</span>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" name="position" row>
                      <FormControlLabel control={<Checkbox checked={totalNumberOfPaymentState} onChange={handleCheckChange("totalNumberOfPayment")} color="primary" />} label="기한 없음" />
                    </FormGroup>
                  </FormControl>
                </TableCell>
                <TableCell className="th">수납주기</TableCell>
                <TableCell className="td">
                  <FormControl component="fieldset">
                    <RadioGroup name="payPeriod" value={radioState.payPeriod} onChange={handleRadioChange("payPeriod")} data-testid="unpaid-period" row>
                      <FormControlLabel value="value1" control={<Radio color="primary" />} label="비정기" />
                      <FormControlLabel value="value2" control={<Radio color="primary" />} className="delay-radio" />
                      <span className="radio-label-l">정기</span>
                      <SelectForm
                        value={value.payPeriod}
                        handleChange={handleValuesChange("payPeriod")}
                        name="payPeriod"
                        arrayOption={payPeriodOption}
                        optionValue="value"
                        optionLabel="label"
                        disabled={radioState.payPeriod !== "value2"}
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">수납시작월</TableCell>
                <TableCell className="td">
                  <SelectForm
                    disabled={true}
                    value={fnConvertY4mm(value.payStartY4mm, "year")}
                    handleChange={handleValuesChange("payStartY4mm", "payStartY4")}
                    name="payStartY4"
                    arrayOption={startYearOptions}
                    optionValue="value"
                    optionLabel="label"
                  />
                  <SelectForm
                    disabled={true}
                    value={fnConvertY4mm(value.payStartY4mm, "mm")}
                    handleChange={handleValuesChange("payStartY4mm", "payStartMm")}
                    name="payStartMm"
                    arrayOption={fnCreateMonthOption()}
                    optionValue="value"
                    optionLabel="label"
                  />
                </TableCell>
                <TableCell className="th">수납종료월</TableCell>
                <TableCell className="td">
                  <SelectForm
                    value={fnPastCheck(value.payEndY4mm, "year")}
                    handleChange={handleValuesChange("payEndY4mm", "payEndY4")}
                    name="payEndY4"
                    arrayOption={endYearOptions}
                    optionValue="value"
                    optionLabel="label"
                    disabled={flag}
                  />
                  <SelectForm
                    value={fnPastCheck(value.payEndY4mm, "mm")}
                    handleChange={handleValuesChange("payEndY4mm", "payEndMm")}
                    name="payEndMm"
                    arrayOption={fnCreateMonthOption()}
                    optionValue="value"
                    optionLabel="label"
                    disabled={flag}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th td-strong-cell">수정적용 희망월</TableCell>
                <TableCell className="td td-strong-cell" colSpan="3">
                  <SelectForm
                    value={fnConvertY4mm(value.expectedApplyY4mm, "year")}
                    required={true}
                    handleChange={handleValuesChange("expectedApplyY4mm", "expectedApplyY4")}
                    name="expectedApplyY4"
                    arrayOption={applyYearOptions}
                    optionValue="value"
                    optionLabel="label"
                  />
                  <SelectForm
                    value={fnConvertY4mm(value.expectedApplyY4mm, "mm")}
                    required={true}
                    handleChange={handleValuesChange("expectedApplyY4mm", "expectedApplyMm")}
                    name="expectedApplyMm"
                    arrayOption={fnCreateMonthOption("expectedApplyMm")}
                    optionValue="value"
                    optionLabel="label"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </div>
    </div>
  );
};

/*
@desc 이체 계좌정보 component
*/
const AccountInfo = ({
  withdrawCriteriaOptions,
  handleRadioChange,
  transactionMethod,
  value,
  handleValuesChange,
  financialInstitutes,
  financialRepresentativeInstitutes,
  radioState,
  handleCheckChange,
  handleClickModal,
  fnAccountRegistrationStatus,
  evidence,
  evidenceFile,
  instituteService,
  arsRequestStatus,
  fnProceedArsRequest,
  handleDeleteEvidenceFile,
  handleAccountChange,
  accountValue,
  setAccountValue,
  smsToCustomerSetting,
}) => {
  /*
  @desc ARS 출금동의 버튼 클릭 이벤트 핸들러
  */
  const handleClickArsRequest = () => {
    CM.cfnConfirm(
      `아래의 내용으로 ARS 동의자료 대행 요청을 진행하시겠습니까?\n- 고객명 : ${value.customer.customerName}\n- 납부자번호 : ${value.payerNo}\n- 금융기관 : ${CM.cfnMatchBankName(
        value.account.accountBankCode,
        financialInstitutes
      )}\n- 계좌번호 : ${value.account.accountNo}\n- 예금주 생년월일(사업자번호) : ${value.account.depositorIdentificationNo}`,
      fnProceedArsRequest
    );
  };

  /**
   * @desc ARS 출금동의자료 대행요청 현황 상태값에 따른 버튼 처리 및 상태명 변환 함수
   **/
  const fnConvertArsRequestStatus = () => {
    let tempObj = { disabled: false, statusName: "" };

    if (!(instituteService.serviceUseYn && instituteService.serviceType === "ARS_EVIDENCE")) {
      //동의자료 "제출" 그룹에 속해있으나 ARS 출금동의 서비스 미신청 시
      tempObj.disabled = true;
      tempObj.tooltipContents =
        "ARS 동의자료를 청구할 수 없는 상태입니다.<br/><br/>ARS 동의자료 청구 서비스를 이용하시려면<br/>기관·업무정보 > 부가서비스 정보 > ARS 동의자료 대행 서비스에서 신청해주시기 바랍니다.";
    }

    //동의자료 "제출" 그룹에 속하면서 ARS 출금동의 서비스 신청 중일 경우
    if (CM.cfnIsEmpty(arsRequestStatus)) {
      //API 조회 결과 object가 empty -> 출금동의 요청전
      tempObj.statusName = "ARS 동의자료 대행 미요청";
    } else if (CM.cfnIsNotEmpty(arsRequestStatus.createdDatetime) && CM.cfnIsNotEmpty(arsRequestStatus.callRequestResultCode)) {
      //createdDatetime 있음 및 callRequestResultCode null 혹은 empty -> 고객 출금동의 중
      if (arsRequestStatus.callRequestResultCode === "1") {
        //callRequestResultCode 1 -> 오류
        if (arsRequestStatus.callResultDesc === "inputErr") {
          tempObj.statusName = "인증번호 입력오류";
        } else if (arsRequestStatus.callResultDesc === "dialErr") {
          tempObj.statusName = "ARS전화 발신 실패";
        } else if (arsRequestStatus.callResultDesc === "noAnswer") {
          tempObj.statusName = "ARS전화 무응답";
        } else if (arsRequestStatus.callResultDesc === "reject") {
          tempObj.statusName = "자동납부 동의 거부";
        } else if (arsRequestStatus.callResultDesc === "expired") {
          tempObj.statusName = "제한기간(24시간) 만료";
        } else if (arsRequestStatus.callResultDesc === "canceled") {
          tempObj.statusName = "취소(계좌정보 수정)";
        } else {
          tempObj.statusName = "오류";
        }
      } else if (arsRequestStatus.callRequestResultCode === "0" && arsRequestStatus.callResultResultYn === "Y") {
        //callRequestResultCode 0 및 callResultResultYn Y -> 출금동의 완료
        tempObj.statusName = "ARS 자동납부 동의 완료";
      } else if (arsRequestStatus.callRequestResultCode === "" || arsRequestStatus.callResultResultYn === null) {
        //callRequestResultCode null이거나 빈값 -> LMS 발송완료 및 고객 대기
        tempObj.statusName = "LMS 발송완료 및 고객 대기";
      } else {
        //나머지 케이스 -> 오류(출금동의 거부)
        tempObj.statusName = "오류(자동납부 거부)";
      }
    } else if (CM.cfnIsNotEmpty(arsRequestStatus.createdDatetime)) {
      tempObj.disabled = true;
      tempObj.statusName = "ARS요청 중";
      let limit = CM.cfnConvertStringToDate(arsRequestStatus.createdDatetime);
      limit.setDate(limit.getDate() + 1);
      tempObj.tooltipContents = "고객의 ARS응답을 대기하는 중입니다. 요청 후 24시간 이후(" + CM.cfnDateFormat(CM.cfnConvertDateTimeToString(limit)) + ")에 다시 요청할 수 있습니다.";
    } else {
      // 이외에는 무슨상황인지 모름..
      tempObj.statusName = "ARS 동의자료 대행 미요청";
    }

    return tempObj;
  };

  const [searchValue, setSearchValue] = useState(
    {
      //선택된 계좌정보 저장을 위한 state
      accountNo: "",
      depositorIdentification: "",
      financialInstituteCode: Object.isExtensible(value.account) ? value.account.accountBankCode : "",
      fncInsNm: "",
    },
    [value.account]
  );
  const [isBankSelected, setIsBankSelected] = useState(false);
  const bankbookPostfixTypeOptions = CM.cfnGetPostFixTypes().map((type) => ({ label: type.bankbookPostfixTypeName, value: type.bankbookPostfixType }));

  useEffect(() => {
    if (value.account && value.account.accountBankCode) {
      let financialInstituteCode = value.account.accountBankCode.substring(0, 3);
      const financialInstituteIndex = financialInstitutes.findIndex((value, index) => {
        if (value.fncInsCd === financialInstituteCode) return index;
        return null;
      });
      if (financialInstituteIndex !== -1) {
        financialInstituteCode = financialInstitutes[financialInstituteIndex].repFncInsCd;
      }
      setSearchValue((value) => ({
        ...value,
        financialInstituteCode,
      }));
    }
  }, [value.account, financialInstitutes]);

  useEffect(() => {
    if (isBankSelected) {
      setIsBankSelected(false);
      // 계좌 금융기관코드 변경 체크 및 반영. 대표코드가 같으면 원래것으로 돌린다.
      if (accountValue && accountValue.accountBankCode !== null && accountValue.accountBankCode !== undefined && !accountValue.accountBankCode.startsWith(searchValue.financialInstituteCode)) {
        let financialInstituteCode = accountValue.accountBankCode.length < 3 ? accountValue.accountBankCode : accountValue.accountBankCode.substring(0, 3);
        const financialInstituteIndex = financialInstitutes.findIndex((value, index) => {
          if (value.fncInsCd === financialInstituteCode) return index;
          return null;
        });
        if (financialInstituteIndex !== -1) {
          financialInstituteCode = financialInstitutes[financialInstituteIndex].repFncInsCd; // 대표코드 찾기
        }
        if (financialInstituteCode !== searchValue.financialInstituteCode) {
          // 대표코드가 다르면 변경
          setAccountValue({ ...accountValue, accountBankCode: searchValue.financialInstituteCode });
        }
      }
    }
  }, [isBankSelected, accountValue, financialInstitutes, searchValue.financialInstituteCode, setAccountValue]);
  /*
  @desc 조건에 따라 자동납부 동의자료 & ARS 출금동의 cell render
  */
  const fnRenderWithdrawAgreementCell = () => {
    switch (evidence) {
      case "제출":
        const arsRequestStatusObj = fnConvertArsRequestStatus();
        return (
          <Fragment>
            <TableRow>
              <TableCell className="th">자동납부 동의자료</TableCell>
              <TableCell className="td">
                {evidenceFile.isSavedOnServer ? (
                  <Fragment>
                    <div className="btn-s" onClick={() => handleClickModal("file")}>
                      동의자료 확인
                    </div>
                    {/* 계좌등록완료된 경우에는 동의자료 *삭제*,등록,ars신청 불가 */}
                    {Object.isExtensible(value.account) ? (
                      value.account.accountRegistrationStatus === "READY_TO_REGISTER" ? (
                        <div className="btn-s" onClick={handleDeleteEvidenceFile}>
                          동의자료 삭제
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : // 계좌등록완료된 경우에는 동의자료 삭제,*등록*,ars신청 불가
                Object.isExtensible(value.account) ? (
                  value.account.accountRegistrationStatus === "READY_TO_REGISTER" ? (
                    <div className="btn-s" onClick={() => handleClickModal("file")}>
                      동의자료 첨부
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell className="th">계좌등록 상태</TableCell>
              <TableCell className="td">
                {fnAccountRegistrationStatus(
                  Object.isExtensible(value.account) ? value.account.accountRegistrationStatus : "",
                  Object.isExtensible(value.account) ? value.account.wayToCreateAccount : "",
                  Object.isExtensible(value.account) ? value.account.registeredDate : "",
                  Object.isExtensible(value.account) ? value.account.unregisteredDate : "",
                  Object.isExtensible(value.account) ? value.account.registrationAppliedSubject : null,
                  Object.isExtensible(value.account) ? value.account.unregistrationAppliedSubject : null,
                  value.askStatus
                )}
              </TableCell>
            </TableRow>
            {/* 계좌등록완료된 경우에는 동의자료 삭제,등록,*ars신청* 불가 */}
            {Object.isExtensible(value.account) && value.account.accountRegistrationStatus !== "REGISTERED" ? (
              <TableRow>
                <TableCell className="th">ARS 동의자료 대행</TableCell>
                <TableCell className="td">
                  {arsRequestStatusObj.disabled ? (
                    <ButtonTooltipForm contents={arsRequestStatusObj.tooltipContents} buttonContents="ARS 동의자료 요청" disabled={true} divClass="d-inline-block" />
                  ) : (
                    <div className="btn-s" onClick={handleClickArsRequest}>
                      ARS 동의자료 요청
                    </div>
                  )}
                  <div className="btn-s" onClick={(event) => handleClickModal("ARS")}>
                    이용안내(ARS관련)
                  </div>
                </TableCell>
                <TableCell className="th">ARS 동의자료 대행 상태</TableCell>
                <TableCell className="td">{arsRequestStatusObj.statusName}</TableCell>
              </TableRow>
            ) : null}
          </Fragment>
        );
      case "일부제출":
        return (
          <TableRow>
            <TableCell className="th">자동납부 동의자료</TableCell>
            <TableCell className="td">
              <Select native value={Object.isExtensible(value.account) ? value.account.evidenceFileType : ""} onChange={handleValuesChange("evidenceFileType", "account")}>
                {CM.cfnGetEvidenceFileTypes().map((row, idx) => {
                  return (
                    <option value={row.evidenceFileType} key={idx}>
                      {row.evidenceFileTypeName}
                    </option>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell className="th">계좌등록 상태</TableCell>
            <TableCell className="td">
              {fnAccountRegistrationStatus(
                Object.isExtensible(value.account) ? value.account.accountRegistrationStatus : "",
                Object.isExtensible(value.account) ? value.account.wayToCreateAccount : "",
                Object.isExtensible(value.account) ? value.account.registeredDate : "",
                Object.isExtensible(value.account) ? value.account.unregisteredDate : "",
                Object.isExtensible(value.account) ? value.account.registrationAppliedSubject : null,
                Object.isExtensible(value.account) ? value.account.unregistrationAppliedSubject : null,
                value.askStatus
              )}
            </TableCell>
          </TableRow>
        );
      default:
        return (
          <TableRow>
            <TableCell className="th">계좌등록 상태</TableCell>
            <TableCell className="td" colSpan="3">
              {fnAccountRegistrationStatus(
                Object.isExtensible(value.account) ? value.account.accountRegistrationStatus : "",
                Object.isExtensible(value.account) ? value.account.wayToCreateAccount : "",
                Object.isExtensible(value.account) ? value.account.registeredDate : "",
                Object.isExtensible(value.account) ? value.account.unregisteredDate : "",
                Object.isExtensible(value.account) ? value.account.registrationAppliedSubject : null,
                Object.isExtensible(value.account) ? value.account.unregistrationAppliedSubject : null,
                value.askStatus
              )}
            </TableCell>
          </TableRow>
        );
    }
  };

  if (transactionMethod === "CMS_WITHDRAW") {
    return (
      <div>
        <h4>이체 계좌정보</h4>
        <div className="inforbox2" style={{textAlign:"left"}}>
          ※ 계좌변경/재등록의 경우, 수납종료월을 설정하지 않았다면 계좌 미등록 기간의 내역이 청구대상에 포함됩니다.
          <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;해당내역에 대해 출금청구를 원치 않을 경우, "회차별 수납내역 보기"에서 수납보류 또는 손실처리하시기 바랍니다.
          <br/>&nbsp;&nbsp;&nbsp;&nbsp;* 경로: 빌링원플러스>고객정보관리>수납 고객정보>수납 고객정보 조회
        </div>
        <Table>
          {CM.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">금융기관</TableCell>
              <TableCell className="td">
                {Object.isExtensible(value.account) &&
                !(
                  value.account.accountRegistrationStatus === "READY_TO_REGISTER" ||
                  value.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                  value.account.accountRegistrationStatus === "UNREGISTERED" ||
                  value.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER"
                ) ? (
                  CM.cfnMatchBankName(value.account.accountBankCode, financialInstitutes)
                ) : (
                  <BankAutoComplete list={financialRepresentativeInstitutes} state={searchValue} setState={setSearchValue} setFlag={setIsBankSelected} />
                )}
              </TableCell>
              <TableCell className="th">계좌번호</TableCell>
              <TableCell className="td">
                <Input
                  readOnly={
                    Object.isExtensible(value.account)
                      ? value.account.accountRegistrationStatus === "READY_TO_REGISTER" ||
                        value.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                        value.account.accountRegistrationStatus === "UNREGISTERED" ||
                        value.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER"
                        ? false
                        : true
                      : false
                  }
                  className="w100p"
                  value={accountValue.accountNo}
                  inputProps={{
                    "aria-label": "description",
                  }}
                  onChange={handleAccountChange("accountNo")}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">출금형태</TableCell>
              <TableCell className="td">
                <SelectForm
                  value={value.withdrawCriteria}
                  handleChange={handleValuesChange("withdrawCriteria")}
                  name="withdrawCriteria"
                  arrayOption={withdrawCriteriaOptions}
                  optionValue="value"
                  optionLabel="label"
                  style={{ width: "98%" }}
                />
              </TableCell>
              <TableCell className="th">예금주</TableCell>
              <TableCell className="td">
                <Input
                  readOnly={
                    Object.isExtensible(value.account)
                      ? value.account.accountRegistrationStatus === "REGISTERED" ||
                        value.account.accountRegistrationStatus === "READY_TO_REGISTER" ||
                        value.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                        value.account.accountRegistrationStatus === "UNREGISTERED" ||
                        value.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER"
                        ? false
                        : true
                      : false
                  }
                  className="w100p"
                  value={accountValue.depositorName}
                  onChange={handleAccountChange("depositorName")}
                  inputProps={{
                    "aria-label": "description",
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">
                예금주 생년월일
                <br />
                (사업자번호)
                <TooltipForm contents="출금계좌 등록시나 현금영수증 자동발행시 예금주 사업자번호로 들어간 경우 사용(지출증빙용)됩니다." spanStyle={{ lineHeight: "32px" }} />
              </TableCell>
              <TableCell className="td">
                <Input
                  readOnly={
                    Object.isExtensible(value.account)
                      ? value.account.accountRegistrationStatus === "READY_TO_REGISTER" ||
                        value.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                        value.account.accountRegistrationStatus === "UNREGISTERED" ||
                        value.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER"
                        ? false
                        : true
                      : false
                  }
                  className="100p"
                  value={accountValue.depositorIdentificationNo}
                  onChange={handleAccountChange("depositorIdentificationNo")}
                  inputComponent={MaskedField}
                  inputProps={{
                    mask: "identificationNo",
                    "aria-label": "description",
                  }}
                  placeholder="6자리 생년월일 또는 10자리 사업자번호"
                />
              </TableCell>
              <TableCell className="th">
                예금주 연락처
                <TooltipForm contents="현금영수증 자동발행시 예금주 생년월일로 들어간 경우 사용(소득공제용)됩니다." spanStyle={{ lineHeight: "32px" }} />
              </TableCell>
              <TableCell className="td">
                <FormControl>
                  <Input
                    readOnly={
                      Object.isExtensible(value.account)
                        ? value.account.accountRegistrationStatus === "REGISTERED" ||
                          value.account.accountRegistrationStatus === "READY_TO_REGISTER" ||
                          value.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                          value.account.accountRegistrationStatus === "UNREGISTERED" ||
                          value.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER"
                          ? false
                          : true
                        : false
                    }
                    className="w100p"
                    value={accountValue.depositorTelephone}
                    placeholder={"상세 내용은 \"좌측의 물음표 그림\" 위에 마우스를 올려보세요"}
                    style={{
                      width: `150px`,
                      ...(accountValue.depositorTelephone === '' && { minWidth: '400px'}),
                    }}
                    onChange={handleAccountChange("depositorTelephone")}
                    required={true}
                    inputComponent={MaskedField}
                    inputProps={{
                      mask: "mobile",
                    }}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">통장기재내용</TableCell>
              <TableCell className="td" colSpan="3">
                <span className="label-l">기본</span>
                <Input
                  className="w150"
                  value={value.bankbookContents}
                  onChange={handleValuesChange("bankbookContents")}
                  inputProps={{
                    "aria-label": "description",
                  }}
                  required={true}
                  readOnly={true}
                />
                <span className="label-l">추가</span>
                <SelectForm
                  value={value.bankbookPostfixType}
                  handleChange={handleValuesChange("bankbookPostfixType")}
                  name="bankbookPostfixType"
                  arrayOption={bankbookPostfixTypeOptions}
                  optionValue="value"
                  optionLabel="label"
                />
                <Input
                  disabled={value.bankbookPostfixType !== "MANUAL"}
                  className="w150"
                  value={value.bankbookPostfix}
                  onChange={handleValuesChange("bankbookPostfix")}
                  inputProps={{
                    "aria-label": "description",
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">
                출금정보 자동 알림
                <TooltipForm contents="이용기관에서 입금 또는 출금 대상 고객에게 입출금 예정 금액이나 실제 입출금 결과를 SMS로 자동 통지해주는 서비스.<br/>발송 건당 16원(부가세 별도) 요금 부과" />
              </TableCell>
              <TableCell className="td" colSpan="3">
                <FormControl component="fieldset">
                  <RadioGroup aria-label="position" name="position" value={radioState.SMS} onChange={handleRadioChange("SMS")} row>
                    <FormControlLabel value="value1" control={<Radio color="primary" />} label="고지안함" />
                    <FormControlLabel
                      value="value2"
                      control={<Radio color="primary" />}
                      disabled={
                        !smsToCustomerSetting.serviceUseYn ||
                        (!smsToCustomerSetting.sendForAskingWithdraw && !smsToCustomerSetting.sendForFailureOnWithdraw && !smsToCustomerSetting.sendForSuccessOnWithdraw)
                      }
                      label={
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" name="position" value={value} onChange={handleCheckChange} row>
                            <span className="radio-label-l">
                              고지
                              {!smsToCustomerSetting.serviceUseYn ||
                              (!smsToCustomerSetting.sendForAskingWithdraw && !smsToCustomerSetting.sendForFailureOnWithdraw && !smsToCustomerSetting.sendForSuccessOnWithdraw) ? (
                                <TooltipForm contents="입출금 자동 알림 서비스를 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                              ) : null}
                            </span>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingWithdraw && value.sendForAskingWithdraw}
                                  onChange={handleCheckChange("sendForAskingWithdraw")}
                                  color="primary"
                                  disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForAskingWithdraw}
                                />
                              }
                              label={
                                <>
                                  출금청구시
                                  {smsToCustomerSetting.serviceUseYn &&
                                    !smsToCustomerSetting.sendForAskingWithdraw &&
                                    (smsToCustomerSetting.sendForFailureOnWithdraw || smsToCustomerSetting.sendForSuccessOnWithdraw) && (
                                      <TooltipForm contents="출금청구시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                                    )}
                                </>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnWithdraw && value.sendForSuccessOnWithdraw}
                                  onChange={handleCheckChange("sendForSuccessOnWithdraw")}
                                  color="primary"
                                  disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForSuccessOnWithdraw}
                                />
                              }
                              label={
                                <>
                                  정상출금시
                                  {smsToCustomerSetting.serviceUseYn &&
                                    !smsToCustomerSetting.sendForSuccessOnWithdraw &&
                                    (smsToCustomerSetting.sendForFailureOnWithdraw || smsToCustomerSetting.sendForAskingWithdraw) && (
                                      <TooltipForm contents="정상출금시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                                    )}
                                </>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnWithdraw && value.sendForFailureOnWithdraw}
                                  onChange={handleCheckChange("sendForFailureOnWithdraw")}
                                  color="primary"
                                  disabled={!smsToCustomerSetting.serviceUseYn || !smsToCustomerSetting.sendForFailureOnWithdraw}
                                />
                              }
                              label={
                                <>
                                  미출금시
                                  {smsToCustomerSetting.serviceUseYn &&
                                    !smsToCustomerSetting.sendForFailureOnWithdraw &&
                                    (smsToCustomerSetting.sendForAskingWithdraw || smsToCustomerSetting.sendForSuccessOnWithdraw) && (
                                      <TooltipForm contents="미출금시 자동 알림을 사용하도록 설정되어있지 않습니다.<br/>기관·업무정보 > 부가서비스 정보에서 설정을 변경하실 수 있습니다." />
                                    )}
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            {fnRenderWithdrawAgreementCell() /* 조건에 따라 자동납부 동의자료 첨부란 및 ARS 출금동의 선택란 렌더 */}
          </TableBody>
        </Table>
      </div>
    );
  } else {
    return "";
  }
};

/*
@desc 고객 계좌 정보/ 자동납부 동의자료 component
*/
const ExtraServiceInfo = (props) => {
  if (props.transactionMethod === "CMS_WITHDRAW") {
    return (
      <div className="display-none">
        <h4>고객 계좌 정보 / 자동납부 동의자료</h4>
        <Table>
          {CM.cfnCompColGroup(["15%", "35%", "15%", "35%"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">ARS 출금동의</TableCell>
              <TableCell className="td">
                <div className="btn-s">서비스 신청</div>
                <div className="btn-s">이용예시</div>
              </TableCell>
              <TableCell className="td">
                본 서비스는 성공 건당 100원, 실패 건당 50원의 요금이 부가됩니다.
                <br />본 서비스를 이용함에 있어서 부당출금 방지를 위해 출금계좌의 실제 소유주와 고객(지급인)이 동일인임은 이용기관에서 확인해야 합니다.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">실시간 실명조회</TableCell>
              <TableCell className="td">
                <div className="btn-s">서비스 신청</div>
                <div className="btn-s">이용예시</div>
              </TableCell>
              <TableCell className="td">
                계좌번호와 계좌소유주를 실시간으로 확인하는 서비스로 이용시간은 08:00 ~ 22:00까지 입니다.
                <br />
                실명조회 이용요금은 금융기관별로 상이합니다.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  } else {
    return "";
  }
};

const ReceiptCustomerUpdate = (props) => {
  // 이전 page로부터 전달받은 값
  let location = useLocation();
  let history = useHistory();
  const { store, loginStore } = props;
  let defaultCopyObject = location.state.receipt;

  // 고객정보 hooks
  const [customer, setCustomer] = useState(CustomerData.getCustomer(location.state.receipt.customer));

  // 고객 이메일 주소
  const [customerEmail, setCustomerEmail] = useState(CustomerData.getCustomerEmail(location.state.receipt.customer));

  // 담당자 정보
  const [customerDetailInfo, setCustomerDetailInfo] = useState(CustomerData.getCustomerDetailInfo(location.state.receipt.customer));

  // 담당자 정보 handler
  const handleCustomerDetailInfoChange = (name, e) => {
    const value = name !== "결혼기념일" ? e.target.value : e;
    setCustomerDetailInfo((data) => ({ ...data, [name]: value }));
  };

  // 고객구분영역 hooks and handler
  const [firstIdentificationNo, setFirstIdentificationNo] = useState("");
  const [lastIdentificationNo, setLastIdentificationNo] = useState("");
  const [checkIdentificationNo, setCheckIdentificationNo] = useState(
    location.state && location.state.receipt && location.state.receipt.customer && location.state.receipt.customer.identificationNo && location.state.receipt.customer.identificationNo.length > 6
  ); // 주민등록번호앞자리 체크 hooks
  const [limitAmountForEachWithdrawal, setLimitAmountForEachWithdrawal] = useState(null);

  // 주민등록번호 앞자리, 뒷자리 handler
  const handleIdentificationNoChange = (e, type) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    changeIdentificationNo(value, type);
  };

  // 주민등록번호 앞자리, 뒷자리 입력시 해당 값 변경
  const changeIdentificationNo = (value, type) => {
    if (type === "first") {
      setFirstIdentificationNo(value);
    } else {
      setLastIdentificationNo(value);
    }
  };

  // 주민등록번호 앞, 뒷자리 초기화
  useEffect(() => {
    if (customer && customer.customerType === "INDIVIDUAL") {
      if (customer.identificationNo) {
        if (customer.identificationNo.length >= 6) {
          setFirstIdentificationNo(customer.identificationNo.substring(0, 6));
          setLastIdentificationNo(customer.identificationNo.substring(6));
        } else {
          setFirstIdentificationNo(customer.identificationNo);
        }
      }
    }
  }, [customer]);

  // 현금영수증 발행정보 radio 값
  const [selectedCashbillIdentificationNo, setSelectedCashbillIdentificationNo] = useState(CustomerData.getCashbillIdentificationNo(location.state.receipt.customer));

  // 이메일 주소 handler
  const handleCustomerEmailChange = (e, name) => {
    const value = e.target.value;
    setCustomerEmail((email) => ({
      ...email,
      [name]: value,
    }));
  };

  // 이메일 주소 selectbox handler
  const handleSelectboxCustomerEmailChange = (e) => {
    const value = e.target.value;
    const domain = value !== "manual" ? value : "";

    setCustomerEmail((email) => ({
      ...email,
      domain: domain,
      selected: value,
    }));
  };

  // 선택입력사항 handler(이메일, 현금영수증 발행정보 제외)
  const handleCustomerChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    // 현금영수증 발행정보의 주민등록번호를 입력할 때
    if (name === "cashbillIdentificationNo" && selectedCashbillIdentificationNo === "IDENTIFICATION_NO") {
      value = value.replace(/[^0-9]/g, "");
    }

    changeCustomer(name, value);
  };

  // 현금영수증 발행정보 radio handler
  const handleCashbillIdentificationNoRadioChange = (e) => {
    const value = e.target.value;
    setSelectedCashbillIdentificationNo(value);

    // 현금영수증 발행정보 input 초기화
    changeCustomer("cashbillIdentificationNo", "");
  };

  // 선택입력사항 입력시 해당 값 변경
  const changeCustomer = (name, value) => {
    setCustomer((data) => ({ ...data, [name]: value }));
  };

  // 개인 && 사업 > 고객정보 > 필수 입력사항 hooks and handler
  const [selectRequiredInputs, setSelectRequiredInputs] = useState(CustomerData.getCustomerRequiredInputs(location.state.receipt.customer));
  const [optionStaffsAndBranches, setOptionStaffsAndBranches] = useState([]);

  // 필수 입력 사항 목록 가져오기
  useEffect(() => {
    async function startStoreAxios() {
      try {
        await store.axiosCustomerGroup(); // 고객구분
        const resultStaffs = await store.axiosStaffs();
        const tempStaffs = [];

        // 관리담당자/담당지사 option data 설정
        for (const staff of resultStaffs) {
          tempStaffs.push({
            uniqueKey: staff.uniqueKey,
            name: `${staff.staffName}/${staff.branch.branchName}`,
          });
        }
        setOptionStaffsAndBranches(tempStaffs);

        await store.axiosBusinessInfo();
        setLimitAmountForEachWithdrawal(store.businessInfo && store.businessInfo.cmsBusiness ? store.businessInfo.cmsBusiness.limitAmountForEachWithdrawal : null);
      } catch (error) {
        console.error(error);
      }
    }
    startStoreAxios();
  }, [store]);

  // 선택한 고객구분, 고객담당지사, 고객관리담당자 값을 변경하는 handler
  const handleRequiredInputsHandler = (e, name) => {
    const value = e.target.value;
    setSelectRequiredInputs((data) => ({ ...data, [name]: value }));
  };

  // 고객 등록시 필수 값 체크
  const checkRequired = () => {
    if (CM.cfnIsEmpty(selectRequiredInputs.customerGroup)) {
      CM.cfnAlert("고객구분을 선택하세요.");
      return false;
    }

    if (CM.cfnIsEmpty(selectRequiredInputs.staff)) {
      CM.cfnAlert("고객관리담당자/고객담당지사를 선택하세요.");
      return false;
    }

    return true;
  };

  //기관 부가서비스 정보
  const [instituteService, setInstituteService] = useState({});

  //화면 진입 시 기관 부가서비스 정보 조회(ARS 출금동의 여부 확인)
  useEffect(() => {
    /*
     * @desc    기관 부가서비스 정보 조회 Request
     */
    const fnFetchInstituteServiceInfo = async () => {
      const resultData = await store.axiosInstituteService();

      if (resultData.length > 0) fnGetArsEvidence(resultData);
    };

    /*
     * @desc    기관 부가서비스 목록 중 ARS 출금동의 서비스 관련 항목 추출
     */
    const fnGetArsEvidence = (list) => {
      for (const row of list) {
        if (row.serviceType === "ARS_EVIDENCE") {
          setInstituteService({ serviceType: row.serviceType, serviceUseYn: row.serviceUseYn });
          return;
        }
      }

      setInstituteService({ serviceType: "", serviceUseYn: false });
    };
    //실행영역
    if (CM.cfnIsEmpty(toJS(store.instituteService)) && CM.cfnIsEmpty(instituteService)) fnFetchInstituteServiceInfo();
    else if (CM.cfnIsNotEmpty(toJS(store.instituteService)) && CM.cfnIsEmpty(instituteService)) fnGetArsEvidence(toJS(store.instituteService));
  }, [store, instituteService]);

  //ARS 서비스 이용 정보
  const [arsRequestStatus, setArsRequestStatus] = useState({});
  const accountUniqueKey = Object.isExtensible(location.state.receipt.account) ? location.state.receipt.account.uniqueKey : ""; //account 정보 없을 시 처리 위해 별도 변수 선언

  useEffect(() => {
    /*
     * @desc    출금계좌 ARS출금동의자료 대행요청 현황조회 Request
     */
    const fnFetchArsRequestStatus = () => {
      const url = `api/customer/ars/result/${accountUniqueKey}`;

      CM.cfnAxios(
        url,
        "get",
        "",
        async (status, data) => {
          setArsRequestStatus(data);
        },
        async (status, data) => {
          //대행요청 결과 없을 시 404 에러 alert창 뜨지 않도록 empty error callback 선언
        },
        false,
        true
      );
    };
    //실행영역
    const transactionMethod = location.state.receipt.transactionMethod;
    if (CM.cfnIsEmpty(arsRequestStatus) && transactionMethod === "CMS_WITHDRAW") fnFetchArsRequestStatus();
  }, [arsRequestStatus, location.state.receipt.transactionMethod, accountUniqueKey]);

  /*
  @desc ARS 출금동의 대행요청 Request
  */
  const fnProceedArsRequest = () => {
    const url = `api/customer/ars/${location.state.receipt.account.uniqueKey}`;
    CM.cfnAxios(url, "put", "", (status, data) => {
      CM.cfnAlert("정상적으로 처리되었습니다.", setArsRequestStatus({}));
    });
  };

  const capitalType = "RECEIPT";

  const [value, setValue] = useState(() => {
    let valueData = location.state.receipt;
    valueData.payAmount = CM.cfnAddCommaOnChange(valueData.payAmount);
    return valueData;
  });
  const [smsToCustomerSetting, setSmsToCustomerSetting] = useState({ serviceUseYn: false, sendForAskingWithdraw: false, sendForFailureOnWithdraw: false, sendForSuccessOnWithdraw: false });

  /*
    @desc radio 버튼 제어 state 
    */
  const [radioState, setRadioState] = useState({
    cashbillIdentificationNo: "NONE",
    // autoGeneratePayerNoYn: false, //store.businessInfo.autoGeneratePayerNoYn
    payerNoType: value.payerNo !== "" ? "value2" : "value1", //납부자번호
    installment: "", //할부구분
    payPeriod: value.payPeriod !== "VARIABLE" ? "value2" : "value1", //수납주기
    SMS: value.sendForAskingWithdraw || value.sendForFailureOnWithdraw || value.sendForSuccessOnWithdraw ? "value2" : "value1", //SMS고지방법
  });

  /*
    @desc 계좌변경 State 
    */
  const [accountValue, setAccountValue] = useState({
    accountBankCode: Object.isExtensible(value.account) ? value.account.accountBankCode : "",
    accountNo: Object.isExtensible(value.account) ? value.account.accountNo : "",
    depositorIdentificationNo: Object.isExtensible(value.account) ? value.account.depositorIdentificationNo : "",
    depositorName: Object.isExtensible(value.account) ? value.account.depositorName : "",
    depositorTelephone: Object.isExtensible(value.account) ? value.account.depositorTelephone : "",
  });

  const [totalNumberOfPaymentState, setTotalNumberOfPaymentState] = useState(value.payEndY4mm === "999912");

  /*
    @desc 변경이력조회
    */
  const [receiptCustomerChangeHistories, setReceiptCustomerChangeHistories] = useState([]);

  /*
    @desc 수납종료월 자동계산 여부 판단 flag
    */
  const [flag, setFlag] = useState(false);
  const [isDirectlyUpdatingPayRange, setIsDirectlyUpdatingPayRange] = useState(false);

  /*
  @desc 기본정보 > 수납종료월 자동계산을 위한 useEffect
  */
  useEffect(() => {
    let year = value.payStartY4mm !== null ? Number(value.payStartY4mm.toString().substring(0, 4)) : 0;
    let month = value.payStartY4mm !== null ? Number(value.payStartY4mm.toString().substring(4)) - 1 : 0;
    let endYear = value.payEndY4mm !== null ? Number(value.payEndY4mm.toString().substring(0, 4)) : 0;
    let endMonth = value.payEndY4mm !== null ? Number(value.payEndY4mm.toString().substring(4)) - 1 : 0;
    if (value.payPeriod !== "VARIABLE" && radioState.payPeriod !== "value1" && endYear !== 9999 && value.payStartY4mm.length > 4) {
      let monthDifference = (endYear - year) * 12 - month + endMonth;
      let totalNumberOfPayment = "";
      switch (value.payPeriod) {
        case "MONTHLY":
          totalNumberOfPayment = Math.floor(monthDifference / 1.0);
          break;
        case "EVERY_2_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 2.0);
          break;
        case "EVERY_3_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 3.0);
          break;
        case "EVERY_4_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 4.0);
          break;
        case "EVERY_6_MONTHS":
          totalNumberOfPayment = Math.floor(monthDifference / 6.0);
          break;
        case "YEARLY":
          totalNumberOfPayment = Math.floor(monthDifference / 12.0);
          break;
        default:
          break;
      }
      // setFlag(true);
      setValue((val) => ({
        ...val,
        totalNumberOfPayment: totalNumberOfPayment + 1,
      }));
    } else {
      if (endYear === 9999) {
        setValue((val) => ({
          ...val,
          totalNumberOfPayment: "",
        }));
      }
      setFlag(!!totalNumberOfPaymentState);
    }
  }, [value.payPeriod, value.payEndY4mm, value.payStartY4mm, flag, totalNumberOfPaymentState, radioState.payPeriod]);

  useEffect(() => {
    if (isDirectlyUpdatingPayRange) {
      if (value.payPeriod !== "VARIABLE" && radioState.payPeriod !== "value1" && value.payStartY4mm.length > 4) {
        if (value.totalNumberOfPayment !== "" && value.totalNumberOfPayment > 0) {
          let period = 1.0;
          switch (value.payPeriod) {
            case "EVERY_2_MONTHS":
              period = 2.0;
              break;
            case "EVERY_3_MONTHS":
              period = 3.0;
              break;
            case "EVERY_4_MONTHS":
              period = 4.0;
              break;
            case "EVERY_6_MONTHS":
              period = 6.0;
              break;
            case "YEARLY":
              period = 12.0;
              break;
            default:
              break;
          }
          let months = period * (Number(value.totalNumberOfPayment) - 1);
          let payEndY4mm = new Date(value.payStartY4mm.substring(0, 4), value.payStartY4mm.substring(4) === "" ? 0 : Number(value.payStartY4mm.substring(4)) - 1 + months);
          setValue((val) => ({
            ...val,
            totalNumberOfPayment: val.totalNumberOfPayment,
            payEndY4mm: payEndY4mm.getFullYear() + "" + (payEndY4mm.getMonth() < 9 ? "0" + (payEndY4mm.getMonth() + 1) : payEndY4mm.getMonth() + 1),
          }));
        } else {
          setValue((val) => ({ ...val, payEndY4mm: "999912" }));
        }
      }
      setIsDirectlyUpdatingPayRange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirectlyUpdatingPayRange, value.totalNumberOfPayment, value.payStartY4mm]);
  const [financialRepresentativeInstitutes, setFinancialRepresentativeInstitutes] = useState([]); //금융기관 자동완성 기능을 위한 state
  const [financialInstitutes, setFinancialInstitutes] = useState([]);

  useEffect(() => {
    CM.cfnIsEmpty(financialRepresentativeInstitutes) &&
      (async () => {
        setFinancialRepresentativeInstitutes(await store.axiosFinancialRepresentativeInstitutes());
      })();
    CM.cfnIsEmpty(financialInstitutes) &&
      (async () => {
        setFinancialInstitutes(await store.axiosFinancialInstitutes());
      })();
  }, [financialRepresentativeInstitutes, financialInstitutes, store]);

  /*
  @desc 수납주기 > 정기 옵션 요소
  */
  const payPeriodOption = [
    { value: "MONTHLY", label: "매월" },
    { value: "EVERY_2_MONTHS", label: "2개월마다" },
    { value: "EVERY_3_MONTHS", label: "3개월마다" },
    { value: "EVERY_4_MONTHS", label: "4개월마다" },
    { value: "EVERY_6_MONTHS", label: "6개월마다" },
    { value: "YEARLY", label: "매년" },
  ];

  /*
  @desc 출금형태 옵션 요소
  */
  const withdrawCriteriaOptions = [
    { value: "ABOVE_FEE", label: "수수료 이상금액" },
    { value: "ENTIRE_PRICE", label: "전액출금" },
    { value: "ABOVE_1000", label: "1000원이상" },
    { value: "ABOVE_2000", label: "2000원이상" },
    { value: "ABOVE_3000", label: "3000원이상" },
    { value: "ABOVE_4000", label: "4000원이상" },
    { value: "ABOVE_5000", label: "5000원이상" },
  ];

  /*
  @desc 기본정보 > 서비스상태 상태/버튼 노출 구분 함수
  */
  const fnContractStatus = (contractStatus, askStatus) => {
    switch (contractStatus) {
      case "NORMAL":
        return (
          <div>
            <label>정상{askStatus === "ASKING" ? "(청구중)" : ""}</label>
            <div className="btn-s" data-testid="receiptStop-modal" onClick={(event) => handleClickModal("ReceiptStop")}>
              수납중지
            </div>
          </div>
        );

      case "PAUSED":
        return (
          <div>
            <label>수납중지</label>
            <div className="btn-s" data-testid="receiptStopCancel-modal" onClick={fnReceiptStop("receiptStopCancel")}>
              수납중지 해제
            </div>
          </div>
        );

      case "DELETED":
        return (
          <div>
            <label>삭제</label>
          </div>
        );

      default:
        break;
    }
  };

  //수납중지 이벤트 함수
  const fnReceiptStop = (name) => async () => {
    let massage;
    let contractStatus;
    if (name === "receiptStop") {
      massage =
        "수납을 중지하시겠습니까?\n수납중지하더라도 당월 이전의 미납/미청구 내역은 청구 대상에 포함되므로,\n미납/미청구 내역에 대해 청구하지 않으시려면 아래에서 수납보류 혹은 손실처리 작업을 해주시기 바랍니다.";
      contractStatus = "PAUSED";
    } else {
      massage =
        '수납중지를 해제 하시겠습니까?\n수납중지를 해제하는 경우 출금청구시 수납중지월 이후의 내역이 모두 청구 대상에 포함됩니다.\n해당내역에 대해 출금청구를 원치 않을 경우, "회차별 수납내역 보기"에서 수납보류 또는 손실처리하시기 바랍니다.';
      contractStatus = "NORMAL";
    }

    CM.cfnConfirm(massage, () => {
      const url = `/api/customer/receipt/receipts/pause?contractUniqueKey=${value.uniqueKey}`;
      CM.cfnAxios(url, "put", value.uniqueKey, (objStatus, objData) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        // 성공시
        CM.cfnAlert(objData);
        setValue({
          ...value,
          contractStatus: contractStatus,
        });
        setReceiptStopOpen(false);
      });
    });
  };

  //계좌변경 이벤트 함수
  const fnAccountUpdate = async () => {
    //계좌변경 statecopy
    const accountCopyObject = CM.cfnCopyObject(toJS(accountValue));
    //계좌변경 파리미터 생성 함수
    function fnAccountSubmit() {
      return new Promise((resolve) => {
        accountCopyObject.depositorTelephone = CM.cfnReplaceSymbol(accountCopyObject.depositorTelephone);
        accountCopyObject.depositorIdentificationNo = CM.cfnReplaceSymbol(accountCopyObject.depositorIdentificationNo);
        accountCopyObject.accountNo = CM.cfnReplaceSymbol(accountCopyObject.accountNo);
        resolve(true);
      });
    }
    const result = await fnAccountSubmit();
    if (result) {
      let form = new FormData();
      const url = `/api/customer/receipt/receipts/account/update`;
      // if (evidenceFile.file && evidenceFile.fileName && evidenceFile.fileName !== "") {
      //   form.append("file", evidenceFile.file, evidenceFile.fileName);
      // } else {
      //   form.append("file", evidenceFile.file);
      // }
      form.append(
        "contractUniqueKey ",
        new Blob([JSON.stringify(value.uniqueKey)], {
          type: "application/json",
        })
      );
      form.append(
        "account",
        new Blob([JSON.stringify(accountCopyObject)], {
          type: "application/json",
        })
      );
      CM.cfnAxios(
        url,
        "post",
        form,
        (objStatus, objData) => {
          if (objStatus.status !== 200) {
            CM.cfnAlert(objStatus.statusText);
            return;
          }
          // 성공시
          CM.cfnAlert(objData);

          // 기존에 계좌가 없었는데 만들었다면 uniqueKey를 받아와야 하기때문에 정보를 다시 요청한다.
          resetContractInformation();
          setAccountUpdateOpen(false);
          setEvidenceFile({
            file: null,
            fileType: null,
            fileName: null,
            isSavedOnServer: false,
          });
        },
        "",
        true
      );
    }
  };

  //계좌변경 신청 취소 이벤트 함수
  const fnAccountCancelUpdate = () => {
    CM.cfnConfirm("계좌변경 신청을 취소 하시겠습니까?", () => {
      const url = `/api/customer/receipt/receipts/account/cancelUpdate?contractUniqueKey=${value.uniqueKey}`;
      CM.cfnAxios(url, "put", value.uniqueKey, (objStatus, objData) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        // 성공시(responseObject로 변경)
        setValue((val) => ({
          ...val,
          account: objData,
        }));
        setAccountValue({
          accountBankCode: objData.accountBankCode,
          accountNo: objData.accountNo,
          depositorIdentificationNo: CM.cfnReplaceSymbol(objData.depositorIdentificationNo),
          depositorName: objData.depositorName,
          depositorTelephone: objData.depositorTelephone,
        });
        CM.cfnAlert("정상적으로 처리되었습니다.");
      });
    });
  };

  //계좌해지 신청 취소 버튼 이벤트 함수
  const fnAccountCancelUnregister = () => {
    CM.cfnConfirm("계좌해지 신청을 취소 하시겠습니까?", () => {
      const url = `api/customer/receipt/receipts/account/cancelUnregister?contractUniqueKey=${value.uniqueKey}`;
      CM.cfnAxios(url, "put", value.uniqueKey, (objStatus, objData) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        // 성공시
        CM.cfnAlert(objData);
        setValue((val) => ({
          ...val,
          account: {
            ...val.account,
            accountRegistrationStatus: "REGISTERED",
          },
        }));
      });
    });
  };
  //계좌해지 버튼 이벤트 함수
  const fnAccountDelete = () => {
    const confirmMessage = "계좌를 해지하시겠습니까?\n※계좌가 해지되어도 수납내역은 계속 생성됩니다. \n더 이상 수납하지 않을 고객이라면 수납 고객정보에서 해당 고객을 수납중지하거나, 수납종료월을 입력(총수납횟수 \"기한없음\"이 체크된 경우 해제 후)하시기 바랍니다."
    CM.cfnConfirm(confirmMessage, () => {
      const url = `api/customer/receipt/receipts/account/${value.uniqueKey}`;
      CM.cfnAxios(url, "put", value.uniqueKey, (objStatus, objData) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        // 성공시
        CM.cfnAlert(objData);
        setValue((val) => ({
          ...val,
          account: {
            ...val.account,
            accountRegistrationStatus: "READY_TO_UNREGISTER",
          },
        }));
      });
    });
  };

  const fnAccountRegister = () => {
    CM.cfnConfirm("해지된 계좌를 다시 등록대기 상태로 변경하시겠습니까?", () => {
      const url = `api/customer/receipt/receipts/account`;
      let form = new FormData();
      let tempObj = { ...value, customer: { ...customer } };
      // 개인일 때
      if ("INDIVIDUAL" === customer.customerType) {
        tempObj.customer.identificationNo = firstIdentificationNo + lastIdentificationNo;
        setCustomer({ ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo });
      }
      setValue({ ...value, customer: { ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo } });
      const sendData = fnConvertForRequest(tempObj);
      if (!sendData) {
        // 검증 실패
        return;
      }

      if (evidenceFile.file && evidenceFile.fileName && evidenceFile.fileName !== "") {
        form.append("file", evidenceFile.file, evidenceFile.fileName);
      } else {
        form.append("file", evidenceFile.file);
      }
      form.append("account", new Blob([JSON.stringify(sendData.account)], { type: "application/json" }));
      form.append(
        "contractUniqueKey",
        new Blob([JSON.stringify(value.uniqueKey)], {
          type: "application/json",
        })
      );
      CM.cfnAxios(url, "post", form, (objStatus, objData) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        // 성공시
        CM.cfnAlert("정상적으로 처리되었습니다.");
        resetContractInformation();
      });
    });
  };

  /*
  @desc 이체 계좌정보 > 계좌등록 상태/버튼 노출 구분 함수
  */
  const fnAccountRegistrationStatus = (accountRegistrationStatus, wayToCreateAccount, registeredDate, unregisteredDate, registrationAppliedSubject, unregistrationAppliedSubject, askStatus) => {
    switch (accountRegistrationStatus) {
      case "READY_TO_REGISTER":
        return (
          <div>
            등록대기
            {wayToCreateAccount === "CHANGING" && askStatus !== "ASKING" && askStatus !== "REGISTERING" ? (
              <div className="btn-s" data-testid="accountUpdateCancel-modal" onClick={fnAccountCancelUpdate} style={{ marginTop: "0px" }}>
                계좌변경 취소
              </div>
            ) : (
              ""
            )}
          </div>
        );
      case "REGISTERING":
        return <div>등록중</div>;
      case "REGISTERED":
        return (
          <div>
            등록됨
            {registeredDate !== "" && registeredDate ? "(" + (registrationAppliedSubject === "BANK" ? "금융기관등록 " : "직접등록 ") + CM.cfnDateFormat(registeredDate) + ")" : ""}
            {askStatus !== "ASKING" && askStatus !== "REGISTERING" ? (
              <>
                <div className="btn-s" data-testid="accountCancel-modal" onClick={fnAccountDelete} style={{ marginTop: "0px" }}>
                  계좌해지
                </div>
                <div className="btn-s" data-testid="accountUpdate-modal" onClick={(e) => handleClickModal("AccountUpdate")} style={{ marginTop: "0px" }}>
                  계좌변경
                </div>
              </>
            ) : null}
          </div>
        );
      case "FAILED_TO_REGISTER":
        return <div>등록실패</div>;
      case "READY_TO_UNREGISTER":
        return (
          <div>
            해지대기
            {registeredDate !== "" && registeredDate ? "(" + (registrationAppliedSubject === "BANK" ? "금융기관등록 " : "직접등록 ") + CM.cfnDateFormat(registeredDate) + ")" : ""}
            {askStatus !== "ASKING" && askStatus !== "REGISTERING" ? (
              <div className="btn-s" onClick={fnAccountCancelUnregister}>
                계좌해지 취소
              </div>
            ) : null}
          </div>
        );
      case "UNREGISTERING":
        return (
          <div>
            해지중
            {registeredDate !== "" && registeredDate ? "(" + (registrationAppliedSubject === "BANK" ? "금융기관등록 " : "직접등록 ") + CM.cfnDateFormat(registeredDate) + ")" : ""}
          </div>
        );
      case "UNREGISTERED":
        const contents = "수납 고객이 금융기관에서 직접 계좌등록을 해지한 계좌는 이용기관에서 직접 재신청이 불가능합니다.<br/>수납 고객이 금융기관(또는 payinfo사이트)에서 직접 계좌등록을 재신청하거나, 이용기관에서 납부자번호를 변경해서 새로 등록하셔야 합니다.";
        return (
          <div>
            해지됨
            {registeredDate !== "" && registeredDate
              ? "(" +
                (registrationAppliedSubject === "BANK" ? "금융기관등록 " : "직접등록 ") +
                CM.cfnDateFormat(registeredDate) +
                (unregisteredDate !== "" && unregisteredDate ? ", " + (unregistrationAppliedSubject === "BANK" ? "금융기관" : "직접") + "해지 " + CM.cfnDateFormat(unregisteredDate) : "") +
                ")"
              : ""}
            {unregisteredDate !== "" && unregisteredDate && unregistrationAppliedSubject === "BANK" ? (
                <ButtonTooltipForm contents={contents} buttonContents="재등록" disabled={true} divClass="d-inline-block" />
                ) :
                ( <div className="btn-s" onClick={fnAccountRegister}>`
                  재등록
                </div> )
            }
          </div>
        );
      case "FAILED_TO_UNREGISTER":
        return (
          <div>
            해지실패
            {registeredDate !== "" && registeredDate
              ? "(" +
                (registrationAppliedSubject === "BANK" ? "금융기관등록 " : "직접등록 ") +
                CM.cfnDateFormat(registeredDate) +
                (unregisteredDate !== "" && unregisteredDate ? ", " + (unregistrationAppliedSubject === "BANK" ? "금융기관" : "직접") + "해지 " + CM.cfnDateFormat(unregisteredDate) : "") +
                ")"
              : ""}
            <div className="btn-s" onClick={fnAccountRegister}>
              재등록
            </div>
          </div>
        );
      default:
        return <div>-</div>;
    }
  };

  /*
  @desc value State 제어 핸들러
  */
  const handleValuesChange = (depth4, depth3, depth2, depth1) => (event) => {
    if (depth1 !== undefined) {
      setValue({
        ...value,
        [depth1]: {
          ...value[depth1],
          [depth2]: {
            ...value[depth1][depth2],
            [depth3]: {
              ...value[depth1][depth2][depth3],
              [depth4]: event.target.value,
            },
          },
        },
      });
    } else if (depth2 !== undefined) {
      setValue({
        ...value,
        [depth2]: {
          ...value[depth2],
          [depth3]: {
            ...value[depth2][depth3],
            [depth4]: event.target.value,
          },
        },
      });
    } else if (depth3 !== undefined) {
      switch (depth3) {
        //수납시작년도
        case "payStartY4":
          const startMm = value.payStartY4mm.substring(4);
          setValue({
            ...value,
            payStartY4mm: `${event.target.value}${startMm}`,
          });

          break;
        //수납시작월
        case "payStartMm":
          const startY4 = value.payStartY4mm.substring(0, 4);
          setValue({
            ...value,
            payStartY4mm: `${startY4}${event.target.value}`,
          });
          break;
        //수납종료년도
        case "payEndY4":
          const endMm = value.payEndY4mm.substring(4);
          setValue({
            ...value,
            payEndY4mm: `${event.target.value}${endMm}`,
          });
          break;
        //수납종료월
        case "payEndMm":
          const endY4 = value.payEndY4mm.substring(0, 4);
          setValue({
            ...value,
            payEndY4mm: `${endY4}${event.target.value}`,
          });
          break;
        //반영희망년도
        case "expectedApplyY4":
          let expectedApplyMm = "";
          if (CM.cfnIsNotEmpty(value.expectedApplyY4mm)) {
            expectedApplyMm = value.expectedApplyY4mm.substring(4);
          }
          setValue({
            ...value,
            expectedApplyY4mm: `${event.target.value}${expectedApplyMm}`,
          });
          break;
        //반영희망월
        case "expectedApplyMm":
          let expectedApplyY4 = "";
          if (CM.cfnIsNotEmpty(value.expectedApplyY4mm)) {
            expectedApplyY4 = value.expectedApplyY4mm.substring(0, 4);
          }
          setValue({
            ...value,
            expectedApplyY4mm: `${expectedApplyY4}${event.target.value}`,
          });
          break;
        //자금종류 변경 시
        case "capital":
          const selectedCapital = fnMatchSelectedCapital(event.target.value);
          setValue({
            ...value,
            bankbookContents: selectedCapital.bankbookContents,
            bankbookPostfix: selectedCapital.bankbookPostfix,
            bankbookPostfixType: selectedCapital.bankbookPostfixType,
            [depth3]: {
              uniqueKey: selectedCapital.uniqueKey,
            },
          });
          break;
        default:
          setValue({
            ...value,
            [depth3]: {
              ...value[depth3],
              [depth4]: event.target.value,
            },
          });
      }
    } else {
      if (depth4 === "totalNumberOfPayment") {
        let totalNumberOfPayment = event.target.value.replace(/[^0-9]/g, "");
        if (totalNumberOfPayment === "0") {
          event.preventDefault();
          if (!flag) {
            setIsDirectlyUpdatingPayRange(true);
          }
        } else if (Number(totalNumberOfPayment) > 200) {
          event.preventDefault();
        } else {
          setValue({
            ...value,
            [depth4]: totalNumberOfPayment,
          });
          if (!flag) {
            setIsDirectlyUpdatingPayRange(true);
          }
        }
      } else if (depth4 === "payAmount") {
        setValue({
          ...value,
          [depth4]: CM.cfnAddCommaOnChange(event.target.value.replace(/[^0-9]/g, "")),
        });
      } else {
        setValue({
          ...value,
          [depth4]: event.target.value,
        });
      }
    }
  };

  /*
  @desc 계좌변경(account) State 제어 핸들러
  */
  const handleAccountChange = (name) => (event) => {
    setAccountValue({
      ...accountValue,
      [name]: event.target.value,
    });
  };

  /*
  @desc radio state 제어 핸들러 
  */
  const handleRadioChange = (name) => (event) => {
    setRadioState({ ...radioState, [name]: event.target.value });
    switch (name) {
      //수납주기
      case "payPeriod":
        if (event.target.value === "value1") {
          setValue({ ...value, payPeriod: "VARIABLE" });
        } else {
          setValue({ ...value, payPeriod: "MONTHLY" });
        }
        break;
      //SMS고지방법
      case "SMS":
        if (event.target.value === "value1") {
          setValue((val) => ({
            ...val,
            sendForAskingWithdraw: false,
            sendForFailureOnWithdraw: false,
            sendForSuccessOnWithdraw: false,
          }));
        } else {
          setValue((val) => ({
            ...val,
            sendForAskingWithdraw: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingWithdraw,
            sendForFailureOnWithdraw: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnWithdraw,
            sendForSuccessOnWithdraw: smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnWithdraw,
          }));
        }
        break;
      default:
        break;
    }
  };

  /*
   * @desc    yyyyMM 형태로 들어오는 데이터를 년도와 월로 분리하여 출력
   */
  const fnConvertY4mm = (y4mm, type) => {
    let val;
    if (CM.cfnIsNotEmpty(y4mm)) {
      if (type === "year") {
        val = y4mm.toString().substring(0, 4);
      } else if (type === "mm") {
        if (y4mm.toString().startsWith("9999")) {
          val = "";
        } else {
          val = y4mm.toString().substring(4);
        }
      }

      return val;
    }
  };

  /*
   * @desc    자금종류 관련 state 설정 함수
   */
  const fnSetCapitals = (data) => {
    setCapitalRows(data);
    fnSetCapitalOptions(data);
  };

  /*
   * @desc    자금종류 셀렉트박스 옵션 요소 생성 함수
   */
  const fnSetCapitalOptions = (data) => {
    let tempArray = [];

    if (CM.cfnIsNotEmpty(data)) {
      data.forEach((row) => {
        if (row.capitalType === "RECEIPT")
          tempArray.push({
            label: row.capitalName,
            value: row.uniqueKey,
            key: row.uniqueKey,
          });
      });
    }

    setCapitalOptions(tempArray);
  };

  /*
   * @desc    선택된 자금종류에 해당하는 정보 가져오는 함수
   */
  const fnMatchSelectedCapital = (target) => {
    let capital = {
      bankbookContents: "",
      bankbookPostfix: "",
      bankbookPostfixType: "",
      uniqueKey: "",
    };

    capitalRows.forEach((row) => {
      if (row.uniqueKey === target) capital = row;
    });

    return capital;
  };

  /*
   * @desc     수납예정일 옵션 생성하는 함수
   */
  const fnCreateDateOption = () => {
    const arrDate = [];
    const maxLoop = 32;

    for (let i = 0; i < maxLoop; i++) {
      let label = `${i}일`;
      let value = i < 10 ? `0${i}` : i;

      if (i === 0) {
        label = "지정일";
        value = "";
      }

      arrDate.push({
        label: label,
        value: value,
      });
    }

    return arrDate;
  };

  /*
   * @desc     수납시작월의 년도 옵션 생성하는 함수
   */
  const fnCreateStartYearOption = () => {
    const arrYear = [];
    const maxLoop = 10;
    const startYear = value && value.payStartY4mm ? Math.min(Number(value.payStartY4mm.substr(0, 4)), new Date().getFullYear()) : new Date().getFullYear();

    arrYear.push({
      label: "년도",
      value: "",
    });

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납종료월의 년도 옵션 생성하는 함수
   */
  const fnCreateEndYearOption = () => {
    const arrYear = [];
    const payEndYear = value.payEndY4mm && Number(value.payEndY4mm.substr(0, 4));
    const maxLoop = payEndYear && payEndYear !== 9999 ? Math.max(payEndYear - new Date().getFullYear() + 1, 50) : 50;
    const startYear = payEndYear && payEndYear !== 9999 ? Math.min(payEndYear, new Date().getFullYear()) : new Date().getFullYear();

    arrYear.push({
      label: "연도",
      value: "9999",
    });

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };
  /*
   * @desc     변경사항 적용 희망월의 년도 옵션 생성하는 함수
   */
  const fnCreateApplyYearOption = () => {
    const arrYear = [];
    const payEndYear = value.payEndY4mm && value.payEndY4mm.substr(0, 4);
    const maxLoop = payEndYear && payEndYear !== "9999" ? Number(payEndYear) - new Date().getFullYear() + 1 : 50;
    const startYear = new Date().getFullYear();

    arrYear.push({
      label: "연도",
      value: "9999",
    });

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납종료월의 년도 옵션 생성하는 함수
   */
  const fnCreateMonthOption = (type) => {
    const arrMonth = [];
    arrMonth.push({
      label: "월",
      value: "",
    });
    let maxLoop = 13;
    let year = `${new Date().getFullYear().toString()}`;
    let mm;
    let date = CM.cfnConvertDateToString(new Date()).substring(6, 8);

    //변경한 수납일자가 해당월에서 이미 지나갔을 경우 수정적용은 그 다음달부터 적용가능하도록 다음달부터 보여주도록 mm값 변경
    if (CM.cfnIsNotEmpty(value.expectedApplyY4mm) && value.expectedApplyY4mm.toString().substring(0, 4) === year && value.paySpecifiedDay !== "" && date > value.paySpecifiedDay) {
      mm = `${new Date().getMonth() + 2}`;
    } else {
      mm = `${new Date().getMonth() + 1}`;
    }
    if (CM.cfnIsNotEmpty(value.expectedApplyY4mm) && value.expectedApplyY4mm.toString().substring(0, 4) === year && type === "expectedApplyMm") {
      if (CM.cfnIsNotEmpty(value.payEndY4mm) && value.payEndY4mm.substring(0, 4) === value.expectedApplyY4mm.toString().substring(0, 4)) {
        maxLoop = parseInt(value.payEndY4mm.substring(4)) + 1;
      }
      //수납종료월은 지정되어있으나 수정적용 희망년도에 12달을 모두 보여주는 경우
      for (let i = mm; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else if (
      CM.cfnIsNotEmpty(value.expectedApplyY4mm) &&
      CM.cfnIsNotEmpty(value.payEndY4mm) &&
      value.payEndY4mm.substring(0, 4) === value.expectedApplyY4mm.toString().substring(0, 4) &&
      type === "expectedApplyMm"
    ) {
      //수납종료월을 지정해놓은 경우 수정적용 희망월이 종료년월을 넘지 않도록 반영
      maxLoop = parseInt(value.payEndY4mm.substring(4)) + 1;
      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else {
      //수납기한이 없을 경우 (수납종료월 지정X)
      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    }
    return arrMonth;
  };

  const dayOptions = fnCreateDateOption();
  const startYearOptions = fnCreateStartYearOption();
  const endYearOptions = fnCreateEndYearOption();
  const applyYearOptions = fnCreateApplyYearOption();

  /*
  @desc SMS고지방법 > radio 버튼 제어 함수
  */
  const fnRadioValue = (name, eventValue) => {
    let smsValue = "value1";
    let flag = false;
    switch (name) {
      case "sendForAskingWithdraw":
        flag =
          eventValue ||
          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnWithdraw && value.sendForFailureOnWithdraw) ||
          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnWithdraw && value.sendForSuccessOnWithdraw);
        break;
      case "sendFor FailureOnWithdraw":
        flag =
          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingWithdraw && value.sendForAskingWithdraw) ||
          eventValue ||
          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForSuccessOnWithdraw && value.sendForSuccessOnWithdraw);
        break;
      case "sendForSuccessOnWithdraw":
        flag =
          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForAskingWithdraw && value.sendForAskingWithdraw) ||
          (smsToCustomerSetting.serviceUseYn && smsToCustomerSetting.sendForFailureOnWithdraw && value.sendForFailureOnWithdraw) ||
          eventValue;
        break;
      default:
        break;
    }
    if (flag) smsValue = "value2";
    setRadioState({ ...radioState, SMS: smsValue });
  };

  /*
  @desc checkBox 제어 핸들러 (예금주> 납부자와 동일, SMS고지방법 > 출금의뢰시/정상출금시/미출금시)
  */
  const handleCheckChange = (name) => (event) => {
    if (name === "payer") {
      setValue({
        ...value,
        account: {
          ...value.account,
          depositorName: customer.customerName,
        },
      });
    } else if (name === "totalNumberOfPayment") {
      if (event.target.checked) {
        //기한없음
        setTotalNumberOfPaymentState(true);
        setValue((val) => ({
          ...val,
          totalNumberOfPayment: "",
          payEndY4mm: "999912",
        }));
        setFlag(true);
      } else {
        //기한없음 해제
        setTotalNumberOfPaymentState(false);
        setFlag(false);
      }
    } else {
      setValue({ ...value, [name]: event.target.checked });
      fnRadioValue(name, event.target.checked);
    }
  };

  /*
   * @desc   modal창 관련  state
   */
  const [fileOpen, setFileOpen] = useState(false); //출금동의
  const [receiptCustomerChangeHistoriesOpen, setReceiptCustomerChangeHistoriesOpen] = useState(false); //변경이력
  const [numberReceiptHistoriesOpen, setNumberReceiptHistoriesOpen] = useState(false); //회차별 수납내역

  const [receiptStopOpen, setReceiptStopOpen] = useState(false); //수납중지

  const [capitalOpen, setCapitalOpen] = useState(false); //자금종류

  const [capitalRows, setCapitalRows] = useState([]);
  const [capitalOptions, setCapitalOptions] = useState([]);
  const [accountUpdateOpen, setAccountUpdateOpen] = useState(false); //계좌변경
  const [arsInfoOpen, setARSInfoOpen] = useState(false);
  const [staffModalOpen, setStaffModalOpen] = useState(false);
  const [customerGroupModalOpen, setCustomerGroupModalOpen] = useState(false);
  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = async () => {
      await store.getCapital(fnSetCapitalList);
    };

    /*
     * @desc    자금종류 조회 Request Callback
     */
    const fnSetCapitalList = (objStatus, responseData) => {
      if (objStatus.status === 200) {
        setCapitalRows(responseData);
        fnSetCapitalOptions(responseData);
      }
    };

    if (CM.cfnIsEmpty(store.capital.$mobx.values)) {
      fnFetchCapitalList();
    } else {
      setCapitalRows(toJS(store.capital));
      fnSetCapitalOptions(toJS(store.capital));
    }

    const fetchInstituteService = async () => {
      const storeInstituteService = await store.axiosInstituteService();
      let setting = { serviceUseYn: false, sendForAskingWithdraw: false, sendForFailureOnWithdraw: false, sendForSuccessOnWithdraw: false };
      for (const service of storeInstituteService) {
        if (service.serviceType === "SMS_TO_CUSTOMER") {
          setting = service;
        }
      }
      setting.serviceUseYn = !!setting.serviceUseYn;
      setting.sendForAskingWithdraw = !!setting.sendForAskingWithdraw;
      setting.sendForFailureOnWithdraw = !!setting.sendForFailureOnWithdraw;
      setting.sendForSuccessOnWithdraw = !!setting.sendForSuccessOnWithdraw;
      setSmsToCustomerSetting(setting);
      setRadioState((s) => ({
        ...s,
        SMS:
          (setting.serviceUseYn && setting.sendForAskingWithdraw && value.sendForAskingWithdraw) ||
          (setting.serviceUseYn && setting.sendForFailureOnWithdraw && value.sendForFailureOnWithdraw) ||
          (setting.serviceUseYn && setting.sendForSuccessOnWithdraw && value.sendForSuccessOnWithdraw)
            ? "value2"
            : "value1",
      }));
    };
    fetchInstituteService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, setCapitalOptions, setCapitalRows]);

  /*
   * @desc   변경이력 조회 API
   */
  function fnReceiptCustomerChangeHistories(uniqueKey) {
    return new Promise((resolve) => {
      const url = `api/customer/receipt/receipts/history?contractUniqueKey=${uniqueKey}`;
      CM.cfnAxios(url, "get", "", (status, response) => {
        // 성공시
        setReceiptCustomerChangeHistories(response);
        resolve(true);
      });
    });
  }

  /*
   * @desc   자동납부 동의자료 다운로드 API
   */
  function fnFetchSavedEvidenceFileDownload() {
    return new Promise((resolve) => {
      const url = `api/customer/receipt/evidence?accountUniqueKey=${value.account.uniqueKey}`;
      CM.cfnAxiosGeneralFileDownload(url, "get", "", evidenceFile.fileName);
    });
  }

  /*
   * @desc   modal창 관련 핸들러
   */
  const handleClickModal = async (name) => {
    switch (name) {
      case "capitalName":
        setCapitalOpen(true);
        break;
      case "capitalOpen":
        setCapitalOpen(true);
        break;
      case "ReceiptCustomerChangeHistories":
        const result = await fnReceiptCustomerChangeHistories(value.uniqueKey);
        if (result) setReceiptCustomerChangeHistoriesOpen(true);
        break;
      case "NumberReceiptHistories":
        setNumberReceiptHistoriesOpen(true);
        break;
      case "ReceiptStop":
        setReceiptStopOpen(true);
        break;
      case "file":
        setFileOpen(true);
        break;
      case "AccountUpdate":
        setAccountUpdateOpen(true);
        break;
      case "ARS":
        setARSInfoOpen(true);
        break;
      case "fileDownload":
        fnFetchSavedEvidenceFileDownload();
        break;
      default:
        break;
    }
  };

  //파일삭제 버튼 클릭 이벤트 핸들러
  const handleDeleteEvidenceFile = () => {
    CM.cfnConfirm("정말로 삭제하시겠습니까?", () => {
      //본 컴포넌트에서 사용하는 state 세팅 후 프로세스 종료
      const url = `api/customer/receipt/evidence/${value.account.uniqueKey}`;

      CM.cfnAxios(url, "delete", "", (status, data) => {
        CM.cfnAlert(data, async () => {
          setValue((value) => ({
            ...value,
            account: {
              ...value.account,
              evidenceFileName: "",
              evidenceFileType: "",
            },
          }));
          setEvidenceFile({
            file: null,
            fileType: null,
            fileName: "",
            isSavedOnServer: false,
          });
          history.replace({ ...history.location, state: { ...location.state, receipt: { ...value, account: { ...value.account, evidenceFileName: "", evidenceFileType: "" } } } });
        });
      });
    });
  };

  /*
   * @desc    출금동의 파일 state
   */
  const [evidenceFile, setEvidenceFile] = useState({
    file: null,
    fileType: null,
    fileName: "",
    isSavedOnServer: false,
  });

  //화면 진입 시 자동납부 동의자료 존재 여부 확인 후 값 세팅
  useEffect(() => {
    const fnInitEvidenceFile = () => {
      setEvidenceFile((evidenceFile) => ({
        ...evidenceFile,
        file: null,
        fileType: null,
        fileName: value.account.evidenceFileName == null ? null : value.account.evidenceFileName.substring(value.account.evidenceFileName.lastIndexOf("/") + 1),
        isSavedOnServer: true,
      }));
    };

    //실행영역
    if (Object.isExtensible(value.account) && CM.cfnIsNotEmpty(value.account.evidenceFileName) && CM.cfnIsEmpty(evidenceFile.fileName)) {
      fnInitEvidenceFile();
    }
  }, [value.account, evidenceFile, setEvidenceFile]);

  //자동납부 동의자료 모달 내에서 form submit request 실행 하도록 함수 분리 및 수정
  const fnConvertForRequest = (tempObj) => {
    const copyCustomerDetailInfo = {}; // 고객추가정보

    //필수값 체크
    if (!checkRequired()) {
      return false;
    }
    if (tempObj.expectedApplyY4mm && (tempObj.expectedApplyY4mm.length !== 6 || tempObj.expectedApplyY4mm.substring(0, 2) !== "20")) {
      CM.cfnAlert("수정 적용 희망월을 올바르게 선택해주세요.");
      return false;
    }

    // 고객추가정보 값 확인
    for (const [key, value] of Object.entries(customerDetailInfo)) {
      if (CM.cfnIsNotEmpty(value)) {
        const applyValue = key === "결혼기념일" ? CM.cfnConvertDateToString(value) : value;
        copyCustomerDetailInfo[key] = applyValue;
      }
    }

    // 고객추가정보 값 설정
    tempObj.customer.customerGroup.uniqueKey = selectRequiredInputs.customerGroup;
    tempObj.customer.staff.uniqueKey = selectRequiredInputs.staff;
    tempObj.customer.customerDetailInfo = JSON.stringify(copyCustomerDetailInfo);
    tempObj.customer.customerMobile = CM.cfnReplaceSymbol(tempObj.customer.customerMobile);
    tempObj.customer.customerTelephone = CM.cfnReplaceSymbol(tempObj.customer.customerTelephone);
    tempObj.customer.customerFax = CM.cfnReplaceSymbol(tempObj.customer.customerFax);
    tempObj.customer.cashbillIdentificationNo = CM.cfnReplaceSymbol(tempObj.customer.cashbillIdentificationNo);
    tempObj.customer.identificationNo = CM.cfnReplaceSymbol(tempObj.customer.identificationNo);
    let email = null;

    // 이메일이 빈값이 아니라면
    if (CM.cfnIsNotEmpty(customerEmail.id) || CM.cfnIsNotEmpty(customerEmail.domain)) {
      email = customerEmail.id + "@" + customerEmail.domain;
      // 이메일 형식 체크
      if (!CM.cfnCheckEmail(email)) {
        CM.cfnAlert("올바른 이메일 형식이 아닙니다.");
        return false;
      }
    }

    tempObj.customer.customerEmail = email;

    //수납정보 > 수납종료월 (총수납횟수가 ""이거나 0일 때 null로 데이터 전환)
    if (tempObj.payEndY4mm === "" || (tempObj.payEndY4mm && tempObj.payEndY4mm.startsWith("9999"))) {
      tempObj.payEndY4mm = null;
      tempObj.totalNumberOfPayment = null;
    }

    // 수납금액 ,(콤마) 삭제
    if (CM.cfnIsNotEmpty(tempObj.payAmount)) {
      tempObj.payAmount = Number(CM.cfnReplaceSymbol(tempObj.payAmount.toString()));
    }

    if (tempObj.account) {
      tempObj.account = { ...tempObj.account, ...accountValue };
      if (tempObj.account.depositorIdentificationNo) {
        tempObj.account.depositorIdentificationNo = CM.cfnReplaceSymbol(tempObj.account.depositorIdentificationNo);
      }
      if (
        tempObj.account.depositorIdentificationNo &&
        tempObj.account.depositorIdentificationNo !== "" &&
        tempObj.account.depositorIdentificationNo.length !== 6 &&
        tempObj.account.depositorIdentificationNo.length !== 10
      ) {
        CM.cfnAlert("예금주 생년월일 혹은 사업자번호는 6자리 혹은 10자리로 입력해주세요.");
        return false;
      }
    } else if (accountValue.accountNo !== "" && accountValue.accountNo) {
      tempObj.account = { ...accountValue };
      if (tempObj.account.depositorIdentificationNo) {
        tempObj.account.depositorIdentificationNo = CM.cfnReplaceSymbol(tempObj.account.depositorIdentificationNo);
      }
      if (
        tempObj.account.depositorIdentificationNo &&
        tempObj.account.depositorIdentificationNo !== "" &&
        tempObj.account.depositorIdentificationNo.length !== 6 &&
        tempObj.account.depositorIdentificationNo.length !== 10
      ) {
        CM.cfnAlert("예금주 생년월일 혹은 사업자번호는 6자리 혹은 10자리로 입력해주세요.");
        return false;
      }
    }
    return tempObj;
  };

  //고객정보 수정 Request
  function fnUpdateCustomer(sendData) {
    return new Promise((resolve) => {
      const customerCopyObject = CM.cfnCopyObject(location.state.receipt.customer);
      if (sendData.customer !== customerCopyObject) {
        if (typeof sendData.customer.customerDetailInfo === "object") {
          sendData.customer.customerDetailInfo = "{}";
        }
        const url = "api/customer/basics";
        CM.cfnAxios(url, "put", sendData.customer, (objStatus, data) => {
          history.replace({
            ...history.location,
            state: {
              ...location.state,
              receipt: { ...value, customer: { ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo } },
            },
          });
          resolve(true);
        });
      }
    });
  }

  const resetContractInformation = () => {
    CM.cfnAxios("/api/customer/receipt/receipts/" + value.uniqueKey, "get", null, (status, data) => {
      setValue(data);
      history.replace({
        ...history.location,
        state: {
          ...location.state,
          receipt: data,
        },
      });
      setAccountValue({ ...accountValue, ...data.account });
    });
  };
  //고객정보 수정 Request
  // function fnUpdateAccount(sendData) {
  //   return new Promise(resolve => {
  //     const accountCopyObject = CM.cfnCopyObject(location.state.receipt.account);

  //     if (sendData.account !== accountCopyObject) {
  //       fnAccountUpdate();
  //     }
  //   });
  // }

  /*
   * @desc    고객정보 및 수납정보 수정 Request
   */
  const fnSubmit = async () => {
    //동의자료 구분 수정 시 validation check
    if (Object.isExtensible(value.account) && CM.cfnIsNotEmpty(value.account.evidenceFileName)) {
      if (!CM.cfnCheckEvidenceFileTypeValidation(value.account.evidenceFileName, value.account.evidenceFileType)) return;
    }

    let form = new FormData();
    let tempObj = { ...value, customer: { ...customer } };
    // 개인일 때
    if ("INDIVIDUAL" === customer.customerType) {
      tempObj.customer.identificationNo = firstIdentificationNo + lastIdentificationNo;
      setCustomer({ ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo });
    }
    setValue({ ...value, customer: { ...customer, identificationNo: customer.customerType === "INDIVIDUAL" ? firstIdentificationNo + lastIdentificationNo : customer.identificationNo } });
    const sendData = fnConvertForRequest(tempObj);
    if (!sendData) {
      // 검증 실패
      return;
    }

    let arsConfirm = false;
    // ARS요청중이고, 계좌정보가 바뀔 예정인 경우 확인문구 변경
    if (
      CM.cfnIsNotEmpty(arsRequestStatus) &&
      CM.cfnIsNotEmpty(arsRequestStatus.createdDatetime) &&
      CM.cfnIsEmpty(arsRequestStatus.callRequestResultCode) &&
      ((!location.state.receipt?.account && sendData.account && sendData.account.accountNo) ||
        (location.state.receipt &&
          location.state.receipt.account &&
          (sendData.account.accountNo !== location.state.receipt.account.accountNo ||
            (location.state.receipt.account.accountBankCode !== sendData.account.accountBankCode &&
              (!location.state.receipt.account.accountBankCode || !sendData.account.accountBankCode.startsWith(location.state.receipt.account.accountBankCode))) ||
            location.state.receipt.account.depositorIdentificationNo !== sendData.account.depositorIdentificationNo)))
    ) {
      arsConfirm = true;
    }
    CM.cfnConfirm(
      arsConfirm
        ? "계좌정보(금융기관, 계좌번호, 예금주 생년월일) 중 한 가지 이상이 변경되는 경우\n현재 진행중인 ARS 동의자료 요청은 자동으로 실패처리됩니다. \n계속하시겠습니까?"
        : "수정하시겠습니까?",
      async () => {
        const result = await fnUpdateCustomer(sendData);

        if (result) {
          if (evidenceFile.file && evidenceFile.fileName && evidenceFile.fileName !== "") {
            form.append("file", evidenceFile.file, evidenceFile.fileName);
          } else {
            form.append("file", evidenceFile.file);
          }
          form.append("contract", new Blob([JSON.stringify(sendData)], { type: "application/json" }));
          const url = `api/customer/receipt/receipts/update`;
          CM.cfnAxios(
            url,
            "post",
            form,
            async (objStatus, objData) => {
              CM.cfnAlert(objData);

              //파일첨부했을 경우 evidenceFile status 변경
              if (CM.cfnIsNotEmpty(evidenceFile.file)) {
                const fileNameUrl = `api/customer/receipt/evidence?accountUniqueKey=${value.account.uniqueKey}`;

                const getFileName = (name) => value.payerNo + name.substring(name.lastIndexOf("."));

                CM.cfnGetSavedFile(fileNameUrl, async (status, data) => {
                  var prntFileName = getFileName(data.fileName);
                  setEvidenceFile(
                    (evidenceFile) => ({
                      ...evidenceFile,
                      file: data.file,
                      fileName: prntFileName,
                      isSavedOnServer: true,
                    }),
                    () => {
                      //실패 시 알림창을 띄우지 않도록 아무것도 선언하지 않은 콜백함수 추가
                    }
                  );

                  // 새로운 계좌정보로 반영되었을 것을 고려하여 계좌등록 상태를 등록대기로 전환함
                  if (
                    sendData.account &&
                    (sendData.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                      sendData.account.accountRegistrationStatus === "UNREGISTERED" ||
                      sendData.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER") &&
                    location.state.receipt &&
                    location.state.receipt.account &&
                    (sendData.account.accountNo !== location.state.receipt.account.accountNo ||
                      (location.state.receipt.account.accountBankCode !== sendData.account.accountBankCode &&
                        (!location.state.receipt.account.accountBankCode || !sendData.account.accountBankCode.startsWith(location.state.receipt.account.accountBankCode))) ||
                      location.state.receipt.account.depositorIdentificationNo !== sendData.account.depositorIdentificationNo)
                  ) {
                    resetContractInformation();
                  }
                });
              } else if (sendData.account) {
                // 새로운 계좌정보로 반영되었을 것을 고려하여 계좌등록 상태를
                if (!location.state.receipt.account && sendData.account && sendData.account.accountNo) {
                  // 기존에 계좌가 없었는데 만들었다면 uniqueKey를 받아와야 하기때문에 정보를 다시 요청한다.
                  resetContractInformation();
                } else if (
                  (sendData.account.accountRegistrationStatus === "FAILED_TO_REGISTER" ||
                    sendData.account.accountRegistrationStatus === "UNREGISTERED" ||
                    sendData.account.accountRegistrationStatus === "FAILED_TO_UNREGISTER") &&
                  location.state.receipt &&
                  location.state.receipt.account &&
                  (sendData.account.accountNo !== location.state.receipt.account.accountNo ||
                    (location.state.receipt.account.accountBankCode !== sendData.account.accountBankCode &&
                      (!location.state.receipt.account.accountBankCode || !sendData.account.accountBankCode.startsWith(location.state.receipt.account.accountBankCode))) ||
                    location.state.receipt.account.depositorIdentificationNo !== sendData.account.depositorIdentificationNo)
                ) {
                  resetContractInformation();
                }
              }

              //수정완료 시, defaultCopyObject를 value로 변환
              defaultCopyObject = tempObj;
              setFileOpen(false);
            },
            "",
            true
          );
        }
      }
    );
  };

  /*
   * @desc    고객정보 및 수납정보 수정 Request
   */
  const fnSubmitEvidenceFile = async (file, fileName, evidenceFileType, setFileChange) => {
    //동의자료 구분 수정 시 validation check
    if (Object.isExtensible(value.account) && (CM.cfnIsNotEmpty(fileName) || CM.cfnIsNotEmpty(evidenceFileType))) {
      if (!CM.cfnCheckEvidenceFileTypeValidation(fileName, evidenceFileType)) return;
    }

    let form = new FormData();
    const tempObj = { ...value, customer: { ...customer } };
    const sendData = fnConvertForRequest(tempObj);
    sendData.account.evidenceFileType = evidenceFileType;
    sendData.account.evidenceFileName = fileName;

    if (file && fileName && fileName !== "") {
      form.append("file", file, fileName);
    } else {
      form.append("file", file);
    }
    form.append("contract", new Blob([JSON.stringify(sendData)], { type: "application/json" }));
    const url = `api/customer/receipt/receipts/update`;
    CM.cfnAxios(
      url,
      "post",
      form,
      async (objStatus, objData) => {
        const getFileName = (name) => value.payerNo + name.substring(name.lastIndexOf("."));
        CM.cfnAlert(objData);
        //파일첨부했을 경우 evidenceFile status 변경
        setEvidenceFile({ ...evidenceFile, file, evidenceFileType, isSavedOnServer: true });
        setValue({ ...value, account: { ...value.account, evidenceFileType, evidenceFileName: getFileName(fileName) } });
        setAccountValue({ ...accountValue, evidenceFileType, evidenceFileName: getFileName(fileName) });
        history.replace({ ...history.location, state: { ...location.state, receipt: { ...value, account: { ...value.account, evidenceFileType, evidenceFileName: getFileName(fileName) } } } });

        setFileOpen(false);
        setFileChange(false);
      },
      undefined,
      true
    );
  };

  /*
   * @desc    수정버튼 클릭 이벤트 핸들러
   */
  const handleSubmit = async () => {
    fnSubmit();
  };

  /*
   * @desc    삭제버튼  클릭 이벤트 핸들러
   */
  const handleDelete = () => {
    if (value.customer?.uniqueKey) {
      CM.cfnAxios(
        "api/customer/basics/" + value.customer.uniqueKey,
        "get",
        null,
        (objStatus, objData) => {
          if (objData.receiptContractCount === 1 && objData.receiptContractList[0].uniqueKey === value.uniqueKey && objData.paymentContractCount === 0) {
            CM.cfnConfirm("수납고객 정보를 삭제하시겠습니까? 삭제 후에도 같은 납부자번호는 재사용이 불가합니다.", () => {
              CM.cfnConfirm(
                "해당 고객의 수납고객 정보가 1건만 존재합니다. 고객정보도 함께 삭제하시겠습니까?\n(확인 : 동시 삭제, 취소 : 수납고객정보만 삭제)",
                () => {
                  // 확인 : 고객정보 전체 삭제
                  const url = `api/customer/basics/${value.customer.uniqueKey}`;
                  CM.cfnAxios(url, "delete", "", fnDeleteCallback);
                },
                () => {
                  // 취소 : 수납고객정보만 삭제
                  const url = `api/customer/receipt/receipts/${value.uniqueKey}`;
                  CM.cfnAxios(url, "delete", "", fnDeleteCallback);
                }
              );
            });
          } else {
            CM.cfnConfirm("수납고객 정보를 삭제하시겠습니까? 삭제 후에도 같은 납부자번호는 재사용이 불가합니다.", () => {
              const url = `api/customer/receipt/receipts/${value.uniqueKey}`;
              CM.cfnAxios(url, "delete", "", fnDeleteCallback);
            });
          }
        },
        () => {
          CM.cfnConfirm("수납고객 정보를 삭제하시겠습니까? 삭제 후에도 같은 납부자번호는 재사용이 불가합니다.", () => {
            const url = `api/customer/receipt/receipts/${value.uniqueKey}`;
            CM.cfnAxios(url, "delete", "", fnDeleteCallback);
          });
        },
        false,
        true
      );
    } else {
      CM.cfnConfirm("수납고객 정보를 삭제하시겠습니까? 삭제 후에도 같은 납부자번호는 재사용이 불가합니다.", () => {
        const url = `api/customer/receipt/receipts/${value.uniqueKey}`;
        CM.cfnAxios(url, "delete", "", fnDeleteCallback);
      });
    }
  };

  /*
   * @desc 삭제버튼 콜백함수
   */
  const fnDeleteCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
    }
    CM.cfnAlert(objData, () => {
      history.goBack();
    });
  };

  return (
    <div>
      <div>
        <div>
          <CreateCustomerDivision
            customer={customer}
            handleCustomerChange={handleCustomerChange}
            changeCustomer={changeCustomer}
            firstIdentificationNo={firstIdentificationNo}
            lastIdentificationNo={lastIdentificationNo}
            handleIdentificationNoChange={handleIdentificationNoChange}
            changeIdentificationNo={changeIdentificationNo}
            checkIdentificationNo={checkIdentificationNo}
            setCheckIdentificationNo={setCheckIdentificationNo}
          />
          <CustomerRequiredInputs
            storeCustomerGroup={toJS(store.customerGroup)}
            optionStaffsAndBranches={optionStaffsAndBranches}
            selectRequiredInputs={selectRequiredInputs}
            handleRequiredInputsHandler={handleRequiredInputsHandler}
            setCustomerGroupModalOpen={setCustomerGroupModalOpen}
            setStaffModalOpen={setStaffModalOpen}
          />
          <CustomerIndividualSelectInput
            customer={customer}
            handleCustomerChange={handleCustomerChange}
            changeCustomer={changeCustomer}
            customerEmail={customerEmail}
            handleCustomerEmailChange={handleCustomerEmailChange}
            selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
            handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
            handleRadioChange={handleCashbillIdentificationNoRadioChange}
            customerDetailInfo={customerDetailInfo}
            handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
            checkIdentificationNo={checkIdentificationNo}
          />
          <CustomerBusinessSelectInput
            customer={customer}
            handleCustomerChange={handleCustomerChange}
            changeCustomer={changeCustomer}
            customerDetailInfo={customerDetailInfo}
            handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
            customerEmail={customerEmail}
            handleCustomerEmailChange={handleCustomerEmailChange}
            selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
            handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
            handleRadioChange={handleCashbillIdentificationNoRadioChange}
          />
        </div>
        <div className="table-bottom-area" />
        <ReceiptInfo
          fnContractStatus={fnContractStatus}
          capitalOptions={capitalOptions}
          dayOptions={dayOptions}
          totalNumberOfPaymentState={totalNumberOfPaymentState}
          payPeriodOption={payPeriodOption}
          startYearOptions={startYearOptions}
          endYearOptions={endYearOptions}
          applyYearOptions={applyYearOptions}
          flag={flag}
          handleClickModal={handleClickModal}
          handleValuesChange={handleValuesChange}
          value={value}
          handleCheckChange={handleCheckChange}
          radioState={radioState}
          handleRadioChange={handleRadioChange}
          fnConvertY4mm={fnConvertY4mm}
          fnCreateMonthOption={fnCreateMonthOption}
          limitAmountForEachWithdrawal={limitAmountForEachWithdrawal}
        />
      </div>
      <AccountInfo
        withdrawCriteriaOptions={withdrawCriteriaOptions}
        handleRadioChange={handleRadioChange}
        financialInstitutes={financialInstitutes}
        financialRepresentativeInstitutes={financialRepresentativeInstitutes}
        transactionMethod={value.transactionMethod}
        value={value}
        handleValuesChange={handleValuesChange}
        radioState={radioState}
        handleCheckChange={handleCheckChange}
        handleClickModal={handleClickModal}
        evidenceFile={evidenceFile}
        setEvidenceFile={setEvidenceFile}
        fnAccountRegistrationStatus={fnAccountRegistrationStatus}
        evidence={loginStore.getEvidence()}
        instituteService={instituteService}
        arsRequestStatus={arsRequestStatus}
        fnProceedArsRequest={fnProceedArsRequest}
        handleDeleteEvidenceFile={handleDeleteEvidenceFile}
        accountValue={accountValue}
        setAccountValue={setAccountValue}
        handleAccountChange={handleAccountChange}
        smsToCustomerSetting={smsToCustomerSetting}
      />
      <div className="table-bottom-area" style={{ marginBottom: "150px" }}>
        <button type="submit" className="btn-l" onClick={handleSubmit}>
          수정
        </button>
        <button type="button" className="btn-m" onClick={handleDelete}>
          삭제
        </button>
        <button type="button" className="btn-m" onClick={() => history.goBack()}>
          목록
        </button>
      </div>

      <ExtraServiceInfo transactionMethod={value.transactionMethod} />
      <ReceiptStopModal open={receiptStopOpen} setOpen={setReceiptStopOpen} value={defaultCopyObject} fnReceiptStop={fnReceiptStop} />

      <ReceiptCustomerChangeHistoriesModal open={receiptCustomerChangeHistoriesOpen} setOpen={setReceiptCustomerChangeHistoriesOpen} object={receiptCustomerChangeHistories} />

      <NumberReceiptHistories open={numberReceiptHistoriesOpen} setOpen={setNumberReceiptHistoriesOpen} receiptInfo={value} />

      <AccountUpdateModal
        withdrawCriteriaOptions={withdrawCriteriaOptions}
        handleRadioChange={handleRadioChange}
        financialRepresentativeInstitutes={financialRepresentativeInstitutes}
        financialInstitutes={financialInstitutes}
        transactionMethod={value.transactionMethod}
        value={value}
        handleValuesChange={handleValuesChange}
        radioState={radioState}
        handleCheckChange={handleCheckChange}
        evidenceFile={evidenceFile}
        handleClickModal={handleClickModal}
        open={accountUpdateOpen}
        setOpen={setAccountUpdateOpen}
        accountValue={accountValue}
        setAccountValue={setAccountValue}
        handleAccountChange={handleAccountChange}
        fnAccountUpdate={fnAccountUpdate}
      />

      <Modal open={staffModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setStaffModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>담당자 정보</h3>
            <StaffInfo />
          </div>
        </div>
      </Modal>
      <Modal open={customerGroupModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setCustomerGroupModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>고객구분 관리</h3>
            <CustMnmtTable />
          </div>
        </div>
      </Modal>
      <CapitalsModal open={capitalOpen} setOpen={setCapitalOpen} capitalType={capitalType} store={store} setCapitals={fnSetCapitals} />
      {Object.isExtensible(value.account) ? (
        <EvidenceFileModal
          open={fileOpen}
          setOpen={setFileOpen}
          originalState={value}
          setOriginalState={setValue}
          evidenceFile={evidenceFile}
          setEvidenceFile={setEvidenceFile}
          fnSubmitEvidenceFile={fnSubmitEvidenceFile}
          type="update"
        />
      ) : (
        ""
      )}
      <ARSInfoModal open={arsInfoOpen} setOpen={setARSInfoOpen} />
    </div>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
}))(observer(ReceiptCustomerUpdate));
