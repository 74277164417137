import React from "react";

import { Modal, Button } from "@material-ui/core";
import { SealImageUploadForm } from "../../template/ComponentForm";

//import DonationData from "./DonationBillData"
//import * as CM from "../../common/Common";

// 부가서비스 > 기부금영수증 > 직인등록 모달
// 렌더를 처리하는 메인 컴포넌트
const SealImageModal = ({ open, setOpen, sealImage, setSealImage }) => {
  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={(e) => setOpen(false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>직인 등록</h3>
          <div className="modify-area">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <SealImageUploadForm imageFile={sealImage} setImageFile={setSealImage} />
              <button type="button" className="btn-l" onClick={() => setOpen(false)}>
                저장
              </button>
            </div>
          </div>

          <div className="preview-section">
            {sealImage.fileSrc === "#" ? (
              <p>미리보기를 할 수 없는 파일입니다.</p>
            ) : sealImage.fileSrc ? (
              <img src={sealImage.fileSrc} alt="" className="preview-image" data-testid="preview-image" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SealImageModal;
