import React from "react";

//메인화면 > 이용약관
const Contents = () => {
  return (
    <div className="ftm">
      <h3 className="title">이용약관</h3>
      <div className="content-box-scroll-back">
        <div className="content-box-scroll">
          <h5 className="title">제 1 장 총 칙</h5>
          <h6 className="title">제 1 조 (목 적)</h6>
          <p>본 약관은 빌링원플러스의 제공자인 사단법인 금융결제원(이하 “결제원”이라 함)과 빌링원플러스를 이용하는 기관(이하 “이용기관”이라 함)간의 권리, 의무 관계를 명확히 규정함을 목적으로 한다.</p>
          <h6 className="title">제 2 조 (계약의 성립 및 해지)</h6>
          <p>
            ① 이용기관이 본 약관에 동의하고, 결제원이 이를 승인함으로써 빌링원플러스 이용계약은 성립하며, 이용승인이 해지된 때 빌링원플러스 이용계약은 해지된다.
            <br />② 결제원은 필요할 경우, 본 약관과는 별도로 빌링원플러스 이용특약(이하 “특약” 이라 함)을 체결할 수 있다.
          </p>
          <h6 className="title">제 3 조 (약관의 효력 및 변경)</h6>
          <p>
            ① 본 약관은 이용기관이 약관에 동의한 날로부터 1년간 효력이 발생하며 어느 일방이 서면으로 해지의사를 표시하지 아니하는 한, 매년 1월 1일부터 효력이 갱신되는 것으로 한다.
            <br />② 결제원은 새로운 서비스의 적용, 보안체계의 향상 및 유지, 정부에 의한 시정 명령의 이행 등 업무상 약관을 변경하여야 할 중요한 사유가 있다고 판단될 경우 약관을 변경(이용요금 변경
            포함)할 수 있다.
            <br />③ 결제원이 본 약관을 변경할 경우 변경사유 및 적용일자를 명시한 변경약관을 결제원의 빌링원플러스 서비스 화면(이하 “서비스 화면”이라 함)에 그 적용일자 1개월 이전부터 게시하여야 한다.
            <br />④ 제3항의 변경내용이 이용기관에게 불리한 경우 결제원은 이를 전자우편으로 시행 전 최소 1개월 전까지 개별통지하여야 한다. 다만, 이용기관이 변경내용에 대한 통지를 받지 아니하겠다는
            의사를 명시적으로 표시한 경우에는 그러하지 아니한다.
            <br />⑤ 결제원은 제4항의 통지를 할 경우, “이용기관이 변경에 동의하지 아니한 경우 통지를 받은날로부터 1개월 이내에 서비스 이용을 해지할 수 있으며, 서비스 해지의 의사표시를 하지 아니한
            경우에는 변경에 동의한 것으로 본다.”라는 취지의 내용을 통지하여야 한다.
            <br />⑥ 이용기관이 제4항의 통지를 받은 날로부터 1개월 이내에 결제원에 이의를 제기하지 아니한 때에는 약관의 변경에 동의한 것으로 본다.
            <br />⑦ 이용기관이 제6항의 이의를 제기하는 경우 결제원은 이용기관과 성실히 협의하여야 하며 합의에 이르지 못하면 이용기관이 빌링원플러스 서비스 이용의사가 없는 것으로 간주하고 결제원은
            이용승인을 해지할 수 있다.
          </p>
          <h6 className="title">제 4 조 (용어의 정의)</h6>
          <p>
            ① “빌링원플러스”란 각종 자금을 수납 및 지급하려는 이용기관이 고객원장, 지급결제 관련 파일 또는 내역 등을 생성, 관리 할 수 있는 S/W를 말하며, WEB방식과 C/S방식으로 구분한다.
            <br />
            &emsp;1. “WEB 방식”이란 이용기관이 결제원에서 제공하는 WEB을 통하여 빌링원플러스를 이용하며 데이터를 결제원의 전산설비에 저장, 관리하는 서비스 형태를 말한다.
            <br />
            &emsp;2. “C/S 방식”이란 이용기관이 결제원에서 제공하는 별도 C/S S/W를 이용기관의 PC에 설치하여 빌링원플러스를 이용하며 데이터를 이용기관의 PC에 저장, 관리하는 서비스 형태를 말한다.
            <br />② “이용기관”이란 빌링원플러스 이용약관에 동의하고 이용신청을 한 기관 중 결제원으로부터 이용승인을 받은 기관을 말한다.
            <br />③ “고객”이란 대금의 수납 및 지급을 위하여 관리하고 있는 이용기관의 고객중 빌링원플러스 고객원장에 등록된 고객을 말한다.
            <br />④ “ID”란 빌링원플러스를 이용하기 위하여 이용기관이 결제원으로부터 승인 받은 문자와 숫자의 조합을 말한다.
            <br />⑤ “비밀번호”란 이용기관이 빌링원플러스에 접속하기 위하여 정당한 권리가 있는 자임을 확인받기 위해 제시해야 하는 일련의 숫자와 문자의 조합을 말하며, 비밀번호는 이용기관 스스로
            설정한다.
            <br />⑥ “이용요금”이란 이용기관이 빌링원플러스를 이용하는 대가로 결제원에 지급하는 요금을 말한다.
            <br />⑦ “영업일”이란 다음 각 호를 제외한 날을 말한다.
            <br />
            &emsp;1. 「관공서의 공휴일에 관한 규정」에 의한 공휴일
            <br />
            &emsp;2. 토요일
            <br /> &emsp;3. 근로자의 날
            <br />⑧ 본 약관에서 정하지 않은 용어는 결제원의 지급결제서비스에 관련되는 각 업무별 이용약관 및 『전자금융거래법령』등에서 정의하는 용어를 준용한다.
          </p>
          <h6 className="title">제 5 조 (빌링원플러스 이용대상)</h6>
          <p>
            ① 빌링원플러스는 결제원에서 제공하고 있는 각종 지급결제서비스를 이용하여 자금을 수납 및 지급하려는 이용기관을 그 대상으로 한다.
            <br />② 결제원은 관계법령 및 지급결제서비스별 제반 규정을 위반하지 않는 범위 내에서 빌링원플러스를 제공하며, 서비스의 종류는 다음 각 호와 같다.
            <br />
            &emsp;1. 고객 원장 관리
            <br />
            &emsp;2. 수납 및 지급내역 관리
            <br />
            &emsp;3. 지급결제서비스별 파일 생성, 송/수신 및 관리
            <br />
            &emsp;4. 그 밖의 부가서비스
            <br />③ 제2항 제4호의 그 밖의 부가서비스(이하 ‘부가서비스’라 함)의 범위 및 제공 서비스의 종류는 결제원이 따로 정하는 바에 따른다.
          </p>
          <h6 className="title">제 6 조 (결제원의 의무)</h6>
          <p>
            ① 결제원은 빌링원플러스를 지속적·안정적으로 제공하여야 한다.
            <br />② 결제원은 빌링원플러스 제공 설비를 항상 운용·가능한 상태로 유지보수하며, 설비에 장애가 발생하거나 멸실된 경우 지체없이 이를 수리 복구할 수 있도록 최선의 노력을 다하여야 한다.
            <br />③ 결제원은 빌링원플러스 이용신청, 신청 사항의 변경 및 해지 절차 등 관련 제반 절차에 대하여 이용기관에게 편의를 제공하도록 노력한다.
            <br />④ 결제원은 이용기관으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우 신속히 처리하여야 한다. 다만, 신속한 처리가 곤란한 경우 이용기관에게 그 사유와 처리일정을 E-mail이나 서면
            또는 전화로 통보하여야 한다.
          </p>
          <h6 className="title">제 7 조 (이용기관의 의무)</h6>
          <p>
            ① 이용기관은 빌링원플러스 이용의 대가로 이용요금을 지정된 기일까지 납부하여야 한다.
            <br />② 이용기관은 본 약관 및 관계법령을 준수하여야 하며, 기타 결제원의 업무수행에 지장을 초래하는 행위를 하여서는 아니 된다.
            <br />③ 이용기관은 이용기관의 ID, 비밀번호가 유출되지 않도록 관리하여야 한다.
            <br />④ 이용기관은 고객 정보가 유출되거나 타 용도로 사용되지 않도록 철저히 관리하여야 하며, 이용기관의 고객 정보 관리 해태에 의하여 사고가 발생하는 경우 전적으로 이용기관의 책임으로 한다.
            <br />⑤ 이용기관은 이용기관정보(주소, 전화번호, FAX번호, 담당자 정보, 이용요금 납부계좌 정보 등)의 변경이 필요한 때에는 결제원이 별도로 정한 방법을 이용하여 즉시 변경하여야 하며,
            변경누락으로 인한 불이익은 이용기관의 책임으로 한다.
          </p>
          <h6 className="title">제 7 조의2 (정보의 목적 및 이용금지)</h6>
          <p>
            이용기관과 결제원은 빌링원플러스 업무 수행과정에서 취득한 정보를 동 업무 이외의 목적으로 이용할 수 없다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는 예외로 한다.
            <br />
            &emsp;1. 정보주체로부터 목적 외 이용에 대한 별도의 동의를 얻은 경우
            <br />
            &emsp;2. 관련 법령에 따라 정보주체의 동의 없이 정보 이용이 가능한 경우
          </p>
          <h6 className="title">제 8 조 (ID /비밀번호 및 접근매체의 관리)</h6>
          <p>
            ① ID 및 비밀번호는 이용기관이 요청하는 경우 결제원이 정한 방법에 따라 이용기관 또는 결제원이 변경할 수 있다. 다만, 다음 각 호의 어느 하나에 해당하는 경우 결제원은 이용기관에게 ID변경을
            요청하거나, 사전통보 후 해당 ID를 변경할 수 있다.
            <br />
            &emsp;1. ID가 이용기관의 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해의 우려가 있는 경우
            <br />
            &emsp;2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
            <br />
            &emsp;3. 기타 합리적인 변경 사유가 있는 경우
            <br />② 이용기관은 빌링원플러스 서비스에 필요한 접근매체를 제3자에게 대여, 사용위임, 양도 또는 담보제공하거나 본인 이외의 제3자에게 누설해서는 아니되며, 접근매체의 도용이나 위조 또는
            변조를 방지할 수 있도록 관리에 충분한 주의를 기울여야 한다.
          </p>
          <h6 className="title">제 9 조 (저작권의 귀속)</h6>
          <p>
            ① 결제원이 제공하는 소프트웨어에 대한 저작권 및 기타 지적재산권은 결제원에 귀속된다.
            <br />② 이용기관은 빌링원플러스를 이용함으로써 얻은 정보 및 프로그램을 결제원의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법으로 상업적 또는 영리목적으로 이용하거나 제3자에게
            제공할 수 없다.
          </p>
          <h6 className="title">제 10 조 (게시물의 관리)</h6>
          <p>
            ① 이용기관은 다음 각 호에 해당하는 내용의 게시물을 서비스 화면에 게시하거나 등록할 수 없다.
            <br /> &emsp;1. 다른 이용기관, 제3자 또는 결제원을 비방하거나 명예를 손상시키는 내용인 경우
            <br /> &emsp;2. 빌링원플러스의 안정적인 운영에 지장을 주거나 지장을 초래할 우려가 있는 경우
            <br /> &emsp;3. 특정 제품의 선전, 사이버 시위 등 게시판의 목적에 맞지 않는 내용인 경우
            <br /> &emsp;4. 범죄행위와 결부된다고 판단되는 내용인 경우
            <br /> &emsp;5. 제3자의 저작권 등, 기타 권리를 침해하는 내용인 경우
            <br /> &emsp;6. 음란물 내지 음란사이트 링크 등을 할 경우
            <br /> &emsp;7. 기타 위 각 호에 준하는 경우로서, 관계법령을 위반하거나 위반할 우려가 있는 경우
            <br />② 이용기관이 제1항 각 호의 어느 하나에 해당하는 게시물을 게시하거나 이와 유사한 내용을 등록한 경우 결제원은 이용기관에 대한 통지나 동의 없이 이를 삭제할 수 있다.
            <br />③ 이용기관은 게시물을 이용하여 얻은 정보를 가공, 판매하는 행위 등 게시된 자료를 상업적 또는 영리 목적으로 이용하거나 제3자에게 제공할 수 없다.
          </p>

          <h5 className="title" style={{ marginTop: "60px" }}>
            제 2 장 서비스 이용
          </h5>
          <h6 className="title">제 11 조 (이용신청)</h6>
          <p>
            ① 빌링원플러스를 이용하고자 하는 자(이하 “이용신청자“라 함)는 결제원이 정한 방법에 따라 『빌링원플러스 이용신청서』(이하 “이용신청서“라 함)를 작성하여 결제원에 제출하여야 한다.
            <br />② 이용신청자 중에서 기존 지급결제서비스 이용기관은 이용신청서 외의 제반 서류 제출을 생략할 수 있다. 다만, 결제원에서 별도 요청한 경우는 예외로 한다.
            <br />③ 이용신청서에 기재한 제반 신청 정보는 모두 실명이어야 하며, 허위정보를 기재 혹은 입력하거나, 허위서류를 첨부하여서는 아니 된다.
            <br />④ 이용신청서에 기재한 이용신청자의 사업자등록번호(또는 대표자의 주민등록번호)는 이용요금 납부계좌 예금주의 사업자등록번호(또는 대표자의 주민등록번호)와 일치하여야 한다.
          </p>
          <h6 className="title">제 12 조 (이용승인)</h6>
          <p>
            ① 결제원은 이용신청자가 제11조에 따라 모든 사항을 성실히 기재하여 이용신청을 완료하면 필요사항을 확인한 후 서비스 이용에 대한 승인여부를 결정한다.
            <br />② 결제원은 결정된 승인결과를 결제원이 정한 방법으로 이용신청자에게 통지한다.
          </p>
          <h6 className="title">제 13 조 (빌링원플러스의 이용/이용시간)</h6>
          <p>
            ① 빌링원플러스는 ID/비밀번호, 결제원이 정한 인증서 등을 사용하여 결제원이 정한 보안절차에 따라 사용자 인증을 통과한 후에 서비스를 이용할 수 있다.
            <br />② 이용기관은 각 지급결제서비스별 약관을 준수하여 결제원이 정한 절차 및 방법에 따라 빌링원플러스 서비스를 이용해야 한다.
            <br />③ 빌링원플러스의 이용시간은 연중무휴 24시간을 원칙으로 한다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는 그러하지 아니한다.
            <br /> &emsp;1. 보안의 유지 및 향상, 주 전산기기의 유지 점검 등을 위하여 결제원이 정한 날이나 시간
            <br /> &emsp;2. 데이터의 백업 및 안전한 보관을 위하여 결제원이 정한 정기적이고 규칙적인 날이나 시간
            <br /> &emsp;3. 천재지변 등 불가항력적인 사유로 빌링원플러스를 제공하지 못하는 경우
            <br /> &emsp;4. 통신기기, 통신회선 등의 장애로 불가피하게 빌링원플러스를 제공하지 못하는 경우
            <br /> &emsp;5. 그 밖의 위 각 호에 준하는 경우
            <br />④ 결제원은 제3항의 사유로 인하여 빌링원플러스 이용을 제한하는 경우 그 내용을 서비스 화면에 게시한다.
            <br />⑤ 결제원은 제1항의 인증서 종류를 빌링원플러스 홈페이지에 게시한다.
          </p>
          <h6 className="title">제 14 조 (전산처리 절차)</h6>
          <p>빌링원플러스를 이용함에 있어 데이터 송·수신 절차, 방법, 시간, 이체일 등 전산처리와 관련된 사항은 해당 지급결제서비스에서 별도로 정한 바에 따른다.</p>
          <h6 className="title">제 15 조 (이용한도)</h6>
          <p>
            ① 빌링원플러스에서 제공하는 여러 지급결제서비스 중, 해당 지급결제서비스에서 별도로 정한 이용한도가 있을 경우 결제원의 판단에 따라 빌링원플러스에서도 이를 준용할 수 있다.
            <br />② 결제원은 빌링원플러스 전산시스템의 저장 용량을 감안하여 이용승인 시 이용기관별 이용한도를 제한할 수 있으며, 이용기관은 필요시 결제원이 정한 방법에 따라 결제원에 이용한도 변경을
            요청할 수 있다.
          </p>
          <h6 className="title">제 16 조 (이용제한)</h6>
          <p>
            ① 결제원은 이용기관이 다음 각 호의 어느 하나에 해당하는 경우에는 빌링원플러스 이용을 제한할 수 있다.
            <br /> &emsp;1. 제7조 위반의 경우, 다만 이용요금 미납의 경우에는 1개월 이상 납부하지 아니하거나 연 3회이상 제19조 제2항에 따라 재 출금한 경우
            <br /> &emsp;2. 제9조 제2항 위반의 경우
            <br /> &emsp;3. 제10조 제1항과 제3항 위반의 경우
            <br /> &emsp;4. 제11조 제3항과 제4항 위반의 경우
            <br /> &emsp;5. 그 밖의 결제원이 위 각 호에 준한다고 인정하는 경우
            <br />② 결제원은 미납요금을 납부하는 등 이용 제한 사유가 해소된 것이 확인된 경우에는 이용 제한을 해제할 수 있다.
            <br />③ 결제원은 제2항에 따라 이용제한을 해제할 때에는 그 사실을 이용기관에 통보하여야 한다.
          </p>
          <h6 className="title">제 17 조 (이용해지)</h6>
          <p>
            ① 빌링원플러스 이용을 해지하고자 하는 경우 이용기관은 결제원이 정한 방법에 따라 『빌링원플러스 해지신청서』를 작성하여 결제원에 제출하여야 한다.
            <br />② 결제원은 이용기관이 빌링원플러스 이용 해지를 신청하는 즉시 이용승인을 해지한다. 다만, 이용요금의 미납이 있는 경우에는 그러하지 아니한다.
            <br />③ 결제원은 이용기관이 다음 각 호의 어느 하나에 해당하는 경우 제4항 및 제5항의 절차에 따라 이용승인을 해지할 수 있다. 다만, 제4항의 절차에 의거 정당한 소명이 이루어지기 전까지
            결제원은 서비스 제공을 중지할 수 있다.
            <br /> &emsp;1. 제16조에 따라 연 2회이상 동일한 행위에 의해 이용제한을 받은 경우, 다만 이용요금 미납의 경우에는 6개월 이상 납부하지 아니한 경우
            <br /> &emsp;2. 최근 1년 간 이용실적이 없는 경우
            <br /> &emsp;3. 해산 등으로 인하여 법인격(또는 개인사업자격)을 상실하거나 기타 법인격(또는 개인사업자격)이 없다고 볼 만한 상당한 이유가 있을 경우
            <br /> &emsp;4. 타인의 ID 또는 비밀번호를 도용하여 부당하게 빌링원플러스를 이용하는 경우
            <br /> &emsp;5. 선량한 풍속, 기타 사회질서에 반하는 행위를 하는 경우
            <br /> &emsp;6. 정보통신윤리위원회의 정보내용 심의에 따라 일정한 제재조치를 받은 경우
            <br /> &emsp;7. 해킹, 컴퓨터 바이러스 유포 등의 방법에 의하여 빌링원플러스 운영을 고의 또는 중대한 과실로 방해하는 경우
            <br /> &emsp;8. 그 밖의 결제원이 본 약관이나 관계법령에서 정한 사항의 위반 등 이용기관 자격을 그대로 유지시키는 것이 부적합하다고 인정할 만한 상당한 사유가 있는 경우
            <br />④ 이용기관이 제3항에 해당할 경우, 결제원은 해당 이용기관에 대한 이용승인 해지의사를 서면으로 통지하거나 동 이용기관에 대하여 소명자료의 제출을 요구할 수 있으며, 이용기관은 통지를
            받은 날로부터 10일 이내에 서면으로 소명자료를 제출하여야 한다.
            <br />⑤ 결제원은 이용기관이 정당한 사유 없이 제4항의 소명에 응하지 아니하거나, 이용기관의 소재불명으로 의견진술의 기회를 줄 수 없는 등 그 기간 내에 소명 내지 시정이 이루어지지 아니한 경우,
            이용기관의 이용승인을 해지할 수 있다.
            <br />⑥ 결제원은 제3항에 의하여 해지된 이용기관에 대하여는 해지일로부터 1년 동안 재 신청을 제한할 수 있다.
          </p>

          <h5 className="title" style={{ marginTop: "60px" }}>
            제 3 장 이용요금
          </h5>
          <h6 className="title">제 18 조 (이용요금)</h6>
          <p>
            ① 이용기관은 빌링원플러스를 이용하는 대가로 결제원에 이용요금을 납부하여야 하며 동 요금은 빌링원플러스 홈페이지에 게시한다. 다만, 결제원과 “특약”을 체결한 이용기관은 “특약”에서 별도로
            정하는 바에 따른다.
            <br />② 이용요금 및 부과방법을 변경할 때에는 그 내용을 빌링원플러스 홈페이지에 게시한다.
            <br />③ 빌링원플러스 외에 결제원에서 제공하는 별도의 지급결제서비스를 이용할 때는 해당 지급결제서비스에서 정하는 이용요금을 부과할 수 있다.
            <br />④ 이용기관의 이용요금 납부의무는 이용승인일의 익월부터 발생하며, 이용승인 취소일 또는 해지일의 익월에 종료된다. 다만, 이용요금 미납분이 있는 경우에는 이용요금이 완납된 때에
            납부의무는 종료된다.
          </p>
          <h6 className="title">제 19 조 (이용요금의 청구 및 납부)</h6>
          <p>
            ① 이용요금 납기일은 매월 15일(영업일이 아닌 경우 익영업일, 이하 같음)로 한다.
            <br />② 결제원은 이용월의 익월 12일까지 전자세금계산서로 이용기관에게 이용요금을 청구하고, 납기일에 사전 신청된 이용요금 납부계좌에서 CMS로 자동 출금한다.
            <br />③ 이용기관이 납기일에 이용요금을 납입하지 아니할 경우 결제원은 동 월 20일과 25일에 각각 재 출금할 수 있다.
            <br />④ 결제원은 2차에 걸친 재 출금시에도 이용기관이 미납할 경우 제16조 제1항의 규정에 따라 빌링원플러스 이용을 제한할 수 있다. 다만, 연체료는 부과하지 아니한다.
            <br />⑤ 결제원은 제2항의 전자세금계산서를 사전 신고된 이용기관의 E-mail 주소로 송부한다. 다만, 이용기관의 E-mail 주소 불명, 전산장애 등으로 인해 청구일까지 이용기관에 도달하지 못할 경우
            이용기관은 빌링원플러스 서비스 화면에서 직접 조회/수령하여야 한다.
            <br />⑥ 제 1항부터 제3항까지의 규정에도 불구하고 부가서비스 이용요금에 대한 청구 및 납부와 관련된 사항은 결제원이 따로 정할 수 있다.
          </p>
          <h6 className="title">제 20 조 (이용요금의 면제)</h6>
          <p>결제원은 WEB 방식을 사용하는 이용기관이 실제 등록한 고객이 없는 경우 동 이용기관에 대하여는 제18조의 규정에도 불구하고 이용요금을 부과하지 아니한다.</p>
          <h6 className="title">제 21 조 (이용요금의 과오납 정정)</h6>
          <p>
            ① 이용기관은 결제원이 청구한 이용요금에 대하여 이의가 있을 경우 청구일로부터 1주일 이내에 결제원에 이를 통지하여야 한다. 다만, 이용기관은 이를 이유로 납부를 지체할 수 없다.
            <br />② 결제원은 제1항의 이의가 정당할 경우, 해당금액을 익월 청구요금에서 상계함으로써 이용요금 과오납의 정정에 갈음한다.
          </p>

          <h5 className="title" style={{ marginTop: "60px" }}>
            제 4 장 보 칙
          </h5>
          <h6 className="title">제 22 조 (자료의 보관)</h6>
          <p>
            ① 결제원은 이용기관의 빌링원플러스관련 정보를 처리일로부터 5년간 보관한다. 다만, 이용기관이 C/S 방식을 이용할 경우 이용기관에서 해당 자료를 5년간 보관하여야 한다.
            <br />② 빌링원플러스 이용승인이 해지된 이용기관의 각종 자료는 본 빌링원플러스를 이용하여 조회할 수 없으며, 이용기관은 이용승인 해지 신청 전 관련 자료를 이용기관의 PC에 다운로드받아
            보관하여야 한다.
          </p>
          <h6 className="title">제 23 조 (부정접속 방지)</h6>
          <p>
            ① 결제원은 이용기관의 비밀번호 입력오류가 연속 5회를 초과할 경우, 빌링원플러스 이용을 정지시킬 수 있다.
            <br />② 결제원은 이용기관이 제1항의 입력오류 사유를 소명하고 정지 해제를 신청하면 이용 정지를 해제할 수 있다.
          </p>
          <h6 className="title">제 24 조 (준용 규정)</h6>
          <p>본 약관에서 정하지 아니한 지급결제서비스별 수납 및 지급 자금이체업무와 관련한 세부 사항은 『전자금융거래법』을 준용하며, 각 지급결제서비스별 약관 등 결제원이 따로 정한 바에 따른다.</p>
          <h6 className="title">제 25 조 (손해 배상)</h6>
          <p>
            ① 이용기관은 빌링원플러스 이용과 관련하여 이용기관의 고의 또는 과실로 발생한 분쟁에 대한 모든 민·형사상의 책임을 부담한다.
            <br />② 제1항과 관련하여 결제원이 제3자에 대하여 배상 책임을 지는 등 손해가 발생한 경우, 이용기관은 결제원의 모든 손해를 배상하여야 한다. 다만 제3자의 손해가 결제원의 직접적인 귀책사유에
            기인한 경우에는 그러하지 아니하다.
            <br />③ 이용기관이 제9조 제2항의 의무를 위반하여, 결제원이 저작권 및 기타 지적재산권에 피해를 입은 경우, 이용기관은 결제원의 모든 손해를 배상하여야 한다.
            <br />④ 이용기관이 제10조 제1항 및 제3항의 의무를 위반하여 결제원 또는 제3자가 피해를 입은 경우, 이용기관은 결제원 또는 제3자의 모든 손해를 배상하여야 한다.
          </p>
          <h6 className="title">제 26 조 (면책 범위)</h6>
          <p>
            결제원은 고의 또는 중과실이 없는 한, 다음 각 호의 경우에 대하여는 책임을 지지 아니한다.
            <br /> &emsp;1. 제13조 제3항 각 호의 사유로 빌링원플러스를 제공하지 못하는 경우
            <br /> &emsp;2. 통신기기, 통신회선 또는 결제원과 이용기관의 전산시스템 장애에 의한 업무처리의 지연 및 불능
            <br /> &emsp;3. 이용기관이 제7조의 의무를 위반하여 발생하는 사고
            <br /> &emsp;4. 이용기관이 제22조 제2항을 위반하여 발생하는 사고
            <br /> &emsp;5. 기타 결제원의 고의 또는 중과실로 인하여 발생한 사고가 아닌 경우
          </p>
          <h6 className="title">제 27 조 (관할 법원)</h6>
          <p>
            ① 본 약관과 관련하여 분쟁이 발생한 경우 관련법규가 있으면 그 관련법규를 따르고, 관련법규가 없으면 관습 및 신의성실의 원칙에 입각, 상호 협의하여 해결하기로 한다.
            <br />② 본 약관과 관련하여 발생한 분쟁이 제1항의 원칙에 따라 원만하게 해결되지 않은 경우에 이와 관련된 소송의 관할법원은 민사소송법에서 정하는 바에 따른다.
          </p>

          <h5 className="title" style={{ marginTop: "60px" }}>
            부 칙(‘09. 12. 04. )
          </h5>
          <h6 className="title">제 1 조 (시행일)</h6>
          <p>본 약관은 ‘10. 07. 05.부터 시행합니다.</p>
          <h6 className="title">제 2 조 (약관 폐지)</h6>
          <p>『CMS ASP 이용약관』은 빌링원플러스를 본격실시할 때 폐지한다.</p>
          <h6 className="title">제 3 조 (경과조치)</h6>
          <p>본 약관 시행 전에 『CMS ASP 이용약관』에 따라 시행된 사항은 본 약관에 따라 시행된 것으로 본다.</p>

          <h5 className="title" style={{ marginTop: "60px" }}>
            부 칙(‘16. 4. 26. )
          </h5>
          <p>본 약관은 ‘16. 06. 01.부터 시행합니다.</p>
          <h5 className="title" style={{ marginTop: "60px" }}>
            부 칙(‘20. 5. 7. )
          </h5>
          <p>본 약관은 ‘20. 07. 01.부터 시행합니다.</p>
          <h5 className="title" style={{ marginTop: "60px" }}>
            부 칙(‘20. 12. 18. )
          </h5>
          <p>본 약관은 ‘21. 02. 01.부터 시행합니다.</p>
        </div>
      </div>
    </div>
  );
};

export default Contents;
