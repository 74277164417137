import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Pagination from "material-ui-flat-pagination";
// import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";

// 부가서비스 > 현금영수증 > 현금영수증 직접발행ㆍ취소 > 목록
const CashbillTabTargetsList = (props) => {
  // 발행대상 제외 / 제외취소
  const excludeTargets = (applyDate, cashbillUniqueKey, isExempted) => {
    CM.cfnConfirm("해당 내용을 제외처리하시겠습니까? 제외한 내용을 다시 발행하시려면 발행대상 삭제 후 재생성이 필요합니다.", () => {
      const url = `api/extraservice/cashbill/targets/${props.summary.uniqueKey}/${cashbillUniqueKey}`;
      CM.cfnAxios(url, "delete", "", (status, data) => {
        CM.cfnAlert("제외되었습니다.", () => {
          props.handleSearchStart(true);
        });
      });
    });
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    const sortProperty = sortObjArray.find((obj) => obj.name === "sortProperty").value;
    const sortDirection = sortObjArray.find((obj) => obj.name === "sortDirection").value;

    props.handleSearchCondition((data) => ({ ...data, sortProperty, sortDirection }));

    // 정렬조건 세팅 후 검색 trigger
    props.handleSearchStart(true, false);
  };

  return (
    <div>
      <Table>
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          value=""
          arrData={[
            { id: "transactionType", label: "요청구분", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "cashbillIdentificationNo", label: "신분확인번호", sortable: false },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "transactionMethod", label: "수납방법", sortable: true },
            { id: "receiptDate", label: "수납일", sortable: false },
            { id: "paidAmount", label: "수납금액", sortable: true },
            { id: "issuingAmount", label: "발행금액", sortable: true },
            { id: "issuePurposeType", label: "발행구분", sortable: true },
            { id: "", label: "발행대상제외", sortable: false },
          ]}
          searchRequest={props.searchCondition}
          handleSortProperty={handleSortProperty}
          tableSortLabelDataTestId={"cashbillTabTargetsList-list-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionType(target.transactionType)}</TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo)}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionMethod(target.transactionMethod)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.receiptDate, "yyyyMMdd")}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(target.paidAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(target.issuingAmount)}원</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.purposeType(target.purposeType)}</TableCell>
                  <TableCell align="center">
                    <button className="btn-s search-button" onClick={() => excludeTargets(target.applyDate, target.cashbillUniqueKey, target.isExempted)} data-testid={`isExempted-button-${index}`}>
                      제외
                    </button>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        className="centered"
        limit={props.pagination.rowsPerPage}
        offset={props.pagination.offset}
        total={props.pagination.total}
        onClick={(e, offset, page) => props.handlePage(page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
        data-testid="cashbill-targets-pagination"
      />
    </div>
  );
};

export default CashbillTabTargetsList;
