import { Input, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { Fragment } from "react";
import * as CM from "../../common/Common";
import { DatePickerForm, FileUploadForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

/*
 * @desc  사후점검 단계 안내 컴포넌트
 */
export const PostCheckStepInfoForm = ({ postCheckStep }) => {
  return (
    <div className="inforbox" align="center">
      <div className={`post-check-step ${postCheckStep >= 1 && "on-step"}`}>
        (1단계)
        <br /> 사후점검 요청 파일 등록
      </div>
      <div className="post-check-icon">
        <ArrowForwardIosIcon />
      </div>
      <div className={`post-check-step ${postCheckStep >= 2 && "on-step"}`}>
        (2단계)
        <br /> 동의자료 등록·매칭
      </div>
      <div className="post-check-icon">
        <ArrowForwardIosIcon />
      </div>
      <div className={`post-check-step ${postCheckStep >= 3 && "on-step"}`}>
        (3단계)
        <br /> 점검결과 파일 저장
      </div>
    </div>
  );
};

/*
 * @desc  사후점검 요청파일 등록 컴포넌트
 */
export const RequestFileForm = ({ postCheckStep, uploadFile, setUploadFile, handleRegisterFile, handleResetEvidenceFile }) => {
  return (
    <Fragment>
      <div className="search-area">
        <div className="block">
          <label className="label-l">사후점검 요청파일</label>
          {postCheckStep === 1 ? (
            <FileUploadForm needPreview={false} uploadFile={uploadFile} setUploadFile={setUploadFile} handleRegisterFile={handleRegisterFile} />
          ) : (
            <Input value={uploadFile.fileName} readOnly={true} />
          )}
          {postCheckStep === 3 ? (
            <button className="btn-m fr" onClick={handleResetEvidenceFile} data-testid="rest-selected-file">
              동의자료 초기화
            </button>
          ) : null}
        </div>
      </div>
      {postCheckStep === 1 && (
        <div className="inforbox">
          ※{" "}
          <a href="https://www.payinfo.or.kr/biz" target="_blank" rel="noopener noreferrer" className="link">
            페이인포 비즈
          </a>
          에 접속하여 회원가입 후 다운받은 동의자료 요청파일(AE4119로 시작)을 등록해 주세요.
        </div>
      )}
    </Fragment>
  );
};

/*
 * @desc  동의자료 목록(2단계) 컴포넌트
 */
export const Step2ListForm = ({ ldsReqList, evidenceType, handleResetEvidenceFile, financialInstitutes, setBulkModalOpen, fnConverFileStatus, handleProceedRegistration }) => {
  return (
    <Fragment>
      <div className="table-top-area">
        <TotalCountForm totalElements={ldsReqList.length || 0} />
        <button className="btn-m fr" onClick={handleResetEvidenceFile} data-testid="rest-selected-file">
          동의자료 초기화
        </button>
        <button className="btn-m fr" onClick={(e) => setBulkModalOpen(true)} data-testid="postCheck-bulk-open">
          동의자료 일괄등록
        </button>
      </div>
      <Table>
        <TableForm.compTableHead arrData={["일련번호", "요청유형", "납부자번호", "금융기관", "계좌번호", "신청일", "동의자료구분", "동의자료"]} />
        <TableBody>
          {ldsReqList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            ldsReqList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{row.SEQ_NO}</TableCell>
                  <TableCell align="center">{row.REQ_TYPE === "1" ? "사후점검" : "고객 열람"}</TableCell>
                  <TableCell align="center">{row.PAYER_NO}</TableCell>
                  <TableCell align="center">{CM.cfnMatchBankName(row.FNC_NO.substr(0, 3), financialInstitutes)}</TableCell>
                  <TableCell align="center">{row.ACCT_NO}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.REQ_DATE, "yyyyMMdd")}</TableCell>

                  <TableCell align="center">{evidenceType.hasOwnProperty(row.EVDC_TYPE) ? evidenceType[row.EVDC_TYPE] : "기타"}</TableCell>
                  <TableCell align="center">{fnConverFileStatus(row, index)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area">
        <button className="btn-l" onClick={handleProceedRegistration} data-testid="finish-register-file">
          등록완료
        </button>
      </div>
    </Fragment>
  );
};

/*
 * @desc  동의자료 다운로드(3단계) 컴포넌트
 */
export const Step3DownloadForm = ({ handleClickDownload, createDate, handleChangeDate, handleResetEvidenceFile }) => {
  return (
    <Fragment>
      <div className="modify-area">
        <div className="block">
          <label className="label-l">결과송신일</label>
          <DatePickerForm className="w140 tc" value={createDate} handleChange={handleChangeDate} format="yyyy.MM.dd" customProps={{ placeholder: "YYYY.MM.DD" }} />
          <button className="btn-l" onClick={handleClickDownload}>
            사후점검 결과 파일 다운로드
          </button>
        </div>
      </div>
      <div className="inforbox">
        ※ 점검결과 파일은{" "}
        <a href="https://www.payinfo.or.kr/biz" target="_blank" rel="noopener noreferrer" className="link">
          페이인포 비즈
        </a>
        에 5영업일 이내 제출해야 하며, 동의자료가 누락되거나 기한이 경과된 경우 미제출로 처리됩니다.
        <br />※ 점검결과 파일명은 임의로 변경 시 정상적으로 처리되지 않습니다.
      </div>
    </Fragment>
  );
};
