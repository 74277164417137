import { Button, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Pagination from "material-ui-flat-pagination";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { EditableCellInput, RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsPaymentData";

//모달창 크기 조정 위해 추가 선언
const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "800px",
  },
});

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const { searchRequest, handleSearchFormChange } = props;

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area centered" data-testid="modify-search-area">
      <div className="block">
        <label className="label-l">고객구분</label>
        <Select native name="groupUniqueKey" value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")}>
          <option value="">전체</option>
          {props.customerGroupOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select native name="capitalUniqueKey" value={searchRequest.capitalUniqueKey} onChange={handleSearchFormChange("capitalUniqueKey")}>
          <option value="">전체</option>
          {props.capitalOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">검색어입력</label>
        <SelectForm value={searchRequest.term} handleChange={handleSearchFormChange("term")} name="term" arrayOption={CmsData.targetSearchKeywordForUpdate} optionValue="value" optionLabel="label" />
        <TextField className="w150" placeholder={searchRequest.term === "TARGET_Y4MM" ? "YYYYMM" : ""} value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="modify-keyword-input" />
        <button className="search-button" onClick={() => fnSearch()} data-testid="modify-search">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  const [exemptAllRow, setExemptAllRow] = useState(false);
  useEffect(() => {
    if (!props.searchButton) {
      setExemptAllRow(false);
    }
  }, [props.searchButton]);
  /*
   * @desc    상태값에 따른 입금제외 버튼 렌더 함수
   */
  const fnRenderButton = (row) => {
    if (row.isExempted) {
      return (
        <button className={`btn-s btn-exempted`} onClick={(e) => handleClickExemptButton(row.uniqueKey)}>
          제외취소
        </button>
      );
    } else {
      return (
        <button className={`btn-s`} onClick={(e) => handleClickExemptButton(row.uniqueKey)}>
          제외하기
        </button>
      );
    }
  };
  /*
   * @desc    테이블 내 입력란 변경 이벤트 핸들러
   */
  const handleTargetRowsChange = (row, change) => {
    const tempArray = [...props.targetList];
    const uniqueKey = row.uniqueKey;
    let rowIndex = null;
    tempArray.forEach((row, index) => {
      if (row.uniqueKey === uniqueKey) rowIndex = index;
    });

    const currentValue = Number(change.fieldValue.replace(/[^0-9]/g, ""));

    tempArray[rowIndex]["totalAskingAmount"] = Number(tempArray[rowIndex]["previouslyUnpaidAmount"]) + Number(tempArray[rowIndex]["unpaidAmount"]) + currentValue;
    tempArray[rowIndex]["tempEditAmount"] = currentValue;

    props.handleTargetListChange(tempArray);
  };

  /*
   * @desc    테이블 내 입금제외 버튼 클릭 이벤트 함수
   */
  const handleClickExemptButton = (key) => {
    const tempArray = CM.cfnCopyObject(props.targetList);
    let rowIndex = null;
    tempArray.forEach((row, index) => {
      if (row.uniqueKey === key) rowIndex = index;
    });

    let originalValue = tempArray[rowIndex]["isExempted"];
    if (CM.cfnIsEmpty(originalValue)) originalValue = false;

    tempArray[rowIndex]["isExempted"] = !originalValue;

    props.handleTargetListChange(tempArray);
  };

  const handleClickAllRowExemptButton = () => {
    const tempArray = CM.cfnCopyObject(props.targetList);
    tempArray.forEach((row, index) => {
      row.isExempted = !exemptAllRow;
    });

    props.handleTargetListChange(tempArray);
    setExemptAllRow(!exemptAllRow);
  };

  const exemptEntireRowButton = () => {
    return (
      <>
        제외처리
        <br />
        <button className={`btn-s`} onClick={handleClickAllRowExemptButton}>
          {exemptAllRow ? "제외취소" : "목록제외"}
        </button>
      </>
    );
  };
  return (
    <div className="modal-overflow-table">
      <Table>
        {CM.cfnCompColGroup(["80px", "100px", "100px", "120px", "140px", "100px", "50px", "140px", "140px", "100px", "100px", "120px", "140px", "140px"])}
        <TableForm.compServerSortDoubleRowTableHead
          useCheckbox={false}
          rowOne={[
            { id: "EXCLUDE", label: exemptEntireRowButton(), sortable: false, rowSpan: 2 },
            { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
            { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true, rowSpan: 2 },
            { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true, rowSpan: 2 },
            { id: "CAPITAL", label: "자금종류", sortable: false, rowSpan: 2 },
            { id: "TARGET_Y4MM", label: "지급연월", sortable: false, rowSpan: 2 },
            { id: "PAY_SPECIFIED_DAY", label: "지급\n지정일", sortable: false, rowSpan: 2 },
            { id: "ACCOUNT_BANK", label: "금융기관", sortable: false, rowSpan: 2 },
            { id: "ACCOUNT_NO", label: "계좌번호", sortable: false, rowSpan: 2 },
            {
              id: "CMS_PAYMENT_TARGET",
              label: "입금청구금액",
              sortable: false,
              colSpan: 4,
            },
            { id: "bankbookContents", label: "통장기재내역", sortable: false, rowSpan: 2 },
          ]}
          rowTwo={[
            { id: "UNPAID_AMOUNT", label: "합계", sortable: true },
            { id: "THIS_TIME_AMOUNT", label: "청구일분", sortable: false },
            {
              id: "PREVIOUSLY_UNASKED_AMOUNT",
              label: "미청구분",
              sortable: false,
            },
            { id: "ASKING_DELAY_FEE", label: "미입금분", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.targetList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={14} />
          ) : (
            props.targetList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{fnRenderButton(row)}</TableCell>
                  <TableCell align="center">{row.customerName /*고객명*/}</TableCell>
                  <TableCell align="center">{row.customerGroupName /*고객구분명*/}</TableCell>
                  <TableCell align="center"> {CM.cfnIdentificationNoFormat(row.identificationNo) /*생년월일*/}</TableCell>
                  <TableCell align="center"> {row.capitalName /*자금종류*/}</TableCell>
                  <TableCell align="center"> {CM.cfnDateFormat(row.targetY4mm, "yyyyMM") /*지급연월*/}</TableCell>
                  <TableCell align="center"> {CM.cfnDayFormat(row.paySpecifiedDay) /*지급지정일*/}</TableCell>
                  <TableCell align="center">{CM.cfnMatchBankName(row.accountBankCode, props.financialInstitutes) /*금융기관*/}</TableCell>
                  <TableCell align="center"> {row.accountNo /*계좌번호*/}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount) /*입금청구금액 금액합계 */}원</TableCell>
                  <TableCell align="right">
                    {row.askResult === "UNREACHED" ? (
                      <EditableCellInput
                        row={row}
                        fieldName="tempEditAmount"
                        onCellValueChange={handleTargetRowsChange.bind(this, row)}
                        size="w100" /*당회청구액(가공)*/
                        needComma={true}
                        inputProps={{
                          style: { textAlign: "right" },
                        }}
                        endAdornment="원"
                      />
                    ) : row.askResult === "UNPAID" ? (
                      "0원"
                    ) : (
                      CM.cfnAddComma(row.unpaidAmount) + "원"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {
                      row.askResult === "UNASKED" ? (
                        <EditableCellInput
                          row={row}
                          fieldName="tempEditAmount"
                          onCellValueChange={handleTargetRowsChange.bind(this, row)}
                          size="w100" /*당회청구액(가공)*/
                          needComma={true}
                          inputProps={{
                            style: { textAlign: "right" },
                          }}
                          endAdornment="원"
                        />
                      ) : (
                        CM.cfnAddComma(row.previouslyUnpaidAmount) + "원"
                      ) /*전회미청구액(가공)*/
                    }
                  </TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.unpaidAmount) /*미납액*/}원</TableCell>
                  <TableCell align="center">
                    <EditableCellInput
                      row={row}
                      fieldName="bankbookContents"
                      onCellValueChange={handleTargetRowsChange.bind(this, row)}
                      inputProps={{
                        style: { textAlign: "left" },
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
const CmsPaymentRequestTargetModify = (props) => {
  const { open, setOpen, fileUniqueKey, targetAskUniqueKey } = props;

  const classes = useStyles();

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(CmsData.updateTargetSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(false); // 검색 실행 flag
  const [originalTargetList, setOriginalTargetList] = useState([]); // 서버로 부터 전달받은 original list
  const [targetList, setTargetList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(CmsData.paginationData());
  const excelInput = React.useRef("");

  /*
   * @desc 입금청구 추가대상 목록 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);

    if (tempObj.term === "CUSTOMER_NAME") {
      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.identificationNo = "";
      tempObj.askUniqueKey = "";
    } else if (tempObj.term === "IDENTIFICATION_NO") {
      tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
      tempObj.customerName = "";
      tempObj.askUniqueKey = "";
    } else if (tempObj.term === "ASK_UNIQUEKEY") {
      tempObj.askUniqueKey = CM.cfnNvl(tempObj.keyword, "");
      tempObj.customerName = "";
      tempObj.identificationNo = "";
    }

    tempObj.fileUniqueKey = fileUniqueKey;

    return tempObj;
  }, [searchRequest, fileUniqueKey]);
  // 테이블 데이터 검색
  useEffect(() => {
    // axios request
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/cms/contents/update?capitalUniqueKey=${search.capitalUniqueKey}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&searchProperty=${search.term}&searchContents=${search.keyword}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          () => {
            handleSearchButtonChange(false);
          }
        );
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {

      const resultData = await axiosList(search);
      const resultList = resultData.content;

      for (const row of resultList) {
        row.totalAskingAmount -= row.paidAmount;
        switch (row.askResult) {
          case "UNREACHED":
            row.tempEditAmount = row.unpaidAmount; //입금청구일분
            row.previouslyUnpaidAmount = 0; //미청구분
            row.unpaidAmount = 0; //미입금분
            break;
          case "UNASKED":
            row.tempEditAmount = row.unpaidAmount; //입금청구일분
            row.previouslyUnpaidAmount = 0; //미청구분
            row.unpaidAmount = 0; //미입금분
            break;
          case "UNPAID":
            row.tempEditAmount = 0; //입금청구일분
            row.previouslyUnpaidAmount = 0; //미청구분
            //row.unpaidAmount = row.unpaidAmount - row.unpaidDelayFee; //미입금분
            break;
          default:
            break;
        }
      }

      setOriginalTargetList(CM.cfnCopyObject(resultData));
      handleTargetListChange(resultList);
      setPagination(CmsData.paginationData(resultData));
      handleSearchButtonChange(false);
    };

    if (open && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest, searchButton, fileUniqueKey, fnMakeParameter]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if(name === "term"){
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
        keyword : "",
      });
    }else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 청구 대상 리스트 값을 변경하는 함수
  const handleTargetListChange = (value) => setTargetList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    창닫기 버튼 클릭 이벤트 핸들러
   */
  const handleTargetModifyModalClose = () => {
    handleSearchButtonChange(false);
    if (searchRequest.term === "askUniqueKey") {
      setSearchRequest({ ...searchRequest, term: "customerName", keyword: "" });
    }
    setOpen(false);
  };

  /*
   * @desc  엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleExcelDownload = () => {
    const search = fnMakeParameter("get");
    let url = `api/payment/cms/contents/update/excel?`;
    url += `capitalUniqueKey=${search.capitalUniqueKey}&fileUniqueKey=${search.fileUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&resultCode=${search.resultCode}`;
    url += `&searchProperty=${search.term}&searchContents=${search.keyword}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc  일괄수정(엑셀) 버튼 클릭 이벤트 핸들러
   */
  const handleModifyTargetBulk = () => {
    excelInput.current.click();
  };

  /*
   * @desc  입금청구대상 일괄수정 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc  입금청구대상 일괄수정 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/payment/cms/contents/update/excel";
    let form = new FormData();
    form.append("file", file);
    form.append(
      "fileUniqueKey ",
      new Blob([JSON.stringify(fileUniqueKey)], {
        type: "application/json",
      })
    );

    CM.cfnAxios(url, "post", form, fnUploadExcelCallback, (error) => {
      CM.cfnAlert(
        error && error.response && error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : error && error.response && error.response.data && typeof error.response.data.message === "string"
          ? error.response.data.message
          : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
        () => {}
      );
    });
  };

  /*
   * @desc  입금청구대상 일괄수정 Request Callback
   */
  const fnUploadExcelCallback = (objStatus, objData) => {
    props.setIsTargetChanged(true); //입금청구완료 목록 재조회
    handleTargetModifyModalClose();
    CM.cfnAlert(objData);
  };

  /*
   * @desc    수정내용 저장 버튼 클릭 이벤트 핸들러
   */
  const handleSaveModified = () => {
    const tempArray = fnMakeParameterArray();

    if (tempArray.length < 1) {
      CM.cfnAlert("수정된 내용이 없습니다.");
    } else {
      CM.cfnConfirm("수정하시겠습니까?", () => {
        fnSaveModifiedTarget(tempArray);
      });
    }
  };

  /*
   * @desc    수정내용 저장 대상 파라미터 생성 함수
   */
  const fnMakeParameterArray = () => {
    let tempArray = [];

    for (const element of targetList) {
      const originalValue = originalTargetList.content.filter(function (row) {
        return row.uniqueKey === element.uniqueKey;
      });

      //기존 값과 비교하여 수정된 데이터만 추출하여 Request parameter 로 가공
      if (originalValue[0].bankbookPostfix !== element.bankbookPostfix || originalValue[0].totalAskingAmount !== element.totalAskingAmount || originalValue[0].isExempted !== element.isExempted) {
        //당회청구액 변경 시 입력된 당회청구액 가공
        element.regularAskingAmount = Number(CM.cfnReplaceSymbol(element.tempEditAmount));

        tempArray.push(element);
      }
    }

    return tempArray;
  };

  /*
   * @desc    수정내용 저장 Request
   */
  const fnSaveModifiedTarget = (paramArray) => {
    const url = `api/payment/cms/contents/update/${fileUniqueKey}`;
    CM.cfnAxios(url, "post", paramArray, fnSaveModifiedTargetCallback);
  };

  /*
   * @desc    수정내용 저장 Request Callback
   */
  const fnSaveModifiedTargetCallback = async (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    CM.cfnAlert("정상적으로 처리되었습니다.");
    await props.setIsTargetChanged(true); //입금청구완료 목록 재조회
    // handleTargetModifyModalClose();
  };

  useEffect(() => {
    if (targetAskUniqueKey) {
      setSearchRequest((s) => ({ ...s, term: "askUniqueKey", keyword: targetAskUniqueKey }));
    }
    if (open) {
      setSearchButton(true);
    }
  }, [open, targetAskUniqueKey]);

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleTargetModifyModalClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>입금금액 수정</h3>
          <div>
            <SearchForm
              searchRequest={searchRequest}
              capitalOptions={props.capitalOptions}
              customerGroupOptions={props.customerGroupOptions}
              handleSearchFormChange={handleSearchFormChange}
              handleSearchButtonChange={handleSearchButtonChange}
            />
            <div className="table-top-area">
              <TotalCountForm totalElements={originalTargetList.totalElements || 0} />
              <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleSelectExcelFile(e)}
                ref={excelInput}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
                value=""
                data-testid="select-excel-input"
              />
              <button className="btn-m fr" onClick={handleModifyTargetBulk} data-testid="modify-excel">
                [일괄수정]엑셀파일 등록
              </button>
              <button className="btn-m fr" onClick={handleExcelDownload} data-testid="save-excel">
                [일괄수정양식]엑셀저장
              </button>
              <div className="btn-l fr" onClick={handleSaveModified}>
                수정내용 저장
              </div>
            </div>
            <ListForm
              targetList={targetList}
              handleTargetListChange={handleTargetListChange}
              searchRequest={searchRequest}
              handleSortProperty={handleSortProperty}
              pagination={pagination}
              financialInstitutes={props.financialInstitutes}
              searchButton={searchButton}
            />
            <Pagination
              className="centered"
              limit={pagination.rowsPerPage}
              offset={pagination.offset}
              total={pagination.total}
              onClick={(e, offset, page) => handleOffsetChange(offset, page)}
              reduced={true}
              centerRipple={false}
              disableFocusRipple={true}
              disableRipple={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CmsPaymentRequestTargetModify;
