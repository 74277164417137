
import React, {useEffect, useState} from "react";
import {Select, Table, TableRow, TableBody, TableCell, TextField, Checkbox, FormControlLabel} from "@material-ui/core";
import {RowPerPageForm, SelectForm, TooltipForm, TotalCountForm} from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import Pagination from "material-ui-flat-pagination/lib/Pagination";
import TableForm from "../../template/TableForm";
import TabOpenGiroAndPgSendmessageModal from "../../institute/extraServiceInfo/TabOpenGiroAndPgSendMessageModal";
import UnpaidReceiptRequestFailContentsModal from "../../receipt/unpaidReceipt/UnpaidReceiptRequestFailContentsModal";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

function defaultSearchParam() {
    const endY4Mm = new Date();
    const startY4Mm = new Date(endY4Mm.getFullYear(), endY4Mm.getMonth() - 1);


    return {
        searchStartY4:  `${startY4Mm.getFullYear().toString()}`,//검색 시작 년월
        searchStartMm : startY4Mm.getMonth() < 9 ? `${0}${startY4Mm.getMonth() + 1}` : startY4Mm.getMonth() + 1,
        searchEndY4: `${endY4Mm.getFullYear().toString()}`,
        searchEndMm: endY4Mm.getMonth() < 9 ? `${0}${endY4Mm.getMonth() + 1}` : endY4Mm.getMonth() + 1,
        searchContents: "", //검색내용
        searchProperty: "CUSTOMER_NAME", //검색대상 - CUSTOMER_NAME(고객이름), PAYER_NO(납부자번호)
        sortProperty: "CUSTOMER_NAME", //정렬기준 -  CUSTOMER_NAME(고객이름), PAYER_NO(납부자번호)
        sortDirection: "ASC", //정렬방향 - ASC(오름차순), DESC(내림차순)
        pageNumber: "0", //페이지 번호
        pageSize: "5", //페이지 크기
    };
}

/*
 * 페이징 데이터
 */
function paginationData(data) {
    if (CM.cfnIsEmpty(data)) {
        return {
            rowsPerPage: 5,
            offset: 0,
            total: 0,
        };
    }

    return {
        rowsPerPage: data.pageable.pageSize,
        offset: data.pageable.offset,
        total: data.totalElements,
    };
}

/*
 * @desc  검색 부분 생성
 */
function CreateSearchForm(props) {
    const {searchOption, changeSearchFormHandler, changeSearchRequest} = props;
    const searchOptionObject = [
        { label: "고객이름", value: "CUSTOMER_NAME" },
        { label: "납부자번호", value: "PAYER_NO" }
    ];

    const fnClickSearch = () => {
        changeSearchRequest();
    };

    return(
        <div className="search-area tl">
            <div className="block">
                <label className="label-l">조회대상 월</label>
                <SelectForm
                    value={searchOption.searchStartY4}
                    handleChange={changeSearchFormHandler("searchStartY4")}
                    name="searchStartY4"
                    arrayOption={props.fnCreateYearOption()}
                    optionValue="value"
                    optionLabel="label"
                />
                <SelectForm
                    value={searchOption.searchStartMm}
                    handleChange={changeSearchFormHandler("searchStartMm")}
                    name="searchStartMm"
                    arrayOption={props.fnCreateEndMonthOption(searchOption.searchStartMm)}
                    optionValue="value"
                    optionLabel="label"
                />
                <span className="between">~</span>
                <SelectForm
                    value={searchOption.searchEndY4}
                    handleChange={changeSearchFormHandler("searchEndY4")}
                    name="searchEndY4"
                    arrayOption={props.fnCreateYearOption()}
                    optionValue="value"
                    optionLabel="label"
                />
                <SelectForm
                    value={searchOption.searchEndMm}
                    handleChange={changeSearchFormHandler("searchEndMm")}
                    name="searchEndMm"
                    arrayOption={props.fnCreateEndMonthOption(searchOption.searchEndMm)}
                    optionValue="value"
                    optionLabel="label"
                />
                <div className="margin-right-20 display-block" />
                <label className="label-l">검색어입력</label>
                <Select native data-testid="searchProperty_selectBox" className="w110" name="searchProperty" value={searchOption.searchProperty} onChange={changeSearchFormHandler("searchProperty")}>
                    {searchOptionObject.map((option, index) => {
                        return (
                            <option value={option.value} key={index}>
                                {option.label}
                            </option>
                        );
                    })}
                </Select>
                <TextField
                    data-testid="searchContents_inputBox"
                    className="w130"
                    value={searchOption.searchContents}
                    onChange={changeSearchFormHandler("searchContents")}
                    onKeyUp={(e) => {
                        if (e.keyCode === 13) fnClickSearch();
                    }}
                    name="searchContents"
                />
                <button className="search-button" onClick={() => fnClickSearch()}>
                    검색
                </button>
            </div>
        </div>
    );

}


/*
 * @desc  출금불능분 청구 컴포넌트
 */
function UnpaidReceiptGeneration(props) {
    const {openGiroUseYn, pgServiceUseYn, selectedTransactionMethod, handleRadioChange , handleSendLmsMessageInfoModal, handleClickRequest} = props;

    return (
        <div>
            <Table>
                {CM.cfnCompColGroup(["120px", "auto", "120px", "auto"])}
                <TableBody>
            <TableRow>
                <TableCell className="th">
                    수납방법
                </TableCell>
                <TableCell className="td">
                    <RadioGroup
                        name="selectedTransactionMethod"
                        value={selectedTransactionMethod}
                        onChange={handleRadioChange}
                        row>
                        <FormControlLabel
                            disabled={openGiroUseYn ? false : true}
                            control={<Radio color="primary" />}
                            value="OPENGIRO_ONLY"
                            label="계좌이체(지로)"
                         />
                        <FormControlLabel
                            disabled={pgServiceUseYn ? false : true}
                            control={<Radio color="primary" />}
                            value="PGSERVICE_ONLY"
                            label="신용카드"
                        />
                        <FormControlLabel
                            disabled={(openGiroUseYn && pgServiceUseYn) ? false : true}
                            control={<Radio color="primary" />}
                            value="OPENGIRO_AND_PG"
                            label="계좌이체(지로)+신용카드"
                        />
                    </RadioGroup>
                </TableCell>
                <TableCell colSpan={2} className="td">
                    <div className="fr">
                        <button className="btn-l" onClick={() => handleSendLmsMessageInfoModal()}>
                            발송내용<br/>미리보기
                        </button>
                        <button className="btn-l" onClick={handleClickRequest} data-testid="post-opengiro-and-pg-request">
                            청구하기
                        </button>
                    </div>
                </TableCell>
            </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {

    const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

    // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
    useEffect(() => {
        let checkAllFlag = 0;
        for (const element of props.targetList) {
            if (element._checked) checkAllFlag++;
        }

        // 전체 row 수
        const targetLength = props.targetList.length;

        if (checkAllFlag === targetLength && targetLength > 0) {
            setCheckAllRow(true);
        } else {
            setCheckAllRow(false);
        }
    }, [props.list, props.pagination, props.targetList]);

    /*
    * 전체 체크박스 클릭 시 모든 체크박스 버튼 활성화 또는 비활성화를 처리하는 함수
    */
    const handleCheckAllRowChange = (event) => {
        const checkAllValue = event.target.checked;

        setCheckAllRow(checkAllValue);
        props.handleListChange((target) => target.map((element) => ({...element, _checked: checkAllValue })));
    };

    // 체크박스 활성화 또는 비활성화를 처리하는 함수
    const handleUpdateChange = (event) => {
        const key = event.target.value;
        const value = event.target.checked;
        const index = event.target.getAttribute("index");

        const cloneData = [...props.targetList];
        cloneData[index][key] = value;
        props.handleListChange(cloneData);
    };

    // 자금 유형 전환 처리 함수
    const fnPayAmountType = (payAmountType) => {
        switch (payAmountType) {
            case "FIXED":
                return "정액";
            case "VARIABLE":
                return "비정액";
            case "TEMPORARY_ADDED":
                return "추가분";
            default:
                return payAmountType;
        }
    };


    return (
        <div>
            <Table>
                {CM.cfnCompColGroup(["50px","10%", "10%", "5%", "9%", "10%", "6%", "8%", "10%", "10%", "8%", "auto"])}
                <TableForm.compServerSortTableHead
                    checked={checkAllRow}
                    useCheckbox={true}
                    onChange={handleCheckAllRowChange}
                    arrData={[
                        { id: "TRANSACTION_DATE", label: "CMS 청구일", sortable: false },
                        { id: "TARGET_Y4MM", label: "대상 월", sortable: true },
                        { id: "PAY_SPECIFIED_DAY", label: "수납일", sortable: false },
                        { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
                        { id: "PAYER_NO", label: "납부자번호", sortable: true },
                        { id: "PAY_AMOUNT_TYPE", label: "자금유형", sortable: false },
                        { id: "CAPITAL", label: "자금종류", sortable: false },
                        { id: "PAY_AMOUNT", label: "수납대상 금액", sortable: false },
                        { id: "UNPAID_AMOUNT", label: "미납 금액", sortable: false },
                        { id: "DELAY_FEE", label: "연체료", sortable: false },
                        { id: "TOTAL_ASKING_AMOUNT", label: "청구 총액", sortable: false },
                    ]}
                    searchRequest={props.searchRequest}
                    handleSortProperty={props.handleSortProperty}
                />
                <TableBody>
                    {props.targetList.length === 0 ? (
                        <TableForm.compEmptyTableRow colSpan={12} />
                    ) : (
                        props.targetList.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align="center">
                                    <Checkbox
                                        checked={row._checked}
                                        value="_checked"
                                        id={`checkbox-${index}`}
                                        onChange={handleUpdateChange}
                                        inputProps={{
                                            index: index,
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">{CM.cfnDateFormat(row.lastAskedDate)}</TableCell>
                                <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                                <TableCell align="center">{row.paySpecifiedDay === "99" ? "말일" : row.paySpecifiedDay} </TableCell>
                                <TableCell align="center">{row.customerName}</TableCell>
                                <TableCell align="center">{row.payerNo}</TableCell>
                                <TableCell align="center">{fnPayAmountType(row.payAmountType)}</TableCell>
                                <TableCell align="center">{row.capitalName}</TableCell>
                                <TableCell align="center">{row.payAmountType === "FIXED" ? CM.cfnAddComma(row.regularAskingAmount) : CM.cfnAddComma(row.totalAskingAmount) /*수납대상금액*/}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.unpaidAmount - row.unpaidDelayFee) /*미납금액*/}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.unpaidDelayFee) /*연체료*/}원</TableCell>
                                <TableCell align="center">{CM.cfnAddComma(row.unpaidAmount) /*청구총액*/}원</TableCell>
                            </TableRow>);
                    })
                 )}
                </TableBody>
        </Table>
        </div>
    );
}

/*
 * @desc  메인 컴포넌트 생성
 */
const UnpaidReceiptRequest = (props) => {
    const {tabIndex, openGiroUseYn, pgServiceUseYn} = props;

    const [searchOption, setSearchOption] = useState(defaultSearchParam());
    const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
    const [excelButton, setExcelButton] = useState(false); // 엑셀버튼 실행 flag

    const [originalList, setOriginalList] = useState([]);
    const [targetList, setTargetList] = useState([]); // table 데이터
    const [pagination, setPagination] = useState(paginationData());

    //고객 앞 LMS 발송 내용 모달 화면
    const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
    //출금 실패 내역 조회 모달 화면
    const [failContentsModalOpen, setFailContentsModalOpen] = useState(false);

    //출금불능분 수납 방법 체크박스(지로 / PG / 지로+PG)
    const [selectedTransactionMethod, setSelectedTransactionMethod] = useState((openGiroUseYn && pgServiceUseYn) ? "OPENGIRO_AND_PG" : (openGiroUseYn ? "OPENGIRO_ONLY" : "PGSERVICE_ONLY"));

    const changeSearchRequest = () => {
        setSearchOption((data) => ({ ...data, pageNumber: 0 }));
        setSearchButton(true);
    };

    //조회 대상 셀렉박스 변경 시
    const changeSearchFormHandler = (name) => (e) => {
        const originEndY4Mm = new Date();
        const originStartY4Mm = new Date(originEndY4Mm.getFullYear(), originEndY4Mm.getMonth() - 1);

        //조회대상의 년도가 걸쳐있을 경우(2022.12 ~ 2023.01) 년도 월 선택에 따라 년월 맞춰주는 부분(2023년01일 경우 2022년 선택했을 때 12월로 자동 맞춰주기)
        if(name === "searchStartY4" || name === "searchEndY4" || name === "searchStartMm" || name === "searchEndMm"){
            if(originEndY4Mm.getFullYear() !== originStartY4Mm.getFullYear()){
                if(name === "searchStartY4"){
                        setSearchOption({
                            ...searchOption,
                            searchStartMm : e.target.value === originEndY4Mm.getFullYear().toString() ? `${0}${originEndY4Mm.getMonth() + 1}` : originStartY4Mm.getMonth() + 1,
                            [name]: e.target.value,
                        });
                }else if(name === "searchEndY4"){
                    setSearchOption({
                        ...searchOption,
                        searchEndMm : e.target.value === originEndY4Mm.getFullYear().toString() ? `${0}${originEndY4Mm.getMonth() + 1}` : originStartY4Mm.getMonth() + 1,
                        [name]: e.target.value,
                    });
                }else if(name === "searchStartMm"){
                    setSearchOption({
                        ...searchOption,
                        searchStartY4 : e.target.value === '12' ? originStartY4Mm.getFullYear().toString() : originEndY4Mm.getFullYear().toString(),
                        [name]: e.target.value,
                    });
                }else if(name === "searchEndMm"){
                    setSearchOption({
                        ...searchOption,
                        searchEndY4 : e.target.value === '12' ? originStartY4Mm.getFullYear().toString() : originEndY4Mm.getFullYear().toString(),
                        [name]: e.target.value,
                    });
                }
            }else {
                setSearchOption({
                    ...searchOption,
                    [name]: e.target.value,
                });
            }
        }else if(name === "searchProperty") {
            setSearchOption({
                ...searchOption,
                [name]: e.target.value,
                searchContents: "",
            });
        }else {
            const changedValue = e.target.value;
            setSearchOption({
                ...searchOption,
                [name]: changedValue,
            });
        }
    };


    /*
 * @desc     미납내역 청구 년도 옵션 생성하는 함수
 */
    const fnCreateYearOption = () => {
        const arrYear = [];

        const today = new Date();
        const startY4Mm = new Date(today.getFullYear(), today.getMonth() - 1);
        const endY4Mm = new Date();

        if(startY4Mm.getFullYear() === endY4Mm.getFullYear()){
            arrYear.push({
                label: `${startY4Mm.getFullYear()}년`,
                value: startY4Mm.getFullYear(),
            });
        }else{
            for(let startY4=startY4Mm.getFullYear(); startY4<=endY4Mm.getFullYear(); startY4++){
                arrYear.push({
                    label : `${startY4}년`,
                    value : startY4,
                });
            }
        }
        return arrYear;
    };

    /*
   * @desc     미납내역 청구 시작월 / 종료월의 옵션 생성하는 함수
   */
    const fnCreateEndMonthOption = (searchMm) => {
        const arrMonth = [];

        const endY4Mm = new Date();
        const startY4Mm = new Date(endY4Mm.getFullYear(), endY4Mm.getMonth() - 1);

        arrMonth.push({
            label : `${startY4Mm.getMonth() + 1}월`,
            value : startY4Mm.getMonth() < 9 ? `${0}${startY4Mm.getMonth() + 1}` : startY4Mm.getMonth() + 1,
        },
            {
            label : `${endY4Mm.getMonth() + 1}월`,
            value : endY4Mm.getMonth() < 9 ? `${0}${endY4Mm.getMonth() + 1}` : endY4Mm.getMonth() + 1,
        });

        return arrMonth;
    };

    /*
    * @desc   미납내역 체크 대상 청구 Request
    */
    const fnRequestTarget = () => {
        const url = `api/receipt/unpaid/request`;
        const unpaidRequestReceiptDto = {};
        const unpaidReceiptSourceList = targetList.filter((element) => element._checked).map((element) => ({ ...element}));

        unpaidRequestReceiptDto.transactionMethod = selectedTransactionMethod;
        unpaidRequestReceiptDto.unpaidReceiptSourceList = unpaidReceiptSourceList;

        CM.cfnAxios(
            url,
            "post",
            unpaidRequestReceiptDto,
            fnRequestTargetCallback
        );
    };

    /*
 * @desc    미납내역 체크 대상 청구 Request Callback
 */
    const fnRequestTargetCallback = async (objStatus, objData) => {

        CM.cfnAlert(objData);

        //데이터초기화
        setSearchOption((data) => ({...data, pageNumber: 0}));
        setSearchButton(true);
    }

    /*
    * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
    */
    const handleRowPerPageChange = (e) => {
        const value = e.target.value;
        setSearchOption((data) => ({...data, pageSize: value, pageNumber: 0}));
        setSearchButton(true);
    };

    /*
     * @desc  출금청구 버튼 클릭 이벤트 핸들러
     */
    const handleClickRequest = () => {
        const tempArray = [];

        for (const element of targetList) {
            if (element._checked){
                tempArray.push(element);
            }
        }

        if (fnCheckValidation(tempArray)) {
            let today = new Date();
            CM.cfnConfirm("즉시납부 청구하시겠습니까?\n(청구일 : " + CM.cfnDateFormat(CM.cfnConvertDateTimeToString(today),"yyyyMMdd") + ", 건수 : " + tempArray.length + "건)\n*해당고객에게 문자메시지가 즉시발송되며, 별도요금이 과금됩니다.", fnRequestTarget);
        }
    }

    /*
 * @desc    선택항목 추가 전 validation 체크 함수
 */
    const fnCheckValidation = (tempArray) => {

        //체크된 행 없을 시
        if (CM.cfnIsEmpty(tempArray)) {
            CM.cfnAlert("선택된 항목이 없습니다.");
            return false;
        }

        return true;
    }

    /*
    * 청구 대상 리스트 값을 변경하는 함수
    */
    const handleListChange = (value) => setTargetList(value);

    /*
     * @desc    정렬 조건 변경 이벤트 핸들러
     */
    const handleSortProperty = async (sortObjArray) => {
        let sortProperty = "";
        let sortDirection = "";

        for (const obj of sortObjArray) {
            if (obj.name === "sortProperty") sortProperty = obj.value;
            if (obj.name === "sortDirection") sortDirection = obj.value;
        }

        setSearchOption({
            ...searchOption,
            sortProperty: sortProperty,
            sortDirection: sortDirection,
        });
        //정렬조건 세팅 후 검색 trigger
        setSearchButton(true);
    };

    /*
    * @desc    페이지 변경 이벤트 핸들러 (검색)
    */
    const handleOffsetChange = (offset, page) => {
        setSearchOption((data) => ({ ...data, pageNumber: page - 1 }));
        setSearchButton(true);
    };

    useEffect(() => {
        /*
         * @desc 검색 결과조회 파라미터 생성 함수
         */
        const fnMakeParameter = (search) => {
            let tempObj = CM.cfnCopyObject(search);
            tempObj.searchStartY4mm = search.searchStartY4 + search.searchStartMm;
            tempObj.searchEndY4mm = search.searchEndY4 + search.searchEndMm;

            return tempObj;
        };

        /*
        * @desc  미납내역 청구 분 검색결과조회 Request
        */
        const axiosList = (searchParam) => {
            return new Promise((resolve) => {
                let url = `api/receipt/unpaid/request/?searchStartY4mm=${searchParam.searchStartY4mm}&searchEndY4mm=${searchParam.searchEndY4mm}&searchContents=${searchParam.searchContents}&searchProperty=${searchParam.searchProperty}&sortProperty=${searchParam.sortProperty}&sortDirection=${searchParam.sortDirection}&pageNumber=${searchParam.pageNumber}&pageSize=${searchParam.pageSize}`;

                CM.cfnAxios(url, "get", "", (status, data) => {
                    resolve(data);
                });
            });
        };

        /*
        * @desc    목록저장(엑셀) Request
        */
        const axiosExcel = (searchParam) => {
            return new Promise((resolve) => {
                const url = `api/receipt/unpaid/request/excel?searchStartY4mm=${searchParam.searchStartY4mm}&searchEndY4mm=${searchParam.searchEndY4mm}&searchContents=${searchParam.searchContents}&searchProperty=${searchParam.searchProperty}&sortProperty=${searchParam.sortProperty}&sortDirection=${searchParam.sortDirection}`;
                CM.cfnAxiosFileDownload(url, "get", "", () => {});
            });
        };

        const startAxios = async (search, buttonType) => {
            if (buttonType === "search") {
                setSearchButton(false);
                if(search.searchStartY4mm.substr(0,4) === search.searchEndY4mm.substr(0,4)){
                    if(search.searchStartY4mm.substr(4,2) > search.searchEndY4mm.substr(4,2)){
                        CM.cfnAlert("종료년월은 시작년월보다 크거나 같아야합니다.");
                        return false;
                    }
                }else if(search.searchStartY4mm.substr(0,4) > search.searchEndY4mm.substr(0,4)){
                        CM.cfnAlert("종료년월은 시작년월보다 크거나 같아야합니다.");
                        return false;
                }

                axiosList(search).then((data) => {
                    setOriginalList(data);
                    setTargetList(data.content.map((data) => ({...data, _checked: false})));
                    setPagination(paginationData(data));
                });

            }else if(buttonType === "excel") {
                setExcelButton(false);
                await axiosExcel(search);
            }
        }

        if (tabIndex === 1 && searchButton === true) {
            const param = fnMakeParameter(searchOption);
            startAxios(param, "search");
        } else if (tabIndex === 1  && excelButton === true) {
            const param = fnMakeParameter(searchOption);
            startAxios(param, "excel");
        } else if (tabIndex !== 1 && !searchButton) {
            setSearchButton(true);
        }
    },[tabIndex, searchButton, excelButton, searchOption]);

    /*
* @desc  계좌/신용카드 수납방법 라디오 버튼 핸들러
*/
    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedTransactionMethod(value);
    };

    /*
 * @desc  계좌/신용카드 발송 내용 설정 클릭 이벤트 핸들러
 */
    const handleSendLmsMessageInfoModal = () => {
        setSendMessageModalOpen(true);
    }

    /*
* @desc  청구 실패내역 조회 이벤트 핸들러
*/
    const handleFailContentsModal = () => {
        setFailContentsModalOpen(true);
    }


  return (
      <div>
        <div className="inforbox">
              <ul>
                <li>(청구 대상 조회) 조회 대상 월을 최근 2개월이내로 하여 CMS 출금 청구 내역 중 미납 내역을 조회합니다.
                <br/>※ 현재 CMS로 출금 청구 중인 미납 내역은 중복 수납 우려 등으로 인해 청구 대상에서 제외됩니다.</li>
                <li>(청구) 조회된 내역을 선택하여 청구합니다.
                <br/>-발송내역 미리 보기를 클릭하시면 고객 앞 문자 메시지로 발신될 내용을 사전에 확인 할 수 있습니다.
                <br/>-문자메세지내 결제용 URL은 발송 이후, 익일까지 유효합니다.</li>
              </ul>
        </div>
        <div>
            {/* 검색 폼 */}
            <CreateSearchForm
                searchOption={searchOption}
                changeSearchFormHandler={changeSearchFormHandler}
                changeSearchRequest={changeSearchRequest}
                fnCreateYearOption={fnCreateYearOption}
                fnCreateEndMonthOption={fnCreateEndMonthOption}
            />
            {/* 청구 요청 폼 */}
            <UnpaidReceiptGeneration
                openGiroUseYn = {openGiroUseYn}
                pgServiceUseYn = {pgServiceUseYn}
                selectedTransactionMethod = {selectedTransactionMethod}
                handleRadioChange = {handleRadioChange}
                handleSendLmsMessageInfoModal={handleSendLmsMessageInfoModal}
                handleClickRequest={handleClickRequest}
            />
            <h4>즉시납부 청구대상</h4>
            {/* 건수 폼 */}
            <div className="table-top-area">
                <TotalCountForm totalElements={originalList.totalElements || 0} />
                <RowPerPageForm data-testid="receiptList-rowPerPage-select" value={searchOption.pageSize} onChange={handleRowPerPageChange} />
                <div className="fr">
                <button className="btn-m " data-testid="save-excel" onClick={handleFailContentsModal}>
                    청구실패내역 조회
                    <TooltipForm contents="최근 2개월내 출금불능분 즉시납부 청구 실패내역을 조회합니다." />
                </button>
                <button className="btn-m " data-testid="save-excel" onClick={() => setExcelButton(true)}>
                    엑셀저장
                </button>
                </div>
            </div>
            {/* 미납 내역 청구분 리스트 보여주는 부분 */}
            <ListForm
                targetList={targetList}
                handleListChange={handleListChange}
                pagination={pagination}
                page={searchOption.pageNumber}
                setSearchButton={setSearchButton}
                searchRequest={searchOption}
                handleSortProperty={handleSortProperty}
            />
            <Pagination
                className="centered"
                limit={pagination.rowsPerPage}
                offset={pagination.offset}
                total={pagination.total}
                onClick={(e, offset, page) => handleOffsetChange(offset, page)}
                reduced={true}
                centerRipple={false}
                disableFocusRipple={true}
                disableRipple={true}
            />
            <TabOpenGiroAndPgSendmessageModal open={sendMessageModalOpen} setOpen={setSendMessageModalOpen} data-testid="openGiroAndPgAgreement-open-modal"/>
            <UnpaidReceiptRequestFailContentsModal open={failContentsModalOpen} setOpen={setFailContentsModalOpen}/>
        </div>
      </div>
  );
};

export default UnpaidReceiptRequest;
