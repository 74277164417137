import React from "react";
import { TableHead, TableSortLabel, TableCell, TableRow, Checkbox } from "@material-ui/core";
import MuiTableSortLabelIcon from "./MuiTableSortLabel-icon";
import * as Common from "../common/Common";
import {TooltipForm} from "./ComponentForm";

/*
 * @desc    TableFrom을 생성하는 메인 컴포넌트
 */
const TableForm = {
  /*
   * @desc    Table Head를 생성하는 컴포넌트
   * @param   { array }
   * @return  { TableCell }
   */
  compTableHead: ({ arrData, cellClassName }) => {
    return (
      <TableHead className="table-head-line">
        <TableRow key="headRow">
          {arrData.map((element, index) => {
            return (
              <TableCell
                key={index}
                align="center"
                style={{
                  whiteSpace: typeof element === "string" && element.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}
                className={cellClassName}>
                {element}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
  /*
   * @desc    checkbox를 가진 Table Head를 생성하는 컴포넌트
   * @param   { array }
   * @return  { TableCell }
   */
  compCheckboxTableHead: ({ arrData, checked, value, onChange, customProps }) => {
    return (
      <TableHead className="table-head-line">
        <TableRow key="headRow">
          <TableCell key={0} align="center">
            <Checkbox checked={checked} value={value || ""} id="TableHeadCheckbox" onChange={onChange} {...customProps} />
          </TableCell>
          {arrData.map((element, index) => {
            return (
              <TableCell
                key={index + 1}
                align="center"
                style={{
                  whiteSpace: element.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}>
                {element}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
  /*
   * @desc    정렬 가능한 Table Head를 생성하는 컴포넌트
   * @param   { array }
   * @return  { TableCell }
   */
  compSortableTableHead: ({ headRows, searchCondition, onRequestSort, rowCount, isCheckable, onSelectAllClick, numSelected }) => {
    const handleClickSortLabel = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className="table-head-line">
        <TableRow>
          {isCheckable && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all rows" }}
                color={"primary"}
              />
            </TableCell>
          )}
          {headRows.map((row, index) => {
            const order = searchCondition.sortDirection.toLowerCase();
            const orderBy = searchCondition.sortProperty;

            return row.sortable ? (
              <TableCell
                key={row.id}
                align="center"
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  whiteSpace: row.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}>
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={handleClickSortLabel(row.id)}
                  // data-testid={`${tableSortLabelDataTestId}-${row.id}`}
                  IconComponent={MuiTableSortLabelIcon}
                  className={orderBy === row.id ? (order === "desc" ? "MuiTableSortLabel-desc" : "MuiTableSortLabel-asc") : ""}>
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={index + 1}
                align="center"
                style={{
                  whiteSpace: row.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}>
                {row.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
  /*
   * @desc    데이터가 없을 때 빈 Table Row를 생성하는 컴포넌트
   * @param   { colSpan: Number }
   * @return  { TableCell }
   */
  compEmptyTableRow: ({ colSpan }) => {
    return (
      <TableRow>
        <TableCell align="center" colSpan={colSpan}>
          {"표시할 내용이 없습니다."}
        </TableCell>
      </TableRow>
    );
  },
  /*
   * @desc    row가 두 개인 Table Head를 생성하는 컴포넌트
   * @param   { rowOne, rowTwo }
   * @return  { TableCell }
   */
  compDoubleRowTableHead: ({ rowOne, rowTwo }) => {
    return (
      <TableHead className="table-head-line">
        <TableRow key="headRowOne">
          {rowOne.map((value, index) => {
            const rowSpanProps = Common.cfnIsNotEmpty(value.rowSpan) ? { rowSpan: value.rowSpan } : {};
            const colSpanProps = Common.cfnIsNotEmpty(value.colSpan) ? { colSpan: value.colSpan } : {};

            return (
              <TableCell key={index} className="multiLine-cell" {...rowSpanProps} {...colSpanProps}>
                {value.value}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow key="headRowTwo">
          {rowTwo.map((value, index) => {
            return (
              <TableCell className="multiLine-cell" key={index}>
                {value.value}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
  /*
   * @desc    row가 두 개인 Table Head를 생성하는 컴포넌트
   * @param   { rowOne, rowTwo }
   * @return  { TableCell }
   */
  compCheckBoxDoubleRowTableHead: ({ rowOne, rowTwo, checked, value, onChange }) => {
    return (
      <TableHead className="table-head-line">
        <TableRow key="headRowOne">
          <TableCell key={0} align="center" rowSpan="2">
            <Checkbox checked={checked} value={value || ""} id="TableHeadCheckbox" onChange={onChange} />
          </TableCell>
          {rowOne.map((value, index) => {
            const rowSpanProps = Common.cfnIsNotEmpty(value.rowSpan) ? { rowSpan: value.rowSpan } : {};
            const colSpanProps = Common.cfnIsNotEmpty(value.colSpan) ? { colSpan: value.colSpan } : {};

            return (
              <TableCell key={index} className="multiLine-cell" {...rowSpanProps} {...colSpanProps}>
                {value.value}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow key="headRowTwo">
          {rowTwo.map((value, index) => {
            return (
              <TableCell className="multiLine-cell" key={index}>
                {value.value}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
  /*
   * @desc    서버에서 페이징 및 데이터 정렬 시 검색 이벤트를 처리하는 Table Head를 생성하는 컴포넌트
   * @param   {
   *            arrData : [Array] 생성 시 필요한 항목을 담은 Array(id, label, sortable)
   *            useCheckbox : [boolean] 체크박스 사용 여부
   *            checked : [boolean] (체크박스 사용 시) 체크박스의 check 여부 결정값
   *            value : (체크박스 사용 시) 체크박스의 value
   *            onChange : [function] (체크박스 사용 시) 체크박스 변경 이벤트 핸들러
   *            searchRequest : 검색조건을 담은 state
   *            handleSortProperty : [function] 검색조건 중 정렬 관련 필드만 변경 후 검색버튼을 trigger하는 핸들러
   *          }
   * @return  { TableCell }
   */
  compServerSortTableHead: ({ arrData, useCheckbox, checked, value, onChange, searchRequest, handleSortProperty, checkboxCustomProps, tableSortLabelDataTestId }) => {
    const handleClickSortLabel = (property) => (e) => {
      const isDesc = searchRequest.sortProperty === property && searchRequest.sortDirection === "DESC";

      const paramArray = [
        {
          name: "sortProperty",
          value: property,
        },
        {
          name: "sortDirection",
          value: isDesc ? "ASC" : "DESC",
        },
      ];

      handleSortProperty(paramArray);
    };

    return (
      <TableHead className="table-head-line">
        <TableRow key="headRow">
          {useCheckbox && (
            <TableCell key="checkbox" align="center">
              <Checkbox checked={checked} value={value || ""} id="TableHeadCheckbox" onChange={onChange} {...checkboxCustomProps} />
            </TableCell>
          )}
          {arrData.map((cell, index) => {
            const order = searchRequest.sortDirection.toLowerCase();
            const orderBy = searchRequest.sortProperty;

            return cell.sortable ? (
              <TableCell
                key={cell.id}
                align="center"
                sortDirection={orderBy === cell.id ? order : false}
                style={{
                  whiteSpace: typeof cell.label === "string" && cell.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}>
                <TableSortLabel
                  active={orderBy === cell.id}
                  direction={order}
                  onClick={handleClickSortLabel(cell.id)}
                  data-testid={`${tableSortLabelDataTestId}-${cell.id}`}
                  IconComponent={MuiTableSortLabelIcon}
                  className={orderBy === cell.id ? (order === "desc" ? "MuiTableSortLabel-desc" : "MuiTableSortLabel-asc") : ""}>
                  {cell.label}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={cell.id}
                align="center"
                style={{
                  whiteSpace: typeof cell.label === "string" && cell.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}>
                {cell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
  /*
   * @desc    row가 두 개이면서 서버에서 페이징 및 정렬 처리하는 Table Head를 생성하는 컴포넌트
   * @param   {
   *            rowOne : [Array] 생성 시 필요한 항목을 담은 Array(id, label, sortable, rowSpan, colSpan)
   *            rowTwo : [Array] 생성 시 필요한 항목을 담은 Array(id, label, sortable, rowSpan, colSpan)
   *            checked : [boolean] (체크박스 사용 시) 체크박스의 check 여부 결정값
   *            value : (체크박스 사용 시) 체크박스의 value
   *            onChange : [function] (체크박스 사용 시) 체크박스 변경 이벤트 핸들러
   *            searchRequest : 검색조건을 담은 state
   *            handleSortProperty : [function] 검색조건 중 정렬 관련 필드만 변경 후 검색버튼을 trigger하는 핸들러
   *          }
   * @return  { TableCell }
   */
  compServerSortDoubleRowTableHead: ({ rowOne, rowTwo, useCheckbox, checked, value, onChange, searchRequest, handleSortProperty, tableSortLabelDataTestId, checkboxCustomProps }) => {
    const handleClickSortLabel = (property) => (e) => {
      const isDesc = searchRequest.sortProperty === property && searchRequest.sortDirection === "DESC";

      const paramArray = [
        {
          name: "sortProperty",
          value: property,
        },
        {
          name: "sortDirection",
          value: isDesc ? "ASC" : "DESC",
        },
      ];

      handleSortProperty(paramArray);
    };

    return (
      <TableHead className="table-head-line">
        <TableRow key="headRowOne">
          {useCheckbox && (
            <TableCell key="checkbox" align="center" rowSpan="2">
              <Checkbox checked={checked} value={value || ""} id="TableHeadCheckbox" onChange={onChange} {...checkboxCustomProps} />
            </TableCell>
          )}
          {rowOne.map((cell, index) => {
            const rowSpanProps = Common.cfnIsNotEmpty(cell.rowSpan) ? { rowSpan: cell.rowSpan } : {};
            const colSpanProps = Common.cfnIsNotEmpty(cell.colSpan) ? { colSpan: cell.colSpan } : {};

            const order = searchRequest.sortDirection.toLowerCase();
            const orderBy = searchRequest.sortProperty;

            return cell.sortable ? (
              <TableCell
                key={index + 1}
                align="center"
                sortDirection={orderBy === cell.id ? order : false}
                className="multiLine-cell"
                style={{
                  whiteSpace: cell.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}
                {...rowSpanProps}
                {...colSpanProps}>
                <TableSortLabel
                  active={orderBy === cell.id}
                  direction={order}
                  onClick={handleClickSortLabel(cell.id)}
                  data-testid={`${tableSortLabelDataTestId}-${cell.id}`}
                  IconComponent={MuiTableSortLabelIcon}
                  className={orderBy === cell.id ? (order === "desc" ? "MuiTableSortLabel-desc" : "MuiTableSortLabel-asc") : ""}>
                  {cell.label}
                  {cell.tooltip ?  <TooltipForm contents={cell.contents} /> : ""}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={index + 1}
                align="center"
                style={{
                  whiteSpace: typeof cell.label === "string" && cell.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}
                className="multiLine-cell"
                {...rowSpanProps}
                {...colSpanProps}>
                {cell.label}
                {cell.tooltip ?  <TooltipForm contents={cell.contents} /> : ""}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow key="headRowTwo">
          {rowTwo.map((cell, index) => {
            const order = searchRequest.sortDirection.toLowerCase();
            const orderBy = searchRequest.sortProperty;

            return cell.sortable ? (
              <TableCell
                key={index + 1}
                align="center"
                sortDirection={orderBy === cell.id ? order : false}
                className="multiLine-cell"
                style={{
                  whiteSpace: cell.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}>
                <TableSortLabel
                  active={orderBy === cell.id}
                  direction={order}
                  onClick={handleClickSortLabel(cell.id)}
                  data-testid={`${tableSortLabelDataTestId}-${cell.id}`}
                  IconComponent={MuiTableSortLabelIcon}>
                  {cell.label}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={index + 1}
                align="center"
                style={{
                  whiteSpace: cell.label.indexOf("\n") === -1 ? "" : "pre-wrap",
                }}
                className="multiLine-cell">
                {cell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  },
};

export default TableForm;
