const CommonDataName = {
  // //금융기관 접수분 > 오류내역전송 > 오류코드
  // askedContents: value => {
  //   switch (value) {
  //     case "N":
  //     case "M":
  //       return "면제";
  //     // case "M":
  //     //   return "한시적면제";
  //     case "P":
  //       return "일부제출";
  //     case "Y":
  //       return "제출";
  //     default:
  //       return value;
  //   }
  // },
  evidenceSubmissionType: (value) => {
    switch (value) {
      case "N":
      case "M":
        return "면제";
      // case "M":
      //   return "한시적면제";
      case "P":
        return "일부제출";
      case "Y":
        return "제출";
      default:
        return value;
    }
  },
  // type: r = 수납, p = 지급
  transactionMethod: (value, type) => {
    switch (value) {
      case "CMS_WITHDRAW":
        return "CMS";
      case "CMS_DEPOSIT":
        return "CMS지급";
      case "ETC":
      default:
        // return type === 'r' ? '기타수납' : '기타지급';
        return "기타";
    }
  },
  accountRegistrationStatus: (value) => {
    switch (value) {
      case "READY_TO_REGISTER":
        return "등록대기";
      case "REGISTERING":
        return "등록중";
      case "REGISTERED":
        return "등록완료";
      case "FAILED_TO_REGISTER":
        return "등록실패";
      case "READY_TO_UNREGISTER":
        return "해지대기";
      case "UNREGISTERING":
        return "해지중";
      case "UNREGISTERED":
        return "해지완료";
      case "FAILED_TO_UNREGISTER":
        return "해지실패";
      default:
        return value;
    }
  },
  appliedResultStatus: (value) => {
    switch (value) {
      case "WAITING_FOR_RESULT":
        return "결과대기";
      case "CORRECTION_NEEDED":
        return "수정필요";
      case "REFUSED_TO_ACCEPT":
        return "수신불가(기한초과)";
      case "WAITING_FOR_APPROVEMENT":
        return "승인대기";
      case "APPLIED_RESULT":
        return "결과반영";
      default:
        return value;
    }
  },
  // type: r = 수납, p = 지급
  contractStatus: (value, type) => {
    switch (value) {
      case "NORMAL":
        return "정상";
      case "PAUSED":
        return type === "r" ? "수납중지" : "지급중지";
      case "DELETED":
        return "삭제";
      default:
        return value;
    }
  },
  payPeriod: (value) => {
    switch (value) {
      case "MONTHLY":
        return "매월";
      case "EVERY_2_MONTHS":
        return "2개월";
      case "EVERY_3_MONTHS":
        return "3개월";
      case "EVERY_6_MONTHS":
        return "6개월";
      case "YEARLY":
        return "1년";
      case "VARIABLE":
        return "비정기";
      default:
        return value;
    }
  },
  askStatus: (value) => {
    switch (value) {
      case "NORMAL":
        return "정상/미청구";
      case "INITIAL":
        return "초기상태";
      case "ASKING":
        return "청구중";
      case "HOLDING":
        return "지급보류";
      default:
        return value;
    }
  },
  evidenceFileType: (value) => {
    switch (value) {
      case "PAPER":
        return "서면";
      case "PUBLIC_SIGNATURE":
        return "전자문서-공인전자서명";
      case "GENERAL_SIGNATURE":
        return "전자문서-일반전자서명";
      case "RECORDING":
        return "녹취";
      case "ARS":
        return "ARS";
      case "ETC":
        return "기타";
      case "PRIVATE_SIGNATURE":
        return "전자문서-사설전자서명";
      default:
        return value;
    }
  },
  accountFixingReason: (value) => {
    switch (value) {
      case "EVIDENCE_FILE_MISSING_ERROR":
        return "동의자료 미존재";
      case "ACCOUNT_ERROR":
        return "계좌 오류";
      case "IDENTIFICATION_ERROR":
        return "생년월일(사업자번호) 오류";
      case "ETC_ERROR":
        return "기타 오류";
      default:
        return value;
    }
  },
  //계좌요청 상태(실시간서비스-실시간계좌등록/해지요청탭-신청구분)
  accountRequestStatus: (wayToCreate, accountRegistration) => {
    if (wayToCreate === "CHANGING") {
      if (accountRegistration === "READY_TO_REGISTER") {
        return "(변경)등록";
      } else {
        return "(변경)해지";
      }
    } else {
      if (accountRegistration === "READY_TO_REGISTER") {
        return "등록";
      } else {
        return "해지";
      }
    }
  },
  //계좌요청 상태(실시간서비스-실시간계좌등록요청내역탭-신청구분)
  accountRequestHistoryStatus: (wayToCreate, realtimeTransactionType) => {
    if (wayToCreate === "CHANGING") {
      if (realtimeTransactionType === "ACCOUNT_REGISTRATION") {
        return "(변경)등록";
      } else {
        return "(변경)해지";
      }
    } else {
      if (realtimeTransactionType === "ACCOUNT_REGISTRATION") {
        return "등록";
      } else {
        return "해지";
      }
    }
  },
  fileStatus: (value) => {
    switch (value) {
      case "READY":
        return "요청대기";
      case "TRANSMITTED":
        return "요청완료";
      case "ERROR_ON_TRANSMISSION":
        return "요청중 실패";
      case "RECEIVED_ERROR_FILE":
        return "오류응답 수신";
      case "CONFIRMED_RESULT_FILE":
      case "APPLIED_RESULT_FILE":
        return "결과반영 완료";
      default:
        return value;
    }
  },
  // 고객 구분
  customerType: (value) => {
    switch (value) {
      case "INDIVIDUAL":
        return "개인";
      case "BUSINESSER":
      case "CORPORATION":
        return "사업자";
      case "ETC":
        return "기타";
      default:
        return value;
    }
  },
  //종이영수증 / 종이세금계산서 발행내역 상태
  billIssueStatus: (status) => {
    switch (status) {
      case "SUCCESSFULLY_ISSUED":
        return "발행성공";
      case "ISSUE_FAILED":
        return "발행실패";
      case "WRITTEN":
        return "작성";
      case "NORMAL":
        return "작성대기";
      case "READY_TO_RESEND":
        return "재전송대기";
      default:
        return "";
    }
  },
  cashbillDirectIssueTargetDto: {
    issuingType: (value) => {
      switch (value) {
        case "BO11":
          return "직접발행";
        case "EA44":
          return "자동발행";
        default:
          return value;
      }
    },
    transactionMethod: (value) => {
      switch (value) {
        case "CMS_WITHDRAW":
          return "CMS출금";
        case "AUTOMATIC_TRANSFER":
          return "자동이체";
        case "ELECTRONIC_RECEIPT":
          return "전자수납";
        case "FIXED_OCR":
          return "정액OCR";
        case "CMS_DEPOSIT":
          return "CMS입금";
        case "MICR":
          return "MICR";
        case "OCR":
          return "일반OCR";
        case "ETC":
          return "기타";
        default:
          return value;
      }
    },
    purposeType: (value) => {
      switch (value) {
        case "INCOME_DEDUCTION":
          return "소득공제용";
        case "EXPENDITURE_DOCUMENT":
          return "지출증빙용";
        default:
          return value;
      }
    },
    transactionType: (value) => {
      switch (value) {
        case "APPROVAL":
          return "승인거래";
        case "CANCELLATION":
          return "취소거래";
        default:
          return value;
      }
    },
  },
  cashbillIssueSummaryDto: {
    resultType: (value) => {
      switch (value) {
        case "TRANSFERABLE":
          return "취소요청";
        case "TRANSFERRED":
          return "전송완료";
        case "RECEIVED_RESULT":
          return "결과수신";
        case "APPLY_COMPLETED":
          return "등록완료";
        case "NOT_ABLE_TO_TRANSFER":
          return "전송불가";
        default:
          return value;
      }
    },
  },
  paymentContract: {
    transactionMethod: (value) => {
      switch (value) {
        case "CMS_DEPOSIT":
          return "CMS";
        default:
          // ETC
          return "기타";
      }
    },
  },
  receiptContract: {
    transactionMethod: (value) => {
      switch (value) {
        case "CMS_WITHDRAW":
          return "CMS";
        default:
          // ETC
          return "기타";
      }
    },
  },
  delayRate: {
    delayChargingType: (value) => {
      switch (value) {
        case "FIXED_RATE":
          return "정율제";
        case "FIXED_PRICE":
          return "정액제";
        default:
          // "NONE"
          return "적용안함";
      }
    },
  },
  //동의자료 사후점검 메뉴에서 요청파일 parsing 시 사용하는 동의자료 유형
  evidenceFileTypeEnum: {
    "1": "서면",
    "2": "전자문서-공인전자서명",
    "3": "전자문서-일반전자서명",
    "4": "녹취",
    "5": "ARS",
    "6": "전자문서-기타",
    "7": "전자문서-사설전자서명",
  },
  //CMS출금&입금 결과코드
  cmsResultOptions: [
    {
      label: "완납",
      value: "PAID",
    },
    {
      label: "부분납",
      value: "PARTIALLY_PAID",
    },
    {
      label: "미납",
      value: "UNPAID",
    },
  ],
  cmsResultCode: (code) => {
    switch (code) {
      case "E_0012":
        return "계좌번호 오류 또는 계좌번호 없음";
      case "E_0014":
        return "사업자등록번호 또는 생년월일 오류";
      case "E_0015":
        return "계정과목 오류";
      case "E_0017":
        return "출금이체 미신청계좌";
      case "E_0018":
        return "출금이체신청 임의해지";
      case "E_0019":
        return "출금이체신청 은행 해지";
      case "E_0020":
        return "자동납부 동의자료 부재로 인한 임의해지";
      case "E_0021":
        return "잔액 또는 지불가능 잔액 부족";
      case "E_0022":
        return "입금한도 초과";
      case "E_0024":
        return "계좌변경으로 인한 출금이체신청 해지";
      case "E_0031":
        return "해약계좌";
      case "E_0032":
        return "가명계좌 또는 실명미확인";
      case "E_0033":
        return "잡 좌";
      case "E_0034":
        return "법적제한계좌, 지급정지 또는 사고신고계좌";
      case "E_0035":
        return "압류․가압류 계좌";
      case "E_0036":
        return "잔액증명발급 계좌";
      case "E_0037":
        return "연체계좌 또는 지점통제계좌";
      case "E_0038":
        return "거래중지계좌";
      case "E_0041":
        return "은행시스템 오류";
      case "E_0051":
        return "기타 오류";
      case "E_0065":
        return "법인계좌 사용불가";
      case "E_0066":
        return "투자자예탁금이 아님";
      case "E_0097":
        return "농협 은행점코드 오류";
      case "E_A011":
        return "신청일자 오류";
      case "E_A016":
        return "이중신청";
      case "E_A018":
        return "납부자번호체계 오류";
      case "E_A012":
        return "신청구분 오류(출금이체신청내역(EB13)에서 신청구분이 신규(1), 해지(3), 임의해지(7)이 아닌 경우)";
      case "E_0011":
        return "은행점코드 오류";
      case "E_0061":
        return "의뢰금액이 0원인 경우";
      case "E_0062":
        return "건당 이체금액한도 초과";
      case "E_0068":
        return "통장기재내용 Field에 HEX 20(Space)보다 작은 값이 왔을 때";
      case "E_0075":
        return "① 출금의뢰내역(EB21, EC21)의 “출금형태” 항목에 전액출금(1) 또는 부분출금가능(0, 2, 3, 4, 5, 6)이 아닌 값이 왔을 경우 ② EB21 출금의뢰내역에 의뢰금액이 140원 미만이거나,EC21 출금의뢰내역에 의뢰금액이 300원 미만인 경우";
      case "E_0077":
        return "계좌변경 내역을 상대 은행(변경 전 또는 변경 후)에서 전송하지 않은 경우";
      case "E_0078":
        return "기관이 EI13을 전송하지 않아 EB13 신규 신청내역을 오류처리 하는 경우";
      case "E_0079":
        return "기관이 계좌변경 내역을 SET로 처리하지 않아 결제원이 은행으로 대행응답하는 경우";
      case "E_0081":
        return "Record 구분 항목이 H, R, T가 아니거나, 일련번호 오류인 경우";
      case "E_0087":
        return "한글 오류 (한글코드 Field에 한글코드 이외의 값이 올 경우)";
      case "E_0088":
        return "영문자/숫자 오류 (영숫자코드 Field에 영숫자코드 이외의 값이 올 경우)";
      case "E_0089":
        return "Space 오류 (Space Field에 Space가 아닌 값이 올 경우)";
      case "E_0090":
        return "All Zero 오류 (All Zero Field에 All Zero가 아닌 값이 올 경우)";
      case "E_0091":
        return "생년월일 대신 주민등록번호로 신청하는 경우";
      case "E_0096":
        return "CMS 미참가 은행";
      case "E_0098":
        return "Alpha-Numeric + Space 오류";
      case "E_0101":
        return "동의자료 구분 오류(정의되지 않은 동의자료 구분값인 경우)";
      case "E_0102":
        return "동의자료 확장자 오류(동의자료 확장자가 동의자료 구분과 매칭되지 않거나 정의되지 않은 확장자인 경우.단, 일부 제출기관은 Space 이외 값 수록 시)";
      case "E_0103":
        return "동의자료 크기 오류(동의자료 형태별 최대 수록가능 크기 초과. 단, 일부 제출기관은 ‘0000000’ 이외 값 수록 시)";
      case "E_0105":
        return "동의자료 기타 오류(정의되지 않은 사유로 동의자료 전송이 실패한 경우)";
      case "E_9998":
        return "기타 오류 (표준오류코드에 정의되지 않은 사유로 발생한 오류)";
      case "E_9999":
        return "은행시스템 장애";
      case "E_A013":
        return "기타오류";
      case "E_A017":
        return "기타오류";
      case "E_A019":
        return "출금요청 중 또는 출금일 도래";
      default:
        return code;
    }
  },
};

export default CommonDataName;
