import { Checkbox, FormControlLabel, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import { searchPayAmountTypeData, searchTransactionMethodData } from "../../customer/paymentCustomerInformation/PaymentInformationData";
import { ButtonTooltipFormTwo, RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaymentSourceCreateModal from "./PaymentSourceCreateModal";
import PaymentSourceUpdateModal from "./PaymentSourceUpdateModal";

// 검색 데이터
function searchData() {
  return {
    searchStartY4: `${new Date().getFullYear().toString()}`,
    searchStartMm: new Date().getMonth() < 9 ? `${0}${new Date().getMonth() + 1}` : new Date().getMonth() + 1,
    searchEndY4: `${new Date().getFullYear().toString()}`,
    searchEndMm: new Date().getMonth() < 9 ? `${0}${new Date().getMonth() + 1}` : new Date().getMonth() + 1,
    sourceStatusList: {
      sourceStatus: true,
      UNASKED: true,
      UNPAID: true,
      UNREACHED: true,
      PAID: true,
      ASKING: true,
      ASKING_AGAIN: true,
      HOLDING: false,
    },
    capitalUniqueKey: "",
    groupUniqueKey: "",
    payAmountType: "",
    transactionMethod: "",
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
    pageNumber: 0,
    pageSize: 5,
    payerNo: "",
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;
  const [holdingButtonDisabled, setHoldingButtonDisabled] = useState(true); //일괄지급보류 버튼 비활성화flag
  const [unholdingButtonDisabled, setUnholdingButtonDisabled] = useState(true); //일괄지급보류 해제 버튼 비활성화flag
  const [hasSearchedAfterChangedCheckbox, setHasSearchedAfterChangedCheckbox] = useState(true); // 검색조건 지급상태 변경 후 검색을 했는지 플래그

  const searchOptionList = [
    { value: "CUSTOMER_NAME", label: "고객명" },
    { value: "IDENTIFICATION_NO", label: "생년월일(사업자번호)" },
  ];

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };
  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    //검색 버튼 클릭 시, pageNumber 0으로 초기화
    props.setSearchRequest((searchRequest) => ({ ...searchRequest, pageNumber: 0 }));
    props.handleSearchButtonChange(true);
    setHasSearchedAfterChangedCheckbox(true);
  };

  useEffect(() => {
    setHasSearchedAfterChangedCheckbox(
      unholdingButtonDisabled ===
        !(
          !searchRequest.sourceStatusList.ASKING &&
          !searchRequest.sourceStatusList.ASKING_AGAIN &&
          !searchRequest.sourceStatusList.PAID &&
          !searchRequest.sourceStatusList.UNASKED &&
          !searchRequest.sourceStatusList.UNPAID &&
          !searchRequest.sourceStatusList.UNREACHED &&
          searchRequest.sourceStatusList.HOLDING
        ) &&
        holdingButtonDisabled ===
          !(
            !searchRequest.sourceStatusList.ASKING &&
            !searchRequest.sourceStatusList.ASKING_AGAIN &&
            !searchRequest.sourceStatusList.PAID &&
            (searchRequest.sourceStatusList.UNASKED || searchRequest.sourceStatusList.UNPAID || searchRequest.sourceStatusList.UNREACHED) &&
            !searchRequest.sourceStatusList.HOLDING
          )
    );
    // 일괄지급보류 해제 : 지급보류만 체크되어있을 때 비활성화 해제
    setUnholdingButtonDisabled(
      !(
        !searchRequest.sourceStatusList.ASKING &&
        !searchRequest.sourceStatusList.ASKING_AGAIN &&
        !searchRequest.sourceStatusList.PAID &&
        !searchRequest.sourceStatusList.UNASKED &&
        !searchRequest.sourceStatusList.UNPAID &&
        !searchRequest.sourceStatusList.UNREACHED &&
        searchRequest.sourceStatusList.HOLDING
      )
    );
    // 일괄 지급보류 : 미청구/미도래/미지급분 중 하나이상만 체크되어있을 때 비활성화 해제
    setHoldingButtonDisabled(
      !(
        !searchRequest.sourceStatusList.ASKING &&
        !searchRequest.sourceStatusList.ASKING_AGAIN &&
        !searchRequest.sourceStatusList.PAID &&
        (searchRequest.sourceStatusList.UNASKED || searchRequest.sourceStatusList.UNPAID || searchRequest.sourceStatusList.UNREACHED) &&
        !searchRequest.sourceStatusList.HOLDING
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest]);

  /*
   * @desc    일괄수납보류 클릭 이벤트 핸들러
   */
  const fnSubmit = () => {
    props.handleHoldingButtonChange(true);
  };

  /*
   * @desc    일괄수납보류 해지 클릭 이벤트 핸들러
   */
  const fnUnholdingSubmit = () => {
    props.handleUnholdingButtonChange(true);
  };

  const SearchMonth = () => {
    const buttonHtml = [];
    for (let i = 1; i <= 12; i++) {
      buttonHtml.push(
        <div
          key={i}
          className="btn-xs"
          onClick={(e) => {
            props.fnChangeMonth(i > 9 ? i : "0" + i);
          }}>
          {i}월
        </div>
      );
    }
    return buttonHtml;
  };

  return (
    <div className="search-area tl">
      <div className="block" style={{ width: "100%" }}>
        <label className="label-l">지급기간</label>
        <SelectForm
          value={searchRequest.searchStartY4}
          handleChange={handleSearchFormChange("searchStartY4")}
          name="searchStartY4"
          arrayOption={props.fnCreateYearOption()}
          optionValue={"value"}
          optionLabel={"label"}
        />
        <SelectForm
          value={searchRequest.searchStartMm}
          handleChange={handleSearchFormChange("searchStartMm")}
          name="searchStartMm"
          arrayOption={props.fnCreateMonthOption()}
          optionValue={"value"}
          optionLabel={"label"}
        />
        <span className="between">~</span>
        <SelectForm
          value={searchRequest.searchEndY4}
          handleChange={handleSearchFormChange("searchEndY4")}
          name="searchEndY4"
          arrayOption={props.fnCreateYearOption()}
          optionValue={"value"}
          optionLabel={"label"}
        />
        <SelectForm
          value={searchRequest.searchEndMm}
          handleChange={handleSearchFormChange("searchEndMm")}
          name="searchEndMm"
          arrayOption={props.fnCreateMonthOption()}
          optionValue={"value"}
          optionLabel={"label"}
        />
        <div style={{ display: "flex" }}>
          <SearchMonth />
        </div>
        <label className="label-l">지급상태</label>
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.sourceStatus ? true : false}
              value={searchRequest.sourceStatusList.sourceStatus}
              onChange={handleSearchFormChange("sourceStatus")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-new-account",
              }}
            />
          }
          label="전체"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.PAID ? true : false}
              value={searchRequest.sourceStatusList.PAID}
              onChange={handleSearchFormChange("PAID")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="지급"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.ASKING ? true : false}
              value={searchRequest.sourceStatusList.ASKING}
              onChange={handleSearchFormChange("ASKING")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="입금중(최초입금)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.ASKING_AGAIN ? true : false}
              value={searchRequest.sourceStatusList.ASKING_AGAIN}
              onChange={handleSearchFormChange("ASKING_AGAIN")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="입금중(재입금)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.UNASKED ? true : false}
              value={searchRequest.sourceStatusList.UNASKED}
              onChange={handleSearchFormChange("UNASKED")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="미청구"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.UNREACHED ? true : false}
              value={searchRequest.sourceStatusList.UNREACHED}
              onChange={handleSearchFormChange("UNREACHED")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="미도래"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.UNPAID ? true : false}
              value={searchRequest.sourceStatusList.UNPAID}
              onChange={handleSearchFormChange("UNPAID")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="미지급"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchRequest.sourceStatusList.HOLDING ? true : false}
              value={searchRequest.sourceStatusList.HOLDING}
              onChange={handleSearchFormChange("HOLDING")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="지급보류"
        />
        <br />
        <br />
        <label className="label-l">자금종류</label>
        <Select
          native
          value={props.searchRequest.capitalUniqueKey}
          onChange={handleSearchFormChange("capitalUniqueKey")}
          name="capital"
          inputProps={{ "data-testid": "paymentInformation-select-capital" }}>
          <option value={""} key={"none"}>
            {"전체"}
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객구분</label>
        <Select native value={props.searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          <option value={""} key={"none"}>
            {"전체"}
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">지급방법</label>
        <Select
          native
          value={props.searchRequest.transactionMethod}
          onChange={handleSearchFormChange("transactionMethod")}
          name="group"
          inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          {props.transactionMethod.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금유형</label>
        <Select native value={props.searchRequest.payAmountType} onChange={handleSearchFormChange("payAmountType")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          {props.payAmountType.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
          <option value={"TEMPORARY_ADDED"} key={"TEMPORARY_ADDED"}>
            {"추가분"}
          </option>
        </Select>
        <label className="label-l">검색어입력</label>
        <Select native value={props.searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")} inputProps={{ "data-testid": "receiptsource-select-terms" }} name="terms">
          {searchOptionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField
          className="w130"
          value={props.searchRequest.searchContents}
          onChange={handleSearchFormChange("searchContents")}
          onKeyUp={handleSearchKeyUp}
          inputProps={{ "data-testid": "receiptsource-textfield-search" }}
          name="search"
        />
        <div style={{ marginTop: "5px", display: "inline-flex", width: "100%", justifyContent: "flex-end" }}>
          <button className="search-button" data-testid="" onClick={(e) => fnSearch()}>
            지급내역 조회
          </button>
          <ButtonTooltipFormTwo
            buttonContents="선택조건 일괄지급보류"
            contents="해당 버튼은 '미청구','미도래','미지급' 이외의 항목이 체크되어있을 시 비활성화됩니다."
            className="btn-l"
            onClick={(e) => !holdingButtonDisabled && (hasSearchedAfterChangedCheckbox ? fnSubmit() : CM.cfnAlert("선택조건을 변경하신 후에는 조회버튼을 클릭하여 대상을 확인해주시기 바랍니다."))}
            disabled={holdingButtonDisabled}
          />
          <ButtonTooltipFormTwo
            buttonContents="선택조건 일괄지급보류 해제"
            contents="해당 버튼은 '지급보류'만 체크해야 활성화됩니다."
            className="btn-l"
            onClick={(e) =>
              !unholdingButtonDisabled && (hasSearchedAfterChangedCheckbox ? fnUnholdingSubmit() : CM.cfnAlert("선택조건을 변경하신 후에는 조회버튼을 클릭하여 대상을 확인해주시기 바랍니다."))
            }
            disabled={unholdingButtonDisabled}
          />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const handleRowClick = (e, row) => {
    if (e.target.classList.contains("ignoreClick") || e.target.type === "button") {
      return;
    }
    props.fnGoSourceDetail(row);
  };

  //지급보류/지급보류해제
  const fnSubmitPaymentAskResult = (row, name) => {
    const fnCallback = (objStatus, objData) => {
      // 실패시
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        return;
      }
      //성공시
      CM.cfnAlert(objData, () => {
        props.setPagingButton(true);
      });
    };
    let url = `api/payment/source/${name}/${row.uniqueKey}`;
    CM.cfnAxios(url, "put", "", fnCallback);
  };

  //삭제
  const fnSubmitPaymentDelete = (row) => {
    const fnCallback = (objStatus, objData) => {
      // 실패시
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
        row.handlingDeletion = false;
        return;
      }
      //성공시
      CM.cfnAlert(objData, () => {
        props.setSearchButton(true);
      });
    };
    row.handlingDeletion = true;
    let url = `api/payment/source/${row.uniqueKey}`;
    CM.cfnAxios(url, "delete", "", fnCallback);
  };

  //지급보류, 보류해제 버튼 컴포넌트
  const fnPaymentHolding = (row) => {
    if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID" || row.askResult === "PARTIALLY_PAID") && row.askStatus === "NORMAL") {
      return (
        <div>
          <button className="btn-l2" type="button" onClick={(e) => fnSubmitPaymentAskResult(row, "holding")}>
            지급보류
          </button>
        </div>
      );
    } else if (row.askStatus === "HOLDING") {
      return (
        <div>
          <button className="btn-l3" type="button" onClick={(e) => fnSubmitPaymentAskResult(row, "cancelHolding")}>
            보류해제
          </button>
        </div>
      );
    }
  };

  //삭제버튼 노출 컴포넌트
  const fnPaymentDelete = (row) => {
    if (row.payAmountType === "TEMPORARY_ADDED" && row.refundedAmount === 0 && row.paidAmount === 0) {
      return (
        <div>
          <button className="btn-m" type="button" onClick={(e) => fnSubmitPaymentDelete(row)} disabled={row.handlingDeletion || false}>
            삭제
          </button>
        </div>
      );
    } else if (row.payAmountType === "TEMPORARY_ADDED") {
      return (
        <div>
          <button className="btn-m btn-disabled" disabled={true}>
            삭제
          </button>
        </div>
      );
    }
  };

  const fnAskStatus = (row) => {
    let status = "";
    switch (row.askResult) {
      case "UNASKED":
        status = "미청구";
        break;
      case "UNREACHED":
        status = "미도래";
        break;
      case "UNPAID":
        status = "미지급";
        break;
      case "PAID":
        status = "지급";
        break;
      case "PARTIALLY_PAID":
        status = "부분지급";
        break;
      default:
        break;
    }
    if (row.contractStatus === "DELETED") {
      status += "\n(삭제)";
    } else if (row.contractStatus === "PAUSED") {
      status += "\n(지급중지)";
    } else if (row.askStatus === "HOLDING") {
      status += "\n(지급보류)";
    } else if (row.askStatus === "ASKING") {
      status += "\n(입금중/" + (row.numberOfAsking > 0 ? "재입금" : "최초입금") + ")";
    }
    return status;
  };

  const fnPayAmounType = (payAmountType) => {
    switch (payAmountType) {
      case "FIXED":
        return "정액";
      case "VARIABLE":
        return "비정액";
      case "TEMPORARY_ADDED":
        return "추가분";
      default:
        return payAmountType;
    }
  };
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["8%", "5%", "7%", "11%", "auto", "5%", "8%", "8%", "8%", "8%", "11%", "9%", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          arrData={[
            { id: "targetY4mm", label: "대상월", sortable: true },
            { id: "TARGET_Y4MM", label: "지급일", sortable: true },
            { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
            { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true },
            { id: "customerGroupName", label: "고객구분", sortable: true },
            { id: "TRANSACTION_METHOD", label: "지급\n방법", sortable: true },
            { id: "PAY_AMOUNT_TYPE", label: "자금유형", sortable: true },
            { id: "CAPITAL", label: "자금종류", sortable: true },
            { id: "TOTAL_ASKING_AMOUNT", label: "지급\n대상금액", sortable: true },
            { id: "PAID_AMOUNT", label: "지급금액", sortable: true },
            { id: "ASK_RESULT", label: "지급상태", sortable: true },
            { id: "askStatus", label: "지급보류", sortable: false },
            { id: "delete", label: "삭제", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId={"paymentList-list-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={13} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} className="show-detail" hover onClick={(e) => handleRowClick(e, row)} data-testid={`paymentList-goDetail-${index}`}>
                  <TableCell align="center">{CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}</TableCell>
                  <TableCell align="center">{row.paySpecifiedDay === "99" ? "말일" : row.paySpecifiedDay}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center">{row.transactionMethod !== "CMS_DEPOSIT" ? "기타" : "CMS"}</TableCell>
                  <TableCell align="center">{fnPayAmounType(row.payAmountType)}</TableCell>
                  <TableCell align="center">{row.capitalName}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.paidAmount)}원</TableCell>
                  <TableCell align="center" style={{ whiteSpace: "pre-wrap" }}>
                    {fnAskStatus(row)}
                  </TableCell>
                  <TableCell align="center" className="ignoreClick">
                    {fnPaymentHolding(row)}
                  </TableCell>
                  <TableCell align="center" className="ignoreClick">
                    {fnPaymentDelete(row)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}
/*
 * @desc  목록 컴포넌트 생성
 */
function SummaryForm(props) {
  return (
    <div>
      <h4>지급대상(지급정보 기준)</h4>
      <div className="summary-main-area">
        <div className="summary-area">
          <div className="text-graph">
            <label>지급대상금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalAskingAmount)}원</span>
          </div>
          <div className="text-graph">
            <label>지급금액</label>
            <span>{CM.cfnAddComma(Number(props.summaryList.totalAskingAmount) - Number(props.summaryList.totalUnpaidAmount))}원</span>
          </div>
          <div className="text-graph">
            <label>미지급금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalUnpaidAmount)}원</span>
          </div>
        </div>
        <div className="summary-button-area">
          <button className="summary-button btn-m" data-testid="create-payments" onClick={(event) => props.setCreateModalOpen(true)}>
            지급내역추가
          </button>
          <button className="summary-button btn-m" data-testid="update-payments" onClick={(event) => props.setUpdateModalOpen(true)}>
            지급금액수정
          </button>
        </div>
      </div>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
const PaymentSourceList = ({ props }) => {
  const { store } = props;
  let history = useHistory();

  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [originRequest, setOriginRequest] = useState(searchData());
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [holdingButton, setHoldingButton] = useState(false); // 지급보류/지급보류해제/손실처리 실행 flag
  const [unholdingButton, setUnholdingButton] = useState(false);
  const [excelButton, setExcelButton] = useState(false);
  const [pagingButton, setPagingButton] = useState(false);
  const [summaryList, setsummaryList] = useState({
    totalAmount: 0,
    totalAskingAmount: 0,
    totalDelayFee: 0,
    totalUnpaidAmount: 0,
  }); //지급대상(지급정보 기준)
  const [originalList, setOriginalList] = useState([]);

  const [list, setlist] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  const [instituteInfoState, setInstituteInfoState] = useState(false);
  //검색 request옵션 생성 함수
  const fnCreatSearchPropertyOption = [
    { label: "고객명", value: "CUSTOMER_NAME" },
    { label: "생년월일(사업자번호)", value: "IDENTIFICATION_NO_FIRST7" },
  ];

  // 검색조건 고객구분 및 자금종류 / 기관구분정보 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosPaymentCapitals(); //지급 자금종류
      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      if (businessInfo.cmsService.indexOf("EB31") === -1) {
        await setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 출금이체서비스만 이용하시는 기관입니다.", () => {});
      }
    };

    getStoreDataAxios();
  }, [store]);

  useEffect(() => {
    /*
     * @desc 검색 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = (search) => {
      let tempObj = CM.cfnCopyObject(search);
      tempObj.searchStartY4mm = search.searchStartY4 + search.searchStartMm;
      tempObj.searchEndY4mm = search.searchEndY4 + search.searchEndMm;
      let tempArray = [];
      if (CM.cfnIsNotEmpty(search.sourceStatusList) && search.sourceStatusList.length > 0 && typeof search.sourceStatusList[0] === "string") {
        tempArray = search.sourceStatusList;
      } else {
        if (CM.cfnIsNotEmpty(search.sourceStatusList)) {
          const keyArray = Object.keys(search.sourceStatusList);

          Object.values(search.sourceStatusList).map((value, index) => (value && keyArray[index] !== "sourceStatus" ? tempArray.push(keyArray[index]) : ""));
        }
      }
      tempObj.sourceStatusList = tempArray;

      return tempObj;
    };

    /*
     * @desc    검색결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    지급대상(지급정보 기준) 결과조회 Request
     */
    const axiossummaryList = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/summary?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    일괄지급보류 처리 함수
     */
    const axiosHolding = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/holding/bulk?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "put", "", (status, data) => {
          CM.cfnAlert(data, () => {
            resolve(data);
          });
        });
      });
    };

    /*
     * @desc    일괄지급보류 해제 처리 함수
     */
    const axiosUnholding = (search) => {
      return new Promise((resolve) => {
        let url = `api/payment/source/unholding/bulk?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;

        CM.cfnAxios(url, "put", "", (status, data) => {
          CM.cfnAlert(data, () => {
            resolve(data);
          });
        });
      });
    };

    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `/api/payment/source/excel?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&sourceStatusList=${search.sourceStatusList}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;
        CM.cfnAxiosFileDownload(url, "get", "");
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      //일괄지급보류 시
      if (buttonType === "submit") {
        setHoldingButton(false);
        await axiosHolding(search);
        const resultData = await axiosList(search);
        const resultList = resultData.content;
        setlist(resultList);
      } else if (buttonType === "unholdingSubmit") {
        setUnholdingButton(false);
        await axiosUnholding(search);
        const resultData = await axiosList(search);
        const resultList = resultData.content;
        setlist(resultList);
      } else if (buttonType === "excel") {
        setExcelButton(false);
        await axiosExcel(search);
      } else if (buttonType === "originSearch") {
        setPagingButton(false);
        const resultData = await axiosList(search);
        const resultList = resultData.content;
        setlist(resultList);
        setPagination(paginationData(resultData));
      } else {
        setSearchButton(false);

        if (CM.cfnAddDate(search.searchStartY4mm, search.searchEndY4mm, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }

        //검색 시
        const summeryResultData = await axiossummaryList(search);
        const resultData = await axiosList(search);

        const resultList = resultData.content;
        const summeryResultList = summeryResultData;
        setOriginalList(resultData);
        setlist(resultList);
        setsummaryList(summeryResultList);
        setPagination(paginationData(resultData));
      }
      // state 유지를 위한 history 설정
      history.replace("/payment/paymentSource", search);
    }

    if (searchButton === true) {
      setOriginRequest(searchRequest);
      const param = fnMakeParameter(searchRequest);
      startAxios(param, "search");
    } else if (holdingButton === true) {
      const param = fnMakeParameter(searchRequest, "submit");
      startAxios(param, "submit");
    } else if (unholdingButton === true) {
      const param = fnMakeParameter(searchRequest, "unholdingSubmit");
      startAxios(param, "unholdingSubmit");
    } else if (pagingButton === true) {
      const param = fnMakeParameter(originRequest);
      startAxios(param, "originSearch");
    } else if (excelButton === true) {
      const param = fnMakeParameter(originRequest);
      startAxios(param, "excel");
    } else if ((history.action === "POP" || history.action === "PUSH") && CM.cfnIsNotEmpty(history.location.state)) {
      const getState = history.location.state;
      let sourceStatusList = {};
      if (getState.sourceStatusList && Array.isArray(getState.sourceStatusList)) {
        getState.sourceStatusList.map((status) => {
          sourceStatusList[status] = true;
          return null;
        });
        sourceStatusList.sourceStatus =
          sourceStatusList.PAID && sourceStatusList.ASKING && sourceStatusList.ASKING_AGAIN && sourceStatusList.UNASKED && sourceStatusList.UNREACHED && sourceStatusList.UNPAID;
      }
      let originalParameter = { ...searchRequest, ...getState, sourceStatusList };
      if (!CM.equals(sourceStatusList, searchRequest.sourceStatusList)) {
        setSearchRequest(originalParameter);
        setOriginRequest(originalParameter);
        setSearchButton(true);
      }
    }
  }, [searchRequest, searchButton, holdingButton, unholdingButton, excelButton, pagingButton, originRequest, history]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleOriginRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchPagingButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleOriginRequestChange((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    handleSearchPagingButtonChange(true);
  };

  /*
   * @desc  검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (e.target.type === "checkbox") {
      if (name === "sourceStatus") {
        setSearchRequest({
          ...searchRequest,
          sourceStatusList: {
            sourceStatus: e.target.checked,
            UNASKED: e.target.checked,
            UNPAID: e.target.checked,
            UNREACHED: e.target.checked,
            PAID: e.target.checked,
            ASKING: e.target.checked,
            ASKING_AGAIN: e.target.checked,
            HOLDING: e.target.checked,
          },
        });
      } else {
        setSearchRequest({
          ...searchRequest,
          sourceStatusList: {
            ...searchRequest.sourceStatusList,
            [name]: e.target.checked,
          },
        });
      }
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = async (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    await setOriginRequest({
      ...originRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    await setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    페이징 flag 변경 핸들러
   */
  const handleSearchPagingButtonChange = (flag) => setPagingButton(flag);

  /*
   * @desc    지급내역 검색조건에 따른 일괄 지급보류처리 핸들러
   */
  const handleHoldingButtonChange = (flag) => setHoldingButton(flag);

  /*
   * @desc    지급내역 검색조건에 따른 일괄 지급보류처리 핸들러
   */
  const handleUnholdingButtonChange = (flag) => setUnholdingButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleOriginRequestChange = (data) => setOriginRequest(data);

  const fnGoSourceDetail = (row) => {
    history.push("/payment/paymentSourceDetail", {
      paymentSourceDetail: row,
      searchRequest,
    });
  };

  /*
   * @desc     지급기간 일자 옵션 생성하는 함수
   */
  const fnCreateMonthOption = () => {
    const arrDate = [];
    const maxLoop = 13;

    for (let i = 1; i < maxLoop; i++) {
      let label = `${i}월`;
      let value = i < 10 ? `0${i}` : i;

      arrDate.push({
        label: label,
        value: value,
      });
    }

    return arrDate;
  };

  /*
   * @desc     지급기간 년도 옵션 생성하는 함수
   */
  const fnCreateYearOption = () => {
    const arrYear = [];
    const maxLoop = 20;
    const startYear = 2010;

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  //지급기간 (월 선택 ) 함수
  async function fnChangeMonth(month) {
    await setSearchRequest((searchRequest) => ({
      ...searchRequest,
      searchEndMm: month,
      searchStartMm: month,
      pageNumber: 0,
    }));
    handleSearchButtonChange(true);
  }

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  const style = useStyles();

  return (
    <div className={instituteInfoState ? style.disabled_div : ""}>
      <div>
        <div className="inforbox">
          <ul>
            <li>지급내역 중 지급계획은 차년도말까지 조회 및 변경 가능합니다.</li>
            <br />- 일괄지급보류는 현재 조회된 내역 중 지급상태가 미지급/미청구/지급일미도래건을 모두 동시에 지급보류 하는 기능입니다.
          </ul>
        </div>
      </div>
      <SearchForm
        searchRequest={searchRequest}
        setSearchRequest={setSearchRequest}
        fnChangeMonth={fnChangeMonth}
        handleSearchFormChange={handleSearchFormChange}
        handleSearchButtonChange={handleSearchButtonChange}
        fnCreateYearOption={fnCreateYearOption}
        fnCreateMonthOption={fnCreateMonthOption}
        storeCapital={toJS(store.paymentCapital)} // 자금종류
        storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
        payAmountType={searchPayAmountTypeData()}
        transactionMethod={searchTransactionMethodData()}
        handleHoldingButtonChange={handleHoldingButtonChange}
        handleUnholdingButtonChange={handleUnholdingButtonChange}
      />
      <SummaryForm summaryList={summaryList} setCreateModalOpen={setCreateModalOpen} setUpdateModalOpen={setUpdateModalOpen} />
      <div className="table-top-area">
        <TotalCountForm totalElements={originalList.totalElements || 0} />
        <RowPerPageForm data-testid="paymentList-rowPerPage-select" value={originRequest.pageSize} onChange={handleRowPerPageChange} />
        <button className="btn-m fr" data-testid="save-excel" onClick={() => setExcelButton(true)}>
          엑셀저장
        </button>
      </div>
      <ListForm
        list={list}
        pagination={pagination}
        fnGoSourceDetail={fnGoSourceDetail}
        page={searchRequest.pageNumber}
        setPagingButton={setPagingButton}
        setSearchButton={setSearchButton}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />

      <PaymentSourceCreateModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        capitalUniqueKey={toJS(store.paymentCapital)}
        groupUniqueKey={toJS(store.customerGroup)} // 고객구분
        transactionMethod={searchTransactionMethodData()}
        searchProperty={fnCreatSearchPropertyOption}
        setListSearchButton={setSearchButton}
      />

      <PaymentSourceUpdateModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        searchRequestData={searchRequest}
        paginationData={pagination}
        setListSearchButton={setSearchButton}
        storeCapital={toJS(store.paymentCapital)}
        storeCustomerGroup={toJS(store.customerGroup)}
        payAmountType={searchPayAmountTypeData()}
        searchProperty={fnCreatSearchPropertyOption}
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaymentSourceList));
