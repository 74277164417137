import React, { Fragment, useRef } from "react";
import ReactToPrint from "react-to-print";
// import { observer, inject } from "mobx-react";
import { Modal, Button } from "@material-ui/core";

import * as DF from "./DonationForm";

// import * as CM from "../../common/Common";
/*
 * @desc  A4 용지 사이즈에 맞춰 영수증 생성하는 컴포넌트
 */
const DonationBillPrintForm = (props) => {
  return (
    <Fragment>
      {Array.isArray(props.selectedRows) ? (
        props.selectedRows.map((row, index) => {
          return (
            <DF.DonationBillPrintForm
              key={index}
              donationBill={row}
              isEachIssuingTarget={props.isEachIssuingTarget}
              sealImage={props.sealImage}
              donationTypeCode={props.donationTypeCode}
              donationTypeName={props.donationTypeName}
              donationForm={props.donationForm}
            />
          );
        })
      ) : (
        <DF.DonationBillPrintForm
          donationBill={props.selectedRows}
          isEachIssuingTarget={props.isEachIssuingTarget}
          sealImage={props.sealImage}
          donationTypeCode={props.donationTypeCode}
          donationTypeName={props.donationTypeName}
          donationForm={props.donationForm}
        />
      )}
    </Fragment>
  );
};

// 부가서비스 > 기부금영수증 > 기부금영수증 인쇄
// 렌더를 처리하는 메인 컴포넌트
const DonationBillPrintModal = ({ setSearchButton, open, setOpen, selectedRows, isEachIssuingTarget, sealImage, donationTypeCode, donationTypeName, donationForm }) => {
  //인쇄 시 지정영역 타겟팅을 위해 useRef 선언
  const printRef = useRef();

  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button
              className="fr btn-close"
              onClick={(e) => {
                setOpen(false);
                if (setSearchButton !== undefined) {
                  setSearchButton(true);
                }
              }}
              data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>기부금영수증 발행</h3>
          <div className="inforbox">
            <ul>
              <li>양식의 글씨가 미리보기에 나타나지 않는 경우 2~3회 다시 시도해주시기 바랍니다.</li>
              <li>값을 수정하여 출력하실 수 있으나, 변경된 값은 저장되지 않습니다.</li>
            </ul>
          </div>
          <ReactToPrint
            trigger={() => (
              <button className="btn-l" style={{ display: "block", marginLeft: "auto" }}>
                영수증 인쇄
              </button>
            )}
            content={() => printRef.current}
            copyStyles={true}
          />
          <div className="print-outer-box" ref={printRef}>
            <DonationBillPrintForm
              selectedRows={selectedRows}
              isEachIssuingTarget={isEachIssuingTarget}
              sealImage={sealImage}
              donationTypeCode={donationTypeCode}
              donationTypeName={donationTypeName}
              donationForm={donationForm}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DonationBillPrintModal;
