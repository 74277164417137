import React, { useState } from "react";
import BusinessInfo from "./BusinessInfo";
import CMSBusinessInfo from "./CMSBusinessInfo";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";

const Registrations = () => {
  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  return (
    <div data-testid="businessInfoTabDiv" id="businessInfoTabDiv">
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          data-testid="account-tab"
        >
          <Tab disableRipple id="businessInfoTab" value={0} label="빌링원플러스 업무 정보 및 설정" />
          <Tab disableRipple id="cmsBusinessInfoTab" value={1} label="CMS 업무 신청정보" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <BusinessInfo tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <CMSBusinessInfo tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default Registrations;
