import React, { useState, useEffect, Fragment } from "react";

import { Table, TableBody, TableCell, TableRow, Button, Modal } from "@material-ui/core";
// import TableForm from "../../template/TableForm";
// import * as CM from "../../common/Common";

const ChargeModal = ({ open, setOpen, financialInstitutes }) => {
  // 모달 클로즈
  const handleClose = () => setOpen(false);

  const [financialRepresentativeInstitutes, setFinancialRepresentativeInstitutes] = useState([]);

  useEffect(() => {
    if (financialInstitutes.filter) {
      let sortedList = financialInstitutes.filter((institute) => institute.cmsRealtimeYn && institute.fncInsCd === institute.repFncInsCd).sort((a, b) => a.cmsRealtimeOrder - b.cmsRealtimeOrder);
      if (sortedList.length % 8 > 0) {
        const defaultFinancialInstitute = { fncInsNm: "", fncInsSnm: "", cmsRealtimeFee: "" };
        sortedList = sortedList.concat(Array.apply(null, Array(8 - (sortedList.length % 8))).map(() => defaultFinancialInstitute));
      }
      console.log(sortedList);
      setFinancialRepresentativeInstitutes(sortedList);
    }
  }, [financialInstitutes]);

  const renderTableBody = () => {
    let i = 0,
      size = Math.floor(financialRepresentativeInstitutes.length / 8);
    let result = [];
    for (; i < size; i++) {
      result.push(
        <Fragment key={i}>
          <TableRow>
            <TableCell className="th">금융기관명</TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 1].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 1].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 1].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 1].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 2].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 2].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 2].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 2].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 3].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 3].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 3].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 3].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 4].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 4].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 4].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 4].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 5].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 5].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 5].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 5].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 6].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 6].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 6].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 6].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
            <TableCell className="th">
              {financialRepresentativeInstitutes[i * 8 + 7].fncInsSnm}
              {financialRepresentativeInstitutes[i * 8 + 7].cmsRealtimeOpertype === "A" || !financialRepresentativeInstitutes[i * 8 + 7].cmsRealtimeOpertype ? null : (
                <span className="check-astro">{financialRepresentativeInstitutes[i * 8 + 7].cmsRealtimeOpertype === "C" ? "*" : "**"}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">수수료</TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 1].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 2].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 3].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 4].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 5].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 6].cmsRealtimeFee}
            </TableCell>
            <TableCell className="td" align="center">
              {financialRepresentativeInstitutes[i * 8 + 7].cmsRealtimeFee}
            </TableCell>
          </TableRow>
        </Fragment>
      );
    }
    return result;
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>금융기관 수수료</h3>
          <Table>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
          <div className="inforbox2">
            ※ 부가가치세 없음 <br />※ 실시간 중계수수료 별도 (14 ~ 40원/건, 부가세 별도) <br /> <span className="check-astro">*</span> : 평일만 이용가능 <br /> <span className="check-astro">**</span>{" "}
            : 월~토요일 이용가능(공휴일 제외)
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChargeModal;
