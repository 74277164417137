import { Checkbox, FormControlLabel, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CommonData from "../../common/CommonDataName";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import AccountCheckData from "./AccountCheckData";

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">신청구분</label>
        <FormControlLabel
          control={
            <Checkbox
              value={searchRequest.isNewAccountIncluded}
              checked={searchRequest.isNewAccountIncluded}
              onChange={handleSearchFormChange("isNewAccountIncluded")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-new-account",
              }}
            />
          }
          label="신규신청"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={searchRequest.isClosingAccountIncluded}
              checked={searchRequest.isClosingAccountIncluded}
              onChange={handleSearchFormChange("isClosingAccountIncluded")}
              inputProps={{
                "aria-label": "primary checkbox",
                "data-testid": "check-closing-account",
              }}
            />
          }
          label="해지신청"
        />
        <label className="label-l">신청일자</label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.startDate}
          handleChange={handleSearchFormChange("startDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.endDate}
          handleChange={handleSearchFormChange("endDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <label className="label-l">검색어입력</label>
        <Select native value={searchRequest.term} onChange={handleSearchFormChange("term")}>
          <option value="customerName">고객명</option>
          <option value="payerNo">납부자번호</option>
          <option value="customerMobile">전화번호</option>
        </Select>
        <TextField className="w120" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" />
        <button className="search-button" data-testid="search-history" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["13%", "8%", "9%", "9%", "10%", "8%", "12%", "8%", "7%", "7%"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          arrData={[
            //{ id: 'sn', label: '순번', sortable: false },
            { id: "applyDatetime", label: "신청일시", sortable: true },
            {
              id: "realtimeTransactionType",
              label: "신청구분",
              sortable: true,
            },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "customerMobile", label: "전화번호", sortable: true },
            { id: "financialInstituteName", label: "금융기관", sortable: true },
            { id: "accountNo", label: "계좌번호", sortable: false },
            {
              id: "depositorIdentificationNoFirst7",
              label: "예금주생년월일",
              sortable: false,
            },

            { id: "messageResultCode", label: "처리결과", sortable: false },

            { id: "evidenceFileType", label: "동의자료 구분", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.accountList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.accountList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  {/*<TableCell align="center" component="th" scope="row" padding="none">
                    {props.pagination.total - props.pagination.rowsPerPage * props.searchRequest.pageNumber - index}
              </TableCell>*/}
                  <TableCell align="center" style={{ whiteSpace: "pre-wrap" }}>
                    {CM.cfnDateFormat(row.applyDatetime)}
                  </TableCell>
                  <TableCell align="center">{CommonData.accountRequestHistoryStatus(row.wayToCreateAccount, row.realtimeTransactionType)}</TableCell>
                  <TableCell align="center">{row.customerName || ""}</TableCell>
                  <TableCell align="center">{row.payerNo || ""}</TableCell>
                  <TableCell align="center">{CM.cfnAddtDashToPhoneNumber(row.customerMobile || "")}</TableCell>
                  <TableCell align="center">{CM.cfnMatchBankName(row.financialInstituteCode || "", props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{row.accountNo}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.depositorIdentificationNo || "")}</TableCell>
                  <TableCell align="center">
                    {row.messageResultCodeMessage === null ? (
                      <Fragment>
                        처리중
                        <div className="d-inline-flex">
                          <button className="btn-m table-top-button" data-testid="update-account-inquiry" onClick={() => props.setSearchButton(true)}>
                            갱신
                          </button>
                        </div>
                      </Fragment>
                    ) : (
                      row.messageResultCodeMessage
                    )}
                  </TableCell>
                  <TableCell align="center">{CommonData.evidenceFileType(row.evidenceFileType)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function RegisterRequestHistories(props) {
  const { tabIndex, store } = props;

  const [searchRequest, setSearchRequest] = useState(AccountCheckData.historySearchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [accountList, setAccountList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(AccountCheckData.paginationData());

  //금융기관명 매칭을 위한 금융기관 목록
  const [financialInstitutes, setFinancialInstitutes] = useState(toJS(store.financialInstitutes));

  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await store.axiosFinancialInstitutes();
      setFinancialInstitutes(institutesList);
    };

    if (tabIndex === 2 && CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();
  }, [tabIndex, financialInstitutes, store, setFinancialInstitutes]);

  useEffect(() => {
    /*
     * @desc 계좌실명조회 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      if (tempObj.term === "customerName") {
        tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.payerNo = "";
        tempObj.customerMobile = "";
      } else if (tempObj.term === "payerNo") {
        tempObj.customerName = "";
        tempObj.payerNo = CM.cfnNvl(tempObj.keyword, "");
        tempObj.customerMobile = "";
      } else if (tempObj.term === "customerMobile") {
        tempObj.customerName = "";
        tempObj.payerNo = "";
        tempObj.customerMobile = CM.cfnNvl(tempObj.keyword, "");
      }

      tempObj.endDate = CM.cfnConvertDateToString(searchRequest.endDate);
      tempObj.startDate = CM.cfnConvertDateToString(searchRequest.startDate);

      return tempObj;
    };

    /*
     * @desc    실시간실명조회 결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/extra/account-registration/results?customerMobile=${search.customerMobile}&customerName=${search.customerName}&endDate=${search.endDate}&isClosingAccountIncluded=${search.isClosingAccountIncluded}&isNewAccountIncluded=${search.isNewAccountIncluded}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&payerNo=${search.payerNo}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&startDate=${search.startDate}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          (error) => {
            CM.cfnAlert(
              error && error.response && error.response.data && typeof error.response.data === "string"
                ? error.response.data
                : error && error.response && error.response.data && typeof error.response.data.message === "string"
                ? error.response.data.message
                : "현재 요청량이 많아 처리가 지연되고 있습니다. 잠시 후에 다시 시도해주시기 바랍니다.",
              () => {}
            );
            setSearchButton(false);
          }
        );
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      setOriginalList(resultData);
      setAccountList(resultList);
      setPagination(AccountCheckData.paginationData(resultData));
      setSearchButton(false);
    }

    // 실행 영역
    if (tabIndex === 2 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    실시간 실명조회 결과조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "startDate" || name === "endDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else if (e.target.type === "checkbox") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.checked,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
      </div>
      <ListForm
        accountList={accountList}
        pagination={pagination}
        financialInstitutes={financialInstitutes}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
        setSearchButton={setSearchButton}
      />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(RegisterRequestHistories));
