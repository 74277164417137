import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";
import RegistrationDetailModal from "./RegistrationDetailModal";
// 검색 데이터
function searchData() {
  return {
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "DESC",
    sortProperty: "payerNo",
  };
}

/*
 * @desc  목록 컴포넌트 생성
 */
function RegistrationsSummaryForm(props) {
  const registrations = props.registrationsSummary;
  const headRowOne = [
    { value: "수신일자", rowSpan: 2 },
    { value: "접수일자", rowSpan: 2 },
    { value: "등록요청", colSpan: 3 },
    { value: "처리결과", colSpan: 3 },
    { value: "미처리고객", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "총건수" }, { value: "신규등록" }, { value: "해지등록" }, { value: "신규등록" }, { value: "해지등록" }, { value: "처리오류" }];
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["15%", "15%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"])}
        <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />
        <TableBody>
          <TableRow>
            <TableCell align="center">{CM.cfnIsNotEmpty(registrations.receivedDate) ? CM.cfnDateFormat(registrations.receivedDate.toString()) : "-"}</TableCell>
            <TableCell align="center">{CM.cfnIsNotEmpty(registrations.confirmedDate) ? CM.cfnDateFormat(registrations.confirmedDate.toString()) : "-"}</TableCell>
            <TableCell align="center">{registrations.totalRequestTargets}</TableCell>
            <TableCell align="center">{registrations.newAccountRequestTargets}</TableCell>
            <TableCell align="center">{registrations.closingAccountRequestTargets}</TableCell>
            <TableCell align="center">{registrations.notProcessedResultCount > 0 ? "-" : registrations.newAccountResultCount}</TableCell>
            <TableCell align="center">{registrations.notProcessedResultCount > 0 ? "-" : registrations.closedAccountResultCount}</TableCell>
            <TableCell align="center">
              {(CM.cfnIsNotEmpty(registrations.newAccountResultErrorCount) ? Number(registrations.newAccountResultErrorCount) : 0) +
                (CM.cfnIsNotEmpty(registrations.closedAccountResultErrorCount) ? Number(registrations.closedAccountResultErrorCount) : 0)}
            </TableCell>
            <TableCell align="center">{registrations.notProcessedResultCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  미처리 고객 목록 접수 버튼
 */
function RegistrationListForm(props) {
  const handleRegistrationClick = (e, row) => {
    props.setFile(row);
    props.setOpen(true);
  };

  return (
    <div>
      <h4>미처리 고객수 {props.list.length}명</h4>
      <div className="inforbox2">
        <p>
          {" "}
          <li> 업무담당자 분께서 판단 후 승인 또는 오류로 처리해야 할 대상입니다. </li>{" "}
        </p>
      </div>
      <Table>
        {CM.cfnCompColGroup(["auto"])}
        <TableForm.compTableHead arrData={["납부자번호", "예금주생년월일\n(사업자번호)", "휴대전화번호", "금융기관", "계좌번호", "접수구분", "접수"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">{row.payerNo}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.depositorIdentificationNumber)}</TableCell>
                  <TableCell align="center">{CM.cfnIsNotEmpty(row.customerMobile) ? CM.cfnAddtDashToPhoneNumber(row.customerMobile) : "-"}</TableCell>
                  <TableCell align="center">{row.financialInstituteName}</TableCell>
                  <TableCell align="center">{CM.cfnMasking(row.accountNo)}</TableCell>
                  <TableCell align="center">{row.receiptType}</TableCell>
                  <TableCell align="center">
                    {row.receiptType === "계좌(변경)해지" ? (
                      "-"
                    ) : (
                      <button className="search-button" onClick={(e) => handleRegistrationClick(e, row)}>
                        {row.receiptType}
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}
/*
 * @desc  처리완료 목록 컴포넌트 생성
 */
function RegistrationResultListForm(props) {
  //엑셀다운로드
  const fnSavedExcel = () => {
    let url = `/api/customer/receipt/account/financial-institute/registrations/detailed/excel`;
    url += `?fileUniqueKey=${props.registrations.fileUniqueKey}&sortProperty=PAYER_NO&sortDirection=ASC`;
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  return (
    <div>
      <h4>처리완료 {props.list.length}명 </h4>
      <div className="inforbox2">
        <li> 업무담당자 분께서 처리하셨거나, 시스템에서 자동으로 처리된 내역입니다. (해지 건은 모두 자동으로 처리) </li>
      </div>
      <button className="btn-m fr" data-testid="save-excel" onClick={fnSavedExcel}>
        {" "}
        엑셀저장{" "}
      </button>
      <Table>
        {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "15%", "auto", "auto"])}
        <TableForm.compTableHead arrData={["고객명", "예금주생년월일\n(사업자번호)", "납부자번호", "접수구분", "휴대전화", "금융기관", "계좌번호", "처리결과"]} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.depositorIdentificationNumber)}</TableCell>
                  <TableCell align="center">{row.payerNo}</TableCell>
                  <TableCell align="center">{row.receiptType}</TableCell>
                  <TableCell align="center">{CM.cfnIsNotEmpty(row.depositorTelephone) ? CM.cfnAddtDashToPhoneNumber(row.depositorTelephone) : "-"}</TableCell>
                  <TableCell align="center">{row.financialInstituteName}</TableCell>
                  <TableCell align="center">{CM.cfnMasking(row.accountNo)}</TableCell>
                  <TableCell align="center">{row.fileResultCodeMessage}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}
/*
 * @desc  메인 컴포넌트
 */
function RegistrationDetail(props) {
  // 이전 page로부터 전달받은 값
  let location = useLocation();
  let history = useHistory();

  //파라미터 State
  const [file, setFile] = useState({});

  const [open, setOpen] = useState(false);
  const [registrations] = useState(location.state.file);
  const [registrationsSummary, setRegistrationsSummary] = useState({});
  const [searchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [registrationList, setRegistrationList] = useState([]); // table 데이터
  const [registrationResultList, setRegistrationResultList] = useState([]); //

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    /*
     * @desc    처리결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `/api/customer/receipt/account/financial-institute/registrations/${registrations.fileUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    summary 처리결과조회 Request
     */
    const axiosRegistrationsSummaryList = (fileUniqueKey) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/financial-institute/registrations/summary/${fileUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      let resultData = await axiosList(search);
      const resultRegistrationsData = await axiosRegistrationsSummaryList(registrations.fileUniqueKey);

      if (CM.cfnIsEmpty(resultData)) {
        resultData = {
          receiptAccountNotProcessedFromFinancialInstitutes: [],
          receiptAccountProcessedFromFinancialInstitutes: [],
        };
      }

      //등록요청내용
      setRegistrationsSummary(resultRegistrationsData);

      //미처리
      setRegistrationList(resultData.receiptAccountNotProcessedFromFinancialInstitutes);

      //처리
      setRegistrationResultList(resultData.receiptAccountProcessedFromFinancialInstitutes);
      setFlag(false);
    }

    startAxios(searchRequest);
  }, [searchRequest, open, registrations.fileUniqueKey, registrations.filename, flag]);

  const fnSubmitRegistrations = (row) => {
    return new Promise((resolve) => {
      let url = `api/customer/receipt/account/financial-institute/registrations/detailed?accountApplicationUniqueKey=${row.accountApplicationUniqueKey}&fileUniqueKey=${row.fileUniqueKey}`;
      const data = {
        accountApplicationUniqueKey: row.accountApplicationUniqueKey,
        fileUniqueKey: row.fileUniqueKey,
      };
      CM.cfnAxios(url, "post", data, (status, data) => {
        resolve(data);
        setFlag(true);
      });
    });
  };

  // 목록 버튼을 눌렀을때 이동
  const goRegistrationListPage = () => {
    history.push("/customer/registrations", { searchRequest: history?.location?.state?.searchRequest, tabIndex: 0 });
  };

  return (
    <div>
      <div>
        <RegistrationsSummaryForm registrationsSummary={registrationsSummary} />
        <div className="table-bottom-area">
          <button type="button" className="btn-m" onClick={goRegistrationListPage}>
            목록
          </button>
        </div>
        <RegistrationListForm list={registrationList} file={file} setFile={setFile} setOpen={setOpen} fnSubmitRegistrations={fnSubmitRegistrations} />
        <RegistrationResultListForm list={registrationResultList} registrations={registrations} />
      </div>
      <RegistrationDetailModal open={open} setOpen={setOpen} file={file} />
    </div>
  );
}

export default RegistrationDetail;
