import React, { useState } from "react";

import { Modal, Button, Select, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

import DonationData from "./DonationBillData"
import * as CM from "../../common/Common";

// 부가서비스 > 기부금영수증 > 기부금단체 근거법령 모달
// 렌더를 처리하는 메인 컴포넌트
const BasedLawModal = ({ open, setOpen, handleSelectRaw }) => {
  
  //필요 state 선언
  const [filterCondition, setFilterCondition] = useState(""); //상단 필터링 조건 제어

  /*
   * @desc  필터 조건 변경 이벤트 핸들러
   */
  const handleChangeFilterCondition = e => {
    setFilterCondition(e.target.value);
  }

  /*
   * @desc  행 클릭 이벤트 핸들러
   */
  const handleRowClick = async row => {
    await handleSelectRaw(row);
    setOpen(false);
  }

  /*
   * @desc  선택된 필터링 조건에 따라 보여줄 근거법령 목록 제어
   */
  const filteredRows = DonationData.basedLawList.filter(row => {
    let flag = true;

    if(CM.cfnIsNotEmpty(filterCondition)){
      flag = row.code === filterCondition ? true : false;
    }

    return flag;
  });


  // 화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={e => setOpen(false)} data-testid="basedLawModal-close-button">
              {""}
            </Button>
          </div>
          <h3>기부금 단체코드(근거법령)</h3>
          <div className="search-area">
            <label className="label-l">기부금 구분</label>
            <Select
              native
              value={filterCondition}
              onChange={handleChangeFilterCondition}
              inputProps={{ "data-testid": "basedLaw-type-select" }}
            >
              <option value="">전체</option>
              <option value="10">법정기부금</option>
              <option value="20">정치자금기부금</option>
              <option value="40">지정기부금</option>
              <option value="41">지정기부금(종교단체)</option>
              <option value="42">우리사주조합기부금</option>
            </Select>
            <label className="label-l">기부금코드</label>
            <Select
              native
              value={filterCondition}
              onChange={handleChangeFilterCondition}
              inputProps={{ "data-testid": "basedLaw-code-select" }}
            >
              <option value="">전체</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
            </Select>
          </div>
          <Table>
            {CM.cfnCompColGroup(["15%", "15%", "auto", "10%", "15%", "10%"])} 
            <TableHead>
              <TableRow>
                <TableCell className="th">
                  기부금 단체
                </TableCell>
                <TableCell className="th">
                  관련법령
                </TableCell>
                <TableCell className="th">
                  참고 법령 및 세부내용
                </TableCell>
                <TableCell className="th">
                  기부금단체코드
                </TableCell>
                <TableCell className="th">
                  기부금 구분
                </TableCell>
                <TableCell className="th">
                  기부금코드
                </TableCell>
              </TableRow>
            </TableHead> 
            <TableBody>
              {filteredRows
                .filter(rows => rows !== {})
                .map((row, index) => {
                  return (
                    <TableRow 
                      hover 
                      className="show-detail"
                      onClick={e => handleRowClick(row)}
                      data-testid={`donation-basedLaw-selectRow-${index}`}
                      key={index}
                    >
                      <TableCell className="td" align="center" style={{whiteSpace: row.content.indexOf("\n") === -1 ? "" : "pre-line"}}>
                        {row.group}
                      </TableCell> 
                      <TableCell className="td" align="center" style={{whiteSpace: row.content.indexOf("\n") === -1 ? "" : "pre-line"}}>
                        {row.basedLaw}
                      </TableCell>
                      <TableCell className="td" style={{whiteSpace: row.content.indexOf("\n") === -1 ? "" : "pre-line"}}>
                        {row.content}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {row.basedLawCode}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {row.type}
                      </TableCell>                 
                      <TableCell className="td" align="center">
                        {row.code}
                      </TableCell>
                    </TableRow>
                  )
              })}                                 
            </TableBody>
          </Table>
          ※ 해당 항목을 클릭 해주세요
        </div>
      </div>
    </Modal>
  );
};

export default BasedLawModal;
