import { Checkbox, FormControl, FormControlLabel, FormGroup, Input, Radio, RadioGroup, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import DonationData from "./DonationBillData";

//기부금영수증 > 기부금영수증 양식관리 > 기본정보
export const BasicInformation = ({ donationForm, handleDonationFormChange }) => {
  return (
    <div>
      <h4>기부금영수증 기본정보</h4>
      <Table>
        {CM.cfnCompColGroup(["14%", "18%", "14%", "18%", "14%", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th" rowSpan={2}>
              일련번호체계
            </TableCell>
            <TableCell className="td" colSpan={7}>
              <FormControlLabel
                checked={donationForm.serialNumberType === "EN" ? true : false}
                value={"EN"}
                onChange={handleDonationFormChange("serialNumberType")}
                control={<Radio color="primary" />}
                label="고정값+숫자조합"
                data-testid="donationBill-serialNumberType-en-radio"
              />
              <span className="label-l">고정내용(최대20자)</span>
              <Input
                className="w130"
                value={donationForm.serialNoPrefix || ""}
                onChange={handleDonationFormChange("serialNoPrefix")}
                disabled={donationForm.serialNumberType !== "EN" ? true : false}
                inputProps={{ "data-testid": "donationBill-serialNoPrefix-en-input", maxLength: 20 }}
              />
              <span className="label-l">일련번호 자릿수</span>
              <CF.SelectForm
                value={donationForm.serialNoDigit || ""}
                handleChange={handleDonationFormChange("serialNoDigit")}
                arrayOption={DonationData.numSerialNoDigit}
                disabled={donationForm.serialNumberType !== "EN" ? true : false}
                customProps={{ inputProps: { "data-testid": "donationBill-serialNoDigit-en-select" } }}
              />
              <div className="infobox2 margin-left-20">예) 고정값(결제원) + 일련번호 숫자(3)자리 ⇒ 결제원001, 고정값(KFTC) + 일련번호 숫자 (3)자리 ⇒ KFTC001</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="td" colSpan={7}>
              <FormControlLabel
                checked={donationForm.serialNumberType === "NUM" ? true : false}
                value={"NUM"}
                onChange={handleDonationFormChange("serialNumberType")}
                control={<Radio color="primary" />}
                label="숫자일련번호(예:00001)"
                data-testid="donationBill-serialNumberType-num-radio"
              />
              <span className="label-l">일련번호 자릿수</span>
              <CF.SelectForm
                value={donationForm.serialNoDigit || ""}
                handleChange={handleDonationFormChange("serialNoDigit")}
                arrayOption={DonationData.numSerialNoDigit}
                disabled={donationForm.serialNumberType === "EN" ? true : false}
                customProps={{ inputProps: { "data-testid": "donationBill-serialNoDigit-no-select" } }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              기부유형(개인)
              <CF.TooltipForm
                contents="- 법정(10) : 소득세법 제34조제2항, 법인세법 제24조제2항<br/>
                    - 정치자금(20) : 조세특례제한법 제76조<br/>
                    - 지정(40) : 소득세법 제34조제1항(종교단체 기부금 제외), 법인세법 제24조제1항<br/>
                    - 종교단체(41) : 소득세법 제34조제1항 중 종교단체기부금<br/>
                    - 우리사주(42) : 조세특례제한법 제88조의4<br/>
                    - 공제제외(50) : 필요경비(손금) 및 세액공제 대상에 해당되지 않는 기부금"
              />
            </TableCell>
            <TableCell className="td" colSpan={7}>
              <CF.RadioGroupForm
                name=""
                onChange={handleDonationFormChange("personalDonationType")}
                value={donationForm.personalDonationType || ""}
                testId=""
                option={DonationData.personalDonationTypeOptions}
                row={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              기부유형(사업자)
              <CF.TooltipForm
                contents="- 법정(10) : 소득세법 제34조제2항, 법인세법 제24조제2항<br/>
                    - 정치자금(20) : 조세특례제한법 제76조<br/>
                    - 지정(40) : 소득세법 제34조제1항(종교단체 기부금 제외), 법인세법 제24조제1항<br/>
                    - 종교단체(41) : 소득세법 제34조제1항 중 종교단체기부금<br/>
                    - 우리사주(42) : 조세특례제한법 제88조의4<br/>
                    - 공제제외(50) : 필요경비(손금) 및 세액공제 대상에 해당되지 않는 기부금"
              />
            </TableCell>
            <TableCell className="td" colSpan={7}>
              <CF.RadioGroupForm
                name=""
                onChange={handleDonationFormChange("businessDonationType")}
                value={donationForm.businessDonationType}
                testId=""
                option={DonationData.businessDonationTypeOptions}
                row={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">국세청 제출 담당자</TableCell>
            <TableCell className="td" align="center">
              <Input
                className="w100p"
                value={donationForm.managerName || ""}
                onChange={handleDonationFormChange("managerName")}
                inputProps={{ "data-testid": "donatinoBill-managerName-input", maxLength: 30 }}
              />
            </TableCell>
            <TableCell className="th">연락처</TableCell>
            <TableCell className="td" align="center">
              <FormControl>
                <Input
                  className="w100p"
                  value={donationForm.managerTelephone || ""}
                  onChange={handleDonationFormChange("managerTelephone")}
                  inputComponent={CF.MaskedField}
                  inputProps={{
                    mask: "mobile",
                    "data-testid": "donationBill-managerContactNo-input",
                    maxLength: 30,
                  }}
                />
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">기부내용</TableCell>
            <TableCell className="td" colSpan={7}>
              <Input style={{ width: "95%" }} value={donationForm.donationContents || ""} onChange={handleDonationFormChange("donationContents")} inputProps={{ "data-testid": "", maxLength: 30 }} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

//기부금영수증 > 기부금영수증 양식관리 > 단체정보
export const GroupInformation = ({ donationForm, handleDonationFormChange, handleClickSearchLawButton, handleAddressModal, sealImage, handleClickSearchSealImageButton }) => {
  return (
    <div>
      <h4>기부금 단체정보</h4>
      <Table>
        {CM.cfnCompColGroup(["14%", "18%", "14%", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">기부금단체명</TableCell>
            <TableCell className="td">
              <Input className="w100p" value={donationForm.instituteName || ""} onChange={handleDonationFormChange("instituteName")} inputProps={{ "data-testid": "", maxLength: 40 }} />
            </TableCell>
            <TableCell className="th">주민번호(사업자번호)</TableCell>
            <TableCell className="td" colSpan={3}>
              <div className="d-flex">
                <Input
                  value={CM.cfnIdentificationNoFormatOnChange(donationForm.identificationNo) || ""}
                  onChange={handleDonationFormChange("identificationNo")}
                  inputProps={{ "data-testid": "", maxLength: 14 }}
                />
                <FormGroup name="position" row>
                  <FormControlLabel
                    value={donationForm.isIdenticalGroup || ""}
                    control={<Checkbox checked={donationForm.isIdenticalGroup ? true : false} onChange={handleDonationFormChange("isIdenticalGroup")} color="primary" />}
                    label="기관정보와 동일"
                    data-testid="donationForm-copy-groupInfo-checkbox"
                  />
                </FormGroup>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">기부금단체 직인등록</TableCell>
            <TableCell className="td" colSpan={2}>
              <button className="btn-s" onClick={handleClickSearchSealImageButton} data-testid="donationBill-sealImage-modal-open">
                {sealImage && sealImage.fileName ? "이미지 확인" : "이미지 등록"}
              </button>
              <label style={{ marginLeft: "10px" }}>* 100KB 이하 파일만 가능합니다</label>
            </TableCell>
            <TableCell className="th">기부금단체 직인 인쇄</TableCell>
            <TableCell className="td" colSpan={2}>
              <FormControl component="fieldset">
                <RadioGroup value={donationForm.sealPrintYn || ""} onChange={handleDonationFormChange("sealPrintYn")} data-testid="donationBill-sealPrintYn-radio" row>
                  <FormControlLabel value={"true"} checked={donationForm.sealPrintYn} control={<Radio color="primary" />} label="인쇄" />
                  <FormControlLabel value={"false"} checked={!donationForm.sealPrintYn} control={<Radio color="primary" />} label="인쇄안함" />
                </RadioGroup>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">기부금단체 근거법령</TableCell>
            <TableCell className="td" colSpan={5}>
              <label className="label-l">관련 법령</label>
              <Input className="w300" value={donationForm.basedLaw || ""} inputProps={{ "data-testid": "" }} />
              <label className="label-l">근거 법령 코드</label>
              <Input className="w140" value={donationForm.basedLawCode || ""} inputProps={{ "data-testid": "" }} />
              <button className="btn-s" onClick={handleClickSearchLawButton} data-testid="donationBill-basedLaw-modal-open">
                찾기
              </button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">주소</TableCell>
            <TableCell className="td" colSpan={"5"}>
              <Input
                name="zipCode"
                style={{ width: "10%" }}
                placeholder="우편번호"
                value={donationForm.zipCode || ""}
                onChange={handleDonationFormChange("zipCode")}
                inputProps={{ "data-testid": "donationBill-zipcode-input", maxLength: 6 }}
              />
              <button className="btn-s" onClick={(e) => handleAddressModal("")} data-testid="donationBill-search-address-button" style={{ marginRight: "10px" }}>
                조회
              </button>
              <Input
                name="address"
                style={{ width: "40%", marginLeft: "10px" }}
                placeholder="주소"
                value={donationForm.address || ""}
                onClick={(e) => handleAddressModal("")}
                onChange={handleDonationFormChange("address")}
                inputProps={{ "data-testid": "donationBill-address-input", maxLength: 80 }}
              />
              <Input
                name="addressDetail"
                style={{ width: "35%", marginLeft: "10px" }}
                placeholder="상세주소"
                value={donationForm.addressDetail || ""}
                onChange={handleDonationFormChange("addressDetail")}
                inputProps={{ "data-testid": "donationBill-addressDetail-input", maxLength: 100 }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

//기부금영수증 > 기부금영수증 양식관리 > 모집처
export const RaiserInformation = ({ donationForm, handleDonationFormChange, handleAddressModal }) => {
  return (
    <div>
      <h4>기부금 모집처(언론기관 등)</h4>
      <Table>
        {CM.cfnCompColGroup(["14%", "18%", "14%", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">기부금모집처명</TableCell>
            <TableCell className="td">
              <Input className="w100p" value={donationForm.raiserInstituteName || ""} onChange={handleDonationFormChange("raiserInstituteName")} inputProps={{ "data-testid": "", maxLength: 40 }} />
            </TableCell>
            <TableCell className="th">주민번호(사업자번호)</TableCell>
            <TableCell className="td" colSpan="3">
              <div className="d-flex">
                <Input
                  value={CM.cfnIdentificationNoFormatOnChange(donationForm.raiserIdentificationNo) || ""}
                  onChange={handleDonationFormChange("raiserIdentificationNo")}
                  inputProps={{ "data-testid": "", maxLength: 14 }}
                />
                <FormGroup name="position" row>
                  <FormControlLabel
                    value={donationForm.isIdenticalRaiser || ""}
                    control={<Checkbox checked={donationForm.isIdenticalRaiser ? true : false} onChange={handleDonationFormChange("isIdenticalRaiser")} color="primary" />}
                    label="기관정보와 동일"
                    data-testid="donationForm-copy-raiserInfo-checkbox"
                  />
                </FormGroup>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">주소</TableCell>
            <TableCell className="td" colSpan="5">
              <Input
                name="raiserZipCode"
                style={{ width: "10%" }}
                placeholder="우편번호"
                value={donationForm.raiserZipCode || ""}
                onChange={handleDonationFormChange("raiserZipCode")}
                inputProps={{ "data-testid": "donationBill-raiserZipCode-input", maxLength: 6 }}
              />
              <button className="btn-s" onClick={(e) => handleAddressModal("raiser")} data-testid="donationBill-search-raiserAddress-button" style={{ marginRight: "10px" }}>
                조회
              </button>
              <Input
                name="raiserAddress"
                style={{ width: "40%", marginLeft: "10px" }}
                placeholder="주소"
                value={donationForm.raiserAddress || ""}
                onChange={handleDonationFormChange("raiserAddress")}
                inputProps={{ "data-testid": "donationBill-raiserAddress-input", maxLength: 80 }}
              />
              <Input
                name="raiserAddressDetail"
                style={{ width: "35%", marginLeft: "10px" }}
                placeholder="상세주소"
                value={donationForm.raiserAddressDetail || ""}
                onChange={handleDonationFormChange("raiserAddressDetail")}
                inputProps={{ "data-testid": "donationBill-raiserAddressDetail-input", maxLength: 100 }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

//기부금영수증 > 개별 발행 > 개인 발행 내용 목록
export const IndividualIssueTargetListForm = ({
  checkAllRow,
  handleCheckAllRowChange,
  isEachIssuingTarget,
  targetList,
  handleCheckChange,
  handleChangeInput,
  donationbillTypeName,
  donationbillTypeCode,
}) => {
  return (
    <Table>
      {CM.cfnCompColGroup(["5%", "5%", "10%", "8%", "8%", "5%", "auto", "auto"])}
      <TableForm.compCheckboxTableHead arrData={["발행여부", "유형", "코드", "년도", "월", "내용", "금액"]} checked={checkAllRow} value="" onChange={handleCheckAllRowChange} />
      <TableBody>
        {targetList.length === 0 ? (
          <TableForm.compEmptyTableRow colSpan={8} />
        ) : (
          targetList.map((row, index) => {
            return (
              <TableRow hover key={index}>
                <TableCell align="center">
                  {!row.isIssued && (
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`selectCheckbox-${index}`}
                      onChange={handleCheckChange}
                      inputProps={{
                        index,
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">{row.isIssued ? "발행" : "미발행"}</TableCell>
                <TableCell align="center">{donationbillTypeName /*유형*/}</TableCell>
                <TableCell align="center">{donationbillTypeCode /*코드*/}</TableCell>
                <TableCell align="center">
                  <Input
                    value={row.targetYear || ""}
                    disabled={row.isIssued || (!isEachIssuingTarget && index > 0)}
                    onChange={(e) => handleChangeInput(e, "targetYear", index)}
                    inputProps={{ maxLength: 4 }}
                    /*년도*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    value={row.targetMonth || ""}
                    disabled={row.isIssued || (!isEachIssuingTarget && index > 0)}
                    onChange={(e) => handleChangeInput(e, "targetMonth", index)}
                    inputProps={{ maxLength: 2 }}
                    /*월*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    value={row.donationContents || ""}
                    disabled={row.isIssued || (!isEachIssuingTarget && index > 0)}
                    // disabled={row.isIssued || (!searchRequest.isIssuedIndividually && index !== 0) ? true : false}
                    onChange={(e) => handleChangeInput(e, "donationContents", index)}
                    inputProps={{ maxLength: 30 }}
                    /*내용*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    className="editableCellInput"
                    value={CM.cfnAddCommaOnChange(row.donationAmount) || ""}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "donationAmount", index)}
                    endAdornment="원"
                    /*금액*/
                  />
                </TableCell>
              </TableRow>
            );
          })
        )}
        <TableRow>
          <TableCell className="th" colSpan={7} align="center">
            합계
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(
              targetList
                .map((row) => row.donationAmount)
                .filter((amount) => amount)
                .reduce((a, b) => a + b, 0)
            )}
            원
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

//기부금영수증 > 개별 발행 > 사업자 발행 내용 목록
export const CorpIssueTargetListForm = ({
  checkAllRow,
  handleCheckAllRowChange,
  isEachIssuingTarget,
  targetList,
  handleCheckChange,
  handleChangeInput,
  donationbillTypeName,
  donationbillTypeCode,
}) => {
  return (
    <Table>
      {CM.cfnCompColGroup(["5%", "5%", "10%", "8%", "8%", "5%", "auto", "10%", "10%", "auto"])}
      <TableForm.compCheckBoxDoubleRowTableHead
        rowOne={[
          { value: "발행여부", rowSpan: 2 },
          { value: "유형", rowSpan: 2 },
          { value: "코드", rowSpan: 2 },
          { value: "년도", rowSpan: 2 },
          { value: "월", rowSpan: 2 },
          { value: "내용", colSpan: 4 },
        ]}
        rowTwo={[{ value: "품명" }, { value: "수량" }, { value: "단가" }, { value: "금액" }]}
        checked={checkAllRow}
        value=""
        onChange={handleCheckAllRowChange}
      />
      <TableBody>
        {targetList.length === 0 ? (
          <TableForm.compEmptyTableRow colSpan={10} />
        ) : (
          targetList.map((row, index) => {
            return (
              <TableRow hover key={index}>
                <TableCell align="center">
                  {!row.isIssued && (
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`selectCheckbox-${index}`}
                      onChange={handleCheckChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">{row.isIssued ? "발행" : "미발행"}</TableCell>
                <TableCell align="center">{donationbillTypeName /*유형*/}</TableCell>
                <TableCell align="center">{donationbillTypeCode /*코드*/}</TableCell>
                <TableCell align="center">
                  <Input
                    value={row.targetYear}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "targetYear", index)}
                    inputProps={{ maxLength: 4 }}
                    /*년도*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    value={row.targetMonth}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "targetMonth", index)}
                    inputProps={{ maxLength: 2 }}
                    /*월*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    value={row.donationContents === null ? "" : row.donationContents}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "donationContents", index)}
                    inputProps={{ maxLength: 30 }}
                    /*내용/품명*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    className="editableCellInput"
                    value={CM.cfnAddCommaOnChange(row.donationQuantities) || ""}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "donationQuantities", index)}
                    /*수량*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    className="editableCellInput"
                    value={CM.cfnAddCommaOnChange(row.donationUnitPrice) || ""}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "donationUnitPrice", index)}
                    endAdornment="원"
                    /*단가*/
                  />
                </TableCell>
                <TableCell align="center">
                  <Input
                    className="editableCellInput"
                    value={CM.cfnAddCommaOnChange(row.donationAmount) || ""}
                    disabled={row.isIssued}
                    onChange={(e) => handleChangeInput(e, "donationAmount", index)}
                    endAdornment="원"
                    /*금액*/
                  />
                </TableCell>
              </TableRow>
            );
          })
        )}
        <TableRow>
          <TableCell className="th" colSpan={7} align="center">
            합계
          </TableCell>

          <TableCell className="td" colSpan={3} align="right">
            {CM.cfnAddComma(
              targetList
                .map((row) => row.donationAmount)
                .filter((amount) => amount)
                .reduce((a, b) => a + b, 0)
            )}
            원
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

//기부금영수증 > 기부금영수증 발행 모달 내 인쇄 양식
export const DonationBillPrintForm = ({ donationBill, idx, isEachIssuingTarget, sealImage, donationTypeCode, donationTypeName, donationForm }) => {
  const defaultContent = {
    donationTypeName: "",
    donationTypeCode: "",
    donationType: "",
    targetY4mmdd: "",
    donationContents: "",
    donationQuantities: "",
    donationUnitPrice: "",
    donationAmount: "",
  };
  const [customer, setCustomer] = useState({ customerName: "", identificationNo: "", customerAddress: "" });
  const [contents, setContents] = useState([
    { donationTypeName: "", donationTypeCode: "", donationType: "", targetYear: "", targetY4mmdd: "", donationContents: "", donationQuantities: "", donationUnitPrice: "", donationAmount: "" },
  ]);
  useEffect(() => {
    setCustomer({
      ...donationBill.donationbillCustomerSummary,
      identificationNo: CM.cfnIdentificationNoFormat(donationBill.donationbillCustomerSummary.identificationNo, true),
      customerAddress:
        (donationBill.donationbillCustomerSummary.customerZipCode ? "(" + donationBill.donationbillCustomerSummary.customerZipCode.trim() + ") " : "") +
        donationBill.donationbillCustomerSummary.customerAddress +
        " " +
        donationBill.donationbillCustomerSummary.customerAddressDetail,
    });
    let list = null;
    if (isEachIssuingTarget) {
      list = donationBill.donationbillIssueTargets.map((target) => ({
        ...target,
        donationTypeName,
        donationTypeCode,
        donationType: "금전",
        targetY4mmdd: (target.targetYear || "") + "." + (target.targetMonth || "") + "." + (target.targetDay || "01"),
      }));
    } else {
      let sum = 0;
      for (const row of donationBill.donationbillIssueTargets) {
        sum += row.donationAmount;
      }
      list = [
        {
          donationTypeName,
          donationTypeCode,
          donationType: "금전",
          targetY4mmdd: donationBill?.donationbillIssueTargets[0]?.targetYear + "." + donationBill?.donationbillIssueTargets[0]?.targetMonth + "." + donationBill?.donationbillIssueTargets[0]?.targetDay,
          donationContents: donationBill?.donationbillIssueTargets[0]?.donationContents,
          donationAmount: sum,
          donationQuantities: 1,
          donationUnitPrice: sum,
        },
      ];
    }
    const size = list.length;
    for (let i = 0; i < 13 - size; i++) {
      list.push(defaultContent);
    }
    setContents(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationBill, donationTypeCode, donationTypeName, isEachIssuingTarget]);

  const handleChangeContents = (i, name, value) => {
    setContents(
      contents.map((content, index) => {
        if (index === i) {
          return { ...content, [name]: value };
        } else {
          return content;
        }
      })
    );
  };

  return (
    <div className="donationbill-print-area">
      <div className="donationbill-print-inner">
        <div className="donationbill-bottom">
          <span>■ 소득세법 시행규칙 [별지 제45호의2서식] (개정 2020. 3. 13.)</span>
        </div>
        <h2 style={{ textAlign: "center" }}>기부금영수증</h2>
        <table className="donationtableSerial">
          {CM.cfnCompColGroup(["auto", "auto"])}
          <tbody>
            <tr>
              <th align="center">일련번호</th>
              <th align="right">{donationBill.donationbillCustomerSummary.serialNumber}</th>
            </tr>
          </tbody>
        </table>

        <div className="fl w100p">{/* <span>※아래의 작성방법을 읽고 작성하여 주시기 바랍니다.</span> */}</div>

        <table className="donationtable">
          <tbody>
            <tr>
              <td colSpan={8} align="left" className="title">
                ① 기부자
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                성명
                <br />
                (법인명)
              </td>
              <td colSpan={3}>
                <input value={customer.customerName} type="text" className="rawForm" onChange={(e) => setCustomer({ ...customer, customerName: e.target.value })} />
              </td>
              <td colSpan={2}>
                주민등록번호 <br />
                (사업자등록번호)
              </td>
              <td colSpan={2}>
                <input value={customer.identificationNo} type="text" className="rawForm" onChange={(e) => setCustomer({ ...customer, identificationNo: e.target.value })} />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                주소
                <br />
                (소재지)
              </td>
              <td colSpan={7}>
                <input value={customer.customerAddress} type="text" className="rawForm left" onChange={(e) => setCustomer({ ...customer, customerAddress: e.target.value })} />
              </td>
            </tr>

            <tr className="space"></tr>
            <tr>
              <td colSpan={8} align="left" className="title">
                ② 기부금 단체
              </td>
            </tr>
            <tr>
              <td colSpan={1}>단체명</td>
              <td colSpan={3}>{donationForm.instituteName}</td>
              <td colSpan={2}>
                사업자등록번호
                <br />
                (고유번호)
              </td>
              <td colSpan={2}>{CM.cfnIdentificationNoFormat(donationForm.identificationNo)}</td>
            </tr>
            <tr>
              <td colSpan={1}>소재지</td>
              <td colSpan={3}>{(donationForm.zipCode ? "(" + donationForm.zipCode.trim() + ") " : "") + donationForm.address + " " + donationForm.addressDetail}</td>
              <td colSpan={2}>
                기부금공제대상 <br /> 기부금단체 근거법령
              </td>
              <td colSpan={2}>{donationForm.basedLaw}</td>
            </tr>

            <tr className="space"></tr>
            <tr>
              <td colSpan={8} align="left" className="title">
                ③ 기부금 모집처 (언론기관 등)
              </td>
            </tr>
            <tr>
              <td colSpan={1}>단체명</td>
              <td colSpan={3}>{donationForm.raiserInstituteName}</td>
              <td colSpan={2}>사업자등록번호</td>
              <td colSpan={2}>{CM.cfnIdentificationNoFormat(donationForm.raiserIdentificationNo)}</td>
            </tr>
            <tr>
              <td colSpan={1}>소재지</td>
              <td colSpan={7}>{(donationForm.raiserZipCode ? "(" + donationForm.raiserZipCode.trim() + ") " : "") + donationForm.raiserAddress + " " + donationForm.raiserAddressDetail}</td>
            </tr>

            <tr className="space"></tr>
            <tr>
              <td colSpan={8} align="left" className="title">
                ④ 기부내용
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>유형</td>
              <td rowSpan={2}>코드</td>
              <td rowSpan={2}>
                구분
                <br />
                (금전 또는 현물)
              </td>
              <td rowSpan={2}>연월일</td>
              <td colSpan={3} align="center">
                내용
              </td>
              <td rowSpan={2}>금액</td>
            </tr>
            <tr>
              <td>품명</td>
              <td>수량</td>
              <td>단가</td>
            </tr>
            {contents.map((content, i) => (
              <tr key={i}>
                <td>
                  <input value={content.donationTypeName /*유형*/} type="text" className="rawForm" onChange={(e) => handleChangeContents(i, "donationTypeName", e.target.value)} />
                </td>
                <td>
                  <input value={content.donationTypeCode /*코드*/} type="text" className="rawForm" onChange={(e) => handleChangeContents(i, "donationTypeCode", e.target.value)} />
                </td>
                <td>
                  <input value={content.donationType} type="text" className="rawForm" onChange={(e) => handleChangeContents(i, "donationType", e.target.value)} />
                  {/*구분*/}
                </td>
                <td>
                  <input value={`${content.targetY4mmdd}`/*연월일*/} type="text" className="rawForm" onChange={(e) => handleChangeContents(i, "targetY4mmdd", e.target.value)} />
                </td>
                <td>
                  <input value={content.donationContents /*품명*/} type="text" className="rawForm" onChange={(e) => handleChangeContents(i, "donationContents", e.target.value)} />
                </td>
                <td>
                  <input
                    value={content.donationQuantities ? CM.cfnAddComma(content.donationQuantities) : "" /*수량*/}
                    type="text"
                    className="rawForm"
                    onChange={(e) => handleChangeContents(i, "donationQuantities", e.target.value?.replace(/[^0-9]/g, ""))}
                  />
                </td>
                <td>
                  <input
                    value={content.donationUnitPrice ? CM.cfnAddComma(content.donationUnitPrice) : "" /*단가*/}
                    type="text"
                    className="rawForm"
                    onChange={(e) => handleChangeContents(i, "donationUnitPrice", e.target.value?.replace(/[^0-9]/g, ""))}
                  />
                </td>
                <td>
                  <input
                    value={content.donationAmount ? CM.cfnAddComma(content.donationAmount) : "" /*금액*/}
                    type="text"
                    className="rawForm"
                    onChange={(e) => handleChangeContents(i, "donationAmount", e.target.value?.replace(/[^0-9]/g, ""))}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={8}>
                <span>「소득세법」 제34조, 「조세특례제한법」 제76조ㆍ제88조의4 및 「법인세법」 제24조에 따른 기부금을 위와 같이 기부하였음을 증명하여 주시기 바랍니다.</span>
                <br />
                <span className="fr">
                  {donationBill.donationbillIssueTargets.length > 0
                    ? `${donationBill.donationbillIssueTargets[0].issueDate.substr(0, 4)} 년 ${Number(donationBill.donationbillIssueTargets[0].issueDate.substr(4, 2))} 월 ${Number(
                        donationBill.donationbillIssueTargets[0].issueDate.substr(6)
                      )} 일`
                    : `${new Date().getFullYear()} 년   ${new Date().getMonth() + 1} 월   ${new Date().getDate()} 일`}
                </span>
                <br />
                <span className="fr">
                  신청인{" "}
                  <input
                    defaultValue={customer.customerName}
                    type="text"
                    className="rawForm right"
                    style={{ width: "18ch" }}
                    onChange={(e) => setCustomer({ ...customer, customerName: e.target.value })}
                  />{" "}
                  (서명 또는 인)
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={8} style={{ position: "relative" }}>
                <span>위와 같이 기부금을 기부받았음을 증명합니다.</span>
                <br />
                <span className="fr">
                  {donationBill.donationbillIssueTargets.length > 0
                    ? `${donationBill.donationbillIssueTargets[0].issueDate.substr(0, 4)} 년 ${Number(donationBill.donationbillIssueTargets[0].issueDate.substr(4, 2))} 월 ${Number(
                        donationBill.donationbillIssueTargets[0].issueDate.substr(6)
                      )} 일`
                    : `${new Date().getFullYear()} 년   ${new Date().getMonth() + 1} 월   ${new Date().getDate()} 일`}
                </span>
                <br />
                {sealImage?.fileSrc && donationForm.sealPrintYn ? (
                  <img alt="직인" style={{ width: 60, height: 60, position: "absolute", right: "1ch", bottom: "2px" }} src={sealImage.fileSrc} title="직인" />
                ) : (
                  ""
                )}
                <span className="fr" style={{ marginTop: "50px", paddingRight: sealImage?.fileSrc && donationForm.sealPrintYn && "10ch" }}>
                  기부금 수령인 {donationForm.instituteName} {sealImage?.fileSrc && donationForm.sealPrintYn ? "" : " (서명 또는 인)"}
                </span>
              </td>
            </tr>
            <tr className="space" style={{ height: "20px" }}></tr>
            {/* <tr>
              <td colSpan={11} align="center">
                작 성 방 법
              </td>
            </tr>
            <tr>
              <td colSpan={11} style={{ textAlign: "left" }}>
                1. ❷ 기부금 단체는 해당 단체를 기부금공제대상 기부금단체로 규정하고 있는 「소득세법」 또는 「법인세법」 등 관련 법령을 적어 기부금영수증을 발행해야 합니다.(예, 「소득세법 시행령」
                제80조제1항제5호, 「법인세법 시행규칙」 제18조제1항) <br />
                2. ❸ 기부금 모집처(언론기관 등)는 방송사, 신문사, 통신회사 등 기부금을 대신 접수하여 기부금 단체에 전달하는 기관을 말하며, 기부금단체에 직접 기부한 경우에는 적지 않습니다. <br />
                3. ❹ 기부내용의 유형 및 코드는 다음 구분에 따라 적습니다. 이 경우 "법정", "지정", "종교단체" 유형에 해당하는 기부금 중 「조세특례 제한법」 제75조에 따라 기부장려금단체에 기부장려금으로
                신청한 기부금도 "법정", "지정", "종교단체" 유형으로 분류합니다. <br />
                <table style={{ display: "inline-flex", justifyContent: "center" }}>
                  {CM.cfnCompColGroup(["70%", "15%", "15%"])}
                  <tbody>
                    <tr>
                      <td align="center">기부금 구분</td>
                      <td align="center">유형</td>
                      <td align="center">코드</td>
                    </tr>
                    <tr>
                      <td>「소득세법」 제34조제2항, 「법인세법」 제24조제2항에 따른 기부금 법정</td>
                      <td align="center">법정</td>
                      <td align="center">10</td>
                    </tr>
                    <tr>
                      <td>「조세특례제한법」 제76조에 따른 기부금</td>
                      <td align="center">정치자금</td>
                      <td align="center">20</td>
                    </tr>
                    <tr>
                      <td>「소득세법」 제34조제1항(종교단체 기부금 제외), 「법인세법」 제24조제1항에 따른 기부금</td>
                      <td align="center">지정</td>
                      <td align="center">40</td>
                    </tr>
                    <tr>
                      <td>「소득세법」 제34조제1항에 따른 기부금 중 종교단체기부금</td>
                      <td align="center">종교단체</td>
                      <td align="center">41</td>
                    </tr>
                    <tr>
                      <td>「조세특례제한법」 제88조의4에 따른 기부금</td>
                      <td align="center">우리사주</td>
                      <td align="center">42</td>
                    </tr>
                    <tr>
                      <td>필요경비(손금) 및 세액공제 대상에 해당되지 않는 기부금</td>
                      <td align="center">공제제외 기타</td>
                      <td align="center">50</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                4. ❹ 기부내용의 구분란에는 "금전기부"의 경우에는 "금전", "현물기부"의 경우에는 "현물"로 적고, 내용란은 현물기부의 경우에만 적습니다.
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

/*
 * @desc  선택고객 일괄 발행 모달 - 상단 영수자 선택 컴포넌트
 */
export const DonationBillCustomerSelect = ({ selectedBillsList, selectedDonationBill, currentIndex, handleChangeSelected }) => {
  return (
    <div className="search-area">
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <button className="btn-s" disabled={currentIndex === 0 ? true : false} onClick={(e) => handleChangeSelected("prev")}>
          이전
        </button>
        <span className="label-l">주민등록번호(사업자번호)</span>
        <span name="billRecipient" className="w180" data-testid="DonationBill-selected-target-customer-identification-no">
          {CM.cfnIdentificationNoFormat(selectedDonationBill.identificationNo) || ""}
        </span>
        <span className="label-l">이름</span>
        <span name="billRecipient" className="w180" data-testid="DonationBill-selected-target-customer-name">
          {selectedDonationBill.customerName || ""}
        </span>
        <button className="btn-s" disabled={currentIndex === selectedBillsList.length - 1 ? true : false} onClick={(e) => handleChangeSelected("next")}>
          다음
        </button>
        <label className="label-l fr">
          전체 {selectedBillsList.length}건 중 {currentIndex + 1}번째
        </label>
      </div>
    </div>
  );
};
