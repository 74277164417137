import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import RegistrationsResultDetailModal from "./RegistrationsResultDetailModal";

// 검색 데이터
function searchData() {
  const today = new Date();
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return {
    startDate: monthAgo,
    endDate: today,
    pageNumber: 0,
    pageSize: 5,
    payerNo: "",
    sortDirection: "DESC",
    sortProperty: "confirmedDate",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">접수일자</label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.startDate}
          handleChange={handleSearchFormChange("startDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.endDate}
          handleChange={handleSearchFormChange("endDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <button className="search-button" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  // TableRow 클릭시 상세 페이지로 이동
  const handleRowClick = (e, row) => {
    props.setFile({
      ...props.file,
      fileUniqueKey: row.fileUniqueKey,
      filename: row.filename,
    });
    props.setOpen(true);
  };
  const headRowOne = [
    { value: "접수일자", rowSpan: 2 },
    { value: "수신일시", rowSpan: 2 },
    { value: "접수내용", colSpan: 3 },
    { value: "처리결과", colSpan: 4 },
  ];
  const headRowTwo = [{ value: "총 건수" }, { value: "등록 건수" }, { value: "해지 건수" }, { value: "등록완료" }, { value: "해지완료" }, { value: "등록오류" }, { value: "해지오류" }];
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["15%", "15%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"])}
        <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} hover className="show-detail" onClick={(e) => handleRowClick(e, row)} data-testid={`open-modal-${index}`}>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.receivedDate) ? CM.cfnDateFormat(row.receivedDate.toString()) : "-"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.confirmedDate) ? CM.cfnDateFormat(row.confirmedDate.toString()) : "-"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.totalRequestTargets}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.newAccountRequestTargets}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.closingAccountRequestTargets}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.newAccountResultCount}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.closedAccountResultCount}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.newAccountResultErrorCount}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.closedAccountResultErrorCount}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function RegistrationsResult(props) {
  const { tabIndex } = props;

  //모달 파라미터 State
  const [file, setFile] = useState({
    fileUniqueKey: "",
    filename: "",
  });

  //상세 모달
  const [open, setOpen] = useState(false);
  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  useEffect(() => {
    /*
     * @desc 계좌실명조회 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.endDate = CM.cfnConvertDateToString(searchRequest.endDate);
      tempObj.startDate = CM.cfnConvertDateToString(searchRequest.startDate);

      return tempObj;
    };

    /*
     * @desc    처리결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/financial-institute/registrations/result?startDate=${search.startDate}&endDate=${search.endDate}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;

        CM.cfnAxios(
          url,
          "get",
          "",
          (status, data) => {
            resolve(data);
          },
          (error) => {
            CM.cfnAlert(
              error && error.response && error.response.data && typeof error.response.data === "string"
                ? error.response.data
                : error && error.response && error.response.data && typeof error.response.data.message === "string"
                ? error.response.data.message
                : "현재 요청량이 많아 처리가 지연되고 있습니다. 잠시 후에 다시 시도해주시기 바랍니다.",
              () => {}
            );
            setSearchButton(false);
          }
        );
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      const resultData = await axiosList(search);

      let resultList = resultData.content;
      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }
      setOriginalList(resultData);
      setList(resultList);
      setPagination(paginationData(resultData));
      setSearchButton(false);
    }

    // 실행 영역
    if (tabIndex === 1 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc  검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "startDate" || name === "endDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
      </div>
      <ListForm list={list} setFile={setFile} setOpen={setOpen} pagination={pagination} page={searchRequest.pageNumber} />
      <Template.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <RegistrationsResultDetailModal open={open} setOpen={setOpen} file={file} />
    </div>
  );
}

export default RegistrationsResult;
