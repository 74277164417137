import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as CM from "../../common/Common";
import { StyleTooltip } from "../../template/ComponentForm";
import * as CalData from "./RequestCalendarData";

/*
 * @desc  달력 상단 - 버튼 및 연월 표기 컴포넌트
 */
const CalendarHead = (props) => {
  /*
   * @desc  달력 변경 버튼 클릭 이벤트 핸들러
   */
  const handleClickMonthButton = (button) => {
    const newMonth = CalData.fnMoveCalendar(button, props.currentMonth);
    props.handleChangeCalendar(newMonth);
  };

  return (
    <div className="calendar-head">
      <ArrowBackIosIcon fontSize="small" color="action" onClick={(e) => handleClickMonthButton("prev")} data-testid="prev-month" style={{ cursor: "pointer", outline: "none" }} />

      <span className="blue3" data-testid="current-ym">
        {`${props.currentMonth.year}년
          ${CalData.fnGetMonthName(props.currentMonth.month)} `}
      </span>
      <ArrowForwardIosIcon fontSize="small" color="action" onClick={(e) => handleClickMonthButton("next")} data-testid="next-month" style={{ cursor: "pointer", outline: "none" }} />
    </div>
  );
};

/*
 * @desc  달력 상단 - 요일 표기 컴포넌트
 */
const CalendarDayHead = (props) => {
  return (
    <div className="calendar-day-head">
      <div className="day-box-sunday">
        <div className="date-label ">일</div>
      </div>
      <div className="day-box-week">
        <div className="date-label">월</div>
      </div>
      <div className="day-box-week">
        <div className="date-label">화</div>
      </div>
      <div className="day-box-week">
        <div className="date-label">수</div>
      </div>
      <div className="day-box-week">
        <div className="date-label">목</div>
      </div>
      <div className="day-box-week">
        <div className="date-label">금</div>
      </div>
      <div className="day-box-saturday">
        <div className="date-label">토</div>
      </div>
    </div>
  );
};

/*
 * @desc  달력 - 일주일 단위 행 생성 컴포넌트
 */
const CalendarWeek = (props) => {
  return (
    <div className="calendar-week">
      {props.dayList.map((element) => {
        return <DayBox day={element} key={element.date} />;
      })}
    </div>
  );
};

/*
 * @desc  달력 - 개별 박스 컴포넌트
 */
const DayBox = (props) => {
  const { day } = props;

  return (
    <div className={`day-box ${day.isCurrentMonth ? "" : "other-month"}`}>
      <div className={`day-label ${day.isCurrentMonth ? "" : "other-month"} ${day.isToday ? "today" : ""} ${day.isHoliday ? "holiday" : ""}`}>{Number(day.date.substring(2))}</div>
      {CM.cfnIsNotEmpty(day.historyList) &&
        day.historyList.map((history, index) => {
          return (
            <div className="history-area" key={index}>
              {history.type === "HOLIDAY" ? (
                <span className="history-summary holiday">
                  <span className={`day-label holiday ${day.isCurrentMonth ? "" : "other-month"}`}>{history.summary}</span>
                </span>
              ) : CM.cfnIsNotEmpty(history.summary) && history.needTooltip ? (
                <StyleTooltip
                  title={
                    history.onRequest ? (
                      <div>
                        청구건수 : {history.details.numberOfTotalRequest || 0}건 <br />
                        청구금액 : {history.details.amountOfTotalAmount || 0}원
                      </div>
                    ) : (
                      <div>
                        {history.type === "PAYMENT" ? "입금" : "출금"} : {history.details.numberOfTotalRequest || 0}건 / {history.details.amountOfTotalAmount || 0}원 <br />
                        실패 : {history.details.numberOfTotalError || 0}건 / {history.details.amountOfTotalError || 0}원
                      </div>
                    )
                  }>
                  <Link to={{ pathname: history.type === "PAYMENT" ? "/payment/cmsPayment" : "/receipt/cmsReceipt", state: { tabIndex: 1 } }}>
                    <span className={`event-type-label ${history.type.toLowerCase()}`}>{CalData.fnMatchEventType(history.type)}</span>
                    <span className="history-summary show-detail" id={`${day.date}-${history.type}-${index}`}>
                      {history.summary}
                    </span>
                  </Link>
                </StyleTooltip>
              ) : (
                <span className="history-summary" id={history.date}>
                  {history.summary}
                </span>
              )}
            </div>
          );
        })}
    </div>
  );
};

/*
 * @desc  메인 컴포넌트
 */
export default function RequestCalendar() {
  //필요 state 선언
  const [currentMonth, setCurrentMonth] = useState(CalData.fnGetCurrent()); //선택된 연월 제어
  const [dateList, setDateList] = useState([]); //일자 목록

  //선택연월 변경 시 출금/지급내역 목록 조회 후 일자 목록 생성
  useEffect(() => {
    /*
     * @desc  출금/지급내역 목록 조회 Request
     */
    const fnFetchEventList = () => {
      return new Promise((resolve) => {
        const startDate = new Date(`${currentMonth.year}-${currentMonth.month < 10 ? "0" + currentMonth.month : currentMonth.month}-01`);
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

        const url = `api/receipt/cms/calendar?searchEndDate=${CM.cfnConvertDateToString(endDate)}&searchStartDate=${CM.cfnConvertDateToString(startDate)}`;

        CM.cfnAxios(url, "get", "", (objStatus, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc  휴일정보 목록 조회 Request
     */
    const fnHolidayList = () => {
      return new Promise((resolve) => {
        const startDate = new Date(`${currentMonth.year}-${currentMonth.month < 10 ? "0" + currentMonth.month : currentMonth.month}-01`);
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 2, 0);

        const url = `api/gadget/bizDate/period?endDate=${CM.cfnConvertDateToString(endDate)}&startDate=${CM.cfnConvertDateToString(startDate)}`;

        CM.cfnAxios(url, "get", "", (objStatus, data) => {
          resolve(data);
        });
      });
    };

    const startAxios = async () => {
      //출금/지급내역 목록 조회
      const resultData = await fnFetchEventList();
      //휴일 목록 조회
      const holidayData = await fnHolidayList();

      //선택연월의 일자 목록 생성
      const newDateList = CalData.fnMakeDateList(currentMonth, resultData, holidayData);
      setDateList(newDateList);
    };

    //실행영역
    startAxios();
  }, [currentMonth]);

  /*
   * @desc  선택연월 변경 핸들러
   */
  const handleChangeCalendar = (value) => {
    setCurrentMonth(value);
  };

  return (
    <div className="calendar-area">
      <div className="no-icon-header">
        <h3>출금·입금 청구 및 결과 내역</h3>
      </div>
      <div className="content-box calendar">
        <CalendarHead currentMonth={currentMonth} handleChangeCalendar={handleChangeCalendar} />
        <CalendarDayHead />
        <div className="calendar-body">
          {dateList.map((row, index) => {
            return <CalendarWeek key={index} dayList={row} />;
          })}
        </div>
        <div className="calendar-bottom">
          <span className="event-type-label withdraw">출</span>
          <label>출금내역</label>
          <span className="event-type-label payment">입</span>
          <label>입금내역</label>
          <span className="event-type-label auto">자</span>
          <label>자동출금내역</label>
          <label className="blue2">청구 건수(성공 건수/실패 건수)</label>
        </div>
      </div>
    </div>
  );
}
