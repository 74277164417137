import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";

import DonationData from "./DonationBillData";
import DonationFormManagementTab from "./DonationFormManagementTab";
import IndividualIssueTab from "./IndividualIssueTab";
import BatchIssueTab from "./BatchIssueTab";
import IssueHistoryTab from "./IssueHistoryTab";
import SubmissionFileTab from "./SubmissionFileTab";
import * as CM from "../../common/Common";

// 부가서비스 > 기부금영수증 > 메인
// 렌더를 처리하는 메인 컴포넌트
const DonationBill = () => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [sealImage, setSealImage] = useState(DonationData.defaultSealImageValue); //선택된 직인 이미지 제어
  const [donationForm, setDonationForm] = useState(DonationData.defaultDonationFormValue); //기부금 영수증 양식 정보 입력 제어
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    //직인 이미지 및 양식 조회 실행 함수
    CM.cfnAxiosPreview(
      "api/extraservice/donations/formats/seals",
      "get",
      null,
      (status, contentType, file, fileName) => {
        if (contentType && contentType.indexOf("image") !== -1) {
          setSealImage((image) => ({
            ...image,
            file: file,
            fileName: fileName,
            fileSrc: `data:${contentType};base64,${new Buffer(file, "binary").toString("base64")}`,
          }));
        } else {
          setSealImage((image) => ({
            ...image,
            file: file,
            fileName: fileName,
            fileSrc: "#",
          }));
        }
      },
      () => {}
    );
    //직인 이미지 및 양식 조회 실행 함수
    const startAxios = async () => {
      const formData = await DonationData.fnFetchDonationForm();
      formData.serialNumberType = CM.cfnIsNotEmpty(formData.serialNoPrefix) ? "EN" : "NUM";
      formData.serialNoDigit = formData.serialNoDigit || 1;
      formData.sealPrintYn = !!formData.sealPrintYn;
      setDonationForm(formData);
    };

    //실행영역
    startAxios();
  }, []);

  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  // 화면 렌더
  return (
    <div name="main-tab">
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="donationBill-change-tabs">
          <Tab disableRipple value={0} label="기부금영수증 양식관리" data-testid="donationBill-tab-donationFormManagementTab" />
          <Tab disableRipple value={1} label="개별 발행" data-testid="donationBill-tab-individualIssueTab" />
          <Tab disableRipple value={2} label="일괄 발행" data-testid="donationBill-tab-batchIssueTab" />
          <Tab disableRipple value={3} label="발행 내역" data-testid="donationBill-tab-issueHistoryTab" />
          <Tab disableRipple value={4} label="국세청 제출파일 생성" data-testid="donationBill-tab-submissionFileTab" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <DonationFormManagementTab tabIndex={tabIndex} sealImage={sealImage} setSealImage={setSealImage} donationForm={donationForm} setDonationForm={setDonationForm} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div className="inforbox">
          <ul>
            <li>기부금영수증을 발행할 고객을 선택한 후, 기부내용을 편집하여 기부금 영수증을 발행할 수 있습니다.</li>
            <li>고객기본정보의 고객구분이 "기타고객"인 경우 기부금영수증을 발행할 수 없습니다.</li>
          </ul>
        </div>
        <IndividualIssueTab tabIndex={tabIndex} sealImage={sealImage} donationForm={donationForm} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className="inforbox">
          <ul>
            <li>
              기부금영수증은 이름, <b>주민등록번호(사업자번호)</b>, 합계금이 모두 있어야 발행가능합니다. <br />
              단, 주소는 영수증 제출 시 필수 입력사항이므로 주소가 등록되어 있지 않은 경우 기부자 본인이 기재하도록 안내하시기 바랍니다.
            </li>
            <li>일괄발행시 '건별인쇄'를 진행하면 건별로 기부내용 수정이 가능하고, '합계로인쇄'를 진행하면 전체 1건으로 보여져 합산된 기부내용 수정이 가능합니다.</li>
            <li>기부금영수증 발행은 주민등록번호(사업자번호)를 기준으로 하므로, 주민등록번호(사업자번호) 하나에 다수의 수납내역이 있을 경우 1건으로 통합되어 발행됩니다.</li>
          </ul>
        </div>
        <BatchIssueTab tabIndex={tabIndex} sealImage={sealImage} donationForm={donationForm} />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <div className="inforbox">
          <ul>
            <li>"재인쇄"를 눌러 기부금 영수증을 다시 인쇄할 경우, 발행일자는 최초 발행일자로 인쇄됩니다.</li>
            <li>일련번호를 초기화하고자 하는 경우 발행한 연도의 모든 발행내역을 삭제하시면 됩니다.</li>
            <li>기부금영수증 발행은 이름 및 주민등록번호(사업자번호)를 기준으로 하므로, 이름 및 주민등록번호(사업자번호) 하나에 다수의 수납내역이 있을 경우 1건으로 통합되어 발행됩니다.</li>
          </ul>
        </div>
        <IssueHistoryTab tabIndex={tabIndex} sealImage={sealImage} donationForm={donationForm} />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <div className="inforbox">
          <ul>
            <li>국세청에 제출할 파일 양식 중 엑셀파일은 개인 및 사업자 기부자에, D001파일은 개인 기부자에 대한 양식입니다.</li>
            <li>
              다운받은 파일은 사용자의 PC에 저장되며, 국세청이 정하는 방식에 따라 국세청으로 직접 전송하시기 바랍니다.(
              <a href="https://www.hometax.go.kr" target="_blank" rel="noopener noreferrer" className="link">
                연말정산간소화서비스 홈페이지
              </a>{" "}
              참조)
            </li>
            <li>조회기준 설정에 따라 기부금 내역이 달라집니다.</li>
            <li>기부장려금 신청금액 이용시 국세청 제출양식 엑셀 파일을 다운받아 수정해 주시기 바랍니다.</li>
          </ul>
        </div>
        <SubmissionFileTab tabIndex={tabIndex} sealImage={sealImage} donationForm={donationForm} />
      </TabPanel>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(DonationBill));
