import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as CF from "../../template/ComponentForm";
import * as Common from "../../common/Common";

// 영수증 발행 대상 생성 컴포넌트
const CashbillCreateIssueTarget = (props) => {
  const [minDate, setMinDate] = useState(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate() + 1));
  const [maxDate, setMaxDate] = useState(new Date());
  const [requestCriteria, setRequestCriteria] = useState({
    isBusinessCustomerIncluded: true, // 사업자고객/매입세액공제용(부가세포함)
    isBusinessCustomerIncludedVatNotIncluded : false, // 사업자고객/매입세액공제용(부가세미포함)
    receiptStartDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate() + 1),
    receiptEndDate: new Date(),
  });

  useEffect(() => {
    if (props.tabIndex === 0) {
      setMaxDate(new Date());
      setMinDate(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate() + 1));
    }
  }, [props.tabIndex]);

  const handleDateOfStartRequestCriteria = (date) => {
    let diffEndDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate() - 1);
    if (requestCriteria.receiptStartDate > date || date > requestCriteria.receiptEndDate) {
      setRequestCriteria((value) => ({
        ...value,
        receiptStartDate: date,
        receiptEndDate: diffEndDate > new Date() ? new Date() : diffEndDate,
      }));
    } else {
      setRequestCriteria((value) => ({
        ...value,
        receiptStartDate: date,
      }));
    }
  };

  const handleDateOfEndRequestCriteria = (date) => {
    let diffStartDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() + 1);
    if (requestCriteria.receiptStartDate > date || date > requestCriteria.receiptEndDate) {
      setRequestCriteria((value) => ({
        ...value,
        receiptStartDate: diffStartDate < minDate ? minDate : diffStartDate,
        receiptEndDate: date,
      }));
    } else {
      setRequestCriteria((value) => ({
        ...value,
        receiptEndDate: date,
      }));
    }
  };

  const handleRequestCriteria = ({ target: { value, checked } }) => {
    if (value === "isBusinessCustomerIncludedVatNotIncluded") {
      setRequestCriteria((data) => ({
        ...data,
        isBusinessCustomerIncluded: false,
        isBusinessCustomerIncludedVatNotIncluded: checked
      }))
    } else {
      setRequestCriteria((data) => ({
        ...data,
        isBusinessCustomerIncluded: checked,
        isBusinessCustomerIncludedVatNotIncluded: false
      }))
    }
  }

  // 유효성 검사
  const validation = () => {
    let message;

    if (Common.cfnIsEmpty(maxDate)) message = "수납종료일을 선택하세요.";
    else if (!Common.cfnValidDate(maxDate)) message = "수납종료일 날짜 형식이 올바르지 않습니다.";

    if (Common.cfnIsNotEmpty(message)) {
      Common.cfnAlert(message);
      return false;
    }

    return true;
  };

  const createIssueTarget = async (e) => {
    e.preventDefault();
    const check = validation();
    if (!check) return false;

    const cloneData = Common.cfnCopyObject(requestCriteria);
    const convertStartDate = await Common.cfnConvertDateToString(cloneData.receiptStartDate);
    const convertEndDate = await Common.cfnConvertDateToString(cloneData.receiptEndDate);

    let url = "api/extraservice/cashbill/targets";
    url += `?isBusinessCustomerIncluded=${cloneData.isBusinessCustomerIncluded}`;
    url += `&isBusinessCustomerIncludedVatNotIncluded=${cloneData.isBusinessCustomerIncludedVatNotIncluded}`;
    url += `&receiptStartDate=${convertStartDate}`;
    url += `&receiptEndDate=${convertEndDate}`;

    Common.cfnAxios(url, "post", "", (status, data) => {
      if (status.status === 200 && data) {
        props.handleSearchStart(true);
      } else {
        Common.cfnAlert(data || "현금영수증 발행대상을 생성하지 못했습니다. 잠시 후 다시 시도해주세요.");
      }
    });
  };

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>오늘을 기준으로 1년 전 수납 내역부터 1개월 단위로 수납일을 입력해 발행합니다. 단, 발급한 내역의 공제기간 등은 반드시 국세청에서 정확한 정보를 확인하시기 바랍니다.</li>
          <li>
            빌링원플러스를 통하여 수납이 완료된 내역에 대해서 현금영수증을 발행할 수 있습니다. 단, 고객기본정보에 <b>현금영수증 발행정보가 등록되지 않은 고객은 현금영수증 발행이 불가능</b>합니다.
          </li>
          <li>
            발행대상과 수납일을 선택한 후 "영수증 발행대상 생성"을 클릭하시기 바랍니다.
          </li>
        </ul>
      </div>
      <Table>
        {Common.cfnCompColGroup(["200px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">수납기간</TableCell>
            <TableCell className="td">
              <CF.DatePickerForm
                value={requestCriteria.receiptStartDate}
                handleChange={handleDateOfStartRequestCriteria}
                customProps={{
                  minDate,
                  maxDate,
                }}
                className="w160 receiptRequestResultDatePicker"
              />
              <span>~&nbsp;&nbsp;&nbsp;</span>
              <CF.DatePickerForm
                value={requestCriteria.receiptEndDate}
                handleChange={handleDateOfEndRequestCriteria}
                customProps={{
                  minDate,
                  maxDate,
                  inputProps: { "data-testid": "cahsbill-createIssueTarget-datePicker-receiptStartDate" },
                }}
                className="w160 receiptRequestResultDatePicker"
              />
              <span className="label-r">까지</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">현금영수증 발행대상</TableCell>
            <TableCell className="td">
              <CF.CheckboxForm
                  checked={requestCriteria.isBusinessCustomerIncluded}
                  value="isBusinessCustomerIncluded"
                  label="사업자고객포함(매입세액공제용, 부가세 포함)"
                  handleChange={handleRequestCriteria}
                  customProps={{ "data-testid": "checkbox-businessCustomer" }}
              />
              <span>&nbsp;</span>
              <CF.CheckboxForm
                  checked={requestCriteria.isBusinessCustomerIncludedVatNotIncluded}
                  value="isBusinessCustomerIncludedVatNotIncluded"
                  label="사업자고객포함(매입세액공제용, 부가세 미포함)"
                  handleChange={handleRequestCriteria}
                  customProps={{ "data-testid": "checkbox-businessCustomer-vatNotIncluded" }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area">
        <button className="btn-l" onClick={createIssueTarget} data-testid="cashbill-create-targets">
          영수증 발행대상 생성
        </button>
      </div>
    </div>
  );
};

export default CashbillCreateIssueTarget;
