import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";

import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import { TooltipForm } from "../../template/ComponentForm";

// radio 기본 옵션 데이터
const initialRadioOption = {
  // 출금시작(희망) 일
  fileType: [
    { value: "EB21", label: "(익일 출금) 출금시행 전 영업일 17시까지 청구" },
    { value: "EC21", label: "(당일 출금) 출금시행 당일 12시까지 청구" },
  ],
  // 출금방식
  targetIntegratedYn: [
    { value: true, label: "통합출금" },
    { value: false, label: "건별출금" },
  ],
  // 출금대상
  askTargetCriteria: [
    { value: "THIS_TIME_AND_UNASKED", label: "청구일분" },
    { value: "THIS_TIME_AND_UNASKED_AND_UNPAID", label: "청구일분 + 미납분" },
  ],
};

// 최대 출금시도횟수 select 기본 옵션 데이터
const initialSelectMaxNumberOfTryToAsk = (number) => {
  const option = [];

  for (let i = 0; i < number; i++) {
    const label = `${i}회`;
    const value = i;

    option.push({ label, value });
  }

  return option;
};

// disalbe을 처리하는 css
const useStyles = makeStyles((theme) => ({
  disabled: {
    "pointer-events": "none",
    opacity: 0.6,
  },
}));

// 기관 업무 정보 > 부가서비스 정보 관리 > 자동 출금 예약 tab
export default function TabAutoWithdraw(props) {
  const [autoWithdraw, setAutoWithdraw] = React.useState();

  const handleAutoWithDraw = {
    expectedApplyDate: (date) => setAutoWithdraw((data) => ({ ...data, expectedApplyDate: date })),
    fileType: ({ target: { value } }) => setAutoWithdraw((data) => ({ ...data, fileType: value })),
    askTargetCriteria: ({ target: { value } }) => setAutoWithdraw((data) => ({ ...data, askTargetCriteria: value })),
    maxNumberOfTryToAsk: ({ target: { value } }) => setAutoWithdraw((data) => ({ ...data, maxNumberOfTryToAsk: value })),
    targetIntegratedYn: ({ target: { value } }) => setAutoWithdraw((data) => ({ ...data, targetIntegratedYn: value === "true" ? true : false })),
    paySpecifiedDays: ({ target: { value } }, index) => {
      const clone = { ...autoWithdraw };

      // 중복일 선택 불가
      for (const prev of clone.paySpecifiedDays) {
        if ((prev === value) & CM.cfnIsNotEmpty(value)) {
          CM.cfnAlert("같은 통지일을 선택할 수 없습니다.");
          return;
        }
      }

      // 선택안함을 선택했을 때
      if (CM.cfnIsEmpty(value)) {
        clone.paySpecifiedDays[index] = null;
      } else {
        clone.paySpecifiedDays[index] = value;
      }

      setAutoWithdraw(clone);
    },
  };

  // 서비스 신청 또는 설정 변경
  const updateService = () => {
    const sendData = { ...autoWithdraw };
    const { expectedApplyDate } = sendData;

    sendData.expectedApplyDate = CM.cfnConvertDateToString(expectedApplyDate);
    sendData.maxNumberOfTryToAsk = sendData.maxNumberOfTryToAsk || 0; // 기존값 유지 또는 0으로 초기화

    const url = "api/institute/business/autoWithdraw";
    CM.cfnAxios(url, "put", sendData, (objStatus, data) => {
      CM.cfnAlert("신청이 접수되었습니다.");
    });
  };

  // 서비스 해지
  const terminateService = () => {
    const sendData = { ...autoWithdraw };
    sendData.expectedApplyDate = CM.cfnConvertDateToString(sendData.expectedApplyDate);
    sendData.serviceStatus = "TERMINATED";

    const url = "api/institute/business/autoWithdraw";
    CM.cfnAxios(url, "put", sendData, (objStatus, data) => {
      CM.cfnAlert("신청이 접수되었습니다.");
    });
  };

  React.useEffect(() => {
    // autoWithdraw state 초기화
    const initialAutoWithdraw = (autoWithdraw, disabled) => {
      if (autoWithdraw.serviceStatus === "NORMAL" || disabled) {
        setAutoWithdraw({ ...autoWithdraw });
        return;
      }

      const date = CM.cfnGetDate();
      const url = `api/support-service/gadget/bizDate/next?targetDate=${date}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        const expectedApplyDate = String(data);

        setAutoWithdraw({
          serviceStartDate: null,
          serviceStatus: "TERMINATED",
          askTargetCriteria: "",
          targetIntegratedYn: "",
          maxNumberOfTryToAsk: "0",
          paySpecifiedDays: [],
          expectedApplyDate,
          fileType: "",
        });
      });
    };

    initialAutoWithdraw(props.autoWithdraw, props.disabled);
  }, [props.autoWithdraw, props.disabled]);

  // 렌더
  if (autoWithdraw === undefined) {
    return null;
  }

  return (
    <div>
      <div className="modify-area">
        <div className="block">{autoWithdraw.serviceStatus === "NORMAL" ? "현재 자동 출금 예약 서비스를 이용 중입니다." : "다음 내용으로 자동 출금 예약 서비스를 신청합니다."}</div>
      </div>
      <div>
        <h4>서비스 설정(자동출금 예약)</h4>
      </div>
      <div>
        <ServiceSettingForm autoWithdraw={autoWithdraw} handleAutoWithDraw={handleAutoWithDraw} disabled={props.disabled} instituteServiceState={props.instituteServiceState} />
        <ServiceButtonForm serviceStatus={autoWithdraw.serviceStatus} updateService={updateService} terminateService={terminateService} disabled={props.disabled} />
      </div>
      <div className="inforbox">
        <h4>서비스안내</h4>
        <ul>
          <li>CMS 요금미납, 보증보험 만기일 경우, CMS 또는 빌링원플러스 서비스가 중단됩니다.(중단사유 처리 후 자동 서비스 개시)</li>
          <li>자동출금 예약서비스를 이용시 직접 출금청구를 할 수 없습니다.</li>
        </ul>
      </div>
    </div>
  );
}

// 서비스 설정 폼
function ServiceSettingForm(props) {
  const style = useStyles();
  const optionDate = CM.cfnSelectOptionDate();
  const optionMaxNumberOfTryToAsk = initialSelectMaxNumberOfTryToAsk(4);

  return (
    <Table>
      {CM.cfnCompColGroup(["15%", "20%", "15%", "auto"])}
      <TableBody>
        <TableRow>
          <TableCell className="th">자동출금 승인희망일</TableCell>
          <TableCell className={`td ${props.disabled ? style.disabled : null}`} colSpan="3">
            <CF.DatePickerForm
              value={props.autoWithdraw.expectedApplyDate}
              handleChange={props.handleAutoWithDraw.expectedApplyDate}
              className={"w150 fl"}
              customProps={{
                minDate: CM.cfnConvertStringToDate(props.autoWithdraw.expectedApplyDate),
                inputProps: { "data-testid": "tabAutoWithDraw-datepicker-expectedApplyDate" },
              }}
            />
            <RadioGroup name="fileType" value={props.autoWithdraw.fileType} onChange={props.handleAutoWithDraw.fileType} data-testid="tabAutoWithDraw-radio-fileType" row>
              {initialRadioOption.fileType
                .filter((element) => {
                  return !props.instituteServiceState || props.instituteServiceState.indexOf(element.value) !== -1;
                })
                .map((element, index) => {
                  return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
                })}
            </RadioGroup>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="th">출금일</TableCell>
          <TableCell className={`td ${props.disabled ? style.disabled : null}`} colSpan="3">
            {[0, 1, 2, 3, 4, 5].map((i) => {
              return (
                <CF.SelectForm
                  key={i}
                  value={(props.autoWithdraw && props.autoWithdraw.paySpecifiedDays && props.autoWithdraw.paySpecifiedDays.length > i && props.autoWithdraw.paySpecifiedDays[i]) || ""}
                  handleChange={(e) => props.handleAutoWithDraw.paySpecifiedDays(e, i)}
                  arrayOption={optionDate}
                  customProps={{ "data-testid": `tabAutoWithDraw-select-paySpecifiedDays-${i}` }}
                />
              );
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="th">
            출금대상
            <TooltipForm contents="출금 대상을 청구일분만 선택하면 전월 이전의 미납분은 출금대상에서 제외되므로 미납분을 함께 출금해야 할 경우, 청구일분+미납분을 선택하시기 바랍니다." />
          </TableCell>
          <TableCell className={`td ${props.disabled ? style.disabled : null}`} colSpan="3">
            <RadioGroup
              name="askTargetCriteria"
              value={props.autoWithdraw.askTargetCriteria}
              onChange={props.handleAutoWithDraw.askTargetCriteria}
              data-testid="tabAutoWithDraw-radio-askTargetCriteria"
              row>
              {initialRadioOption.askTargetCriteria.map((element, index) => {
                return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
              })}
            </RadioGroup>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="th">
            출금 재시도 횟수
            <TooltipForm contents="출금 재시도 횟수는 출금결과를 수신한 후 미출금된 내역을 당월에 몇 번 더 출금청구할지 정하는 것입니다. (예시 : 출금 재시도 횟수를 1회로 설정시 익일출금의 경우 15일자 출금결과를 16일에 수신한 후 미출금 내역만 17일자로 1회 더 출금청구)" />
          </TableCell>
          <TableCell className={`td ${props.disabled ? style.disabled : null}`}>
            <CF.SelectForm
              value={props.autoWithdraw.maxNumberOfTryToAsk}
              handleChange={props.handleAutoWithDraw.maxNumberOfTryToAsk}
              arrayOption={optionMaxNumberOfTryToAsk}
              customProps={{ "data-testid": "tabAutoWithDraw-select-maxNumberOfTryToAsk" }}
            />
          </TableCell>
          <TableCell className="th">
            출금방식
            <TooltipForm contents="출금대상을 1건으로 처리는 미청구분 및 미납분 등 동일 고객의 출금대상 건을 통합하여 1건으로 처리하는 것이며 출금내역별로 분리하여 처리는 모든 출금의뢰 대상 건을 각각 분리하여 출금의뢰하는 것임" />
          </TableCell>
          <TableCell className={`td ${props.disabled ? style.disabled : null}`}>
            <RadioGroup
              name="targetIntegratedYn"
              value={props.autoWithdraw.targetIntegratedYn}
              onChange={props.handleAutoWithDraw.targetIntegratedYn}
              data-testid="tabAutoWithDraw-radio-targetIntegratedYn"
              row>
              {initialRadioOption.targetIntegratedYn.map((element, index) => {
                return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
              })}
            </RadioGroup>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

// 서비스 설정 버튼
function ServiceButtonForm(props) {
  const style = useStyles();

  if (props.serviceStatus === "NORMAL") {
    return (
      <div className={`table-bottom-area ${props.disabled ? style.disabled : null}`}>
        <button className="btn-l" onClick={props.updateService} data-testid="tabAutoWithDraw-button-updateService">
          설정 변경
        </button>
        <button className="btn-l" onClick={props.terminateService} data-testid="tabAutoWithDraw-button-terminateService">
          서비스 해지
        </button>
      </div>
    );
  }

  return (
    <div className={`table-bottom-area ${props.disabled ? style.disabled : null}`}>
      <button className="btn-l" onClick={props.updateService} data-testid="tabAutoWithDraw-button-requestService">
        서비스 신청
      </button>
    </div>
  );
}
