import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as CM from "../../common/Common";

// 기관 업무 정보 > 기관 정보
// 렌더를 처리하는 메인 컴포넌트
function InstituteInfo({ instituteStore }) {
  const [master, setMaster] = useState(toJS(instituteStore.master));

  // store로 부터 기관 정보 받아오기
  useEffect(() => {
    async function getMaster() {
      const result = await instituteStore.axiosMaster();
      setMaster(result);
    }

    // const storeInstitute = CM.cfnCopyObject(toJS(instituteStore.master));

    // if (CM.cfnIsEmpty(storeInstitute)) {
    //   getMaster();
    // }
    getMaster();
  }, [instituteStore]);

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>
            기관의 정보를 변경 하시려면&nbsp;
            <a href="https://www.cmsedi.or.kr/cms" target="_blank" rel="noopener noreferrer" className="link">
              CMS홈페이지
            </a>
            에서 변경하시거나 고객센터(1577-5500)로 문의하시기 바랍니다.
          </li>
        </ul>
      </div>
      <div>
        <Table>
          {CM.cfnCompColGroup(["17%", "auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">기관명 / ID</TableCell>
              <TableCell className="td">
                {master.instName} / {master.intgtLoginId}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">CMS기관코드</TableCell>
              <TableCell className="td">{master.cmsInstCode || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">대표자 / 전화번호</TableCell>
              <TableCell className="td">
                {master.ceoName} / {CM.cfnAddtDashToPhoneNumber(master.telephone)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">생년월일(법인등록번호)</TableCell>
              <TableCell className="td">{CM.cfnIdentificationNoFormat(master.corporationNo, true)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">사업자등록번호</TableCell>
              <TableCell className="td">{CM.cfnIdentificationNoFormat(master.bizNo)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">업태 / 종목</TableCell>
              <TableCell className="td">
                {master.bizType} / {master.bizItem}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">업무개시일</TableCell>
              <TableCell className="td">{CM.cfnDateFormat(master.serviceStartDate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">사업장 주소</TableCell>
              <TableCell className="td">{Object.isExtensible(master.address) ? `(${master.address.zipCode.trim()}) ${master.address.address} ${master.address.addressDetail}` : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">우편물 수취주소</TableCell>
              <TableCell className="td">
                {Object.isExtensible(master.mailingAddress) ? `(${master.mailingAddress.zipCode.trim()}) ${master.mailingAddress.address} ${master.mailingAddress.addressDetail}` : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">세금계산서 수취주소</TableCell>
              <TableCell className="td">{Object.isExtensible(master.taxbillAddress) ? `${master.taxbillAddress.address} ${master.taxbillAddress.addressDetail}` : ""}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default inject((rootStore) => ({
  instituteStore: rootStore.instituteStore,
}))(observer(InstituteInfo));
