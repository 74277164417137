import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableRow, Button, Modal, Input } from "@material-ui/core";

import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";
const ReceiptDirectlyPaidModal = ({ open, setOpen, contractDetail, setSearchButton }) => {
  const [value, setValue] = useState({});
  // 모달이 오픈될 때 value에 선택한 row의 object 값 넣기
  useEffect(() => {
    if (open) setValue({ ...contractDetail, amountAfterTransaction: 0, delayFeeAfterTransaction: 0 });
    else setValue({});
  }, [open, contractDetail]);

  //수정버튼 클릭 이벤트
  const updateButtonOnClick = () => {
    const fnSubmit = async () => {
      const sendData = {
        amountAfterTransaction:
          Number(value.unpaidAmount) -
          (Number(CM.cfnIsNotEmpty(value.amountAfterTransaction) ? CM.cfnReplaceSymbol(value.amountAfterTransaction) : 0) +
            Number(CM.cfnIsNotEmpty(value.delayFeeAfterTransaction) ? CM.cfnReplaceSymbol(value.delayFeeAfterTransaction) : 0)),
        amountBeforeTransaction: value.unpaidAmount,
        delayFeeBeforeTransaction: value.unpaidDelayFee === null ? 0 : value.unpaidDelayFee,
        delayFeeAfterTransaction:
          (value.unpaidDelayFee === null ? 0 : value.unpaidDelayFee) - (CM.cfnIsNotEmpty(value.delayFeeAfterTransaction) ? Number(CM.cfnReplaceSymbol(value.delayFeeAfterTransaction)) : 0),
        remark: value.remark,
        sourceUniqueKey: value.uniqueKey,
        transactionType: "DIRECTLY_PAID",
      };

      const url = "api/receipt/source/amount";
      CM.cfnAxios(url, "put", sendData, (status, data) => {
        CM.cfnAlert("수정되었습니다.", () => {
          setSearchButton(true);
          handleClose();
        });
      });
    };

    CM.cfnConfirm("수정하시겠습니까?", fnSubmit);
  };

  // 모달 클로즈
  const handleClose = () => setOpen(false);

  /*
   * @desc    입력란 변경 이벤트 핸들러
   */
  const handleValueChange = (name) => (e) => {
    if (name === "remark") {
      setValue({
        ...value,
        [name]: e.target.value,
      });
    } else {
      setValue({
        ...value,
        [name]: CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, "")),
      });
    }
  };

  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.askStatus === "HOLDING") {
          return "미청구\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미청구\n(청구중)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.askStatus === "HOLDING") {
          return "미도래\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미도래\n(청구중)";
        } else {
          return "미도래";
        }
      case "UNPAID":
        if (row.askStatus === "HOLDING") {
          return "미납\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미납\n(청구중)";
        } else {
          return "미납";
        }
      case "PARTIALLY_PAID":
        if (row.askStatus === "HOLDING") {
          return "부분납\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "부분납\n(청구중)";
        } else {
          return "부분납";
        }
      case "PAID":
        return "수납";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "수납보류";
      default:
        if (row.askStatus === "ASKING" && row.numberOfAsking === 1) {
          return "출금중\n(최초출금)";
        } else if (row.askStatus === "ASKING" && row.numberOfAsking > 1) {
          return "출금중\n(재출금)";
        }
    }
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {" "}
            </Button>
          </div>
          <h3>직접수납 처리</h3>
          <Table>
            {CM.cfnCompColGroup(["100px", "120px", "100px", "100px", "auto", "auto", "auto", "auto"])}
            <TableForm.compTableHead arrData={["구분", "내용", "구분", "내용", "구분", "원금", "연체료", "합계"]} />
            <TableBody>
              <TableRow>
                <TableCell className="th">고객명</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.customerName}
                </TableCell>
                <TableCell className="th">수납연월</TableCell>
                <TableCell className="td" align="center">
                  {CM.cfnDateFormat(contractDetail.targetY4mm, "yyyyMM")}
                </TableCell>
                <TableCell className="th">미납금액</TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(Number(CM.cfnIsNotEmpty(value.unpaidAmount) ? value.unpaidAmount : 0) - Number(CM.cfnIsNotEmpty(value.unpaidDelayFee) ? value.unpaidDelayFee : 0)) + "원"}
                </TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(value.unpaidDelayFee) + "원"}
                </TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(value.unpaidAmount) + "원"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">납부자번호</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.payerNo}
                </TableCell>
                <TableCell className="th">수납회차</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.payAmountType === "TEMPORARY_ADDED" ? "추가분" : contractDetail.contractAskSequence}
                </TableCell>
                <TableCell className="th"> 직접수납금액</TableCell>
                <TableCell className="td" align="right">
                  <Input
                    className="input-w100 mr0"
                    value={value.amountAfterTransaction === "" || value.amountAfterTransaction === undefined ? "0" : value.amountAfterTransaction}
                    onChange={handleValueChange("amountAfterTransaction")}
                    name="amountAfterTransaction"
                    data-testid="amountAfterTransaction-input-directlyPaid"
                    inputProps={{
                      style: { textAlign: "right", marginRight: 0 },
                    }}
                    endAdornment="원"
                  />
                </TableCell>
                <TableCell className="td" align="right">
                  <Input
                    className="input-w100 mr0"
                    value={value.delayFeeAfterTransaction === "" || value.amountAfterTransaction === undefined ? "0" : value.delayFeeAfterTransaction}
                    onChange={handleValueChange("delayFeeAfterTransaction")}
                    name="delayFeeAfterTransaction"
                    data-testid="delayFeeAfterTransaction-input-directlyPaid"
                    inputProps={{
                      style: { textAlign: "right", marginRight: 0 },
                    }}
                    endAdornment="원"
                  />
                </TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(
                    Number(CM.cfnIsNotEmpty(value.amountAfterTransaction) ? CM.cfnReplaceSymbol(value.amountAfterTransaction) : 0) +
                      Number(CM.cfnIsNotEmpty(value.delayFeeAfterTransaction) ? CM.cfnReplaceSymbol(value.delayFeeAfterTransaction) : 0)
                  ) + "원"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">수납일</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.paySpecifiedDay === "99" ? "말일" : contractDetail.paySpecifiedDay}
                </TableCell>
                <TableCell className="th">수납상태</TableCell>
                <TableCell className="td" style={{ whiteSpace: "pre-wrap" }} align="center">
                  {fnAskStatus(contractDetail)}
                </TableCell>
                <TableCell className="th">직접수납 후 미납금액</TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(
                    Number(CM.cfnIsNotEmpty(value.unpaidAmount) ? value.unpaidAmount : 0) -
                      Number(CM.cfnIsNotEmpty(value.unpaidDelayFee) ? value.unpaidDelayFee : 0) -
                      Number(CM.cfnIsNotEmpty(value.amountAfterTransaction) ? CM.cfnReplaceSymbol(value.amountAfterTransaction) : 0)
                  ) + "원"}
                </TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(
                    Number(CM.cfnIsNotEmpty(value.unpaidDelayFee) ? value.unpaidDelayFee : 0) -
                      Number(CM.cfnIsNotEmpty(value.delayFeeAfterTransaction) ? CM.cfnReplaceSymbol(value.delayFeeAfterTransaction) : 0)
                  ) + "원"}
                </TableCell>
                <TableCell align="right" className="td">
                  {CM.cfnAddComma(
                    Number(CM.cfnIsNotEmpty(value.unpaidAmount) ? value.unpaidAmount : 0) -
                      Number(CM.cfnIsNotEmpty(value.unpaidDelayFee) ? value.unpaidDelayFee : 0) -
                      Number(CM.cfnIsNotEmpty(value.amountAfterTransaction) ? CM.cfnReplaceSymbol(value.amountAfterTransaction) : 0) +
                      Number(CM.cfnIsNotEmpty(value.unpaidDelayFee) ? value.unpaidDelayFee : 0) -
                      Number(CM.cfnIsNotEmpty(value.delayFeeAfterTransaction) ? CM.cfnReplaceSymbol(value.delayFeeAfterTransaction) : 0)
                  ) + "원"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th"></TableCell>
                <TableCell className="td"></TableCell>
                <TableCell className="th"></TableCell>
                <TableCell className="td"></TableCell>
                <TableCell className="th">비고</TableCell>
                <TableCell className="td" colSpan="3">
                  <Input className="w100p" data-testid="remark-input-directlyPaid" value={value.remark} onChange={handleValueChange("remark")} name="remark" inputProps={{ maxLength: 40 }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="table-bottom-area">
            <button className="btn-l" data-testid="update-button" onClick={(e) => updateButtonOnClick()}>
              저장
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReceiptDirectlyPaidModal;
