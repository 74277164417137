import { Button, Checkbox, Input, Modal, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import { SelectForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

const toDay = new Date();

function searchData() {
  return {
    pageNumber: "0",
    pageSize: "5",
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
    transactionMethod: "",
    capitalUniqueKey: "",
    groupUniqueKey: "",
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
  };
}

// 페이징 데이터
function fnPagination(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

const PaymentSourceCreateModal = ({ open, setOpen, capitalUniqueKey, groupUniqueKey, transactionMethod, setListSearchButton, searchProperty }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
    setListSearchButton(true);
  };

  // 화면 open시 화면 초기화
  useEffect(() => {
    if (!open) {
      return;
    }
    setOriginalList([]);
    setFiltertList([]);
    setCheckAllRow(false);
    setSearchRequest(searchData);
    setSearchButton(true);
  }, [open]);

  //검색 버튼 State
  const [searchButton, setSearchButton] = useState(true);
  //검색 Request State
  const [searchRequest, setSearchRequest] = useState(searchData);

  const [originalList, setOriginalList] = useState([]);
  const [filtertList, setFiltertList] = useState([]);
  const [pagination, setPagination] = useState({
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  });
  const [paySpecifiedDate, setPaySpecifiedDate] = useState("");
  const [totalAskingAmount, setTotalAskingAmount] = useState("");
  //일괄추가 관련 state
  const excelInput = React.useRef("");

  useEffect(() => {
    // axios request informationList
    function axiosList() {
      return new Promise((resolve, reject) => {
        let url = `api/payment/source/addition?capitalUniqueKey=${searchRequest.capitalUniqueKey}&groupUniqueKey=${searchRequest.groupUniqueKey}&pageNumber=${searchRequest.pageNumber}&pageSize=${searchRequest.pageSize}&sortDirection=${searchRequest.sortDirection}&sortProperty=${searchRequest.sortProperty}&searchContents=${searchRequest.searchContents}&searchProperty=${searchRequest.searchProperty}&transactionMethod=${searchRequest.transactionMethod}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    }
    async function startAxios() {
      const resultData = await axiosList();

      let resultList = resultData.content;

      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }

      // 체크 옵션 추가
      if (CM.cfnIsNotEmpty(resultList)) {
        for (const element of resultList) {
          element._checked = false;
        }
      }

      setSearchButton(false);
      setOriginalList(resultData);
      setFiltertList(resultList);
      setPagination(fnPagination(resultData));
      setCheckAllRow(false);
      setPaySpecifiedDate("");
      setTotalAskingAmount("");
    }
    if (open && searchButton) {
      startAxios();
    }
  }, [searchRequest, searchButton, open]);

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc 조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    setSearchButton(true);
  };

  // table head checkbox state
  const [checkAllRow, setCheckAllRow] = useState(false);

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    setFiltertList((row) => row.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...filtertList];
    cloneData[index][key] = value;
    setFiltertList(cloneData);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc 값(추가금액/지급지정일) 변경 핸들러
   */
  const handleFormChange = (name, row) => (e) => {
    const tempArray = [...filtertList];
    const targetRow = row.contractUniqueKey;
    let rowIndex = null;

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].contractUniqueKey === targetRow) {
        rowIndex = i;
        break;
      }
    }
    if (name === "totalAskingAmount") {
      tempArray[rowIndex].totalAskingAmount = CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, ""));
    } else {
      tempArray[rowIndex].paySpecifiedDate = e;
    }

    setFiltertList(tempArray);
  };

  const fnSubmit = () => {
    const sendData = []; // 선택 항목 추가
    const url = "api/payment/source/addition/list";
    for (const row of filtertList) {
      if (row._checked) {
        const copyObj = CM.cfnCopyObject(row);
        if (CM.cfnIsNotEmpty(row.paySpecifiedDate) && CM.cfnIsNotEmpty(row.totalAskingAmount)) {
          copyObj.paySpecifiedDate = CM.cfnConvertDateToString(row.paySpecifiedDate);
          copyObj.totalAskingAmount = Number(CM.cfnReplaceSymbol(row.totalAskingAmount));
          sendData.push(copyObj);
        } else {
          CM.cfnAlert("추가할 지급내역의 지급지정일, 추가금액을 입력해주세요");
          return false;
        }
      }
    }

    // 체크된 row가 없다면
    if (CM.cfnIsEmpty(sendData)) return CM.cfnAlert("추가할 항목을 선택해주세요");

    // axios 호출
    CM.cfnAxios(url, "post", sendData, fnCallback);
  };

  const fnCallback = (objStatus, data) => {
    CM.cfnAlert(data, () => {
      setSearchButton(true);
    });
  };

  // 엑셀다운로드
  const fnSavedExcel = () => {
    let url = `/api/payment/source/addition/excel?groupUniqueKey=${searchRequest.groupUniqueKey}&searchContents=${searchRequest.searchContents}&searchProperty=${searchRequest.searchProperty}&transactionMethod=${searchRequest.transactionMethod}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    일괄추가 버튼 클릭 이벤트 핸들러
   */
  const handleClickBulkAdd = () => {
    excelInput.current.click();
  };

  /*
   * @desc    일괄추가 파일 선택 이벤트 핸들러
   */
  const handleSelectExcelFile = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.type.indexOf("vnd.ms-excel") === -1 && file.type.indexOf("spreadsheetml") === -1 && !file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        CM.cfnAlert("엑셀 형식의 파일을 선택하여 주시기 바랍니다.");
        return false;
      } else {
        fnUploadExcel(file);
      }
    }
  };

  /*
   * @desc   일괄추가 Request
   */
  const fnUploadExcel = (file) => {
    const url = "api/payment/source/addition/excel";
    let form = new FormData();
    form.append("file", file);
    CM.cfnAxios(
      url,
      "post",
      form,
      (status, data) => {
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {
          handleClose();
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      }
    );
  };

  // 검색 (rowperpage) handler
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageSize: value,
      pageNumber: 0,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  const setPaySpecifiedDateEntireRow = (e) => {
    setPaySpecifiedDate(e);
    const tempArray = filtertList.map((row) => {
      row.paySpecifiedDate = e;
      return row;
    });

    setFiltertList(tempArray);
  };
  const selectPaySpecifiedDateEntireRow = () => {
    return (
      <>
        지급지정일
        <br />
        <Template.DatePickerForm
          className="w100p"
          value={paySpecifiedDate}
          handleChange={setPaySpecifiedDateEntireRow}
          format="yyyy.MM.dd"
          customProps={{
            placeholder: "YYYY.MM.DD",
            minDate: toDay,
          }}
        />
      </>
    );
  };

  const setTotalAskingAmountEntireRow = (e) => {
    const amount = CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, ""));
    const tempArray = filtertList.map((row) => {
      row.totalAskingAmount = amount;
      return row;
    });

    setFiltertList(tempArray);
    setTotalAskingAmount(amount);
  };
  const totalAskingAmountEntireRow = () => {
    return (
      <>
        추가금액
        <br />
        <TextField
          className="w100p margin-right-10"
          value={totalAskingAmount}
          onChange={(e) => setTotalAskingAmountEntireRow(e)}
          name="search"
          inputProps={{
            style: { textAlign: "right", paddingRight: "10px" },
          }}
        />
      </>
    );
  };
  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>지급 내역 추가</h3>
          <div className="search-area">
            <div className="block">
              <span className="label-l">자금종류</span>
              <Select native name="capitalUniqueKey" value={searchRequest.capitalUniqueKey} onChange={handleSearchFormChange("capitalUniqueKey")}>
                <option value={""} key={"none"}>
                  전체
                </option>
                {capitalUniqueKey.map((option, index) => {
                  return (
                    <option value={option.uniqueKey} key={index}>
                      {option.capitalName}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">고객구분</label>
              <Select native value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
                <option value={""} key={"none"}>
                  전체
                </option>
                {groupUniqueKey.map((option, index) => {
                  return (
                    <option value={option.uniqueKey} key={index}>
                      {option.customerGroupName}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">지급방법</label>
              <Select
                native
                value={searchRequest.transactionMethod}
                onChange={handleSearchFormChange("transactionMethod")}
                name="group"
                inputProps={{ "data-testid": "paymentInformation-select-group" }}>
                {transactionMethod.map((option, index) => {
                  return (
                    <option value={option.value} key={index}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>

              {/*<SelectForm
              value={searchRequest.transactionMethod}
              handleChange={handleSearchFormChange("transactionMethod")}
              name="transactionMethod"
              arrayOption={fnCreatTransactionMethodOption}
              optionValue={"value"}
              optionLabel={"label"}
            />*/}
              <span className="label-l">검색어입력</span>
              <SelectForm
                value={searchRequest.searchProperty}
                handleChange={handleSearchFormChange("searchProperty")}
                name="searchProperty"
                arrayOption={searchProperty}
                optionValue="value"
                optionLabel="label"
              />
              <TextField className="w150" value={searchRequest.searchContents} onChange={handleSearchFormChange("searchContents")} onKeyUp={handleSearchKeyUp} name="search" />
              <button className="search-button" onClick={() => fnSearch()}>
                검색
              </button>
            </div>
          </div>

          <div className="table-top-area">
            <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
            <Template.RowPerPageForm value={pagination.rowsPerPage} onChange={handleRowPerPageChange} />
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleSelectExcelFile(e)}
              ref={excelInput}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
              value=""
              data-testid="select-excel-input"
            />
            <button className="btn-m fr" data-testid="insert-button-test" onClick={(e) => handleClickBulkAdd()}>
              [일괄추가]엑셀파일 등록
            </button>
            <button className="btn-m fr" data-testid="insert-button-test" onClick={(e) => fnSavedExcel()}>
              [일괄추가양식]엑셀저장
            </button>
            <button className="btn-l fr" data-testid="insert-button-test" onClick={(e) => fnSubmit()}>
              선택항목 추가
            </button>
          </div>
          <Table>
            {CM.cfnCompColGroup(["8%", "14%", "12%", "12%", "10%", "14%", "16%", "14%"])}

            <TableForm.compServerSortTableHead
              useCheckbox={true}
              checked={checkAllRow}
              value=""
              onChange={handleCheckAllRowChange}
              arrData={[
                { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
                { id: "IDENTIFICATION_NO", label: "생년월일\n(사업자번호)", sortable: true },
                { id: "identificationNoFirst7", label: "자금종류", sortable: false },
                { id: "TRANSACTION_METHOD", label: "지급방법", sortable: true },
                { id: "accountNo", label: "계좌번호", sortable: false },
                { id: "paySpecifiedDate", label: selectPaySpecifiedDateEntireRow(), sortable: false },
                { id: "totalAskingAmount", label: totalAskingAmountEntireRow(), sortable: false },
              ]}
              searchRequest={searchRequest}
              handleSortProperty={handleSortProperty}
            />
            <TableBody>
              {filtertList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={8} />
              ) : (
                filtertList.map((row, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell align="center">
                        <Checkbox
                          checked={row._checked}
                          value="_checked"
                          id={`checkbox-${index}`}
                          onChange={handleUpdateChange}
                          inputProps={{
                            index: index,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{row.customerName}</TableCell>
                      <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo)}</TableCell>
                      <TableCell align="center">{row.capitalName}</TableCell>
                      <TableCell align="center">{row.transactionMethod !== "CMS_DEPOSIT" ? "기타" : "CMS"}</TableCell>
                      <TableCell align="center">{CM.cfnMasking(row.accountNo)}</TableCell>
                      <TableCell align="center">
                        <Template.DatePickerForm
                          className="w100p"
                          value={row.paySpecifiedDate}
                          handleChange={handleFormChange("paySpecifiedDate", row)}
                          format="yyyy.MM.dd"
                          customProps={{
                            placeholder: "YYYY.MM.DD",
                            minDate: toDay,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Input
                          className="w100p"
                          value={CM.cfnIsNotEmpty(row.totalAskingAmount) ? row.totalAskingAmount : ""}
                          onChange={handleFormChange("totalAskingAmount", row)}
                          name="search"
                          inputProps={{
                            style: { textAlign: "right" },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
        <Pagination
          className="centered"
          limit={pagination.rowsPerPage}
          offset={pagination.offset}
          total={pagination.total}
          onClick={(e, offset, page) => handleOffsetChange(offset, page)}
          reduced={true}
          centerRipple={false}
          disableFocusRipple={true}
          disableRipple={true}
        />
      </div>
    </Modal>
  );
};

export default PaymentSourceCreateModal;
