import React, { useState, useEffect, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableForm from "../../template/TableForm";
import Pagination from "material-ui-flat-pagination";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";

const useStyles = makeStyles({
  modalPaper: {
    width: "1250px",
    maxWidth: "1250px",
    maxHeight: "650px",
  },
});

// 임시 데이터
const nowDate = new Date();

const DEFAULT_HOOKS = {
  searchStart: false,
  cancellationsList: [],
  searchCondition: (fromDate, toDate) => ({
    customerName: "",
    cashbillIdentificationNo: "",
    payerNo: "",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "customerName",
    transactionStartDate: fromDate || CM.cfnGetCustomDate("month", -1, "string"),
    transactionEndDate: toDate || CM.cfnGetDate(),
  }),
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
  searchForm: (fromDate, toDate) => ({
    terms: "customerName",
    input: "",
    transactionStartDate: CM.cfnConvertStringToDate(fromDate) || CM.cfnGetCustomDate("month", -1, "date"),
    transactionEndDate: CM.cfnConvertStringToDate(toDate) || nowDate,
  }),
};

// 부가서비스 > 현금영수증 > 현금영수증 직접발행ㆍ취소 > 취소대상 추가 modal
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabTargetsModalCancellations = (props) => {
  const classes = useStyles();

  // hooks
  const [searchStart, setSearchStart] = useState(DEFAULT_HOOKS.searchStart);
  const [cancellationsList, setCancellationsList] = useState(DEFAULT_HOOKS.cancellationsList);
  const [searchCondition, setSearchCondition] = useState(DEFAULT_HOOKS.searchCondition());
  const [pagination, setPagination] = useState(DEFAULT_HOOKS.pagination);
  const [searchForm, setSearchForm] = useState(DEFAULT_HOOKS.searchForm());

  // handler
  const handleSearchForm = (key, value) => setSearchForm((data) => ({ ...data, [key]: value }));
  const handleSearchCondition = (value) => setSearchCondition(value);
  const handleCancellationsList = (value) => setCancellationsList(value);
  const handleSearchStart = (value) => setSearchStart(value);
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  const handlePage = (page) => {
    handleSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchStart(true);
  };

  const handleRowPerPage = ({ target: { value } }) => {
    handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchStart(true);
  };

  // useCallback
  // hooks 초기화
  const initializeHooks = useCallback(() => {
    setSearchStart(DEFAULT_HOOKS.searchStart);
    setCancellationsList(DEFAULT_HOOKS.cancellationsList);
    setSearchCondition(DEFAULT_HOOKS.searchCondition(props.fromDate, props.toDate));
    setPagination(DEFAULT_HOOKS.pagination);
    setSearchForm(DEFAULT_HOOKS.searchForm(props.fromDate, props.toDate));
  }, [props.fromDate, props.toDate]);

  // 현금영수증 발행취소대상 목록 조회
  const getCancellationsList = useCallback(
    (parameter) => {
      return new Promise((resolve) => {
        let url = "api/extraservice/cashbill/targets/cancellations";
        url += `?pageNumber=${parameter.pageNumber}&pageSize=${parameter.pageSize}`;
        url += `&sortDirection=${parameter.sortDirection}&sortProperty=${parameter.sortProperty}`;
        url += `&transactionStartDate=${parameter.transactionStartDate}&transactionEndDate=${parameter.transactionEndDate}`;
        url += `&fileUniqueKey=${props.summary.uniqueKey}`;
        url += `&customerName=${parameter.customerName}&cashbillIdentificationNo=${parameter.cashbillIdentificationNo}&payerNo=${parameter.payerNo}`;

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    },
    [props.summary]
  );

  // useEffect
  useEffect(() => {
    if (props.modalOpen.cancellations) handleSearchStart(true);
    return () => initializeHooks();
  }, [props.modalOpen.cancellations, initializeHooks]);

  useEffect(() => {
    const startAxios = async (parameter) => {
      const resultData = await getCancellationsList(parameter);
      const content = resultData.content;
      const contentWithCheckAdded = content.map((value) => ({
        ...value,
        _checked: false,
      }));

      handleCancellationsList(contentWithCheckAdded);
      handlePagination(resultData);
    };

    if (searchStart) startAxios(searchCondition);

    return () => handleSearchStart(false);
  }, [searchStart, searchCondition, getCancellationsList]);

  // 선택한 항목 영수증 발행 취소 처리
  const cancelSelection = () => {
    const checkRow = cancellationsList.filter((cancellation) => cancellation._checked);

    if (checkRow.length === 0) {
      CM.cfnAlert("선택된 항목이 없습니다.");
      return false;
    }

    for (const value of checkRow) {
      if(value.cancelReason === null || value.cancelReason === ""){
        CM.cfnAlert("선택하신 항목의 취소 사유를 선택하시기 바랍니다.");
        return false;
      }
      delete value._checked;
    }

    const url = `api/extraservice/cashbill/targets/cancellations/${props.summary.uniqueKey}`;

    CM.cfnAxios(url, "post", checkRow, (status, data) => {
      CM.cfnAlert(data || "정상적으로 처리되었습니다.", () => {
        handleSearchStart(true);
        props.handleSearchStart(true);
        props.handleModalOpen("cancellations", false);
      });
    });
  };

  return (
    <Modal open={props.modalOpen.cancellations}>
      <div className={`paper ${classes.modalPaper}`}>
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpen("cancellations", false)} data-testid="close-cashbill-cancellations-modal">
              {""}
            </Button>
          </div>
          <h3>현금영수증 발행 취소대상 추가</h3>
          <div className="inforbox">
            <ul>
              <li>발행한 현금영수증의 발행 취소는 발행일로부터 6개월 이내만 가능합니다.</li>
              <li>발행을 취소한 내역을 다시 국세청에 전송하셔야, 취소 처리가 됩니다.</li>
            </ul>
          </div>
          <div>
            <SearchComponent
              searchForm={searchForm}
              handleSearchForm={handleSearchForm}
              handleSearchCondition={handleSearchCondition}
              handleSearchStart={handleSearchStart}
              modalOpen={props.modalOpen.cancellations}
            />
          </div>
          <div className="table-top-area">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} customProps={{ inputProps: { "data-testid": "cashbill-cancellations-rowPerPage-select" } }} />
            <button className="btn-l fr table-top-button" onClick={cancelSelection} data-testid="cashbill-cancel-selection-button">
              선택 항목을 발행취소 대상에 추가
            </button>
          </div>
          <div>
            <CancellationsListComponent
              list={cancellationsList}
              handleCancellationsList={handleCancellationsList}
              pagination={pagination}
              handlePage={handlePage}
              searchCondition={searchCondition}
              handleSearchCondition={handleSearchCondition}
              handleSearchStart={handleSearchStart}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

// 검색 컴포넌트
const SearchComponent = (props) => {
  // 선택할 수 있는 최소 날짜 가져오기
  const getMinDate = useCallback(() => {
    const thisDate = CM.cfnGetCustomDate("month", -6, "date");
    thisDate.setDate(thisDate.getDate() + 1);
    return thisDate;
  }, []);

  const optionList = [
    { value: "customerName", label: "고객명" },
    { value: "cashbillIdentificationNo", label: "신분확인번호" },
    { value: "payerNo", label: "납부자번호" },
  ];
  const [minDate, setMinDate] = useState(getMinDate);

  // 취소대상 추가 모달이 오픈될 때마다 선택할 수 있는 최소 날짜 변경
  useEffect(() => {
    if (props.modalOpen.cancellations) setMinDate(getMinDate);
  }, [props.modalOpen.cancellations, getMinDate]);

  const handleSearchKeyUp = ({ keyCode }) => {
    if (keyCode === 13) startSearch();
  };

  // 신청일자 유효성 검사
  const applyDateValidation = (startDate, endDate) => {
    if (!CM.cfnPeriodValidation(startDate, endDate)) {
      return false;
    }

    if (CM.cfnDateCalculation(startDate, minDate) < 0) {
      CM.cfnAlert("현재로부터 6개월 이내만 검색 가능합니다.");
      return false;
    }

    return true;
  };

  const startSearch = async () => {
    const startDate = props.searchForm.transactionStartDate;
    const endDate = props.searchForm.transactionEndDate;

    // 신청일 유효성 검사
    const validation = applyDateValidation(startDate, endDate);
    if (!validation) return false;

    props.handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      transactionStartDate: CM.cfnConvertDateToString(startDate),
      transactionEndDate: CM.cfnConvertDateToString(endDate),
      customerName: props.searchForm.terms === "customerName" ?  props.searchForm.input : "",
      cashbillIdentificationNo : props.searchForm.terms === "cashbillIdentificationNo" ?  props.searchForm.input : "",
      payerNo: props.searchForm.terms === "payerNo" ?  props.searchForm.input : "",
    }));
    props.handleSearchStart(true);
  };

  // 검색 form 데이터 변경을 처리하는 함수
  const handleChange = {
    transactionDate: (date, key) => {
      let diffStartDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() + 1);
      let diffEndDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate() - 1);
      if(key === "transactionStartDate"){
        if (props.searchForm.transactionStartDate > date || date > props.searchForm.transactionEndDate) {
          props.handleSearchForm("transactionStartDate", date);
          props.handleSearchForm("transactionEndDate", diffEndDate > new Date() ? new Date() : diffEndDate);
        } else {
          props.handleSearchForm("transactionStartDate", date);
        }
      } else if (key === "transactionEndDate") {
        if (props.searchForm.transactionStartDate > date || date > props.searchForm.transactionEndDate) {
          props.handleSearchForm("transactionStartDate", diffStartDate < minDate ? minDate : diffStartDate);
          props.handleSearchForm("transactionEndDate", date);
        } else{
          props.handleSearchForm("transactionEndDate", date);
        }
      }
    },
    terms: ({ target: { value }}, key) => {
      props.handleSearchForm(key, value);
      props.handleSearchForm("input", "");
    },
    default: ({ target: { value } }, key) => props.handleSearchForm(key, value),
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">발행일자&nbsp;</label>
        <CF.DatePickerForm
          value={props.searchForm.transactionStartDate}
          handleChange={(date) => handleChange.transactionDate(date, "transactionStartDate")}
          customProps={{
            minDate: minDate,
            maxDate: new Date(),
            style: { width: "140px" },
            inputProps: { "data-testid": "cancellations-datepicker-transactionStartDate" },
          }}
        />
        <span className="between">~</span>
        <CF.DatePickerForm
          value={props.searchForm.transactionEndDate}
          handleChange={(date) => handleChange.transactionDate(date, "transactionEndDate")}
          customProps={{
            minDate: minDate,
            maxDate: new Date(),
            style: { width: "140px" },
            inputProps: { "data-testid": "cancellations-datepicker-transactionEndDate" },
          }}
        />
        <label className="label-l">발행대상</label>
        <Select native value={props.searchForm.terms} onChange={(e) => handleChange.terms(e, "terms")} inputProps={{ "data-testid": "cashbill-cancellations-select-terms" }}>
          {optionList.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <TextField className="w150" value={props.searchForm.input} onChange={(e) => handleChange.default(e, "input")} onKeyUp={handleSearchKeyUp} data-testid="cashbill-cancellations-search-textfield" />
        <button className="search-button" onClick={startSearch} data-testid="cancellations-button-search">
          검색
        </button>
      </div>
    </div>
  );
};

// 발행 취소 대상 목록
const CancellationsListComponent = (props) => {
  const cancelOption = [
    { value: "", label: "사유선택" },
    { value: "TRANSACTION_CANCELED", label: "거래취소" },
    { value: "FALSE_ISSUED", label: "오류발급" },
    { value: "ETC", label: "기타" },
  ];

  // table head checkbox state
  const [checkAllRow, setCheckAllRow] = useState(false);

  // 헤더 체크박스를 선택했을 때 모든 row의 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRow = ({ target: { checked } }) => {
    setCheckAllRow(checked);
    props.handleCancellationsList((data) => data.map((element) => ({ ...element, _checked: checked })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleChecked = ({ target: { value, checked } }, index) => {
    props.handleCancellationsList((data) => data.map((element, idx) => (idx === Number(index) ? { ...element, [value]: checked } : { ...element })));
  };

  // 모든 row가 선택되었을 때 헤더 체크박스 활성화를 처리하는 함수
  const enableHeaderRow = useCallback(() => {
    let checkAllFlag = 0;
    for (const element of props.list) {
      if (element._checked) checkAllFlag++;
    }

    const listLength = props.list.length;

    if (checkAllFlag === listLength && listLength > 0) setCheckAllRow(true);
    else setCheckAllRow(false);
  }, [props.list]);

  useEffect(() => {
    enableHeaderRow();
  }, [enableHeaderRow]);

  // 취소 사유를 선택하면 값 변경과 check 처리를 하는 함수
  const handleCancelReason = ({ target: { value } }, name, index) => {
    const checked = CM.cfnIsEmpty(value) ? false : true;
    props.handleCancellationsList((data) => data.map((element, idx) => (index === idx ? { ...element, [name]: value, _checked: checked } : { ...element })));
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    const sortProperty = sortObjArray.find((obj) => obj.name === "sortProperty").value;
    const sortDirection = sortObjArray.find((obj) => obj.name === "sortDirection").value;

    props.handleSearchCondition((data) => ({ ...data, sortProperty, sortDirection }));

    // 정렬조건 세팅 후 검색 trigger
    props.handleSearchStart(true);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["50px"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRow}
          arrData={[
            { id: "", label: "취소", sortable: false },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "receiptDate", label: "수납일", sortable: true },
            { id: "cashbillIssuedDate", label: "발행일", sortable: true },
            { id: "issuingAmount", label: "발행금액", sortable: true },
            { id: "issuePurposeType", label: "발행구분", sortable: true },
            { id: "transactionType", label: "거래구분", sortable: true },
            { id: "cashbillIdentificationNo", label: "신분확인번호", sortable: false },
          ]}
          searchRequest={props.searchCondition}
          handleSortProperty={handleSortProperty}
          checkboxCustomProps={{ "data-testid": "cashbill-cancellations-head-checkbox" }}
          tableSortLabelDataTestId={"cashbill-cancellations-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">
                    <Checkbox checked={target._checked} value="_checked" onChange={(e) => handleChecked(e, index)} data-testid={`cancellations-checkbox-${index}`} />
                  </TableCell>
                  <TableCell align="center">
                    <CF.SelectForm
                      value={target.cancelReason || ""}
                      handleChange={(e) => handleCancelReason(e, "cancelReason", index)}
                      arrayOption={cancelOption}
                      customProps={{ "data-testid": `cashbill-cancel-cancelReason-${index}` }}
                    />
                  </TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.receiptDate)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(target.cashbillIssuedDate)}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(target.issuingAmount)}</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.purposeType(target.purposeType)}</TableCell>
                  <TableCell align="center">{CDN.cashbillDirectIssueTargetDto.transactionType(target.transactionType)}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.cashbillIdentificationNo)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        className="centered"
        limit={props.pagination.rowsPerPage}
        offset={props.pagination.offset}
        total={props.pagination.total}
        onClick={(e, offset, page) => props.handlePage(page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
        data-testid="cashbill-cancellations-pagination"
      />
    </div>
  );
};

export default CashbillTabTargetsModalCancellations;
