import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Input, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import * as CM from "../../common/Common";
// import CDN from '../../common/CommonDataName';
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

import RegisteredDetailList from "./RegisteredDetailList";
import { Link } from "react-router-dom";

// hooks 초기 값
const DEFAULT_HOOKS = (() => {
  const today = new Date();
  const endDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
  return {
    requestParams: {
      pageNumber: 0, // 요청 페이지번호
      pageSize: 5, // 페이지당 조회건수
      sortDirection: "DESC", // 정렬방법(ASC or DESC)
      sortProperty: "sentDatetime", // 정렬필드
      searchStartDate: monthAgo,
      searchEndDate: endDate,
      searchProperty: "CUSTOMER_NAME",
      searchContents: "",
    },
  };
})();

/*
 * @desc  CMS 출금청구 결과 검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange, handleSearchButtonChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  return (
    <div className="search-area centered">
      <div className="block">
        <label className="label-l" data-testid="request-date-resulttab">
          접수일자
        </label>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.searchStartDate}
          handleChange={handleSearchFormChange("searchStartDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.searchEndDate}
          handleChange={handleSearchFormChange("searchEndDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <label className="label-l">검색어입력</label>
        <Select native name="searchProperty" value={searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")}>
          <option value="CUSTOMER_NAME">고객명</option>
          <option value="PAYER_NO">납부자번호</option>
          <option value="ACCOUNT_NO">계좌번호</option>
        </Select>
        <Input
          value={searchRequest.searchContents}
          onChange={handleSearchFormChange("searchContents")}
          onKeyUp={handleSearchKeyUp}
          name="searchContents"
          className="w120"
          data-testid="cms-request-keyword-resulttab"
        />
        <button className="search-button" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}
// 고객정보관리 > 직접 접수분 > 등록요청 내역 tab
// 렌더를 처리하는 메인 컴포넌트
const RegisteredList = (props) => {
  const { tabCountChange } = props;

  // hooks
  const [searchOn, setSearchOn] = useState(false); // 검색 실행 flag hooks
  const [searchRequest, setSearchRequest] = useState(DEFAULT_HOOKS.requestParams); // 검색 조건 hooks
  const [registeredList, setRegisteredList] = useState([]); // 목록 hooks
  const [pagination, setPagination] = useState(CM.cfnPaginationData()); // 페이지네이션 hooks
  const [selectedFileUniqueKey, setSelectedFileUniqueKey] = useState(""); // 선택된 fileUniqueKey hooks
  const [modalOpen, setModalOpen] = useState(false);

  // 페이지네이션 handler
  const handlePaginationChange = (param) => setPagination(CM.cfnSetPagination(param));

  // 페이지 handler
  const handlePageChange = (e, offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchOn(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = ({ target: { value } }) => {
    setSearchRequest((data) => ({ ...data, pageNumber: 0, pageSize: value }));
    setSearchOn(true);
  };

  // 선택된 fileUniqueKey handler
  const handleSetSelectedFileUniqueKeyChange = (fileUniqueKey) => {
    setSelectedFileUniqueKey(fileUniqueKey);
    setModalOpen(true);
  };

  /*
   * @desc    CMS등록/해지요청 결과 목록 조회 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "searchEndDate" || name === "searchStartDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest((data) => ({ ...data, sortProperty, sortDirection }));

    //정렬조건 세팅 후 검색 trigger
    setSearchOn(true);
  };

  // hooks 초기화
  const initializeHooks = useCallback(() => {
    setRegisteredList([]);
    setPagination(CM.cfnPaginationData());
    setSelectedFileUniqueKey("");
    setModalOpen(false);
  }, []);

  // Axios 실행
  const getAxios = (param) => {
    return new Promise((resolve) => {
      let url = "api/customer/receipt/account/institute/registered";
      url += `?pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
      url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}&searchStartDate=${CM.cfnConvertDateToString(param.searchStartDate)}&searchEndDate=${CM.cfnConvertDateToString(
        param.searchEndDate
      )}&searchProperty=${param.searchProperty}&searchContents=${param.searchContents}`;

      CM.cfnAxios(url, "get", "", (objectStatus, resultData) => resolve(resultData));
    });
  };

  const getRegistered = async (param) => {
    setSearchOn(false); // 검색 실행
    const resultData = await getAxios(param);
    const currentDate = new Date();

    Promise.all(
      resultData.content.map(async (data) => {
        if (data.fileStatus === "TRANSMITTED") {
          const nextDate = await CM.fnGetNextBizDate(data.financialInstituteProcessedDate.substr(0, 8));
          return Promise.resolve({ ...data, preDate: currentDate < new Date(`${nextDate.toString().substr(0, 4)}-${nextDate.toString().substr(4, 2)}-${nextDate.toString().substr(6)}T15:30:00`) });
        } else {
          return Promise.resolve(data);
        }
      })
    ).then((resultList) => setRegisteredList(resultList));

    tabCountChange("registered", resultData.totalElements);
    handlePaginationChange(resultData); // 페이지네이션
  };

  useEffect(() => {
    getRegistered(searchRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 테이블 데이터 조회
  useEffect(() => {
    if (props.tabIndex === 3 && searchOn) {
      getRegistered(searchRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOn, searchRequest, props.tabIndex]);

  // tab이 변경될 때 검색 flag 변경
  useEffect(() => {
    if (props.tabIndex !== 3 && !searchOn) {
      setSearchOn(true);
    }
    return () => initializeHooks();
  }, [props.tabIndex, searchOn, initializeHooks]);

  return (
    <div name="registered-div">
      <div className="inforbox">
        <ul>
          <li>수납고객정보에 등록한 고객의 CMS 계좌에서 출금이 가능하도록 금융기관에 요청한 내용입니다.</li>
          <li>
            고객이 금융기관에 직접 방문하여 계좌를 등록한 내용은{" "}
            <Link to="/customer/registrations" className="link">
              금융기관 접수분
            </Link>
            에서 보실 수 있습니다.
          </li>
        </ul>
      </div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={setSearchOn} />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="registerAccountByInstitute-registered-select-rowPerPage" />
      </div>
      <ListForm
        list={registeredList}
        pagination={pagination}
        handlePageChange={handlePageChange}
        handleSetSelectedFileUniqueKeyChange={handleSetSelectedFileUniqueKeyChange}
        handleSearchOnChange={setSearchOn}
        requestParams={searchRequest}
        handleSortProperty={handleSortProperty}
      />
      <RegisteredDetailList modalOpen={modalOpen} handleModalOpenChange={setModalOpen} searchRequest={searchRequest} selectedFileUniqueKey={selectedFileUniqueKey} financialInstitutes={props.financialInstitutes} />
    </div>
  );
};

// 목록 컴포넌트
const ListForm = (props) => {
  // 요청취소 처리
  const cancelRequest = (fileUniqueKey) => {
    const url = `api/customer/receipt/account/institute/registered/${fileUniqueKey}`;
    CM.cfnAxios(url, "delete", "", (status, data) => {
      CM.cfnAlert("요청이 취소되었습니다.", () => {
        props.handleSearchOnChange(true);
      });
    });
  };

  const confirmButton = (fileStatus, fileUniqueKey, preDate) => {
    let buttonText;
    switch (fileStatus) {
      case "READY":
        buttonText = "요청대기";
        break;
      case "ERROR_ON_TRANSMISSION":
        buttonText = "요청중 실패";
        break;
      case "CONFIRMED_RESULT_FILE":
        buttonText = "확인완료";
        break;
      case "RECEIVED_ERROR_FILE":
        buttonText = "오류응답 수신";
        break;
      case "APPLIED_RESULT_FILE":
        buttonText = "결과확인";
        break;
      case "TRANSMITTED":
        if (preDate) {
          buttonText = "취소요청";
          return (
            <button
              className="btn-l2"
              onClick={(e) => {
                e.stopPropagation();
                cancelRequest(fileUniqueKey);
              }}
              data-testid={`registerAccountByInstitute-registered-button-goDetail-${fileUniqueKey}`}>
              {buttonText}
            </button>
          );
        } else {
          buttonText = "요청완료";
          break;
        }
      default:
        return null;
    }

    return (
      <div
        className={fileStatus === "APPLIED_RESULT_FILE" ? "btn-l2" : ""}
        onClick={() => props.handleSetSelectedFileUniqueKeyChange(fileUniqueKey)}
        data-testid={`registerAccountByInstitute-registered-button-goDetail-${fileUniqueKey}`}>
        {buttonText}
      </div>
    );
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["10%", "10%", "10%", "8%", "8%", "8%", "8%", "8%", "8%", "auto"])}
        <TableForm.compServerSortDoubleRowTableHead
          useCheckbox={false}
          value=""
          rowOne={[
            { id: "financialInstituteProcessedDate", label: "접수일자", sortable: true, rowSpan: 2 },
            { id: "sentDatetime", label: "전송일시", sortable: true, rowSpan: 2 },
            { id: "resultVerifiedDatetime", label: "결과확인 일시", sortable: true, rowSpan: 2 },
            { id: "", label: "등록요청 건수", sortable: false, colSpan: 3 },
            { id: "", label: "처리 건수", sortable: false, colSpan: 3 },
            { id: "", label: "진행상태", sortable: false, rowSpan: 2 },
          ]}
          rowTwo={[
            { id: "totalRequests", label: "전체", sortable: false },
            { id: "totalNewRequests", label: "신규", sortable: false },
            { id: "totalClosingRequests", label: "해지", sortable: false },
            { id: "totalResults", label: "등록", sortable: false },
            { id: "totalNewResults", label: "등록실패", sortable: false },
            { id: "totalClosingResults", label: "해지실패", sortable: false },
          ]}
          searchRequest={props.requestParams}
          tableSortLabelDataTestId={"registerAccountByInstitute-registered-list-head-sortLabel"}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index} className="show-detail" onClick={(e) => props.handleSetSelectedFileUniqueKeyChange(target.fileUniqueKey)}>
                  <TableCell align="center">{CM.cfnDateFormat(target.financialInstituteProcessedDate, "yyyyMMdd")}</TableCell>
                  <TableCell align="center">
                    {target.sentDatetime &&
                      CM.cfnDateFormat(target.sentDatetime)
                        .split("\n")
                        .map((line, i) => (
                          <Fragment key={i}>
                            <span>{line}</span>
                            <br />
                          </Fragment>
                        ))}
                  </TableCell>
                  <TableCell align="center">
                    {target.resultVerifiedDatetime &&
                      CM.cfnDateFormat(target.resultVerifiedDatetime)
                        .split("\n")
                        .map((line, i) => (
                          <Fragment key={i}>
                            <span>{line}</span>
                            <br />
                          </Fragment>
                        ))}
                  </TableCell>
                  <TableCell align="center">{target.totalRequests}</TableCell>
                  <TableCell align="center">{target.totalNewRequests}</TableCell>
                  <TableCell align="center">{target.totalClosingRequests}</TableCell>
                  <TableCell align="center">{target.totalResults}</TableCell>
                  <TableCell align="center">{target.totalNewResults}</TableCell>
                  <TableCell align="center">{target.totalClosingResults}</TableCell>
                  {/*<TableCell align="center">{CDN.fileStatus(target.fileStatus)}</TableCell>*/}
                  <TableCell align="center">{confirmButton(target.fileStatus, target.fileUniqueKey, target.preDate)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <CF.PaginationForm pagination={props.pagination} onClick={props.handlePageChange} testId="registerAccountByInstitute-registered-pagination" />
    </div>
  );
};

export default RegisteredList;
