import React, { useState, useEffect } from "react";
import "../../../styles/extraService.scss";
import { Modal, Button, Checkbox, FormGroup, FormControl, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";

import MailLabelPrintForm from "./MailLabelPrintForm";

import LabelData from "./MailLabelPrintData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

/*
 * @desc    메인 컴포넌트 선언
 */
const PrintPreviewModal = (props) => {
  const { open, setOpen, printSettings, setPrintSettings } = props;

  //필요 state 선언
  const [showPreview, setShowPreview] = useState(true); //미리보기 버튼 flag
  const [targetList, setTargetList] = useState([]); //선택 규격에 따라 생성한 dummy customer 목록
  const [copyPrintSettings, setCopyPrintSettings] = useState({}); //인쇄항목 변경이 바로 미리보기 화면에 반영되지 않도록 state copy
  /*
   * @desc  라벨지 인쇄 방식 변경 이벤트 핸들러
   */
  const handleSettingsChange = (name) => (e) => {
    if (e.target.type === "radio") {
      setPrintSettings({
        ...printSettings,
        [name]: e.target.value,
      });
    } else if (e.target.type === "checkbox") {
      setPrintSettings({
        ...printSettings,
        [name]: e.target.checked,
      });
    }
    handleClickPreview();
  };

  useEffect(() => {
    /*
     * @desc    선택 규격에 따라 dummy customer 목록 생성 함수
     */
    const fnMakeDummyCustomerList = () => {
      let tempArray = [];
      switch (printSettings.size) {
        case "24":
          for (let i = 0; i < 24; i++) {
            tempArray.push(LabelData.dummyCustomer);
          }
          break;

        case "40":
          for (let i = 0; i < 40; i++) {
            tempArray.push(LabelData.dummyCustomer);
          }
          break;

        default:
          for (let i = 0; i < 16; i++) {
            tempArray.push(LabelData.dummyCustomer);
          }
          break;
      }
      setTargetList(tempArray);
    };

    //모달창 열린 상태에서만 규격 계산하여 고객 리스트 생성
    if (open && showPreview) {
      setShowPreview(false);
      setCopyPrintSettings(printSettings);
      fnMakeDummyCustomerList();
    }
  }, [open, showPreview, printSettings, printSettings.size, copyPrintSettings]);

  /*
   * @desc    모달창 닫기 이벤트 핸들러
   */
  const handleClose = () => {
    setOpen(false);
  };

  /*
   * @desc    미리보기 버튼 클릭 이벤트 핸들러
   */
  const handleClickPreview = () => {
    setShowPreview(true);
  };
  return (
    <Modal open={open}>
      <div
        className="paper"
        style={{
          width: "1030px",
        }}>
        <div className="inner d-flex wrap">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>라벨지 미리보기</h3>
          <div className="inforbox">
            <ul>
              <li>
                고객주소는{" "}
                <Link to="/customer/customerInformationList" className="link">
                  고객기본정보
                </Link>
                에 등록된 내용으로 우편번호, 주소를 등록하지 않았다면 라벨지 인쇄 대상에서 제외됩니다.
              </li>
            </ul>
          </div>

          <div className="search-area tl">
            <label className="label-l">규격</label>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" name="position" row>
                <RadioGroup name="size" value={printSettings.size} onChange={handleSettingsChange("size")} data-testid="print-settings-size-in-modal" row>
                  <FormControlLabel value="16" control={<Radio color="primary" />} label="16칸(2x8)" />
                  <FormControlLabel value="24" control={<Radio color="primary" />} label="24칸(3x8)" />
                  <FormControlLabel value="40" control={<Radio color="primary" />} label="40칸(4x10)" />
                </RadioGroup>
              </FormGroup>
            </FormControl>
            <label className="label-l">인쇄항목</label>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" name="position" row>
                <FormControlLabel
                  value={printSettings.printName}
                  control={<Checkbox checked={printSettings.printName} onChange={handleSettingsChange("printName")} color="primary" />}
                  label="고객명"
                  inputprops={{
                    "data-testid": "print-customer-name",
                  }}
                />
                <FormControlLabel
                  value={printSettings.printZipCode}
                  control={<Checkbox checked={printSettings.printZipCode} onChange={handleSettingsChange("printZipCode")} color="primary" />}
                  label="우편번호"
                />
                <FormControlLabel
                  value={printSettings.printAddress}
                  control={<Checkbox checked={printSettings.printAddress} onChange={handleSettingsChange("printAddress")} color="primary" />}
                  label="주소"
                />
                <FormControlLabel
                  value={printSettings.printMobileNo}
                  control={<Checkbox checked={printSettings.printMobileNo} onChange={handleSettingsChange("printMobileNo")} color="primary" />}
                  label="휴대전화번호"
                />
                <FormControlLabel
                  value={printSettings.printTelNo}
                  control={<Checkbox checked={printSettings.printTelNo} onChange={handleSettingsChange("printTelNo")} color="primary" />}
                  label="유선전화번호"
                />
              </FormGroup>
            </FormControl>
          </div>

          <div className="print-outer-box">
            <MailLabelPrintForm className="w100p" targetList={targetList} printSettings={printSettings} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrintPreviewModal;
