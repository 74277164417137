import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PaymentCustomerStatistics from "./PaymentCustomerStatistics";
import PaymentDailyStatistics from "./PaymentDailyStatistics";
import PaymentMonthlyStatistics from "./PaymentMonthlyStatistics";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as CM from "../../common/Common";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
const PaymentStatisticsChart = ({ props }) => {
  const { store } = props;
  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  const [instituteInfoState, setInstituteInfoState] = useState(false);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles(theme => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6
    }
  }));

  const style = useStyles();

  // 기관구분정보 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosPaymentCapitals(); //지급 자금종류
      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      if (businessInfo.cmsService.indexOf("EB31") === -1) {
        await setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 출금이체서비스만 이용하시는 기관입니다.", () => {});
      }
    };

    getStoreDataAxios();
  }, [store]);
  return (
    <div id="paymentStatisticsChartWrapper" data-testid="paymentStatisticsChartWrapper" className={instituteInfoState ? style.disabled_div : ""}>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
          <Tab disableRipple id="customerStatistics" value={0} label="고객별통계(지급정보기준)" />
          <Tab disableRipple id="dailyStatistics" value={1} label="일별통계(지급일기준)" />
          <Tab disableRipple id="monthlyStatistics" value={2} label="월별(지급정보기준)" />
        </Tabs>
      </AppBar>

      <TabPanel value={tabIndex} index={0}>
        <div>
          <div className="inforbox">
            <ul>
              <li>지급 내역을 기준으로 각 고객별로 지급금액, 미지급금액, 청구중금액을 볼 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <PaymentCustomerStatistics tabIndex={tabIndex} />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <div>
          <div className="inforbox">
            <ul>
              <li>조회 조건에 해당하는 지급대상을 조회할 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <PaymentDailyStatistics tabIndex={tabIndex} />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <div>
          <div className="inforbox">
            <ul>
              <li>월별 통계는 대상 년도의 지급건수 및 금액을 월 단위로 제공합니다.</li>
            </ul>
          </div>
        </div>
        <PaymentMonthlyStatistics tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props
}))(observer(PaymentStatisticsChart));
