import React, { useState} from "react";
import { Table, TableBody, TableCell, TableRow, Input } from "@material-ui/core/";
import * as CM from "../../common/Common";
import TabOpenGiroAndPgSendMessageModal from "./TabOpenGiroAndPgSendMessageModal";
import {TooltipForm} from "../../template/ComponentForm";


// 기관 업무정보 > 부가서비스 정보 > 계좌/신용카드 수납(출금 불능분 대상)
// 렌더를 처리하는 메인 컴포넌트
export default function TabOpenGiroAndPg(props) {
  const { instituteService } = props;
  const [openGiroData, setOpenGiroData] = useState(getObject(instituteService, "OPEN_GIRO"));
  const [pgServiceData, setPgServiceData] = useState(getObject(instituteService, "PG_SERVICE"));
  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false); //고객 앞 LMS 발송 내용 화면

  // 계좌/신용카드 기관코드 등록(숫자만 입력 가능하도록)
  const handleChangeCode = ({ target: { value, name } }) => {
    const re = /^\w*$/;

    if (name === "openGiroCode" && (!re.test(value) || value.length > 9)) {
      return;
    }

    if (name === "pgServiceCode" && value.length > 15) {
      return;
    }

    if(name === "openGiroCode") {
      setOpenGiroData((data) => ({...data, [name]: value.replace(/[^0-9]/g, '')}));
    }else if (name === "pgServiceCode"){
      setPgServiceData((data) => ({...data, [name]: value}));
    }
  };

  // 계좌/신용카드 서비스 신청
  const handleStartService = (type) => {
    let sendData;

    if (type === "OPEN_GIRO") {
      sendData = {...openGiroData, serviceUseYn: true, serviceStartDate: CM.cfnGetDate()};
    } else if (type === "PG_SERVICE") {
      sendData = {...pgServiceData, serviceUseYn: true, serviceStartDate: CM.cfnGetDate()};
    }
    axiosService(sendData);
  };


  // 계좌/신용카드 서비스 해지
  const handleTerminateService = (type) => {
    let sendData;

    if(type === "OPEN_GIRO"){
      sendData = { ...openGiroData, serviceUseYn: false, openGiroCode : null };
    }else if(type === "PG_SERVICE"){
      sendData = { ...pgServiceData, serviceUseYn: false, pgServiceCode : null};
    }

    CM.cfnConfirm("출금불능분 즉시납부 서비스를 해지하시겠습니까?\n* 청구중인 내역이 있는 경우, 해지가 불가합니다.\n** 추가로, 결제수단(지로,신용카드PG) 자체의 이용해지를 원하시는 경우, \n고객센터로 문의바랍니다.", () => {axiosService(sendData)});
  };


  // 계좌/신용카드 발송 내용 설정
  const openGiroAndPgServiceSendMessage = () => {
    setSendMessageModalOpen(true);
  }

  // 서비스 신청, 해지 axios
  const axiosService = (sendData) => {
    const url = "api/institute/services";

    CM.cfnAxios(url, "put", sendData, (status, data) => {
      CM.cfnAlert(data, () => {
        if(sendData.serviceType === "OPEN_GIRO"){
          setOpenGiroData(sendData);
          props.updateInstituteService(sendData, "OPEN_GIRO");
        }else if(sendData.serviceType === "PG_SERVICE") {
          setPgServiceData(sendData);
          props.updateInstituteService(sendData, "PG_SERVICE");
        }
      });
    });
  };

  /*
  * @desc  serviceType에 해당하는 object를 반환하는 함수
  */
  function getObject(object, type) {
    for (const element of object) {
      if (element.serviceType === type) {
        return element;
      }
    }
  }

  return (
    <div>
      <div className="modify-area">
        <div className="block">{(openGiroData.serviceUseYn && pgServiceData.serviceUseYn) ? "현재 계좌/신용카드 수납 서비스를 이용 중입니다." :
            (openGiroData.serviceUseYn ? "현재 계좌 서비스를 이용 중입니다." :
                (pgServiceData.serviceUseYn ? "현재 신용카드 서비스를 이용중입니다." :
                    "다음 내용으로 CMS출금 청구한 내역 중 미 출금분에 대한 계좌/신용카드를 활용한 수납 서비스를 신청합니다."))}</div>
      </div>
      <div>
        <h4>서비스 설정</h4>
      </div>
      <div>
        <Table>
          {CM.cfnCompColGroup(["20%", "30%","auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">지로 기관코드
                <TooltipForm contents="금융결제원 지로 기관코드를 입력합니다.(지로 이용기관 등록 필요)"/>
              </TableCell>
              <TableCell className="td">
                {openGiroData.serviceUseYn ? openGiroData.openGiroCode :
                    <Input className="w300" name="openGiroCode" placeholder="지로 기관코드 9자리를 입력해주세요." value={openGiroData.openGiroCode || ""} onChange={handleChangeCode} inputProps={{ "data-testid": "tabOpenGiro-input-openGiroCode" }} />}
              </TableCell>
              <TableCell style={{width: 120}}>
                {openGiroData.serviceUseYn ?
                <button className="btn-l" onClick={() => handleTerminateService(openGiroData.serviceType)} data-testid="tabOpenGiro-button-terminateService"> 서비스 해지</button>
                : <button className="btn-l" onClick={() => handleStartService(openGiroData.serviceType)} data-testid="tabOpenGiro-button-requestService"> 서비스 신청 </button> }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="th">신용카드 기관코드
                <TooltipForm contents="금융결제원 신용카드PG 가맹점ID를 입력합니다. (금융결제원 신용카드PG 가맹점 등록 필요)"/>
              </TableCell>
              <TableCell className="td">
                {pgServiceData.serviceUseYn ? pgServiceData.pgServiceCode :
                    <Input className="w300" name="pgServiceCode" placeholder="신용카드 기관코드 15자리를 입력해주세요." value={pgServiceData.pgServiceCode || ""} onChange={handleChangeCode} inputProps={{ "data-testid": "tabPgService-input-pgServiceCode" }} />}
              </TableCell>
              <TableCell style={{width: 120}}>
                {pgServiceData.serviceUseYn ?
                    <button className="btn-l" onClick={() => handleTerminateService(pgServiceData.serviceType)} data-testid="tabPgService-button-terminateService"> 서비스 해지</button>
                    : <button className="btn-l" onClick={() => handleStartService(pgServiceData.serviceType)} data-testid="tabPgService-button-requestService"> 서비스 신청 </button> }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={`table-bottom-area`}>
        <button className="btn-l" disabled = {(!openGiroData.serviceUseYn && !pgServiceData.serviceUseYn) ? true : false} onClick={() => openGiroAndPgServiceSendMessage()}>
          발송 내용 설정
        </button>
      </div>
      <div className="inforbox">
        <h4>서비스안내</h4>
        <ul>
          <li>출금불능분 즉시납부 서비스는 별도 이용요금이 부과됩니다.</li>
          <li>서비스를 이용하시려면, 우선 지로 또는 신용카드PG에 이용기관(가맹점)으로 등록하셔야합니다.</li>
          <li>지로 또는 신용카드PG에서 부여받은 기관코드 또는 가맹점ID를 입력하세요.</li>
        </ul>
      </div>
      <TabOpenGiroAndPgSendMessageModal open={sendMessageModalOpen} setOpen={setSendMessageModalOpen} data-testid="openGiroAndPgAgreement-open-modal"/>
    </div>

  );
}




