import React, { useState, useEffect } from "react";

import * as CM from "../../common/Common";
import CMData from "../../common/CommonDataName";

import * as CheckForm from "./EvidenceFilePostCheckForm";

import EvidenceFilePostCheckModal from "./EvidenceFilePostCheckModal";
import EvidenceFileBulkModal from "../../template/EvidenceFileBulkModal";
import { inject } from "mobx-react";
import { observer } from "mobx-react";

/*
 * @desc  메인 컴포넌트
 */
const EvidenceFilePostCheck = ({ store }) => {
  //필요 state 선언
  const [postCheckStep, setPostCheckStep] = useState(1); //사후점검 순서 제어\
  const [financialInstitutes, setFinancialInstitutes] = React.useState([]); //금융기관 자동완성 기능을 위한 state

  //요청파일 parsing 제어
  const [uploadFile, setUploadFile] = useState({
    //선택된 사후점검 요청파일 제어
    file: null,
    fileName: "",
  });
  const [ldsReqList, setLdsReqList] = useState([]); //요청파일 parsing 결과 목록 제어
  const [fileHeader, setFileHeader] = useState(""); //응답파일 Header 설정
  const [fileTailer, setFileTailer] = useState(""); //응답파일 Tailer 설정
  const [createDate, setCreateDate] = useState(new Date()); //결과 송신일 제어

  const [selectedFile, setSelectedFile] = useState({
    uniqueKey: "",
    file: null,
    fileName: "",
    fileType: "",
    fileSize: "",
    fileChanged: false,
    index: 0,
  }); //동의자료 등록 모달 전달

  //모달 제어
  const [fileOpen, setFileOpen] = useState(false); //동의자료 등록 모달
  const [bulkModalOpen, setBulkModalOpen] = useState(false); //동의자료 일괄등록 모달

  /*
   * @desc  금융기관 목록 조회 Request
   */
  const fnFetchFinancialInstitutes = () => {
    const url = "/api/gadget/financial-institutes";
    CM.cfnAxios(url, "get", "", (objStatus, objData) => {
      setFinancialInstitutes(objData);
    });
  };

  //화면 진입 시 금융기관 목록 조회
  useEffect(() => {
    if (financialInstitutes.length === 0) fnFetchFinancialInstitutes();
  }, [financialInstitutes]);

  //동의자료 사후점검 1단계 처리 함수 선언 start
  let nowFileNameValue = "";
  let fileContentValue = "";
  let loginDto = typeof CM.storage.get("loginDto") === "object" ? CM.storage.get("loginDto") : JSON.parse(CM.storage.get("loginDto"));

  /*
   * @desc  파일 등록버튼 클릭 이벤트 핸들러
   */
  const handleRegisterFile = () => {
    //파일 선택 여부 체크 추가 등 validation 체크 필요

    if (CM.cfnIsEmpty(uploadFile.fileName) === false) {
      let file = uploadFile.file;

      let file_name_filter = new RegExp("^(AE4119)[0-1][0-9][0-3][0-9](09999)[0-9]{8}$", "g");
      if (!file_name_filter.test(file.name)) {
        CM.cfnAlert("파일명이 올바르지 않습니다. \n정확한 파일을 선택하였는지 다시 한 번 확인하시기 바랍니다.");
        return false;
      } else if (loginDto.cmsInstCode !== file.name.substr(13)) {
        CM.cfnAlert("기관코드 정보가 일치하지 않습니다. \n정확한 파일을 선택하였는지 다시 한 번 확인하시기 바랍니다.");
        return false;
      }

      let fileReader = new FileReader();

      fileReader.onload = () => {
        fnProcessSelectedFile(fileReader.result);
      };
      fileReader.readAsText(file);
    } else {
      // CM.cfnAlert('요청파일을 선택하여 주시기 바랍니다.');
      // return false;
    }
  };

  /*
   * @desc  선택된 요청파일 parsing 전처리 함수
   */
  const fnProcessSelectedFile = (val) => {
    if (val.length % 140 !== 0) {
      CM.cfnAlert("파일 형식이 정해진 포맷과 상이합니다. 파일을 확인하시기 바랍니다.");
      return;
    }

    fileContentValue = val;

    if (nowFileNameValue !== "") {
      CM.cfnConfirm("현재 입력되어있는 파일 및 동의자료의 정보는 모두 사라집니다. 덮어씌우시겠습니까?", fnParseSelectedFile);
    } else {
      fnParseSelectedFile();
    }
  };

  /*
   * @desc  증방자료 사후점검 요청파일 parsing 함수
   */
  const fnParseSelectedFile = async () => {
    if (ldsReqList.length === 0) {
      let lineCount = 0;
      let line = fileContentValue.substring(lineCount, lineCount + 140);
      //헤더영역
      const master = await store.axiosMaster();
      let header;
      let regex = new RegExp("^(AE4119110000000)[0-9]{8}(2)(" + loginDto.cmsInstCode + ")( ){11}(" + master.bizNo + ")[0-9]{7}( ){78}$");

      if (!regex.test(line)) {
        CM.cfnAlert("파일의 Header부분이 정상적인 포맷과 상이합니다.");
        return false;
      }
      header = line;

      let recordCount = line.substring(55, 62) * 1; // 9. 총 요청개수(Data Record개수)
      if (recordCount === 0) {
        CM.cfnAlert("파일의 Header부분이 정상적인 상태가 아닙니다.");
        return false;
      }
      //레코드영역
      regex = new RegExp("^(AE411922)[0-9]{7}[1-2]( ){10}(" + loginDto.cmsInstCode + ")( ){10}[a-zA-Z0-9]|\\s{30}[0-9]{3}[0-9\\s]{20}[0-9]{8}[1-5]( ){32}$");

      for (lineCount++; lineCount < recordCount + 1; lineCount++) {
        line = fileContentValue.substring(lineCount * 140, (lineCount + 1) * 140);
        if (!regex.test(line)) {
          CM.cfnAlert(lineCount + 1 + "번 째 행이 정상적인 포맷과 상이합니다.");
          return false;
        }
        let ldsReqObject = {};
        ldsReqObject["SEQ_NO"] = line.substring(8, 15);
        ldsReqObject["REQ_TYPE"] = line.substring(15, 16);
        ldsReqObject["PAYER_NO"] = line.substring(46, 76);
        ldsReqObject["FNC_NO"] = line.substring(76, 79);
        ldsReqObject["ACCT_NO"] = line.substring(79, 99);
        ldsReqObject["REQ_DATE"] = line.substring(99, 107);
        ldsReqObject["EVDC_TYPE"] = line.substring(107, 108);
        ldsReqObject["EVDC_SUBMIT_YN"] = "N"; //원래Y
        ldsReqList.push(ldsReqObject);
      }
      line = fileContentValue.substring(lineCount * 140, (lineCount + 1) * 140);

      //테일러영역
      regex = new RegExp("^(AE4119339999999)(" + loginDto["cmsInstCode"] + ")( ){10}(" + CM.cfnLpad(recordCount.toString(), 7, "0") + ")( ){98}$");
      if (!regex.test(line)) {
        CM.cfnAlert("파일의 Tailer부분이 정상적인 포맷과 상이합니다.");
        return false;
      }

      //응답파일을 위해 파일의 헤더와 트레일러 저장
      setFileHeader(header);
      setFileTailer(line);

      // txt_NowFileCount = record_count + "건";
      setPostCheckStep(2);
      setLdsReqList(ldsReqList);
    }
    return true;
  };
  //동의자료 사후점검 1단계 처리 함수 선언 end

  //동의자료 사후점검 2단계 처리 함수 선언 start
  /*
   * @desc  상태코드에 따라 테이블 내 '첨부' 버튼을 생성하는 함수
   */
  const fnConverFileStatus = (row, index) => {
    if (row.EVDC_SUBMIT_YN === "N") {
      return (
        <button
          type="button"
          className="btn-s"
          onClick={(e) => {
            setSelectedFile({ ...selectedFile, fileType: row.EVDC_TYPE });
            handleOpenFileModal(row, index);
          }}>
          첨부하기
        </button>
      );
    } else {
      return (
        <button type="button" className="btn-s btn-disabled" onClick={(e) => handleOpenFileModal(row, index)}>
          첨부완료
        </button>
      );
    }
  };

  /*
   * @desc  첨부하기 버튼 클릭 이벤트 핸들러
   */
  const handleOpenFileModal = (row, index) => {
    setSelectedFile((file) => ({
      ...file,
      uniqueKey: ldsReqList[index].PAYER_NO.trim(),
      index: index,
    }));
    setFileOpen(true);
  };

  /*
   * @desc   2단계 모달 첨부파일 해당 row의 EVDC_FILE에 셋팅
   */
  const fnSubmit = () => {
    const index = selectedFile.index;
    ldsReqList[index].EVDC_SUBMIT_YN = "Y";
    ldsReqList[index].EVDC_FILE_NAME = selectedFile.fileName;
    ldsReqList[index].EVDC_FILE_DATA = selectedFile.file;
    ldsReqList[index].EVDC_FILE_LENGTH = selectedFile.fileSize;

    ldsReqList[index].EVDC_TYPE = fnChangeFileType(selectedFile.fileType);
    setFileOpen(false);
  };

  /*
   * @desc   2단계 모달 첨부파일 해당 row의 EVDC_FILE에 셋팅
   */
  const fnBulkSubmit = (fileList) => {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i].evidenceFileList;
      const fileName = file.name.substr(0, file.name.lastIndexOf(".")); //납부자번호

      for (let j = 0; j < ldsReqList.length; j++) {
        if (ldsReqList[j].PAYER_NO.trim() === fileName) {
          ldsReqList[j].EVDC_SUBMIT_YN = "Y";
          ldsReqList[j].EVDC_FILE_NAME = file.name;
          ldsReqList[j].EVDC_FILE_LENGTH = file.size;

          ldsReqList[j].EVDC_TYPE = fnChangeFileType(fileList[i].evidenceFileType);

          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (e) {
            const fileData = e.target.result;
            ldsReqList[j].EVDC_FILE_DATA = fileData;
          };
        }
      }
    }
    setBulkModalOpen(false);
  };

  const fnChangeFileType = (fileType) => {
    switch (fileType) {
      case "PAPER":
        return "1";
      case "PUBLIC_SIGNATURE":
        return "2";
      case "GENERAL_SIGNATURE":
        return "3";
      case "RECORDING":
        return "4";
      case "ARS":
        return "5";
      case "ETC":
        return "6";
      default:
        return fileType;
    }
  };

  /*
   * @desc  동의자료 초기화 버튼 클릭 이벤트 핸들러
   */
  const handleResetEvidenceFile = () => {
    CM.cfnConfirm("등록한 동의자료 정보를 초기화하시겠습니까?", async () => {
      setUploadFile({
        file: null,
        fileName: "",
        fileSrc: "#",
      });
      setLdsReqList([]);
      setPostCheckStep(1);
    });
  };

  /*
   * @desc  등록완료 버튼 클릭 이벤트 핸들러
   */
  const handleProceedRegistration = () => {
    if (ldsReqList.length === 0) {
      CM.cfnAlert("파일을 선택하지 않았거나, 비정상적인 파일이 선택되어있습니다. 파일을 다시 선택해주세요.");
      return false;
    }

    for (const row of ldsReqList) {
      if (row.EVDC_SUBMIT_YN === "N" && CM.cfnIsEmpty(row.EVDC_FILE_NAME)) {
        CM.cfnAlert(`일련번호 ${row.SEQ_NO}인 납부자에 대한 동의자료가 등록되어있지 않습니다. 모든 납부자의 동의자료를 등록해주세요.`);
        return false;
      }
    }

    setPostCheckStep(3);
  };
  //동의자료 사후점검 2단계 처리 함수 선언 end

  //동의자료 사후점검 3단계 처리 함수 선언 start
  /*
   * @desc  결과송신일 변경 이벤트 핸들러
   */
  const handleChangeDate = (e) => {
    setCreateDate(e);
  };

  /*
   * @desc  점검결과 다운로드 버튼 클릭 이벤트 핸들러
   */
  const handleClickDownload = () => {
    if (CM.cfnIsEmpty(createDate) || createDate === "") {
      CM.cfnAlert("결과 송신일을 선택해주세요.");
      return false;
    }

    fnCreateFile();
  };

  /*
   * @desc  동의자료 응답파일 생성 함수
   */
  const fnCreateFile = () => {
    const fileDate = new Intl.DateTimeFormat("en-US", { month: "2-digit", day: "2-digit" }).format(createDate).replace("/", "");
    const fileName = "AE5119" + uploadFile.fileName.substring(6, 10) + fileDate + "099" + loginDto["cmsInstCode"];
    let dataBlockSize = 0;

    //헤더 부분
    let buf = Buffer.from(CM.cfnRpad(fileHeader.replace("AE4119", "AE5119"), 1024, " "));

    //data 부분
    for (const row of ldsReqList) {
      let record = "";
      record +=
        "AE511922" + //1. 업무구분코드(6) + 2.데이터구분코드(2)
        row.SEQ_NO + //3. 일련번호(7)
        row.REQ_TYPE + //4. 요청유형(1)
        "          " + //5. 공백(10)
        loginDto["cmsInstCode"] +
        "          " + //6. 이용기관코드:코드10+공백10(20)
        row.PAYER_NO + //7. 납부자번호(30)
        row.FNC_NO + //8. 금융회사 코드(3)
        row.ACCT_NO + //9. 계좌번호(20)
        row.REQ_DATE + //10. 신청일(8)
        row.EVDC_TYPE + //11. 동의자료 구분(1)
        "          " + //12. 하위 이용기관 코드(10) : space
        "          " + //13. 하위 이용기관 사업자번호(10) : space
        row.EVDC_SUBMIT_YN; //14. 요청결과 여부(1)

      if (row.EVDC_SUBMIT_YN === "N") {
        //미제출인 경우
        record +=
          "     " + //15. 동의자료 확장자(5), 미제출이니 공백
          "0000000" + //16. 동의자료 길이(7), 미제출이니 0
          CM.cfnRpad(" ", 883, " "); //17. 동의자료 Data(883), 미제출이니 공백
        dataBlockSize++;
        buf = Buffer.concat([buf, Buffer.from(record)]);
      } else {
        //제출인 경우
        record += CM.cfnRpad(row.EVDC_FILE_NAME.substring(row.EVDC_FILE_NAME.lastIndexOf(".") + 1), 5, " ") + CM.cfnLpad(row.EVDC_FILE_LENGTH + "", 7, "0");
        buf = Buffer.concat([buf, Buffer.from(record)]);

        const file = row.EVDC_FILE_DATA;
        buf = Buffer.concat([buf, Buffer.from(file)]);
        const size = row.EVDC_FILE_DATA.byteLength;

        //binary 데이터 필러
        if (883 >= size) {
          buf = Buffer.concat([buf, Buffer.from(CM.cfnRpad("", 883 - size, " "))]);
          dataBlockSize++;
        } else {
          let sizeTmp = size;
          sizeTmp = sizeTmp - 883;
          dataBlockSize++;

          dataBlockSize = dataBlockSize + Math.ceil(sizeTmp / 1024);

          buf = Buffer.concat([buf, Buffer.from(CM.cfnRpad("", 1024 - ((size - 883) % 1024), " "))]);
        }
      }
    }

    //tailer 부분
    let trailer = fileTailer.replace("AE4119", "AE5119");
    trailer = trailer.substr(0, 42);
    // trailer += CM.cfnLpad(dataBlockSize.toString(), 10, "0");
    buf = Buffer.concat([buf, Buffer.from(CM.cfnRpad(trailer + CM.cfnLpad(buf.length / 1024 - 1 + "", 10, "0"), 1024, " "))]);

    //파일 다운로드
    if ((navigator.appName === "Netscpe" && navigator.userAgent.search("Trident") !== -1) || navigator.userAgent.toLowerCase().indexOf("msie")) {
      //ie
      const blob = new Blob([new Uint8Array(buf)], { type: "/" });
      const objURL = window.URL.createObjectURL(blob);

      if (window.__Xr_objURL_forCreatingFile__) {
        window.URL.revokeObjectURL(window.__Xr_objURL_forCreatingFile__);
      }

      window.__Xr_objURL_forCreatingFile__ = objURL;

      const fileDown = document.createElement("a");
      fileDown.download = fileName;
      fileDown.href = objURL;
      fileDown.click();
    } else {
      //chrome
      const blob = new Blob([buf], { type: "/", endings: "native" });
      window.navigator.msSaveBlob(blob, fileName);
    }
    if (buf.length % 1024 !== 0) {
      CM.cfnAlert("알 수 없는 오류가 발생했습니다. 첨부된 파일이 정상적이지 않을 수 있습니다. 다시 한번 확인 후 시도해주시기 바랍니다.");
      return false;
    }
  };
  //동의자료 사후점검 3단계 처리 함수 선언 end

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>
            <a href="https://www.payinfo.or.kr/biz" target="_blank" rel="noopener noreferrer" className="link">
              페이인포 비즈
            </a>
            에서 요청한 계좌이체 관련 동의자료를 점검하고 결과 파일을 생성해 제공합니다.
          </li>
        </ul>
      </div>
      <h4>사후 점검 단계</h4>
      <CheckForm.PostCheckStepInfoForm postCheckStep={postCheckStep} />
      <CheckForm.RequestFileForm
        postCheckStep={postCheckStep}
        setLdsReqList={setLdsReqList}
        setPostCheckStep={setPostCheckStep}
        uploadFile={uploadFile}
        setUploadFile={setUploadFile}
        handleRegisterFile={handleRegisterFile}
        handleResetEvidenceFile={handleResetEvidenceFile}
      />
      {postCheckStep === 2 && (
        <CheckForm.Step2ListForm
          ldsReqList={ldsReqList}
          evidenceType={CMData.evidenceFileTypeEnum}
          handleResetEvidenceFile={handleResetEvidenceFile}
          financialInstitutes={financialInstitutes}
          setBulkModalOpen={setBulkModalOpen}
          fnConverFileStatus={fnConverFileStatus}
          handleProceedRegistration={handleProceedRegistration}
        />
      )}
      {postCheckStep === 3 && <CheckForm.Step3DownloadForm handleClickDownload={handleClickDownload} createDate={createDate} handleChangeDate={handleChangeDate} />}

      <EvidenceFilePostCheckModal open={fileOpen} setOpen={setFileOpen} evidenceFile={selectedFile} setEvidenceFile={setSelectedFile} fnSubmitEvidenceFile={fnSubmit} />

      <EvidenceFileBulkModal open={bulkModalOpen} setOpen={setBulkModalOpen} handleSearchButtonChange={""} fnSubmitBulkEvidenceFile={fnBulkSubmit} />
    </div>
  );
};

// export default EvidenceFilePostCheck;
export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(EvidenceFilePostCheck));
