import React from "react";
import Modal from "@material-ui/core/Modal";
import {Button} from "@material-ui/core";

// 기관 업무정보 > 부가서비스 정보 > 계좌/신용카드 수납(출금 불능분 대상)
// 렌더를 처리하는 메인 컴포넌트
const OpenGiroAndPgInfoModal = ({ open, setOpen }) => {
  // 모달 클로즈
  const handleClose = async () => {
    setOpen(false);
  };

  return (
      <div>
      <Modal open={open}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
                {""}
             </Button>
            </div>
            <h3>출금불능분 즉시납부 서비스 안내</h3>
            <div className="inforbox">
              <ul>
                <li> CMS 출금 청구한 내역에 대해 미납이 발생할 경우, 고객에게 문자메시지로 납부링크를 전달하여 지로(계좌이체) 또는 신용카드로 수납할 수 있는 유료 서비스입니다.
                <br/>(단, 수납 대상은 전월 및 금월 미납 내역입니다.)</li>
                <li>
                  [사전신청] 계좌이체 수납을 이용하기 위해서는 장표 지로 이용 신청을, 신용카드 수납을 이용하기 위해서는 신용카드PG 이용신청을 하셔야합니다.&nbsp;&nbsp;
                  <Button className="search-button" onClick={() => window.open('https://e-giro.giro.or.kr')} data-testid="redirect-open-giro">
                    {"지로 이용신청 바로가기"}
                  </Button>
                  <br/> (관련 신청 문의 : 1577-5500 (단축 : 3>2번))
                </li>
                <li>
                  [서비스 신청] 부가서비스 상세 내역에서 계좌/신용카드 수납 서비스를 신청합니다.
                </li>
                <li>
                  [서비스 이용] 서비스 신청 이후 "수납 관리 > 미납관리"에서 서비스를 이용할 수 있습니다.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OpenGiroAndPgInfoModal;


