import React, { useState, useEffect } from "react";
import { Modal, Button } from "@material-ui/core";
import * as CM from "../../common/Common";

import PaperData from "./PaperTaxBillData";
import * as BillForm from "./PaperTaxBillForm";

/*
 * @desc  메인 컴포넌트 선언
 */
const PaperTaxBillIssueModal = ({ open, handleModal, selectedTaxBillsList, setSelectedTaxBillsList, master }) => {
  //필요 state 선언
  const [currentIndex, setCurrentIndex] = useState(0); //현재 선택된 발행 대상 제어
  const [selectedPaperTaxBill, setSelectedPaperTaxBill] = useState({ customer: { customerDetailInfo: {} } });
  const [isAmountChanged, setIsAmountChanged] = useState(false); //합계금액 계산 필요 여부 제어
  const [inputMethod, setInputMethod] = useState("unitPrice"); //공급내역 - 입력유형 제어
  const [flag, setFlag] = useState(true);
  const [isContentsChanged, setIsContentsChanged] = useState(false); // 내용 변경 시 체크 용도

  /*
   * @desc  종이(세금)계산서 특정건 조회 Request
   */

  //화면 진입 시 선택된 종이(세금)계산서의 uniqueKey로 개별 데이터 조회
  useEffect(() => {
    //발행정보 조회 api호출
    const getAxios = (parameter) => {
      return new Promise((resolve) => {
        let url = `api/extraservice/taxbill/paper/targets/detail?taxbillDirection=${parameter.taxbillDirection}&taxbillType=${parameter.taxbillType}`;
        url += parameter.taxbillUniqueKey ? "&taxbillUniqueKey=" + parameter.taxbillUniqueKey : "";
        url += parameter.sourceUniqueKey ? "&sourceUniqueKey=" + parameter.sourceUniqueKey : "";
        url += parameter.historyUniqueKey ? "&historyUniqueKey=" + parameter.historyUniqueKey : "";

        CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
      });
    };

    const fnFetchIssueTargetList = async () => {
      let tempObjList = [];
      for (let index = 0; index < selectedTaxBillsList.length; index++) {
        const selectedTaxBill = selectedTaxBillsList[index];

        let data = await getAxios(selectedTaxBill);
        //조회 성공 시 개별 데이터 setting
        if (CM.cfnIsEmpty(data.issueDate)) data.issueDate = new Date();
        data.paperTaxbillItemDtos = PaperData.fnCreatePaperTaxBillDetails(data.paperTaxbillItemDtos);
        data.totalAmount = data.totalSuppliedAmount + data.totalTaxAmount;
        if (data.instMainBookNumber === null) {
          data.instMainBookNumber = 1;
        }
        if (data.instSubBookNumber === null) {
          data.instSubBookNumber = 1;
        }
        tempObjList.push(data);
      }
      if (tempObjList.length > 0) {
        setSelectedPaperTaxBill(tempObjList[0]);
        setSelectedTaxBillsList(tempObjList);
      }
    };

    //모달 처음 열릴시 초기화
    if (open === true && flag === true) {
      setSelectedPaperTaxBill({ customer: { customerDetailInfo: {} } });
      setFlag(false);
    } else if (open === true && flag === false) {
      //모달 초기화된 후 넘어온 리스트의 uniqueKey가 있을 경우 api조회를 호출 후 상세정보 모달에 표현
      if (selectedTaxBillsList.length !== 0) {
        //실행영역
        if (CM.cfnIsEmpty(selectedPaperTaxBill) || typeof selectedPaperTaxBill.taxbillUniqueKey === "undefined") {
          fnFetchIssueTargetList();
        }
      }
    }
  }, [open, currentIndex, selectedTaxBillsList, selectedPaperTaxBill, setSelectedPaperTaxBill, setSelectedTaxBillsList, flag]);

  //공급내역 내 숫자 입력 시 합계금액 재계산
  useEffect(() => {
    if (isAmountChanged) {
      const tempObjList = CM.cfnCopyObject(selectedTaxBillsList);
      const tempObj = CM.cfnCopyObject(selectedPaperTaxBill);

      let totalSuppliedAmount = 0;
      let totalTaxAmount = 0;
      let totalAmount = 0;

      for (const row of selectedPaperTaxBill.paperTaxbillItemDtos) {
        totalSuppliedAmount += row.itemSupplyAmount;
        if (selectedPaperTaxBill.taxbillType === "PAPER") {
          totalTaxAmount += row.itemTaxAmount;
        }
      }

      //계산서(TAXFREE)의 세액은 없음
      if (selectedPaperTaxBill.taxbillType === "PAPER") {
        totalAmount = totalSuppliedAmount + totalTaxAmount;
      } else if (selectedPaperTaxBill.taxbillType === "TAXFREE") {
        totalAmount = totalSuppliedAmount;
        totalTaxAmount = "";
      }

      tempObj["totalSuppliedAmount"] = totalSuppliedAmount;
      tempObj["totalTaxAmount"] = totalTaxAmount;
      tempObj.totalAmount = totalAmount;
      tempObjList[currentIndex] = tempObj;

      setIsAmountChanged(false);
      setSelectedPaperTaxBill({
        ...selectedPaperTaxBill,
        totalSuppliedAmount: totalSuppliedAmount,
        totalTaxAmount: totalTaxAmount,
        totalAmount: totalAmount,
      });
      setSelectedTaxBillsList(tempObjList);
    }
  }, [isAmountChanged, currentIndex, selectedPaperTaxBill, selectedTaxBillsList, setSelectedPaperTaxBill, setSelectedTaxBillsList]);

  /*
   * @desc  발행 서식 상단 - 이전/다음 버튼 클릭 이벤트 핸들러
   */
  const handleChangeSelected = (type) => {
    let tempValue = currentIndex;
    const tempObjList = CM.cfnCopyObject(selectedTaxBillsList);
    if (isContentsChanged) {
      CM.cfnConfirm(
        "저장되지 않은 내용은 사라집니다. 계속하시겠습니까?",
        () => {
          setIsContentsChanged(false);
          if (type === "prev") {
            //이전버튼 클릭 시
            setSelectedPaperTaxBill(tempObjList[tempValue - 1]);
            setCurrentIndex(--tempValue);
          } else if (type === "next") {
            //다음버튼 클릭 시
            if (tempObjList[tempValue + 1].customerIdentificationNo !== undefined) {
              //이미 taxbillUniqueKey로 발행내역을 조회해 왔다면
              setSelectedPaperTaxBill(tempObjList[tempValue + 1]);
            } else {
              //조회한적이 없다면 selectedPaperBill을 초기화하여 useEffect에서 조회api호출
              setSelectedPaperTaxBill({ customer: { customerDetailInfo: {} } });
            }
            setCurrentIndex(++tempValue);
          }
        },
        () => {}
      );
    } else {
      if (type === "prev") {
        //이전버튼 클릭 시
        setSelectedPaperTaxBill(tempObjList[tempValue - 1]);
        setCurrentIndex(--tempValue);
      } else if (type === "next") {
        //다음버튼 클릭 시
        if (tempObjList[tempValue + 1].customerIdentificationNo !== undefined) {
          //이미 taxbillUniqueKey로 발행내역을 조회해 왔다면
          setSelectedPaperTaxBill(tempObjList[tempValue + 1]);
        } else {
          //조회한적이 없다면 selectedPaperBill을 초기화하여 useEffect에서 조회api호출
          setSelectedPaperTaxBill({ customer: { customerDetailInfo: {} } });
        }
        setCurrentIndex(++tempValue);
      }
    }
  };

  /*
   * @desc  종이(세금)계산서 발행 버튼 클릭 이벤트 핸들러
   */
  const handleClickIssuePaperTaxBill = () => {
    if (fnValidation()) {
      CM.cfnConfirm("발행하시겠습니까?", fnIssuePaperTaxBill);
    }
  };

  /*
   * @desc  종이(세금)계산서 발행 버튼 클릭 이벤트 핸들러
   */
  const handleClickSavePaperTaxBill = () => {
    if (fnValidation()) {
      fnSavePaperTaxBill();
    }
  };

  /*
   * @desc  종이(세금)계산서 발행 서식 내 입력란 변경 이벤트 핸들러
   */
  const handlePaperTaxBillFormChange = (name, index, type) => async (e) => {
    const tempObj = CM.cfnCopyObject(selectedPaperTaxBill);
    const tempObjList = CM.cfnCopyObject(selectedTaxBillsList);
    let tempValue;

    if (name === "totalAmount") {
      //합계금액 입력 시 공급가액 및 세액 자동계산
      tempValue = Number(e.target.value.replace(/[^0-9]/g, ""));

      tempObj.paperTaxbillItemDtos[0].itemSupplyAmount = Math.round(tempValue / 1.1);
      if (inputMethod === "sum") {
        tempObj.paperTaxbillItemDtos[0].itemUnitPrice = tempObj.paperTaxbillItemDtos[0].itemSupplyAmount;
      }
      if (tempObj.taxbillType === "PAPER") {
        tempObj.paperTaxbillItemDtos[0].itemTaxAmount = Math.round(tempValue / 1.1 / 10);
      } else if (tempObj.taxbillType === "TAXFREE") {
        tempObj.paperTaxbillItemDtos[0].itemTaxAmount = "";
      }
      tempObj.totalAmount = tempValue;
      tempObj.totalSuppliedAmount = tempObj.paperTaxbillItemDtos[0].itemSupplyAmount;
      tempObj.totalTaxAmount = tempObj.paperTaxbillItemDtos[0].itemTaxAmount;
    } else if (CM.cfnIsEmpty(index)) {
      //합계금액 or 공급내역 외 다른 입력란 변경 시
      switch (name) {
        case "issueDate":
          //작성년월일
          tempValue = e;
          break;
        case "instSubBookNumber":
        case "cashAmount":
        case "chequeAmount":
        case "noteAmount":
        case "unpaidAmount":
          //책번호의 호 또는 결제방법 내 현금,수표,어음,외상미수금 변경 시
          tempValue = Number(e.target.value.replace(/[^0-9]/g, ""));
          break;
        default:
          tempValue = e.target.value;
          break;
      }

      tempObj[name] = tempValue;
    } else {
      //공급내역 상세항목 입력 시
      if (type === "amount") {
        //금액 계산과 관련된 입력란 변경 시 선택된 입력항목에 따라 자동계산 처리
        switch (inputMethod) {
          case "unitPrice":
            if (name === "itemQuantity") {
              //수량 입력 시
              tempValue = Number(e.target.value.replace(/[^0-9]/g, ""));

              const unitPrice = tempObj.paperTaxbillItemDtos[index].itemUnitPrice;
              const supplyAmount = tempValue * unitPrice;
              const taxAmount = Math.round(supplyAmount * 0.1);

              tempObj.paperTaxbillItemDtos[index].itemQuantity = tempValue;
              tempObj.paperTaxbillItemDtos[index].itemSupplyAmount = supplyAmount;
              if (tempObj.taxbillType === "PAPER") {
                tempObj.paperTaxbillItemDtos[index].itemTaxAmount = taxAmount;
              } else if (tempObj.taxbillType === "TAXFREE") {
                tempObj.paperTaxbillItemDtos[index].itemTaxAmount = "";
              }
            } else {
              //단가 입력 시
              tempValue = Number(e.target.value.replace(/[^0-9]/g, ""));

              const qty = tempObj.paperTaxbillItemDtos[index].itemQuantity;
              const supplyAmount = tempValue * qty;
              const taxAmount = Math.round(supplyAmount * 0.1);

              tempObj.paperTaxbillItemDtos[index].itemUnitPrice = tempValue;
              tempObj.paperTaxbillItemDtos[index].itemSupplyAmount = supplyAmount;
              if (tempObj.taxbillType === "PAPER") {
                tempObj.paperTaxbillItemDtos[index].itemTaxAmount = taxAmount;
              } else if (tempObj.taxbillType === "TAXFREE") {
                tempObj.paperTaxbillItemDtos[index].itemTaxAmount = "";
              }
            }
            break;
          case "supply":
            //공급가액 입력 시
            tempValue = Number(e.target.value.replace(/[^0-9]/g, ""));

            tempObj.paperTaxbillItemDtos[index].itemSupplyAmount = tempValue;
            if (tempObj.taxbillType === "PAPER") {
              tempObj.paperTaxbillItemDtos[index].itemTaxAmount = Math.round(tempValue * 0.1);
            } else if (tempObj.taxbillType === "TAXFREE") {
              tempObj.paperTaxbillItemDtos[index].itemTaxAmount = "";
            }
            break;
          case "whole":
            //전체입력 선택 시 계산없이 사용자 입력값을 그대로 받아 저장
            tempObj.paperTaxbillItemDtos[index][name] = Number(e.target.value.replace(/[^0-9]/g, ""));
            break;

          default:
            break;
        }

        //변경된 금액을 토대로 합계금액 계산하도록 flag 변경
        setIsAmountChanged(true);
      } else {
        //공급내역 입력란 중 금액 계산과 무관한 항목 변경 시
        if (name === "month" || name === "day") {
          //월 또는 일 입력 시
          tempValue = e.target.value.replace(/[^0-9]/g, "");
        } else {
          tempValue = e.target.value;
        }

        tempObj.paperTaxbillItemDtos[index][name] = tempValue;
      }
    }
    tempObjList[currentIndex] = tempObj;
    setSelectedPaperTaxBill(tempObj);
    setSelectedTaxBillsList(tempObjList);
    setIsContentsChanged(true);
  };

  /*
   * @desc  공급내역 입력방법 변경 이벤트 핸들러
   */
  const handleInputMethodChange = (e) => {
    const targetValue = e.target.value;

    switch (targetValue) {
      case "unitPrice":
        CM.cfnConfirm("수량과 단가를 입력하시면 공급가액과 세액, 합계금액이 자동계산됩니다.\r\n\r\n계속하시겠습니까?", () => {
          setInputMethod(targetValue);
        });
        break;
      case "supply":
        CM.cfnConfirm("공급가액을 입력하시면 세액과 합계금액이 자동 계산됩니다.\r\n수량과 단가는 입력이 불가능합니다.\r\n\r\n계속하시겠습니까?", async () => {
          // await fnClearInput(targetValue);
          setInputMethod(targetValue);
        });
        break;
      case "whole":
        CM.cfnConfirm("공급가액과 세액은 반드시 입력하셔야 합니다.\r\n\r\n계속하시겠습니까?", () => {
          setInputMethod(targetValue);
        });
        break;
      case "sum":
        CM.cfnConfirm(
          "합계금액을 입력하시면 공급가액과 세액이 자동 계산됩니다.\r\n\r\n수량과 단가는 입력이 불가능하며 기존에 작성중이던 데이터는 모두 지워집니다.\r\n\r\n계속하시겠습니까?",
          async () => {
            fnClearInput(targetValue);
            setInputMethod(targetValue);
          }
        );
        break;
      default:
        break;
    }
  };

  /*
   * @desc  공급내역 입력란 초기화 함수
   */
  const fnClearInput = (method) => {
    let tempObj = CM.cfnCopyObject(selectedPaperTaxBill);

    if (method === "supply") {
      //공급가액 입력 선택 시 -> 수량 및 단가 입력란 초기화
      for (const row of tempObj.paperTaxbillItemDtos) {
        row.itemQuantity = 0;
        row.itemUnitPrice = 0;
      }
    } else if (method === "sum") {
      //합계금액 입력 선택 시 -> 공급내역 입력란 전체 초기화
      tempObj.paperTaxbillItemDtos.forEach((row, i) => {
        if (i === 0) {
          row.itemQuantity = 1;
          row.itemTaxAmount = Math.floor(tempObj.totalAmount / 11.0);
          row.itemUnitPrice = tempObj.totalAmount - tempObj.paperTaxbillItemDtos[0].itemTaxAmount;
          row.itemSupplyAmount = tempObj.paperTaxbillItemDtos[0].itemUnitPrice;
        } else {
          row.month = "";
          row.day = "";
          row.itemName = "";
          row.itemSpecification = "";
          row.itemQuantity = 0;
          row.itemUnitPrice = 0;
          row.itemSupplyAmount = 0;
          row.itemTaxAmount = 0;
          row.itemRemark = "";
        }
      });
    }

    setSelectedPaperTaxBill(tempObj);
  };

  /*
   * @desc  계산서 발행 전 validation 체크 함수
   */
  const fnValidation = () => {
    if (CM.cfnIsEmpty(selectedPaperTaxBill.totalAmount) || selectedPaperTaxBill.totalAmount === 0) {
      CM.cfnAlert("금액이 입력된 내용이 없습니다.");
      return false;
    }

    //결제내역 내 입력된 금액과 합계금액 검증
    const tempSum = Number(selectedPaperTaxBill.cashAmount) + Number(selectedPaperTaxBill.chequeAmount) + Number(selectedPaperTaxBill.noteAmount) + Number(selectedPaperTaxBill.unpaidAmount);
    if (tempSum !== Number(selectedPaperTaxBill.totalAmount)) {
      CM.cfnAlert("(현금+수표+어음+외상미수금)이 합계금액과 다릅니다.");
      return false;
    }

    if (!selectedPaperTaxBill.paperTaxbillItemDtos || selectedPaperTaxBill.paperTaxbillItemDtos.length === 0) {
      CM.cfnAlert("발행 상세내역을 입력해주세요.");
      return false;
    }

    let isContentsExists = false;
    //공급내역 내 입력된 날짜 검증
    for (let i = 0; i < selectedPaperTaxBill.paperTaxbillItemDtos.length; i++) {
      const supplyAmount = selectedPaperTaxBill.paperTaxbillItemDtos[i].itemSupplyAmount;
      if (CM.cfnIsNotEmpty(supplyAmount) && supplyAmount !== 0) {
        const month = Number(selectedPaperTaxBill.paperTaxbillItemDtos[i].month);
        const day = Number(selectedPaperTaxBill.paperTaxbillItemDtos[i].day);

        if (!month || month > 13 || month < 1) {
          CM.cfnAlert(`${i + 1}번째 세부항목의 월이 잘못 되었습니다.`);
          return false;
        }

        if (!day || day > 31 || day < 1) {
          CM.cfnAlert(`${i + 1}번째 세부항목의 일이 잘못 되었습니다.`);
          return false;
        }
        isContentsExists = true;
      }
    }

    if (!isContentsExists) {
      CM.cfnAlert("발행 상세내역을 입력해주세요.");
      return false;
    }
    return true;
  };

  /*
   * @desc    전송 전 state 처리 함수
   * 계산서 타입(세금계산서,계산서), 납부자번호, 발행일자, 공급내역(DTO)를 하나의 Object리스트객체에 담아 반환한다.
   */
  const fnConvertForRequest = () => {
    const tempObjList = CM.cfnCopyObject(selectedTaxBillsList);
    for (let i = 0; i < tempObjList.length; i++) {
      tempObjList[i] = adjustParameters(tempObjList[i]);
    }
    return tempObjList;
  };

  const adjustParameters = (tempObj) => {
    const tempArray = [];

    if (tempObj.billRemark === null) {
      tempObj.billRemark = "";
    }
    tempObj.issueDate = CM.cfnConvertDateToString(tempObj.issueDate);
    for (const row of tempObj.paperTaxbillItemDtos) {
      if (Number(row.itemSupplyAmount) > 0) {
        tempArray.push(row);
      }
    }
    tempObj.paperTaxbillItemDtos = tempArray;
    return tempObj;
  };

  /*
   * @desc  계산서 발행 Request 함수
   * 발행완료 후 발행된 리스트의 상세정보값을 모달을 닫으며 메인컴포넌트(paperTaxBillPublishTargetTab)으로 보낸다.
   * 발행모달안에 보여주었던 정보를 초기화한다.
   */
  const fnIssuePaperTaxBill = () => {
    const paramObj = fnConvertForRequest();
    const url = "api/extraservice/taxbill/paper/targets";
    CM.cfnAxios(url, "post", paramObj, (status, data) => {
      if (status.status === 200) {
        CM.cfnAlert("정상적으로 처리되었습니다.", async () => {
          let taxbillsList = CM.cfnCopyObject(selectedTaxBillsList);
          for (const copyObj of taxbillsList) {
            copyObj.instSerialNumberArray = PaperData.fnConvertSerialNumber(copyObj.instSerialNumber);
            copyObj.totalSuppliedAmountArray = PaperData.fnConvertAmountArray(copyObj.totalSuppliedAmount, copyObj.taxbillType === "PAPER" ? 12 : 9);
            copyObj.totalTaxAmountArray = PaperData.fnConvertAmountArray(copyObj.totalTaxAmount, 11);
            copyObj.paperBillDetails = PaperData.fnCreatePaperTaxBillDetails(copyObj.paperTaxbillItemDtos);
          }
          setSelectedPaperTaxBill({ customer: { customerDetailInfo: {} } });
          handleModal("print", false, taxbillsList);
        });
      } else {
        CM.cfnAlert(typeof data === "string" ? data : data.message || "(세금)계산서를 발행하지 못했습니다.");
      }
    });
  };

  /*
   * @desc  계산서 저장 Request 함수
   * 정보 임시저장 요청
   */
  const fnSavePaperTaxBill = () => {
    const paramObj = adjustParameters(CM.cfnCopyObject(selectedPaperTaxBill));
    const url = "api/extraservice/taxbill/paper/targets/detail";
    CM.cfnAxios(url, "put", paramObj, (status, data) => {
      if (status.status === 200) {
        setIsContentsChanged(false);
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {});
      } else {
        CM.cfnAlert(typeof data === "string" ? data : data.message || "(세금)계산서 정보를 임시저장하지 못했습니다.");
      }
    });
  };

  // 모달 클로즈
  // 모달을 닫으며 모달안에 보여주었던 내용을 초기화한다.
  const handleClose = (type, open) => {
    setSelectedPaperTaxBill({ customer: { customerDetailInfo: {} } });
    setSelectedTaxBillsList([]);
    handleModal(type, open);
  };

  //화면 렌더
  return (
    <Modal open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={(e) => handleClose("close", false)} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>{selectedPaperTaxBill.taxbillType === "PAPER" ? "종이세금계산서" : "종이계산서"} 발행</h3>
          <BillForm.CustomerSelect
            master={master}
            selectedBillsList={selectedTaxBillsList}
            selectedPaperTaxBill={selectedPaperTaxBill}
            currentIndex={currentIndex}
            handleChangeSelected={handleChangeSelected}
          />
          <div className="h30" />
          <BillForm.CustomerInfo master={master} selectedPaperTaxBill={selectedPaperTaxBill} handlePaperTaxBillFormChange={handlePaperTaxBillFormChange} />
          <div className="h30" />
          <BillForm.ReceiptInfo selectedPaperTaxBill={selectedPaperTaxBill} handlePaperTaxBillFormChange={handlePaperTaxBillFormChange} />
          <div className="h30" />
          <BillForm.TaxBillDetails
            selectedPaperTaxBill={selectedPaperTaxBill}
            handlePaperTaxBillFormChange={handlePaperTaxBillFormChange}
            inputMethod={inputMethod}
            handleInputMethodChange={handleInputMethodChange}
          />
          <div className="h30" />
          <BillForm.PaymentMethod selectedPaperTaxBill={selectedPaperTaxBill} handlePaperTaxBillFormChange={handlePaperTaxBillFormChange} />
          <div className="table-bottom-area centered">
            <button className="btn-m" onClick={handleClickSavePaperTaxBill} data-testid="paper-tax-bill-save">
              임시저장
            </button>
            <button className="btn-l" onClick={handleClickIssuePaperTaxBill} data-testid="paper-tax-bill-issue">
              {selectedPaperTaxBill.taxbillType === "PAPER" ? "세금계산서" : "계산서"} 전체 발행
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PaperTaxBillIssueModal;
