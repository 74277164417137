import React, { useState, useEffect, useCallback, Fragment } from "react";
import { observer, inject } from "mobx-react";
import {Table, TableBody, TableRow, TableCell, TextField, Select} from "@material-ui/core";

import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

import * as StatisticsChartEnum from "./StatisticsChartEnum";

// TODO:
// 양과장님이 만드셨던 파일입니다.
// 치명적인 에러, 버그 모두 수정했습니다.

// 초기 검색 조건
const defaultSearchParam = () => {
  const targetYear = new Date().getFullYear();
  return {
    searchContents: "", //검색내용
    searchProperty: "CUSTOMER_NAME", //검색대상 - CUSTOMER_NAME(고객명), IDENTIFICATION_NO(고객식별번호), PAYER_NO(납부자번호)
    transactionMethod: "", //수납방법 - null 또는 empty(전체), CMS_WITHDRAW(CMS출금), ETC(기타수납)
    capitalUniqueKey: "", //자금종류key
    customerGroupUniqueKey: "", //고객구분key
    sortProperty: "CUSTOMER_NAME", //정렬기준 -  CUSTOMER_NAME(고객명), PAYER_NO(납부자번호)
    sortDirection: "ASC", //정렬방향 - ASC(오름차순), DESC(내림차순)
    targetYear,
    pageNumber: "0", //페이지 번호
    pageSize: "5", //페이지 크기
  };
};

// 통계연도 selectbox에 들어갈 option data
const getStatisticalYear = (startYear = 2005) => {
  const arrayYear = [];
  const lastYear = new Date().getFullYear();

  for (let year = lastYear; year >= startYear; year--) {
    const label = `${year}년`;
    const value = String(year);

    arrayYear.push({ label, value });
  }

  return arrayYear;
};

// 수납통계 > 고객별통계(수납정보기준)
// 렌더를 처리하는 메인 컴포넌트
function CustomerStatistics(props) {
  const { store, tabIndex } = props;

  const [searchParam, setSearchParam] = useState(defaultSearchParam());
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [topList, setTopList] = useState([]);
  const [bottomList, setBottomList] = useState([]);
  const [pagination, setPagination] = useState(CM.cfnPaginationData());

  // 2019/12/13
  // 기존 파일이 너무나도 엉망이라 전체 수정
  // 검색 중 필요없이 state setting하고 store axios 전송해서 메모리 낭비하던 부분 수정
  // 검색조건 수정 후 검색버튼을 누르지 않고 페이지 이동 시 해당 검색조건이 바로 적용되는 bug 수정
  const [selectOptionCapitals, setSelectOptionCapitals] = useState([]); // 자금종류 selectbox option data
  const [selectOptionCustomerGroup, setSelectOptionCustomerGroup] = useState([]); //고객구분 selectbox option data
  const selectOptionYear = getStatisticalYear(); // 통계연도 selectbox option data

  useEffect(() => {
    // 자금종류 selectbox에 들어갈 option data
    const getStoreCapital = async (store) => {
      const capital = await store.axiosCapitals();
      const arrayCapital = [{ label: "전체", value: "", key: "" }];

      await capital
        .filter((value) => value.capitalType === "RECEIPT")
        .map((filterValue) => arrayCapital.push({ label: filterValue.capitalName, value: filterValue.uniqueKey, key: filterValue.uniqueKey }));

      setSelectOptionCapitals(arrayCapital);
    };

    const getStoreCustomerGroup  = async  (store) => {
      const customerGroup = await store.axiosCustomerGroup(); // 고객구분
      setSelectOptionCustomerGroup(customerGroup);
    }

    getStoreCapital(store);
    getStoreCustomerGroup(store);
  }, [store]);

  // topList를 가져오는 axios
  const getTopListAxios = useCallback((parameters) => {
    return new Promise((resolve) => {
      let url = "api/receipt/statistics/customer/summary";
      url += `?capitalUniqueKey=${parameters.capitalUniqueKey}`;
      url += `&customerGroupUniqueKey=${parameters.customerGroupUniqueKey}`;
      url += `&searchContents=${parameters.searchContents}&searchProperty=${parameters.searchProperty}`;
      url += `&targetYear=${parameters.targetYear}&transactionMethod=${parameters.transactionMethod}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  // bottomList를 가져오는 axios
  const getBottomListAxios = useCallback((parameters) => {
    return new Promise((resolve) => {
      let url = "api/receipt/statistics/customer/";
      url += `?capitalUniqueKey=${parameters.capitalUniqueKey}`;
      url += `&customerGroupUniqueKey=${parameters.customerGroupUniqueKey}`;
      url += `&searchContents=${parameters.searchContents}&searchProperty=${parameters.searchProperty}`;
      url += `&targetYear=${parameters.targetYear}&transactionMethod=${parameters.transactionMethod}`;
      url += `&pageNumber=${parameters.pageNumber}&pageSize=${parameters.pageSize}`;
      url += `&sortDirection=${parameters.sortDirection}&sortProperty=${parameters.sortProperty}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  // data 조회를 처리하는 useEffect
  useEffect(() => {
    const getSearch = async (parameters) => {
      getTopListAxios(parameters)
        .then((resultTopList) => {
          setTopList(resultTopList);
        })
        .catch((error) => {
          console.error(error);
        });

      getBottomListAxios(parameters)
        .then((resultBottomList) => {
          setBottomList(resultBottomList.content);
          handlePagination(resultBottomList);

          setSearchButton(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (tabIndex === 0 && searchButton) {
      getSearch(searchParam);
    }
  }, [getTopListAxios, getBottomListAxios, tabIndex, searchButton, searchParam]);

  // pagination data 설정 handler
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  // 조회조건 변경
  const changeSearchParamHandler = (searchParam) => setSearchParam(searchParam);

  // 페이지 변경 이벤트 핸들러 (검색)
  const handleOffsetChange = (e, offset, page) => {
    changeSearchParamHandler((data) => ({ ...data, pageNumber: page - 1 }));
    changeSearchButtonHandler(true);
  };

  // 페이지 당 조회건수 변경 이벤트 핸들러 (검색)
  const handleRowPerPageChange = ({ target: { value } }) => {
    changeSearchParamHandler((data) => ({ ...data, pageNumber: 0, pageSize: value }));
    changeSearchButtonHandler(true);
  };

  const changeSearchButtonHandler = (flag) => {
    setSearchButton(flag);
  };

  const customerExcelDownload = (e) => {
    let param = [];
    Object.keys(searchParam).forEach((key) => param.push(key + "=" + searchParam[key]));
    let url = "/api/receipt/statistics/customer/excel?" + param.join("&");
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  return (
    <div id="customerStatisticsWrapper" data-testid="customerStatisticsWrapper" style={{ width: "1180px" }}>
      <CreateSearchForm
        searchParam={searchParam}
        changeSearchParamHandler={changeSearchParamHandler}
        changeSearchButtonHandler={changeSearchButtonHandler}
        selectOptionCapitals={selectOptionCapitals}
        selectOptionCustomerGroup={selectOptionCustomerGroup}
        selectOptionYear={selectOptionYear}
      />
      <CreateListForm topList={topList} />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={searchParam.pageSize} onChange={handleRowPerPageChange} customProps={{ inputProps: { "data-testid": "customerStatistics-select-rowPerPage" } }} />
        <button className="btn-m table-top-button" onClick={(event) => customerExcelDownload()} data-testid="save-excel">
          엑셀저장
        </button>
      </div>
      <CreateBottomListForm bottomList={bottomList} pagination={pagination} page={searchParam.pageNumber} totalCount={pagination.total} pageSize={searchParam.pageSize} />
      <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="customerStatistics-pagination" />
    </div>
  );
}

// 검색 조건 form
// 기존 파일이 엉망진창이여서 수정
function CreateSearchForm(props) {
  const { changeSearchParamHandler, changeSearchButtonHandler, selectOptionCapitals, selectOptionCustomerGroup, selectOptionYear } = props;

  const [searchList, setSearchList] = useState({
    targetYear: new Date().getFullYear(), // 통계연도
    capitalUniqueKey: "", // 자금종류
    transactionMethod: "", // 수납방법
    customerGroupUniqueKey: "", // 고객구분
    searchProperty: "CUSTOMER_NAME", // 검색어 구분
    searchContents: "", // 검색어
  });

  // 검색조건 변경
  const handleSearchListChange = ({ target: { value } }, key) => setSearchList((data) => ({ ...data, [key]: value }));

  // 검색어입력에서 key를 입력했을 때 동작
  const handleSearchContentsKeyUp = ({ keyCode }) => {
    if (keyCode === 13) {
      fnSearch();
    }
  };

  // 검색 시작
  const fnSearch = () => {
    const { targetYear, capitalUniqueKey, transactionMethod, customerGroupUniqueKey, searchProperty, searchContents } = searchList;

    changeSearchParamHandler((data) => ({ ...data, pageNumber: 0, targetYear, capitalUniqueKey, transactionMethod, customerGroupUniqueKey, searchProperty, searchContents }));
    changeSearchButtonHandler(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <span>
          <label className="label-l">통계연도</label>
          <CF.SelectForm
            value={searchList.targetYear}
            handleChange={(e) => handleSearchListChange(e, "targetYear")}
            arrayOption={selectOptionYear}
            customProps={{ inputProps: { "data-testid": "customerStatistics-select-targetYear" } }}
          />
        </span>
        <span>
          <label className="label-l">자금종류</label>
          <CF.SelectForm
            value={searchList.capitalUniqueKey}
            handleChange={(e) => handleSearchListChange(e, "capitalUniqueKey")}
            arrayOption={selectOptionCapitals}
            customProps={{ inputProps: { "data-testid": "customerStatistics-select-capitalUniqueKey" } }}
          />
        </span>
        <span>
          <label className="label-l">수납방법</label>
          <CF.SelectForm
            value={searchList.transactionMethod}
            handleChange={(e) => handleSearchListChange(e, "transactionMethod")}
            arrayOption={StatisticsChartEnum.getSearchOption("transactionMethod")}
            customProps={{ inputProps: { "data-testid": "customerStatistics-select-transactionMethod" } }}
          />
        </span>
        <span>
          <label className="label-l">고객구분</label>
          <Select native name="customerGroupUniqueKey" value={searchList.customerGroupUniqueKey} onChange={(e) => handleSearchListChange(e, "customerGroupUniqueKey")}>
          <option value="">전체</option>
              {selectOptionCustomerGroup.map((option, index) => {
                return (
                    <option key={index} value={option.uniqueKey}>
                      {option.customerGroupName}
                    </option>
                );
              })}
          </Select>
        </span>
        <span>
          <label className="label-l">검색어입력</label>
          <CF.SelectForm
            value={searchList.searchProperty}
            handleChange={(e) => handleSearchListChange(e, "searchProperty")}
            arrayOption={StatisticsChartEnum.getSearchOption("searchProperty")}
            customProps={{ inputProps: { "data-testid": "customerStatistics-select-searchProperty" } }}
          />
          <TextField
            className="w150"
            value={searchList.searchContents}
            onChange={(e) => handleSearchListChange(e, "searchContents")}
            onKeyUp={handleSearchContentsKeyUp}
            inputProps={{ "data-testid": "customerStatistics-input-searchContents" }}
          />
        </span>
        <button className="search-button" data-testid="customerStatistics-button-search" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

// 상단 요약 목록 컴폰넌트
function CreateListForm(props) {
  const { topList } = props;

  // 불필요하게 노가다로 cell 생성하던 것 map으로 변경
  return (
    <div className="scroll-table">
      <div className="left-table-div-top">
        <Table>
          {CM.cfnCompColGroup(["24%", "auto", "47%"])}
          <TableForm.compTableHead arrData={["구분", "건수", "총액"]} />
          <TableBody>
            {topList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={3} />
            ) : (
              <Fragment>
                <TableRow>
                  <TableCell align="center">수납</TableCell>

                  <TableCell align="right">{CM.cfnAddComma(topList.totalPaidCount)}건</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(topList.totalPaidAmount)}원</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" data-testid={"summaryList2_startCell"}>
                    미납
                  </TableCell>

                  <TableCell align="right">{CM.cfnAddComma(topList.totalUnpaidCount)}건</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(topList.totalUnpaidAmount)}원</TableCell>
                </TableRow>
              </Fragment>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div-top">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"])}
            <TableForm.compTableHead arrData={[...Array(12).keys()].map((value) => (typeof value === "number" ? `${value + 1}월` : value))} />
            <TableBody>
              {topList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={12} />
              ) : (
                <Fragment>
                  <TableRow>
                    {topList.paidAmounts.map((paidAmounts, index) => {
                      return (
                        <TableCell align="right" key={index}>
                          {CM.cfnAddComma(paidAmounts)}원
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    {topList.unpaidAmounts.map((unpaidAmounts, index) => {
                      return (
                        <TableCell align="right" key={index}>
                          {CM.cfnAddComma(unpaidAmounts)}원
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </Fragment>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

// 하단 목록 list
function CreateBottomListForm(props) {
  // 불필요하게 노가다로 cell 생성하던 것 map으로 변경
  return (
    <div className="scroll-table">
      <div className="left-table-div">
        <Table>
          {CM.cfnCompColGroup(["12%", "17%", "auto", "14%", "14%", "12%", "14%"])}
          <TableForm.compTableHead arrData={["고객명", "납부자번호", "수납방법", "총수납금액", "청구중금액", "미납건수", "미납금액"]} />
          <TableBody>
            {props.bottomList.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={7} />
            ) : (
              props.bottomList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center" data-testid={"detailList1_" + index} title={row.customerName} className="ellipsis" style={{ maxWidth: "12%" }}>
                      {row.customerName}
                    </TableCell>
                    <TableCell className="ellipsis" align="center" title={row.payerNo}>
                      {row.payerNo}
                    </TableCell>
                    <TableCell align="center">{StatisticsChartEnum.getApiAvailableLabel(row.transactionMethod)}</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.totalPaidAmount)}원</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.askingAmount)}원</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.unpaidCount)}건</TableCell>
                    <TableCell align="right">{CM.cfnAddComma(row.unpaidAmount)}원</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className="right-table-div">
        <div className="right-inner-table-div">
          <Table>
            {CM.cfnCompColGroup(["120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"])}
            <TableForm.compTableHead arrData={[...Array(12).keys()].map((value) => `${value + 1}월`)} />
            <TableBody>
              {props.bottomList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={12} />
              ) : (
                props.bottomList.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {row.paidAmounts.map((value, valueIndex) => {
                        return (
                          <Fragment key={valueIndex}>
                            <TableCell align="right">{CM.cfnAddComma(value) + "원"}</TableCell>
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(CustomerStatistics));
