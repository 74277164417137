import { FormGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import React, { Fragment, useRef, useState } from "react";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";

// 기본문구
const defaultMessage = {
  formatForAskingWithdraw: "(㉮)㉰님 ㉱계좌에서㉳원이 ㉲ 출금예정입니다.", // 출금의뢰시
  formatForFailureOnWithdraw: "(㉮)㉰님 ㉱계좌에서㉳원이 ㉲ 미납되었습니다.", // 출금실패시
  formatForSuccessOnWithdraw: "(㉮)㉰님 ㉱계좌에서㉳원이 ㉲ 납부되었습니다.", // 정상출금시
  formatForAskingDeposit: "(㉮)㉰님 ㉱계좌로㉳원이 ㉲ 입금예정입니다.", // 입금의뢰시
  formatForFailureOnDeposit: "(㉮)㉰님 ㉱계좌로㉳원이 ㉲ 미입금되었습니다.", // 입금실패시
  formatForSuccessOnDeposit: "(㉮)㉰님 ㉱계좌로㉳원이 ㉲ 입금되었습니다.", // 입금성공시
};

// 간편 입력 버튼
const easyInputButtonList = [
  { value: "㉮", label: "[ ㉮ : 통장기재내역(16) ]" },
  { value: "㉯", label: "[ ㉯ : 이용기관명(16) ]" },
  { value: "㉰", label: "[ ㉰ : 고객명(8) ]" },
  { value: "㉱", label: "[ ㉱ : 고객거래은행(8) ]" },
  { value: "㉲", label: "[ ㉲ : 출금일(5) ]" },
  { value: "㉳", label: "[ ㉳ : 금액(9) ]" },
  { value: "㉴", label: "[ ㉴ : 금액(12) ]" },
  { value: "○", label: "○" },
  { value: "▶", label: "▶" },
  { value: "◀", label: "◀" },
  { value: "♠", label: "♠" },
  { value: "♣", label: "♣" },
  { value: "♡", label: "♡" },
  { value: "☆", label: "☆" },
];

/*
 * @desc  SMS text byte 계산을 처리하는 함수
 */
const fnGetSmsLength = (value) => {
  let chkValue = value !== null ? value.toString() : "";
  let rtnCount = 0;

  const textstr = "㉮㉯㉰㉱㉲㉳㉴";
  const textlength = [16, 16, 8, 8, 5, 9, 12];

  for (let i = 0; i < chkValue.length; i++) {
    // 한글일 때
    if (chkValue.charCodeAt(i) > 127) {
      // text에 textstr이 존재하지 않을 때
      if (textstr.indexOf(chkValue.charAt(i)) === -1) {
        rtnCount += 2;
      } else {
        rtnCount += textlength[textstr.indexOf(chkValue.charAt(i))];
      }
    } else {
      rtnCount += 1;
    }
  }

  return rtnCount;
};

// 렌더를 처리하는 메인 컴포넌트
export default function TabSmsAlarm(props) {
  const { instituteService, instituteServiceState } = props;

  const [smsData, setSmsData] = useState(instituteService);
  const [focused, setFocused] = useState("");
  const [avoidSendingAtNight, setAvoidSendingAtNight] = useState(instituteService.serviceUseYn ? instituteService.avoidSendingAtNight : true); // 사용안하고 있을 경우 기본 세팅을 사용함으로 설정

  const askingReference = useRef(); // 출금의뢰시
  const successReference = useRef(); // 정상출금시
  const failureReference = useRef(); // 출금실패시

  const askingReferenceDeposit = useRef(); // 입금의뢰시
  const failureReferenceDeposit = useRef(); // 정상입금시
  const successReferenceDeposit = useRef(); // 입금실패시

  // SMS 문구 수정을 처리하는 handler
  const handleChangeText = (name) => ({ target: { value } }) => fnSettingSmsText(name, value);

  // SMS 문구 값 설정을 처리하는 함수
  const fnSettingSmsText = (name, value) => {
    if (fnGetSmsLength(value) > 80) {
      CM.cfnAlert("길이가 초과되었습니다.");
      return false;
    }

    setSmsData((data) => ({ ...data, [name]: value }));
    return true;
  };

  // focus state 값을 설정하는 함수
  const handleFocusedChange = (name) => setFocused(name);

  // checkBox 설정을 처리하는 handler
  const handleChangeCheck = (name) => ({ target: { checked } }) => setSmsData((data) => ({ ...data, [name]: checked }));

  // SMS 문구를 기본문구로 바꾸는 처리하는 함수
  const fnOnClickDefaultButton = (name) => setSmsData((data) => ({ ...data, [name]: defaultMessage[name] }));

  // 서비스 신청 또는 설정 변경
  const updateService = (status) => {
    const sendData = { ...smsData, serviceUseYn: true, avoidSendingAtNight };
    axiosService(sendData);
  };

  // 서비스 해지
  const terminateService = () => {
    const sendData = { ...smsData, serviceUseYn: false, avoidSendingAtNight };
    axiosService(sendData);
  };

  // 서비스 신청, 변경, 해지 axios
  const axiosService = (sendData) => {
    const url = "api/institute/services";
    CM.cfnAxios(url, "put", sendData, (status, data) => {
      CM.cfnAlert(data, () => {
        setSmsData(sendData);
        props.updateInstituteService(sendData, "SMS_TO_CUSTOMER");
      });
    });
  };

  // 간편입력버튼 클릭시 활성화된 SMS 문구 TextArea에 선택한 문자열을 추가하는 함수
  const fnSimpleInputButtonOnClick = async (e) => {
    if (CM.cfnIsEmpty(focused)) {
      e.preventDefault();
      return false;
    }

    let inputComponent = "";

    switch (focused) {
      case "formatForAskingWithdraw":
        inputComponent = askingReference;
        break;
      case "formatForSuccessOnWithdraw":
        inputComponent = successReference;
        break;
      case "formatForFailureOnWithdraw":
        inputComponent = failureReference;
        break;
      case "formatForAskingDeposit":
        inputComponent = askingReferenceDeposit;
        break;
      case "formatForSuccessOnDeposit":
        inputComponent = successReferenceDeposit;
        break;
      default:
        // formatForFailureOnDeposit
        inputComponent = failureReferenceDeposit;
        break;
    }

    const cusorStartIndex = inputComponent.current.selectionStart;
    const cusorEndIndex = inputComponent.current.selectionEnd;
    const focusIndex = cusorStartIndex === cusorEndIndex ? cusorEndIndex + 1 : cusorStartIndex + 1;
    const prevValue = smsData[focused];
    const clickValue = e.currentTarget.value;
    const applyValue = CM.cfnIsEmpty(prevValue) ? clickValue : prevValue.substring(0, cusorStartIndex) + clickValue + prevValue.substring(cusorEndIndex, prevValue.length);

    fnSettingSmsText(focused, applyValue);

    await inputComponent.current.focus();
    inputComponent.current.setSelectionRange(focusIndex, focusIndex);
  };

  // 렌더
  return (
    <div>
      <div className="modify-area">
        <div className="block">{smsData.serviceUseYn ? "현재 입출금 자동 알림 서비스를 이용 중입니다." : "다음 내용으로 입출금 자동 알림 서비스를 신청합니다."}</div>
      </div>
      <div>
        {instituteServiceState.indexOf("EB21") !== -1 || instituteServiceState.indexOf("EC21") !== -1 ? (
          <div>
            <h4>출금 정보 알림 설정</h4>
            <Table>
              {CM.cfnCompColGroup(["auto", "auto", "auto"])}
              <TableHead>
                <TableRow key="headRow">
                  <TableCell align="center">
                    출금청구시
                    <CompCellButton
                      smsData={smsData}
                      buttonFieldName="formatForAskingWithdraw"
                      fnOnClickDefaultButton={fnOnClickDefaultButton}
                      sendFieldName="sendForAskingWithdraw"
                      handleChangeCheck={handleChangeCheck}
                    />
                  </TableCell>
                  <TableCell align="center">
                    정상출금시
                    <CompCellButton
                      smsData={smsData}
                      buttonFieldName="formatForSuccessOnWithdraw"
                      fnOnClickDefaultButton={fnOnClickDefaultButton}
                      sendFieldName="sendForSuccessOnWithdraw"
                      handleChangeCheck={handleChangeCheck}
                    />
                  </TableCell>
                  <TableCell align="center">
                    출금실패시
                    <CompCellButton
                      smsData={smsData}
                      buttonFieldName="formatForFailureOnWithdraw"
                      fnOnClickDefaultButton={fnOnClickDefaultButton}
                      sendFieldName="sendForFailureOnWithdraw"
                      handleChangeCheck={handleChangeCheck}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <TextAreaForm smsData={smsData} name="formatForAskingWithdraw" handleChangeText={handleChangeText} customRef={askingReference} handleFocusedChange={handleFocusedChange} />
                  </TableCell>
                  <TableCell align="center">
                    <TextAreaForm smsData={smsData} name="formatForSuccessOnWithdraw" handleChangeText={handleChangeText} customRef={successReference} handleFocusedChange={handleFocusedChange} />
                  </TableCell>
                  <TableCell align="center">
                    <TextAreaForm smsData={smsData} name="formatForFailureOnWithdraw" handleChangeText={handleChangeText} customRef={failureReference} handleFocusedChange={handleFocusedChange} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          ""
        )}
        {instituteServiceState.indexOf("EB31") !== -1 ? (
          <div>
            <h4>입금 정보 알림 설정</h4>
            <Table>
              <TableHead>
                <TableRow key="headRow">
                  <TableCell align="center">
                    입금청구시
                    <CompCellButton
                      smsData={smsData}
                      buttonFieldName="formatForAskingDeposit"
                      fnOnClickDefaultButton={fnOnClickDefaultButton}
                      sendFieldName="sendForAskingDeposit"
                      handleChangeCheck={handleChangeCheck}
                    />
                  </TableCell>
                  <TableCell align="center">
                    정상입금시
                    <CompCellButton
                      smsData={smsData}
                      buttonFieldName="formatForSuccessOnDeposit"
                      fnOnClickDefaultButton={fnOnClickDefaultButton}
                      sendFieldName="sendForSuccessOnDeposit"
                      handleChangeCheck={handleChangeCheck}
                    />
                  </TableCell>
                  <TableCell align="center">
                    입금실패시
                    <CompCellButton
                      smsData={smsData}
                      buttonFieldName="formatForFailureOnDeposit"
                      fnOnClickDefaultButton={fnOnClickDefaultButton}
                      sendFieldName="sendForFailureOnDeposit"
                      handleChangeCheck={handleChangeCheck}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <TextAreaForm smsData={smsData} name="formatForAskingDeposit" handleChangeText={handleChangeText} customRef={askingReferenceDeposit} handleFocusedChange={handleFocusedChange} />
                  </TableCell>
                  <TableCell align="center">
                    <TextAreaForm
                      smsData={smsData}
                      name="formatForSuccessOnDeposit"
                      handleChangeText={handleChangeText}
                      customRef={successReferenceDeposit}
                      handleFocusedChange={handleFocusedChange}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextAreaForm
                      smsData={smsData}
                      name="formatForFailureOnDeposit"
                      handleChangeText={handleChangeText}
                      customRef={failureReferenceDeposit}
                      handleFocusedChange={handleFocusedChange}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          ""
        )}
        <Table>
          {CM.cfnCompColGroup(["15%", "auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">간편입력버튼</TableCell>
              <TableCell className="td">
                <div>
                  {easyInputButtonList.map((element, index) => {
                    return (
                      <Button value={element.value} key={index} onClick={fnSimpleInputButtonOnClick} data-testid={`tabSmsAlarm-button-easyInput-${element.value}`}>
                        {element.label}
                      </Button>
                    );
                  })}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="table-bottom-area">
        <FormGroup row>
          <CF.CheckboxForm
            checked={avoidSendingAtNight}
            value={avoidSendingAtNight}
            handleChange={(e) => setAvoidSendingAtNight(e.target.checked)}
            label="야간(22:00 ~ 09:00)에 처리된 입출금 내역에 대해 안내 SMS를 09:00 이후 시간에 발송합니다."
            customProps={{ inputProps: { "data-testid": "tabSmsAlarm-checkbox-avoidSendingAtNight" } }}
          />
        </FormGroup>
        <ServiceButtonForm useYn={smsData.serviceUseYn} updateService={updateService} terminateService={terminateService} />
        <div className="inforbox">
          <h4>서비스안내</h4>
          <ul>
            <li>SMS발신번호는 기관정보에 기재된 전화번호로 적용됩니다.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

// 테이블 텍스트 필드 컴포넌트
function CompCellButton(props) {
  const { smsData, buttonFieldName, sendFieldName, fnOnClickDefaultButton, handleChangeCheck } = props;
  return (
    <div className="usebox">
      <CF.CheckboxForm
        checked={!!smsData[sendFieldName]}
        value={!!smsData[sendFieldName]}
        handleChange={handleChangeCheck(sendFieldName)}
        label="사용"
        customProps={{ inputProps: { "data-testid": `tabSmsAlarm-checkbox-used-${sendFieldName}` } }}
      />
      <div className="btn-s" onClick={() => fnOnClickDefaultButton(buttonFieldName)} data-testid={`tabSmsAlarm-button-defaultText-${buttonFieldName}`}>
        기본문구
      </div>
    </div>
  );
}

// 테이블 텍스트 필드 컴포넌트
function TextAreaForm(props) {
  const { smsData, name, handleChangeText, customRef, handleFocusedChange } = props;

  return (
    <Fragment>
      <TextField
        className="sms-textfield"
        variant="outlined"
        value={smsData[name] || ""}
        onChange={handleChangeText(name)}
        inputProps={{ ref: customRef, "data-testid": `tabSmsAlarm-textfield-${name}` }}
        multiline
        rows={3}
        rowsMax={3}
        onFocus={() => handleFocusedChange(name)}
      />
      <div>{fnGetSmsLength(smsData[name])} / 80 bytes</div>
    </Fragment>
  );
}

// 서비스 신청 버튼 컴포넌트
function ServiceButtonForm(props) {
  const { useYn, updateService, terminateService } = props;

  // 사용여부 체크
  if (useYn) {
    return (
      <div className="table-bottom-area">
        <button className="btn-l" onClick={updateService} data-testid="tabSmsAlarm-button-updateService">
          설정 변경
        </button>
        <button className="btn-l" onClick={terminateService} data-testid="tabSmsAlarm-button-terminateService">
          서비스 해지
        </button>
      </div>
    );
  }

  return (
    <div className="table-bottom-area">
      <button className="btn-l" onClick={updateService} data-testid="tabSmsAlarm-button-requestService">
        서비스 신청
      </button>
    </div>
  );
}
