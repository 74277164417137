import React, { useState, useEffect, useCallback } from "react";
import { inject, observer } from "mobx-react";

import {Input, Select, Table, TableBody, TableCell, TableRow} from "@material-ui/core";

import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import CustomerData from "../customerBasicInformation/CustomerData";
import FixingsUpdate from "./FixingsUpdate";
import { TooltipForm } from "../../template/ComponentForm";

// hooks 초기화 기본 값
const DEFAULT_HOOKS = {
  requestParams: {
    isAccountNoErrorIncluded: true, // (요약정보 연계)계좌번호 오류 조회
    isEtcErrorIncluded: true, // (요약정보 연계)기타 오류 조회
    isIdentificationNumberErrorIncluded: true, // (요약정보 연계)생년월일(사업자번호) 오류 조회
    isWithdrawalAgreementMissingIncluded: true, // (요약정보 연계)출금동의 누락 조회
    pageNumber: 0, // 요청 페이지번호
    pageSize: 5, // 페이지당 조회건수
    sortDirection: "ASC", // 정렬방법(ASC or DESC)
    sortProperty: "customerName", // 정렬필드
    searchProperty: "CUSTOMER_NAME", //검색조건
    searchContents: "", //검색내용
  },
  summaryInfo: (evidence) => {
    const summaryData = [
      { key: "totalFixingTargets", label: "전체대상", value: 0 },
      { key: "totalEvidenceMissingErrors", label: "동의자료 누락", value: 0 },
      { key: "totalAccountNoErrors", label: "계좌번호 오류", value: 0 },
      { key: "totalIdentificationNoErrors", label: "생년월일(사업자번호) 오류", value: 0 },
      { key: "totalEtcErrors", label: "기타 오류", value: 0 },
    ];

    return summaryData.filter((value) => (evidence === "면제" ? value.key !== "totalEiMissingErrors" : value));
  },
  pagination: {
    rowsPerPage: 5,
    offset: 0,
    total: 0,
    totalPages: 1,
  },
};

/*
* @desc  CMS 출금청구 결과 검색 컴포넌트 생성
*/
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange, handleSearchButtonChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  return (
      <div className="search-area centered">
        <div className="block">
          <label className="label-l">검색어입력</label>
          <Select native name="searchProperty" value={searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")}>
            <option value="CUSTOMER_NAME">고객명</option>
            <option value="PAYER_NO">납부자번호</option>
            <option value="ACCOUNT_NO">계좌번호</option>
          </Select>
          <Input
              value={searchRequest.searchContents}
              onChange={handleSearchFormChange("searchContents")}
              onKeyUp={handleSearchKeyUp}
              name="searchContents"
              className="w120"
              data-testid="cms-request-keyword-resulttab"
          />
          <button className="search-button" onClick={() => fnSearch()}>
            검색
          </button>
        </div>
      </div>
  );
}


// 직접 접수분 > 보완대상
// 렌더를 처리하는 메인 컴포넌트
const FixingsList = ({ props }) => {
  const { tabCountChange, loginStore } = props;

  const [evidence, setEvidence] = useState(""); // 동의자료 flag
  const [searchOn, setSearchOn] = useState(false); // 검색 실행 flag hooks
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams); // 검색 조건 hooks
  const [summaryInfo, setSummaryInfo] = useState(DEFAULT_HOOKS.summaryInfo(loginStore.getEvidence())); // 요약 정보 hooks
  const [fixingsList, setFixingsList] = useState([]); // 목록 hooks
  const [pagination, setPagination] = useState(DEFAULT_HOOKS.pagination); // 페이지네이션 hooks
  const [selectedTarget, setSelectedTarget] = useState({}); // 선택한 row의 인덱스
  const [modalOpen, setModalOpen] = useState(false); // 모달 열기 Hooks

  // 페이지네이션 handler
  const handlePaginationChange = (value) => setPagination(CM.cfnSetPagination(value));

  // 요약 정보 handler
  const handleSummaryInfoChange = (param) => setSummaryInfo((data) => data.map((element) => ({ ...element, value: param[element.key] || 0 })));

  // 요약 정보를 클릭했을 때 동작
  const handleSummaryInfoClick = (key) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 0,
      isAccountNoErrorIncluded: key === "totalAccountNoErrors" || key === "totalFixingTargets",
      isEtcErrorIncluded: key === "totalEtcErrors" || key === "totalFixingTargets",
      isIdentificationNumberErrorIncluded: key === "totalIdentificationNoErrors" || key === "totalFixingTargets",
      isWithdrawalAgreementMissingIncluded: key === "totalEvidenceMissingErrors" || key === "totalFixingTargets",
    }));
    setSearchOn(true);
  };

  // 페이지 handler
  const handlePageChange = (e, offset, page) => {
    setRequestParams((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchOn(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = ({ target: { value } }) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    setSearchOn(true);
  };

  //엑셀다운로드
  const fnSavedExcel = () => {
    const targetCount = pagination.total;
    if (targetCount < 100000) {
      let url = `/api/customer/receipt/account/institute/fixing/excel`;
      url += `?isAccountNoErrorIncluded=${requestParams.isAccountNoErrorIncluded}&isEtcErrorIncluded=${requestParams.isEtcErrorIncluded}`;
      url += `&isIdentificationNumberErrorIncluded=${requestParams.isIdentificationNumberErrorIncluded}`;
      url += `&isWithdrawalAgreementMissingIncluded=${requestParams.isWithdrawalAgreementMissingIncluded}`;
      url += `&sortDirection=${requestParams.sortDirection}&sortProperty=${requestParams.sortProperty}`;
      url += `&searchProperty=${requestParams.searchProperty}&searchContents=${requestParams.searchContents}`;
      url += `&totalElements=${targetCount}`;

      CM.cfnAxiosFileDownload(url, "get", "", () => {});
    } else {
      CM.cfnAlert("데이터 10만건 이상일 경우 엑셀저장이 불가합니다.");
    }
  };

  // modal open
  const goUpdate = (target) => {
    // 생년월일일 경우 마지막 한 자리 자르기
    const identificationNo = target.depositorIdentificationNo;
    const cloneTarget = {
      ...target,
      depositorIdentificationNoFirst7: identificationNo.length === 7 ? identificationNo.substr(0, 6) : identificationNo,
    };

    setSelectedTarget(cloneTarget);
    setModalOpen(true);
  };

  // 요약정보 및 list 가져오기
  const getAxiosData = useCallback((param) => {
    return new Promise((resolve) => {
      let url = "api/customer/receipt/account/institute/fixing";
      url += `?isAccountNoErrorIncluded=${param.isAccountNoErrorIncluded}&isEtcErrorIncluded=${param.isEtcErrorIncluded}`;
      url += `&isIdentificationNumberErrorIncluded=${param.isIdentificationNumberErrorIncluded}`;
      url += `&isWithdrawalAgreementMissingIncluded=${param.isWithdrawalAgreementMissingIncluded}`;
      url += `&pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
      url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}`;
      url += `&searchProperty=${param.searchProperty}&searchContents=${param.searchContents}`;

      CM.cfnAxios(url, "get", "", (objectStatus, resultData) => resolve(resultData));
    });
  }, []);

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setRequestParams((data) => ({ ...data, sortProperty, sortDirection }));

    //정렬조건 세팅 후 검색 trigger
    setSearchOn(true);
  };

  /*
 * @desc    CMS등록/해지요청 결과 목록 조회 검색 조건 입력란 변경 이벤트 핸들러
 */
  const handleSearchFormChange = (name) => (e) => {
      setRequestParams({
        ...requestParams,
        [name]: e.target.value,
      });
  };


  // 서비스 권한 가져오기
  useEffect(() => {
    const getEvidence = async () => {
      const resultData = await loginStore.getEvidence();
      setEvidence(resultData);
      setSummaryInfo(DEFAULT_HOOKS.summaryInfo(resultData));
    };

    getEvidence();
  }, [loginStore]);

  const getRegistrations = async (param) => {
    setSearchOn(false);
    const resultData = await getAxiosData(param);
    const resultSummaryInfo = resultData.receiptAccountRegistrationFixingSummaryInfo;
    const resultTarget = resultData.receiptAccountRegistrationTargets;

    tabCountChange("fixings", resultSummaryInfo.totalFixingTargets);

    handleSummaryInfoChange(resultSummaryInfo); // 요약
    setFixingsList(resultTarget.content); // 목록
    handlePaginationChange(resultTarget); // 페이지네이션
  };

  useEffect(() => {
    getRegistrations(requestParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 검색 useEffect
  useEffect(() => {
    if (props.tabIndex === 0 && searchOn) {
      getRegistrations(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOn, requestParams, props.tabIndex]);

  // tab이 변경될 때 검색 flag 변경 useEffect
  useEffect(() => {
    if (props.tabIndex !== 0 && !searchOn) {
      setSearchOn(true);
    }
  }, [props.tabIndex, searchOn]);

  // TODO: 화면 렌더
  return (
      <div>
        <div className="summary-main-area">
          <CF.SummaryForm list={summaryInfo} onClick={handleSummaryInfoClick}
                          data-testid="registerAccountByInstitute-fixings"/>
        </div>
        <SearchForm searchRequest={requestParams} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={setSearchOn} />
        <div className="table-top-area">
          <CF.TotalCountForm totalElements={pagination.total} />
          <CF.RowPerPageForm value={requestParams.pageSize} onChange={handleRowPerPageChange} data-testid="registerAccountByInstitute-fixings-select-rowPerPage" />
          <button className="btn-m fr" data-testid="save-excel" onClick={fnSavedExcel}>
            엑셀저장
          </button>
        </div>
        <ListForm
            list={fixingsList}
            pagination={pagination}
            handlePageChange={handlePageChange}
            goUpdate={goUpdate}
            financialInstitutes={props.financialInstitutes}
            requestParams={requestParams}
            handleSortProperty={handleSortProperty}
        />
        <FixingsUpdate modalOpen={modalOpen} handleModalOpenChange={setModalOpen} target={selectedTarget}
                       handleSearchOnChange={setSearchOn} evidence={evidence}/>
      </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  return (
    <div>
      <Table>
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          value=""
          arrData={[
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "financialInstituteName", label: "금융기관", sortable: true },
            { id: "accountNo", label: "계좌번호", sortable: true },
            { id: "accountFixingReason", label: "보완대상 내용", sortable: true },
            { id: "appliedResultDate", label: "보완사유\n발생일", sortable: true },
            { id: "accountRegistrationStatus", label: "신청구분", sortable: true },
            { id: "", label: "보완처리", sortable: false },
          ]}
          searchRequest={props.requestParams}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId={"registerAccountByInstitute-fixings-list-head-sortLabel"}
        />
        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{target.financialInstituteName || CM.cfnMatchBankName(target.financialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{target.accountNo}</TableCell>
                  <TableCell align="center" style={{ position: "relative" }}>
                    {CustomerData.getDataName.accountFixingReason(target.accountFixingReason)}
                    {CM.cfnIsNotEmpty(target.fileResultCodeMessage) ? <TooltipForm contents={target.fileResultCodeMessage} /> : ""}
                  </TableCell>

                  {/*<TableCell align="center">
                    {CDN.appliedResultStatus(target.appliedResultStatus)}
                    {target.fileResultCodeMessage !== null && target.fileResultCodeMessage !== "" ? "/" + target.fileResultCodeMessage : ""}
                  </TableCell>*/}
                  <TableCell align="center">{CM.cfnDateFormat(target.appliedResultDate)}</TableCell>
                  <TableCell align="center">{target.accountRegistrationType}</TableCell>
                  <TableCell align="center">
                    <button className="btn-s" onClick={() => props.goUpdate(target)} data-testid={`registerAccountByInstitute-fixings-button-goUpdate-${target.accountUniqueKey}`}>
                      보완처리
                    </button>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <CF.PaginationForm pagination={props.pagination} onClick={props.handlePageChange} testId="registerAccountByInstitute-fixings-pagination" />
    </div>
  );
};

export default inject((rootStore, props) => ({
  props: props,
  loginStore: rootStore.loginStore,
}))(observer(FixingsList));
