import { Checkbox, FormControlLabel, FormGroup, Input, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import * as CM from "../../common/Common";
import { DatePickerForm, SelectForm, TooltipForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import * as CmsData from "./CmsReceiptData";

/*
 * @desc  CMS 출금 청구 상단 안내 문구
 */
export const CmsReceiptRequestInfo = (props) => {
  return (
    <div className="inforbox">
      {props.cmsRequestStatus === 2 ? (
        <ul>
          <li>고객계좌 출금청구일, 출금청구 구분, 출금청구 대상 및 출금방식 구분을 선택한 후 출금대상 생성을 누르면 출금청구할 대상고객이 생성됩니다.</li>
          <li>출금대상 생성 이후, 반드시 출금청구를 눌러야 대상고객으로부터 출금할 수 있습니다.</li>
          <li>생성된 출금대상의 취소요청은 익일출금의 경우 출금청구 전영업일 17시까지, 당일출금의 경우 출금청구 당일 12시까지 가능합니다. </li>
        </ul>
      ) : (
        <ul>
          <li>"제외"버튼을 이용하시면 해당 출금청구 대상에서 제외됩니다.</li>
          <li>다수의 내역을 수정하려면 "엑셀저장" 및 "일괄제외(엑셀)" 기능을 이용하시기 바랍니다. 자체 암호화프로그램을 사용하시는 경우 암호화 해제 후 업로드하시기 바랍니다.</li>
          <li>출금청구내역이 다를 경우, 청구대상 삭제를 누르시면 출금대상 생성 작업을 다시 하실 수 있습니다. </li>
          <li>익일출금은 출금청구 전영업일 17시까지, 당일출금은 출금청구 당일 12시까지 출금청구를 완료하시기 바랍니다.</li>
        </ul>
      )}
    </div>
  );
};

/*
 * @desc  출금대상 생성 컴포넌트
 */
export const CmsReceiptGeneration = (props) => {
  const calculateMaxDate = () => {
    let date = new Date();
    return new Date(date.setMonth(date.getMonth() + 2));
  };

  const maxDate = calculateMaxDate();
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["120px", "auto", "120px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">
              고객계좌
              <br />
              출금청구일
              <TooltipForm contents="출금청구일로 익일출금은 출금청구 희망일 전영업일 17시까지, 당일출금은 출금희망일 당일 12시까지 지정가능합니다." />
            </TableCell>
            <TableCell className="td">
              <DatePickerForm
                disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                className="w160 receiptRequestResultDatePicker"
                value={props.cmsRequestSummary.transactionDate}
                handleChange={props.handleCmsRequestInfoChange("transactionDate")}
                customProps={{
                  placeholder: "YYYY.MM.DD",
                  inputProps: {
                    "data-testid": "cms-request-date",
                  },
                  minDate: props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? undefined : new Date(),
                  shouldDisableDate: (day) => day.getDay() === 0 || day.getDay() === 6,
                  maxDate,
                }}
              />
            </TableCell>
            <TableCell className="th">
              출금청구구분
              <TooltipForm contents="익일출금 또는 당일출금을 선택하시면 됩니다. 당일출금을 신청하지 않은 경우, 사용할 수 없습니다." />
            </TableCell>
            <TableCell className="td">
              <RadioGroup
                disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                name="fileType"
                value={String(props.cmsRequestSummary.fileType)}
                onChange={props.handleCmsRequestInfoChange("fileType")}
                data-testid="cms-receipt-fileType-radio"
                row>
                {/* 기관정보스토어의 businessInfo에 cmsService로 라디오버튼구분. EB21만 있다면 익일출금만 선택가능*/}
                <FormControlLabel
                  disabled={
                    props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2
                      ? true
                      : Object.isExtensible(props.businessInfo) && Object.isExtensible(props.businessInfo.cmsService)
                      ? props.businessInfo.cmsService.indexOf("EB21") !== -1
                        ? false
                        : true
                      : false
                  }
                  control={<Radio color="primary" />}
                  value="true"
                  label="익일출금"
                />
                <div className="w30" />
                <FormControlLabel
                  disabled={
                    props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2
                      ? true
                      : Object.isExtensible(props.businessInfo) && Object.isExtensible(props.businessInfo.cmsService)
                      ? props.businessInfo.cmsService.indexOf("EC21") !== -1
                        ? false
                        : true
                      : false
                  }
                  control={<Radio color="primary" />}
                  value="false"
                  label="당일출금"
                />
              </RadioGroup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">
              출금청구대상
              <TooltipForm contents="⧠ 청구일분 : 수납일이 도래하여 출금 청구일에 해당되는 고객<br/>⧠ 미청구분 : 수납일이 지났으나 출금청구를 하지 않은 고객<br/>⧠ 미납분 : 수납일에 출금청구 하였으나 출금실패된 고객" />
            </TableCell>
            <TableCell className="td">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                      checked={props.targetCheckbox.thisTime}
                      onChange={props.handleTargetCheckbox("thisTime")}
                      name="thisTime"
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "this-time-checkbox",
                      }}
                    />
                  }
                  label="청구일분"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                      checked={props.targetCheckbox.unasked}
                      onChange={props.handleTargetCheckbox("unasked")}
                      name="unasked"
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "unasked-checkbox",
                      }}
                    />
                  }
                  label="미청구분"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : props.cmsRequestSummary.reaskUnpaidLimit !== false ? false : true}
                      checked={props.targetCheckbox.unpaid}
                      onChange={props.handleTargetCheckbox("unpaid")}
                      name="unpaid"
                      inputProps={{
                        "aria-label": "primary checkbox",
                        "data-testid": "unpaid-checkbox",
                      }}
                    />
                  }
                  label="미납분"
                />
              </FormGroup>
            </TableCell>
            <TableCell className="th">
              출금방식구분
              <TooltipForm contents="통합출금은 수납대상 납부자번호에 대해 회차(월별) 구분 없이 1건으로 출금대상이 생성됩니다. (단, 출금대상을 추가한 경우, 해당 건은 별도 건으로 생성) <br /> 건별출금은 회차(월별)을 분리하여 출금대상이 생성됩니다. (건별출금 청구시 각 건 별로 은행수수료 발생)" />
            </TableCell>
            <TableCell className="td">
              <RadioGroup
                disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                name="targetIntegratedYn"
                value={String(props.cmsRequestSummary.targetIntegratedYn)}
                onChange={props.handleCmsRequestInfoChange("targetIntegratedYn")}
                data-testid="target-integrated-radio"
                row>
                <FormControlLabel
                  disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                  control={<Radio color="primary" />}
                  value="true"
                  label="통합출금"
                />
                <div className="w30" />
                <FormControlLabel
                  disabled={props.cmsRequestSummary.uniqueKey !== "" && props.cmsRequestStatus !== 2 ? true : false}
                  control={<Radio color="primary" />}
                  value="false"
                  label="건별출금"
                />
              </RadioGroup>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  출금 청구 내역 컴포넌트
 */
export const CmsRequestHistory = (props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className="th">고객계좌 출금일</TableCell>
          <TableCell className="th">금액 합계</TableCell>
          <TableCell className="th">청구일분</TableCell>
          <TableCell className="th">미청구분</TableCell>
          <TableCell className="th">미납분</TableCell>
          <TableCell className="th">미납연체료</TableCell>
          <TableCell className="th">
            건별 한도
            <br />
            초과 건
          </TableCell>
          <TableCell className="th">
            출금 기준금액
            <br />
            미만 건
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className="td" align="center">
            {CM.cfnDateFormat(CM.cfnConvertDateToString(props.cmsRequestSummary.transactionDate))}
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingAmount)}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingThisTimeAmount)}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingPreviouslyUnaskedAmount)}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingUnpaidAmount)}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.totalAskingDelayFee)}원
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.numberOfExceededLimit)}건
          </TableCell>
          <TableCell className="td" align="right">
            {CM.cfnAddComma(props.cmsRequestSummary.numberOfZeroAmount)}건
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/*
 * @desc  월간출금한도 정보 컴포넌트
 */
export const WithdrawalLimitInfo = (props) => {
  return (
    <div className="inforbox3">
      ※ 월간출금한도는 {CM.cfnAddComma(props.cmsRequestSummary.maxLimitAmountForMonthlyWithdrawal)}
      원이며, 현재 잔여 한도는 {CM.cfnAddComma(props.cmsRequestSummary.limitAmountForMonthlyWithdrawal)}원 (건별 출금한도 :{" " + CM.cfnAddComma(props.cmsRequestSummary.limitAmountForEachWithdrawal)}
      원) 입니다.
    </div>
  );
};

/*
 * @desc  출금대상 목록 검색조건 컴포넌트
 */
export const CmsRequestSearchForm = (props) => {
  return (
    <div className="search-area">
      <div>
        <label className="label-l">고객구분</label>
        <Select native name="groupUniqueKey" value={props.searchRequest.groupUniqueKey} onChange={props.handleRequestSearchChange("groupUniqueKey")}>
          <option value="">전체</option>
          {props.customerGroupOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금종류</label>
        <Select
          native
          name="capitalUniqueKey"
          value={props.searchRequest.capitalUniqueKey}
          onChange={props.handleRequestSearchChange("capitalUniqueKey")}
          inputProps={{
            "data-testid": "cms-receipt-search-capitalSelect",
          }}>
          <option value="">전체</option>
          {props.capitalOptions.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금유형</label>
        <Select native value={props.searchRequest.payAmountType} onChange={props.handleRequestSearchChange("payAmountType")} name="group">
          <option value="">전체</option>
          <option value="FIXED" key="FIXED">
            정액
          </option>
          <option value="VARIABLE" key="VARIABLE">
            비정액
          </option>
          <option value="TEMPORARY_ADDED" key="TEMPORARY_ADDED">
            추가분
          </option>
        </Select>
        <label className="label-l">검색어입력</label>
        <SelectForm
          value={props.searchRequest.term}
          handleChange={props.handleRequestSearchChange("term")}
          name="term"
          arrayOption={CmsData.targetOption}
          optionValue={"value"}
          optionLabel={"label"}
        />
        <Input
          value={props.searchRequest.keyword}
          onChange={props.handleRequestSearchChange("keyword")}
          onKeyUp={props.handleSearchKeyUp}
          name="keyword"
          className="w120"
          data-testid="cms-request-keyword"
        />
        <button className="search-button" onClick={(e) => props.handleSearchButtonChange(true)} data-testid="cms-receipt-search-button">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  출금대상 목록 컴포넌트
 */
export const CmsRequestTable = (props) => {
  return (
    <Table>
      {CM.cfnCompColGroup(["auto", "9%", "8%", "8%", "7%", "10%", "8%", "8%", "7%", "7%", "7%", "5%", "7%"])}
      <TableForm.compServerSortDoubleRowTableHead
        useCheckbox={false}
        rowOne={[
          { id: "CUSTOMER_NAME", label: "고객명", sortable: true, rowSpan: 2 },
          { id: "PAYER_NO", label: "납부자\n번호", sortable: true, rowSpan: 2 },
          { id: "CUSTOMER_GROUP", label: "고객구분", sortable: true, rowSpan: 2 },
          { id: "CAPITAL", label: "자금종류", sortable: true, rowSpan: 2 },
          { id: "ACCOUNT_BANK", label: "금융기관", sortable: false, rowSpan: 2 },
          { id: "ACCOUNT_NO", label: "계좌번호", sortable: false, rowSpan: 2 },
          { id: "TARGET_Y4MM", label: "수납내역\n기간(갯수)", sortable: false, rowSpan: 2 },
          {
            id: "CMS_RECEIPT_TARGET",
            label: "출금청구금액",
            sortable: false,
            colSpan: 5,
          },
          { id: "EXCLUDE", label: "제외", sortable: false, rowSpan: 2 },
        ]}
        rowTwo={[
          {
            id: "TOTAL_ASKING_AMOUNT",
            label: "합계",
            sortable: true,
          },
          { id: "THIS_TIME_AMOUNT", label: "청구일분", sortable: false },
          {
            id: "PREVIOUSLY_UNASKED_AMOUNT",
            label: "미청구분",
            sortable: false,
          },
          { id: "ASKING_UNPAID_AMOUNT", label: "미납분", sortable: false },
          { id: "ASKING_DELAY_FEE", label: "미납\n연체료", sortable: false },
        ]}
        searchRequest={props.searchRequest}
        handleSortProperty={props.handleSortProperty}
        tableSortLabelDataTestId="cmsReceiptRequestTable"
      />
      <TableBody>
        {props.cmsRequestList.length === 0 ? (
          <TableForm.compEmptyTableRow colSpan={13} />
        ) : (
          props.cmsRequestList.map((row, index) => {
            return (
              <TableRow key={index} hover onClick={() => props.handleClickedRow && props.handleClickedRow(row)}>
                <TableCell align="center">{row.customer.customerName /*고객명*/}</TableCell>
                <TableCell align="center">{row.contract.payerNo /*납부자번호*/}</TableCell>
                <TableCell align="center">{row.customer.customerGroup.customerGroupName /*고객구분*/}</TableCell>
                <TableCell align="center">{row.contract.capital.capitalName /*자금종류*/}</TableCell>
                <TableCell align="center">{CM.cfnMatchBankName(row.account.accountBankCode, props.financialInstitutes) /*금융기관*/}</TableCell>
                <TableCell align="center">{CM.cfnMasking(row.account.accountNo) /*계좌번호*/}</TableCell>
                <TableCell align="center" className="multiLine-cell">
                  {CM.cfnDateFormat(row.targetSourceStartY4mm, "yyyyMM") +
                    (row.targetSourceStartY4mm === row.targetSourceEndY4mm ? "" : " ~\n" + CM.cfnDateFormat(row.targetSourceEndY4mm, "yyyyMM")) +
                    "(" +
                    row.numberOfIncludedSources +
                    ")"}
                </TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.totalAskingAmount) /*합계금액*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.thisTimeAmount) /*청구일분*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.previouslyUnaskedAmount) /*미청구분*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.askingUnpaidAmount - row.askingDelayFee) /*미납분*/}원</TableCell>
                <TableCell align="right">{CM.cfnAddComma(row.askingDelayFee) /*미납연체료*/}원</TableCell>
                <TableCell align="center">
                  <button
                    className="btn-s"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleExcludeRow(row);
                    }}
                    data-testid={`exclude-button-${index}`}
                    disabled={row.handlingExclusion || false}>
                    제외
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};
