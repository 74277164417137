import React, { useState, useEffect, useCallback } from "react";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";

import CashbillTabIssuedSearch from "./CashbillTabIssuedSearch";
import CashbillTabIssuedMonthlySummary from "./CashbillTabIssuedMonthlySummary";
import CashbillTabIssuedCustomerInformation from "./CashbillTabIssuedCustomerInformation";
import CashbillTabIssuedModalDetail from "./CashbillTabIssuedModalDetail";

const FROM_DATE = CM.cfnGetCustomDate("month", -11, "string");

// hooks 초기 값
const DEFAULT_HOOKS = {
  searchCondition: {
    capitalUniqueKey: "", // 자금종류
    groupUniqueKey: "", // 고객구분
    staffUniqueKey: "", // 고객담당자
    fromDate: FROM_DATE.substr(0, 6), // 발행기간 시작월
    isAllIncluded: true, // 발행방법(전체)
    isDirectIssueIncluded: true, // 발행방법(직접발행)
    isKftcIssueIncluded: true, // 발행방법(자동발행)
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
    pageNumber: 0,
    pageSize: 5,
    sortDirection: "ASC",
    sortProperty: "customerName",
  },
  customerDetailInformation: [
    {
      customerName: "",
      cashbillIdentificationNo: "",
    },
  ],
};

// 동적 테이블 head 생성
const getHeader = (date) => {
  const rtnArray = [];
  let n = 0;

  for (let i = Number(date); n < 12; i++) {
    rtnArray.push(`${i}월`);
    if (i === 12) i = 0;
    n++;
  }

  return rtnArray;
};

// 렌더를 처리하는 메인 컴포넌트
// 부가서비스 > 현금영수증 > 현금영수증 발행내역 탭
const CashbillTabIssued = (props) => {
  const [searchStart, setSearchStart] = useState(false);
  const [searchCondition, setSearchCondition] = useState(DEFAULT_HOOKS.searchCondition);
  const [pagination, setPagination] = useState(CM.cfnPaginationData());
  const [monthlySummary, setMonthlySummary] = useState([]); // 월별 요약정보
  const [customerInformation, setCustomerInformation] = useState([]); // 고객별 요약정보
  const [dynamicHead, setDynamicHead] = useState(getHeader(FROM_DATE.substr(4, 2)));
  const [customerDetailInformation, setCustomerDetailInformation] = useState(DEFAULT_HOOKS.customerDetailInformation); // 상세 고객 정보
  const [modalOpen, setModalOpen] = useState(false);

  // 검색시작 falg 변경
  const handleSearchStart = (value) => setSearchStart(value);

  // 검색조건 변경
  const handleSearchCondition = (value) => setSearchCondition(value);

  // 페이지네이션 변경
  const handlePagination = (value) => setPagination(CM.cfnSetPagination(value));

  // 페이지 변경
  const handlePage = (page) => {
    handleSearchCondition((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchStart(true);
  };

  // x건씩 조회 변경
  const handleRowPerPage = ({ target: { value } }) => {
    handleSearchCondition((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchStart(true);
  };

  // 모달 open 변경
  const handleModalOpen = (value) => setModalOpen(value);

  // 월별 요약정보 조회
  const getMonthlySummary = useCallback((param) => {
    return new Promise((resolve) => {
      let url = "api/extraservice/cashbill/issued";
      url += `?fromDate=${param.fromDate}`;
      url += `&isDirectIssueIncluded=${param.isAllIncluded || param.isDirectIssueIncluded}&isKftcIssueIncluded=${param.isAllIncluded || param.isKftcIssueIncluded}`;
      url += `&capitalUniqueKey=${param.capitalUniqueKey}&groupUniqueKey=${param.groupUniqueKey}&staffUniqueKey=${param.staffUniqueKey}&searchProperty=${param.searchProperty}&searchContents=${param.searchContents}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  // 고객별 월별합산 조회
  const getCustomerInformation = useCallback((param) => {
    return new Promise((resolve) => {
      let url = "api/extraservice/cashbill/issued/customers";
      url += `?pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
      url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}`;
      url += `&fromDate=${param.fromDate}`;
      url += `&isDirectIssueIncluded=${param.isAllIncluded || param.isDirectIssueIncluded}&isKftcIssueIncluded=${param.isAllIncluded || param.isKftcIssueIncluded}`;
      url += `&capitalUniqueKey=${param.capitalUniqueKey}&groupUniqueKey=${param.groupUniqueKey}&staffUniqueKey=${param.staffUniqueKey}&searchProperty=${param.searchProperty}&searchContents=${param.searchContents}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  useEffect(() => {
    const startAxios = async (parameter) => {
      await getMonthlySummary(parameter)
        .then((data) => {
          let total = 0;
          for (const element of data) total += element.issuedAmount;
          data.push({ yearMonth: "total", issuedAmount: total });

          setMonthlySummary(data);
        })
        .then(() => {
          setDynamicHead(getHeader(searchCondition.fromDate.substr(4, 2)));
        });

      await getCustomerInformation(parameter).then((data) => {
        setCustomerInformation(data.content);
        handlePagination(data);
      });
    };

    if (searchStart) startAxios(searchCondition);

    return () => setSearchStart(false);
  }, [getMonthlySummary, getCustomerInformation, searchStart, searchCondition]);

  // hooks 초기화
  const initializePage = useCallback(() => {
    setSearchStart(true);
    setSearchCondition(DEFAULT_HOOKS.searchCondition);
    setPagination(CM.cfnPaginationData());
    setMonthlySummary([]);
    setCustomerInformation([]);
    setDynamicHead(getHeader(FROM_DATE.substr(4, 2)));
  }, []);

  useEffect(() => {
    if (props.tabIndex === 2) initializePage();
  }, [props.tabIndex, initializePage]);

  // 고객 상세 조회
  const getCustomerDetailInformation = useCallback((param, customerUniqueKey, payerNo) => {
    return new Promise((resolve) => {
      let url = `api/extraservice/cashbill/issued/customers/${customerUniqueKey}`;
      url += `?fromDate=${param.fromDate}`;
      url += `&isDirectIssueIncluded=${param.isAllIncluded || param.isDirectIssueIncluded}&isKftcIssueIncluded=${param.isAllIncluded || param.isKftcIssueIncluded}`;
      url += `&capitalUniqueKey=${param.capitalUniqueKey}&groupUniqueKey=${param.groupUniqueKey}&staffUniqueKey=${param.staffUniqueKey}&payerNo=${payerNo}`;

      CM.cfnAxios(url, "get", "", (status, data) => resolve(data));
    });
  }, []);

  // 상세 조회
  const searchDetail = async (customerUniqueKey, payerNo) => {
    await getCustomerDetailInformation(searchCondition, customerUniqueKey, payerNo).then((data) => {
      setCustomerDetailInformation(data);
      handleModalOpen(true);
    });
  };

  // 엑셀 저장
  const saveExcel = (param) => {
    let url = "api/extraservice/cashbill/issued/customers/excel";
    url += `?fromDate=${param.fromDate}`;
    url += `&isDirectIssueIncluded=${param.isAllIncluded || param.isDirectIssueIncluded}&isKftcIssueIncluded=${param.isAllIncluded || param.isKftcIssueIncluded}`;
    url += `&capitalUniqueKey=${param.capitalUniqueKey}&groupUniqueKey=${param.groupUniqueKey}&staffUniqueKey=${param.staffUniqueKey}&searchProperty=${param.searchProperty}&searchContents=${param.searchContents}&sortProperty=${param.sortProperty}&sortDirection=${param.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  return (
    <div>
      <div className="inforbox">
        <ul>
          <li>현금영수증 발행내역은 1년 단위로 조회 가능합니다.</li>
          <li>해당 기간 중 신분확인번호가 변경되는 경우 최종 내용으로 표시됩니다.</li>
        </ul>
      </div>
      <div>
        <CashbillTabIssuedSearch handleSearchCondition={handleSearchCondition} handleSearchStart={handleSearchStart} tabIndex={props.tabIndex} />
      </div>
      <div>
        <CashbillTabIssuedMonthlySummary dynamicHead={dynamicHead} list={monthlySummary} />
      </div>
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={pagination.total} />
        <CF.RowPerPageForm value={searchCondition.pageSize} onChange={handleRowPerPage} data-testid="cashbill-issued-main-select-rowPerPage" />
        <button className="btn-m fr" onClick={() => saveExcel(searchCondition)} data-testid="cashbill-issued-main-save-excel">
          엑셀저장
        </button>
      </div>
      <div>
        <CashbillTabIssuedCustomerInformation
          dynamicHead={dynamicHead}
          list={customerInformation}
          pagination={pagination}
          handlePage={handlePage}
          searchDetail={searchDetail}
          searchCondition={searchCondition}
          handleSearchCondition={handleSearchCondition}
          handleSearchStart={handleSearchStart}
        />
      </div>
      <CashbillTabIssuedModalDetail modalOpen={modalOpen} handleModalOpen={handleModalOpen} list={customerDetailInformation} />
    </div>
  );
};

export default CashbillTabIssued;
