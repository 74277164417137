import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import format from "date-fns/format";
import koLocale from "date-fns/locale/ko";
import Pagination from "material-ui-flat-pagination";
import React, { useState } from "react";
import AutoComplete from "react-autosuggest";
import MaskedInput from "react-text-mask";
import * as CM from "../common/Common";

/*
 * @desc    Select 컴포넌트
 */
export const SelectForm = (props) => {
  const { value, handleChange, name, index, arrayOption, optionValue, optionLabel, disabled, readOnly } = props;

  return (
    <Select
      native
      value={value}
      onChange={handleChange}
      inputProps={{
        name: name,
        index: index,
        readOnly: readOnly,
      }}
      disabled={disabled}
      {...props.customProps}>
      {arrayOption.map((element, idx) => {
        return (
          <option value={element[CM.cfnNvl(optionValue, "value")]} key={idx}>
            {element[CM.cfnNvl(optionLabel, "label")]}
          </option>
        );
      })}
    </Select>
  );
};

/*
 * @desc  달력 컴포넌트 설정 class
 */
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "M월 d일 EEEE", { locale: this.locale });
  }

  getCalendarHeaderText(date) {
    return format(date, "yyyy년 M월", { locale: this.locale });
  }

  getYearText(date) {
    return format(date, "yyyy년", { locale: this.locale });
  }
}

/*
 * @desc    DatePicker를 생성하는 컴포넌트
 * @param   {
 *            value: date
 *            handleChange: function
 *            format: string
 *            className : string
 *            disabled : boolean
 *          }
 * @return  { KeyboardDatePicker }
 */
export const DatePickerForm = (props) => {
  const { value, handleChange, format, className, disabled, customProps } = props;

  // value string일 때 date type으로 변경
  const applyValue = typeof value === "string" ? CM.cfnConvertStringToDate(value) : value;

  // 최대날짜 / 최소날짜 설정시 Error Message 설정
  let maxDateLabel = "최대날짜";
  let minDateLabel = "최소날짜";

  if (Object.isExtensible(customProps)) {
    if (CM.cfnIsNotEmpty(customProps.maxDate)) {
      maxDateLabel = CM.cfnDateFormat(CM.cfnConvertDateToString(customProps.maxDate));
    }
    if (CM.cfnIsNotEmpty(customProps.minDate)) {
      minDateLabel = CM.cfnDateFormat(CM.cfnConvertDateToString(customProps.minDate));
    }
  }

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={koLocale}>
      <KeyboardDatePicker
        margin="normal"
        variant="inline"
        format={CM.cfnNvl(format, "yyyy.MM.dd")}
        value={applyValue}
        onChange={handleChange}
        className={className}
        disabled={disabled}
        autoOk={true}
        invalidDateMessage={""}
        maxDateMessage={`날짜를 (${maxDateLabel}) 이전으로 선택해주세요.`}
        minDateMessage={`날짜를 (${minDateLabel}) 이후로 선택해주세요.`}
        placeholder={"YYYY.MM.DD"}
        {...customProps}
      />
    </MuiPickersUtilsProvider>
  );
};

/*
 * @desc    Checkbox를 생성하는 컴포넌트
 * @param   {
 *            checked: state의 value 값
 *            value: state의 key 값
 *            handleChange: onChange function
 *            label: 체크박스를 설명하는 라벨
 *          }
 * @return  { Checkbox }
 */
export const CheckboxForm = (props) => {
  return <FormControlLabel control={<Checkbox checked={props.checked} value={props.value} onChange={props.handleChange} {...props.customProps} />} label={props.label} />;
};

/*
 * @desc    Masking 처리된 Input 컴포넌트 생성
 * @param   { props }
 * @return  { Input }
 */
export const MaskedField = (props) => {
  const { inputRef, ...other } = props;

  let mask = props.mask;
  switch (mask) {
    case "mobile":
      mask = (value) => {
        if (value) {
          const raw = value.replace(/[.\-_/]/gi, "");
          return raw && raw.length < 11 ? [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/] : [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
        } else {
          return [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
        }
      };
      break;
    case "telephone":
      mask = (value) => {
        if (value) {
          const raw = value.replace(/[.\-_/]/gi, "");
          if (raw.startsWith("02")) {
            if (raw.length <= 9) {
              return [/\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
            } else {
              return [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
            }
          } else {
            if (raw.length <= 10) {
              return [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
            } else if (raw.length === 11) {
              return [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
            } else {
              return [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
            }
          }
        } else {
          return [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
        }
      };
      break;
    case "identificationNo":
      mask = (value) => {
        if (value && value.replace(/[.\-_/]/gi, "").length > 6) {
          return [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/];
        }
        return [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/];
      };
      break;
    case "personalIdentificationNo":
      mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
      break;
    case "bizNo":
      mask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/];
      break;
    default:
      break;
  }

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
    />
  );
};

export const StyleTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "12px",
  },
}))(Tooltip);

const ButtonStyleTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "12px",
    maxWidth: "200px",
  },
}))(Tooltip);

const ButtonStyleTooltipTwo = withStyles((theme) => ({
  tooltip: {
    fontSize: "12px",
    maxWidth: "200px",
  },
}))(Tooltip);
/*
 * @desc    Tooltip을 생성하는 컴포넌트
 * @param   { string }
 * @return  { Tooltip }
 */
export const TooltipForm = (props) => {
  return (
    <StyleTooltip className={`${props.tooltipClass || ""}`} title={<p dangerouslySetInnerHTML={{ __html: props.contents }}></p>}>
      <span style={props.spanStyle}>
        <HelpOutlineIcon fontSize="small" />
      </span>
    </StyleTooltip>
  );
};

/*
 * @desc    button형 Tooltip을 생성하는 컴포넌트
 * @param   { string }
 * @return  { Tooltip }
 */
export const ButtonTooltipForm = (props) => {
  return (
    <ButtonStyleTooltip className={`${props.tooltipClass || ""}`} title={<p dangerouslySetInnerHTML={{ __html: props.contents }}></p>}>
      <div onClick={(e) => props.onClick && props.onClick(e)} className={props.divClass || ""}>
        <button type="button" className={`btn-s ${props.disabled ? "btn-disabled" : ""}`} disabled={props.disabled}>
          {props.buttonContents}
          <HelpOutlineIcon fontSize="small" type="button" />
        </button>
      </div>
    </ButtonStyleTooltip>
  );
};

/*
 * @desc    button형 Tooltip을 생성하는 컴포넌트
 * @param   { string }
 * @return  { Tooltip }
 */
export const ButtonTooltipFormTwo = (props) => {
  return (
    <ButtonStyleTooltipTwo className={`${props.tooltipClass || ""}`} title={<p dangerouslySetInnerHTML={{ __html: props.contents }}></p>}>
      <div onClick={(e) => props.onClick && props.onClick(e)} className={props.divClass || ""}>
        <button type="button" className={`btn-l6 ${props.disabled ? "btn-disabled" : ""}`} disabled={props.disabled}>
          {props.buttonContents}
          <HelpOutlineIcon fontSize="small" type="button" />
        </button>
      </div>
    </ButtonStyleTooltipTwo>
  );
};

/*
 * @desc    물음표 표시 없이 Tooltip을 생성하는 컴포넌트
 * @param   { string }
 * @return  { Tooltip }
 */
export const RawTooltipForm = (props) => {
  return (
    <StyleTooltip className={`${props.tooltipClass || ""}`} title={<p dangerouslySetInnerHTML={{ __html: props.contents }}></p>}>
      <div className={props.rawClass || ""}>{props.rawContents}</div>
    </StyleTooltip>
  );
};

/*
 * @desc    테이블 내 수정 가능한 Input을 생성하는 컴포넌트
 * @param   {
 *            row: 해당되는 행의 전체 데이터
 *            fieldName: state의 key 값
 *            onCellValueChange: onChange handler
 *            readOnly: readOnly 속성값 (boolean)
 *            size : 컴포넌트 길이를 결정하는 className
 *          }
 * @return  { Input }
 */
export const EditableCellInput = (props) => {
  const { row, fieldName, onCellValueChange, readOnly, size, needComma, inputProps } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    onCellValueChange({
      fieldValue: e.target.value,
      fieldName: fieldName,
    });
  };

  return (
    <Input
      className={size}
      onChange={handleInputChange}
      name={fieldName}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      value={needComma && !isFocused ? CM.cfnAddCommaOnChange(CM.cfnNvl(row[fieldName], 0)) : row[fieldName] || ""}
      readOnly={readOnly}
      inputProps={inputProps}
      endAdornment={props.endAdornment}
    />
  );
};

/*
 * @desc    테이블 내 수정 가능한 Input을 생성하는 컴포넌트
 * @param   {
 *            row: 해당되는 행의 전체 데이터
 *            fieldName: state의 key 값
 *            onCellValueChange: onChange handler
 *            label: 체크박스를 설명하는 라벨
 *          }
 * @return  { Checkbox }
 */
export const EditableCellCheckbox = (props) => {
  const { row, fieldName, onCellValueChange, label } = props;

  const handleCheckbox = (e) => {
    onCellValueChange({
      fieldName: fieldName,
      fieldValue: e.target.checked,
    });
  };

  return <FormControlLabel control={<Checkbox onChange={handleCheckbox} id={fieldName} value={row[fieldName]} checked={row[fieldName]} />} label={label} />;
};

/*
 * @desc    테이블 내 수정 가능한 셀렉트박스를 생성하는 컴포넌트
 * @param   {
 *            options : 셀렉트 박스 생성 시 필요한 옵션 (Array 형태)
 *            optionValue : 옵션 Array 중 option value로 쓰일 필드명
 *            optionLabel : 옵션 Array 중 option label로 쓰일 필드명
 *            row: 해당되는 행의 전체 데이터
 *            fieldName: state의 key 값
 *            onCellValueChange: onChange handler
 *          }
 * @return  { Selectbox }
 */
export const EditableCellSelect = (props) => {
  const { options, optionValue, optionLabel, row, fieldName, onCellValueChange } = props;

  const handleSelectChange = (e) => {
    onCellValueChange({
      fieldValue: e.target.value,
      fieldName: fieldName,
    });
  };

  if (CM.cfnIsNotEmpty(options)) {
    return (
      <Select native value={row[fieldName]} onChange={handleSelectChange}>
        {options.map((element, idx) => {
          return (
            <option value={element[CM.cfnNvl(optionValue, "value")]} key={idx}>
              {element[CM.cfnNvl(optionLabel, "label")]}
            </option>
          );
        })}
      </Select>
    );
  } else {
    return (
      <Select native>
        <option value="" />
      </Select>
    );
  }
};

//서버에 저장된 이미지를 파일로 변환하는 함수
export const fnConvertImageToFile = (file, fileName) => {
  let tempObj = {
    url: "",
    blob: "",
  };

  let canvas = document.createElement("canvas");
  let image = new Image();
  image.src = file;
  canvas.getContext("2d").drawImage(image, 0, 0);
  const dataURL = canvas.toDataURL("image/jpeg");
  tempObj.url = dataURL;

  const BASE64_MARKER = ";base64,";

  //base64로 인코딩 되지 않은 경우
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1];
    const raw = parts[1];

    tempObj.blob = new Blob([raw], { type: contentType });
  }

  //base64로 인코딩 되어 있는 경우
  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]); //base64 디코딩 메서드
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength); //부호없는 1byte 정수 배열 생성

  for (let i = 0; i < rawLength; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  tempObj.blob = new Blob([uInt8Array], { type: contentType });

  return tempObj;
};

//파일 타입 반환 함수
const getFileType = (imageType, typeGbn) => {
  var returnVal = false;
  if (typeGbn === "image" && (imageType === "jpg" || imageType === "jpeg" || imageType === "gif" || imageType === "png")) {
    returnVal = true;
  } else if (typeGbn === "pdf" && imageType === "pdf") {
    returnVal = true;
  } else if (typeGbn === "audio" && (imageType === "mp3" || imageType === "wav")) {
    returnVal = true;
  } else if (typeGbn === "text" && imageType === "txt") {
    returnVal = true;
  }
  return returnVal;
};

//서버에 저장된 이미지를 다시 모달에 출력 처리하는 함수
export const fnLoadServerSavedImage = (setInModalFile, file, fileName, fileType, isSavedOnServer, fileChange, setPdfAudioFile) => {
  const imageType = fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase();
  const isImage = getFileType(imageType, "image");
  const isPdf = getFileType(imageType, "pdf");
  const isAudio = getFileType(imageType, "audio");
  const isText = getFileType(imageType, "text");

  let tempObj = {
    file: file,
    src: "#",
  };

  if ((isImage || isPdf || isAudio || isText) && isSavedOnServer) {
    tempObj.src = `data:${fileType};base64,${new Buffer(file, "binary").toString("base64")}`;

    setInModalFile((modalFile) => ({
      ...modalFile,
      file: tempObj.file,
      fileName: fileName,
      fileType: fileType,
      fileSrc: tempObj.src,
    }));

    if (isImage) {
      setPdfAudioFile(() => ({
        fileSrc: "",
        style: { height: "0px" },
        text: "",
      }));
    } else {
      setPdfAudioFile(() => ({
        fileSrc: tempObj.src,
        style: { height: "400px", width: "70%", marginLeft: "150px" },
        text: "",
      }));
    }
  } else {
    if (!isImage && !isPdf && !isAudio && !isText) {
      setPdfAudioFile(() => ({
        fileSrc: "",
        style: { height: "0px" },
        text: "미리보기를 지원하지 않는 형식의 파일입니다. 파일을 저장하여 확인하시기 바랍니다.",
      }));
    } else if (isPdf || isAudio || isText) {
      setPdfAudioFile((data) => ({
        ...data,
        style: { height: "400px", width: "70%", marginLeft: "150px" },
        text: "",
      }));
    }

    if (!isImage) {
      setInModalFile((modalFile) => ({
        ...modalFile,
        file: tempObj.file,
        fileName: fileName,
        fileType: fileType,
        fileSrc: "#",
      }));
    }
  }
};

//이미지 파일 체크하여 리사이징 여부 결정 처리 함수
export const fnCheckEvidenceImageFile = async (file, image, maxSize) => {
  const MAX_SIZE = maxSize * 1024;
  let tempImage = file;
  let src = image.src;
  let resized = false;

  //제한 용량 초과 시 이미지 크기 조정 함수 호출
  if (file.size > MAX_SIZE) {
    tempImage = fnResizeImage(image, file.type, file.name, maxSize);
    //이미지 파일 조정 시 src 설정을 위해 하기 코드 실행
    resized = true;
    src = window.URL.createObjectURL(tempImage);
    window.URL.revokeObjectURL(tempImage);
  }
  return {
    file: tempImage,
    src,
    resized,
  };
};

//선택된 이미지 리사이징 처리 함수
export const fnResizeImage = (image, type, name, maxSize) => {
  const MAX_SIZE = maxSize * 1024;
  let resizeRatio = 0.7; //기존 이미지의 70%로 축소
  let newImage = fnCreateNewImageFile(resizeRatio, image, type, name);

  while (newImage.size > MAX_SIZE) {
    //업로드 제한 용량 이하로 줄어들 때까지 반복문 실행하며 resizing 파일 생성하는 함수 실행
    resizeRatio -= 0.1;
    if (resizeRatio < 0.1) {
      CM.cfnAlert("선택하신 이미지의 크기가 지나치게 큽니다.");
      break;
    }
    newImage = fnCreateNewImageFile(resizeRatio, image, type, name);
  }

  return newImage;
};

const BASE64_MARKER = ";base64,";

//리사이징 처리된 이미지를 File 객체로 변환 생성 처리하는 함수
export const fnCreateNewImageFile = (ratio, image, type, name) => {
  let canvas = document.createElement("canvas");
  let width = image.width * ratio;
  let height = image.height * ratio;

  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d").drawImage(image, 0, 0, width, height);
  const dataURL = canvas.toDataURL(type);

  //base64로 인코딩 되지 않은 경우
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(",");
    const raw = parts[1];

    try {
      return new File([raw], name, { type: type });
    } catch (e) {
      const blob = new Blob([JSON.stringify(raw)], { type });
      blob.lastModifiedDate = new Date();
      blob.name = name;
      return blob;
    }
  }

  //base64로 인코딩 되어 있는 경우
  const parts = dataURL.split(BASE64_MARKER);
  const raw = window.atob(parts[1]); //base64 디코딩 메서드
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength); //부호없는 1byte 정수 배열 생성

  for (let i = 0; i < rawLength; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  try {
    return new File([uInt8Array], name, { type: type });
  } catch (e) {
    const blob = new Blob([uInt8Array], { type });
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
  }
};

/*
 * @desc    파일 업로드용 Input + Button 을 생성하는 컴포넌트
 * @param   {
 *            acceptFileType : 업로드를 허용할 파일 유형
 *            needPreview : 미리보기 기능의 필요 여부 (Boolean)
 *            uploadFile: 선택된 파일 관련 정보를 담을 state
 *            setUploadFile: 선택된 파일 관련 정보를 담을 state의 setter
 *          }
 * @return  { Input + Button }
 */
export const FileUploadForm = ({ acceptFileType, needPreview, uploadFile, setUploadFile, handleRegisterFile }) => {
  const fileInput = React.useRef();
  //파일찾기 버튼 클릭 이벤트 핸들러
  const handleFindFile = () => {
    fileInput.current.click();
  };
  //파일 선택 이벤트 핸들러
  const handleChange = (e) => {
    setUploadFile({
      ...uploadFile,
      file: null,
      fileName: "",
      fileSrc: "#",
    });
    const file = e.currentTarget.files[0];
    if (file && file.name) {
      const isImage = file.type.indexOf("image") !== -1 ? true : false;
      if (isImage) {
        const reader = new FileReader();
        let tempObj = {
          file: file,
          src: "#",
        };

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = async (e) => {
            tempObj = await fnCheckEvidenceImageFile(file, image, 300);
            setUploadFile({
              ...uploadFile,
              file: tempObj.file,
              fileName: file.name,
              fileSrc: tempObj.src,
              resized: tempObj.resized,
            });
          };
        };
        reader.readAsDataURL(file);
      } else {
        setUploadFile({
          ...uploadFile,
          file: file,
          fileName: file.name,
          fileSrc: "",
        });
      }
    }
  };
  return (
    <React.Fragment>
      <input accept={acceptFileType} type="file" style={{ display: "none" }} onChange={(e) => handleChange(e)} ref={fileInput} value="" data-testid="selected-file-input" />
      {handleRegisterFile ? (
        <Input value={uploadFile.fileName} readOnly={true} data-testid="uploaded-file-name" onClick={handleFindFile} onLoadedData={handleRegisterFile()} />
      ) : (
        <Input value={uploadFile.fileName} readOnly={true} data-testid="uploaded-file-name" onClick={handleFindFile} />
      )}
      <Button className="btn-s" onClick={handleFindFile} data-testid="find-file">
        파일찾기
      </Button>
    </React.Fragment>
  );
};

const loadFile = async (file, files, sn, uploadFolder, setUploadFolder, folderName) => {
  const isImage = file.type.indexOf("image") !== -1 && file.type.indexOf("tif") === -1 ? true : false;
  if (isImage) {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = async () => {
        let tempObj = await fnCheckEvidenceImageFile(file, image, 300);
        files.push({ sn: sn, file: tempObj.file, resized: tempObj.resized });
        setUploadFolder({
          ...uploadFolder,
          files,
          folderName,
        });
      };
      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  } else {
    files.push({ sn: sn, file: file });
    setUploadFolder({
      ...uploadFolder,
      files,
      folderName,
    });
  }
};

const isIE = /*@cc_on!@*/ false || !!document.documentMode;
/*
 * @desc    폴더 업로드용 Input + Button 을 생성하는 컴포넌트
 * @param   {
 *            uploadFolder: 선택된 폴더 관련 정보를 담을 state
 *            setUploadFolder: 선택된 폴더 관련 정보를 담을 state의 setter
 *          }
 * @return  { Input + Button }
 */
export const FolderUploadForm = (props) => {
  const { uploadFolder, setUploadFolder } = props;

  const fileInput = React.useRef();

  const onButtonClick = () => {
    fileInput.current.click();
  };

  const handleChange = async (e) => {
    let fileList = e.target.files;
    let files = [];
    let folderName = "";
    console.warn("uploaded file list : [" + (fileList ? fileList.length : "null") + "]");

    if (fileList) {
      let sn = fileList.length;
      folderName = isIE ? "파일 목록 선택됨" : fileList[0].webkitRelativePath.split("/")[0];

      for (let i = 0; i < fileList.length; i++) {
        try {
          loadFile(fileList[i], files, sn, uploadFolder, setUploadFolder, folderName);
        } catch (exception) {
          console.error(exception);
          CM.cfnAlert("파일을 로드하지 못했습니다. (" + fileList[i].name + ")");
        }
        sn--;
      }
    }
  };

  return (
    <React.Fragment>
      <input type="file" style={{ display: "none" }} onChange={(e) => handleChange(e)} ref={fileInput} value="" multiple directory="" webkitdirectory="" mozdirectory="" />
      <Input className="w140" value={uploadFolder.folderName} readOnly={true} onClick={onButtonClick} />
      <Button className="btn-s w70" onClick={onButtonClick} style={{ marginTop: "3px" }}>
        {isIE ? "파일찾기" : "폴더찾기"}
      </Button>
    </React.Fragment>
  );
};

/*
 * @desc    금융기관명 입력 시 자동완성 목록을 생성하는 컴포넌트
 * @param   {
 *            list : 금융기관 리스트
 *            state : 선택한 금융기관을 담을 state
 *            setState: 금융기관 저장 state setter
 *            setFlag : 본 컴포넌트에서 주소 setting 하기 위한 flag setter (boolean)
 *            className : 스타일 클래스명
 *            customProps : 기타 적용 필요한 props
 *          }
 * @return  { div }
 */
export const BankAutoComplete = (props) => {
  const { list, state, setState, setFlag, className, customProps } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");

  //최초 컴포넌트 생성 시 저장된 기관코드가 있으면 기관명만
  React.useEffect(() => {
    if (CM.cfnIsNotEmpty(state.financialInstituteCode) && CM.cfnIsEmpty(state.fncInsNm)) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].fncInsCd === state.financialInstituteCode) {
          setState({
            ...state,
            fncInsNm: list[i].fncInsNm,
          });
          if (value !== list[i].fncInsNm) {
            setValue(list[i].fncInsNm);
          }
          break;
        }
      }
    } else if (CM.cfnIsNotEmpty(state.financialInstituteCode) && CM.cfnIsNotEmpty(state.fncInsNm)) {
      setValue(state.fncInsNm);
    }
  }, [list, setState, state, value]);

  //Input 입력 이벤트 핸들러
  const handleChange = (value) => {
    let fncInsCd = "";
    let isMatched = false;
    //입력값과 옵션 내 금융기관명을 비교하여 일치할 경우 기관코드 저장
    for (let i = 0; i < list.length; i++) {
      if (list[i].fncInsNm === value) {
        fncInsCd = list[i].fncInsCd;
        isMatched = true;
        break;
      }
    }

    if (isMatched) {
      //사용자가 자동완성된 옵션을 선택하지 않을 경우 찾아낸 기관코드를 저장
      setState({
        ...state,
        fncInsNm: value,
        financialInstituteCode: fncInsCd,
      });
      if (CM.cfnIsNotEmpty(setFlag) && typeof setFlag === "function") setFlag(true);
    } else {
      setState({
        ...state,
        fncInsNm: value,
        financialInstituteCode: "",
      });
    }
  };

  const handleSelect = (item) => {
    setState({
      ...state,
      financialInstituteCode: item.suggestion.fncInsCd,
      fncInsNm: item.suggestionValue,
    });
    setValue(item.suggestionValue);
    if (CM.cfnIsNotEmpty(setFlag) && typeof setFlag === "function") setFlag(true);
  };

  const inputProps = {
    value,
    className: `MuiInput-underline-autocom ${className}`,
    onChange: (e, value) => {
      handleChange(value.newValue);
      setValue(value.newValue);
    },
    ...customProps,
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(
      list.length === 0 || !value
        ? []
        : list.filter(
            (item) => (item.fncInsCd != null && item.fncInsCd.includes(value)) || (item.fncInsNm != null && item.fncInsNm.includes(value)) || (item.fncInsSnm != null && item.fncInsSnm.includes(value))
          )
    );
  };

  const onSuggestionsClearRequested = (e) => {
    setSuggestions([]);
  };

  return (
    <span className="autocom-area MuiInputBase-root" data-testid="auto-bank-search">
      <AutoComplete
        suggestions={suggestions}
        inputProps={inputProps}
        shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(item) => item.fncInsNm}
        onSuggestionSelected={(e, item) => handleSelect(item)}
        renderSuggestion={(item, { isHighlighted }) => (
          <div
            className="auto-complete-option"
            key={item.fncInsCd}
            style={{
              background: isHighlighted ? "#1579b9" : "white",
              color: isHighlighted ? "white" : "black",
            }}>
            {item.fncInsNm}
          </div>
        )}
        renderSuggestionsContainer={({ containerProps, children }) => (
          <div {...containerProps} className={[containerProps.className, "MuiInput-list"].join(" ")} style={{ position: "absolute", left: "0", right: "0" }}>
            {children}
          </div>
        )}
      />
    </span>
  );
};

/*
 * @desc    요약 컴포넌트
 * @param   {
 *            list: 요약 data
 *            onClick: 요약 목록을 클릭했을 때 동작을 처리할 함수
 *          }
 * @return  { div }
 */
export const SummaryForm = (props) => {
  return (
    <div className="summary-area">
      {props.list.map((summary, index) => {
        return (
          <div
            className={summary.key === "registrationExcludedTargets" ? "text-graph" : "text-graph show-detail"}
            key={index}
            onClick={() => (summary.key === "registrationExcludedTargets" ? "" : props.onClick(summary.key))}
            data-testid={`${props["data-testid"]}-summary-info-${index}`}>
            <label>{summary.label}</label>
            <span>{CM.cfnAddComma(summary.value)}</span>
          </div>
        );
      })}
    </div>
  );
};

/*
 * @desc    Tab 세부영역 컴포넌트
 * @param   {
 *            value: Tabs value
 *            index: 해당 탭 index
 *          }
 * @return  { div }
 */
export const TabPanel = (props) => {
  return (
    <Typography component="div" role="tabpanel" hidden={props.value !== props.index} {...props.other}>
      <Box>{props.children}</Box>
    </Typography>
  );
};

/*
 * @desc    Table 전체 row count 컴포넌트
 * @param   {
 *            totalElements: 전체 수
 *          }
 * @return  { div }
 */
export const TotalCountForm = (props) => {
  return (
    <FormControl>
      <InputLabel>전체 : {CM.cfnAddComma(props.totalElements || 0)} 건</InputLabel>
    </FormControl>
  );
};

/*
 * @desc    Table row per page 컴포넌트
 * @param   {
 *            value: 값
 *            onChange: onChange 함수
 *          }
 * @return  { div }
 */
export const RowPerPageForm = (props) => {
  return (
    <Select native className="w100" name="rowPerPage" value={props.value} onChange={props.onChange} data-testid={props["data-testid"]} {...props.customProps}>
      {CM.cfnGetOptionPageSize().map((option, index) => {
        return (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        );
      })}
    </Select>
  );
};

/*
 * @desc    RadioGroup 컴포넌트
 * @param   {
 *            name: 이름
 *            value: 값
 *            onChange: onChange 함수
 *            option: option 배열
 *            optionValue: optionValue 이름
 *            optionLabel: optionLabel 이름
 *            testId: data-testid
 *            row: radio 정렬 방향
 *          }
 * @return  { div }
 */
export const RadioGroupForm = ({ name, value, onChange, option, optionValue = "value", optionLabel = "label", testId, row = false, customProps }) => {
  return (
    <RadioGroup name={name} value={value} onChange={onChange} data-testid={testId} row={row} {...customProps}>
      {option.map((element, index) => {
        return <FormControlLabel key={index} control={<Radio color="primary" />} value={element[optionValue]} label={element[optionLabel]} />;
      })}
    </RadioGroup>
  );
};

/*
 * @desc    Pagination 컴포넌트
 * @param   {
 *            pagination: 페이지네이션 object
 *            onClick: function
 *            testId: data-testid
 *          }
 * @return  { div }
 */
export const PaginationForm = (props) => {
  return (
    <Pagination
      className="centered"
      limit={props.pagination.rowsPerPage || props.limit}
      offset={props.pagination.offset || props.offset}
      total={props.filteredRows ? props.filteredRows.length : props.pagination.total || props.total}
      onClick={props.onClick}
      innerButtonCount={2}
      outerButtonCount={1}
      size="large"
      data-testid={props.testId}
      centerRipple={false}
      disableFocusRipple={true}
      disableRipple={true}
    />
  );
};

/*
 * @desc    직인 이미지 업로드용 Input + Button 을 생성하는 컴포넌트
 * @param   {
 *            uploadFile: 선택된 파일 관련 정보를 담을 state
 *            setUploadFile: 선택된 파일 관련 정보를 담을 state의 setter
 *          }
 * @return  { Input + Button }
 */
export const SealImageUploadForm = ({ imageFile, setImageFile }) => {
  const fileInput = React.useRef();

  //파일찾기 버튼 클릭 이벤트 핸들러
  const handleFindFile = () => {
    fileInput.current.click();
  };

  //이미지 파일 resizing 처리 함수
  const fnResizeSealImage = (image, type, name) => {
    let resizeRatio = 0.7; //기존 이미지의 70%로 축소
    let newImage = fnCreateNewImageFile(resizeRatio, image, type, name);

    while (newImage.size > 100 * 1024) {
      //업로드 제한 용량 이하로 줄어들 때까지 반복문 실행하며 resizing 파일 생성하는 함수 실행
      resizeRatio -= 0.1;
      if (resizeRatio < 0.5) {
        CM.cfnAlert("직인 이미지파일의 크기가 너무 큽니다. 작은 크기의 이미지를 선택해주세요.");
        newImage = null;
        break;
      }
      newImage = fnCreateNewImageFile(resizeRatio, image);
    }

    return newImage;
  };

  //이미지 파일 선택 시 이벤트 핸들러
  const handleSelectedImage = (file, image) => {
    const MAX_SIZE = 100 * 1024;
    let tempImage = file;
    let src = image.src;

    //제한 용량 초과 시 이미지 크기 조정 함수 호출
    if (file.size > MAX_SIZE) {
      tempImage = fnResizeSealImage(image, file.type, file.name);
      //blob 파일 생성한 경우 src 설정을 위해 하기 코드 실행
      src = URL.createObjectURL(tempImage);
    }

    setImageFile({
      ...imageFile,
      file: tempImage,
      fileName: file.name,
      fileSrc: src,
    });
  };

  //파일 선택 이벤트 핸들러
  const handleChange = (e) => {
    setImageFile({
      ...imageFile,
      file: null,
      fileName: "",
      fileSrc: "#",
    });

    const file = e.currentTarget.files[0];

    if (file && file.name) {
      const isImage = file.type.indexOf("image") !== -1 ? true : false;
      if (isImage) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = (e) => {
            handleSelectedImage(file, image);
          };
        };
        reader.readAsDataURL(file);
      } else {
        CM.cfnAlert("이미지 파일만 선택하실 수 있습니다.");
      }
    }
  };

  return (
    <React.Fragment>
      <label className="label-1">등록된 파일 : </label>
      <input type="file" style={{ display: "none" }} accept="image/jpg, image/jpeg, .jpg, .jpeg" onChange={handleChange} ref={fileInput} value="" data-testid="selected-file-input" />
      <Input value={imageFile.fileName} readOnly={true} data-testid="uploaded-file-name" onClick={handleFindFile} placeholder="등록된 파일이 없습니다." />
      <Button className="btn-file" onClick={handleFindFile} data-testid="find-file">
        파일 선택
      </Button>
    </React.Fragment>
  );
};

/*
 * @desc    실시간서비스 화면 내 동의자료 업로드 Input
 * @param   {
 *            accountUniqueKey : 동의자료 첨부 대상 계좌의 UUID
 *            fileInput : 화면 내 숨겨진 input의 ref
 *            handleSearchButtonChange: 파일 등록 성공 후 재조회 실행 함수
 *          }
 * @return  { Input + Button }
 */
export const AccountEvidenceFileInput = ({ accountUniqueKey, fileInput, handleSearchButtonChange }) => {
  //파일 선택 이벤트 핸들러
  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];

    if (file && file.name) {
      const isImage = file.type.indexOf("image") !== -1 ? true : false;
      let tempObj = {
        file: file,
        fileName: file.name,
        src: "#",
      };

      if (isImage) {
        //선택한 파일이 이미지일 경우
        const reader = new FileReader();
        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = async (e) => {
            //제한 용량 초과 여부 확인하여 리사이징 처리 시작
            tempObj = await fnCheckEvidenceImageFile(file, image, 300);
          };
        };
      }

      fnSubmitEvidenceFile(accountUniqueKey, tempObj.file, tempObj.name);
    }
  };

  //선택된 파일 저장 Request
  const fnSubmitEvidenceFile = (accountUniqueKey, file, fileName) => {
    const url = `api/customer/receipt/account/extra/account-registration/files`;

    const form = new FormData();
    if (file && fileName && fileName !== "") {
      form.append("evidenceFile", file, fileName);
    } else {
      form.append("evidenceFile", file);
    }
    form.append("accountUniqueKey", new Blob([JSON.stringify(accountUniqueKey)], { type: "application/json" }));

    CM.cfnAxios(
      url,
      "post",
      form,
      (status, objData) => {
        CM.cfnAlert(objData, () => {
          handleSearchButtonChange(true);
        });
      },
      (error) => {
        CM.cfnAlert(
          error && error.response && error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : error && error.response && error.response.data && typeof error.response.data.message === "string"
            ? error.response.data.message
            : "요청한 양이 너무 많아 처리가 지연되고 있습니다. 처리는 계속 진행중이니 일정시간 이후에 결과를 확인하시기 바랍니다.",
          () => {}
        );
      }
    );
  };

  return (
    <React.Fragment>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) => handleFileChange(e)}
        ref={fileInput}
        accept=".jpg, .jpeg, .gif, .tif, .pdf, .der, .mp3, .wav, .wma, .txt, .jb64"
        value=""
        data-testid="selected-file-input"
      />
    </React.Fragment>
  );
};

/*
 * @desc    자동납부 동의자료 제출 시 제출 가능한 포맷 안내 테이블 렌더 컴포넌트
 * @return  { Component }
 */
export const EvidenceFileTypeInformation = () => {
  return (
    <Table className="contentsTable">
      <TableHead>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">동의자료 구분</TableCell>
          <TableCell className="contentsTableCell">동의자료 확장자</TableCell>
          <TableCell className="contentsTableCell">최대 크기(KByte)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">서면</TableCell>
          <TableCell className="contentsTableCell">jpg, jpeg, gif, tif, pdf</TableCell>
          <TableCell className="contentsTableCell">300</TableCell>
        </TableRow>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">전자문서·공인전자서명</TableCell>
          <TableCell className="contentsTableCell">der(RFC 2630 SignedData를 DER 인코딩 형태로 생성)</TableCell>
          <TableCell className="contentsTableCell">10</TableCell>
        </TableRow>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">전자문서·일반전자서명</TableCell>
          <TableCell className="contentsTableCell">jpg, jpeg, gif, tif, pdf</TableCell>
          <TableCell className="contentsTableCell">300</TableCell>
        </TableRow>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">녹취</TableCell>
          <TableCell className="contentsTableCell">mp3, wav, wma</TableCell>
          <TableCell className="contentsTableCell">300</TableCell>
        </TableRow>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">ARS</TableCell>
          <TableCell className="contentsTableCell">mp3, wav, wma</TableCell>
          <TableCell className="contentsTableCell">300</TableCell>
        </TableRow>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">전자문서·기타</TableCell>
          <TableCell className="contentsTableCell">jpg, jpeg, gif, tif, pdf, der, mp3, wav, wma, txt, jb64</TableCell>
          <TableCell className="contentsTableCell">300</TableCell>
        </TableRow>
        <TableRow className="contentsTableRow">
          <TableCell className="contentsTableCell">전자문서·사설전자서명</TableCell>
          <TableCell className="contentsTableCell">der(RFC 2630 SignedData를 DER 인코딩 형태로 생성)</TableCell>
          <TableCell className="contentsTableCell">10</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/*
 * @desc    사후점검 파일 개별 업로드 컴포넌트
 * @param   {
 *            acceptFileType : 업로드를 허용할 파일 유형
 *            needPreview : 미리보기 기능의 필요 여부 (Boolean)
 *            evidenceFile: 선택된 파일 관련 정보를 담을 state
 *            setEvidenceFile: 선택된 파일 관련 정보를 담을 state의 setter
 *          }
 * @return  { Input + Button }
 */
export const PostCheckEvidenceFileUploadForm = ({ evidenceFile, setEvidenceFile, inModalFile, setInModalFile, fnSubmitEvidenceFile }) => {
  const fileInput = React.useRef();

  //파일찾기 버튼 클릭 이벤트 핸들러
  const handleFindFile = () => {
    fileInput.current.click();
  };

  /*
   * @desc    입력란 Validation 체크 함수
   */
  const fnCheckValidation = () => {
    if (CM.cfnIsEmpty(inModalFile.fileType)) {
      CM.cfnAlert("동의자료 구분을 선택하여 주시기 바랍니다.");
      return false;
    }

    if (CM.cfnIsEmpty(inModalFile.fileName)) {
      CM.cfnAlert("등록할 파일을 선택하여 주시기 바랍니다.");
      return false;
    } else {
      //파일 용량 체크
      const selectedSize = inModalFile.fileSize;

      if (inModalFile.fileType === "PUBLIC_SIGNATURE" && selectedSize > 5 * 1024) {
        CM.cfnAlert(`공인전자서명파일의 크기는 5KB 이내여야 합니다.\n선택된 파일 : ${Math.round(selectedSize / 1024, 0)}KB`);
        return false;
      } else if (selectedSize > 300 * 1024) {
        CM.cfnAlert(`자동납부 동의자료의 크기는 300KB 이내여야 합니다.\n선택된 파일 : ${Math.round(selectedSize / 1024, 0)}KB`);
        return false;
      } else {
        //동의파일 유형 선택에 따른 파일타입 체크
        const fileName = inModalFile.fileName;
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toUpperCase();

        switch (inModalFile.fileType) {
          case "PAPER":
          case "GENERAL_SIGNATURE":
            if (fileType !== "JPG" && fileType !== "JPEG" && fileType !== "GIF" && fileType !== "TIF" && fileType !== "PDF") {
              CM.cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.");
            }
            break;
          case "RECORDING":
          case "ARS":
            if (fileType !== "MP3" && fileType !== "WAV" && fileType !== "WMA") {
              CM.cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.");
            }
            break;
          case "PUBLIC_SIGNATURE":
          case "PRIVATE_SIGNATURE":
            if (fileType !== "DER") {
              CM.cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.("+ fileType + ")");
            }
            break;
          default:
            if (
              fileType !== "JPG" &&
              fileType !== "JPEG" &&
              fileType !== "GIF" &&
              fileType !== "TIF" &&
              fileType !== "PDF" &&
              fileType !== "MP3" &&
              fileType !== "WAV" &&
              fileType !== "WMA" &&
              fileType !== "DER" &&
              fileType !== "TXT" &&
              fileType !== "JB64"
            ) {
              CM.cfnAlert("동의자료 구분에 해당하는 파일이 아닙니다.");
            }
            break;
        }
      }
    }

    return true;
  };

  const handleSubmitFile = (e) => {
    e.preventDefault();

    if (fnCheckValidation()) {
      //state 초기화
      setInModalFile({
        file: null,
        fileName: "",
        fileSrc: "#",
        fileType: "",
        isFirstLoaded: false,
      });

      if (fnSubmitEvidenceFile !== "") {
        fnSubmitEvidenceFile();
      }
    }
  };

  //파일 선택 이벤트 핸들러
  const handleFileChange = async (e) => {
    const file = e.currentTarget.files[0];
    if (file && file.name) {
      //선택된 파일이 있을 시 프로세스 진행
      const isImage = file.type.indexOf("image") !== -1 && file.type.indexOf("tif") === -1;
      const isPdf = file.type.indexOf("pdf") !== -1;
      const isAudio = file.type.indexOf("audio") !== -1;

      //기존에 iframe 영역이 존재하면 삭제
      var oriNode = document.getElementById("preview-iframe");
      if (oriNode != null) {
        document.getElementById("preview-section").removeChild(oriNode);
      }

      let tempObj = {
        file: file,
        src: "#",
      };

      if (isImage) {
        //선택된 파일이 이미지일 경우
        const reader = new FileReader();

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = async (e) => {
            // size 조정을 위한 처리
            tempObj = await fnCheckEvidenceImageFile(file, image, 300);
            setInModalFile((inModalFile) => ({
              ...inModalFile,
              file: tempObj.file,
              fileName: file.name,
              fileSrc: tempObj.src,
              fileSize: tempObj.file.size,
            }));
            const reader2 = new FileReader();
            reader2.onload = function () {
              setEvidenceFile((evidenceFile) => ({
                ...evidenceFile,
                file: reader2.result,
                isFileChanged: true,
                fileName: file.name,
                fileSize: tempObj.file.size,
              }));
            };
            reader2.readAsArrayBuffer(tempObj.file);
          };
        };
        reader.readAsDataURL(tempObj.file);
      }
      if (isPdf || isAudio) {
        //선택된 파일이 pdf거나 audio일 경우
        const reader = new FileReader();
        reader.onload = function (e) {
          const blob = e.target.result;
          var iframe = document.createElement("iframe");
          iframe.setAttribute("src", e.target.result);
          iframe.setAttribute("id", "preview-iframe");
          iframe.style.width = "70%";
          iframe.style.height = "400px";

          document.getElementById("preview-section").appendChild(iframe);

          //tempObj = fnCheckEvidenceImageFile(file, image, 300);
          setInModalFile((inModalFile) => ({
            ...inModalFile,
            file: blob,
            fileName: file.name,
            fileSrc: tempObj.src,
            fileSize: tempObj.file.size,
          }));
        };

        reader.readAsDataURL(tempObj.file);
        const reader2 = new FileReader();
        reader2.onload = function (e) {
          setEvidenceFile((evidenceFile) => ({
            ...evidenceFile,
            file: reader2.result,
            isFileChanged: true,
            // fileType: file.type,
            fileName: file.name,
            fileSize: tempObj.file.size,
          }));
        };
        reader2.readAsArrayBuffer(tempObj.file);
      } else {
        const reader = new FileReader();
        reader.onload = function (e) {
          setEvidenceFile((evidenceFile) => ({
            ...evidenceFile,
            file: reader.result,
            isFileChanged: true,
            // fileType: file.type,
            fileName: file.name,
            fileSize: tempObj.file.size,
          }));
        };
        reader.readAsArrayBuffer(tempObj.file);
      }
    }
  };

  //동의자료 구분 셀렉트 박스 변경 이벤트 핸들러
  const handleEvidenceTypeChange = (e) => {
    setInModalFile({
      ...inModalFile,
      fileType: e.target.value,
    });
    setEvidenceFile({
      ...evidenceFile,
      fileType: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <form name="evidenceFileForm" onSubmit={handleSubmitFile}>
        <div className="modify-area">
          <div className="block">
            <label className="label-l" data-testid="select-evidence-label">
              동의자료 구분
            </label>
            <FormControl>
              <SelectForm
                value={inModalFile.fileType}
                handleChange={handleEvidenceTypeChange}
                name="evidenceFileType"
                arrayOption={CM.cfnGetEvidenceFileTypeNumber()}
                optionValue={"evidenceFileType"}
                optionLabel={"evidenceFileTypeName"}
                className="box-preview"
                disabled
              />
            </FormControl>
            <label className="label-l">파일경로</label>
            <React.Fragment>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
                ref={fileInput}
                accept=".jpg, .jpeg, .gif, .tif, .pdf, .der, .mp3, .wav, .wma, .txt, .jb64"
                value=""
                data-testid="selected-file-input"
              />

              <React.Fragment>
                <Input value={inModalFile.fileName} readOnly={true} onClick={() => handleFindFile()} data-testid="uploaded-file-name" />
                <Button className="btn-file" onClick={() => handleFindFile()} data-testid="find-file">
                  파일 찾기
                </Button>
              </React.Fragment>
            </React.Fragment>
          </div>
        </div>
        <div id="preview-section" className="preview-section">
          <img src={inModalFile.fileSrc} alt="" className="preview-image" data-testid="preview-image" />
        </div>
        <div className="table-bottom-area">
          <button type="submit" className="btn-l fr">
            동의자료 등록
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
