import React, { useState, useEffect } from "react";
import RegistrationAwait from "./RegistrationAwait";
import RegistrationsResult from "./RegistrationsResult";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Registrations = () => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  // 이전 page로부터 전달받은 값
  let history = useHistory();
  let tabIdx = "";

  if (Object.isExtensible(history.location.state)) {
    tabIdx = history.location.state.tabIndex;
  }
  useEffect(() => {
    if (tabIdx !== "") {
      setTabIndex(tabIdx);
    } else {
      setTabIndex(0);
    }
  }, [setTabIndex, tabIdx]);

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="account-tab">
          <Tab disableRipple value={0} label="처리대기" />
          <Tab disableRipple value={1} label="처리완료" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <div>
          <div className="inforbox">
            <ul>
              <li>
                금융기관 접수분은 수신기한(접수일자 + 2영업일 15:00)내에 수신하실 수 있습니다.
                <br />
                단, 수신기한이 종료되어도 금융기관 접수분을 수신하여 "해지"건을 반영처리할 수 있습니다.
              </li>
              <li>
                금융기관 접수분을 수신하지 않은 채로 수신기한이 종료되면 <b>CMS에서</b> "등록" 건은 "오류"로, "해지" 건은 "정상"으로 자동 처리됩니다. <br />
                단, 계좌변경 건은 "변경등록" 및 "변경해지" 건 모두 "오류"로 처리됩니다.
              </li>
              <li>수신기한 내 수신하였으나 미처리건이 남아 결과를 발송하지 않은 상태로 결과 발송기한이 경과한 "등록" 및 "해지" 건은 금융기관에서 모두 "정상(등록/해지 완료)"으로 처리됩니다.</li>
            </ul>
          </div>
        </div>
        <RegistrationAwait tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div>
          <div className="inforbox">
            <ul>
              <li>수신기한 내 수신하였으나 미처리건이 남아 결과를 발송하지 않은 상태로 결과 발송기한이 경과한 "등록" 및 "해지" 건은 금융기관에서 모두 "정상(등록/해지 완료)"으로 처리됩니다.</li>
              <li>"해지등록"건은 등록 완료 및 결과 발송시까지 "처리대기" 상태가 되며, 출금 요청시 금융기관에서 출금 처리되지 않습니다.</li>
            </ul>
          </div>
        </div>
        <RegistrationsResult tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default Registrations;
