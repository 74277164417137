import React, { useState, useEffect } from "react";
import * as Common from "../../common/Common";
import { EditableCellInput, EditableCellSelect } from "../../template/ComponentForm";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";

import { Modal, Button, Input, FormControl, Select, Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import TableForm from "../../template/TableForm";

const CapitalsModal = (props) => {
  const store = props.store;

  const defaultValue = {
    bankbookContents: "",
    bankbookPostfix: "",
    bankbookPostfixType: "",
    capitalName: "",
    capitalType: props.capitalType === "PAYMENT" ? "PAYMENT" : "RECEIPT",
  };

  //필요 state 선언
  const [capitalRows, setCapitalRows] = useState([]);

  //기존 데이터 실패 시 온전한 데이터를 본 컴포넌트에 넘겨 주기 위해 state의 copy 선언
  const [copyCapitalRows, setCopyCapitalRows] = useState([]);
  const [bankbookOptions, setBankbookOptions] = useState([]);
  const [newCapital, setNewCapital] = useState(defaultValue);

  useEffect(() => {
    /*
     * @desc    자금종류 조회 Request
     */
    const fnFetchCapitalList = () => {
      const fnSetCapitalList = (objStatus, data) => {
        if (objStatus.status !== 200) {
          Common.cfnAlert(objStatus.statusText);
          return;
        }

        store.setCapital(data);
        setCapitalRows(data);
        setCopyCapitalRows(data);
      };

      const storeCapital = Common.cfnCopyObject(toJS(store.capital));
      if (Common.cfnIsEmpty(storeCapital)) {
        store.getCapital(fnSetCapitalList);
      } else {
        setCapitalRows(storeCapital);
        setCopyCapitalRows(storeCapital);
      }
    };

    /*
     * @desc    업무정보 조회 Request
     */
    const fnFetchBusinessInfo = () => {
      const fnSetBusinessInfo = (objStatus, data) => {
        if (objStatus.status !== 200) {
          Common.cfnAlert(objStatus.statusText);
          return;
        }

        store.setBusinessInfo(data); // store 값 설정
        fnSetBankBookOptions(data.bankbookContents.bankbookContents);
      };

      const storeBusinessInfo = Common.cfnCopyObject(toJS(store.businessInfo));
      if (Common.cfnIsEmpty(storeBusinessInfo)) {
        store.getBusinessInfo(fnSetBusinessInfo);
      } else {
        fnSetBankBookOptions(storeBusinessInfo.bankbookContents.bankbookContents);
      }
    };

    if (props.open) {
      fnFetchBusinessInfo();
      fnFetchCapitalList();
    }
  }, [store, props.open]);

  /*
   * @desc    자금종류 관리 모달창 close 이벤트 핸들러
   */
  const handleCapitalClose = async () => {
    //기존 데이터 수정 실패 후 바로 모달창을 닫았을 경우를 대비하여 copy state를 전달
    await props.setCapitals(copyCapitalRows);
    props.setOpen(false);
  };

  /*
   * @desc    신규 자금종류 추가 버튼 클릭 이벤트 핸들러
   */
  const handleCapitalSave = (e) => {
    e.preventDefault();
    if (fnCheckValidation(newCapital, false)) {
      Common.cfnConfirm("등록하시겠습니까?", fnInsertNewCapital);
    }
  };

  /*
   * @desc    기존 자금종류 데이터 수정 버튼 클릭 이벤트 핸들러
   */
  const handleCapitalUpdate = (row) => {
    if (fnCheckValidation(row, true)) {
      Common.cfnConfirm("수정하시겠습니까?", () => {
        fnUpdateCapital(row);
      });
    }
  };

  /*
   * @desc    테이블 내 행 삭제 버튼 클릭 이벤트 핸들러
   */
  const handleCapitalDelete = (uniqueKey) => {
    Common.cfnConfirm("삭제하시겠습니까?", () => {
      fnDeleteCapital(uniqueKey);
    });
  };

  /*
   * @desc    자금종류 type에 따라 한글명 출력 함수
   */
  const fnConvertCapitalType = (type) => {
    return type === "PAYMENT" ? "지급자금" : "수납자금";
  };

  /*
   * @desc    통장기재내역 셀렉트박스 옵션 목록 설정 함수
   */
  const fnSetBankBookOptions = (data) => {
    let tempArray = [];

    if (Common.cfnIsNotEmpty(data)) {
      for (let i = 0; i < data.length; i++) {
        tempArray.push({
          label: data[i],
          value: data[i],
        });
      }
    }

    setBankbookOptions(tempArray);
  };

  /*
   * @desc    신규 자금종류 입력란 변경 이벤트 핸들러
   */
  const handleNewCapital = (name) => (event) => {
    setNewCapital({
      ...newCapital,
      [name]: event.target.value,
    });
  };

  /*
   * @desc    테이블 내 통장기재내역(추가기재) Input 변경 이벤트 핸들러
   */
  const handlePostfixChange = (e, row) => {
    //fieldName 선언에 대한 경고 메시지로 인해 handleCapitalRowsChange를 바로 호출하지 않고 함수 분리
    handleCapitalRowsChange(row, {
      fieldValue: e.target.value,
      fieldName: "bankbookPostfix",
    });
  };

  /*
   * @desc    테이블 내 자금종류 입력란 변경 이벤트 핸들러
   */
  const handleCapitalRowsChange = (row, change) => {
    const tempArray = [...capitalRows];
    const uniqueKey = row.uniqueKey;
    let rowIndex = null;

    tempArray.forEach((row, index) => {
      if (row.uniqueKey === uniqueKey) rowIndex = index;
    });

    tempArray[rowIndex][change.fieldName] = change.fieldValue;
    if (change.fieldName === "bankbookPostfixType") {
      tempArray[rowIndex]["bankbookPostfix"] = "";
    }
    setCapitalRows(tempArray);
  };

  /*
   * @desc    입력된 자금종류명에 대한 validation 체크 함수
   */
  const fnCheckValidation = (data, isUpdate) => {
    const capitalName = data.capitalName;

    if (Common.cfnIsEmpty(capitalName)) {
      Common.cfnAlert("자금종류명을 입력하여 주시기 바랍니다.");
      return false;
    }

    if (Common.cfnGetByteLength(capitalName) > 20) {
      Common.cfnAlert("자금종류명은 한글 10자 또는 영문,숫자 20자까지 입력가능합니다.");
      return false;
    }

    for (let i = 0; i < capitalRows.length; i++) {
      let uniqueKey = null;
      //기존 데이터 수정인 경우 uniqueKey까지 비교하도록 값 대입
      if (isUpdate) uniqueKey = data.uniqueKey;

      if (!isUpdate && capitalRows[i].capitalName === capitalName) {
        //신규 데이터 입력인 경우 기존 자금종류명과 비교
        Common.cfnAlert("이미 등록된 이름입니다");
        return false;
      } else if (isUpdate && capitalRows[i].uniqueKey !== uniqueKey && capitalRows[i].capitalName === capitalName) {
        //기존 데이터 수정인 경우 기존 데이터의 uniqueKey 및 자금종류명과 비교
        Common.cfnAlert("이미 등록된 이름입니다");
        return false;
      }
    }

    return true;
  };

  /*
   * @desc    자금종류 조회 Request
   */
  const fnFetchCapitalList = async () => {
    await store.getCapital(fnSetCapitalList);
  };

  /*
   * @desc    자금종류 조회 Request Callback
   */
  const fnSetCapitalList = (objStatus, responseData) => {
    if (objStatus.status === 200) {
      setCapitalRows(responseData);
      setCopyCapitalRows(responseData);
    }
  };

  /*
   * @desc    신규 자금종류 저장 Request
   */
  const fnInsertNewCapital = () => {
    const url = "api/institute/capitals";
    Common.cfnAxios(url, "post", newCapital, fnInsertCallback);
  };

  /*
   * @desc    신규 자금종류 저장 Request Callback
   */
  const fnInsertCallback = (objStatus, objData) => {
    if (objStatus.status !== 200) {
      Common.cfnAlert(objStatus.statusText);
    } else {
      Common.cfnAlert("정상적으로 처리되었습니다.");
      setNewCapital(defaultValue);
      fnFetchCapitalList();
    }

    return false;
  };

  /*
   * @desc    테이블 내 행 수정 Request
   */
  const fnUpdateCapital = async (rowData) => {
    const url = "api/institute/capitals";
    await Common.cfnAxios(url, "put", rowData, fnUpdateCallBack);
  };

  /*
   * @desc    테이블 내 행 삭제 Request
   */
  const fnDeleteCapital = async (uniqueKey) => {
    const url = `api/institute/capitals/${uniqueKey}`;
    await Common.cfnAxios(url, "delete", "", fnUpdateCallBack);
  };

  /*
   * @desc    테이블 내 행 수정/삭제 Request Callback
   */
  const fnUpdateCallBack = (objStatus, objData) => {
    if (objStatus.status === 304) {
      Common.cfnAlert("변경된 내용이 없습니다. 다시 확인해주시기 바랍니다.");
      return false;
    } else if (objStatus.status !== 200) {
      Common.cfnAlert(objStatus.statusText);
      return false;
    } else if (objStatus.status === 200) {
      Common.cfnAlert(objData);
      fnFetchCapitalList();
    }
  };

  return (
    <Modal open={props.open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleCapitalClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>자금종류</h3>
          <div className="inforbox">
            <ul>
              <li>자금종류는 고객의 자금 청구 등 내부 관리 목적으로 활용하시면 됩니다.</li>
              <li>통장기재내역은 입출금 대상 고객의 계좌에서 자금 입출금시 통장에 기재되는 내용으로 기본 기재 및 추가기재 내역이 기재됩니다.</li>
              <li>통장기재내역의 기본기재 내용 변경은 CMS 홈페이지에서 또는 02-531-3334번으로 연락바랍니다.</li>
            </ul>
          </div>

          <div className="modify-area">
            <div className="block">
              <form name="capitals">
                <label className="label-l">자금구분</label>
                <FormControl>
                  <Select
                    native
                    value={newCapital.capitalType}
                    onChange={handleNewCapital("capitalType")}
                    required={true}
                    input={<Input name="capitalType" />}
                    name="capitalType"
                    inputProps={{ "data-testid": "capital-type-select" }}>
                    <option key="RECEIPT" value="RECEIPT">
                      수납자금
                    </option>
                    <option key="PAYMENT" value="PAYMENT">
                      지급자금
                    </option>
                  </Select>
                </FormControl>
                <label className="label-l">자금종류명</label>
                <Input className="w150" value={newCapital.capitalName} onChange={handleNewCapital("capitalName")} placeholder="자금종류명 입력" required={true} data-testid="new-capital-name" />
                <label className="label-l">통장기재</label>
                <FormControl>
                  <Select
                    native
                    value={newCapital.bankbookContents}
                    onChange={handleNewCapital("bankbookContents")}
                    required={true}
                    input={<Input name="bankbookContents" />}
                    name="bankbookContents"
                    inputProps={{ "data-testid": "bankbook-contents-select" }}>
                    <option key="" value="">
                      기본내용
                    </option>
                    {Common.cfnIsNotEmpty(bankbookOptions) &&
                      bankbookOptions.map((row, index) => {
                        return (
                          <option key={index} value={row.value}>
                            {row.label}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    native
                    value={newCapital.bankbookPostfixType}
                    onChange={handleNewCapital("bankbookPostfixType")}
                    required={true}
                    input={<Input name="bankbookPostfixType" />}
                    name="bankbookPostfixType"
                    inputProps={{ "data-testid": "bankbook-post-select" }}>
                    <option value={""}>추가내용</option>
                    {Common.cfnGetPostFixTypes().map((row, index) => {
                      return (
                        <option key={index} value={row.bankbookPostfixType}>
                          {row.bankbookPostfixTypeName}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* 직접입력 시에만 보이도록 코드 추가할 것
                 <FormControl>
                <Input
                value={newCapital.bankbookPostfix}
                onChange={handleNewCapital("bankbookPostfix")}
                className="w100"
                readOnly={
                  newCapital.bankbookPostfixType === "MANUAL" ? false : true
                }
              />
             </FormControl>
            */}
                <button type="button" className="btn-l" data-testid="submit-add" onClick={handleCapitalSave}>
                  추가
                </button>
              </form>
            </div>
          </div>
          <Table aria-labelledby="tableTitle" data-testid="capitals-table">
            {Common.cfnCompColGroup(["15%", "15%", "auto", "auto", "15%"])}
            <TableForm.compTableHead arrData={["자금구분", "자금종류", "통장기재내역(기본)", "통장기재내역(추가기재)", "관리"]} />
            <TableBody>
              {capitalRows.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={5} />
              ) : (
                capitalRows.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">{fnConvertCapitalType(row.capitalType)}</TableCell>
                      <TableCell align="center">
                        <EditableCellInput
                          row={row}
                          fieldName="capitalName"
                          onCellValueChange={handleCapitalRowsChange.bind(this, row)}
                          size="w120"
                          inputProps={{
                            "aria-label": "description",
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" scope="row" padding="none">
                        <EditableCellSelect
                          options={bankbookOptions}
                          optionValue={"value"}
                          optionLabel={"label"}
                          row={row}
                          fieldName="bankbookContents"
                          onCellValueChange={handleCapitalRowsChange.bind(this, row)}
                        />
                      </TableCell>
                      <TableCell align="center" scope="row" padding="none">
                        <EditableCellSelect
                          options={Common.cfnGetPostFixTypes()}
                          optionValue={"bankbookPostfixType"}
                          optionLabel={"bankbookPostfixTypeName"}
                          row={row}
                          fieldName="bankbookPostfixType"
                          onCellValueChange={handleCapitalRowsChange.bind(this, row)}
                        />
                        <Input
                          className="w100"
                          readOnly={row.bankbookPostfixType === "MANUAL" ? false : true}
                          value={Common.cfnNvl(row.bankbookPostfix, "")}
                          onChange={(e) => handlePostfixChange(e, row)}
                          placeholder={Common.cfnConvertPostFix(row.bankbookPostfixType)}
                        />
                      </TableCell>
                      <TableCell align="center" component="th" scope="row" padding="none">
                        <div className="btn-l" onClick={handleCapitalUpdate.bind(this, row)}>
                          수정
                        </div>

                        <div className="btn-m" onClick={(e) => handleCapitalDelete(row.uniqueKey)}>
                          삭제
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </Modal>
  );
};

export default inject((rootStore) => ({
  instituteStore: rootStore.InstituteStore,
}))(observer(CapitalsModal));
