import React, { useState, useEffect } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import {Input, Select} from "@material-ui/core";

// hooks 초기 값
const DEFAULT_HOOKS = {
  requestParams: {
    fileUniqueKey: "", // 파일 unique key
    pageNumber: 0, // 요청 페이지번호
    pageSize: 25, // 페이지당 조회건수
    sortDirection: "ASC", // 정렬방법(ASC or DESC)
    sortProperty: "payerNo", // 정렬필드
    searchProperty : "", //검색조건
    searchContents: "", //검색내용
  },
};

function SearchForm(props) {
  const { requestParams, handleSearchFormChange, handleSearchButtonChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    handleSearchButtonChange(true);
  };

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSearchButtonChange(true);
    }
  };

  return (
      <div className="search-area centered">
        <div className="block">
          <label className="label-l">검색어입력</label>
          <Select native name="searchProperty" value={requestParams.searchProperty} onChange={handleSearchFormChange("searchProperty")}>
            <option value="CUSTOMER_NAME">고객명</option>
            <option value="PAYER_NO">납부자번호</option>
            <option value="ACCOUNT_NO">계좌번호</option>
          </Select>
          <Input
              value={requestParams.searchContents}
              onChange={handleSearchFormChange("searchContents")}
              onKeyUp={handleSearchKeyUp}
              name="searchContents"
              className="w120"
              data-testid="cms-request-keyword-resulttab"
          />
          <button className="search-button" onClick={() => fnSearch()}>
            검색
          </button>
        </div>
      </div>
  );
}

// 고객정보관리 > 직접 접수분 > 등록요청 내역 > 상세정보 modal
// 렌더를 처리하는 메인 컴포넌트
const RegisteredDetailList = (props) => {
  // hooks
  const [searchOn, setSearchOn] = useState(false); // 검색 실행 flag hooks
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams); // 검색 조건 hooks
  const [registeredDetailList, setRegisteredDetailList] = useState([]); // 목록 hooks
  const [pagination, setPagination] = useState(CM.cfnPaginationData(25)); // 페이지네이션 hooks


  // 검색 실행 flag handler
  const handleSearchOnChange = (param) => setSearchOn(param);

  // 검색 조건 handler
  const handleRequestParamsChange = (param) => setRequestParams(param);

  // 목록 handler
  const handleRegisteredDetailListChange = (param) => setRegisteredDetailList(param);

  // 페이지네이션 handler
  const handlePaginationChange = (param) => setPagination(CM.cfnSetPagination(param));

  // 페이지 handler
  const handlePageChange = (e, offset, page) => {
    setRequestParams((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchOnChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = ({ target: { value } }) => {
    handleRequestParamsChange((data) => ({ ...data, pageNumber: 0, pageSize: value }));
    handleSearchOnChange(true);
  };

  // 정렬 조건 변경 이벤트 핸들러
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    handleRequestParamsChange((data) => ({ ...data, sortProperty, sortDirection }));

    //정렬조건 세팅 후 검색 trigger
    handleSearchOnChange(true);
  };

  // 엑셀 저장
  const saveExcel = (param) => {
    let url = "api/customer/receipt/account/institute/registered/detailed/excel";
    url += `?fileUniqueKey=${param}&sortProperty=${requestParams.sortProperty}&sortDirection=${requestParams.sortDirection}`
    url += `&searchProperty=${requestParams.searchProperty}&searchContents=${requestParams.searchContents}`;

    CM.cfnAxiosFileDownload(url, "get", "");
  };

  /*
 * @desc    CMS등록/해지요청 결과 목록 조회 검색 조건 입력란 변경 이벤트 핸들러
 */
  const handleSearchFormChange = (name) => (e) => {
      setRequestParams({
        ...requestParams,
        [name]: e.target.value,
      });
  };

  // 목록 검색
  useEffect(() => {
    // Axios 실행
    const getAxios = (param) => {
      return new Promise((resolve) => {
        let url = "api/customer/receipt/account/institute/registered/detailed";
        url += `?fileUniqueKey=${param.fileUniqueKey}`;
        url += `&pageNumber=${param.pageNumber}&pageSize=${param.pageSize}`;
        url += `&sortDirection=${param.sortDirection}&sortProperty=${param.sortProperty}`
        url += `&searchProperty=${param.searchProperty}&searchContents=${param.searchContents}`;

        CM.cfnAxios(url, "get", "", (objectStatus, resultData) => resolve(resultData));
      });
    };

    const getRegistered = async (param) => {
      const resultData = await getAxios(param);

      handleRegisteredDetailListChange(resultData.content); // 목록
      handlePaginationChange(resultData); // 페이지네이션
      handleSearchOnChange(false); // 검색 실행
    };

    if (searchOn) getRegistered(requestParams);
    return () => handleSearchOnChange(false); // 검색 실행
  }, [requestParams, searchOn]);

  // modal이 open될 때 검색 flag 변경 useEffect
  useEffect(() => {
    if (props.modalOpen) {
      handleRequestParamsChange((data) => ({ ...data, searchProperty : props.searchRequest.searchProperty, searchContents : props.searchRequest.searchContents, fileUniqueKey: props.selectedFileUniqueKey }));
      handleSearchOnChange(true);
    }
    return () => {
      handleRequestParamsChange(DEFAULT_HOOKS.requestParams);
      setRegisteredDetailList([]);
      setPagination(CM.cfnPaginationData(25));
    };
  }, [props.modalOpen, props.searchRequest, props.selectedFileUniqueKey]);

  return (
    <Modal open={props.modalOpen}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button
              className="fr btn-close"
              onClick={() => {
                props.handleModalOpenChange(false);
              }}
              data-testid="registerAccountByInstitute-registeredDetail-close-modal">
              {""}
            </Button>
          </div>
          <h3>등록요청 내역 상세보기</h3>
          <SearchForm requestParams={requestParams} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={setSearchOn} />
          <div className="table-top-area">
            <CF.TotalCountForm totalElements={pagination.total} />
            <CF.RowPerPageForm value={requestParams.pageSize} onChange={handleRowPerPageChange} data-testid="registerAccountByInstitute-registeredDetail-select-rowPerPage" />
            <button className="btn-m fr" onClick={() => saveExcel(props.selectedFileUniqueKey)} data-testid="registerAccountByInstitute-registeredDetail-save-excel">
              엑셀저장
            </button>
          </div>
          <ListForm
            list={registeredDetailList}
            pagination={pagination}
            handlePageChange={handlePageChange}
            financialInstitutes={props.financialInstitutes}
            requestParams={requestParams}
            handleSortProperty={handleSortProperty}
          />
        </div>
      </div>
    </Modal>
  );
};

// 목록 컴포넌트
const ListForm = (props) => {
  return (
    <div>
      <Table>
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          value=""
          arrData={[
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "expectedResultDate", label: "자금종류", sortable: false },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "financialInstituteName", label: "금융기관", sortable: false },
            { id: "depositorName", label: "예금주명", sortable: true },
            { id: "accountNo", label: "계좌번호", sortable: false },
            { id: "depositorIdentificationNoFirst7", label: "예금주 생년월일\n(사업자번호)", sortable: true },
            { id: "accountRegistrationStatus", label: "신청구분", sortable: true },
            { id: "fileResultCodeMessage", label: "등록결과", sortable: false },
          ]}
          searchRequest={props.requestParams}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId={"registerAccountByInstitute-registeredDetailList-list-head-sortLabel"}
        />

        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.list.map((target, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center">{target.payerNo}</TableCell>
                  <TableCell align="center">{target.capitalName}</TableCell>
                  <TableCell align="center">{target.customerName}</TableCell>
                  <TableCell align="center">{target.financialInstituteName || CM.cfnMatchBankName(target.financialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{target.depositorName}</TableCell>
                  <TableCell align="center">{CM.cfnMasking(target.accountNo)}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(target.depositorIdentificationNo)}</TableCell>
                  <TableCell align="center">{target.accountRegistrationType}</TableCell>
                  <TableCell align="center">
                    {CDN.appliedResultStatus(target.appliedResultStatus)}
                    {target.fileResultCodeMessage !== null && target.fileResultCodeMessage !== ""
                      ? "/" + target.fileResultCodeMessage + (target.fileResultCode ? "(" + target.fileResultCode.replace("E_", "") + ")" : "")
                      : ""}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <CF.PaginationForm pagination={props.pagination} onClick={props.handlePageChange} testId="registerAccountByInstitute-registeredDetail-pagination" />
    </div>
  );
};

export default RegisteredDetailList;
