import React, { useState } from "react";

import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";

// import { TabPanel } from "../../template/ComponentForm";

import PaperBillPublishTargetTab from "./PaperBillPublishTargetTab";
import PaperBillIssueHistoryTab from "./PaperBillIssueHistoryTab";

//부가서비스 > 영수증 발행 > 종이영수증 메인 tab
//렌더를 처리하는 메인 컴포넌트
const PaperBill = () => {
  //필요 state 선언
  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
        <Box>{children}</Box>
      </Typography>
    );
  };
  /*
   * @desc  탭 변경 이벤트 핸들러
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="account-tab">
          <Tab disableRipple value={0} label="발행대상 조회" data-testid="tab-paperbill-targets" />
          <Tab disableRipple value={1} label="발행내역" data-testid="tab-paperbill-issued" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <PaperBillPublishTargetTab tabIndex={tabIndex} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <PaperBillIssueHistoryTab tabIndex={tabIndex} />
      </TabPanel>
    </div>
  );
};

export default PaperBill;
