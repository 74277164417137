import React, { useEffect, useState, Fragment } from "react";
import * as CM from "../../common/Common";
import { Table, Radio, Select, TableRow, TableBody, TableCell, TextField, RadioGroup, FormControlLabel } from "@material-ui/core";

import DonationData, { getPersonalDonationTypeName, getPersonalDonationTypeCode, getBusinessDonationTypeCode, getBusinessDonationTypeName } from "./DonationBillData";
import * as DF from "./DonationForm";

import TargetCustomerSearchModal from "./TargetCustomerSearchModal";
import DonationBillPrintModal from "./DonationBillPrintModal";
import CDN from "../../common/CommonDataName";
/*
 * @desc  대상 고객 검색 컴포넌트
 */
const CustomerSearchForm = ({ setSearchOpen, searchRequest, handleChangeSearchForm }) => {
  return (
    <div className="search-area">
      <div className="block">
        <Select
          native
          value={searchRequest.customerType}
          onChange={handleChangeSearchForm("customerType")}
          inputProps={{ "data-testid": "donationIndividualIssue-customer-type-select" }}
          onClick={() => setSearchOpen(true)}
          disabled={true}>
          <option value="INDIVIDUAL">개인고객</option>
          <option value="BUSINESSER">사업자고객</option>
        </Select>
        <label className="label-l">고객명</label>
        <TextField value={searchRequest.customerName} disabled={true} data-testid="customerInformation-search-input" onClick={() => setSearchOpen(true)} />
        <label className="label-l">주민등록번호(사업자번호)</label>
        <TextField value={CM.cfnIdentificationNoFormat(searchRequest.identificationNo)} disabled={true} data-testid="customerInformation-search-input" onClick={() => setSearchOpen(true)} />
        <button className="search-button" onClick={() => setSearchOpen(true)}>
          조회
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  대상 고객 정보 컴포넌트
 */
const CustomerInformation = ({ searchRequest, selectedCustomer, handleChangeSearchForm }) => {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["12%", "18%", "12%", "27%", "12%", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">개인/사업자구분</TableCell>
            <TableCell className="td" align="center">
              {CDN.customerType(selectedCustomer.customerType) || ""}
            </TableCell>
            <TableCell className="th">고객명</TableCell>
            <TableCell className="td" align="center">
              {selectedCustomer.customerName || ""}
            </TableCell>
            <TableCell className="th">{selectedCustomer.customerType === "INDIVIDUAL" ? "주민등록번호" : "사업자번호"}</TableCell>
            <TableCell className="td" align="center">
              {CM.cfnIdentificationNoFormat(selectedCustomer.identificationNo) || ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">주소</TableCell>
            <TableCell className="td" colSpan={5}>
              {DonationData.fnPrintCustomerAddress(selectedCustomer)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">기부년도</TableCell>
            <TableCell className="td" align="center">
              <Select native value={searchRequest.targetYear} onChange={handleChangeSearchForm("targetYear")} inputProps={{ "data-testid": "donationIndividualIssue-targetYear-select" }}>
                {DonationData.donationBillIssueTargetYearOptions().map((el, index) => {
                  return (
                    <option value={el.value} key={index}>
                      {el.label}
                    </option>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell className="th">발행방식</TableCell>
            <TableCell className="td">
              <RadioGroup name="isEachIssuingTarget" className="margin-left-20" value={searchRequest.isEachIssuingTarget} onChange={handleChangeSearchForm("isEachIssuingTarget")} row>
                <FormControlLabel key={0} checked={!!searchRequest.isEachIssuingTarget} control={<Radio color="primary" />} value="true" label="건별인쇄" />
                <FormControlLabel key={1} checked={!searchRequest.isEachIssuingTarget} control={<Radio color="primary" />} value="false" label="합계로인쇄" />
              </RadioGroup>
            </TableCell>
            <TableCell className="th">일련번호</TableCell>
            <TableCell className="td">{selectedCustomer.serialNumber} </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  발행 내용 목록 컴포넌트
 */
const ListForm = ({ searchRequest, selectedCustomer, targetList, handleTargetListChange, handleClickIssueButton, donationForm }) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of targetList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const targetLength = targetList.length;

    if (checkAllFlag === targetLength && targetLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [targetList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...targetList];
    cloneData[index][key] = value;
    handleTargetListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;
    setCheckAllRow(checkAllValue);
    handleTargetListChange((target) => target.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  /*
   * @desc  발행 내용 목록 내 입력란 변경 이벤트 핸들러
   */
  const handleChangeInput = (e, name, index) => {
    const cloneData = [...targetList];
    let inputValue = e.target.value;

    //수량, 단가 등 숫자만 입력 가능한 필드에는 입력값에 정규식 적용
    switch (name) {
      case "targetYear":
        inputValue = inputValue === "" ? "" : Number(inputValue.replace(/[^0-9]/g, "")).toString();
        break;
      case "targetMonth":
        inputValue = inputValue === "" ? "" : Number(inputValue.replace(/[^0-9]/g, ""));
        if (inputValue > 12) {
          inputValue = "12";
        } else {
          inputValue = inputValue + "";
        }
        break;
      case "donationQuantities":
      case "donationUnitPrice":
      case "donationAmount":
        inputValue = Number(inputValue.replace(/[^0-9]/g, ""));
        break;
      default:
        break;
    }

    cloneData[index][name] = inputValue;
    if (name === "donationQuantities" || name === "donationUnitPrice") {
      cloneData[index].donationAmount = (cloneData[index].donationQuantities || 0) * (cloneData[index].donationUnitPrice || 0);
    } else if (name === "donationAmount" && selectedCustomer.customerType !== "INDIVIDUAL") {
      cloneData[index].donationUnitPrice = (inputValue || 0) / (cloneData[index].donationQuantities || 1);
    }
    handleTargetListChange(cloneData);
  };

  /*
   * @desc  발행 내용 추가 버튼 클릭 이벤트 핸들러
   */
  const handleClickAddButton = () => {
    if (CM.cfnIsEmpty(selectedCustomer.customerName)) {
      CM.cfnAlert("추가할 신청인을 먼저 선택해주세요.");
      return false;
    }

    if (targetList.length > 11) {
      CM.cfnAlert("기부금은 기부년도별 12건까지만 발행가능합니다.");
      return false;
    }

    const currentList = CM.cfnCopyObject(targetList);
    let billUniqueKey = "";
    if (currentList.length > 0 && CM.cfnIsNotEmpty(currentList[0].donationbillUniqueKey)) billUniqueKey = currentList[0].donationbillUniqueKey;

    currentList.push({
      _checked: false,
      donationbillUniqueKey: billUniqueKey,
      donationbillDetailUniqueKey: "",
      isIssued: false,
      customerName: selectedCustomer.customerName,
      customerIdentificationNo: selectedCustomer.identificationNo,
      customerAddress: selectedCustomer.customerAddress,
      customerUniqueKey: selectedCustomer.customerUniqueKey,
      targetYear: searchRequest.targetYear,
    });
    handleTargetListChange(currentList);
  };

  /*
   * @desc  선택내역 삭제 버튼 클릭 이벤트 핸들러
   */
  const handleClickDeleteButton = () => {
    const tmpTargetList = [];
    for (const element of targetList) {
      if (!element._checked) tmpTargetList.push(element);
    }
    handleTargetListChange(tmpTargetList);
  };

  return (
    <div>
      <div className="table-top-area">
        <button className="btn-m fr" onClick={handleClickAddButton} data-testid="individualIssue-add-targets">
          발행 내용 추가
        </button>
        {targetList.length > 0 && (
          <Fragment>
            <button className="btn-m fr" onClick={handleClickDeleteButton} data-testid="individualIssue-delete-targets">
              선택내역 삭제
            </button>
            <button
              type="button"
              onClick={handleClickIssueButton}
              className="btn-l fr"
              disabled={
                targetList[0].isIssued ||
                (selectedCustomer.customerType === "INDIVIDUAL"
                  ? !donationForm.personalDonationType || donationForm.personalDonationType === ""
                  : !donationForm.businessDonationType || donationForm.businessDonationType === "")
              }>
              기부금영수증 발행
            </button>
          </Fragment>
        )}
      </div>
      {selectedCustomer.customerType === "INDIVIDUAL" ? (
        <DF.IndividualIssueTargetListForm
          checkAllRow={checkAllRow}
          handleCheckAllRowChange={handleCheckAllRowChange}
          targetList={targetList}
          handleCheckChange={handleCheckChange}
          handleChangeInput={handleChangeInput}
          searchRequest={searchRequest}
          isEachIssuingTarget={searchRequest.isEachIssuingTarget}
          donationbillTypeName={getPersonalDonationTypeName(donationForm.personalDonationType)}
          donationbillTypeCode={getPersonalDonationTypeCode(donationForm.personalDonationType)}
        />
      ) : (
        <DF.CorpIssueTargetListForm
          checkAllRow={checkAllRow}
          handleCheckAllRowChange={handleCheckAllRowChange}
          targetList={targetList}
          handleCheckChange={handleCheckChange}
          handleChangeInput={handleChangeInput}
          searchRequest={searchRequest}
          isEachIssuingTarget={searchRequest.isEachIssuingTarget}
          donationbillTypeName={getBusinessDonationTypeName(donationForm.businessDonationType)}
          donationbillTypeCode={getBusinessDonationTypeCode(donationForm.businessDonationType)}
        />
      )}
    </div>
  );
};

//부가서비스 > 기부금 영수증 > 개별 발행 탭
/*
 * @desc  메인 컴포넌트
 */
const IndividualIssueTab = ({ tabIndex, sealImage, donationForm }) => {
  //필요 state 선언
  const [searchRequest, setSearchRequest] = useState(DonationData.individualIssueSearchData); //개별발행 대상 고객 및 발행 대상 목록 조회 검색조건

  const [searchButton, setSearchButton] = useState(true);
  const [targetList, setTargetList] = useState([]);
  const [printList, setPrintList] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState({ customerType: "INDIVIDUAL" });

  //모달 제어
  const [searchOpen, setSearchOpen] = useState(false); //고객 조회 모달
  const [printOpen, setPrintOpen] = useState(false); //프린트 모달
  //고객 선택 모달 내에서 고객 선택 후 본 컴포넌트로 복귀 시 개별발행 조회
  useEffect(() => {
    /*
     * @desc    (개별발행) 조회 Request (기부년도가 선택/변경될때마다 재조회 필요)
     */
    const fnFetchIssueTargetList = () => {
      const url = `api/extraservice/donations/targets/customers/${searchRequest.customerUniqueKey}?targetYear=${searchRequest.targetYear}`;
      CM.cfnAxios(
        url,
        "get",
        "",
        (status, objData) => {
          let resultList = [];
          if (CM.cfnIsNotEmpty(objData.donationbillIssueTargets)) resultList = objData.donationbillIssueTargets.map((data) => ({ ...data, _checked: false }));

          setSelectedCustomer(objData.donationbillCustomerSummary);
          if (resultList.length > 0 && resultList[0].isIssued) {
            CM.cfnAlert(
              "선택하신 고객(" +
                objData.donationbillCustomerSummary.customerName +
                ")에게 " +
                searchRequest.targetYear +
                "년에 발행한 기부금영수증 내역이 있습니다. 발행 내용을 변경하시려면 발행내역 탭에서 내역을 삭제하신 후 재발행하시기 바랍니다."
            );
          } else if (objData.donationbillCustomerSummary.customerType === "INDIVIDUAL" && (!donationForm.personalDonationType || donationForm.personalDonationType === "")) {
            CM.cfnAlert("선택한 고객 유형(개인)에 대한 기부유형이 선택되어있지 않습니다. 해당 고객에게 영수증을 발행하시려면 기부금영수증 양식관리 탭에서 기부유형을 선택해주시기 바랍니다.");
          } else if (objData.donationbillCustomerSummary.customerType !== "INDIVIDUAL" && (!donationForm.businessDonationType || donationForm.businessDonationType === "")) {
            CM.cfnAlert("선택한 고객 유형(사업자)에 대한 기부유형이 선택되어있지 않습니다. 해당 고객에게 영수증을 발행하시려면 기부금영수증 양식관리 탭에서 기부유형을 선택해주시기 바랍니다.");
          }
          setTargetList(resultList);
          setSearchButton(false);
        },
        (status, objData) => {
          CM.cfnAlert(objData && objData.message ? objData.message : status.statusText);
          //조회 실패 시 검색조건에 세팅된 고객 관련 정보를 clear
          setSearchRequest((condition) => ({
            ...condition,
            customerName: "",
            identificationNo: "",
            customerUniqueKey: "",
          }));
          setSearchButton(false);
        }
      );
    };

    //실행영역
    if (tabIndex === 1 && searchButton) {
      if (CM.cfnIsNotEmpty(searchRequest.customerUniqueKey)) {
        fnFetchIssueTargetList();
      }
    }
  }, [tabIndex, searchButton, searchRequest.customerUniqueKey, searchRequest.targetYear, searchRequest.isEachIssuingTarget, donationForm.personalDonationType, donationForm.businessDonationType]);

  //기부금영수증 대상고객의 발행목록 변경 핸들러
  const handleTargetListChange = (value) => setTargetList(value);

  /*
   * @desc    개별 발행 조회 조건 입력란 변경 이벤트 핸들러
   */
  const handleChangeSearchForm = (name) => (e) => {
    switch (name) {
      case "identificationNo":
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        break;
      case "targetYear":
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value.replace(/[^0-9]/g, ""),
        });

        if (CM.cfnIsEmpty(selectedCustomer.customerName)) {
          //발행 대상 고객을 조회하지 않은 상태라면 모달창을 열어줌
          setSearchOpen(true);
        } else {
          //발행 대상 고객을 조회한 상태라면 재조회
          setSearchButton(true);
        }
        break;
      case "isEachIssuingTarget":
        const inputVal = e.target.value === "true" ? true : false;

        setSearchRequest({
          ...searchRequest,
          [name]: inputVal,
        });
        break;
      default:
        setSearchRequest({
          ...searchRequest,
          [name]: e.target.value,
        });
        break;
    }
  };

  /*
   * @desc    기부금영수증 발행 버튼 클릭 이벤트 핸들러
   */
  const handleClickIssueButton = () => {
    if (fnCheckValidation()) fnIssueDonationBill();
  };

  /*
   * @desc    기부금영수증 입력 내용 validation 체크 함수
   */
  const fnCheckValidation = () => {
    if (targetList.length === 0) {
      CM.cfnAlert("발행할 영수증 항목이 없습니다. 발행 내용 추가 버튼을 누르시어 내용을 추가하신 후 발행해 주세요.");
      return false;
    }
    if (targetList[0].isIssued) {
      CM.cfnAlert(
        "선택하신 고객(" +
          selectedCustomer.customerName +
          ")에게 " +
          searchRequest.targetYear +
          "년에 발행한 기부금영수증 내역이 있습니다. 발행 내용을 변경하시려면 발행내역 탭에서 내역을 삭제하신 후 재발행하시기 바랍니다."
      );
      return false;
    } else if (selectedCustomer.customerType === "INDIVIDUAL" && (!donationForm.personalDonationType || donationForm.personalDonationType === "")) {
      CM.cfnAlert("선택한 고객 유형(개인)에 대한 기부유형이 선택되어있지 않습니다. 해당 고객에게 영수증을 발행하시려면 기부금영수증 양식관리 탭에서 기부유형을 선택해주시기 바랍니다.");
      return false;
    } else if (selectedCustomer.customerType !== "INDIVIDUAL" && (!donationForm.businessDonationType || donationForm.businessDonationType === "")) {
      CM.cfnAlert("선택한 고객 유형(사업자)에 대한 기부유형이 선택되어있지 않습니다. 해당 고객에게 영수증을 발행하시려면 기부금영수증 양식관리 탭에서 기부유형을 선택해주시기 바랍니다.");
      return false;
    }
    const targetListObject = [];
    for (const target of targetList) {
      if (target.targetMonth && target.targetMonth.toString().length === 1) {
        target.targetMonth = "0" + target.targetMonth;
      }
      if (target._checked === true) targetListObject.push(target);
    }

    if (targetListObject.length === 0) {
      CM.cfnAlert("발행할 영수증 항목이 없습니다. 발행할 항목을 체크하신 후 발행해 주세요.");
      return false;
    }

    for (let i = 0; i < targetListObject.length; i++) {
      if (CM.cfnIsEmpty(targetListObject[i].targetYear)) {
        CM.cfnAlert(`미발행 건 중 ${i + 1}번째 항목의 연도를 입력해주세요.`);
        return false;
      }

      if (CM.cfnIsEmpty(targetListObject[i].targetMonth) && (searchRequest.isEachIssuingTarget || i === 0)) {
        CM.cfnAlert(`미발행 건 중 ${i + 1}번째 항목의 월을 입력해주세요.`);
        return false;
      }

      const y4mm = new Date(`${targetListObject[i].targetYear}, ${targetList[i].targetMonth}-01`);
      if (!y4mm.getFullYear && (searchRequest.isEachIssuingTarget || i === 0)) {
        CM.cfnAlert(`미발행 건 중 ${i + 1}번째 항목의 날짜를 날짜 형식에 맞게 입력해주세요.`);
        return false;
      }

      if (CM.cfnIsNotEmpty(targetListObject[i].donationContents) && targetListObject[i].donationContents.length > 30 && (searchRequest.isEachIssuingTarget || i === 0)) {
        CM.cfnAlert(`미발행 건 중 ${i + 1}번째 항목의 기부내용 길이가 너무 깁니다. (최대 30자)`);
        return false;
      }

      if (CM.cfnIsEmpty(targetListObject[i].donationAmount)) {
        CM.cfnAlert(`${i + 1}번째 항목의 금액을 입력해주세요.`);
        return false;
      }

      if (targetListObject[i].donationAmount.toString().length > 15) {
        CM.cfnAlert(`미발행 건 중 ${i + 1}번째 항목의 금액이 너무 큽니다.`);
        return false;
      }

      if (searchRequest.customerType !== "INDIVIDUAL" && searchRequest.isEachIssuingTarget) {
        //사업자고객 선택 시 수량 입력 내용까지 체크
        if (CM.cfnIsNotEmpty(targetListObject[i].donationQuantities) && targetList[i].donationQuantities > 999) {
          CM.cfnAlert(`미발행 건 중 ${i + 1}번째 항목의 수량이 너무 큽니다. 최대 수량은 999입니다.`);
          return false;
        }
      }
    }

    return true;
  };

  /*
   * @desc    기부금영수증 발행 Request ()
   */
  const fnIssueDonationBill = async () => {
    let sendObject = [];
    for (const element of targetList) {
      if (element._checked) sendObject.push(element);
    }
    if (!searchRequest.isEachIssuingTarget) {
      sendObject = [
        sendObject.reduce((a, b) => ({ ...a, donationQuantities: 1, donationUnitPrice: a.donationAmount + b.donationAmount, donationAmount: a.donationAmount + b.donationAmount }), {
          donationbillUniqueKey: null,
          donationbillDetailUniqueKey: null,
          isIssued: false,
          customerName: selectedCustomer.customerName,
          customerIdentificationNo: selectedCustomer.identificationNo,
          customerAddress: selectedCustomer.customerAddress,
          customerUniqueKey: selectedCustomer.customerUniqueKey,
          targetYear: searchRequest.targetYear,
          targetMonth: sendObject.length > 0 ? sendObject[0].targetMonth : "01",
          targetDay: sendObject.length > 0 ? sendObject[0].targetDay : "01",
          donationUnitPrice: 0,
          donationAmount: 0,
        }),
      ];
    }

    const url = `api/extraservice/donations/targets/customers/${selectedCustomer.customerUniqueKey}/${searchRequest.targetYear}`;
    CM.cfnAxios(url, "post", sendObject, async (status, data) => {
      await fnPrintIssueDonationBill(data);
      await setPrintOpen(true);
    });
  };

  const fnPrintIssueDonationBill = (donationUniqueKey) => {
    const url = `/api/extra-service-service/extraservice/donations/targets/print/${donationUniqueKey}?isIssuedIndividually=${searchRequest.isEachIssuingTarget}`;
    CM.cfnAxios(url, "get", "", async (status, data) => {
      await setPrintList(data);
      await setPrintOpen(true);
    });
  };

  return (
    <div>
      <CustomerSearchForm setSearchOpen={setSearchOpen} searchRequest={searchRequest} handleChangeSearchForm={handleChangeSearchForm} />

      <CustomerInformation
        searchRequest={searchRequest}
        handleChangeSearchForm={handleChangeSearchForm}
        setSearchOpen={setSearchOpen}
        selectedCustomer={selectedCustomer}
        setSearchButton={setSearchButton}
      />

      <ListForm
        searchRequest={searchRequest}
        selectedCustomer={selectedCustomer}
        targetList={targetList}
        handleTargetListChange={handleTargetListChange}
        handleClickIssueButton={handleClickIssueButton}
        donationForm={donationForm}
      />

      <TargetCustomerSearchModal
        open={searchOpen}
        setOpen={setSearchOpen}
        searchRequest={searchRequest}
        setSearchRequest={setSearchRequest}
        handleChangeSearchForm={handleChangeSearchForm}
        setIssueTargetSearchButton={setSearchButton}
      />
      <DonationBillPrintModal
        setSearchButton={setSearchButton}
        isEachIssuingTarget={searchRequest.isEachIssuingTarget}
        open={printOpen}
        setOpen={setPrintOpen}
        selectedRows={printList}
        sealImage={sealImage}
        donationForm={donationForm}
        donationTypeCode={
          selectedCustomer.customerType === "INDIVIDUAL" ? getPersonalDonationTypeCode(donationForm.personalDonationType) : getBusinessDonationTypeCode(donationForm.businessDonationType)
        }
        donationTypeName={
          selectedCustomer.customerType === "INDIVIDUAL" ? getPersonalDonationTypeName(donationForm.personalDonationType) : getBusinessDonationTypeName(donationForm.businessDonationType)
        }
      />
    </div>
  );
};

export default IndividualIssueTab;
