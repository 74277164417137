import React, { useState, useEffect, useCallback } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import * as CF from "../../template/ComponentForm";
import * as CM from "../../common/Common";
import { inject, observer } from "mobx-react";
import { TextField } from "@material-ui/core";

const FROM_DATE = CM.cfnGetCustomDate("month", -11, "string");

// hooks 초기화
const defaultHooks = {
  period: {
    fromYear: FROM_DATE.substr(0, 4),
    fromMonth: FROM_DATE.substr(4, 2),
  },
  searchOption: {
    group: [{ label: "전체", value: "" }],
    capital: [{ label: "전체", value: "" }],
    staff: [{ label: "전체", value: "" }],
  },
};

// 발행기간 "년" 생성
const createYearOption = (type) => {
  const arrYear = [];
  const endYear = new Date().getFullYear() + 1;
  const startYear = 2010;

  for (let i = startYear; i <= endYear; i++) {
    const label = `${i}년`;
    const value = String(i);

    arrYear.push({ label: label, value: value });

    // 시작년일 때는 해당 년 까지만 생성
    if (type === "start" && i + 1 === endYear) break;
  }

  return arrYear;
};

// 발행기간 "월" 생성
const createMonthOption = () => {
  const arrMonth = [];
  const endMonth = 12;

  for (let i = 1; i <= endMonth; i++) {
    let label = `${i}월`;
    let value = i < 10 ? `0${i}` : String(i);

    arrMonth.push({ label: label, value: value });
  }

  return arrMonth;
};

// 부가서비스 > 현금영수증 > 현금영수증 발행내역 탭 > 검색 form
// 렌더를 처리하는 메인 컴포넌트
const CashbillTabIssuedSearch = ({ props }) => {
  const { store } = props;

  const [period, setPeriod] = useState(defaultHooks.period);
  const [groupUniqueKey, setGroupUniqueKey] = useState(""); // 고객구분
  const [capitalUniqueKey, setCapitalUniqueKey] = useState(""); // 자금종류
  const [staffUniqueKey, setStaffUniqueKey] = useState(""); // 고객담당자
  const [method, setMethod] = useState("isAllIncluded"); // 발행방법
  const [searchProperty, setSearchProperty] = useState("CUSTOMER_NAME");
  const [searchContents, setSearchContents] = useState("");

  const [searchOption, setSearchOption] = useState({
    group: [],
    capital: [],
    staff: [],
    searchProperty: [
      { label: "고객명", value: "CUSTOMER_NAME" },
      { label: "납부자번호", value: "PAYER_NO" },
      { label: "생년월일(사업자번호)", value: "IDENTIFICATION_NO" },
    ],
  });

  // 발행기간 select option data
  const option = {
    fromYear: createYearOption("start"),
    fromMonth: createMonthOption(),
    toYear: createYearOption("end"),
    toMonth: createMonthOption(),
  };

  // 발행방법 radio option data
  const radioOption = [
    { label: "전체", value: "isAllIncluded" },
    { label: "직접발행", value: "isDirectIssueIncluded" },
    { label: "자동발행", value: "isKftcIssueIncluded" },
  ];

  // 발행기간 "년" 변경
  const handlePeriodYear = ({ target: { value } }) => {
    setPeriod((data) => ({ ...data, fromYear: String(value) }));
  };

  // 발행기간 "월" 변경
  const handlePeriodMonth = ({ target: { value } }) => {
    setPeriod((data) => ({ ...data, fromMonth: value, toMonth: value }));
  };

  // 고객구분 변경
  const handleCustomerGroup = ({ target: { value } }) => setGroupUniqueKey(value);

  // 자금종류 변경
  const handleCapitalName = ({ target: { value } }) => setCapitalUniqueKey(value);

  // 고객담당자 변경
  const handleCustomerStaffName = ({ target: { value } }) => setStaffUniqueKey(value);

  // 발행방법 변경
  const handleMethod = ({ target: { value } }) => setMethod(value);

  const handleSearchProperty = ({ target: { value } }) => setSearchProperty(value);

  const handleSearchContents = ({ target: { value } }) => setSearchContents(value);

  // 검색 시작
  const goSearch = () => {
    const fromDate = `${period.fromYear}${period.fromMonth}`;
    const toDate = `${period.toYear}${period.toMonth}`;
    const isAllIncluded = method === "isAllIncluded" ? true : false;
    const isDirectIssueIncluded = method === "isDirectIssueIncluded" ? true : false;
    const isKftcIssueIncluded = method === "isKftcIssueIncluded" ? true : false;

    props.handleSearchCondition((data) => ({
      ...data,
      capitalUniqueKey,
      groupUniqueKey,
      staffUniqueKey,
      fromDate,
      isAllIncluded,
      isDirectIssueIncluded,
      isKftcIssueIncluded,
      toDate,
      searchProperty,
      searchContents,
    }));

    props.handleSearchStart(true);
  };

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      goSearch();
    }
  };

  // 검색조건 select option data
  const getStore = useCallback(async () => {
    const storeCustomerGroup = await store.axiosCustomerGroup(); // 고객구분
    const storeCapital = await store.axiosCapitals(); // 자금종류
    const storeStaffs = await store.axiosStaffs(); // 고객담당자

    const arrCustomerGroup = [{ label: "전체", value: "" }];
    const arrCapital = [{ label: "전체", value: "" }];
    const arrStaffs = [{ label: "전체", value: "" }];

    for (const value of storeCustomerGroup) arrCustomerGroup.push({ label: value.customerGroupName, value: value.uniqueKey });
    for (const value of storeCapital) arrCapital.push({ label: value.capitalName, value: value.uniqueKey });
    for (const value of storeStaffs) arrStaffs.push({ label: value.staffName, value: value.uniqueKey });

    setSearchOption((option) => ({
      ...option,
      group: arrCustomerGroup,
      capital: arrCapital,
      staff: arrStaffs,
    }));
  }, [store]);

  useEffect(() => {
    getStore();
  }, [getStore]);

  // hooks 초기화
  const initializePage = useCallback(() => {
    setPeriod(defaultHooks.period);
    setGroupUniqueKey("");
    setCapitalUniqueKey("");
    setStaffUniqueKey("");
    setMethod("isAllIncluded");
  }, []);

  useEffect(() => {
    if (props.tabIndex === 2) initializePage();
  }, [props.tabIndex, initializePage]);

  return (
    <div className="search-area tl">
      <div className="block">
        <label className="label-l">발행기간(1년)</label>
        <CF.SelectForm value={period.fromYear} handleChange={handlePeriodYear} arrayOption={option.fromYear} customProps={{ "data-testid": "cashbill-issued-select-fromYear" }} />
        <CF.SelectForm value={period.fromMonth} handleChange={handlePeriodMonth} arrayOption={option.fromMonth} customProps={{ "data-testid": "cashbill-issued-select-fromMonth" }} />
        <span className="label-r">~ 부터 1년</span>
        <span className="label-l">발행방법</span>
        <FormControl component="fieldset">
          <RadioGroup name="method" value={method} onChange={handleMethod} data-testid="cashbill-issued-radio-method" row>
            {radioOption.map((element, index) => {
              return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
            })}
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        <span className="label-l">고객구분</span>
        <CF.SelectForm value={groupUniqueKey} handleChange={handleCustomerGroup} arrayOption={searchOption.group} customProps={{ "data-testid": "cashbill-issued-select-customerGroup" }} />
        <span className="label-l">자금종류</span>
        <CF.SelectForm value={capitalUniqueKey} handleChange={handleCapitalName} arrayOption={searchOption.capital} customProps={{ "data-testid": "cashbill-issued-select-capitalName" }} />
        <span className="label-l">고객담당자</span>
        <CF.SelectForm value={staffUniqueKey} handleChange={handleCustomerStaffName} arrayOption={searchOption.staff} customProps={{ "data-testid": "cashbill-issued-select-customerStaffName" }} />
        <label className="label-l">검색어입력</label>
        <CF.SelectForm value={searchProperty} handleChange={handleSearchProperty} name="searchProperty" arrayOption={searchOption.searchProperty} optionValue="value" optionLabel="label" />
        <TextField className="w150" value={searchContents} onChange={handleSearchContents} onKeyUp={handleSearchKeyUp} name="search" data-testid="modify-keyword-input" />
        <button className="search-button" onClick={goSearch} data-testid="cashbill-issued-button-search">
          검색
        </button>
      </div>
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(CashbillTabIssuedSearch));
