import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow, Input } from "@material-ui/core/";
import * as CM from "../../common/Common";

// 기관 업무정보 > 부가서비스 정보 > 전자세금계산서 발행
// 렌더를 처리하는 메인 컴포넌트
export default function TabTrusBill(props) {
  const { instituteService } = props;
  const [trusBillData, setTrusBillData] = useState(instituteService);

  // 트러스빌아이디 변경을 처리하는 handler
  const handleChangeTrusbillId = ({ target: { value, name } }) => {
    const re = /^\w*$/;
    if (!re.test(value) || value.length > 12) {
      return;
    }

    setTrusBillData((data) => ({ ...data, [name]: value }));
  };

  // 서비스 신청 또는 설정 변경
  const updateService = () => {
    const sendData = { ...trusBillData, serviceStartDate: CM.cfnGetDate(), serviceUseYn: true };
    axiosService(sendData);
  };

  // 서비스 해지
  const terminateService = () => {
    const sendData = { ...trusBillData, serviceUseYn: false, trusbillId: null };
    axiosService(sendData);
  };

  // 서비스 신청, 변경, 해지 axios
  const axiosService = (sendData) => {
    const url = "api/institute/services";
    CM.cfnAxios(url, "put", sendData, (status, data) => {
      CM.cfnAlert(data, () => {
        setTrusBillData(sendData);
        props.updateInstituteService(sendData, "TRUSBILL");
      });
    });
  };

  return (
    <div>
      <div className="modify-area">
        <div className="block">{trusBillData.serviceUseYn ? "현재 전자세금계산서 발행 서비스를 이용 중입니다." : "다음 내용으로 전자세금계산서 발행 서비스를 신청합니다."}</div>
      </div>
      <div>
        <h4>서비스 설정(전자세금계산서 발행)</h4>
      </div>
      <div>
        <Table>
          {CM.cfnCompColGroup(["20%", "auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th">트러스빌 아이디</TableCell>
              <TableCell className="td">
                <Input className="w250" name="trusbillId" value={trusBillData.trusbillId || ""} onChange={handleChangeTrusbillId} inputProps={{ "data-testid": "tabTrusBill-input-trusbillId" }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ServiceButtonForm useYn={trusBillData.serviceUseYn} updateService={updateService} terminateService={terminateService} />
      </div>
      <div className="inforbox">
        <h4>서비스안내</h4>
        <ul>
          <li>전자세금계산서 발행 서비스는 발행 건당 200원(부가세 별도)의 수수료가 부과됩니다.</li>
          <li>
            전자세금계산서를 발행하시려면 우선 금융결제원 전자세금계산서 서비스인{" "}
            <a href="https://www.trusbill.or.kr" target="_blank" rel="noopener noreferrer" className="link">
              트러스빌
            </a>
            에 가입하셔야 합니다.
          </li>
        </ul>
      </div>
    </div>
  );
}

// 서비스 설정 버튼
function ServiceButtonForm(props) {
  const { useYn, updateService, terminateService } = props;

  // 사용여부 체크
  if (useYn) {
    return (
      <div className="table-bottom-area">
        <button className="btn-l" onClick={updateService} data-testid="tabTrusBill-button-updateService">
          설정 변경
        </button>
        <button className="btn-l" onClick={terminateService} data-testid="tabTrusBill-button-terminateService">
          서비스 해지
        </button>
      </div>
    );
  }

  return (
    <div className="table-bottom-area">
      <button className="btn-l" onClick={updateService} data-testid="tabTrusBill-button-requestService">
        서비스 신청
      </button>
    </div>
  );
}
