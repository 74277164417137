import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import CustomerSearch from "./CustomerSearch";
import { CreateCustomerDivision, CustomerRequiredInputs, CustomerIndividualSelectInput, CustomerBusinessSelectInput } from "./CustomerForm";
import * as CM from "../../common/Common";
import { TooltipForm } from "../../template/ComponentForm";
import { Modal } from "@material-ui/core";
import { Button } from "@material-ui/core";
import StaffInfo from "../../institute/staffInfo/StaffInfo";
import CustMnmtTable from "../../institute/customerCriteria/CustMnmtTable";

/*
 * @desc: 렌더 메인 컴포넌트
 */
const CustomerCreate = ({ store, loginStore }) => {
  // 고객구분영역 hooks and handler
  const [firstIdentificationNo, setFirstIdentificationNo] = useState("");
  const [lastIdentificationNo, setLastIdentificationNo] = useState("");

  // 주민등록번호 앞자리, 뒷자리 handler
  const handleIdentificationNoChange = (e, type) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    changeIdentificationNo(value, type);
  };

  // 주민등록번호 앞자리, 뒷자리 입력시 해당 값 변경
  const changeIdentificationNo = (value, type) => {
    if (type === "first") {
      setFirstIdentificationNo(value);
    } else {
      setLastIdentificationNo(value);
    }
  };

  // 개인 && 사업 > 고객정보 > 필수 입력사항 hooks and handler
  const [selectRequiredInputs, setSelectRequiredInputs] = useState({
    customerGroup: "", // 고객구분
    staff: "", // 고객관리담당자
  });
  const [optionStaffsAndBranches, setOptionStaffsAndBranches] = useState([]);
  const [optionCustomerGroup, setOptionCustomerGroup] = useState([]);
  const [checkIdentificationNo, setCheckIdentificationNo] = useState(false); // 주민등록번호앞자리 체크 hooks

  // 필수 입력 사항 목록 가져오기
  useEffect(() => {
    const startStoreAxios = async () => {
      const customerGroups = await store.axiosCustomerGroup(); // 고객구분
      const resultStaffs = await store.axiosStaffs();
      setOptionCustomerGroup(customerGroups);

      // 관리담당자/담당지사 option data 설정
      const tempStaffs = resultStaffs.map((staff) => ({ uniqueKey: staff.uniqueKey, name: `${staff.staffName}/${staff.branch.branchName}` }));

      setOptionStaffsAndBranches(tempStaffs);

      setSelectRequiredInputs({
        ...selectRequiredInputs,
        staff: tempStaffs.length === 1 ? tempStaffs[0].uniqueKey : "",
        customerGroup: customerGroups.length === 1 ? customerGroups[0].uniqueKey : "",
      });

      setCustomer({
        ...customer,
        customerGroup: customerGroups[0],
        staff: resultStaffs[0],
      });
    };

    startStoreAxios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  // 선택한 고객구분, 고객담당지사, 고객관리담당자 값을 변경하는 handler
  const handleRequiredInputsHandler = (e, name) => {
    const value = e.target.value;
    setSelectRequiredInputs((data) => ({ ...data, [name]: value }));
  };

  // 고객정보 object 및 선택입력 사항 hooks & handler
  // 고객 이메일 주소
  const [customerEmail, setCustomerEmail] = useState({
    id: "",
    domain: "",
    selected: "manual",
  });

  // 사용자 정보
  const [customer, setCustomer] = useState({
    customerGroup: { uniqueKey: "" },
    staff: { branch: { uniqueKey: "" }, uniqueKey: "" },
    customerType: "INDIVIDUAL", // 고객 구분
    customerName: "", // 고객명
    identificationNo: "", // 전체 주민번호
    customerMobile: "", // 휴대전화번호
    customerEmail: "", // 이메일주소
    customerTelephone: "", // 유선전화번호
    customerFax: "", // 팩스번호
    customerDetailInfo: null,
    customerZipCode: "", // 우편번호
    customerAddress: "", // 주소
    roadAddressYn: true, // 도로명 주소 여부
    customerAddressDetail: "", // 상세 주소
    remark: "", // 비고
    cashbillIdentificationNo: "", // 현금영수증 발행정보
  });

  // 담당자 정보
  const [customerDetailInfo, setCustomerDetailInfo] = useState({
    결혼기념일: null,
    담당자명: "",
    담당자직위: "",
    대표자명: "",
    업태: "",
    업종: "",
  });

  // 담당자 정보 handler
  const handleCustomerDetailInfoChange = (name, e) => {
    const value = name !== "결혼기념일" ? e.target.value : e;
    setCustomerDetailInfo((data) => ({ ...data, [name]: value }));
  };

  // 현금영수증 발행정보 radio 값
  const [selectedCashbillIdentificationNo, setSelectedCashbillIdentificationNo] = useState("NONE");

  // 이메일 주소 handler
  const handleCustomerEmailChange = (e, name) => {
    const value = e.target.value;
    setCustomerEmail((email) => ({
      ...email,
      [name]: value,
    }));
  };

  // 이메일 주소 selectbox handler
  const handleSelectboxCustomerEmailChange = (e) => {
    const value = e.target.value;
    const domain = value !== "manual" ? value : "";

    setCustomerEmail((email) => ({
      ...email,
      domain: domain,
      selected: value,
    }));
  };

  // 선택입력사항 handler(이메일, 현금영수증 발행정보 제외)
  const handleCustomerChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    // 현금영수증 발행정보의 주민등록번호를 입력할 때
    if (name === "cashbillIdentificationNo" && selectedCashbillIdentificationNo === "IDENTIFICATION_NO") {
      value = value.replace(/[^0-9]/g, "");
    }

    changeCustomer(name, value);
  };

  // 현금영수증 발행정보 radio handler
  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedCashbillIdentificationNo(value);

    // 현금영수증 발행정보 input 초기화
    changeCustomer("cashbillIdentificationNo", "");
  };

  // 선택입력사항 입력시 해당 값 변경
  const changeCustomer = (name, value) => {
    setCustomer((data) => ({ ...data, [name]: value }));
  };

  // 등록 및 등록취소
  // 고객 등록시 필수 값 체크
  const checkRequired = () => {
    if (CM.cfnIsEmpty(selectRequiredInputs.customerGroup)) {
      CM.cfnAlert("고객구분은 필수 입력사항입니다.");
      return false;
    }

    if (CM.cfnIsEmpty(selectRequiredInputs.staff)) {
      CM.cfnAlert("고객담당자/고객지사는 필수 입력사항입니다.");
      return false;
    }

    if (selectedCashbillIdentificationNo !== "NONE" && CM.cfnIsEmpty(customer.cashbillIdentificationNo)) {
      CM.cfnAlert("현금영수증 발행정보를 입력해주세요.");
      return false;
    }

    return true;
  };

  const checkMaxCustomerCount = async () => {
    const businessInfo = await store.axiosBusinessInfo();
    const loginStaffInfo = loginStore.loginDto;
    const url = `/api/customer/basics/count?customerName=${customer?.customerName}&identificationNo=${(firstIdentificationNo || "") + (lastIdentificationNo || "")}`;

    let response = await CM.cfnAxiosAsync(url, "get", null, false);
    //현재 등록 된 고객 수와 기관 최대 고객 수 비교
    if (response.data >= businessInfo.maxCustomerCount) {
      //총괄담당자와 이용요금 담당자일 경우
      if (loginStaffInfo.masterYn || loginStaffInfo.billingManagerYn) {
        try {
          await CM.cfnConfirmAsync(
            "현재 등록된 고객수가 최대 등록가능 고객수에 도달하였습니다. 최대 등록가능 고객수를 100명 추가하신 후 등록하시겠습니까?\n※100명 추가 시 이용요금이 2,000원 증가됩니다."
          );
          businessInfo.maxCustomerCount = businessInfo.maxCustomerCount + 100;
          businessInfo.delayRate = null;
          await fnSubmitUrl(businessInfo);
          // true : 체크 완료
          return true;
        } catch (reject) {
          return false;
        }
      } else {
        CM.cfnAlert(
          "현재 등록된 고객수가 최대 등록가능 고객수에 도달하였습니다.\n최대 등록가능 고객수는 총괄담당자와 이용요금담당자가 변경할 수 있습니다.\n최대 등록가능 고객수 추가를 원하시면, 총괄담당자나 이용요금담당자에게 요청하시기 바랍니다."
        );
        return false;
      }
    } else if (response.data === -1) {
      CM.cfnAlert("이미 입력한 고객이름과 생년월일(사업자번호)로 등록되어있는 고객이 존재합니다.");
      return false;
    }
    return true;
  };

  const fnSubmitUrl = async (sendData) => {
    return new Promise((resolve, reject) => {
      CM.cfnAxios(
        `/api/institute/business`,
        "put",
        sendData,
        () => {
          store.getBusinessInfo(fnGetBusinssInfo);
          resolve();
        },
        (error) => {
          if (error.response.status === 304) {
            CM.cfnAlert("변경된 내용이 없습니다.", () => {});
          } else {
            let message = "업무정보를 변경하지 못했습니다.";
            if (typeof error.response.data === "string") message = error.response.data;
            else if (typeof error.response.data === "object") message = error.response.data.message;
            CM.cfnAlert(message || error.response);
          }
          reject();
        },
        false,
        true
      );
    });
  };

  /*
   * @desc    업무정보 조회 후 callback 함수
   */
  const fnGetBusinssInfo = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      // window.alert(objStatus.statusText);
      return false;
    }
    store.setBusinessInfo(data);
  };

  // 등록버튼 클릭시 고객 등록 처리
  const handleSubmitClick = async () => {
    //현재 등록 된 고객 수와 기관 최대 고객 수 비교
    if (!(await checkMaxCustomerCount())) {
      return false;
    }
    // 필수값 체크
    if (!checkRequired()) {
      return false;
    }

    const url = "api/customer/basics";
    const sendData = CM.cfnCopyObject(customer);
    const copyCustomerDetailInfo = {}; // 고객추가정보

    // 고객추가정보 값 확인
    for (const [key, value] of Object.entries(customerDetailInfo)) {
      if (CM.cfnIsNotEmpty(value)) {
        const applyValue = key === "결혼기념일" ? CM.cfnConvertDateToString(value) : value;
        copyCustomerDetailInfo[key] = applyValue;
      }
    }

    // 고객추가정보 값 설정
    sendData.customerGroup.uniqueKey = selectRequiredInputs.customerGroup;
    sendData.staff.uniqueKey = selectRequiredInputs.staff;
    sendData.customerDetailInfo = JSON.stringify(copyCustomerDetailInfo);
    sendData.customerMobile = CM.cfnReplaceSymbol(sendData.customerMobile);
    sendData.customerTelephone = CM.cfnReplaceSymbol(sendData.customerTelephone);
    sendData.customerFax = CM.cfnReplaceSymbol(sendData.customerFax);
    sendData.identificationNo = CM.cfnReplaceSymbol(sendData.identificationNo);

    // 현금영수증 발행정보의 마스킹 기호 삭제
    sendData.cashbillIdentificationNo = CM.cfnReplaceSymbol(sendData.cashbillIdentificationNo);

    let email = null;
    // 이메일이 빈값이 아니라면
    if (CM.cfnIsNotEmpty(customerEmail.id) || CM.cfnIsNotEmpty(customerEmail.domain)) {
      email = customerEmail.id + "@" + customerEmail.domain;
      // 이메일 형식 체크
      if (!CM.cfnCheckEmail(email)) {
        CM.cfnAlert("올바른 이메일 형식이 아닙니다.");
        return false;
      }
    }

    sendData.customerEmail = email;

    // 개인일 때
    if ("INDIVIDUAL" === customer.customerType) {
      sendData.identificationNo = firstIdentificationNo + lastIdentificationNo;
    }

    // 고객 등록 완료 후 update page 이동
    const fnCallback = (objStatus, customerUniqueKey) => {
      CM.cfnAlert("정상적으로 등록되었습니다.", () => {
        history.push("/customer/customerUpdate", {
          customer: { uniqueKey: customerUniqueKey },
        });
      });
    };

    // 고객기본정보 생성 api 호출
    CM.cfnAxios(url, "post", sendData, fnCallback);
  };

  // 기타 hooks
  const [modalOpen, setModalOpen] = useState(false); // 모달 open
  const [staffModalOpen, setStaffModalOpen] = useState(false);
  const [customerGroupModalOpen, setCustomerGroupModalOpen] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState([]); // 고객조회에서 검색된 고객 목록
  const history = useHistory();

  // 고객 조회 modal open
  const checkCustomerDuplication = async () => {
    let identificationNo = ""; // 주민등록번호 또는 사업자번호

    // 고객구분이 개인일 때
    if (customer.customerType === "INDIVIDUAL") {
      if (CM.cfnIsEmpty(customer.customerName) && CM.cfnIsEmpty(firstIdentificationNo)) {
        CM.cfnAlert("고객명 또는 주민등록번호 앞자리를 입력하세요.");
        return false;
      }

      identificationNo = firstIdentificationNo + lastIdentificationNo;
    } else {
      if (CM.cfnIsEmpty(customer.customerName) && CM.cfnIsEmpty(customer.identificationNo)) {
        CM.cfnAlert("고객명 또는 사업자등록번호를 입력하세요.");
        return false;
      }

      identificationNo = customer.identificationNo;
    }

    const resultData = await axiosSearchCustomer(customer.customerName, identificationNo);

    // 조회된 고객이 있다면 modal open
    if (resultData.length > 0) {
      setSearchCustomers(resultData);
      handleModalOpenChange(true);
    } else {
      CM.cfnAlert("고객이 존재하지 않습니다.");
    }
  };

  // 고객 조회 aixos
  const axiosSearchCustomer = (customerName, identificationNo) => {
    return new Promise((resolve) => {
      const url = `api/customer-service/customer/basics/duplication?customerName=${customerName}&identificationNo=${identificationNo}`;
      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  };

  // modalOpen handler
  const handleModalOpenChange = (value) => {
    setModalOpen(value);
  };

  // 화면 렌더
  return (
    <div>
      <CreateCustomerDivision
        customer={customer}
        handleCustomerChange={handleCustomerChange}
        changeCustomer={changeCustomer}
        firstIdentificationNo={firstIdentificationNo}
        lastIdentificationNo={lastIdentificationNo}
        handleIdentificationNoChange={handleIdentificationNoChange}
        changeIdentificationNo={changeIdentificationNo}
        checkIdentificationNo={checkIdentificationNo}
        setCheckIdentificationNo={setCheckIdentificationNo}
      />
      <div className="table-bottom-area">
        <button className="search-button" onClick={checkCustomerDuplication} data-testid="open-modal">
          고객 조회
        </button>
        <TooltipForm contents="이미 등록된 고객에게 새로운 수납·지급 방법을 추가할 경우, 고객명 및 주민등록번호 앞자리를 입력 후 고객조회로 검색하시면 됩니다" />
      </div>
      <CustomerRequiredInputs
        storeCustomerGroup={optionCustomerGroup}
        optionStaffsAndBranches={optionStaffsAndBranches}
        selectRequiredInputs={selectRequiredInputs}
        handleRequiredInputsHandler={handleRequiredInputsHandler}
        setCustomerGroupModalOpen={setCustomerGroupModalOpen}
        setStaffModalOpen={setStaffModalOpen}
      />
      <CustomerIndividualSelectInput
        customer={customer}
        handleCustomerChange={handleCustomerChange}
        changeCustomer={changeCustomer}
        customerEmail={customerEmail}
        handleCustomerEmailChange={handleCustomerEmailChange}
        selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
        handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
        handleRadioChange={handleRadioChange}
        customerDetailInfo={customerDetailInfo}
        handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
        checkIdentificationNo={checkIdentificationNo}
      />
      <CustomerBusinessSelectInput
        customer={customer}
        handleCustomerChange={handleCustomerChange}
        changeCustomer={changeCustomer}
        customerDetailInfo={customerDetailInfo}
        handleCustomerDetailInfoChange={handleCustomerDetailInfoChange}
        customerEmail={customerEmail}
        handleCustomerEmailChange={handleCustomerEmailChange}
        selectedCashbillIdentificationNo={selectedCashbillIdentificationNo}
        handleSelectboxCustomerEmailChange={handleSelectboxCustomerEmailChange}
        handleRadioChange={handleRadioChange}
      />
      <div className="table-bottom-area">
        <button type="submit" className="btn-l" onClick={handleSubmitClick}>
          등록
        </button>
        <button type="button" className="btn-m" onClick={() => history.goBack()}>
          등록취소
        </button>
      </div>
      <CustomerSearch modalOpen={modalOpen} handleModalOpenChange={handleModalOpenChange} searchCustomers={searchCustomers} type={"customerCreate"} />
      <Modal open={staffModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setStaffModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>담당자 정보</h3>
            <StaffInfo />
          </div>
        </div>
      </Modal>
      <Modal open={customerGroupModalOpen}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={() => setCustomerGroupModalOpen(false)} data-testid="close-modal">
                {""}
              </Button>
            </div>
            <h3>고객구분 관리</h3>
            <CustMnmtTable />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
  loginStore: rootStore.loginStore,
}))(observer(CustomerCreate));
