//import * as CM from "../../common/Common";

const EvidenceFileData = {
  //자동납부 동의자료 모달 내 사용 state 초기화
  evidenceFileDefaultValue: {
    file: null,
    fileName: "",
    fileSrc: "#",
    fileType: "",
    isFirstLoaded: true,
  },
  //수납고객정보 수정 화면에서 전달받은 동의자료 유형값 매핑 함수
  fnConvertEvidenceFileType: (type) => {
    switch (type) {
      case "1":
        return "PAPER";
      case "2":
        return "PUBLIC_SIGNATURE";
      case "3":
        return "GENERAL_SIGNATURE";
      case "4":
        return "RECORDING";
      case "5":
        return "ARS";
      case "6":
        return "ETC";
      default:
        return "";
    }
  },
};

export default EvidenceFileData;
