import React, { useRef, useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { useLocation } from "react-router-dom";

import { AppBar, Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "../../template/ComponentForm";

import makeStyles from "@material-ui/core/styles/makeStyles";

import * as CM from "../../common/Common";

import CmsPaymentRequest from "./CmsPaymentRequest";
import CmsPaymentRequestResult from "./CmsPaymentRequestResult";

/*
 * @desc  전체 disable 처리를 하는 css
 */
const useStyles = makeStyles((theme) => ({
  disabled_div: {
    "pointer-events": "none",
    opacity: 0.6,
  },
}));

/*
 * @desc  메인 컴포넌트 생성
 */
const CmsPayment = (props) => {
  const tabRef = useRef();
  const store = props.instituteStore;

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  const [businessInfo, setBusinessInfo] = useState(CM.cfnCopyObject(toJS(store.businessInfo))); //기관업무정보 제어
  const [isPaymentCustomer, setIsPaymentCustomer] = useState(false);

  const style = useStyles();
  // 이전 page로부터 전달받은 값
  let location = useLocation();
  let tabIdx = "";
  if (Object.isExtensible(location.state)) {
    tabIdx = location.state.tabIndex;
  }

  useEffect(() => {
    if (tabIdx !== "") {
      setTabIndex(tabIdx);
    }
  }, [setTabIndex, tabIdx]);

  //화면 진입 시 기관업무 정보 조회
  useEffect(() => {
    /*
     * @desc    기관 업무정보 조회 Request callback
     */
    const fnSetBusinessInfo = async (objStatus, objData) => {
      await store.setBusinessInfo(objData);

      fnSetPaymentUser(objData);
    };

    /*
     * @desc    출금이체/입금이체 서비스 이용 기관 여부 판별 및 데이터 세팅 함수
     */
    const fnSetPaymentUser = (data) => {
      const isEB31 = data.cmsService.indexOf("EB31") !== -1 ? true : false;
      let paymentFlag;

      if (isEB31) {
        paymentFlag = true;
      } else {
        paymentFlag = false;
        CM.cfnAlert("귀 기관은 CMS입금이체 서비스를 이용하지 않는 기관입니다.");
      }

      setBusinessInfo(data);
      setIsPaymentCustomer(paymentFlag);
    };

    //실행영역
    if (CM.cfnIsEmpty(businessInfo)) {
      store.getBusinessInfo(fnSetBusinessInfo);
    } else {
      fnSetPaymentUser(businessInfo);
    }
  }, [businessInfo, store]);

  /*
   * @desc  탭 인덱스 변경
   */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  return (
    <div>
      {isPaymentCustomer ? (
        <div>
          <AppBar position="static" color="default">
            <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="cms-payment-tab">
              <Tab ref={tabRef} disableRipple value={0} label="CMS 입금청구" />
              <Tab disableRipple value={1} label="CMS 입금청구 결과" />
            </Tabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
            <CmsPaymentRequest tabIndex={tabIndex} store={store} businessInfo={businessInfo} />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <div>
              <div className="inforbox">
                <ul>
                  <li>생성된 입금대상의 취소요청은 입금시행 전영업일 14시까지 가능합니다.</li>
                  <li>입금청구 건의 결과수신은 입금시행일 13시부터 가능합니다.</li>
                </ul>
              </div>
            </div>
            <CmsPaymentRequestResult tabIndex={tabIndex} setTabIndex={setTabIndex} targetTab={tabRef.current} businessInfo={businessInfo} />
          </TabPanel>
        </div>
      ) : (
        <div className={style.disabled_div}>
          <AppBar position="static" color="default">
            <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" data-testid="cms-payment-tab">
              <Tab ref={tabRef} disableRipple value={0} label="CMS 입금청구" />
              <Tab disableRipple value={1} label="CMS 입금청구 결과" />
            </Tabs>
          </AppBar>
        </div>
      )}
    </div>
  );
};

export default inject((rootStore) => ({
  instituteStore: rootStore.instituteStore,
}))(observer(CmsPayment));
