import React from "react";

//메인화면 > 오시는길
const Location = () => {
  return (
    <div className="ftm">
      <h3 className="title">오시는길</h3>
      <div className="content-box">
        <h4
          className="tgit itle"
          style={{
            marginTop: "10px !important"
          }}
        >
          분당센터
        </h4>
        <div className="map-image hidden">지도 들어갈 자리</div>
        <div className="contentbox">
          <table>
            <tbody>
              <tr>
                <th className="w150">전화</th>
                <td>1577-5500</td>
              </tr>
              <tr>
                <th>팩스</th>
                <td>02-531-1897</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>경기도 성남시 분당구 정자일로213번길 9 (경기도 성남시 분당구 정자동 10-3) (우)13556</td>
              </tr>
              <tr>
                <th>교통편</th>
                <td>
                  신분당선 정자역 5번 출구로 나오시면 분당 아이파크 쪽으로 직진, 분당 아이파크에서 우회전 하셔서 20미터 오시면 왼편에 금융결제원이 위치하고
                  있습니다.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
} 

export default Location;
