import React, { useEffect, useState } from "react";
import * as CM from "../../components/common/Common";

const Preview = (props) => {
  const [contentType, setContentType] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    let mounted = false;
    const fnGetImage = async data => {
      if(!mounted){
        const url = `api/support/popups/${props.isLogin ? 'authorized/' : ''}attachment?popupSequence=` + props.data.popupSequence;
        CM.cfnAxiosPreview(url, "get", "", (status, contentType, binary) => {
          if(!mounted){
            let type = contentType;
            if(type.indexOf(";") !== -1){
              type = type.substring(0, type.indexOf(";"));
            }
            setContentType(type);
            setFile(type === "text/plain" ? binary : new Buffer(binary, 'binary').toString('base64'));
            mounted = true;
          }
        }, null, false, true);
      }
    };
    (async () => {
      fnGetImage();
    })();
  }, [props]);

  return (
    contentType == null ? "" :
    (<img
      src={"data:" + contentType + ";base64," + file}
      alt="팝업 이미지"
      style={{maxWidth:'100%', maxHeight:'100%'}}
    />
    )
  );
}

export { Preview };

