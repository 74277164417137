import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import waitimage from "../../styles/images/waitimage.gif";
//import Loader from "react-loader-spinner";

const LoadingImage = props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="loading-image-area">
        <img src={waitimage} alt="wait" className="loading-image" />
      </div>
    )
  );
};

export default LoadingImage;
