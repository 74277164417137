import React, { useState, useEffect } from "react";

import { Table, TableBody, TableCell, TableRow, Button, Modal, Input } from "@material-ui/core";
// import TableForm from "../../template/TableForm";
import * as CM from "../../common/Common";

const PaymentRefundModal = ({ open, setOpen, contractDetail, setSearchButton }) => {
  const [value, setValue] = useState({});
  // 모달이 오픈될 때 value에 선택한 row의 object 값 넣기
  useEffect(() => {
    if (open) setValue(contractDetail);
    else setValue({});
  }, [open, contractDetail]);

  const updateButtonOnClick = () => {
    const fnSubmit = async () => {
      const sendData = {
        amountBeforeTransaction: value.unpaidAmount,
        amountAfterTransaction: Number(value.unpaidAmount) + Number(CM.cfnIsNotEmpty(value.amountAfterTransaction) ? CM.cfnReplaceSymbol(value.amountAfterTransaction) : 0),
        remark: value.remark,
        sourceUniqueKey: value.uniqueKey,
        transactionType: "REFUND",
      };

      const url = "api/payment/source/amount";
      CM.cfnAxios(url, "put", sendData, (status, data) => {
        CM.cfnAlert("수정되었습니다.", () => {
          setSearchButton(true);
          handleClose();
        });
      });
    };

    CM.cfnConfirm("수정하시겠습니까?", fnSubmit);
  };

  // 모달 클로즈
  const handleClose = () => setOpen(false);

  /*
   * @desc    입력란 변경 이벤트 핸들러
   */
  const handleValueChange = (name) => (e) => {
    if (name === "remark") {
      setValue({
        ...value,
        [name]: e.target.value,
      });
    } else {
      setValue({
        ...value,
        [name]: CM.cfnAddCommaOnChange(e.target.value.replace(/[^0-9]/g, "")),
      });
    }
  };

  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.askStatus === "HOLDING") {
          return "미청구\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "미청구\n(청구중)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.askStatus === "HOLDING") {
          return "미도래\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "미도래\n(청구중)";
        } else {
          return "미도래";
        }
      case "UNPAID":
        if (row.askStatus === "HOLDING") {
          return "미납\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "미납\n(청구중)";
        } else {
          return "미납";
        }
      case "PARTIALLY_PAID":
        if (row.askStatus === "HOLDING") {
          return "부분납\n(지급보류)";
        } else if (row.askStatus === "ASKING") {
          return "부분납\n(청구중)";
        } else {
          return "부분납";
        }
      case "PAID":
        return "지급";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "지급보류";
      default:
        if (row.askStatus === "ASKING" && row.numberOfAsking === 1) {
          return "입금중(최초입금)";
        } else if (row.askStatus === "ASKING" && row.numberOfAsking > 1) {
          return "입금중(재입금)";
        }
    }
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal" />
          </div>
          <h3>지급금액 환입</h3>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="th">고객명</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.customerName}
                </TableCell>
                <TableCell className="th">수납연월</TableCell>
                <TableCell className="td" align="center">
                  {CM.cfnDateFormat(contractDetail.targetY4mm, "yyyyMM")}
                </TableCell>
                <TableCell className="th">지급대상액</TableCell>
                <TableCell className="td" align="right">
                  {CM.cfnAddComma(Number(CM.cfnIsNotEmpty(value.totalAskingAmount) ? value.totalAskingAmount : 0))}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th" style={{ whiteSpace: "pre-wrap" }}>
                  생년월일{"\n"}(사업자번호)
                </TableCell>
                <TableCell className="td" align="center">
                  {CM.cfnIdentificationNoFormat(contractDetail.identificationNo)}
                </TableCell>
                <TableCell className="th">수납회차</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.payAmountType === "TEMPORARY_ADDED" ? "추가분" : contractDetail.contractAskSequence}
                </TableCell>
                <TableCell className="th">지급액</TableCell>
                <TableCell className="td" align="right">
                  {CM.cfnAddComma(Number(CM.cfnIsNotEmpty(value.paidAmount) ? value.paidAmount : 0))}원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">지급일</TableCell>
                <TableCell className="td" align="center">
                  {contractDetail.paySpecifiedDay === "99" ? "말일" : contractDetail.paySpecifiedDay}
                </TableCell>
                <TableCell className="th">지급상태</TableCell>
                <TableCell className="td" style={{ whiteSpace: "pre-wrap" }} align="center">
                  {fnAskStatus(contractDetail)}
                </TableCell>
                <TableCell className="th">환입액</TableCell>
                <TableCell className="td" align="right">
                  <Input
                    className="w100p"
                    value={value.amountAfterTransaction}
                    onChange={handleValueChange("amountAfterTransaction")}
                    name="amountAfterTransaction"
                    data-testid="amountAfterTransaction-input-refund"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    endAdornment="원"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th"></TableCell>
                <TableCell className="td"></TableCell>
                <TableCell className="th"></TableCell>
                <TableCell className="td"></TableCell>
                <TableCell className="th">환입후 지급액</TableCell>
                <TableCell className="td" align="right">
                  {CM.cfnAddComma(
                    Number(CM.cfnIsNotEmpty(value.paidAmount) ? value.paidAmount : 0) - Number(CM.cfnIsNotEmpty(value.amountAfterTransaction) ? CM.cfnReplaceSymbol(value.amountAfterTransaction) : 0)
                  )}
                  원
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th"></TableCell>
                <TableCell className="td"></TableCell>
                <TableCell className="th"></TableCell>
                <TableCell className="td"></TableCell>
                <TableCell className="th">비고</TableCell>
                <TableCell className="td">
                  <Input className="w100p" data-testid="remark-input-refund" value={value.remark} onChange={handleValueChange("remark")} name="remark" inputProps={{ maxLength: 40 }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="table-bottom-area">
            <button className="btn-l" data-testid="update-button" onClick={(e) => updateButtonOnClick()}>
              저장
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentRefundModal;
