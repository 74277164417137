import React, { Fragment } from "react";
import "../../../styles/extraService.scss";
import * as CM from "../../common/Common";

/*
 * @desc    인쇄를 위해 페이지 수에 맞춰 리스트 분할하여 재가공하는 함수
 */
export const fnMakePrintList = (list, size) => {
  const printArray = [];
  let totalPages = Math.floor(list.length / size); //생성해야 할 페이지 장수 계산
  if (list.length % Number(size) > 0) totalPages++;

  for (let i = 0; i < totalPages; i++) {
    //페이지 장수에 맞춰 분할된 리스트를 담을 Array 선언
    const tempArray = [];

    //선택된 고객 리스트를 규격에 맞춰 분할하도록 반복문 인덱스 설정
    const startIndex = i * Number(size);
    let endIndex = startIndex + Number(size);
    if (i === totalPages - 1 && list.length < endIndex) endIndex = list.length;

    for (let j = startIndex; j < endIndex; j++) {
      //규격에 맞춰 리스트 분할
      tempArray.push(list[j]);
    }

    printArray.push(tempArray);
  }

  return printArray;
};

/*
 * @desc    인쇄용 개별 라벨 컴포넌트 선언
 */
const PrintBox = (props) => {
  const { customer, printSettings } = props;

  return (
    <div className={`print-label-box-${printSettings.size}`}>
      <div className="print-label-box-top">
        <div className="print-label-address">{printSettings.printAddress ? `${CM.cfnNvl(customer.customerAddress, "")} ${CM.cfnNvl(customer.customerAddressDetail, "")}` : ""}</div>
      </div>

      <div className="print-label-box-bottom">
        <div className="print-label-box-bottom-l">
          <div className={`print-label-left print-label-text-${printSettings.size}`}>
            {printSettings.printMobileNo && CM.cfnIsNotEmpty(customer.customerMobile) ? "HP : " + CM.cfnAddtDashToPhoneNumber(customer.customerMobile) : ""}
          </div>
          <div className={`print-label-left print-label-text-${printSettings.size}`}>
            {printSettings.printTelNo && CM.cfnIsNotEmpty(customer.customerTelephone) ? "TEL : " + CM.cfnAddtDashToPhoneNumber(customer.customerTelephone) : ""}
          </div>
        </div>
        <div className="print-label-box-bottom-r">
          <div className={`print-label-name print-label-text-${printSettings.size}`}>{printSettings.printName && CM.cfnIsNotEmpty(customer.customerName) ? customer.customerName + " 귀하" : ""}</div>
          <div className={`print-label-zipcode print-label-text-${printSettings.size}`}>{printSettings.printZipCode ? CM.cfnNvl(customer.customerZipCode, "") : ""}</div>
        </div>
      </div>
    </div>
  );
};

/*
 * @desc    A4 용지 크기의 인쇄 영역 컴포넌트 선언
 */
const PrintPage = (props) => {
  return (
    <div className="print-label-area">
      {props.targetList.map((customer, index) => {
        return <PrintBox key={index} customer={customer} printSettings={props.printSettings} />;
      })}
    </div>
  );
};

/*
 * @desc    렌더 컴포넌트 선언
 */
const MailLabelPrintForm = (props) => {
  const { targetList, printSettings } = props;

  return (
    <Fragment>
      {fnMakePrintList(targetList, printSettings.size).map((row, index) => {
        //규격에 맞춰 선택된 리스트 재가공 후 페이지별로 인쇄
        return <PrintPage targetList={row} printSettings={printSettings} key={index} />;
      })}
    </Fragment>
  );
};

export default MailLabelPrintForm;
