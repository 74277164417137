import React, { useState, useEffect } from "react";
import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";
import { Modal, Table, TableBody, TableCell, TableRow, Button, Checkbox, Select } from "@material-ui/core";
// import { SelectForm } from "../../template/ComponentForm";
import * as Template from "../../template/ComponentForm";

/*
 * @desc     메인 컴포넌트 생성
 */
const ReceiptHistories = (props) => {
  const { open, setOpen, receiptInfo } = props;

  const defaultValue = (contractUniqueKey) => {
    const today = new Date();

    const monthAgo = new Date(today.getFullYear(), today.getMonth() - 2);
    const monthAfter = new Date(today.getFullYear(), today.getMonth() + 2);

    return {
      contractUniqueKey: contractUniqueKey,
      searchStartY4mm: monthAgo,
      searchEndY4mm: monthAfter,
    };
  };

  //필요 state 선언
  const [searchData, setSearchData] = useState(defaultValue(receiptInfo.uniqueKey)); //검색조건 제어
  const [receiptList, setReceiptList] = useState([]); //수납내역 목록 제어
  const [searchButton, setSearchButton] = useState(false);
  const [receiptDetailHistories, setReceiptDetailHistories] = useState([]);

  // 화면 open시 화면 초기화
  useEffect(() => {
    if (!open) {
      return;
    }
    setCheckAllRow(false);
    setSearchData(defaultValue(receiptInfo.uniqueKey));
    // state 초기화
    setSearchButton(true);
  }, [open, receiptInfo.uniqueKey]);

  useEffect(() => {
    /*
     * @desc 날짜(년월)변환함수 date to string
     */
    const cfnConvertDateToString = (date) => {
      let obj = date;
      //파라미터 객체가 Date 타입일 때만 변환
      if (date.getFullYear) {
        const year = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();

        obj = `${year}${mm[1] ? mm : 0 + mm[0]}`;
      }

      return obj;
    };

    /*
     * @desc 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = (search) => {
      let tempObj = CM.cfnCopyObject(search);
      //파라미터 객체가 Date 타입일 때만 변환
      tempObj.searchStartY4mm = cfnConvertDateToString(search.searchStartY4mm);
      tempObj.searchEndY4mm = cfnConvertDateToString(search.searchEndY4mm);
      return tempObj;
    };

    /*
     * @desc    회차별 수납 내역 요청 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/contract?searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&contractUniqueKey=${search.contractUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      const resultList = await axiosList(search);
      let resultListData = [];
      //상태 filter
      if (CM.cfnIsNotEmpty(resultList)) {
        if (searchData.status === "UNASKED") {
          resultListData = resultList.filter(
            (element) =>
              element.askStatus === "NORMAL" && (element.askResult === "UNASKED" || element.askResult === "UNREACHED" || element.askResult === "UNPAID" || element.askResult === "PARTIALLY_PAID")
          );
        } else if (searchData.status === "PAUSED") {
          resultListData = resultList.filter((element) => element.askStatus === "HOLDING");
        } else if (searchData.status === "LOSS") {
          resultListData = resultList.filter((element) => (element.askStatus === "NORMAL" || element.askStatus === "HOLDING") && (element.askResult === "UNASKED" || element.askResult === "UNPAID"));
        } else {
          resultListData = resultList;
        }

        //체크박스 추가
        for (const element of resultListData) {
          element._checked = false;
          element.index = resultListData.indexOf(element);
        }
      }

      await setSearchButton(false);
      await setReceiptList(resultListData);
      await setCheckAllRow(false);
    }
    // 실행 영역
    if (open === true && searchButton === true) {
      const param = fnMakeParameter(searchData);
      startAxios(param);
    }
  }, [searchData, searchButton, open]);

  /*
   * @desc     검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchOptionChange = (name) => (e) => {
    setSearchData({
      ...searchData,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchData({
      ...searchData,
      [name]: e,
    });
  };

  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    setReceiptList((receipt) => receipt.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...receiptList];
    cloneData[index][key] = value;
    setReceiptList(cloneData);
  };

  //선택 회차 수납내역 변경 이력 조회
  function fnContractDetail(row, e, index) {
    const key = "_checked";
    const value = row._checked;
    const cloneData = [...receiptList];
    cloneData[index][key] = value;

    setReceiptList(cloneData);
    for (let i = 0; i < receiptList.length; i++) {
      if (i === index) {
        if (receiptList[i]._checked === true) {
          receiptList[i]._checked = false;
        } else {
          receiptList[i]._checked = true;
        }
      }
    }

    let url = `api/receipt/source/history?sourceUniqueKey=${row.uniqueKey}`;
    CM.cfnAxios(url, "get", "", fnCallback);
  }

  const fnCallback = (objStatus, objData) => {
    // 실패시
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText, () => {
        return;
      });
    }
    setReceiptDetailHistories(objData);
  };

  const [receiptHolding, setReceiptHolding] = useState(false); //수납보류
  const [receiptCancelHolding, setReceiptCancelHolding] = useState(false); //수납보류해제
  const [receiptLoss, setReceiptLoss] = useState(false); //손실처리

  //수납보류, 수납보류해제, 손실처리 (버튼 제어)
  useEffect(() => {
    /*
     * @desc   수납보류 Request
     */
    const startAxiosHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/holding`;
        if (sendData.length === 1) {
          url = `api/receipt/source/holding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };
    /*
     * @desc   수납보류해제 Request
     */
    const startAxiosCancelHolding = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/cancelHolding`;
        if (sendData.length === 1) {
          url = `api/receipt/source/cancelHolding/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };
    /*
     * @desc   손실처리 Request
     */

    const startAxiosLoss = (sendData) => {
      return new Promise((resolve) => {
        let url = `api/receipt/source/loss`;
        if (sendData.length === 1) {
          url = `api/receipt/source/loss/${sendData[0]}`;
        }
        CM.cfnAxios(url, "put", sendData, (status, data) => {
          if (status.status !== 200) {
            CM.cfnAlert(status.statusText);
            return false;
          }

          CM.cfnAlert(data, () => {
            setSearchButton(true);
          });
        });
      });
    };

    const checkedData = [];
    const sendData = [];
    for (const row of receiptList) {
      if (row._checked) {
        checkedData.push(row);
      }
    }

    // 수납보류 버튼 클릭
    if (receiptHolding === true) {
      if (checkedData.length < 1) {
        CM.cfnAlert("보류처리 할 수납내역을 선택해주세요. ", () => {
          setReceiptHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if (row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 수납내역은 보류처리 대상이 아닙니다.`, () => {
            setReceiptHolding(false);
          });
          return;
        }
      }
      startAxiosHolding(sendData);
      setReceiptHolding(false);
    }
    // 보류해체처리 버튼 클릭
    if (receiptCancelHolding === true) {
      if (checkedData.length === 0) {
        CM.cfnAlert("보류해체처리 할 수납내역을 선택해주세요. ", () => {
          setReceiptCancelHolding(false);
        });
        return;
      }
      for (const row of checkedData) {
        if ((row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") && row.askStatus === "HOLDING") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 수납내역은 보류해체처리 대상이 아닙니다.`, () => {
            setReceiptCancelHolding(false);
          });
          return;
        }
      }
      startAxiosCancelHolding(sendData);
      setReceiptCancelHolding(false);
    }
    // 손실처리 버튼 클릭
    if (receiptLoss === true) {
      if (checkedData.length < 1) {
        CM.cfnAlert("손실처리 할 수납내역을 선택해주세요. ", () => {
          setReceiptLoss(false);
        });
        return;
      }
      for (const row of checkedData) {
        if (row.askResult === "UNASKED" || row.askResult === "UNREACHED" || row.askResult === "UNPAID") {
          sendData.push(row.uniqueKey);
        } else {
          CM.cfnAlert(`${row.index + 1}번 째 수납내역은 손실처리 대상이 아닙니다.`, () => {
            setReceiptLoss(false);
          });
          return;
        }
      }
      startAxiosLoss(sendData);
      setReceiptLoss(false);
    }
  }, [receiptHolding, receiptCancelHolding, receiptLoss, receiptList]);

  //search상태 조회 option
  const searchStatusList = [
    { value: "", label: "전체" },
    { value: "PAUSED", label: "정상" },
    { value: "UNASKED", label: "수납보류" },
    { value: "LOSS", label: "손실처리" },
  ];

  //AskStatus 구분 함수
  const fnAskStatus = (row) => {
    switch (row.askResult) {
      case "UNASKED":
        if (row.askStatus === "HOLDING") {
          return "미청구\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미청구\n(청구중)";
        } else {
          return "미청구";
        }
      case "UNREACHED":
        if (row.askStatus === "HOLDING") {
          return "수납일\n미도래\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "수납일\n미도래\n(청구중)";
        } else {
          return "수납일\n미도래";
        }
      case "UNPAID":
        if (row.askStatus === "HOLDING") {
          return "미납\n(수납보류)";
        } else if (row.askStatus === "ASKING") {
          return "미납\n(청구중)";
        } else {
          return "미납";
        }
      case "PAID":
        return "수납";
      case "LOSS":
        return "손실처리";
      case "HOLDING":
        return "수납보류";
      case "PARTIALLY_PAID":
        return "부분납";
      default:
        if (row.askStatus === "ASKING" && row.numberOfAsking === 1) {
          return "출금중(최초출금)";
        } else if (row.askStatus === "ASKING" && row.numberOfAsking > 1) {
          return "출금중(재출금)";
        }
    }
  };

  const headRowOne = [
    { value: "수납\n회차", rowSpan: 2 },
    { value: "수납년월", rowSpan: 2 },
    { value: "수납대상액", colSpan: 2 },
    { value: "수납액", colSpan: 2 },
    { value: "미납액", colSpan: 2 },
    { value: "손실처리액", colSpan: 2 },
    { value: "수납상태", rowSpan: 2 },
    { value: "오류내역", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "원금" }, { value: "연체료" }, { value: "원금" }, { value: "연체료" }, { value: "원금" }, { value: "연체료" }, { value: "원금" }, { value: "연체료" }];

  const fnTransactionType = (transactionType) => {
    switch (transactionType) {
      case "DIRECTLY_PAID":
        return "직접수납";
      case "CHANGE_AMOUNT":
        return "금액변경";
      case "DELAY_FEE":
        return "연체료조정";
      case "REFUND":
        return "환불";
      case "HOLD_OFF_ASKING":
        return "수납보류";
      case "CANCEL_HOLD_OFF_ASKING":
        return "수납보류취소";
      case "EXEMPT_ASK_TARGET":
        return "CMS출금 대상 제외";
      case "LOSS_AND_REMOVE":
        return "손실처리";
      case "TEMPORARY_ADDED":
        return "추가";
      case "EB21":
        return "익일출금";
      case "EC21":
        return "당일출금";
      default:
        return transactionType;
    }
  };

  const fnReceiptTransactionType = (transactionType) => {
    switch (transactionType) {
      case "EB21":
      case "EC21":
        return "CMS";
      case "DIRECTLY_PAID":
        return "직접수납";
      default:
        return "-";
    }
  };

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open} onClose={handleClose}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
              {""}
            </Button>
          </div>
          <h3>회차별 수납내역</h3>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="th">고객명</TableCell>
                <TableCell className="td" align="left">
                  {receiptInfo.customer.customerName}
                </TableCell>
                <TableCell className="th">
                  생년월일
                  <br />
                  (사업자번호)
                </TableCell>
                <TableCell className="td" align="left">
                  {CM.cfnIdentificationNoFormat(receiptInfo.customer.identificationNo)}
                </TableCell>
                <TableCell className="th">자금종류</TableCell>
                <TableCell className="td" align="left">
                  {receiptInfo.capital.capitalName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="th">수납시작월</TableCell>
                <TableCell className="td" align="left">
                  {CM.cfnDateFormat(receiptInfo.payStartY4mm, "yyyyMM")}
                </TableCell>
                <TableCell className="th">수납종료월</TableCell>
                <TableCell className="td" align="left">
                  {receiptInfo.payEndY4mm !== "999912" ? CM.cfnDateFormat(receiptInfo.payEndY4mm, "yyyyMM") : "기한없음"}
                </TableCell>
                <TableCell className="th">회차당 수납금액</TableCell>
                <TableCell className="td" align="left">
                  {receiptInfo.payAmountType === "VARIABLE" ? "비정액" : receiptInfo.payAmount + "원"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="table-top-area" />
          <div className="search-area">
            <div className="block">
              <label className="label-l">수납상태</label>
              <Select native value={searchData.status} onChange={handleSearchOptionChange("status")} name="status">
                {searchStatusList.map((option, index) => {
                  return (
                    <option value={option.value} key={index}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
              <label className="label-l">수납기간</label>
              <Template.DatePickerForm
                className="w150 receiptHistDatepicker"
                value={searchData.searchStartY4mm}
                handleChange={handleSearchFormChange("searchStartY4mm")}
                format="yyyy.MM"
                customProps={{ placeholder: "YYYY.MM", views: ["month", "year"] }}
              />
              <span className="between">~</span>
              <Template.DatePickerForm
                className="w150 receiptHistDatepicker"
                value={searchData.searchEndY4mm}
                handleChange={handleSearchFormChange("searchEndY4mm")}
                format="yyyy.MM"
                customProps={{ placeholder: "YYYY.MM", views: ["month", "year"] }}
              />
              <div className="search-button" onClick={() => setSearchButton(true)}>
                검색
              </div>
            </div>
          </div>

          <h4>회차별 수납내역</h4>
          <Table aria-labelledby="tableTitle">
            {CM.cfnCompColGroup(["3%", "5%", "7%", "9%", "7%", "9%", "7%", "9%", "7%", "8%", "7%", "10%","auto"])}
            <TableForm.compCheckBoxDoubleRowTableHead checked={checkAllRow} value="" onChange={handleCheckAllRowChange} rowOne={headRowOne} rowTwo={headRowTwo} />
            <TableBody>
              {receiptList.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={13} />
              ) : (
                receiptList.map((row, index) => {
                  //const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow key={index} hover className="show-detail" role="checkbox" onClick={(e) => fnContractDetail(row, e, index)}>
                      <TableCell className="checkbox">
                        <Checkbox
                          checked={row._checked}
                          value="_checked"
                          id={`deleteCheckbox-${index}`}
                          onChange={handleUpdateChange}
                          inputProps={{
                            index: index,
                          }}
                        />
                      </TableCell>
                      <TableCell className="td" align="center">
                        {row.contractAskSequence === null ? "추가분" : row.contractAskSequence}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.totalAskingAmount)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.totalAskingDelayFee)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.paidAmount)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.paidDelayFee)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.unpaidAmount)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.unpaidDelayFee)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.lostAmount)}
                      </TableCell>
                      <TableCell className="td" align="right">
                        {CM.cfnAddComma(row.lostDelayFee)}
                      </TableCell>
                      <TableCell className="td" align="center" style={{ whiteSpace: "pre-wrap" }}>
                        {fnAskStatus(row)}
                      </TableCell>
                      <TableCell align="center">
                        {row.resultCodeMsg === null ? "-" : row.resultCodeMsg}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <div className="table-bottom-area">
            <div
              className="btn-l"
              data-testid="receiptHolding-button"
              onClick={(e) => setReceiptHolding(true)}
              //onClick={enent => handleClickModal("NumberReceiptHistories")}
            >
              선택내역 수납보류
            </div>
            <div className="btn-l" data-testid="receiptCancelHolding-button" onClick={(e) => setReceiptCancelHolding(true)}>
              선택내역 수납보류 해제
            </div>
            <div
              className="btn-l"
              data-testid="receiptLoss-button"
              onClick={(e) => setReceiptLoss(true)}
              //onClick={enent => handleClickModal("NumberReceiptHistories")}
            >
              선택내역 손실처리
            </div>
          </div>
          <h4>선택회차 상세내역</h4>
          <div className="table-top-area" />
          <Table>
            {CM.cfnCompColGroup(["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
            <TableForm.compTableHead arrData={["변경일", "처리구분", "수납일", "수납원금", "미납원금", "수납연체료", "미납연체료", "수납방법", "비고"]} />
            <TableBody>
              {receiptDetailHistories.length === 0 ? (
                <TableForm.compEmptyTableRow colSpan={9} />
              ) : (
                receiptDetailHistories.map((row, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell align="center">{CM.cfnDateFormat(row.askedDate)}</TableCell>
                      <TableCell align="center">{fnTransactionType(row.transactionType)}</TableCell>
                      <TableCell align="center">{CM.cfnDateFormat(row.paidDate)}</TableCell>
                      <TableCell align="right">
                        {CM.cfnAddComma(Number(row.amountBeforeTransaction - row.amountAfterTransaction) - Number(row.delayFeeBeforeTransaction - row.delayFeeAfterTransaction))}
                      </TableCell>
                      <TableCell align="right">
                        {CM.cfnAddComma(
                          (CM.cfnIsNotEmpty(row.amountAfterTransaction) ? Number(row.amountAfterTransaction) : 0) -
                            (CM.cfnIsNotEmpty(row.delayFeeAfterTransaction) ? Number(row.delayFeeAfterTransaction) : 0)
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {CM.cfnAddComma(
                          (CM.cfnIsNotEmpty(row.delayFeeBeforeTransaction) ? Number(row.delayFeeBeforeTransaction) : 0) -
                            (CM.cfnIsNotEmpty(row.delayFeeAfterTransaction) ? Number(row.delayFeeAfterTransaction) : 0)
                        )}
                      </TableCell>
                      <TableCell align="right">{CM.cfnAddComma(row.delayFeeAfterTransaction)}</TableCell>
                      <TableCell align="center">{fnReceiptTransactionType(row.transactionType)}</TableCell>
                      <TableCell align="center">{row.remark}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </Modal>
  );
};
export default ReceiptHistories;
