import {AppBar, Input, Select, Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import * as CM from "../../common/Common";
import CommonDataName from "../../common/CommonDataName";
import { searchPayAmountTypeData, searchTransactionMethodData } from "../../customer/receiptCustomerInformation/ReceiptInformationData";
import ReceiptStopModal from "../../customer/receiptCustomerInformation/ReceiptStopModal";
import {RowPerPageForm, SelectForm, TabPanel, TotalCountForm} from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import UnpaidReceiptRequest from "./UnpaidReceiptRequest";
import UnpaidReceiptResult from "./UnpaidReceiptResult";
// 검색 데이터
function searchData() {
  const today = new Date();
  return {
    searchStartY4: `${today.getFullYear().toString()}`,
    searchStartMm: today.getMonth() < 9 ? `${0}${today.getMonth() + 1}` : today.getMonth() + 1,
    searchEndY4: `${today.getFullYear().toString()}`,
    searchEndMm: today.getMonth() < 9 ? `${0}${today.getMonth() + 1}` : today.getMonth() + 1,
    minimumUnpaidMonths: 1,
    capitalUniqueKey: "",
    groupUniqueKey: "",
    transactionMethod: "",
    payAmountType: "",
    staffUniqueKey: "",
    pageNumber: 0,
    pageSize: 5,
    payerNo: "",
    sortDirection: "ASC",
    sortProperty: "CUSTOMER_NAME",
    searchProperty: "CUSTOMER_NAME",
    searchContents: "",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    //검색 버튼 클릭 시, pageNumber 0으로 초기화
    props.setSearchRequest((searchRequest) => ({ ...searchRequest, pageNumber: 0 }));
    props.handleSearchButtonChange(true);
  };

  const SearchMonth = () => {
    const buttonHtml = [];
    for (let i = 1; i <= 12; i++) {
      buttonHtml.push(
        <div key={i} className="btn-xs" onClick={(e) => props.fnChangeMonth(i > 9 ? i : "0" + i)}>
          {i}월
        </div>
      );
    }
    return buttonHtml;
  };
  return (
    <div className="search-area tl" style={{height : "100px"}}>
      <div className="block" style={{ width: "100%" }}>
        <label className="label-l">수납기간</label>
        <SelectForm
          value={searchRequest.searchStartY4}
          handleChange={handleSearchFormChange("searchStartY4")}
          name="searchStartY4"
          arrayOption={props.fnCreateYearOption()}
          optionValue="value"
          optionLabel="label"
        />
        <SelectForm
          value={searchRequest.searchStartMm}
          handleChange={handleSearchFormChange("searchStartMm")}
          name="searchStartMm"
          arrayOption={props.fnCreateEndMonthOption(searchRequest.searchStartY4)}
          optionValue="value"
          optionLabel="label"
        />
        <span className="between">~</span>
        <SelectForm
          value={searchRequest.searchEndY4}
          handleChange={handleSearchFormChange("searchEndY4")}
          name="searchEndY4"
          arrayOption={props.fnCreateYearOption()}
          optionValue="value"
          optionLabel="label"
        />
        <SelectForm
          value={searchRequest.searchEndMm}
          handleChange={handleSearchFormChange("searchEndMm")}
          name="searchEndMm"
          arrayOption={props.fnCreateEndMonthOption(searchRequest.searchEndY4)}
          optionValue="value"
          optionLabel="label"
        />
        <div style={{ display: "flex" }}>
          <SearchMonth />
        </div>
        <label className="label-l">총 미납건수</label>
        <TextField
          name="payAmount"
          className="w100"
          value={searchRequest.minimumUnpaidMonths}
          onChange={handleSearchFormChange("minimumUnpaidMonths")}
          onKeyUp={handleSearchKeyUp}
          required={true}
          type="number"
          InputProps={{ style: { textAlign: "right" }, min: "1" }}
        />
        <span className="MuiTypography-body1" style={{ lineHeight: 2 }}>
          건 이상
        </span>
        <label className="label-l">자금종류</label>
        <Select
          native
          value={props.searchRequest.capitalUniqueKey}
          onChange={handleSearchFormChange("capitalUniqueKey")}
          name="capital"
          inputProps={{ "data-testid": "paymentInformation-select-capital" }}>
          <option value="" key="none">
            전체
          </option>
          {props.storeCapital.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.capitalName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객구분</label>
        <Select native value={searchRequest.groupUniqueKey} onChange={handleSearchFormChange("groupUniqueKey")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          <option value="" key="none">
            전체
          </option>
          {props.storeCustomerGroup.map((option, index) => {
            return (
              <option value={option.uniqueKey} key={index}>
                {option.customerGroupName}
              </option>
            );
          })}
        </Select>
        <label className="label-l">수납방법</label>
        <Select native value={searchRequest.transactionMethod} onChange={handleSearchFormChange("transactionMethod")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          {props.transactionMethod.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">자금유형</label>
        <Select native value={searchRequest.payAmountType} onChange={handleSearchFormChange("payAmountType")} name="group" inputProps={{ "data-testid": "paymentInformation-select-group" }}>
          {props.payAmountType.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <label className="label-l">고객담당자</label>
        <Select native value={searchRequest.staffUniqueKey} onChange={handleSearchFormChange("staffUniqueKey")}>
          <option value="">전체</option>
          {CM.cfnIsNotEmpty(props.staffs) &&
            props.staffs.map((row, index) => {
              return (
                <option key={index} value={row.uniqueKey}>
                  {row.staffName}
                </option>
              );
            })}
        </Select>
        <br />
        <br />
        <div>
        <label className="label-l">검색어입력</label>
        <Select native name="searchProperty" value={searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")}>
          <option value="CUSTOMER_NAME">고객명</option>
          <option value="TOTAL_UNPAID_AMOUNT">미납금액</option>
        </Select>
        <Input
            value={searchRequest.searchContents}
            onChange={handleSearchFormChange("searchContents")}
            onKeyUp={handleSearchKeyUp}
            name="searchContents"
            className="w120"
            data-testid="cms-request-keyword-resulttab"
        />
        {searchRequest.searchProperty === "TOTAL_UNPAID_AMOUNT" ? <span className="MuiTypography-body1" style={{ lineHeight: 2.5 }}>원 이상&nbsp;&nbsp;&nbsp;</span> : <></>}
        <button className="search-button" style={{margin : "5px 0 0 -3px"}} data-testid="" onClick={(e) => fnSearch()}>
          미납내역 조회
        </button>
        </div>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const handleRowClick = (e, row) => {
    if (e.target.classList.contains("ignoreClick") || e.target.type === "button") {
      return;
    }
    props.handleClick(row);
  };

  //수납보류, 보류해제 버튼 컴포넌트
  const fnReceiptStopButton = (row) => {
    if (row.contractStatus === "PAUSED") {
      return <div>수납중지됨</div>;
    } else if (row.askStatus === "ASKING") {
      return <div>청구중</div>;
    } else if (row.askStatus === "REGISTERING") {
      return <div style={{ whiteSpace: "pre-wrap" }}>계좌{"\n"}등록/해지중</div>;
    } else if (row.contractStatus === "NORMAL") {
      return (
        <div>
          <button className="btn-l3" type="button" onClick={() => props.handleClick(row)}>
            수납중지
          </button>
        </div>
      );
    }
  };
  const fnPayAmountType = (payAmountType) => {
    switch (payAmountType) {
      case "FIXED":
        return "정액";
      case "VARIABLE":
        return "비정액";
      case "TEMPORARY_ADDED":
        return "추가분";
      default:
        return payAmountType;
    }
  };
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["auto", "10%", "9%", "8%", "8%", "8%", "10%", "8%", "5%", "9%", "70px"])}
        <TableForm.compServerSortTableHead
          useCheckbox={false}
          arrData={[
            { id: "CUSTOMER_NAME", label: "고객명", sortable: true },
            { id: "PAYER_NO", label: "납부자번호", sortable: true },
            { id: "customerGroupName", label: "고객구분", sortable: false },
            { id: "transactionMethod", label: "수납방법", sortable: false },
            { id: "payAmountType", label: "자금유형", sortable: false },
            { id: "capitalName", label: "자금종류", sortable: false },
            { id: "TOTAL_UNPAID_AMOUNT", label: "미납금액", sortable: false },
            { id: "TOTAL_DELAY_FEE", label: "연체료", sortable: false },
            { id: "PAY_PERIOD", label: "수납주기", sortable: false },
            { id: "NUMBER_OF_UNPAID_SOURCE", label: "미납건수", sortable: false },
            { id: "RECEIPT_STOP", label: "수납중지", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
          tableSortLabelDataTestId="receiptList-list-head-sortLabel"
        />
        <TableBody>
                {props.list.length === 0 ? (
                    <TableForm.compEmptyTableRow colSpan={11} />
                ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} className="show-detail" hover onClick={(e) => handleRowClick(e, row)} data-testid={`receiptList-goDetail-${index}`}>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{row.payerNo}</TableCell>
                  <TableCell align="center">{row.customerGroupName}</TableCell>
                  <TableCell align="center">{row.transactionMethod !== "CMS_WITHDRAW" ? "기타" : "CMS"}</TableCell>
                  <TableCell align="center">{fnPayAmountType(row.payAmountType)}</TableCell>
                  <TableCell align="center">{row.capitalName}</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalUnpaidAmount)}원</TableCell>
                  <TableCell align="right">{CM.cfnAddComma(row.totalDelayFee)}원</TableCell>
                  <TableCell align="center">{CommonDataName.payPeriod(row.payPeriod)}</TableCell>
                  <TableCell align="center">{row.numberOfUnpaidSource}회</TableCell>
                  <TableCell align="center" className="ignoreClick">
                    {fnReceiptStopButton(row)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}
/*
 * @desc  목록 컴포넌트 생성
 */
function SummaryForm(props) {
  return (
    <div>
      <h4>미납 요약정보</h4>
      <div className="summary-main-area">
        <div className="summary-area">
          <div className="text-graph">
            <label>미납자수</label>
            <span>{CM.cfnAddComma(props.summaryList.numberOfUnpaidReceipt)}건</span>
          </div>
          <div className="text-graph">
            <label>미납금액</label>
            <span>{CM.cfnAddComma(props.summaryList.totalUnpaidAmount)}원</span>
          </div>
          <div className="text-graph">
            <label>연체료</label>
            <span>{CM.cfnAddComma(props.summaryList.totalDelayFee)}원</span>
          </div>
        </div>
      </div>
    </div>
  );
}

/*
* @desc  미납 내역 컴포넌트 생성
*/
const UnpaidReceipt = (props) => {
  const { store } = props;
  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [excelButton, setExcelButton] = useState(false); // 엑셀버튼 실행 flag
  const [summaryList, setSummaryList] = useState({
    numberOfUnpaidReceipt: 0,
    totalDelayFee: 0,
    totalUnpaidAmount: 0,
  }); //수납대상(수납정보 기준)
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());
  const [receiptStopModalOpen, setReceiptStopModalOpen] = useState(false);
  // const [staffs, setStaffs] = useState([]);
  const [stopTargetReceipt, setStopTargetReceipt] = useState({ uniqueKey: null, customer: {}, capital: {} });

  useEffect(() => {
    /*
     * @desc 검색 결과조회 파라미터 생성 함수
     */
    const fnMakeParameter = (search, buttonType) => {
      let tempObj = CM.cfnCopyObject(search);
      tempObj.searchStartY4mm = search.searchStartY4 + search.searchStartMm;
      tempObj.searchEndY4mm = search.searchEndY4 + search.searchEndMm;

      return tempObj;
    };
    /*
     * @desc    검색결과조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/unpaid/?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&minimumUnpaidMonths=${search.minimumUnpaidMonths}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchProperty=${search.searchProperty}&searchContents=${search.searchContents}&staffUniqueKey=${search.staffUniqueKey}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    수납대상(수납정보 기준) 결과조회 Request
     */
    const axiosSummaryList = (search) => {
      return new Promise((resolve) => {
        let url = `api/receipt/unpaid/summary?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&minimumUnpaidMonths=${search.minimumUnpaidMonths}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&staffUniqueKey=${search.staffUniqueKey}&searchProperty=${search.searchProperty}&searchContents=${search.searchContents}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    /*
     * @desc    엑셀저장 처리 함수
     */
    const axiosExcel = (search) => {
      return new Promise((resolve) => {
        let url = `/api/receipt/unpaid/excel?capitalUniqueKey=${search.capitalUniqueKey}&groupUniqueKey=${search.groupUniqueKey}&payAmountType=${search.payAmountType}&minimumUnpaidMonths=${search.minimumUnpaidMonths}&transactionMethod=${search.transactionMethod}&searchStartY4mm=${search.searchStartY4mm}&searchEndY4mm=${search.searchEndY4mm}&staffUniqueKey=${search.staffUniqueKey}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&searchContents=${search.searchContents}&searchProperty=${search.searchProperty}`;
        CM.cfnAxiosFileDownload(url, "get", "", () => {});
      });
    };

    // start axios and set table data
    async function startAxios(search, buttonType) {
      //일괄수납보류 시
      if (buttonType === "excel") {
        setExcelButton(false);
        await axiosExcel(search);
      } else {
        setSearchButton(false);
        if (CM.cfnAddDate(search.searchStartY4mm, search.searchEndY4mm, 10)) {
          CM.cfnAlert("종료년월은 시작년월로부터 10년 이내만 가능합니다.");
          return false;
        }
        //검색 시
        axiosSummaryList(search).then((data) => {
          setSummaryList(data);
        });
        axiosList(search).then((data) => {
          setOriginalList(data);
          setList(data.content);
          setPagination(paginationData(data));
        });
      }
    }

    if (searchButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param, "search");
    } else if (excelButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param, "excel");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchButton, excelButton]);

  /*
  * @desc    페이지 변경 이벤트 핸들러 (검색)
  */
  const handleOffsetChange = (offset, page) => {
    setSearchRequest((data) => ({ ...data, pageNumber: page - 1 }));
    setSearchButton(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    setSearchRequest((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    setSearchButton(true);
  };

  /*
   * @desc  검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "searchStartY4") {
      setSearchRequest({
        ...searchRequest,
        searchStartMm: e.target.value > searchRequest.searchStartY4 ? "01" : e.target.value < searchRequest.searchStartY4 ? "12" : searchRequest.searchStartMm,
        searchStartY4: e.target.value,
      });
    } else if (name === "searchEndY4") {
      setSearchRequest({
        ...searchRequest,
        searchEndMm: e.target.value > searchRequest.searchEndY4 ? "01" : e.target.value < searchRequest.searchEndY4 ? "12" : searchRequest.searchEndMm,
        searchEndY4: e.target.value,
      });
    } else if (name === "minimumUnpaidMonths") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value ? e.target.value.replace(/[^0-9]/g, "") : e.target.value,
      });
    } else if (name === "searchProperty") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
        searchContents : "",
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = async (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });
    //정렬조건 세팅 후 검색 trigger
    setSearchButton(true);
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc     수납기간 년도 옵션 생성하는 함수
   */
  const fnCreateYearOption = () => {
    const arrYear = [];

    const startYear = 2010;
    let maxLoop = new Date().getFullYear() - startYear + 2;
    // if (type === "end") {
    //   maxLoop++;
    // }

    for (let i = 0; i < maxLoop; i++) {
      let label = `${startYear + i}년`;
      let value = startYear + i;

      arrYear.push({
        label: label,
        value: value,
      });
    }

    return arrYear;
  };

  /*
   * @desc     수납시작월 / 종료월의 옵션 생성하는 함수
   */
  const fnCreateEndMonthOption = (searchY4) => {
    const arrMonth = [];

    let year = `${(new Date().getFullYear() + 1).toString()}`;
    let mm;

    if (searchY4.toString() === year) {
      //수납종료기간 년도가 내년과 같다면 이번달까지만 셀렉트박스 생성
      mm = `${new Date().getMonth()}`; //이번달

      for (let i = 1; i <= mm; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;
        arrMonth.push({
          label: label,
          value: value,
        });
      }
    } else {
      const maxLoop = 13;

      for (let i = 1; i < maxLoop; i++) {
        let label = `${i}월`;
        let value = i < 10 ? `0${i}` : i;

        arrMonth.push({
          label: label,
          value: value,
        });
      }
    }
    return arrMonth;
  };

  //수납기간 (월 선택 ) 함수
  function fnChangeMonth(month) {
    setSearchRequest((searchRequest) => ({
      ...searchRequest,
      searchEndMm: month,
      searchStartMm: month,
      pageNumber: 0,
    }));
    handleSearchButtonChange(true);
  }

  //수납중지 이벤트 함수
  const fnReceiptStop = () => async () => {
    let massage =
        "수납을 중지하시겠습니까?\n수납중지하더라도 당월 이전의 미납/미청구 내역은 청구 대상에 포함되므로,\n미납/미청구 내역에 대해 청구하지 않으시려면 아래에서 수납보류 혹은 손실처리 작업을 해주시기 바랍니다.";
    let contractStatus = "PAUSED";

    CM.cfnConfirm(massage, () => {
      const url = `/api/customer/receipt/receipts/pause?contractUniqueKey=${stopTargetReceipt.uniqueKey}`;
      CM.cfnAxios(url, "put", stopTargetReceipt.uniqueKey, (objStatus, objData) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        // 성공시
        CM.cfnAlert(objData);
        setStopTargetReceipt({
          ...stopTargetReceipt,
          contractStatus,
        });
        setReceiptStopModalOpen(false);
        handleSearchButtonChange(true);
      });
    });
  };

  return (
      <div>
        <SearchForm
            searchRequest={searchRequest}
            setSearchRequest={setSearchRequest}
            fnChangeMonth={fnChangeMonth}
            handleSearchFormChange={handleSearchFormChange}
            handleSearchButtonChange={handleSearchButtonChange}
            fnCreateYearOption={fnCreateYearOption}
            fnCreateEndMonthOption={fnCreateEndMonthOption}
            storeCapital={toJS(store.receiptCapital)} // 자금종류
            storeCustomerGroup={toJS(store.customerGroup)} // 고객구분
            payAmountType={searchPayAmountTypeData()}
            transactionMethod={searchTransactionMethodData()}
            staffs={toJS(store.staffs)}
        />
        <SummaryForm summaryList={summaryList} />
        <div className="table-top-area">
          <TotalCountForm totalElements={originalList.totalElements || 0} />
          <RowPerPageForm data-testid="receiptList-rowPerPage-select" value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
          <button className="btn-m fr" data-testid="save-excel" onClick={() => setExcelButton(true)}>
            엑셀저장
          </button>
        </div>
        <ListForm
            list={list}
            pagination={pagination}
            handleClick={(target) => {
              if (target.askStatus === "NORMAL" && target.contractStatus === "NORMAL") {
                setStopTargetReceipt({ ...target, customer: { customerName: target.customerName }, capital: { capitalName: target.capitalName } });
                setReceiptStopModalOpen(true);
              }
            }}
            page={searchRequest.pageNumber}
            setSearchButton={setSearchButton}
            searchRequest={searchRequest}
            handleSortProperty={handleSortProperty}
        />
        <Pagination
            className="centered"
            limit={pagination.rowsPerPage}
            offset={pagination.offset}
            total={pagination.total}
            onClick={(e, offset, page) => handleOffsetChange(offset, page)}
            reduced={true}
            centerRipple={false}
            disableFocusRipple={true}
            disableRipple={true}
        />
        <ReceiptStopModal open={receiptStopModalOpen} setOpen={setReceiptStopModalOpen} value={stopTargetReceipt} fnReceiptStop={fnReceiptStop}/>
      </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function UnpaidReceiptManagement(props) {
  const tabRef = useRef();
  const { store } = props;

  const [tabIndex, setTabIndex] = useState(0); // 탭 인덱스
  const [autoWithdraw, setAutoWithdraw] = useState(toJS(store.autoWithdraw));

  const [instituteInfoState, setInstituteInfoState] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [openGiroUseYn, setOpenGiroUseYn]  = useState(false);
  const [pgServiceUseYn, setPgServiceUseYn] = useState(false);

  /*
 * @desc  serviceType에 해당하는 object를 반환하는 함수
 */
  function getObject(object, type) {
    for (const element of object) {
      if (element.serviceType === type) {
        return element;
      }
    }
  }

  /*
 * @desc  자동출금 정보 조회
 */
  useEffect(() => {
    async function fnFetchAutoWithdrawInfo() {
      try {
        const storeInfo = CM.cfnCopyObject(toJS(store.autoWithdraw));
        if (CM.cfnIsEmpty(storeInfo)) {
          await store.axiosAutoWithdraw();
        }
        setAutoWithdraw(CM.cfnCopyObject(toJS(store.autoWithdraw)));
      } catch (error) {
        console.log(error);
      }
    }

    if (CM.cfnIsEmpty(autoWithdraw)) fnFetchAutoWithdrawInfo();
  }, [store, autoWithdraw]);

  // 검색조건 고객구분 및 자금종류 / 기관구분정보 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      await store.axiosReceiptCapitals(); //지급 자금종류
      await store.axiosStaffs(); //고객 담당자
      const businessInfo = await store.axiosBusinessInfo(); // 수납,지급기관 구분을 위한 값

      //출금 불능분 부가서비스 사용 구분을 위한 값
      const instituteService = await store.axiosInstituteService();
      const openGiroData = getObject(instituteService, "OPEN_GIRO");
      const pgServiceData = getObject(instituteService, "PG_SERVICE");

      setOpenGiroUseYn(openGiroData.serviceUseYn);
      setPgServiceUseYn(pgServiceData.serviceUseYn);
      setSearchFlag(true);

      if (businessInfo.cmsService.indexOf("EB21") === -1 && businessInfo.cmsService.indexOf("EC21") === -1) {
        setInstituteInfoState(true);
        CM.cfnAlert("귀 기관은 입금이체서비스만 이용하시는 기관입니다.", () => {});
      }
      //고객담당자 option data 설정
     // setStaffs();
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc  전체 disable 처리를 하는 css
   */
  const useStyles = makeStyles((theme) => ({
    disabled_div: {
      "pointer-events": "none",
      opacity: 0.6,
    },
  }));

  /*
 * @desc  탭 인덱스 변경
 */
  const handleChangeTab = (event, value) => {
    setTabIndex(value);
  };

  /*
 * @desc  자동출금예약 서비스 이용중 안내 컴포넌트 생성
 */
  const AutoWithdraw = () => {
    return (
        <div>
          <h4 data-testid="auto-withdraw-text">현재 "자동출금예약 서비스"를 이용 중입니다.</h4>
          <div className="infobox">
            미납 내역 청구를 희망할 경우 자동출금 서비스를 해지 후 사용해주시기 바랍니다.
          </div>
        </div>
    );
  };

  const style = useStyles();

  return (
      <div className={instituteInfoState ? style.disabled_div : ""}>
        {searchFlag && (openGiroUseYn || pgServiceUseYn) ? (
            <div>
              <AppBar position="static" color="default">
                <Tabs value={tabIndex} onChange={handleChangeTab} indicatorColor="primary" textColor="primary"
                      data-testid="cms-receipt-tab">
                  <Tab ref={tabRef} disableRipple value={0} label="미납 내역"/>
                  <Tab disableRipple value={1} label={<React.Fragment>출금불능분 즉시납부 청구</React.Fragment>}/>
                  <Tab disableRipple value={2} label={<React.Fragment>출금불능분 즉시납부 청구결과</React.Fragment>}/>
                </Tabs>
              </AppBar>
              <TabPanel value={tabIndex} index={0}>
                <UnpaidReceipt store={store}/>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                {autoWithdraw.serviceStatus === "NORMAL" ? <AutoWithdraw/> : <UnpaidReceiptRequest tabIndex={tabIndex} openGiroUseYn={openGiroUseYn} pgServiceUseYn={pgServiceUseYn}/>}
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                {autoWithdraw.serviceStatus === "NORMAL" ? <AutoWithdraw/> : <UnpaidReceiptResult tabIndex={tabIndex}/>}
              </TabPanel>
            </div>
        ) : (
            <div>
              <UnpaidReceipt store={store}/>
            </div>
        )}
      </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(UnpaidReceiptManagement));
