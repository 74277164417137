import { Checkbox, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import * as CM from "../../common/Common";
import CDN from "../../common/CommonDataName";
import * as CF from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import PaperData from "./PaperBillData";
import PaperBillPrintModal from "./PaperBillPrintModal";

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc     키보드 이벤트 핸들러
   */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    const validation = CM.cfnPeriodValidation(searchRequest.fromDate, searchRequest.toDate);
    if (!validation) return false;

    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">발행일</label>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.fromDate}
          handleChange={handleSearchFormChange("fromDate")}
          format="yyyy.MM.dd"
          customProps={{
            placeholder: "YYYY.MM.DD",
            inputProps: { "data-testid": "paperbill-issued-datePicker-fromDate" },
          }}
        />
        <span className="between">~</span>
        <CF.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.toDate}
          handleChange={handleSearchFormChange("toDate")}
          format="yyyy.MM.dd"
          customProps={{
            placeholder: "YYYY.MM.DD",
            inputProps: { "data-testid": "paperbill-issued-datePicker-toDate" },
          }}
        />
        <label className="label-l">검색어 입력</label>
        <Select native value={searchRequest.term} onChange={handleSearchFormChange("term")} inputProps={{ "data-testid": "paperbill-issued-term-select" }}>
          <option value="customerName">고객명</option>
          <option value="identificationNo">주민등록번호(사업자번호)</option>
        </Select>
        <TextField className="w130" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="paperbill-issued-keyword-input" />
        <button className="search-button" data-testid="search-issued" onClick={() => fnSearch()}>
          발행 내용 조회
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state
  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.setIssuedList((taxbills) => taxbills.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleUpdateChange = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.issuedList];

    cloneData[index][key] = value;
    props.setIssuedList(cloneData);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "issueDate", label: "발행일", sortable: true },
            { id: "transactionDate", label: "영수일", sortable: true },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "instIdentificationNo", label: "주민등록번호\n(사업자번호)", sortable: false },
            { id: "totalAmount", label: "수납금액", sortable: false },
            { id: "issueStatus", label: "비고", sortable: false },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.issuedList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={7} />
          ) : (
            props.issuedList.map((row, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`checkbox-${index}`}
                      onChange={handleUpdateChange}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.issueDate) /*발행일*/}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(row.transactionDate) /*영수일*/}</TableCell>
                  <TableCell align="center">{row.customerName /*고객명*/}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.identificationNo) /* 주민등록번호(사업자번호)*/}</TableCell>
                  <TableCell align="center">{CM.cfnAddComma(row.totalAmount) /*공급가액*/}</TableCell>
                  <TableCell align="center">{CDN.billIssueStatus(row.issueStatus) /*비고*/}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function PaperIssueHistoryTab(props) {
  const { tabIndex, store } = props;

  //필요 state 선언
  const [searchRequest, setSearchRequest] = useState(PaperData.issuedSearchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [issuedList, setIssuedList] = useState([]); // table 데이터
  const [selectedBills, setSelectedBills] = useState([]); //선택된 발행내역
  const [pagination, setPagination] = useState(PaperData.paginationData());
  const [master, setMaster] = useState({});

  const [openPrintModal, setOpenPrintModal] = useState(false); //영수증 인쇄 모달 제어

  /*
   * @desc 종이영수증 발행내역 조회 파라미터 생성 함수
   */
  const fnMakeParameter = useCallback(() => {
    let tempObj = CM.cfnCopyObject(searchRequest);

    tempObj.fromDate = CM.cfnConvertDateToString(searchRequest.fromDate);
    tempObj.toDate = CM.cfnConvertDateToString(searchRequest.toDate);

    if (tempObj.term === "customerName") {
      tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
      tempObj.identificationNo = "";
    } else if (tempObj.term === "identificationNo") {
      tempObj.customerName = "";
      tempObj.identificationNo = CM.cfnNvl(tempObj.keyword, "");
    }

    return tempObj;
  }, [searchRequest]);

  useEffect(() => {
    /*
     * @desc    종이영수증 발행내역 조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `/api/extraservice/receipt/issued?customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&toDate=${search.toDate}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      let resultData = await axiosList(search);

      let resultList = resultData.content;
      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }

      // 체크 옵션 추가
      if (CM.cfnIsNotEmpty(resultList)) {
        for (const element of resultList) {
          element._checked = false;
        }
      }

      setOriginalList(resultData);
      setIssuedList(resultList);
      setPagination(PaperData.paginationData(resultData));
      setSearchButton(false);
    }

    // 실행 영역
    if (tabIndex === 1 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton, fnMakeParameter]);

  // 검색조건 고객구분 및 자금종류 데이터 가져오기
  useEffect(() => {
    const getStoreDataAxios = async () => {
      await store.axiosCustomerGroup(); // 고객구분
      setMaster(toJS(await store.axiosMaster()));
    };

    getStoreDataAxios();
  }, [store]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc   종이영수증 발행내역 검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "fromDate" || name === "toDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  const axiosPrintList = (element) => {
    return new Promise((resolve) => {
      let url = `/api/extraservice/receipt/targets/detail?taxbillUniqueKey=${element.taxbillUniqueKey}`;

      CM.cfnAxios(url, "get", "", (status, data) => {
        resolve(data);
      });
    });
  };

  /*
   * @desc    영수증 인쇄 버튼 클릭 이벤트 핸들러
   */
  async function handleOpenPrintBill() {
    const tempArray = [];
    for (const element of issuedList) {
      if (element._checked) {
        let resultData = await axiosPrintList(element);
        tempArray.push(resultData);
      }
    }
    if (tempArray.length === 0) {
      CM.cfnAlert("인쇄할 영수증 내역을 선택해주세요.");
      return;
    }

    setSelectedBills(tempArray);
    setOpenPrintModal(true);
  }

  /*
   * @desc    엑셀저장 버튼 클릭 이벤트 핸들러
   */
  const handleClickIssuedExcelDownload = () => {
    const search = fnMakeParameter();
    const url = `api/extraservice/receipt/issued/excel?customerName=${search.customerName}&fromDate=${search.fromDate}&identificationNo=${search.identificationNo}&toDate=${search.toDate}&sortProperty=${searchRequest.sortProperty}&sortDirection=${searchRequest.sortDirection}`;

    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <CF.TotalCountForm totalElements={originalList.totalElements} />
        <CF.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} data-testid="paperbill-issued-rowPerPage-select" />
        <button className="btn-m fr" data-testid="save-issued-excel" onClick={(e) => handleClickIssuedExcelDownload()}>
          엑셀저장
        </button>
        <button className="btn-l fr" data-testid="print-issued-bills" onClick={handleOpenPrintBill}>
          영수증 인쇄
        </button>
      </div>
      <ListForm issuedList={issuedList} setIssuedList={setIssuedList} searchRequest={searchRequest} handleSortProperty={handleSortProperty} />
      <CF.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <PaperBillPrintModal open={openPrintModal} setOpen={setOpenPrintModal} selectedBills={selectedBills} master={master} />
    </div>
  );
}

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaperIssueHistoryTab));
