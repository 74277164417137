import { Input, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
//import PaperData from "./PaperBillData";
import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";

/*
 * @desc  종이영수증 발행 모달 - 상단 영수자 선택 컴포넌트
 */
export const PaperBillCustomerSelect = ({ selectedBillsList, selectedPaperBill, currentIndex, handleChangeSelected }) => {
  return (
    <div className="search-area">
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <button className="btn-s" disabled={currentIndex === 0 ? true : false} onClick={(e) => handleChangeSelected("prev")}>
          이전
        </button>
        <span className="label-l">공급받는자</span>
        <span name="billRecipient" className="margin-left-20 margin-right-20" data-testid="paperbill-selected-target-customer-name" style={{ fontSize: "15px" }}>
          {selectedPaperBill && selectedPaperBill.customer ? `${selectedPaperBill.customer.customerName} 귀하` : ""}
        </span>
        <button className="btn-s" disabled={currentIndex === selectedBillsList.length - 1 ? true : false} onClick={(e) => handleChangeSelected("next")}>
          다음
        </button>
        <label className="label-l fr">
          전체 {selectedBillsList.length}건 중 {currentIndex + 1}번째
        </label>
      </div>
    </div>
  );
};

/*
 * @desc  종이영수증 발행 모달 - 상단 영수자 상세정보 컴포넌트
 */
export const PaperBillCustomerInfo = ({ master, selectedPaperBill, handlePaperBillFormChange }) => {
  return (
    <Table>
      {CM.cfnCompColGroup(["120px", "auto", "120px", "auto", "120px", "auto"])}
      <TableBody>
        <TableRow>
          <TableCell className="th">사업자등록번호</TableCell>
          <TableCell className="td" align="center">
            {CM.cfnIdentificationNoFormat(master.bizNo)}
          </TableCell>
          <TableCell className="th">상호</TableCell>
          <TableCell className="td" align="center">
            {master.instName}
          </TableCell>
          <TableCell className="th">성명</TableCell>
          <TableCell className="td" align="center">
            {master.ceoName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="th">업태</TableCell>
          <TableCell className="td" align="center">
            {master.bizType /* 업태 */}
          </TableCell>
          <TableCell className="th">종목</TableCell>
          <TableCell className="td" align="center">
            {master.bizItem /* 종목 */}
          </TableCell>
          <TableCell className="th">영수증 No</TableCell>
          <TableCell className="td" align="center">
            <Input
              className="w140 editableCellInput"
              value={CM.cfnAddCommaOnChange(selectedPaperBill.receiptNumber) || ""}
              onChange={handlePaperBillFormChange("receiptNumber")}
              data-testid="paperbill-selectedTarget-receiptNumber-input"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="th">사업자소재지</TableCell>
          <TableCell className="td" align="center" colSpan="5">
            {master.address ? "(" + master.address.zipCode + ") " + master.address.address + " " + master.address.addressDetail : "" /* 공급자 주소*/}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/*
 * @desc  종이영수증 발행 모달 - 영수내역 컴포넌트
 */
export const PaperBillReceiptInfo = ({ selectedBillsList, selectedPaperBill, currentIndex, handlePaperBillFormChange }) => {
  return (
    <Fragment>
      <h4>영수 내역</h4>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="th">작성년월일</TableCell>
            <TableCell className="th">공급대가총액</TableCell>
            <TableCell className="th">비고</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="td" align="center">
              <CF.DatePickerForm
                className="w160"
                value={selectedPaperBill.transactionDate || "20200101"}
                handleChange={handlePaperBillFormChange("transactionDate")}
                format="yyyy.MM.dd"
                customProps={{
                  placeholder: "YYYY.MM.DD",
                  inputProps: { "data-testid": "paperbill-selectedTarget-datePicker-transactionDate" },
                }}
              />
            </TableCell>
            <TableCell className="td" align="center">
              {CM.cfnAddCommaOnChange(selectedPaperBill.totalAmount) || 0}원
            </TableCell>
            <TableCell className="td" align="center">
              <Input
                className="w180"
                value={selectedPaperBill.billRemark || ""}
                onChange={handlePaperBillFormChange("billRemark")}
                data-testid="paperbill-selectedTarget-remark-input"
                inputProps={{ maxLength: 20 }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

/*
 * @desc  종이영수증 발행 모달 - 공급내역 컴포넌트
 */
export const PaperBillDetails = ({ selectedPaperBill, handlePaperBillFormChange }) => {
  return (
    <Fragment>
      <h4>공급내역</h4>
      <Table>
        {CM.cfnCompColGroup(["250px", "100px", "180px", "auto", "auto"])}
        <TableHead>
          <TableRow>
            <TableCell className="th">품목</TableCell>
            <TableCell className="th">수량</TableCell>
            <TableCell className="th">단가</TableCell>
            <TableCell className="th">금액</TableCell>
            <TableCell className="th">비고</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.isExtensible(selectedPaperBill.paperReceiptItemDtos) &&
            selectedPaperBill.paperReceiptItemDtos.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="td">
                    <Input
                      value={row.itemName || ""}
                      inputProps={{ maxLength: 30 }}
                      onChange={handlePaperBillFormChange("itemName", index)}
                      data-testid={`paperbill-selectedTarget-itemName-${index}`}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="editableCellInput"
                      value={CM.cfnAddCommaOnChange(row.itemQuantity) || ""}
                      onChange={handlePaperBillFormChange("itemQuantity", index)}
                      data-testid={`paperbill-selectedTarget-itemQuantity-${index}`}
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="editableCellInput"
                      value={CM.cfnAddCommaOnChange(row.itemUnitPrice) || ""}
                      onChange={handlePaperBillFormChange("itemUnitPrice", index)}
                      data-testid={`paperbill-selectedTarget-itemUnitPrice-${index}`}
                      endAdornment="원"
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="w100p editableCellInput"
                      value={CM.cfnAddCommaOnChange(row.itemQuantity * row.itemUnitPrice) || ""}
                      onChange={handlePaperBillFormChange("itemAmount", index)}
                      data-testid={`paperbill-selectedTarget-itemAmount-${index}`}
                      endAdornment="원"
                    />
                  </TableCell>
                  <TableCell className="td">
                    <Input
                      className="w100p"
                      value={row.itemRemark || ""}
                      onChange={handlePaperBillFormChange("itemRemark", index)}
                      data-testid={`paperbill-selectedTarget-itemRemark-${index}`}
                      inputProps={{ maxLength: 30 }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

const PaperBillUnit = ({ paperBill, master, purpose }) => {
  return (
    <Fragment>
      <table>
        {CM.cfnCompColGroup(["50px", "calc(100% - 50px)"])}
        <tbody>
          <tr style={{ borderBottom: "none" }}>
            <td style={{ border: "none", textAlign: "left", paddingLeft: "5px" }}>No. {paperBill.receiptNumber || ""}</td>
            <td style={{ border: "none", paddingRight: "30px" }}>
              <span style={{ fontSize: "20px" }}>영 수 증</span> ({purpose}용)
            </td>
          </tr>
          <tr style={{ borderTop: "none" }}>
            <td colSpan={2} style={{ textAlign: "right", paddingRight: "10px", borderTop: "none" }}>
              <span className="text-black">{paperBill.customer.customerName}</span> 귀하
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        {CM.cfnCompColGroup(["30px", "75px", "auto", "75px", "auto"])}
        <tbody>
          <tr>
            <td rowSpan="4">
              공<br />급<br />자
            </td>
            <td>
              사업자
              <br />
              등록번호
            </td>
            <td colSpan="3" align="center" className="text-black">
              {CM.cfnIdentificationNoFormat(master.bizNo)}
            </td>
          </tr>
          <tr className="paperbill-height-40">
            <td>상호</td>
            <td align="center" className="text-black">
              {master.instName}
            </td>
            <td>성명</td>
            <td align="center" className="text-black">
              {master.ceoName || master.instName}
            </td>
          </tr>
          <tr>
            <td>
              사업장
              <br />
              소재지
            </td>
            <td colSpan="3" align="center" className="text-black">
              {master.address ? "(" + master.address.zipCode + ") " + master.address.address + " " + master.address.addressDetail : "" /* 공급자 주소*/}
            </td>
          </tr>
          <tr className="paperbill-height-40">
            <td>업태</td>
            <td align="center" className="text-black">
              {master.bizType /* 업태 */}
            </td>
            <td>종목</td>
            <td align="center" className="text-black">
              {master.bizItem /* 종목 */}
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        {CM.cfnCompColGroup(["60px", "60px", "60px", "auto", "auto"])}
        <thead>
          <tr className="paperbill-height-40">
            <td className="th">년</td>
            <td className="th">월</td>
            <td className="th">일</td>
            <td className="th">영수금액</td>
            <td className="th">비고</td>
          </tr>
        </thead>

        <tbody>
          <tr className="paperbill-height-40">
            <td className="text-black" align="center">
              {paperBill.transactionDate ? paperBill.transactionDate.substr(0, 4) : ""}
            </td>
            <td className="text-black" align="center">
              {paperBill.transactionDate ? paperBill.transactionDate.substr(4, 2) : ""}
            </td>
            <td className="text-black" align="center">
              {paperBill.transactionDate ? paperBill.transactionDate.substr(6) : ""}
            </td>
            <td className="text-black" align="center">
              {CM.cfnAddComma(paperBill.totalAmount) || "0"}
            </td>
            <td className="text-black" align="center">
              {paperBill.billRemark}
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        {CM.cfnCompColGroup(["100px", "60px", "70px", "auto", "auto"])}
        <thead>
          <tr className="paperbill-height-40">
            <td colSpan="5">공급내역</td>
          </tr>
          <tr className="paperbill-height-40">
            <td>품목명</td>
            <td>수량</td>
            <td>단가</td>
            <td>금액</td>
            <td>비고</td>
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(10).keys()).map((value, index) => {
            return !paperBill.paperReceiptItemDtos || paperBill.paperReceiptItemDtos.length <= index ? (
              <tr key={index} className="paperbill-height-30">
                <td align="center"></td>
                <td align="center"></td>
                <td align="center"></td>
                <td align="center"></td>
                <td align="center"></td>
              </tr>
            ) : (
              <tr key={index} className="paperbill-height-30">
                <td className="text-black" align="center">
                  {paperBill.paperReceiptItemDtos[index].itemName}
                </td>
                <td className="text-black" align="center">
                  {paperBill.paperReceiptItemDtos[index].itemQuantity ? CM.cfnAddComma(paperBill.paperReceiptItemDtos[index].itemQuantity) : ""}
                </td>
                <td className="text-black" align="center">
                  {paperBill.paperReceiptItemDtos[index].itemUnitPrice ? CM.cfnAddComma(paperBill.paperReceiptItemDtos[index].itemUnitPrice) : ""}
                </td>
                <td className="text-black" align="center">
                  {paperBill.paperReceiptItemDtos[index].itemAmount ? CM.cfnAddComma(paperBill.paperReceiptItemDtos[index].itemAmount) : ""}
                </td>
                <td className="text-black" align="center">
                  {paperBill.paperReceiptItemDtos[index].itemRemark}
                </td>
              </tr>
            );
          })}
          <tr className="paperbill-height-40">
            <td colSpan={2}>합 계</td>
            <td colSpan={3} className="text-black">
              &#8361; {CM.cfnAddComma(paperBill.totalAmount) || "0"}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        위 금액을 <b>영수</b>함
      </div>
    </Fragment>
  );
};
/*
 * @desc  종이영수증 발행내역 - 인쇄용 영수증 생성 컴포넌트
 */
export const PaperBill = (props) => {
  const { paperBill, master } = props;

  return (
    <div className="bill-print-area">
      <div className="d-inline-flex w100p">
        <div className="tax-bill-recipient" style={{ marginRight: "5px", width: "50%" }}>
          <PaperBillUnit paperBill={paperBill} master={master} purpose="공급받는자" />
        </div>
        <div className="tax-bill-recipientRed" style={{ marginLeft: "5px", width: "50%" }}>
          <PaperBillUnit paperBill={paperBill} master={master} purpose="공급자" />
        </div>
      </div>
    </div>
  );
};
