import { Checkbox, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import * as CM from "../../common/Common";
import CommonData from "../../common/CommonDataName";
import { AccountEvidenceFileInput, RowPerPageForm, SelectForm, TotalCountForm } from "../../template/ComponentForm";
import EvidenceFileModal from "../../template/EvidenceFileModal";
import FolderRegisterModal from "../../template/FolderRegisterModal";
import TableForm from "../../template/TableForm";
import AccountCheckData from "./AccountCheckData";

/*
 * @desc  검색 컴포넌트
 */
const SearchForm = (props) => {
  const { searchRequest, handleSearchFormChange } = props;

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  // 검색바 옵션 검색
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">검색어입력</label>
        <Select
          native
          value={searchRequest.term}
          onChange={handleSearchFormChange("term")}
          inputProps={{
            "data-testid": "request-term-select",
          }}>
          <option value="customerName">고객명</option>
          <option value="payerNo">납부자번호</option>
        </Select>
        <TextField className="w150" value={searchRequest.keyword} onChange={handleSearchFormChange("keyword")} onKeyUp={handleSearchKeyUp} name="search" data-testid="request-keyword-input" />
        <button className="search-button" onClick={() => fnSearch()} data-testid="request-search-button">
          검색
        </button>
      </div>
    </div>
  );
};

/*
 * @desc  목록 컴포넌트
 */
const ListForm = (props) => {
  const [checkAllRow, setCheckAllRow] = useState(false); // table head checkbox state

  // 체크박스 개별 모두 클릭 시 전체 체크박스 활성화 / 비활성화 설정
  useEffect(() => {
    let checkAllFlag = 0;
    for (const element of props.requestList) {
      if (element._checked) checkAllFlag++;
    }

    // 전체 row 수
    const requestLength = props.requestList.length;

    if (checkAllFlag === requestLength && requestLength > 0) {
      setCheckAllRow(true);
    } else {
      setCheckAllRow(false);
    }
  }, [props.requestList]);

  // 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleSelectCheckbox = (event) => {
    const key = event.target.value;
    const value = event.target.checked;
    const index = event.target.getAttribute("index");

    const cloneData = [...props.requestList];
    cloneData[index][key] = value;
    props.handleRequestListChange(cloneData);
  };

  // 전체 체크박스 활성화 또는 비활성화를 처리하는 함수
  const handleCheckAllRowChange = (event) => {
    const checkAllValue = event.target.checked;

    setCheckAllRow(checkAllValue);
    props.handleRequestListChange((request) => request.map((element) => ({ ...element, _checked: checkAllValue })));
  };

  /*
   * @desc  상태코드에 따라 테이블 내 '첨부' 버튼을 생성하는 함수
   */
  const fnConverFileStatus = (row) => {
    if (props.submissionType === "제출") {
      if (row.accountRegistrationStatus === "READY_TO_REGISTER") {
        if (CM.cfnIsEmpty(row.evidenceFile)) {
          return (
            <button type="button" className="btn-s" onClick={(e) => handleClickSelectFile(row.accountUniqueKey)}>
              첨부하기
            </button>
          );
        } else {
          return (
            <button type="button" className="btn-s btn-disabled">
              첨부완료
            </button>
          );
        }
      }
    } else if (props.submissionType === "일부제출") {
      return (
        <SelectForm
          value={row.evidenceFileType || ""}
          handleChange={props.handleRowEvidenceTypeChange.bind(this, row)}
          name="evidenceFileType"
          arrayOption={CM.cfnGetEvidenceFileTypes()}
          optionValue={"evidenceFileType"}
          optionLabel={"evidenceFileTypeName"}
          customProps={{
            inputProps: { "data-testid": "file-type-select" },
          }}
        />
      );
    }
  };

  /*
   * @desc  첨부하기 버튼 클릭 이벤트 핸들러
   */
  const handleClickSelectFile = (accountUniqueKey) => {
    props.setSelectedAccountUniqueKey(accountUniqueKey);
    props.setOpen(true);
  };

  return (
    <div>
      <Table data-testid="request-table">
        {CM.cfnCompColGroup(["5%", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"])}
        <TableForm.compServerSortTableHead
          useCheckbox={true}
          checked={checkAllRow}
          value=""
          onChange={handleCheckAllRowChange}
          arrData={[
            { id: "accountRequestStatus", label: "신청구분", sortable: false },
            { id: "customerName", label: "고객명", sortable: true },
            { id: "payerNo", label: "납부자번호", sortable: true },
            { id: "customerMobile", label: "전화번호", sortable: true },
            {
              id: "financialInstituteName",
              label: "금융기관",
              sortable: false,
            },
            { id: "accountNo", label: "계좌번호", sortable: false },
            {
              id: "depositorIdentificationNoFirst7",
              label: "예금주생년월일",
              sortable: false,
            },
            { id: "evidenceFile", label: "동의자료", sortable: false },
            { id: "evidenceFileType", label: "동의자료 구분", sortable: true },
          ]}
          searchRequest={props.searchRequest}
          handleSortProperty={props.handleSortProperty}
        />
        <TableBody>
          {props.requestList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={10} />
          ) : (
            props.requestList.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell align="center" name="?" index="0">
                    <Checkbox
                      checked={row._checked}
                      value="_checked"
                      id={`deleteCheckbox-${index}`}
                      onChange={handleSelectCheckbox}
                      inputProps={{
                        index: index,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">{CommonData.accountRequestStatus(row.wayToCreateAccount, row.accountRegistrationStatus)}</TableCell>
                  <TableCell align="center">{row.customerName}</TableCell>
                  <TableCell align="center">{row.payerNo}</TableCell>
                  <TableCell align="center">{CM.cfnIsEmpty(row.customerMobile) ? "-" : CM.cfnAddtDashToPhoneNumber(row.customerMobile)}</TableCell>
                  <TableCell align="center">{CM.cfnMatchBankName(row.financialInstituteCode, props.financialInstitutes)}</TableCell>
                  <TableCell align="center">{row.accountNo}</TableCell>
                  <TableCell align="center">{CM.cfnIdentificationNoFormat(row.depositorIdentificationNo)}</TableCell>
                  <TableCell align="center">{fnConverFileStatus(row)}</TableCell>
                  <TableCell align="center">{CM.cfnIsEmpty(row.evidenceFileType) ? "-" : CommonData.evidenceFileType(row.evidenceFileType)}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

//고객정보관리 > 출금 계좌 등록해지 > 실시간 서비스 > 실시간계좌등록/해지요청
/*
 * @desc  메인 컴포넌트
 */
const AccountRegisterRequestList = ({ tabIndex, store }) => {
  const loginStore = store.loginStore;
  const instituteStore = store.instituteStore;

  const fileInput = useRef(""); //첨부하기 버튼 클릭 시 파일선택창 제어

  //화면 내 권한에 따른 제어를 위한 state 선언
  const [submissionType] = useState(loginStore.getEvidence()); // 제출 or 일부제출인 경우에만 동의자료 등록 가능하도록 제어

  //테이블 관련 state 선언
  const [searchRequest, setSearchRequest] = useState(AccountCheckData.requestSearchData); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalRequestList, setOriginalRequestList] = useState([]); // 서버로 부터 전달받은 original customer object
  const [requestList, setRequestList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(AccountCheckData.paginationData());

  //금융기관명 매칭을 위한 금융기관 목록
  const [financialInstitutes, setFinancialInstitutes] = useState(toJS(instituteStore.financialInstitutes));

  //모달 제어를 위한 state 선언
  const [folderModalOpen, setFolderModalOpen] = useState(false);

  //동의자료 첨부모달 내 전달
  const [selectedAccountUniqueKey, setSelectedAccountUniqueKey] = useState("");

  const [fileOpen, setFileOpen] = React.useState(false); //출금동의

  /*
   * @desc    출금동의 파일 state
   */
  const [evidenceFile, setEvidenceFile] = React.useState({
    file: null,
    fileName: "",
    fileType: "",
    isSavedOnServer: false,
  });

  /*
   * @desc    고객정보 및 수납정보 수정 Request
   */
  const fnChangeFileType = (fileType) => {
    switch (fileType) {
      case "PAPER":
        return "1";
      case "PUBLIC_SIGNATURE":
        return "2";
      case "GENERAL_SIGNATURE":
        return "3";
      case "RECORDING":
        return "4";
      case "ARS":
        return "5";
      case "ETC":
        return "6";
      case "PRIVATE_SIGNATURE":
        return "7";
      default:
        return fileType;
    }
  };
  const fnSubmit = async () => {
    let form = new FormData();

    if (evidenceFile.file && evidenceFile.fileName && evidenceFile.fileName !== "") {
      form.append("evidenceFile", evidenceFile.file, evidenceFile.fileName);
    } else {
      form.append("evidenceFile", evidenceFile.file);
    }
    form.append("evidenceFileType", new Blob([JSON.stringify(fnChangeFileType(evidenceFile.fileType))], { type: "application/json" }));
    form.append("accountUniqueKey", new Blob([JSON.stringify(selectedAccountUniqueKey)], { type: "application/json" }));

    if (CM.cfnIsNotEmpty(evidenceFile.file)) {
      const fileNameurl = "api/customer/receipt/account/extra/account-registration/files";

      CM.cfnAxios(fileNameurl, "post", form, async (objStatus, objData) => {
        CM.cfnAlert("정상적으로 처리되었습니다.", () => {
          setFileOpen(false);
          handleSearchButtonChange(true);
        });
      });
    }
  };

  useEffect(() => {
    /*
     * @desc    금융기관 목록 조회 Request
     */
    const fnFetchFinancialInstitutes = async () => {
      const institutesList = await instituteStore.axiosFinancialInstitutes();
      setFinancialInstitutes(institutesList);
    };

    if (tabIndex === 1 && CM.cfnIsEmpty(financialInstitutes)) fnFetchFinancialInstitutes();
  }, [tabIndex, financialInstitutes, instituteStore, setFinancialInstitutes]);

  // 테이블 데이터 검색
  useEffect(() => {
    /*
     * @desc 실시간계좌등록요청 목록 조회 파라미터 생성 함수
     */
    const fnMakeParameter = () => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      if (tempObj.term === "customerName") {
        tempObj.customerName = CM.cfnNvl(tempObj.keyword, "");
        tempObj.payerNo = "";
      } else if (tempObj.term === "payerNo") {
        tempObj.payerNo = CM.cfnNvl(tempObj.keyword, "");
        tempObj.customerName = "";
      }

      return tempObj;
    };

    // axios request informationList
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/extra/account-registration?pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}&customerMobile=${search.customerMobile}&customerName=${search.customerName}&payerNo=${search.payerNo}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    const startAxios = async (search) => {
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      // 체크 옵션 추가
      for (const element of resultList) {
        element._checked = false;
      }

      setOriginalRequestList(resultData);
      handleRequestListChange(resultList);
      setPagination(AccountCheckData.paginationData(resultData));
      handleSearchButtonChange(false);
    };

    if (tabIndex === 1 && searchButton === true) {
      const param = fnMakeParameter();
      startAxios(param);
    }
  }, [tabIndex, searchRequest, searchButton]);

  // 검색 (page)
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  // 검색 (rowperpage)
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({
      ...data,
      pageNumber: 0,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    setSearchRequest({
      ...searchRequest,
      [name]: e.target.value,
    });
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });

    //정렬조건 세팅 후 검색 trigger
    handleSearchButtonChange(true);
  };

  // 검색 flag handleChange
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  // 고객 목록 리스트 값을 변경하는 함수
  const handleRequestListChange = (value) => setRequestList(value);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    테이블 내 동의자료 셀렉트박스 변경 이벤트 핸들러
   */
  const handleRowEvidenceTypeChange = (row, change) => {
    const tempArray = [...requestList];
    const uniqueKey = row.accountUniqueKey;
    let rowIndex = null;
    tempArray.forEach((row, index) => {
      if (row.accountUniqueKey === uniqueKey) rowIndex = index;
    });

    tempArray[rowIndex]["evidenceFileType"] = change.currentTarget.value;

    handleRequestListChange(tempArray);
  };

  /*
   * @desc    계좌 등록 요청 버튼 클릭 이벤트 핸들러
   */
  const handleRegisterAccountRequest = () => {
    if (fnCheckValidation()) CM.cfnConfirm("등록하시겠습니까?", fnRequestRegisterFiles);
  };

  /*
   * @desc    모달창 열기 버튼 클릭 이벤트 핸들러
   */
  const handleClickModal = () => {
    setFolderModalOpen(true);
  };

  /*
   * @desc    요청 validation 체크 함수
   */
  const fnCheckValidation = () => {
    const tempArray = [];

    for (const request of requestList) {
      if (request._checked) {
        tempArray.push(request);
      }
    }

    //체크된 행 없을 시
    if (CM.cfnIsEmpty(tempArray)) {
      CM.cfnAlert("선택된 내용이 없습니다.");
      return false;
    }

    //체크된 행의 첨부파일 없을 시
    // for (let i = 0; i < tempArray.length; i++) {
    //   if (CM.cfnIsEmpty(tempArray[i].evidenceFileType)) {
    //     CM.cfnAlert(`선택한 항목 중 ${i + 1}번째 항목의 동의자료가 첨부되어있지 않습니다.`);
    //     return false;
    //   }
    // }

    return true;
  };

  /*
   * @desc    실시간 계좌등록 요청 Request
   */
  const fnRequestRegisterFiles = () => {
    const selectedList = [];

    for (const request of requestList) {
      if (request._checked) {
        selectedList.push(request);
      }
    }

    //쿼리 스트링에 붙여줄 array 생성
    const uniqueKeyList = fnConvertArrayToQueryString("accountUniqueKey", selectedList); //계좌 unique key 목록
    const evidenceTypeList = fnConvertArrayToQueryString("type", selectedList); //증빙파일 구분값 목록

    let url = `api/customer/receipt/account/extra/account-registration?accountUniqueKeys=${uniqueKeyList}&evidenceFileTypes=${evidenceTypeList}`;

    CM.cfnAxios(url, "post", "", fnRequestRegisterFilesCallback);
  };

  /*
   * @desc    계좌 등록 Request Callback
   */
  const fnRequestRegisterFilesCallback = (objStatus, objData) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    CM.cfnAlert(objData);
    handleSearchButtonChange(true);
  };

  /*
   * @desc    요청 파라미터 Array를 가공하여 query string 형태로 변환하는 함수
   */
  const fnConvertArrayToQueryString = (type, list) => {
    switch (type) {
      case "accountNo":
        return list.map((row) => row.accountNo);
      case "accountUniqueKey":
        return list.map((row) => row.accountUniqueKey);
      case "depositorIdentificationNo":
        return list.map((row) => row.depositorIdentificationNo);
      case "type":
        return list.map((row) => row.evidenceFileType);
      case "file":
        return list.map((row) => row.evidenceFile);
      case "bankCode":
        return list.map((row) => row.financialInstituteCode);
      case "payerNo":
        return list.map((row) => row.payerNo);
      default:
        return [];
    }
  };

  // 화면 렌더
  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <TotalCountForm totalElements={originalRequestList.totalElements || 0} />
        <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
        {submissionType === "제출" && (
          <div className="btn-m fr" onClick={handleClickModal} data-testid="insert-evidence">
            동의자료 일괄등록
          </div>
        )}
        <div className="btn-l fr" onClick={handleRegisterAccountRequest} data-testid="account-request">
          계좌 등록/해지 요청
        </div>
      </div>
      <ListForm
        requestList={requestList}
        handleRequestListChange={handleRequestListChange}
        financialInstitutes={financialInstitutes}
        setSelectedAccountUniqueKey={setSelectedAccountUniqueKey}
        searchRequest={searchRequest}
        handleSortProperty={handleSortProperty}
        submissionType={submissionType}
        handleRowEvidenceTypeChange={handleRowEvidenceTypeChange}
        fileInput={fileInput}
        setOpen={setFileOpen}
      />
      <Pagination
        className="centered"
        limit={pagination.rowsPerPage}
        offset={pagination.offset}
        total={pagination.total}
        onClick={(e, offset, page) => handleOffsetChange(offset, page)}
        reduced={true}
        centerRipple={false}
        disableFocusRipple={true}
        disableRipple={true}
      />
      <FolderRegisterModal open={folderModalOpen} setOpen={setFolderModalOpen} handleSearchButtonChange={handleSearchButtonChange} />
      <AccountEvidenceFileInput accountUniqueKey={selectedAccountUniqueKey} fileInput={fileInput} handleSearchButtonChange={handleSearchButtonChange} />

      <EvidenceFileModal
        open={fileOpen}
        setOpen={setFileOpen}
        originalState={""}
        setOriginalState={""}
        evidenceFile={evidenceFile}
        setEvidenceFile={setEvidenceFile}
        fnSubmitEvidenceFile={fnSubmit}
        fnDeleteEvidenceFile={""}
        type={"update"}
        modalType={"accountRegisterRequest"}
      />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore,
  props: props,
}))(observer(AccountRegisterRequestList));
