import {Button, Input, Modal, Select, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as CM from "../../common/Common";
import { PaginationForm, RowPerPageForm, TotalCountForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";

//초기 기본 값
const DEFAULT_HOOKS = {
  pageNumber: "0",
  pageSize: "5",
  sortDirection: "DESC",
  sortProperty: "customerName",
  searchProperty: "CUSTOMER_NAME", //검색조건
  searchContents: "", //검색내용
};

const RegistrationsResultDetailModal = ({ open, setOpen, file }) => {
  // 모달 클로즈
  const handleClose = () => {
    setOpen(false);
  };

  const [searchButton, setSearchButton] = useState(false);
  const [searchRequest, setSearchRequest] = useState(DEFAULT_HOOKS);

  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  // 화면 open시 화면 초기화
  useEffect(() => {
    if (!open) {
      return;
    }
    setSearchButton(true);
    setSearchRequest(DEFAULT_HOOKS)
  }, [open]);

  // 페이징 데이터
  function paginationData(data) {
    if (CM.cfnIsEmpty(data)) {
      return {
        rowsPerPage: 5,
        offset: 0,
        total: 0,
        totalPages: 1,
      };
    }

    return {
      rowsPerPage: data.pageable.pageSize,
      offset: data.pageable.offset,
      total: data.totalElements,
      totalPages: data.totalPages,
    };
  }

  // 검색 flag handleChange
  function handleSearchButtonChange(flag) {
    setSearchButton(flag);
  }

  useEffect(() => {
    // axios request informationList
    function axiosList() {
      return new Promise((resolve, reject) => {
        let url = `api/customer/receipt/account/financial-institute/registrations/result/detailed?pageNumber=${searchRequest.pageNumber}&pageSize=${searchRequest.pageSize}&sortDirection=${searchRequest.sortDirection}&sortProperty=${searchRequest.sortProperty}&searchProperty=${searchRequest.searchProperty}&searchContents=${searchRequest.searchContents}&fileUniqueKey=${file.fileUniqueKey}&filename=${file.filename}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    }

    async function startAxios() {
      const resultData = await axiosList();

      let resultList = resultData.content;
      if (CM.cfnIsEmpty(resultList)) {
        resultList = [];
      }

      setOriginalList(resultData);
      setList(resultList);
      setPagination(paginationData(resultData));
      setSearchButton(false);
    }

    if (searchButton) {
      startAxios();
    }
  }, [searchRequest, searchButton, file.fileUniqueKey, file.filename, open]);

  // 테이블 데이터 검색 조건을 변경하는 함수
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    정렬 조건 변경 이벤트 핸들러
   */
  const handleSortProperty = (sortObjArray) => {
    let sortProperty = "";
    let sortDirection = "";

    for (const obj of sortObjArray) {
      if (obj.name === "sortProperty") sortProperty = obj.value;
      if (obj.name === "sortDirection") sortDirection = obj.value;
    }

    setSearchRequest({
      ...searchRequest,
      sortProperty: sortProperty,
      sortDirection: sortDirection,
    });
  };

  const parseAskedContents = (askedContents) => {
    switch (askedContents) {
      case "REGISTRATION":
        return "등록";
      default:
        return "해지";
    }
  };

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    handleSearchButtonChange(true);
  };

  /*
 * @desc     키보드 이벤트 핸들러
 */
  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      fnSearch();
    }
  };

  const handleSearchFormChange = (name) => (e) => {
    if(name === "searchProperty"){
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
        searchContents : "",
      });
    }else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  return (
      <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
        <div className="paper">
          <div className="inner">
            <div className="modal-top-area">
              <Button className="fr btn-close" onClick={handleClose} data-testid="close-modal">
                {" "}
              </Button>
            </div>
            <h3>수납 계좌 등록/해지(금융기관 접수분)</h3>
            <div className="modal-overflow-table">
              <div className="search-area centered-w95">
                <div className="wrapSafeArea" style={{ justifyContent: "flex-start" }}>
                  <label className="label-l">검색어 입력</label>
                  <Select native name="searchProperty" value={searchRequest.searchProperty} onChange={handleSearchFormChange("searchProperty")}>
                    <option value="CUSTOMER_NAME">고객명</option>
                    <option value="PAYER_NO">납부자번호</option>
                  </Select>
                  <Input
                      value={searchRequest.searchContents}
                      onChange={handleSearchFormChange("searchContents")}
                      onKeyUp={handleSearchKeyUp}
                      name="searchContents"
                      className="w120"
                      data-testid="cms-request-keyword-resulttab"
                  />
                  <button className="search-button" onClick={fnSearch}>
                    검색
                  </button>
                </div>
              </div>
              <div className="table-top-area">
                <TotalCountForm totalElements={originalList.totalElements || 0}/>
                <RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange}/>
              </div>
              <Table>
                {CM.cfnCompColGroup(["140px", "120px", "100px", "140px", "100px", "120px", "140px", "140px", "140px"])}
                <TableForm.compServerSortTableHead
                    useCheckbox={false}
                    arrData={[
                      {id: "customerName", label: "고객명", sortable: true},
                      {id: "customerIdentification", label: "예금주 생년월일\n(사업자번호)", sortable: false},
                      {id: "payerNo", label: "납부자번호", sortable: true},
                      {id: "capitalName", label: "자금종류", sortable: false},
                      {id: "askedContents", label: "신청구분", sortable: false},
                      {id: "customerMobile", label: "예금주연락처", sortable: false},
                      {id: "fncInsNm", label: "금융기관", sortable: false},
                      {id: "accountNo", label: "계좌번호", sortable: false},
                      {id: "fileResultCodeMessage", label: "처리결과", sortable: false},
                    ]}
                    searchRequest={searchRequest}
                    handleSortProperty={handleSortProperty}
                />
                <TableBody>
                  {list.length === 0 ? (
                      <TableForm.compEmptyTableRow colSpan={10}/>
                  ) : (
                      list.map((row, index) => {
                        return (
                            <TableRow key={index}>
                              <TableCell align="center">{row.customerName}</TableCell>
                              <TableCell
                                  align="center">{CM.cfnIdentificationNoFormat(row.customerIdentification)}</TableCell>
                              <TableCell align="center">{row.payerNo}</TableCell>
                              <TableCell align="center">{row.capitalName}</TableCell>
                              <TableCell
                                  align="center">{(row.receiptType || "") + parseAskedContents(row.askedContents)}</TableCell>
                              <TableCell
                                  align="center">{CM.cfnIsNotEmpty(row.customerMobile) ? CM.cfnAddtDashToPhoneNumber(row.customerMobile) : "-"}</TableCell>
                              <TableCell align="center">{row.financialInstituteName}</TableCell>
                              <TableCell align="center">{CM.cfnMasking(row.accountNo)}</TableCell>
                              <TableCell
                                  align="center">{row.fileResultCodeMessage + (row.fileResultCode ? `(${row.fileResultCode.replace("E_", "")})` : "")}</TableCell>
                            </TableRow>
                        );
                      })
                  )}
                </TableBody>
              </Table>
              <PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)}/>
            </div>
          </div>
        </div>
      </Modal>
  );
};

export default RegistrationsResultDetailModal;
