import React, { useState, useEffect, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { FormControl, Table, TableBody, TableRow, TableCell, Select, FormControlLabel, FormGroup, RadioGroup, Radio } from "@material-ui/core";

import * as CM from "../../common/Common";
import TableForm from "../../template/TableForm";

function CreateSearchForm(props) {
  const { searchParam, changeSearchFormHandler, selectOptionYear } = props;

  const fnClickSearch = () => {
    props.changeSearchButtonHandler(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">통계연도</label>
        <Select native data-testid="targetYear_selectBox" className="w100" name="targetYear" value={searchParam.targetYear} onChange={changeSearchFormHandler("targetYear")}>
          {selectOptionYear.map((option, index) => {
            return (
              <option value={option} key={index}>
                {option}년
              </option>
            );
          })}
        </Select>
        <label className="label-l">지급방법</label>
        <FormControl component="fieldset">
          <FormGroup aria-label="transactionMethod" name="transactionMethod" row>
            <RadioGroup name="transactionMethod" value={searchParam.transactionMethod} onChange={changeSearchFormHandler("transactionMethod")} data-testid="transactionMethod" row>
              <FormControlLabel value="" control={<Radio color="primary" />} label="전체" />
              <FormControlLabel value="CMS_DEPOSIT" control={<Radio color="primary" />} label="CMS" />
              <FormControlLabel value="ETC" control={<Radio color="primary" />} label="기타" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
        <button className="search-button" onClick={() => fnClickSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

function CreateTopListForm(props) {
  const headRowOne = [
    { value: "구분", rowSpan: 2 },
    { value: "지급대상", rowSpan: 2 },
    { value: "지급내역", colSpan: 4 },
    { value: "지급보류", rowSpan: 2 },
    { value: "환입", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "지급완료" }, { value: "부분지급" }, { value: "미지급" }, { value: "미청구" }];

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["10%", "auto", "12%", "12%", "12%", "12%", "12%", "12%"])}
        <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />
        <TableBody>
          {props.topList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            <>
              <TableRow>
                <TableCell align="center"> 건수 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalAskingCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPartiallyPaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnpaidCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnaskedCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalHoldingCount)}건 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalRefundedCount)}건 </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center"> 금액 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalAskingAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPaidAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalPartiallyPaidAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnpaidAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalUnaskedAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalHoldingAmount)}원 </TableCell>
                <TableCell align="right"> {CM.cfnAddComma(props.topList.totalRefundedAmount)}원 </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}

function CreateBottomListForm(props) {
  const headRowOne = [
    { value: "년/월", rowSpan: 2 },
    { value: "구분", rowSpan: 2 },
    { value: "지급대상", rowSpan: 2 },
    { value: "지급내역", colSpan: 4 },
    { value: "지급보류", rowSpan: 2 },
    { value: "환입", rowSpan: 2 },
  ];
  const headRowTwo = [{ value: "지급완료" }, { value: "부분지급" }, { value: "미지급" }, { value: "미청구" }];

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["auto", "11%", "11%", "11%", "11%", "11%", "11%", "11%", "11%"])}
        <TableForm.compDoubleRowTableHead rowOne={headRowOne} rowTwo={headRowTwo} />

        <TableBody>
          {props.bottomList.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={9} />
          ) : (
            props.bottomList.map((row, index) => {
              return (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>
                      {CM.cfnDateFormat(row.targetY4mm, "yyyyMM")}
                    </TableCell>
                    <TableCell align="center"> 건수 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalAskingCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPaidCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPartiallyPaidCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnpaidCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnaskedCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalHoldingCount)}건 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalRefundedCount)}건 </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center"> 금액 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalAskingAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalPartiallyPaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnpaidAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalUnaskedAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalHoldingAmount)}원 </TableCell>
                    <TableCell align="right"> {CM.cfnAddComma(row.totalRefundedAmount)}원 </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
          )}
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
}

function defaultSearchParam() {
  const today = new Date();

  const currentYYYY = today.getFullYear();

  return {
    transactionMethod: "", //수납방법 - null 또는 empty(전체), CMS_WITHDRAW(CMS출금), ETC(기타수납)
    targetYear: currentYYYY,
  };
}

//Main Component
const PaymentMonthlyStatistics = (props) => {
  const store = props.store;
  const { tabIndex } = props;
  const [searchParam, setSearchParam] = useState(defaultSearchParam());
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [topList, setTopList] = useState([]);
  const [bottomList, setBottomList] = useState([]);
  const [selectOptionYear, setSelectOptionYear] = useState([]);

  const setSelectBoxYearList = () => {
    const today = new Date();
    const currentYYYY = today.getFullYear();
    const startOptionYear = 2005;
    const lastOptionYear = currentYYYY;
    let selectOptionYearList = [];
    for (var i = lastOptionYear; i >= startOptionYear; i--) {
      selectOptionYearList.push(i);
    }
    setSelectOptionYear(selectOptionYearList);
  };

  useEffect(() => {
    setSelectBoxYearList();
    let queryString = `?transactionMethod=${searchParam.transactionMethod}&targetYear=${searchParam.targetYear}`;

    const getTopListAxios = (searchParam) => {
      return new Promise((resolve) => {
        let url = `api/payment/statistics/month/summary` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseTopListData) => {
          resolve(responseTopListData);
        });
      });
    };

    const getBottomListAxios = (searchParam) => {
      return new Promise((resolve) => {
        let url = `api/payment/statistics/month` + queryString;
        CM.cfnAxios(url, "get", "", (status, responseBottomListData) => {
          resolve(responseBottomListData);
        });
      });
    };

    const startAxios = async (searchParam) => {
      //고객별 수납통계 요약내용 조회
      getTopListAxios(searchParam)
        .then((responseSummaryData) => {
          setTopList(responseSummaryData);
        })
        .catch((error) => {
          console.error(error);
        });

      getBottomListAxios(searchParam)
        .then((responseDetailData) => {
          setBottomList(responseDetailData);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (tabIndex === 2 && searchButton === true) {
      startAxios(searchParam);
      setSearchButton(false);
    }
  }, [tabIndex, searchButton, searchParam, store]);

  const changeSearchButtonHandler = (flag) => {
    setSearchButton(flag);
  };

  const changeSearchFormHandler = (name) => (e) => {
    const changedValue = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: changedValue,
    });
  };

  const monthlyExcelDownload = (e) => {
    let param = [];
    Object.keys(searchParam).forEach((key) => param.push(key + "=" + searchParam[key]));
    let url = "/api/payment/statistics/month/excel?" + param.join("&");
    CM.cfnAxiosFileDownload(url, "get", "", () => {});
  };

  return (
    <div id="searchForm" data-testid="searchForm">
      {/* 검색 폼 */}
      <CreateSearchForm searchParam={searchParam} changeSearchFormHandler={changeSearchFormHandler} changeSearchButtonHandler={changeSearchButtonHandler} selectOptionYear={selectOptionYear} />

      <CreateTopListForm topList={topList} />
      <div className="table-top-area">
        <button className="btn-m fr" onClick={(event) => monthlyExcelDownload()} data-testid="save-excel">
          엑셀저장
        </button>
      </div>
      {/* 상세 목록 폼 */}
      <CreateBottomListForm bottomList={bottomList} />
    </div>
  );
};

export default inject((rootStore, props) => ({
  store: rootStore.instituteStore,
  props: props,
}))(observer(PaymentMonthlyStatistics));
