import React, { useState, useEffect } from "react";

import Modal from "@material-ui/core/Modal";
import { Table, TableBody, TableRow, TableCell, Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

import * as CM from "../../common/Common";
import * as CF from "../../template/ComponentForm";

// 고객정보관리 > 직접 접수분 > 계좌등록요청 modal
// 렌더를 처리하는 메인 컴포넌트
const RegistrationsAccountRegistrationRequest = (props) => {
  // 등록요청대상 radio 목록
  const radioRequestTargets = [
    { value: "all", label: "전체" },
    { value: "new", label: "계좌등록 요청" },
    { value: "closing", label: "계좌해지 요청" },
  ];

  // hooks
  const [requestTarget, setRequestTargets] = useState("all"); // 등록요청대상 값
  const [requestDate, setRequestDate] = useState(null); // 등록요청 일
  const [minDate, setMinDate] = useState(null); // 등록요청 최소 일
  const [requestFlag, setRequestFlag] = useState(false);

  const handleRequestTargetChange = ({ target: { value } }) => setRequestTargets(value);

  const handleRequestDateChange = (date) => setRequestDate(date);
  const calculateMaxDate = () => {
    let date = new Date();
    return new Date(date.setMonth(date.getMonth() + 2));
  };

  const SummaryForm = (props) => {
    return (
        <div className="summary-area">
          {props.list.map((summary, index) => {
            return (
                <div
                    className={summary.key === "registrationExcludedTargets" ? "text-graph" : "text-graph show-detail"}
                    key={index}
                    data-testid={`${props["data-testid"]}-summary-info-${index}`}>
                  <label>{summary.label}</label>
                  <span>{CM.cfnAddComma(summary.value)}</span>
                </div>
            );
          })}
        </div>
    );
  };

  const maxDate = calculateMaxDate();
  // 날짜 설정
  useEffect(() => {
    //휴일 조회 Request
    const fnFetchPeriodDate = (paramDate) => {
      return new Promise((resolve) => {
        const url = `api/gadget/bizDate/period?startDate=${paramDate}&endDate=${paramDate}`;

        CM.cfnAxios(url, "get", "", (status, data) => {
          if (data.length !== 0) {
            resolve(data[0].isHoliday);
          } else {
            resolve(false);
          }
        });
      });
    };

    const setDate = async (date) => {
      let today = new Date();
      let bizDate;
      const currentYear = today.getFullYear().toString();
      const currentMonth = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
      const currentDate = today.getDate().toString() > 9 ? today.getDate().toString() : `0${today.getDate().toString()}`;
      const currentTime = today.getHours();
      let periodDate = await fnFetchPeriodDate(`${currentYear}${currentMonth}${currentDate}`);
      if (periodDate) {
        bizDate = await CM.fnGetPrevBizDate(`${currentYear}${currentMonth}${currentDate}`);
        bizDate = bizDate.toString();
      } else {
        if (currentTime < 12) {
          bizDate = await CM.fnGetPrevBizDate(`${currentYear}${currentMonth}${currentDate}`);
          bizDate = bizDate.toString();
        } else {
          bizDate = `${currentYear}${currentMonth}${currentDate}`;
        }
      }

      const prevBizDate = new Date(`${bizDate.substr(0, 4)}-${bizDate.substr(4, 2)}-${bizDate.substr(6)}`);

      setRequestDate(prevBizDate);
      setMinDate(prevBizDate);
    };

    // 현재 날짜
    const date = new Date();

    setRequestTargets("all"); // 전체 체크
    setDate(date); // 날짜 설정
  }, [props.modalOpen]);

  useEffect(() => {
    if (requestFlag) {
      if (CM.cfnDateCalculation(requestDate, minDate) < 0) {
        const formattingDate = CM.cfnDateFormat(CM.cfnConvertDateToString(minDate), "yyyyMMdd");

        CM.cfnAlert(`접수일은 ${formattingDate} 보다 작을 수 없습니다.`);
        return false;
      }
      const registrationTargets = props.registrationExcludedTargets;
      for (const value of registrationTargets) {
        delete value.excluded;
      }
      const requestUrl = "api/customer/receipt/account/institute/registrations";

      const sendData = {
        isNewAccountIncluded: requestTarget === "closing" ? false : true,
        isClosingAccountIncluded: requestTarget === "new" ? false : true,
        requestDate: CM.cfnConvertDateToString(requestDate),
        receiptAccountRegistrationTargets: registrationTargets,
      };

      const callback = async () => {
        setRequestFlag(false);
        await props.initialRegistrationExcludedTargets();
        await props.handleModalOpenChange(false);
        await props.handleSearchOnChange(true);
      };

      CM.cfnAxios(
        requestUrl,
        "post",
        sendData,
        (status, data) => {
          CM.cfnAlert("등록 요청에 성공했습니다.", callback);
        },
        () => {
          setRequestFlag(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestFlag]);

  // 등록 요청
  const registrationRequest = (e) => {
    e.preventDefault();
    if (!requestFlag) {
      setRequestFlag(true);
    }
  };

  // * 화면 렌더
  return (
    <Modal open={props.modalOpen}>
      <div className="paper">
        <div className="inner">
          <div className="modal-top-area">
            <Button className="fr btn-close" onClick={() => props.handleModalOpenChange(false)} data-testid="registerAccountByInstitute-registrationRequest-close-modal">
              {""}
            </Button>
          </div>
          <h3>계좌등록/해지 요청</h3>
          <div className="inforbox">
            <ul>
              <li>접수일은 현재 시각이 낮 12시를 지났으면 오늘 날짜(영업일)부터, 낮 12시 이전이면 어제 날짜(전 영업일)부터 선택할 수 있습니다.</li>
              <li>고객의 자동납부 동의자료가 없으면 계좌등록을 할 수 없으며, "보완대상"으로 분류됩니다.</li>
            </ul>
          </div>
          <div className="summary-main-area margin-top-10">
            <SummaryForm list={props.summaryInfo} data-testid="registerAccountByInstitute-registrations"/>
          </div>
          <div>
            <Table>
              {CM.cfnCompColGroup(["20%", "auto"])}
              <TableBody>
                <TableRow>
                  <TableCell className="th">접수대상</TableCell>
                  <TableCell className="td">
                    <RadioGroup name="requestTarget" value={requestTarget} onChange={handleRequestTargetChange} data-testid="registerAccountByInstitute-registrationRequest-radio-requestTarget" row>
                      {radioRequestTargets.map((element, index) => {
                        return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
                      })}
                    </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="th">접수일</TableCell>
                  <TableCell className="td">
                    <CF.DatePickerForm
                      className="w160"
                      value={requestDate}
                      handleChange={handleRequestDateChange}
                      customProps={{
                        minDate,
                        maxDate,
                        shouldDisableDate: (day) => day.getDay() === 0 || day.getDay() === 6,
                        inputProps: { "data-testid": "registerAccountByInstitute-registrationRequest-requestDate" },
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="table-bottom-area">
            <button className="btn-l" onClick={registrationRequest}>
              등록/해지 요청
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegistrationsAccountRegistrationRequest;
