import React from "react";
import FtLogo from "../../styles/images/ft-logo.png";

import { Link } from "react-router-dom";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    //focus 처리
    this.state = {
      familySite: "",
      familySiteList: [
        { name: "패밀리사이트", value: "" },
        { name: "계좌이체PG", value: "http://www.bankpay.or.kr" },
        { name: "계좌정보통합관리", value: "https://www.payinfo.or.kr/account.html" },
        { name: "금융인증센터", value: "https://www.yessign.or.kr" },
        { name: "물품대금결제", value: "http://www.buycard.or.kr" },
        { name: "오픈뱅킹", value: "https://www.openbanking.or.kr" },
        { name: "기업 회계감사자료 온라인 발급", value: "https://audit.kftc.or.kr" },
        { name: "EXK(국가간ATM·송금)", value: "http://exk.kftc.or.kr" },
        { name: "어음정보센터(Knote)", value: "https://www.knote.kr" },
        { name: "전자어음(U-note)", value: "https://www.unote.kr/unoteIndex.html" },
        { name: "비즈지로", value: "https://biz.giro.or.kr" },
        { name: "이지로", value: "http://e-giro.giro.or.kr" },
        { name: "인터넷지로", value: "https://www.giro.or.kr" },
        { name: "전자수입인지", value: "https://www.e-revenuestamp.or.kr" },
        { name: "카드로택스", value: "https://www.cardrotax.kr" },
        { name: "나라빌", value: "http://www.narabill.kr" },
        { name: "빌링원플러스", value: "https://www.billingone.or.kr" },
        { name: "지로EDI", value: "https://www.cmsedi.or.kr/edi" },
        { name: "카드VAN", value: "https://www.kftcvan.or.kr" },
        { name: "트러스빌(전자세금계산서)", value: "http://www.trusbill.kr" },
        { name: "CMS", value: "https://www.cmsedi.or.kr/cms" },
        { name: "현금카드", value: "https://www.cashcard.or.kr" },
      ],
    };
  }

  handleSelectBox = (e) => {
    let jsonValue = Object.assign({}, this.state);
    jsonValue[e.target.name] = e.target.value;
    this.handleState(jsonValue);
    const w = window.open();
    w.opener = null;
    w.location = e.target.value;
  };

  handleState = ({ ...stateName }) => {
    let newState = Object.assign({}, this.state);
    Object.keys(stateName).forEach((key) => (newState[key] = stateName[key]));
    this.setState(newState);
  };

  render() {
    return (
      <footer>
        <div className="ft-info-area">
          <img className="logo" src={FtLogo} alt="" />
          <div className="ft-menu">
            <div className="ft-menu_area">
              <div>
                <a href="http://www.kftc.or.kr/kftc/main/EgovkftcHubMain.do" target="_blank" rel="noopener noreferrer">
                  회사소개
                </a>
                <a href="http://www.kftc.or.kr/kftc/about/EgovMapInfo.do?category=map2" target="_blank" rel="noopener noreferrer">
                  오시는길
                </a>
                <Link to="/about/contents">이용약관</Link>
                <a href="https://www.kftc.or.kr/kftc_html/footer/privacy/billingone.html" target="_blank" rel="noopener noreferrer">
                  <b>개인정보처리방침</b>
                </a>
                <a href="http://www.kftc.or.kr/kftc/data/EgovDataServiceInfo.do" target="_blank" rel="noopener noreferrer">
                  고객서비스헌장
                </a>
                <a href="http://www.kftc.or.kr/kftc/data/EgovDataCopyrightInfo.do" target="_blank" rel="noopener noreferrer">
                  저작권정책
                </a>
              </div>
              <select name="familySite" value={this.state.familySite} onChange={this.handleSelectBox} data-testid="test-family-site">
                {this.state.familySiteList.map((option, index) => {
                  return (
                    <option value={option["value"]} key={index}>
                      {option["name"]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="ft-text">
              <span>
                본 관 : (우)06220 서울특별시 강남구 논현로 432 (역삼동 717번지) <br />
                분당센터 : (우)13556 경기도 성남시 분당구 정자일로213번길 9 (정자동 10-3)
                <br />
                Copyright ⓒ 2019 KFTC (Korea Financial Telecommunications & Clearings Institute) All rights reserved.
              </span>
            </div>
          </div>
          <img src="https://ps.kftc.or.kr/logo.jpg" width="0" height="0" alt="" />
        </div>
      </footer>
    );
  }
}
export default Footer;
