import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as CM from "../../common/Common";
import * as Template from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import RegistrationError from "./RegistrationError";

// 검색 데이터
function searchData() {
  const today = new Date();
  const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return {
    startDate: monthAgo,
    endDate: today,
    pageNumber: 0,
    pageSize: 5,
    payerNo: "",
    sortDirection: "DESC",
    sortProperty: "confirmedDate",
  };
}

// 페이징 데이터
function paginationData(data) {
  if (CM.cfnIsEmpty(data)) {
    return {
      rowsPerPage: 5,
      offset: 0,
      total: 0,
      totalPages: 1,
    };
  }

  return {
    rowsPerPage: data.pageable.pageSize,
    offset: data.pageable.offset,
    total: data.totalElements,
    totalPages: data.totalPages,
  };
}

/*
 * @desc  검색 컴포넌트 생성
 */
function SearchForm(props) {
  const { searchRequest, handleSearchFormChange } = props;

  /*
   * @desc    검색버튼 클릭 이벤트 핸들러
   */
  const fnSearch = () => {
    props.handleSearchButtonChange(true);
  };

  return (
    <div className="search-area">
      <div className="block">
        <label className="label-l">접수일자</label>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.startDate}
          handleChange={handleSearchFormChange("startDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <span className="between">~</span>
        <Template.DatePickerForm
          className="w160 receiptRequestResultDatePicker"
          value={searchRequest.endDate}
          handleChange={handleSearchFormChange("endDate")}
          format="yyyy.MM.dd"
          customProps={{ placeholder: "YYYY.MM.DD" }}
        />
        <button className="search-button" onClick={() => fnSearch()}>
          검색
        </button>
      </div>
    </div>
  );
}

/*
 * @desc  목록 컴포넌트 생성
 */
function ListForm(props) {
  //accountRegistrationFileStatus별 버튼 처리
  const registrationButton = (row) => {
    if (row.accountRegistrationFileStatus === "READY_TO_RECEIVE" || row.accountRegistrationFileStatus === "READY_TO_RECEIVE_AFTER_DEADLINE") {
      return (
        <div>
          <button className="search-button" onClick={(e) => props.fnRegistrationsGetFile(row)}>
            접수분 수신
          </button>
        </div>
      );
    } else if (row.accountRegistrationFileStatus === "COMPLETED_TO_RECEIVE" && !(row.notProcessedResultCount === 0)) {
      return (
        <div>
          <button className="search-button" onClick={(e) => fnGoDetail(e, row)}>
            수신분 등록
          </button>
        </div>
      );
    } else if (row.accountRegistrationFileStatus === "COMPLETED_TO_RECEIVE" && row.notProcessedResultCount === 0) {
      return (
        <div>
          <button className="search-button" onClick={(e) => props.fnRegistrationsPostFile(row)}>
            수신분 처리
          </button>
        </div>
      );
    } else if (row.accountRegistrationFileStatus === "COMPLETED_AND_EXIST_ERRORS") {
      return (
        <div>
          <button className="search-button" onClick={(e) => props.fnOpenRegistrationsRefused(row)}>
            접수결과 전송
          </button>
        </div>
      );
    } else if (row.accountRegistrationFileStatus === "COMPLETED_TO_RECEIVE_AFTER_DEADLINE" && !(row.notProcessedResultCount === 0)) {
      return (
        <div>
          <button className="search-button" onClick={(e) => fnGoDetail(e, row)}>
            수신분 등록
          </button>
        </div>
      );
    } else if (row.accountRegistrationFileStatus === "COMPLETED_TO_RECEIVE_AFTER_DEADLINE" && row.notProcessedResultCount === 0) {
      return (
        <div>
          <button className="search-button" onClick={(e) => props.fnRegistrationsPostFile(row)}>
            수신분 처리
          </button>
        </div>
      );
    } else if (row.accountRegistrationFileStatus == null && row.notProcessedResultCount === 0) {
      return (
        <div>
          <button className="search-button" onClick={(e) => props.fnRegistrationsPostFile(row)}>
            수신분 처리
          </button>
        </div>
      );
    } else {
      return <div>-</div>;
    }
  };

  // row 클릭 시,  ignoreClick일 경우 detail페이지로 이동 제어
  const handleRowClick = (e, row) => {
    if (e.target.classList.contains("ignoreClick") || e.target.type === "submit") {
      return;
    }
    if (row.accountRegistrationFileStatus === "READY_TO_RECEIVE" || row.accountRegistrationFileStatus === "READY_TO_RECEIVE_AFTER_DEADLINE") {
      CM.cfnAlert("아직 수신하지 않은 계좌등록/해지 접수내역입니다. 먼저 내역을 수신하시기 바랍니다.", () => {});
    } else {
      props.fnGoDetailFile(row);
    }
  };

  // row 클릭 시,  detail페이지로 이동
  const fnGoDetail = (e, row) => {
    props.fnGoDetailFile(row);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["10%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"])}
        <TableForm.compTableHead arrData={["접수일자", "수신일시", "총 건수", "등록 건수", "해지 건수", "미처리 건수", "처리기한", "처리"]} />

        <TableBody>
          {props.list.length === 0 ? (
            <TableForm.compEmptyTableRow colSpan={8} />
          ) : (
            props.list.map((row, index) => {
              return (
                <TableRow key={index} className="show-detail" hover onClick={(e) => handleRowClick(e, row)}>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.receivedDate) ? CM.cfnDateFormat(row.receivedDate.toString()) : "-"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.confirmedDate) ? CM.cfnDateFormat(row.confirmedDate.toString()) : "-"}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.totalRequestTargets}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.newAccountRequestTargets}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.closingAccountRequestTargets}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {row.notProcessedResultCount}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {CM.cfnIsNotEmpty(row.limitDateTime) ? CM.cfnDateFormat(row.limitDateTime.toString()) : "-"}
                  </TableCell>
                  <TableCell className="ignoreClick" align="center">
                    {registrationButton(row)}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
}

/*
 * @desc  메인 컴포넌트
 */
function RegistrationsAwait(props) {
  const { tabIndex } = props;

  let history = useHistory();

  const [searchRequest, setSearchRequest] = useState(searchData()); // table 데이터 검색 조건
  const [searchButton, setSearchButton] = useState(true); // 검색 실행 flag
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]); // table 데이터
  const [pagination, setPagination] = useState(paginationData());

  const [openRegistrationsRefused, setOpenRegistrationsRefused] = useState(false);
  const [file, setFile] = useState({});
  useEffect(() => {
    /*
     * @desc  조회 파라미터 생성 함수
     */
    const fnMakeParameter = (searchRequest) => {
      let tempObj = CM.cfnCopyObject(searchRequest);

      tempObj.endDate = CM.cfnConvertDateToString(searchRequest.endDate);
      tempObj.startDate = CM.cfnConvertDateToString(searchRequest.startDate);

      return tempObj;
    };

    /*
     * @desc  조회 Request
     */
    const axiosList = (search) => {
      return new Promise((resolve) => {
        let url = `api/customer/receipt/account/financial-institute/registrations?startDate=${search.startDate}&endDate=${search.endDate}&pageNumber=${search.pageNumber}&pageSize=${search.pageSize}&sortDirection=${search.sortDirection}&sortProperty=${search.sortProperty}`;
        CM.cfnAxios(url, "get", "", (status, data) => {
          resolve(data);
        });
      });
    };

    // start axios and set table data
    async function startAxios(search) {
      setSearchButton(false);
      const resultData = await axiosList(search);
      const resultList = resultData.content;

      setOriginalList(resultData);
      setList(resultList);
      setPagination(paginationData(resultData));
    }

    // 실행 영역
    const getState = history.location.state;
    let tabIdx = "";
    if (Object.isExtensible(history.location.state) && (history.location.state.tabIndex === 0 || history.location.state.tabIndex === 1)) {
      tabIdx = history.location.state.tabIndex;
    }

    if (tabIndex === 0 && (history.action === "POP" || history.action === "PUSH") && CM.cfnIsNotEmpty(getState) && tabIndex === tabIdx && searchButton === true) {
      if (CM.cfnIsNotEmpty(getState.searchRequest)) {
        setSearchRequest({ ...searchRequest, ...getState.searchRequest });
      }
      const param = fnMakeParameter({ ...searchRequest, ...getState.searchRequest });
      startAxios(param);
    } else if (tabIndex === 0 && searchButton === true) {
      const param = fnMakeParameter(searchRequest);
      startAxios(param, searchRequest);
    }
  }, [tabIndex, searchRequest, searchButton, history]);

  /*
   * @desc    페이지 변경 이벤트 핸들러 (검색)
   */
  const handleOffsetChange = (offset, page) => {
    handleSearchRequestChange((data) => ({ ...data, pageNumber: page - 1 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc    페이지 당 조회건수 변경 이벤트 핸들러 (검색)
   */
  const handleRowPerPageChange = (e) => {
    const value = e.target.value;
    handleSearchRequestChange((data) => ({ ...data, pageSize: value, pageNumber: 0 }));
    handleSearchButtonChange(true);
  };

  /*
   * @desc   검색 조건 입력란 변경 이벤트 핸들러
   */
  const handleSearchFormChange = (name) => (e) => {
    if (name === "startDate" || name === "endDate") {
      setSearchRequest({
        ...searchRequest,
        [name]: e,
      });
    } else if (e.target.type === "checkbox") {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.checked,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [name]: e.target.value,
      });
    }
  };

  /*
   * @desc    검색 flag 변경 핸들러
   */
  const handleSearchButtonChange = (flag) => setSearchButton(flag);

  /*
   * @desc    검색조건 변경 이벤트 핸들러
   */
  const handleSearchRequestChange = (data) => setSearchRequest(data);

  const fnGoDetailFile = (row) => {
    history.push("/customer/registrationDetail", {
      file: row,
      searchRequest,
    });
  };

  const fnRegistrationsGetFile = (row) => {
    const url = `api/customer/receipt/account/financial-institute/registrations/file/${row.fileUniqueKey}`;
    CM.cfnAxios(url, "get", "", (status, data) => {
      CM.cfnAlert(data, () => {
        setSearchButton(true);
      });
    });
  };

  //수신분 등록
  const fnRegistrationsPostFile = (row) => {
    const url = `api/customer/receipt/account/financial-institute/registrations/file/${row.fileUniqueKey}`;
    CM.cfnAxios(url, "post", row.fileUniqueKey, (status, data) => {
      CM.cfnAlert(data, () => {
        setSearchButton(true);
      });
    });
  };

  //오류내역 전송 시, 모달 오픈
  const fnOpenRegistrationsRefused = async (row) => {
    await setFile(row);
    await setOpenRegistrationsRefused(true);
  };

  return (
    <div>
      <SearchForm searchRequest={searchRequest} handleSearchFormChange={handleSearchFormChange} handleSearchButtonChange={handleSearchButtonChange} />
      <div className="table-top-area">
        <Template.TotalCountForm totalElements={originalList.totalElements || 0} />
        <Template.RowPerPageForm value={searchRequest.pageSize} onChange={handleRowPerPageChange} />
      </div>
      <ListForm
        list={list}
        pagination={pagination}
        fnGoDetailFile={fnGoDetailFile}
        page={searchRequest.pageNumber}
        fnRegistrationsGetFile={fnRegistrationsGetFile}
        fnOpenRegistrationsRefused={fnOpenRegistrationsRefused}
        fnRegistrationsPostFile={fnRegistrationsPostFile}
      />
      <Template.PaginationForm pagination={pagination} onClick={(e, offset, page) => handleOffsetChange(offset, page)} />
      <RegistrationError open={openRegistrationsRefused} setOpen={setOpenRegistrationsRefused} file={file} />
    </div>
  );
}

export default RegistrationsAwait;
