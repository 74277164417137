import React from "react";

const ComponentFormEmbed = data => {
  React.useEffect(() => {
    var embedContent = '<embed src="' + data.src + '" style="height:' + data.style.height + '; width:100%"/>';
    document.getElementById("embedDiv").innerHTML = embedContent;
  }, [data]);

  return (
    <div id="embedDiv" style={data.style}>
      <embed src={data.src} id="frame" className="preview-content" data-testid="preview-content" title="iframe-title" />
    </div>
  );
};

export default ComponentFormEmbed;
