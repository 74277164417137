export const trusbillErrorMessageObject = {
  305: "자릿수는 1자리입니다 ",
  306: "자릿수는 10자리입니다.",
  307: "자릿수는 70자리입니다.",
  308: "자릿수는 24자리입니다.",
  309: "자릿수는 20자리입니다.",
  235: "발급자 사업자번호 값이 없습니다.",
  236: "발급자 상호 값이 없습니다.",
  237: "발급자 대표자 값이 없습니다.",
  238: "발급자 주소 값이 없습니다.",
  239: "발급자 업태 값이 없습니다.",
  240: "발급자 종목 값이 없습니다.",
  241: "공급가액 값이 없습니다.",
  242: "일련번호 값이 없습니다.",
  243: "문서 종류의 키 값이 없습니다.",
  244: "xmlData의 키 값이 없습니다.",
  245: "공급받는자 담당자 ID 값이 없습니다.",
  246: "공급받는자 담당자명 값이 없습니다.",
  247: "공급가액 값이 없습니다.",
  248: "일련번호 값이 없습니다.",
  249: "상품 세액이 없습니다.",
  250: "상품 공급가액이 없습니다.",
  251: "사업자등록번호 구분코드가 없습니다.",
  301: "자릿수는 21자리입니다.",
  302: "자릿수는 1자리입니다.",
  303: "자릿수는 4자리입니다.",
  304: "자릿수는 2자리입니다.",
  310: "자릿수는 20자리입니다.",
  311: "자릿수는 1자리입니다.",
  312: "자릿수는 32자리입니다.",
  313: "자릿수는 8자리입니다.",
  314: "자릿수는 4자리입니다.",
  315: "자릿수는 1자리입니다.",
  316: "자릿수는 1자리입니다.",
  317: "자릿수는 10자리입니다.",
  318: "자릿수는 10자리입니다.",
  319: "자릿수는 150자리입니다.",
  320: "자릿수는 12자리입니다.",
  321: "자릿수는 20자리입니다.",
  322: "이메일 자릿수는 40자리입니다.",
  323: "연락처 자릿수는 20자리입니다.",
  324: "공급자사업자번호 자릿수는 13자리 혹은 10자리입니다.",
  325: "공급자 상호 자릿수는 70자리입니다.",
  326: "공급자 대표자 자릿수는 30자리입니다.",
  327: "공급자 주소 자릿수는 150자리입니다.",
  328: "공급자 업태 자릿수는 40자리입니다.",
  329: "공급자 종목 자릿수는 40자리입니다.",
  330: "공급받는자 트러스빌 아이디 자릿수는 12자리입니다.",
  331: "공급받는자담당자명 자릿수는 20자리입니다.",
  332: "공급받는자담당자Email 자릿수는 40자리입니다.",
  333: "공급받는회사 전화번호 자릿수는 20자리입니다.",
  334: "공급받는자사업자번호 자릿수는 10자리입니다.",
  335: "공급받는자상호 자릿수는 70자리입니다.",
  336: "공급받는자대표자 자릿수는 30자리입니다.",
  337: "공급받는자주소 자릿수는 150자리입니다.",
  338: "공급받는자업태 자릿수는 40자리입니다.",
  339: "공급받는자종목 자릿수는 40자리입니다.",
  340: "발급 담당자 Email 자릿수는 40자리입니다.",
  341: "발급회사 전화번호 자릿수는 20자리입니다.",
  342: "발급자 사업자번호 자릿수는 13자리 혹은 10자리입니다.",
  343: "발급자 상호명 자릿수는 70자리입니다.",
  344: "발급자 대표자 자릿수는 30자리입니다.",
  345: "발급자 회사 주소 자릿수는 150자리입니다.",
  346: "발급자 업태 자릿수는 40자리입니다.",
  347: "공급가액의 자릿수는 18자리입니다.",
  348: "세액의 자릿수는 18자리입니다.",
  349: "자릿수는 4자리입니다.",
  350: "자릿수는 40자리입니다.",
  351: "자릿수는 20자리입니다.",
  352: "자릿수는 40자리입니다.",
  353: "자릿수는 4자리입니다. ",
  354: "자릿수는 40자리입니다.",
  355: "자릿수는 20자리입니다.",
  356: "자릿수는 40자리입니다.",
  357: "자릿수는 4자리입니다.",
  358: "자릿수는 40자리입니다.",
  359: "자릿수는 20자리입니다.",
  360: "자릿수는 40자리입니다.",
  361: "자릿수는 4자리입니다.",
  362: "자릿수는 40자리입니다.",
  363: "자릿수는 20자리입니다.",
  364: "자릿수는 40자리입니다.",
  365: "자릿수는 13자리입니다.",
  366: "자릿수는 1자리입니다.",
  367: "세금계산서품목의 수량은 소수점 2자리까지 허용합니다.",
  368: "세금계산서품목의 단가는 소수점 2자리까지 허용합니다.",
  369: "세금계산서품목의 공급가액은 원단위이하 자릿수를 허용하지 않습니다.",
  370: "세금계산서품목의 세액은 원단위이하 자릿수를 허용하지 않습니다.",
  371: "공급받는자 사업자번호 자릿수는 13자리입니다.",
  372: "위수탁자업태 자릿수는 40자리입니다.",
  401: "세금계산서 문서코드는 02 입니다. ",
  402: "입금표 문서코드는 세금계산서 문서코드는 03 입니다.",
  403: "대행사업자 등록번호가 올바르지 않습니다.",
  404: "공급자 담당자 Email 값이 올바르지 않습니다.",
  405: "공급회사 사업자 등록번호가 올바르지 않습니다.",
  406: "공급받는자 담당자 Email 값이 올바르지 않습니다.",
  407: "공급받는 회사 사업자 등록번호가 올바르지 않습니다.",
  408: "발급자 담당자 Email 값이 올바르지 않습니다.",
  409: "발급회사 사업자 등록번호가 올바르지 않습니다.",
  410: "과세구분이 'T'일 때 세액 값이 존재해야 합니다.",
  411: "과세구분이 'Z'일 때 세액 값이 '0'이어야 합니다.",
  412: "과세구분이 'N'일 때 세액 값이 'NULL'이거나, '0'이어야 합니다.",
  413: "발행구분은 'S' 혹은 'R' 이어야 합니다.",
  414: "발행양식은 'R 혹은 'D' 이어야 합니다.",
  415: "과세구분은 'T' 혹은 'Z' 혹은 'N' 이어야 합니다.",
  416: "발행종류 값은 'N' 혹은 'R' 이어야 합니다.",
  417: "해당 문서의 리스트 내역이 없습니다.",
  418: "발급회사 사업자 등록번호가 올바르지 않습니다.",
  419: "첨부 거래명세서의 합계가 세금계산서의 합계와 일치 하지 않습니다.",
  420: "작성일은 현재일보다 미래일 수 없습니다.",
  421: "공급받는자 사업자 구분은 '01' 혹은 '02' 혹은 '03'입니다.",
  422: "공급받는자사업자 구분이 외국인인 경우 사업자번호는 9999999999999 입니다.",
  423: "세금계산서의 세액과 세금계산서품목의 세액의 합이 일치하지 않습니다.",
  424: "세금계산서의 공급가액과 세금계산서품목의 공급가액의 합이 일치하지 않습니다.",
  425: "수정세금계산서만 금액에 (-)를 허용합니다.",
  426: "상품 리스트 정보가 없습니다. ",
  427: "상품 리스트의 수는 99 개까지입니다.",
  428: "작성일을 확인하시기 바랍니다.",
  429: "인증서의 만료기간과 작성일을 확인하시기 바랍니다.",
  430: "상품리스트의 날짜가 올바르지 않습니다.",
  431: "수정코드는 '01~06' 이어야 합니다.",
  432: "발행 불가한 작성일자 입니다. ",
  433: "공급자의 종사업장번호가 올바르지 않습니다.",
  434: "공급받는자의 종사업장번호가 올바르지 않습니다.",
  435: "위수탁자의 종사업장번호가 올바르지 않습니다.",
  436: "(-)만 가능한 수정코드입니다.",
  437: "1월 10일까지 발급가능한 작성일입니다.",
  438: "1월 20일까지 발급가능한 작성일입니다.",
  439: "6월 30일까지 발급가능한 작성일입니다.",
  440: "7월 10일까지 발급가능한 작성일입니다.",
  441: "7월 20일까지 발급가능한 작성일입니다.",
  442: "12월 31일까지 발급가능한 작성일입니다.",
  443: "공급받는자 주민등록번호가 올바르지 않습니다.",
  444: "지연발행 세금계산서입니다.",
  445: "1월 25일까지 발급가능한 작성일입니다.",
  446: "7월 25일까지 발급가능한 작성일입니다.",
  447: "공급가액과 세액의 부호가 다릅니다.",
  448: "상품리스트의 날짜는 작성일의 년월과 동일해야합니다.",
  0: "초기 상태 ",
  1: "발급 정상 완료됨",
  2: "전자문서 데이타는 올바르고 서버 Upload하기 전 상태",
  205: "대행사업자 등록번호의 값이 없습니다.",
  206: "대행사업자명의 값이 없습니다.",
  207: "전표번호 1 값이 없습니다.",
  208: "문서 구분 값이 없습니다.",
  209: "발행일자 값이 없습니다.",
  210: "시스템 코드 값이 없습니다.",
  11: "전자문서 발행시 서명하기 위해 사용되는 인증서가 유효하지 않은 인증서 입니다.",
  12: "전자문서 발행시 서명하기 위해 사용되는 인증서가 유효기간이 만료된 인증서 입니다",
  13: "서명오류",
  14: "전자문서 발행시 서명하기 위해 사용되는 인증서의 만료기간을 확인하시기 바랍니다.",
  21: "전자 세금계산서 XML생성 오류 입니다",
  22: "거래명세서 XML 생성오류 입니다.",
  23: "XML 데이타가 Well-formed 문서가 아닙니다.",
  24: "세금계산서와 연결된 첨부문서의 XML 데이타가 Well-formed 문서가 아닙니다.",
  50: "Trusbill 서버 접속 오류",
  51: "전자 문서 Trusbill 전송 오류 ",
  52: "로그인 오류",
  100: "전자 문서데이타 올바름",
  101: "DB 접속 오류 입니다.",
  102: "Table의 DB 필드명이 올바르지 않은경우 ",
  103: "각 필드의 인터페이스 규약이 맞지 않은 경우 ",
  104: "세금계산서와 연결된 첨부문서가 없음(EB_Relate Table에는 첨부문서 정보 있는데 실제 첨부문서에는 Record가 없는경우)",
  105: "세금계산서와 연결된 첨부문서 Table 스키마 오류",
  106: "세금계산서와 연결된 첨부문서 Table Data오류",
  904: "설정된 DB 버전에 따른 column 이 해당 테이블에 없거나, 정확하지 않습니다.",
  201: "발행종류 값이 없습니다",
  202: "위수탁세금계산서 여부 값이 없습니다",
  203: "증서 코드 값이 없습니다.",
  204: "과세 구분 값이 없습니다.",
  211: "발행구분 값이 없습니다.",
  212: "발행양식 값이 없습니다. ",
  213: "공급자 담당자 ID 값이 없습니다.",
  214: "공급자 담당자명 값이 없습니다.",
  215: "공급자 담당자 Email 값이 없습니다.",
  216: "공급회사 전화번호 값이 없습니다.",
  217: "공급자 사업자번호 값이 없습니다.",
  218: "공급자 상호 값이 없습니다.",
  219: "공급자 대표자 값이 없습니다.",
  220: "공급자 주소 값이 없습니다.",
  221: "공급자 업태 값이 없습니다.",
  222: "공급자 종목 값이 없습니다.",
  223: "공급받는자 담당자 ID 값이 없습니다.",
  224: "공급받는자 담당자명 값이 없습니다.",
  225: "공급받는자 담당자 Email 값이 없습니다.",
  226: "공급받는 회사 전화번호 값이 없습니다.",
  227: "공급받는자 사업자번호 값이 없습니다.",
  228: "공급받는자 상호 값이 없습니다.",
  229: "공급받는자 대표자 값이 없습니다.",
  230: "공급받는자 주소 값이 없습니다.",
  231: "공급받는자 업태 값이 없습니다.",
  232: "공급받는자 종목 값이 없습니다.",
  233: "발급자 담당자 Email 값이 없습니다.",
  234: "발급회사 전화번호 값이 없습니다.",
};

export const trusbillNtsErrorMessageObject = {
  SUC001: "성공",
  SYS001: "연계 기관 오류",
  SYN001: "복호화 실패",
  SYN002: "공급사업자,수탁자 전자서명 오류",
  SYN003: "승인번호 오류",
  SYN004: "전자세금계산서 스키마 오류",
  WAIT02: "국세청에 전송중입니다.",
  ERR001: "공급자사업자번호 오류",
  ERR002: "공급받는자사업자번호 오류",
  ERR003: "수탁사업자등록번호 오류",
  ERR005: "발행일시 오류",
  ERR006: "작성일자 오류",
  ERR007: "공급가액, 세액 오류",
  ERR008: "코드 유형 오류",
  ERR009: "공급자(수탁자)의 폐업신고일자 이후 발생",
  ERR999: "기타 오류",
  ETE001: "계산서 내에 승인 번호가 없습니다.",
};
