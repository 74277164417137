import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import * as CM from "../../common/Common";

import icon3 from "../../../styles/images/icon3.png";
import {toJS} from "mobx";

/*
 * @desc  한도관리 컴포넌트
 */
const LimitSummary = (props) => {
  //필요 state 선언
  const [isLoaded, setIsLoaded] = useState(false);
  const [limitSummary, setLimitSummary] = useState({
    maxLimitAmountForMonthlyWithdrawal: 0, //월간출금한도
    limitAmountForMonthlyWithdrawal: 0, //잔여이용한도
    limitAmountForEachWithdrawal: 0, //출금의뢰건별한도
    accumulatedAmount: 0, //출금누계액
  });

  //화면 진입 시 한도관리 조회
  useEffect(() => {
    /*
     * @desc  한도관리 및 업무정보 조회 Request
     */
    async function startStoreAxios() {
      try {
        await props.store.axiosBusinessInfo();
      }catch (error) {
        console.error(error);
      }
    }

    const fnSetLimitSummary = (data) => {
      setLimitSummary({
        maxLimitAmountForMonthlyWithdrawal: data.cmsBusiness.maxLimitAmountForMonthlyWithdrawal, //월간출금한도
        limitAmountForMonthlyWithdrawal: data.cmsBusiness.limitAmountForMonthlyWithdrawal, //잔여이용한도
        limitAmountForEachWithdrawal: data.cmsBusiness.limitAmountForEachWithdrawal, //출금의뢰건별한도
        accumulatedAmount: data.cmsBusiness.maxLimitAmountForMonthlyWithdrawal - data.cmsBusiness.limitAmountForMonthlyWithdrawal, //출금누계액
      });
    }

    const fnFetchBusinessInfo = () => {
      setIsLoaded(true);
      const fnSetBusinessInfo = (objStatus, data) => {
        if (objStatus.status !== 200) {
          CM.cfnAlert(objStatus.statusText);
          return;
        }
        startStoreAxios(); //store 값을 불러와서 set 하는 부분

        if(data.insuranceExpireDate !== null) {
          const today = new Date();
          const compareDate = CM.cfnConvertStringToDate(data.insuranceExpireDate);
          const remainDate = CM.cfnDateCalculation(compareDate, today) + 1;


          if (remainDate > 0 && remainDate < 30) {
            CM.cfnAlert("귀 기관의 CMS " + (data.insuranceMethodCode === "5" ? "보증보험이 " : "질권이 ") + CM.cfnDateFormat(data.insuranceExpireDate, "yyyyMMdd") + "일자로 만료됩니다.\n만료일 이전까지 해당 보험을 갱신하지 않을 경우 출금청구(자동출금) 등이 \n중단되오니 만기일 이전에 갱신하시기 바랍니다. ")
          } else if (remainDate <= 0) {
            CM.cfnAlert((data.insuranceMethodCode === "5" ? "보증보험이 " : "질권이 ") + "만기되었습니다. (만기일자 : " + CM.cfnDateFormat(data.insuranceExpireDate, "yyyyMMdd") + ")\n해당 보험을 갱신하지 않을 경우 출금청구(자동출금) 등 이용이 불가하오니 \n갱신하시기 바랍니다.")
          }
        }
        fnSetLimitSummary(data);
      };

      const storeBusinessInfo = CM.cfnCopyObject(toJS(props.store.businessInfo));
      if (CM.cfnIsEmpty(storeBusinessInfo)) {
        props.store.getBusinessInfo(fnSetBusinessInfo);
      } else {
        fnSetLimitSummary(storeBusinessInfo);
      }
    };
    if(!isLoaded) fnFetchBusinessInfo();
  }, [props.store,  isLoaded, limitSummary]);

  return (
    <div className="limit-manag">
      <div className="icon-header">
        <h3 className="icon" style={{ backgroundImage: `url(${icon3})` }}>
          한도관리
        </h3>
      </div>
      <div className="content-box limit">
        <div className="text-unit">
          <h4 style={{ maginBottom: "0!important" }}>월간 출금한도</h4>
          <div>
            <span className="text-unit_num">{CM.cfnAddComma(limitSummary.maxLimitAmountForMonthlyWithdrawal) || 0}</span>
            <span className="text-unit_text"> 원</span>
          </div>
        </div>
        <div className="text-unit line">
          <div className="text-unit_text">출금 누계액</div>
          <div>
            <span className="text-unit_num">{CM.cfnAddComma(limitSummary.accumulatedAmount) || 0}</span>
            <span className="text-unit_text"> 원</span>
          </div>
        </div>
        <div className="text-unit">
          <div className="text-unit_text">잔여 월간 출금한도</div>
          <div>
            <span className="text-unit_spnum">{CM.cfnAddComma(limitSummary.limitAmountForMonthlyWithdrawal) || 0}</span>
            <span className="text-unit_text"> 원</span>
          </div>
        </div>
        <div className="text-unit">
          <h4 style={{ marginBottom: "0!important" }}>건별 출금한도</h4>
          <div>
            <span className="text-unit_num">{CM.cfnAddComma(limitSummary.limitAmountForEachWithdrawal) || 0}</span>
            <span className="text-unit_text"> 원</span>
          </div>
        </div>
      </div>
      <div className="text-unit2" style={{textAlign: "left", fontSize: "11px", paddingTop: "5px"}}>
        <span>※ 월간출금한도는 출금의뢰하는 금액을 기준으로 당월 제2영업일부터<br/>익월 제1영업일까지 적용</span>
      </div>
    </div>
  );
};

export default inject((rootStore) => ({
  store: rootStore.instituteStore,
}))(observer(LimitSummary));
