import LoginStore from "./LoginStore";
import InstituteStore from "./InstituteStore";

// 루트 스토어
class RootStore {
  constructor() {
    this.loginStore = new LoginStore(this);
    this.instituteStore = new InstituteStore(this);
  }
}

export default RootStore;
