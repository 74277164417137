import React, { useState, Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as CM from "../../common/Common";
import { CheckboxForm, MaskedField, DatePickerForm } from "../../template/ComponentForm";
import TableForm from "../../template/TableForm";
import CustomerData from "./CustomerData";
import { TooltipForm } from "../../template/ComponentForm";
import { AddressSearchModal } from "../customerModal/AddressSearchModal";

// 현금영수증 발행정보 radio 값
const radioCashBillIndividual = [
  { value: "NONE", label: "해당없음" },
  { value: "MOBILE", label: "휴대전화번호" },
  { value: "IDENTIFICATION_NO", label: "주민등록번호" },
];
const radioCashBillBusinesser = [
  { value: "NONE", label: "해당없음" },
  { value: "MOBILE", label: "휴대전화번호" },
  { value: "IDENTIFICATION_NO", label: "사업자등록번호" },
];

/*
 * @desc  전체 disable 처리를 하는 css
 */
const useStyles = makeStyles((theme) => ({
  disabled_div: {
    "pointer-events": "none",
    opacity: 0.6,
  },
}));

/*
 * @desc  [등록] 고객 구분 컴포넌트
 */
export const CreateCustomerDivision = (props) => {
  // 주민등록번호 전체입력 구분 radio value
  const radioDivision = [
    { value: "INDIVIDUAL", label: "개인" },
    { value: "BUSINESSER", label: "사업자" },
  ];
  const { checkIdentificationNo, setCheckIdentificationNo } = props;

  // 기부금/현금영수증대상(주민번호 전체입력) checkbox handler
  const handleCheckIdentificationNoChange = (e) => {
    const checked = e.target.checked;
    setCheckIdentificationNo(checked);

    if (!checked) {
      props.changeIdentificationNo("", "last"); // 체크해제시 주민번호 뒷자리 값 초기화
    }
  };

  // 개인/사업자 구분이 변경될 때 주민등록번호 초기화를 처리하는 handler
  const handleCustomerTypeChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    await props.changeCustomer(name, value);
    await props.changeCustomer("identificationNo", "");
    await props.changeIdentificationNo("", "first");
    await props.changeIdentificationNo("", "last");

    setCheckIdentificationNo(false);
  };

  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["150px", "auto", "150px", "auto", "150px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">개인/사업자 구분</TableCell>
            <TableCell className="td" colSpan="5">
              <RadioGroup name="customerType" value={props.customer.customerType} onChange={handleCustomerTypeChange} data-testid="customer-division-customerType" row>
                {radioDivision.map((element, index) => {
                  return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
                })}
              </RadioGroup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">고객명</TableCell>
            <TableCell className="td">
              <TextField
                className="w180"
                value={props.customer.customerName}
                onChange={props.handleCustomerChange}
                name="customerName"
                inputProps={{ "data-testid": "customer-division-customerName" }}
              />
            </TableCell>
            <TableCell className="th" style={{ whiteSpace: "pre-wrap" }}>
              {"INDIVIDUAL" !== props.customer.customerType ? "사업자등록번호" : checkIdentificationNo ? "주민등록번호" : "주민등록번호 앞자리\n(6자리)"}
            </TableCell>
            <TableCell className="td" colSpan="3">
              <CreateCustomerIdentification
                checkIdentificationNo={checkIdentificationNo}
                handleCheckIdentificationNoChange={handleCheckIdentificationNoChange}
                firstIdentificationNo={props.firstIdentificationNo}
                lastIdentificationNo={props.lastIdentificationNo}
                handleIdentificationNoChange={props.handleIdentificationNoChange}
                customer={props.customer}
                handleCustomerChange={props.handleCustomerChange}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  [등록] 고객 구분 컴포넌트 사업자등록번호 또는 주민등록번호 앞자리
 */
const CreateCustomerIdentification = (props) => {
  // 고객구분 == 개인
  if (props.customer.customerType === "INDIVIDUAL") {
    return (
      <Fragment>
        <CheckboxForm
          checked={props.checkIdentificationNo || false}
          handleChange={props.handleCheckIdentificationNoChange}
          label="전자(종이)세금계산서/기부금 영수증 대상 (주민등록번호 전체입력)"
          customProps={{ "data-testid": "customer-form-checkbox-identification" }}
        />
        <div>
          <Input
            name="firstIdentificationNo"
            className="w100 nomargin"
            type="text"
            placeholder="YYMMDD"
            value={props.firstIdentificationNo}
            onChange={(e) => props.handleIdentificationNoChange(e, "first")}
            inputProps={{ maxLength: 6, "data-testid": "customer-form-individual-firstIdentificationNo" }}
          />
          <span style={{ marginRight: "11px" }}>{props.checkIdentificationNo ? " - " : ""}</span>
          <Input
            name="lastIdentificationNo"
            className="w100"
            type="text"
            value={props.lastIdentificationNo}
            onChange={(e) => props.handleIdentificationNoChange(e, "last")}
            inputProps={{ maxLength: 7, "data-testid": "customer-form-individual-lastIdentificationNo" }}
            style={{ display: props.checkIdentificationNo ? "" : "none" }}
          />
        </div>
      </Fragment>
    );
  }

  // 고객구분 == 사업자
  return (
    <Fragment>
      <Input
        name="identificationNo"
        className="w200"
        type="text"
        value={props.customer.identificationNo}
        onChange={props.handleCustomerChange}
        inputComponent={MaskedField}
        inputProps={{
          mask: "bizNo",
          "aria-label": "description",
          "data-testid": "customer-form-business-identificationNo",
        }}
      />
        (숫자 10자리)
    </Fragment>
  );
};

/*
 * @desc  개인 && 사업 > 고객정보 > 필수 입력사항 컴포넌트
 */
export const CustomerRequiredInputs = (props) => {
  const style = useStyles();
  return (
    <div className={props.readOnlyFlag === true ? style.disabled_div : ""}>
      <Table>
        {CM.cfnCompColGroup(["150px", "auto", "150px", "auto", "150px"])}
        <TableBody>
          <TableRow>
            <TableCell className="th" style={{ borderTop: "0" }}>
              고객구분
              </TableCell>
            <TableCell className="td" style={{ borderTop: "0" }}>
              <Select
                native
                className="w60p"
                value={props.selectRequiredInputs.customerGroup}
                onChange={(e) => props.handleRequiredInputsHandler(e, "customerGroup")}
                inputProps={{ "data-testid": "customer-update-select-customerGroup" }}>
                <option value="" key="none">
                  선택
                  </option>
                {props.storeCustomerGroup.map((element, index) => {
                  return (
                    <option value={element.uniqueKey} key={index}>
                      {element.customerGroupName}
                    </option>
                  );
                })}
              </Select>
              <button
                className="btn-s"
                onClick={(event) => {
                  props.setCustomerGroupModalOpen && props.setCustomerGroupModalOpen(true);
                }}>
                관리
                </button>
            </TableCell>
            <TableCell className="th" style={{ borderTop: "0" }}>
              고객담당자/고객지사
              </TableCell>
            <TableCell className="td" style={{ borderTop: "0" }} colSpan="2">
              <Select
                native
                className=""
                value={props.selectRequiredInputs.staff}
                onChange={(e) => props.handleRequiredInputsHandler(e, "staff")}
                inputProps={{ "data-testid": "customer-update-select-staff" }}>
                <option value="" key="none">
                  선택
                  </option>
                {props.optionStaffsAndBranches.map((element, index) => {
                  return (
                    <option value={element.uniqueKey} key={index}>
                      {element.name}
                    </option>
                  );
                })}
              </Select>
              <button
                className="btn-s"
                onClick={(event) => {
                  props.setStaffModalOpen && props.setStaffModalOpen(true);
                }}>
                관리
                </button>
            </TableCell>
            <TableCell className="td" style={{ borderTop: "0" }} />
          </TableRow>
        </TableBody>
      </Table>
      <div className="table-bottom-area" />
    </div>
  );
};

/*
 * @desc  개인 > 고객정보 > 선택 입력사항 컴포넌트
 */
export const CustomerIndividualSelectInput = (props) => {
  // 현금영수증 발행정보 mask 설정 / 해제
  const cashbillIdentificationNoProps = (radioValue, dataTestId) => {
    if (radioValue === "MOBILE") {
      return {
        type: "text",
        inputComponent: MaskedField,
        inputProps: {
          mask: "mobile",
          "data-testid": dataTestId,
        },
      };
    } else if (radioValue === "IDENTIFICATION_NO") {
      return {
        type: "text",
        inputComponent: MaskedField,
        inputProps: {
          mask: "personalIdentificationNo",
          "data-testid": dataTestId,
        },
      };
    }

    return { type: "text" };
  };

  const style = useStyles();
  const [addressOpen, setAddressOpen] = useState(false);
  const addressColumnList = {
    zipCode: "customerZipCode",
    addressCode: "customerAddress",
  };

  const handleAddressModal = () => {
    setAddressOpen(true);
  };

  const handleAddress = ({ ...address }) => {
    Object.keys(address).forEach((key) => {
      props.changeCustomer(key, address[key]);
    });
  };

  return (
    <div className={props.readOnlyFlag === true ? style.disabled_div : ""} hidden={props.customer.customerType === "INDIVIDUAL" ? false : true}>
      <h4>
        선택 입력사항
          {props.checkIdentificationNo && !props.isPaymentInformation ? (
          <span className="inforbox3 padding-l20">
            세금계산서 발행고객인 경우 (<span className="check-astro">*</span>) 항목을 반드시 입력해야 합니다.
          </span>
        ) : null}
      </h4>
      <Table>
        {CM.cfnCompColGroup(["150px", "auto", "150px", "auto", "150px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">휴대전화번호</TableCell>
            <TableCell className="td">
              <Input
                name="customerMobile"
                className="w100p"
                value={props.customer.customerMobile}
                onChange={props.handleCustomerChange}
                inputComponent={MaskedField}
                inputProps={{
                  mask: "mobile",
                  "data-testid": "customer-individual-customerMobile",
                }}
              />
            </TableCell>
            <TableCell className="th">이메일주소{props.checkIdentificationNo && !props.isPaymentInformation ? <span className="check-astro">*</span> : null}</TableCell>
            <TableCell className="td" colSpan="3">
              <Input
                className="w150 nomargin"
                value={props.customerEmail.id}
                onChange={(e) => props.handleCustomerEmailChange(e, "id")}
                inputProps={{ "data-testid": "customer-individual-email-id" }}
              />
              {"@"}
              <Input
                className="w150"
                value={props.customerEmail.domain}
                required={true}
                readOnly={props.customerEmail.selected !== "manual" ? true : false}
                onChange={(e) => props.handleCustomerEmailChange(e, "domain")}
                inputProps={{ "data-testid": "customer-individual-email-domain" }}
              />
              <Select
                native
                className="w140"
                value={props.customerEmail.selected}
                onChange={props.handleSelectboxCustomerEmailChange}
                inputProps={{ "data-testid": "customer-individual-select-email" }}>
                {CM.cfnGetEmailDomains().map((element, index) => {
                  return (
                    <option value={element.domain} key={index}>
                      {element.label}
                    </option>
                  );
                })}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">유선전화번호</TableCell>
            <TableCell className="td">
              <Input
                name="customerTelephone"
                className="w100p"
                value={props.customer.customerTelephone}
                onChange={props.handleCustomerChange}
                inputComponent={MaskedField}
                inputProps={{
                  mask: "telephone",
                  "data-testid": "customer-individual-customerTelephone",
                }}
              />
            </TableCell>
            <TableCell className="th">팩스번호</TableCell>
            <TableCell className="td">
              <Input
                name="customerFax"
                className="w100p"
                value={props.customer.customerFax}
                onChange={props.handleCustomerChange}
                inputComponent={MaskedField}
                inputProps={{
                  mask: "telephone",
                  "data-testid": "customer-individual-customerFax",
                }}
              />
            </TableCell>
            <TableCell className="th">결혼기념일</TableCell>
            <TableCell className="td">
              <DatePickerForm
                className="w100p"
                value={props.customerDetailInfo.결혼기념일}
                handleChange={(e) => props.handleCustomerDetailInfoChange("결혼기념일", e)}
                customProps={{ inputProps: { "data-testid": "customer-individual-wedding-anniversary" } }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">주소</TableCell>
            <TableCell className="td" colSpan="5">
              <Input
                name="customerZipCode"
                className="w70"
                placeholder="우편번호"
                value={props.customer.customerZipCode}
                onClick={handleAddressModal}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-individual-customerZipCode" }}
              />
              <button className="btn-s" onClick={handleAddressModal} data-testid="customer-individual-button-openAddress" style={{ marginRight: "10px" }}>
                조회
                </button>
              <Input
                name="customerAddress"
                className="w320"
                placeholder="주소"
                style={{ marginLeft: "00px" }}
                value={props.customer.customerAddress}
                onClick={handleAddressModal}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-individual-customerAddress" }}
              />
              <Input
                name="customerAddressDetail"
                className="w320"
                placeholder="상세주소"
                value={props.customer.customerAddressDetail}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-individual-customerAddressDetail" }}
              />
            </TableCell>
          </TableRow>
          {props.isPaymentInformation ? null : (
            <TableRow>
              <TableCell className="th" style={{ whiteSpace: "pre-wrap" }}>
                현금영수증
                    <br />
                    발행정보
                    <TooltipForm contents="현금영수증 직접발행시에만 사용되고, 자동발행시에는 예금주 사업자번호와 예금주 연락처로 현금영수증이 발행됩니다. 전화번호로 현금영수증을 직접발행할 고객은 현금영수증 발행정보에 입력해 주시기 바랍니다." />
              </TableCell>
              <TableCell className="td" colSpan="5">
                <RadioGroup
                  name="selectedCashbillIdentificationNo"
                  value={props.selectedCashbillIdentificationNo}
                  onChange={props.handleRadioChange}
                  data-testid="customer-individual-radio-cashbillIdentificationNo"
                  row>
                  {radioCashBillIndividual.map((element, index) => {
                    return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
                  })}
                </RadioGroup>
                <Input
                  name="cashbillIdentificationNo"
                  className="w150"
                  value={props.customer.cashbillIdentificationNo}
                  onChange={props.handleCustomerChange}
                  readOnly={props.selectedCashbillIdentificationNo === "NONE"}
                  inputProps={{ "data-testid": "customer-individual-input-cashbillIdentificationNo" }}
                  {...cashbillIdentificationNoProps(props.selectedCashbillIdentificationNo, "customer-individual-input-cashbillIdentificationNo")}
                />
                <span style={{ color: "grey" }}>※ 상세 내용은 "좌측의 물음표 그림" 위에 마우스를 올려보세요</span>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className="th">비고</TableCell>
            <TableCell className="td" colSpan="5">
              <TextField
                name="remark"
                className="w100p"
                variant="outlined"
                value={props.customer.remark}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-individual-remark" }}
                multiline
                rows={3}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <AddressSearchModal open={addressOpen} setOpen={setAddressOpen} setAddress={handleAddress} addressColumnList={addressColumnList} />
    </div>
  );
};

/*
 * @desc  사업자 > 고객정보 > 선택 입력사항 컴포넌트
 */
export const CustomerBusinessSelectInput = (props) => {
  // 현금영수증 발행정보 mask 설정 / 해제
  const cashbillIdentificationNoProps = (radioValue, dataTestId) => {
    if (radioValue === "MOBILE") {
      return {
        type: "text",
        inputComponent: MaskedField,
        inputProps: {
          mask: "mobile",
          "data-testid": dataTestId,
        },
      };
    } else if (radioValue === "IDENTIFICATION_NO") {
      return {
        type: "text",
        inputComponent: MaskedField,
        inputProps: {
          mask: "bizNo",
          "data-testid": dataTestId,
        },
      };
    }

    return null;
  };

  const style = useStyles();

  const [addressOpen, setAddressOpen] = useState(false);
  const addressColumnList = {
    zipCode: "customerZipCode",
    addressCode: "customerAddress",
  };

  const handleAddressModal = () => {
    setAddressOpen(true);
  };

  const handleAddress = ({ ...address }) => {
    Object.keys(address).forEach((key) => {
      props.changeCustomer(key, address[key]);
    });
  };

  return (
    <div className={props.readOnlyFlag === true ? style.disabled_div : ""} hidden={props.customer.customerType === "INDIVIDUAL" ? true : false}>
      <h4>
        선택 입력사항
          {props.isPaymentInformation ? null : (
          <span className="inforbox3 padding-l20">
            세금계산서 발행고객인 경우 (<span className="check-astro">*</span>) 항목을 반드시 입력해야 합니다.
          </span>
        )}
      </h4>
      <Table>
        {CM.cfnCompColGroup(["150px", "auto", "150px", "auto", "150px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">대표자명{props.isPaymentInformation ? null : <span className="check-astro">*</span>}</TableCell>
            <TableCell className="td">
              <Input
                name="대표자명"
                className="w100p"
                value={props.customerDetailInfo.대표자명}
                onChange={(e) => props.handleCustomerDetailInfoChange("대표자명", e)}
                inputProps={{ "data-testid": "customer-business-representative-name" }}
              />
            </TableCell>
            <TableCell className="th">업태{props.isPaymentInformation ? null : <span className="check-astro">*</span>}</TableCell>
            <TableCell className="td">
              <Input
                name="업태"
                className="w100p"
                value={props.customerDetailInfo.업태}
                onChange={(e) => props.handleCustomerDetailInfoChange("업태", e)}
                inputProps={{ "data-testid": "customer-business-business-conditions" }}
              />
            </TableCell>
            <TableCell className="th">업종{props.isPaymentInformation ? null : <span className="check-astro">*</span>}</TableCell>
            <TableCell className="td">
              <Input
                name="업종"
                className="w100p"
                value={props.customerDetailInfo.업종}
                onChange={(e) => props.handleCustomerDetailInfoChange("업종", e)}
                inputProps={{ "data-testid": "customer-business-business-type" }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">담당자명{props.isPaymentInformation ? null : <span className="check-astro">*</span>}</TableCell>
            <TableCell className="td">
              <Input
                name="담당자명"
                className="w100p"
                value={props.customerDetailInfo.담당자명}
                onChange={(e) => props.handleCustomerDetailInfoChange("담당자명", e)}
                inputProps={{ "data-testid": "customer-business-name-of-person-in-charge" }}
              />
            </TableCell>
            <TableCell className="th">담당자 이메일주소{props.isPaymentInformation ? null : <span className="check-astro">*</span>}</TableCell>
            <TableCell className="td" colSpan="3">
              <Input className="w150 nomargin" value={props.customerEmail.id} onChange={(e) => props.handleCustomerEmailChange(e, "id")} inputProps={{ "data-testid": "customer-business-email-id" }} />
              {"@"}
              <Input
                className="w180"
                value={props.customerEmail.domain}
                required={true}
                readOnly={props.customerEmail.selected !== "manual" ? true : false}
                onChange={(e) => props.handleCustomerEmailChange(e, "domain")}
                inputProps={{ "data-testid": "customer-business-email-domain" }}
              />
              <Select native className="" value={props.customerEmail.selected} onChange={props.handleSelectboxCustomerEmailChange} inputProps={{ "data-testid": "customer-business-select-email" }}>
                <option value={""} key={"none"}>
                  선택
                  </option>
                {CM.cfnGetEmailDomains().map((element, index) => {
                  return (
                    <option value={element.domain} key={index}>
                      {element.label}
                    </option>
                  );
                })}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">담당자 휴대전화번호</TableCell>
            <TableCell className="td">
              <Input
                name="customerMobile"
                className="w100p"
                value={props.customer.customerMobile}
                onChange={props.handleCustomerChange}
                inputComponent={MaskedField}
                inputProps={{
                  mask: "mobile",
                  "data-testid": "customer-business-mobile",
                }}
              />
            </TableCell>
            <TableCell className="th">담당자 직위</TableCell>
            <TableCell className="td" colSpan="3">
              <Input
                name="담당자직위"
                className="w50p"
                value={props.customerDetailInfo.담당자직위}
                onChange={(e) => props.handleCustomerDetailInfoChange("담당자직위", e)}
                inputProps={{ "data-testid": "customer-business-position-in-charge" }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">유선전화번호</TableCell>
            <TableCell className="td">
              <Input
                id="create_customer_telephone"
                name="customerTelephone"
                className="w100p"
                value={props.customer.customerTelephone}
                onChange={props.handleCustomerChange}
                inputComponent={MaskedField}
                inputProps={{
                  mask: "telephone",
                  "data-testid": "customer-business-telephone",
                }}
              />
            </TableCell>
            <TableCell className="th">팩스번호</TableCell>
            <TableCell className="td" colSpan="3">
              <Input
                name="customerFax"
                className="w100p"
                value={props.customer.customerFax}
                onChange={props.handleCustomerChange}
                inputComponent={MaskedField}
                inputProps={{
                  mask: "telephone",
                  "data-testid": "customer-business-fax",
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">주소</TableCell>
            <TableCell className="td" colSpan="5">
              <Input
                id="create_customer_zipcode"
                name="customerZipCode"
                className="w70"
                placeholder="우편번호"
                value={props.customer.customerZipCode}
                onClick={handleAddressModal}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-business-zipcode" }}
              />
              <button className="btn-s" onClick={handleAddressModal} data-testid="customer-business-button-openAddress" style={{ marginRight: "10px" }}>
                조회
                </button>
              <Input
                id="create_customer_address"
                name="customerAddress"
                className="w320"
                placeholder="주소"
                style={{ marginLeft: "20px" }}
                value={props.customer.customerAddress}
                onClick={handleAddressModal}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-business-address" }}
              />
              <Input
                id="create_customer_address_detail"
                name="customerAddressDetail"
                placeholder="상세주소"
                className="w320"
                value={props.customer.customerAddressDetail}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-business-address-detail" }}
              />
            </TableCell>
          </TableRow>
          {props.isPaymentInformation ? null : (
            <TableRow>
              <TableCell className="th" style={{ whiteSpace: "pre-wrap" }}>
                현금영수증
                    <br />
                    발행정보
                    <TooltipForm contents="현금영수증 직접발행시에만 사용되고, 자동발행시에는 예금주 사업자번호와 예금주 연락처로 현금영수증이 발행됩니다. 전화번호로 현금영수증을 직접발행할 고객은 현금영수증 발행정보에 입력해 주시기 바랍니다." />
              </TableCell>
              <TableCell className="td" colSpan="5">
                <RadioGroup
                  name="selectedCashbillIdentificationNo"
                  value={props.selectedCashbillIdentificationNo}
                  onChange={props.handleRadioChange}
                  data-testid="customer-business-cashbillIdentificationNo"
                  row>
                  {radioCashBillBusinesser.map((element, index) => {
                    return <FormControlLabel key={index} control={<Radio color="primary" />} value={element.value} label={element.label} />;
                  })}
                </RadioGroup>
                <Input
                  id="create_customer_cashbill_identification_no"
                  name="cashbillIdentificationNo"
                  className="w180"
                  value={props.customer.cashbillIdentificationNo}
                  onChange={props.handleCustomerChange}
                  readOnly={props.selectedCashbillIdentificationNo === "NONE" ? true : false}
                  inputProps={{ "data-testid": "customer-business-cashbill-identificationNo" }}
                  {...cashbillIdentificationNoProps(props.selectedCashbillIdentificationNo, "customer-business-cashbill-identificationNo")}
                />
                <span style={{ color: "grey" }}>※ 상세 내용은 "좌측의 물음표 그림" 위에 마우스를 올려보세요</span>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className="th">비고</TableCell>
            <TableCell className="td" colSpan="5">
              <TextField
                name="remark"
                className="w100p"
                variant="outlined"
                value={props.customer.remark}
                onChange={props.handleCustomerChange}
                inputProps={{ "data-testid": "customer-business-remark" }}
                multiline
                rows={3}
                rowsMax={3}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <AddressSearchModal open={addressOpen} setOpen={setAddressOpen} setAddress={handleAddress} addressColumnList={addressColumnList} />
    </div>
  );
};

/*
 * @desc  [수정/조회] 고객 구분 컴포넌트
 */
export const ViewCustomerDivision = (props) => {
  return (
    <div>
      <Table>
        {CM.cfnCompColGroup(["150px", "auto", "150px", "auto", "150px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">개인/사업자 구분</TableCell>
            <TableCell className="td" align="center">
              {"INDIVIDUAL" === props.customer.customerType ? "개인" : "사업자"}
            </TableCell>
            <TableCell className="th">고객명</TableCell>
            <TableCell className="td" align="center">
              {props.customer.customerName}
            </TableCell>
            <TableCell
              className="th"
              style={{
                whiteSpace: "pre-wrap",
              }}>
              {"INDIVIDUAL" !== props.customer.customerType
                ? "사업자등록번호"
                : props.customer.identificationNo && props.customer.identificationNo.length === 6
                  ? "주민등록번호\n앞자리"
                  : "주민등록번호"}
            </TableCell>
            <TableCell className="td" align="center">
              {CM.cfnIdentificationNoFormat(props.customer.identificationNo, true)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

/*
 * @desc  [수정/조회] 한 고객의 수납정보 목록 컴포넌트
 */
export const CustomerReceiptList = (props) => {
  return (
    <Table>
      <TableForm.compTableHead arrData={["수납방법", "납부자번호", "자금종류", "수납일", "금액", "수납시작월", "수납종료월", "계좌등록상태", "서비스상태"]} />
      <TableBody>
        {CM.cfnIsEmpty(props.receiptContractList) ? (
          <TableForm.compEmptyTableRow colSpan={9} />
        ) : (
            props.receiptContractList.map((receiptContract, index) => {
              return (
                <TableRow hover key={index} className="show-detail" onClick={(e) => props.handleRowClick(e, "receipt", receiptContract)} data-testid={`customer-update-receipt-list-${index}`}>
                  <TableCell align="center">{CustomerData.getReceiptName.transactionMethod(receiptContract.transactionMethod)}</TableCell>
                  <TableCell align="center">{receiptContract.payerNo}</TableCell>
                  <TableCell align="center">{receiptContract.capital.capitalName}</TableCell>
                  <TableCell align="center">{CM.cfnDayFormat(receiptContract.paySpecifiedDay)}</TableCell>
                  <TableCell align="center">{CM.cfnAddComma(receiptContract.payAmount)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(receiptContract.payStartY4mm, "yyyyMM")}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(receiptContract.payEndY4mm, "yyyyMM")}</TableCell>
                  <TableCell align="center">
                    {Object.isExtensible(receiptContract.account) ? CustomerData.getReceiptName.accountRegistrationStatus(receiptContract.account.accountRegistrationStatus) : ""}
                  </TableCell>
                  <TableCell align="center">{CustomerData.getReceiptName.contractStatus(receiptContract.contractStatus)}</TableCell>
                </TableRow>
              );
            })
          )}
      </TableBody>
    </Table>
  );
};

/*
 * @desc  [수정/조회] 한 고객의 지급정보 목록 컴포넌트
 */
export const CustomerPaymentList = (props) => {
  return (
    <Table>
      <TableForm.compTableHead arrData={["지급방법", "자금종류", "지급일", "금액", "지급시작월", "지급종료월", "서비스상태"]} />
      <TableBody>
        {CM.cfnIsEmpty(props.paymentContractList) ? (
          <TableForm.compEmptyTableRow colSpan={7} />
        ) : (
            props.paymentContractList.map((paymentContract, index) => {
              return (
                <TableRow hover key={index} className="show-detail" onClick={(e) => props.handleRowClick(e, "payment", paymentContract)} data-testid={`customer-update-payment-list-${index}`}>
                  <TableCell align="center">{CustomerData.getPaymentName.transactionMethod(paymentContract.transactionMethod)}</TableCell>
                  <TableCell align="center">{paymentContract.capital.capitalName}</TableCell>
                  <TableCell align="center">{CM.cfnDayFormat(paymentContract.paySpecifiedDay)}</TableCell>
                  <TableCell align="center">{CM.cfnAddComma(paymentContract.payAmount)}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(paymentContract.payStartY4mm, "yyyyMM")}</TableCell>
                  <TableCell align="center">{CM.cfnDateFormat(paymentContract.payEndY4mm, "yyyyMM")}</TableCell>
                  <TableCell align="center">{CustomerData.getPaymentName.contractStatus(paymentContract.contractStatus)}</TableCell>
                </TableRow>
              );
            })
          )}
      </TableBody>
    </Table>
  );
};