import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, TextField, FormControl } from '@material-ui/core';
import * as CM from '../../common/Common';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { EditableCellInput } from '../../template/ComponentForm';
import TableForm from '../../template/TableForm';

const CustMnmtTable = ({ instituteStore }) => {
  const [newCustomerGroup, setNewCustomerGroup] = useState('');
  const [customerGroup, setCustomerGroup] = useState([]);

  useEffect(() => {
    const fnGetCustomerGroupCallback = (objStatus, data) => {
      if (objStatus.status !== 200) {
        CM.cfnAlert(objStatus.statusText);
      }else{
        instituteStore.setCustomerGroup(data); // store 값 설정
        setCustomerGroup(data);
      }
    };

    const storeCustomerGroup = CM.cfnCopyObject(toJS(instituteStore.customerGroup));
    if (CM.cfnIsEmpty(storeCustomerGroup)) {
      instituteStore.getCustomerGroup(fnGetCustomerGroupCallback);
    }else{
      setCustomerGroup(storeCustomerGroup);
    }
  }, [instituteStore]);

  /*
   * @desc    신규 추가 고객 그룹명 입력란 변경 이벤트 핸들러
   */
  const handleCustomerGroupAdd = e => {
    e.preventDefault();
    if (newCustomerGroup && newCustomerGroup.trim() !== '') fnInsertCustomerGroup();
  };

  /*
   * @desc    신규 고객 그룹명 추가 버튼 클릭 이벤트 핸들러
   */
  const fnInsertCustomerGroup = () => {
    CM.cfnAxios('api/institute/groups', 'post', { customerGroupName: newCustomerGroup }, fnCustomerGroupCallback);
  };

  /*
   * @desc     추가 필드 값 복사
   */
  const handleNewCustomerGroupChange = e => {
    setNewCustomerGroup(e.target.value);
  };

  /*
   * @desc    Request 후 초기화하는 callback 함수
   */
  const fnCustomerGroupCallback = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }

    if (objStatus.status === 200) {
      CM.cfnAlert('정상적으로 처리되었습니다.');
      setNewCustomerGroup('');
      instituteStore.getCustomerGroup(fnGetCustomerCallback);
    }
  };

  /*
   * @desc    고객그룹 조회 후 callback 함수
   */
  const fnGetCustomerCallback = (objStatus, data) => {
    if (objStatus.status !== 200) {
      CM.cfnAlert(objStatus.statusText);
      return false;
    }
    setCustomerGroup(data);
    instituteStore.setCustomerGroup(data);
  };

  /*
   * @desc    기존 지사 수정 버튼 클릭 이벤트 핸들러
   */
  const handleCustomerGroupUpdate = (rowData) => {
    if(rowData.isChanged){
      CM.cfnAxios('api/institute/groups', 'put', rowData, fnCustomerGroupCallback);
    }else{
      CM.cfnAlert("변경된 내역이 없습니다.");
    }
  };

  /*
   * @desc    기존 고개그룹 삭제 버튼 클릭 이벤트 핸들러
   */
  const handleCustomerGroupDelete = groupUniqueKey => {
    CM.cfnConfirm('정말 삭제하시겠습니까?', () => {
      CM.cfnAxios(`/api/institute/groups/${groupUniqueKey}`, 'delete', '', fnCustomerGroupCallback);
    });
  };

  const handleCustomerGroupNameChange = (row, change) => {
    //array 형태의 state에 직접 접근하지 않도록 임시배열 선언 후 값 복사
    const tempArray = [...customerGroup];
    const targetBranch = row.uniqueKey;
    let rowIndex = null;

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].uniqueKey === targetBranch) {
        rowIndex = i;
        break;
      }
    }

    tempArray[rowIndex].customerGroupName = change.fieldValue;
    tempArray[rowIndex].isChanged = true;
    setCustomerGroup(tempArray);
  };

  return (
    <div>
      <div className="modify-area">
        <div className="block">
          <form name="customerGroup" onSubmit={handleCustomerGroupAdd}>
            <label className="label-l">고객구분명</label>
            <TextField
              placeholder="추가할 고객구분명 입력"
              value={newCustomerGroup}
              data-testid="insert-customerGroup-name"
              onChange={handleNewCustomerGroupChange}
              required={true}
            />
            <button type="submit" className="btn-l">
              고객구분 추가
            </button>
          </form>
        </div>
      </div>
      <div>
        <div className="table-top-area" />
        <Table aria-labelledby="tableTitle" data-testid="customerGroup-table" style={{ marginBottom: '120px' }}>
          {CM.cfnCompColGroup(['auto', 'auto'])}
          <TableHead>
            <TableRow>
              <TableCell className="th2">고객구분명</TableCell>
              <TableCell className="th2">고객구분 관리</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerGroup.length === 0 ? (
              <TableForm.compEmptyTableRow colSpan={2} />
            ) : (
              customerGroup.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow key={index} hover tabIndex={-1}>
                    <TableCell align="center" component="th" id={labelId} scope="row" padding="none">
                      <FormControl>
                        <EditableCellInput
                          row={row}
                          fieldName="customerGroupName"
                          onCellValueChange={e => handleCustomerGroupNameChange(row, e)}
                          inputProps={{
                            style: { textAlign: "center", paddingLeft: 0 }
                          }}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      <div className="btn-l" onClick={() => handleCustomerGroupUpdate(row)}>
                        수정
                      </div>
                      <div className="btn-m" onClick={() => handleCustomerGroupDelete(row.uniqueKey)}>
                        삭제
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );

};

export default inject(rootStore => ({
  instituteStore: rootStore.instituteStore
}))(observer(CustMnmtTable));
